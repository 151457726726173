/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type CustomFields_Building_Fragment = { __typename: 'Building', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFields_CrmCompany_Fragment = { __typename: 'CrmCompany', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFields_CrmPerson_Fragment = { __typename: 'CrmPerson', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFields_Item_Fragment = { __typename: 'Item', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFields_Project_Fragment = { __typename: 'Project', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFields_Quote_Fragment = { __typename: 'Quote', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFields_Requirement_Fragment = { __typename: 'Requirement', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFields_TaskDocument_Fragment = { __typename: 'TaskDocument', id: string, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export type CustomFieldsFragment = CustomFields_Building_Fragment | CustomFields_CrmCompany_Fragment | CustomFields_CrmPerson_Fragment | CustomFields_Item_Fragment | CustomFields_Project_Fragment | CustomFields_Quote_Fragment | CustomFields_Requirement_Fragment | CustomFields_TaskDocument_Fragment;

export const CustomFieldsFragmentDoc = gql`
    fragment CustomFields on WithCustomFields {
  id
  customFields {
    key
    value
    dataType
    group
  }
}
    `;