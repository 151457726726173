import { useApolloClient } from "@apollo/client";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { ModalOpenButton } from "@msys/ui";
import { Skill } from "../../../../clients/graphqlTypes";
import { useModifyOrganisationProfile__ServicesMutation } from "../Organisations.generated";
import { PickSkillsetModal } from "../modals/PickSkillsetModal";

interface Props {
  slug: string;
  skillset: Skill[];
}

export const ModifyOrganisationProfileSkillsetButton: React.FC<Props> = ({
  slug,
  skillset,
}) => {
  const client = useApolloClient();
  const [modifyProfile] = useModifyOrganisationProfile__ServicesMutation({
    client,
  });

  return (
    <ModalOpenButton
      Modal={PickSkillsetModal}
      modalProps={{
        skillset,
        handleComplete: async skillset => {
          await modifyProfile({ variables: { input: { slug, skillset } } });
        },
      }}
    >
      <IconButton color="primary" size="small">
        <EditIcon />
      </IconButton>
    </ModalOpenButton>
  );
};
