import { useApolloClient } from "@apollo/client";
import { Modal, ModalOpenButton } from "@msys/ui";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import { DialogContentText, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import moment, { Moment } from "moment/moment";
import { useSnackbar } from "notistack";
import React from "react";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField";
import { usePayInvoiceMutation } from "../Invoices.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  invoiceId: string;
  projectId: string;
  docId: string;
}

export const PayInvoiceButton = ({ invoiceId, projectId, docId }: Props) => {
  const client = useApolloClient();
  const [payInvoice, { loading }] = usePayInvoiceMutation({ client });

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate("InvoiceDetailsForm");

  const handleSubmit = async (values: FormValues) => {
    try {
      await payInvoice({
        variables: {
          input: {
            projectId,
            docId,
            invoiceId,
            values: {
              paidAt: values.paidAt.format("YYYY-MM-DD"),
            },
          },
        },
      });
      enqueueSnackbar(t("Invoice marked as paid"));
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <ModalOpenButton
      Modal={PayInvoiceModal}
      modalProps={{
        handleComplete: handleSubmit,
      }}
    >
      <LoadingButton
        color="primary"
        variant="outlined"
        loading={loading}
        startIcon={<CheckIcon />}
      >
        {t("Mark as Paid")}
      </LoadingButton>
    </ModalOpenButton>
  );
};

interface FormValues {
  paidAt: Moment;
}

function PayInvoiceModal({
  handleClose,
  handleComplete,
}: {
  handleClose: () => void;
  handleComplete: (value: FormValues) => Promise<void>;
}) {
  const { t } = useTranslate(["Global", "InvoiceDetailsForm", "Invoices"]);

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      initialValues={{
        paidAt: moment(),
      }}
      onSubmit={handleComplete}
    >
      {formikProps => (
        <Modal
          id="pay-invoice-modal"
          title={t("Mark as Paid", {
            ns: "InvoiceDetailsForm",
          })}
          dialogProps={{ maxWidth: "xs" }}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
        >
          <DialogContentText>
            {t("Enter the date when the invoice was paid:", {
              ns: "InvoiceDetailsForm",
            })}
          </DialogContentText>
          <Form id={formId}>
            <Stack spacing={1}>
              <DatePickerField
                name={"paidAt"}
                label={t("Paid date", {
                  ns: "Invoices",
                })}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
