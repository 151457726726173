import { CardContainer, ellipsisStyle } from "@msys/ui";
import { List, ListItem, ListItemText } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link } from "react-router-dom";
import { px } from "../../../../common/MuiThemeProvider";
import { RequirementIcon } from "../../requirements/RequirementIcon";
import {
  RequirementDraftBadge,
  RequirementPublishedBadge,
} from "../../requirements/RequirementStatusBadge";
import { RequestRequirementBoxFragment } from "./RequestRequirementBox.generated";

export const RequestRequirementBox: React.FC<{
  request: RequestRequirementBoxFragment;
}> = ({ request }) => {
  const { t } = useTranslate("Requests");

  const badge = request.requestorDoc.isPublished ? (
    <RequirementPublishedBadge align="right" />
  ) : (
    <RequirementDraftBadge align="right" />
  );

  return (
    <CardContainer title={t("Requirements list")} Icon={<RequirementIcon />}>
      <List disablePadding>
        <ListItem
          key={request.id}
          button
          divider={false}
          component={Link}
          to={`/projects/${request.requestorProjectId}/requirements/${request.requestorDoc.id}`}
        >
          <ListItemText
            style={{ marginRight: px.s }}
            primary={request.requestorDoc.title}
            primaryTypographyProps={{ style: ellipsisStyle }}
          />
          {badge}
        </ListItem>
      </List>
    </CardContainer>
  );
};
