import { Salutation } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { SelectField, SelectFieldProps } from "./SelectField";

interface Props
  extends Omit<SelectFieldProps<Salutation>, "options" | "label"> {
  name: string;
}

export const UserTitleField = ({ name, ...otherProps }: Props) => {
  const { t } = useTranslate(["Global"]);

  const options: { id: string; label: string; value: Salutation }[] = [
    {
      id: "mr",
      label: t("Mr", {
        ns: "Global",
      }),
      value: "mr",
    },
    {
      id: "ms",
      label: t("Ms", {
        ns: "Global",
      }),
      value: "ms",
    },
  ];

  return (
    <SelectField
      label={t("Salutation", {
        ns: "Global",
      })}
      name={name}
      options={options}
      {...otherProps}
    />
  );
};
