import {
  ErrorMessage,
  LoadingSpinner as LoadingIndicator,
  useScreenWidth,
} from "@msys/ui";
import { Box } from "@mui/material";
import { PageFullScreenModal } from "../../../commons/layout/PageFullScreenModal";
import { PageMiddleColumn } from "../../../commons/layout/PageMiddleColumn";
import { useTranslate } from "@tolgee/react";

interface Props {
  isAbsolute?: boolean;
  loading?: boolean;
  error?: Error;
  onCloseButtonClick?: () => void;
}

export const EmptyStateItem = ({
  isAbsolute,
  loading,
  error,
  onCloseButtonClick,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const { isMinTablet } = useScreenWidth();
  if (loading) {
    if (isMinTablet) {
      return (
        <PageMiddleColumn
          isAbsolute={isAbsolute}
          hasRightBorder
          hasBackground
          onCloseButtonClick={onCloseButtonClick}
        >
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <LoadingIndicator />
          </Box>
        </PageMiddleColumn>
      );
    }
    return (
      <PageFullScreenModal onCloseButtonClick={onCloseButtonClick}>
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <LoadingIndicator />
        </Box>
      </PageFullScreenModal>
    );
  }

  if (error) {
    if (isMinTablet) {
      return (
        <PageMiddleColumn
          isAbsolute={isAbsolute}
          hasRightBorder
          hasBackground
          onCloseButtonClick={onCloseButtonClick}
        >
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <ErrorMessage message={error.message} />
          </Box>
        </PageMiddleColumn>
      );
    }
    return (
      <PageFullScreenModal onCloseButtonClick={onCloseButtonClick}>
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage message={error.message} />
        </Box>
      </PageFullScreenModal>
    );
  }

  if (isMinTablet) {
    return (
      <PageMiddleColumn
        isAbsolute={isAbsolute}
        hasRightBorder
        hasBackground
        onCloseButtonClick={onCloseButtonClick}
      >
        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage
            message={t("Not found", {
              ns: "Global",
            })}
          />
        </Box>
      </PageMiddleColumn>
    );
  }
  return (
    <PageFullScreenModal onCloseButtonClick={onCloseButtonClick}>
      <Box display="flex" flex={1} justifyContent="center" alignItems="center">
        <ErrorMessage
          message={t("Not found", {
            ns: "Global",
          })}
        />
      </Box>
    </PageFullScreenModal>
  );
};
