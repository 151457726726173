/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PickBrandModalSearchBrands_BrandFragment = { __typename: 'PimBrandRef', id: string, title: string };

export type PickBrandModalSearchBrandsQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String']['input'];
}>;


export type PickBrandModalSearchBrandsQuery = { pimSearchBrands: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchBrandsResult', totalCount: number, brands: Array<{ __typename: 'PimBrandRef', id: string, title: string }> } };

export const PickBrandModalSearchBrands_BrandFragmentDoc = gql`
    fragment PickBrandModalSearchBrands_Brand on PimBrandRef {
  id
  title
}
    `;
export const PickBrandModalSearchBrandsDocument = gql`
    query PickBrandModalSearchBrands($searchTerm: String!) {
  pimSearchBrands(limit: 10, searchTerm: $searchTerm) {
    ... on PimSearchBrandsResult {
      brands {
        id
        ...PickBrandModalSearchBrands_Brand
      }
      totalCount
    }
  }
}
    ${PickBrandModalSearchBrands_BrandFragmentDoc}`;

/**
 * __usePickBrandModalSearchBrandsQuery__
 *
 * To run a query within a React component, call `usePickBrandModalSearchBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickBrandModalSearchBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickBrandModalSearchBrandsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function usePickBrandModalSearchBrandsQuery(baseOptions: Apollo.QueryHookOptions<PickBrandModalSearchBrandsQuery, PickBrandModalSearchBrandsQueryVariables> & ({ variables: PickBrandModalSearchBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickBrandModalSearchBrandsQuery, PickBrandModalSearchBrandsQueryVariables>(PickBrandModalSearchBrandsDocument, options);
      }
export function usePickBrandModalSearchBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickBrandModalSearchBrandsQuery, PickBrandModalSearchBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickBrandModalSearchBrandsQuery, PickBrandModalSearchBrandsQueryVariables>(PickBrandModalSearchBrandsDocument, options);
        }
export function usePickBrandModalSearchBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PickBrandModalSearchBrandsQuery, PickBrandModalSearchBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PickBrandModalSearchBrandsQuery, PickBrandModalSearchBrandsQueryVariables>(PickBrandModalSearchBrandsDocument, options);
        }
export type PickBrandModalSearchBrandsQueryHookResult = ReturnType<typeof usePickBrandModalSearchBrandsQuery>;
export type PickBrandModalSearchBrandsLazyQueryHookResult = ReturnType<typeof usePickBrandModalSearchBrandsLazyQuery>;
export type PickBrandModalSearchBrandsSuspenseQueryHookResult = ReturnType<typeof usePickBrandModalSearchBrandsSuspenseQuery>;
export type PickBrandModalSearchBrandsQueryResult = Apollo.QueryResult<PickBrandModalSearchBrandsQuery, PickBrandModalSearchBrandsQueryVariables>;