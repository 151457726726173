import { gql } from "@apollo/client";
import { LabeledValue } from "@msys/ui";
import { Stack } from "../../commons/layout/Stack";
import {
  UserRolesBox_UserFragment,
  UserRolesBox_ViewerFragment,
} from "./UserRolesBox.generated";
import { useTranslate } from "@tolgee/react";
import { useOrganisationRoles, useProjectRoles } from "./useRoles";

type User = UserRolesBox_UserFragment | UserRolesBox_ViewerFragment;

interface Props {
  user: User;
}

export const UserRolesBox = ({ user }: Props) => {
  const { t } = useTranslate(["UserProfile", "Global"]);

  const { getOrganisationRoleTitle } = useOrganisationRoles();
  const { getProjectRoleTitle } = useProjectRoles();

  const rolesLabel = getOrganisationRoleTitle(user.roles);
  const projectRolesLabel = getProjectRoleTitle(user.defaultProjectRoles);

  return (
    <Stack flexDirection="column">
      <LabeledValue
        label={t("Default project role", {
          ns: "UserProfile",
        })}
      >
        {projectRolesLabel ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Organisation role", {
          ns: "UserProfile",
        })}
      >
        {rolesLabel ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
    </Stack>
  );
};
