import { useApolloClient } from "@apollo/client";
import React from "react";
import {
  UseExpressionValidation_CompileDocIsolatedExpressionQueryResult,
  useUseExpressionValidation_CompileDocIsolatedExpressionQuery,
} from "./useExpressionValidation.generated";

export type ExpressionResult = Exclude<
  UseExpressionValidation_CompileDocIsolatedExpressionQueryResult["data"],
  undefined
>["compileDocIsolatedExpression"]["results"][number];

export function useExpressionValidation(docId: string, itemId: string) {
  const client = useApolloClient();
  const compileDocQuery =
    useUseExpressionValidation_CompileDocIsolatedExpressionQuery({
      client,
      variables: {
        docId,
        itemId,
      },
      fetchPolicy: "no-cache",
    });
  const expressionCheckResults =
    compileDocQuery.data?.compileDocIsolatedExpression.results;

  return {
    expressionCheckResults,
    validateExpressions: compileDocQuery.refetch,
  };
}

export function useExpressionValidationError(
  docId: string,
  itemId: string,
  predicate: (value: ExpressionResult) => boolean
) {
  const [expressionError, setExpressionError] = React.useState<Error | null>(
    null
  );

  const { expressionCheckResults, validateExpressions } =
    useExpressionValidation(docId, itemId);

  const expressionCheckResult = expressionCheckResults?.find(predicate);

  React.useEffect(() => {
    setExpressionError(
      expressionCheckResult
        ? new Error(expressionCheckResult.messageText)
        : null
    );
  }, [expressionCheckResult]);

  return { expressionError, validateExpressions };
}
