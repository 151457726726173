import { gql, useApolloClient } from "@apollo/client";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import {
  QuoteTemplateSubscribeButton_QuoteTemplateFragment,
  useSubscribeToQuoteTemplateMutation,
} from "./QuoteTemplateSubscribeButton.generated";
import { useTranslate } from "@tolgee/react";

interface Props extends ButtonProps {
  doc: QuoteTemplateSubscribeButton_QuoteTemplateFragment;
}

export const QuoteTemplateSubscribeButton = ({ doc, ...rest }: Props) => {
  const { t } = useTranslate(["Templates", "TemplatesPublishing"]);

  const client = useApolloClient();
  const [subscribe, { loading: subscribeLoading }] =
    useSubscribeToQuoteTemplateMutation({
      client,
    });

  return (
    <LoadingButton
      loading={subscribeLoading}
      startIcon={rest.size !== "extra-small" ? <BookmarkAddIcon /> : undefined}
      variant={"contained"}
      color="primary"
      sx={{ whiteSpace: "nowrap" }}
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();
        if (doc.subscribedToTemplate) {
          throw new Error("Template already subscribed");
        }
        await subscribe({
          variables: { input: { quoteTemplateId: doc.id } },
        });
      }}
      {...rest}
    >
      {t("Subscribe", { ns: "TemplatesPublishing" })}
    </LoadingButton>
  );
};
