import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { useUserData } from "../../auth/useUserData";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageGrid, splitStrategy } from "../../commons/layout/PageGrid";
import { OrganisationPriceAndCalculationSettingsBox } from "../../features/organisations/boxes/OrganisationPriceAndCalculationSettingsBox";
import { useOrganisationSettingsPricesAndCalculationsQuery } from "./OrganisationSettingsPricesAndCalculations.generated";

interface Props {
  submenuItems: PageTopbarItem[];
}

export const OrganisationSettingsPricesAndCalculations = ({
  submenuItems,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("OrganisationPageTopbar");

  const client = useApolloClient();
  const query = useOrganisationSettingsPricesAndCalculationsQuery({
    client,
  });

  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);

  return (
    <Page title={t("Documents")} submenuItems={submenuItems}>
      {organisationDefaults && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 3 }} strategy={splitStrategy}>
            <>
              <OrganisationPriceAndCalculationSettingsBox
                organisationId={viewer.organisation.id}
                organisationDefaults={organisationDefaults}
              />
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
};
