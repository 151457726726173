import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUserData } from "../../auth/useUserData";
import { PageNotFound } from "../../commons/PageNotFound";
import { PageTopbarItem } from "../../commons/layout/Page";
import { AbsenceIcon } from "../../features/absences/AbsenceIcon";
import { ProjectIcon } from "../../features/projects/ProjectIcon";
import { PlanningAbsences } from "./PlanningAbsences";
import { PlanningPlanSessions } from "./PlanningPlanSessions";
import { PlanningProjects } from "./PlanningProjects";
import { RestrictedByOrganisationPermission } from "../../auth/RestrictedByOrganisationPermission";

type PageSubmenuItem = PageTopbarItem;

export const PlanningRoutes = () => {
  const { t } = useTranslate(["Tickets", "Projects", "Absences"]);
  const viewer = useUserData().currentUser!;

  const submenuItems = useMemo(() => {
    const items: PageSubmenuItem[] = [
      {
        name: "planning-projects",
        label: t("Projects", {
          ns: "Projects",
        }),
        icon: <ProjectIcon />,
        path: `/planning/projects`,
      },
      {
        name: "planning-absences",
        label: t("Absences", {
          ns: "Absences",
        }),
        icon: <AbsenceIcon />,
        path: `/planning/absences`,
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
        permission: "MANAGE_ORG",
      },
    ];

    return items.filter(item => !item.isHidden);
  }, [t, viewer]);

  return (
    <Routes>
      <Route index element={<Navigate to="projects" replace />} />
      <Route
        path={"projects"}
        element={<PlanningProjects submenuItems={submenuItems} />}
      />
      <Route
        path={"projects/:projectId"}
        element={<PlanningPlanSessions path={"projects"} />}
      />
      <Route
        path={"absences"}
        element={
          <RestrictedByOrganisationPermission permission="MANAGE_ORG">
            <PlanningAbsences submenuItems={submenuItems} />
          </RestrictedByOrganisationPermission>
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
