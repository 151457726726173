/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type CalendarQuery = { planSessions: { __typename: 'MissingCapabilities' } | { __typename: 'PlanSessionConnection', edges: Array<{ __typename: 'PlanSessionEdge', node: { __typename: 'PlanSession', id: string, from: any, till: any, project: { __typename: 'Project', id: string, title: string } } }> } };


export const CalendarDocument = gql`
    query Calendar($userId: ID!) {
  planSessions(limit: 0, filters: {whoIds: [$userId]}) {
    ... on PlanSessionConnection {
      edges {
        node {
          id
          from
          till
          project {
            id
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCalendarQuery(baseOptions: Apollo.QueryHookOptions<CalendarQuery, CalendarQueryVariables> & ({ variables: CalendarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, options);
      }
export function useCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, options);
        }
export function useCalendarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, options);
        }
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>;
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>;
export type CalendarSuspenseQueryHookResult = ReturnType<typeof useCalendarSuspenseQuery>;
export type CalendarQueryResult = Apollo.QueryResult<CalendarQuery, CalendarQueryVariables>;