/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationProjectDefaultSettingsBox_SettingsFragment = { __typename: 'OrganisationSetting', id: string, projectDefaultAttachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export type OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationVariables = Types.Exact<{
  input: Types.OrganisationSettingsUpdateInput;
}>;


export type OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation = { updateOrganisationSettings: { __typename: 'OrganisationSettingsUpdateResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, projectDefaultAttachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> } } };

export const OrganisationProjectDefaultSettingsBox_SettingsFragmentDoc = gql`
    fragment OrganisationProjectDefaultSettingsBox_Settings on OrganisationSetting {
  id
  projectDefaultAttachments {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}`;
export const OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsDocument = gql`
    mutation OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettings($input: OrganisationSettingsUpdateInput!) {
  updateOrganisationSettings(input: $input) {
    organisationSettings {
      id
      ...OrganisationProjectDefaultSettingsBox_Settings
    }
  }
}
    ${OrganisationProjectDefaultSettingsBox_SettingsFragmentDoc}`;
export type OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationFn = Apollo.MutationFunction<OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationVariables>;

/**
 * __useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation__
 *
 * To run a mutation, you first call `useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationProjectDefaultSettingsFormModifyOrganisationSettingsMutation, { data, loading, error }] = useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationVariables>(OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsDocument, options);
      }
export type OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationHookResult = ReturnType<typeof useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation>;
export type OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationResult = Apollo.MutationResult<OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation>;
export type OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationOptions = Apollo.BaseMutationOptions<OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutationVariables>;