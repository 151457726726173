import { Modal } from "@msys/ui";
import { Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { namedOperations } from "../../../clients/graphqlTypes";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { Stack } from "../../commons/layout/Stack";
import { ItemCalculationFragment } from "../doc-items/Fragments.generated";
import { MaterialCalculationBox } from "../doc-items/boxes/MaterialCalculationBox";
import { PropertyDisplayBox } from "../doc-items/boxes/PropertyDisplayBox";
import { RequiredOnCompletionBox } from "../doc-items/boxes/RequiredOnCompletionBox";
import { TaskManagementBox } from "../doc-items/boxes/TaskManagementBox";
import { TimeCalculationBox } from "../doc-items/boxes/TimeCalculationBox";
import { InvoiceItemCalculationBox } from "./InvoiceItemCalculationBox";
import {
  InvoiceItemDetailsModal_ItemFragment,
  InvoiceItemDetailsModal_ProjectFragment,
  InvoiceItemDetailsModal_QuoteFragment,
} from "./InvoiceItemDetailsModal.generated";

interface Props {
  project: InvoiceItemDetailsModal_ProjectFragment;
  invoiceId: string;
  doc: InvoiceItemDetailsModal_QuoteFragment;
  item: InvoiceItemDetailsModal_ItemFragment;
  selectedItemInvoiceCalculation: ItemCalculationFragment | null;
  handleClose: () => void;
  invoiceableQuoteItemIds: string[];
  invoiceableInInvoiceQuoteItemIds: string[];
}

export const InvoiceItemDetailsModal = ({
  item,
  selectedItemInvoiceCalculation,
  doc,
  project,
  invoiceId,
  handleClose,
  invoiceableQuoteItemIds,
  invoiceableInInvoiceQuoteItemIds,
}: Props) => {
  const { t } = useTranslate("Invoices");
  return (
    <Modal
      handleClose={handleClose}
      dialogProps={{ maxWidth: "md" }}
      title={t("Invoice item detail")}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack flexDirection="column" spacing={2}>
            <PropertyDisplayBox item={item} />
            {item.type === "paid" && (
              <InvoiceItemCalculationBox
                item={item}
                itemCalculation={selectedItemInvoiceCalculation}
                doc={doc}
                projectId={project.id}
                invoiceId={invoiceId}
                canEdit={
                  item.type === "paid" &&
                  (invoiceableQuoteItemIds.includes(item.id) ||
                    invoiceableInInvoiceQuoteItemIds.includes(item.id))
                }
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack flexDirection="column" spacing={2}>
            {item.type === "paid" && (
              <MaterialCalculationBox
                project={project}
                docId={doc.id}
                item={item}
                refetchQueriesOnAction={[
                  namedOperations.Query.ProjectOutgoingInvoice,
                  namedOperations.Query
                    .ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?
                ]}
              />
            )}
            <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
              {item.type === "paid" && (
                <TimeCalculationBox
                  project={project}
                  docId={doc.id}
                  document={{ viewerPermissions: project.viewerPermissions }}
                  item={item}
                  refetchQueriesOnAction={[
                    namedOperations.Query.ProjectOutgoingInvoice,
                    namedOperations.Query
                      .ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?
                  ]}
                />
              )}
            </RestrictedByCapabilityWithDebug>

            {!item.isRootItem && (
              <TaskManagementBox
                project={project}
                docId={doc.id}
                document={{ viewerPermissions: project.viewerPermissions }}
                item={item}
              />
            )}
            {!item.isRootItem && (
              <RequiredOnCompletionBox
                project={project}
                docId={doc.id}
                document={{ viewerPermissions: project.viewerPermissions }}
                item={item}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Modal>
  );
};
