import { useApolloClient } from "@apollo/client";
import { getDataOrThrow } from "@msys/common";
import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Divider, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  DocActorModifyInput,
  DocActorType,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { CrmCompanySelectModal } from "../crm-companies/modals/CrmCompanySelectModal";
import { DocumentActorData } from "../documents/DocumentActorData";
import { DocumentActorEditModal } from "../documents/DocumentActorEditModal";
import { DocumentActorsModal } from "../documents/DocumentActorsModal";
import { useActorTypes } from "../documents/useActorTypes";
import {
  useAddRequirementDocumentActorMutation,
  useModifyRequirementDocumentActorMutation,
  useRequirementDocumentActorsSuspenseQuery,
  useResetRequirementDocumentActorMutation,
} from "./RequirementDocumentActorsModal.generated";

interface Props {
  handleClose: () => void;
  projectId: string;
  requirementId: string;
}

export const RequirementDocumentActorsModal = ({
  handleClose,
  projectId,
  requirementId,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const { actorTypeLabels } = useActorTypes();

  const client = useApolloClient();
  const query = useRequirementDocumentActorsSuspenseQuery({
    client,
    variables: { projectId, requirementId },
  });
  const [addActor] = useAddRequirementDocumentActorMutation({
    client,
    refetchQueries: [namedOperations.Query.RequirementDocumentActors],
    awaitRefetchQueries: true,
  });
  const [modifyActor] = useModifyRequirementDocumentActorMutation({
    client,
    refetchQueries: [namedOperations.Query.RequirementDocumentActors],
    awaitRefetchQueries: true,
  });
  const [resetActor] = useResetRequirementDocumentActorMutation({
    client,
    refetchQueries: [namedOperations.Query.RequirementDocumentActors],
    awaitRefetchQueries: true,
  });

  const requirement = getDataOrThrow(query.data.requirement).requirement;

  if (!requirement) throw new Error("Requirement not found");

  const contractorActor = requirement?.contractor;
  const contracteeActor = requirement?.contractee;
  const clientActor = requirement?.client;

  const handleAdd = async (actorType: DocActorType, crmCompanyId: string) => {
    await addActor({
      variables: {
        input: {
          projectId,
          docId: requirementId,
          docActorType: actorType,
          crmCompanyId,
        },
      },
    });
  };

  const handleChange = async (
    actorId: string,
    actorType: DocActorType,
    actorInput: DocActorModifyInput,
    handleClose: () => void
  ) => {
    await modifyActor({
      variables: {
        input: {
          projectId,
          docId: requirementId,
          docActorId: actorId,
          docActorType: actorType,
          docActor: actorInput,
        },
      },
    });
    handleClose();
  };

  const handleReset = async (
    actorId: string,
    actorType: DocActorType,
    crmCompanyId: string
  ) => {
    await resetActor({
      variables: {
        input: {
          projectId,
          docId: requirementId,
          docActorId: actorId,
          docActorType: actorType,
          crmCompanyId,
        },
      },
    });
  };

  return (
    <DocumentActorsModal handleClose={handleClose}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <DocumentActorData actorType={"CONTRACTEE"} actor={contracteeActor} />
        {contracteeActor ? (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_REQUIREMENTS_ACTORS"
            document={requirement}
          >
            <ModalOpenButton
              Modal={DocumentActorEditModal}
              modalProps={{
                actor: contracteeActor,
                title: t("{docActorType} details", {
                  ns: "Global",
                  docActorType: actorTypeLabels[contracteeActor.type],
                }),
                handleReset: async (crmCompanyId, handleClose) => {
                  await handleReset(
                    contracteeActor.id,
                    contracteeActor.type,
                    crmCompanyId
                  );
                  handleClose();
                },
                handleComplete: async (actorFormValues, handleClose) => {
                  await handleChange(
                    contracteeActor.id,
                    contracteeActor.type,
                    actorFormValues,
                    handleClose
                  );
                },
              }}
            >
              <IconButton color="secondary">
                <EditIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        ) : (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_REQUIREMENTS_ACTORS"
            document={requirement}
          >
            <ModalOpenButton
              Modal={CrmCompanySelectModal}
              modalProps={{
                title: t("Select {docActorType}", {
                  ns: "Global",
                  docActorType: actorTypeLabels["CONTRACTEE"],
                }),
                inputLabel: t("Choose one of your contacts", {
                  ns: "Global",
                }),
                canCreateNew: true,
                createNewLabel: t("Create new contact", { ns: "Global" }),
                allowedContactTypesForCreate: ["COMPANY", "INDIVIDUAL"],
                required: true,
                handleComplete: async (crmCompanyId, handleClose) => {
                  await handleAdd("CONTRACTEE", crmCompanyId);
                  handleClose();
                },
              }}
            >
              <IconButton color="secondary">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        )}
      </Stack>
      <Divider />
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <DocumentActorData actorType={"CLIENT"} actor={clientActor} />
        {clientActor ? (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_REQUIREMENTS_ACTORS"
            document={requirement}
          >
            <ModalOpenButton
              Modal={DocumentActorEditModal}
              modalProps={{
                actor: clientActor,
                title: t("{docActorType} details", {
                  ns: "Global",
                  docActorType: actorTypeLabels[clientActor.type],
                }),
                handleReset: async (crmCompanyId, handleClose) => {
                  await handleReset(
                    clientActor.id,
                    clientActor.type,
                    crmCompanyId
                  );
                  handleClose();
                },
                handleComplete: async (actorFormValues, handleClose) => {
                  await handleChange(
                    clientActor.id,
                    clientActor.type,
                    actorFormValues,
                    handleClose
                  );
                },
              }}
            >
              <IconButton color="secondary">
                <EditIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        ) : (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_REQUIREMENTS_ACTORS"
            document={requirement}
          >
            <ModalOpenButton
              Modal={CrmCompanySelectModal}
              modalProps={{
                title: t("Select {docActorType}", {
                  ns: "Global",
                  docActorType: actorTypeLabels["CLIENT"],
                }),
                inputLabel: t("Choose one of your contacts", {
                  ns: "Global",
                }),
                canCreateNew: true,
                createNewLabel: t("Create new contact", { ns: "Global" }),
                allowedContactTypesForCreate: ["COMPANY", "INDIVIDUAL"],
                required: true,
                handleComplete: async (crmCompanyId, handleClose) => {
                  await handleAdd("CLIENT", crmCompanyId);
                  handleClose();
                },
              }}
            >
              <IconButton color="secondary">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        )}
      </Stack>
      <Divider />
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <DocumentActorData actorType={"CONTRACTOR"} actor={contractorActor} />
        {contractorActor ? (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_REQUIREMENTS_ACTORS"
            document={requirement}
          >
            <ModalOpenButton
              Modal={DocumentActorEditModal}
              modalProps={{
                actor: contractorActor,
                title: t("{docActorType} details", {
                  ns: "Global",
                  docActorType: actorTypeLabels[contractorActor.type],
                }),
                handleReset: async (crmCompanyId, handleClose) => {
                  await handleReset(
                    contractorActor.id,
                    contractorActor.type,
                    crmCompanyId
                  );
                  handleClose();
                },
                handleComplete: async (actorFormValues, handleClose) => {
                  await handleChange(
                    contractorActor.id,
                    contractorActor.type,
                    actorFormValues,
                    handleClose
                  );
                },
              }}
            >
              <IconButton color="secondary">
                <EditIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        ) : (
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_REQUIREMENTS_ACTORS"
            document={requirement}
          >
            <ModalOpenButton
              Modal={CrmCompanySelectModal}
              modalProps={{
                title: t("Select {docActorType}", {
                  ns: "Global",
                  docActorType: actorTypeLabels["CONTRACTOR"],
                }),
                inputLabel: t("Choose one of your contacts", {
                  ns: "Global",
                }),
                canCreateNew: true,
                createNewLabel: t("Create new contact", { ns: "Global" }),
                allowedContactTypesForCreate: ["COMPANY", "INDIVIDUAL"],
                required: true,
                handleComplete: async (crmCompanyId, handleClose) => {
                  await handleAdd("CONTRACTOR", crmCompanyId);
                  handleClose();
                },
              }}
            >
              <IconButton color="secondary">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        )}
      </Stack>
    </DocumentActorsModal>
  );
};
