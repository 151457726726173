import { ModalOpenButton, useFormatting } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { EntityNumber } from "../../../commons/EntityNumber";
import { CrmCompanyEditBillingAddressModal } from "../../crm-companies/modals/CrmCompanyEditBillingAddressModal";
import { CrmCompanyEditContactTypeModal } from "../../crm-companies/modals/CrmCompanyEditContactTypeModal";
import { DocumentPreviewHeader } from "../../documents/DocumentPreviewHeader";
import {
  InvoiceHeader__InvoiceFragment,
  InvoiceHeader__PayeeFragment,
  InvoiceHeader__PayerFragment,
  InvoiceHeader__ProjectFragment,
} from "./InvoiceHeader.generated";

interface Props {
  project: InvoiceHeader__ProjectFragment;
  payee: InvoiceHeader__PayeeFragment;
  payer: InvoiceHeader__PayerFragment;
  invoice: InvoiceHeader__InvoiceFragment;
  canEdit: boolean;
}

export const InvoiceHeader = ({
  project: { number: projectNumber, crmOrganisation },
  payee,
  payer,
  invoice,
  canEdit,
}: Props) => {
  const { t } = useTranslate("Invoices");
  const { getFormattedDate } = useFormatting();

  if (!crmOrganisation) throw new Error("Crm organisation is missing");

  const client = crmOrganisation;

  const documentData = [
    {
      label: t("Project No"),
      value: <EntityNumber noLabel number={projectNumber} />,
    },
    {
      label: t("Client No"),
      value: <EntityNumber noLabel number={client?.number} />,
    },
    {
      label: t("Invoice No"),
      value: invoice.number ? (
        <EntityNumber noLabel number={invoice.number} />
      ) : (
        "-"
      ),
    },
    {
      label: t("Issued on"),
      value: invoice.openedAt ? getFormattedDate(invoice.openedAt) : "-",
    },
    {
      label: t("Date of service"),
      value:
        getDeliveryOfServiceDate(
          invoice.deliveryOfServiceStart,
          invoice.deliveryOfServiceEnd,
          getFormattedDate
        ) ?? "-",
    },
    {
      label: t("Due date"),
      value: invoice.dueDate ? getFormattedDate(invoice.dueDate) : "-",
    },
  ];

  return (
    <DocumentPreviewHeader
      recipient={{
        organisationName:
          payer.companyOrIndividual === "COMPANY"
            ? payer.companyName
            : `${payer.firstname} ${payer.familyname}`,
        address: payer?.address ?? null,
        contactName: payer?.contact?.fullname ?? null,
      }}
      sender={{
        organisationName: payee?.companyName,
        address: payee?.address ?? null,
        contactName: invoice?.contactPerson?.fullname ?? null,
        contactPhones: invoice?.contactPerson?.phones ?? null,
        contactEmail: invoice?.contactPerson?.email ?? null,
      }}
      documentData={documentData}
      /*recipientActionButtons={
        canEdit && (
          <ModalOpenButton
            Modal={CrmCompanyEditContactTypeModal}
            modalProps={{ crmOrganisationId: client.id }}
          >
            <IconButton color="secondary" size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </ModalOpenButton>
        )
      }
      recipientAddressActionButtons={
        canEdit && (
          <ModalOpenButton
            Modal={CrmCompanyEditBillingAddressModal}
            modalProps={{ crmOrganisationId: client.id }}
          >
            <IconButton color="secondary" size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </ModalOpenButton>
        )
      }*/
    />
  );
};

function getDeliveryOfServiceDate(
  start: string,
  end: string,
  getFormattedDate: (value: Date | moment.Moment | number | string) => string
) {
  if (!start) return null;
  if (!end || start === end) return getFormattedDate(start);
  return `${getFormattedDate(start)}–${getFormattedDate(end)}`;
}
