import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { assign } from "lodash";
import React from "react";
import {
  DefineItemTemplateSearchFilterDefinitionsInput,
  DefineItemTemplateSearchFilterExpressionsInput,
  ItemTemplateSearchFilterPropertyFilter,
  ItemTemplateSearchFilterPropertyFilterComputed,
  TemplateSearchFilterPropertyFilterDefinitionInput,
  TemplateSearchFilterPropertyFilterExpressionInput,
} from "../../../clients/graphqlTypes";
import {
  ItemTemplateSearchFilterPropertyFilterComputedFragment,
  ItemTemplateSearchFilterPropertyFilterFragment,
} from "../templates/templateSearchFilters.generated";
import {
  useTemplateSeachFiltersSection_DefineTemplateSearchFilterDefintionsMutation,
  useTemplateSearchFiltersSection_DefineTemplateSearchFilterExpressionsMutation,
} from "./useTemplateSearchFiltersMutations.generated";

type AllowedTemplateSearchFilterDefinitionKey =
  keyof DefineItemTemplateSearchFilterDefinitionsInput["templateSearchFilterDefinitions"];
type TemplateSearchFilterDefinitionsValue =
  DefineItemTemplateSearchFilterDefinitionsInput["templateSearchFilterDefinitions"][AllowedTemplateSearchFilterDefinitionKey];
export type HandleSetTemplateSearchFilterDefinition = (
  key: AllowedTemplateSearchFilterDefinitionKey,
  value: TemplateSearchFilterDefinitionsValue
) => Promise<void>;
export type HandleSetTemplateSearchFilterPropertyDefinition = (
  propertyKey: string,
  value: DefineItemTemplateSearchFilterDefinitionsInput["templateSearchFilterDefinitions"]["propertyFilterDefinitions"][number]
) => Promise<void>;

type AllowedTemplateSearchFilterExpressionKey =
  keyof DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"];
type TemplateSearchFilterExpressionsValue =
  DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"][AllowedTemplateSearchFilterExpressionKey];
export type HandleSetTemplateSearchFilterExpression = (
  key: AllowedTemplateSearchFilterExpressionKey,
  value: TemplateSearchFilterExpressionsValue
) => Promise<void>;
export type HandleSetTemplateSearchFilterPropertyExpression = (
  propertyKey: string,
  value: DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"]["propertyFilterExpressions"][number]
) => Promise<void>;

export function useTemplateSearchFiltersMutations({
  projectId,
  docId,
  itemId,
  propertySearchFiltersDefinitions,
  propertySearchFiltersExpressions,
  refetchQueries,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  propertySearchFiltersDefinitions: ItemTemplateSearchFilterPropertyFilter[];
  propertySearchFiltersExpressions: ItemTemplateSearchFilterPropertyFilterComputed[];
  refetchQueries?: string[];
}) {
  const client = useApolloClient();

  /////////////////
  // DEFINITIONS //
  /////////////////

  const [defineItemTemplateSearchFilterDefinitions] =
    useTemplateSeachFiltersSection_DefineTemplateSearchFilterDefintionsMutation(
      { client, refetchQueries }
    );

  const handleSetTemplateSearchFilterDefinition = React.useCallback(
    async (
      key: AllowedTemplateSearchFilterDefinitionKey,
      value: TemplateSearchFilterDefinitionsValue
    ) => {
      const existingDefinitions: Pick<
        DefineItemTemplateSearchFilterDefinitionsInput["templateSearchFilterDefinitions"],
        AllowedTemplateSearchFilterDefinitionKey
      > = {
        propertyFilterDefinitions: propertySearchFiltersDefinitions.map(
          templateSearchFilterPropertyFilterDefinition2Input
        ),
      };

      await defineItemTemplateSearchFilterDefinitions({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            templateSearchFilterDefinitions: {
              ...assign(existingDefinitions, { [key]: value }),
            },
          },
        },
      });
    },
    [
      defineItemTemplateSearchFilterDefinitions,
      docId,
      itemId,
      projectId,
      propertySearchFiltersDefinitions,
    ]
  );
  const handleSetTemplateSearchFilterPropertyDefinition = (
    propertyKey: string,
    value: DefineItemTemplateSearchFilterDefinitionsInput["templateSearchFilterDefinitions"]["propertyFilterDefinitions"][number]
  ) => {
    return handleSetTemplateSearchFilterDefinition(
      "propertyFilterDefinitions",
      [
        ...propertySearchFiltersDefinitions
          .filter(filter => {
            return filter.key !== propertyKey;
          })
          .map(templateSearchFilterPropertyFilterDefinition2Input),
        value,
      ]
    );
  };
  const handleDeleteTemplateSearchFilterDefinition = async (
    key: Exclude<
      AllowedTemplateSearchFilterDefinitionKey,
      "templateSearchFilterPropertyFilterDefinitions"
    >
  ) => {
    return handleSetTemplateSearchFilterDefinition(key, []);
  };
  const handleDeleteTemplateSearchFilterPropertyDefinition = async (
    propertyKey: string
  ) => {
    return handleSetTemplateSearchFilterDefinition(
      "propertyFilterDefinitions",
      propertySearchFiltersDefinitions
        .filter(filter => filter.key !== propertyKey)
        .map(templateSearchFilterPropertyFilterDefinition2Input)
    );
  };

  /////////////////
  // EXPRESSIONS //
  /////////////////

  const [defineItemTemplateSearchFilterExpressions] =
    useTemplateSearchFiltersSection_DefineTemplateSearchFilterExpressionsMutation(
      {
        client,
        refetchQueries,
      }
    );

  const handleSetTemplateSearchFilterExpression = React.useCallback(
    async (
      key: AllowedTemplateSearchFilterExpressionKey,
      value: TemplateSearchFilterExpressionsValue
    ) => {
      const existingExpressions: Pick<
        DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"],
        AllowedTemplateSearchFilterExpressionKey
      > = {
        propertyFilterExpressions: propertySearchFiltersExpressions.map(
          templateSearchFilterPropertyFilterExpression2Input
        ),
      };

      await defineItemTemplateSearchFilterExpressions({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            templateSearchFilterExpressions: {
              ...assign(existingExpressions, { [key]: value }),
            },
          },
        },
      });
    },
    [
      defineItemTemplateSearchFilterExpressions,
      docId,
      itemId,
      projectId,
      propertySearchFiltersExpressions,
    ]
  );
  const handleSetTemplateSearchFilterPropertyExpression = (
    propertyKey: string,
    value: DefineItemTemplateSearchFilterExpressionsInput["templateSearchFilterExpressions"]["propertyFilterExpressions"][number]
  ) => {
    return handleSetTemplateSearchFilterExpression(
      "propertyFilterExpressions",
      [
        ...propertySearchFiltersExpressions
          .filter(filter => {
            return filter.key !== propertyKey;
          })
          .map(templateSearchFilterPropertyFilterExpression2Input),
        value,
      ]
    );
  };
  const handleDeleteTemplateSearchFilterExpression = async (
    key: Exclude<
      AllowedTemplateSearchFilterExpressionKey,
      "templateSearchFilterPropertyFilterExpressions"
    >
  ) => {
    return handleSetTemplateSearchFilterExpression(key, []);
  };
  const handleDeleteTemplateSearchFilterPropertyExpression = async (
    propertyKey: string
  ) => {
    return handleSetTemplateSearchFilterExpression(
      "propertyFilterExpressions",
      propertySearchFiltersExpressions
        .filter(filter => filter.key !== propertyKey)
        .map(templateSearchFilterPropertyFilterExpression2Input)
    );
  };

  return {
    handleSetTemplateSearchFilterDefinition,
    handleDeleteTemplateSearchFilterDefinition,
    handleSetTemplateSearchFilterPropertyDefinition,
    handleDeleteTemplateSearchFilterPropertyDefinition,
    defineItemTemplateSearchFilterDefinitions,

    handleSetTemplateSearchFilterExpression,
    handleDeleteTemplateSearchFilterExpression,
    handleSetTemplateSearchFilterPropertyExpression,
    handleDeleteTemplateSearchFilterPropertyExpression,
    defineItemTemplateSearchFilterExpressions,
  };
}

export function templateSearchFilterPropertyFilterDefinition2Input(
  filter: ItemTemplateSearchFilterPropertyFilterFragment
): TemplateSearchFilterPropertyFilterDefinitionInput {
  switch (filter.__typename) {
    case "EntitySearchPropertyFilterBoolFilter": {
      return {
        boolFilter: {
          key: filter.key,
          operatorBool: filter.operatorBool,
          valueBool: filter.valueBool,
        },
      };
    }
    case "EntitySearchPropertyFilterBoolInFilter": {
      return {
        boolInFilter: {
          key: filter.key,
          operatorBoolIn: filter.operatorBoolIn,
          valueBoolIn: filter.valueBoolIn,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilter": {
      return {
        numberArrayOfFilter: {
          key: filter.key,
          operatorNumberArrayOf: filter.operatorNumberArrayOf,
          valueNumberArrayOf: filter.valueNumberArrayOf,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberBetweenFilter": {
      return {
        numberBetweenFilter: {
          key: filter.key,
          operatorNumberBetween: filter.operatorNumberBetween,
          valueNumberBetween: filter.valueNumberBetween,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberFilter": {
      return {
        numberFilter: {
          key: filter.key,
          operatorNumber: filter.operatorNumber,
          valueNumber: filter.valueNumber,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberInFilter": {
      return {
        numberInFilter: {
          key: filter.key,
          operatorNumberIn: filter.operatorNumberIn,
          valueNumberIn: filter.valueNumberIn,
        },
      };
    }
    case "EntitySearchPropertyFilterTextArrayOfFilter": {
      return {
        textArrayOfFilter: {
          key: filter.key,
          operatorTextArrayOf: filter.operatorTextArrayOf,
          valueTextArrayOf: filter.valueTextArrayOf,
        },
      };
    }
    case "EntitySearchPropertyFilterTextFilter": {
      return {
        textFilter: {
          key: filter.key,
          operatorText: filter.operatorText,
          valueText: filter.valueText,
        },
      };
    }
    case "EntitySearchPropertyFilterTextInFilter": {
      return {
        textInFilter: {
          key: filter.key,
          operatorTextIn: filter.operatorTextIn,
          valueTextIn: filter.valueTextIn,
        },
      };
    }
    default: {
      assertNever(filter);
    }
  }
}

export function templateSearchFilterPropertyFilterExpression2Input(
  filter: ItemTemplateSearchFilterPropertyFilterComputedFragment
): TemplateSearchFilterPropertyFilterExpressionInput {
  switch (filter.__typename) {
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      return {
        boolInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorBoolIn: filter.operatorBoolIn,
        },
      };
    }
    case "EntitySearchPropertyFilterBoolFilterComputed": {
      return {
        boolFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorBool: filter.operatorBool,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      return {
        numberInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberIn: filter.operatorNumberIn,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      return {
        numberBetweenFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberBetween: filter.operatorNumberBetween,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
      return {
        numberArrayOfFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumberArrayOf: filter.operatorNumberArrayOf,
        },
      };
    }
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      return {
        numberFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorNumber: filter.operatorNumber,
        },
      };
    }
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      return {
        textInFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorTextIn: filter.operatorTextIn,
        },
      };
    }
    case "EntitySearchPropertyFilterTextFilterComputed": {
      return {
        textFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorText: filter.operatorText,
        },
      };
    }
    case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
      return {
        textArrayOfFilter: {
          expr: filter.expr,
          key: filter.key,
          operatorTextArrayOf: filter.operatorTextArrayOf,
        },
      };
    }
    default: {
      assertNever(filter);
    }
  }
}
