import { SvgIcon, TypographyProps } from "@mui/material";
import React from "react";
import { useFormatting } from "../formatting/useFormatting";
import { LabeledValue } from "./LabeledValue";

interface Props {
  label: string;
  value?: any;
  align?: React.ComponentProps<typeof LabeledValue>["align"];
  icon?: typeof SvgIcon;
  labelProps?: Omit<
    TypographyProps<"label">,
    "children" | "variant" | "component"
  >;
  notSetText: string;
}

export const LabeledDateValue = ({
  label,
  value,
  align,
  icon,
  notSetText,
  labelProps,
}: Props) => {
  const { getFormattedDate } = useFormatting();

  return (
    <LabeledValue
      label={label}
      align={align}
      icon={icon}
      labelProps={labelProps}
      notSet={!value}
    >
      {value ? getFormattedDate(value) : notSetText}
    </LabeledValue>
  );
};
