/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type VanMarckeProfitability_ItemFragment = { __typename: 'Item', id: string, isRootItem: boolean, type: Types.ItemType, product?: { __typename: 'ItemProduct', articleNumber?: string | null } | null, proposedCalculation?: { __typename: 'ItemCalculation', quantity: number, priceSubTotal: number } | null };

export const VanMarckeProfitability_ItemFragmentDoc = gql`
    fragment VanMarckeProfitability_Item on Item {
  id
  isRootItem
  type
  product {
    articleNumber
  }
  proposedCalculation {
    quantity
    priceSubTotal
  }
}
    `;