import { omit } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useRef, useState } from "react";
import omitDeep from "omit-deep-lodash";
import { dataURLtoFile, uploadFileToUploadCare } from "../attachments/helpers";
import {
  AttachmentInput,
  _3d_Item,
  _3d_ShapeInput,
} from "../../../clients/graphqlTypes";
import {
  ThreeDShapeDetailsFragment,
  ThreeD__3d_ItemFragment,
} from "./ThreeD.generated";
import { useUserData } from "../../auth/useUserData";

export const prepareDateShape = (data: any): any => {
  // const preparedItems = omitDeep(data.items, ["__typename"]);

  const inputDataShape = omitDeep(data, "__typename");
  //   .map((item: _3d_Item) => {
  //   return {
  //     ...item,
  //     __typename: undefined,
  //     // itemDefinitionFiles: {
  //     //   ...item.itemDefinitionFiles,
  //     //   __typename: undefined,
  //     // },
  //     itemDefinitionFiles: item.itemDefinitionFiles.map(f => ({
  //       ...f,
  //       __typename: undefined,
  //     })),
  //     itemTextureData: item.itemTextureData
  //       ? {
  //           ...item.itemTextureData,
  //           __typename: undefined,
  //         }
  //       : null,
  //     itemRepeatData: item.itemRepeatData
  //       ? {
  //           ...item.itemRepeatData,
  //           __typename: undefined,
  //         }
  //       : null,
  //     itemIconData: item.itemIconData
  //       ? {
  //           ...item.itemIconData,
  //           __typename: undefined,
  //         }
  //       : null,
  //     itemWallData: item.itemWallData
  //       ? {
  //           ...item.itemWallData,
  //           __typename: undefined,
  //         }
  //       : null,
  //     item3dData: item.item3dData
  //       ? {
  //           ...item.item3dData,
  //           __typename: undefined,
  //         }
  //       : null,
  //     itemProductData: item.itemProductData
  //       ? {
  //           ...item.itemProductData,
  //           price: item.itemProductData.price ? item.itemProductData.price : 0,
  //           __typename: undefined,
  //         }
  //       : null,
  //     _msItemInfo: item._msItemInfo
  //       ? {
  //           ...item._msItemInfo,
  //           itemDefinitionFiles: item._msItemInfo.itemDefinitionFiles.map(
  //             f => ({ ...f, __typename: undefined })
  //           ),
  //           // ? Object.fromEntries(
  //           //     Object.entries(item._msItemInfo.itemDefinition)
  //           //       .filter(([key, value]) => key !== "__typename")
  //           //       .map(([key, value]) => [
  //           //         key,
  //           //         value && typeof value !== "string"
  //           //           ? { s3Key: value.s3Key }
  //           //           : null,
  //           //       ])
  //           //   )
  //           // : null,
  //           __typename: undefined,
  //         }
  //       : null,
  //   };
  // });

  // const inputDataShape = {
  //   ...data,
  //   __typename: undefined,
  //   items: preparedItems,
  // } as _3d_ShapeInput;

  return inputDataShape;
};

export const useThreeDCommunicate = (
  threeDUrl: string,
  threeDRoomSnapshot: ThreeDShapeDetailsFragment | undefined | null,
  threeDSelectedRoomItems: ThreeD__3d_ItemFragment[],
  threeDInitialStepKey:
    | "select-room"
    | "select-shape"
    | "transform-room-shape"
    | "transform-fittings-in-room"
    | "module-3d"
    | undefined,
  threeDGuiMode:
    | "complete-ui"
    | "no-right-menu-ui"
    | "no-right-footer-menu-ui"
    | "no-ui"
    | undefined,
  onClose: () => void,
  onDataChange: (inputDataShape: _3d_ShapeInput) => Promise<void> | void,
  onSnapshots: (inputSnapshots: AttachmentInput[]) => Promise<void> | void
): [
  (event: any) => Promise<void>,
  boolean,
  () => Promise<void>,
  (selectedRoomItems: ThreeD__3d_ItemFragment[]) => void
] => {
  const { enqueueSnackbar } = useSnackbar();
  const viewer = useUserData().currentUser;
  const locale: string =
    viewer && viewer.locale ? viewer.locale : navigator.language || "en-GB";

  const [uploading, setUploading] = useState<boolean>(false);
  const lastSnapshotDataURL = useRef<string | null>(null);
  const [threeDCommunicationDetails, setThreeDCommunicationDetails] = useState<{
    source: WindowProxy;
    origin: string;
  } | null>(null);

  const saveSnapshotAndClose = useCallback(async () => {
    if (lastSnapshotDataURL.current) {
      try {
        setUploading(true);
        const fileToUpload = dataURLtoFile(
          lastSnapshotDataURL.current,
          `Snapshot - ${new Date().toISOString()}`
        );
        const attachment = await uploadFileToUploadCare(fileToUpload, true);
        await onSnapshots([omit(attachment, "id")]);
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      } finally {
        setUploading(false);
        onClose();
      }
    } else {
      onClose();
    }
  }, [onSnapshots, onClose, enqueueSnackbar]);

  const threeDCommunicate = useCallback(
    async (event: MessageEvent<any>) => {
      if (!(event.isTrusted && threeDUrl?.startsWith(event.origin))) {
        return;
      }

      const source = event.source as WindowProxy;

      setThreeDCommunicationDetails({ source, origin: event.origin });

      if (source && event.data.action === "getData") {
        source.postMessage(
          {
            action: "setRoom",
            roomSnapshot: threeDRoomSnapshot,
            selectedRoomItems: threeDSelectedRoomItems,
            plannerStepKey: threeDInitialStepKey,
            enabledFloorPlanGUI: threeDGuiMode,
            locale: locale,
            enabledRightMenuItems: "reduced-menu",
          },
          event.origin
        );
      } else if (event.data.action === "closePlanner") {
        await saveSnapshotAndClose();
      } else if (event.data.action === "putSnapshot") {
        if (event.data.data.snapshotDataURL)
          lastSnapshotDataURL.current = event.data.data.snapshotDataURL;
      } else if (event.data.action === "putData") {
        const inputDataShape = prepareDateShape(event.data.data);
        await onDataChange(inputDataShape);
        // if (result.data) {
        //   source.postMessage(result.data._3d_modifyRoomShape, event.origin);
        // }
      }
    },
    [
      threeDRoomSnapshot,
      threeDSelectedRoomItems,
      onDataChange,
      threeDUrl,
      saveSnapshotAndClose,
    ]
  );

  const threeDUpdateRoomItems = useCallback(
    (selectedRoomItems: ThreeD__3d_ItemFragment[]) => {
      if (threeDCommunicationDetails) {
        threeDCommunicationDetails.source.postMessage(
          {
            action: "updateRoomItems",
            selectedRoomItems: selectedRoomItems,
          },
          threeDCommunicationDetails.origin
        );
      }
    },
    [threeDUrl, threeDCommunicationDetails]
  );

  return [
    threeDCommunicate,
    uploading,
    saveSnapshotAndClose,
    threeDUpdateRoomItems,
  ];
};
