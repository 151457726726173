import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SplashScreen } from "../common/SplashScreen";
import { useAuth } from "./auth/useAuth";
import { useSelectedUser } from "./auth/useSelectedUser";
import { SELECTED_USER_HTTP_HEADER } from "./constants";

interface Props {
  m1AccountId: number;
  m1OfferNumber: string;
}

export function M1RedirectRoute({ m1AccountId, m1OfferNumber }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { selectedUserId } = useSelectedUser();

  React.useEffect(() => {
    fetch(`/api/partner/m1plus/${m1AccountId}/quote/${m1OfferNumber}`, {
      headers: {
        authorization: `Bearer ${auth.token}`,
        ...(selectedUserId
          ? { [SELECTED_USER_HTTP_HEADER]: selectedUserId }
          : undefined),
      },
    })
      .then(response => response.json())
      .then(result => {
        navigate(
          `/u/${result.organisationId}/projects/${result.projectId}/quotes/${result.quoteId}`
        );
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  }, [
    auth.token,
    enqueueSnackbar,
    m1AccountId,
    m1OfferNumber,
    navigate,
    selectedUserId,
  ]);

  return <SplashScreen />;
}
