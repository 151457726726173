import { defaults, pick } from "lodash";
import React from "react";
import {
  CreateItemsValues,
  DecisionBehaviorOfSubitems,
  ItemProductInput,
  ItemType,
} from "../../../../clients/graphqlTypes";
import { assertNever } from "../../../utils";

export type CreateItemArgs =
  | {
      type: "paid";
      title: string;
      docId: string;
      parentItemId: string;
      product?: ItemProductInput;
    }
  | {
      type: "section";
      title: string;
      docId: string;
      parentItemId: string;
      templateQuoteId?: string;
    }
  | {
      type: "decision";
      title: string;
      docId: string;
      parentItemId: string;
      decisionBehaviorOfSubitems?: DecisionBehaviorOfSubitems;
    }
  | {
      type: Exclude<ItemType, "paid" | "section" | "decision">;
      title: string;
      docId: string;
      parentItemId: string;
    };

export type CreateItemsVariables = {
  input: {
    projectId: string | null;
    docId: string;
    parentItemId: string;
    items: CreateItemsValues[];
    expandedItemIds?: string[];
  };
};

export type CreateItemsReturn = {
  data?: { createItems: { items: { id: string }[] } } | null | undefined;
};

interface Props {
  projectId: string | null;
  createDocumentItems: (args: {
    variables: CreateItemsVariables;
  }) => Promise<CreateItemsReturn>;
  expandedItemIds?: string[];
}

export function useCreateItem({
  projectId,
  createDocumentItems,
  expandedItemIds,
}: Props) {
  const createItem = React.useCallback(
    async ({ type, docId, parentItemId, ...opts }: CreateItemArgs) => {
      const item: CreateItemsValues = defaults(
        {
          type,
          ...opts,
          ...("product" in opts
            ? { product: pick(opts.product, "supplierId", "articleNumber") }
            : {}),
        },
        {
          type,
          title: "",
          templateQuoteId: undefined,
          decisionBehaviorOfSubitems:
            type === "decision" ? "SELECT_ONE" : "NONE",
          product: undefined,
        } as const
      );

      const result = await createDocumentItems({
        variables: {
          input: {
            projectId: projectId,
            docId: docId,
            parentItemId,
            items: [item],
            expandedItemIds,
          },
        },
      });
      const newItem = result.data!.createItems.items[0];
      return newItem;
    },
    [createDocumentItems, expandedItemIds, projectId]
  );

  const createItemSection = React.useCallback(
    async (
      title: string,
      docId: string,
      parentItemId: string,
      templateQuoteId?: string
    ) => {
      return await createItem({
        type: "section",
        title,
        docId,
        parentItemId,
        templateQuoteId,
      });
    },
    [createItem]
  );
  const createItemDecision = React.useCallback(
    async (title: string, docId: string, parentItemId: string) => {
      return await createItem({
        type: "decision",
        title,
        docId,
        parentItemId,
        decisionBehaviorOfSubitems: "SELECT_ONE",
      });
    },
    [createItem]
  );
  const createItemPaid = React.useCallback(
    async (title: string, docId: string, parentItemId: string) => {
      return await createItem({ type: "paid", docId, parentItemId, title });
    },
    [createItem]
  );
  const createItemUnpaid = React.useCallback(
    async (title: string, docId: string, parentItemId: string) => {
      return await createItem({ type: "unpaid", docId, parentItemId, title });
    },
    [createItem]
  );
  const createItemDefect = React.useCallback(
    async (title: string, docId: string, parentItemId: string) => {
      return await createItem({ type: "defect", docId, parentItemId, title });
    },
    [createItem]
  );

  const createItemByType = React.useCallback(
    async (
      itemType: ItemType,
      docId: string,
      parentItemId: string,
      input?: string
    ) => {
      switch (itemType) {
        case "section":
          return await createItemSection(
            input ?? "New section",
            docId,
            parentItemId
          );
        case "decision":
          return await createItemDecision(
            input ?? "New decision",
            docId,
            parentItemId
          );
        case "paid":
          return await createItemPaid(
            input ?? "New paid item",
            docId,
            parentItemId
          );
        case "unpaid":
          return await createItemUnpaid(
            input ?? "New unpaid item",
            docId,
            parentItemId
          );
        case "defect":
          return await createItemDefect(
            input ?? "New defect",
            docId,
            parentItemId
          );
        default:
          throw assertNever(itemType);
      }
    },
    [
      createItemDecision,
      createItemDefect,
      createItemPaid,
      createItemSection,
      createItemUnpaid,
    ]
  );

  return {
    createItem,
    createItemSection,
    createItemByType,
  };
}
