import { useApolloClient } from "@apollo/client";
import { CardContainer, ModalOpenButton } from "@msys/ui";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import DelIcon from "@mui/icons-material/Delete";
import PhoneIcon from "@mui/icons-material/Phone";
import { Divider, IconButton, List, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { groupBy, partition } from "lodash";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../../auth/useUserData";
import { Avatar } from "../../../commons/Avatar";
import { ProfileListItem } from "../../../commons/ProfileListItem";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { Stack } from "../../../commons/layout/Stack";
import { ContactsIcon } from "../../crm-persons/ContactsIcon";
import { InvitationStatusBadge } from "../../invitations/InvitationStatusBadge";
import { InviteOrganisationMemberModal } from "../../invitations/InviteOrganisationMemberModal";
import { UserAvatar } from "../../users/UserAvatar";
import { getMemberOptions } from "../../users/helpers";
import { useOrganisationRoles } from "../../users/useRoles";
import {
  OrganisationMemberInvitationRowFragment,
  OrganisationMemberRowFragment,
  OrganisationMembersBoxFragment,
  useCancelOrganisationMembershipInvitationMutation,
  useOrganisationMembers_ModifyOrganisationMutation,
} from "./OrganisationMembersBox.generated";

interface FormValues {
  representiveId: string;
}

interface Props {
  // representiveId: string | undefined;
  isViewerOrganisation: boolean;
  organisation: OrganisationMembersBoxFragment;
  // members: OrganisationMembersBox_MembershipsFragment[] | undefined;
  // invitations:
  //   | OrganisationMembersBox_MembershipInvitationsFragment[]
  //   | undefined;
}

export function OrganisationMembersBox({
  isViewerOrganisation,
  organisation,
}: // members = [],
// invitations = [],
Props) {
  const viewer = useUserData().currentUser!;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate([
    "OrganisationInvite",
    "OrganisationRole",
    "OrganisationProfile",
  ]);

  const client = useApolloClient();
  const [cancelInvitation] = useCancelOrganisationMembershipInvitationMutation({
    client,
  });
  const [modifyOrganisation] =
    useOrganisationMembers_ModifyOrganisationMutation({
      client,
    });

  const initialValues: FormValues = {
    representiveId: organisation.representive?.id ?? "",
  };

  const validationSchema = Yup.object().shape({
    representiveId: Yup.string()
      .label(
        t("Organisation representative", {
          ns: "OrganisationProfile",
        })
      )
      .required(),
  });

  const [activeMembers, inactiveMembers] = partition(
    organisation.memberships,
    member => member.active
  );

  const {
    PENDING: pendingMembershipInvitations = [],
    DECLINED: declinedMembershipInvitations = [],
    DELETED: deletedMembershipInvitations = [],
  } = groupBy(organisation.membershipInvitations, "status");

  const handleSubmit = async (values: FormValues) => {
    if (!isViewerOrganisation) return;

    try {
      await modifyOrganisation({
        variables: {
          input: {
            representiveId: values.representiveId,
          },
        },
        refetchQueries: [namedOperations.Query.OrganisationProfile],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <CardContainer
        Icon={<ContactsIcon />}
        title={t("Members", {
          ns: "OrganisationInvite",
        })}
        itemCount={
          activeMembers.length +
          inactiveMembers.length +
          pendingMembershipInvitations.length +
          declinedMembershipInvitations.length
        }
        ActionButton={
          <RestrictedByOrganisationPermissionWithDebug permission="ADD_MEMBER_TO_ORG">
            <ModalOpenButton
              Modal={InviteOrganisationMemberModal}
              modalProps={{
                refetchQueries: [namedOperations.Query.OrganisationProfile],
              }}
            >
              <IconButton
                color="primary"
                aria-label={t("Add team member", {
                  ns: "OrganisationInvite",
                })}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          </RestrictedByOrganisationPermissionWithDebug>
        }
      >
        {isViewerOrganisation && (
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
            {viewer.organisation.id === organisation.id &&
              !!viewer.roles.find(
                r =>
                  r.internalName === "ORG_ADMIN" ||
                  r.internalName === "ORG_ADMIN"
              ) && (
                <Form>
                  <Stack p={1} flexDirection="column">
                    <SelectField
                      name="representiveId"
                      options={getMemberOptions(activeMembers)}
                      label={t("Organisation representative", {
                        ns: "OrganisationProfile",
                      })}
                      disabled={false}
                    />
                    <AutoSave />
                  </Stack>
                  <Divider />
                </Form>
              )}
          </RestrictedByOrganisationPermissionWithDebug>
        )}

        <List disablePadding>
          {activeMembers.sort(sortMemberByFirstname).map(member => (
            <OrganisationMemberRow
              key={member.id}
              member={member}
              organisationTitle={viewer.organisation.title}
              isRepresentive={member.id === organisation.representive?.id}
            />
          ))}

          {pendingMembershipInvitations
            .sort(sortInvitationByFirstname)
            .map(invitation => {
              return (
                <OrganisationMemberInvitationRow
                  key={invitation.id}
                  invitation={invitation}
                  onCancel={async () => {
                    await cancelInvitation({
                      variables: {
                        organisationMembershipInvitationId: invitation.id,
                      },
                      refetchQueries: [
                        namedOperations.Query.OrganisationProfile,
                      ],
                    });
                    enqueueSnackbar(
                      t("Invitation canceled", {
                        ns: "OrganisationInvite",
                      }),
                      {
                        variant: "info",
                      }
                    );
                  }}
                />
              );
            })}

          {inactiveMembers.sort(sortMemberByFirstname).map(member => (
            <OrganisationMemberRow
              key={member.id}
              member={member}
              organisationTitle={organisation.title}
              isRepresentive={member.id === organisation.representive?.id}
            />
          ))}

          {declinedMembershipInvitations
            .sort(sortInvitationByFirstname)
            .map(invitation => {
              return (
                <OrganisationMemberInvitationRow
                  key={invitation.id}
                  invitation={invitation}
                  onCancel={async () => {
                    await cancelInvitation({
                      variables: {
                        organisationMembershipInvitationId: invitation.id,
                      },
                    });
                    enqueueSnackbar(
                      t("Invitation canceled", {
                        ns: "OrganisationInvite",
                      }),
                      {
                        variant: "info",
                      }
                    );
                  }}
                />
              );
            })}
          {deletedMembershipInvitations
            .sort(sortInvitationByFirstname)
            .map(invitation => {
              return (
                <OrganisationMemberInvitationRow
                  key={invitation.id}
                  invitation={invitation}
                />
              );
            })}
        </List>
      </CardContainer>
    </Formik>
  );
}

function OrganisationMemberRow({
  member,
  organisationTitle,
  isRepresentive,
}: {
  member: OrganisationMemberRowFragment;
  organisationTitle: string;
  isRepresentive: boolean;
}) {
  const { currentUser } = useUserData();
  const { t } = useTranslate(["Global", "OrganisationProfile"]);
  const { getOrganisationRoleTitle } = useOrganisationRoles();

  let primaryLabel = `${member.firstname} ${member.familyname}`.trim();
  if (primaryLabel.trim().length === 0) primaryLabel = member.email;
  if (primaryLabel.trim().length === 0) primaryLabel = member.email;
  if (member.id === currentUser?.id)
    primaryLabel += ` (${t("me", {
      ns: "Global",
    })})`;

  let roleLabel = getOrganisationRoleTitle(member.roles);

  if (!member.active) {
    primaryLabel = `${primaryLabel} (${t("deactivated", {
      ns: "Global",
    })})`;
  }

  const secondaryLabel = organisationTitle
    ? `${organisationTitle} (${roleLabel})`
    : roleLabel;

  return (
    <ProfileListItem
      button
      component={Link}
      to={`/organisation/users/${member.id}`}
      avatar={<UserAvatar userAvatar={member} size="m" />}
      primary={primaryLabel}
      secondary={secondaryLabel}
      isNotActive={member.__typename === "User" && !member.active}
      ActionButtons={[
        ...(isRepresentive
          ? [
              <Tooltip
                key="representive-icon"
                title={t("Organisation representative", {
                  ns: "OrganisationProfile",
                })}
              >
                <AccountCircleIcon fontSize="small" />
              </Tooltip>,
            ]
          : []),
        ...(member.phones.length > 0
          ? [
              <IconButton
                key="phone-button"
                size="small"
                color="primary"
                component="a"
                href={`tel:${member.phones[0].number}`}
              >
                <PhoneIcon />
              </IconButton>,
            ]
          : []),
      ]}
    />
  );
}

function OrganisationMemberInvitationRow({
  invitation,
  onCancel,
}: {
  invitation: OrganisationMemberInvitationRowFragment;
  onCancel?: () => Promise<void>;
}) {
  const { getOrganisationRoleTitle } = useOrganisationRoles();

  const i1 = invitation.contact.firstname?.substring(0, 1) ?? "";
  const i2 = invitation.contact.familyname?.substring(0, 1) ?? "";

  const name =
    `${invitation.contact.firstname} ${invitation.contact.familyname}`.trim();

  const roles = getOrganisationRoleTitle(invitation.organisationRoles);

  return (
    <ProfileListItem
      // button
      // component={Link}
      // to={`/crm/users/${invitation.contact.id}`}
      avatar={<Avatar initials={`${i1}${i2}`} type="circle" size="m" />}
      primary={name}
      secondary={roles}
      badge={<InvitationStatusBadge status={invitation.status} />}
      ActionButton={
        onCancel ? (
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
            <IconButton
              style={{ pointerEvents: "all" }}
              color="primary"
              size="small"
              onClick={onCancel}
            >
              <DelIcon />
            </IconButton>
          </RestrictedByOrganisationPermissionWithDebug>
        ) : undefined
      }
      isNotActive
    />
  );
}

interface SortableMembership {
  firstname: string;
}

function sortMemberByFirstname(
  member1: SortableMembership,
  member2: SortableMembership
) {
  return member1.firstname.localeCompare(member2.firstname);
}

interface SortableInvitation {
  contact: {
    firstname: string;
  };
}

function sortInvitationByFirstname(
  invitation1: SortableInvitation,
  invitation2: SortableInvitation
) {
  return invitation1.contact.firstname.localeCompare(
    invitation2.contact.firstname
  );
}
