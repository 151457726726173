import { isPicture } from "@msys/common";
import {
  Attachment,
  ellipsisStyle,
  isImageOr3dModel,
  processAttachment,
  TextWithBreaks,
} from "@msys/ui";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { color } from "../../../common/MuiThemeProvider";
import { useUserData } from "../../auth/useUserData";
import { ChatBubble } from "../../commons/ChatBubble";
import { GalleryGrid } from "../../commons/images/GalleryGrid";
import { Stack } from "../../commons/layout/Stack";
import { assertNever } from "../../utils";
import { FileRow } from "../attachments/FileRow";
import {
  ChannelMessages__ChannelFragment,
  ChannelMessages__MessageFragment,
} from "./ChannelMessages.generated";
import {
  Bundle,
  FilesBundle,
  ImagesBundle,
  InformationBundle,
  LabeledMessage,
  MessageBundle,
  NotificationBundle,
} from "./types";

type Channel = ChannelMessages__ChannelFragment & {
  messages: {
    edges: Array<{
      node: ChannelMessages__MessageFragment;
    }>;
  };
};

export type Layout = "CHAT" | "COMMENTS";

interface Props {
  channel: Channel;
  hasPreviousPage: boolean;
  onLoadMore: () => void;
  loadPreviousMessagesLabel?: string;
  noMessagesTitle?: string;
  layout?: Layout;
}

export const ChannelMessages = ({
  channel,
  onLoadMore,
  hasPreviousPage,
  loadPreviousMessagesLabel,
  noMessagesTitle,
  layout = "CHAT",
}: Props) => {
  const { t } = useTranslate("Channel");
  const items = groupByTypes(channel);
  const bundles = groupBundles(items);

  return (
    <>
      <Stack flexDirection="column" spacing={1} padding={2}>
        {hasPreviousPage && (
          <Box
            sx={{
              textAlign: "center",
              paddingBottom: 2,
              borderBottom: theme => `2px dashed ${theme.palette.grey[300]}`,
            }}
          >
            <Button
              size="small"
              startIcon={<ArrowUpwardIcon />}
              onClick={() => {
                onLoadMore();
              }}
              variant="text"
            >
              {loadPreviousMessagesLabel ?? t("Load previous messages")}
            </Button>
          </Box>
        )}
        {bundles.length === 0 && (
          <ChatBubble>
            {noMessagesTitle ??
              t("This is a new channel, there are no messages yet")}
          </ChatBubble>
        )}
        {bundles.map((bundle: Bundle, index: number) => {
          if (bundle.type === "information") {
            return (
              <ChannelInformationBundle
                key={`bundle-${index}`}
                bundle={bundle}
              />
            );
          }
          if (bundle.type === "notification") {
            return (
              <ChannelNotificationBundle
                key={`bundle-${index}`}
                bundle={bundle}
              />
            );
          }
          if (bundle.type === "images") {
            return (
              <ChannelImageBundle
                key={`bundle-${index}`}
                bundle={bundle}
                layout={layout}
              />
            );
          }
          if (bundle.type === "files") {
            return (
              <ChannelFileBundle
                key={`bundle-${index}`}
                bundle={bundle}
                layout={layout}
              />
            );
          }
          if (bundle.type === "message") {
            return (
              <ChannelMessageBundle
                key={`bundle-${index}`}
                bundle={bundle}
                layout={layout}
              />
            );
          }

          return null;
        })}
      </Stack>
    </>
  );
};

function groupByTypes(channel: Channel) {
  let items: Array<LabeledMessage> = [];
  const messagesSinceLastRead =
    channel && channel.messagesSinceLastRead
      ? channel.messagesSinceLastRead
      : 0;
  const now = moment().format();

  const orderedMessages = sortBy(channel.messages.edges, message => {
    return moment(message.node.createdAt).format("X");
  });

  orderedMessages.forEach((edge, i) => {
    const item = edge.node;

    if (
      messagesSinceLastRead &&
      i === orderedMessages.length - messagesSinceLastRead
    ) {
      items.push({
        type: "messages_since_last_read",
        content: messagesSinceLastRead,
      });
    }

    if (i === 0) {
      items.push({
        type: "date",
        content: item.createdAt || now,
      });
    } else {
      const currentMessageDate = moment(item.createdAt);
      const previousMessageDate = moment(orderedMessages[i - 1].node.createdAt);

      if (!currentMessageDate.isSame(previousMessageDate, "day")) {
        items.push({
          type: "date",
          content: item.createdAt || now,
        });
      }
    }

    if (item.attachments.length > 0) {
      const [images, files] = partition(item.attachments, a =>
        isPicture(a.mimeType)
      );

      if (images.length > 0) {
        items.push({
          type: "images",
          content: {
            ...item,
            attachments: images,
          } as ChannelMessages__MessageFragment,
        });
      }
      if (files.length > 0) {
        items.push({
          type: "files",
          content: {
            ...item,
            attachments: files,
          } as ChannelMessages__MessageFragment,
        });
      }
    } else if (isNotification(item)) {
      items.push({
        type: "notification",
        content: item as ChannelMessages__MessageFragment,
      });
    } else {
      items.push({
        type: "message",
        content: item as ChannelMessages__MessageFragment,
      });
    }
  });

  return items;
}

function isNotification(item: ChannelMessages__MessageFragment) {
  return item.quote || item.project || item.invoice;
}

function groupBundles(items: LabeledMessage[]) {
  return items.reduce((groups: Bundle[], item: LabeledMessage) => {
    const lastGroup = groups[groups.length - 1];
    const itemAuthor =
      (item.type === "message" ||
        item.type === "files" ||
        item.type === "images") &&
      item.content.author
        ? item.content.author.id
        : null;

    const sameAuthor = lastGroup && itemAuthor === lastGroup.authorID;

    if (
      lastGroup &&
      lastGroup.type === item.type &&
      sameAuthor &&
      // we don't need to group files & images since every message can have multiple attachments
      !(item.type === "files" || item.type === "images")
    ) {
      // adding to previous group
      const newGroup = {
        ...lastGroup,
        items: [...lastGroup.items, item],
      };

      switch (item.type) {
        case "message":
          groups[groups.length - 1] = newGroup as MessageBundle;
          break;
        // case "files":
        //   groups[groups.length - 1] = newGroup as FilesBundle;
        //   break;
        // case "images":
        //   groups[groups.length - 1] = newGroup as ImagesBundle;
        //   break;
        case "notification":
          groups[groups.length - 1] = newGroup as NotificationBundle;
          break;
        default:
          assertNever(item);
      }
    } else {
      // creating new group
      switch (item.type) {
        case "date":
        case "messages_since_last_read":
          groups.push({
            type: "information",
            items: [item],
            authorID: null,
          });
          break;

        case "files":
          if (item.content.text) {
            groups.push({
              type: "message",
              authorID: itemAuthor,
              items: [
                {
                  ...item,
                  type: "message",
                },
              ],
            });
          }
          groups.push({
            type: "files",
            authorID: itemAuthor,
            items: [item],
          });
          break;

        case "images":
          if (item.content.text) {
            groups.push({
              type: "message",
              authorID: itemAuthor,
              items: [
                {
                  ...item,
                  type: "message",
                },
              ],
            });
          }
          groups.push({
            type: "images",
            authorID: itemAuthor,
            items: [item],
          });
          break;

        case "message":
          groups.push({
            type: "message",
            authorID: itemAuthor,
            items: [item],
          });
          break;

        case "notification":
          groups.push({
            type: "notification",
            authorID: null,
            items: [item],
          });
          break;
        default:
          assertNever(item);
      }
    }

    return groups;
  }, []);
}

const ChannelInformationBundle = ({
  bundle,
}: {
  bundle: InformationBundle;
}) => {
  const { t } = useTranslate("Channel");

  return (
    <>
      {bundle.items.map((item, i) => {
        if (item.type === "messages_since_last_read") {
          return (
            <div key={`date-last-read-${i}`}>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "secondary.light",
                  },
                }}
              >
                <Typography variant="caption" color="secondary">
                  {`${item.content} ${t("New messages")}`}
                </Typography>
              </Divider>
            </div>
          );
        }

        if (item.type === "date") {
          const now = moment();
          const date = moment(item.content);

          return (
            <div key={`date-${i}`}>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "secondary.light",
                  },
                }}
              >
                <Typography variant="caption" color="secondary">
                  {now.isSame(date, "day")
                    ? t("Today")
                    : now.diff(date, "days") === 1
                      ? t("Yesterday")
                      : date.format("DD/MM/YY")}
                </Typography>
              </Divider>
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

const ChannelNotificationBundle = ({
  bundle,
}: {
  bundle: NotificationBundle;
}) => {
  const { t } = useTranslate("Channel");

  return (
    <Stack flexDirection="column" spacing={0.5}>
      {bundle.items.map((item, i) => {
        if (!item.content) return null;

        return (
          <ChatBubble
            key={`bubble--${i}`}
            createdAt={item.content.createdAt}
            authorLabel="MeisterSystems"
            alignSelf={"stretch"}
            backgroundColor="information"
          >
            <List disablePadding dense sx={{ marginLeft: -1, marginRight: -2 }}>
              <ListItem dense divider={false}>
                <ListItemAvatar
                  sx={theme => ({
                    minWidth: theme.layout.listItemMinWidth.xs,
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <NotificationsActiveIcon fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                  style={ellipsisStyle}
                  primary={
                    <>
                      {t(item.content.text as any)}
                      {item.content.quote && (
                        <>
                          :{" "}
                          <MuiLink
                            color="secondary"
                            component={Link}
                            to={`/projects/${item.content.quote.projectId}/quotes/${item.content.quote.id}`}
                          >
                            {item.content.quote.title}
                          </MuiLink>
                        </>
                      )}
                      {item.content.invoice && (
                        <>
                          :{" "}
                          <MuiLink
                            color="secondary"
                            href={item.content.invoice.file?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.content.invoice.title}
                          </MuiLink>
                        </>
                      )}
                      {item.content.project && (
                        <>
                          :{" "}
                          <MuiLink
                            color="secondary"
                            component={Link}
                            to={`/projects/${item.content.project.id}/overview`}
                          >
                            {item.content.project.title}
                          </MuiLink>
                        </>
                      )}
                    </>
                  }
                  primaryTypographyProps={{ style: ellipsisStyle }}
                />
              </ListItem>
            </List>
          </ChatBubble>
        );
      })}
    </Stack>
  );
};

const ChannelMessageBundle = ({
  bundle,
  layout,
}: {
  bundle: MessageBundle;
  layout: Layout;
}) => {
  const currentUser = useUserData().currentUser!;

  return (
    <Stack flexDirection="column" spacing={0.5}>
      {bundle.items.map((item, i) => {
        if (!item.content) return null;

        if (!item.content.author)
          return (
            <ChatBubble key={`bubble--${i}`} createdAt={item.content.createdAt}>
              <Typography whiteSpace={"pre-wrap"}>
                {item.content.text}
              </Typography>
            </ChatBubble>
          );

        const isViewerMessage = item.content.author?.id === currentUser.id;

        return (
          <ChatBubble
            key={`bubble--${i}`}
            createdAt={item.content.createdAt}
            authorLabel={
              layout === "COMMENTS"
                ? `${item.content.author.firstname} ${item.content.author.familyname}`
                : !isViewerMessage && i === 0
                  ? `${item.content.author.firstname} ${item.content.author.familyname}`
                  : null
            }
            alignSelf={
              layout === "COMMENTS"
                ? "flex-start"
                : isViewerMessage
                  ? "flex-end"
                  : "flex-start"
            }
            backgroundColor={isViewerMessage ? color.blue6 : color.blue8}
          >
            <Typography>
              <TextWithBreaks text={item.content.text} />
            </Typography>
          </ChatBubble>
        );
      })}
    </Stack>
  );
};

const ChannelFileBundle = ({
  bundle,
  layout,
}: {
  bundle: FilesBundle;
  layout: Layout;
}) => {
  const currentUser = useUserData().currentUser!;
  const firstItem = bundle.items[0];

  const attachments = bundle.items
    .map(i => i.content.attachments)
    .flat(1)
    .filter(Boolean) as Attachment[];

  return (
    <ChatBubble
      createdAt={firstItem.content.createdAt}
      authorLabel={
        layout === "COMMENTS"
          ? `${firstItem.content.author!.firstname} ${
              firstItem.content.author!.familyname
            }`
          : null
      }
      alignSelf={
        layout === "COMMENTS"
          ? "flex-start"
          : firstItem.content.author!.id === currentUser.id
            ? "flex-end"
            : "flex-start"
      }
      backgroundColor={
        firstItem.content.author!.id === currentUser.id
          ? color.blue6
          : color.blue8
      }
    >
      <List disablePadding dense sx={{ marginLeft: -1, marginRight: -2 }}>
        {attachments.map((attachment, i) => {
          return (
            <FileRow
              key={`file-bundle--${i}`}
              file={attachment}
              button={false}
              divider={false}
            />
          );
        })}
      </List>
    </ChatBubble>
  );
};

const ChannelImageBundle = ({
  bundle,
  layout,
}: {
  bundle: ImagesBundle;
  layout: Layout;
}) => {
  const currentUser = useUserData().currentUser!;
  const firstItem = bundle.items[0];

  const attachments = (
    bundle.items
      .map(i => i.content.attachments)
      .flat(1)
      .filter(Boolean) as Attachment[]
  )
    .map(processAttachment)
    .filter(isImageOr3dModel);

  return (
    <ChatBubble
      createdAt={firstItem.content.createdAt}
      authorLabel={
        layout === "COMMENTS"
          ? `${firstItem.content.author!.firstname} ${
              firstItem.content.author!.familyname
            }`
          : null
      }
      alignSelf={
        layout === "COMMENTS"
          ? "flex-start"
          : firstItem.content.author!.id === currentUser.id
            ? "flex-end"
            : "flex-start"
      }
      backgroundColor={
        firstItem.content.author!.id === currentUser.id
          ? color.blue6
          : color.blue8
      }
    >
      <Box width={attachments.length === 1 ? "132px" : "calc(132px * 2 + 4px)"}>
        <GalleryGrid
          images={attachments}
          columns={attachments.length === 1 ? 1 : 2}
        />
      </Box>
    </ChatBubble>
  );
};
