/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type RequestRequirementBoxFragment = { __typename: 'Request', id: string, requestorProjectId: string, requestorDoc: { __typename: 'Requirement', id: string, title: string, isPublished: boolean } };

export const RequestRequirementBoxFragmentDoc = gql`
    fragment RequestRequirementBox on Request {
  id
  requestorDoc {
    id
    title
    isPublished
  }
  requestorProjectId
}
    `;