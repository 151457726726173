import React from "react";

// source: https://github.com/facebook/react/issues/13029#issuecomment-445480443
export const mergeRefs =
  <T>(...refs: (React.Ref<T> | undefined)[]) =>
  (ref: T) => {
    refs.forEach(resolvableRef => {
      if (!resolvableRef) return;
      if (typeof resolvableRef === "function") resolvableRef(ref);
      else (resolvableRef as any).current = ref;
    });
  };
