import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import moment from "moment";
import { useLatestDefinedValue } from "../../../commons/hooks/useLatestDefinedValue";
import { Stack } from "../../../commons/layout/Stack";
import { TasksTimesheetBreakForm } from "./TasksTimesheetBreakForm";
import {
  TasksTimesheetForm_ItemFragment,
  useTasksTimesheetBox__WorkSessionsQuery,
} from "./TasksTimesheetForm.generated";
import { TasksTimesheetTasksForm } from "./TasksTimesheetTasksForm";
import { TasksTimesheetTimeForm } from "./TasksTimesheetTimeForm";

interface Props {
  selectedItemId?: string;
  projectId: string;
  assigneeId: string;
  date: moment.Moment;
  tasksItems: TasksTimesheetForm_ItemFragment[];
}

export const TasksTimesheetForm = ({
  selectedItemId,
  projectId,
  assigneeId,
  date,
  tasksItems,
}: Props) => {
  const client = useApolloClient();
  const query = useTasksTimesheetBox__WorkSessionsQuery({
    client,
    variables: {
      projectId,
      assigneeId,
      date: date.format("YYYY-MM-DD"),
    },
    fetchPolicy: "network-only",
  });

  const project = useLatestDefinedValue(
    getDataOrNull(query.data?.project)?.project
  );
  const workSessions = project?.workSessions ?? [];

  if (!project) return null;

  return (
    <Stack width="100%" flexDirection="column">
      <TasksTimesheetTimeForm
        loading={query.loading}
        projectId={projectId}
        assigneeId={assigneeId}
        date={date}
        workSessions={workSessions}
        project={project}
      />
      <TasksTimesheetTasksForm
        loading={query.loading}
        projectId={projectId}
        assigneeId={assigneeId}
        selectedItemId={selectedItemId}
        date={date}
        tasksItems={tasksItems}
      />
      <TasksTimesheetBreakForm
        loading={query.loading}
        projectId={projectId}
        assigneeId={assigneeId}
        date={date}
        workSessions={workSessions}
        tasksItems={tasksItems}
        project={project}
      />
    </Stack>
  );
};
