import { PopoverOpenButton } from "@msys/ui";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";

interface Props {
  warnings: React.ReactNode[];
}

export const WarningsIconButton = ({ warnings }: Props) => {
  const { t } = useTranslate("Global");
  return warnings.length > 0 ? (
    <PopoverOpenButton
      content={
        <Stack
          direction={"column"}
          spacing={1}
          padding={1}
          minWidth={280}
          maxWidth={320}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {warnings}
        </Stack>
      }
    >
      <IconButton
        color="warning"
        size="small"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Tooltip
          title={t(
            "{count, plural, =0 {No warnings} one {One warning} other {# warnings}}",
            {
              count: warnings.length,
            }
          )}
        >
          <WarningAmberIcon />
        </Tooltip>
      </IconButton>
    </PopoverOpenButton>
  ) : null;
};
