import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useTolgee } from "@tolgee/react";
import React from "react";
import { Capabilities, PermissionName } from "../../clients/graphqlTypes";
import { UserDataContext } from "./UserDataContext";
import {
  AuthUpdateUserMutationVariables,
  useAuthUpdateUserMutation,
  useMeQuery,
} from "./UserDataProvider.generated";

export const UserDataProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const tolgee = useTolgee(["language"]);

  const client = useApolloClient();
  const meQuery = useMeQuery({ client, variables: {} });
  const [updateUserMutation] = useAuthUpdateUserMutation({ client });

  const userData = meQuery.data?.viewer ?? null;

  const hasCapability = React.useCallback(
    (capability: Capabilities) => {
      if (!userData) return false;

      return userData.organisation.capabilities.includes(capability);
    },
    [userData]
  );

  const hasOrganisationPermission = React.useCallback(
    (permissionName: PermissionName) => {
      if (!userData) return false;

      return userData.organisationPermissions.includes(permissionName);
    },
    [userData]
  );

  const updateUser = React.useCallback(
    async function updateUser(variables: AuthUpdateUserMutationVariables) {
      await updateUserMutation({ variables });
      await meQuery.refetch();
    },
    [meQuery, updateUserMutation]
  );

  React.useEffect(() => {
    if (userData?.locale) {
      if (userData.locale !== tolgee.getLanguage()) {
        tolgee.changeLanguage(userData.locale);
      }
    }
  }, [tolgee, userData?.locale]);

  React.useEffect(() => {
    if (userData) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: userData.id });
        scope.setTag("organisation_id", userData.organisation.id);
      });
    } else {
      Sentry.configureScope(scope => {
        scope.setUser(null);
        scope.setTag("organisation_id", undefined);
      });
    }
  }, [userData]);

  React.useEffect(() => {
    function windowFocusHandler() {
      meQuery.refetch();
    }

    window.addEventListener("focus", windowFocusHandler);

    return () => {
      window.removeEventListener("focus", windowFocusHandler);
    };
  }, [meQuery]);

  const installerRole = "ORG_MEMBER";

  const appViewerRole = !userData
    ? null
    : userData.roles.every(role => role.internalName === installerRole)
      ? "INSTALLER"
      : "MAIN";

  return (
    <UserDataContext.Provider
      value={{
        isLoading: meQuery.loading && meQuery.data === undefined,
        currentUser: userData,
        appViewerRole,
        isOrganisationAdmin: userData?.isAdmin ?? false,
        hasCapability,
        hasOrganisationPermission,
        updateUser,
        refetchMe: meQuery.refetch,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
