import { Visibility } from "../../clients/graphqlTypes";
import { VisibilityButton as UIVisibilityButton } from "@msys/ui";

type Props = {
  visibility: Visibility;
  onChange: (visibility: Visibility) => void;
  disabled?: boolean;
  color?: React.ComponentProps<typeof UIVisibilityButton>["color"];
};

export const VisibilityButton = ({
  visibility,
  onChange,
  disabled = false,
  color,
}: Props) => {
  return (
    <UIVisibilityButton
      isVisible={visibility !== "hide"}
      color={color}
      disabled={disabled}
      onChange={() => onChange(visibility === "hide" ? "show" : "hide")}
    />
  );
};
