/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { OrganisationAvatarFragmentDoc } from '../../organisations/OrganisationAvatar.generated';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyDetailsBox_CrmComapnyFragment = { __typename: 'CrmCompany', id: string, title: string, number: string, description: string, website: string, email: any, skillset: Array<Types.Skill>, isCraftsmanOrganisation: boolean, isSupplierOrganisation: boolean, contactType: Types.CrmOrganisationContactType, abcCategory: Types.CrmOrganisationAbcCategory, systemTags: Array<string>, customTags: Array<string>, notices: string, isEmailValid?: boolean | null, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string }, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type ModifyCrmOrganisationAvatarMutationVariables = Types.Exact<{
  organisations: Array<Types.CrmOrganisationModifyInput>;
}>;


export type ModifyCrmOrganisationAvatarMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, url: string, title: string } }> } };

export type CrmCompanyDetailsData_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, title: string, description: string, website: string, email: any, skillset: Array<Types.Skill>, isCraftsmanOrganisation: boolean, isSupplierOrganisation: boolean, contactType: Types.CrmOrganisationContactType, abcCategory: Types.CrmOrganisationAbcCategory, systemTags: Array<string>, customTags: Array<string>, notices: string, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type CrmCompanyDetailsForm_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, title: string, description: string, email: any, isEmailValid?: boolean | null, website: string, contactType: Types.CrmOrganisationContactType, abcCategory: Types.CrmOrganisationAbcCategory, skillset: Array<Types.Skill>, systemTags: Array<string>, customTags: Array<string>, notices: string, isCraftsmanOrganisation: boolean, isSupplierOrganisation: boolean, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type CrmCompanyDetailsForm_ModifyCrmCompanyMutationVariables = Types.Exact<{
  crmCompanies: Array<Types.CrmOrganisationModifyInput>;
}>;


export type CrmCompanyDetailsForm_ModifyCrmCompanyMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, title: string, description: string, email: any, isEmailValid?: boolean | null, website: string, contactType: Types.CrmOrganisationContactType, abcCategory: Types.CrmOrganisationAbcCategory, skillset: Array<Types.Skill>, systemTags: Array<string>, customTags: Array<string>, notices: string, isCraftsmanOrganisation: boolean, isSupplierOrganisation: boolean, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }>, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, pickupAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null }> } };

export const CrmCompanyDetailsData_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyDetailsData_CrmCompany on CrmCompany {
  id
  title
  description
  website
  email
  phones {
    id
    type
    main
    number
  }
  skillset
  isCraftsmanOrganisation
  isSupplierOrganisation
  contactType
  abcCategory
  systemTags
  customTags
  notices
  billingAddress {
    id
    ...AddressDetails__Address
  }
  branchAddress {
    id
    ...AddressDetails__Address
  }
  pickupAddress {
    id
    ...AddressDetails__Address
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const CrmCompanyDetailsForm_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyDetailsForm_CrmCompany on CrmCompany {
  id
  title
  description
  email
  isEmailValid
  phones {
    id
    type
    main
    number
  }
  billingAddress {
    id
    ...AddressDetails__Address
  }
  branchAddress {
    id
    ...AddressDetails__Address
  }
  pickupAddress {
    id
    ...AddressDetails__Address
  }
  website
  contactType
  abcCategory
  skillset
  systemTags
  customTags
  notices
  isCraftsmanOrganisation
  isSupplierOrganisation
}
    ${AddressDetails__AddressFragmentDoc}`;
export const CrmCompanyDetailsBox_CrmComapnyFragmentDoc = gql`
    fragment CrmCompanyDetailsBox_CrmComapny on CrmCompany {
  id
  title
  number
  ...OrganisationAvatar
  ...CrmCompanyDetailsData_CrmCompany
  ...CrmCompanyDetailsForm_CrmCompany
}
    ${OrganisationAvatarFragmentDoc}
${CrmCompanyDetailsData_CrmCompanyFragmentDoc}
${CrmCompanyDetailsForm_CrmCompanyFragmentDoc}`;
export const ModifyCrmOrganisationAvatarDocument = gql`
    mutation ModifyCrmOrganisationAvatar($organisations: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $organisations) {
    crmOrganisations {
      id
      ...OrganisationAvatar
    }
  }
}
    ${OrganisationAvatarFragmentDoc}`;
export type ModifyCrmOrganisationAvatarMutationFn = Apollo.MutationFunction<ModifyCrmOrganisationAvatarMutation, ModifyCrmOrganisationAvatarMutationVariables>;

/**
 * __useModifyCrmOrganisationAvatarMutation__
 *
 * To run a mutation, you first call `useModifyCrmOrganisationAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmOrganisationAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmOrganisationAvatarMutation, { data, loading, error }] = useModifyCrmOrganisationAvatarMutation({
 *   variables: {
 *      organisations: // value for 'organisations'
 *   },
 * });
 */
export function useModifyCrmOrganisationAvatarMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmOrganisationAvatarMutation, ModifyCrmOrganisationAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmOrganisationAvatarMutation, ModifyCrmOrganisationAvatarMutationVariables>(ModifyCrmOrganisationAvatarDocument, options);
      }
export type ModifyCrmOrganisationAvatarMutationHookResult = ReturnType<typeof useModifyCrmOrganisationAvatarMutation>;
export type ModifyCrmOrganisationAvatarMutationResult = Apollo.MutationResult<ModifyCrmOrganisationAvatarMutation>;
export type ModifyCrmOrganisationAvatarMutationOptions = Apollo.BaseMutationOptions<ModifyCrmOrganisationAvatarMutation, ModifyCrmOrganisationAvatarMutationVariables>;
export const CrmCompanyDetailsForm_ModifyCrmCompanyDocument = gql`
    mutation CrmCompanyDetailsForm_ModifyCrmCompany($crmCompanies: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $crmCompanies) {
    crmOrganisations {
      id
      ...CrmCompanyDetailsForm_CrmCompany
    }
  }
}
    ${CrmCompanyDetailsForm_CrmCompanyFragmentDoc}`;
export type CrmCompanyDetailsForm_ModifyCrmCompanyMutationFn = Apollo.MutationFunction<CrmCompanyDetailsForm_ModifyCrmCompanyMutation, CrmCompanyDetailsForm_ModifyCrmCompanyMutationVariables>;

/**
 * __useCrmCompanyDetailsForm_ModifyCrmCompanyMutation__
 *
 * To run a mutation, you first call `useCrmCompanyDetailsForm_ModifyCrmCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyDetailsForm_ModifyCrmCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmCompanyDetailsFormModifyCrmCompanyMutation, { data, loading, error }] = useCrmCompanyDetailsForm_ModifyCrmCompanyMutation({
 *   variables: {
 *      crmCompanies: // value for 'crmCompanies'
 *   },
 * });
 */
export function useCrmCompanyDetailsForm_ModifyCrmCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CrmCompanyDetailsForm_ModifyCrmCompanyMutation, CrmCompanyDetailsForm_ModifyCrmCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmCompanyDetailsForm_ModifyCrmCompanyMutation, CrmCompanyDetailsForm_ModifyCrmCompanyMutationVariables>(CrmCompanyDetailsForm_ModifyCrmCompanyDocument, options);
      }
export type CrmCompanyDetailsForm_ModifyCrmCompanyMutationHookResult = ReturnType<typeof useCrmCompanyDetailsForm_ModifyCrmCompanyMutation>;
export type CrmCompanyDetailsForm_ModifyCrmCompanyMutationResult = Apollo.MutationResult<CrmCompanyDetailsForm_ModifyCrmCompanyMutation>;
export type CrmCompanyDetailsForm_ModifyCrmCompanyMutationOptions = Apollo.BaseMutationOptions<CrmCompanyDetailsForm_ModifyCrmCompanyMutation, CrmCompanyDetailsForm_ModifyCrmCompanyMutationVariables>;