import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { List, Paper, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { groupBy } from "lodash";
import React from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { Page } from "../../commons/layout/Page";
import { BreadcrumbItem } from "../../commons/layout/PageBreadcrumbs";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageHeader } from "../../commons/layout/PageHeader";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu";
import { CrmEmailListItem } from "../../features/crm/CrmEmailListItem";
import {
  ProjectEmailsSidebar_CrmMailMessageFragment,
  useProjectEmailsQuery,
} from "./ProjectEmails.generated";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
  tabs: React.ReactElement;
}

export const ProjectEmails = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
  tabs,
}: Props) => {
  const { t } = useTranslate(["ProjectPageTopbar"]);

  const client = useApolloClient();
  const query = useProjectEmailsQuery({
    client,
    variables: { projectId },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const emailCount =
    getDataOrNull(query.data?.crmEmailMessages)?.totalCount ?? 0;
  const emails =
    getDataOrNull(query.data?.crmEmailMessages)?.edges.map(edge => edge.node) ??
    [];

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Chats", { ns: "ProjectPageTopbar" }),
        to: `/projects/${projectId}/communication/emails`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  return (
    <Page
      subtitle={project?.title}
      title={t("Emails", { ns: "ProjectPageTopbar" })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={tabs}
    >
      <PageColumn $hasBackground $hasRightBorder $display="block">
        {project && <ProjectEmailsSidebar emails={emails} />}
      </PageColumn>
      <Outlet />
    </Page>
  );
};

const ProjectEmailsSidebar = ({
  emails,
}: {
  emails: ProjectEmailsSidebar_CrmMailMessageFragment[];
}) => {
  const match = useMatch(`/projects/:projectId/communication/emails/:emailId`);
  const emailId = match?.params?.emailId;

  const navigate = useNavigate();

  const threads = groupBy(emails, email => email.threadId);

  return (
    <Stack spacing={1}>
      {Object.entries(threads).map(([threadId, emails]) => (
        <Paper key={threadId}>
          <List disablePadding>
            {emails
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )
              .map((email, index, array) => (
                <CrmEmailListItem
                  key={email.id}
                  email={email}
                  selected={email.id === emailId}
                  isThreadRoot={index === 0}
                  divider={index < array.length - 1}
                  onClick={() => {
                    navigate(email.id);
                  }}
                />
              ))}
          </List>
        </Paper>
      ))}
    </Stack>
  );
};
