import { useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import SyncIcon from "@mui/icons-material/Sync";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useQuoteBulkUpdateProductsButton__QuoteBulkUpdateProductsMutation } from "./QuoteBulkUpdateProductsButton.generated";

interface Props {
  docId: string;
  projectId: string;
  refetchQueries?: string[];
}

export function QuoteBulkUpdateProductsButton({
  docId,
  projectId,
  refetchQueries,
}: Props) {
  const { t } = useTranslate("Quote");

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [quoteBulkUpdateProducts, { loading: quoteBulkUpdateProductsLoading }] =
    useQuoteBulkUpdateProductsButton__QuoteBulkUpdateProductsMutation({
      client,
      refetchQueries,
    });

  return (
    <MenuItemWithIcon
      icon={<SyncIcon />}
      onClick={async () => {
        try {
          await quoteBulkUpdateProducts({
            variables: {
              input: {
                docId: docId,
                projectId: projectId,
              },
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, {
              variant: "error",
            });
        }
      }}
      disabled={quoteBulkUpdateProductsLoading}
    >
      {t("Sync products")}
    </MenuItemWithIcon>
  );
}
