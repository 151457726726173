/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MassImportCrm_OrganisationSettingsFragment = { __typename: 'OrganisationSetting', id: string, crmOrganisationNumberMinLength: number, crmOrganisationNumberPrefix: string };

export type MassImportCrm_OrganisationSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MassImportCrm_OrganisationSettingsQuery = { organisationSettings: { __typename: 'OrganisationSetting', id: string, crmOrganisationNumberMinLength: number, crmOrganisationNumberPrefix: string } };

export type MassImportCrm_CrmCompanyFragment = { __typename: 'CrmCompaniesRecord', id: string, number: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type MassImportCrm_CrmCompaniesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  numbers: Array<Types.Scalars['String']['input']>;
}>;


export type MassImportCrm_CrmCompaniesQuery = { crmCompanies: { __typename: 'CrmCompanyConnection', edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, number: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } | { __typename: 'MissingPermissions' } };

export type ImportCrmOrganisationsMutationVariables = Types.Exact<{
  input: Array<Types.ImportCrmEntryInput>;
}>;


export type ImportCrmOrganisationsMutation = { importCrmOrganisations: { __typename: 'ImportCrmOrganisationsResult', ok: boolean } };

export const MassImportCrm_OrganisationSettingsFragmentDoc = gql`
    fragment MassImportCrm_OrganisationSettings on OrganisationSetting {
  id
  crmOrganisationNumberMinLength
  crmOrganisationNumberPrefix
}
    `;
export const MassImportCrm_CrmCompanyFragmentDoc = gql`
    fragment MassImportCrm_CrmCompany on CrmCompaniesRecord {
  id
  number
  title
  logo {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const MassImportCrm_OrganisationSettingsDocument = gql`
    query MassImportCrm_OrganisationSettings {
  organisationSettings {
    id
    ...MassImportCrm_OrganisationSettings
  }
}
    ${MassImportCrm_OrganisationSettingsFragmentDoc}`;

/**
 * __useMassImportCrm_OrganisationSettingsQuery__
 *
 * To run a query within a React component, call `useMassImportCrm_OrganisationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMassImportCrm_OrganisationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMassImportCrm_OrganisationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMassImportCrm_OrganisationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<MassImportCrm_OrganisationSettingsQuery, MassImportCrm_OrganisationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MassImportCrm_OrganisationSettingsQuery, MassImportCrm_OrganisationSettingsQueryVariables>(MassImportCrm_OrganisationSettingsDocument, options);
      }
export function useMassImportCrm_OrganisationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MassImportCrm_OrganisationSettingsQuery, MassImportCrm_OrganisationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MassImportCrm_OrganisationSettingsQuery, MassImportCrm_OrganisationSettingsQueryVariables>(MassImportCrm_OrganisationSettingsDocument, options);
        }
export function useMassImportCrm_OrganisationSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MassImportCrm_OrganisationSettingsQuery, MassImportCrm_OrganisationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MassImportCrm_OrganisationSettingsQuery, MassImportCrm_OrganisationSettingsQueryVariables>(MassImportCrm_OrganisationSettingsDocument, options);
        }
export type MassImportCrm_OrganisationSettingsQueryHookResult = ReturnType<typeof useMassImportCrm_OrganisationSettingsQuery>;
export type MassImportCrm_OrganisationSettingsLazyQueryHookResult = ReturnType<typeof useMassImportCrm_OrganisationSettingsLazyQuery>;
export type MassImportCrm_OrganisationSettingsSuspenseQueryHookResult = ReturnType<typeof useMassImportCrm_OrganisationSettingsSuspenseQuery>;
export type MassImportCrm_OrganisationSettingsQueryResult = Apollo.QueryResult<MassImportCrm_OrganisationSettingsQuery, MassImportCrm_OrganisationSettingsQueryVariables>;
export const MassImportCrm_CrmCompaniesDocument = gql`
    query MassImportCrm_CrmCompanies($limit: Int!, $numbers: [String!]!) {
  crmCompanies(limit: $limit, filters: {number: $numbers}) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          ...MassImportCrm_CrmCompany
        }
      }
    }
  }
}
    ${MassImportCrm_CrmCompanyFragmentDoc}`;

/**
 * __useMassImportCrm_CrmCompaniesQuery__
 *
 * To run a query within a React component, call `useMassImportCrm_CrmCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMassImportCrm_CrmCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMassImportCrm_CrmCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      numbers: // value for 'numbers'
 *   },
 * });
 */
export function useMassImportCrm_CrmCompaniesQuery(baseOptions: Apollo.QueryHookOptions<MassImportCrm_CrmCompaniesQuery, MassImportCrm_CrmCompaniesQueryVariables> & ({ variables: MassImportCrm_CrmCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MassImportCrm_CrmCompaniesQuery, MassImportCrm_CrmCompaniesQueryVariables>(MassImportCrm_CrmCompaniesDocument, options);
      }
export function useMassImportCrm_CrmCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MassImportCrm_CrmCompaniesQuery, MassImportCrm_CrmCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MassImportCrm_CrmCompaniesQuery, MassImportCrm_CrmCompaniesQueryVariables>(MassImportCrm_CrmCompaniesDocument, options);
        }
export function useMassImportCrm_CrmCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MassImportCrm_CrmCompaniesQuery, MassImportCrm_CrmCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MassImportCrm_CrmCompaniesQuery, MassImportCrm_CrmCompaniesQueryVariables>(MassImportCrm_CrmCompaniesDocument, options);
        }
export type MassImportCrm_CrmCompaniesQueryHookResult = ReturnType<typeof useMassImportCrm_CrmCompaniesQuery>;
export type MassImportCrm_CrmCompaniesLazyQueryHookResult = ReturnType<typeof useMassImportCrm_CrmCompaniesLazyQuery>;
export type MassImportCrm_CrmCompaniesSuspenseQueryHookResult = ReturnType<typeof useMassImportCrm_CrmCompaniesSuspenseQuery>;
export type MassImportCrm_CrmCompaniesQueryResult = Apollo.QueryResult<MassImportCrm_CrmCompaniesQuery, MassImportCrm_CrmCompaniesQueryVariables>;
export const ImportCrmOrganisationsDocument = gql`
    mutation ImportCrmOrganisations($input: [ImportCrmEntryInput!]!) {
  importCrmOrganisations(input: $input) {
    ok
  }
}
    `;
export type ImportCrmOrganisationsMutationFn = Apollo.MutationFunction<ImportCrmOrganisationsMutation, ImportCrmOrganisationsMutationVariables>;

/**
 * __useImportCrmOrganisationsMutation__
 *
 * To run a mutation, you first call `useImportCrmOrganisationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCrmOrganisationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCrmOrganisationsMutation, { data, loading, error }] = useImportCrmOrganisationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCrmOrganisationsMutation(baseOptions?: Apollo.MutationHookOptions<ImportCrmOrganisationsMutation, ImportCrmOrganisationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCrmOrganisationsMutation, ImportCrmOrganisationsMutationVariables>(ImportCrmOrganisationsDocument, options);
      }
export type ImportCrmOrganisationsMutationHookResult = ReturnType<typeof useImportCrmOrganisationsMutation>;
export type ImportCrmOrganisationsMutationResult = Apollo.MutationResult<ImportCrmOrganisationsMutation>;
export type ImportCrmOrganisationsMutationOptions = Apollo.BaseMutationOptions<ImportCrmOrganisationsMutation, ImportCrmOrganisationsMutationVariables>;