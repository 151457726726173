/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypePopularPropertyFiltersEditModalQueryVariables = Types.Exact<{
  productTypeId: Types.Scalars['ID']['input'];
}>;


export type ProductTypePopularPropertyFiltersEditModalQuery = { pimProductTypePropertyTypeFavourites: { __typename: 'MissingCapabilities' } | { __typename: 'PimProductTypePropertyTypeFavourites', productType: { __typename: 'PimProductType', id: string, key: string, label: string, typeSystem: Types.PimProductTypeSystemSystemType, propertyTypes: Array<{ __typename: 'PimProductTypePropertyType', id: string, propertyType: { __typename: 'PimPropertyType', id: string, key: string, label: string, kind: Types.PimPropertyTypeKind } }> }, propertyTypeFavourites: Array<{ __typename: 'PimPropertyTypeFavourite', id: string }> } };

export type ProductTypePopularPropertyFiltersEditModal_PimProductTypeFragment = { __typename: 'PimProductType', id: string, key: string, label: string, typeSystem: Types.PimProductTypeSystemSystemType };

export type ProductTypePopularPropertyFiltersEditModal_PimPropertyTypeFragment = { __typename: 'PimProductTypePropertyType', id: string, propertyType: { __typename: 'PimPropertyType', id: string, key: string, label: string, kind: Types.PimPropertyTypeKind } };

export type SetProductTypePopularPropertyFiltersMutationVariables = Types.Exact<{
  input: Types.PimSetProductTypePropertyTypeFavouritesInput;
}>;


export type SetProductTypePopularPropertyFiltersMutation = { pimSetProductTypePropertyTypeFavourites: { __typename: 'PimSetProductTypePropertyTypeFavouritesResult', productTypePropertyTypeFavourites: { __typename: 'PimProductTypePropertyTypeFavourites' } } };

export const ProductTypePopularPropertyFiltersEditModal_PimProductTypeFragmentDoc = gql`
    fragment ProductTypePopularPropertyFiltersEditModal_PimProductType on PimProductType {
  id
  key
  label
  typeSystem
}
    `;
export const ProductTypePopularPropertyFiltersEditModal_PimPropertyTypeFragmentDoc = gql`
    fragment ProductTypePopularPropertyFiltersEditModal_PimPropertyType on PimProductTypePropertyType {
  id
  propertyType {
    id
    key
    label
    kind
  }
}
    `;
export const ProductTypePopularPropertyFiltersEditModalDocument = gql`
    query ProductTypePopularPropertyFiltersEditModal($productTypeId: ID!) {
  pimProductTypePropertyTypeFavourites(productTypeId: $productTypeId) {
    ... on PimProductTypePropertyTypeFavourites {
      productType {
        id
        ...ProductTypePopularPropertyFiltersEditModal_PimProductType
        propertyTypes {
          ...ProductTypePopularPropertyFiltersEditModal_PimPropertyType
        }
      }
      propertyTypeFavourites {
        id
      }
    }
  }
}
    ${ProductTypePopularPropertyFiltersEditModal_PimProductTypeFragmentDoc}
${ProductTypePopularPropertyFiltersEditModal_PimPropertyTypeFragmentDoc}`;

/**
 * __useProductTypePopularPropertyFiltersEditModalQuery__
 *
 * To run a query within a React component, call `useProductTypePopularPropertyFiltersEditModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypePopularPropertyFiltersEditModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypePopularPropertyFiltersEditModalQuery({
 *   variables: {
 *      productTypeId: // value for 'productTypeId'
 *   },
 * });
 */
export function useProductTypePopularPropertyFiltersEditModalQuery(baseOptions: Apollo.QueryHookOptions<ProductTypePopularPropertyFiltersEditModalQuery, ProductTypePopularPropertyFiltersEditModalQueryVariables> & ({ variables: ProductTypePopularPropertyFiltersEditModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypePopularPropertyFiltersEditModalQuery, ProductTypePopularPropertyFiltersEditModalQueryVariables>(ProductTypePopularPropertyFiltersEditModalDocument, options);
      }
export function useProductTypePopularPropertyFiltersEditModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypePopularPropertyFiltersEditModalQuery, ProductTypePopularPropertyFiltersEditModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypePopularPropertyFiltersEditModalQuery, ProductTypePopularPropertyFiltersEditModalQueryVariables>(ProductTypePopularPropertyFiltersEditModalDocument, options);
        }
export function useProductTypePopularPropertyFiltersEditModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductTypePopularPropertyFiltersEditModalQuery, ProductTypePopularPropertyFiltersEditModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductTypePopularPropertyFiltersEditModalQuery, ProductTypePopularPropertyFiltersEditModalQueryVariables>(ProductTypePopularPropertyFiltersEditModalDocument, options);
        }
export type ProductTypePopularPropertyFiltersEditModalQueryHookResult = ReturnType<typeof useProductTypePopularPropertyFiltersEditModalQuery>;
export type ProductTypePopularPropertyFiltersEditModalLazyQueryHookResult = ReturnType<typeof useProductTypePopularPropertyFiltersEditModalLazyQuery>;
export type ProductTypePopularPropertyFiltersEditModalSuspenseQueryHookResult = ReturnType<typeof useProductTypePopularPropertyFiltersEditModalSuspenseQuery>;
export type ProductTypePopularPropertyFiltersEditModalQueryResult = Apollo.QueryResult<ProductTypePopularPropertyFiltersEditModalQuery, ProductTypePopularPropertyFiltersEditModalQueryVariables>;
export const SetProductTypePopularPropertyFiltersDocument = gql`
    mutation SetProductTypePopularPropertyFilters($input: PimSetProductTypePropertyTypeFavouritesInput!) {
  pimSetProductTypePropertyTypeFavourites(input: $input) {
    productTypePropertyTypeFavourites {
      __typename
    }
  }
}
    `;
export type SetProductTypePopularPropertyFiltersMutationFn = Apollo.MutationFunction<SetProductTypePopularPropertyFiltersMutation, SetProductTypePopularPropertyFiltersMutationVariables>;

/**
 * __useSetProductTypePopularPropertyFiltersMutation__
 *
 * To run a mutation, you first call `useSetProductTypePopularPropertyFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProductTypePopularPropertyFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProductTypePopularPropertyFiltersMutation, { data, loading, error }] = useSetProductTypePopularPropertyFiltersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetProductTypePopularPropertyFiltersMutation(baseOptions?: Apollo.MutationHookOptions<SetProductTypePopularPropertyFiltersMutation, SetProductTypePopularPropertyFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProductTypePopularPropertyFiltersMutation, SetProductTypePopularPropertyFiltersMutationVariables>(SetProductTypePopularPropertyFiltersDocument, options);
      }
export type SetProductTypePopularPropertyFiltersMutationHookResult = ReturnType<typeof useSetProductTypePopularPropertyFiltersMutation>;
export type SetProductTypePopularPropertyFiltersMutationResult = Apollo.MutationResult<SetProductTypePopularPropertyFiltersMutation>;
export type SetProductTypePopularPropertyFiltersMutationOptions = Apollo.BaseMutationOptions<SetProductTypePopularPropertyFiltersMutation, SetProductTypePopularPropertyFiltersMutationVariables>;