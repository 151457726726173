import { useApolloClient } from "@apollo/client";
import { FC } from "react";
import { use_3d_RequestAddProductDataUrlMutation } from "./_3d_DataUploader.generated";

export interface UploadFile {
  filename: string;
}

export const ThreeDDataUploader: FC<{
  innerRef: any;
  onUploadFile: (uploadFile: UploadFile) => void;
  onCancel?: () => void;
  onStart?: () => void;
  onError?: () => void;
  productId: string;
}> = props => {
  const client = useApolloClient();
  const showThreeD = import.meta.env.VITE_THREED_PLANNER_SHOW === "true";

  const [_3d_requestAddProductDataUrlMutation] =
    use_3d_RequestAddProductDataUrlMutation({
      client,
    });

  return (
    <>
      {showThreeD && (
        <input
          type="file"
          style={{ display: "none" }}
          multiple={false}
          accept={".zip"}
          ref={props.innerRef}
          onChange={async e => {
            console.info(e.target.files);
            if (e.target.files && e.target.files[0]) {
              if (props.onStart) props.onStart();

              try {
                let file = e.target.files[0];

                let filename = file.name;

                let _3d_requestAddProductDataUrlResult =
                  await _3d_requestAddProductDataUrlMutation({
                    variables: {
                      input: {
                        productId: props.productId,
                        filename: filename,
                      },
                    },
                  });

                await fetch(
                  _3d_requestAddProductDataUrlResult.data!
                    ._3d_requestAddProductDataUrl.uploadUrl,
                  { method: "PUT", body: file }
                ).catch(err => {
                  console.info(err);
                });

                if (props.innerRef.current) {
                  props.innerRef.current.value = "";
                }
                props.onUploadFile({ filename });
              } catch (err) {
                if (props.onError) props.onError();
                throw err;
              }
            } else {
              if (props.onCancel) props.onCancel();
            }
          }}
        />
      )}
    </>
  );
};
