import { useTranslate } from "@tolgee/react";
import React from "react";
import { TplApplicableFor } from "../../../../clients/graphqlTypes";

const DOCUMENTS: TplApplicableFor[] = ["QUOTE", "REQUIREMENT", "BUILDING"];

export function useTemplateAvaliableFor() {
  const { t } = useTranslate(["Quote", "Requirements", "Buildings"]);

  const labels: Record<TplApplicableFor, string> = React.useMemo(
    () => ({
      QUOTE: t("Quote", { ns: "Quote" }),
      REQUIREMENT: t("Requirement", { ns: "Requirements" }),
      BUILDING: t("Building", { ns: "Buildings" }),
    }),
    [t]
  );

  const options = React.useMemo(
    () => DOCUMENTS.map(option => ({ value: option, label: labels[option] })),
    [labels]
  );

  return { documents: DOCUMENTS, options, labels };
}
