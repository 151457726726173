import { gql, useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { uniqueId } from "lodash";
import React from "react";
// import { Stack } from "../../../commons/layout/Stack";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import {
  SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariantFragment,
  useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation,
} from "./SupplierDiscountGroupVariantCreateModal.generated";

type FormValues = {
  title: string;
};

interface Props {
  title?: string;
  supplierId: string;
  refetchQueries?: string[];
  handleClose: () => void;
  handleComplete?: (
    supplierDiscountGroupVariant: SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariantFragment
  ) => void;
}

export function SupplierDiscountGroupVariantCreateModal({
  title,
  supplierId,
  refetchQueries,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["SupplierDiscountGroups", "Global"]);
  const client = useApolloClient();

  const [createSupplierDiscountGroupVariantMutation] =
    useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation(
      { client, refetchQueries }
    );

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Modal
      title={
        title ??
        t("Create supplier discount group variant", {
          ns: "SupplierDiscountGroups",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          buttonProps: {
            form: formId,
            type: "submit",
          },
        },
      ]}
    >
      <Formik<FormValues>
        initialValues={{ title: "" }}
        validationSchema={Yup.object({ title: Yup.string().trim().required() })}
        onSubmit={async values => {
          const result = await createSupplierDiscountGroupVariantMutation({
            variables: { input: { supplierId, title: values.title } },
          });

          handleComplete?.(
            result.data!.pimCreateSupplierDiscountGroupVariant
              .supplierDiscountGroupVariant
          );

          handleClose();
        }}
      >
        <Form id={formId}>
          <Field
            name="title"
            label={t("Title", { ns: "SupplierDiscountGroups" })}
            component={TextField}
          />
        </Form>
      </Formik>
    </Modal>
  );
}
