import type { GridGroupNode, GridRowId } from "@mui/x-data-grid-premium";
import type { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import React from "react";
import { useLocalStorageAsRef } from "../hooks/useLocalStorage";

type ExpandedState = {
  [key: GridRowId]: boolean;
};

export function useDataGridExpandedStore(
  apiRef: React.MutableRefObject<GridApiPremium>,
  name: string
) {
  const [expandedState, setExpandedState] = useLocalStorageAsRef<ExpandedState>(
    `msys-expanded-store-${name}`,
    {}
  );

  const subscribed = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (apiRef?.current?.subscribeEvent && !subscribed.current) {
      subscribed.current = true;
      apiRef.current.subscribeEvent?.("rowExpansionChange", node => {
        setExpandedState({
          ...expandedState.current,
          [node.id]: node.childrenExpanded ?? false,
        });
      });
    }
  }, [
    apiRef,
    apiRef?.current?.subscribeEvent,
    expandedState,
    setExpandedState,
  ]);

  const isGroupExpanded = React.useCallback(
    (node: GridGroupNode) => expandedState.current[node.id] ?? false,
    [expandedState]
  );

  return { isGroupExpanded };
}
