import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { THREED_PLANNER_SHOW } from "../../../environment";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { EntityNumber } from "../../commons/EntityNumber";
import {
  LayoutView,
  ToggleLayoutViewButton,
  ToggleLayoutViewButtonGroup,
} from "../../commons/button/ToggleLayoutViewButtonGroup";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../commons/layout/PageSubHeader";
import { useHeaderBox_ModifyItemInfoMutation } from "../doc-items/boxes/HeaderBox.generated";
import { S4HanaQuoteMenuButton } from "../sap-s4-hana/S4HanaQuoteMenuButton";
import { QuoteMenu } from "./QuoteMenu";
import { QuoteSapStatusChip } from "./QuoteSapStatusChip";
import { QuoteSettingsModal } from "./QuoteSettingsModal";
import { QuoteStatusChip } from "./QuoteStatusBadge";
import {
  QuoteSubHeader_ProjectFragment,
  QuoteSubHeader_QuoteFragment,
} from "./QuoteSubHeader.generated";
import { DownloadQuoteAsPdfIconButton } from "./buttons/DownloadQuoteAsPdfIconButton";

interface Props {
  project: QuoteSubHeader_ProjectFragment;
  quote: QuoteSubHeader_QuoteFragment;
  activeView: LayoutView | null;
  canEdit: boolean;
  isReadOnly: boolean;
  pathToDocList: string;
  pathToDoc: string;
  onUpdateDataRefetchQueries?: string[];
  hasSapS4HanaIntegration: boolean;
  expandedItemIds: string[] | undefined;
}

export const QuoteSubHeader = ({
  project,
  quote,
  activeView,
  canEdit,
  isReadOnly,
  pathToDocList,
  pathToDoc,
  onUpdateDataRefetchQueries,
  hasSapS4HanaIntegration,
  isHeaderVisible,
  setHeaderVisible,
  expandedItemIds,
}: Props & PageSubHeaderInjectedProps) => {
  const navigate = useNavigate();
  const { t } = useTranslate(["Global", "Quote"]);

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyItemInfo] = useHeaderBox_ModifyItemInfoMutation({
    client,
    refetchQueries: onUpdateDataRefetchQueries,
    awaitRefetchQueries: true,
  });
  const handleTitleChange = async (title: string) => {
    await modifyItemInfo({
      variables: {
        input: {
          itemId: quote.rootItem.id,
          docId: quote.id,
          projectId: project.id,
          values: {
            title,
          },
        },
      },
    });
  };

  return (
    <PageSubHeader
      title={quote.title}
      hideTitleOnMobile
      handleTitleChange={
        canEdit && !isReadOnly
          ? async newValue => {
              await handleTitleChange(newValue);
              enqueueSnackbar(
                t("Title updated", {
                  ns: "Global",
                })
              );
            }
          : undefined
      }
      titleEditTooltip={
        canEdit && !isReadOnly
          ? t("Rename", {
              ns: "Global",
            })
          : undefined
      }
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
      status={
        <Stack direction="row" alignItems="center" spacing={1}>
          <QuoteStatusChip quote={quote} />
          {hasSapS4HanaIntegration && (
            <RestrictedByDocumentPermissionWithDebug
              permission="MANAGE_QUOTES"
              document={quote}
              otherwise={
                <QuoteSapStatusChip
                  projectId={project.id}
                  quoteId={quote.id}
                  canEdit={false}
                />
              }
            >
              <QuoteSapStatusChip projectId={project.id} quoteId={quote.id} />
            </RestrictedByDocumentPermissionWithDebug>
          )}
        </Stack>
      }
      entityNumber={<EntityNumber number={quote.number} />}
      actionMenu={
        canEdit ? (
          <QuoteMenu
            quote={quote}
            project={project}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
          />
        ) : undefined
      }
      actionButtons={
        <RestrictedByDocumentPermissionWithDebug
          permission="READ_QUOTES"
          document={quote}
        >
          <DownloadQuoteAsPdfIconButton
            doc={quote}
            docId={quote.id}
            projectId={project.id}
          />
        </RestrictedByDocumentPermissionWithDebug>
      }
      rightButtons={
        <Stack direction={"row"} alignItems="center" spacing={1}>
          <ToggleLayoutViewButtonGroup
            activeView={activeView}
            setActiveView={view =>
              navigate(view === "tree" ? pathToDoc : `${pathToDoc}/${view}`)
            }
          >
            <RestrictedByCapabilityWithDebug capability="QUOTING">
              <RestrictedByDocumentPermissionWithDebug
                permission="MANAGE_QUOTES"
                document={quote}
              >
                <ToggleLayoutViewButton viewType="tree" />
              </RestrictedByDocumentPermissionWithDebug>
            </RestrictedByCapabilityWithDebug>
            <RestrictedByCapabilityWithDebug capability="QUOTING">
              <RestrictedByDocumentPermissionWithDebug
                permission="MANAGE_QUOTES"
                document={quote}
              >
                <ToggleLayoutViewButton viewType="calculation" />
              </RestrictedByDocumentPermissionWithDebug>
            </RestrictedByCapabilityWithDebug>
            <RestrictedByDocumentPermissionWithDebug
              permission="READ_QUOTES"
              document={quote}
            >
              <ToggleLayoutViewButton viewType="preview" />
            </RestrictedByDocumentPermissionWithDebug>
            {THREED_PLANNER_SHOW === "true" && (
              <RestrictedByDocumentPermissionWithDebug
                permission="READ_QUOTES"
                document={quote}
              >
                <ToggleLayoutViewButton viewType="3d" />
              </RestrictedByDocumentPermissionWithDebug>
            )}
          </ToggleLayoutViewButtonGroup>
          {hasSapS4HanaIntegration && (
            <RestrictedByDocumentPermissionWithDebug
              permission="MANAGE_QUOTES"
              document={quote}
            >
              <S4HanaQuoteMenuButton
                quoteId={quote.id}
                projectId={project.id}
                expandedItemIds={expandedItemIds}
                docViewerPermissions={quote.viewerPermissions}
              />
            </RestrictedByDocumentPermissionWithDebug>
          )}
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_QUOTES_SETTINGS"
            document={quote}
          >
            <ModalOpenButton
              Modal={QuoteSettingsModal}
              modalProps={{
                projectId: project.id,
                quoteId: quote.id,
                canEdit: true,
              }}
            >
              <Tooltip title={t("Settings", { ns: "Global" })}>
                <IconButton size="small" color="primary">
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </ModalOpenButton>
          </RestrictedByDocumentPermissionWithDebug>
        </Stack>
      }
    />
  );
};
