import MoveDownIcon from "@mui/icons-material/MoveDown";
import Storefront from "@mui/icons-material/Storefront";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useFeature } from "../../../common/FeatureFlags";
import { RestrictedByCapability } from "../../auth/RestrictedByCapability";
import { PageNotFound } from "../../commons/PageNotFound";
import { PageTopbarItem } from "../../commons/layout/Page";
import {
  OpportunityIcon,
  OpportunityPastIcon,
} from "../../features/opportunities/OpportunityIcon";
import { OpportunitiesArticleList } from "./OpportunitiesArticleList";
import { OpportunitiesArticleLists } from "./OpportunitiesArticleLists";
import { OpportunitiesCurrent } from "./OpportunitiesCurrent";
import { OpportunitiesMarketplace } from "./OpportunitiesMarketplace";
import { OpportunitiesPast } from "./OpportunitiesPast";
import { RequestOverview } from "./OpportunitiesRequestOverview";

type PageSubmenuItem = PageTopbarItem;

export const OpportunitiesRoutes = () => {
  const navigate = useNavigate();
  const { t } = useTranslate(["ProjectPageTopbar", "Opportunities"]);
  const areArticleListsEnabled = useFeature("ArticleLists");
  const isMarketplaceEnabled = useFeature("Marketplace");

  const submenuItems = React.useMemo(() => {
    const items: PageSubmenuItem[] = [
      {
        name: "opportunities-current",
        label: t("Current opportunities", {
          ns: "Opportunities",
        }),
        path: `/opportunities/current`,
        icon: <OpportunityIcon />,
      },
      {
        name: "opportunities-past",
        label: t("Past opportunities", {
          ns: "Opportunities",
        }),
        path: `/opportunities/past`,
        icon: <OpportunityPastIcon />,
      },
      {
        name: "opportunities-marketplace",
        label: t("Marketplace", {
          ns: "ProjectPageTopbar",
        }),
        path: `/opportunities/marketplace`,
        icon: <Storefront />,
        isHidden: !isMarketplaceEnabled,
        capability: "MARKETPLACE",
      },
      {
        name: "opportunities-article-lists",
        label: t("Article lists", {
          ns: "Opportunities",
        }),
        path: `/opportunities/article-lists`,
        icon: <MoveDownIcon />,
        isHidden: !areArticleListsEnabled,
      },
    ];

    return items.filter(item => !item.isHidden);
  }, [areArticleListsEnabled, isMarketplaceEnabled, t]);

  return (
    <Routes>
      <Route index element={<Navigate to="current" replace />} />
      <Route
        path={"current"}
        element={<OpportunitiesCurrent submenuItems={submenuItems} />}
      />
      <Route
        path={"past"}
        element={<OpportunitiesPast submenuItems={submenuItems} />}
      />
      <Route
        path={"marketplace"}
        element={
          <RestrictedByCapability capability="MARKETPLACE">
            <OpportunitiesMarketplace submenuItems={submenuItems} />
          </RestrictedByCapability>
        }
      >
        <Route
          path={":opportunityId"}
          element={
            <RequestOverview pathToPage={"/opportunities/marketplace"} />
          }
        />
      </Route>
      <Route
        path={"article-lists"}
        element={<OpportunitiesArticleLists submenuItems={submenuItems} />}
      >
        <Route
          path={":opportunityId"}
          element={
            <OpportunitiesArticleList
              pathToPage={"/opportunities/article-lists"}
              handleComplete={(projectId, quoteId) =>
                navigate(`/projects/${projectId}/quotes/${quoteId}`)
              }
            />
          }
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
