/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductEditHeaderBox__PimBrandFragment = { __typename: 'PimBrandRef', id: string, title: string };

export type ProductEditHeaderBox__PimSearchBrandsQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit: Types.Scalars['Int']['input'];
}>;


export type ProductEditHeaderBox__PimSearchBrandsQuery = { pimSearchBrands: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchBrandsResult', brands: Array<{ __typename: 'PimBrandRef', id: string, title: string }> } };

export type ProductEditHeaderBox__PimBrandLineFragment = { __typename: 'PimBrandLineRef', id: string, title: string, brand: { __typename: 'PimBrandRef', id: string, title: string } };

export type ProductEditHeaderBox__PimSearchBrandLinesQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  brandIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  limit: Types.Scalars['Int']['input'];
}>;


export type ProductEditHeaderBox__PimSearchBrandLinesQuery = { pimSearchBrandLines: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchBrandLinesResult', brandLines: Array<{ __typename: 'PimBrandLineRef', id: string, title: string, brand: { __typename: 'PimBrandRef', id: string, title: string } }> } };

export type ProductEditHeaderBox__PimProductTypeFragment = { __typename: 'PimProductType', id: string, key: string, label: string, typeSystem: Types.PimProductTypeSystemSystemType };

export const ProductEditHeaderBox__PimBrandFragmentDoc = gql`
    fragment ProductEditHeaderBox__PimBrand on PimBrandRef {
  id
  title
}
    `;
export const ProductEditHeaderBox__PimBrandLineFragmentDoc = gql`
    fragment ProductEditHeaderBox__PimBrandLine on PimBrandLineRef {
  id
  title
  brand {
    ...ProductEditHeaderBox__PimBrand
  }
}
    ${ProductEditHeaderBox__PimBrandFragmentDoc}`;
export const ProductEditHeaderBox__PimProductTypeFragmentDoc = gql`
    fragment ProductEditHeaderBox__PimProductType on PimProductType {
  id
  key
  label
  typeSystem
}
    `;
export const ProductEditHeaderBox__PimSearchBrandsDocument = gql`
    query ProductEditHeaderBox__PimSearchBrands($searchTerm: String, $limit: Int!) {
  pimSearchBrands(searchTerm: $searchTerm, limit: $limit) {
    ... on PimSearchBrandsResult {
      brands {
        ...ProductEditHeaderBox__PimBrand
      }
    }
  }
}
    ${ProductEditHeaderBox__PimBrandFragmentDoc}`;

/**
 * __useProductEditHeaderBox__PimSearchBrandsQuery__
 *
 * To run a query within a React component, call `useProductEditHeaderBox__PimSearchBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductEditHeaderBox__PimSearchBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductEditHeaderBox__PimSearchBrandsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductEditHeaderBox__PimSearchBrandsQuery(baseOptions: Apollo.QueryHookOptions<ProductEditHeaderBox__PimSearchBrandsQuery, ProductEditHeaderBox__PimSearchBrandsQueryVariables> & ({ variables: ProductEditHeaderBox__PimSearchBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductEditHeaderBox__PimSearchBrandsQuery, ProductEditHeaderBox__PimSearchBrandsQueryVariables>(ProductEditHeaderBox__PimSearchBrandsDocument, options);
      }
export function useProductEditHeaderBox__PimSearchBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductEditHeaderBox__PimSearchBrandsQuery, ProductEditHeaderBox__PimSearchBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductEditHeaderBox__PimSearchBrandsQuery, ProductEditHeaderBox__PimSearchBrandsQueryVariables>(ProductEditHeaderBox__PimSearchBrandsDocument, options);
        }
export function useProductEditHeaderBox__PimSearchBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductEditHeaderBox__PimSearchBrandsQuery, ProductEditHeaderBox__PimSearchBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductEditHeaderBox__PimSearchBrandsQuery, ProductEditHeaderBox__PimSearchBrandsQueryVariables>(ProductEditHeaderBox__PimSearchBrandsDocument, options);
        }
export type ProductEditHeaderBox__PimSearchBrandsQueryHookResult = ReturnType<typeof useProductEditHeaderBox__PimSearchBrandsQuery>;
export type ProductEditHeaderBox__PimSearchBrandsLazyQueryHookResult = ReturnType<typeof useProductEditHeaderBox__PimSearchBrandsLazyQuery>;
export type ProductEditHeaderBox__PimSearchBrandsSuspenseQueryHookResult = ReturnType<typeof useProductEditHeaderBox__PimSearchBrandsSuspenseQuery>;
export type ProductEditHeaderBox__PimSearchBrandsQueryResult = Apollo.QueryResult<ProductEditHeaderBox__PimSearchBrandsQuery, ProductEditHeaderBox__PimSearchBrandsQueryVariables>;
export const ProductEditHeaderBox__PimSearchBrandLinesDocument = gql`
    query ProductEditHeaderBox__PimSearchBrandLines($searchTerm: String, $brandIds: [ID!], $limit: Int!) {
  pimSearchBrandLines(
    searchTerm: $searchTerm
    filter: {brandIds: $brandIds}
    limit: $limit
  ) {
    ... on PimSearchBrandLinesResult {
      brandLines {
        ...ProductEditHeaderBox__PimBrandLine
      }
    }
  }
}
    ${ProductEditHeaderBox__PimBrandLineFragmentDoc}`;

/**
 * __useProductEditHeaderBox__PimSearchBrandLinesQuery__
 *
 * To run a query within a React component, call `useProductEditHeaderBox__PimSearchBrandLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductEditHeaderBox__PimSearchBrandLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductEditHeaderBox__PimSearchBrandLinesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      brandIds: // value for 'brandIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductEditHeaderBox__PimSearchBrandLinesQuery(baseOptions: Apollo.QueryHookOptions<ProductEditHeaderBox__PimSearchBrandLinesQuery, ProductEditHeaderBox__PimSearchBrandLinesQueryVariables> & ({ variables: ProductEditHeaderBox__PimSearchBrandLinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductEditHeaderBox__PimSearchBrandLinesQuery, ProductEditHeaderBox__PimSearchBrandLinesQueryVariables>(ProductEditHeaderBox__PimSearchBrandLinesDocument, options);
      }
export function useProductEditHeaderBox__PimSearchBrandLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductEditHeaderBox__PimSearchBrandLinesQuery, ProductEditHeaderBox__PimSearchBrandLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductEditHeaderBox__PimSearchBrandLinesQuery, ProductEditHeaderBox__PimSearchBrandLinesQueryVariables>(ProductEditHeaderBox__PimSearchBrandLinesDocument, options);
        }
export function useProductEditHeaderBox__PimSearchBrandLinesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductEditHeaderBox__PimSearchBrandLinesQuery, ProductEditHeaderBox__PimSearchBrandLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductEditHeaderBox__PimSearchBrandLinesQuery, ProductEditHeaderBox__PimSearchBrandLinesQueryVariables>(ProductEditHeaderBox__PimSearchBrandLinesDocument, options);
        }
export type ProductEditHeaderBox__PimSearchBrandLinesQueryHookResult = ReturnType<typeof useProductEditHeaderBox__PimSearchBrandLinesQuery>;
export type ProductEditHeaderBox__PimSearchBrandLinesLazyQueryHookResult = ReturnType<typeof useProductEditHeaderBox__PimSearchBrandLinesLazyQuery>;
export type ProductEditHeaderBox__PimSearchBrandLinesSuspenseQueryHookResult = ReturnType<typeof useProductEditHeaderBox__PimSearchBrandLinesSuspenseQuery>;
export type ProductEditHeaderBox__PimSearchBrandLinesQueryResult = Apollo.QueryResult<ProductEditHeaderBox__PimSearchBrandLinesQuery, ProductEditHeaderBox__PimSearchBrandLinesQueryVariables>;