/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PlanningAbsenceFragmentDoc } from '../../main-routes/planning/PlanningAbsences.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModifyAbsenceMutationVariables = Types.Exact<{
  input: Types.ModifyAbsenceInput;
}>;


export type ModifyAbsenceMutation = { modifyAbsence: { __typename: 'ModifyAbsenceResult', absence: { __typename: 'Absence', id: string, from: any, till: any, note: string, reason: Types.AbsenceReason, who: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } } };


export const ModifyAbsenceDocument = gql`
    mutation ModifyAbsence($input: ModifyAbsenceInput!) {
  modifyAbsence(input: $input) {
    absence {
      id
      ...PlanningAbsence
    }
  }
}
    ${PlanningAbsenceFragmentDoc}`;
export type ModifyAbsenceMutationFn = Apollo.MutationFunction<ModifyAbsenceMutation, ModifyAbsenceMutationVariables>;

/**
 * __useModifyAbsenceMutation__
 *
 * To run a mutation, you first call `useModifyAbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyAbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyAbsenceMutation, { data, loading, error }] = useModifyAbsenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyAbsenceMutation(baseOptions?: Apollo.MutationHookOptions<ModifyAbsenceMutation, ModifyAbsenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyAbsenceMutation, ModifyAbsenceMutationVariables>(ModifyAbsenceDocument, options);
      }
export type ModifyAbsenceMutationHookResult = ReturnType<typeof useModifyAbsenceMutation>;
export type ModifyAbsenceMutationResult = Apollo.MutationResult<ModifyAbsenceMutation>;
export type ModifyAbsenceMutationOptions = Apollo.BaseMutationOptions<ModifyAbsenceMutation, ModifyAbsenceMutationVariables>;