import { useScreenWidth } from "@msys/ui";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { IconButton, IconButtonProps } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

const BOOKMARK_COLOR = "#ffaa2c";

interface Props {
  isBookmarked: boolean;
  size?: IconButtonProps["size"];
  style?: React.CSSProperties;
  handleClick: () => void | Promise<void>;
}

export const BookmarkButton = ({
  isBookmarked,
  size: sizeProp,
  style,
  handleClick,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMinTablet } = useScreenWidth();
  const [loading, setLoading] = React.useState(false);
  const size = sizeProp ?? (isMinTablet ? "medium" : "small");

  return (
    <IconButton
      size={size}
      disabled={loading}
      onClick={async event => {
        event.preventDefault();
        event.stopPropagation();
        try {
          setLoading(true);
          await handleClick();
        } catch (e) {
          if (e instanceof Error)
            enqueueSnackbar(e.message, { variant: "error" });
        } finally {
          setLoading(false);
        }
      }}
      style={style}
    >
      {isBookmarked ? (
        <BookmarkIcon style={{ color: BOOKMARK_COLOR }} fontSize="small" />
      ) : (
        <BookmarkBorderIcon fontSize="small" />
      )}
    </IconButton>
  );
};
