import { useLocalStorageAsState } from "@msys/ui";
import React from "react";

export const ALL_KEY = "__all__";

export const defaultAllClosedValue = {};
export const defaultAllOpenedValue = { [ALL_KEY]: true };

export type ExpandedStore = {
  isItemExpanded: (itemId: string, defaultExpanded?: boolean) => boolean;
  setItemExpanded: (itemId: string, expanded: boolean) => void;
  setExpandedState: (state: { [itemId: string]: boolean }) => void;
  setAllItemsExpanded: (expanded: boolean) => void;
  areAllItemsExpanded: boolean;
  expandedItemIds: string[] | undefined;
};

export type StoreType =
  | "quote-template"
  | "building"
  | "requirement"
  | "quote"
  | "tasks"
  | "invoice"
  | "decisions";

export function useEnrichExpandedStoreWithParentPathIds<
  I extends { id: string; pathForPdf: string }
>(
  expandedStore: ExpandedStore,
  items: I[],
  selectedItemId: string | null,
  ready: boolean
) {
  const checked = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (ready && !checked.current) {
      // we check only once after loading is done
      checked.current = true;
      if (selectedItemId) {
        const item = items.find(i => i.id === selectedItemId);
        if (item) {
          const itemParentPathIds = items
            .filter(
              i => item.pathForPdf.startsWith(i.pathForPdf) && i.id !== item.id
            )
            .map(i => i.id);
          if (itemParentPathIds.some(id => !expandedStore.isItemExpanded(id))) {
            // not all parent path item ids are expanded - need expand them
            const newExpandedState = itemParentPathIds.reduce<{
              [key: string]: boolean;
            }>((acc, cur) => ({ ...acc, [cur]: true }), {});
            expandedStore.setExpandedState(newExpandedState);
          }
        }
      }
    }
  }, [expandedStore, items, selectedItemId, ready]);
}

export function useExpandedStoreWithLocalStorage(
  storeType: StoreType,
  docId: string,
  selectedItemId?: string | null
): ExpandedStore {
  const [value, setValue] = useLocalStorageAsState<{ [key: string]: boolean }>(
    `msys-expanded-${storeType}-${docId}`,
    defaultAllClosedValue,
    true,
    {
      processOnGet: selectedItemId
        ? (expandedState: { [key: string]: boolean }) => {
            if (expandedState[selectedItemId]) return expandedState;
            return { ...expandedState, [selectedItemId]: true };
          }
        : undefined,
    }
  );

  const store = useExpandedStore(value, setValue);

  return store;
}

export function useExpandedStoreWithComponentState(): ExpandedStore {
  const [value, setValue] = React.useState<{
    [key: string]: boolean;
  }>({});

  const store = useExpandedStore(value, setValue);

  return store;
}

interface Value {
  [key: string]: boolean;
}
interface SetValue {
  (newValue: Value): void;
}

function useExpandedStore(value: Value, setValue: SetValue) {
  return React.useMemo((): ExpandedStore => {
    return {
      isItemExpanded: (itemId: string) => {
        return value[ALL_KEY] || (value[itemId] ?? false);
      },
      setItemExpanded: (itemId: string, expanded: boolean) => {
        // FIXME
        if (!expanded)
          throw new Error("Not allowed to collapse a single item directly");
        // everything is already expanded
        if (value[ALL_KEY]) return;
        const newValue = { ...value, [itemId]: expanded };
        setValue(newValue);
      },
      setExpandedState: (state: { [itemId: string]: boolean }) => {
        const newValue = { ...value, ...state };
        setValue(newValue);
      },
      areAllItemsExpanded: Boolean(value[ALL_KEY]),
      setAllItemsExpanded: (expanded: boolean) => {
        setValue({ [ALL_KEY]: expanded });
      },
      expandedItemIds: value[ALL_KEY]
        ? undefined
        : Object.entries(value)
            .filter(([key, value]) => value)
            .map(([key, value]) => key),
    };
  }, [setValue, value]);
}
