import { draftStateToHtml } from "@msys/textutils";
import { RichTextEditor } from "@msys/ui";
import { TextFieldProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { convertFromRaw, RawDraftContentState } from "draft-js";
import { useField } from "formik";
import React from "react";
import { cleanHTML } from "../../utils";

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  InputProps?: TextFieldProps["InputProps"];
}

export const RichTextField = ({ label, name, disabled, InputProps }: Props) => {
  const { t } = useTranslate(["Global"]);
  const [{ value }, , fieldHelperProps] = useField({
    name,
  });
  return (
    <RichTextEditor
      label={label}
      htmlContent={value}
      onChange={(content: RawDraftContentState) => {
        fieldHelperProps.setValue(
          cleanHTML(draftStateToHtml(convertFromRaw(content)))
        );
      }}
      saveButtonLabel={t("Save", { ns: "Global" })}
      cancelButtonLabel={t("Cancel", { ns: "Global" })}
      disabled={disabled}
      InputProps={InputProps}
    />
  );
};
