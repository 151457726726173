import { gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import React from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  ItemCalculation,
  ItemCalculationItemOverrideInput,
} from "../../../clients/graphqlTypes";
import { useUseProposedCalculationsWithOverrideLazyQuery } from "./useProposedCalculationsWithOverride.generated";

export const useProposedCalculationsWithOverride = ({
  projectId,
  docId,
  itemId,
}: {
  projectId: string;
  docId: string;
  itemId: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [fetch] = useUseProposedCalculationsWithOverrideLazyQuery({
    client,
    fetchPolicy: "no-cache",
  });

  const [itemCalculation, setItemCalculation] = React.useState<
    {} | ItemCalculation
  >({});

  const resetItemCalculation = React.useCallback(
    () => setItemCalculation({}),
    []
  );

  const onValuesChanged = useDebouncedCallback(
    async (values: ItemCalculationItemOverrideInput) => {
      const override =
        "materialBuyingPrice" in values
          ? {
              quantity: values.quantity,
              materialBuyingPrice: values.materialBuyingPrice,
              materialMargin: values.materialMargin,
              time: values.time,
              workSellingRate: values.workSellingRate,
              workBuyingRate: values.workBuyingRate,
            }
          : {
              quantity: values.quantity,
            };

      const result = await fetch({
        variables: {
          projectId,
          docId,
          itemOverrides: [
            {
              itemId,
              ...override,
            },
          ],
        },
      });

      const calculation =
        result.data?.docProposedCalculation.itemCalculations.find(
          calculation => calculation.itemId === itemId
        )?.itemCalculation;
      if (calculation) {
        setItemCalculation(calculation);
      } else {
        enqueueSnackbar("Failed to calculate with overrides", {
          variant: "error",
        });
      }
    },
    350
  );

  return {
    values: itemCalculation,
    onValuesChanged: onValuesChanged.callback,
    reset: resetItemCalculation,
  };
};
