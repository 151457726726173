import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

/** @deprecated */
export const useProjectAddEntity = ({ projectId }: { projectId: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToPlanning = React.useCallback(() => {
    navigate({
      pathname: `/planning/projects/${projectId}`,
      search: new URLSearchParams({
        r: location.pathname + location.search,
      }).toString(),
    });
  }, [location, navigate, projectId]);

  const handleNavigateToTasks = React.useCallback(() => {
    navigate(`/projects/${projectId}/tasks`);
  }, [navigate, projectId]);

  const handleNavigateToBillOfMaterials = React.useCallback(() => {
    navigate(`/projects/${projectId}/materials/bill-of-materials`);
  }, [navigate, projectId]);

  return {
    handleNavigateToPlanning,
    handleNavigateToTasks,
    handleNavigateToBillOfMaterials,
  };
};
