import { gql } from "@apollo/client";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { TemplateQuoteAddedToShopStatus_QuoteTemplateFragment } from "./TemplateQuoteAddedToShopStatus.generated";

interface Props {
  doc: TemplateQuoteAddedToShopStatus_QuoteTemplateFragment;
}

export const TemplateQuoteAddedToShopIcon = ({ doc }: Props) => {
  const { t } = useTranslate(["Templates"]);

  const label = !doc.addedToShop
    ? t("Not in Shop", {
        ns: "Templates",
      })
    : t("Added to Shop", {
        ns: "Templates",
      });

  return (
    <Tooltip title={label}>
      {!doc.addedToShop ? (
        <ShoppingBasketIcon color={"disabled"} />
      ) : (
        <ShoppingBasketIcon color={"secondary"} />
      )}
    </Tooltip>
  );
};
