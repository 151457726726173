import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useModalStackLength } from "@msys/ui";
import { createTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useUserData } from "../../auth/useUserData";
import { color, inverseTheme, theme } from "../../../common/MuiThemeProvider";
import {
  ViewerBrandingSlot,
  ViewerBrandingTheme,
} from "../../../clients/graphqlTypes";

const BrandingContext = React.createContext<{
  sidebarTheme: Theme;
  topbarTheme: Theme;
  sidebarTopLogo: ViewerBrandingSlot | null;
  sidebarBottomLogo: ViewerBrandingSlot | null;
  topbarLeftLogo: ViewerBrandingSlot | null;
  topbarRightLogo: ViewerBrandingSlot | null;
  dashboardLeftTopAd: ViewerBrandingSlot | null;
  dashboardRightTopAd: ViewerBrandingSlot | null;
  dashboardSideTopAd: ViewerBrandingSlot | null;
}>({
  sidebarTheme: theme,
  topbarTheme: theme,
  sidebarTopLogo: null,
  sidebarBottomLogo: null,
  topbarLeftLogo: null,
  topbarRightLogo: null,
  dashboardLeftTopAd: null,
  dashboardRightTopAd: null,
  dashboardSideTopAd: null,
});

const makeTheme = (
  brandingTheme: Pick<
    ViewerBrandingTheme,
    "type" | "colorBackground" | "colorText"
  >,
  useInverseColors: boolean = false
) => {
  const parentTheme = !useInverseColors
    ? brandingTheme.type === "dark"
      ? inverseTheme
      : theme
    : brandingTheme.type === "dark"
      ? theme
      : inverseTheme;

  const {
    colorText: colorTextFromTheme,
    colorBackground: colorBackgroundFromTheme,
  } = brandingTheme;

  const colorText = !useInverseColors
    ? colorTextFromTheme
    : colorBackgroundFromTheme;

  const colorBackground = !useInverseColors
    ? colorBackgroundFromTheme
    : colorTextFromTheme;

  return createTheme(parentTheme, {
    palette: {
      ...parentTheme.palette,
      primary: { main: colorText, contrastText: colorBackground },
      secondary: { main: colorText, contrastText: colorBackground },
      text: {
        primary: colorText,
        secondary: colorText,
        disabled: color.grey,
      },
    },
  });
};

export const BrandingProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const viewer = useUserData().currentUser;

  const topbarTheme = React.useMemo(() => {
    const topbarTheme = viewer?.viewerBranding.topbarTheme;
    return topbarTheme
      ? makeTheme(topbarTheme, true)
      : viewer?.organisation.isClientOrganisation
        ? inverseTheme
        : theme;
  }, [
    viewer?.organisation.isClientOrganisation,
    viewer?.viewerBranding.topbarTheme,
  ]);

  const sidebarTheme = React.useMemo(() => {
    const sidebarTheme = viewer?.viewerBranding.sidebarTheme;
    return sidebarTheme ? makeTheme(sidebarTheme) : theme;
  }, [viewer?.viewerBranding.sidebarTheme]);

  const lastMode = React.useRef<"light" | "dark" | null>(null);
  const stackLength = useModalStackLength();

  React.useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    const newMode: "light" | "dark" =
      stackLength > 0 ? "dark" : topbarTheme.palette.mode;

    if (newMode !== lastMode.current) {
      if (newMode === "light") {
        (async () => {
          const deviceInfo = await Device.getInfo();
          const platform = deviceInfo.platform;

          await StatusBar.setStyle({ style: Style.Dark });
          if (platform === "android") {
            await StatusBar.setOverlaysWebView({ overlay: false });
            await StatusBar.setBackgroundColor({
              color: topbarTheme.palette.primary.main,
            });
          }
        })();
      } else if (newMode === "dark") {
        (async () => {
          const deviceInfo = await Device.getInfo();
          const platform = deviceInfo.platform;

          await StatusBar.setStyle({ style: Style.Light });
          if (platform === "android") {
            await StatusBar.setOverlaysWebView({ overlay: false });
            await StatusBar.setBackgroundColor({
              color: topbarTheme.palette.common.white,
            });
          }
        })();
      }
      lastMode.current = newMode;
    }
  }, [
    stackLength,
    topbarTheme.palette.mode,
    topbarTheme.palette.primary.main,
    topbarTheme.palette.common.white,
  ]);

  return (
    <BrandingContext.Provider
      value={{
        topbarTheme,
        sidebarTheme,
        sidebarTopLogo: viewer?.viewerBranding.sidebarTopLogo ?? null,
        sidebarBottomLogo: viewer?.viewerBranding.sidebarBottomLogo ?? null,
        topbarLeftLogo: viewer?.viewerBranding.topbarLeftLogo ?? null,
        topbarRightLogo: viewer?.viewerBranding.topbarRightLogo ?? null,
        dashboardLeftTopAd: viewer?.viewerBranding.dashboardLeftTopAd ?? null,
        dashboardRightTopAd: viewer?.viewerBranding.dashboardRightTopAd ?? null,
        dashboardSideTopAd: viewer?.viewerBranding.dashboardSideTopAd ?? null,
      }}
    >
      {children}
    </BrandingContext.Provider>
  );
};

export const useBranding = () => {
  return React.useContext(BrandingContext);
};
