import { LabeledDateValue, TextWithBreaks } from "@msys/ui";
import { Alert, Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { PageContainer } from "../../commons/layout/PageContainer";
import { Stack } from "../../commons/layout/Stack";
import { ClosingTextInput } from "./InvoiceClosingText";
import { OpeningTextInput } from "./InvoiceOpeningText";
import { InvoiceDetails__InvoiceFragment } from "./Invoices.generated";
import { InvoiceFinalTotals } from "./preview/InvoiceFinalTotals";
import { InvoiceHeader } from "./preview/InvoiceHeader";
import {
  InvoiceHeader__PayeeFragment,
  InvoiceHeader__PayerFragment,
  InvoiceHeader__ProjectFragment,
} from "./preview/InvoiceHeader.generated";
import { InvoicePriceTable } from "./preview/InvoicePriceTable";
import { InvoiceSum } from "./preview/InvoiceSum";

export const InvoicePreview = ({
  project,
  invoice,
  payee,
  payer,
}: {
  project: InvoiceHeader__ProjectFragment;
  invoice: InvoiceDetails__InvoiceFragment;
  payee: InvoiceHeader__PayeeFragment;
  payer: InvoiceHeader__PayerFragment;
}) => {
  const { t } = useTranslate("Invoices");

  const quoteItemMapper = React.useCallback(
    (item: InvoiceDetails__InvoiceFragment["invoiceItems"][number]) => ({
      ...item.itemSnapshot,
    }),
    []
  );

  const invoiceTexts = invoice.invoiceTexts;

  if (!invoice.number) throw new Error("Invoice number missing");

  const showPdfInsteadOfPreview = invoice.status !== "draft";

  return (
    <PageContainer $fullHeight>
      {showPdfInsteadOfPreview ? (
        <iframe
          src={invoice.file?.url}
          title={"PDF"}
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      ) : (
        <Container maxWidth="md">
          <Stack flexDirection="column">
            {invoice.status === "paid" && (
              <Alert
                variant="outlined"
                severity="info"
                sx={{ ".MuiAlert-message": { flexGrow: 1 } }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <LabeledDateValue
                      label={t("Paid on")}
                      value={invoice.paidAt}
                      notSetText="–"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabeledDateValue
                      label={t("Marked as paid on")}
                      value={invoice.markedAsPaidAt}
                      notSetText="–"
                    />
                  </Grid>
                </Grid>
              </Alert>
            )}
            {invoice.status === "cancelled" && (
              <Alert
                variant="outlined"
                severity="warning"
                sx={{ ".MuiAlert-message": { flexGrow: 1 } }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6} md={3}>
                    <LabeledDateValue
                      label={t("Cancelled on")}
                      value={invoice.cancelledAt}
                      notSetText="–"
                      labelProps={{
                        sx: theme => ({ color: theme.palette.warning.main }),
                      }}
                    />
                  </Grid>
                  {invoice.cancellationReason && (
                    <Grid item xs={6} md={9}>
                      <TextWithBreaks text={invoice.cancellationReason} />
                    </Grid>
                  )}
                </Grid>
              </Alert>
            )}

            <InvoiceHeader
              project={project}
              payee={payee}
              payer={payer}
              invoice={invoice}
              canEdit={false}
            />

            <Paper>
              <Box padding={2}>
                <Typography variant="h2">{invoice.title}</Typography>
              </Box>
            </Paper>
            {invoiceTexts?.opening && (
              <OpeningTextInput
                content={invoiceTexts?.opening}
                canEdit={false}
                showInfo={false}
              />
            )}

            <InvoicePriceTable
              invoice={invoice}
              quoteItemMapper={quoteItemMapper}
            />

            <InvoiceSum
              calculation={invoice.calculation}
              doc={invoice.quote}
              invoice={invoice}
            />
            {invoice.type === "final_invoice" && (
              <InvoiceFinalTotals
                invoiceId={invoice.id}
                calculation={invoice.calculation}
                invoiceReferences={invoice.invoiceReferences}
              />
            )}
            {invoiceTexts?.closing && (
              <ClosingTextInput
                content={invoiceTexts?.closing}
                canEdit={false}
                showInfo={false}
              />
            )}
          </Stack>
        </Container>
      )}
    </PageContainer>
  );
};
