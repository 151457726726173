import { useTranslate } from "@tolgee/react";
import React from "react";
import { ColumnExtended } from "./types";

export const columns: ColumnExtended[] = [
  "productTitle",
  "itemStatus",
  "supplier",
  "productArticleNumber",
  "productManufacturerArticleNumber",
  "listPrice",
  "netPrice",
  "discountValue",
  "quantityUnit",
  "quantityRequiredTotal",
  "quantityOrdered",
  "installationTime",
];

export const useColumnLabel = () => {
  const { t } = useTranslate("Materials");

  const columnLabels: Record<ColumnExtended, string> = React.useMemo(
    () => ({
      productTitle: t("Product title"),
      itemStatus: t("Status"),
      supplier: t("Supplier"),
      productArticleNumber: t("Article #"),
      productManufacturerArticleNumber: t("Manufacturer article #"),
      listPrice: t("List price/unit"),
      netPrice: t("Net price/unit"),
      discountValue: t("Discount"),
      quantityUnit: t("Unit"),
      quantityRequiredTotal: t("Required"),
      quantityOrdered: t("Already ordered"),
      installationTime: t("Installation time/unit"),
    }),
    [t]
  );

  const columnOptions = React.useMemo(
    () =>
      columns.map(column => ({
        label: columnLabels[column],
        value: column,
      })),
    [columnLabels]
  );

  return { columnLabels, columnOptions };
};
