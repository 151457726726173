import { EditableTitle } from "@msys/ui";
import { AppBar, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { BreadcrumbItem, PageBreadcrumbs } from "./PageBreadcrumbs";
import { PageSubmenu } from "./PageSubmenu";

interface BaseProps {
  actionMenu?: React.ReactNode;
  submenuItems?: React.ComponentProps<typeof PageSubmenu>["items"];
  activeSubmenuItem?: React.ComponentProps<typeof PageSubmenu>["activeItem"];
}

interface WithBreadcrumbsProps {
  title?: undefined;
  titleEditTooltip?: undefined;
  handleTitleChange?: undefined;
  status?: undefined;
  breadcrumbs?: BreadcrumbItem[];
}

interface WithTitleProps {
  title?: string;
  handleTitleChange?: (value: string) => void;
  titleEditTooltip?: string;
  status?: React.ReactNode;
  breadcrumbs?: undefined;
}

type Props = BaseProps & (WithTitleProps | WithBreadcrumbsProps);

export function PageHeader({
  title,
  handleTitleChange,
  titleEditTooltip,
  status,
  breadcrumbs,
  actionMenu,
  submenuItems = [],
  activeSubmenuItem,
}: Props) {
  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar variant="dense" sx={{ minHeight: "auto" }}>
        <Stack
          direction={"column"}
          justifyContent={"space-between"}
          alignSelf="flex-end"
          minWidth={0}
        >
          {breadcrumbs ? (
            <PageBreadcrumbs breadcrumbs={breadcrumbs} />
          ) : (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={2}
              marginTop={1}
              marginBottom={0.5}
            >
              {title &&
                (handleTitleChange ? (
                  <EditableTitle
                    title={title}
                    tooltip={titleEditTooltip}
                    handleChange={handleTitleChange}
                  />
                ) : (
                  <Typography variant={"h1"}>{title}</Typography>
                ))}
              {status}
            </Stack>
          )}
          {submenuItems.length > 0 && (
            <PageSubmenu
              key={submenuItems.map(i => i.label).join("-")}
              items={submenuItems}
              activeItem={activeSubmenuItem}
            />
          )}
        </Stack>
        {actionMenu}
      </Toolbar>
    </AppBar>
  );
}
