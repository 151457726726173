import { Alert, AlertTitle, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { LoginButton } from "./LoginButton";

export const SessionExpiredError = () => {
  const { t } = useTranslate(["Global"]);

  return (
    <Stack justifyContent={"center"} alignItems={"center"} flex={1}>
      <Alert action={<LoginButton />}>
        <AlertTitle>{t("Error", { ns: "Global" })}</AlertTitle>
        {t("Session expired", { ns: "Global" })}
      </Alert>
    </Stack>
  );
};
