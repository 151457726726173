import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React from "react";
import Truncate from "react-truncate";
import { TextWithBreaks } from "./TextWithBreaks";

interface Props {
  lines?: number;
  text?: string;
  textProps?: TypographyProps<"div">;
  buttonProps?: ButtonProps;
  showMoreLabel: string;
  showLessLabel: string;
  notSetLabel: string;
}

export const TruncatedTextValue = ({
  lines = 3,
  text,
  textProps,
  notSetLabel,
  showMoreLabel,
  showLessLabel,
  buttonProps,
}: Props) => {
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const [hasTruncatedText, setHasTruncatedText] =
    React.useState<boolean>(false);
  return (
    <Typography
      sx={!text?.trim() ? { color: "grey.500" } : undefined}
      component="div"
      {...textProps}
    >
      <Truncate
        lines={showMore ? false : lines}
        trimWhitespace
        ellipsis={
          <>
            …&nbsp;
            <Button
              onClick={() => setShowMore(true)}
              variant="text"
              color="secondary"
              size="extra-small"
              {...buttonProps}
            >
              {showMoreLabel}
            </Button>
          </>
        }
        onTruncate={truncated => {
          if (!showMore && truncated && !hasTruncatedText)
            setHasTruncatedText(true);
          if (!showMore && !truncated && hasTruncatedText)
            setHasTruncatedText(false);
        }}
      >
        {text?.trim() ? (
          <>
            <TextWithBreaks text={text} />
            {hasTruncatedText && (
              <>
                &nbsp;
                <Button
                  onClick={() => setShowMore(false)}
                  variant="text"
                  color="secondary"
                  size="extra-small"
                  {...buttonProps}
                >
                  {showLessLabel}
                </Button>
              </>
            )}
          </>
        ) : (
          notSetLabel
        )}
      </Truncate>
    </Typography>
  );
};
