import { Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../../../commons/layout/Stack";
import { OrganisationAvatar } from "../../../features/organisations/OrganisationAvatar";
import { OrganisationAvatarFragment } from "../../../features/organisations/OrganisationAvatar.generated";

interface Props {
  handleClose: () => void;
  organisation: OrganisationAvatarFragment;
}

export const DashboardNewCompanyModal = ({
  handleClose,
  organisation,
}: Props) => {
  const { t } = useTranslate(["Dashboard", "Global"]);
  return (
    <Modal
      title={t("You joined a new organisation", {
        ns: "Dashboard",
      })}
      actionButtons={[
        {
          label: t("OK", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", autoFocus: true },
        },
      ]}
      handleClose={handleClose}
      dialogProps={{
        onBackdropClick: handleClose,
        fullScreen: false,
        fullWidth: false,
      }}
    >
      <Stack flexDirection="column" alignItems="center">
        <OrganisationAvatar organisationAvatar={organisation} size="l" />
        <div>
          {t(
            "You joined {organisationName}! Now you can easily look into shared work and follow the progress of your projects",
            {
              ns: "Dashboard",
              organisationName: organisation.title,
            }
          )}
        </div>
      </Stack>
    </Modal>
  );
};
