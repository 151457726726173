import { Box, Button, Stack, StackProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import React from "react";
import { useUpdateEffect } from "react-use";

interface Props {
  disabled?: boolean;
  disabledCancel?: boolean;
  disabledSubmit?: boolean;
  onCancel?: () => void;
  onChange?: (values: any) => void;
  onSubmitted?: () => void;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  form?: string;
  stackProps?: StackProps;
}

export const ManualSave = ({
  disabledCancel = false,
  disabledSubmit = false,
  disabled = false,
  onCancel,
  onChange,
  onSubmitted,
  onSubmit,
  form,
  stackProps,
}: Props) => {
  const { t } = useTranslate("Global");
  const { isValid, isSubmitting, dirty } = useFormikContext();
  const { values } = useFormikContext();

  useUpdateEffect(() => {
    onChange?.(values);
  }, [values]);

  useUpdateEffect(() => {
    if (!isSubmitting) onSubmitted?.();
  }, [isSubmitting]);

  return (
    <Stack
      width="100%"
      justifyContent="stretch"
      direction="row"
      spacing={1}
      {...stackProps}
    >
      <Box flex={1}>
        <Button
          disabled={disabled || disabledCancel}
          fullWidth
          onClick={onCancel}
          size="small"
          variant="outlined"
          color="primary"
        >
          {t("Cancel")}
        </Button>
      </Box>
      <Box flex={1}>
        <Button
          fullWidth
          disabled={
            disabled || disabledSubmit || !dirty || !isValid || isSubmitting
          }
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          form={form}
          onClick={onSubmit}
        >
          {t("Save changes")}
        </Button>
      </Box>
    </Stack>
  );
};
