import {
  convertFromRaw,
  Editor,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import React from "react";
import { blockRenderMap } from "../rich-text-editor/blockRenderMap";

interface Props {
  content?: RawDraftContentState;
  placeholder?: string;
}

export const LabeledRichTextValue = ({ content, placeholder }: Props) => {
  const editorState = content
    ? EditorState.createWithContent(convertFromRaw(content))
    : EditorState.createEmpty();
  const contentState = editorState.getCurrentContent();

  return (
    <Editor
      editorState={editorState}
      placeholder={
        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        contentState.hasText() ||
        contentState.getBlockMap().first().getType() !== "unstyled"
          ? undefined
          : placeholder
      }
      readOnly={true}
      onChange={() => {}}
      blockRenderMap={blockRenderMap}
    />
  );
};
