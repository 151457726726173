import React from "react";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../../commons/layout/Stack";
import { OrganisationRole as GQLOrganisationRole } from "../../../clients/graphqlTypes";
import { PlanningResourcesFragment } from "./PlanningSchedule.generated";
import { Chip } from "@mui/material";
import { OrganisationRole, useOrganisationRoles } from "../users/useRoles";
import moment from "moment";

export const ResourceFilterChips: React.FC<{
  resources: PlanningResourcesFragment[];
  roles: GQLOrganisationRole[];
  resourceList: string[] | null | undefined;
  resourceMaxDistance: number | null | undefined;
  resourceOrganisationRoleIds: string[] | null | undefined;
  resourceAvailability:
    | [moment.Moment | null, moment.Moment | null]
    | null
    | undefined;
  setResourceList: (newValue: string[] | null) => void;
  setResourceMaxDistance: (newValue: number) => void;
  setResourceOrganisationRoleIds: (newValue: string[] | null) => void;
  setResourceAvailability: (
    newValue: [moment.Moment | null, moment.Moment | null] | null
  ) => void;
}> = ({
  resources,
  roles,
  resourceList,
  resourceOrganisationRoleIds,
  resourceAvailability,
  resourceMaxDistance,
  setResourceList,
  setResourceOrganisationRoleIds,
  setResourceAvailability,
  setResourceMaxDistance,
}) => {
  const { t } = useTranslate(["PlanningModal", "Global"]);
  const { getOrganisationRoleTitle } = useOrganisationRoles();
  const isAvailabilityDefined = Boolean(
    resourceAvailability && (resourceAvailability[0] || resourceAvailability[1])
  );

  if (
    !isAvailabilityDefined &&
    !((resourceMaxDistance ?? 0) > 0) &&
    !(
      resourceOrganisationRoleIds &&
      resourceOrganisationRoleIds.length < roles.length
    ) &&
    !(resourceList && resourceList.length < resources.length)
  )
    return null;

  return (
    <Stack flexWrap={"wrap"}>
      {/* Availability */}
      {isAvailabilityDefined && (
        <Chip
          size="small"
          variant="outlined"
          color="primary"
          label={
            t("Availability", {
              ns: "PlanningModal",
            }) +
            ": " +
            [
              resourceAvailability?.[0]
                ? t("from {date}", {
                    ns: "Global",
                    date: moment(resourceAvailability[0]).format("DD/MM LT"),
                  })
                : null,
              resourceAvailability?.[1]
                ? t("to {date}", {
                    ns: "Global",
                    date: moment(resourceAvailability[1]).format("DD/MM LT"),
                  })
                : null,
            ]
              .filter(Boolean)
              .join(", ")
          }
          onDelete={() => setResourceAvailability(null)}
        />
      )}
      {/* Max distance */}
      {(resourceMaxDistance ?? 0) > 0 && (
        <Chip
          size="small"
          variant="outlined"
          color="primary"
          label={
            t("Max distance", {
              ns: "PlanningModal",
            }) +
            ": " +
            t("{number} km", {
              ns: "Global",
              number: resourceMaxDistance ?? 0,
            })
          }
          onDelete={() => setResourceMaxDistance(0)}
        />
      )}
      {/* Organisation roles */}
      {resourceOrganisationRoleIds &&
        resourceOrganisationRoleIds.length < roles.length && (
          <Chip
            size="small"
            variant="outlined"
            color="primary"
            label={
              t("Roles", {
                ns: "PlanningModal",
              }) +
              ": " +
              getOrganisationRoleTitle(
                resourceOrganisationRoleIds.map(
                  roleId => roles.find(r => r.id === roleId)!
                )
              )
            }
            onDelete={() => setResourceOrganisationRoleIds(null)}
          />
        )}
      {/* Team members */}
      {resourceList && resourceList.length < resources.length && (
        <Chip
          size="small"
          variant="outlined"
          color="primary"
          label={
            t("Members", {
              ns: "PlanningModal",
            }) +
            ": " +
            t("{selectedCount} out of {totalCount}", {
              ns: "Global",
              selectedCount: resourceList.length,
              totalCount: resources.length,
            })
          }
          onDelete={() => setResourceList(null)}
        />
      )}
    </Stack>
  );
};
