/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeaderBox_RunProjectDocumentAttributeExpressionsMutationVariables = Types.Exact<{
  input: Types.RunProjectDocumentAttributeExpressionsInput;
}>;


export type HeaderBox_RunProjectDocumentAttributeExpressionsMutation = { runProjectDocumentAttributeExpressions: boolean };

export type HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationVariables = Types.Exact<{
  input: Types.RunNonProjectDocumentAttributeExpressionsInput;
}>;


export type HeaderBox_RunNonProjectDocumentAttributeExpressionsMutation = { runNonProjectDocumentAttributeExpressions: boolean };


export const HeaderBox_RunProjectDocumentAttributeExpressionsDocument = gql`
    mutation HeaderBox_RunProjectDocumentAttributeExpressions($input: RunProjectDocumentAttributeExpressionsInput!) {
  runProjectDocumentAttributeExpressions(input: $input)
}
    `;
export type HeaderBox_RunProjectDocumentAttributeExpressionsMutationFn = Apollo.MutationFunction<HeaderBox_RunProjectDocumentAttributeExpressionsMutation, HeaderBox_RunProjectDocumentAttributeExpressionsMutationVariables>;

/**
 * __useHeaderBox_RunProjectDocumentAttributeExpressionsMutation__
 *
 * To run a mutation, you first call `useHeaderBox_RunProjectDocumentAttributeExpressionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeaderBox_RunProjectDocumentAttributeExpressionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headerBoxRunProjectDocumentAttributeExpressionsMutation, { data, loading, error }] = useHeaderBox_RunProjectDocumentAttributeExpressionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHeaderBox_RunProjectDocumentAttributeExpressionsMutation(baseOptions?: Apollo.MutationHookOptions<HeaderBox_RunProjectDocumentAttributeExpressionsMutation, HeaderBox_RunProjectDocumentAttributeExpressionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HeaderBox_RunProjectDocumentAttributeExpressionsMutation, HeaderBox_RunProjectDocumentAttributeExpressionsMutationVariables>(HeaderBox_RunProjectDocumentAttributeExpressionsDocument, options);
      }
export type HeaderBox_RunProjectDocumentAttributeExpressionsMutationHookResult = ReturnType<typeof useHeaderBox_RunProjectDocumentAttributeExpressionsMutation>;
export type HeaderBox_RunProjectDocumentAttributeExpressionsMutationResult = Apollo.MutationResult<HeaderBox_RunProjectDocumentAttributeExpressionsMutation>;
export type HeaderBox_RunProjectDocumentAttributeExpressionsMutationOptions = Apollo.BaseMutationOptions<HeaderBox_RunProjectDocumentAttributeExpressionsMutation, HeaderBox_RunProjectDocumentAttributeExpressionsMutationVariables>;
export const HeaderBox_RunNonProjectDocumentAttributeExpressionsDocument = gql`
    mutation HeaderBox_RunNonProjectDocumentAttributeExpressions($input: RunNonProjectDocumentAttributeExpressionsInput!) {
  runNonProjectDocumentAttributeExpressions(input: $input)
}
    `;
export type HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationFn = Apollo.MutationFunction<HeaderBox_RunNonProjectDocumentAttributeExpressionsMutation, HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationVariables>;

/**
 * __useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation__
 *
 * To run a mutation, you first call `useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headerBoxRunNonProjectDocumentAttributeExpressionsMutation, { data, loading, error }] = useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation(baseOptions?: Apollo.MutationHookOptions<HeaderBox_RunNonProjectDocumentAttributeExpressionsMutation, HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HeaderBox_RunNonProjectDocumentAttributeExpressionsMutation, HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationVariables>(HeaderBox_RunNonProjectDocumentAttributeExpressionsDocument, options);
      }
export type HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationHookResult = ReturnType<typeof useHeaderBox_RunNonProjectDocumentAttributeExpressionsMutation>;
export type HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationResult = Apollo.MutationResult<HeaderBox_RunNonProjectDocumentAttributeExpressionsMutation>;
export type HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationOptions = Apollo.BaseMutationOptions<HeaderBox_RunNonProjectDocumentAttributeExpressionsMutation, HeaderBox_RunNonProjectDocumentAttributeExpressionsMutationVariables>;