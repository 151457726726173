/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../doc-items/Fragments.generated';
export type InvoiceSelectButton__QuoteFragment = { __typename: 'Quote', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, type: Types.ItemType, rank: number, childrenVisibility: Types.ItemChildrenVisibility, needsAgreement: boolean, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null }> };

export type InvoiceSelectAgreedOrProposedList__QuoteItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type InvoiceSelectAgreedOrProposedList_ItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export const InvoiceSelectAgreedOrProposedList__QuoteItemFragmentDoc = gql`
    fragment InvoiceSelectAgreedOrProposedList__QuoteItem on Item {
  id
  type
  proposedCalculation {
    ...ItemCalculation
  }
  agreedCalculation {
    ...ItemCalculation
  }
}
    ${ItemCalculationFragmentDoc}`;
export const InvoiceSelectButton__QuoteFragmentDoc = gql`
    fragment InvoiceSelectButton__Quote on Quote {
  id
  items {
    id
    originVersionNumber
    type
    rank
    childrenVisibility
    needsAgreement
    ...InvoiceSelectAgreedOrProposedList__QuoteItem
  }
}
    ${InvoiceSelectAgreedOrProposedList__QuoteItemFragmentDoc}`;
export const InvoiceSelectAgreedOrProposedList_ItemFragmentDoc = gql`
    fragment InvoiceSelectAgreedOrProposedList_Item on Item {
  id
  type
  proposedCalculation {
    ...ItemCalculation
  }
  agreedCalculation {
    ...ItemCalculation
  }
}
    ${ItemCalculationFragmentDoc}`;