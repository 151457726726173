/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { QuoteStatusChip_QuoteFragmentDoc } from './QuoteStatusBadge.generated';
import { QuoteMenu_QuoteFragmentDoc, QuoteMenu_ProjectFragmentDoc } from './QuoteMenu.generated';
import { DownloadQuoteAsPdfButton_QuoteFragmentDoc } from './buttons/DownloadQuoteAsPdfIconButton.generated';
export type QuoteSubHeader_QuoteFragment = { __typename: 'Quote', id: string, viewerPermissions: Array<Types.PermissionName>, title: string, number: string, isPublished: boolean, xStatus?: string | null, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement, owningSystemOrganisationId: string, canBeDeleted: boolean, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null }, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type QuoteSubHeader_ProjectFragment = { __typename: 'Project', id: string };

export const QuoteSubHeader_QuoteFragmentDoc = gql`
    fragment QuoteSubHeader_Quote on Quote {
  id
  viewerPermissions
  title
  number
  isPublished
  ...QuoteStatusChip_Quote
  ...QuoteMenu_Quote
  ...DownloadQuoteAsPdfButton_Quote
  rootItem {
    id
    originVersionNumber
  }
  xStatus
}
    ${QuoteStatusChip_QuoteFragmentDoc}
${QuoteMenu_QuoteFragmentDoc}
${DownloadQuoteAsPdfButton_QuoteFragmentDoc}`;
export const QuoteSubHeader_ProjectFragmentDoc = gql`
    fragment QuoteSubHeader_Project on Project {
  id
  ...QuoteMenu_Project
}
    ${QuoteMenu_ProjectFragmentDoc}`;