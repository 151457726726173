import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { MenuButton, TitleWithPathForPdf, useScreenWidth } from "@msys/ui";
import { Divider, Stack } from "@mui/material";
import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  AskWhom,
  ItemType,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { usePageWidth } from "../../commons/hooks/usePageWidth";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal";
import { PageMiddleColumn } from "../../commons/layout/PageMiddleColumn";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { REQUIREMENT_ITEM_TYPES } from "../../constants";
import { DecisionModalButton } from "../../features/doc-items/buttons/DecisionModalButton";
import { hasAnyQuestions } from "../../features/doc-items/helpers";
import { EmptyStateItem } from "../../features/doc-items/items/EmptyStateItem";
import { DocItemContextMenuItems } from "../../features/doc-items/menus/DocItemContextMenuItems";
import { ItemTypeMenuItem } from "../../features/doc-items/menus/ItemTypeMenuItem";
import { ProjectBuildingPlacementMenuItem } from "../../features/doc-items/menus/ProjectBuildingPlacementMenuItem";
import { RequirementItem } from "../../features/requirements/RequirementItem";
import { useProjectRequirementEditItemQuery } from "./ProjectRequirementEditItem.generated";

const REFETCH_QUERY = [namedOperations.Query.ProjectRequirementEditItem];

export function ProjectRequirementEditItem({
  projectId,
  requirementId,
  pathToDocPage,
  isAbsolute,
}: {
  projectId: string;
  requirementId: string;
  pathToDocPage: string;
  isAbsolute?: boolean;
}) {
  const { itemId } = useParams();
  if (!itemId) throw new Error("Requirement item id is missing");
  const navigate = useNavigate();

  const { isMinTablet } = useScreenWidth();
  const { isMinTwoColumnsWithPreview } = usePageWidth();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const {
    expandedItemIds,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
  } = useOutletContext<{
    expandedItemIds: string[] | undefined;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: { type: ItemType }) => boolean;
    viewerDecisionRole: AskWhom;
  }>();

  const client = useApolloClient();
  const query = useProjectRequirementEditItemQuery({
    client,
    variables: { projectId, requirementId, itemId },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const requirement = getDataOrNull(query.data?.requirement)?.requirement;
  const item = requirement?.item;

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  if (!item || !requirement || !project)
    return (
      <EmptyStateItem
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        loading={query.loading}
        error={query.error}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      />
    );

  const requirementItem = (
    <>
      <RequirementItem
        key={itemId} // force reset forms
        project={project}
        requirement={requirement}
        item={item}
        pathToDocPage={pathToDocPage}
        onUpdateDataRefetchQueries={REFETCH_QUERY}
      />
      <ConfirmProcess ref={confirmProcessRef} />
    </>
  );

  const title = (
    <TitleWithPathForPdf title={item.title} pathForPdf={item.pathForPdf} />
  );

  const headerActions = (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {hasAnyQuestions(item, "REQUIREMENT", viewerDecisionRole) && (
        <DecisionModalButton
          projectId={projectId}
          docType="REQUIREMENT"
          docId={requirementId}
          itemId={item.id}
          canFinalize={true}
          viewerDecisionRole={viewerDecisionRole}
          expandedItemIds={expandedItemIds}
          type="icon"
        />
      )}
      <MenuButton onToggle={setIsOpen}>
        {/* we need to trigger refetch when menu gets opened */}
        {isOpen && (
          <ItemTypeMenuItem
            projectId={projectId}
            docId={requirementId}
            itemId={itemId}
            itemType={item.type}
            allowedTypes={REQUIREMENT_ITEM_TYPES}
            expandedItemIds={expandedItemIds}
          />
        )}
        <Divider />
        <ProjectBuildingPlacementMenuItem
          projectId={projectId}
          docId={requirementId}
          itemId={item.id}
        />
        <Divider />
        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={requirementId}
          docType="REQUIREMENT"
          projectId={projectId}
          item={item}
          startConfirmProcess={startConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={isAllowedToPasteItem(item) ? pasteItem : null}
        />
      </MenuButton>
    </Stack>
  );

  if (isMinTablet) {
    return (
      <PageMiddleColumn
        key={itemId}
        hasRightBorder
        hasBackground
        isSelected
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        title={title}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
        headerActions={headerActions}
      >
        {requirementItem}
      </PageMiddleColumn>
    );
  }

  return (
    <PageFullScreenModal
      key={itemId}
      title={title}
      onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      headerActions={headerActions}
    >
      {requirementItem}
    </PageFullScreenModal>
  );
}
