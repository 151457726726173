import { Capacitor } from "@capacitor/core";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React, { PropsWithChildren } from "react";
import { AttachmentField } from "./AttachmentField";
import { AttachmentPhotoFieldWithEditor } from "./AttachmentPhotoFieldWithEditor";
import { AttachmentPhotoUploaderWithEditorRef } from "./AttachmentPhotoUploaderWithEditor";
import { AddAttachmentsFn } from "./useAttachments";

interface Props {
  addAttachments: AddAttachmentsFn;
  label?: string;
  children?: React.ReactElement;
}

export const ButtonPhotoUpload = React.forwardRef(
  (
    { addAttachments, label, children }: PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const { t } = useTranslate("AttachmentField");
    const attachmentFieldRef = React.useRef<HTMLInputElement>(null);
    const attachmentPhotoUploaderRef =
      React.useRef<AttachmentPhotoUploaderWithEditorRef>(null);

    const handleClick = () => {
      Capacitor.isNativePlatform()
        ? attachmentPhotoUploaderRef.current?.start()
        : attachmentFieldRef.current?.click();
    };

    const button = children ? (
      React.cloneElement(children, {
        onClick: handleClick,
        ref,
      })
    ) : (
      <IconButton color="primary" size="small" onClick={handleClick} ref={ref}>
        <AddAPhotoIcon />
      </IconButton>
    );

    return Capacitor.isNativePlatform() ? (
      <>
        {button}
        <AttachmentPhotoFieldWithEditor
          ref={attachmentPhotoUploaderRef}
          addAttachments={addAttachments}
        />
      </>
    ) : (
      <>
        <Tooltip title={label ?? t("Upload file")}>{button}</Tooltip>
        <AttachmentField
          accept="image/*,.heic,.heif"
          multiple={true}
          innerRef={attachmentFieldRef}
          addAttachments={addAttachments}
        />
      </>
    );
  }
);
