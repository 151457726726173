/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateQuoteSelectFieldQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterApplicableFor: Types.TplApplicableFor;
  filterShareStatus?: Types.InputMaybe<Types.TemplateShareStatus>;
  filterIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type TemplateQuoteSelectFieldQuery = { quoteTemplates: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'QuoteTemplateConnection', totalCount: number, edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, owningSystemOrganisationId: string } }> } };


export const TemplateQuoteSelectFieldDocument = gql`
    query TemplateQuoteSelectField($limit: Int!, $searchTerm: String, $filterApplicableFor: TplApplicableFor!, $filterShareStatus: TemplateShareStatus, $filterIds: [ID!]) {
  quoteTemplates(
    limit: $limit
    search: $searchTerm
    filters: {sources: [available], applicableFor: [$filterApplicableFor], mineShareStatus: $filterShareStatus, templateIds: $filterIds}
    sorting: {column: title, direction: asc}
  ) {
    ... on QuoteTemplateConnection {
      edges {
        node {
          id
          resolvedAsReadModelVersionNumber
          title
          owningSystemOrganisationId
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useTemplateQuoteSelectFieldQuery__
 *
 * To run a query within a React component, call `useTemplateQuoteSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuoteSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuoteSelectFieldQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      filterApplicableFor: // value for 'filterApplicableFor'
 *      filterShareStatus: // value for 'filterShareStatus'
 *      filterIds: // value for 'filterIds'
 *   },
 * });
 */
export function useTemplateQuoteSelectFieldQuery(baseOptions: Apollo.QueryHookOptions<TemplateQuoteSelectFieldQuery, TemplateQuoteSelectFieldQueryVariables> & ({ variables: TemplateQuoteSelectFieldQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateQuoteSelectFieldQuery, TemplateQuoteSelectFieldQueryVariables>(TemplateQuoteSelectFieldDocument, options);
      }
export function useTemplateQuoteSelectFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateQuoteSelectFieldQuery, TemplateQuoteSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateQuoteSelectFieldQuery, TemplateQuoteSelectFieldQueryVariables>(TemplateQuoteSelectFieldDocument, options);
        }
export function useTemplateQuoteSelectFieldSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateQuoteSelectFieldQuery, TemplateQuoteSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateQuoteSelectFieldQuery, TemplateQuoteSelectFieldQueryVariables>(TemplateQuoteSelectFieldDocument, options);
        }
export type TemplateQuoteSelectFieldQueryHookResult = ReturnType<typeof useTemplateQuoteSelectFieldQuery>;
export type TemplateQuoteSelectFieldLazyQueryHookResult = ReturnType<typeof useTemplateQuoteSelectFieldLazyQuery>;
export type TemplateQuoteSelectFieldSuspenseQueryHookResult = ReturnType<typeof useTemplateQuoteSelectFieldSuspenseQuery>;
export type TemplateQuoteSelectFieldQueryResult = Apollo.QueryResult<TemplateQuoteSelectFieldQuery, TemplateQuoteSelectFieldQueryVariables>;