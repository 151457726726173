import DoneIcon from "@mui/icons-material/Done";
import { Theme, useTheme } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import React from "react";
import {
  Agreement,
  ItemType,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { px } from "../../../common/MuiThemeProvider";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { useUserData } from "../../auth/useUserData";
import { ViewMode } from "../../commons/ViewModeMenuItem";
import { Stack } from "../../commons/layout/Stack";
import { BareTreeItem } from "../../trees/BareTreeItem";
import { HeaderBox } from "../doc-items/boxes/HeaderBox";
import { ItemFilesBox } from "../doc-items/boxes/ItemFilesBox";
import { ProductBox } from "../doc-items/boxes/ProductBox";
import { RequiredOnCompletionBox } from "../doc-items/boxes/RequiredOnCompletionBox";
import {
  SubelementsBox,
  SubitemComponentProps,
} from "../doc-items/boxes/SubelementsBox";
import { TaskManagementBox } from "../doc-items/boxes/TaskManagementBox";
import { TimeCalculationBox } from "../doc-items/boxes/TimeCalculationBox";
import { RelatedOrderItemsBox } from "../purchase-orders/boxes/RelatedOrderItemsBox";
import {
  TaskItem_ChildItemComponentFragment,
  TaskItem_ChildItemFragment,
  TaskItem_DocumentFragment,
  TaskItem_ItemFragment,
  TaskItem_ProjectFragment,
} from "./TaskItem.generated";
import { TaskManagementButtons } from "./TaskManagementButtons";
import { shouldRenderChildItem } from "./helpers";
import { IconButtonFactory, getItemData123, getItemRightIcons } from "./trees";

interface Props {
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  isManualSave: boolean;
  canEdit: boolean;
  project: TaskItem_ProjectFragment;
  doc: TaskItem_DocumentFragment;
  item: TaskItem_ItemFragment;
  pathToDocPage: string;
  onUpdateDataRefetchQueries?: string[];
  isInitiallyClosed?: boolean;
}

export const TaskItem = ({
  viewMode,
  setViewMode,
  isManualSave,
  canEdit,
  item,
  doc,
  project,
  pathToDocPage,
  onUpdateDataRefetchQueries,
  isInitiallyClosed,
}: Props) => {
  const viewer = useUserData().currentUser!;

  const documentItemTypes: ItemType[] = ["unpaid", "defect"];

  const ChildItemComponent = React.useMemo(
    () =>
      doc?.agreement
        ? createChildItemComponent(
            pathToDocPage,
            doc?.agreement,
            doc.viewerPermissions
          )
        : undefined,
    [doc?.agreement, doc.viewerPermissions, pathToDocPage]
  );

  return (
    <Stack flexDirection="column">
      <HeaderBox
        projectId={project.id}
        docId={doc.id}
        docType="QUOTE"
        item={item}
        viewMode={canEdit ? viewMode : null}
        setViewMode={setViewMode}
        isManualSave={isManualSave}
        hideRules
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />

      {((item.product && item.product.title.length > 0) ||
        (item.product && item.product.articleNumber)) && (
        <ProductBox
          project={project}
          docId={doc.id}
          docType="QUOTE"
          item={item}
          isEditable={false}
          viewerIsContractor={doc.contractor.isMyOrganisation}
          isInitiallyClosed={isInitiallyClosed}
          onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
        />
      )}

      <RestrictedByDocumentPermissionWithDebug
        permission="EXECUTE_TASK"
        document={doc}
      >
        <TaskManagementBox
          project={project}
          docId={doc.id}
          document={doc}
          item={item}
          canEdit={true}
          isInitiallyClosed={isInitiallyClosed}
        />
      </RestrictedByDocumentPermissionWithDebug>

      <RequiredOnCompletionBox
        project={project}
        docId={doc.id}
        document={doc}
        item={item}
        canEdit={true}
        canAddPhotoApproval={true}
        isInitiallyClosed={isInitiallyClosed}
      />

      <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
        <RestrictedByDocumentPermissionWithDebug
          permission="EXECUTE_TASK"
          document={doc}
        >
          <TimeCalculationBox
            project={project}
            docId={doc.id}
            document={doc}
            item={item}
            canAddWorkSession={true}
            isInitiallyClosed={isInitiallyClosed}
          />
        </RestrictedByDocumentPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>

      {viewer.organisation.isCraftsmanOrganisation && (
        <RelatedOrderItemsBox
          orderItems={item.orderItems}
          project={project}
          document={doc}
          isInitiallyClosed={isInitiallyClosed}
        />
      )}

      <ItemFilesBox
        projectId={project.id}
        docId={doc.id}
        docType="QUOTE"
        viewerPermissions={doc.viewerPermissions}
        item={item}
        isInitiallyClosed={isInitiallyClosed}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />

      {ChildItemComponent && (
        <SubelementsBox<TaskItem_ChildItemFragment>
          projectId={project.id}
          doc={{ id: doc.id, docType: "QUOTE", templateIsDraft: false }}
          item={item}
          subitemComponent={ChildItemComponent}
          pathToDocPage={pathToDocPage}
          documentItemTypes={documentItemTypes}
          isInitiallyClosed={isInitiallyClosed}
          shouldRenderItem={shouldRenderChildItem}
        />
      )}

      {!item.isRootItem && item.canBeWorkedOn && (
        <TaskManagementButtons
          itemId={item.id}
          docId={doc.id}
          projectId={project.id}
        />
      )}
    </Stack>
  );
};

const createChildItemComponent =
  <T extends TaskItem_ChildItemComponentFragment>(
    pathToDocPage: string | null,
    docAgreement: Agreement,
    docViewerPermissions: PermissionName[],
    getIconRightButton?: IconButtonFactory<T>
  ) =>
  (itemProps: SubitemComponentProps<T>) => {
    const theme: Theme = useTheme();
    const language = useTolgee(["language"]).getLanguage()!;

    const IconLeftButtons = React.useMemo(() => {
      if (!itemProps.item.isDone) return null;
      return (
        <DoneIcon
          fontSize="medium"
          style={{ marginRight: px.xs, color: theme.palette.success.main }}
        />
      );
    }, [itemProps.item, theme]);

    const IconRightButtons = React.useMemo(
      () => (getIconRightButton ? getIconRightButton(itemProps.item) : null),
      [itemProps.item]
    );

    const [Data123Left, Data123Right] = React.useMemo(
      () =>
        getItemData123(docViewerPermissions, itemProps.item, theme, language),
      [language, itemProps.item, theme]
    );
    const RightIcons = React.useMemo(
      () => getItemRightIcons(docViewerPermissions, itemProps.item, theme),
      [itemProps.item, theme]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        RightIcons={RightIcons}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconLeftButtons={IconLeftButtons}
        IconRightButtons={IconRightButtons}
        showPath={!itemProps.item.isDecisionOption}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        onClick={itemProps.onClick}
        subcontractPath={itemProps.subcontractPath}
        to={
          itemProps.to !== undefined
            ? itemProps.to
            : pathToDocPage
              ? `${pathToDocPage}/${itemProps.item.docId}/items/${itemProps.item.id}`
              : undefined
        }
      />
    );
  };
