import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import React from "react";
import { AttachmentUploader } from "./AttachmentUploader";
import { Attachment } from "./helpers";
import { Stack } from "../../commons/layout/Stack";

interface Props {
  title: string;
  subTitle?: string;
  accept?: string;
}
interface Single {
  multiple: false;
  onComplete?: (attachment: Attachment) => Promise<void> | void;
}
interface Multiple {
  multiple: true;
  onComplete?: (attachments: Attachment[]) => Promise<void> | void;
}

export const AttachmentUploadRow = ({
  title,
  subTitle,
  accept = "*/*",
  multiple,
  onComplete,
}: Props & (Single | Multiple)) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const uploaderRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      {multiple ? (
        <AttachmentUploader
          innerRef={uploaderRef}
          accept={accept}
          multiple={true}
          onStart={() => setIsSubmitting(true)}
          onError={() => setIsSubmitting(false)}
          onComplete={async attachment => {
            setIsSubmitting(false);
            await onComplete?.(attachment);
          }}
        />
      ) : (
        <AttachmentUploader
          innerRef={uploaderRef}
          accept={accept}
          multiple={false}
          onStart={() => setIsSubmitting(true)}
          onError={() => setIsSubmitting(false)}
          onComplete={async attachment => {
            setIsSubmitting(false);
            await onComplete?.(attachment);
          }}
        />
      )}
      <Stack justifyContent="space-between" alignItems="center" width="100%">
        <Typography>{title}</Typography>
        {onComplete && (
          <IconButton
            color="secondary"
            aria-label="Add attachment"
            onClick={() => {
              uploaderRef.current?.click();
            }}
            size="small"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress color="primary" size="1.5rem" />
            ) : (
              <AddIcon />
            )}
          </IconButton>
        )}
      </Stack>
      {subTitle && (
        <Typography variant="caption" component="p" color="textSecondary">
          {subTitle}
        </Typography>
      )}
    </>
  );
};
