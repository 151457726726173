import { gql, useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { SwitchField } from "../../../commons/form-fields/SwitchField";
import {
  OrganisationProjectSettingsBox_SettingsFragment,
  useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation,
} from "./OrganisationProjectSettingsBox.generated";

interface Props {
  organisationSettings: OrganisationProjectSettingsBox_SettingsFragment;
  organisationId: string;
}

export function OrganisationProjectSettingsBox(props: Props) {
  const { t } = useTranslate("OrganisationSettings");
  return (
    <CardContainer isExpandable title={t("Project settings")}>
      <OrganisationProjectSettingsForm {...props} />
    </CardContainer>
  );
}

interface FormValues {
  projectCouldBeUnassigned: boolean;
}

function OrganisationProjectSettingsForm({
  organisationId,
  organisationSettings,
}: {
  organisationSettings: OrganisationProjectSettingsBox_SettingsFragment;
  organisationId: string;
}) {
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const { t } = useTranslate(["OrganisationSettings", "ProjectMembers"]);
  const { enqueueSnackbar } = useSnackbar();
  const [modifyOrganisationSettings] =
    useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation({
      client,
    });

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const initialValues = {
    projectCouldBeUnassigned: organisationSettings.projectCouldBeUnassigned,
  };

  const validationSchema = Yup.object().shape({
    projectCouldBeUnassigned: Yup.bool().label(
      t("Project could be unassigned", { ns: "OrganisationSettings" })
    ),
  });

  const handleSubmit = async (values: FormValues) => {
    await modifyOrganisationSettings({
      variables: {
        input: {
          projectCouldBeUnassigned: values.projectCouldBeUnassigned,
        },
      },
    });
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1} p={1}>
            <div>
              <Typography>{t("Assignee", { ns: "ProjectMembers" })}</Typography>
              <SwitchField
                name="projectCouldBeUnassigned"
                label={t("Project could be unassigned", {
                  ns: "OrganisationSettings",
                })}
                disabled={false}
              />
            </div>
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
