/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeOrderItemQuantityMutationVariables = Types.Exact<{
  input: Types.ModifyOrderItemInput;
}>;


export type ChangeOrderItemQuantityMutation = { modifyOrderItem: { __typename: 'ModifyOrderItemResult', order: { __typename: 'Order', id: string, calculationPrepared: { __typename: 'OrderCalculationPrepared', id?: string | null, quantityOrdered: number, priceOrdered: number, priceVatOrdered: number, priceTotalOrdered: number } }, item: { __typename: 'OrderItem', id: string, quantityOrdered: number, unitPrice: number } } };


export const ChangeOrderItemQuantityDocument = gql`
    mutation ChangeOrderItemQuantity($input: ModifyOrderItemInput!) {
  modifyOrderItem(input: $input) {
    order {
      id
      calculationPrepared {
        id
        quantityOrdered
        priceOrdered
        priceVatOrdered
        priceTotalOrdered
      }
    }
    item {
      id
      quantityOrdered
      unitPrice
    }
  }
}
    `;
export type ChangeOrderItemQuantityMutationFn = Apollo.MutationFunction<ChangeOrderItemQuantityMutation, ChangeOrderItemQuantityMutationVariables>;

/**
 * __useChangeOrderItemQuantityMutation__
 *
 * To run a mutation, you first call `useChangeOrderItemQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderItemQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrderItemQuantityMutation, { data, loading, error }] = useChangeOrderItemQuantityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOrderItemQuantityMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOrderItemQuantityMutation, ChangeOrderItemQuantityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOrderItemQuantityMutation, ChangeOrderItemQuantityMutationVariables>(ChangeOrderItemQuantityDocument, options);
      }
export type ChangeOrderItemQuantityMutationHookResult = ReturnType<typeof useChangeOrderItemQuantityMutation>;
export type ChangeOrderItemQuantityMutationResult = Apollo.MutationResult<ChangeOrderItemQuantityMutation>;
export type ChangeOrderItemQuantityMutationOptions = Apollo.BaseMutationOptions<ChangeOrderItemQuantityMutation, ChangeOrderItemQuantityMutationVariables>;