import React, { useRef, useState, useEffect, FC } from "react";
import { styled } from "@mui/material/styles";
import { color } from "../../../common/MuiThemeProvider";
import { useLatest } from "react-use";
import { Modal } from "@msys/ui";
import { Typography } from "@mui/material";
import { ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";

// source: https://codesandbox.io/s/i7ekk?file=/src/DurationInput.js:0-3154

const TRACK_TOTAL_HEIGHT = 128;
const TRACK_HEIGHT = 96;
const TRACK_WIDTH = 80;

const StyledContainer = styled("div")(
  ({ theme }) => `
  position: relative;
  height: ${TRACK_TOTAL_HEIGHT}px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 0 0 ${theme.palette.grey[300]};
  margin-bottom: 12px;
`
);

const StyledList = styled("div")(
  ({ theme }) => `
  width: 100%;
  height: ${TRACK_HEIGHT}px;
  overflow-y: scroll;

  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;

  // hiding scrollbar
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  box-shadow: 0 -1px 0 0 ${theme.palette.grey[300]};
`
);

const StyledTrack = styled("div")(`
  width: ${TRACK_WIDTH}px;
  flex-grow: 1;
  height: ${TRACK_TOTAL_HEIGHT}px;
  overflow-y: hidden;
`);

const StyledNumber = styled("div")(`
  position: relative;
  height: ${TRACK_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  background-color: ${color.white};

  &::after {
    content: "";
    background-color: ${color.grey};
    position: absolute;
    height: 8px;
    width: 8px;
    bottom: -4px;
    border-radius: 100%;
    z-index: 5;
  }
`);

const StyledHeader = styled("div")(`
  height: ${TRACK_TOTAL_HEIGHT - TRACK_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`);

const StyledInputWrapper = styled("div")(`
  display: flex;

  & > * {
    pointer-events: none;
    user-select: none;
  }
`);

const DurationTrack: FC<{
  initialValue: number;
  numbers: number[];
  qualifier: string;
  onChange(newValue: number): void;
}> = ({ initialValue, numbers, qualifier, onChange }) => {
  const list = useRef(null);
  const onChangeLatest = useLatest(onChange);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) return;
          onChangeLatest.current(
            Number.parseFloat(entry.target.textContent ?? "0")
          );
        });
      },
      { threshold: 0.75 }
    );
    // @ts-ignore
    [...list.current.children].forEach(child => {
      observer.observe(child);
    });
  }, [onChangeLatest]);

  useEffect(() => {
    if (!list.current) return;
    // @ts-ignore
    list.current.scrollTop = TRACK_HEIGHT * initialValue;
  }, [initialValue]);

  return (
    <StyledTrack>
      <StyledHeader>
        <Typography align="center" color="textSecondary" variant="caption">
          {qualifier}
        </Typography>
      </StyledHeader>
      <StyledList ref={list}>
        {numbers.map((number, i) => (
          <StyledNumber key={qualifier + number}>
            <Typography
              align="center"
              color="primary"
              variant="body1"
              component="div"
              style={{ fontSize: "1.5em", lineHeight: "1" }}
            >
              {number}
            </Typography>
          </StyledNumber>
        ))}
      </StyledList>
    </StyledTrack>
  );
};

export const DurationTrackDailyInput: FC<{
  input: JSX.Element;
  value: number;
  onChange(newValue: number): void;
}> = ({ value, input, onChange }) => {
  return (
    <ModalOpenButton
      Modal={DurationTrackModal}
      modalProps={{
        initialDuration: value,
        handleComplete: (durationInMinutes: number) => {
          onChange(durationInMinutes);
        },
      }}
    >
      <StyledInputWrapper>{input}</StyledInputWrapper>
    </ModalOpenButton>
  );
};

const DurationTrackModal: FC<{
  initialDuration: number;
  handleClose: () => void;
  handleComplete: (durationInMinutes: number) => void;
}> = ({ handleComplete, handleClose, initialDuration = 0 }) => {
  const { t } = useTranslate(["DurationField", "Global"]);
  const [duration, setDuration] = useState(initialDuration);

  const initialHours = Math.floor(initialDuration / 60);
  const initialMinutes = Math.floor(initialDuration % 60);

  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);

  useEffect(() => {
    const value = hours * 60 + minutes;
    setDuration(value);
  }, [hours, minutes]);

  return (
    <Modal
      notInStack
      title={t("Select duration", {
        ns: "DurationField",
      })}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", {
            ns: "Global",
          }),
          handleClick: () => {
            handleComplete(duration);
            handleClose();
          },
        },
      ]}
      handleClose={handleClose}
      dialogProps={{
        onBackdropClick: handleClose,
        fullScreen: false,
        fullWidth: false,
      }}
    >
      <StyledContainer>
        <DurationTrack
          initialValue={initialHours}
          numbers={[...Array(24).keys()]}
          qualifier={t("Hours", {
            ns: "DurationField",
          })}
          onChange={value => setHours(value)}
        />
        <DurationTrack
          initialValue={initialMinutes}
          numbers={[...Array(60).keys()]}
          qualifier={t("Minutes", {
            ns: "DurationField",
          })}
          onChange={value => setMinutes(value)}
        />
      </StyledContainer>
    </Modal>
  );
};
