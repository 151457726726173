import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue, Modal, ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { differenceWith, uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import {
  IntegrationId,
  namedOperations,
} from "../../../../clients/graphqlTypes";
import { FormattedIntegerField } from "../../../commons/form-fields/FormattedIntegerField";
import { ManualSave } from "../../../commons/form-fields/ManualSave";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { TextField } from "../../../commons/form-fields/TextField";
import {
  M1ApiLeadResponseDocument,
  M1ApiLeadResponseQuery,
  M1ApiLeadResponseQueryVariables,
  OrganisationIntegrationsBox_IntegrationFragment,
  OrganisationIntegrationsBox_OrganisationIntegrationFragment,
  useOrganisationIntegrationsBoxAddMutation,
  useOrganisationIntegrationsBoxRemoveMutation,
  useOrganisationIntegrationsBoxUpdateMutation,
} from "./OrganisationIntegrationsBox.generated";

export const OrganisationIntegrationsBox = ({
  integrationM1MsysId,
  allAvailableIntegrations,
  organisationIntegrations,
}: {
  integrationM1MsysId: string;
  allAvailableIntegrations: OrganisationIntegrationsBox_IntegrationFragment[];
  organisationIntegrations: OrganisationIntegrationsBox_OrganisationIntegrationFragment[];
}) => {
  const { t } = useTranslate(["OrganisationSettings"]);

  const client = useApolloClient();

  const availableIntegrations = React.useMemo(() => {
    return differenceWith(
      allAvailableIntegrations,
      organisationIntegrations,
      (integration, organisationIntegrations) =>
        integration.id === organisationIntegrations.integration.id
    );
  }, [allAvailableIntegrations, organisationIntegrations]);

  const [addOrganisationIntegration, { loading: addLoading }] =
    useOrganisationIntegrationsBoxAddMutation({ client });
  const [removeOrganisationIntegration, { loading: removeLoading }] =
    useOrganisationIntegrationsBoxRemoveMutation({ client });
  const [updateOrganisationIntegration, { loading: updateLoading }] =
    useOrganisationIntegrationsBoxUpdateMutation({ client });

  const handleAdd = async (values: {
    integrationId: IntegrationId;
    foreignAccountId: string | null;
  }) => {
    await addOrganisationIntegration({
      variables: {
        input: {
          integrationId: values.integrationId,
          foreignAccountId: values.foreignAccountId,
        },
      },
      refetchQueries: [namedOperations.Query.OrganisationSettingsIntegrations],
      awaitRefetchQueries: true,
    });
  };

  const handleRemove = async (integrationId: IntegrationId) => {
    await removeOrganisationIntegration({
      variables: {
        input: { integrationId },
      },
      refetchQueries: [namedOperations.Query.OrganisationSettingsIntegrations],
      awaitRefetchQueries: true,
    });
  };

  const handleUpdate = async (
    integrationId: IntegrationId,
    values: {
      foreignAccountId: string | null;
      settings: {
        m1CalculatorId2MsysTemplateId: {};
        m1CalculatorIdCreateM1Import: {};
      };
    }
  ) => {
    await updateOrganisationIntegration({
      variables: {
        input: {
          integrationId: integrationId,
          foreignAccountId: values.foreignAccountId,
          settings: values.settings,
        },
      },
      refetchQueries: [namedOperations.Query.OrganisationSettingsIntegrations],
      awaitRefetchQueries: true,
    });
  };

  return (
    <CardContainer
      title={t("Integrations", {
        ns: "OrganisationSettings",
      })}
      isInitiallyClosed={false}
      itemCount={organisationIntegrations.length}
    >
      <Stack direction="column" spacing={1} py={1}>
        <Box px={1}>
          <LabeledValue
            label={t("MeisterSystems organisation ID", {
              ns: "OrganisationSettings",
            })}
          >
            {integrationM1MsysId}
          </LabeledValue>
        </Box>
        <Divider />
        {organisationIntegrations.map((organisationIntegration, index) => (
          <Stack
            direction="column"
            spacing={1}
            px={1}
            key={organisationIntegration.id}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="h3">
                {organisationIntegration.integration.name}
              </Typography>

              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  handleRemove(
                    organisationIntegration.integration.integrationId
                  )
                }
                disabled={removeLoading}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>

            <Formik<{
              foreignAccountId: string | null;
              settings: string;
            }>
              initialValues={{
                foreignAccountId:
                  organisationIntegration.foreignAccountId || null,
                settings: JSON.stringify(
                  organisationIntegration.settings,
                  null,
                  2
                ),
              }}
              onSubmit={async values => {
                await handleUpdate(
                  organisationIntegration.integration.integrationId,
                  {
                    foreignAccountId: values.foreignAccountId,
                    settings: JSON.parse(values.settings),
                  }
                );
              }}
              enableReinitialize
            >
              {formikProps => (
                <Form>
                  <Stack direction="column" spacing={1}>
                    {organisationIntegration.integration
                      .isForeignAccountIdRequired && (
                      <TextField
                        label={t("Foreign account ID", {
                          ns: "OrganisationSettings",
                        })}
                        name="foreignAccountId"
                        required
                      />
                    )}
                    <TextField
                      label={t("Settings", {
                        ns: "OrganisationSettings",
                      })}
                      name="settings"
                      required
                      multiline
                      maxRows={12}
                    />
                    <ManualSave
                      onCancel={formikProps.handleReset}
                      disabled={formikProps.isSubmitting}
                      disabledCancel={!formikProps.dirty}
                    />
                  </Stack>
                </Form>
              )}
            </Formik>

            {organisationIntegration.integration.id === "meister1" && (
              <>
                <Box>
                  <ModalOpenButton Modal={InspectM1LeadResponseModal}>
                    <Button
                      size="small"
                      color="secondary"
                      endIcon={<NavigateNextIcon />}
                    >
                      {t("Inspect lead response", {
                        ns: "OrganisationSettings",
                      })}
                    </Button>
                  </ModalOpenButton>
                </Box>
                <Box>
                  <Button
                    size="small"
                    color="secondary"
                    endIcon={<OpenInNewIcon />}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${import.meta.env.VITE_M1_MY_URL}/campaigns`}
                  >
                    {t("Go to campaigns settings", {
                      ns: "OrganisationSettings",
                    })}
                  </Button>
                </Box>
              </>
            )}

            <Divider />
          </Stack>
        ))}

        <Box px={1}>
          <ModalOpenButton
            Modal={AddNewIntegrationModal}
            modalProps={{
              availableIntegrations,
              handleComplete: async ({ integrationId, foreignAccountId }) => {
                if (!integrationId) return;
                await handleAdd({ integrationId, foreignAccountId });
              },
            }}
          >
            <Button
              type="button"
              color="secondary"
              size="small"
              startIcon={<AddIcon />}
              disabled={availableIntegrations.length === 0 || addLoading}
            >
              {t("Add new integration", {
                ns: "OrganisationSettings",
              })}
            </Button>
          </ModalOpenButton>
        </Box>
      </Stack>
    </CardContainer>
  );
};

const AddNewIntegrationModal = ({
  availableIntegrations,
  handleClose,
  handleComplete,
}: {
  handleClose: () => void;
  handleComplete: (values: {
    integrationId: IntegrationId | null;
    foreignAccountId: string | null;
  }) => void | Promise<void>;
  availableIntegrations: OrganisationIntegrationsBox_IntegrationFragment[];
}) => {
  const { t } = useTranslate(["Global", "OrganisationSettings"]);
  const formId = React.useMemo(() => uniqueId(), []);
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        integration: Yup.object()
          .shape({
            id: Yup.string()
              .label(
                t("Integration name", {
                  ns: "OrganisationSettings",
                })
              )
              .required(),
            isForeignAccountIdRequired: Yup.boolean(),
          })
          .nullable()
          .required(),
        foreignAccountId: Yup.string()
          .label(
            t("Foreign account ID", {
              ns: "OrganisationSettings",
            })
          )
          .nullable()
          .test((value, context) => {
            if (
              context.parent.integration &&
              context.parent.integration.isForeignAccountIdRequired
            ) {
              return !!value;
            }
            return true;
          }),
      }),
    [t]
  );
  return (
    <Formik<{
      integration: {
        id: string;
        integrationId: IntegrationId;
        isForeignAccountIdRequired: boolean;
      } | null;
      foreignAccountId: string | null;
    }>
      validationSchema={validationSchema}
      initialValues={{
        integration: null,
        foreignAccountId: null,
      }}
      onSubmit={async values => {
        await handleComplete({
          foreignAccountId: values.foreignAccountId,
          integrationId: values.integration
            ? values.integration.integrationId
            : null,
        });
        handleClose();
      }}
    >
      {formikProps => {
        return (
          <Modal
            handleClose={handleClose}
            dialogProps={{ maxWidth: "xs" }}
            title={t("Add new integration", {
              ns: "OrganisationSettings",
            })}
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Submit", {
                  ns: "Global",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  loading: formikProps.isSubmitting,
                  disabled: !formikProps.dirty || !formikProps.isValid,
                },
              },
            ]}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                <SelectField
                  label={t("Integration name", {
                    ns: "OrganisationSettings",
                  })}
                  name="integrationId"
                  options={availableIntegrations.map(i => ({
                    value: i.id,
                    label: i.name,
                  }))}
                  required
                  onChange={event => {
                    const integration =
                      availableIntegrations.find(
                        i => i.id === event.target.value
                      ) ?? null;

                    formikProps.setValues(values => ({
                      ...values,
                      integration,
                      foreignAccountId: null,
                    }));
                  }}
                />
                {formikProps.values.integration &&
                  formikProps.values.integration.isForeignAccountIdRequired && (
                    <TextField
                      label={t("Foreign account ID", {
                        ns: "OrganisationSettings",
                      })}
                      name="foreignAccountId"
                      required
                    />
                  )}
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const InspectM1LeadResponseModal = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const { t } = useTranslate(["Global", "OrganisationSettings"]);
  const formId = React.useMemo(() => uniqueId(), []);
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        leadId: Yup.number()
          .label(
            t("Lead ID", {
              ns: "OrganisationSettings",
            })
          )
          .required(),
      }),
    [t]
  );
  const client = useApolloClient();
  const [response, setResponse] = React.useState<string | null>(null);

  return (
    <Formik<{
      leadId: number;
    }>
      validationSchema={validationSchema}
      initialValues={{
        leadId: 0,
      }}
      onSubmit={async values => {
        const result = await client.query<
          M1ApiLeadResponseQuery,
          M1ApiLeadResponseQueryVariables
        >({
          query: M1ApiLeadResponseDocument,
          variables: {
            leadId: values.leadId,
          },
        });

        console.log(result.data.m1ApiLeadResponse);

        setResponse(
          JSON.stringify(
            JSON.parse(result.data?.m1ApiLeadResponse) ?? null,
            null,
            2
          )
        );
      }}
    >
      {formikProps => (
        <Modal
          handleClose={handleClose}
          dialogProps={{ maxWidth: "md" }}
          title={t("Inspect Meister1 lead response", {
            ns: "OrganisationSettings",
          })}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Submit", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <FormattedIntegerField
                label={t("Lead ID", {
                  ns: "OrganisationSettings",
                })}
                name="leadId"
                required
              />
              {response && (
                <Box
                  position="relative"
                  padding={1}
                  paddingTop={4}
                  sx={{
                    backgroundColor: theme => theme.palette.grey[200],
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="pre"
                    fontFamily="monospace"
                    whiteSpace={"pre-wrap"}
                  >
                    {response}
                  </Typography>
                  <IconButton
                    size="small"
                    color="secondary"
                    sx={{ position: "absolute", top: "4px", right: "4px" }}
                    onClick={() => setResponse(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
