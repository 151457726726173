import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "@msys/ui";

export const PageFullScreenModal = ({
  children,
  title,
  headerActions,
  headerButtons,
  onCloseButtonClick,
  noPadding = false,
}: React.PropsWithChildren<{
  noPadding?: boolean;
  title?: ReactNode;
  onCloseButtonClick?: () => void;
  headerActions?: React.ComponentProps<typeof Modal>["headerActions"];
  headerButtons?: React.ComponentProps<typeof Modal>["headerButtons"];
}>) => {
  const navigate = useNavigate();

  return (
    <Modal
      dialogProps={{
        fullScreen: true,
      }}
      title={title}
      titleProps={{
        sx: {
          padding: "8px 12px",
        },
      }}
      handleClose={onCloseButtonClick ?? (() => navigate(-1))}
      headerActions={headerActions}
      headerButtons={headerButtons}
      contentProps={{
        sx: {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingTop: 0,
          ...(noPadding ? { padding: "0 !important" } : undefined),
        },
      }}
    >
      {children}
    </Modal>
  );
};
