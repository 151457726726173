import { gql } from "@apollo/client";
import { notNull } from "@msys/common";
import { groupBy } from "lodash";
import { ProjectCrmLinksLabelFragment } from "./helper.generated";
import { useRelationshipRoles } from "./useRelationshipRoles";

export const getProjectCrmLinksLabel = (
  project: ProjectCrmLinksLabelFragment,
  getRoleLabel: (value: string) => string | undefined
) => {
  const groupedContacts = groupBy(project.crmLinks, contact =>
    contact.__typename === "ProjectLinkCrmUser"
      ? contact.crmUser.id
      : contact.__typename === "ProjectLinkCrmOrganisation"
      ? contact.crmOrganisation.id
      : undefined
  );

  const labels = Object.values(groupedContacts)
    .map(contacts => {
      const contact = contacts[0];

      const label =
        contact.__typename === "ProjectLinkCrmUser"
          ? contact.crmUser.fullname
          : contact.__typename === "ProjectLinkCrmOrganisation"
          ? contact.crmOrganisation.title
          : null;

      if (label === null) return null;

      const roles = contacts
        .sort((a, b) => a.linkAs.localeCompare(b.linkAs))
        .map(contact => getRoleLabel(contact.linkAs) ?? contact.linkAs)
        .join(", ");

      return `${label} (${roles})`;
    })
    .filter(notNull);

  return labels.length > 0 ? labels.join(", ") : "–";
};

function isCrmCompanyLink(
  link: ProjectCrmLinksLabelFragment["crmLinks"][0]
): link is Extract<
  ProjectCrmLinksLabelFragment["crmLinks"][0],
  { crmOrganisation: any }
> {
  return link.__typename === "ProjectLinkCrmOrganisation";
}

function isCrmPersonLink(
  link: ProjectCrmLinksLabelFragment["crmLinks"][0]
): link is Extract<
  ProjectCrmLinksLabelFragment["crmLinks"][0],
  { crmUser: any }
> {
  return link.__typename === "ProjectLinkCrmUser";
}
