/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateBillOfMaterialsPdfQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  filterStatus?: Types.InputMaybe<Array<Types.BillOfMaterialsItemStatus>>;
  filterType?: Types.InputMaybe<Array<Types.BillOfMaterialsItemType>>;
  aggregateProducts: Types.Scalars['Boolean']['input'];
  filterQuoteId?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterQuoteItemStatus?: Types.InputMaybe<Types.BillOfMaterialsQuoteItemStatusFilter>;
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sections?: Types.InputMaybe<Array<Types.BillOfMaterialsSection>>;
  fields?: Types.InputMaybe<Array<Types.BillOfMaterialsField>>;
}>;


export type GenerateBillOfMaterialsPdfQuery = { billOfMaterialsGeneratePdf: { __typename: 'BillOfMaterialsGeneratePdfResult', file?: { __typename: 'AttachmentSnapshot', id: string, url: string } | null } | { __typename: 'MissingPermissions' } };


export const GenerateBillOfMaterialsPdfDocument = gql`
    query GenerateBillOfMaterialsPdf($projectId: ID!, $filterStatus: [BillOfMaterialsItemStatus!], $filterType: [BillOfMaterialsItemType!], $aggregateProducts: Boolean!, $filterQuoteId: [ID!], $filterQuoteItemStatus: BillOfMaterialsQuoteItemStatusFilter, $filterSearchTerm: String, $sections: [BillOfMaterialsSection!], $fields: [BillOfMaterialsField!]) {
  billOfMaterialsGeneratePdf(
    projectId: $projectId
    itemStatus: $filterStatus
    itemType: $filterType
    aggregateProducts: $aggregateProducts
    quoteId: $filterQuoteId
    quoteItemStatus: $filterQuoteItemStatus
    filterSearchTerm: $filterSearchTerm
    sections: $sections
    fields: $fields
  ) {
    ... on BillOfMaterialsGeneratePdfResult {
      file {
        id
        url
      }
    }
  }
}
    `;

/**
 * __useGenerateBillOfMaterialsPdfQuery__
 *
 * To run a query within a React component, call `useGenerateBillOfMaterialsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateBillOfMaterialsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateBillOfMaterialsPdfQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      filterStatus: // value for 'filterStatus'
 *      filterType: // value for 'filterType'
 *      aggregateProducts: // value for 'aggregateProducts'
 *      filterQuoteId: // value for 'filterQuoteId'
 *      filterQuoteItemStatus: // value for 'filterQuoteItemStatus'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *      sections: // value for 'sections'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useGenerateBillOfMaterialsPdfQuery(baseOptions: Apollo.QueryHookOptions<GenerateBillOfMaterialsPdfQuery, GenerateBillOfMaterialsPdfQueryVariables> & ({ variables: GenerateBillOfMaterialsPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateBillOfMaterialsPdfQuery, GenerateBillOfMaterialsPdfQueryVariables>(GenerateBillOfMaterialsPdfDocument, options);
      }
export function useGenerateBillOfMaterialsPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateBillOfMaterialsPdfQuery, GenerateBillOfMaterialsPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateBillOfMaterialsPdfQuery, GenerateBillOfMaterialsPdfQueryVariables>(GenerateBillOfMaterialsPdfDocument, options);
        }
export function useGenerateBillOfMaterialsPdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateBillOfMaterialsPdfQuery, GenerateBillOfMaterialsPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateBillOfMaterialsPdfQuery, GenerateBillOfMaterialsPdfQueryVariables>(GenerateBillOfMaterialsPdfDocument, options);
        }
export type GenerateBillOfMaterialsPdfQueryHookResult = ReturnType<typeof useGenerateBillOfMaterialsPdfQuery>;
export type GenerateBillOfMaterialsPdfLazyQueryHookResult = ReturnType<typeof useGenerateBillOfMaterialsPdfLazyQuery>;
export type GenerateBillOfMaterialsPdfSuspenseQueryHookResult = ReturnType<typeof useGenerateBillOfMaterialsPdfSuspenseQuery>;
export type GenerateBillOfMaterialsPdfQueryResult = Apollo.QueryResult<GenerateBillOfMaterialsPdfQuery, GenerateBillOfMaterialsPdfQueryVariables>;