import { useApolloClient } from "@apollo/client";
import { getDataOrNull, notNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  ErrorMessage,
  FormattedPrice,
  GridColDef,
  LoadingSpinner,
  StateStore,
  useFormatting,
} from "@msys/ui";
import { Button, Stack, TablePagination, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  OrganisationProjectsSortBy,
  OrganisationProjectsSorting,
} from "../../../clients/graphqlTypes";
import { useUserData } from "../../auth/useUserData";
import { EntityNumber } from "../../commons/EntityNumber";
import { CollectionView } from "../../commons/hooks/useCollectionView";
import { RESULTS_PER_PAGE_OPTIONS } from "../../constants";
import { getAddressLabel } from "../addresses/helpers";
import { getProjectCrmLinksLabel } from "../contact-links/helper";
import { useRelationshipRoles } from "../contact-links/useRelationshipRoles";
import { useGetCustomFieldValueAsDisplayContent } from "../custom-fields/CustomFieldsBox";
import { useCustomFieldConfig } from "../custom-fields/useCustomFieldConfig";
import { getPersonLabel } from "../users/helpers";
import { ProjectPhaseChip } from "./ProjectPhaseChip";
import {
  ProjectsListQueryVariables,
  ProjectsList_ProjectFragment,
  useProjectsListQuery,
} from "./ProjectsList.generated";
import { TicketUrgentBadge } from "./TicketStatusBadge";
import { ProjectStatusBadge } from "./badges/ProjectStatusBadge";
import {
  ProjectListItem,
  ProjectListItemDisplayConfig,
} from "./components/ProjectListItem";
import { ProjectSourceCreatedBy } from "./components/ProjectSourceCreatedBy";
import { ProjectSourceEntity } from "./components/ProjectSourceEntity";

export const DEFAULT_SORTING: OrganisationProjectsSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];
export type QuickFilterValue = "ALL" | "PROJECTS" | "TICKETS";
export type Filters = Omit<
  ProjectsListQueryVariables,
  "offset" | "limit" | "sorting"
>;

interface Props {
  sorting: OrganisationProjectsSorting[];
  setSorting: React.Dispatch<
    React.SetStateAction<OrganisationProjectsSorting[]>
  >;
  activeView: CollectionView;
  pathToPage: string;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  quickFilter?: QuickFilterValue;
  showStatus?: boolean;
  showPhase?: boolean;
  stateStore: StateStore;
  variables: ProjectsListQueryVariables;
  displayConfig: ProjectListItemDisplayConfig;
}

export function ProjectsList({
  sorting,
  setSorting,
  activeView,
  pathToPage,
  paginationModel,
  setPaginationModel,
  quickFilter,
  showStatus = true,
  showPhase = false,
  stateStore,
  variables,
  displayConfig,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslate(["Projects", "Tickets", "Global"]);
  const viewer = useUserData().currentUser!;
  const { getFormattedDate, getFormattedDateTime } = useFormatting();

  const { getRoleLabel } = useRelationshipRoles();

  const getCustomFieldValueAsDisplayContent =
    useGetCustomFieldValueAsDisplayContent();
  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  const client = useApolloClient();
  const query = useProjectsListQuery({
    client,
    variables,
  });
  const projects =
    getDataOrNull(
      query.data?.projects ?? query.previousData?.projects
    )?.edges?.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull(query.data?.projects ?? query.previousData?.projects)
      ?.totalCount ?? 0;
  const phases = query.data?.organisationSettings.projectPhases;

  const columns = React.useMemo(
    (): GridColDef<ProjectsList_ProjectFragment>[] => [
      {
        field: "number",
        headerName: t("No.", {
          ns: "Global",
        }),
        minWidth: 60,
        flex: 0.75,
        renderCell: ({ row: project }) => (
          <EntityNumber noLabel number={project.number} />
        ),
      },
      {
        field: "phase",
        headerName: t("Phase", { ns: "Opportunities" }),
        editable: false,
        sortable: true,
        flex: 1,
        minWidth: 100,
        renderCell: ({ row: project }) => (
          <ProjectPhaseChip
            projectId={project.id}
            currentState={project.state}
            currentPhase={project.phase}
            availablePhases={phases}
          />
        ),
      },
      {
        field: "title",
        headerName: t("Title", {
          ns: "Projects",
        }),
        minWidth: 100,
        flex: 3,
        renderCell: ({ row: project }) => (
          <>
            {project.title}
            {project.urgent && (
              <span
                style={{
                  display: "inline-flex",
                  marginLeft: "8px",
                  verticalAlign: "middle",
                }}
              >
                <TicketUrgentBadge small />
              </span>
            )}
          </>
        ),
      },
      {
        field: "organisationName",
        headerName: viewer.organisation.isClientOrganisation
          ? t("Organisation", {
              ns: "Projects",
            })
          : t("Client", {
              ns: "Projects",
            }),
        minWidth: 100,
        flex: 2,
        renderCell: ({ row: project }) =>
          project.crmOrganisation?.title || t("Not set", { ns: "Global" }),
      },
      {
        field: "buildingStreet",
        headerName: t("Address", {
          ns: "Projects",
        }),
        minWidth: 100,
        flex: 2,
        renderCell: ({ row: project }) =>
          project.address
            ? getAddressLabel(project.address)
            : t("Not set", {
                ns: "Global",
              }),
      },
      {
        field: "source",
        headerName: t("Source", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        hideable: true,
        sortable: false,
        renderCell: ({ row: project }) => (
          <ProjectSourceEntity
            projectSource={project.source}
            projectId={project.id}
          />
        ),
      },
      {
        field: "createdBy",
        headerName: t("Created by", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        sortable: true,
        renderCell: ({ row: project }) => (
          <ProjectSourceCreatedBy projectSource={project.source} />
        ),
      },
      {
        field: "createdAt",
        headerName: t("Created at", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        valueGetter: ({ row: project }) =>
          project.createdAt ? new Date(project.createdAt) : null,
        renderCell: ({ row: project }) =>
          project.createdAt ? getFormattedDateTime(project.createdAt) : "-",
      },
      {
        field: "deadline",
        headerName: t("Deadline", { ns: "Projects" }),
        minWidth: 100,
        flex: 1,
        renderCell: ({ row: project }) =>
          project.deadline ? (
            <Typography
              color={project.overdue ? "error" : undefined}
              component="span"
              variant="body2"
            >
              {getFormattedDate(project.deadline)}
            </Typography>
          ) : (
            "–"
          ),
      },
      {
        field: "minBudget",
        headerName: t("Budget", {
          ns: "Projects",
        }),
        minWidth: 100,
        flex: 1,
        renderCell: ({ row: project }) => (
          <FormattedPrice value={project.budget} />
        ),
      },
      {
        field: "state",
        headerName: t("Status", { ns: "Projects" }),
        minWidth: 80,
        sortable: true,
        flex: 1,
        renderCell: ({ row: project }) => (
          <ProjectStatusBadge projectState={project.state} />
        ),
      },
      {
        field: "crmLinks",
        headerName: t("Other contacts", { ns: "Projects" }),
        minWidth: 100,
        sortable: false,
        flex: 1,
        renderCell: ({ row: project }) =>
          getProjectCrmLinksLabel(project, getRoleLabel),
      },
      ...(quickFilter === "PROJECTS"
        ? customFieldConfigs
            .filter(e => e.showAsColumnInList)
            .map(e => {
              return {
                field: `custom:${e.key}`,
                headerName: e.key,
                minWidth: 100,
                sortable: false,
                flex: 1,
                renderCell: ({ row: project }) => {
                  const field = project.customFields.find(f => f.key === e.key);
                  return getCustomFieldValueAsDisplayContent(
                    field
                      ? {
                          dataType: field.dataType,
                          key: field.key,
                          value: JSON.parse(field.value),
                        }
                      : undefined
                  );
                },
              } as GridColDef<ProjectsList_ProjectFragment>;
            })
        : []),
      ...(quickFilter === "TICKETS" &&
      viewer.organisation.capabilities.includes("PLANNING")
        ? [
            {
              field: "assignees",
              headerName: t("Resources", { ns: "Projects" }),
              minWidth: 100,
              flex: 2,
              sortable: false,
              renderCell: ({ row: ticket }) => {
                if (!ticket.assignees || ticket.assignees.length === 0) {
                  return (
                    <Button
                      size="extra-small"
                      color="primary"
                      variant="outlined"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.stopPropagation();
                      }}
                      component={Link}
                      to={{
                        pathname: `/planning/projects/${ticket.id}`,
                        search: new URLSearchParams({
                          r: location.pathname + location.search,
                        }).toString(),
                      }}
                    >
                      {t("Plan", {
                        ns: "Projects",
                      })}
                    </Button>
                  );
                }

                return (
                  <span>
                    {ticket.assignees
                      .map(assignee =>
                        assignee ? getPersonLabel(assignee, false) : null
                      )
                      .filter(notNull)
                      .join(", ")}
                  </span>
                );
              },
            } as GridColDef<ProjectsList_ProjectFragment>,
          ]
        : []),
      {
        field: "leadId",
        headerName: t("Lead ID", { ns: "Projects" }),
        flex: 1,
        hideable: true,
        sortable: false,
        valueGetter: ({ row: project }) => project.leadId ?? "-",
      },
    ],
    [
      t,
      viewer.organisation.isClientOrganisation,
      viewer.organisation.capabilities,
      quickFilter,
      customFieldConfigs,
      phases,
      getFormattedDateTime,
      getFormattedDate,
      getRoleLabel,
      getCustomFieldValueAsDisplayContent,
      location.pathname,
      location.search,
    ]
  );

  switch (activeView) {
    case "table": {
      return (
        <DataGrid
          stateStore={stateStore}
          loading={query.loading}
          hideFooter={totalCount === 0}
          columns={columns}
          rows={projects}
          sortModel={sorting.map(s => ({
            field: s.column,
            sort: s.direction,
          }))}
          onSortModelChange={newModel => {
            setSorting(
              newModel.map(({ field, sort }) => ({
                column: field as OrganisationProjectsSortBy,
                direction: sort ?? "asc",
              }))
            );
          }}
          onRowClick={({ row: project }) => {
            navigate(`/projects/${project.id}/overview`);
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
          initialState={{
            columns: {
              columnVisibilityModel: {
                source: false,
                ...(!showStatus ? { status: false } : {}),
              },
            },
          }}
        />
      );
    }
    case "list": {
      if (query.loading) return <LoadingSpinner />;
      if (totalCount === 0)
        return (
          <ErrorMessage
            message={t("There are no items to display", {
              ns: "Global",
            })}
          />
        );
      return (
        <Stack direction="column" spacing={1}>
          {projects.map(project => (
            <CardItem
              key={project.id}
              //@ts-ignore
              component={Link}
              to={`/projects/${project.id}/overview`}
            >
              <ProjectListItem
                project={project}
                showStatus={showStatus}
                showPhase={showPhase}
                availablePhases={phases}
                displayConfig={displayConfig}
              />
            </CardItem>
          ))}
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", { ns: "Global" })}
          />
        </Stack>
      );
    }
    default:
      return null;
  }
}
