/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserNotificationSettingsBox_UserFragment = { __typename: 'User', id: string, notifyEventsByEmail: Array<string> };

export type UpdateUserNotificationSettingsMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
  notifyEventsByEmail: Array<Types.Scalars['String']['input']>;
}>;


export type UpdateUserNotificationSettingsMutation = { modifyOrganisationMembership: { __typename: 'ModifyOrganisationMembershipResult', member: { __typename: 'User', id: string, notifyEventsByEmail: Array<string> } } };

export const UserNotificationSettingsBox_UserFragmentDoc = gql`
    fragment UserNotificationSettingsBox_User on User {
  id
  notifyEventsByEmail
}
    `;
export const UpdateUserNotificationSettingsDocument = gql`
    mutation UpdateUserNotificationSettings($userId: ID!, $notifyEventsByEmail: [String!]!) {
  modifyOrganisationMembership(
    input: {memberId: $userId, notifyEventsByEmail: $notifyEventsByEmail}
  ) {
    member {
      id
      notifyEventsByEmail
    }
  }
}
    `;
export type UpdateUserNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>;

/**
 * __useUpdateUserNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationSettingsMutation, { data, loading, error }] = useUpdateUserNotificationSettingsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      notifyEventsByEmail: // value for 'notifyEventsByEmail'
 *   },
 * });
 */
export function useUpdateUserNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>(UpdateUserNotificationSettingsDocument, options);
      }
export type UpdateUserNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationResult = Apollo.MutationResult<UpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserNotificationSettingsMutation, UpdateUserNotificationSettingsMutationVariables>;