import { BackdropModal } from "../gallery/BackdropModal";
import { ThreeDViewer } from "./ThreeDViewer";

interface Props {
  modelUrl: string;
  handleClose: () => void;
  /*
   * Use Modal as a standalone modal which is not pushing something to stack which involves closing other modals
   * Useful to show small modal on top of existing one without closin it
   */
  notInStack?: boolean;
  arButtonTitle?: string;
}

export function ThreeDViewModal({
  modelUrl,
  handleClose,
  notInStack,
  arButtonTitle,
}: Props) {
  return (
    <BackdropModal handleClose={handleClose} notInStack={notInStack}>
      <ThreeDViewer
        modelUrl={modelUrl}
        cameraControls={true}
        ar={true}
        autoRotate={true}
        arButtonTitle={arButtonTitle}
        fullScreen={true}
      />
    </BackdropModal>
  );
}
