import { Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { CrmCompanySelect } from "../CrmCompanySelect";

interface Props
  extends Omit<
    React.ComponentProps<typeof CrmCompanySelect>,
    "onChange" | "crmCompanyId"
  > {
  title: string;
  handleClose: () => void;
  handleComplete: (crmCompanyId: string, handleClose: () => void) => void;
  initialValue?: string | null;
}

export const CrmCompanySelectModal = ({
  initialValue,
  title,
  handleClose,
  handleComplete,
  ...props
}: Props) => {
  const { t } = useTranslate(["Global"]);

  const [selectedCrmCompanyId, setSelectedCrmCompanyId] = React.useState<
    string | null
  >(initialValue ?? null);

  return (
    <Modal
      title={title}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", { ns: "Global" }),
          handleClick: () => {
            if (!selectedCrmCompanyId)
              throw new Error("No crm company selected");
            handleComplete(selectedCrmCompanyId, handleClose);
          },
          buttonProps: { disabled: !selectedCrmCompanyId },
        },
      ]}
    >
      <CrmCompanySelect
        onChange={crmCompany => {
          setSelectedCrmCompanyId(crmCompany?.id ?? null);
        }}
        crmCompanyId={selectedCrmCompanyId}
        {...props}
      />
    </Modal>
  );
};
