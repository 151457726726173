/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PropertyMappingSection_MappingsFragmentDoc } from './PropertyMappingSection.generated';
import { Props2MappingAllFragmentDoc } from '../../doc-items/properties.generated';
import { DocumentStatus_DocumentFragmentDoc, ProposedCalculations_ItemFragmentDoc } from '../../doc-items/hooks/useDecisionItemMutations.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PropertyMappingExpressionModalQueryVariables = Types.Exact<{
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
}>;


export type PropertyMappingExpressionModalQuery = { item: { __typename: 'Item', id: string, originVersionNumber?: number | null, propertyMappings: Array<{ __typename: 'PropertyMapping', from: { __typename: 'PropertyMappingFrom', expr: string }, to: { __typename: 'PropertyMappingTo', key: string } }> } | { __typename: 'MissingPermissions' } };

export type SetItemProps2MappingsMutationVariables = Types.Exact<{
  input: Types.SetItemProps2MappingsInput;
}>;


export type SetItemProps2MappingsMutation = { setItemProps2Mappings: { __typename: 'SetItemProps2MappingsResult', doc: { __typename: 'Building', id: string } | { __typename: 'Quote', id: string, isPublished: boolean, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement } | { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, containsUnpublishedChanged: boolean } | { __typename: 'Requirement', id: string, isPublished: boolean }, item: { __typename: 'Item', id: string, originVersionNumber?: number | null, propertyMappings: Array<{ __typename: 'PropertyMapping', from: { __typename: 'PropertyMappingFrom', expr: string }, to: { __typename: 'PropertyMappingTo', key: string } }> } } };


export const PropertyMappingExpressionModalDocument = gql`
    query PropertyMappingExpressionModal($docId: ID!, $itemId: ID!) {
  item(docId: $docId, itemId: $itemId) {
    ... on Item {
      id
      originVersionNumber
      propertyMappings {
        ...PropertyMappingSection_Mappings
      }
    }
  }
}
    ${PropertyMappingSection_MappingsFragmentDoc}`;

/**
 * __usePropertyMappingExpressionModalQuery__
 *
 * To run a query within a React component, call `usePropertyMappingExpressionModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyMappingExpressionModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyMappingExpressionModalQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function usePropertyMappingExpressionModalQuery(baseOptions: Apollo.QueryHookOptions<PropertyMappingExpressionModalQuery, PropertyMappingExpressionModalQueryVariables> & ({ variables: PropertyMappingExpressionModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertyMappingExpressionModalQuery, PropertyMappingExpressionModalQueryVariables>(PropertyMappingExpressionModalDocument, options);
      }
export function usePropertyMappingExpressionModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertyMappingExpressionModalQuery, PropertyMappingExpressionModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertyMappingExpressionModalQuery, PropertyMappingExpressionModalQueryVariables>(PropertyMappingExpressionModalDocument, options);
        }
export function usePropertyMappingExpressionModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PropertyMappingExpressionModalQuery, PropertyMappingExpressionModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PropertyMappingExpressionModalQuery, PropertyMappingExpressionModalQueryVariables>(PropertyMappingExpressionModalDocument, options);
        }
export type PropertyMappingExpressionModalQueryHookResult = ReturnType<typeof usePropertyMappingExpressionModalQuery>;
export type PropertyMappingExpressionModalLazyQueryHookResult = ReturnType<typeof usePropertyMappingExpressionModalLazyQuery>;
export type PropertyMappingExpressionModalSuspenseQueryHookResult = ReturnType<typeof usePropertyMappingExpressionModalSuspenseQuery>;
export type PropertyMappingExpressionModalQueryResult = Apollo.QueryResult<PropertyMappingExpressionModalQuery, PropertyMappingExpressionModalQueryVariables>;
export const SetItemProps2MappingsDocument = gql`
    mutation SetItemProps2Mappings($input: SetItemProps2MappingsInput!) {
  setItemProps2Mappings(input: $input) {
    doc {
      ... on WithDocumentInfo {
        id
      }
      ... on QuoteTemplate {
        resolvedAsReadModelVersionNumber
      }
      ...DocumentStatus_Document
    }
    item {
      __typename
      id
      originVersionNumber
      propertyMappings {
        ...Props2MappingAll
      }
    }
  }
}
    ${DocumentStatus_DocumentFragmentDoc}
${Props2MappingAllFragmentDoc}`;
export type SetItemProps2MappingsMutationFn = Apollo.MutationFunction<SetItemProps2MappingsMutation, SetItemProps2MappingsMutationVariables>;

/**
 * __useSetItemProps2MappingsMutation__
 *
 * To run a mutation, you first call `useSetItemProps2MappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetItemProps2MappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setItemProps2MappingsMutation, { data, loading, error }] = useSetItemProps2MappingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetItemProps2MappingsMutation(baseOptions?: Apollo.MutationHookOptions<SetItemProps2MappingsMutation, SetItemProps2MappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetItemProps2MappingsMutation, SetItemProps2MappingsMutationVariables>(SetItemProps2MappingsDocument, options);
      }
export type SetItemProps2MappingsMutationHookResult = ReturnType<typeof useSetItemProps2MappingsMutation>;
export type SetItemProps2MappingsMutationResult = Apollo.MutationResult<SetItemProps2MappingsMutation>;
export type SetItemProps2MappingsMutationOptions = Apollo.BaseMutationOptions<SetItemProps2MappingsMutation, SetItemProps2MappingsMutationVariables>;