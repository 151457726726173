import { DEFAULT_END_WORK_DAY, DEFAULT_START_WORK_DAY } from "@msys/common";
import {
  ellipsisStyle,
  ListItem,
  ListItemAction,
  ListItemWrapper,
} from "@msys/ui";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
  ClientLine,
  DataItem,
  LocationLine,
  RangeLine,
} from "../../../commons/DataItem";
import { Scalars } from "../../../../clients/graphqlTypes";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated";
import {
  getAddressDirectionUrl,
  getAddressLabel,
} from "../../addresses/helpers";

interface Props {
  range: [moment.Moment, moment.Moment];
  planSession: {
    from: Scalars["DateTime"]["output"];
    till: Scalars["DateTime"]["output"];
    project: {
      id: string;
      title: string;
      building?: {
        buildingAddress?: AddressDetails__AddressFragment | null;
      } | null;
      contractee?: {
        title: string;
      } | null;
      contracteeCrm?: {
        title: string;
      } | null;
    };
  };
  organisationDefaults: {
    defaultStartWorkDay?: string | null;
    defaultEndWorkDay?: string | null;
  };
}

export const PlanSessionListItem = ({
  range,
  planSession,
  organisationDefaults,
}: Props) => {
  const { t } = useTranslate(["Dashboard", "DataItem"]);

  const contractee =
    planSession.project.contracteeCrm || planSession.project.contractee;

  const location = planSession.project.building?.buildingAddress
    ? getAddressLabel(planSession.project.building.buildingAddress)
    : undefined;

  const startWorkDay = organisationDefaults?.defaultStartWorkDay
    ? moment(organisationDefaults?.defaultStartWorkDay, "HH:mm:ssZ").format(
        "HH:mm"
      )
    : DEFAULT_START_WORK_DAY;
  const endWorkDay = organisationDefaults?.defaultEndWorkDay
    ? moment(organisationDefaults?.defaultEndWorkDay, "HH:mm:ssZ").format(
        "HH:mm"
      )
    : DEFAULT_END_WORK_DAY;

  const fromTime = moment(planSession.from).local().format("HH:mm");
  const tillTime = moment(planSession.till).local().format("HH:mm");

  const isUntil = moment(planSession.from) <= range[0];
  const isFrom = moment(planSession.till) >= range[1];
  const isWholeDay = isFrom && isUntil;

  const dateRange = isWholeDay
    ? `${startWorkDay} – ${endWorkDay}`
    : isFrom
    ? moment(fromTime, "HH:mm") < moment(endWorkDay, "HH:mm")
      ? `${fromTime} – ${endWorkDay}`
      : t("From {time}", { time: fromTime, ns: "Dashboard" })
    : isUntil
    ? moment(tillTime, "HH:mm") > moment(startWorkDay, "HH:mm")
      ? `${startWorkDay} – ${tillTime}`
      : t("Until {time}", { time: tillTime, ns: "Dashboard" })
    : `${fromTime} – ${tillTime}`;

  return (
    <ListItemWrapper
      $hasAction={!!planSession.project.building?.buildingAddress}
    >
      <ListItem
        component={Link}
        // @ts-ignore compound component
        to={`/projects/${planSession.project.id}`}
      >
        <Stack direction="column" spacing={0.5} minWidth={0}>
          <Typography variant={"h4"}>{planSession.project.title}</Typography>
          <Box
            gap={0.5}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            style={ellipsisStyle}
          >
            {location && (
              <Tooltip
                title={t("Location", { ns: "DataItem" })}
                arrow
                placement="bottom"
              >
                <div style={ellipsisStyle}>
                  <LocationLine>{location}</LocationLine>
                </div>
              </Tooltip>
            )}
            {contractee?.title && (
              <Tooltip
                title={t("Client", { ns: "DataItem" })}
                arrow
                placement="bottom"
              >
                <div style={ellipsisStyle}>
                  <ClientLine>{contractee.title}</ClientLine>
                </div>
              </Tooltip>
            )}
            {dateRange && (
              <Tooltip
                title={t("Planned session", { ns: "DataItem" })}
                arrow
                placement="top"
              >
                <div>
                  <RangeLine>{dateRange}</RangeLine>
                </div>
              </Tooltip>
            )}
          </Box>
        </Stack>
      </ListItem>
      {planSession.project.building?.buildingAddress ? (
        <ListItemAction>
          <Tooltip title={t("Direction", { ns: "Dashboard" })}>
            <IconButton
              size="small"
              color="primary"
              component="a"
              target="_blank"
              rel="noreferrer nofollow"
              href={getAddressDirectionUrl(
                planSession.project.building.buildingAddress!
              )}
            >
              <DirectionsIcon />
            </IconButton>
          </Tooltip>
        </ListItemAction>
      ) : null}
    </ListItemWrapper>
  );
};
