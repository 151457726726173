import { Button, ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useAuth } from "./useAuth";

interface Props {
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  prefill?: { email: string };
}

export const LoginButton = ({
  variant = "contained",
  color = "primary",
  prefill,
}: Props) => {
  const { auth } = useAuth();
  const { t } = useTranslate(["Global"]);

  return (
    <Button
      variant={variant}
      color={color}
      disableElevation
      fullWidth
      onClick={() => {
        auth.login({ loginHint: prefill?.email });
      }}
    >
      {t("Login", { ns: "Global" })}
    </Button>
  );
};
