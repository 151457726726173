import { StyledComponent } from "@emotion/styled";
import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Stack } from "../commons/layout/Stack";
import { color } from "../../common/MuiThemeProvider";

export const HeaderTitle: StyledComponent<TypographyProps> = styled(Typography)(
  ({ theme }) => ({
    fontWeight: 500,
    "& > b, & > strong": {
      color: color.danger,
    },
  })
);

export const SideTitle: StyledComponent<TypographyProps> = styled(Typography)(
  ({ theme }) => ({
    fontSize: "1.375rem",
    lineHeight: 1.2,
    "& > b, & > strong": {
      color: color.danger,
    },
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
      fontSize: "1.75rem",
      lineHeight: 1.2,
    },
  })
);

export const SideContainer: StyledComponent<
  React.ComponentProps<typeof Stack>
> = styled(Stack)({
  backgroundColor: color.blue1,
  backgroundImage: "url('/auth/auth-3d-bg.svg')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
});
