import React, { useImperativeHandle, useState } from "react";
import { PimSupplierDiscountGroupsImportationColumnMappingModal } from "./PimSupplierDiscountGroupsImportationColumnMappingModal";

export interface PimSupplierDiscountGroupsImportationMappingProcessRef {
  start: (attachment: { url?: string; filename: string }) => Promise<{
    delimiter: string;
    decimal: string;
    fieldMapping: {
      key: string;
      description: string | null;
      value: string;
      _multiplier: number;
    };
  } | null>;
}

interface Props {
  title?: string;
  supplierId: string;
}

export const PimSupplierDiscountGroupsImportationMappingProcess =
  React.forwardRef(({ title, supplierId }: Props, forwardedRef) => {
    const [csvAttachment, setCsvAttachment] = useState<{
      url?: string;
      filename: string;
    } | null>(null);

    const resolveRef = React.useRef<(value: unknown) => void>();

    useImperativeHandle(forwardedRef, () => ({
      start: (attachment: { url?: string; filename: string }) =>
        new Promise(resolve => {
          resolveRef.current = resolve;
          setCsvAttachment(attachment);
        }),
    }));

    return csvAttachment ? (
      <PimSupplierDiscountGroupsImportationColumnMappingModal
        csvUrl={csvAttachment.url}
        csvFilename={csvAttachment.filename}
        supplierId={supplierId}
        handleClose={() => setCsvAttachment(null)}
        handleComplete={async values => {
          resolveRef.current?.(values);
          resolveRef.current = undefined;
        }}
      />
    ) : null;
  });
