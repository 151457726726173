/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PendingChangesBoxQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
}>;


export type PendingChangesBoxQuery = { item: { __typename: 'Item', id: string, originVersionNumber?: number | null, pendingChangeAttributes: any } | { __typename: 'MissingPermissions' } };


export const PendingChangesBoxDocument = gql`
    query PendingChangesBox($projectId: ID!, $docId: ID!, $itemId: ID!) {
  item(projectId: $projectId, docId: $docId, itemId: $itemId) {
    ... on Item {
      id
      originVersionNumber
      pendingChangeAttributes
    }
  }
}
    `;

/**
 * __usePendingChangesBoxQuery__
 *
 * To run a query within a React component, call `usePendingChangesBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingChangesBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingChangesBoxQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function usePendingChangesBoxQuery(baseOptions: Apollo.QueryHookOptions<PendingChangesBoxQuery, PendingChangesBoxQueryVariables> & ({ variables: PendingChangesBoxQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingChangesBoxQuery, PendingChangesBoxQueryVariables>(PendingChangesBoxDocument, options);
      }
export function usePendingChangesBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingChangesBoxQuery, PendingChangesBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingChangesBoxQuery, PendingChangesBoxQueryVariables>(PendingChangesBoxDocument, options);
        }
export function usePendingChangesBoxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PendingChangesBoxQuery, PendingChangesBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PendingChangesBoxQuery, PendingChangesBoxQueryVariables>(PendingChangesBoxDocument, options);
        }
export type PendingChangesBoxQueryHookResult = ReturnType<typeof usePendingChangesBoxQuery>;
export type PendingChangesBoxLazyQueryHookResult = ReturnType<typeof usePendingChangesBoxLazyQuery>;
export type PendingChangesBoxSuspenseQueryHookResult = ReturnType<typeof usePendingChangesBoxSuspenseQuery>;
export type PendingChangesBoxQueryResult = Apollo.QueryResult<PendingChangesBoxQuery, PendingChangesBoxQueryVariables>;