import { CollapseSection, LabeledValue, FormattedPercentage } from "@msys/ui";
import { Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import { round } from "lodash";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField";
import {
  FormattedPriceField,
  FormattedPriceFieldNullable,
} from "../../commons/form-fields/FormattedPriceField";

export interface FormValues {
  netPrice?: number | null;
  listPrice?: number | null;
}

type Props = {
  gridColumnSpacing?: number;
  fieldName: string;
};

export function ProductPricingEdit({
  gridColumnSpacing = 2,
  fieldName,
}: Props) {
  const { t } = useTranslate("Product");

  const formikProps = useFormikContext<{
    [key: typeof fieldName]: FormValues;
  }>();

  return (
    <CollapseSection title={t("Pricing")}>
      <Grid
        container
        columns={2}
        rowSpacing={1}
        columnSpacing={gridColumnSpacing}
      >
        <Grid item xs={1} display={"flex"} justifyContent={"space-between"}>
          <FormattedPriceFieldNullable
            disabled={false}
            label={t("List Price")}
            name={`${fieldName}.listPrice`}
            min={0}
            required
          />
        </Grid>
        <Grid item xs={1} display={"flex"} justifyContent={"space-between"}>
          <LabeledValue label={t("Discount")}>
            <FormattedPercentage
              value={
                formikProps.values[fieldName]?.listPrice &&
                formikProps.values[fieldName]?.netPrice
                  ? round(
                      1 -
                        formikProps.values[fieldName].netPrice! /
                          formikProps.values[fieldName].listPrice!,
                      2
                    )
                  : undefined
              }
            />
          </LabeledValue>
        </Grid>
        <Grid item xs={1} display={"flex"} justifyContent={"space-between"}>
          <FormattedPriceFieldNullable
            disabled={false}
            label={t("Net Price")}
            name={`${fieldName}.netPrice`}
            min={0}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}
