import { useApolloClient } from "@apollo/client";
import { assertNever, getDataOrNull } from "@msys/common";
import {
  CardContainer,
  CollapseSection,
  LabeledValue,
  LabeledValueWithButton,
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
  TruncatedHtmlValue,
  ValueWithDiff,
  VisibilityIndicatorIcon,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FunctionsIcon from "@mui/icons-material/Functions";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  ListSubheader,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikConfig, useFormik } from "formik";
import { TextField as FormikTextField } from "formik-mui";
import { differenceWith, isNull, isUndefined, noop, partition } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";
import {
  DefineItemProductSearchFilterDefinitionsInput,
  DocType,
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
  ModifyItemProductValuesInput,
  Props2NonComputed,
  Visibility,
  namedOperations,
} from "../../../../clients/graphqlTypes";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import type { ViewMode } from "../../../commons/ViewModeMenuItem";
import { ViewModeMenuItem } from "../../../commons/ViewModeMenuItem";
import { VisibilityButton } from "../../../commons/VisibilityButton";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { ManualSave } from "../../../commons/form-fields/ManualSave";
import { RichTextField } from "../../../commons/form-fields/RichTextField";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { ALLOW_AI_FEATURE } from "../../../constants";
import { useSelectCrmSuppliersQuery } from "../../crm/CrmSuppliersSelect.generated";
import { ItemProductPropertiesSection } from "../../item-properties/ItemProductPropertiesSection";
import { LabeledPropertiesValue } from "../../item-properties/LabeledPropertiesValue";
import {
  PimProductPropertyFragment,
  PimProductTypePropertyTypeFragment,
  PimPropertyTypeFragment,
  ProductSearchItem__ProductSearchResultFragment,
} from "../../products/Product.generated";
import { ProductPricing } from "../../products/ProductPricing";
import {
  BoolFilterOperatorOptions,
  NumberFilterOperatorOptions,
  TextFilterOperatorOptions,
} from "../../products/ProductSearchFilterOperatorOptions";
import {
  OnProductSelectFn,
  SupplierProductPropertyMatchingProductAlternativesSelect,
} from "../../products/SupplierProductAlternativePicker";
import {
  SupplierProductAlternativePicker__PimSupplierProductAlternativesProductFragment,
  SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment,
} from "../../products/SupplierProductAlternativePicker.generated";
import { ProductCataloguePageButton } from "../../products/buttons/ProductCataloguePageButton";
import {
  ProductFiltersFormValues,
  productFiltersDefaultValue,
  productFiltersValidationSchema,
} from "../../products/filters/ProductFilterFields";
import { definitionsToFormValues } from "../../products/helper";
import { PickProductTypeModal } from "../../products/modals/PickProductTypeModal";
import { ProductCreateModal } from "../../products/modals/ProductCreateModal";
import { ProductFilterModal } from "../../products/modals/ProductFilterModal";
import { ProductSearchModal } from "../../products/modals/ProductSearchModal";
import {
  ItemProductSearchFilterBrandFilterComputedFragment,
  ItemProductSearchFilterBrandFilterFragment,
  ItemProductSearchFilterBrandLineFilterComputedFragment,
  ItemProductSearchFilterBrandLineFilterFragment,
  ItemProductSearchFilterDefinitionsFragment,
  ItemProductSearchFilterExpressionsFragment,
  ItemProductSearchFilterPriceFilterComputedFragment,
  ItemProductSearchFilterPriceFilterFragment,
  ItemProductSearchFilterPropertyFilterComputedFragment,
  ItemProductSearchFilterPropertyFilterFragment,
} from "../../products/productSearchFilters.generated";
import { SupplierSelect } from "../../suppliers/SupplierSelect";
import {
  QuestionControlSection,
  QuestionControlSectionFormValues,
} from "../QuestionControlSection";
import { AiRephraseMenuItem } from "../menus/AiRephraseMenuItem";
import { ProductSearchFilterExpressionModal } from "../modals/ProductSearchFilterExpressionModal";
import { propToDefineInput } from "../properties";
import {
  ProductSearchFilterPropertyFilterDefinitionToInputValue,
  useDefineItemProductSearchFilteDefinitionsMutation,
  useProductSearchFilterDefinitionMutations,
} from "../useProductSearchFilterDefinitionMutations";
import {
  HandleSetProductSearchFilterExpression,
  HandleSetProductSearchFilterPropertyExpression,
  useProductSearchFilterExpressionMutations,
} from "../useProductSearchFilterExpressionMutations";
import {
  ProductBox_ItemFragment,
  ProductBox_ProjectFragment,
  ProductSearchFiltersSection_ItemFragment,
  ProductSelectQuestionControlForm_ItemFragment,
  useProductBox_ModifyItemProductMutation,
  useProductBox_ProductQuery,
  useProductBox_RemoveItemProductMutation,
  useProductBox_SetItemProductMutation,
  useProductBox_SupplierProductAlternativesQuery,
  useProductSearchFiltersSection_ProductTypeLazyQuery,
  useProductSelectQuestionControlForm_ModifyWizardSettingsMutation,
} from "./ProductBox.generated";
import { ReactComponent as SapLogo } from "../../../assets/icons/sap-logo.svg";
import { S4HanaKmatProductConfigModal } from "../../sap-s4-hana/S4HanaKmatProductConfigureModal";
import {
  useHasSapS4HanaIntegration,
  useProductIsConfigurable,
} from "../../sap-s4-hana/hooks";

interface FormValues {
  productArticleNumber?: string | null;
  productSupplier?: { id: string; title: string } | null;
  productTitle?: string;
  productDescription?: string;
  productBrand?: string;
  productBrandLine?: string;
  productGtin?: string | null;
  productManufacturerArticleNumber?: string | null;
  productCategory?: string;
  productTypeId?: string | null;
  productTypeTitle?: string | null;
  productTitleClientVisibility: Visibility;
  productDescriptionClientVisibility: Visibility;
  productBrandClientVisibility: Visibility;
  productProps2: Props2NonComputed[];
}

interface Props {
  project: ProductBox_ProjectFragment | null;
  docId: string;
  docType: DocType;
  item: ProductBox_ItemFragment;
  isEditable?: boolean;
  viewerIsContractor?: boolean;
  isInitiallyClosed?: boolean;
  onUpdateDataRefetchQueries?: string[];
}

export const ProductBox = ({
  project,
  docId,
  docType,
  item,
  isEditable = true,
  viewerIsContractor = false,
  isInitiallyClosed,
  onUpdateDataRefetchQueries,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate([
    "QuoteItem",
    "Global",
    "Product",
    "ItemPropertyField",
    "SapS4Hana",
  ]);

  const { expandedItemIds } = useOutletContext<{
    expandedItemIds: string[] | undefined;
  }>();

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();

  const productArticleNumberIdentifier = React.useMemo(() => {
    if (!item) return null;

    const productArticleNumber = !isUndefined(
      item.pendingChangeAttributes["productArticleNumber"]
    )
      ? item.pendingChangeAttributes["productArticleNumber"]
      : item.product
        ? item.product.articleNumber
        : null;

    const productSupplierId = !isUndefined(
      item.pendingChangeAttributes["productSupplierId"]
    )
      ? item.pendingChangeAttributes["productSupplierId"]
      : item.product
        ? item.product.supplierId
        : null;

    if (productArticleNumber && productSupplierId) {
      return {
        articleNumber: productArticleNumber,
        supplierId: productSupplierId,
      };
    } else {
      return null;
    }
  }, [item]);

  const productBoxProductQuery = useProductBox_ProductQuery({
    client,
    variables: {
      articleNumber: productArticleNumberIdentifier?.articleNumber,
      supplierId: productArticleNumberIdentifier?.supplierId,
    },
    skip: !(productArticleNumberIdentifier && viewerIsContractor),
    fetchPolicy: "network-only", //network-only here, because else we get problems with the product field!
  });

  const supplierProductAlternativesQuery =
    useProductBox_SupplierProductAlternativesQuery({
      client,
      variables: {
        articleNumber: productArticleNumberIdentifier?.articleNumber,
        supplierId: productArticleNumberIdentifier?.supplierId,
      },
      skip: !(productArticleNumberIdentifier && viewerIsContractor),
      fetchPolicy: "cache-and-network",
    });

  const productAlternatives = getDataOrNull(
    supplierProductAlternativesQuery.data?.pimSupplierProductAlternatives
  )?.result;

  const [setItemProduct, { loading: setItemProductLoading }] =
    useProductBox_SetItemProductMutation({
      client,
    });

  const [removeItemProduct, { loading: removeItemProductLoading }] =
    useProductBox_RemoveItemProductMutation({
      client,
    });
  const removeProduct = async () => {
    await removeItemProduct({
      variables: {
        input: {
          projectId: project?.id,
          docId,
          itemId: item.id,
          expandedItemIds,
        },
      },
    });
  };

  const [modifyItemProduct, { loading: modifyItemProductLoading }] =
    useProductBox_ModifyItemProductMutation({
      client,
    });
  const modifyProduct = async (product: ModifyItemProductValuesInput) => {
    try {
      await modifyItemProduct({
        variables: {
          input: {
            projectId: project?.id,
            docId,
            itemId: item.id,
            values: product,
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const itemAgreed = item.agreement === "YES";

  const initialValues: FormValues = {
    productArticleNumber: !isUndefined(
      item.pendingChangeAttributes["productArticleNumber"]
    )
      ? item.pendingChangeAttributes["productArticleNumber"]
      : item.product?.articleNumber,
    productSupplier: isUndefined(
      item.pendingChangeAttributes["productSupplierId"]
    )
      ? item.product?.supplier
      : isNull(item.pendingChangeAttributes["productSupplierId"])
        ? null
        : { id: item.pendingChangeAttributes["productSupplierId"], title: "" },
    productTitle: !isUndefined(item.pendingChangeAttributes["productTitle"])
      ? item.pendingChangeAttributes["productTitle"]
      : item.product?.title,
    productDescription: !isUndefined(
      item.pendingChangeAttributes["productDescription"]
    )
      ? item.pendingChangeAttributes["productDescription"]
      : item.product?.description,
    productBrand: !isUndefined(item.pendingChangeAttributes["productBrand"])
      ? item.pendingChangeAttributes["productBrand"]
      : item.product?.brand,
    productBrandLine: !isUndefined(
      item.pendingChangeAttributes["productBrandLine"]
    )
      ? item.pendingChangeAttributes["productBrandLine"]
      : item.product?.brandLine,
    productGtin: !isUndefined(item.pendingChangeAttributes["productGtin"])
      ? item.pendingChangeAttributes["productGtin"]
      : item.product?.gtin,
    productManufacturerArticleNumber: !isUndefined(
      item.pendingChangeAttributes["productManufacturerArticleNumber"]
    )
      ? item.pendingChangeAttributes["productManufacturerArticleNumber"]
      : item.product?.manufacturerArticleNumber,
    productCategory: !isUndefined(
      item.pendingChangeAttributes["productCategory"]
    )
      ? item.pendingChangeAttributes["productCategory"]
      : item.product?.productCategory,
    productTypeId: !isUndefined(item.pendingChangeAttributes["productTypeId"])
      ? item.pendingChangeAttributes["productTypeId"]
      : item.product?.productTypeId,
    productTypeTitle: !isUndefined(
      item.pendingChangeAttributes["productTypeTitle"]
    )
      ? item.pendingChangeAttributes["productTypeTitle"]
      : item.product?.productTypeTitle,
    productTitleClientVisibility: !isUndefined(
      item.pendingChangeAttributes["productTitleClientVisibility"]
    )
      ? item.pendingChangeAttributes["productTitleClientVisibility"]
      : item.product?.titleClientVisibility,
    productDescriptionClientVisibility: !isUndefined(
      item.pendingChangeAttributes["productDescriptionClientVisibility"]
    )
      ? item.pendingChangeAttributes["productDescriptionClientVisibility"]
      : item.product?.descriptionClientVisibility,
    productBrandClientVisibility: !isUndefined(
      item.pendingChangeAttributes["productBrandClientVisibility"]
    )
      ? item.pendingChangeAttributes["productBrandClientVisibility"]
      : item.product?.brandClientVisibility,
    productProps2: !isUndefined(item.pendingChangeAttributes["productProps2"])
      ? item.pendingChangeAttributes["productProps2"]
      : item.product?.props2,
  };

  const hasProduct =
    (initialValues.productTitle && initialValues.productTitle.length > 0) ||
    initialValues.productArticleNumber;

  const pimCustomSupplierProduct = getDataOrNull(
    productBoxProductQuery?.data?.pimGetProduct
  )?.product;

  const isLoading =
    setItemProductLoading ||
    modifyItemProductLoading ||
    removeItemProductLoading;

  const productSearchFilters: ProductFiltersFormValues | undefined =
    item.__typename === "Item"
      ? definitionsToFormValues(item.productSearchFilterDefaults)
      : undefined;

  const [defineItemProductSearchFilteDefinitions] =
    useDefineItemProductSearchFilteDefinitionsMutation([
      namedOperations.Query.ProductBox_Product,
    ]);

  const onFiltersSave = async (values: ProductFiltersFormValues) => {
    await defineItemProductSearchFilteDefinitions({
      variables: {
        input: {
          projectId: project?.id,
          docId,
          itemId: item.id,
          ...filterFormValuesToDefinitionInput(values),
        },
      },
    });
  };

  const onProductSelect = async (
    product: Pick<
      ProductSearchItem__ProductSearchResultFragment,
      "articleNumber" | "supplierId"
    >,
    filters?: ProductFiltersFormValues
  ) => {
    try {
      await setItemProduct({
        variables: {
          input: {
            projectId: project?.id,
            docId,
            itemId: item.id,
            productArticleNumber: product.articleNumber,
            productSupplierId: product.supplierId,
            expandedItemIds,
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
    setViewMode(null);
  };

  const onProductCreate = async (values: FormValues) => {
    await removeProduct();
    await modifyProduct(productFormValuesToInput(values));
    setViewMode(null);
  };

  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();

  const isKmatProduct = useProductIsConfigurable({
    product: pimCustomSupplierProduct,
    projectId: project?.id,
    quoteId: docId,
  });

  return (
    <CardContainer
      isExpandable={true}
      title={t("Product", {
        ns: "QuoteItem",
      })}
      isInitiallyClosed={isInitiallyClosed}
      isLoading={isLoading}
      ActionButton={
        <Stack direction={"row"} spacing={0.5}>
          {hasSapS4HanaIntegration &&
            productArticleNumberIdentifier &&
            isKmatProduct &&
            project && (
              <ModalOpenButton
                Modal={S4HanaKmatProductConfigModal}
                modalProps={{
                  projectId: project.id,
                  quoteId: docId,
                  itemId: item.id,
                  productId: productArticleNumberIdentifier.articleNumber,
                  manufacturerArticleNumber:
                    initialValues?.productManufacturerArticleNumber,
                  netPrice:
                    pimCustomSupplierProduct?.pricing?.netPrice ??
                    item.materialBuyingPrice,
                  refetchQueries: onUpdateDataRefetchQueries,
                }}
              >
                <Tooltip title={t("Configure product", { ns: "SapS4Hana" })}>
                  <IconButton size="small" color="warning">
                    <Icon>
                      <SapLogo />
                    </Icon>
                  </IconButton>
                </Tooltip>
              </ModalOpenButton>
            )}
          {!isEditable ||
          viewMode === "edit" ||
          !(hasProduct || ALLOW_AI_FEATURE) ? null : (
            <Stack direction={"row"} spacing={1}>
              <MenuButton>
                {hasProduct && [
                  <ViewModeMenuItem
                    viewMode={viewMode}
                    onViewModeChange={setViewMode}
                    allowedModes={[null, "edit"]}
                    key="view-mode-item"
                  />,
                  <Divider key="view-mode-divider" />,
                ]}
                {ALLOW_AI_FEATURE && [
                  <AiRephraseMenuItem
                    itemId={item.id}
                    docId={docId}
                    projectId={project?.id ?? null}
                    key="ai-rephrase-item"
                  />,
                  <Divider key="ai-rephrase-divider" />,
                ]}
                {hasProduct && (
                  <RestrictedByCapabilityWithDebug
                    capability="PIM"
                    otherwise={
                      <ModalOpenButton
                        Modal={ProductCreateModal}
                        modalProps={{
                          handleSubmit: onProductCreate,
                        }}
                      >
                        <MenuItemWithIcon icon={<SearchIcon />}>
                          {t("Replace product", { ns: "QuoteItem" })}
                        </MenuItemWithIcon>
                      </ModalOpenButton>
                    }
                  >
                    <ModalOpenButton
                      Modal={ProductSearchModal}
                      modalProps={{
                        handleProductChoice: onProductSelect,
                        handleProductCreate: onProductCreate,
                        handleFiltersSave: onFiltersSave,
                        initialFilters: productSearchFilters,
                        canChangeProductType:
                          !item.productSearchFilterDefinitions
                            .productTypeIdFilter,
                      }}
                    >
                      <MenuItemWithIcon icon={<SearchIcon />}>
                        {t("Replace product", { ns: "QuoteItem" })}
                      </MenuItemWithIcon>
                    </ModalOpenButton>
                  </RestrictedByCapabilityWithDebug>
                )}
                {hasProduct && (
                  <ModalOpenButton
                    Modal={ConfirmModal}
                    modalProps={{
                      title: t("Confirm deletion", { ns: "Global" }),
                      text: t(
                        "Deletion cannot be undone. Do you want to continue?",
                        {
                          ns: "Global",
                        }
                      ),
                      handleConfirm: async () => {
                        await removeProduct();
                        setViewMode(null);
                      },
                    }}
                  >
                    <MenuItemWithIcon icon={<DeleteIcon />}>
                      {t("Remove product", { ns: "QuoteItem" })}
                    </MenuItemWithIcon>
                  </ModalOpenButton>
                )}
              </MenuButton>
              {!hasProduct && (
                <ModalOpenButton
                  Modal={ProductSearchModal}
                  modalProps={{
                    handleProductChoice: onProductSelect,
                    handleProductCreate: onProductCreate,
                    handleFiltersSave: onFiltersSave,
                    initialFilters: productSearchFilters,
                    canChangeProductType:
                      !item.productSearchFilterDefinitions.productTypeIdFilter,
                  }}
                >
                  <IconButton size={"small"} color={"primary"}>
                    <AddIcon />
                  </IconButton>
                </ModalOpenButton>
              )}
            </Stack>
          )}
        </Stack>
      }
    >
      <Box padding={1}>
        {!hasProduct ? (
          isEditable ? (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="center"
            >
              <RestrictedByCapabilityWithDebug
                capability="PIM"
                otherwise={
                  <ModalOpenButton
                    Modal={ProductCreateModal}
                    modalProps={{
                      handleSubmit: onProductCreate,
                    }}
                  >
                    <Button startIcon={<AddIcon />} color={"secondary"}>
                      {t("Add product", {
                        ns: "QuoteItem",
                      })}
                    </Button>
                  </ModalOpenButton>
                }
              >
                <ModalOpenButton
                  Modal={ProductSearchModal}
                  modalProps={{
                    handleProductChoice: onProductSelect,
                    handleProductCreate: onProductCreate,
                    handleFiltersSave: onFiltersSave,
                    initialFilters: productSearchFilters,
                    canChangeProductType:
                      !item.productSearchFilterDefinitions.productTypeIdFilter,
                  }}
                >
                  <Button startIcon={<AddIcon />} color={"secondary"}>
                    {t("Add product", {
                      ns: "QuoteItem",
                    })}
                  </Button>
                </ModalOpenButton>
              </RestrictedByCapabilityWithDebug>
              {!item.productSearchFilterDefinitions.productTypeIdFilter && (
                <RestrictedByCapabilityWithDebug capability="PIM">
                  <Typography>{t("or", { ns: "Global" })}</Typography>
                  <ModalOpenButton
                    Modal={PickProductTypeModal}
                    modalProps={{
                      async handleComplete(productType, handleClose) {
                        await defineItemProductSearchFilteDefinitions({
                          variables: {
                            input: {
                              projectId: project?.id,
                              docId,
                              itemId: item.id,
                              itemProductSearchFilterProductTypeIdFilterDefinition:
                                {
                                  idFilter: {
                                    operatorId: "eq",
                                    valueId: productType.id,
                                  },
                                },
                              itemProductSearchFilterPropertyFilterDefinitions:
                                [],
                            },
                          },
                        });
                        handleClose();
                      },
                    }}
                  >
                    <Button startIcon={<AddIcon />} color={"secondary"}>
                      {t("Add product type", {
                        ns: "QuoteItem",
                      })}
                    </Button>
                  </ModalOpenButton>
                </RestrictedByCapabilityWithDebug>
              )}
            </Stack>
          ) : (
            <Stack direction="column" spacing={1} alignItems={"center"}>
              <Typography color={"gray"} variant="caption">
                {t("No product added", {
                  ns: "QuoteItem",
                })}
              </Typography>
            </Stack>
          )
        ) : !viewerIsContractor || viewMode === null ? (
          <ProductReadView
            item={initialValues}
            product={pimCustomSupplierProduct}
            viewerIsContractor={viewerIsContractor}
            isEditable={isEditable}
            onProductSelect={onProductSelect}
            productAlternatives={productAlternatives ?? null}
            visibilityEditable={itemAgreed && isEditable}
            setVisibility={(fieldName, value) =>
              modifyItemProduct({
                variables: {
                  input: {
                    projectId: project?.id,
                    docId,
                    itemId: item.id,
                    values: {
                      [fieldName]: value,
                    },
                  },
                },
              })
            }
          />
        ) : isEditable && viewMode === "edit" ? (
          <ProductEditView
            docType={docType}
            item={initialValues}
            product={pimCustomSupplierProduct}
            itemMaterialBuyingPrice={item.materialBuyingPrice}
            onSubmit={async values => {
              await modifyProduct(productFormValuesToInput(values));
              setViewMode(null);
            }}
            isSibmitting={modifyItemProductLoading}
            onCancel={() => setViewMode(null)}
          />
        ) : null}

        {project && viewerIsContractor && pimCustomSupplierProduct && (
          <RestrictedByCapabilityWithDebug capability="PIM">
            <RestrictedByProjectPermissionWithDebug
              permission="MANAGE_QUOTES"
              project={project}
            >
              <ProductPricing product={pimCustomSupplierProduct} />
            </RestrictedByProjectPermissionWithDebug>
          </RestrictedByCapabilityWithDebug>
        )}
        {project && viewerIsContractor && pimCustomSupplierProduct && (
          <RestrictedByCapabilityWithDebug capability="PIM">
            <RestrictedByProjectPermissionWithDebug
              permission="MANAGE_QUOTES"
              project={project}
            >
              <Divider sx={{ marginX: -1, paddingTop: 1 }} />
              <Stack alignContent={"flex-start"} pt={1}>
                <ProductCataloguePageButton
                  productArticleNumber={pimCustomSupplierProduct.articleNumber}
                  productSupplierId={pimCustomSupplierProduct.supplierId}
                />
              </Stack>
            </RestrictedByProjectPermissionWithDebug>
          </RestrictedByCapabilityWithDebug>
        )}
        {"productSearchFilterExpressions" in item &&
          "productSearchFilterDefinitions" in item &&
          item.productSearchFilterDefinitions.productTypeIdFilter &&
          !pimCustomSupplierProduct && (
            <ProductSearchFiltersSection
              projectId={project?.id ?? null}
              docId={docId}
              docType={docType}
              item={item}
              productTypeId={
                item.productSearchFilterDefinitions.productTypeIdFilter.valueId
              }
              isEditable={isEditable}
            />
          )}
      </Box>
    </CardContainer>
  );
};

function filterFormValuesToDefinitionInput(
  values: ProductFiltersFormValues
): Omit<
  DefineItemProductSearchFilterDefinitionsInput,
  "projectId" | "docId" | "itemId"
> {
  return {
    itemProductSearchFilterFavouritesFilterDefinition:
      values.organisationFavourite === true
        ? { boolFilter: { valueBool: true, operatorBool: "eq" } }
        : undefined,
    itemProductSearchFilterProductTypeIdFilterDefinition: values
      .productTypeIds?.[0]
      ? {
          idFilter: {
            operatorId: "eq",
            valueId: values.productTypeIds[0],
          },
        }
      : undefined,
    itemProductSearchFilterSupplierIdFilterDefinition: values.supplierIds
      ? {
          idInFilter: {
            operatorIdIn: "in",
            valueIdIn: values.supplierIds,
          },
        }
      : undefined,
    itemProductSearchFilterBrandFilterDefinition: values.brands
      ? {
          textInFilter: {
            operatorTextIn: "in",
            valueTextIn: values.brands,
          },
        }
      : undefined,
    itemProductSearchFilterBrandLineFilterDefinition: values.brandLines
      ? {
          textInFilter: {
            operatorTextIn: "in",
            valueTextIn: values.brandLines,
          },
        }
      : undefined,
    itemProductSearchFilterListPriceFilterDefinition:
      values.listPriceMin && values.listPriceMax
        ? {
            numberBetweenFilter: {
              operatorNumberBetween: "between",
              valueNumberBetween: {
                min: values.listPriceMin,
                max: values.listPriceMax,
              },
            },
          }
        : undefined,
    itemProductSearchFilterPropertyFilterDefinitions: (
      values.properties ?? []
    ).flatMap(filter => {
      if (filter.boolFilter) {
        return {
          boolFilter: {
            key: filter.key,
            operatorBool: filter.boolFilter.operatorBool,
            valueBool: filter.boolFilter.valueBool,
          },
        };
      }
      if (filter.boolInFilter) {
        return {
          boolInFilter: {
            key: filter.key,
            operatorBoolIn: filter.boolInFilter.operatorBoolIn,
            valueBoolIn: filter.boolInFilter.valueBoolIn,
          },
        };
      }
      if (filter.numberBetweenFilter) {
        return {
          numberBetweenFilter: {
            key: filter.key,
            operatorNumberBetween:
              filter.numberBetweenFilter.operatorNumberBetween,
            valueNumberBetween: filter.numberBetweenFilter.valueNumberBetween,
          },
        };
      }
      if (filter.numberFilter) {
        return {
          numberFilter: {
            key: filter.key,
            operatorNumber: filter.numberFilter.operatorNumber,
            valueNumber: filter.numberFilter.valueNumber,
          },
        };
      }
      if (filter.numberInFilter) {
        return {
          numberInFilter: {
            key: filter.key,
            operatorNumberIn: filter.numberInFilter.operatorNumberIn,
            valueNumberIn: filter.numberInFilter.valueNumberIn,
          },
        };
      }
      if (filter.textFilter) {
        return {
          textFilter: {
            key: filter.key,
            operatorText: filter.textFilter.operatorText,
            valueText: filter.textFilter.valueText,
          },
        };
      }
      if (filter.textInFilter) {
        return {
          textInFilter: {
            key: filter.key,
            operatorTextIn: filter.textInFilter.operatorTextIn,
            valueTextIn: filter.textInFilter.valueTextIn,
          },
        };
      }

      throw new Error("Unknown filter type");
    }),
  };
}

export const productFormValuesToInput = (
  values: FormValues
): ModifyItemProductValuesInput => {
  return {
    productArticleNumber: values.productArticleNumber ?? null,
    productSupplierId: values.productSupplier?.id ?? null,
    productTitle: values.productTitle,
    productDescription: values.productDescription,
    productBrand: values.productBrand,
    productBrandLine: values.productBrandLine,
    productGtin: values.productGtin ?? null,
    productManufacturerArticleNumber:
      values.productManufacturerArticleNumber ?? null,
    productCategory: values.productCategory,
    productTypeId: values.productTypeId,
    productTypeTitle: values.productTypeTitle,
    productTitleClientVisibility: values.productTitleClientVisibility,
    productDescriptionClientVisibility:
      values.productDescriptionClientVisibility,
    productBrandClientVisibility: values.productBrandClientVisibility,
    productProps2: values.productProps2.map(propToDefineInput),
  };
};

interface ProductEditViewProps {
  docType: DocType;
  item: FormValues;
  product?: {
    id: string;
    articleNumber: string;
    supplierId: string;
    pricing?:
      | { netPrice?: number | null; listPrice?: number | null }
      | null
      | undefined;
  } | null;
  itemMaterialBuyingPrice?: number;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  isSibmitting: boolean;
  onCancel: () => void;
}

const ProductEditView = ({
  docType,
  item,
  product,
  onSubmit,
  isSibmitting,
  onCancel,
}: ProductEditViewProps) => {
  const { t } = useTranslate([
    "QuoteItem",
    "Global",
    "Product",
    "ItemPropertyField",
  ]);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        productArticleNumber: Yup.string()
          .nullable()
          .label(
            t("Article number", {
              ns: "Product",
            })
          ),
        productSupplier: Yup.object()
          .shape({ id: Yup.string(), title: Yup.string() })
          .nullable()
          .label(t("Supplier", { ns: "Product" })),
        productTitle: Yup.string()
          .trim()
          .required(
            t("Product title required", {
              ns: "QuoteItem",
            })
          )
          .label(
            t("Title", {
              ns: "Product",
            })
          ),
        productDescription: Yup.string().label(
          t("Description", {
            ns: "Product",
          })
        ),
        productBrand: Yup.string().label(
          t("Brand", {
            ns: "Product",
          })
        ),
        productBrandLine: Yup.string().label(
          t("Brand line", {
            ns: "Product",
          })
        ),
        productGtin: Yup.string()
          .nullable()
          .label(
            t("GTIN", {
              ns: "Product",
            })
          ),
        productManufacturerArticleNumber: Yup.string()
          .nullable()
          .label(
            t("Manufacturer article number", {
              ns: "Product",
            })
          ),
        productCategory: Yup.string().label(
          t("Category", {
            ns: "Product",
          })
        ),
        productTypeId: Yup.string().nullable(),
        productTypeTitle: Yup.string().nullable(),
        productTitleClientVisibility: Yup.string().oneOf([
          "none",
          "show",
          "hide",
        ]),
        productDescriptionClientVisibility: Yup.string().oneOf([
          "none",
          "show",
          "hide",
        ]),
        productBrandClientVisibility: Yup.string().oneOf([
          "none",
          "show",
          "hide",
        ]),
        // TODO: product properties put correct validation
        productProps2: Yup.mixed(),
      }),
    [t]
  );

  const gridColumnSpacing = 1;

  return (
    <Formik<FormValues>
      initialValues={item}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form>
          <Grid
            container
            columns={2}
            rowSpacing={1}
            columnSpacing={gridColumnSpacing}
          >
            {product && (
              <Grid item xs={2}>
                <Alert severity="info" variant="outlined">
                  {t(
                    "Editing product details on a quote item only changes what information you share with the client. To edit product details permanently, go to the catalogue page.",
                    { ns: "Product" }
                  )}
                </Alert>
              </Grid>
            )}
            <Grid item xs={2}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Field
                  component={FormikTextField}
                  label={t("Product title", {
                    ns: "QuoteItem",
                  })}
                  name="productTitle"
                  disabled={false}
                  required
                  // value={formikProps.values.productTitle}
                />
                <VisibilityButton
                  visibility={formikProps.values.productTitleClientVisibility}
                  onChange={visibility =>
                    formikProps.setFieldValue(
                      "productTitleClientVisibility",
                      visibility
                    )
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box flex={1}>
                  <RichTextField
                    name="productDescription"
                    label={t("Product description", {
                      ns: "QuoteItem",
                    })}
                    disabled={false}
                  />
                </Box>
                <VisibilityButton
                  visibility={
                    formikProps.values.productDescriptionClientVisibility
                  }
                  onChange={visibility =>
                    formikProps.setFieldValue(
                      "productDescriptionClientVisibility",
                      visibility
                    )
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {!product ? (
                  <Field
                    component={FormikTextField}
                    label={t("Category", {
                      ns: "Product",
                    })}
                    name="productCategory"
                  />
                ) : (
                  <LabeledValue
                    label={t("Category", {
                      ns: "Product",
                    })}
                  >
                    <ValueWithDiff
                      value={item.productCategory}
                      spacing={0}
                      showDiff={false}
                      placeholder={"-"}
                    />
                  </LabeledValue>
                )}
                <VisibilityIndicatorIcon isVisible={false} />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {!product ? (
                  <Field
                    component={FormikTextField}
                    label={t("Article number", {
                      ns: "Product",
                    })}
                    name="productArticleNumber"
                  />
                ) : (
                  <LabeledValue
                    label={t("Article number", {
                      ns: "Product",
                    })}
                  >
                    <ValueWithDiff
                      value={item.productArticleNumber}
                      showDiff={false}
                      spacing={0}
                      placeholder={"-"}
                    />
                  </LabeledValue>
                )}
                <VisibilityIndicatorIcon isVisible={false} />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Field
                  component={FormikTextField}
                  label={t("GTIN", {
                    ns: "Product",
                  })}
                  name="productGtin"
                />
                <VisibilityIndicatorIcon isVisible={false} />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Field
                  component={FormikTextField}
                  label={t("Manufacturer article number", {
                    ns: "Product",
                  })}
                  name="productManufacturerArticleNumber"
                />
                <VisibilityIndicatorIcon isVisible={false} />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Field
                  component={FormikTextField}
                  label={t("Brand", {
                    ns: "Product",
                  })}
                  name="productBrand"
                />
                <VisibilityButton
                  visibility={formikProps.values.productBrandClientVisibility}
                  onChange={visibility =>
                    formikProps.setFieldValue(
                      "productBrandClientVisibility",
                      visibility
                    )
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Field
                  component={FormikTextField}
                  label={t("Brand line", {
                    ns: "Product",
                  })}
                  name="productBrandLine"
                />
                <VisibilityButton
                  visibility={formikProps.values.productBrandClientVisibility}
                  onChange={noop}
                  disabled={true}
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {!product ? (
                  <SupplierSelect
                    excludeIfAlreadyCrm={false}
                    required={false}
                    disabled={formikProps.isSubmitting}
                    productSupplierId={
                      formikProps.values.productSupplier?.id ?? null
                    }
                    onChange={productSupplier => {
                      formikProps.setFieldValue(
                        "productSupplier",
                        productSupplier
                      );
                    }}
                  />
                ) : (
                  <LabeledValue
                    label={t("Supplier", {
                      ns: "Product",
                    })}
                  >
                    <ValueWithDiff
                      value={item.productSupplier?.title}
                      showDiff={false}
                      spacing={0}
                      placeholder={"-"}
                    />
                  </LabeledValue>
                )}
                <VisibilityIndicatorIcon isVisible={false} />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Field
                  component={FormikTextField}
                  label={t("Product Type", {
                    ns: "Product",
                  })}
                  name="productTypeTitle"
                />
                <VisibilityIndicatorIcon isVisible={false} />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <ItemProductPropertiesSection
                fieldName={"productProps2"}
                title={t("Product Properties", {
                  ns: "QuoteItem",
                })}
                properties={formikProps.values.productProps2}
                disabled={false}
                docType={docType}
              />
            </Grid>
            <Grid item xs={2}>
              <ManualSave
                disabled={isSibmitting}
                disabledSubmit={!formikProps.isValid || !formikProps.dirty}
                onCancel={onCancel}
              />
            </Grid>
            {/* <NavigationPrompt when={formikProps.dirty} /> */}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

interface ProductReadViewProps {
  item: FormValues;
  product?: {
    id: string;
    articleNumber: string;
    gtin?: string | null;
    manufacturerArticleNumber?: string | null;
    supplier?: { id: string; title: string } | null;
    supplierId: string;
    category: string;
    texts?: {
      title?: string | null;
      clientTitleText?: string | null;
      clientDescriptionText?: string | null;
    } | null;
    pricing?: { netPrice?: number | null; listPrice?: number | null } | null;
    manufacturer?: { id: string; title: string } | null;
    brand?: string;
    branding?: {
      brand: { title: string };
      brandLine?: { title: string } | null;
    } | null;
    productVariantGroup?: {
      id: string;
      title: string;
    } | null;
    productType?: { id: string; label: string } | null;
    properties?: PimProductPropertyFragment[];
  } | null;
  viewerIsContractor: boolean;
  visibilityEditable?: boolean;
  setVisibility: (fieldName: keyof FormValues, value: Visibility) => void;
  isEditable: boolean;
  productAlternatives: {
    propertyAlternatives: SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternativeFragment[];
    propertyMatchingProductAlternatives: SupplierProductAlternativePicker__PimSupplierProductAlternativesProductFragment[];
  } | null;
  onProductSelect: OnProductSelectFn;
}

const ProductReadView = ({
  item,
  product,
  viewerIsContractor,
  visibilityEditable,
  setVisibility,
  isEditable,
  productAlternatives,
  onProductSelect,
}: ProductReadViewProps) => {
  const { t } = useTranslate([
    "QuoteItem",
    "Global",
    "Product",
    "ItemPropertyField",
  ]);

  const gridColumnSpacing = 1;

  const properties = item.productProps2;
  const visibleProperties = properties.filter(
    property => property.clientVisibility || viewerIsContractor
  );
  const originalProperties = product?.properties?.map(p => p.prop2);
  const visibleOriginalProperties = originalProperties?.filter(
    property => property.clientVisibility || viewerIsContractor
  );

  return (
    <Stack direction="column" spacing={1}>
      <Box>
        <Grid
          container
          columns={2}
          rowSpacing={1}
          columnSpacing={gridColumnSpacing}
        >
          {(viewerIsContractor ||
            item.productTitleClientVisibility !== "hide") && (
            <Grid item xs={2}>
              <ValueWithDiff
                value={item.productTitle}
                ValueComponent={({ value }) => (
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"h3"}>{value}</Typography>
                    {viewerIsContractor && (
                      <VisibilityButton
                        visibility={item.productTitleClientVisibility}
                        onChange={visibility =>
                          setVisibility(
                            "productTitleClientVisibility",
                            visibility
                          )
                        }
                        disabled={!visibilityEditable}
                      />
                    )}
                  </Stack>
                )}
                diffValue={
                  viewerIsContractor && product
                    ? product.texts?.clientTitleText
                    : undefined
                }
                DiffValueComponent={({ value }) => (
                  <Typography variant={"h4"}>{value}</Typography>
                )}
                showDiff={!!product}
                flex={1}
                placeholder={"-"}
              />
            </Grid>
          )}
          {((viewerIsContractor &&
            (item.productDescription ||
              product?.texts?.clientDescriptionText)) ||
            (item.productDescriptionClientVisibility !== "hide" &&
              item.productDescription)) && (
            <Grid item xs={2}>
              <ValueWithDiff
                value={item.productDescription}
                ValueComponent={({ value }) => (
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <TruncatedHtmlValue
                      textProps={{ variant: "body2" }}
                      lines={5}
                      html={value}
                      showMoreLabel={t("Show more", { ns: "Global" })}
                      showLessLabel={t("Show less", { ns: "Global" })}
                      notSetLabel={t("Not set", { ns: "Global" })}
                    />
                    {viewerIsContractor && (
                      <VisibilityButton
                        visibility={item.productDescriptionClientVisibility}
                        onChange={visibility =>
                          setVisibility(
                            "productDescriptionClientVisibility",
                            visibility
                          )
                        }
                        disabled={!visibilityEditable}
                      />
                    )}
                  </Stack>
                )}
                diffValue={
                  viewerIsContractor
                    ? product?.texts?.clientDescriptionText
                    : undefined
                }
                DiffValueComponent={({ value }) => (
                  <TruncatedHtmlValue
                    textProps={{ variant: "body2" }}
                    lines={3}
                    html={value}
                    showMoreLabel={t("Show more", { ns: "Global" })}
                    showLessLabel={t("Show less", { ns: "Global" })}
                    notSetLabel={t("Not set", { ns: "Global" })}
                  />
                )}
                showDiff={!!product}
                flex={1}
                placeholder={"-"}
              />
            </Grid>
          )}

          {viewerIsContractor &&
            (item.productCategory || product?.category) && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <LabeledValue
                    label={t("Category", {
                      ns: "Product",
                    })}
                  >
                    <ValueWithDiff
                      value={item.productCategory}
                      diffValue={
                        viewerIsContractor && product
                          ? product.category
                          : undefined
                      }
                      spacing={0}
                      showDiff={!!product}
                      placeholder={"-"}
                    />
                  </LabeledValue>
                  {viewerIsContractor && (
                    <VisibilityIndicatorIcon isVisible={false} />
                  )}
                </Stack>
              </Grid>
            )}
          {viewerIsContractor &&
            (item.productArticleNumber || product?.articleNumber) && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {product &&
                  isEditable &&
                  productAlternatives &&
                  productAlternatives.propertyMatchingProductAlternatives
                    .length > 0 ? (
                    <RestrictedByCapabilityWithDebug capability="PIM">
                      <SupplierProductPropertyMatchingProductAlternativesSelect
                        product={product}
                        propertyMatchingProductAlternatives={
                          productAlternatives.propertyMatchingProductAlternatives
                        }
                        onProductSelect={onProductSelect}
                      />
                    </RestrictedByCapabilityWithDebug>
                  ) : (
                    <LabeledValue
                      label={t("Article number", { ns: "Product" })}
                    >
                      <ValueWithDiff
                        value={item.productArticleNumber}
                        diffValue={
                          viewerIsContractor
                            ? product?.articleNumber
                            : undefined
                        }
                        showDiff={!!product}
                        spacing={0}
                        placeholder={"-"}
                      />
                    </LabeledValue>
                  )}
                  {viewerIsContractor && (
                    <VisibilityIndicatorIcon isVisible={false} />
                  )}
                </Stack>
              </Grid>
            )}
          {viewerIsContractor &&
            (item.productSupplier || product?.supplier) && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <LabeledValue
                    label={t("Supplier", {
                      ns: "Product",
                    })}
                  >
                    <ValueWithDiff
                      value={item.productSupplier?.title}
                      diffValue={
                        viewerIsContractor
                          ? product?.supplier?.title
                          : undefined
                      }
                      showDiff={!!product}
                      spacing={0}
                      placeholder={"-"}
                    />
                  </LabeledValue>
                  {viewerIsContractor && (
                    <VisibilityIndicatorIcon isVisible={false} />
                  )}
                </Stack>
              </Grid>
            )}
          {viewerIsContractor && (item.productGtin || product?.gtin) && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <LabeledValue
                  label={t("GTIN", {
                    ns: "Product",
                  })}
                >
                  <ValueWithDiff
                    value={item.productGtin}
                    diffValue={viewerIsContractor ? product?.gtin : undefined}
                    showDiff={!!product}
                    spacing={0}
                    placeholder={"-"}
                  />
                </LabeledValue>
                {viewerIsContractor && (
                  <VisibilityIndicatorIcon isVisible={false} />
                )}
              </Stack>
            </Grid>
          )}
          {viewerIsContractor &&
            (item.productManufacturerArticleNumber ||
              product?.manufacturerArticleNumber) && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <LabeledValue
                    label={t("Manufacturer article number", {
                      ns: "Product",
                    })}
                  >
                    <ValueWithDiff
                      value={item.productManufacturerArticleNumber}
                      diffValue={
                        viewerIsContractor
                          ? product?.manufacturerArticleNumber
                          : undefined
                      }
                      showDiff={!!product}
                      spacing={0}
                      placeholder={"-"}
                    />
                  </LabeledValue>
                  {viewerIsContractor && (
                    <VisibilityIndicatorIcon isVisible={false} />
                  )}
                </Stack>
              </Grid>
            )}
          {((viewerIsContractor &&
            (item.productBrand || product?.branding || product?.brand)) ||
            (item.productBrandClientVisibility !== "hide" &&
              item.productBrand)) && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <LabeledValue
                  label={t("Brand", {
                    ns: "Product",
                  })}
                >
                  <ValueWithDiff
                    value={item.productBrand}
                    diffValue={
                      viewerIsContractor
                        ? product?.branding?.brand.title ??
                          product?.brand ??
                          undefined
                        : undefined
                    }
                    showDiff={!!product}
                    spacing={0}
                    placeholder={"-"}
                  />
                </LabeledValue>
                {viewerIsContractor && (
                  <VisibilityButton
                    visibility={item.productBrandClientVisibility}
                    onChange={visibility =>
                      setVisibility("productBrandClientVisibility", visibility)
                    }
                    disabled={!visibilityEditable}
                  />
                )}
              </Stack>
            </Grid>
          )}
          {((viewerIsContractor &&
            (item.productBrandLine || product?.branding?.brandLine)) ||
            (item.productBrandClientVisibility !== "hide" &&
              item.productBrandLine)) && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <LabeledValue
                  label={t("Brand line", {
                    ns: "Product",
                  })}
                >
                  <ValueWithDiff
                    value={item.productBrandLine}
                    diffValue={
                      viewerIsContractor
                        ? product?.branding?.brandLine?.title
                        : undefined
                    }
                    showDiff={!!product}
                    spacing={0}
                    placeholder={"-"}
                  />
                </LabeledValue>
                {viewerIsContractor && (
                  <VisibilityButton
                    visibility={item.productBrandClientVisibility}
                    onChange={noop}
                    disabled={true}
                  />
                )}
              </Stack>
            </Grid>
          )}
          {viewerIsContractor &&
            (item.productTypeTitle || product?.productType?.label) && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <LabeledValue
                    label={t("Product type", {
                      ns: "Product",
                    })}
                  >
                    <ValueWithDiff
                      value={item.productTypeTitle}
                      diffValue={
                        viewerIsContractor
                          ? product?.productType?.label
                          : undefined
                      }
                      showDiff={!!product}
                      spacing={0}
                      placeholder={"-"}
                    />
                  </LabeledValue>
                  {viewerIsContractor && (
                    <VisibilityIndicatorIcon isVisible={false} />
                  )}
                </Stack>
              </Grid>
            )}
        </Grid>
      </Box>
      <CollapseSection
        title={t("Product Properties", {
          ns: "QuoteItem",
        })}
        isInitiallyExpanded={
          visibleProperties.length > 0 ||
          (visibleOriginalProperties && visibleOriginalProperties.length > 0)
        }
        titleVariant="h4"
        itemCount={visibleProperties.length}
        hideItemCountOnExpand={true}
      >
        <LabeledPropertiesValue
          properties={visibleProperties}
          originalProperties={visibleOriginalProperties}
          propertyAlternatives={
            isEditable ? productAlternatives?.propertyAlternatives : undefined
          }
          onProductSelect={isEditable ? onProductSelect : undefined}
          viewerIsContractor={viewerIsContractor}
          gridColumnSpacing={gridColumnSpacing}
        />
      </CollapseSection>
    </Stack>
  );
};

const EMPTY_ARRAY: any[] = [];

function ProductSearchFiltersSection({
  projectId,
  docId,
  docType,
  item,
  productTypeId,
  isEditable = false,
}: {
  projectId: string | null;
  docId: string;
  docType: DocType;
  item: ProductSearchFiltersSection_ItemFragment;
  productTypeId: string;
  isEditable: boolean;
}) {
  const { t } = useTranslate([
    "QuoteItem",
    "Global",
    "Product",
    "ItemPropertyField",
  ]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();
  const [getProductType, productTypeQuery] =
    useProductSearchFiltersSection_ProductTypeLazyQuery({
      client,
      fetchPolicy: "network-only", //network-only here, because else we get problems with the product field!
    });
  React.useEffect(() => {
    if (productTypeId) {
      getProductType({
        variables: {
          productTypeId: productTypeId,
        },
      });
    }
  }, [getProductType, productTypeId]);
  const productType = getDataOrNull(productTypeQuery.data?.pimProductType);
  const productTypeSearchFilter =
    productType && productTypeId ? productType : null;
  const popularProductTypePropertyTypes =
    getDataOrNull(productTypeQuery.data?.pimProductTypePropertyTypeFavourites)
      ?.propertyTypeFavourites ?? EMPTY_ARRAY;

  const supplierOrganisationsQuery = useSelectCrmSuppliersQuery({
    client,
    variables: {},
  });
  const supplierOrganisations =
    getDataOrNull(supplierOrganisationsQuery.data?.crmSuppliers)?.edges.map(
      edge => edge.node
    ) ?? [];

  const {
    supplierIdFilter: supplierSearchFilterDefinition,
    brandFilter: brandSearchFilterDefinition,
    brandLineFilter: brandLineSearchFilterDefinition,
    listPriceFilter: listPriceSearchFilterDefinition,
    propertyFilters: propertySearchFiltersDefinitions,
    favouritesFilter: organisationFavouritesSearchFilterDefinition,
  } = item.productSearchFilterDefinitions;

  const {
    handleSetProductSearchFilterDefinition,
    handleDeleteProductSearchFilterDefinition,
    defineItemProductSearchFilteDefinitions,
  } = useProductSearchFilterDefinitionMutations({
    projectId,
    docId,
    itemId: item.id,
    productTypeSearchFilter,
    supplierSearchFilterDefinition,
    brandSearchFilterDefinition,
    brandLineSearchFilterDefinition,
    listPriceSearchFilterDefinition,
    propertySearchFiltersDefinitions,
    refetchQueries: [namedOperations.Query.ProductBox_Product],
  });

  const {
    brandFilter: brandSearchFilterExpression,
    brandLineFilter: brandLineSearchFilterExpression,
    listPriceFilter: listPriceSearchFilterExpression,
    propertyFilters: propertySearchFiltersExpressions,
  } = item.productSearchFilterExpressions;

  const {
    handleSetProductSearchFilterExpression,
    handleDeleteProductSearchFilterExpression,
    handleSetProductSearchFilterPropertyExpression,
    handleDeleteProductSearchFilterPropertyExpression,
    defineItemProductSearchFilterExpressions,
  } = useProductSearchFilterExpressionMutations({
    projectId,
    docId,
    itemId: item.id,
    brandSearchFilterExpression,
    brandLineSearchFilterExpression,
    listPriceSearchFilterExpression,
    propertySearchFiltersExpressions,
    refetchQueries: [namedOperations.Query.ProductBox_Product],
  });

  const nonProductTypeSearchFiltersCount = [
    supplierSearchFilterDefinition,
    brandSearchFilterDefinition,
    brandLineSearchFilterDefinition,
    listPriceSearchFilterDefinition,
    ...propertySearchFiltersDefinitions,
  ].reduce((acc, cur) => (cur ? acc + 1 : acc), 0);
  const searchFiltersCount =
    nonProductTypeSearchFiltersCount + (productTypeSearchFilter ? 1 : 0);
  const searchFilterExpressionsCount = [
    brandSearchFilterExpression,
    brandLineSearchFilterExpression,
    listPriceSearchFilterExpression,
    ...propertySearchFiltersExpressions,
  ].reduce((acc, cur) => (cur ? acc + 1 : acc), 0);
  const atLeastOneSearchFilterExists =
    searchFiltersCount + searchFilterExpressionsCount > 0;

  if (!(isEditable || atLeastOneSearchFilterExists)) return null;

  const gridColumnSpacing = 2;

  return (
    <Stack>
      <CollapseSection
        title={t("Search filters", { ns: "Product" })}
        titleVariant="h4"
        isInitiallyExpanded={true}
        hideItemCountOnExpand={true}
        itemCount={searchFiltersCount}
        ActionButtons={
          <Stack direction={"row"} spacing={1}>
            {atLeastOneSearchFilterExists && (
              <RestrictedByCapabilityWithDebug capability="PIM">
                <MenuButton>
                  <ViewModeMenuItem
                    viewMode={viewMode}
                    onViewModeChange={setViewMode}
                    allowedModes={[null, "delete"]}
                  />
                </MenuButton>
              </RestrictedByCapabilityWithDebug>
            )}
            <RestrictedByCapabilityWithDebug capability="PIM">
              <ModalOpenButton
                Modal={AddProductSearchFilterDefinitionsModal}
                modalProps={{
                  handleComplete: async values => {
                    await defineItemProductSearchFilteDefinitions({
                      variables: {
                        input: {
                          projectId,
                          docId,
                          itemId: item.id,
                          itemProductSearchFilterFavouritesFilterDefinition: {
                            boolFilter: { operatorBool: "eq", valueBool: true },
                          },
                          ...filterFormValuesToDefinitionInput(values),
                        },
                      },
                    });
                  },
                  productSearchFilterDefinitions:
                    item.productSearchFilterDefinitions,
                }}
              >
                <IconButton size={"small"} color={"primary"}>
                  <AddIcon />
                </IconButton>
              </ModalOpenButton>
            </RestrictedByCapabilityWithDebug>
          </Stack>
        }
      >
        <Grid
          container
          columns={2}
          rowSpacing={1}
          columnSpacing={gridColumnSpacing}
        >
          <Grid item xs={1}>
            <Stack
              direction={"row"}
              spacing={0.5}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {productTypeSearchFilter && (
                <>
                  <LabeledValue
                    label={t("Product type", {
                      ns: "Product",
                    })}
                  >
                    {productTypeSearchFilter?.label ?? "-"}
                  </LabeledValue>
                  {viewMode === "delete" &&
                    (nonProductTypeSearchFiltersCount +
                      searchFilterExpressionsCount >
                    0 ? (
                      <ModalOpenButton
                        Modal={ConfirmModal}
                        modalProps={{
                          title: t(
                            "All filters and expressions will be deleted",
                            { ns: "Product" }
                          ),
                          handleConfirm: async () => {
                            await defineItemProductSearchFilterExpressions({
                              variables: {
                                input: {
                                  projectId,
                                  docId,
                                  itemId: item.id,
                                  itemProductSearchFilterPropertyFilterExpressions:
                                    [],
                                },
                              },
                            });
                            await defineItemProductSearchFilteDefinitions({
                              variables: {
                                input: {
                                  projectId,
                                  docId,
                                  itemId: item.id,
                                  itemProductSearchFilterPropertyFilterDefinitions:
                                    [],
                                },
                              },
                            });
                          },
                        }}
                      >
                        <IconButton size={"small"} color="primary">
                          <DeleteIcon />
                        </IconButton>
                      </ModalOpenButton>
                    ) : (
                      <IconButton
                        size={"small"}
                        color="primary"
                        onClick={async () => {
                          await defineItemProductSearchFilterExpressions({
                            variables: {
                              input: {
                                projectId,
                                docId,
                                itemId: item.id,
                                itemProductSearchFilterPropertyFilterExpressions:
                                  [],
                              },
                            },
                          });
                          await defineItemProductSearchFilteDefinitions({
                            variables: {
                              input: {
                                projectId,
                                docId,
                                itemId: item.id,
                                itemProductSearchFilterPropertyFilterDefinitions:
                                  [],
                              },
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ))}
                </>
              )}
            </Stack>
          </Grid>

          {organisationFavouritesSearchFilterDefinition && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={1}
                height="100%"
              >
                <Stack direction="row" spacing={1}>
                  <StarIcon color="secondary" />
                  <Typography variant={"button"} color="secondary">
                    {t("Favourites", { ns: "ProductSearch" })}
                  </Typography>
                </Stack>
                {viewMode === "delete" && (
                  <IconButton
                    size={"small"}
                    color="primary"
                    onClick={async () => {
                      await handleDeleteProductSearchFilterDefinition(
                        "itemProductSearchFilterFavouritesFilterDefinition"
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          )}

          {supplierSearchFilterDefinition && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <LabeledValue
                  label={t("Supplier", {
                    ns: "Product",
                  })}
                >
                  {(supplierSearchFilterDefinition.__typename ===
                  "EntitySearchIdFilter"
                    ? [supplierSearchFilterDefinition.valueId]
                    : supplierSearchFilterDefinition.valueIdIn
                  )
                    .map(
                      (id: string) =>
                        supplierOrganisations.find(
                          org => org.linkedSystemOrganisationId === id
                        )?.title ?? "?"
                    )
                    .join(", ")}
                </LabeledValue>

                {viewMode === "delete" && (
                  <IconButton
                    size={"small"}
                    color="primary"
                    onClick={async () => {
                      await handleDeleteProductSearchFilterDefinition(
                        "itemProductSearchFilterSupplierIdFilterDefinition"
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          )}
          {brandSearchFilterDefinition && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <LabeledValue
                  label={t("Brand", {
                    ns: "Product",
                  })}
                >
                  {getValueWithOperator(brandSearchFilterDefinition)}
                </LabeledValue>

                {viewMode === "delete" && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={async () => {
                      await handleDeleteProductSearchFilterDefinition(
                        "itemProductSearchFilterBrandFilterDefinition"
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          )}
          {brandLineSearchFilterDefinition && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <LabeledValue
                  label={t("Brand line", {
                    ns: "Product",
                  })}
                >
                  {getValueWithOperator(brandLineSearchFilterDefinition)}
                </LabeledValue>

                {viewMode === "delete" && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={async () => {
                      await handleDeleteProductSearchFilterDefinition(
                        "itemProductSearchFilterBrandLineFilterDefinition"
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          )}
          {listPriceSearchFilterDefinition && (
            <Grid item xs={1}>
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <LabeledValue
                  label={t("List price", {
                    ns: "Product",
                  })}
                >
                  {getValueWithOperator(listPriceSearchFilterDefinition)}
                </LabeledValue>

                {viewMode === "delete" && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={async () => {
                      await handleDeleteProductSearchFilterDefinition(
                        "itemProductSearchFilterListPriceFilterDefinition"
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          )}

          {propertySearchFiltersDefinitions.map(definition => {
            const propertyType = productTypeSearchFilter?.propertyTypes.find(
              p => p.propertyType.label === definition.key
            )?.propertyType;
            return (
              <Grid key={definition.key} item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <LabeledValue label={definition.key}>
                    {getValueWithOperator(
                      definition,
                      propertyType?.kind === "number"
                        ? propertyType.unit
                        : undefined
                    )}
                  </LabeledValue>

                  {viewMode === "delete" && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={async () => {
                        await handleSetProductSearchFilterDefinition(
                          "itemProductSearchFilterPropertyFilterDefinitions",
                          propertySearchFiltersDefinitions
                            .filter(d => d !== definition)
                            .map(
                              ProductSearchFilterPropertyFilterDefinitionToInputValue
                            )
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </CollapseSection>
      <CollapseSection
        title={t("Expressions", {
          ns: "Product",
        })}
        titleVariant="h4"
        isInitiallyExpanded={searchFilterExpressionsCount > 0}
        hideItemCountOnExpand={true}
        itemCount={searchFilterExpressionsCount}
        ActionButtons={
          isEditable ? (
            <RestrictedByCapabilityWithDebug capability="PIM">
              <Stack direction={"row"} spacing={1} alignItems="center">
                <AddProductSearchExpressionMenuButton
                  projectId={projectId}
                  docId={docId}
                  itemId={item.id}
                  popularProductTypePropertyTypes={
                    popularProductTypePropertyTypes
                  }
                  productTypeSearchFilter={productTypeSearchFilter}
                  productSearchFilterExpressions={
                    item.productSearchFilterExpressions
                  }
                  handleSetProductSearchFilterExpression={
                    handleSetProductSearchFilterExpression
                  }
                  handleSetProductSearchFilterPropertyExpression={
                    handleSetProductSearchFilterPropertyExpression
                  }
                />
              </Stack>
            </RestrictedByCapabilityWithDebug>
          ) : undefined
        }
      >
        {!productTypeSearchFilter ? (
          <Stack>
            <Typography variant="caption" color="gray" align="center">
              {t("Add a product type to create expressions", { ns: "Product" })}
            </Typography>
          </Stack>
        ) : searchFilterExpressionsCount === 0 ? (
          <Stack>
            <Typography variant="caption" color="gray" align="center">
              {t("No filter expressions added", { ns: "Product" })}
            </Typography>
          </Stack>
        ) : (
          <Grid
            container
            columns={2}
            rowSpacing={1}
            columnSpacing={gridColumnSpacing}
          >
            {brandSearchFilterExpression && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <RestrictedByCapabilityWithDebug
                    capability="PIM"
                    otherwise={
                      <LabeledValueWithButton
                        label={t("Brand", { ns: "Product" })}
                        labelIcon={FunctionsIcon}
                        warning={
                          brandSearchFilterDefinition
                            ? t("Overruled by filter definition", {
                                ns: "Product",
                              })
                            : undefined
                        }
                        disabled={true}
                      >
                        {getValueWithOperator(
                          item.productSearchFilterExpressions.brandFilter
                        )}
                      </LabeledValueWithButton>
                    }
                  >
                    <ModalOpenButton
                      Modal={ProductSearchFilterExpressionModal}
                      modalProps={{
                        projectId,
                        docId,
                        itemId: item.id,
                        filterKey: t("Brand", {
                          ns: "Product",
                        }),
                        operator:
                          brandSearchFilterExpression.__typename ===
                          "EntitySearchTextFilterComputed"
                            ? brandSearchFilterExpression.operatorText
                            : brandSearchFilterExpression.operatorTextIn,
                        allowedSearchFilterOperators: TextFilterOperatorOptions,
                        expression: brandSearchFilterExpression?.expr,
                        handleComplete: async (value, handleClose) => {
                          await handleSetProductSearchFilterExpression(
                            "itemProductSearchFilterBrandFilterExpression",
                            {
                              textFilter:
                                value.operator === "eq"
                                  ? {
                                      expr: value.expr,
                                      operatorText: value.operator,
                                    }
                                  : undefined,
                              textInFilter:
                                value.operator === "in"
                                  ? {
                                      expr: value.expr,
                                      operatorTextIn: value.operator,
                                    }
                                  : undefined,
                            }
                          );
                          handleClose();
                        },
                      }}
                      disabled={!isEditable}
                    >
                      <LabeledValueWithButton
                        label={t("Brand", { ns: "Product" })}
                        labelIcon={FunctionsIcon}
                        warning={
                          brandSearchFilterDefinition
                            ? t("Overruled by filter definition", {
                                ns: "Product",
                              })
                            : undefined
                        }
                      >
                        {getValueWithOperator(
                          item.productSearchFilterExpressions.brandFilter
                        )}
                      </LabeledValueWithButton>
                    </ModalOpenButton>
                  </RestrictedByCapabilityWithDebug>

                  {viewMode === "delete" && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={async () => {
                        await handleDeleteProductSearchFilterExpression(
                          "itemProductSearchFilterBrandFilterExpression"
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            )}
            {brandLineSearchFilterExpression && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <RestrictedByCapabilityWithDebug
                    capability="PIM"
                    otherwise={
                      <LabeledValueWithButton
                        label={t("Brand line", { ns: "Product" })}
                        labelIcon={FunctionsIcon}
                        warning={
                          brandLineSearchFilterDefinition
                            ? t("Overruled by filter definition", {
                                ns: "Product",
                              })
                            : undefined
                        }
                        disabled={true}
                      >
                        {getValueWithOperator(
                          item.productSearchFilterExpressions.brandLineFilter
                        )}
                      </LabeledValueWithButton>
                    }
                  >
                    <ModalOpenButton
                      Modal={ProductSearchFilterExpressionModal}
                      modalProps={{
                        projectId,
                        docId,
                        itemId: item.id,
                        filterKey: t("Brand line", {
                          ns: "Product",
                        }),
                        operator:
                          brandLineSearchFilterExpression.__typename ===
                          "EntitySearchTextFilterComputed"
                            ? brandLineSearchFilterExpression.operatorText
                            : brandLineSearchFilterExpression.operatorTextIn,
                        allowedSearchFilterOperators: TextFilterOperatorOptions,
                        expression: brandLineSearchFilterExpression?.expr,
                        handleComplete: async (value, handleClose) => {
                          await handleSetProductSearchFilterExpression(
                            "itemProductSearchFilterBrandLineFilterExpression",
                            {
                              textFilter:
                                value.operator === "eq"
                                  ? {
                                      expr: value.expr,
                                      operatorText: value.operator,
                                    }
                                  : undefined,
                              textInFilter:
                                value.operator === "in"
                                  ? {
                                      expr: value.expr,
                                      operatorTextIn: value.operator,
                                    }
                                  : undefined,
                            }
                          );
                          handleClose();
                        },
                      }}
                      disabled={!isEditable}
                    >
                      <LabeledValueWithButton
                        label={t("Brand line", { ns: "Product" })}
                        labelIcon={FunctionsIcon}
                        warning={
                          brandLineSearchFilterDefinition
                            ? t("Overruled by filter definition", {
                                ns: "Product",
                              })
                            : undefined
                        }
                      >
                        {getValueWithOperator(
                          item.productSearchFilterExpressions.brandLineFilter
                        )}
                      </LabeledValueWithButton>
                    </ModalOpenButton>
                  </RestrictedByCapabilityWithDebug>

                  {viewMode === "delete" && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={async () => {
                        await handleDeleteProductSearchFilterExpression(
                          "itemProductSearchFilterBrandLineFilterExpression"
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            )}
            {listPriceSearchFilterExpression && (
              <Grid item xs={1}>
                <Stack
                  direction={"row"}
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <RestrictedByCapabilityWithDebug
                    capability="PIM"
                    otherwise={
                      <LabeledValueWithButton
                        label={t("List price", { ns: "Product" })}
                        labelIcon={FunctionsIcon}
                        warning={
                          listPriceSearchFilterDefinition
                            ? t("Overruled by filter definition", {
                                ns: "Product",
                              })
                            : undefined
                        }
                        disabled={true}
                      >
                        {getValueWithOperator(
                          item.productSearchFilterExpressions.listPriceFilter
                        )}
                      </LabeledValueWithButton>
                    }
                  >
                    <ModalOpenButton
                      Modal={ProductSearchFilterExpressionModal}
                      modalProps={{
                        projectId,
                        docId,
                        itemId: item.id,
                        filterKey: t("List price", {
                          ns: "Product",
                        }),
                        operator:
                          listPriceSearchFilterExpression.__typename ===
                          "EntitySearchNumberFilterComputed"
                            ? listPriceSearchFilterExpression.operatorNumber
                            : listPriceSearchFilterExpression.operatorNumberBetween,
                        allowedSearchFilterOperators:
                          NumberFilterOperatorOptions,
                        expression: listPriceSearchFilterExpression?.expr,
                        handleComplete: async (value, handleClose) => {
                          await handleSetProductSearchFilterExpression(
                            "itemProductSearchFilterListPriceFilterExpression",
                            {
                              numberFilter:
                                value.operator === "eq"
                                  ? {
                                      expr: value.expr,
                                      operatorNumber: value.operator,
                                    }
                                  : undefined,
                              numberBetweenFilter:
                                value.operator === "between"
                                  ? {
                                      expr: value.expr,
                                      operatorNumberBetween: value.operator,
                                    }
                                  : undefined,
                            }
                          );
                          handleClose();
                        },
                      }}
                      disabled={!isEditable}
                    >
                      <LabeledValueWithButton
                        label={t("List price", { ns: "Product" })}
                        labelIcon={FunctionsIcon}
                        warning={
                          listPriceSearchFilterDefinition
                            ? t("Overruled by filter definition", {
                                ns: "Product",
                              })
                            : undefined
                        }
                      >
                        {getValueWithOperator(
                          item.productSearchFilterExpressions.listPriceFilter
                        )}
                      </LabeledValueWithButton>
                    </ModalOpenButton>
                  </RestrictedByCapabilityWithDebug>

                  {viewMode === "delete" && (
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={async () => {
                        await handleDeleteProductSearchFilterExpression(
                          "itemProductSearchFilterListPriceFilterExpression"
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            )}
            {propertySearchFiltersExpressions.map(
              (propertySearchFilter, index) => {
                switch (propertySearchFilter.__typename) {
                  case "EntitySearchPropertyFilterBoolFilterComputed":
                  case "EntitySearchPropertyFilterBoolInFilterComputed": {
                    return (
                      <Grid key={propertySearchFilter.key} item xs={1}>
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <RestrictedByCapabilityWithDebug
                            capability="PIM"
                            otherwise={
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                                warning={
                                  propertySearchFiltersDefinitions.some(
                                    definition =>
                                      definition.key ===
                                      propertySearchFilter.key
                                  )
                                    ? t("Overruled by filter definition", {
                                        ns: "Product",
                                      })
                                    : undefined
                                }
                                disabled={true}
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            }
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchBoolFilterOperator
                                  | EntitySearchBoolInFilterOperator
                                >
                              }
                              modalProps={{
                                projectId,
                                docId,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.__typename ===
                                  "EntitySearchPropertyFilterBoolInFilterComputed"
                                    ? propertySearchFilter.operatorBoolIn
                                    : propertySearchFilter.operatorBool,
                                allowedSearchFilterOperators:
                                  BoolFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetProductSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "in"
                                      ? {
                                          boolInFilter: {
                                            key: propertySearchFilter.key,
                                            operatorBoolIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq"
                                        ? {
                                            boolFilter: {
                                              operatorBool: value.operator,
                                              expr: value.expr,
                                              key: propertySearchFilter.key,
                                            },
                                          }
                                        : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                                warning={
                                  propertySearchFiltersDefinitions.some(
                                    definition =>
                                      definition.key ===
                                      propertySearchFilter.key
                                  )
                                    ? t("Overruled by filter definition", {
                                        ns: "Product",
                                      })
                                    : undefined
                                }
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                          </RestrictedByCapabilityWithDebug>

                          {viewMode === "delete" && (
                            <IconButton
                              size={"small"}
                              color="primary"
                              onClick={async () => {
                                await handleDeleteProductSearchFilterPropertyExpression(
                                  propertySearchFilter.key
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    );
                  }
                  case "EntitySearchPropertyFilterNumberFilterComputed":
                  case "EntitySearchPropertyFilterNumberInFilterComputed":
                  case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
                    const propertyType =
                      productTypeSearchFilter?.propertyTypes.find(
                        p => p.propertyType.label === propertySearchFilter.key
                      )?.propertyType;
                    return (
                      <Grid key={propertySearchFilter.key} item xs={1}>
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <RestrictedByCapabilityWithDebug
                            capability="PIM"
                            otherwise={
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                                warning={
                                  propertySearchFiltersDefinitions.some(
                                    definition =>
                                      definition.key ===
                                      propertySearchFilter.key
                                  )
                                    ? t("Overruled by filter definition", {
                                        ns: "Product",
                                      })
                                    : undefined
                                }
                                disabled={true}
                              >
                                {getValueWithOperator(
                                  propertySearchFilter,
                                  propertyType?.kind === "number"
                                    ? propertyType.unit
                                    : undefined
                                )}
                              </LabeledValueWithButton>
                            }
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchNumberFilterOperator
                                  | EntitySearchNumberInFilterOperator
                                  | EntitySearchNumberBetweenFilterOperator
                                >
                              }
                              modalProps={{
                                projectId,
                                docId,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.__typename ===
                                  "EntitySearchPropertyFilterNumberFilterComputed"
                                    ? propertySearchFilter.operatorNumber
                                    : propertySearchFilter.__typename ===
                                        "EntitySearchPropertyFilterNumberInFilterComputed"
                                      ? propertySearchFilter.operatorNumberIn
                                      : propertySearchFilter.operatorNumberBetween,
                                allowedSearchFilterOperators:
                                  NumberFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetProductSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "in"
                                      ? {
                                          numberInFilter: {
                                            key: propertySearchFilter.key,
                                            operatorNumberIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "between"
                                        ? {
                                            numberBetweenFilter: {
                                              key: propertySearchFilter.key,
                                              operatorNumberBetween:
                                                value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "eq" ||
                                            value.operator === "lt" ||
                                            value.operator === "lte" ||
                                            value.operator === "gt" ||
                                            value.operator === "gte"
                                          ? {
                                              numberFilter: {
                                                key: propertySearchFilter.key,
                                                operatorNumber: value.operator,
                                                expr: value.expr,
                                              },
                                            }
                                          : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                                warning={
                                  propertySearchFiltersDefinitions.some(
                                    definition =>
                                      definition.key ===
                                      propertySearchFilter.key
                                  )
                                    ? t("Overruled by filter definition", {
                                        ns: "Product",
                                      })
                                    : undefined
                                }
                              >
                                {getValueWithOperator(
                                  propertySearchFilter,
                                  propertyType?.kind === "number"
                                    ? propertyType.unit
                                    : undefined
                                )}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                          </RestrictedByCapabilityWithDebug>

                          {viewMode === "delete" && (
                            <IconButton
                              size={"small"}
                              color="primary"
                              onClick={async () => {
                                await handleDeleteProductSearchFilterPropertyExpression(
                                  propertySearchFilter.key
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    );
                  }
                  case "EntitySearchPropertyFilterTextFilterComputed":
                  case "EntitySearchPropertyFilterTextInFilterComputed": {
                    return (
                      <Grid key={propertySearchFilter.key} item xs={1}>
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <RestrictedByCapabilityWithDebug
                            capability="PIM"
                            otherwise={
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                                warning={
                                  propertySearchFiltersDefinitions.some(
                                    definition =>
                                      definition.key ===
                                      propertySearchFilter.key
                                  )
                                    ? t("Overruled by filter definition", {
                                        ns: "Product",
                                      })
                                    : undefined
                                }
                                disabled={true}
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            }
                          >
                            <ModalOpenButton
                              Modal={
                                ProductSearchFilterExpressionModal<
                                  | EntitySearchTextFilterOperator
                                  | EntitySearchTextInFilterOperator
                                >
                              }
                              modalProps={{
                                projectId,
                                docId,
                                itemId: item.id,
                                filterKey: propertySearchFilter.key,
                                operator:
                                  propertySearchFilter.__typename ===
                                  "EntitySearchPropertyFilterTextFilterComputed"
                                    ? propertySearchFilter.operatorText
                                    : propertySearchFilter.operatorTextIn,
                                allowedSearchFilterOperators:
                                  TextFilterOperatorOptions,
                                expression: propertySearchFilter.expr,
                                handleComplete: async (value, handleClose) => {
                                  await handleSetProductSearchFilterPropertyExpression(
                                    propertySearchFilter.key,
                                    value.operator === "in"
                                      ? {
                                          textInFilter: {
                                            key: propertySearchFilter.key,
                                            operatorTextIn: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq"
                                        ? {
                                            textFilter: {
                                              key: propertySearchFilter.key,
                                              operatorText: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                  );
                                  handleClose();
                                },
                              }}
                              disabled={!isEditable}
                            >
                              <LabeledValueWithButton
                                label={propertySearchFilter.key}
                                labelIcon={FunctionsIcon}
                                warning={
                                  propertySearchFiltersDefinitions.some(
                                    definition =>
                                      definition.key ===
                                      propertySearchFilter.key
                                  )
                                    ? t("Overruled by filter definition", {
                                        ns: "Product",
                                      })
                                    : undefined
                                }
                              >
                                {getValueWithOperator(propertySearchFilter)}
                              </LabeledValueWithButton>
                            </ModalOpenButton>
                          </RestrictedByCapabilityWithDebug>

                          {viewMode === "delete" && (
                            <IconButton
                              size={"small"}
                              color="primary"
                              onClick={async () => {
                                await handleDeleteProductSearchFilterPropertyExpression(
                                  propertySearchFilter.key
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    );
                  }
                  default:
                    assertNever(propertySearchFilter);
                }
              }
            )}
          </Grid>
        )}
      </CollapseSection>
      <ProductSelectQuestionControlForm
        projectId={projectId}
        docId={docId}
        docType={docType}
        item={item}
      />
    </Stack>
  );
}

function AddProductSearchFilterDefinitionsModal({
  handleClose,
  handleComplete,
  productSearchFilterDefinitions,
}: {
  handleClose: () => void;
  handleComplete: (values: ProductFiltersFormValues) => Promise<void>;
  productSearchFilterDefinitions: ItemProductSearchFilterDefinitionsFragment;
}) {
  const { t } = useTranslate(["Global"]);

  const initialValues = React.useMemo((): ProductFiltersFormValues => {
    return {
      ...productFiltersDefaultValue,
      ...definitionsToFormValues(productSearchFilterDefinitions),
    };
  }, [productSearchFilterDefinitions]);

  const formikProps = useFormik({
    initialValues,
    validationSchema: productFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: handleComplete,
  });

  return (
    <ProductFilterModal
      handleClose={handleClose}
      formikProps={formikProps}
      productSearchVariables={{ filters: formikProps.values }}
      canChangeProductType={!productSearchFilterDefinitions.productTypeIdFilter}
      submitButtonTitle={t("Save", { ns: "Global" })}
      onCancel={noop}
    />
  );
}

function AddProductSearchExpressionMenuButton({
  projectId,
  docId,
  itemId,
  popularProductTypePropertyTypes,
  productTypeSearchFilter,
  productSearchFilterExpressions,
  handleSetProductSearchFilterExpression,
  handleSetProductSearchFilterPropertyExpression,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  popularProductTypePropertyTypes: { id: string }[];
  productTypeSearchFilter: {
    propertyTypes: PimProductTypePropertyTypeFragment[];
  } | null;
  productSearchFilterExpressions: ItemProductSearchFilterExpressionsFragment;
  handleSetProductSearchFilterExpression: HandleSetProductSearchFilterExpression;
  handleSetProductSearchFilterPropertyExpression: HandleSetProductSearchFilterPropertyExpression;
}) {
  const { t } = useTranslate(["Global", "Product"]);

  const {
    listPriceFilter: listPriceSearchFilter,
    propertyFilters: propertySearchFilters,
    brandFilter: brandSearchFilter,
    brandLineFilter: brandLineSearchFilter,
  } = productSearchFilterExpressions;

  const unusedPropertyTypes =
    productTypeSearchFilter && productTypeSearchFilter.propertyTypes
      ? differenceWith(
          productTypeSearchFilter.propertyTypes,
          propertySearchFilters,
          (propertyType, propertySearchFilter) =>
            propertyType.propertyType.label === propertySearchFilter.key
        )
      : [];
  const [popularPropertyTypes, otherPropertyTypes] = partition(
    unusedPropertyTypes,
    propertyType =>
      popularProductTypePropertyTypes.some(
        popularPropertyType =>
          popularPropertyType.id === propertyType.propertyType.id
      )
  );

  return (
    <MenuButton
      Icon={<AddIcon />}
      buttonProps={{
        disabled: !productTypeSearchFilter,
      }}
    >
      {!brandSearchFilter && (
        <ModalOpenButton
          Modal={ProductSearchFilterExpressionModal}
          modalProps={{
            projectId,
            docId,
            itemId,
            filterKey: t("Brand", {
              ns: "Product",
            }),
            operator: "eq",
            allowedSearchFilterOperators: TextFilterOperatorOptions,
            expression: undefined,
            handleComplete: async (value, handleClose) => {
              await handleSetProductSearchFilterExpression(
                "itemProductSearchFilterBrandFilterExpression",
                {
                  textFilter:
                    value.operator === "eq"
                      ? {
                          expr: value.expr,
                          operatorText: value.operator,
                        }
                      : undefined,
                  textInFilter:
                    value.operator === "in"
                      ? {
                          expr: value.expr,
                          operatorTextIn: value.operator,
                        }
                      : undefined,
                }
              );
              handleClose();
            },
          }}
        >
          <MenuItem>
            {t("Brand", {
              ns: "Product",
            })}
          </MenuItem>
        </ModalOpenButton>
      )}
      {!brandLineSearchFilter && (
        <ModalOpenButton
          Modal={ProductSearchFilterExpressionModal}
          modalProps={{
            projectId,
            docId,
            itemId,
            filterKey: t("Brand line", {
              ns: "Product",
            }),
            operator: "eq",
            allowedSearchFilterOperators: TextFilterOperatorOptions,
            expression: undefined,
            handleComplete: async (value, handleClose) => {
              await handleSetProductSearchFilterExpression(
                "itemProductSearchFilterBrandLineFilterExpression",
                {
                  textFilter:
                    value.operator === "eq"
                      ? {
                          expr: value.expr,
                          operatorText: value.operator,
                        }
                      : undefined,
                  textInFilter:
                    value.operator === "in"
                      ? {
                          expr: value.expr,
                          operatorTextIn: value.operator,
                        }
                      : undefined,
                }
              );
              handleClose();
            },
          }}
        >
          <MenuItem>
            {t("Brand line", {
              ns: "Product",
            })}
          </MenuItem>
        </ModalOpenButton>
      )}
      {!listPriceSearchFilter && (
        <ModalOpenButton
          Modal={ProductSearchFilterExpressionModal}
          modalProps={{
            projectId,
            docId,
            itemId,
            filterKey: t("List price", {
              ns: "Product",
            }),
            operator: "eq",
            allowedSearchFilterOperators: NumberFilterOperatorOptions,
            expression: undefined,
            handleComplete: async (value, handleClose) => {
              await handleSetProductSearchFilterExpression(
                "itemProductSearchFilterListPriceFilterExpression",
                {
                  numberFilter:
                    value.operator === "eq" ||
                    value.operator === "lt" ||
                    value.operator === "lte" ||
                    value.operator === "gt" ||
                    value.operator === "gte"
                      ? {
                          expr: value.expr,
                          operatorNumber: value.operator,
                        }
                      : undefined,
                  numberBetweenFilter:
                    value.operator === "between"
                      ? {
                          expr: value.expr,
                          operatorNumberBetween: value.operator,
                        }
                      : undefined,
                }
              );
              handleClose();
            },
          }}
        >
          <MenuItem>
            {t("List price", {
              ns: "Product",
            })}
          </MenuItem>
        </ModalOpenButton>
      )}
      {popularPropertyTypes.length > 0 && (
        <ListSubheader>
          {t("Popular properties", { ns: "Product" })}
        </ListSubheader>
      )}
      {popularPropertyTypes.length > 0 &&
        popularPropertyTypes.map(propertyType => (
          <AddPropertyFilterExpressionMenuItemButton
            key={propertyType.propertyType.key}
            projectId={projectId}
            docId={docId}
            itemId={itemId}
            propertyType={propertyType.propertyType}
            handleSetProductSearchFilterPropertyExpression={
              handleSetProductSearchFilterPropertyExpression
            }
          />
        ))}
      {otherPropertyTypes.length > 0 && (
        <ListSubheader>
          {t("Other properties", { ns: "Product" })}
        </ListSubheader>
      )}
      {otherPropertyTypes.length > 0 &&
        otherPropertyTypes.map(propertyType => (
          <AddPropertyFilterExpressionMenuItemButton
            key={propertyType.propertyType.key}
            projectId={projectId}
            docId={docId}
            itemId={itemId}
            propertyType={propertyType.propertyType}
            handleSetProductSearchFilterPropertyExpression={
              handleSetProductSearchFilterPropertyExpression
            }
          />
        ))}
    </MenuButton>
  );
}

function AddPropertyFilterExpressionMenuItemButton({
  projectId,
  docId,
  itemId,
  propertyType,
  handleSetProductSearchFilterPropertyExpression,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  propertyType: PimPropertyTypeFragment;
  handleSetProductSearchFilterPropertyExpression: HandleSetProductSearchFilterPropertyExpression;
}) {
  switch (propertyType.kind) {
    case "bool": {
      return (
        <ModalOpenButton
          key={propertyType.key}
          Modal={
            ProductSearchFilterExpressionModal<
              EntitySearchBoolFilterOperator | EntitySearchBoolInFilterOperator
            >
          }
          modalProps={{
            projectId,
            docId,
            itemId,
            filterKey: propertyType.label,
            operator: "eq",
            allowedSearchFilterOperators: BoolFilterOperatorOptions,
            expression: undefined,
            handleComplete: async (value, handleClose) => {
              await handleSetProductSearchFilterPropertyExpression(
                propertyType.label,
                value.operator === "in"
                  ? {
                      boolInFilter: {
                        expr: value.expr,
                        key: propertyType.label,
                        operatorBoolIn: value.operator,
                      },
                    }
                  : value.operator === "eq"
                    ? {
                        boolFilter: {
                          key: propertyType.label,
                          operatorBool: value.operator,
                          expr: value.expr,
                        },
                      }
                    : assertNever(value.operator)
              );
              handleClose();
            },
          }}
        >
          <MenuItem>{propertyType.label}</MenuItem>
        </ModalOpenButton>
      );
    }
    case "number":
    case "range": {
      return (
        <ModalOpenButton
          key={propertyType.key}
          Modal={
            ProductSearchFilterExpressionModal<
              | EntitySearchNumberFilterOperator
              | EntitySearchNumberInFilterOperator
              | EntitySearchNumberBetweenFilterOperator
            >
          }
          modalProps={{
            projectId,
            docId,
            itemId,
            filterKey: propertyType.label,
            operator: "eq",
            allowedSearchFilterOperators: NumberFilterOperatorOptions,
            expression: undefined,
            handleComplete: async (value, handleClose) => {
              await handleSetProductSearchFilterPropertyExpression(
                propertyType.label,
                value.operator === "in"
                  ? {
                      numberInFilter: {
                        key: propertyType.label,
                        operatorNumberIn: value.operator,
                        expr: value.expr,
                      },
                    }
                  : value.operator === "between"
                    ? {
                        numberBetweenFilter: {
                          key: propertyType.label,
                          operatorNumberBetween: value.operator,
                          expr: value.expr,
                        },
                      }
                    : value.operator === "eq" ||
                        value.operator === "lt" ||
                        value.operator === "lte" ||
                        value.operator === "gt" ||
                        value.operator === "gte"
                      ? {
                          numberFilter: {
                            key: propertyType.label,
                            operatorNumber: value.operator,
                            expr: value.expr,
                          },
                        }
                      : assertNever(value.operator)
              );
              handleClose();
            },
          }}
        >
          <MenuItem>{propertyType.label}</MenuItem>
        </ModalOpenButton>
      );
    }
    case "text": {
      return (
        <ModalOpenButton
          key={propertyType.key}
          Modal={
            ProductSearchFilterExpressionModal<
              EntitySearchTextFilterOperator | EntitySearchTextInFilterOperator
            >
          }
          modalProps={{
            projectId,
            docId,
            itemId,
            filterKey: propertyType.label,
            operator: "eq",
            allowedSearchFilterOperators: TextFilterOperatorOptions,
            expression: undefined,
            handleComplete: async (value, handleClose) => {
              await handleSetProductSearchFilterPropertyExpression(
                propertyType.label,
                value.operator === "in"
                  ? {
                      textInFilter: {
                        key: propertyType.label,
                        operatorTextIn: value.operator,
                        expr: value.expr,
                      },
                    }
                  : value.operator === "eq"
                    ? {
                        textFilter: {
                          key: propertyType.label,
                          operatorText: value.operator,
                          expr: value.expr,
                        },
                      }
                    : assertNever(value.operator)
              );
              handleClose();
            },
          }}
        >
          <MenuItem>{propertyType.label}</MenuItem>
        </ModalOpenButton>
      );
    }
    default:
      assertNever(propertyType.kind);
  }
}

export function getValueWithOperator(
  filter:
    | ItemProductSearchFilterBrandFilterFragment
    | ItemProductSearchFilterBrandFilterComputedFragment
    | ItemProductSearchFilterBrandLineFilterFragment
    | ItemProductSearchFilterBrandLineFilterComputedFragment
    | ItemProductSearchFilterPriceFilterFragment
    | ItemProductSearchFilterPriceFilterComputedFragment
    | ItemProductSearchFilterPropertyFilterFragment
    | ItemProductSearchFilterPropertyFilterComputedFragment
    | undefined
    | null,
  unit?: string | null | undefined
) {
  const placeholder = "-";
  if (!filter) return placeholder;

  switch (filter.__typename) {
    case "EntitySearchNumberFilter":
    case "EntitySearchPropertyFilterNumberFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumber}` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchNumberBetweenFilter":
    case "EntitySearchPropertyFilterNumberBetweenFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetween.min}-${filter.valueNumberBetween.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchNumberFilterComputed":
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      if (
        filter.valueNumberComputed === null ||
        filter.valueNumberComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      if (
        filter.valueNumberInComputed === null ||
        filter.valueNumberInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchNumberBetweenFilterComputed":
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      if (
        filter.valueNumberBetweenComputed === null ||
        filter.valueNumberBetweenComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetweenComputed.min}-${filter.valueNumberBetweenComputed.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchTextFilter":
    case "EntitySearchPropertyFilterTextFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueText}"` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchTextFilterComputed":
    case "EntitySearchPropertyFilterTextFilterComputed": {
      if (
        filter.valueTextComputed === null ||
        filter.valueTextComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueTextComputed}"` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchTextInFilter":
    case "EntitySearchPropertyFilterTextInFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextIn.join(", ")}`;
    }
    case "EntitySearchTextInFilterComputed":
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      if (
        filter.valueTextInComputed === null ||
        filter.valueTextInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextInComputed.join(", ")}`;
    }
    case "EntitySearchPropertyFilterBoolFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return `${operatorLabel} ${filter.valueBool}` + (unit ? ` ${unit}` : "");
    }

    case "EntitySearchPropertyFilterBoolInFilter": {
      const operatorLabel = BoolFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }

    case "EntitySearchPropertyFilterBoolFilterComputed": {
      if (
        filter.valueBoolComputed === null ||
        filter.valueBoolComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      if (
        filter.valueBoolInComputed === null ||
        filter.valueBoolInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    default:
      assertNever(filter);
  }
}

function ProductSelectQuestionControlForm({
  projectId,
  docId,
  docType,
  item,
}: {
  projectId: string | null;
  docId: string;
  docType: DocType;
  item: ProductSelectQuestionControlForm_ItemFragment;
}) {
  const client = useApolloClient();
  const [modifyWizardSettings] =
    useProductSelectQuestionControlForm_ModifyWizardSettingsMutation({
      client,
    });

  return (
    <Formik<QuestionControlSectionFormValues>
      initialValues={{
        askWhen: item.wizardSettings.askWhenPaidAndProduct,
        askWhom: item.wizardSettings.askWhomPaidAndProduct,
        prompt: item.wizardSettings.promptPaidAndProduct,
      }}
      onSubmit={async values => {
        await modifyWizardSettings({
          variables: {
            input: {
              projectId,
              docId,
              itemId: item.id,
              values: {
                askWhenPaidAndProduct: values.askWhen,
                askWhomPaidAndProduct: values.askWhom,
                promptPaidAndProduct: values.prompt,
              },
            },
          },
        });
      }}
    >
      <RestrictedByCapabilityWithDebug
        capability="PIM"
        otherwise={
          <QuestionControlSection
            isInitiallyExpanded
            disabled={true}
            readOnly={true}
          />
        }
      >
        <Form>
          <QuestionControlSection isInitiallyExpanded disabled={false} />
          <AutoSave />
        </Form>
      </RestrictedByCapabilityWithDebug>
    </Formik>
  );
}
