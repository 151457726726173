import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const RecommendationListItem = ({
  icon,
  text,
  link,
  secondaryAction,
  secondaryActionWidth,
}: {
  icon: React.ReactElement;
  text: string;
  link: string;
  secondaryAction: ListItemProps["secondaryAction"];
  secondaryActionWidth?: number;
}) => {
  return (
    <ListItem disablePadding secondaryAction={secondaryAction}>
      <ListItemButton
        component={Link}
        to={link}
        dense
        sx={{
          pr:
            secondaryActionWidth !== undefined
              ? `${secondaryActionWidth + 16}px !important`
              : undefined,
        }}
      >
        <ListItemIcon
          sx={theme => ({
            minWidth: theme.layout.listItemMinWidth.sm,
          })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};
