import { Select, Switch } from "@msys/ui";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isEqual } from "lodash";
import React from "react";
import { useUserData } from "../../auth/useUserData";
import { FilterByOrganisationUsers } from "../../commons/filters";
import { FilterModal } from "../../commons/filters/FilterModal";
import {
  OrganisationProjectsSorting,
  ProjectStateMachineStatus,
  SortDirection,
} from "../../../clients/graphqlTypes";
import { CrmCompaniesOrPersonsSelect } from "../crm-companies/CrmCompaniesOrPersonsSelect";
import { CrmCompaniesSelect } from "../crm-companies/CrmCompaniesSelect";
import { CrmCompanySelect } from "../crm-companies/CrmCompanySelect";
import {
  FilterMinBudget,
  FilterProjectCategory,
  FilterProjectState,
} from "../projects/filters";
import { FilterAssignees } from "../projects/filters/ProjectAssignees";
import { FilterProjectPhases } from "../projects/filters/ProjectPhases";
import { FilterSources } from "../projects/filters/ProjectSources";
import { DEFAULT_SORTING, type Filters } from "./OpportunitiesList";

export function OpportunitiesFilterModal({
  handleClose,
  sorting,
  setSorting,
  filters,
  setFilters,
  resetFilters,
  allowedStates,
  showSorting = true,
  showRelevantToMe = true,
  showPhasesFilter = true,
}: {
  handleClose: () => void;
  sorting: OrganisationProjectsSorting[];
  setSorting: (sorting: OrganisationProjectsSorting[]) => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  resetFilters: () => void;
  allowedStates: ProjectStateMachineStatus[];
  showSorting?: boolean;
  showRelevantToMe?: boolean;
  showPhasesFilter?: boolean;
}) {
  const { t } = useTranslate([
    "Opportunities",
    "Projects",
    "Global",
    "ProjectSorting",
  ]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  const sortByOptions = React.useMemo(() => {
    const opportunitiesSortingLabels: Record<
      "createdAt" | "minBudget",
      Record<SortDirection, string>
    > = {
      // title: {
      //   asc: t("Project title asc", {
      //     ns: "ProjectSorting",
      //   }),
      //   desc: t("Project title desc", {
      //     ns: "ProjectSorting",
      //   }),
      // },
      createdAt: {
        asc: t("Project created at asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project created at desc", {
          ns: "ProjectSorting",
        }),
      },
      minBudget: {
        asc: t("Project budget asc", {
          ns: "ProjectSorting",
        }),
        desc: t("Project budget desc", {
          ns: "ProjectSorting",
        }),
      },
    };

    const ALL_SORTING_FIELDS: ("createdAt" | "minBudget")[] = [
      "createdAt",
      // "title",
      "minBudget",
    ];

    return ALL_SORTING_FIELDS.flatMap(value => [
      {
        label: opportunitiesSortingLabels[value]["asc"],
        value: { column: value, direction: "asc" as SortDirection },
      },
      {
        label: opportunitiesSortingLabels[value]["desc"],
        value: { column: value, direction: "desc" as SortDirection },
      },
    ]);
  }, [t]);

  return (
    <FilterModal
      title={t("Opportunity Filters", {
        ns: "Opportunities",
      })}
      handleApply={() => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleClose={handleClose}
      handleReset={resetFilters}
    >
      <Stack direction="column" spacing={3}>
        {showSorting && (
          <Stack direction="column" spacing={1}>
            <Typography variant="h3">
              {t("Sort by", { ns: "Global" })}
            </Typography>
            <Select
              label={t("Sort by", { ns: "Global" })}
              options={sortByOptions}
              value={
                sortByOptions.find(option =>
                  isEqual(option.value, sortingState[0])
                )?.value
              }
              onChange={value => {
                setSortingState(value ? [value] : DEFAULT_SORTING);
              }}
            />
          </Stack>
        )}
        <Stack direction="column" spacing={1}>
          <Typography variant="h3">
            {t("Filter by", { ns: "Global" })}
          </Typography>
          {/* <FilterProjectType
            value={filtersState.filterType ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterType: newValue,
              }))
            }
          /> */}
          {showRelevantToMe && (
            <Box>
              <FilterRelevantToMe
                filters={filtersState}
                setFilters={setFiltersState}
              />
            </Box>
          )}
          <FilterProjectCategory
            value={filtersState.filterCategories ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterCategories: newValue,
              }))
            }
          />
          <FilterMinBudget
            value={filtersState.filterMinBudget ?? 0}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterMinBudget: newValue,
              }))
            }
          />
          <FilterProjectState
            allowedStates={allowedStates}
            value={filtersState.filterIncludeState ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterIncludeState: newValue,
              }))
            }
          />
          {showPhasesFilter && (
            <FilterProjectPhases
              allowedStates={allowedStates}
              value={filtersState.filterPhaseIds ?? []}
              setValue={newValue =>
                setFiltersState(filters => ({
                  ...filters,
                  filterPhaseIds: newValue,
                }))
              }
            />
          )}
          <CrmCompanySelect
            crmCompanyId={filtersState.filterByCrmOrganisationId ?? undefined}
            onChange={crmCompany =>
              setFiltersState(state => ({
                ...state,
                filterByCrmOrganisationId: crmCompany?.id,
              }))
            }
            inputLabel={t("Client", { ns: "Opportunities" })}
            required={false}
            canCreateNew={false}
            disableBlockedBusinessPartners={false}
          />
          <CrmCompaniesOrPersonsSelect
            crmCompaniesOrPersonsIds={filtersState.filterByCrmLinkIds ?? []}
            onChange={crmCompaniesOrPersonsIds =>
              setFiltersState(state => ({
                ...state,
                filterByCrmLinkIds: crmCompaniesOrPersonsIds,
              }))
            }
            inputLabel={t("Other contacts", { ns: "Projects" })}
            required={false}
          />
          <FilterAssignees
            value={filtersState.filterAssigneeIds ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterAssigneeIds: newValue,
              }))
            }
          />
          <FilterSources
            value={filtersState.sources ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                sources: newValue,
              }))
            }
          />
          <FilterByOrganisationUsers
            label={t("Created by", { ns: "Opportunities" })}
            placeholder=""
            value={filtersState.filterBySourceUserIds ?? []}
            setValue={newValue =>
              setFiltersState(filters => ({
                ...filters,
                filterBySourceUserIds: newValue,
              }))
            }
          />
          {/*<CrmCompaniesSelect
            crmCompanyIds={
              filtersState.filterBySourceCrmOrganisationIds ?? undefined
            }
            onChange={crmCompanies =>
              setFiltersState(state => ({
                ...state,
                filterBySourceCrmOrganisationIds: crmCompanies.map(c => c.id),
              }))
            }
            inputLabel={t("Created by CRM contact", { ns: "Opportunities" })}
            required={false}
          />*/}
        </Stack>
      </Stack>
    </FilterModal>
  );
}

export function FilterRelevantToMe({
  filters,
  setFilters,
}: {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}) {
  const { t } = useTranslate(["Opportunities"]);
  const viewer = useUserData().currentUser!;
  return (
    <Switch
      label={t("Relevant to me", { ns: "Opportunities" })}
      checked={Boolean(
        filters.filterRelevantToUserIds &&
          filters.filterRelevantToUserIds.length > 0 &&
          filters.filterRelevantToUserIds.includes(viewer.id)
      )}
      onChange={(_, checked) => {
        setFilters(filters => {
          let newRelevantIds = [...(filters.filterRelevantToUserIds ?? [])];
          if (checked) {
            if (!newRelevantIds.includes(viewer.id))
              newRelevantIds.push(viewer.id);
          } else {
            if (newRelevantIds.includes(viewer.id))
              newRelevantIds = newRelevantIds.filter(id => id !== viewer.id);
          }
          return {
            ...filters,
            filterRelevantToUserIds:
              newRelevantIds.length > 0 ? newRelevantIds : undefined,
          };
        });
      }}
    />
  );
}
