import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../../auth/useUserData";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { Stack } from "../../../commons/layout/Stack";
import {
  OrganisationPaymentInformationBox_OrganisationFragment,
  OrganisationPaymentInformationBox_ViewerOrganisationFragment,
  useModifyOrganisationPaymentInformationMutation,
} from "./OrganisationPaymentInformationBox.generated";

type Organisation =
  | OrganisationPaymentInformationBox_OrganisationFragment
  | OrganisationPaymentInformationBox_ViewerOrganisationFragment;

interface Props {
  organisation: Organisation;
}

export function OrganisationPaymentInformationBox({ organisation }: Props) {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("OrganisationProfile");

  return (
    <CardContainer isExpandable title={t("Payment information")}>
      <Box p={1}>
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_ORG"
          otherwise={
            <OrganisationPaymentInformationData organisation={organisation} />
          }
        >
          {viewer.organisation.id === organisation.id &&
          !!viewer.roles.find(
            r =>
              r.internalName === "ORG_ADMIN" || r.internalName === "ORG_ADMIN"
          ) ? (
            <OrganisationPaymentInformationForm organisation={organisation} />
          ) : (
            <OrganisationPaymentInformationData organisation={organisation} />
          )}
        </RestrictedByOrganisationPermissionWithDebug>
      </Box>
    </CardContainer>
  );
}

function OrganisationPaymentInformationData({
  organisation,
}: {
  organisation: Organisation;
}) {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);

  return (
    <Stack flexDirection="column">
      <LabeledValue
        label={t("Bank name", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.bankName ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("IBAN", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.bankAccount ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("BIC code", {
          ns: "OrganisationProfile",
        })}
      >
        {organisation.bankCode ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
    </Stack>
  );
}

interface FormValues {
  bankName: string;
  bankAccount: string;
  bankCode: string;
}

function OrganisationPaymentInformationForm({
  organisation,
}: {
  organisation: Organisation;
}) {
  const { t } = useTranslate("OrganisationProfile");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyOrganisation] = useModifyOrganisationPaymentInformationMutation({
    client,
  });

  const validationSchema = Yup.object().shape({
    bankName: Yup.string().label(t("Bank name")),
    bankAccount: Yup.string().label(t("IBAN")),
    bankCode: Yup.string().label(t("BIC code")),
  });

  const initialValues = {
    bankName: organisation.bankName,
    bankAccount: organisation.bankAccount,
    bankCode: organisation.bankCode,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          await modifyOrganisation({
            variables: {
              input: {
                organisationId: organisation.id,
                bankName: values.bankName,
                bankAccount: values.bankAccount,
                bankCode: values.bankCode,
              },
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, { variant: "error" });
        }
      }}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            <Field
              component={TextField}
              name="bankName"
              label={t("Bank name")}
              disabled={false}
            />
            <Field
              component={TextField}
              name="bankAccount"
              label={t("IBAN")}
              disabled={false}
            />
            <Field
              component={TextField}
              name="bankCode"
              label={t("BIC code")}
              disabled={false}
            />
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
