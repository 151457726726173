/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from './Fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseProposedCalculationsWithOverrideQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  docId: Types.Scalars['ID']['input'];
  itemOverrides?: Types.InputMaybe<Array<Types.ItemCalculationItemOverrideInput>>;
}>;


export type UseProposedCalculationsWithOverrideQuery = { docProposedCalculation: { __typename: 'DocProposedCalculation', itemCalculations: Array<{ __typename: 'ProposedCalculation', itemId: string, itemCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null }> } };


export const UseProposedCalculationsWithOverrideDocument = gql`
    query UseProposedCalculationsWithOverride($projectId: ID, $docId: ID!, $itemOverrides: [ItemCalculationItemOverrideInput!]) {
  docProposedCalculation(
    projectId: $projectId
    docId: $docId
    decisionMode: selected
    minStateLevel: binding
    itemOverrides: $itemOverrides
  ) {
    itemCalculations {
      itemId
      itemCalculation {
        ...ItemCalculation
      }
    }
  }
}
    ${ItemCalculationFragmentDoc}`;

/**
 * __useUseProposedCalculationsWithOverrideQuery__
 *
 * To run a query within a React component, call `useUseProposedCalculationsWithOverrideQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProposedCalculationsWithOverrideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProposedCalculationsWithOverrideQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemOverrides: // value for 'itemOverrides'
 *   },
 * });
 */
export function useUseProposedCalculationsWithOverrideQuery(baseOptions: Apollo.QueryHookOptions<UseProposedCalculationsWithOverrideQuery, UseProposedCalculationsWithOverrideQueryVariables> & ({ variables: UseProposedCalculationsWithOverrideQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProposedCalculationsWithOverrideQuery, UseProposedCalculationsWithOverrideQueryVariables>(UseProposedCalculationsWithOverrideDocument, options);
      }
export function useUseProposedCalculationsWithOverrideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProposedCalculationsWithOverrideQuery, UseProposedCalculationsWithOverrideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProposedCalculationsWithOverrideQuery, UseProposedCalculationsWithOverrideQueryVariables>(UseProposedCalculationsWithOverrideDocument, options);
        }
export function useUseProposedCalculationsWithOverrideSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseProposedCalculationsWithOverrideQuery, UseProposedCalculationsWithOverrideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseProposedCalculationsWithOverrideQuery, UseProposedCalculationsWithOverrideQueryVariables>(UseProposedCalculationsWithOverrideDocument, options);
        }
export type UseProposedCalculationsWithOverrideQueryHookResult = ReturnType<typeof useUseProposedCalculationsWithOverrideQuery>;
export type UseProposedCalculationsWithOverrideLazyQueryHookResult = ReturnType<typeof useUseProposedCalculationsWithOverrideLazyQuery>;
export type UseProposedCalculationsWithOverrideSuspenseQueryHookResult = ReturnType<typeof useUseProposedCalculationsWithOverrideSuspenseQuery>;
export type UseProposedCalculationsWithOverrideQueryResult = Apollo.QueryResult<UseProposedCalculationsWithOverrideQuery, UseProposedCalculationsWithOverrideQueryVariables>;