import { LabeledValue } from "@msys/ui";
import { Chip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React, { FC } from "react";
import { Stack } from "../../commons/layout/Stack";
import { Skill } from "../../../clients/graphqlTypes";
import { Category, useCategories } from "./useCategories";

interface Props {
  label: string;
  value?: Skill[];
  align?: React.ComponentProps<typeof LabeledValue>["align"];
  chipSize?: React.ComponentProps<typeof Chip>["size"];
}

export const LabeledCategoriesValue: FC<Props> = ({
  label,
  value,
  align,
  chipSize,
}) => {
  const { t } = useTranslate(["Global"]);
  const categories = useCategories();

  const skillset = categories.allCategories.filter(c =>
    value?.includes(c.value)
  );
  return (
    <LabeledValue label={label} align={align} notSet={!skillset.length}>
      {skillset.length > 0 ? (
        <Stack flexWrap="wrap" mt={1}>
          {skillset.map((category: Category, index) => (
            <Chip
              key={index}
              icon={category.icon}
              size={chipSize ?? "small"}
              color="primary"
              variant="outlined"
              label={category.name}
            />
          ))}
        </Stack>
      ) : (
        t("Not set", {
          ns: "Global",
        })
      )}
    </LabeledValue>
  );
};
