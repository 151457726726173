import { CardContainer } from "@msys/ui";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Stack } from "../../../commons/layout/Stack";
import { ProductOverviewProductSetInfoBox_PimSupplierProductSetInfoFragment } from "./ProductOverviewSupplierProductSetInfoBox.generated";

interface Props {
  setInfo: ProductOverviewProductSetInfoBox_PimSupplierProductSetInfoFragment;
}

export const ProductOverviewSupplierProductSetInfoBox: FC<Props> = ({
  setInfo,
}) => {
  const { t } = useTranslate(["ProductOverview", "Global"]);

  return (
    <CardContainer
      title={t("Set products", {
        ns: "ProductOverview",
      })}
      isExpandable
      isInitiallyClosed={false}
    >
      <Stack flexDirection="column" width="100%" spacing={0}>
        <List disablePadding>
          {setInfo.setProducts.map(product => (
            <ListItem key={product.id}>
              <ListItemText
                primary={
                  <Typography variant="body1" color="primary">
                    {[product.articleNumber, product.texts?.title]
                      .filter(Boolean)
                      .join(" ") + ` (${product.quantity}x)`}
                  </Typography>
                }
              />
              {product.supplierProductId && (
                <Button
                  component={Link}
                  to={`/products/catalogues/supplier/${product.supplierId}/${product.articleNumber}/overview`}
                  endIcon={<OpenInNewIcon />}
                  color="secondary"
                  variant="text"
                  size="extra-small"
                  // @ts-ignore
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("View", {
                    ns: "Global",
                  })}
                </Button>
              )}
            </ListItem>
          ))}
        </List>
      </Stack>
    </CardContainer>
  );
};
