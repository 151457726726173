import CheckIcon from "@mui/icons-material/Check";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";

export const FinalizeDecisionsInBulkButton = ({
  onClick,
}: {
  onClick(): void;
}) => {
  const { t } = useTranslate(["Global"]);
  return (
    <Button
      color="primary"
      variant="outlined"
      startIcon={<CheckIcon />}
      onClick={onClick}
    >
      {t("Finalize", { ns: "Global" })}
    </Button>
  );
};
