import { gql } from "@apollo/client";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton, IconButtonProps, SvgIconProps } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  pathToTemplateDoc: string;
  iconButtonProps?: Pick<IconButtonProps, "size" | "color">;
  svgIconProps?: Pick<SvgIconProps, "fontSize" | "color">;
  disabled?: boolean;
}

export const TemplateLinkIconButton = ({
  pathToTemplateDoc,
  disabled = false,
  iconButtonProps,
  svgIconProps,
}: Props) => {
  const navigate = useNavigate();

  return (
    <IconButton
      component={Link}
      color="primary"
      to={pathToTemplateDoc}
      style={{ flexShrink: 0, flexGrow: 0 }}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        if (e.ctrlKey || e.metaKey) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        navigate(pathToTemplateDoc);
      }}
      disabled={disabled}
      {...iconButtonProps}
    >
      <LinkIcon {...svgIconProps} />
    </IconButton>
  );
};
