import { assertNever } from "@msys/common";
import { crmCompanyToEmailRecipientOption } from "../crm-companies/utils";
import { CrmCompanyToEmailRecipientOption_CrmCompanyFragment } from "../crm-companies/utils.generated";
import { crmPersonToEmailRecipientOption } from "../crm-persons/utils";
import { CrmPersonToEmailRecipientOptionl_CrmPersonFragment } from "../crm-persons/utils.generated";

type Contact =
  | CrmCompanyToEmailRecipientOption_CrmCompanyFragment
  | CrmPersonToEmailRecipientOptionl_CrmPersonFragment;

export function crmContactToEmailRecipient(contact: Contact) {
  if (contact.__typename === "CrmCompany") {
    return crmCompanyToEmailRecipientOption(contact);
  } else if (contact.__typename === "CrmPerson") {
    return crmPersonToEmailRecipientOption(contact);
  } else {
    assertNever(contact);
  }
}
