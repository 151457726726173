/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SapDocumentInfoFragment = { __typename: 'SapSalesQuotationDocumentFlowDocumentRelationShipDocumentInfo', creationDate: any, documentId: string, documentType: string, hierarchyLevel: number, orderType: string, status: string };

export type SapSalesQuotationDocumentFlowQueryVariables = Types.Exact<{
  salesQuotationId: Types.Scalars['String']['input'];
}>;


export type SapSalesQuotationDocumentFlowQuery = { sapSalesQuotationDocumentFlow: { __typename: 'SapSalesQuotationDocumentFlowResult', salesQuotationDocumentFlow: { __typename: 'SapSalesQuotationDocumentFlow', anchorDocumentId: string, documentRelationships: Array<{ __typename: 'SapSalesQuotationDocumentFlowDocumentRelationShip', fromDocument: { __typename: 'SapSalesQuotationDocumentFlowDocumentRelationShipDocumentInfo', creationDate: any, documentId: string, documentType: string, hierarchyLevel: number, orderType: string, status: string }, toDocument: { __typename: 'SapSalesQuotationDocumentFlowDocumentRelationShipDocumentInfo', creationDate: any, documentId: string, documentType: string, hierarchyLevel: number, orderType: string, status: string } }> } } };

export const SapDocumentInfoFragmentDoc = gql`
    fragment SapDocumentInfo on SapSalesQuotationDocumentFlowDocumentRelationShipDocumentInfo {
  creationDate
  documentId
  documentType
  hierarchyLevel
  orderType
  status
}
    `;
export const SapSalesQuotationDocumentFlowDocument = gql`
    query SapSalesQuotationDocumentFlow($salesQuotationId: String!) {
  sapSalesQuotationDocumentFlow(salesQuotationId: $salesQuotationId) {
    salesQuotationDocumentFlow {
      anchorDocumentId
      documentRelationships {
        fromDocument {
          ...SapDocumentInfo
        }
        toDocument {
          ...SapDocumentInfo
        }
      }
    }
  }
}
    ${SapDocumentInfoFragmentDoc}`;

/**
 * __useSapSalesQuotationDocumentFlowQuery__
 *
 * To run a query within a React component, call `useSapSalesQuotationDocumentFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useSapSalesQuotationDocumentFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSapSalesQuotationDocumentFlowQuery({
 *   variables: {
 *      salesQuotationId: // value for 'salesQuotationId'
 *   },
 * });
 */
export function useSapSalesQuotationDocumentFlowQuery(baseOptions: Apollo.QueryHookOptions<SapSalesQuotationDocumentFlowQuery, SapSalesQuotationDocumentFlowQueryVariables> & ({ variables: SapSalesQuotationDocumentFlowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SapSalesQuotationDocumentFlowQuery, SapSalesQuotationDocumentFlowQueryVariables>(SapSalesQuotationDocumentFlowDocument, options);
      }
export function useSapSalesQuotationDocumentFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SapSalesQuotationDocumentFlowQuery, SapSalesQuotationDocumentFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SapSalesQuotationDocumentFlowQuery, SapSalesQuotationDocumentFlowQueryVariables>(SapSalesQuotationDocumentFlowDocument, options);
        }
export function useSapSalesQuotationDocumentFlowSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SapSalesQuotationDocumentFlowQuery, SapSalesQuotationDocumentFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SapSalesQuotationDocumentFlowQuery, SapSalesQuotationDocumentFlowQueryVariables>(SapSalesQuotationDocumentFlowDocument, options);
        }
export type SapSalesQuotationDocumentFlowQueryHookResult = ReturnType<typeof useSapSalesQuotationDocumentFlowQuery>;
export type SapSalesQuotationDocumentFlowLazyQueryHookResult = ReturnType<typeof useSapSalesQuotationDocumentFlowLazyQuery>;
export type SapSalesQuotationDocumentFlowSuspenseQueryHookResult = ReturnType<typeof useSapSalesQuotationDocumentFlowSuspenseQuery>;
export type SapSalesQuotationDocumentFlowQueryResult = Apollo.QueryResult<SapSalesQuotationDocumentFlowQuery, SapSalesQuotationDocumentFlowQueryVariables>;