/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationTagsQueryVariables = Types.Exact<{
  group: Types.TagGroup;
}>;


export type OrganisationTagsQuery = { viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string, systemTags: Array<string>, customTags: Array<string> } } | null };


export const OrganisationTagsDocument = gql`
    query OrganisationTags($group: TagGroup!) {
  viewer {
    id
    organisation {
      id
      systemTags
      customTags: organisationTags(filter: {group: $group})
    }
  }
}
    `;

/**
 * __useOrganisationTagsQuery__
 *
 * To run a query within a React component, call `useOrganisationTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationTagsQuery({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useOrganisationTagsQuery(baseOptions: Apollo.QueryHookOptions<OrganisationTagsQuery, OrganisationTagsQueryVariables> & ({ variables: OrganisationTagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationTagsQuery, OrganisationTagsQueryVariables>(OrganisationTagsDocument, options);
      }
export function useOrganisationTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationTagsQuery, OrganisationTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationTagsQuery, OrganisationTagsQueryVariables>(OrganisationTagsDocument, options);
        }
export function useOrganisationTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationTagsQuery, OrganisationTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationTagsQuery, OrganisationTagsQueryVariables>(OrganisationTagsDocument, options);
        }
export type OrganisationTagsQueryHookResult = ReturnType<typeof useOrganisationTagsQuery>;
export type OrganisationTagsLazyQueryHookResult = ReturnType<typeof useOrganisationTagsLazyQuery>;
export type OrganisationTagsSuspenseQueryHookResult = ReturnType<typeof useOrganisationTagsSuspenseQuery>;
export type OrganisationTagsQueryResult = Apollo.QueryResult<OrganisationTagsQuery, OrganisationTagsQueryVariables>;