import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { AddProjectMemberCRMModal } from "../modals/AddProjectMemberCRMModal";

interface Props {
  projectId: string;
  title?: string;
  userId?: string;
  style?: React.CSSProperties;
  refetchQueries?: string[];
  Button?: React.ReactElement;
}

export const AddProjectMemberCRMButton = ({
  projectId,
  userId,
  title,
  style,
  refetchQueries,
  Button,
}: Props) => {
  const { t } = useTranslate("ProjectMembers");
  const defaultTitle = t("Invite contractee");

  const button = Button ?? (
    <IconButton
      color="primary"
      aria-label={title || defaultTitle}
      size="small"
      style={style}
    >
      <AddIcon />
    </IconButton>
  );

  return (
    <ModalOpenButton
      Modal={AddProjectMemberCRMModal}
      modalProps={{
        projectId,
        userId,
        refetchQueries,
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
