import { gql, useApolloClient } from "@apollo/client";
import {
  Attachment,
  CollapseSection,
  isImageOr3dModel,
  LabeledValue,
  Modal,
  processAttachment,
  useFormatting,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import RemoveDone from "@mui/icons-material/RemoveDone";
import {
  Box,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { omit, partition } from "lodash";
import moment, { Moment } from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AutoSave } from "../../commons/form-fields/AutoSave";
import { DateAndTimePickersField } from "../../commons/form-fields/DateAndTimePickersField";
import { SelectField } from "../../commons/form-fields/SelectField";
import { GalleryGrid } from "../../commons/images/GalleryGrid";
import { Stack } from "../../commons/layout/Stack";
import { AttachmentInput } from "../../../clients/graphqlTypes";
import { useToggleTodoItemDoneMutation } from "../../main-routes/tasks/TasksList.generated";
import { AttachmentSnapshotFragment } from "../attachments/Attachments.generated";
import { AttachmentUploader } from "../attachments/AttachmentUploader";
import { PlanningIcon } from "../planning/PlanningIcon";
import { getMemberOptions } from "../users/helpers";
import { UserIcon } from "../users/UserIcon";
import {
  useEditTodoItemModalQuery,
  useModifyTodoItemMutation,
} from "./EditTodoItemModal.generated";
import { RelatedToBox } from "./RelatedToBox";

interface FormValues {
  title: string;
  description?: string;
  time?: Moment;
  assigneeId: string;
  attachments: (Attachment | AttachmentSnapshotFragment)[];
  linkedProjectId: string | null;
  linkedCrmCompanyId: string | null;
  linkedCrmPersonId: string | null; // deprecated
}

interface Props {
  id: string;
  disableAssigneeInput?: boolean;
  title?: string;
  handleClose: () => void;
  refetchQueries?: string[];
}

export const EditTodoItemModal: React.FC<Props> = ({
  id,
  disableAssigneeInput = false,
  title,
  handleClose,
  refetchQueries,
}) => {
  const { t } = useTranslate(["Tasks", "Global"]);
  const navigate = useNavigate();
  const { getFormattedDateTime } = useFormatting();

  const client = useApolloClient();
  const query = useEditTodoItemModalQuery({
    client,
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  const assigneeOptions = getMemberOptions(
    (query.data ?? query.previousData)?.orgMembers ?? []
  );
  const [modifyTodoItem] = useModifyTodoItemMutation({
    client,
    refetchQueries,
  });
  const [toggleTodoDone, { loading: toggleTodoDoneLoading }] =
    useToggleTodoItemDoneMutation({
      client,
      refetchQueries,
    });

  async function onSubmit({
    attachments,
    assigneeId,
    linkedProjectId,
    linkedCrmCompanyId,
    linkedCrmPersonId,
    ...values
  }: FormValues) {
    const res = await modifyTodoItem({
      variables: {
        input: {
          id,
          ...values,
          linkedProjectId: linkedProjectId,
          linkedCrmOrganisationId: linkedCrmCompanyId,
          linkedCrmUserId: linkedCrmPersonId,
          assigneeId: assigneeId || undefined,
          attachments: attachments
            .map(a => omit(a, ["id", "__typename", "__type"]))
            .filter(a => a.url) as AttachmentInput[],
        },
      },
    });

    const todoItemId = res.data?.modifyTodoItem.todoItem.id;
    if (!todoItemId)
      throw new Error(
        t("Failed to update task item", {
          ns: "Tasks",
        })
      );
  }

  const attachmentUploaderRef = React.useRef<HTMLInputElement>(null);
  const onAddAttachment = () => {
    if (attachmentUploaderRef.current) {
      attachmentUploaderRef.current.click();
    }
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(
            t("Title", {
              ns: "Tasks",
            })
          )
          .required(),
        description: Yup.string().label(
          t("Description", {
            ns: "Tasks",
          })
        ),
        time: Yup.date().label(
          t("Time", {
            ns: "Tasks",
          })
        ),
        assigneeId: Yup.string().label(
          t("Assignee", {
            ns: "Tasks",
          })
        ),
      }),
    [t]
  );

  const todoItem = (query.data ?? query.previousData)?.todoItem;

  if (!todoItem) return null;

  const linkedCrmOrganisation = todoItem.linkedCrmOrganisation;
  const linkedCrmUser = todoItem.linkedCrmUser;
  const linkedProject = todoItem.linkedProject;

  const isLinked = !!(
    linkedCrmOrganisation?.id ??
    linkedCrmUser?.id ??
    linkedProject?.id
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
      initialValues={{
        title: todoItem.title,
        description: todoItem.description,
        assigneeId: todoItem.assignee?.id ?? "",
        time: todoItem.time ? moment(todoItem.time) : undefined,
        attachments: todoItem.attachments,
        linkedProjectId: linkedProject?.id ?? null,
        linkedCrmCompanyId: linkedCrmOrganisation?.id ?? null,
        linkedCrmPersonId: linkedCrmUser?.id ?? null,
      }}
    >
      {({ setFieldValue, setValues, values }) => (
        <Modal
          title={title ?? todoItem?.title}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: todoItem?.isDone
                ? t("Reopen task", { ns: "Tasks" })
                : t("Mark as done", { ns: "Tasks" }),
              handleClick: async () => {
                if (todoItem) {
                  await toggleTodoDone({
                    variables: {
                      input: { id: todoItem.id, isDone: !todoItem.isDone },
                    },
                  });
                }
              },
              buttonProps: {
                color: todoItem?.isDone ? "warning" : "secondary",
                startIcon: todoItem?.isDone ? <RemoveDone /> : <CheckIcon />,
                loading: toggleTodoDoneLoading,
              },
            },
          ]}
        >
          <Form>
            <Stack flexDirection="column">
              <RelatedToBox
                linkedProjectId={values.linkedProjectId}
                linkedCrmCompanyId={values.linkedCrmCompanyId}
                linkedCrmPersonId={values.linkedCrmPersonId}
                handleDelete={() =>
                  setValues(values => ({
                    ...values,
                    linkedProjectId: null,
                    linkedCrmCompanyId: null,
                    linkedCrmPersonId: null,
                  }))
                }
                handleChange={(crmCompanyId, projectId) => {
                  setValues(values => ({
                    ...values,
                    linkedProjectId: projectId,
                    linkedCrmCompanyId: crmCompanyId,
                    linkedCrmUserId: null,
                  }));
                }}
              />
              <Field
                component={TextField}
                label={t("Title", {
                  ns: "Tasks",
                })}
                name="title"
                required
                disabled={false}
              />
              <Field
                component={TextField}
                label={t("Description", {
                  ns: "Tasks",
                })}
                name="description"
                multiline
                rows={4}
                disabled={false}
              />
              <DateAndTimePickersField
                name="time"
                dateLabel={t("Date", {
                  ns: "Tasks",
                })}
                timeLabel={t("Time", {
                  ns: "Tasks",
                })}
              />
              <SelectField
                label={t("Assignee", {
                  ns: "Tasks",
                })}
                name="assigneeId"
                options={assigneeOptions}
                disabled={disableAssigneeInput}
              />

              <CollapseSection
                title={t("Files", {
                  ns: "Tasks",
                })}
                itemCount={values.attachments.length}
                ActionButtons={
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      onAddAttachment();
                    }}
                  >
                    <AddIcon />
                    <AttachmentUploader
                      innerRef={attachmentUploaderRef}
                      accept={"*"}
                      multiple={true}
                      onComplete={attachments => {
                        setFieldValue("attachments", [
                          ...values.attachments,
                          ...attachments,
                        ]);
                      }}
                    />
                  </IconButton>
                }
              >
                <FieldArray
                  name="attachments"
                  render={arrayHelpers => {
                    const typedAttachments =
                      values.attachments.map(processAttachment);
                    const [images, files] = partition(
                      typedAttachments,
                      isImageOr3dModel
                    );
                    return (
                      <>
                        <Box>
                          <GalleryGrid
                            images={images}
                            handleClick={null}
                            handleDelete={image => {
                              arrayHelpers.remove(
                                typedAttachments.indexOf(image)
                              );
                            }}
                            columns={4}
                            showDeleteButton={true}
                          />
                        </Box>
                        {files.length > 0 && (
                          <List disablePadding>
                            {files.map((file, index) => (
                              <Stack
                                key={index}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <ListItem>
                                  <MuiLink href={file.url} target="_blank">
                                    <Stack>
                                      <DescriptionIcon fontSize="small" />
                                      {file.title}
                                    </Stack>
                                  </MuiLink>
                                </ListItem>
                                <Tooltip
                                  title={t("Delete File", {
                                    ns: "Tasks",
                                  })}
                                >
                                  <IconButton
                                    aria-label={t("Delete File", {
                                      ns: "Tasks",
                                    })}
                                    color="primary"
                                    onClick={() => {
                                      arrayHelpers.remove(
                                        typedAttachments.indexOf(file)
                                      );
                                    }}
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            ))}
                          </List>
                        )}
                      </>
                    );
                  }}
                />
              </CollapseSection>

              <CollapseSection
                title={t("More details", {
                  ns: "Tasks",
                })}
                isInitiallyExpanded={false}
              >
                <Stack flexDirection="column">
                  <Stack>
                    <Box width="50%">
                      <DetailItem
                        Icon={<UserIcon />}
                        label={t("Created by", {
                          ns: "Tasks",
                        })}
                        value={todoItem?.createdBy.fullname}
                      />
                    </Box>
                    <Box width="50%">
                      <DetailItem
                        Icon={<PlanningIcon />}
                        label={t("Created at", {
                          ns: "Tasks",
                        })}
                        value={
                          todoItem?.createdAt &&
                          getFormattedDateTime(todoItem?.createdAt)
                        }
                      />
                    </Box>
                  </Stack>
                  {todoItem?.completedAt && (
                    <Stack>
                      <Box width="50%">
                        <DetailItem
                          Icon={<UserIcon />}
                          label={t("Completed by", {
                            ns: "Tasks",
                          })}
                          value={todoItem?.completedBy?.fullname}
                        />
                      </Box>
                      <Box width="50%">
                        <DetailItem
                          Icon={<PlanningIcon />}
                          label={t("Completed at", {
                            ns: "Tasks",
                          })}
                          value={
                            todoItem?.completedAt &&
                            getFormattedDateTime(todoItem?.completedAt)
                          }
                        />
                      </Box>
                    </Stack>
                  )}
                </Stack>
              </CollapseSection>
              <AutoSave />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

function DetailItem({
  Icon,
  label,
  value = "",
}: {
  Icon: JSX.Element;
  label: string;
  value: string | undefined;
}) {
  return (
    <Stack alignItems="center">
      <Box marginY={0.5}>{Icon}</Box>
      <Box alignSelf="flex-start">
        <LabeledValue label={label}>{value}</LabeledValue>
      </Box>
    </Stack>
  );
}
