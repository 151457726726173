import { gql, useApolloClient } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import { useSubscribeToQuoteTemplateMutation } from "./QuoteTemplateSubscribeButton.generated";
import { QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplateFragment } from "./QuoteTemplateUpdateSubscribedVersionButton.generated";

interface Props {
  doc: QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplateFragment;
  pathToDoc: string;
}
export function QuoteTemplateUpdateSubscribedVersionButton({
  doc,
  pathToDoc,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslate(["Templates"]);

  const client = useApolloClient();
  const [subscribe, { loading }] = useSubscribeToQuoteTemplateMutation({
    client,
  });

  return (
    <LoadingButton
      variant="contained"
      color="warning"
      onClick={async () => {
        const result = await subscribe({
          variables: { input: { quoteTemplateId: doc.id } },
        });
        const subscribedVersion =
          result.data?.subscribeToQuoteTemplate.quoteTemplate
            .subscribedToTemplate?.subscribedVersionNumber;
        if (!subscribedVersion) throw new Error("Subscribed version missing");

        navigate(`${pathToDoc}/${subscribedVersion}`);
      }}
      loading={loading}
    >
      {t("Get latest version", { ns: "Templates" })}
    </LoadingButton>
  );
}
