import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DebouncedSearchInput,
  LoadingSpinner as LoadingIndicator,
  Modal,
} from "@msys/ui";
import { Box, List, ListItemButton, Typography, useTheme } from "@mui/material";
import { uniqueId } from "lodash";
import React from "react";
import { Stack } from "../../../commons/layout/Stack";
// import { Stack } from "../../../commons/layout/Stack";
import {
  PickBrandModalSearchBrands_BrandFragment,
  usePickBrandModalSearchBrandsQuery,
} from "./PickBrandModal.generated";
import { useTranslate } from "@tolgee/react";

export interface Brand {
  id: string;
  title: string;
}

interface Props {
  title?: string;
  brand?: Brand | undefined | null;
  handleClose: () => void;
  handleComplete?: (brand: Brand) => void;
}

export function PickBrandModal({
  title,
  brand,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["ProductOverview", "Global", "QuoteCreate"]);
  const client = useApolloClient();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedBrand, setSelectedBrand] = React.useState(brand ?? null);

  const query = usePickBrandModalSearchBrandsQuery({
    client,
    variables: {
      searchTerm,
    },
  });

  const formId = React.useMemo(() => uniqueId(), []);

  const brands = getDataOrNull(query.data?.pimSearchBrands)?.brands;

  return (
    <Modal
      title={
        title ??
        t("Pick brand", {
          ns: "ProductOverview",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          buttonProps: {
            disabled: brand?.id === selectedBrand?.id || !selectedBrand,
            form: formId,
            type: "submit",
          },
        },
      ]}
    >
      <form
        id={formId}
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();
          if (selectedBrand) {
            handleComplete?.(selectedBrand);
          }
          handleClose();
        }}
        style={{ maxHeight: "100%" }}
      >
        <Stack flexDirection="column">
          <DebouncedSearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            defaultValue={searchTerm}
            onChangeSearchTerm={setSearchTerm}
            autoFocus
          />
          {brands?.length === 10 && (
            <Typography color="secondary" variant="caption">
              {t("Showing only top 10 results", {
                ns: "QuoteCreate",
              })}
            </Typography>
          )}
          <Box minHeight="20em">
            <SearchResults
              isLoading={query.loading}
              results={brands}
              selectedBrand={selectedBrand}
              selectBrand={setSelectedBrand}
            />
          </Box>
        </Stack>
      </form>
    </Modal>
  );
}

const SearchResults: React.FC<{
  isLoading: boolean;
  results: PickBrandModalSearchBrands_BrandFragment[] | undefined;
  selectedBrand: Brand | null;
  selectBrand: (brand: Brand) => void;
}> = ({ isLoading, results = [], selectedBrand, selectBrand }) => {
  const theme = useTheme();
  const { t } = useTranslate("QuoteCreate");

  if (isLoading) return <LoadingIndicator />;
  if (results.length === 0)
    return (
      <Box paddingX={2} lineHeight={2} color={theme.palette.secondary.main}>
        {t("No results")}
      </Box>
    );

  return (
    <List dense disablePadding>
      {results.map(result => (
        <ListItemButton
          key={result.id}
          onClick={() => selectBrand(result)}
          selected={selectedBrand?.id === result.id}
        >
          {result.title}
        </ListItemButton>
      ))}
    </List>
  );
};
