import { useApolloClient } from "@apollo/client";
import { DataGrid, DebouncedSearchInput, GridColDef, Select } from "@msys/ui";
import { Typography } from "@mui/material";
import { isEqual } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
// import EmptyScreen from "../layout/EmptyScreen";
import { ListHeader, ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import {
  QuerySearchSuppliersSortBy,
  QuerySearchSuppliersSorting,
  SortDirection,
} from "../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { ButtonCreate } from "../../commons/button/Button";
import { FilterButton } from "../../commons/filters/FilterButton";
import { FilterModal } from "../../commons/filters/FilterModal";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { Stack } from "../../commons/layout/Stack";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { OrganisationAvatar } from "../../features/organisations/OrganisationAvatar";
import { CreateOrganisationModal } from "../../features/organisations/modals/CreateOrganisationModal";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  GlobalSuppliersTableQueryVariables,
  useGlobalSuppliersTableQuery,
} from "./GlobalSupplierOrganisations.generated";

type Filters = Omit<GlobalSuppliersTableQueryVariables, "offset" | "limit">;

const DEFAULT_SORTING: QuerySearchSuppliersSorting[] = [
  {
    column: "name",
    direction: "asc",
  },
];

export function GlobalSupplierOrganisations() {
  const { t } = useTranslate(["CrmOrganisations", "Global"]);
  const navigate = useNavigate();
  const client = useApolloClient();

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
  } = useFiltersAndPagination<QuerySearchSuppliersSorting, Filters>(
    { search: "" },
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const query = useGlobalSuppliersTableQuery({
    client,
    fetchPolicy: "cache-and-network",
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...filters,
      sorting,
    },
  });

  const createOrganisationTitle = t("Create global supplier", {
    ns: "CrmOrganisations",
  });

  const organisations =
    (query.data ?? query.previousData)?.searchSuppliers.edges.map(
      e => e.node.organisation
    ) ?? [];
  const total =
    (query.data ?? query.previousData)?.searchSuppliers.totalCount ?? 0;

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "name",
        headerName: t("Name", {
          ns: "CrmOrganisations",
        }),
        renderCell: ({ row: organisation }) => (
          <Stack alignItems="center" spacing={2}>
            <OrganisationAvatar organisationAvatar={organisation} size="s" />
            <div>{organisation.title}</div>
          </Stack>
        ),
        flex: 2,
      },
      {
        field: "address",
        headerName: t("Address", {
          ns: "CrmOrganisations",
        }),
        valueGetter: ({ row: { branchAddress, billingAddress } }) => {
          const address = branchAddress || billingAddress;
          return address ? `${address?.postalCode} ${address?.city}` : "";
        },
        flex: 1,
      },
    ],
    [t]
  );

  const stateStore = useDataGridStateStore("GlobalSupplierOrganisations");

  return (
    <Page
      title={t("Global Suppliers", {
        ns: "CrmOrganisations",
      })}
    >
      <PageContainer>
        <ListHeader
          hideTitleOnMobile
          title={t("Global Suppliers", {
            ns: "CrmOrganisations",
          })}
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_GLOBAL_SUPPLIER">
              <ModalOpenButton
                Modal={CreateOrganisationModal}
                modalProps={{
                  title: createOrganisationTitle,
                  predefinedOrganisationType: "SUPPLIER",
                  handleComplete: async (supplierOrganisationId, _userId) => {
                    navigate(`/globalsuppliers/${supplierOrganisationId}`);
                  },
                }}
              >
                <ButtonCreate title={createOrganisationTitle} />
              </ModalOpenButton>
            </RestrictedByOrganisationPermissionWithDebug>
          }
          FilterButton={
            <ModalOpenButton
              Modal={GlobalSuppliersFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.search}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  search: newValue,
                }))
              }
            />
          }
          mb={2}
        />

        <DataGrid
          stateStore={stateStore}
          density={"standard"}
          loading={query.loading}
          hideFooter={total === 0}
          columns={columns}
          rows={organisations}
          sortModel={sorting.map(s => ({ field: s.column, sort: s.direction }))}
          onSortModelChange={newModel => {
            setSorting(
              newModel.map(({ field, sort }) => ({
                column: field as QuerySearchSuppliersSortBy,
                direction: sort ?? "asc",
              }))
            );
          }}
          onRowClick={({ row: organisation }) => {
            navigate(`/globalsuppliers/${organisation.id}`);
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={total}
        />
      </PageContainer>
    </Page>
  );
}

function GlobalSuppliersFilterModal({
  handleClose,
  sorting,
  setSorting,
  filters,
  setFilters,
  resetFilters,
}: {
  handleClose: () => void;
  sorting: QuerySearchSuppliersSorting[];
  setSorting: (sorting: QuerySearchSuppliersSorting[]) => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  resetFilters: () => void;
}) {
  const { t } = useTranslate([
    "CrmOrganisationSorting",
    "CrmOrganisations",
    "Global",
  ]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  const sortByOptions = React.useMemo(() => {
    const suppliersSortingLabels: Record<
      QuerySearchSuppliersSortBy,
      Record<SortDirection, string>
    > = {
      name: {
        asc: t("ORGANISATION_NAME_ASC", {
          ns: "CrmOrganisationSorting",
        }),
        desc: t("ORGANISATION_NAME_DESC", {
          ns: "CrmOrganisationSorting",
        }),
      },
    };

    const ALL_SORTING_FIELDS: QuerySearchSuppliersSortBy[] = ["name"];

    return ALL_SORTING_FIELDS.flatMap(value => [
      {
        label: suppliersSortingLabels[value]["asc"],
        value: { column: value, direction: "asc" as SortDirection },
      },
      {
        label: suppliersSortingLabels[value]["desc"],
        value: { column: value, direction: "desc" as SortDirection },
      },
    ]);
  }, [t]);

  return (
    <FilterModal
      title={t("Suppliers Filters", {
        ns: "CrmOrganisations",
      })}
      handleApply={() => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleClose={handleClose}
      handleReset={resetFilters}
    >
      <Typography variant="h3">
        {t("Sort by", {
          ns: "Global",
        })}
      </Typography>
      <Select
        label={t("Sort by", {
          ns: "Global",
        })}
        options={sortByOptions}
        value={
          sortByOptions.find(option => isEqual(option.value, sortingState[0]))
            ?.value
        }
        onChange={value => {
          setSortingState(value ? [value] : DEFAULT_SORTING);
        }}
      />
    </FilterModal>
  );
}
