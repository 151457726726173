import { CardContainer, LabeledTextValue } from "@msys/ui";
import { FC } from "react";
import { Stack } from "../../../commons/layout/Stack";
import { ProductOverview__ProductFragment } from "../Product.generated";
import { useTranslate } from "@tolgee/react";

// interface FormValues {
//   priceUnit: string;
//   priceUnitAmount: number;
// }

interface Props {
  product: ProductOverview__ProductFragment;
  isEditable?: boolean;
}

const ProductInstallationData: FC<Props> = ({ product, isEditable }) => {
  const { t } = useTranslate(["ProductOverview", "Global"]); // TODO

  return (
    <Stack flexDirection="column" p={1}>
      <Stack flexDirection="row">
        <Stack flex={1}>
          <LabeledTextValue
            label={t("Price Unit Amount", { ns: "ProductOverview" })}
            text={product.pricing?.priceUnitAmount?.toString(10)}
            notSetLabel={t("Not set", { ns: "Global" })}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
          />
        </Stack>
        <Stack flex={1}>
          <LabeledTextValue
            label={t("Price Unit", { ns: "ProductOverview" })}
            text={product.pricing?.priceUnit ?? undefined}
            notSetLabel={t("Not set", { ns: "Global" })}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const ProductOverviewInstallationBox: FC<Props> = ({ product }) => {
  const { t } = useTranslate("ProductOverview"); // TODO: edit namespace

  // const initialValues: FormValues = {
  //   priceUnit: product.pricing.priceUnit,
  //   priceUnitAmount: product.pricing.priceUnitAmount,
  // };

  // const handleSubmit = async (values: FormValues) => {
  //   await modifyItem({
  //     variables: {
  //       input: {
  //         itemId,
  //         docId,
  //         projectId,
  //         values: {
  //           title: values.title,
  //           description: values.description,
  //         },
  //       },
  //     },
  // };

  return (
    <CardContainer
      title={t("Installation and Ordering")}
      isExpandable
      isInitiallyClosed={false}
    >
      {/* <Formik<FormValues>
        validationSchema={Yup.object().shape({
          priceUnit: Yup.string(),
          priceUnitAmount: Yup.number(),
        })}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Stack flexDirection="column" m={1}>
              <Typography variant={"h4"}>
                {" "}
                {"Installation and ordering"}
              </Typography>
              <CardContainer>
                <Stack p={1} flexDirection="row">
                  <ProductInstallationData product={product} /> // TODO: adjust
                </Stack>
              </CardContainer>
            </Stack>
          </Form>
        )}
      </Formik> */}
      <ProductInstallationData product={product} />
    </CardContainer>
  );
};
