import { Modal } from "@msys/ui";
import { Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  ProductCategoryTreeSelect,
  useProductCategoryTree,
} from "./ProductCategoryTreeSelect";

export const ProductCategoryTreeSelectModal = ({
  title,
  supplierId: supplierIdProp,
  categoryKey: categoryKeyProp,
  handleSelect,
  handleClose,
}: {
  title?: string;
  categoryKey: string | null;
  supplierId: string;
  handleClose(): void;
  handleSelect(filter: {
    supplierProductCategoryKey?: string | null;
    supplierId?: string | null;
  }): void;
}) => {
  const { t } = useTranslate(["Product", "ProductSearch", "Global"]);

  const [supplierId, setSupplierId] = React.useState<string>(supplierIdProp);
  const [categoryKey, setCategoryKey] = React.useState<string | null>(
    categoryKeyProp
  );
  const productCategoryProps = useProductCategoryTree(supplierId, categoryKey);

  const showCategoriesSelect = Boolean(
    productCategoryProps.supplier &&
      (productCategoryProps.childCategories.length ||
        productCategoryProps.productCategory)
  );

  return (
    <Modal
      title={title ?? t("Product category", { ns: "ProductSearch" })}
      handleClose={handleClose}
      maxWidth="sm"
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Apply", { ns: "Global" }),
          handleClick: () => {
            handleSelect({
              // supplierId,
              supplierProductCategoryKey: categoryKey,
            });
            handleClose();
          },
        },
      ]}
    >
      {showCategoriesSelect ? (
        <ProductCategoryTreeSelect
          {...productCategoryProps}
          categoryKey={categoryKey}
          showAllProductsButton={false}
          onSelect={({ supplierProductCategoryKey }) => {
            if (supplierProductCategoryKey !== undefined)
              setCategoryKey(supplierProductCategoryKey);
          }}
        />
      ) : (
        <Typography
          align="center"
          paddingY={2}
          sx={{ color: "grey.500" }}
          variant="body2"
        >
          {t("No categories for supplier", { ns: "ProductSearch" })}
        </Typography>
      )}
    </Modal>
  );
};
