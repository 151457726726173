/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RedeemLinkingInvitationTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type RedeemLinkingInvitationTokenMutation = { redeemLinkingInvitationToken: boolean };


export const RedeemLinkingInvitationTokenDocument = gql`
    mutation RedeemLinkingInvitationToken($token: String!) {
  redeemLinkingInvitationToken(token: $token)
}
    `;
export type RedeemLinkingInvitationTokenMutationFn = Apollo.MutationFunction<RedeemLinkingInvitationTokenMutation, RedeemLinkingInvitationTokenMutationVariables>;

/**
 * __useRedeemLinkingInvitationTokenMutation__
 *
 * To run a mutation, you first call `useRedeemLinkingInvitationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemLinkingInvitationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemLinkingInvitationTokenMutation, { data, loading, error }] = useRedeemLinkingInvitationTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRedeemLinkingInvitationTokenMutation(baseOptions?: Apollo.MutationHookOptions<RedeemLinkingInvitationTokenMutation, RedeemLinkingInvitationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemLinkingInvitationTokenMutation, RedeemLinkingInvitationTokenMutationVariables>(RedeemLinkingInvitationTokenDocument, options);
      }
export type RedeemLinkingInvitationTokenMutationHookResult = ReturnType<typeof useRedeemLinkingInvitationTokenMutation>;
export type RedeemLinkingInvitationTokenMutationResult = Apollo.MutationResult<RedeemLinkingInvitationTokenMutation>;
export type RedeemLinkingInvitationTokenMutationOptions = Apollo.BaseMutationOptions<RedeemLinkingInvitationTokenMutation, RedeemLinkingInvitationTokenMutationVariables>;