import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { TitleWithPathForPdf, useScreenWidth } from "@msys/ui";
import { noop } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useUserData } from "../../../auth/useUserData";
import { usePageWidth } from "../../../commons/hooks/usePageWidth";
import { PageFullScreenModal } from "../../../commons/layout/PageFullScreenModal";
import { PageMiddleColumn } from "../../../commons/layout/PageMiddleColumn";
import { Stack } from "../../../commons/layout/Stack";
import { HeaderBox } from "../../../features/doc-items/boxes/HeaderBox";
import { ItemFilesBox } from "../../../features/doc-items/boxes/ItemFilesBox";
import { ProductBox } from "../../../features/doc-items/boxes/ProductBox";
import { EmptyStateItem } from "../../../features/doc-items/items/EmptyStateItem";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { fromVersionNumberParamToVersionNumberInt } from "../../../utils";
import { useTemplateQuoteEditItemQuery } from "./TemplateQuoteEditItem.generated";
import { useUnnestedTemplateQuoteEditItemQuery } from "./UnnestedTemplateQuoteEditItem.generated";

const REFETCH_QUERY = [namedOperations.Query.TemplateQuoteEditItem];

export function UnnestedTemplateQuoteEditItem({
  docId,
  pathToDocPage,
  pathToDocList,
  isAbsolute,
}: {
  docId: string;
  pathToDocPage: string;
  pathToDocList: string;
  isAbsolute?: boolean;
}) {
  const { itemId } = useParams();
  if (!itemId) throw new Error("Quote template item id is missing");
  const navigate = useNavigate();

  const { isMinTablet } = useScreenWidth();
  const { isMinTwoColumnsWithPreview } = usePageWidth();

  const client = useApolloClient();
  const query = useUnnestedTemplateQuoteEditItemQuery({
    client,
    variables: { id: docId },
  });

  const quoteTemplate = getDataOrNull(
    query.data?.quoteTemplateLatest
  )?.quoteTemplate;
  if (!quoteTemplate) return null;

  const allDocItems = quoteTemplate.items;
  const item = allDocItems.find(i => i.id === itemId);

  if (!item)
    return (
      <EmptyStateItem
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      />
    );

  const quoteTemplateItem = (
    <TemplateQuoteItem
      key={itemId} // force reset forms
      docId={item.docId}
      itemId={item.originItemId}
      pathToDocList={pathToDocList}
    />
  );

  const title = (
    <TitleWithPathForPdf title={item.title} pathForPdf={item.pathForPdf} />
  );

  if (isMinTablet) {
    return (
      <PageMiddleColumn
        key={itemId}
        hasRightBorder
        hasBackground
        isSelected
        isAbsolute={isAbsolute ?? !isMinTwoColumnsWithPreview}
        title={title}
        onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
      >
        {quoteTemplateItem}
      </PageMiddleColumn>
    );
  }

  return (
    <PageFullScreenModal
      key={itemId}
      title={title}
      onCloseButtonClick={() => navigate(pathToDocPage, { replace: true })}
    >
      {quoteTemplateItem}
    </PageFullScreenModal>
  );
}

const TemplateQuoteItem = ({
  itemId,
  docId,
}: {
  docId: string;
  itemId: string;
  pathToDocList: string;
}) => {
  const { versionNumber: versionNumberFromParams } = useParams();

  const versionNumber = fromVersionNumberParamToVersionNumberInt(
    versionNumberFromParams
  );

  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const query = useTemplateQuoteEditItemQuery({
    client,
    variables: {
      docId,
      itemId,
    },
    fetchPolicy: "cache-and-network",
    skip: !!versionNumber,
  });

  const quoteTemplate = getDataOrNull(
    query.data?.quoteTemplateLatest
  )?.quoteTemplate;
  const item = quoteTemplate?.item[0];

  if (query.loading && !query.data) return null;
  if (!item) return <div>Item not found</div>;

  return (
    <Stack flexDirection="column">
      <HeaderBox
        projectId={null}
        docId={docId}
        docType="TEMPLATE"
        item={item}
        viewMode={null}
        setViewMode={noop}
        onUpdateDataRefetchQueries={REFETCH_QUERY}
      />
      {item.type === "paid" && (
        <ProductBox
          project={null}
          docId={docId}
          docType="TEMPLATE"
          item={item}
          isEditable={false}
          viewerIsContractor={true}
          onUpdateDataRefetchQueries={REFETCH_QUERY}
        />
      )}
      <ItemFilesBox
        projectId={null}
        docId={quoteTemplate.id}
        docType="TEMPLATE"
        viewerPermissions={viewer.organisationPermissions}
        item={item}
        isEditable={false}
      />
    </Stack>
  );
};
