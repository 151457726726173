/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProductOverviewProductSetInfoBox_PimSupplierProductSetInfoFragment = { __typename: 'PimSupplierProductSetInfo', id: string, supplierProductId: string, setArticleNumber: string, supplierId: string, setProducts: Array<{ __typename: 'PimSupplierProductSetProduct', id: string, articleNumber: string, quantity: number, supplierId: string, supplierProductId?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null } | null }> };

export const ProductOverviewProductSetInfoBox_PimSupplierProductSetInfoFragmentDoc = gql`
    fragment ProductOverviewProductSetInfoBox_PimSupplierProductSetInfo on PimSupplierProductSetInfo {
  id
  supplierProductId
  setArticleNumber
  supplierId
  setProducts {
    id
    articleNumber
    quantity
    supplierId
    supplierProductId
    texts {
      title
      description
    }
  }
}
    `;