import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue } from "@msys/ui";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import React from "react";
import { useOutletContext } from "react-router-dom";
import {
  DecisionBehaviorOfSubitems,
  DocType,
} from "../../../../clients/graphqlTypes";
import { useUserData } from "../../../auth/useUserData";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { InputComponentProps } from "../../../trees/types";
import {
  createRecursiveTryFocusElementFunction,
  isInTestMode,
} from "../../../utils";
import { DecisionOptionListItem } from "../DecisionOptionListItem";
import {
  DecisionOptionsType,
  DecisionOptionsTypeTabs,
} from "../DecisionOptionsTypeTabs";
import {
  QuestionControlSection,
  QuestionControlSectionFormValues,
} from "../QuestionControlSection";
import { getDefaultDecisionQuestion } from "../helpers";
import { useDecisionItemMutations } from "../hooks/useDecisionItemMutations";
import { DecisionOptionModal } from "../modals/DecisionOptionModal";
import {
  DecisionManagementBox_ChildItemFragment,
  DecisionManagementBox_ItemFragment,
  useDecisionManagementBox_ModifyWizardSettingsMutation,
} from "./DecisionManagementBox.generated";

interface Props<ChildItem extends DecisionManagementBox_ChildItemFragment> {
  projectId: string | null;
  doc: {
    id: string;
    docType: DocType;
    templateIsDraft: boolean;
    organisationId: string;
  };
  item: DecisionManagementBox_ItemFragment;
  onItemDeleted: () => void;
  isEditable?: boolean;
}

interface FormValues extends QuestionControlSectionFormValues {
  decisionBehaviorOfSubitems: DecisionBehaviorOfSubitems;
}

export const DecisionManagementBox = <
  ChildItem extends DecisionManagementBox_ChildItemFragment,
>({
  projectId,
  doc,
  item,
  onItemDeleted,
  isEditable = true,
}: Props<ChildItem>) => {
  const { currentUser: viewer } = useUserData();
  const isOwnDocument = viewer?.organisation.id === doc.organisationId;
  if (!isOwnDocument) throw new Error("No permissions!");

  const { t } = useTranslate(["Decisions", "OrganisationSettings"]);

  const { expandedItemIds, CreateChildComponent } = useOutletContext<{
    expandedItemIds: string[] | undefined;
    CreateChildComponent: React.ComponentType<
      InputComponentProps<
        DecisionManagementBox_ItemFragment,
        DecisionManagementBox_ChildItemFragment
      >
    >;
  }>();

  const client = useApolloClient();

  const [optionsType, setOptionsType] =
    React.useState<DecisionOptionsType>("all");

  const [selectedItemId, setSelectedItemId] = React.useState<string | null>(
    null
  );

  const {
    modifyItemDecision,
    handleDecisionPreselectionChange,
    handleEliminateOption,
    handleResetEliminateOption,
    handleFinalizeSubitemDecision,
    canFinalizeSubitemDecision,
    isItemGoingToBeReplaced,
    modifyItemDecisionLoading,
    finalizeSubitemDecisionLoading,
    loading,
  } = useDecisionItemMutations<DecisionManagementBox_ItemFragment>(
    projectId,
    doc.id,
    isEditable,
    expandedItemIds
  );

  const onFinalizeClick = async () => {
    if (!item || !canFinalizeSubitemDecision(item)) return;

    const itemIsBeingReplaced = isItemGoingToBeReplaced(item);

    await handleFinalizeSubitemDecision(item.id);

    if (itemIsBeingReplaced) onItemDeleted();
  };

  const [modifyWizardSettings] =
    useDecisionManagementBox_ModifyWizardSettingsMutation({
      client,
    });

  const decisionBehaviourLabels = React.useMemo(
    (): Record<DecisionBehaviorOfSubitems, string> => ({
      NONE: t("NONE", {
        ns: "Decisions",
      }),
      SELECT_ONE: t("SELECT_ONE", {
        ns: "Decisions",
      }),
      SELECT_MANY: t("SELECT_MANY", {
        ns: "Decisions",
      }),
    }),
    [t]
  );

  const decisionTypeOptions = React.useMemo(
    (): {
      value: DecisionBehaviorOfSubitems;
      label: string;
    }[] => [
      ...(item?.decisionBehaviorOfSubitems === "NONE"
        ? [
            {
              value: "NONE",
              label: decisionBehaviourLabels.NONE,
            } as const,
          ]
        : []),
      {
        value: "SELECT_MANY",
        label: decisionBehaviourLabels.SELECT_MANY,
      } as const,
      {
        value: "SELECT_ONE",
        label: decisionBehaviourLabels.SELECT_ONE,
      } as const,
    ],
    [decisionBehaviourLabels, item?.decisionBehaviorOfSubitems]
  );

  if (!item) return <div>item not found</div>;

  const initialValues: FormValues = {
    decisionBehaviorOfSubitems: item.decisionBehaviorOfSubitems,
    askWhen: item.wizardSettings.askWhenDecision,
    askWhom: item.wizardSettings.askWhomDecision,
    prompt: item.wizardSettings.promptDecision,
  };

  const handleSubmit = async (values: FormValues) => {
    if (
      values.decisionBehaviorOfSubitems !==
      initialValues.decisionBehaviorOfSubitems
    ) {
      await modifyItemDecision({
        variables: {
          input: {
            projectId,
            docId: doc.id,
            itemId: item.id,
            values: {
              decisionSubitemsPreselection: [],
              decisionBehaviorOfSubitems: values.decisionBehaviorOfSubitems,
            },
          },
        },
      });
    }

    if (
      values.askWhen !== initialValues.askWhen ||
      values.askWhom !== initialValues.askWhom ||
      values.prompt !== initialValues.prompt
    ) {
      await modifyWizardSettings({
        variables: {
          input: {
            projectId,
            docId: doc.id,
            itemId: item.id,
            values: {
              askWhenDecision: values.askWhen,
              askWhomDecision: values.askWhom,
              promptDecision: values.prompt,
            },
          },
        },
      });
    }
  };

  const allAvailableItemChildren = item.children.filter(i => !i.deletedAt);
  const allItemChildren = allAvailableItemChildren.filter(
    i => !i.decisionOptionElimination
  );
  const eliminatedItemChildren = allAvailableItemChildren.filter(
    i => !!i.decisionOptionElimination
  );
  const selectedItemChildren = allAvailableItemChildren.filter(i =>
    item.decisionSubitemsPreselection.includes(i.id)
  );

  const itemChildren: DecisionManagementBox_ChildItemFragment[] = {
    all: allItemChildren,
    selected: selectedItemChildren,
    discarded: eliminatedItemChildren,
  }[optionsType];

  const noItemsMessage = {
    all: t("You have no options yet", {
      ns: "Decisions",
    }),
    selected: t("You have no selected options yet", {
      ns: "Decisions",
    }),
    discarded: t("You have no discarded options yet", {
      ns: "Decisions",
    }),
  }[optionsType];

  if (!isOwnDocument) return null;

  return (
    <CardContainer
      isExpandable
      title={t("Decision Request", {
        ns: "Decisions",
      })}
    >
      <Stack direction="column" p={1} spacing={1}>
        {isEditable ? (
          <Formik<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <Form>
              <Stack direction="column" spacing={1}>
                <SelectField
                  name="decisionBehaviorOfSubitems"
                  required
                  options={decisionTypeOptions}
                  label={t("Decision type", {
                    ns: "Decisions",
                  })}
                  disabled={modifyItemDecisionLoading}
                />
                <QuestionControlSection
                  isInitiallyExpanded
                  defaultPrompt={getDefaultDecisionQuestion(t, item)}
                />
                <AutoSave enableReinitialize initialValues={initialValues} />
              </Stack>
            </Form>
          </Formik>
        ) : (
          <>
            <LabeledValue
              label={t("Decision type", {
                ns: "Decisions",
              })}
            >
              {decisionBehaviourLabels[item.decisionBehaviorOfSubitems]}
            </LabeledValue>
            <LabeledValue
              label={t("Question", {
                ns: "Decisions",
              })}
            >
              {item.wizardSettings.promptDecision ??
                getDefaultDecisionQuestion(t, item)}
            </LabeledValue>
          </>
        )}

        <Divider />

        <DecisionOptionsTypeTabs
          value={optionsType}
          onChange={setOptionsType}
        />

        {itemChildren.length > 0 ? (
          itemChildren.map(itemChild => (
            <DecisionOptionListItem
              key={itemChild.id}
              // layout={"vertical"}
              // parentItem={item}
              item={itemChild}
              parentItem={item}
              onClick={() => setSelectedItemId(itemChild.id)}
              onMoreClick={() => setSelectedItemId(itemChild.id)}
              loading={loading}
              disabled={!isEditable}
              handleDecisionPreselectionChange={
                handleDecisionPreselectionChange
              }
              eliminateDecisionOption={handleEliminateOption}
              resetEliminateDecisionOption={handleResetEliminateOption}
            />
          ))
        ) : (
          <Box py={2} px={1}>
            <Typography variant="body2" color="gray" align="center">
              {noItemsMessage}
            </Typography>
          </Box>
        )}

        {isEditable && (
          <Box margin="-4px -4px -6px">
            <CreateChildComponent
              id={`msys-decision-input-${item.type}-${item.id}`}
              item={item}
              itemChildren={item.children}
              isRootItem={item.isRootItem}
              autoFocus={false}
              resetFocus={
                //  auto focus does not play nicely with e2e automated tests
                //  the e2e tests will set this sessionStorage as a temporary solution
                isInTestMode()
                  ? undefined
                  : createRecursiveTryFocusElementFunction(
                      `input#msys-decision-input-${item.type}-${item.id}:enabled`
                    )
              }
            />
          </Box>
        )}

        {isEditable &&
          ((item.decisionBehaviorOfSubitems === "SELECT_ONE" &&
            item.decisionSubitemsPreselection.length > 0) ||
            item.decisionBehaviorOfSubitems === "SELECT_MANY") && (
            <LoadingButton
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<CheckIcon />}
              disabled={
                !isEditable || loading || !canFinalizeSubitemDecision(item)
              }
              loading={finalizeSubitemDecisionLoading}
              onClick={onFinalizeClick}
            >
              {t("Finalize decision", {
                ns: "Decisions",
              })}
            </LoadingButton>
          )}
      </Stack>
      {selectedItemId && (
        <DecisionOptionModal
          decisionItem={item}
          optionItems={itemChildren}
          optionItemId={selectedItemId}
          loading={loading}
          handleDecisionPreselectionChange={handleDecisionPreselectionChange}
          eliminateDecisionOption={handleEliminateOption}
          resetEliminateDecisionOption={handleResetEliminateOption}
          handleClose={() => setSelectedItemId(null)}
          onSelectItem={setSelectedItemId}
        />
      )}
    </CardContainer>
  );
};
