/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { WithDocumentItemTaskInfoFragmentDoc } from '../doc-items/Fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssigneeOptions_ProjectFragment = { __typename: 'Project', id: string, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string }, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> }> };

export type ApproveTaskItemMutationVariables = Types.Exact<{
  input: Types.ApproveTaskItemInput;
}>;


export type ApproveTaskItemMutation = { approveTaskItem: { __typename: 'ApproveTaskItemResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null } } };

export type DisapproveTaskItemMutationVariables = Types.Exact<{
  input: Types.DisapproveTaskItemInput;
}>;


export type DisapproveTaskItemMutation = { disapproveTaskItem: { __typename: 'DisapproveTaskItemResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null } } };

export type PhotoApproveTaskItemMutationVariables = Types.Exact<{
  input: Types.PhotoApproveTaskItemInput;
}>;


export type PhotoApproveTaskItemMutation = { photoApproveTaskItem: { __typename: 'PhotoApproveTaskItemResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null } } };

export type PhotoDisapproveTaskItemMutationVariables = Types.Exact<{
  input: Types.PhotoDisapproveTaskItemInput;
}>;


export type PhotoDisapproveTaskItemMutation = { photoDisapproveTaskItem: { __typename: 'PhotoDisapproveTaskItemResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null } } };

export type QaApproveTaskItemMutationVariables = Types.Exact<{
  input: Types.QaApproveTaskItemInput;
}>;


export type QaApproveTaskItemMutation = { qaApproveTaskItem: { __typename: 'QaApproveTaskItemResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null } } };

export type QaDisapproveTaskItemMutationVariables = Types.Exact<{
  input: Types.QaDisapproveTaskItemInput;
}>;


export type QaDisapproveTaskItemMutation = { qaDisapproveTaskItem: { __typename: 'QaDisapproveTaskItemResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null } } };

export type SetTaskItemDoneMutationVariables = Types.Exact<{
  input: Types.SetTaskItemDoneInput;
}>;


export type SetTaskItemDoneMutation = { setTaskItemDone: { __typename: 'SetTaskItemDoneResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null }, otherChangedItems: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null }> } };

export type SetTaskItemNotDoneMutationVariables = Types.Exact<{
  input: Types.SetTaskItemNotDoneInput;
}>;


export type SetTaskItemNotDoneMutation = { setTaskItemNotDone: { __typename: 'SetTaskItemNotDoneResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null }, otherChangedItems: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, dueDate?: any | null, priority: number, doneDate?: any | null, isDone: boolean, isApproved?: boolean | null, actualQuantity: number, actualTime: number, isQaApproved?: boolean | null, approvalRequired: boolean, timeTrackingRequired: boolean, qaApprovalRequired: boolean, photoApprovalRequired: boolean, canBeWorkedOn: boolean, assignee?: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, group: string, mimeType: string, url: string } | null }> } };

export const AssigneeOptions_ProjectFragmentDoc = gql`
    fragment AssigneeOptions_Project on Project {
  id
  internalStakeholders {
    id
    user {
      id
      firstname
      familyname
    }
    roles {
      id
      internalName
    }
  }
}
    `;
export const ApproveTaskItemDocument = gql`
    mutation ApproveTaskItem($input: ApproveTaskItemInput!) {
  approveTaskItem(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type ApproveTaskItemMutationFn = Apollo.MutationFunction<ApproveTaskItemMutation, ApproveTaskItemMutationVariables>;

/**
 * __useApproveTaskItemMutation__
 *
 * To run a mutation, you first call `useApproveTaskItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTaskItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTaskItemMutation, { data, loading, error }] = useApproveTaskItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveTaskItemMutation(baseOptions?: Apollo.MutationHookOptions<ApproveTaskItemMutation, ApproveTaskItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveTaskItemMutation, ApproveTaskItemMutationVariables>(ApproveTaskItemDocument, options);
      }
export type ApproveTaskItemMutationHookResult = ReturnType<typeof useApproveTaskItemMutation>;
export type ApproveTaskItemMutationResult = Apollo.MutationResult<ApproveTaskItemMutation>;
export type ApproveTaskItemMutationOptions = Apollo.BaseMutationOptions<ApproveTaskItemMutation, ApproveTaskItemMutationVariables>;
export const DisapproveTaskItemDocument = gql`
    mutation DisapproveTaskItem($input: DisapproveTaskItemInput!) {
  disapproveTaskItem(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type DisapproveTaskItemMutationFn = Apollo.MutationFunction<DisapproveTaskItemMutation, DisapproveTaskItemMutationVariables>;

/**
 * __useDisapproveTaskItemMutation__
 *
 * To run a mutation, you first call `useDisapproveTaskItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisapproveTaskItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disapproveTaskItemMutation, { data, loading, error }] = useDisapproveTaskItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisapproveTaskItemMutation(baseOptions?: Apollo.MutationHookOptions<DisapproveTaskItemMutation, DisapproveTaskItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisapproveTaskItemMutation, DisapproveTaskItemMutationVariables>(DisapproveTaskItemDocument, options);
      }
export type DisapproveTaskItemMutationHookResult = ReturnType<typeof useDisapproveTaskItemMutation>;
export type DisapproveTaskItemMutationResult = Apollo.MutationResult<DisapproveTaskItemMutation>;
export type DisapproveTaskItemMutationOptions = Apollo.BaseMutationOptions<DisapproveTaskItemMutation, DisapproveTaskItemMutationVariables>;
export const PhotoApproveTaskItemDocument = gql`
    mutation PhotoApproveTaskItem($input: PhotoApproveTaskItemInput!) {
  photoApproveTaskItem(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type PhotoApproveTaskItemMutationFn = Apollo.MutationFunction<PhotoApproveTaskItemMutation, PhotoApproveTaskItemMutationVariables>;

/**
 * __usePhotoApproveTaskItemMutation__
 *
 * To run a mutation, you first call `usePhotoApproveTaskItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhotoApproveTaskItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [photoApproveTaskItemMutation, { data, loading, error }] = usePhotoApproveTaskItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePhotoApproveTaskItemMutation(baseOptions?: Apollo.MutationHookOptions<PhotoApproveTaskItemMutation, PhotoApproveTaskItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PhotoApproveTaskItemMutation, PhotoApproveTaskItemMutationVariables>(PhotoApproveTaskItemDocument, options);
      }
export type PhotoApproveTaskItemMutationHookResult = ReturnType<typeof usePhotoApproveTaskItemMutation>;
export type PhotoApproveTaskItemMutationResult = Apollo.MutationResult<PhotoApproveTaskItemMutation>;
export type PhotoApproveTaskItemMutationOptions = Apollo.BaseMutationOptions<PhotoApproveTaskItemMutation, PhotoApproveTaskItemMutationVariables>;
export const PhotoDisapproveTaskItemDocument = gql`
    mutation PhotoDisapproveTaskItem($input: PhotoDisapproveTaskItemInput!) {
  photoDisapproveTaskItem(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type PhotoDisapproveTaskItemMutationFn = Apollo.MutationFunction<PhotoDisapproveTaskItemMutation, PhotoDisapproveTaskItemMutationVariables>;

/**
 * __usePhotoDisapproveTaskItemMutation__
 *
 * To run a mutation, you first call `usePhotoDisapproveTaskItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhotoDisapproveTaskItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [photoDisapproveTaskItemMutation, { data, loading, error }] = usePhotoDisapproveTaskItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePhotoDisapproveTaskItemMutation(baseOptions?: Apollo.MutationHookOptions<PhotoDisapproveTaskItemMutation, PhotoDisapproveTaskItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PhotoDisapproveTaskItemMutation, PhotoDisapproveTaskItemMutationVariables>(PhotoDisapproveTaskItemDocument, options);
      }
export type PhotoDisapproveTaskItemMutationHookResult = ReturnType<typeof usePhotoDisapproveTaskItemMutation>;
export type PhotoDisapproveTaskItemMutationResult = Apollo.MutationResult<PhotoDisapproveTaskItemMutation>;
export type PhotoDisapproveTaskItemMutationOptions = Apollo.BaseMutationOptions<PhotoDisapproveTaskItemMutation, PhotoDisapproveTaskItemMutationVariables>;
export const QaApproveTaskItemDocument = gql`
    mutation QaApproveTaskItem($input: QaApproveTaskItemInput!) {
  qaApproveTaskItem(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type QaApproveTaskItemMutationFn = Apollo.MutationFunction<QaApproveTaskItemMutation, QaApproveTaskItemMutationVariables>;

/**
 * __useQaApproveTaskItemMutation__
 *
 * To run a mutation, you first call `useQaApproveTaskItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQaApproveTaskItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qaApproveTaskItemMutation, { data, loading, error }] = useQaApproveTaskItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQaApproveTaskItemMutation(baseOptions?: Apollo.MutationHookOptions<QaApproveTaskItemMutation, QaApproveTaskItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QaApproveTaskItemMutation, QaApproveTaskItemMutationVariables>(QaApproveTaskItemDocument, options);
      }
export type QaApproveTaskItemMutationHookResult = ReturnType<typeof useQaApproveTaskItemMutation>;
export type QaApproveTaskItemMutationResult = Apollo.MutationResult<QaApproveTaskItemMutation>;
export type QaApproveTaskItemMutationOptions = Apollo.BaseMutationOptions<QaApproveTaskItemMutation, QaApproveTaskItemMutationVariables>;
export const QaDisapproveTaskItemDocument = gql`
    mutation QaDisapproveTaskItem($input: QaDisapproveTaskItemInput!) {
  qaDisapproveTaskItem(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type QaDisapproveTaskItemMutationFn = Apollo.MutationFunction<QaDisapproveTaskItemMutation, QaDisapproveTaskItemMutationVariables>;

/**
 * __useQaDisapproveTaskItemMutation__
 *
 * To run a mutation, you first call `useQaDisapproveTaskItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQaDisapproveTaskItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qaDisapproveTaskItemMutation, { data, loading, error }] = useQaDisapproveTaskItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQaDisapproveTaskItemMutation(baseOptions?: Apollo.MutationHookOptions<QaDisapproveTaskItemMutation, QaDisapproveTaskItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QaDisapproveTaskItemMutation, QaDisapproveTaskItemMutationVariables>(QaDisapproveTaskItemDocument, options);
      }
export type QaDisapproveTaskItemMutationHookResult = ReturnType<typeof useQaDisapproveTaskItemMutation>;
export type QaDisapproveTaskItemMutationResult = Apollo.MutationResult<QaDisapproveTaskItemMutation>;
export type QaDisapproveTaskItemMutationOptions = Apollo.BaseMutationOptions<QaDisapproveTaskItemMutation, QaDisapproveTaskItemMutationVariables>;
export const SetTaskItemDoneDocument = gql`
    mutation SetTaskItemDone($input: SetTaskItemDoneInput!) {
  setTaskItemDone(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
    otherChangedItems {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type SetTaskItemDoneMutationFn = Apollo.MutationFunction<SetTaskItemDoneMutation, SetTaskItemDoneMutationVariables>;

/**
 * __useSetTaskItemDoneMutation__
 *
 * To run a mutation, you first call `useSetTaskItemDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskItemDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskItemDoneMutation, { data, loading, error }] = useSetTaskItemDoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTaskItemDoneMutation(baseOptions?: Apollo.MutationHookOptions<SetTaskItemDoneMutation, SetTaskItemDoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTaskItemDoneMutation, SetTaskItemDoneMutationVariables>(SetTaskItemDoneDocument, options);
      }
export type SetTaskItemDoneMutationHookResult = ReturnType<typeof useSetTaskItemDoneMutation>;
export type SetTaskItemDoneMutationResult = Apollo.MutationResult<SetTaskItemDoneMutation>;
export type SetTaskItemDoneMutationOptions = Apollo.BaseMutationOptions<SetTaskItemDoneMutation, SetTaskItemDoneMutationVariables>;
export const SetTaskItemNotDoneDocument = gql`
    mutation SetTaskItemNotDone($input: SetTaskItemNotDoneInput!) {
  setTaskItemNotDone(input: $input) {
    item {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
    otherChangedItems {
      id
      originVersionNumber
      ...WithDocumentItemTaskInfo
    }
  }
}
    ${WithDocumentItemTaskInfoFragmentDoc}`;
export type SetTaskItemNotDoneMutationFn = Apollo.MutationFunction<SetTaskItemNotDoneMutation, SetTaskItemNotDoneMutationVariables>;

/**
 * __useSetTaskItemNotDoneMutation__
 *
 * To run a mutation, you first call `useSetTaskItemNotDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskItemNotDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskItemNotDoneMutation, { data, loading, error }] = useSetTaskItemNotDoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTaskItemNotDoneMutation(baseOptions?: Apollo.MutationHookOptions<SetTaskItemNotDoneMutation, SetTaskItemNotDoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTaskItemNotDoneMutation, SetTaskItemNotDoneMutationVariables>(SetTaskItemNotDoneDocument, options);
      }
export type SetTaskItemNotDoneMutationHookResult = ReturnType<typeof useSetTaskItemNotDoneMutation>;
export type SetTaskItemNotDoneMutationResult = Apollo.MutationResult<SetTaskItemNotDoneMutation>;
export type SetTaskItemNotDoneMutationOptions = Apollo.BaseMutationOptions<SetTaskItemNotDoneMutation, SetTaskItemNotDoneMutationVariables>;