import { MenuItemWithIcon } from "@msys/ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/More";
import {
  AppBar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  IconButton,
  Menu,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import { partition } from "lodash";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ViewerBrandingSlot } from "../../../clients/graphqlTypes";
import { color, cssValue, size } from "../../../common/MuiThemeProvider"; // FIXME
import { ReactComponent as MSLogoLong } from "../../assets/icons/logo-long.svg";
import { useRestrictionFilter } from "../../auth/useRestrictionFilter";
import { useUserData } from "../../auth/useUserData"; // FIXME
import { useBranding } from "../../features/branding/context"; // FIXME
import { useNativeFeature } from "../../global/NativeFeatureProvider"; // FIXME
import { useSidebarContext } from "../../global/SidebarProvider"; // FIXME
import { matchPathPredicate } from "../../utils";
import {
  BreadcrumbItem,
  PageBreadcrumbLink,
  PageBreadcrumbs,
} from "./PageBreadcrumbs";
import { PageLogo } from "./PageLogo";
import { PagePullToRefresh } from "./PagePullToRefresh";
import { PageSubmenu, PageTopbarItem } from "./PageSubmenu";

export type { BreadcrumbItem } from "./PageBreadcrumbs";
export type { PageTopbarItem } from "./PageSubmenu";

const HOME_URL = "/"; // TODO: should be passed as prop

interface PageProps<
  SubHeaderProps extends {
    isHeaderVisible: boolean;
    setHeaderVisible: (isVisible: boolean) => void;
  },
> {
  aside?: React.ReactElement;
  action?: React.ReactNode;
  floatAction?: React.ReactElement;

  title?: string | React.ReactElement; // FIXME make mandatory
  subtitle?: string | React.ReactElement;
  submenuItems?: PageTopbarItem[];
  breadcrumbs?: BreadcrumbItem[];
  isTopBarVisible?: boolean;
  isBrandingVisible?: boolean;
  topbarItems?: React.ReactNode;
  rightTopbarButton?: React.ReactNode;
  backLink?: BreadcrumbItem;
  hideNavigation?: boolean;

  header?: React.ReactElement;
  subHeader?: React.ReactElement<SubHeaderProps> | null;
}

export function Page<
  SubHeaderProps extends {
    isHeaderVisible: boolean;
    setHeaderVisible: (isVisible: boolean) => void;
  },
>({
  action,
  aside,
  floatAction,

  title = "", // FIXME make mandatory
  subtitle,
  submenuItems = [],
  breadcrumbs = [],
  isTopBarVisible,
  isBrandingVisible,
  topbarItems,
  rightTopbarButton,
  backLink,
  hideNavigation,

  header: customHeader,
  subHeader,
  children,
}: React.PropsWithChildren<PageProps<SubHeaderProps>>) {
  const theme = useTheme();
  const isMaxSm = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm),
    { noSsr: true }
  );
  const [isHeaderVisible, setHeaderVisible] = React.useState<boolean>(true);

  const [header, footer] = usePageHeaderAndFooter({
    rightTopbarButton,
    backLink,
    submenuItems,
    breadcrumbs,
    title,
    subtitle,
    action,
    floatAction,
    isTopBarVisible,
    isBrandingVisible,
    topbarItems,
    hideNavigation,
  });

  return (
    <Stack flex={1} maxWidth={"100%"} minWidth={0} minHeight="100%">
      <Helmet
        defaultTitle="MeisterSystems.de"
        titleTemplate="MeisterSystems.de - %s"
      >
        {title && (
          <title>{`${subtitle ? `${subtitle} - ` : ""}${title}`}</title>
        )}
      </Helmet>
      {isMaxSm ? header : isHeaderVisible && (customHeader ?? header)}
      {subHeader &&
        React.cloneElement(subHeader, {
          isHeaderVisible,
          setHeaderVisible,
        } as Partial<SubHeaderProps>)}
      <Box
        position="relative"
        display={"flex"}
        flex={1}
        minHeight={isMaxSm ? "unset" : 0}
      >
        <PagePullToRefresh>
          <InnerPage>{children}</InnerPage>
        </PagePullToRefresh>
        {aside}
      </Box>
      {footer}
    </Stack>
  );
}

const InnerPage = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexGrow: 1,
  flexShrink: 1,
  width: "100%",
  [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
    flexDirection: "row",
    alignItems: "stretch",
    minHeight: 0,
  },
  [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
    flexDirection: "column",
    minHeight: "unset",
    "& > .msys-container + .msys-container": {
      paddingTop: "0 !important",
    },
  },
}));

// Top & Bottom bars
const FOOTER_Z_INDEX = 21; // needs to more than on PageMiddleColumn (20)

function usePageHeaderAndFooter({
  rightTopbarButton,
  backLink,
  submenuItems,
  breadcrumbs,
  title,
  subtitle = "",
  action,
  floatAction,
  isTopBarVisible,
  isBrandingVisible,
  topbarItems,
  hideNavigation,
}: {
  rightTopbarButton?: React.ReactNode;
  submenuItems: PageTopbarItem[];
  breadcrumbs: BreadcrumbItem[];
  backLink?: BreadcrumbItem;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  action?: React.ReactNode;
  floatAction?: React.ReactElement;
  isTopBarVisible?: boolean;
  isBrandingVisible?: boolean;
  topbarItems?: React.ReactNode;
  hideNavigation?: boolean;
}) {
  const theme = useTheme();
  const isMaxSm = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm),
    { noSsr: true }
  );
  const location = useLocation(); // FIXME
  const { keyboardHeight } = useNativeFeature(); // FIXME
  const { setIsSidebarOpened, isSidebarPinned } = useSidebarContext(); // FIXME
  const viewer = useUserData().currentUser!; // FIXME
  const { topbarTheme, topbarLeftLogo, topbarRightLogo } = useBranding(); // FIXME

  const selectedItem = submenuItems.find(item =>
    item.activePaths
      ? item.activePaths.some(path => matchPathPredicate(`${path}/*`, location))
      : matchPathPredicate(`${item.path}/*`, location)
  );

  const header = isMaxSm ? (
    <PageTopBarMobile
      title={title}
      subTitle={subtitle}
      showTitle={!isBrandingVisible}
      setIsSidebarOpened={setIsSidebarOpened}
      hideNavigation={hideNavigation}
      topbarItems={topbarItems}
      topbarTheme={topbarTheme}
      brandingLogo={topbarLeftLogo ?? topbarRightLogo}
    />
  ) : (
    <>
      {(!viewer || isTopBarVisible) && (
        <PageTopBarDesktop
          isSidebarPinned={isSidebarPinned}
          topbarItems={topbarItems}
          topbarTheme={topbarTheme}
          brandingLogoLeft={topbarLeftLogo}
          brandingLogoRight={topbarRightLogo}
        />
      )}
      <PageTopNavigationBarDesktop
        rightButton={rightTopbarButton}
        backLink={backLink}
        items={submenuItems}
        breadcrumbs={breadcrumbs}
        selectedItem={selectedItem}
      />
    </>
  );

  const footer = isMaxSm ? (
    // when no keyboard - render bottom menu + float action
    // when keyboard appears - still need to render float action! (there could be modals inside)
    <Box
      position="sticky"
      bottom={0}
      zIndex={FOOTER_Z_INDEX}
      left={0}
      right={0}
      sx={
        !keyboardHeight
          ? theme => ({
              paddingBottom: "env(safe-area-inset-bottom)",
              boxShadow: cssValue.boxShadow,
              backgroundColor: theme.palette.common.white,
            })
          : undefined
      }
    >
      {floatAction ? (
        <Box height={0} position="relative">
          <Box
            display="flex"
            justifyContent="flex-end"
            mb={1}
            mr={1}
            position="absolute"
            right={0}
            left={0}
            bottom={0}
          >
            {floatAction}
          </Box>
        </Box>
      ) : null}
      {!keyboardHeight && action ? (
        <AppBar
          component="footer"
          position="static"
          color="inherit"
          sx={theme => ({
            borderTop: "none",
            borderBottom:
              submenuItems.length > 1
                ? `1px solid ${theme.palette.divider}`
                : "none",
          })}
        >
          <Toolbar
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              "&:empty": { display: "none" },
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"center"}
              spacing={1}
              flex={1}
              flexWrap={"wrap"}
              rowGap={1}
            >
              {action}
            </Stack>
          </Toolbar>
        </AppBar>
      ) : null}
      {!keyboardHeight && submenuItems.length > 1 ? (
        <PageBottomBarMobile items={submenuItems} selectedItem={selectedItem} />
      ) : null}
    </Box>
  ) : action || floatAction ? (
    <Box
      position="sticky"
      bottom={0}
      zIndex={FOOTER_Z_INDEX}
      left={0}
      right={0}
    >
      {floatAction ? (
        <Box height={0} position="relative">
          <Box
            display="flex"
            justifyContent="flex-end"
            mb={2}
            mr={2}
            position="absolute"
            right={0}
            left={0}
            bottom={0}
          >
            {floatAction}
          </Box>
        </Box>
      ) : null}
      {action ? (
        <AppBar
          component="footer"
          position="static"
          color="inherit"
          sx={theme => ({
            borderBottom: "none",
            borderTop: `1px solid ${theme.palette.divider}`,
            top: "auto",
            bottom: 0,
          })}
        >
          <Toolbar
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              "&:empty": { display: "none" },
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
              flex={1}
              flexWrap={"wrap"}
              rowGap={1}
            >
              {action}
            </Stack>
          </Toolbar>
        </AppBar>
      ) : null}
    </Box>
  ) : null;

  return [header, footer];
}

const PageTopBarDesktop = ({
  isSidebarPinned,
  topbarItems,
  topbarTheme,
  brandingLogoLeft,
  brandingLogoRight,
}: {
  isSidebarPinned: boolean;
  topbarItems?: React.ReactNode;
  topbarTheme: Theme;
  brandingLogoLeft: ViewerBrandingSlot | null;
  brandingLogoRight: ViewerBrandingSlot | null;
}) => {
  return (
    <ThemeProvider theme={topbarTheme}>
      <AppBar position="sticky" enableColorOnDark>
        <Toolbar>
          {!isSidebarPinned &&
            (brandingLogoLeft ? (
              <NavLink to={brandingLogoLeft.url ?? HOME_URL}>
                <PageLogo
                  height={size.topMenuSize}
                  width={size.topLogoMaxWidth}
                  logo={brandingLogoLeft}
                  noLink
                />
              </NavLink>
            ) : (
              <NavLink to={HOME_URL}>
                <MSLogoLong
                  style={{
                    height: size.topMenuLogoHeight,
                    color:
                      topbarTheme.palette.mode === "light"
                        ? color.white
                        : color.primary,
                  }}
                />
              </NavLink>
            ))}
          <Box flexGrow={1} flexShrink={1} />
          <Stack direction={"row"} spacing={1} alignItems="center">
            {brandingLogoRight && (
              <PageLogo
                height={size.topMenuSize}
                width={size.topLogoMaxWidth}
                logo={brandingLogoRight}
                objectPosition="right center"
              />
            )}
            {topbarItems}
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

const PageTopNavigationBarDesktop = ({
  items = [],
  breadcrumbs = [],
  selectedItem,
  rightButton,
  backLink,
}: {
  breadcrumbs?: BreadcrumbItem[];
  items?: PageTopbarItem[];
  selectedItem: PageTopbarItem | undefined;
  rightButton?: React.ReactNode;
  backLink?: BreadcrumbItem;
}) => {
  const leftFilteredItems = items.filter(i => i.float !== "right");
  const rightFilteredItems = items.filter(i => i.float === "right");

  return items.length > 0 || breadcrumbs.length > 0 || backLink ? (
    <AppBar
      position="static"
      color="inherit"
      sx={{
        position: "relative",
        zIndex: 2,
        ...(items.length === 0 ? { borderBottom: "none" } : undefined),
      }}
    >
      <Toolbar variant="dense" sx={{ minHeight: "auto" }}>
        <Box width="100%">
          {(backLink || breadcrumbs.length > 0) && (
            <Stack direction="row" spacing={2} alignItems="center">
              {backLink && <PageBreadcrumbLink link={backLink} />}
              {backLink && breadcrumbs.length > 0 && (
                <Divider orientation="vertical" variant="middle" flexItem />
              )}
              {breadcrumbs.length > 0 && (
                <PageBreadcrumbs breadcrumbs={breadcrumbs} />
              )}
            </Stack>
          )}
          {items.length > 0 && (
            <Box display="flex" justifyContent="space-between">
              <PageSubmenu
                key={leftFilteredItems.map(i => i.label).join("-")}
                items={leftFilteredItems}
                activeItem={selectedItem?.float !== "right" && selectedItem}
              />
              {rightFilteredItems.length > 0 || rightButton ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  {rightButton && (
                    <Box height={0} display="flex" alignItems="center">
                      {rightButton}
                    </Box>
                  )}
                  <PageSubmenu
                    key={rightFilteredItems.map(i => i.label).join("-")}
                    items={rightFilteredItems}
                    activeItem={selectedItem?.float === "right" && selectedItem}
                  />
                </Stack>
              ) : null}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  ) : null;
};

const PageTopBarMobile = ({
  title,
  subTitle,
  showTitle,
  setIsSidebarOpened,
  hideNavigation,
  topbarItems,
  topbarTheme,
  brandingLogo,
}: {
  title: string | React.ReactElement;
  subTitle: string | React.ReactElement;
  showTitle: boolean;
  setIsSidebarOpened: (value: boolean) => void;
  hideNavigation?: boolean;
  topbarItems?: React.ReactNode;
  topbarTheme: Theme;
  brandingLogo?: ViewerBrandingSlot | null;
}) => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={topbarTheme}>
      <AppBar
        position="sticky"
        enableColorOnDark
        sx={{ paddingTop: "env(safe-area-inset-top)" }}
      >
        <Toolbar>
          <Stack direction={"row"} alignItems="center" spacing={2} minWidth={0}>
            {!hideNavigation && (
              <IconButton
                color="inherit"
                onClick={() => navigate(-1)}
                size="large"
                sx={{ marginLeft: -1.5, marginRight: -1.5 }}
              >
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
            )}
            {showTitle && title ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="space-around"
                minWidth={0}
                overflow="hidden"
              >
                {subTitle && (
                  <Typography
                    noWrap
                    variant="caption"
                    component="div"
                    style={{ width: "100%" }}
                  >
                    {subTitle}
                  </Typography>
                )}
                <Typography
                  noWrap
                  variant="h2"
                  component="div"
                  style={{ width: "100%" }}
                >
                  {title}
                </Typography>
              </Box>
            ) : brandingLogo ? (
              <NavLink to={brandingLogo.url ?? HOME_URL}>
                <PageLogo
                  height={size.topMenuSize}
                  width={size.topLogoMaxWidth}
                  logo={brandingLogo}
                  noLink
                />
              </NavLink>
            ) : (
              <NavLink to={HOME_URL}>
                <MSLogoLong
                  style={{
                    height: size.topMenuLogoHeight,
                    color:
                      topbarTheme.palette.mode === "light"
                        ? color.white
                        : color.primary,
                  }}
                />
              </NavLink>
            )}
          </Stack>

          <Stack
            direction={"row"}
            alignItems="center"
            spacing={1 / 2}
            flex={0}
            marginRight={-1.5}
          >
            {topbarItems}
            {!hideNavigation && (
              <IconButton
                color="inherit"
                onClick={() => setIsSidebarOpened(true)}
                size="large"
              >
                <MenuIcon fontSize="medium" />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

const PageBottomBarMobile = ({
  items = [],
  selectedItem,
}: {
  items: PageTopbarItem[];
  selectedItem: PageTopbarItem | undefined;
}) => {
  const { t } = useTranslate("Global"); // FIXME
  const restrictionFilter = useRestrictionFilter();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // just filter for mobile
  const filteredItems = items.filter(restrictionFilter);

  const [moreItems, barItems] = partition(
    filteredItems,
    item => filteredItems.length > 4 && item.isInMoreMenu
  );

  const highlightMore =
    selectedItem && moreItems.length > 1 && moreItems.includes(selectedItem);

  return (
    <BottomNavigation
      showLabels
      value={highlightMore ? "/more" : selectedItem?.path}
      sx={{
        position: "static",
        height: "auto !important",
        minHeight: `${size.bottomBarSize}px`,
        ".MuiBottomNavigationAction-label": {
          textAlign: "center",
        },
      }}
    >
      {barItems.map(item => (
        <BottomNavigationAction
          component={Link}
          key={item.label}
          label={item.label}
          icon={
            <Badge badgeContent={item.bubbleAmount} color="secondary">
              {item.icon || <ChangeHistoryIcon />}
            </Badge>
          }
          value={item.path}
          to={item.path}
          disabled={item.disabled ?? false}
        />
      ))}
      {moreItems.length === 1 &&
        moreItems.map(item => (
          <BottomNavigationAction
            component={Link}
            key={item.label}
            label={item.label}
            icon={
              <Badge badgeContent={item.bubbleAmount} color="secondary">
                {item.icon || <ChangeHistoryIcon />}
              </Badge>
            }
            value={item.path}
            to={item.path}
            disabled={item.disabled ?? false}
          />
        ))}
      {moreItems.length > 1 && (
        <BottomNavigationAction
          aria-controls="more-menu"
          aria-haspopup="true"
          component="button"
          label={t("More")}
          icon={<MoreIcon />}
          onClick={handleMenuOpen}
          value="/more"
        />
      )}
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {moreItems.map(item => (
          <MenuItemWithIcon
            component={Link}
            key={item.label}
            onClick={handleClose}
            to={item.path}
            icon={item.icon}
            disabled={item.disabled ?? false}
          >
            {item.label}
          </MenuItemWithIcon>
        ))}
      </Menu>
    </BottomNavigation>
  );
};
