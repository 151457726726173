import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageTopbarItem } from "../../commons/layout/Page";
import { ContactsIcon } from "../../features/crm-persons/ContactsIcon";
import { PageNotFound } from "../../commons/PageNotFound";
import { CrmAll } from "./CrmAll";
import { CrmCompanyProfile } from "./CrmCompanyProfile";
import { CrmPersonProfile } from "./CrmPersonProfile";
import { CrmPersons } from "./CrmPersons";

type PageSubmenuItem = PageTopbarItem;

export const CrmRoutes = () => {
  const { t } = useTranslate(["CrmPageTopbar"]);

  const submenuItems: PageSubmenuItem[] = React.useMemo(() => {
    return [
      {
        name: "crm-contacts",
        label: t("People", {
          ns: "CrmPageTopbar",
        }),
        type: "link",
        icon: <ContactsIcon />,
        path: "/crm/users",
        activePaths: [
          "/crm/users",
          "/crm/organisations/:organisationId/users",
        ] as string[],
      },
      {
        name: "crm-all",
        label: t("Businesses & Groups", {
          ns: "CrmPageTopbar",
        }),
        type: "link",
        icon: <ContactsIcon />,
        path: "/crm/all",
        activePaths: [
          "/crm/all",
          "/crm/organisations/:organisationId/profile",
        ] as string[],
      },
    ];
  }, [t]);

  return (
    <Routes>
      <Route index element={<Navigate to="all" replace />} />
      <Route path="all" element={<CrmAll submenuItems={submenuItems} />} />
      <Route path={"organisations/:organisationId"}>
        <Route index element={<Navigate to="profile" replace />} />
        <Route
          path={"profile"}
          element={<CrmCompanyProfile submenuItems={submenuItems} />}
        />
        <Route path={"users/:userId"}>
          <Route index element={<Navigate to="profile" replace />} />
          <Route
            path="profile"
            element={<CrmPersonProfile submenuItems={submenuItems} />}
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="users">
        <Route index element={<CrmPersons submenuItems={submenuItems} />} />
        <Route path={":userId"}>
          <Route index element={<Navigate to="profile" replace />} />
          <Route
            path={"profile"}
            element={<CrmPersonProfile submenuItems={submenuItems} />}
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
