import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  CollapseButtonBase,
  CollapseTitle,
  Ellipsis,
  LoadingSpinner,
  Switch,
  TextWithOverflow,
  useScreenWidth,
} from "@msys/ui";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { Box, ButtonBase, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { noop, omit } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { useLatest } from "react-use";
import {
  addEntryToEdges,
  removeEntryFromEdges,
} from "../../../clients/apollo-client-pagination";
import { FetchMoreListener } from "../../commons/FetchMoreListener";
import {
  EXPANDED_COLUMN_MIN_WIDTH,
  KanbanColumn,
} from "../../commons/kanban/KanbanColumn";
import { KanbanContainer } from "../../commons/kanban/KanbanContainer";
import { useKanbanColumnCollapseStore } from "../../commons/kanban/useKanbanColumnCollapseStore";
import { ProjectPhaseAndStateChangeMenuButton } from "../projects/ProjectPhaseAndStateChangeMenuButton";
import { ProjectPhasesConfigurator } from "../projects/ProjectPhasesConfigurator";
import { ProjectListItem } from "../projects/components/ProjectListItem";
import { ProjectListItemFragment } from "../projects/components/ProjectListItem.generated";
import { useVisibilityStore } from "../users/useVisibilityStore";
import {
  OpportunitiesKanbanListDocument,
  OpportunitiesKanbanListQueryVariables,
  OpportunitiesKanbanWonListDocument,
  OpportunitiesKanbanWonListQueryVariables,
  OpportunitiesPhaseColumnAvailablePhases_OrganisationProjectPhaseFragment,
  OpportunitiesPhaseColumnPhase_OrganisationProjectPhaseFragment,
  OpportunityCardItemMemoized_OrganisationProjectPhaseFragment,
  OpportunityCardItemMemoized_ProjectFragment,
  useOpportunitiesKanbanListQuery,
  useOpportunitiesKanbanQuery,
  useOpportunitiesKanbanWonListQuery,
} from "./OpportunitiesKanban.generated";
import { OpportunitiesListQueryVariables } from "./OpportunitiesList.generated";
import {
  OpportunityListItem,
  OpportunityListItemDisplayConfig,
} from "./components/OpportunityListItem";

type ColumnsVisibilityKeys = "projectsWon";

export function OpportunitiesKanban({
  displayConfig,
  variables,
}: {
  displayConfig: OpportunityListItemDisplayConfig;
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
}) {
  const { t } = useTranslate(["Opportunities"]);

  const { isMinTablet } = useScreenWidth();

  const [opportunitiesCount, setOpportunitiesCount] = React.useState<
    Record<string, number>
  >({});

  const onOpportunitiesCountChange = (phaseId: string, count: number) => {
    setOpportunitiesCount(state => ({ ...state, [phaseId]: count }));
  };

  const [isConfiguring, setIsConfiguring] = React.useState<boolean>(false);

  const columnsVisibilityStore = useVisibilityStore<ColumnsVisibilityKeys>(
    "OpportunitiesKanban-Columns"
  );

  const client = useApolloClient();
  const query = useOpportunitiesKanbanQuery({
    client,
    variables: { filterIncludeState: ["opportunity"] },
  });
  const phases = query.data?.organisationSettings.projectPhases ?? [];

  const { collapsedState, setPhaseCollapsed, collapseStateLoading } =
    useKanbanColumnCollapseStore({
      key: "Opportunity-KanBan-Phases-Collapsed",
      columns: phases,
    });

  if (query.loading || collapseStateLoading) return <LoadingSpinner />;

  return (
    <Stack
      spacing={1}
      flexBasis={0}
      flexGrow={1}
      justifyContent={"stretch"}
      marginX={-2}
      marginBottom={-2}
    >
      <ProjectPhasesConfigurator
        state={"opportunity"}
        phases={phases}
        opportunitiesCount={opportunitiesCount}
        isConfiguring={isConfiguring}
        setIsConfiguring={setIsConfiguring}
        extraItems={[
          <Switch
            label={t("Show won projects", { ns: "Opportunities" })}
            checked={columnsVisibilityStore.value.projectsWon}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              columnsVisibilityStore.saveValue({
                ...columnsVisibilityStore.value,
                projectsWon: checked,
              });
            }}
          />,
        ]}
      />
      <KanbanContainer>
        {phases.map(phase => (
          <OpportunitiesPhaseColumn
            key={phase.id}
            phase={phase}
            isCollapsed={collapsedState?.[phase.id] ?? false}
            setCollapsed={collapsed => {
              setPhaseCollapsed(phase.id, collapsed);
            }}
            availablePhases={phases}
            displayConfig={displayConfig}
            onCountChange={(count: number) =>
              onOpportunitiesCountChange(phase.id, count)
            }
            variables={variables}
          />
        ))}
        {isConfiguring || columnsVisibilityStore.value.projectsWon ? (
          !columnsVisibilityStore.value.projectsWon ? (
            <Box
              flexBasis={isMinTablet ? EXPANDED_COLUMN_MIN_WIDTH : "100%"}
              flexShrink={0}
              flexGrow={1}
              width={0}
            ></Box>
          ) : (
            <ProjectsWonColumn variables={variables} />
          )
        ) : null}
      </KanbanContainer>
    </Stack>
  );
}

const LIMIT = 20;

function getOpportunitiesKanbanListQueryVariables(
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">,
  phaseId: string
): OpportunitiesKanbanListQueryVariables {
  return {
    ...omit(variables, ["limit", "offset"]),
    filterByPhaseId: phaseId,
    sorting: [
      { column: "createdAt", direction: "desc" },
      { column: "id", direction: "desc" },
    ],
    after: null,
    first: LIMIT,
  };
}

function getOpportunitiesKanbanWonListQueryVariables(
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">
): OpportunitiesKanbanWonListQueryVariables {
  return {
    ...omit(variables, ["limit", "offset"]),
    filterIncludeState: ["contracted"],
    sorting: [
      { column: "createdAt", direction: "desc" },
      { column: "id", direction: "desc" },
    ],
    after: null,
    first: LIMIT,
  };
}

function OpportunitiesPhaseColumn({
  phase,
  isCollapsed,
  setCollapsed,
  availablePhases,
  displayConfig,
  onCountChange,
  variables,
}: {
  phase: OpportunitiesPhaseColumnPhase_OrganisationProjectPhaseFragment;
  isCollapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  availablePhases: OpportunitiesPhaseColumnAvailablePhases_OrganisationProjectPhaseFragment[];
  displayConfig: OpportunityListItemDisplayConfig;
  onCountChange(count: number): void;
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
}) {
  const client = useApolloClient();
  const queryVariables = React.useMemo(
    () => getOpportunitiesKanbanListQueryVariables(variables, phase.id),
    [variables, phase.id]
  );

  const query = useOpportunitiesKanbanListQuery({
    client,
    variables: queryVariables,
  });

  const opportunitiesEdges = getDataOrNull(query.data?.projects)?.edges;
  const totalCount = getDataOrNull(query.data?.projects)?.totalCount;

  const onCountChangeLatest = useLatest(onCountChange);
  React.useEffect(() => {
    if (totalCount !== undefined) onCountChangeLatest.current?.(totalCount);
  }, [totalCount, onCountChangeLatest]);

  const canFetchMore = Boolean(
    opportunitiesEdges && totalCount && opportunitiesEdges.length < totalCount
  );

  const fetchMore = async () => {
    const after =
      getDataOrNull(query.data?.projects)?.pageInfo?.endCursor ?? null;
    if (!after) return;
    await query.fetchMore({
      variables: {
        after,
        first: LIMIT,
      },
    });
  };

  return (
    <KanbanColumn
      title={<CollapseTitle title={phase.name} count={totalCount} />}
      collapsedTitle={`${phase.name}${
        totalCount !== undefined ? ` (${totalCount})` : ""
      }`}
      isCollapsed={isCollapsed}
      setCollapsed={setCollapsed}
    >
      {query.loading && !opportunitiesEdges ? (
        <LoadingSpinner />
      ) : (
        <Stack spacing={1} padding={1}>
          {opportunitiesEdges?.map(opportunityEdge => (
            <OpportunityCardItemMemoized
              key={opportunityEdge.node.id}
              opportunityEdge={opportunityEdge}
              phaseId={phase.id}
              availablePhases={availablePhases}
              variables={variables}
              displayConfig={displayConfig}
            />
          ))}
          <FetchMoreListener enabled={canFetchMore} fetchMore={fetchMore} />
        </Stack>
      )}
    </KanbanColumn>
  );
}

const OpportunityCardItemMemoized = React.memo(function OpportunityCardItem({
  phaseId,
  availablePhases,
  opportunityEdge,
  variables,
  displayConfig,
}: {
  phaseId: string;
  availablePhases: OpportunityCardItemMemoized_OrganisationProjectPhaseFragment[];
  opportunityEdge: {
    __typename: "ProjectEdge";
    cursor?: string | null | undefined;
    node: OpportunityCardItemMemoized_ProjectFragment;
  };
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
  displayConfig: OpportunityListItemDisplayConfig;
}) {
  const { t } = useTranslate(["Projects"]);

  return (
    <CardItem
      key={opportunityEdge.node.id}
      // @ts-ignore
      component={Link}
      to={`/projects/${opportunityEdge.node.id}`}
    >
      <OpportunityListItem
        project={opportunityEdge.node}
        showStatus={false}
        showAssignee
        Action={
          <ProjectPhaseAndStateChangeMenuButton
            projectId={opportunityEdge.node.id}
            projectPhaseId={phaseId}
            projectState={opportunityEdge.node.state}
            availablePhases={availablePhases.filter(p => p.id !== phaseId)}
            Icon={<MultipleStopIcon fontSize="small" />}
            buttonProps={{
              size: "small",
              sx: { padding: "1px" },
            }}
            // onStateChangeRefetchQueries={[
            //   namedOperations.Query.OpportunitiesList,
            // ]}
            phaseGroupLabel={t("Move opportunity to another phase", {
              ns: "Projects",
            })}
            changeProjectPhaseUpdate={(
              oldPhaseId,
              newPhaseId,
              cache,
              result
            ) => {
              const fromVariables = getOpportunitiesKanbanListQueryVariables(
                variables,
                oldPhaseId
              );
              const toVariables = getOpportunitiesKanbanListQueryVariables(
                variables,
                newPhaseId
              );
              cache.updateQuery(
                {
                  query: OpportunitiesKanbanListDocument,
                  variables: fromVariables,
                },
                data => {
                  if (data && data.projects)
                    return {
                      ...data,
                      projects: removeEntryFromEdges(
                        data.projects,
                        opportunityEdge
                      ),
                    };
                }
              );
              cache.updateQuery(
                {
                  query: OpportunitiesKanbanListDocument,
                  variables: toVariables,
                },
                data => {
                  if (data && data.projects)
                    return {
                      ...data,
                      projects: addEntryToEdges(data.projects, opportunityEdge),
                    };
                }
              );
            }}
            stateGroupLabel={t("Mark opportunity as won or lost", {
              ns: "Projects",
            })}
            changeProjectStateUpdate={(
              oldState,
              newStateEvent,
              cache,
              result
            ) => {
              if (oldState === "opportunity") {
                const fromVariables = getOpportunitiesKanbanListQueryVariables(
                  variables,
                  phaseId
                );
                cache.updateQuery(
                  {
                    query: OpportunitiesKanbanListDocument,
                    variables: fromVariables,
                  },
                  data => {
                    if (data && data.projects)
                      return {
                        ...data,
                        projects: removeEntryFromEdges(
                          data.projects,
                          opportunityEdge
                        ),
                      };
                  }
                );
              }
              if (newStateEvent === "WIN") {
                const toVariables =
                  getOpportunitiesKanbanWonListQueryVariables(variables);
                cache.updateQuery(
                  {
                    query: OpportunitiesKanbanWonListDocument,
                    variables: toVariables,
                  },
                  data => {
                    if (data && data.projects)
                      return {
                        ...data,
                        projects: addEntryToEdges(
                          data.projects,
                          opportunityEdge
                        ),
                      };
                  }
                );
              }
            }}
          />
        }
        displayConfig={displayConfig}
      />
    </CardItem>
  );
});

function ProjectsWonColumn({
  variables,
}: {
  variables: Omit<OpportunitiesListQueryVariables, "offset" | "limit">;
}) {
  const { t } = useTranslate(["Opportunities"]);

  const queryVariables = React.useMemo(
    () => getOpportunitiesKanbanWonListQueryVariables(variables),
    [variables]
  );

  const client = useApolloClient();
  const query = useOpportunitiesKanbanWonListQuery({
    client,
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
  });

  const projects = getDataOrNull(query.data?.projects)?.edges.map(e => e.node);
  const totalCount = getDataOrNull(query.data?.projects)?.totalCount;

  const canFetchMore = Boolean(
    projects && totalCount && projects.length < totalCount
  );

  const fetchMore = async () => {
    const after =
      getDataOrNull(query.data?.projects)?.pageInfo?.endCursor ?? null;
    if (!after) return;
    await query.fetchMore({
      variables: {
        after,
        first: LIMIT,
      },
    });
  };

  return (
    <KanbanColumn
      title={
        <CollapseButtonBase
          title={
            <CollapseTitle
              title={t("Won", { ns: "Opportunities" })}
              count={totalCount}
            />
          }
          endIcon={<KeyboardArrowRightIcon />}
          // @ts-ignore
          component={props => (
            <ButtonBase {...props} component={Link} to={`/projects/current`} />
          )}
        />
      }
      collapsedTitle=""
      isCollapsed={false}
      setCollapsed={noop}
      showCollapsedButton={false}
    >
      {query.loading && !projects ? (
        <LoadingSpinner />
      ) : (
        <Stack spacing={1} padding={1}>
          {projects?.map(project => (
            <ProjectCardItemMemoized key={project.id} project={project} />
          ))}
          <FetchMoreListener enabled={canFetchMore} fetchMore={fetchMore} />
        </Stack>
      )}
    </KanbanColumn>
  );
}

const ProjectCardItemMemoized = React.memo(
  ({ project }: { project: ProjectListItemFragment }) => (
    <CardItem
      key={project.id}
      // @ts-ignore
      component={Link}
      to={`/projects/${project.id}`}
    >
      <ProjectListItem project={project} showStatus={false} showAssignee />
    </CardItem>
  )
);
