import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { Stack } from "../../../commons/layout/Stack";
import {
  CrmCompanyRegistrationDetailsBox_CrmCompanyFragment,
  CrmCompanyRegistrationDetailsData_CrmCompanyFragment,
  CrmCompanyRegistrationDetailsForm_CrmCompanyFragment,
  useModifyCrmCompanyRegistrationDetailsMutation,
} from "./CrmCompanyRegistrationDetailsBox.generated";

interface Props {
  crmCompany: CrmCompanyRegistrationDetailsBox_CrmCompanyFragment;
}

export function CrmCompanyRegistrationDetailsBox({ crmCompany }: Props) {
  const { t } = useTranslate("OrganisationProfile");
  return (
    <CardContainer isExpandable title={t("Company registration details")}>
      <Box p={1}>
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_CRM"
          otherwise={
            <CrmCompanyRegistrationDetailsData crmCompany={crmCompany} />
          }
        >
          <CrmCompanyRegistrationDetailsForm crmCompany={crmCompany} />
        </RestrictedByOrganisationPermissionWithDebug>
      </Box>
    </CardContainer>
  );
}

function CrmCompanyRegistrationDetailsData({
  crmCompany,
}: {
  crmCompany: CrmCompanyRegistrationDetailsData_CrmCompanyFragment;
}) {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);

  return (
    <Stack flexDirection="column">
      <LabeledValue
        label={t("Full legal name", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.fullLegalName ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Tax number", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.taxNumber ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("VAT number", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.vatNumber ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Registration number", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.registrationNumber ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Registration office", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.registrationOffice ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Managing director", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.managingDirector ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
    </Stack>
  );
}

interface FormValues {
  fullLegalName: string;
  taxNumber: string;
  vatNumber: string;
  registrationNumber: string;
  registrationOffice: string;
  managingDirector: string;
}

function CrmCompanyRegistrationDetailsForm({
  crmCompany,
}: {
  crmCompany: CrmCompanyRegistrationDetailsForm_CrmCompanyFragment;
}) {
  const { t } = useTranslate("OrganisationProfile");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyOrganisation] = useModifyCrmCompanyRegistrationDetailsMutation({
    client,
  });

  const initialValues = {
    fullLegalName: crmCompany.fullLegalName,
    taxNumber: crmCompany.taxNumber,
    vatNumber: crmCompany.vatNumber,
    registrationNumber: crmCompany.registrationNumber,
    registrationOffice: crmCompany.registrationOffice,
    managingDirector: crmCompany.managingDirector,
  };

  const validationSchema = Yup.object().shape({
    fullLegalName: Yup.string().label(t("Full legal name")),
    taxNumber: Yup.string().label(t("Tax number")),
    vatNumber: Yup.string().label(t("VAT number")),
    registrationNumber: Yup.string().label(t("Registration number")),
    registrationOffice: Yup.string().label(t("Registration office")),
    managingDirector: Yup.string().label(t("Managing director")),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={async (values, formikActions) => {
        try {
          await modifyOrganisation({
            variables: {
              input: [
                {
                  id: crmCompany.id,
                  fullLegalName: values.fullLegalName,
                  taxNumber: values.taxNumber,
                  vatNumber: values.vatNumber,
                  registrationNumber: values.registrationNumber,
                  registrationOffice: values.registrationOffice,
                  managingDirector: values.managingDirector,
                },
              ],
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, { variant: "error" });
        }
      }}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            <Field
              component={TextField}
              disabled={false}
              name="fullLegalName"
              label={t("Full legal name")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="taxNumber"
              label={t("Tax number")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="vatNumber"
              label={t("VAT number")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="registrationNumber"
              label={t("Registration number")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="registrationOffice"
              label={t("Registration office")}
            />
            <Field
              component={TextField}
              disabled={false}
              name="managingDirector"
              label={t("Managing director")}
            />
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
