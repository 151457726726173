import { CardContainer } from "@msys/ui";
import { Box, Grid } from "@mui/material";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";

export const ExampleLayoutRightSidebarCard = () => {
  return (
    <>
      <PageContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <CardContainer title="Gallery">
              <Box height={300} />
            </CardContainer>
            <CardContainer title="About">
              <Box height={150} />
            </CardContainer>
          </Grid>
          <Grid item xs={12} lg={3}>
            <CardContainer title="Cart">
              <Box height={250} />
            </CardContainer>
          </Grid>
        </Grid>
      </PageContainer>
      <Page>
        <PageContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
              <CardContainer title="Description">
                <Box height={500} />
              </CardContainer>
            </Grid>
          </Grid>
        </PageContainer>
      </Page>
    </>
  );
};
