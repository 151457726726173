import { faHeading } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  $isLinkNode,
  AutoLinkNode,
  LinkNode,
  TOGGLE_LINK_COMMAND,
} from "@lexical/link";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListItemNode,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import {
  AutoLinkPlugin,
  LinkMatcher,
} from "@lexical/react/LexicalAutoLinkPlugin";
import {
  type InitialConfigType,
  InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import {
  HorizontalRuleNode,
  INSERT_HORIZONTAL_RULE_COMMAND,
} from "@lexical/react/LexicalHorizontalRuleNode";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import {
  $createHeadingNode,
  $isHeadingNode,
  HeadingNode,
} from "@lexical/rich-text";
import {
  $getSelectionStyleValueForProperty,
  $isAtNodeEnd,
  $patchStyleText,
  $wrapNodes,
} from "@lexical/selection";
import {
  $getNearestNodeOfType,
  $wrapNodeInElement,
  mergeRegister,
} from "@lexical/utils";
import { assert } from "@msys/common";
import {
  ColorButtonPicker,
  ellipsisStyle,
  MenuButton,
  MenuItemWithIcon,
  Modal,
  ModalOpenButton,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ImageIcon from "@mui/icons-material/Image";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import SubjectIcon from "@mui/icons-material/Subject";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Icon,
  IconButton,
  Link,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  $createParagraphNode,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  type EditorState,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  type LexicalEditor,
  type RangeSelection,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from "lexical";
import React from "react";
import { ExtendedTextNode } from "./ExtendedTextNode";
import { $createImageNode, ImageNode } from "./ImageNode";
import ImagesPlugin, {
  INSERT_IMAGE_COMMAND,
  InsertImagePayload,
} from "./ImagesPlugin";
import { InsertImageModal } from "./InsertImageModal";
import { editorTheme } from "./theme";

interface Props {
  placeholder?: string;
  initialEditorState: InitialEditorStateType | undefined;
  handleChange: (editorState: EditorState, editor: LexicalEditor) => void;
}

export function LexicalRichTextEditor({
  placeholder,
  initialEditorState,
  handleChange,
}: Props) {
  const initialConfig: InitialConfigType = {
    theme: editorTheme,
    namespace: "MyEditor",
    onError: (error: Error) => {
      // Catch any errors that occur during Lexical updates and log them
      // or throw them as needed. If you don't throw them, Lexical will
      // try to recover gracefully without losing user data.
      console.log(error);
    },
    nodes: [
      ExtendedTextNode,
      {
        replace: TextNode,
        with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key),
      },
      HeadingNode,
      ListNode,
      ListItemNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
      HorizontalRuleNode,
      // TableCellNode,
      // TableNode,
      // TableRowNode,
    ],
    editorState: initialEditorState,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Box
        className="editor-shell"
        sx={{
          backgroundColor: theme => theme.palette.blue.paper,
          borderRadius: 1,
        }}
      >
        <ToolbarPlugin />
        <Box
          paddingX={1.5}
          paddingY={0.5}
          sx={{ "& > *": { outline: "none" } }}
          position={"relative"}
        >
          <RichTextPlugin
            contentEditable={<ContentEditable />}
            placeholder={
              placeholder ? (
                <Typography
                  color={theme => theme.palette.text.secondary}
                  position="absolute"
                  bottom={"4px"}
                >
                  {placeholder}
                </Typography>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin matchers={MATCHERS} />
          <ImagesPlugin captionsEnabled={false} />
          {/*<TablePlugin hasCellMerge={true} hasCellBackgroundColor={true} />*/}
          <HorizontalRulePlugin />
          <OnChangePlugin onChange={handleChange} />
        </Box>
      </Box>
    </LexicalComposer>
  );
}

///////////////////////////////////
//////// TOOLBAR PLUGIN ///////////
///////////////////////////////////

export type BlockType = typeof SUPPORTED_BLOCK_TYPES[number];
const SUPPORTED_BLOCK_TYPES = ["paragraph", "h1", "h2", "ul", "ol"] as const;

const supportedBlockTypes = new Set(SUPPORTED_BLOCK_TYPES);
function isValidBlockType(type: string): type is BlockType {
  return supportedBlockTypes.has(type as BlockType);
}
function useBlockTypes() {
  const { t } = useTranslate(["Global"]);

  const blockTypeLabels: Record<BlockType, string> = {
    // @ts-ignore FIXME
    paragraph: t("Normal", { ns: "Global" }),
    // @ts-ignore FIXME
    h1: t("Heading 1", { ns: "Global" }),
    // @ts-ignore FIXME
    h2: t("Heading 2", { ns: "Global" }),
    // @ts-ignore FIXME
    ul: t("Bulleted List", { ns: "Global" }),
    // @ts-ignore FIXME
    ol: t("Numbered List", { ns: "Global" }),
  };

  const blockTypeIcons: Record<BlockType, React.ReactElement> = {
    paragraph: <SubjectIcon />,
    h1: (
      <Icon>
        <FontAwesomeIcon icon={faHeading} />
      </Icon>
    ),
    h2: (
      <Icon>
        <FontAwesomeIcon icon={faHeading} />
      </Icon>
    ),
    ul: <FormatListBulletedIcon />,
    ol: <FormatListNumberedIcon />,
  };

  return { blockTypeLabels, blockTypeIcons };
}

const LowPriority = 1;

function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();

  const { blockTypeLabels, blockTypeIcons } = useBlockTypes();

  const [fontColor, setFontColor] = React.useState<string>("#001e64");
  const [blockType, setBlockType] = React.useState<BlockType>("paragraph");
  const [isLink, setIsLink] = React.useState(false);
  const [isBold, setIsBold] = React.useState(false);
  const [isItalic, setIsItalic] = React.useState(false);
  const [isUnderline, setIsUnderline] = React.useState(false);

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          assert(isValidBlockType(type), "Invalid block type");
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setFontColor(
        $getSelectionStyleValueForProperty(selection, "color", "#001e64")
      );
      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    } else {
      setIsLink(false);
    }
  }, [editor]);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const blockTypeClickHandlers: Record<BlockType, () => void> = {
    paragraph: () => {
      if (blockType !== "paragraph") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createParagraphNode());
          }
        });
      }
    },
    h1: () => {
      if (blockType !== "h1") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode("h1"));
          }
        });
      }
    },
    h2: () => {
      if (blockType !== "h2") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode("h2"));
          }
        });
      }
    },
    ul: () => {
      if (blockType !== "ul") {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
    },
    ol: () => {
      if (blockType !== "ol") {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
    },
  };

  const applyStyleText = React.useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      editor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: "historic" } : {}
      );
    },
    [editor]
  );

  const onFontColorSelect = React.useCallback(
    (value: string, skipHistoryStack: boolean = false) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText]
  );

  const insertLink = React.useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <Stack
      direction={"row"}
      alignItems="center"
      paddingX={1}
      paddingY={0.5}
      spacing={0.5}
    >
      {supportedBlockTypes.has(blockType) && (
        <MenuButton
          Button={
            <Button
              size="small"
              startIcon={blockTypeIcons[blockType]}
              endIcon={<ExpandMoreIcon />}
              aria-label="Formatting Options"
              sx={{
                whiteSpace: "nowrap",
                color: theme => theme.palette.text.secondary,
              }}
            >
              {blockTypeLabels[blockType]}
            </Button>
          }
        >
          {[...supportedBlockTypes].map(type => (
            <MenuItemWithIcon
              key={type}
              icon={blockTypeIcons[type]}
              selected={blockType === type}
              onClick={blockTypeClickHandlers[type]}
              // sx={{
              //   color:
              //     blockType === type
              //       ? theme => theme.palette.text.secondary
              //       : "rgba(0, 0, 0, 0.54)",
              // }}
            >
              {blockTypeLabels[type]}
            </MenuItemWithIcon>
          ))}
        </MenuButton>
      )}
      <IconButton
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        sx={
          isBold ? { color: theme => theme.palette.text.secondary } : undefined
        }
        aria-label="Format Bold"
      >
        <FormatBoldIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        sx={
          isItalic
            ? { color: theme => theme.palette.text.secondary }
            : undefined
        }
        aria-label="Format Italics"
      >
        <FormatItalicIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
        sx={
          isUnderline
            ? { color: theme => theme.palette.text.secondary }
            : undefined
        }
        aria-label="Format Underline"
      >
        <FormatUnderlinedIcon />
      </IconButton>
      <ColorButtonPicker
        value={fontColor}
        onChange={onFontColorSelect}
        buttonProps={{
          size: "small",
          color: "secondary",
          variant: "text",
          sx: { minWidth: "auto" },
        }}
      />
      <IconButton
        size="small"
        onClick={insertLink}
        sx={
          isLink ? { color: theme => theme.palette.text.secondary } : undefined
        }
        aria-label="Insert Link"
      >
        <InsertLinkIcon />
      </IconButton>
      {isLink && <FloatingLinkEditor editor={editor} />}
      <Divider orientation="vertical" flexItem />
      <IconButton
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }}
        sx={
          false ? { color: theme => theme.palette.text.secondary } : undefined
        }
        aria-label="Left Align"
      >
        <FormatAlignLeftIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }}
        sx={
          false ? { color: theme => theme.palette.text.secondary } : undefined
        }
        aria-label="Center Align"
      >
        <FormatAlignCenterIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }}
        sx={
          false ? { color: theme => theme.palette.text.secondary } : undefined
        }
        aria-label="Right Align"
      >
        <FormatAlignRightIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        }}
        sx={
          false ? { color: theme => theme.palette.text.secondary } : undefined
        }
        aria-label="Justify Align"
      >
        <FormatAlignJustifyIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <MenuButton
        Button={
          <Button
            size="small"
            startIcon={<AddIcon />}
            endIcon={<ExpandMoreIcon />}
            aria-label="Insert"
            sx={{
              whiteSpace: "nowrap",
              color: theme => theme.palette.text.secondary,
            }}
          >
            Insert
          </Button>
        }
      >
        <ModalOpenButton
          Modal={InsertImageModal}
          modalProps={{ activeEditor: editor }}
        >
          <MenuItemWithIcon icon={<ImageIcon />}>Image</MenuItemWithIcon>
        </ModalOpenButton>
        {/*<ModalOpenButton>*/}
        {/*  Modal={InsertTableModal}*/}
        {/*  modalProps={{ activeEditor: editor }}*/}
        {/*>*/}
        {/*  <MenuItemWithIcon icon={<BackupTableIcon />}>Table</MenuItemWithIcon>*/}
        {/*</ModalOpenButton>*/}
        <MenuItemWithIcon
          icon={<HorizontalRuleIcon />}
          onClick={() => {
            editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
          }}
        >
          Horizontal rule
        </MenuItemWithIcon>
      </MenuButton>
    </Stack>
  );
}

function FloatingLinkEditor({ editor }: { editor: LexicalEditor }) {
  const editButtonRef = React.useRef<HTMLButtonElement>(null);
  const [linkUrl, setLinkUrl] = React.useState<string>("");
  const [linkElement, setLinkElement] = React.useState<HTMLElement | null>(
    null
  );

  const getLinkElementAndUrlFromNode = React.useCallback((): [
    HTMLElement | null,
    string
  ] => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      if ($isLinkNode(node)) {
        return [editor.getElementByKey(node.getKey()), node.getURL()];
      }
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        return [editor.getElementByKey(parent.getKey()), parent.getURL()];
      }
    }

    return [null, ""];
  }, [editor]);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const [linkElement, linkUrl] = getLinkElementAndUrlFromNode();
          setLinkUrl(linkUrl);
          setLinkElement(linkElement);
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const [linkElement, linkUrl] = getLinkElementAndUrlFromNode();
          setLinkUrl(linkUrl);
          setLinkElement(linkElement);

          return true;
        },
        LowPriority
      )
    );
  }, [editor, getLinkElementAndUrlFromNode]);

  React.useEffect(() => {
    editor.getEditorState().read(() => {
      const [linkElement, linkUrl] = getLinkElementAndUrlFromNode();
      setLinkUrl(linkUrl);
      setLinkElement(linkElement);
      if (linkUrl === "") {
        editButtonRef.current?.click();
      }
    });
  }, [editor, getLinkElementAndUrlFromNode]);

  return (
    <Popper
      open={true}
      anchorEl={linkElement}
      disablePortal={true}
      placement="bottom-start"
      sx={{ zIndex: 1, maxWidth: "80%" }}
    >
      <Paper sx={{ padding: 1 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems={"center"}
          maxWidth={"100%"}
        >
          <InsertLinkIcon />
          <Link
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={ellipsisStyle}
          >
            {linkUrl}
          </Link>
          <ModalOpenButton
            Modal={EditLinkModal}
            modalProps={{ editor, linkUrl }}
            ref={editButtonRef}
          >
            <IconButton size="small" color="secondary">
              <EditIcon />
            </IconButton>
          </ModalOpenButton>
        </Stack>
      </Paper>
    </Popper>
  );
}

const EditLinkModal = ({
  handleClose,
  editor,
  linkUrl,
}: {
  handleClose: () => void;
  editor: LexicalEditor;
  linkUrl: string;
}) => {
  const { t } = useTranslate(["Global"]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal
      notInStack
      dialogActions={
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              color="success"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                const inputValue = inputRef.current?.value ?? "";
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, inputValue);
                handleClose();
              }}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
            <IconButton
              size="small"
              color="warning"
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                handleClose();
              }}
            >
              <HighlightOffIcon />
            </IconButton>
            <IconButton
              size="small"
              color="error"
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                handleClose();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </DialogActions>
      }
    >
      <Stack spacing={1}>
        <TextField
          inputRef={inputRef}
          // @ts-ignore FIXME
          label={t("URL", { ns: "Global" })}
          defaultValue={linkUrl}
          onKeyDown={event => {
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
              const inputValue = inputRef.current?.value;
              if (inputValue && inputValue !== "") {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, inputValue);
              }
              handleClose();
            } else if (event.key === "Escape") {
              event.stopPropagation();
              event.preventDefault();
              handleClose();
            }
          }}
          autoFocus
        />
      </Stack>
    </Modal>
  );
};

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const EMAIL_MATCHER =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
const MATCHERS: LinkMatcher[] = [
  text => {
    const match = URL_MATCHER.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: match[0],
      }
    );
  },
  text => {
    const match = EMAIL_MATCHER.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `mailto:${match[0]}`,
      }
    );
  },
];
