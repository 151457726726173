import { useApolloClient } from "@apollo/client";
import { getDataOrNull, getPictures } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  getFormattedDate,
  getFormattedPrice,
  ListHeader,
  LoadingSpinner as LoadingIndicator,
  MediaCardItem,
  MediaListItem,
  ModalOpenButton,
  useScreenWidth,
} from "@msys/ui";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FolderIcon from "@mui/icons-material/Folder";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FilledInput,
  Grid,
  IconButton,
  IconButtonProps,
  Stack,
  SvgIconProps,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { FormikProps, useFormik, useFormikContext } from "formik";
import { isUndefined } from "lodash";
import React from "react";
import {
  ItemType,
  OrganisationQuoteTemplatesFilters,
  OrganisationQuoteTemplatesSortBy,
  OrganisationQuoteTemplatesSorting,
  TemplateSearchFiltersInput,
} from "../../../../clients/graphqlTypes";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../../commons/filters/FilterButton";
import { useCardsNumber } from "../../../commons/hooks/useCardsNumber";
import { CollectionView } from "../../../commons/hooks/useCollectionView";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants";
import { FormValues } from "../../doc-items/SearchProductsAndTemplates";
import { useOrganisationTags } from "../../organisations/useOrganisationTags";
import { PropertyValueChips } from "../../properties/PropertyValueChips";
import { PropertyValueItem } from "../../properties/PropertyValueItems";
import { useDataGridStateStore } from "../../users/useDataGridStateStore";
import { computeTemplateRelevantProperties } from "../helpers";
import { TemplateQuotePublishingStatus } from "./TemplateQuotePublishingStatus";
import {
  canShowChips,
  TemplatesQuoteFilterChips,
} from "./TemplatesQuoteFilterChips";
import {
  TemplateQuotesFilterModal,
  TemplateQuotesFilterWithTemplateTypeModal,
} from "./TemplatesQuoteFilterModal";
import { TemplatesQuoteOverviewModal } from "./TemplatesQuoteOverviewModal";
import {
  TemplatesQuoteSelectMultipleComponent_TemplateFragment,
  useTemplatesQuoteSelectMultipleComponentQuery,
} from "./TemplatesQuoteSelectMultipleModal.generated";
import { useTemplatesQuoteList } from "./useTemplatesQuoteList";
import {
  templateFiltersDefaultValue,
  TemplateFiltersFormValues,
  templateFiltersValidationSchema,
} from "../filters/TemplateFilterFields";
import { useStateWithUrlParams } from "../../../commons/hooks/useStateWithUrlParams";
import { useAutoSave } from "../../../commons/hooks/useAutoSave";
import { TemplateFilters } from "../filters/TemplateFilters";

const ALLOWED_VIEWS: CollectionView[] = ["list", "gallery", "table"];

interface Props {
  excludeTemplateIds?: string[];
  rootItemTypes?: ItemType[];
  initialSearchValue: string;
  handleSearchValueChange?(value: string): void;
  Form: React.ReactNode;
  AddToListIcon?: React.ComponentType<SvgIconProps>;
  showQuantityInput?: boolean;
}

export const TemplatesQuoteSelectMultipleComponent = ({
  excludeTemplateIds,
  rootItemTypes,
  initialSearchValue,
  handleSearchValueChange,
  Form,
  AddToListIcon = AddShoppingCartIcon,
  showQuantityInput = true,
}: Props) => {
  const formikProps = useFormikContext<FormValues>();
  const { t } = useTranslate(["TemplatesSearch", "Templates", "Global"]);
  const { isMinTablet } = useScreenWidth();
  const [selectedDoc, setSelectedDoc] =
    React.useState<TemplatesQuoteSelectMultipleComponent_TemplateFragment | null>(
      null
    );

  const {
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables,
    toRemoveParams,
    searchTerm,
    setSearchTerm,
  } = useTemplatesQuoteList({
    activeViewStorageKey: "templates-list-modal",
    activeViewInitialDesktopValue: "list",
    activeViewInitialMobileValue: "list",
    initialFilters: {},
    initialSearchTerm: initialSearchValue,
  });

  const [templateFilters, setTemplateFilters] =
    useStateWithUrlParams<TemplateFiltersFormValues>(
      "templateFilters",
      templateFiltersDefaultValue,
      toRemoveParams
    );

  // we need an inner formik state to not immediately apply filters on the list
  const templateFilterFormikProps = useFormik<TemplateFiltersFormValues>({
    initialValues: templateFilters,
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      setTemplateFilters(newValues);
    },
  });

  useAutoSave({
    enableReinitialize: true,
    initialValues: templateFilters,
    formikProps: templateFilterFormikProps,
  });

  const variables = {};

  const client = useApolloClient();
  const query = useTemplatesQuoteSelectMultipleComponentQuery({
    client,
    variables: {
      ...queryBaseVariables,
      filters: {
        sources: ["available"],
        ...filters,
        excludeTemplateIds,
        rootItemTypes,
        ...templateFilters,
      },
      sorting: (sorting ?? []).map(s => ({
        fieldSorting: {
          field: {
            createdAt: "createdAt" as const,
            discountedPrice: "priceNetTotal" as const,
            title: "title" as const,
            totalPrice: "priceTotal" as const,
          }[s.column],
          direction: s.direction,
        },
      })),
      searchTerm,
    },
    context: {
      headers: { "x-use-readmodel-cache": "yes" },
    },
  });

  const { customTags } = useOrganisationTags("TEMPLATE");

  const listHeader = (
    <ListHeader
      elementsOrder="separate-rows"
      // QuickFilter={
      //   <Tabs
      //     useSelectOnMobile
      //     options={Object.entries(quickFilterSettings).map(([key, value]) => {
      //       return { value: key as QuickFilterValue, label: value.label };
      //     })}
      //     value={quickFilter}
      //     onChange={(newQuickFilter: QuickFilterValue) => {
      //       setUrlSearchParams({ quickfilter: newQuickFilter });
      //     }}
      //   />
      // }
      FilterButton={
        <ModalOpenButton
          Modal={TemplateQuotesFilterWithTemplateTypeModal}
          modalProps={{
            resetFilters,
            sorting,
            setSorting,
            filters,
            setFilters,
            tags: customTags,
            formikProps: templateFilterFormikProps,
            templateSearchVariables: {
              searchTerm: searchTerm,
              filters: filters,
            },
          }}
        >
          <FilterButton />
        </ModalOpenButton>
      }
      SwitchViewButton={
        <SwitchCollectionViewButton
          allowedViews={ALLOWED_VIEWS}
          activeView={activeView}
          setActiveView={setActiveView}
        />
      }
      FilterChips={
        canShowChips(filters) ? (
          <TemplatesQuoteFilterChips
            filters={filters}
            setFilters={setFilters}
          />
        ) : undefined
      }
      SearchField={
        <DebouncedSearchInput
          defaultValue={searchTerm}
          onChangeSearchTerm={newValue => setSearchTerm(newValue)}
          placeholder={t("Search", { ns: "Global" })}
          onImmediateChangeSearchTerm={handleSearchValueChange}
          autoFocus
          style={{ flex: 1 }}
        />
      }
      QuickFilter={
        isMinTablet ? (
          <TemplateFilters
            formikProps={templateFilterFormikProps}
            templateSearchVariables={{
              searchTerm: searchTerm,
              filters: filters,
            }}
            viewType="buttons"
          />
        ) : undefined
      }
      mb={2}
    />
  );

  React.useEffect(() => {
    const totalCount = getDataOrNull(query.data?.searchTemplates)?.totalCount;
    if (
      !isUndefined(totalCount) &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const items =
    getDataOrNull(
      (query.data ?? query.previousData)?.searchTemplates
    )?.edges.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.searchTemplates)
      ?.totalCount ?? 0;

  const selectedDocIndex = selectedDoc
    ? formikProps.values.templates.findIndex(
        tpl => tpl.template.id === selectedDoc.id
      )
    : -1;

  return (
    <>
      {isMinTablet ? (
        <Stack
          alignItems={"stretch"}
          height="100%"
          spacing={{ xs: 1, md: 1.5 }}
          direction="row"
        >
          <Stack
            direction={"column"}
            height="100%"
            spacing={1}
            flex={1}
            minWidth={0}
          >
            {listHeader}
            {query.error && <ErrorMessage message={query.error.message} />}
            <TemplatesList
              items={items}
              filters={templateFilters}
              totalCount={totalCount}
              formikProps={formikProps}
              activeView={activeView}
              loading={query.loading}
              onSelectItem={setSelectedDoc}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              sorting={sorting}
              setSorting={setSorting}
              AddToListIcon={AddToListIcon}
              showQuantityInput={showQuantityInput}
            />
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack
            direction={"column"}
            height={"100%"}
            overflow="hidden"
            spacing={1}
            flexGrow={0}
            flexShrink={0}
            sx={{ width: theme => theme.layout.columnWidth.md }}
          >
            {Form}
          </Stack>
        </Stack>
      ) : (
        <Stack direction={"column"} spacing={1} height="100%" width="100%">
          {listHeader}
          {query.error && <ErrorMessage message={query.error.message} />}

          <TemplatesList
            items={items}
            filters={templateFilters}
            totalCount={totalCount}
            formikProps={formikProps}
            activeView={activeView}
            loading={query.loading}
            onSelectItem={setSelectedDoc}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            sorting={sorting}
            setSorting={setSorting}
            AddToListIcon={AddToListIcon}
            showQuantityInput={showQuantityInput}
          />
        </Stack>
      )}
      {selectedDoc && selectedDoc.resolvedAsReadModelVersionNumber && (
        <TemplatesQuoteOverviewModal
          docId={selectedDoc.id}
          versionNumber={selectedDoc.resolvedAsReadModelVersionNumber}
          handleClose={() => setSelectedDoc(null)}
          // headerActions={renderTemplateBookmarkButton(
          //   selectedDoc,
          //   "medium"
          // )}
          dialogActionButton={
            <Button
              onClick={e => {
                e.stopPropagation();
                formikProps.setFieldValue("templates", [
                  ...formikProps.values.templates,
                  { quantity: 1, template: selectedDoc },
                ]);
                setSelectedDoc(null);
              }}
              disabled={selectedDocIndex >= 0}
              color="primary"
              variant="contained"
              startIcon={<AddToListIcon />}
            >
              {t("Add to list", {
                ns: "TemplatesSearch",
              })}
            </Button>
          }
        />
      )}
    </>
  );
};

function TemplatesList({
  items,
  filters,
  totalCount,
  formikProps,
  activeView,
  loading,
  onSelectItem,
  paginationModel,
  setPaginationModel,
  sorting,
  setSorting,
  AddToListIcon,
  showQuantityInput,
}: {
  items: TemplatesQuoteSelectMultipleComponent_TemplateFragment[];
  filters: TemplateSearchFiltersInput;
  totalCount: number;
  formikProps: FormikProps<FormValues>;
  activeView: CollectionView;
  loading: boolean;
  onSelectItem(
    template: TemplatesQuoteSelectMultipleComponent_TemplateFragment
  ): void;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  sorting: OrganisationQuoteTemplatesSorting[];
  setSorting: (newSorting: OrganisationQuoteTemplatesSorting[]) => void;
  AddToListIcon: React.ComponentType<SvgIconProps>;
  showQuantityInput: boolean;
}) {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["TemplatesSearch", "Templates", "Global"]);
  const { spacing, sizeMeasureRef, columns } = useCardsNumber();
  const stateStore = useDataGridStateStore("TemplatesQuoteSelectMultipleModal");

  // const renderTemplateBookmarkButton = (
  //   template: QuoteTemplatesTableRowFragment,
  //   size: IconButtonProps["size"] = "small"
  // ) => {
  //   return (
  //     <BookmarkButton
  //       size={size}
  //       isBookmarked={template.isLikedByMe}
  //       handleClick={() =>
  //         handleBookmarkClick(
  //           template.id,
  //           template.isLikedByMe
  //         )
  //       }
  //     />
  //   );
  // };

  switch (activeView) {
    case "list":
      if (loading) return <LoadingIndicator />;
      return items.length > 0 ? (
        <Stack
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
        >
          <Stack
            direction="column"
            spacing={1}
            overflow="auto"
            flex={1}
            minHeight={0}
          >
            {items.map((template, index) => {
              const pictures = getPictures(template.attachments);
              const props = computeTemplateRelevantProperties({
                rootItemProps: template.rootItem.props2,
                templateTypeProps:
                  template.implementsTemplateType?.templateType?.props2 ?? [],
                searchTemplatesPropertiesFilters: filters.properties,
              });

              const Info = (
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {template.implementsTemplateType && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Box
                        sx={theme => ({
                          display: "flex",
                          flexGrow: 0,
                          flexShrink: 0,
                          alignSelf: "flex-start",
                          height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
                        })}
                      >
                        <FolderIcon
                          style={{ fontSize: "15px", flexShrink: 0 }}
                        />
                      </Box>
                      <Box minWidth={0} sx={{ overflowWrap: "break-word" }}>
                        <React.Fragment key={index}>
                          {template.implementsTemplateType.templateType.title}
                        </React.Fragment>
                      </Box>
                    </Stack>
                  )}
                  {props.length > 0 && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Box
                        sx={theme => ({
                          display: "flex",
                          flexGrow: 0,
                          flexShrink: 0,
                          alignSelf: "flex-start",
                          height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
                        })}
                      >
                        <InfoIcon style={{ fontSize: "15px", flexShrink: 0 }} />
                      </Box>
                      <Box minWidth={0} sx={{ overflowWrap: "break-word" }}>
                        {props.map((prop, index) => (
                          <React.Fragment key={index}>
                            {index > 0 ? " • " : ""}
                            {<PropertyValueItem prop={prop} key={prop.key} />}
                          </React.Fragment>
                        ))}
                      </Box>
                    </Stack>
                  )}
                </Typography>
              );

              return (
                <MediaListItem
                  key={template.id}
                  onClick={() => {
                    if (!template.resolvedAsReadModelVersionNumber) {
                      throw new Error("Version missing");
                    }
                    onSelectItem(template);
                  }}
                  title={template.title}
                  description={template.publicDescription}
                  price={getFormattedPrice(
                    template.proposedCalculation?.priceNetTotal ?? 0,
                    language
                  )}
                  imageUrl={pictures[0]?.url}
                  ActionButton={
                    <>
                      {/*{renderTemplateBookmarkButton(template)}*/}
                      <ButtonCircleWrapper>
                        <TemplateAddButton
                          template={template}
                          formikProps={formikProps}
                          AddToListIcon={AddToListIcon}
                        />
                      </ButtonCircleWrapper>
                    </>
                  }
                  Info={Info}
                />
              );
            })}
          </Stack>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      ) : (
        <Box padding={2} display="flex" justifyContent="center">
          {t("There seems to be nothing here", {
            ns: "Global",
          })}
        </Box>
      );

    case "gallery":
      if (loading) return <LoadingIndicator />;
      return (
        <Stack
          direction="column"
          spacing={1}
          flex={1}
          overflow="auto"
          minHeight={0}
          ref={sizeMeasureRef}
        >
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Box overflow="auto" flex={1} minHeight={0}>
                <Grid container spacing={spacing}>
                  {items.map((template, index) => {
                    const pictures = getPictures(template.attachments);

                    const props = computeTemplateRelevantProperties({
                      rootItemProps: template.rootItem.props2,
                      templateTypeProps:
                        template.implementsTemplateType?.templateType?.props2 ??
                        [],
                      searchTemplatesPropertiesFilters: filters.properties,
                    });

                    const Info = (
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {template.implementsTemplateType && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                          >
                            <Box
                              sx={theme => ({
                                display: "flex",
                                flexGrow: 0,
                                flexShrink: 0,
                                alignSelf: "flex-start",
                                height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
                              })}
                            >
                              <FolderIcon
                                style={{ fontSize: "15px", flexShrink: 0 }}
                              />
                            </Box>
                            <Box
                              minWidth={0}
                              sx={{ overflowWrap: "break-word" }}
                            >
                              <React.Fragment key={index}>
                                {
                                  template.implementsTemplateType.templateType
                                    .title
                                }
                              </React.Fragment>
                            </Box>
                          </Stack>
                        )}
                        {props.length > 0 && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                          >
                            <Box
                              sx={theme => ({
                                display: "flex",
                                flexGrow: 0,
                                flexShrink: 0,
                                alignSelf: "flex-start",
                                height: `calc(${theme.typography.caption.fontSize} * ${theme.typography.caption.lineHeight})`,
                              })}
                            >
                              <InfoIcon
                                style={{ fontSize: "15px", flexShrink: 0 }}
                              />
                            </Box>
                            <Box
                              minWidth={0}
                              sx={{ overflowWrap: "break-word" }}
                            >
                              {props.map((prop, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 ? " • " : ""}
                                  {
                                    <PropertyValueItem
                                      prop={prop}
                                      key={prop.key}
                                    />
                                  }
                                </React.Fragment>
                              ))}
                            </Box>
                          </Stack>
                        )}
                      </Typography>
                    );

                    return (
                      <Grid key={template.id} item {...columns}>
                        <MediaCardItem
                          key={template.id}
                          onClick={() => {
                            if (!template.resolvedAsReadModelVersionNumber) {
                              throw new Error("Version missing");
                            }
                            onSelectItem(template);
                          }}
                          title={template.title}
                          description={template.description}
                          price={getFormattedPrice(
                            template.proposedCalculation?.priceNetTotal || 0,
                            language
                          )}
                          imageUrl={pictures[0]?.url}
                          ActionButton={
                            <>
                              {/*<ButtonCircleWrapper>*/}
                              {/*  {renderTemplateBookmarkButton(template, "medium")}*/}
                              {/*</ButtonCircleWrapper>*/}
                              <ButtonCircleWrapper>
                                <TemplateAddButton
                                  template={template}
                                  formikProps={formikProps}
                                  AddToListIcon={AddToListIcon}
                                />
                              </ButtonCircleWrapper>
                            </>
                          }
                          Info={Info}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <TablePagination
                component="div"
                count={totalCount}
                page={paginationModel.page}
                onPageChange={(event, newPage) => {
                  setPaginationModel({
                    pageSize: paginationModel.pageSize,
                    page: newPage,
                  });
                }}
                rowsPerPage={paginationModel.pageSize}
                onRowsPerPageChange={event => {
                  setPaginationModel({
                    pageSize: parseInt(event.target.value, 10),
                    page: 0,
                  });
                }}
                labelRowsPerPage={t("Items per page:", {
                  ns: "Global",
                })}
              />
            </>
          )}
        </Stack>
      );

    case "table":
      return (
        <DataGrid
          stateStore={stateStore}
          density="compact"
          loading={loading}
          hideFooter={totalCount === 0}
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            if (!params.row.resolvedAsReadModelVersionNumber) {
              throw new Error("Version missing");
            }
            if (
              [
                "title",
                "category",
                "createdAt",
                "publishingStatus",
                "discountedPrice",
              ].includes(params.field)
            )
              onSelectItem(params.row);
          }}
          columns={[
            {
              field: "checkbox",
              headerName: "",
              width: 50,
              sortable: false,
              resizable: false,
              renderCell: ({ row: template }) => (
                <TemplateCheckbox
                  template={template}
                  formikProps={formikProps}
                />
              ),
            },
            {
              field: "title",
              headerName: t("Title", {
                ns: "Templates",
              }),
              flex: 2,
              minWidth: 100,
              renderCell: ({
                row: template,
              }: {
                row: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
              }) => template.title,
            },
            {
              field: "templateType",
              headerName: t("Template type", {
                ns: "Templates",
              }),
              flex: 1,
              minWidth: 50,
              renderCell: ({ row: template }) =>
                template.implementsTemplateType?.templateType?.title ?? "",
            },
            {
              field: "templateTypeProperties",
              headerName: t("Template type properties", {
                ns: "Templates",
              }),
              flex: 3,
              minWidth: 150,
              renderCell: ({ row: template }) => {
                const props = computeTemplateRelevantProperties({
                  rootItemProps: template.rootItem.props2,
                  templateTypeProps:
                    template.implementsTemplateType?.templateType?.props2 ?? [],
                  searchTemplatesPropertiesFilters: filters.properties,
                });

                return <PropertyValueChips props={props} wrap={false} />;
              },
            },
            {
              field: "publishingStatus",
              headerName: t("Publishing", {
                ns: "Templates",
              }),
              flex: 0.75,
              minWidth: 100,
              sortable: false,
              renderCell: ({
                row: template,
              }: {
                row: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
              }) => (
                <TemplateQuotePublishingStatus
                  doc={template}
                  variant="body2"
                  IconProps={{ fontSize: "small" }}
                />
              ),
            },
            {
              field: "createdAt",
              headerName: t("Created", {
                ns: "Templates",
              }),
              width: 120,
              renderCell: ({
                row: template,
              }: {
                row: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
              }) => getFormattedDate(template.createdAt, language),
            },
            {
              field: "discountedPrice",
              headerName: t("Price", {
                ns: "Templates",
              }),
              width: 120,
              headerAlign: "right",
              align: "right",
              renderCell: ({
                row: template,
              }: {
                row: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
              }) =>
                getFormattedPrice(
                  template.proposedCalculation?.priceNetTotal || 0,
                  language
                ),
            },
            {
              field: "bookmark",
              headerName: "",
              width: 120,
              sortable: false,
              renderCell: ({
                row: template,
              }: {
                row: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
              }) => (
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {/*{renderTemplateBookmarkButton(template)}*/}
                  {showQuantityInput && (
                    <TemplateQuantityInput
                      template={template}
                      formikProps={formikProps}
                    />
                  )}
                  <TemplateAddButton
                    template={template}
                    formikProps={formikProps}
                    AddToListIcon={AddToListIcon}
                  />
                </Stack>
              ),
            },
          ]}
          rows={items}
          sortModel={sorting.map(s => ({
            field: s.column,
            sort: s.direction,
          }))}
          onSortModelChange={newModel => {
            setSorting(
              newModel.map(({ field, sort }) => ({
                column: field as OrganisationQuoteTemplatesSortBy,
                direction: sort ?? "asc",
              }))
            );
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
        />
      );
  }

  return null;
}

function TemplateAddButton({
  template,
  formikProps,
  size = "small",
  AddToListIcon,
}: {
  template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
  formikProps: FormikProps<FormValues>;
  size?: IconButtonProps["size"];
  AddToListIcon: React.ComponentType<SvgIconProps>;
}) {
  const index = formikProps.values.templates.findIndex(
    tpl => tpl.template.id === template.id
  );
  return (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        formikProps.setFieldValue("templates", [
          ...formikProps.values.templates,
          { quantity: 1, template },
        ]);
      }}
      color="primary"
      size={size}
      disabled={index >= 0}
    >
      <AddToListIcon fontSize="small" />
    </IconButton>
  );
}

function TemplateQuantityInput({
  template,
  formikProps,
}: {
  template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
  formikProps: FormikProps<FormValues>;
}) {
  const { t } = useTranslate(["TemplatesSearch", "Templates", "Global"]);
  const index = formikProps.values.templates.findIndex(
    tpl => tpl.template.id === template.id
  );
  return (
    <FilledInput
      onClick={e => {
        e.stopPropagation();
      }}
      type="number"
      inputProps={{
        type: "number",
        min: 0,
        step: 0.1,
      }}
      size="extra-small"
      placeholder={t("Quantity", {
        ns: "TemplatesSearch",
      })}
      value={index >= 0 ? formikProps.values.templates[index].quantity : 0}
      onChange={e => {
        const newValue = parseFloat(e.target.value || "0");
        if (!Number.isNaN(newValue)) {
          if (newValue > 0 && index < 0) {
            // add
            formikProps.setFieldValue("templates", [
              ...formikProps.values.templates,
              { quantity: newValue, template },
            ]);
          } else if (newValue === 0 && index >= 0) {
            // remove
            formikProps.setFieldValue(
              "templates",
              formikProps.values.templates.filter((tpl, i) => i !== index)
            );
          } else if (newValue >= 0) {
            // replace
            formikProps.setFieldValue(
              "templates",
              formikProps.values.templates.map((tpl, i) =>
                i === index
                  ? {
                      ...tpl,
                      quantity: newValue,
                    }
                  : tpl
              )
            );
          }
        }
      }}
    />
  );
}

function TemplateCheckbox({
  template,
  formikProps,
}: {
  template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
  formikProps: FormikProps<FormValues>;
}) {
  const index = formikProps.values.templates.findIndex(
    tpl => tpl.template.id === template.id
  );
  const checked = index >= 0;
  return (
    <Checkbox
      checked={checked}
      onChange={() => {
        if (checked) {
          formikProps.setFieldValue(
            "templates",
            formikProps.values.templates.filter((tpl, i) => i !== index)
          );
        } else {
          formikProps.setFieldValue("templates", [
            ...formikProps.values.templates,
            { quantity: 1, template },
          ]);
        }
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
}
