import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { Button as MuiButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { QuoteCreateModal } from "../modals/QuoteCreateModal";

export const CreateQuoteButton = ({
  projectId,
  pathToProject,
  requestId,
  requirementId,
  Button,
  refetchQueries,
}: {
  projectId: string;
  pathToProject: string;
  requirementId?: string;
  requestId?: string;
  Button?: React.ReactElement;
  refetchQueries?: string[];
}) => {
  const navigate = useNavigate();
  const { t } = useTranslate("QuoteCreate");

  const button = Button ?? (
    <MuiButton color="secondary" variant="contained" startIcon={<AddIcon />}>
      {t("New Quote")}
    </MuiButton>
  );

  return (
    <ModalOpenButton
      Modal={QuoteCreateModal}
      modalProps={{
        requestId,
        projectId,
        requirementDocId: requirementId,
        handleComplete: async (quoteId, handleClose) => {
          handleClose();
          if (quoteId) {
            navigate({
              pathname: `${pathToProject}/quotes/${quoteId}/edit`,
            });
          } else {
            navigate(`${pathToProject}/quotes`);
          }
        },
        refetchQueries,
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
