import { VatRates, vatRates, VatRateType } from "./rates";

export const vatRateTypes: VatRateType[] = [
  "standard",
  "reduced1",
  "reduced2",
  "reduced3",
  "reduced",
  "zero",
  "custom",
];

export type VatParameters = {
  rate: number;
  countryCode: string;
  rateType: VatRateType;
};

export const getAvailableVatRateTypes = (
  countryCode: string = "DE"
): VatRateType[] => {
  const countryRates =
    vatRates.find(r => r.countryCode === countryCode) ??
    vatRates.find(r => r.countryCode === "DE")!;

  return vatRateTypes.filter(rate => rate in countryRates.rates);
};

export const getAvailableVatRateValues = (
  countryCode: string = "DE"
): VatRates => {
  const countryRates =
    vatRates.find(r => r.countryCode === countryCode) ??
    vatRates.find(r => r.countryCode === "DE")!;

  return countryRates.rates;
};

export const getVatParameters = (
  countryCode: string = "DE",
  rateType: VatRateType = "standard",
  rate?: number | null
): VatParameters => {
  let vatRate: number, vatCountryCode: string, vatRateType: VatRateType;

  const countryRates =
    vatRates.find(r => r.countryCode === countryCode) ??
    vatRates.find(r => r.countryCode === "DE")!;

  vatCountryCode = countryRates.countryCode;

  if (rateType === "custom") {
    if (rate !== null && rate !== undefined) {
      vatRateType = "custom";
      vatRate = rate;
    } else {
      vatRateType = "standard";
      vatRate = countryRates.rates.standard;
    }
  } else {
    if (rateType in countryRates.rates) {
      if (
        rate !== null &&
        rate !== undefined &&
        rate !== countryRates.rates[rateType]!
      ) {
        // current rate is outdated! setting to 'custom'
        vatRateType = "custom";
        vatRate = rate;
      } else {
        vatRateType = rateType;
        vatRate = countryRates.rates[rateType]!;
      }
    } else {
      vatRateType = "standard";
      vatRate = countryRates.rates.standard;
    }
  }

  return {
    rate: vatRate,
    countryCode: vatCountryCode,
    rateType: vatRateType,
  };
};
