import { gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import React from "react";
import {
  useItemOrSubItemsPropsUsedInOtherItemsLazyQuery,
  useDeleteDocumentItemMutation,
} from "./useDeleteItemMutation.generated";

export const useDeleteItemMutation = (
  expandedItemIds: string[] | undefined
) => {
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [checkItemPropsUsed, itemPropsUsedQuery] =
    useItemOrSubItemsPropsUsedInOtherItemsLazyQuery({
      client,
      fetchPolicy: "no-cache",
    });
  const checkItemBeforeDelete = React.useCallback(
    async ({
      projectId,
      docId,
      itemId,
    }: {
      projectId: string | null;
      docId: string;
      itemId: string;
    }): Promise<{ itemOrSubItemsPropsUsedInOtherItems: boolean }> => {
      try {
        const r = await checkItemPropsUsed({
          variables: {
            projectId,
            docId,
            itemId,
          },
        });
        return {
          itemOrSubItemsPropsUsedInOtherItems:
            r?.data?.itemOrSubItemsPropsUsedInOtherItems ?? false,
        };
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(error.message, { variant: "error" });
      }
      return { itemOrSubItemsPropsUsedInOtherItems: false };
    },
    [checkItemPropsUsed, enqueueSnackbar]
  );

  const [deleteQuoteItem, { loading: deleteItemLoading }] =
    useDeleteDocumentItemMutation({ client });
  const deleteItem = React.useCallback(
    async ({
      projectId,
      docId,
      itemId,
      onItemDeleted,
    }: {
      projectId: string | null;
      docId: string;
      itemId: string;
      onItemDeleted?: () => void;
    }) => {
      try {
        await deleteQuoteItem({
          variables: {
            input: {
              projectId,
              docId,
              itemId,
              expandedItemIds,
            },
          },
        });
        onItemDeleted?.();
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [deleteQuoteItem, enqueueSnackbar, expandedItemIds]
  );

  return {
    deleteItem,
    deleteItemLoading,
    checkItemBeforeDelete,
    checkItemCouldBeDeletedLoading: itemPropsUsedQuery.loading,
  };
};
