import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { reverse } from "lodash";
import { PimProductCategoryFragment } from "./Product.generated";
import {
  productCategoryDefaultIcon,
  productCategoryIcons,
} from "./ProductCategoryIcons";
import { useProductCategoryTreeSelectQuery } from "./ProductCategoryTreeSelect.generated";

export function useProductCategoryTree(
  supplierId: string | null,
  categoryKey: string | null
): Props {
  const client = useApolloClient();
  const query = useProductCategoryTreeSelectQuery({
    client,
    variables: { supplierId: supplierId!, categoryKey },
    skip: !supplierId,
  });
  return supplierId
    ? {
        childCategories:
          getDataOrNull(
            (query.data ?? query.previousData)
              ?.pimGetSupplierProductCategoryTree
          )?.tree?.children ?? [],
        parentCategories:
          getDataOrNull(
            (query.data ?? query.previousData)
              ?.pimGetSupplierProductCategoryTree
          )?.tree?.parents ?? [],
        productCategory:
          getDataOrNull(
            (query.data ?? query.previousData)
              ?.pimGetSupplierProductCategoryTree
          )?.tree?.productCategory ?? null,
        supplier:
          getDataOrNull(
            (query.data ?? query.previousData)
              ?.pimGetSupplierProductCategoryTree
          )?.supplier ?? null,
      }
    : {
        childCategories: [],
        parentCategories: [],
        productCategory: null,
        supplier: null,
      };
}

export interface Props {
  childCategories: PimProductCategoryFragment[];
  parentCategories: PimProductCategoryFragment[];
  productCategory: PimProductCategoryFragment | null;
  supplier: { id: string; title: string } | null;
}

export const ProductCategoryTreeSelect = ({
  childCategories,
  parentCategories,
  productCategory,
  supplier,
  categoryKey,
  onSelect,
  showAllProductsButton = true,
}: Props & {
  categoryKey: string | null;
  onSelect(filter: {
    supplierProductCategoryKey?: string | null;
    supplierId?: string | null;
  }): void;
  showAllProductsButton?: boolean;
}) => {
  const { t } = useTranslate(["ProductSearch"]);
  if (!supplier || (!productCategory && !childCategories.length)) return null;
  return (
    <List dense disablePadding>
      {showAllProductsButton && (
        <ListItemButton
          dense
          selected={Boolean(!categoryKey && !supplier)}
          onClick={() => {
            onSelect({ supplierId: null, supplierProductCategoryKey: null });
          }}
        >
          <ListItemIcon
            sx={theme => ({
              minWidth: theme.layout.listItemMinWidth.xs,
              height: 0,
              alignItems: "center",
            })}
          >
            <NavigateBeforeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">
              {t("All products", { ns: "ProductSearch" })}
            </Typography>
          </ListItemText>
        </ListItemButton>
      )}
      <ListItemButton
        dense
        selected={Boolean(!categoryKey && supplier)}
        onClick={() => {
          onSelect({ supplierProductCategoryKey: null });
        }}
      >
        <ListItemIcon
          sx={theme => ({
            minWidth: theme.layout.listItemMinWidth.xs,
            height: 0,
            alignItems: "center",
          })}
        >
          {categoryKey && <NavigateBeforeIcon fontSize="small" />}
        </ListItemIcon>
        <ListItemText>
          <Typography
            variant="body2"
            fontWeight={!categoryKey ? "bold" : undefined}
          >
            {t("All {supplierName} products", {
              ns: "ProductSearch",
              supplierName: supplier.title,
            })}
          </Typography>
        </ListItemText>
      </ListItemButton>
      {reverse([...parentCategories]).map((parentCategory, index) => (
        <ListItemButton
          dense
          onClick={() => {
            onSelect({ supplierProductCategoryKey: parentCategory.key });
          }}
          key={parentCategory.id}
          selected={categoryKey === parentCategory.key}
        >
          <ListItemIcon
            sx={theme => ({
              minWidth: theme.layout.listItemMinWidth.xs,
              height: 0,
              alignItems: "center",
            })}
          >
            <NavigateBeforeIcon fontSize="small" />
          </ListItemIcon>
          {index === 0 && (
            <ListItemIcon
              sx={theme => ({
                minWidth: theme.layout.listItemMinWidth.xs,
                height: 0,
                alignItems: "center",
              })}
            >
              <Icon
                sx={{
                  width: "1.25rem",
                  height: "1.25rem",
                }}
              >
                {productCategoryIcons[parentCategory.key]?.outlined ??
                  productCategoryDefaultIcon}
              </Icon>
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={
                categoryKey === parentCategory.key ? "bold" : undefined
              }
            >
              {parentCategory.title}
            </Typography>
          </ListItemText>
        </ListItemButton>
      ))}
      {productCategory && (
        <ListItemButton
          dense
          selected={true}
          onClick={() => {
            onSelect({ supplierProductCategoryKey: productCategory.key });
          }}
          key={productCategory.id}
        >
          <ListItemIcon
            sx={theme => ({
              minWidth: theme.layout.listItemMinWidth.xs,
              height: 0,
              alignItems: "center",
            })}
          ></ListItemIcon>
          {!parentCategories.length && (
            <ListItemIcon
              sx={theme => ({
                minWidth: theme.layout.listItemMinWidth.xs,
                height: 0,
                alignItems: "center",
              })}
            >
              <Icon
                sx={{
                  width: "1.25rem",
                  height: "1.25rem",
                }}
              >
                {productCategoryIcons[productCategory.key]?.outlined ??
                  productCategoryDefaultIcon}
              </Icon>
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={
                categoryKey === productCategory.key ? "bold" : undefined
              }
            >
              {productCategory.title}
            </Typography>
          </ListItemText>
        </ListItemButton>
      )}
      {childCategories.map(childCategory => (
        <ListItemButton
          sx={{ pl: 8 }}
          onClick={() => {
            onSelect({ supplierProductCategoryKey: childCategory.key });
          }}
          selected={categoryKey === childCategory.key}
          key={childCategory.id}
        >
          {!productCategory && (
            <ListItemIcon
              sx={theme => ({
                minWidth: theme.layout.listItemMinWidth.xs,
                height: 0,
                alignItems: "center",
              })}
            >
              <Icon
                sx={{
                  width: "1.25rem",
                  height: "1.25rem",
                }}
              >
                {productCategoryIcons[childCategory.key]?.outlined ??
                  productCategoryDefaultIcon}
              </Icon>
            </ListItemIcon>
          )}
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={
                categoryKey === childCategory.key ? "bold" : undefined
              }
            >
              {childCategory.title}
            </Typography>
          </ListItemText>
        </ListItemButton>
      ))}
    </List>
  );
};
