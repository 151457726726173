import { PopoverProps } from "@mui/material";
import React from "react";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { Modal } from "../modal/Modal";
import { ModalOpenButton } from "../modal/ModalOpenButton";
import { PopoverOpenButton } from "./PopoverOpenButton";

interface Props extends Omit<PopoverProps, "open" | "title" | "content"> {
  title: React.ReactNode;
  children: React.ReactElement;
  content: React.ReactNode;
}

export function PopoverWithModalOnMobile({
  ref,
  title,
  content,
  children,
  ...props
}: React.PropsWithChildren<Props>) {
  const { isMinTablet } = useScreenWidth();

  return isMinTablet ? (
    <PopoverOpenButton content={content} children={children} {...props} />
  ) : (
    <ModalOpenButton
      Modal={FullscreenOnMobileModal}
      modalProps={{
        title,
        content,
      }}
      children={children}
    />
  );
}

const FullscreenOnMobileModal = ({
  title,
  handleClose,
  content,
}: {
  title: React.ReactNode;
  handleClose(): void;
  content: React.ReactNode;
}) => {
  return (
    <Modal
      title={title}
      handleClose={handleClose}
      dialogProps={{ fullScreen: true }}
    >
      {content}
    </Modal>
  );
};
