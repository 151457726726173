import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Grid, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useParams } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { PageSubHeader } from "../../commons/layout/PageSubHeader";
import { RequestRequestorMenu } from "../../features/requests/RequestRequestorMenu";
import { RequestStatusChip } from "../../features/requests/RequestStatusBadge";
import { RequestAttachmentsBox } from "../../features/requests/boxes/RequestAttachmentsBox";
import { RequestBiddersBox } from "../../features/requests/boxes/RequestBiddersBox";
import { RequestBiddingDetailsBox } from "../../features/requests/boxes/RequestBiddingDetailsBox";
import { RequestContactBox } from "../../features/requests/boxes/RequestContactBox";
import { RequestHeaderBox } from "../../features/requests/boxes/RequestHeaderBox";
import { RequestRequirementBox } from "../../features/requests/boxes/RequestRequirementBox";
import { RequestCancelButton } from "../../features/requests/buttons/RequestCancelButton";
import { RequestCloseForBiddingButton } from "../../features/requests/buttons/RequestCloseForBiddingButton";
import { RequestPublishButton } from "../../features/requests/buttons/RequestPublishButton";
import { useModifyRequestProjectInfoMutation } from "../../features/requests/modals/RequestProjectInfoModal.generated";
import { useProjectRequestQuery } from "./ProjectRequest.generated";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectRequest = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const { requestId } = useParams<{ requestId: string }>();
  if (!requestId) throw new Error("Request id is missing");

  const { t } = useTranslate(["Requests", "Global"]);

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = useProjectRequestQuery({
    client,
    variables: { projectId, requestId },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const request = project?.outgoingQuoteRequest;

  const [modifyRequest] = useModifyRequestProjectInfoMutation({
    client,
  });

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Requests", {
          ns: "Requests",
        }),
        to: `/projects/${projectId}/requests`,
      },
      {
        title: request?.title ?? "",
        to: `/projects/${projectId}/requests/${requestId}`,
      },
    ],
    [prefixBreadcrumbs, t, projectId, request?.title, requestId]
  );

  const closeNeedsConfirm = request?.recipients
    .flatMap(recipient => recipient.recipientQuotes)
    .some(quote => quote.agreement !== "NO");

  const actions =
    project && request
      ? [
          <RestrictedByProjectPermissionWithDebug
            permission="SHARE_REQUIREMENTS"
            project={project}
            key={"publish"}
          >
            {request.status === "OPEN" && (
              <RequestPublishButton
                request={request}
                projectId={project.id}
                refetchQueries={[namedOperations.Query.ProjectRequest]}
              />
            )}
          </RestrictedByProjectPermissionWithDebug>,
          <RestrictedByProjectPermissionWithDebug
            permission="SHARE_REQUIREMENTS"
            project={project}
            key={"close-for-bidding"}
          >
            {request.status === "PUBLISHED" && (
              <RequestCloseForBiddingButton
                projectId={project.id}
                requestId={requestId}
                needsConfirm={closeNeedsConfirm}
                refetchQueries={[namedOperations.Query.ProjectRequest]}
              />
            )}
          </RestrictedByProjectPermissionWithDebug>,
          <RestrictedByProjectPermissionWithDebug
            permission="SHARE_REQUIREMENTS"
            project={project}
            key="cancel"
          >
            {request?.status === "CLOSED_FOR_BIDDING" && (
              <RequestCancelButton
                key="cancel"
                projectId={project.id}
                requestId={requestId}
                needsConfirm={closeNeedsConfirm}
                refetchQueries={[namedOperations.Query.ProjectRequest]}
              />
            )}
          </RestrictedByProjectPermissionWithDebug>,
        ]
      : [];

  return (
    <Page
      subtitle={project?.title}
      title={request?.title ?? ""}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && request ? (
          <PageSubHeader
            title={request.title}
            actionMenu={
              <RequestRequestorMenu request={request} project={project} />
            }
            status={<RequestStatusChip request={request} />}
            handleTitleChange={
              request.status === "OPEN"
                ? async newValue => {
                    await modifyRequest({
                      variables: {
                        input: { projectId, requestId, title: newValue },
                      },
                    });
                    enqueueSnackbar(
                      t("Title updated", {
                        ns: "Global",
                      })
                    );
                  }
                : undefined
            }
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
      action={actions}
    >
      {project && request && (
        <PageContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Stack direction="column" spacing={2}>
                <RequestHeaderBox
                  request={request}
                  address={project.building?.buildingAddress}
                  project={project}
                />

                <RequestRequirementBox request={request} />
                <RequestAttachmentsBox request={request} project={project} />
                <RequestContactBox request={request} />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Stack direction="column" spacing={2}>
                <RequestBiddersBox project={project} request={request} />
                <RequestBiddingDetailsBox request={request} project={project} />
              </Stack>
            </Grid>
          </Grid>
        </PageContainer>
      )}
    </Page>
  );
};
