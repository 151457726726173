import { QuoteMaterialPriceHandling } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

const ALL_QUOTE_MATERIAL_PRICE_HANDLING_TYPES: QuoteMaterialPriceHandling[] = [
  "CALCULATED_SELL_PRICE",
  "CALCULATED_MARGIN",
];

export function useQuoteMaterialPriceHandlingOptions() {
  const { t } = useTranslate(["OrganisationSettings"]);

  const labels: Record<QuoteMaterialPriceHandling, string> = {
    CALCULATED_SELL_PRICE: t("Calculate sell price from net price and margin", {
      ns: "OrganisationSettings",
    }),
    CALCULATED_MARGIN: t("Calculate margin from list and net price", {
      ns: "OrganisationSettings",
    }),
  };

  const options = ALL_QUOTE_MATERIAL_PRICE_HANDLING_TYPES.map(option => ({
    value: option,
    label: labels[option],
  }));

  return options;
}
