import {
  CollapseSection,
  LabeledValue,
  useFormatting,
  VisibilityIndicatorIcon,
} from "@msys/ui";
import { Box, Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";

interface Props {
  product: {
    pricing?: {
      netPrice?: number | null;
      listPrice?: number | null;
      discount?: number | null;
      discountGroupKey?: string | null;
      discountGroup?: {
        key: string;
        description: string;
        value: number;
        discountType: "DISCOUNT" | "FACTOR" | "SURCHARGE";
      } | null;
    } | null;
  };
  hideVisibility?: boolean;
  gridColumnSpacing?: number;
}
export function ProductPricing({
  product,
  hideVisibility,
  gridColumnSpacing = 2,
}: Props) {
  const { t } = useTranslate("Product");
  const { getFormattedPrice } = useFormatting();

  const productPricing = product?.pricing;

  return productPricing ? (
    <CollapseSection title={t("Pricing")}>
      <Grid
        container
        columns={2}
        rowSpacing={1}
        columnSpacing={gridColumnSpacing}
      >
        <Grid item xs={1} display={"flex"} justifyContent={"space-between"}>
          <LabeledValue label={t("List Price")}>
            {productPricing.listPrice
              ? getFormattedPrice(productPricing.listPrice)
              : "-"}
          </LabeledValue>
          {!hideVisibility && <VisibilityIndicatorIcon isVisible={false} />}
        </Grid>
        <Grid item xs={1} display={"flex"} justifyContent={"space-between"}>
          <LabeledValue label={t("Discount")}>
            {productPricing.netPrice && productPricing.listPrice
              ? (
                  (1 - productPricing.netPrice / productPricing.listPrice) *
                  100
                ).toFixed(2) + "%"
              : "-"}
          </LabeledValue>
          {!hideVisibility && <VisibilityIndicatorIcon isVisible={false} />}
        </Grid>
        <Grid item xs={1} display={"flex"} justifyContent={"space-between"}>
          <LabeledValue label={t("Net Price")}>
            {productPricing.netPrice
              ? getFormattedPrice(productPricing.netPrice)
              : "-"}
          </LabeledValue>
          {!hideVisibility && <VisibilityIndicatorIcon isVisible={false} />}
        </Grid>
        {(productPricing.discountGroup || productPricing.discountGroupKey) && (
          <Grid item xs={1} display={"flex"} justifyContent={"space-between"}>
            <LabeledValue label={t("Discount Group")}>
              {[
                productPricing.discountGroup?.key ??
                  productPricing.discountGroupKey,
                productPricing.discountGroup?.description,
              ]
                .filter(Boolean)
                .join(" - ")}
            </LabeledValue>
            {!hideVisibility && <VisibilityIndicatorIcon isVisible={false} />}
          </Grid>
        )}
      </Grid>
    </CollapseSection>
  ) : null;
}
