/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ToggleQuoteBindingMenuItem_QuoteFragmentDoc } from './buttons/ToggleQuoteBindingMenuItem.generated';
export type QuoteMenu_ProjectFragment = { __typename: 'Project', id: string };

export type QuoteMenu_QuoteFragment = { __typename: 'Quote', id: string, viewerPermissions: Array<Types.PermissionName>, agreement: Types.Agreement, owningSystemOrganisationId: string, isPublished: boolean, canBeDeleted: boolean, isBinding: boolean };

export const QuoteMenu_ProjectFragmentDoc = gql`
    fragment QuoteMenu_Project on Project {
  id
}
    `;
export const QuoteMenu_QuoteFragmentDoc = gql`
    fragment QuoteMenu_Quote on Quote {
  id
  viewerPermissions
  agreement
  owningSystemOrganisationId
  isPublished
  canBeDeleted
  ...ToggleQuoteBindingMenuItem_Quote
}
    ${ToggleQuoteBindingMenuItem_QuoteFragmentDoc}`;