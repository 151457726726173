import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  LabeledValue,
  RichTextEditor,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import uploadcare from "uploadcare-widget";
import { color } from "../../../common/MuiThemeProvider";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { EntityNumber } from "../../commons/EntityNumber";
import {
  LabeledSignatureValue,
  SignatureValue,
} from "../../commons/labeled-values/LabeledSignatureValue";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { PageSubHeader } from "../../commons/layout/PageSubHeader";
import { Stack } from "../../commons/layout/Stack";
import { LabeledAddressInlineValue } from "../../features/addresses/LabeledAddressInlineValue";
import { dataURLtoFile } from "../../features/attachments/helpers";
import { TimeReportDownloadButton } from "../../features/time-reports/components/TimeReportDownloadButton";
import { TimeReportHeader } from "../../features/time-reports/components/TimeReportHeader";
import { TimeReportItemsTable } from "../../features/time-reports/components/TimeReportItemsTable";
import { TimeReportStatusBadge } from "../../features/time-reports/components/TimeReportStatusBadge";
import { TimeReportTitle } from "../../features/time-reports/components/TimeReportTitle";
import { ClosingTextInput } from "../../features/time-reports/TimeReportClosingText";
import { OpeningTextInput } from "../../features/time-reports/TimeReportOpeningText";
import {
  useApproveTimeReportMutation,
  useModifyTimeReportTextsMutation,
  useSignTimeReportMutation,
  useTimeReportQuery,
} from "./ProjectTimeReportPreview.generated";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectTimeReportPreview = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const { reportId } = useParams();
  if (!reportId) throw new Error("Report id is missing");

  const { t } = useTranslate(["TimeReports", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const { isMinTablet } = useScreenWidth();

  const { getFormattedDate } = useFormatting();

  const client = useApolloClient();
  const query = useTimeReportQuery({
    client,
    fetchPolicy: "cache-and-network",
    variables: {
      projectId,
      reportId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const report = project?.timeReport;
  const items = report?.items ?? [];
  const contractor = report?.organisation;

  const [modifyTimeReportTexts] = useModifyTimeReportTextsMutation();
  const [signTimeReport, { loading: signTimeReportLoading }] =
    useSignTimeReportMutation();
  const [approveTimeReport, { loading: approveTimeReportLoading }] =
    useApproveTimeReportMutation();

  const breadcrumbs = useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Tasks", {
          ns: "TimeReports",
        }),
        to: `/projects/${projectId}/tasks`,
      },
      {
        title: t("Time Reports", {
          ns: "TimeReports",
        }),
        to: `/projects/${projectId}/tasks/reports`,
      },
      {
        title: report ? <TimeReportTitle report={report} /> : "",
        to: `/projects/${projectId}/tasks/reports/${reportId}`,
      },
    ],
    [prefixBreadcrumbs, t, projectId, report, reportId]
  );

  const Title = React.useMemo(
    () => (report ? <TimeReportTitle report={report} /> : ""),
    [report]
  );

  const handleApproveTimeReport = async () => {
    try {
      await approveTimeReport({ variables: { input: { id: reportId } } });
      enqueueSnackbar(
        t("Time report approved", {
          ns: "TimeReports",
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleSaveTimeReportText = async (content: any) => {
    try {
      await modifyTimeReportTexts({
        variables: {
          input: {
            id: reportId,
            timeReportTexts: content,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleSaveTasksDescription = async (content: any) => {
    try {
      await modifyTimeReportTexts({
        variables: {
          input: {
            id: reportId,
            tasksDescription: content,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleSignTimeReport = async (
    field: "contractorSignature" | "contracteeSignature",
    value: SignatureValue
  ) => {
    try {
      const fileToUpload = dataURLtoFile(value.signature, field);
      const upload = uploadcare.fileFrom("object", fileToUpload);

      await new Promise<void>(resolve => {
        upload.done(async (fileInfo: any) => {
          try {
            await signTimeReport({
              variables: {
                input: {
                  id: reportId,
                  [field]: {
                    place: value.place,
                    date: value.date.format("YYYY-MM-DD"),
                    signature: {
                      url: fileInfo.cdnUrl,
                      title: fileInfo.name,
                      mimeType: fileInfo.mimeType,
                    },
                  },
                },
              },
            });
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
          }
          resolve();
        });
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const canEdit = Boolean(
    report &&
      !report.approvedAt &&
      !report.contracteeSignature &&
      !report.contractorSignature
  );

  return (
    <Page
      subtitle={project?.title}
      title={Title}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      action={
        report && !report.approvedAt ? (
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_QUOTES"
            project={project}
          >
            <LoadingButton
              disabled={isUploadingFile || approveTimeReportLoading}
              variant="contained"
              color="primary"
              endIcon={<CheckCircleOutlineIcon />}
              loading={approveTimeReportLoading}
              onClick={handleApproveTimeReport}
            >
              {t("Mark as approved", {
                ns: "TimeReports",
              })}
            </LoadingButton>
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined
      }
      subHeader={
        project && report ? (
          <PageSubHeader
            actionButtons={
              <RestrictedByProjectPermissionWithDebug
                permission="READ_QUOTES"
                project={project}
              >
                <TimeReportDownloadButton
                  projectId={projectId}
                  reportId={reportId}
                  reportFile={report.file}
                />
              </RestrictedByProjectPermissionWithDebug>
            }
            title={<TimeReportTitle report={report} />}
            status={<TimeReportStatusBadge report={report} />}
            entityNumber={<EntityNumber number={report.number} />}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
    >
      {project && report && contractor && (
        <PageContainer>
          <Container maxWidth="md">
            <RestrictedByProjectPermissionWithDebug
              permission="READ_QUOTES"
              project={project}
            >
              <Stack flexDirection="column" width="100%">
                <RestrictedByProjectPermissionWithDebug
                  permission="READ_QUOTES"
                  project={project}
                >
                  <TimeReportHeader
                    project={project}
                    reportNumber={report.number}
                    createdAt={report.createdAt}
                    contractor={contractor}
                  />
                </RestrictedByProjectPermissionWithDebug>

                <RestrictedByProjectPermissionWithDebug
                  permission="READ_QUOTES"
                  project={project}
                >
                  <Paper>
                    <Box padding={2}>
                      <Typography variant="h2">
                        <TimeReportTitle report={report} />
                      </Typography>
                    </Box>
                  </Paper>
                </RestrictedByProjectPermissionWithDebug>

                <RestrictedByProjectPermissionWithDebug
                  permission="MANAGE_QUOTES"
                  project={project}
                  otherwise={
                    <OpeningTextInput
                      canEdit={false}
                      content={report.timeReportTexts?.opening}
                      showInfo={false}
                    />
                  }
                >
                  <OpeningTextInput
                    canEdit={canEdit}
                    content={report.timeReportTexts?.opening}
                    onSave={handleSaveTimeReportText}
                    showInfo={false}
                  />
                </RestrictedByProjectPermissionWithDebug>

                <Paper>
                  <Box p={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <LabeledAddressInlineValue
                          inline={isMinTablet}
                          label={t("Project address", {
                            ns: "TimeReports",
                          })}
                          address={project.building?.buildingAddress}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {!isMinTablet && (
                          <Divider style={{ marginBottom: 8 }} />
                        )}
                        <LabeledValue
                          inline={isMinTablet}
                          label={t("Time period", {
                            ns: "TimeReports",
                          })}
                        >
                          {getFormattedDate(report.fromDate)} –{" "}
                          {getFormattedDate(report.tillDate)}
                        </LabeledValue>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>

                <RestrictedByProjectPermissionWithDebug
                  permission="READ_QUOTES"
                  project={project}
                >
                  <TimeReportItemsTable items={items} />
                </RestrictedByProjectPermissionWithDebug>

                <RestrictedByProjectPermissionWithDebug
                  permission="READ_QUOTES"
                  project={project}
                >
                  <Paper>
                    <Box p={2}>
                      {report.tasksDescription ? (
                        <RestrictedByProjectPermissionWithDebug
                          permission="MANAGE_QUOTES"
                          project={project}
                          otherwise={
                            <RichTextEditor
                              content={report.tasksDescription}
                              canEdit={false}
                              saveButtonLabel={t("Save", { ns: "Global" })}
                              cancelButtonLabel={t("Cancel", { ns: "Global" })}
                            />
                          }
                        >
                          <RichTextEditor
                            content={report.tasksDescription}
                            canEdit={canEdit}
                            onSave={handleSaveTasksDescription}
                            saveButtonLabel={t("Save", { ns: "Global" })}
                            cancelButtonLabel={t("Cancel", { ns: "Global" })}
                          />
                        </RestrictedByProjectPermissionWithDebug>
                      ) : (
                        <Typography
                          component="div"
                          style={{ color: color.grey }}
                        >
                          {t("No task items", {
                            ns: "TimeReports",
                          })}
                        </Typography>
                      )}
                    </Box>
                  </Paper>
                </RestrictedByProjectPermissionWithDebug>

                <RestrictedByProjectPermissionWithDebug
                  permission="MANAGE_QUOTES"
                  project={project}
                  otherwise={
                    <ClosingTextInput
                      canEdit={false}
                      content={report.timeReportTexts?.closing}
                      showInfo={false}
                    />
                  }
                >
                  <ClosingTextInput
                    canEdit={canEdit}
                    content={report.timeReportTexts?.closing}
                    onSave={handleSaveTimeReportText}
                    showInfo={false}
                  />
                </RestrictedByProjectPermissionWithDebug>

                <Paper>
                  <Box p={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <RestrictedByProjectPermissionWithDebug
                          permission="MANAGE_QUOTES"
                          project={project}
                          otherwise={
                            <LabeledSignatureValue
                              disabled={signTimeReportLoading}
                              canEdit={false}
                              label={t("Contractor signature", {
                                ns: "TimeReports",
                              })}
                              value={report.contractorSignature}
                              style={
                                isMinTablet ? { marginRight: 12 } : undefined
                              }
                            />
                          }
                        >
                          <LabeledSignatureValue
                            disabled={signTimeReportLoading}
                            canEdit={
                              !report.contractorSignature && !report.approvedAt
                            }
                            label={t("Contractor signature", {
                              ns: "TimeReports",
                            })}
                            value={report.contractorSignature}
                            style={
                              isMinTablet ? { marginRight: 12 } : undefined
                            }
                            onChange={async value => {
                              await handleSignTimeReport(
                                "contractorSignature",
                                value
                              );
                            }}
                            defaultPlace={
                              project.building?.buildingAddress?.city
                            }
                            modalTitle={t("Sign time report", {
                              ns: "TimeReports",
                            })}
                            modalDescription={t(
                              "Sign in the box to confirm the time report",
                              {
                                ns: "TimeReports",
                              }
                            )}
                          />
                        </RestrictedByProjectPermissionWithDebug>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {!isMinTablet && (
                          <Divider style={{ marginBottom: 8 }} />
                        )}
                        <RestrictedByProjectPermissionWithDebug
                          permission="DECIDE_QUOTES"
                          project={project}
                          otherwise={
                            <LabeledSignatureValue
                              disabled={signTimeReportLoading}
                              canEdit={false}
                              label={t("Client signature", {
                                ns: "TimeReports",
                              })}
                              value={report.contracteeSignature}
                            />
                          }
                        >
                          <LabeledSignatureValue
                            disabled={signTimeReportLoading}
                            canEdit={
                              !report.contracteeSignature && !report.approvedAt
                            }
                            label={t("Client signature", { ns: "TimeReports" })}
                            value={report.contracteeSignature}
                            onChange={async value => {
                              await handleSignTimeReport(
                                "contracteeSignature",
                                value
                              );
                            }}
                            defaultPlace={
                              project.building?.buildingAddress?.city
                            }
                            modalTitle={t("Sign time report", {
                              ns: "TimeReports",
                            })}
                            modalDescription={t(
                              "Sign in the box to approve the time report",
                              {
                                ns: "TimeReports",
                              }
                            )}
                          />
                        </RestrictedByProjectPermissionWithDebug>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Stack>
            </RestrictedByProjectPermissionWithDebug>
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};
