/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddOrganisationPublicProfileModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddOrganisationPublicProfileModalQuery = { viewer?: { __typename: 'Viewer', organisation: { __typename: 'ViewerOrganisation', id: string, title: string, description: string, email: any, website: string, skillset: Array<Types.Skill>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }> } } | null };

export type AddOrganisationProfileMutationVariables = Types.Exact<{
  input: Types.AddOrganisationProfileInput;
}>;


export type AddOrganisationProfileMutation = { addOrganisationProfile: { __typename: 'AddOrganisationProfileResult', organisation: { __typename: 'Organisation', id: string, profile?: { __typename: 'OrganisationProfile', id: string, slug: string } | null }, profile: { __typename: 'OrganisationProfile', id: string, slug: string } } };


export const AddOrganisationPublicProfileModalDocument = gql`
    query AddOrganisationPublicProfileModal {
  viewer {
    organisation {
      id
      title
      description
      email
      website
      logo {
        ...AttachmentSnapshot
      }
      branchAddress {
        ...AddressDetails__Address
      }
      phones {
        id
        number
        type
        main
      }
      skillset
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}
${AddressDetails__AddressFragmentDoc}`;

/**
 * __useAddOrganisationPublicProfileModalQuery__
 *
 * To run a query within a React component, call `useAddOrganisationPublicProfileModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOrganisationPublicProfileModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOrganisationPublicProfileModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddOrganisationPublicProfileModalQuery(baseOptions?: Apollo.QueryHookOptions<AddOrganisationPublicProfileModalQuery, AddOrganisationPublicProfileModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOrganisationPublicProfileModalQuery, AddOrganisationPublicProfileModalQueryVariables>(AddOrganisationPublicProfileModalDocument, options);
      }
export function useAddOrganisationPublicProfileModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOrganisationPublicProfileModalQuery, AddOrganisationPublicProfileModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOrganisationPublicProfileModalQuery, AddOrganisationPublicProfileModalQueryVariables>(AddOrganisationPublicProfileModalDocument, options);
        }
export function useAddOrganisationPublicProfileModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddOrganisationPublicProfileModalQuery, AddOrganisationPublicProfileModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddOrganisationPublicProfileModalQuery, AddOrganisationPublicProfileModalQueryVariables>(AddOrganisationPublicProfileModalDocument, options);
        }
export type AddOrganisationPublicProfileModalQueryHookResult = ReturnType<typeof useAddOrganisationPublicProfileModalQuery>;
export type AddOrganisationPublicProfileModalLazyQueryHookResult = ReturnType<typeof useAddOrganisationPublicProfileModalLazyQuery>;
export type AddOrganisationPublicProfileModalSuspenseQueryHookResult = ReturnType<typeof useAddOrganisationPublicProfileModalSuspenseQuery>;
export type AddOrganisationPublicProfileModalQueryResult = Apollo.QueryResult<AddOrganisationPublicProfileModalQuery, AddOrganisationPublicProfileModalQueryVariables>;
export const AddOrganisationProfileDocument = gql`
    mutation AddOrganisationProfile($input: AddOrganisationProfileInput!) {
  addOrganisationProfile(input: $input) {
    organisation {
      id
      profile {
        id
        slug
      }
    }
    profile {
      id
      slug
    }
  }
}
    `;
export type AddOrganisationProfileMutationFn = Apollo.MutationFunction<AddOrganisationProfileMutation, AddOrganisationProfileMutationVariables>;

/**
 * __useAddOrganisationProfileMutation__
 *
 * To run a mutation, you first call `useAddOrganisationProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganisationProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganisationProfileMutation, { data, loading, error }] = useAddOrganisationProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganisationProfileMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganisationProfileMutation, AddOrganisationProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganisationProfileMutation, AddOrganisationProfileMutationVariables>(AddOrganisationProfileDocument, options);
      }
export type AddOrganisationProfileMutationHookResult = ReturnType<typeof useAddOrganisationProfileMutation>;
export type AddOrganisationProfileMutationResult = Apollo.MutationResult<AddOrganisationProfileMutation>;
export type AddOrganisationProfileMutationOptions = Apollo.BaseMutationOptions<AddOrganisationProfileMutation, AddOrganisationProfileMutationVariables>;