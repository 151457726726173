import { LabeledValue, ModalOpenButton } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Stack,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { PickBrandModal } from "../modals/PickBrandModal";
import DeleteIcon from "@mui/icons-material/Delete";

interface Brand {
  id: string;
  title: string;
}

interface Props {
  label?: string;
  valueLabel?: string;
  brand?: Brand | null;
  handleChange: (brand: Brand | null) => void;
  canEdit?: boolean;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
}
export function PickBrandButton({
  label,
  valueLabel,
  brand,
  handleChange,
  canEdit = true,
  buttonProps,
  iconButtonProps,
}: Props) {
  const { t } = useTranslate("ProductOverview");

  if (!canEdit && !brand) return null;

  if (brand) {
    return (
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <LabeledValue label={valueLabel ?? t("Brand")}>
          {brand.title}
        </LabeledValue>
        {canEdit && (
          <ModalOpenButton
            Modal={PickBrandModal}
            modalProps={{
              brand: brand,
              handleComplete: handleChange,
            }}
          >
            <IconButton size="small" color="secondary" {...iconButtonProps}>
              <EditIcon />
            </IconButton>
          </ModalOpenButton>
        )}
        {canEdit && (
          <IconButton
            size="small"
            color="secondary"
            {...iconButtonProps}
            onClick={() => handleChange(null)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="flex-start">
      <LabeledValue label={valueLabel ?? t("Product type")}>
        <ModalOpenButton
          Modal={PickBrandModal}
          modalProps={{
            brand: brand,
            handleComplete: handleChange,
          }}
        >
          <Button
            size="small"
            variant="text"
            color="secondary"
            {...buttonProps}
          >
            {label ?? t("Assign product type")}
          </Button>
        </ModalOpenButton>
      </LabeledValue>
    </Stack>
  );
}
