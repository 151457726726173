import { css } from "@emotion/react";
import { StyledComponent } from "@emotion/styled";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Theme, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React, { CSSProperties } from "react";
import { ReactComponent as FileMoveIcon } from "../assets/icons/icon-file-move.svg";
import { useContextMenu } from "../commons/hooks/useContextMenu";
import { color, px, size } from "../../common/MuiThemeProvider";
import { Agreement } from "../../clients/graphqlTypes";
import { transientOptions } from "../styles";
import {
  TreeDataIconsWrapper,
  TreeDataPath,
  TreeDataWrapper,
} from "./components/TreeDataContainer";
import { TreeIconLeftContainer } from "./components/TreeIconLeftContainer";
import { TreeIconRightContainer } from "./components/TreeIconRightContainer";
import { TreeTitle } from "./components/TreeTitle";
import { BareTreeItemType, ItemComponentProps } from "./types";

interface Props
  extends Omit<
    ItemComponentProps<BareTreeItemType>,
    "parentItem" | "allItems"
  > {
  docAgreement: Agreement;
  IconLeftButtons?: JSX.Element | null;
  IconRightButtons?: JSX.Element | null;
  Data123Left?: JSX.Element | null;
  Data123Right?: JSX.Element | null;
  ContextMenu?: JSX.Element | null;
  LeftStatus?: JSX.Element | null;
  RightNumber?: JSX.Element | null;
  RightIcons?: JSX.Element | null;
  FormRow?: JSX.Element | null;
  showPath?: boolean;
  title?: string;
  pathForPdf?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const RIGHT_ICON_STYLE: CSSProperties = {
  width: 12,
  height: 12,
  fontSize: 12,
};

export const BareTreeTableItem: React.FC<Props> = React.memo(
  ({
    onClick,
    depth,
    docAgreement,
    showPath = true,
    title,
    pathForPdf,
    isGreyedOut,
    isHidden,
    isPriceHidden,
    subcontractPath,
    item,
    collapseButton,
    IconLeftButtons,
    IconRightButtons,
    Data123Left,
    Data123Right,
    ContextMenu,
    RightNumber,
    LeftStatus,
    RightIcons,
    FormRow,
  }) => {
    const theme = useTheme();

    const [handleClick, menuElement] =
      useContextMenu<HTMLDivElement>(ContextMenu);

    const showHelpIcon =
      item.type === "decision" || item.decisionIsContingentItem;

    return (
      <TreeItemContainer
        $docAgreement={docAgreement}
        $isGreyedOut={isGreyedOut}
        $item={item}
        onContextMenuCapture={handleClick}
        onClick={onClick}
      >
        <ColoredLineS1 />

        {collapseButton || showHelpIcon || IconLeftButtons ? (
          <TreeIconLeftContainer>
            {collapseButton}
            {IconLeftButtons}
            {showHelpIcon ? (
              <HelpOutlineIcon
                fontSize="small"
                style={{ color: theme.palette.warning.main }}
              />
            ) : null}
          </TreeIconLeftContainer>
        ) : null}

        <TreeTextTableContainer>
          <TreeTitle $bold={item.type === "section"} $depth={depth}>
            {showPath ? (
              <TreeDataPath $depth={depth}>
                {pathForPdf ?? item.pathForPdf}
              </TreeDataPath>
            ) : null}
            {title ?? item.title}
          </TreeTitle>
          <TreeDataWrapper>
            <TreeDataIconsWrapper>
              {RightIcons ? (
                <TreeDataIconsWrapper>{RightIcons}</TreeDataIconsWrapper>
              ) : null}
              {subcontractPath && (
                <span>
                  <FileMoveIcon style={RIGHT_ICON_STYLE} />
                </span>
              )}
              {isHidden && (
                <span>
                  <VisibilityOffIcon style={RIGHT_ICON_STYLE} />
                </span>
              )}
              {isPriceHidden && !isHidden && (
                <span>
                  <MoneyOffIcon style={RIGHT_ICON_STYLE} />
                </span>
              )}
            </TreeDataIconsWrapper>
            {IconRightButtons ? (
              <TreeIconRightContainer>
                {IconRightButtons}
              </TreeIconRightContainer>
            ) : null}
            {FormRow}
          </TreeDataWrapper>
        </TreeTextTableContainer>
        {menuElement}
      </TreeItemContainer>
    );
  }
);

export const ColoredLineS1: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled("div")`
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  width: 4px;

  pointer-events: none;

  position: absolute;

  left: 0;
  top: 0;
  bottom: 0;
`;

ColoredLineS1.defaultProps = {
  className: "msys-colored-line-s1",
};

const TreeItemContainer = styled("div", transientOptions)<{
  $isGreyedOut: boolean;
  $docAgreement: Agreement;
  $item: BareTreeItemType;
}>`
  background-color: unset;
  border-radius: unset;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-grow: 1;
  position: static;
  padding-left: 6px;
  padding-right: 6px;
  min-height: ${size.tableItemFullHeight}px;
  max-height: ${size.tableItemFullHeight}px;
  max-width: 100%;
  user-select: none;
  box-shadow: none;
  min-width: 0;

  ${({ onClick }) => (onClick ? `cursor: pointer;` : "")}
  ${({ $isGreyedOut }) => ($isGreyedOut ? `background-color: #F2F2F2;` : "")}

  ${({ theme, $item }) =>
    $item.decisionOptionElimination
      ? css`
          color: ${theme.palette.grey[600]};
        `
      : ""}
  
  ${({ theme, $item, $docAgreement }) =>
    $docAgreement !== "NONE" && $item.needsAgreement
      ? css`
          .msys-colored-line-s1 {
            margin-right: ${px.xs};
            background-color: ${theme.palette.warning.main};
          }

          .msys-tree-status-label {
            color: ${theme.palette.warning.main};
          }
        `
      : ""}

  ${({ $item }) => {
    if ($item.type === "defect")
      return css`
        color: ${color.red};
        .msys-colored-line-s1 {
          margin-right: ${px.xs};
          background-color: ${color.red};
        }
      `;

    if ($item.type === "unpaid")
      return css`
        color: ${color.turquoise};
        .msys-colored-line-s1 {
          margin-right: ${px.xs};
          background-color: ${color.turquoise};
        }
      `;

    if ($item.deletedAt)
      return css`
        opacity: 0.4;
        text-decoration: line-through;

        .msys-tree-status-label {
          opacity: 1;
          text-decoration: none;
        }
      `;

    if ($item.type !== "paid") return "";

    if ($item.binding === "draft")
      return css`
        opacity: 0.38;

        .msys-tree-title {
          font-style: italic;
        }
      `;

    if ($item.binding === "non_binding")
      return css`
        color: ${color.default};

        .msys-tree-title {
          font-style: italic;
        }
      `;

    if ($item.binding === "binding") return "";

    return "";
  }}
`;

const TreeTextTableContainer = styled("div")(`
  flex: 1;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`);
