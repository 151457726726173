import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Container } from "@mui/material";
import React from "react";
import { useUserData } from "../../auth/useUserData";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { getViewerDecisionRole } from "../../features/doc-items/helpers";
import { useDecisionProcess } from "../../features/doc-items/hooks/useDecisionProcess";
import { DecisionWizardModalWithDefaultActions } from "../../features/doc-items/modals/DecisionWizardModal";
import { DecisionsInBulkProcessRef } from "../../features/doc-items/modals/DecisionsInBulkProcess";
import { RequirementAction } from "../../features/requirements/RequirementAction";
import { RequirementDecisionsInBulkProcess } from "../../features/requirements/RequirementDecisionsInBulkProcess";
import { RequirementPreview } from "../../features/requirements/RequirementPreview";
import { RequirementSubHeader } from "../../features/requirements/RequirementSubHeader";
import { useProjectRequirementPreviewQuery } from "./ProjectRequirementPreview.generated";
import { DocShareBox } from "../documents/DocShareBox";
import { useFeature } from "../../../common/FeatureFlags";

const EMPTY_ARRAY: any[] = [];

interface Props {
  projectId: string;
  requirementId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectRequirementPreview = ({
  projectId,
  requirementId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { isMinTablet } = useScreenWidth();

  const [previewContainer, setPreviewContainer] =
    React.useState<HTMLDivElement | null>(null);

  const client = useApolloClient();
  const query = useProjectRequirementPreviewQuery({
    client,
    variables: {
      quoteId: requirementId,
      projectId,
    },
    fetchPolicy: "no-cache",
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.requirement)?.requirement;
  const docTitle = doc?.title ?? "";
  const rootItem = doc?.rootItem;

  const pathToProject = `/projects/${projectId}`;
  const pathToDocList = `/projects/${projectId}/requirements`;
  const pathToDoc = `/projects/${projectId}/requirements/${requirementId}`;
  const pathToDocPage = `${pathToDoc}/preview`;

  const canFinalize =
    viewer.organisation.id === doc?.owningSystemOrganisationId;

  const canEdit = viewer.organisation.id === doc?.owningSystemOrganisationId;

  const viewerIsOwner =
    viewer.organisation.id === doc?.owningSystemOrganisationId;
  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const decisionProcess = useDecisionProcess({
    projectId,
    docId: requirementId,
    embeddedMode: false,
    itemUuidSeed: "",
    viewerDecisionRole,
    decisionContext: "onQuoteRefinement",
    onClose: query.refetch,
  });

  const docSharingEnabled = useFeature("DocSharing");
  const viewerPermissionDetailsEnabled = useFeature("ViewerPermissionDetails");

  const decisionsInBulkProcessRef =
    React.useRef<DecisionsInBulkProcessRef>(null);
  const startDecisionsInBulkProcess = React.useCallback(() => {
    decisionsInBulkProcessRef.current?.startDecisionsInBulkProcess();
  }, []);

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc && rootItem ? (
          <RequirementSubHeader
            project={project}
            requirement={doc}
            activeView="preview"
            canEdit={canEdit}
            pathToDoc={pathToDoc}
            pathToDocList={pathToDocList}
            pathToDocPage={pathToDocPage}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
      action={
        project && doc ? (
          <RequirementAction
            doc={doc}
            project={project}
            pathToProject={pathToProject}
            startDecisionWizard={decisionProcess.start}
            startDecisionsInBulkProcess={startDecisionsInBulkProcess}
          />
        ) : undefined
      }
    >
      {project && doc && rootItem && (
        <PageContainer ref={setPreviewContainer}>
          <Container maxWidth="md">
            {viewerPermissionDetailsEnabled ? (
              <div>
                <h2>viewer permission details</h2>
                <hr />
                <textarea
                  style={{ width: "100%", height: 50 }}
                  readOnly
                  defaultValue={JSON.stringify(doc.viewerPermissions, null, 2)}
                ></textarea>
              </div>
            ) : null}

            {docSharingEnabled ? (
              <DocShareBox
                docType="REQUIREMENT"
                docId={requirementId}
                projectId={projectId}
              />
            ) : null}

            <RequirementPreview
              requirement={doc}
              canFinalize={canFinalize}
              expandedItemIds={EMPTY_ARRAY}
              refetch={query.refetch}
              container={isMinTablet ? previewContainer : undefined}
            />
            <DecisionWizardModalWithDefaultActions
              processState={decisionProcess.state}
              projectId={projectId}
              docType="REQUIREMENT"
              docId={requirementId}
              expandedItemIds={EMPTY_ARRAY}
              viewerDecisionRole={viewerDecisionRole}
              decisionContext={decisionProcess.decisionContext}
              isOptionHidden={item => !viewerIsOwner && item.isHidden}
              handleClose={decisionProcess.close}
              handleNextItem={decisionProcess.next}
              handlePreviousItem={decisionProcess.previous}
              refetch={query.refetch}
            />
            <RequirementDecisionsInBulkProcess
              ref={decisionsInBulkProcessRef}
              projectId={projectId}
              requirementId={doc.id}
              canFinalize={canFinalize}
              externalExpandedItemIds={EMPTY_ARRAY}
              handleComplete={query.refetch}
            />
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};
