/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectCrmSuppliersQueryVariables = Types.Exact<{
  sorting?: Types.InputMaybe<Array<Types.OrganisationCrmOrganisationsSorting>>;
}>;


export type SelectCrmSuppliersQuery = { crmSuppliers: { __typename: 'CrmCompanyConnection', edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, linkedSystemOrganisationId?: string | null, title: string } }> } | { __typename: 'MissingPermissions' } };


export const SelectCrmSuppliersDocument = gql`
    query SelectCrmSuppliers($sorting: [OrganisationCrmOrganisationsSorting!]) {
  crmSuppliers: crmCompanies(
    limit: 0
    filters: {tagsAll: ["SUPPLIER"]}
    sorting: $sorting
  ) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          linkedSystemOrganisationId
          title
        }
      }
    }
  }
}
    `;

/**
 * __useSelectCrmSuppliersQuery__
 *
 * To run a query within a React component, call `useSelectCrmSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectCrmSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectCrmSuppliersQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSelectCrmSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SelectCrmSuppliersQuery, SelectCrmSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectCrmSuppliersQuery, SelectCrmSuppliersQueryVariables>(SelectCrmSuppliersDocument, options);
      }
export function useSelectCrmSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectCrmSuppliersQuery, SelectCrmSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectCrmSuppliersQuery, SelectCrmSuppliersQueryVariables>(SelectCrmSuppliersDocument, options);
        }
export function useSelectCrmSuppliersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelectCrmSuppliersQuery, SelectCrmSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelectCrmSuppliersQuery, SelectCrmSuppliersQueryVariables>(SelectCrmSuppliersDocument, options);
        }
export type SelectCrmSuppliersQueryHookResult = ReturnType<typeof useSelectCrmSuppliersQuery>;
export type SelectCrmSuppliersLazyQueryHookResult = ReturnType<typeof useSelectCrmSuppliersLazyQuery>;
export type SelectCrmSuppliersSuspenseQueryHookResult = ReturnType<typeof useSelectCrmSuppliersSuspenseQuery>;
export type SelectCrmSuppliersQueryResult = Apollo.QueryResult<SelectCrmSuppliersQuery, SelectCrmSuppliersQueryVariables>;