import { useApolloClient } from "@apollo/client";
import { CollapseSection, ModalActions, ModalContent } from "@msys/ui";
import { DialogContentText, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useLatest } from "react-use";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { ProjectSourcePageName } from "../../../../clients/graphqlTypes";
import { CrmCompanySelect } from "../../crm-companies/CrmCompanySelect";
import {
  CustomFieldsArray,
  CustomFieldsFormValues,
  getCustomFieldsInitialValues,
  getCustomFieldsSubmitValues,
  getCustomFieldsValidationSchema,
} from "../../custom-fields/EditCustomFieldModal";
import { useSetCustomFieldMutation } from "../../custom-fields/EditCustomFieldModal.generated";
import { useCustomFieldConfig } from "../../custom-fields/useCustomFieldConfig";
import { useCreateProjectMutation } from "../Projects.generated";

interface FormValues extends CustomFieldsFormValues {
  title: string;
  crmOrganisationId: string | null;
}

interface Props {
  crmOrganisationId?: string;
  refetchQueries?: string[];
  autoSubmit?: boolean;
  buildingItemId?: string;
  pageName?: ProjectSourcePageName;
  desiredProjectInitialStatus: "opportunity" | "contracted";
  handleClose: () => void;
  handleComplete?: (projectId: string) => Promise<void> | void;
  topInfo?: React.ReactNode;
  text?: string;
  submitButtonLabel: string;
}

export const CreateProjectModalContent = ({
  crmOrganisationId: passedCrmOrgId,
  refetchQueries,
  autoSubmit = true,
  buildingItemId,
  handleClose,
  handleComplete,
  pageName,
  desiredProjectInitialStatus,
  topInfo,
  text,
  submitButtonLabel,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["ProjectCreate", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const {
    customFieldConfig: customFieldConfigs,
    loading,
    error,
  } = useCustomFieldConfig("Project");

  const [createProject, { loading: createProjectLoading }] =
    useCreateProjectMutation({ client });
  const [setCustomField, { loading: setCustomFieldLoading }] =
    useSetCustomFieldMutation({ client });

  const handleSubmit = async (values: FormValues) => {
    const result = await createProject({
      variables: {
        input: {
          title: values.title,
          description: "",
          type: "RENEWAL",
          categories: [],
          crmOrganisationId: viewer.organisation.capabilities.includes(
            "QUOTING"
          )
            ? values.crmOrganisationId
            : undefined,
          buildingItemId: buildingItemId,
          pageName: pageName ?? "CreateProjectModal",
          desiredProjectInitialStatus,
        },
      },
      refetchQueries: refetchQueries,
      awaitRefetchQueries: true,
    });

    if (!result) return;

    const project = result.data!.createProject.project;
    const projectId = project.id;

    await setCustomField({
      variables: {
        input: {
          objectId: projectId,
          objectType: "Project",
          values: getCustomFieldsSubmitValues(
            customFieldConfigs,
            values.customFields
          ),
        },
      },
    });

    handleClose();
    await handleComplete?.(projectId);

    enqueueSnackbar(t("Project created", { ns: "ProjectCreate" }));
  };

  const initialValues: FormValues = {
    title: t("My new project", { ns: "ProjectCreate" }),
    crmOrganisationId: passedCrmOrgId ?? null,
    customFields: getCustomFieldsInitialValues(customFieldConfigs, []),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(t("Project title", { ns: "ProjectCreate" }))
      .required(),
    ...(viewer.organisation.capabilities.includes("QUOTING")
      ? {
          crmOrganisationId: Yup.string()
            .nullable()
            .label(t("CRM contact", { ns: "ProjectCreate" }))
            .required(),
        }
      : undefined),
    customFields: getCustomFieldsValidationSchema(customFieldConfigs),
  });

  const autoSubmitted = React.useRef<boolean>(false);
  const initialSubmit = useLatest(async () => {
    const isAutoSubmitPossible =
      !viewer.organisation.capabilities.includes("QUOTING") ||
      (viewer.organisation.capabilities.includes("QUOTING") && passedCrmOrgId);
    if (isAutoSubmitPossible) {
      await handleSubmit(initialValues);
    }
  });

  // TODO: better handling AutoSubmit
  React.useEffect(() => {
    if (loading || error) return;
    if (autoSubmitted.current) return;
    autoSubmitted.current = true;
    if (autoSubmit) {
      initialSubmit.current();
    }
  }, [error, loading, autoSubmit, initialSubmit]);

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({ values, isSubmitting, setFieldValue, isValid }) => (
        <>
          <ModalContent isLoading={loading}>
            {topInfo}
            <DialogContentText>{text}</DialogContentText>
            <Form id={formId}>
              <Stack width="100%" direction="column" spacing={1}>
                {!autoSubmit && (
                  <Field
                    component={TextField}
                    name="title"
                    label={t("Project title", { ns: "ProjectCreate" })}
                    required
                  />
                )}

                {viewer.organisation.capabilities.includes("QUOTING") && (
                  <CrmCompanySelect
                    crmCompanyId={values.crmOrganisationId}
                    onChange={crmCompany => {
                      setFieldValue(
                        "crmOrganisationId",
                        crmCompany?.id ?? null
                      );
                    }}
                    inputLabel={t("Select from CRM contacts", {
                      ns: "ProjectCreate",
                    })}
                    required
                    createNewLabel={t("Add new contact", {
                      ns: "ProjectCreate",
                    })}
                    canCreateNew
                    disabled={Boolean(passedCrmOrgId)}
                    createModalTitle={t("Create client for project", {
                      ns: "ProjectCreate",
                    })}
                  />
                )}
                {customFieldConfigs.length > 0 && (
                  <CollapseSection
                    title={t("Custom fields", { ns: "ProjectCreate" })}
                    titleVariant="h3"
                    isInitiallyExpanded={false}
                  >
                    <CustomFieldsArray
                      values={values}
                      isSubmitting={isSubmitting}
                      setFieldValue={setFieldValue}
                      customFieldConfigs={customFieldConfigs}
                      objectType="Project"
                      useAutoSave={false}
                    />
                  </CollapseSection>
                )}
              </Stack>
            </Form>
          </ModalContent>
          <ModalActions
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: {
                  variant: "text",
                  disabled:
                    createProjectLoading ||
                    setCustomFieldLoading ||
                    isSubmitting,
                },
              },
              {
                label: submitButtonLabel,
                buttonProps: {
                  type: "submit",
                  form: formId,
                  loading:
                    createProjectLoading ||
                    setCustomFieldLoading ||
                    isSubmitting,
                  disabled: !isValid,
                },
              },
            ]}
          />
        </>
      )}
    </Formik>
  );
};
