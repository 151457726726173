import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { TFunction } from "@tolgee/react";
import { useFormik } from "formik";
import React, { useState } from "react";

interface FormValues {
  columns: Array<string>;
}

interface HeaderAndValues {
  header: string;
  values: string;
}

interface DbColumnMapping {
  label: string;
  column: string;
  disabled: boolean;
}

interface ColumnsMapping {
  headerAndValues: Array<HeaderAndValues>;
  systemLabels: Array<DbColumnMapping>;
}

// Component input properties
type Properties = {
  theme: Theme;
  getCsvHeaders: () => Promise<ColumnsMapping>;
  onComplete: (
    jsonMapping: { [key: string]: string },
    status: string,
    message: string
  ) => void;
  t: TFunction<"Global">;
};

/**
 * @component CsvColumnMapper
 *
 * This component provides the ability to map CSV columns
 * to available database columns for parsing and processing
 * of CSV products data based on provided input URL.
 */
export function CsvColumnMapper(props: Properties) {
  const truncateLength = 25;

  let [headers, setHeaders] = useState<Array<HeaderAndValues>>([]);
  let [systemLabels, setSystemLabels] = useState<Array<DbColumnMapping>>([]);

  // Set values only once
  React.useEffect(() => {
    const fetchData = async () => {
      const headersObj = await props.getCsvHeaders();

      setHeaders(headersObj.headerAndValues);
      setSystemLabels(headersObj.systemLabels);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initializing formik
  const formik = useFormik({
    initialValues: {
      columns: [],
    },
    onSubmit: async values => {
      await performSubmition(values);
    },
  });

  /**
   * This function converts the headers and mapped
   * columns to a key/value object and triggers
   * the callback function with object.
   *
   * @param formValues
   */
  async function performSubmition(formValues: FormValues) {
    const csvColumnMapping: { [key: string]: string } = {};

    const mappedColumns = formValues.columns;

    if (
      mappedColumns.includes("article_number") &&
      (mappedColumns.includes("listprice") ||
        mappedColumns.includes("net_price"))
    ) {
      // iterate over headers and based on index checks
      // prepare key/value object
      headers.forEach((header, index) => {
        if (mappedColumns[index] && mappedColumns[index] !== undefined) {
          csvColumnMapping[header.header] = mappedColumns[index];
        }
      });

      // trigger the callback with mapping object
      props.onComplete(csvColumnMapping, "success", "");
    } else {
      alert(
        "Column mapping is incorrect, articleNumber and list/net prices are required."
      );
    }
  }

  /**
   * This function is responsible for rendering
   * Menu items for choosing system label.
   *
   * @returns
   */
  function renderMenuItems() {
    let menuItems = [];

    menuItems.push(<MenuItem key="none" aria-label="None" value="undefined" />);
    for (const systemLabel of systemLabels) {
      menuItems.push(
        <MenuItem
          disabled={systemLabel.disabled}
          key={systemLabel.column}
          value={systemLabel.column}
          data-pvalue={systemLabel.column}
        >
          {systemLabel.label}
        </MenuItem>
      );
    }

    return menuItems;
  }

  /**
   * This function is responsible for truncating
   * values exceeding given length.
   *
   * @param value
   * @param maxLength
   * @returns
   */
  function truncate(value: string, maxLength: number) {
    return value.length >= maxLength
      ? value.substr(0, maxLength - 1) + "..."
      : value;
  }

  /**
   * This handler is responsible for making the selected
   * value disabled and previous value enabled for all
   * dropdowns.
   *
   * @param event
   */
  const updateOptions = async (
    e: SelectChangeEvent<{ name?: string | undefined; value: unknown }>,
    prevValue: string
  ) => {
    const newValue = e.target.value;

    const newSystemLables = [];
    for (const systemLabel of systemLabels) {
      if (systemLabel.column === newValue) {
        systemLabel.disabled = true;
      }

      if (systemLabel.column === prevValue) {
        systemLabel.disabled = false;
      }

      newSystemLables.push(systemLabel);
    }

    setSystemLabels(newSystemLables);
  };

  /**
   * This function is responsible for rendering the
   * CSV column mapping table body.
   *
   * @returns
   */
  function renderTableBody() {
    return headers.map((header, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          {header.header}
        </TableCell>
        <TableCell title={header.values}>
          {truncate(header.values, truncateLength)}
        </TableCell>
        <TableCell>
          <FormControl fullWidth>
            <InputLabel>{props.t("Choose label")}</InputLabel>
            <Select
              fullWidth
              name={`columns[${index}]`}
              value={formik.values.columns[index] || ""}
              onChange={e => {
                formik.handleChange(e);
                updateOptions(e, formik.values.columns[index]);
              }}
            >
              {renderMenuItems()}
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <ThemeProvider theme={props.theme}>
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <Typography variant="h3" gutterBottom align="center">
          {props.t("Map columns")}
        </Typography>
        <Typography gutterBottom align="center">
          {props.t(
            "Help us match the columns from your file with labels in MeisterSystems so we can display the information correctly."
          )}
        </Typography>
        <TableContainer component={Paper}>
          <Table
            size="small"
            stickyHeader
            aria-label={props.t("Column mapping table")}
          >
            <TableHead>
              <TableRow>
                <TableCell>{props.t("File column header:")}</TableCell>
                <TableCell>{props.t("Column value:")}</TableCell>
                <TableCell>{props.t("System label:")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button color="primary" variant="contained" type="submit">
            {props.t("Submit")}
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
}
