import React from "react";
import { Stack } from "../../commons/layout/Stack";
import { ellipsisStyle } from "@msys/ui";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { IconButton, Link, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Attachment } from "./helpers";

interface Props {
  attachment: Attachment;
  onRemove: (attachment: Attachment) => Promise<void>;
  icon?: JSX.Element;
  disabled?: boolean;
}

export const AttachmentFileRow = ({
  attachment,
  onRemove,
  icon = <AttachmentIcon fontSize="small" />,
  disabled = false,
}: Props) => (
  <Stack
    key={attachment.id}
    justifyContent="space-between"
    alignItems="center"
    width="100%"
  >
    <Stack alignItems="center" style={ellipsisStyle}>
      {icon}
      <Typography variant="body2" style={ellipsisStyle}>
        <Link href={attachment.url} target="_blank" rel="nofollow noreferrer">
          {attachment.title}
        </Link>
      </Typography>
    </Stack>
    <IconButton
      disabled={disabled}
      color="secondary"
      aria-label="Delete attachment"
      onClick={() => onRemove(attachment)}
      size="small"
    >
      <DeleteIcon />
    </IconButton>
  </Stack>
);
