import { useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { THREED_PLANNER_SHOW } from "../../../environment";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { useUserData } from "../../auth/useUserData";
import { EntityNumber } from "../../commons/EntityNumber";
import {
  LayoutView,
  ToggleLayoutViewButton,
  ToggleLayoutViewButtonGroup,
} from "../../commons/button/ToggleLayoutViewButtonGroup";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../commons/layout/PageSubHeader";
import { useHeaderBox_ModifyItemInfoMutation } from "../doc-items/boxes/HeaderBox.generated";
import { RequirementMenu } from "./RequirementMenu";
import { RequirementStatusChip } from "./RequirementStatusBadge";
import {
  RequirementSubHeader_ProjectFragment,
  RequirementSubHeader_RequirementFragment,
} from "./RequirementSubHeader.generated";
import { DownloadRequirementAsPdfButton } from "./buttons/DownloadRequirementAsPdfIconButton";

interface Props {
  project: RequirementSubHeader_ProjectFragment;
  requirement: RequirementSubHeader_RequirementFragment;
  activeView: LayoutView;
  canEdit: boolean;
  pathToDoc: string;
  pathToDocPage: string;
  pathToDocList: string;
  onUpdateDataRefetchQueries?: string[];
}

export const RequirementSubHeader = ({
  activeView,
  canEdit,
  requirement,
  project,
  pathToDoc,
  pathToDocPage,
  pathToDocList,
  onUpdateDataRefetchQueries,
  isHeaderVisible,
  setHeaderVisible,
}: Props & PageSubHeaderInjectedProps) => {
  const navigate = useNavigate();
  const viewer = useUserData().currentUser!;

  const { t } = useTranslate(["Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyItemInfo] = useHeaderBox_ModifyItemInfoMutation({
    client,
    refetchQueries: onUpdateDataRefetchQueries,
    awaitRefetchQueries: true,
  });
  const handleTitleChange = async (title: string) => {
    await modifyItemInfo({
      variables: {
        input: {
          itemId: requirement.rootItem.id,
          docId: requirement.id,
          projectId: project.id,
          values: {
            title,
          },
        },
      },
    });
  };

  return (
    <PageSubHeader
      title={requirement.title}
      hideTitleOnMobile
      handleTitleChange={
        canEdit
          ? async newValue => {
              await handleTitleChange(newValue);
              enqueueSnackbar(
                t("Title updated", {
                  ns: "Global",
                })
              );
            }
          : undefined
      }
      titleEditTooltip={
        canEdit
          ? t("Rename", {
              ns: "Global",
            })
          : undefined
      }
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
      status={<RequirementStatusChip requirement={requirement} />}
      entityNumber={<EntityNumber number={requirement.number} />}
      actionMenu={
        <RequirementMenu
          project={project}
          requirement={requirement}
          pathToDocList={pathToDocList}
        />
      }
      actionButtons={
        <DownloadRequirementAsPdfButton
          projectId={project.id}
          requirementId={requirement.id}
        />
      }
      rightButtons={
        <ToggleLayoutViewButtonGroup
          activeView={activeView}
          setActiveView={view =>
            navigate(view === "tree" ? pathToDoc : `${pathToDoc}/${view}`)
          }
        >
          <RestrictedByDocumentPermissionWithDebug
            permission="MANAGE_REQUIREMENTS"
            document={requirement}
          >
            {requirement.owningSystemOrganisationId ===
              viewer.organisation.id && (
              <ToggleLayoutViewButton viewType="tree" />
            )}
          </RestrictedByDocumentPermissionWithDebug>
          <RestrictedByDocumentPermissionWithDebug
            permission="READ_REQUIREMENTS"
            document={requirement}
          >
            <ToggleLayoutViewButton viewType="preview" />
          </RestrictedByDocumentPermissionWithDebug>
          {THREED_PLANNER_SHOW === "true" && (
            <RestrictedByDocumentPermissionWithDebug
              permission="READ_REQUIREMENTS"
              document={requirement}
            >
              <ToggleLayoutViewButton viewType="3d" />
            </RestrictedByDocumentPermissionWithDebug>
          )}
        </ToggleLayoutViewButtonGroup>
      }
    />
  );
};
