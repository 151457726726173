/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiDescriptionModal_ItemQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
}>;


export type AiDescriptionModal_ItemQuery = { item: { __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, description: string } | { __typename: 'MissingPermissions' } };

export type AiDescriptionModal_AiQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
  locale: Types.Locale;
}>;


export type AiDescriptionModal_AiQuery = { aiDescription: { __typename: 'AiDescriptionResult', title: string, description: string } };


export const AiDescriptionModal_ItemDocument = gql`
    query AiDescriptionModal_Item($projectId: ID, $docId: ID!, $itemId: ID!) {
  item(projectId: $projectId, docId: $docId, itemId: $itemId) {
    ... on Item {
      id
      originVersionNumber
      title
      description
    }
  }
}
    `;

/**
 * __useAiDescriptionModal_ItemQuery__
 *
 * To run a query within a React component, call `useAiDescriptionModal_ItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiDescriptionModal_ItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiDescriptionModal_ItemQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useAiDescriptionModal_ItemQuery(baseOptions: Apollo.QueryHookOptions<AiDescriptionModal_ItemQuery, AiDescriptionModal_ItemQueryVariables> & ({ variables: AiDescriptionModal_ItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiDescriptionModal_ItemQuery, AiDescriptionModal_ItemQueryVariables>(AiDescriptionModal_ItemDocument, options);
      }
export function useAiDescriptionModal_ItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiDescriptionModal_ItemQuery, AiDescriptionModal_ItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiDescriptionModal_ItemQuery, AiDescriptionModal_ItemQueryVariables>(AiDescriptionModal_ItemDocument, options);
        }
export function useAiDescriptionModal_ItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AiDescriptionModal_ItemQuery, AiDescriptionModal_ItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AiDescriptionModal_ItemQuery, AiDescriptionModal_ItemQueryVariables>(AiDescriptionModal_ItemDocument, options);
        }
export type AiDescriptionModal_ItemQueryHookResult = ReturnType<typeof useAiDescriptionModal_ItemQuery>;
export type AiDescriptionModal_ItemLazyQueryHookResult = ReturnType<typeof useAiDescriptionModal_ItemLazyQuery>;
export type AiDescriptionModal_ItemSuspenseQueryHookResult = ReturnType<typeof useAiDescriptionModal_ItemSuspenseQuery>;
export type AiDescriptionModal_ItemQueryResult = Apollo.QueryResult<AiDescriptionModal_ItemQuery, AiDescriptionModal_ItemQueryVariables>;
export const AiDescriptionModal_AiDocument = gql`
    query AiDescriptionModal_AI($projectId: ID, $docId: ID!, $itemId: ID!, $locale: Locale!) {
  aiDescription(
    projectId: $projectId
    docId: $docId
    itemId: $itemId
    locale: $locale
  ) {
    title
    description
  }
}
    `;

/**
 * __useAiDescriptionModal_AiQuery__
 *
 * To run a query within a React component, call `useAiDescriptionModal_AiQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiDescriptionModal_AiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiDescriptionModal_AiQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAiDescriptionModal_AiQuery(baseOptions: Apollo.QueryHookOptions<AiDescriptionModal_AiQuery, AiDescriptionModal_AiQueryVariables> & ({ variables: AiDescriptionModal_AiQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiDescriptionModal_AiQuery, AiDescriptionModal_AiQueryVariables>(AiDescriptionModal_AiDocument, options);
      }
export function useAiDescriptionModal_AiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiDescriptionModal_AiQuery, AiDescriptionModal_AiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiDescriptionModal_AiQuery, AiDescriptionModal_AiQueryVariables>(AiDescriptionModal_AiDocument, options);
        }
export function useAiDescriptionModal_AiSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AiDescriptionModal_AiQuery, AiDescriptionModal_AiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AiDescriptionModal_AiQuery, AiDescriptionModal_AiQueryVariables>(AiDescriptionModal_AiDocument, options);
        }
export type AiDescriptionModal_AiQueryHookResult = ReturnType<typeof useAiDescriptionModal_AiQuery>;
export type AiDescriptionModal_AiLazyQueryHookResult = ReturnType<typeof useAiDescriptionModal_AiLazyQuery>;
export type AiDescriptionModal_AiSuspenseQueryHookResult = ReturnType<typeof useAiDescriptionModal_AiSuspenseQuery>;
export type AiDescriptionModal_AiQueryResult = Apollo.QueryResult<AiDescriptionModal_AiQuery, AiDescriptionModal_AiQueryVariables>;