import { styled } from "@mui/material/styles";
import React from "react";
import { ReactComponent as SelectedIndicator } from "../../assets/selected-indicator.svg";
import { media } from "../../../common/MuiThemeProvider";

export const TreeSelectedIndicator = () => (
  <IndicatorWrapper>
    <SelectedIndicator />
  </IndicatorWrapper>
);

const IndicatorWrapper = styled("div")(`
  @media (${media.maxPhone}) {
    display: none;
  }
  @media (${media.minTablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;

    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
`);
