import { gql } from "@apollo/client";
import React from "react";
import { Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import { getPersonLabel } from "../../users/helpers";
import { ProjectSourceCreatedBy_ProjectSourceFragment } from "./ProjectSourceCreatedBy.generated";

interface Props {
  projectSource: ProjectSourceCreatedBy_ProjectSourceFragment;
}

export const ProjectSourceCreatedBy = ({ projectSource }: Props) => {
  if (projectSource.createdByUserId)
    return (
      <MuiLink
        component={Link}
        to={`/organisation/users/${projectSource.createdByUserId}`}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {getPersonLabel(
          {
            firstname: projectSource.userFirstname ?? "",
            familyname: projectSource.userFamilyname ?? "",
          },
          false
        )}
      </MuiLink>
    );
  if (projectSource.createdByCrmOrganisationId)
    return (
      <MuiLink
        component={Link}
        to={`/crm/organisations/${projectSource.createdByCrmOrganisationId}`}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {projectSource.crmOrganisationTitle ?? ""}
      </MuiLink>
    );
  return <>{"–"}</>;
};
