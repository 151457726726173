/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type RequestPublishButtonFragment = { __typename: 'Request', id: string, marketplace: boolean, viewerNumActiveRecipients: number };

export const RequestPublishButtonFragmentDoc = gql`
    fragment RequestPublishButton on Request {
  id
  marketplace
  viewerNumActiveRecipients
}
    `;