/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PurchaseOrderListItemFragmentDoc } from '../../features/purchase-orders/PurchaseOrderListItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PurchaseOrdersQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type PurchaseOrdersQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, orders: Array<{ __typename: 'Order', id: string, number: string, orderedDate?: any | null, createdAt: any, supplier?: { __typename: 'Company', id: string, title: string } | null, calculationOrdered: { __typename: 'OrderCalculationOrdered', id?: string | null, priceTotalOrdered: number }, calculationPrepared: { __typename: 'OrderCalculationPrepared', id?: string | null, priceTotalOrdered: number } }> } | null } };

export type PurchaseOrders__OrderFragment = { __typename: 'Order', id: string, number: string, orderedDate?: any | null, createdAt: any, supplier?: { __typename: 'Company', id: string, title: string } | null, calculationOrdered: { __typename: 'OrderCalculationOrdered', id?: string | null, priceTotalOrdered: number }, calculationPrepared: { __typename: 'OrderCalculationPrepared', id?: string | null, priceTotalOrdered: number } };

export const PurchaseOrders__OrderFragmentDoc = gql`
    fragment PurchaseOrders__Order on Order {
  id
  number
  supplier {
    id
    title
  }
  orderedDate
  createdAt
  calculationOrdered {
    id
    priceTotalOrdered
  }
  calculationPrepared {
    id
    priceTotalOrdered
  }
}
    `;
export const PurchaseOrdersDocument = gql`
    query PurchaseOrders($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        orders {
          id
          ...PurchaseOrders__Order
          ...PurchaseOrderListItem
        }
      }
    }
  }
}
    ${PurchaseOrders__OrderFragmentDoc}
${PurchaseOrderListItemFragmentDoc}`;

/**
 * __usePurchaseOrdersQuery__
 *
 * To run a query within a React component, call `usePurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrdersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePurchaseOrdersQuery(baseOptions: Apollo.QueryHookOptions<PurchaseOrdersQuery, PurchaseOrdersQueryVariables> & ({ variables: PurchaseOrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>(PurchaseOrdersDocument, options);
      }
export function usePurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>(PurchaseOrdersDocument, options);
        }
export function usePurchaseOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>(PurchaseOrdersDocument, options);
        }
export type PurchaseOrdersQueryHookResult = ReturnType<typeof usePurchaseOrdersQuery>;
export type PurchaseOrdersLazyQueryHookResult = ReturnType<typeof usePurchaseOrdersLazyQuery>;
export type PurchaseOrdersSuspenseQueryHookResult = ReturnType<typeof usePurchaseOrdersSuspenseQuery>;
export type PurchaseOrdersQueryResult = Apollo.QueryResult<PurchaseOrdersQuery, PurchaseOrdersQueryVariables>;