import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Box, Divider } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { flatten, noop } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { DurationDailyField } from "../../../commons/form-fields/DurationDailyField";
import { TreeListItem } from "../../../trees/components/TreeListItem";
import {
  IconButtonFactory,
  TaskListItem,
  createTreeSimpleItem,
} from "../trees";
import {
  TasksTimesheetTasksForm_ItemFragment,
  useCorrectWorkSession_TasksFormMutation,
  useTasksTimesheetTasksFormQuery,
} from "./TasksTimesheetTasksForm.generated";

interface FormValues {
  duration: { [key: string]: number };
}

interface Props {
  loading?: boolean;
  projectId: string;
  assigneeId: string;
  selectedItemId?: string;
  date: moment.Moment;
  tasksItems: TasksTimesheetTasksForm_ItemFragment[];
}

export const TasksTimesheetTasksForm = ({
  loading = false,
  projectId,
  assigneeId,
  selectedItemId,
  date,
  tasksItems,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate("Global");

  const client = useApolloClient();
  const query = useTasksTimesheetTasksFormQuery({
    client,
    variables: { projectId, assigneeId, date: date.format("YYYY-MM-DD") },
    fetchPolicy: "network-only",
  });

  const [correctWorkSession] = useCorrectWorkSession_TasksFormMutation({
    client,
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const tasks = project?.tasks;

  const TreeItem = React.useMemo(
    () =>
      createTreeSimpleItem({
        pathToDocPage: null,
        docAgreement: "YES",
        navigateToItem: noop,
        setItemExpanded: noop,
        getIconRightButton: getTimeInput(loading, t("Time")),
      }),
    [loading, t]
  );

  const workSessions = flatten(
    tasks?.map(tasksDoc => tasksDoc.workSessions) ?? []
  );

  const initialValues: FormValues = useMemo(
    () => ({
      duration: workSessions.reduce(
        (acc, s) => {
          const d = s.duration
            ? s.duration
            : moment(s.till).diff(s.from, "minutes");
          acc[s.itemId!] = (acc[s.itemId!] || 0) + d;
          return acc;
        },
        {} as { [key: string]: number }
      ),
    }),
    [workSessions]
  );

  const onSubmit = async (values: FormValues) => {
    const changedItemIds = Object.keys(values.duration).filter(itemId => {
      const oldValue = initialValues.duration[itemId] || 0;
      const newValue = values.duration[itemId] || 0;
      return oldValue !== newValue;
    });
    if (!changedItemIds.length) return;
    try {
      await Promise.all(
        changedItemIds.map(itemId => {
          return correctWorkSession({
            variables: {
              input: {
                itemId,
                assigneeId,
                date: date.format("YYYY-MM-DD"),
                duration: values.duration[itemId] || 0,
              },
              assigneeId,
              date: date.format("YYYY-MM-DD"),
            },
          });
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const enhancedTasksItems = tasksItems.map(item => ({
    ...item,
    docViewerPermissions:
      tasks?.find(task => task.id === item.docId)?.viewerPermissions ?? [],
    docAgreement:
      tasks?.find(task => task.id === item.docId)?.agreement ?? "NONE",
  }));
  const filteredTasksItems = enhancedTasksItems.filter(
    i => i.timeTrackingRequired
  );
  const validationSchema = Yup.object().shape({
    duration: Yup.object(),
  });

  return filteredTasksItems.length > 0 ? (
    <>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <Box m={-1} mb={0}>
            <Divider sx={{ marginTop: 2 }} />
            {filteredTasksItems.map(item => (
              <TreeListItem
                p={0}
                key={item.id}
                component={Link}
                // @ts-ignore compound component
                to={`/projects/${projectId}/tasks/edit/${item.docId}/items/${item.id}`}
                $canBeFirst={false}
                $canBeLast={false}
                selected={selectedItemId === item.id}
              >
                <TaskListItem
                  item={item}
                  allDocItems={enhancedTasksItems}
                  itemComponent={TreeItem}
                />
              </TreeListItem>
            ))}
          </Box>
          <Divider sx={{ marginLeft: 2, marginRight: 2 }} />
          <AutoSave enableReinitialize initialValues={initialValues} />
        </Form>
      </Formik>
    </>
  ) : (
    <Divider />
  );
};

const getTimeInput =
  (
    disabled: boolean = false,
    label: string = "Time"
  ): IconButtonFactory<{ id: string }> =>
  (item: { id: string }) => {
    return (
      <div
        onClick={e => {
          e.preventDefault();
        }}
        style={{ marginLeft: "8px", position: "relative" }}
      >
        <DurationDailyField
          style={{ width: 82 }}
          disabled={disabled}
          name={`duration.${item.id}`}
          label={label}
        />
      </div>
    );
  };
