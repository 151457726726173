import { useLocalStorageAsState } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam";
import { PimSpecificFieldQueryFieldField } from "../../../../clients/graphqlTypes";

export type ProductSearchType =
  | PimSpecificFieldQueryFieldField
  | "fulltextSearch";
export type ProductSearchTypeOption = {
  label: string;
  value: ProductSearchType;
};

export const useProductSearchType = () => {
  const { t } = useTranslate(["ProductSearch", "Product"]);

  const productSearchTypeOptions: ProductSearchTypeOption[] = [
    {
      label: t("Full-Text Search", {
        ns: "ProductSearch",
      }),
      value: "fulltextSearch",
    },
    {
      label: t("Article Number", {
        ns: "Product",
      }),
      value: "articleNumber",
    },
    // {
    //   label: ("ProductSearch::Gtin"),
    //   value: "gtin",
    // },
    // {
    //   label: ("ProductSearch::matchCode"),
    //   value: "matchCode",
    // },
    {
      label: t("Title", {
        ns: "Product",
      }),
      value: "titleText",
    },
    {
      label: t("Description", {
        ns: "Product",
      }),
      value: "descriptionTextAndExtendedDescriptionText",
    },
  ];

  const [productSearchType, setProductSearchType] =
    useLocalStorageAsState<ProductSearchType>(
      "msys-product-search-type",
      "fulltextSearch"
    );

  return {
    productSearchTypeOptions,
    productSearchType,
    setProductSearchType,
  };
};

export function useProductSearchTypeWithUrlParam({
  urlParamName,
  toRemoveParams,
}: {
  urlParamName: string;
  toRemoveParams: string[];
}) {
  const { productSearchTypeOptions, productSearchType, setProductSearchType } =
    useProductSearchType();

  const [productSearchTypeFromUrl, setProductSearchTypeInUrl] =
    useStateWithUrlParam<ProductSearchType | undefined>(
      urlParamName,
      undefined,
      toRemoveParams
    );

  const searchType = productSearchTypeFromUrl ?? productSearchType;
  const setSearchType = React.useCallback(
    (value: ProductSearchType) => {
      setProductSearchType(value);
      setProductSearchTypeInUrl(value);
    },
    [setProductSearchType, setProductSearchTypeInUrl]
  );

  return {
    productSearchTypeOptions,
    productSearchType: searchType,
    setProductSearchType: setSearchType,
  };
}
