import { Box, Stack } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
}

export function KanbanContainer({ children }: Props) {
  return (
    <Stack
      flexBasis={0}
      flexGrow={1}
      overflow={"auto"}
      height={"100%"}
      paddingBottom={2}
      paddingX={2}
      direction="row"
      spacing={2}
    >
      {children}
    </Stack>
  );
}
