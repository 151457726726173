import { gql, useApolloClient } from "@apollo/client";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDataOrNull } from "@msys/common";
import {
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
  renderMenuWithTheme,
  useScreenWidth,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { Box, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { To, useLocation, useNavigate } from "react-router-dom";
import { useEffectOnce, useLockBodyScroll, useUpdateEffect } from "react-use";
import { ButtonCreate } from "../../commons/button/Button";
import { ButtonCircleWrapper } from "../../commons/button/ButtonCircleWrapper";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam";
import { getUrlSearchParamsByParamsName } from "../../commons/hooks/useStateWithUrlParams";
import { LayoutWithLeftColumn } from "../../commons/layout/LayoutWithLeftColumn";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants";
import { SectionTab } from "../../features/doc-items/SearchProductsAndTemplates";
import { OrganisationSupplierProductFavouriteButton } from "../../features/products/buttons/OrganisationSupplierProductFavouriteButton";
import { ProductsListView } from "../../features/products/components/ProductsListView";
import {
  isAnyFilterSet,
  ProductFiltersFormValues,
} from "../../features/products/filters/ProductFilterFields";
import { productFiltersDefaultValue } from "../../features/products/filters/ProductFilters";
import { useProductSearchTypeWithUrlParam } from "../../features/products/filters/useProductSearchTypes";
import { useCanCreateSupplierCatalogueProduct } from "../../features/products/helper";
import { Products3dDistributionModal } from "../../features/products/modals/Products3dDistributionModal";
import { ProductTypePopularPropertyFiltersEditModal } from "../../features/products/modals/ProductTypePopularPropertyFiltersEditModal";
import { SupplierCatalogueProductCreateModal } from "../../features/products/modals/SupplierCatalogueProductCreateModal";
import { ProductSearchItem__ProductSearchResultFragment } from "../../features/products/Product.generated";
import { ProductCategoryTreeChip } from "../../features/products/ProductCategoryTreeChip";
import {
  ProductCategoryTreeSelect,
  useProductCategoryTree,
} from "../../features/products/ProductCategoryTreeSelect";
import { ProductSearchLandingPage } from "../../features/products/ProductSearchLandingPage";
import {
  ChildrenFn,
  ProductSearchListHeader,
} from "../../features/products/ProductSearchListHeader";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  PimSearchGroupedProductsNodeFragment,
  useOrganisationProductsTableQuery,
} from "./ProductsCatalogues.generated";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  submenuItems: PageTopbarItem[];
}

export const ProductsCatalogues = ({ submenuItems }: Props) => {
  const { t } = useTranslate([
    "Product",
    "Global",
    "OrganisationPageTopbar",
    "ProductSearch",
  ]);

  const { isMinTablet, isMinDesktop, isMinLargeDesktop } = useScreenWidth();

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    toRemoveParams,
  } = useFiltersAndPagination<{}, ProductFiltersFormValues>(
    productFiltersDefaultValue,
    [],
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const { productSearchTypeOptions, productSearchType, setProductSearchType } =
    useProductSearchTypeWithUrlParam({
      urlParamName: "searchType",
      toRemoveParams,
    });

  const [searchTerm, setSearchTerm] = useStateWithUrlParam<string>(
    "searchTerm",
    "",
    toRemoveParams
  );

  const [section, setSection] = useStateWithUrlParam<SectionTab>(
    "section",
    "landing",
    null,
    { replace: false }
  );

  // automatically switching to results when needed
  useEffectOnce(() => {
    if (Boolean(searchTerm) || isAnyFilterSet(filters)) {
      setSection("results");
    }
  });
  useUpdateEffect(() => {
    if (
      (Boolean(searchTerm) || isAnyFilterSet(filters)) &&
      section === "landing"
    )
      setSection("results");
  }, [searchTerm, filters]);

  const productSearchVariables = {
    searchTerm: productSearchType === "fulltextSearch" ? searchTerm : undefined,
    specificFieldQueryFields:
      productSearchType !== "fulltextSearch"
        ? [{ field: productSearchType, value: searchTerm }]
        : undefined,
    filters,
  };

  const client = useApolloClient();
  const query = useOrganisationProductsTableQuery({
    client,
    variables: {
      ...productSearchVariables,
      offset,
      limit,
    },
    skip: section !== "results",
  });
  const pimSearchGroupedProducts =
    getDataOrNull(
      (query.data ?? query.previousData)?.pimSearchGroupedProducts
    )?.edges.map(edge => edge.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchGroupedProducts)
      ?.totalCount ?? 0;

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "products-list",
    "list",
    "list"
  );

  const getProductTo = useGetProductTo();
  const getProductSearchFiltersTo = (
    filters: Partial<ProductFiltersFormValues>
  ): To => ({
    pathname: `/products/catalogues`,
    search: (() => {
      const u = getUrlSearchParamsByParamsName(
        { ...productFiltersDefaultValue, ...filters },
        "filters"
      );
      u.set("section", "results");
      return u.toString();
    })(),
  });

  const [searchEnabled, setSearchEnabled] = React.useState<boolean>(false);
  useLockBodyScroll(searchEnabled);

  const handleBackClick =
    section === "results"
      ? () => {
          resetFilters();
          setSection("landing");
        }
      : undefined;

  const handleSearchSubmit = () => {
    setTimeout(() => {
      if (section === "landing") setSection("results");
    });
  };

  const productCategoryProps = useProductCategoryTree(
    filters.supplierIds?.[0] ?? null,
    filters.supplierProductCategoryKey ?? null
  );

  const showCategoriesSelect = Boolean(
    productCategoryProps.supplier &&
      (productCategoryProps.childCategories.length ||
        productCategoryProps.productCategory)
  );

  const handleCategoryTreeSelect = ({
    supplierId,
    supplierProductCategoryKey,
  }: {
    supplierProductCategoryKey?: string | null;
    supplierId?: string | null;
  }) => {
    const newFilters = { ...filters };
    if (supplierId === null) {
      newFilters.supplierIds = null;
    }
    if (supplierProductCategoryKey !== undefined) {
      newFilters.supplierProductCategoryKey = supplierProductCategoryKey;
    }
    setFilters(newFilters);
  };

  return (
    <Page
      title={t("Products", { ns: "OrganisationPageTopbar" })}
      submenuItems={submenuItems}
      topbarItems={
        !isMinTablet ? (
          <OrganisationProductsActionMenu type="menu" />
        ) : undefined
      }
    >
      <PageContainer $fullHeight={section === "results"}>
        <OrganisationProductsListHeader
          filters={filters}
          setFilters={setFilters}
          resetFilters={resetFilters}
          hideFilters={section !== "results"}
          productSearchTypeOptions={productSearchTypeOptions}
          productSearchType={productSearchType}
          setProductSearchType={setProductSearchType}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeView={activeView}
          setActiveView={setActiveView}
          productSearchVariables={productSearchVariables}
          searchEnabled={searchEnabled}
          setSearchEnabled={setSearchEnabled}
          handleBackClick={handleBackClick}
          handleSearchSubmit={handleSearchSubmit}
          AdditionalChips={
            !isMinTablet &&
            showCategoriesSelect &&
            productCategoryProps.supplier ? (
              <ProductCategoryTreeChip
                supplier={productCategoryProps.supplier}
                productCategory={productCategoryProps.productCategory}
                handleSelect={handleCategoryTreeSelect}
              />
            ) : undefined
          }
        >
          {({ SearchLine, FiltersLine, ChipsLine, SearchBackdrop }) => (
            <Stack
              direction={"column"}
              spacing={1}
              flex={1}
              overflow={section === "results" ? "hidden" : "initial"}
            >
              <Box>{SearchLine}</Box>
              {SearchBackdrop}
              {section === "results" ? (
                <LayoutWithLeftColumn
                  name="ProductsCatalogues"
                  leftColumn={
                    showCategoriesSelect ? (
                      <ProductCategoryTreeSelect
                        {...productCategoryProps}
                        categoryKey={filters.supplierProductCategoryKey ?? null}
                        onSelect={handleCategoryTreeSelect}
                      />
                    ) : undefined
                  }
                  leftColumnWidth={
                    isMinLargeDesktop ? "sm" : isMinDesktop ? "xs" : "xxs"
                  }
                  spacing={1}
                >
                  <Stack
                    direction="column"
                    width="100%"
                    height="100%"
                    spacing={1}
                    minHeight={0}
                    minWidth={0}
                  >
                    {FiltersLine}
                    {ChipsLine}
                    <ProductsList
                      items={pimSearchGroupedProducts}
                      totalCount={totalCount}
                      activeView={activeView}
                      loading={query.loading}
                      paginationModel={paginationModel}
                      setPaginationModel={setPaginationModel}
                    />
                  </Stack>
                </LayoutWithLeftColumn>
              ) : section === "landing" ? (
                <ProductSearchLandingPage
                  getProductTo={getProductTo}
                  getProductSearchFiltersTo={getProductSearchFiltersTo}
                />
              ) : null}
            </Stack>
          )}
        </OrganisationProductsListHeader>
      </PageContainer>
    </Page>
  );
};

function OrganisationProductsActionMenu({
  type,
}: {
  type: "buttons" | "menu";
}) {
  const navigate = useNavigate();

  const { t } = useTranslate(["Global", "ProductSearch"]);

  const { canCreateSupplierCatalogueProduct } =
    useCanCreateSupplierCatalogueProduct();

  switch (type) {
    case "buttons": {
      return (
        <Stack direction={"row"} spacing={1} alignItems="center" height="30px">
          <ModalOpenButton Modal={Products3dDistributionModal}>
            <IconButton size="small" color="primary">
              <Tooltip
                title={t("Product 3D models uploading", {
                  ns: "ProductSearch",
                })}
              >
                <Icon>
                  <FontAwesomeIcon icon={faArrowAltCircleUp} />
                </Icon>
              </Tooltip>
            </IconButton>
          </ModalOpenButton>
          <ModalOpenButton
            Modal={ProductTypePopularPropertyFiltersEditModal}
            modalProps={{}}
          >
            <IconButton size="small" color="primary">
              <Tooltip
                title={t("Set popular filters", { ns: "ProductSearch" })}
              >
                <LowPriorityIcon />
              </Tooltip>
            </IconButton>
          </ModalOpenButton>
          {canCreateSupplierCatalogueProduct && (
            <ModalOpenButton
              Modal={SupplierCatalogueProductCreateModal}
              modalProps={{
                handleComplete: async (values, handleClose) => {
                  handleClose();
                  navigate(
                    `/products/catalogues/supplier/${
                      values.supplierId
                    }/${encodeURIComponent(values.articleNumber)}/overview`
                  );
                },
              }}
            >
              <ButtonCreate
                title={t("New catalogue product", {
                  ns: "ProductSearch",
                })}
                compact
              />
            </ModalOpenButton>
          )}
        </Stack>
      );
    }

    case "menu": {
      return (
        <MenuButton
          renderMenu={renderMenuWithTheme}
          buttonProps={{ color: "inherit" }}
        >
          {canCreateSupplierCatalogueProduct && (
            <ModalOpenButton
              Modal={SupplierCatalogueProductCreateModal}
              modalProps={{
                handleComplete: async (values, handleClose) => {
                  handleClose();
                  navigate(
                    `/products/catalogues/supplier/${
                      values.supplierId
                    }/${encodeURIComponent(values.articleNumber)}/overview`
                  );
                },
              }}
            >
              <MenuItemWithIcon icon={<AddIcon />}>
                {t("New catalogue product", {
                  ns: "ProductSearch",
                })}
              </MenuItemWithIcon>
            </ModalOpenButton>
          )}
          <ModalOpenButton Modal={Products3dDistributionModal}>
            <MenuItemWithIcon
              icon={
                <Icon>
                  <FontAwesomeIcon icon={faArrowAltCircleUp} />
                </Icon>
              }
            >
              {t("Product 3D models uploading", { ns: "ProductSearch" })}
            </MenuItemWithIcon>
          </ModalOpenButton>
          <ModalOpenButton
            Modal={ProductTypePopularPropertyFiltersEditModal}
            modalProps={{}}
          >
            <MenuItemWithIcon icon={<LowPriorityIcon />}>
              {t("Set popular filters", { ns: "ProductSearch" })}
            </MenuItemWithIcon>
          </ModalOpenButton>
        </MenuButton>
      );
    }
  }
}

function OrganisationProductsListHeader({
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ProductSearchListHeader>,
  "ActionButtons" | "allowedViews" | "children"
> & { children: ChildrenFn }) {
  const { isMinTablet } = useScreenWidth();
  const ActionButtons = isMinTablet ? (
    <OrganisationProductsActionMenu type="buttons" />
  ) : undefined;
  return (
    <ProductSearchListHeader
      {...props}
      children={children}
      allowedViews={ALLOWED_VIEWS}
      ActionButtons={ActionButtons}
    />
  );
}

function useGetProductTo() {
  const location = useLocation();

  return React.useCallback(
    (item: ProductSearchItem__ProductSearchResultFragment): To => {
      return {
        pathname: `/products/catalogues/supplier/${
          item.supplierId
        }/${encodeURIComponent(item.articleNumber)}/overview`,
        search: new URLSearchParams({
          r: location.pathname + location.search,
        }).toString(),
      };
    },
    [location]
  );
}

function ProductsList({
  items,
  totalCount,
  activeView,
  loading,
  paginationModel,
  setPaginationModel,
}: {
  items: PimSearchGroupedProductsNodeFragment[];
  totalCount: number;
  activeView: CollectionView;
  loading: boolean;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
}) {
  const stateStore = useDataGridStateStore("ProductsCatalogues");
  const getProductTo = useGetProductTo();
  const ActionButton = React.useCallback(
    ({
      product,
    }: {
      product: PimSearchGroupedProductsNodeFragment["product"];
    }) => (
      <ButtonCircleWrapper>
        <OrganisationSupplierProductFavouriteButton
          product={product}
          canEdit={true}
        />
      </ButtonCircleWrapper>
    ),
    []
  );
  return (
    <ProductsListView
      items={items.map(i => i.product)}
      totalCount={totalCount}
      activeView={activeView}
      loading={loading}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      CardActionButton={ActionButton}
      TableActions={ActionButton}
      stateStore={stateStore}
      tableActionsWidth={50}
      getProductTo={getProductTo}
    />
  );
}
