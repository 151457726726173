import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useState } from "react";
import {
  useDocShareBox_CancelPendingShareDocMutation,
  useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery,
  useDocShareBox_Quote_SharingQuery,
  useDocShareBox_Requirement_SharingQuery,
  useDocShareBox_ShareDocMutation,
  useDocShareBox_UnshareDocMutation,
} from "./DocShareBox.generated";

export function DocShareBox(props: {
  docType: "REQUIREMENT" | "QUOTE";
  docId: string;
  projectId: string;
}) {
  const client = useApolloClient();

  const requirementDocSharingQuery = useDocShareBox_Requirement_SharingQuery({
    client,
    variables: {
      docId: props.docId,
      projectId: props.projectId,
    },
    skip: props.docType === "QUOTE",
  });

  const quoteDocSharingQuery = useDocShareBox_Quote_SharingQuery({
    client,
    variables: {
      docId: props.docId,
      projectId: props.projectId,
    },
    skip: props.docType === "REQUIREMENT",
  });

  const requirement = getDataOrNull(
    requirementDocSharingQuery.data?.requirement
  )?.requirement;
  const quote = getDataOrNull(quoteDocSharingQuery.data?.quote)?.quote;

  const docShares = requirement?.docShares ?? quote?.docShares ?? [];
  const pendingDocShares =
    requirement?.pendingDocShares ?? quote?.pendingDocShares ?? [];

  const [shareDoc] = useDocShareBox_ShareDocMutation({ client });
  const [unshareDoc] = useDocShareBox_UnshareDocMutation({ client });
  const [cancelPendingShareDoc] = useDocShareBox_CancelPendingShareDocMutation({
    client,
  });

  const [showShareForm, setShowShareForm] = useState(false);

  return (
    <div style={{ margin: "1rem" }}>
      <div style={{ border: "1px solid black" }}>
        doc shares
        <hr />
        <ul>
          {docShares.map(ds => (
            <li key={ds.id}>
              <div>
                <strong>id</strong>: {ds.id}
              </div>
              <div>
                <strong>sharedWithProject__DEBUGONLY__Title:</strong>
                {ds.sharedWithProject__DEBUGONLY__Title}
              </div>
              <div>
                <strong>sharedWithSystemOrganisationTitle:</strong>
                {ds.sharedWithSystemOrganisationTitle}
              </div>
              <div>
                <strong>sharedWithSystemOrganisationId:</strong>
                {ds.sharedWithSystemOrganisationId}
              </div>
              <div>
                <strong>sharedWithPermissions:</strong>
                {ds.sharedWithPermissions.join(",")}
              </div>
              <div>
                <strong>docOwningSystemOrganisationId:</strong>
                {ds.docOwningSystemOrganisationId}
              </div>
              <div>
                <strong>docOwningSystemOrganisationTitle:</strong>
                {ds.docOwningSystemOrganisationTitle}
              </div>
              <button
                type="button"
                onClick={async () => {
                  await unshareDoc({
                    variables: {
                      input: {
                        docId: props.docId,
                        recipientSystemOrganisationId:
                          ds.sharedWithSystemOrganisationId,
                        projectId: props.projectId,
                      },
                    },
                  });

                  await client.reFetchObservableQueries();
                }}
              >
                unshare
              </button>
            </li>
          ))}
        </ul>
        {!showShareForm && (
          <>
            <hr />
            <div style={{ margin: "1rem" }}>
              <button type="button" onClick={() => setShowShareForm(true)}>
                share
              </button>
            </div>
          </>
        )}
        {showShareForm && (
          <>
            <hr />
            <div style={{ margin: "1rem" }}>
              <PickSystemOrganisationIdForm
                onCancel={() => setShowShareForm(false)}
                onComplete={async systemOrganisationId => {
                  await shareDoc({
                    variables: {
                      input: {
                        docId: props.docId,
                        permissions: [],
                        recipientSystemOrganisationId: systemOrganisationId,
                        projectId: props.projectId,
                      },
                    },
                  });

                  await client.reFetchObservableQueries();
                  setShowShareForm(false);
                }}
              />
            </div>
          </>
        )}
      </div>
      <div style={{ border: "1px solid black" }}>
        pending doc shares
        <hr />
        <ul>
          {pendingDocShares.map(pds => (
            <li key={pds.id}>
              <div>
                <strong>id:</strong>
                {pds.id}
              </div>
              <div>
                <strong>recipientSystemOrganisationId:</strong>
                {pds.recipientSystemOrganisationId}
              </div>
              <div>
                <strong>recipientSystemOrganisationTitle:</strong>
                {pds.recipientSystemOrganisationTitle}
              </div>
              <div>
                <strong>recipientPermissions:</strong>
                {pds.recipientPermissions.join(",")}
              </div>
              <div>
                <strong>sharingSystemOrganisationId:</strong>
                {pds.sharingSystemOrganisationId}
              </div>
              <div>
                <strong>sharingSystemOrganisationTitle:</strong>
                {pds.sharingSystemOrganisationTitle}
              </div>
              <div>
                <strong>docOwningSystemOrganisationId:</strong>
                {pds.docOwningSystemOrganisationId}
              </div>
              <div>
                <strong>docOwningSystemOrganisationTitle:</strong>
                {pds.docOwningSystemOrganisationTitle}
              </div>
              <button
                type="button"
                onClick={async () => {
                  await cancelPendingShareDoc({
                    variables: {
                      input: {
                        id: pds.id,
                      },
                    },
                  });

                  await client.reFetchObservableQueries();
                }}
              >
                cancel
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function PickSystemOrganisationIdForm(props: {
  onComplete: (systemOrganisationId: string) => void;
  onCancel: () => void;
}) {
  const client = useApolloClient();

  const crmCompaniesQuery =
    useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery({
      client,
    });

  const [systemOrganisationId, setSystemOrganisationId] = useState("");

  return (
    <div>
      <h3>pick a crm record which is linked to msys system organisation</h3>
      <hr />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        onSubmit={e => {
          e.preventDefault();
          props.onComplete(systemOrganisationId);
        }}
      >
        <select
          value={systemOrganisationId}
          required
          onChange={e => setSystemOrganisationId(e.target.value)}
        >
          <option value={""}>select</option>
          {getDataOrNull(crmCompaniesQuery.data?.crmCompanies)?.edges.map(e => (
            <option
              key={e.node.id}
              value={e.node.linkedSystemOrganisationId ?? ""}
            >
              {e.node.title}
            </option>
          ))}
        </select>
        <div>
          <button type="button" onClick={props.onCancel}>
            cancel
          </button>
          <button type="submit">submit</button>
        </div>
      </form>
    </div>
  );
}
