import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner as LoadingContainer, useScreenWidth } from "@msys/ui";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmailIcon from "@mui/icons-material/Email";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import { AppBar, Toolbar } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PermissionName } from "../../../clients/graphqlTypes";
import { ReactComponent as TimesheetIcon } from "../../assets/icons/icon-timesheet.svg";
import { RestrictedByCapability } from "../../auth/RestrictedByCapability";
import { RestrictedByDocumentPermission } from "../../auth/RestrictedByDocumentPermission";
import { useRestrictionFilter } from "../../auth/useRestrictionFilter";
import { useUserData } from "../../auth/useUserData";
import { PageNotFound } from "../../commons/PageNotFound";
import { TabsWithRestriction } from "../../commons/TabsWithRestriction";
import { useBubbleContext } from "../../commons/hooks/useBubbleContext";
import { usePageWidth } from "../../commons/hooks/usePageWidth";
import { BreadcrumbItem, PageTopbarItem } from "../../commons/layout/Page";
import { BillOfMaterialsIcon } from "../../features/bill-of-materials/BillOfMaterialsIcon";
import { BillOfMaterialsItemDetails } from "../../features/bill-of-materials/BillOfMaterialsItemDetails";
import { ChatIcon } from "../../features/channels/ChatIcon";
import { SelectInfoMessageItem } from "../../features/doc-items/items/SelectInfoMessageItem";
import { InvoiceIcon } from "../../features/invoices/InvoiceIcon";
import { PurchaseOrderIcon } from "../../features/purchase-orders/PurchaseOrderIcon";
import { QuoteIcon } from "../../features/quotes/QuoteIcon";
import { RequirementIcon } from "../../features/requirements/RequirementIcon";
import { TaskIcon } from "../../features/tasks/TaskIcon";
import { matchPathPredicate } from "../../utils";
import { ProjectBillOfMaterials } from "./ProjectBillOfMaterials";
import { ProjectChatChannel, ProjectChats } from "./ProjectChats";
import { ProjectEmailPreview } from "./ProjectEmailPreview";
import { ProjectEmails } from "./ProjectEmails";
import { ProjectIncomingInvoice } from "./ProjectIncomingInvoice";
import {
  ProjectIncomingInvoices,
  ProjectOutgoingInvoices,
} from "./ProjectInvoices";
import { ProjectOutgoingInvoice } from "./ProjectOutgoingInvoice";
import { ProjectOverview } from "./ProjectOverview";
import {
  ProjectPurchaseOrderEdit,
  ProjectPurchaseOrderEditItem,
} from "./ProjectPurchaseOrderEdit";
import { ProjectPurchaseOrderPreview } from "./ProjectPurchaseOrderPreview";
import { ProjectPurchaseOrders } from "./ProjectPurchaseOrders";
import { ProjectQuoteCalculation } from "./ProjectQuoteCalculation";
import { ProjectQuoteEdit } from "./ProjectQuoteEdit";
import { ProjectQuoteEditItem } from "./ProjectQuoteEditItem";
import { ProjectQuotePreview } from "./ProjectQuotePreview";
import { ProjectQuoteThreeD } from "./ProjectQuoteThreeD";
import { ProjectQuoteTroubleshoot } from "./ProjectQuoteTroubleshoot";
import { ProjectQuotes } from "./ProjectQuotes";
import { ProjectRequest } from "./ProjectRequest";
import { ProjectRequests } from "./ProjectRequests";
import { ProjectRequirementEdit } from "./ProjectRequirementEdit";
import { ProjectRequirementEditItem } from "./ProjectRequirementEditItem";
import { ProjectRequirementPreview } from "./ProjectRequirementPreview";
import { ProjectRequirementThreeD } from "./ProjectRequirementThreeD";
import { ProjectRequirements } from "./ProjectRequirements";
import {
  useProjectQuoteRoutesQuery,
  useProjectRequirementRoutesQuery,
  useProjectRoutesQuery,
} from "./ProjectRoutes.generated";
import { ProjectTasks } from "./ProjectTasks";
import { ProjectTasksItem } from "./ProjectTasksItem";
import { ProjectTimeReportPreview } from "./ProjectTimeReportPreview";
import { ProjectTimeReports } from "./ProjectTimeReports";

type PageSubmenuItem = PageTopbarItem;

export const ProjectRoutes = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("Project id is missing");
  const location = useLocation();

  const { bubble } = useBubbleContext();
  const bubbleAmount = bubble.perProject[projectId] || 0;
  const { isMaxPhone, isMinTablet } = useScreenWidth();

  const { t } = useTranslate(["ProjectPageTopbar", "SidebarItems", "Invoices"]);

  const client = useApolloClient();
  const { data, loading } = useProjectRoutesQuery({
    client,
    variables: {
      projectId,
    },
  });

  const project = getDataOrNull(data?.project)?.project;
  const submenuItems: PageSubmenuItem[] = React.useMemo(() => {
    if (!project?.viewerPermissions) return [];

    return [
      {
        name: "project-overview",
        label: t("Overview", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/overview`,
        icon: <DashboardIcon />,
        projectPermission: { name: "READ_PROJECT", project },
      },
      {
        name: "project-communication",
        label: t("Communication", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/communication`,
        bubbleAmount,
        icon: <ChatIcon />,
        isInMoreMenu: true,
        projectPermission: { name: "READ_PROJECT", project },
      },
      {
        name: "project-requirements",
        label: t("Requirements", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/requirements`,
        icon: <RequirementIcon />,
        isInMoreMenu: true,
        projectPermission: { name: "READ_REQUIREMENTS", project },
      },
      {
        name: "project-quotes",
        label: t("Quotes", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/quotes`,
        icon: <QuoteIcon />,
        projectPermission: { name: "READ_QUOTES", project },
      },
      ...(isMaxPhone
        ? ([
            {
              name: "project-tasks",
              label: t("Tasks", {
                ns: "ProjectPageTopbar",
              }),
              path: `/projects/${projectId}/tasks/edit#list`,
              icon: <TaskIcon />,
              projectPermission: {
                name: "READ_PROJECT",
                project,
              },
              capability: "EXECUTING",
            },
            {
              name: "project-timesheet",
              label: t("Timesheet", {
                ns: "ProjectPageTopbar",
              }),
              path: `/projects/${projectId}/tasks/edit#timesheet`,
              icon: <TimesheetIcon />,
              projectPermission: {
                name: "READ_PROJECT",
                project,
              },
              capability: "EXECUTING",
            },
          ] as PageSubmenuItem[])
        : ([
            {
              name: "project-tasks",
              label: t("Tasks", {
                ns: "ProjectPageTopbar",
              }),
              path: `/projects/${projectId}/tasks`,
              icon: <TaskIcon />,
              projectPermission: {
                name: "READ_PROJECT",
                project,
              },
              capability: "EXECUTING",
            },
          ] as PageSubmenuItem[])),
      {
        name: "project-materials",
        label: t("Materials", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/materials`,
        icon: <ShoppingCartOutlined />,
        isInMoreMenu: true,
        capability: "ORDERING",
        projectPermission: { name: "MANAGE_QUOTES", project },
      },
      {
        name: "project-invoices",
        label: t("Invoices", {
          ns: "ProjectPageTopbar",
        }),
        path: `/projects/${projectId}/invoices`,
        icon: <InvoiceIcon />,
        isInMoreMenu: true,
        projectPermission: { name: "READ_INVOICES", project },
      },
    ];
  }, [project, t, projectId, bubbleAmount, isMaxPhone]);

  const activeItem = submenuItems.find(item =>
    item.activePaths
      ? item.activePaths.some(path => matchPathPredicate(`${path}/*`, location))
      : matchPathPredicate(`${item.path}/*`, location)
  );

  const breadcrumbs = React.useMemo(
    () => [
      {
        title: project?.title ?? "",
        to: `/projects/${projectId}`,
      },
    ],
    [project?.title, projectId]
  );

  const initialInvoicesTabOptions = [
    {
      value: "outgoing" as const,
      label: t("Outgoing", { ns: "Invoices" }),
      capability: "INVOICING" as const,
    },
    {
      value: "incoming" as const,
      label: t("Incoming", { ns: "Invoices" }),
    },
  ];
  const restrictionFilter = useRestrictionFilter();
  const invoicesTabOptions =
    initialInvoicesTabOptions.filter(restrictionFilter);

  if (loading && !data) return <LoadingContainer />;
  if (!project) return <PageNotFound />;

  return (
    <Routes>
      <Route index element={<Navigate to={`overview`} replace />} />
      <Route
        path={`overview`}
        element={
          <ProjectOverview
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`communication/*`}
        element={
          <CommunicationRoutes
            project={project}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      {/* REQUIREMENTS */}
      <Route
        path={`requirements`}
        element={
          <ProjectRequirements
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`requirements/:requirementId/*`}
        element={
          <ProjectRequirementRoutes
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      {/* QUOTES */}
      <Route
        path={`quotes`}
        element={
          <ProjectQuotes
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`quotes/:quoteId/*`}
        element={
          <ProjectQuoteRoutes
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      {/* PURCHASE ORDER */}
      <Route
        path={`materials/*`}
        element={
          <RestrictedByCapability capability="ORDERING">
            <ProjectMaterialsRoutes
              project={project}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeItem}
            />
          </RestrictedByCapability>
        }
      />
      {/* TASKS */}
      <Route path={`tasks`}>
        <Route
          index
          element={
            <RestrictedByCapability capability="EXECUTING">
              <Navigate to={isMaxPhone ? `edit#list` : `edit`} replace />
            </RestrictedByCapability>
          }
        />

        <Route
          path={`edit`}
          element={
            <RestrictedByCapability capability="EXECUTING">
              <ProjectTasks
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            </RestrictedByCapability>
          }
        >
          {isMinTablet && <Route index element={<SelectInfoMessageItem />} />}
          <Route
            path={":docId/items/:itemId"}
            element={
              <ProjectTasksItem
                projectId={projectId}
                pathToDocPage={`/projects/${projectId}/tasks/edit`}
                isInitiallyClosed={false}
              />
            }
          />
        </Route>
        <Route
          path={`reports`}
          element={
            <RestrictedByCapability capability="TIME_TRACKING">
              <ProjectTimeReports
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            </RestrictedByCapability>
          }
        />
        <Route
          path={`reports/:reportId`}
          element={
            <RestrictedByCapability capability="TIME_TRACKING">
              <ProjectTimeReportPreview
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            </RestrictedByCapability>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* INVOICES */}

      <Route path={`invoices`}>
        <Route
          index
          element={<Navigate to={invoicesTabOptions[0].value} replace />}
        />
        <Route path={`outgoing`}>
          <Route
            index
            element={
              <RestrictedByCapability capability="INVOICING">
                <ProjectOutgoingInvoices
                  projectId={projectId}
                  projectTitle={project.title}
                  prefixBreadcrumbs={breadcrumbs}
                  submenuItems={submenuItems}
                  activeSubmenuItem={activeItem}
                  tabOptions={initialInvoicesTabOptions}
                />
              </RestrictedByCapability>
            }
          />
          <Route
            path={`:invoiceId`}
            element={
              <ProjectOutgoingInvoice
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path={`incoming`}>
          <Route
            index
            element={
              <ProjectIncomingInvoices
                projectId={projectId}
                projectTitle={project.title}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
                tabOptions={initialInvoicesTabOptions}
              />
            }
          />
          <Route
            path={`:invoiceId`}
            element={
              <ProjectIncomingInvoice
                projectId={projectId}
                prefixBreadcrumbs={breadcrumbs}
                submenuItems={submenuItems}
                activeSubmenuItem={activeItem}
              />
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* REQUESTS */}
      <Route
        path={`requests`}
        element={
          <ProjectRequests
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />
      <Route
        path={`requests/:requestId`}
        element={
          <ProjectRequest
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeItem}
          />
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const ProjectQuoteRoutes = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const { quoteId } = useParams();
  if (!quoteId) throw new Error("Quote id is missing");

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["ProjectPageTopbar", "Quotes"]);
  const { isMinTablet } = useScreenWidth();
  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const client = useApolloClient();
  const query = useProjectQuoteRoutesQuery({
    client,
    variables: {
      projectId,
      quoteId,
    },
  });

  const quote = getDataOrNull(query.data?.quote)?.quote;
  const canViewEdit = quote?.viewerPermissions.includes("MANAGE_QUOTES");
  const redirectPath = canViewEdit
    ? `/projects/:projectId/quotes/:quoteId/edit`
    : `/projects/:projectId/quotes/:quoteId/preview`;

  const breadcrumbs = React.useMemo(() => {
    const items = [
      ...prefixBreadcrumbs,
      {
        title: t("Quotes", {
          ns: "ProjectPageTopbar",
        }),
        to: `/projects/${projectId}/quotes`,
      },
    ];

    if (viewer.organisation.capabilities.includes("QUOTING")) {
      items.push({
        title: canViewEdit
          ? t("Outgoing", {
              ns: "Quotes",
            })
          : t("Incoming", {
              ns: "Quotes",
            }),
        to: `/projects/${projectId}/quotes?tab=${
          canViewEdit ? "OUTGOING" : "INCOMING"
        }`,
      });
    }

    items.push({
      title: quote?.title ?? "",
      to: `/projects/${projectId}/quotes/${quoteId}`,
    });

    return items;
  }, [
    prefixBreadcrumbs,
    t,
    projectId,
    viewer.organisation.capabilities,
    quote?.title,
    quoteId,
    canViewEdit,
  ]);

  if (!quote) return null;

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={generatePath(redirectPath, {
              projectId,
              quoteId,
            })}
            replace
          />
        }
      />

      <Route
        path={`preview`}
        element={
          <RestrictedByDocumentPermission
            permission="READ_QUOTES"
            document={quote}
          >
            <ProjectQuotePreview
              projectId={projectId}
              quoteId={quoteId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      />
      <Route
        path={`edit`}
        element={
          <RestrictedByDocumentPermission
            permission="MANAGE_QUOTES"
            document={quote}
          >
            <ProjectQuoteEdit
              projectId={projectId}
              quoteId={quoteId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      >
        {isMinTablet &&
          (isMinTwoColumnsWithPreview || !isMinOneColumnWithPreview) && (
            <Route index element={<SelectInfoMessageItem />} />
          )}
        <Route
          path={"items/:itemId"}
          element={
            <ProjectQuoteEditItem
              projectId={projectId}
              quoteId={quoteId}
              pathToDocList={`/projects/${projectId}/quotes`}
              pathToDocPage={`/projects/${projectId}/quotes/${quoteId}/edit`}
            />
          }
        />
      </Route>
      <Route
        path={`calculation`}
        element={
          <RestrictedByDocumentPermission
            permission="MANAGE_QUOTES"
            document={quote}
          >
            <ProjectQuoteCalculation
              projectId={projectId}
              quoteId={quoteId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      >
        <Route
          path={"items/:itemId"}
          element={
            <ProjectQuoteEditItem
              projectId={projectId}
              quoteId={quoteId}
              pathToDocList={`/projects/${projectId}/quotes`}
              pathToDocPage={`/projects/${projectId}/quotes/${quoteId}/calculation`}
              isAbsolute={false}
            />
          }
        />
      </Route>
      <Route
        path={`3d`}
        element={
          <RestrictedByDocumentPermission
            permission="READ_QUOTES"
            document={quote}
          >
            <ProjectQuoteThreeD
              projectId={projectId}
              quoteId={quoteId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      >
        <Route
          path={"items/:itemId"}
          element={
            <ProjectQuoteEditItem
              projectId={projectId}
              quoteId={quoteId}
              pathToDocList={`/projects/${projectId}/quotes`}
              pathToDocPage={`/projects/${projectId}/quotes/${quoteId}/3d`}
              isAbsolute={true}
            />
          }
        />
      </Route>
      <Route
        path={`troubleshoot`}
        element={
          <RestrictedByDocumentPermission
            permission="MANAGE_QUOTES"
            document={quote}
          >
            <ProjectQuoteTroubleshoot
              projectId={projectId}
              quoteId={quoteId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
              pathToDocList={`/projects/${projectId}/quotes`}
              pathToDoc={`/projects/${projectId}/quotes/${quoteId}`}
            />
          </RestrictedByDocumentPermission>
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const ProjectRequirementRoutes = ({
  projectId,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const { requirementId } = useParams();
  if (!requirementId) throw new Error("Requirement id is missing");

  const { t } = useTranslate(["ProjectPageTopbar", "Quote"]);
  const { isMinTablet } = useScreenWidth();
  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const client = useApolloClient();
  const query = useProjectRequirementRoutesQuery({
    client,
    variables: {
      projectId,
      requirementId,
    },
  });

  const requirement = getDataOrNull(query.data?.requirement)?.requirement;

  const canViewEdit = requirement?.viewerPermissions.includes("MANAGE_QUOTES");
  const redirectPath = canViewEdit
    ? `/projects/:projectId/requirements/:requirementId/edit`
    : `/projects/:projectId/requirements/:requirementId/preview`;

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Requirements", {
          ns: "ProjectPageTopbar",
        }),
        to: `/projects/${projectId}/requirements`,
      },
      {
        title: requirement?.title ?? "",
        to: `/projects/${projectId}/requirements/${requirementId}`,
      },
    ],
    [prefixBreadcrumbs, t, projectId, requirement?.title, requirementId]
  );

  if (!requirement) return null;

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={generatePath(redirectPath, {
              projectId,
              requirementId,
            })}
            replace
          />
        }
      />
      <Route
        path={`preview`}
        element={
          <RestrictedByDocumentPermission
            permission="READ_REQUIREMENTS"
            document={requirement}
          >
            <ProjectRequirementPreview
              projectId={projectId}
              requirementId={requirementId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      />
      <Route
        path={`edit`}
        element={
          <RestrictedByDocumentPermission
            permission="MANAGE_REQUIREMENTS"
            document={requirement}
          >
            <ProjectRequirementEdit
              projectId={projectId}
              requirementId={requirementId}
              prefixBreadcrumbs={breadcrumbs}
              submenuItems={submenuItems}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      >
        {isMinTablet &&
          (isMinTwoColumnsWithPreview || !isMinOneColumnWithPreview) && (
            <Route index element={<SelectInfoMessageItem />} />
          )}
        <Route
          path={"items/:itemId"}
          element={
            <ProjectRequirementEditItem
              projectId={projectId}
              requirementId={requirementId}
              pathToDocPage={`/projects/${projectId}/requirements/${requirementId}/edit`}
            />
          }
        />
      </Route>
      <Route
        path={`3d`}
        element={
          <RestrictedByDocumentPermission
            permission="READ_REQUIREMENTS"
            document={requirement}
          >
            <ProjectRequirementThreeD
              projectId={projectId}
              requirementId={requirementId}
              submenuItems={submenuItems}
              prefixBreadcrumbs={breadcrumbs}
              activeSubmenuItem={activeSubmenuItem}
            />
          </RestrictedByDocumentPermission>
        }
      >
        <Route
          path={"items/:itemId"}
          element={
            <ProjectRequirementEditItem
              projectId={projectId}
              requirementId={requirementId}
              pathToDocPage={`/projects/${projectId}/requirements/${requirementId}/3d`}
              isAbsolute
            />
          }
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const ProjectMaterialsRoutes = ({
  project,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  project: { id: string; viewerPermissions: PermissionName[] };
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslate("Materials");

  const projectId = project.id;

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Materials"),
        to: `/projects/${projectId}/materials/bill-of-materials`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const tabItems = [
    {
      value: "project-bill-of-materials",
      label: t("Bill of materials"),
      icon: <BillOfMaterialsIcon />,
      path: `/projects/${projectId}/materials/bill-of-materials`,
      projectPermission: { name: "MANAGE_QUOTES" as const, project },
    },
    {
      value: "project-purchase-orders",
      label: t("Purchase orders"),
      icon: <PurchaseOrderIcon />,
      path: `/projects/${projectId}/materials/purchase-orders`,
      projectPermission: { name: "MANAGE_QUOTES" as const, project },
    },
  ];

  const activeTab = tabItems.find(item =>
    matchPath(item.path, location.pathname)
  );
  const tabs = (
    <TabsWithRestriction
      condensed
      useSelectOnMobile
      options={tabItems}
      value={activeTab?.value}
      onChange={value => {
        const tab = tabItems.find(t => t.value === value);
        if (tab) navigate(tab.path);
      }}
    />
  );

  return (
    <Routes>
      <Route index element={<Navigate to={"bill-of-materials"} replace />} />
      <Route
        path={`bill-of-materials`}
        element={
          <ProjectBillOfMaterials
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      >
        <Route
          path={":itemId"}
          element={
            <BillOfMaterialsItemDetails
              projectId={projectId}
              handleClose={() => {
                navigate({
                  pathname: `/projects/${projectId}/materials/bill-of-materials`,
                  search: searchParams.toString(),
                });
              }}
            />
          }
        />
      </Route>
      <Route
        path="purchase-orders"
        element={
          <ProjectPurchaseOrders
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      />
      <Route
        path={"purchase-orders/:orderId/edit"}
        element={
          <ProjectPurchaseOrderEdit
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
          />
        }
      >
        <Route
          path={"items/:itemId"}
          element={<ProjectPurchaseOrderEditItem projectId={projectId} />}
        />
      </Route>
      <Route
        path="purchase-orders/:orderId"
        element={
          <ProjectPurchaseOrderPreview
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
          />
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const CommunicationRoutes = ({
  project,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
}: {
  project: { id: string; viewerPermissions: PermissionName[] };
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslate(["ProjectPageTopbar"]);

  const projectId = project.id;

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Materials", { ns: "ProjectPageTopbar" }),
        to: `/projects/${projectId}/materials/bill-of-materials`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const tabItems = [
    {
      value: "project-chats",
      label: t("Chats", { ns: "ProjectPageTopbar" }),
      icon: <ChatIcon />,
      path: `/projects/${projectId}/communication/chats`,
      projectPermission: { name: "READ_PROJECT" as const, project },
    },
    {
      value: "project-emails",
      label: t("Emails", { ns: "ProjectPageTopbar" }),
      icon: <EmailIcon />,
      path: `/projects/${projectId}/communication/emails`,
      projectPermission: { name: "READ_PROJECT" as const, project },
    },
  ];

  const activeTab = tabItems.find(item =>
    matchPath(item.path, location.pathname)
  );

  const tabs = (
    <AppBar position="sticky" color="inherit">
      <Toolbar variant="dense" sx={{ minHeight: "auto" }}>
        <TabsWithRestriction
          useSelectOnMobile
          options={tabItems}
          value={activeTab?.value}
          onChange={value => {
            const tab = tabItems.find(t => t.value === value);
            if (tab) navigate(tab.path);
          }}
        />
      </Toolbar>
    </AppBar>
  );

  return (
    <Routes>
      <Route index element={<Navigate to={"chats"} replace />} />
      <Route
        path="chats"
        element={
          <ProjectChats
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      >
        <Route
          path={":channelId"}
          element={<ProjectChatChannel projectId={projectId} />}
        />
      </Route>
      <Route
        path="emails"
        element={
          <ProjectEmails
            projectId={projectId}
            prefixBreadcrumbs={breadcrumbs}
            submenuItems={submenuItems}
            activeSubmenuItem={activeSubmenuItem}
            tabs={tabs}
          />
        }
      >
        <Route
          path={":emailId"}
          element={<ProjectEmailPreview projectId={projectId} />}
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
