import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { browserHasOnlyTouch } from "../app/featureDetection";

const DND_TOUCH_PROVIDER_OPTIONS = {
  delayTouchStart: 0,
  // enableMouseEvents: true,
};

export const LocalDndProvider = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    // @ts-ignore type children doesn't exist
    <DndProvider
      key={1}
      backend={browserHasOnlyTouch ? TouchBackend : HTML5Backend}
      options={browserHasOnlyTouch ? DND_TOUCH_PROVIDER_OPTIONS : undefined}
    >
      {children}
    </DndProvider>
  );
};
