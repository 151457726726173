/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationChannelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganisationChannelQuery = { organisationChannels: Array<{ __typename: 'Channel', id: string, type: string }> };

export type ChannelParticipantFragment = { __typename: 'User', id: string, title: Types.Salutation, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type ChannelListItemFragment = { __typename: 'Channel', id: string, type: string, label: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, messages: { __typename: 'MessageConnection', edges: Array<{ __typename: 'MessageEdge', node: { __typename: 'Message', text: string, createdAt: any } }> } };

export type ProjectChannelSidebarQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectChannelSidebarQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus, viewerPermissions: Array<Types.PermissionName>, channels: Array<{ __typename: 'Channel', id: string, type: string, label: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, messages: { __typename: 'MessageConnection', edges: Array<{ __typename: 'MessageEdge', node: { __typename: 'Message', text: string, createdAt: any } }> } }>, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, user2UserChannelId?: string | null, title: Types.Salutation, firstname: string, familyname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } | null } };

export const ChannelParticipantFragmentDoc = gql`
    fragment ChannelParticipant on User {
  id
  title
  firstname
  familyname
  avatar {
    ...AttachmentSnapshot
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const ChannelListItemFragmentDoc = gql`
    fragment ChannelListItem on Channel {
  id
  type
  label
  logo {
    ...AttachmentSnapshot
  }
  messages(first: 1) {
    edges {
      node {
        text
        createdAt
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const OrganisationChannelDocument = gql`
    query OrganisationChannel {
  organisationChannels {
    id
    type
  }
}
    `;

/**
 * __useOrganisationChannelQuery__
 *
 * To run a query within a React component, call `useOrganisationChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationChannelQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationChannelQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationChannelQuery, OrganisationChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationChannelQuery, OrganisationChannelQueryVariables>(OrganisationChannelDocument, options);
      }
export function useOrganisationChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationChannelQuery, OrganisationChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationChannelQuery, OrganisationChannelQueryVariables>(OrganisationChannelDocument, options);
        }
export function useOrganisationChannelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationChannelQuery, OrganisationChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationChannelQuery, OrganisationChannelQueryVariables>(OrganisationChannelDocument, options);
        }
export type OrganisationChannelQueryHookResult = ReturnType<typeof useOrganisationChannelQuery>;
export type OrganisationChannelLazyQueryHookResult = ReturnType<typeof useOrganisationChannelLazyQuery>;
export type OrganisationChannelSuspenseQueryHookResult = ReturnType<typeof useOrganisationChannelSuspenseQuery>;
export type OrganisationChannelQueryResult = Apollo.QueryResult<OrganisationChannelQuery, OrganisationChannelQueryVariables>;
export const ProjectChannelSidebarDocument = gql`
    query ProjectChannelSidebar($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        state
        viewerPermissions
        channels {
          id
          ...ChannelListItem
        }
        internalStakeholders {
          id
          user {
            id
            user2UserChannelId
            ...ChannelParticipant
          }
        }
      }
    }
  }
}
    ${ChannelListItemFragmentDoc}
${ChannelParticipantFragmentDoc}`;

/**
 * __useProjectChannelSidebarQuery__
 *
 * To run a query within a React component, call `useProjectChannelSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectChannelSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectChannelSidebarQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectChannelSidebarQuery(baseOptions: Apollo.QueryHookOptions<ProjectChannelSidebarQuery, ProjectChannelSidebarQueryVariables> & ({ variables: ProjectChannelSidebarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectChannelSidebarQuery, ProjectChannelSidebarQueryVariables>(ProjectChannelSidebarDocument, options);
      }
export function useProjectChannelSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectChannelSidebarQuery, ProjectChannelSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectChannelSidebarQuery, ProjectChannelSidebarQueryVariables>(ProjectChannelSidebarDocument, options);
        }
export function useProjectChannelSidebarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectChannelSidebarQuery, ProjectChannelSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectChannelSidebarQuery, ProjectChannelSidebarQueryVariables>(ProjectChannelSidebarDocument, options);
        }
export type ProjectChannelSidebarQueryHookResult = ReturnType<typeof useProjectChannelSidebarQuery>;
export type ProjectChannelSidebarLazyQueryHookResult = ReturnType<typeof useProjectChannelSidebarLazyQuery>;
export type ProjectChannelSidebarSuspenseQueryHookResult = ReturnType<typeof useProjectChannelSidebarSuspenseQuery>;
export type ProjectChannelSidebarQueryResult = Apollo.QueryResult<ProjectChannelSidebarQuery, ProjectChannelSidebarQueryVariables>;