/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectCrmLinksLabelFragment = { __typename: 'Project', id: string, crmLinks: Array<{ __typename: 'ProjectLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string } } | { __typename: 'ProjectLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, fullname: string } }> };

export const ProjectCrmLinksLabelFragmentDoc = gql`
    fragment ProjectCrmLinksLabel on Project {
  id
  crmLinks {
    ... on ProjectLinkCrmUser {
      __typename
      id
      crmUser {
        id
        fullname
      }
      linkAs
    }
    ... on ProjectLinkCrmOrganisation {
      __typename
      id
      crmOrganisation {
        id
        title
      }
      linkAs
    }
  }
}
    `;