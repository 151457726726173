export function suppressConsoleWarnings() {
  type SilenceableConsole = typeof console & { warningsSilenced?: boolean };
  if ((console as SilenceableConsole).warningsSilenced) return;

  const originalConsoleError = console.error;
  console.error = (...args: unknown[]) => {
    {
      // silence props from react-sortable-tree
      const [, , message, stack] = args;
      if (
        typeof message === "string" &&
        message.match(/The prop `[a-zA-Z0-9_]+` is marked as required/) &&
        typeof stack === "string" &&
        stack.includes("at ReactSortableTree")
      ) {
        return;
      }
    }
    originalConsoleError(...args);
  };

  (console as SilenceableConsole).warningsSilenced = true;
}
