import { isImageOr3dModel, processAttachment, RichTextValue } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { GalleryRow } from "../../../commons/images/GalleryRow";
import { PropertiesGridLabeledValue } from "../../../commons/PropertiesGridLabeledValue";
import { useQuoteItemProperties } from "../../item-properties/itemProperties";
import { filterItemProductImages } from "../../quotes/helpers";
import { QuoteProductsVisibilityFragment } from "../../quotes/Quotes.generated";
import {
  InvoiceItemDescriptionColumn_InvoiceExtraItemFragment,
  InvoiceItemDescriptionColumn_InvoiceItemFragment,
  InvoiceItemDescriptionColumn_InvoicePaymentItemFragment,
  InvoiceItemDescriptionColumn_ItemSnapshotFragment,
  InvoiceItemDescriptionColumn_QuoteItemFragment,
} from "./InvoiceItemDescriptionColumn.generated";

export type InvoiceItem =
  | InvoiceItemDescriptionColumn_InvoiceItemFragment
  | InvoiceItemDescriptionColumn_InvoiceExtraItemFragment
  | InvoiceItemDescriptionColumn_InvoicePaymentItemFragment;

type InvoiceQuoteItem =
  | InvoiceItemDescriptionColumn_QuoteItemFragment
  | InvoiceItemDescriptionColumn_ItemSnapshotFragment;

interface Props<Q extends InvoiceQuoteItem> {
  columns?: number;
  item: InvoiceItem;
  quoteItemMapper: (item: InvoiceItem) => Q;
  productsVisibility: QuoteProductsVisibilityFragment;
}

export const InvoiceItemDescriptionColumn = <Q extends InvoiceQuoteItem>({
  columns = 2,
  item,
  quoteItemMapper,
  productsVisibility,
}: Props<Q>) => {
  const quoteItem = isInvoiceItem(item) ? quoteItemMapper(item) : null;
  const { title, description } = isInvoiceItem(item)
    ? quoteItemMapper(item)
    : item;

  return (
    <Stack direction="column" spacing={1} maxWidth="100%">
      <Typography variant="h3">{title}</Typography>
      {quoteItem ? (
        <InvoiceQuoteItemDescriptionColumn
          columns={columns}
          item={quoteItem}
          productsVisibility={productsVisibility}
        />
      ) : description ? (
        <Typography variant="body2" component="div">
          <RichTextValue htmlContent={description} />
        </Typography>
      ) : null}
    </Stack>
  );
};

export const InvoiceQuoteItemDescriptionColumn = <Q extends InvoiceQuoteItem>({
  columns,
  item,
  productsVisibility,
}: {
  columns: number;
  item: Q;
  productsVisibility: QuoteProductsVisibilityFragment;
}) => {
  const [properties, productProperties] = useQuoteItemProperties(item);

  const images = filterItemProductImages(
    item.attachments
      .filter(attachment => attachment.clientVisibility)
      .map(processAttachment)
      .filter(isImageOr3dModel),
    productsVisibility
  );

  const Images =
    images.length > 0 ? <GalleryRow images={images} maxItems={4} /> : null;

  const ItemDescription =
    item.description || properties.length > 0 ? (
      <Typography variant="body2" component="div">
        {item.description ? (
          <RichTextValue
            key={item.description}
            htmlContent={item.description}
            style={{ marginBottom: properties.length > 0 ? 8 : 0 }}
          />
        ) : null}
        {properties.length > 0 ? (
          <PropertiesGridLabeledValue
            properties={properties}
            columns={columns}
          />
        ) : null}
      </Typography>
    ) : null;

  const ProductTitle =
    item.product &&
    item.product.title &&
    item.product.titleClientVisibility !== "hide" &&
    productsVisibility.showTitle ? (
      <Typography variant="h4" style={{ fontWeight: "normal" }}>
        {item.product.title}
      </Typography>
    ) : null;

  const ProductDescription =
    (item.product &&
      item.product.description &&
      item.product.descriptionClientVisibility !== "hide" &&
      productsVisibility.showDescription) ||
    productProperties.length > 0 ? (
      <Typography variant="body2" component="div">
        {item.product &&
        item.product.description &&
        item.product.descriptionClientVisibility !== "hide" &&
        productsVisibility.showDescription ? (
          <div>
            <RichTextValue
              key={item.product.description}
              htmlContent={item.product.description}
              style={{ marginBottom: productProperties.length > 0 ? 8 : 0 }}
            />
          </div>
        ) : null}
        {productProperties.length > 0 ? (
          <PropertiesGridLabeledValue
            properties={properties}
            columns={columns}
          />
        ) : null}
      </Typography>
    ) : null;

  return ItemDescription || ProductTitle || ProductDescription || Images ? (
    <Stack direction="column" spacing={1} width="100%" minWidth={0}>
      {ItemDescription}
      {ProductTitle}
      {ProductDescription}
      {Images}
    </Stack>
  ) : null;
};

function isInvoiceItem(
  item: InvoiceItem
): item is Extract<InvoiceItem, { __typename: "InvoiceItem" }> {
  return item.__typename === "InvoiceItem";
}
