/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PendingInvitationsContracteeQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type PendingInvitationsContracteeQuery = { crmPerson: { __typename: 'CrmPersonResult', crmPerson?: { __typename: 'CrmPerson', id: string, projectContracteeInvitations: Array<{ __typename: 'ProjectContracteeInvitation', id: string, project: { __typename: 'Project', id: string, title: string } }> } | null } | { __typename: 'MissingPermissions' } };

export type CancelProjectContracteeInvitationMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  projectContracteeInvitationId: Types.Scalars['ID']['input'];
}>;


export type CancelProjectContracteeInvitationMutation = { cancelProjectContracteeInvitation: { __typename: 'CancelProjectContracteeInvitationResult', invitation: { __typename: 'ProjectContracteeInvitation', id: string, status: Types.InvitationState } } };


export const PendingInvitationsContracteeDocument = gql`
    query PendingInvitationsContractee($userId: ID!) {
  crmPerson(id: $userId) {
    ... on CrmPersonResult {
      crmPerson {
        id
        projectContracteeInvitations(filterState: [PENDING]) {
          id
          project {
            id
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePendingInvitationsContracteeQuery__
 *
 * To run a query within a React component, call `usePendingInvitationsContracteeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingInvitationsContracteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingInvitationsContracteeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePendingInvitationsContracteeQuery(baseOptions: Apollo.QueryHookOptions<PendingInvitationsContracteeQuery, PendingInvitationsContracteeQueryVariables> & ({ variables: PendingInvitationsContracteeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingInvitationsContracteeQuery, PendingInvitationsContracteeQueryVariables>(PendingInvitationsContracteeDocument, options);
      }
export function usePendingInvitationsContracteeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingInvitationsContracteeQuery, PendingInvitationsContracteeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingInvitationsContracteeQuery, PendingInvitationsContracteeQueryVariables>(PendingInvitationsContracteeDocument, options);
        }
export function usePendingInvitationsContracteeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PendingInvitationsContracteeQuery, PendingInvitationsContracteeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PendingInvitationsContracteeQuery, PendingInvitationsContracteeQueryVariables>(PendingInvitationsContracteeDocument, options);
        }
export type PendingInvitationsContracteeQueryHookResult = ReturnType<typeof usePendingInvitationsContracteeQuery>;
export type PendingInvitationsContracteeLazyQueryHookResult = ReturnType<typeof usePendingInvitationsContracteeLazyQuery>;
export type PendingInvitationsContracteeSuspenseQueryHookResult = ReturnType<typeof usePendingInvitationsContracteeSuspenseQuery>;
export type PendingInvitationsContracteeQueryResult = Apollo.QueryResult<PendingInvitationsContracteeQuery, PendingInvitationsContracteeQueryVariables>;
export const CancelProjectContracteeInvitationDocument = gql`
    mutation cancelProjectContracteeInvitation($projectId: ID!, $projectContracteeInvitationId: ID!) {
  cancelProjectContracteeInvitation(
    projectId: $projectId
    projectContracteeInvitationId: $projectContracteeInvitationId
  ) {
    invitation {
      id
      status
    }
  }
}
    `;
export type CancelProjectContracteeInvitationMutationFn = Apollo.MutationFunction<CancelProjectContracteeInvitationMutation, CancelProjectContracteeInvitationMutationVariables>;

/**
 * __useCancelProjectContracteeInvitationMutation__
 *
 * To run a mutation, you first call `useCancelProjectContracteeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProjectContracteeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProjectContracteeInvitationMutation, { data, loading, error }] = useCancelProjectContracteeInvitationMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectContracteeInvitationId: // value for 'projectContracteeInvitationId'
 *   },
 * });
 */
export function useCancelProjectContracteeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelProjectContracteeInvitationMutation, CancelProjectContracteeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelProjectContracteeInvitationMutation, CancelProjectContracteeInvitationMutationVariables>(CancelProjectContracteeInvitationDocument, options);
      }
export type CancelProjectContracteeInvitationMutationHookResult = ReturnType<typeof useCancelProjectContracteeInvitationMutation>;
export type CancelProjectContracteeInvitationMutationResult = Apollo.MutationResult<CancelProjectContracteeInvitationMutation>;
export type CancelProjectContracteeInvitationMutationOptions = Apollo.BaseMutationOptions<CancelProjectContracteeInvitationMutation, CancelProjectContracteeInvitationMutationVariables>;