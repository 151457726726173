import React from "react";

export const useSyncStates = <S>(
  externalState: S,
  onExternalStateChange: (newState: S) => void,
  validate?: (state: S) => string | undefined
): [S, React.Dispatch<React.SetStateAction<S>>, string | undefined] => {
  const [internalState, setInternalState] = React.useState(externalState);
  const [error, setError] = React.useState<string | undefined>(
    validate?.(externalState)
  );

  const state = React.useRef({
    externalState,
    internalState,
    onExternalStateChange,
    validate,
    error,
  });
  state.current = {
    externalState,
    internalState,
    onExternalStateChange,
    validate,
    error,
  };

  // change from outside
  React.useEffect(() => {
    if (state.current.internalState !== externalState) {
      setError(state.current.validate?.(externalState));
      setInternalState(externalState);
    }
  }, [externalState]);

  // propagate to outside
  React.useEffect(() => {
    if (state.current.externalState !== internalState) {
      const error = state.current.validate?.(internalState);
      setError(error);
      if (!error) {
        state.current.onExternalStateChange(internalState);
      }
    }
  }, [internalState]);

  return [internalState, setInternalState, error];
};
