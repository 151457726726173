import { gql, useApolloClient } from "@apollo/client";
import React from "react";
import { Modal } from "@msys/ui";
import { theme } from "../../../common/MuiThemeProvider";
import { useGetCsvHeaderMutation } from "./PimCsvColumnMappingModal.generated";
import { useTranslate } from "@tolgee/react";
import { CsvColumnMapper } from "./CsvColumnMapper";

interface Props {
  csvUrl?: string;
  csvFilename: string;
  separator?: string;
  title?: string;
  supplierId: string;
  handleClose: () => void;
  handleComplete: (
    jsonMapping: { [key: string]: string } | null
  ) => Promise<void> | void;
}

export const PimCsvColumnMappingModal = ({
  csvUrl,
  csvFilename,
  separator,
  title,
  supplierId,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate("Global");

  const client = useApolloClient();
  const [getCsvHeadersMutation] = useGetCsvHeaderMutation({ client });
  const getCsvHeaders = async () => {
    const result = await getCsvHeadersMutation({
      variables: {
        input: {
          url: csvUrl,
          filename: csvFilename,
          separator: separator ?? ";",
          supplierId,
        },
      },
    });
    return result.data!.getCsvHeader;
  };

  return (
    <Modal
      title={title ?? ""}
      handleClose={async () => {
        handleClose();
        await handleComplete(null);
      }}
      actionButtons={[]}
      dialogProps={{ maxWidth: "md" }}
    >
      <CsvColumnMapper
        getCsvHeaders={getCsvHeaders}
        onComplete={async jsonMapping => {
          handleClose();
          await handleComplete(jsonMapping);
        }}
        t={t}
        theme={theme}
      />
    </Modal>
  );
};
