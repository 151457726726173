import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  ListHeader,
  LoadingSpinner as LoadingIndicator,
  MediaCardItem,
  MediaListItem,
  Modal,
  useLocalStorageAsState,
  useScreenWidth,
} from "@msys/ui";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TablePagination,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isUndefined } from "lodash";
import React from "react";
import { ButtonCircleWrapper } from "../../commons/button/ButtonCircleWrapper";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { CollectionView } from "../../commons/hooks/useCollectionView";
import { RESULTS_PER_PAGE_OPTIONS } from "../../constants";
import { TemplateTypeOverviewModal } from "./TemplateTypeOverviewModal";
import {
  TemplateTypesSearchModal_TemplateTypeFragment,
  useTemplateTypesSearchModalQuery,
} from "./TemplateTypesSearchModal.generated";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  initialSearchValue?: string;
  excludeTemplateTypeIds?: string[];
  handleTemplateTypeChoice: (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => Promise<void> | void;
  handleClose: () => void;
  handleSearchValueChange?: (value: string) => void;
}

export const TemplateTypesSearchModal = (props: Props) => {
  return (
    <Modal
      dialogProps={{
        maxWidth: "xl",
        PaperProps: { style: { height: "100%" } },
      }}
      handleClose={props.handleClose}
      // TODO: translate
      title={"Template type search"}
      alwaysVisible
    >
      <TemplateTypesSearchModalComponent {...props} />
    </Modal>
  );
};

export const TemplateTypesSearchModalComponent = ({
  initialSearchValue = "",
  excludeTemplateTypeIds,
  handleTemplateTypeChoice,
  handleClose,
  handleSearchValueChange,
}: Props) => {
  const { t } = useTranslate(["TemplateTypesSearch", "Global"]);
  const { isMinTablet } = useScreenWidth();

  const [selected, setSelected] =
    React.useState<TemplateTypesSearchModal_TemplateTypeFragment | null>(null);

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 25 });

  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  const client = useApolloClient();
  const query = useTemplateTypesSearchModalQuery({
    client,
    variables: {
      searchTerm,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      filter: {
        excludeTemplateTypeIds,
      },
    },
  });

  const [activeView, setActiveView] = useLocalStorageAsState<CollectionView>(
    "msys-templateTypesListViewType",
    "table"
  );

  React.useEffect(() => {
    const totalCount = getDataOrNull(
      query.data?.searchTemplateTypes
    )?.totalCount;
    if (
      !isUndefined(totalCount) &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const items =
    getDataOrNull(
      (query.data ?? query.previousData)?.searchTemplateTypes
    )?.edges.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.searchTemplateTypes)
      ?.totalCount ?? 0;

  const renderTemplateTypeSelectButton = (
    templateType: TemplateTypesSearchModal_TemplateTypeFragment
  ) => {
    return (
      <IconButton
        color={"primary"}
        size={"small"}
        onClick={() => {
          handleTemplateTypeChoice(templateType);
          handleClose();
        }}
        title={t("Select", {
          ns: "TemplateTypesSearch",
        })}
        aria-label={t("Select", {
          ns: "TemplateTypesSearch",
        })}
      >
        <TouchAppIcon />
      </IconButton>
    );
  };

  const renderItems = (
    items: TemplateTypesSearchModal_TemplateTypeFragment[]
  ) => {
    switch (activeView) {
      case "list":
        if (query.loading) return <LoadingIndicator />;
        return items.length > 0 ? (
          <Stack
            direction="column"
            spacing={1}
            flex={1}
            overflow="auto"
            minHeight={0}
            sx={theme => ({
              marginRight: `-${theme.spacing(2)} !important`,
            })}
          >
            <Stack
              direction="column"
              spacing={1}
              overflow="auto"
              flex={1}
              minHeight={0}
              pr={2}
            >
              {items.map((templateType, index) => {
                return (
                  <MediaListItem
                    key={templateType.id}
                    onClick={() => {
                      setSelected(templateType);
                    }}
                    title={templateType.title}
                    description={templateType.description}
                    ActionButton={
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {renderTemplateTypeSelectButton(templateType)}
                      </Stack>
                    }
                  />
                );
              })}
            </Stack>
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Rows per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        ) : (
          <Box padding={2} display="flex" justifyContent="center">
            {t("There seems to be nothing here", {
              ns: "Global",
            })}
          </Box>
        );

      case "gallery":
        if (query.loading) return <LoadingIndicator />;
        return (
          <Stack
            direction="column"
            spacing={1}
            flex={1}
            overflow="auto"
            minHeight={0}
            sx={theme => ({
              marginRight: `-${theme.spacing(2)} !important`,
            })}
          >
            <Stack
              direction="column"
              spacing={1}
              overflow="auto"
              flex={1}
              minHeight={0}
              pr={2}
            >
              <Grid container spacing={isMinTablet ? 2 : 1}>
                {items.map((templateType, index) => {
                  return (
                    <Grid key={templateType.id} item xs={6} md={6} lg={4}>
                      <MediaCardItem
                        key={templateType.id}
                        onClick={() => {
                          setSelected(templateType);
                        }}
                        title={templateType.title}
                        description={templateType.description}
                        ActionButton={
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonCircleWrapper>
                              {renderTemplateTypeSelectButton(templateType)}
                            </ButtonCircleWrapper>
                          </Stack>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Items per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        );

      case "table":
        return (
          <DataGrid
            density="compact"
            loading={query.loading}
            hideFooter={totalCount === 0}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              setSelected(params.row);
            }}
            localeText={{
              noRowsLabel: t("There are no items to display", {
                ns: "Global",
              }),
              MuiTablePagination: {
                labelRowsPerPage: t("Rows per page", {
                  ns: "Global",
                }),
              },
            }}
            columns={[
              {
                field: "checkbox",
                headerName: "",
                width: 50,
                sortable: false,
                resizable: false,
                renderCell: ({ row: templateType }) =>
                  renderTemplateTypeSelectButton(templateType),
              },
              {
                field: "title",
                headerName: t("Title", {
                  ns: "Templates",
                }),
                flex: 3,
                minWidth: 150,
                renderCell: ({
                  row: templateType,
                }: {
                  row: TemplateTypesSearchModal_TemplateTypeFragment;
                }) => templateType.title,
              },
            ]}
            rows={items}
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel(newPaginationModel);
            }}
            disableColumnFilter
            pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
            rowCount={totalCount}
          />
        );
    }
  };

  return (
    <Stack alignItems={"stretch"} height="100%" spacing={2} direction="row">
      <Stack
        direction={"column"}
        height="100%"
        spacing={1}
        flex={1}
        minWidth={0}
      >
        <TemplateTypesSearchModalListHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeView={activeView}
          setActiveView={setActiveView}
          handleSearchValueChange={handleSearchValueChange}
        />
        {query.error && <ErrorMessage message={query.error.message} />}
        {renderItems(items)}
      </Stack>
      {selected && (
        <TemplateTypeOverviewModal
          templateTypeId={selected.id}
          handleClose={() => setSelected(null)}
          dialogActionButton={
            <Button
              onClick={async e => {
                e.stopPropagation();
                setSelected(null);
                await handleTemplateTypeChoice(selected);
                handleClose();
              }}
              color="primary"
              variant="contained"
              startIcon={<AddShoppingCartIcon />}
            >
              {t("Add to list", {
                ns: "TemplatesSearch",
              })}
            </Button>
          }
        />
      )}
    </Stack>
  );
};

function TemplateTypesSearchModalListHeader({
  searchTerm,
  setSearchTerm,
  handleSearchValueChange,
  activeView,
  setActiveView,
}: {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchValueChange?: (value: string) => void;
  activeView: CollectionView;
  setActiveView: (newValue: CollectionView | undefined) => void;
}) {
  const { t } = useTranslate(["Global"]);

  return (
    <>
      <ListHeader
        elementsOrder="separate-rows"
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        SearchField={
          <Stack direction={"row"} flex={1} spacing={1}>
            <DebouncedSearchInput
              defaultValue={searchTerm}
              placeholder={t("Search", {
                ns: "Global",
              })}
              onChangeSearchTerm={setSearchTerm}
              onImmediateChangeSearchTerm={handleSearchValueChange}
              autoFocus
              style={{ flexGrow: 1 }}
            />
          </Stack>
        }
      />
    </>
  );
}
