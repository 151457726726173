import { CollapseSection, Modal } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { CheckboxGroupField } from "../../commons/form-fields/CheckboxField";
import { SwitchField } from "../../commons/form-fields/SwitchField";
import { useRelationshipRoles } from "../contact-links/useRelationshipRoles";
import { PreferenceStore } from "../users/useUserPreference";
import { VisibilityStore } from "../users/useVisibilityStore";
import { OpportunityListItemDisplayConfig } from "./components/OpportunityListItem";

interface Props<Keys extends string> {
  importVisibilityStore: VisibilityStore<Keys>;
  listItemDisplayConfigStore: PreferenceStore<OpportunityListItemDisplayConfig>;
  labels: Record<Keys, string>;
  title?: string;
  handleClose(): void;
}

interface FormValues<Keys extends string> {
  listItemDisplayConfig: OpportunityListItemDisplayConfig;
  importVisibility: Record<Keys, boolean>;
}

export const defaultListItemDisplayConfig: OpportunityListItemDisplayConfig = {
  clientOrganisation: true,
  clientMainContact: false,
  clientEmail: false,
  clientPhone: false,
  projectNumber: false,
  projectCreatedDate: false,
  projectDueDate: true,
  projectAddress: true,
  projectBudget: false,
  projectCategories: false,
  projectType: false,
  crmRoles: [],
};

export const OpportunitiesSettingsModal = <Keys extends string>({
  title,
  importVisibilityStore,
  listItemDisplayConfigStore,
  labels,
  handleClose,
}: Props<Keys>) => {
  const { t } = useTranslate(["Opportunities", "Global", "DataItem"]);
  const initialValues = {
    listItemDisplayConfig: listItemDisplayConfigStore.value,
    importVisibility: Object.fromEntries(
      (Object.keys(labels) as Keys[]).map(field => [
        field,
        importVisibilityStore.value[field] ?? true,
      ])
    ) as Record<Keys, boolean>,
  };
  const validationSchema = Yup.object().shape({
    importVisibility: Yup.object()
      .shape(
        Object.fromEntries(
          (Object.keys(labels) as Keys[]).map(field => [
            field,
            Yup.boolean().label(labels[field]).required(),
          ])
        )
      )
      .required(),
  });
  const handleSubmit = (values: FormValues<Keys>) => {
    importVisibilityStore.saveValue(values.importVisibility);
    listItemDisplayConfigStore.saveValue(values.listItemDisplayConfig);
    handleClose();
  };
  const formId = React.useMemo(() => uniqueId(), []);

  const clientDisplayOptions = [
    {
      value: "clientOrganisation",
      label: t("Company", { ns: "DataItem" }),
    },
    {
      value: "clientMainContact",
      label: t("Main contact", { ns: "DataItem" }),
    },
    { value: "clientEmail", label: t("Email", { ns: "DataItem" }) },
    { value: "clientPhone", label: t("Phone", { ns: "DataItem" }) },
  ];

  const projectDisplayOptions = [
    { value: "projectNumber", label: t("Number", { ns: "DataItem" }) },
    {
      value: "projectAddress",
      label: t("Address", { ns: "DataItem" }),
    },
    { value: "projectBudget", label: t("Budget", { ns: "DataItem" }) },
    {
      value: "projectCreatedDate",
      label: t("Created date", { ns: "DataItem" }),
    },
    {
      value: "projectDueDate",
      label: t("Deadline", { ns: "DataItem" }),
    },
    {
      value: "projectCategories",
      label: t("Categories", { ns: "DataItem" }),
    },
    { value: "projectType", label: t("Type", { ns: "DataItem" }) },
    { value: "leadId", label: t("Lead ID", { ns: "Projects" }) },
  ];

  const { roleOptions } = useRelationshipRoles();

  return (
    <Formik<FormValues<Keys>>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={false}
    >
      {formikProps => (
        <Modal
          title={title ?? t("Opportunities settings", { ns: "Opportunities" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
          maxWidth="xs"
        >
          <Form id={formId}>
            <Stack direction="column" spacing={2}>
              <CollapseSection
                title={t("Data on cards", { ns: "Opportunities" })}
              >
                <Stack direction="column" spacing={1}>
                  <Stack direction="column">
                    <Typography>
                      {t("Client info", { ns: "Opportunities" })}
                    </Typography>
                    {clientDisplayOptions.map(({ value, label }) => (
                      <SwitchField
                        key={value}
                        label={label}
                        name={`listItemDisplayConfig.${value}`}
                        disabled={formikProps.isSubmitting}
                      />
                    ))}
                  </Stack>
                  <Stack direction="column">
                    <Typography>
                      {t("Project info", { ns: "Opportunities" })}
                    </Typography>
                    {projectDisplayOptions.map(({ value, label }) => (
                      <SwitchField
                        key={value}
                        label={label}
                        name={`listItemDisplayConfig.${value}`}
                        disabled={formikProps.isSubmitting}
                      />
                    ))}
                  </Stack>
                  <Stack direction="column">
                    <Typography>
                      {t("Other info", { ns: "Opportunities" })}
                    </Typography>
                    <CheckboxGroupField
                      inputType="switch"
                      name="listItemDisplayConfig.crmRoles"
                      options={roleOptions.filter(o =>
                        ["PARTNER"].includes(o.value)
                      )}
                    />
                  </Stack>
                </Stack>
              </CollapseSection>
              <CollapseSection
                title={t("Import opportunities via", { ns: "Opportunities" })}
              >
                <Stack direction="column" spacing={0}>
                  {(Object.entries(labels) as [Keys, string][]).map(
                    ([field, label]) => (
                      <SwitchField
                        key={field}
                        label={label}
                        name={`importVisibility.${field}`}
                        disabled={formikProps.isSubmitting}
                      />
                    )
                  )}
                </Stack>
              </CollapseSection>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
