import React from "react";

interface Props {
  properties: { key: string; value: string }[];
  style?: React.CSSProperties;
  columns?: number;
}

export const PropertiesValue = ({ properties, columns = 2, style }: Props) =>
  properties.length ? (
    <ul
      style={{
        listStylePosition: "inside",
        paddingLeft: 8,
        columns: columns,
        WebkitColumns: columns,
        MozColumns: columns,
        marginTop: 0,
        marginBottom: 0,
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
        MozHyphens: "auto",
        WebkitHyphens: "auto",
        hyphens: "auto",
        ...(style ?? {}),
      }}
    >
      {properties.map((property, index) => (
        <li key={`${property.key}-${index}`}>
          {property.key}: {property.value}
        </li>
      ))}
    </ul>
  ) : null;
