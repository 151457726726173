/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectContactLinks_ProjectLinkCrmOrganisation_Fragment = { __typename: 'ProjectLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type ProjectContactLinks_ProjectLinkCrmUser_Fragment = { __typename: 'ProjectLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, fullname: string, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, crmCompany: { __typename: 'CrmCompany', id: string } } };

export type ProjectContactLinksFragment = ProjectContactLinks_ProjectLinkCrmOrganisation_Fragment | ProjectContactLinks_ProjectLinkCrmUser_Fragment;

export type QuoteItemContactLinks_QuoteItemLinkCrmOrganisation_Fragment = { __typename: 'QuoteItemLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, phones: Array<{ __typename: 'Phone', id: string, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type QuoteItemContactLinks_QuoteItemLinkCrmUser_Fragment = { __typename: 'QuoteItemLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, fullname: string, firstname: string, familyname: string, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type QuoteItemContactLinksFragment = QuoteItemContactLinks_QuoteItemLinkCrmOrganisation_Fragment | QuoteItemContactLinks_QuoteItemLinkCrmUser_Fragment;

export type ProjectAddCrmUserLinkMutationVariables = Types.Exact<{
  input: Types.AddProjectLinkCrmUserInput;
}>;


export type ProjectAddCrmUserLinkMutation = { addProjectLinkCrmUser: { __typename: 'AddProjectLinkCrmUserResult', project: { __typename: 'Project', id: string, crmLinks: Array<{ __typename: 'ProjectLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | { __typename: 'ProjectLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, fullname: string, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, crmCompany: { __typename: 'CrmCompany', id: string } } }> } } };

export type ProjectAddCrmOrganisationLinkMutationVariables = Types.Exact<{
  input: Types.AddProjectLinkCrmOrganisationInput;
}>;


export type ProjectAddCrmOrganisationLinkMutation = { addProjectLinkCrmOrganisation: { __typename: 'AddProjectLinkCrmOrganisationResult', project: { __typename: 'Project', id: string, crmLinks: Array<{ __typename: 'ProjectLinkCrmOrganisation', id: string, linkAs: string, crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | { __typename: 'ProjectLinkCrmUser', id: string, linkAs: string, crmUser: { __typename: 'CrmPerson', id: string, fullname: string, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, crmCompany: { __typename: 'CrmCompany', id: string } } }> } } };

export type ProjectRemoveCrmUserLinkMutationVariables = Types.Exact<{
  input: Types.RemoveProjectLinkCrmUserInput;
}>;


export type ProjectRemoveCrmUserLinkMutation = { removeProjectLinkCrmUser: boolean };

export type ProjectRemoveCrmOrganisationLinkMutationVariables = Types.Exact<{
  input: Types.RemoveProjectLinkCrmOrganisationInput;
}>;


export type ProjectRemoveCrmOrganisationLinkMutation = { removeProjectLinkCrmOrganisation: boolean };

export const ProjectContactLinksFragmentDoc = gql`
    fragment ProjectContactLinks on ProjectCrmLink {
  ... on ProjectLinkCrmUser {
    __typename
    id
    crmUser {
      id
      fullname
      firstname
      familyname
      email
      phones {
        id
        number
      }
      avatar {
        ...AttachmentSnapshot
      }
      crmCompany {
        id
      }
    }
    linkAs
  }
  ... on ProjectLinkCrmOrganisation {
    __typename
    id
    crmOrganisation {
      id
      title
      phones {
        id
        number
      }
      email
      logo {
        ...AttachmentSnapshot
      }
    }
    linkAs
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const QuoteItemContactLinksFragmentDoc = gql`
    fragment QuoteItemContactLinks on ItemCrmLink {
  ... on QuoteItemLinkCrmUser {
    __typename
    id
    crmUser {
      id
      fullname
      firstname
      familyname
      phones {
        id
        number
      }
      avatar {
        ...AttachmentSnapshot
      }
    }
    linkAs
  }
  ... on QuoteItemLinkCrmOrganisation {
    __typename
    id
    crmOrganisation {
      id
      title
      phones {
        id
        number
      }
      logo {
        ...AttachmentSnapshot
      }
    }
    linkAs
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const ProjectAddCrmUserLinkDocument = gql`
    mutation ProjectAddCrmUserLink($input: AddProjectLinkCrmUserInput!) {
  addProjectLinkCrmUser(input: $input) {
    project {
      id
      crmLinks {
        ...ProjectContactLinks
      }
    }
  }
}
    ${ProjectContactLinksFragmentDoc}`;
export type ProjectAddCrmUserLinkMutationFn = Apollo.MutationFunction<ProjectAddCrmUserLinkMutation, ProjectAddCrmUserLinkMutationVariables>;

/**
 * __useProjectAddCrmUserLinkMutation__
 *
 * To run a mutation, you first call `useProjectAddCrmUserLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAddCrmUserLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAddCrmUserLinkMutation, { data, loading, error }] = useProjectAddCrmUserLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectAddCrmUserLinkMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAddCrmUserLinkMutation, ProjectAddCrmUserLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAddCrmUserLinkMutation, ProjectAddCrmUserLinkMutationVariables>(ProjectAddCrmUserLinkDocument, options);
      }
export type ProjectAddCrmUserLinkMutationHookResult = ReturnType<typeof useProjectAddCrmUserLinkMutation>;
export type ProjectAddCrmUserLinkMutationResult = Apollo.MutationResult<ProjectAddCrmUserLinkMutation>;
export type ProjectAddCrmUserLinkMutationOptions = Apollo.BaseMutationOptions<ProjectAddCrmUserLinkMutation, ProjectAddCrmUserLinkMutationVariables>;
export const ProjectAddCrmOrganisationLinkDocument = gql`
    mutation ProjectAddCrmOrganisationLink($input: AddProjectLinkCrmOrganisationInput!) {
  addProjectLinkCrmOrganisation(input: $input) {
    project {
      id
      crmLinks {
        ...ProjectContactLinks
      }
    }
  }
}
    ${ProjectContactLinksFragmentDoc}`;
export type ProjectAddCrmOrganisationLinkMutationFn = Apollo.MutationFunction<ProjectAddCrmOrganisationLinkMutation, ProjectAddCrmOrganisationLinkMutationVariables>;

/**
 * __useProjectAddCrmOrganisationLinkMutation__
 *
 * To run a mutation, you first call `useProjectAddCrmOrganisationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAddCrmOrganisationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAddCrmOrganisationLinkMutation, { data, loading, error }] = useProjectAddCrmOrganisationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectAddCrmOrganisationLinkMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAddCrmOrganisationLinkMutation, ProjectAddCrmOrganisationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAddCrmOrganisationLinkMutation, ProjectAddCrmOrganisationLinkMutationVariables>(ProjectAddCrmOrganisationLinkDocument, options);
      }
export type ProjectAddCrmOrganisationLinkMutationHookResult = ReturnType<typeof useProjectAddCrmOrganisationLinkMutation>;
export type ProjectAddCrmOrganisationLinkMutationResult = Apollo.MutationResult<ProjectAddCrmOrganisationLinkMutation>;
export type ProjectAddCrmOrganisationLinkMutationOptions = Apollo.BaseMutationOptions<ProjectAddCrmOrganisationLinkMutation, ProjectAddCrmOrganisationLinkMutationVariables>;
export const ProjectRemoveCrmUserLinkDocument = gql`
    mutation ProjectRemoveCrmUserLink($input: RemoveProjectLinkCrmUserInput!) {
  removeProjectLinkCrmUser(input: $input)
}
    `;
export type ProjectRemoveCrmUserLinkMutationFn = Apollo.MutationFunction<ProjectRemoveCrmUserLinkMutation, ProjectRemoveCrmUserLinkMutationVariables>;

/**
 * __useProjectRemoveCrmUserLinkMutation__
 *
 * To run a mutation, you first call `useProjectRemoveCrmUserLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRemoveCrmUserLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRemoveCrmUserLinkMutation, { data, loading, error }] = useProjectRemoveCrmUserLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectRemoveCrmUserLinkMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRemoveCrmUserLinkMutation, ProjectRemoveCrmUserLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRemoveCrmUserLinkMutation, ProjectRemoveCrmUserLinkMutationVariables>(ProjectRemoveCrmUserLinkDocument, options);
      }
export type ProjectRemoveCrmUserLinkMutationHookResult = ReturnType<typeof useProjectRemoveCrmUserLinkMutation>;
export type ProjectRemoveCrmUserLinkMutationResult = Apollo.MutationResult<ProjectRemoveCrmUserLinkMutation>;
export type ProjectRemoveCrmUserLinkMutationOptions = Apollo.BaseMutationOptions<ProjectRemoveCrmUserLinkMutation, ProjectRemoveCrmUserLinkMutationVariables>;
export const ProjectRemoveCrmOrganisationLinkDocument = gql`
    mutation ProjectRemoveCrmOrganisationLink($input: RemoveProjectLinkCrmOrganisationInput!) {
  removeProjectLinkCrmOrganisation(input: $input)
}
    `;
export type ProjectRemoveCrmOrganisationLinkMutationFn = Apollo.MutationFunction<ProjectRemoveCrmOrganisationLinkMutation, ProjectRemoveCrmOrganisationLinkMutationVariables>;

/**
 * __useProjectRemoveCrmOrganisationLinkMutation__
 *
 * To run a mutation, you first call `useProjectRemoveCrmOrganisationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRemoveCrmOrganisationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRemoveCrmOrganisationLinkMutation, { data, loading, error }] = useProjectRemoveCrmOrganisationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectRemoveCrmOrganisationLinkMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRemoveCrmOrganisationLinkMutation, ProjectRemoveCrmOrganisationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRemoveCrmOrganisationLinkMutation, ProjectRemoveCrmOrganisationLinkMutationVariables>(ProjectRemoveCrmOrganisationLinkDocument, options);
      }
export type ProjectRemoveCrmOrganisationLinkMutationHookResult = ReturnType<typeof useProjectRemoveCrmOrganisationLinkMutation>;
export type ProjectRemoveCrmOrganisationLinkMutationResult = Apollo.MutationResult<ProjectRemoveCrmOrganisationLinkMutation>;
export type ProjectRemoveCrmOrganisationLinkMutationOptions = Apollo.BaseMutationOptions<ProjectRemoveCrmOrganisationLinkMutation, ProjectRemoveCrmOrganisationLinkMutationVariables>;