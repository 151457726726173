import { useTranslate } from "@tolgee/react";

export type CrmContactType = "INDIVIDUAL" | "COMPANY" | "SUPPLIER"; // TODO: extends CrmOrganisationContactType
export const ALL_CONTACT_TYPES: CrmContactType[] = [
  "INDIVIDUAL",
  "COMPANY",
  "SUPPLIER",
];

export function useCrmContactTypes() {
  const { t } = useTranslate(["OrganisationProfile"]);

  const contactTypeLabels: Record<CrmContactType, string> = {
    INDIVIDUAL: t("Individual", {
      ns: "OrganisationProfile",
    }),
    COMPANY: t("Company", {
      ns: "OrganisationProfile",
    }),
    SUPPLIER: t("Supplier", {
      ns: "OrganisationProfile",
    }),
  };

  const contactTypeOptions: { value: CrmContactType; label: string }[] =
    Object.entries(contactTypeLabels).map(([value, label]) => ({
      value: value as CrmContactType,
      label,
    }));

  return { contactTypeLabels, contactTypeOptions, ALL_CONTACT_TYPES };
}
