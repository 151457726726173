import { useApolloClient } from "@apollo/client";
import { useUserData } from "../../auth/useUserData";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageGrid } from "../../commons/layout/PageGrid";
import { OrganisationDetailsBox } from "../../features/organisations/boxes/OrganisationDetailsBox";
import { OrganisationMembersBox } from "../../features/organisations/boxes/OrganisationMembersBox";
import { OrganisationPaymentInformationBox } from "../../features/organisations/boxes/OrganisationPaymentInformationBox";
import { OrganisationRegistrationDetailsBox } from "../../features/organisations/boxes/OrganisationRegistrationDetailsBox";
import { PimImportations } from "../../features/pimImportations/PimImportations";
import { useOrganisationProfileQuery } from "./OrganisationProfile.generated";

interface Props {
  submenuItems: PageTopbarItem[];
}

export function OrganisationProfile({ submenuItems }: Props) {
  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const query = useOrganisationProfileQuery({
    client,
  });

  const organisation = query.data?.viewer?.organisation;
  const isManufacturer = organisation?.organisationType === "MANUFACTURER";
  const isSupplier = organisation?.organisationType === "SUPPLIER";
  const isCraftsman = organisation?.organisationType === "CRAFTSMAN";

  return (
    <Page title={organisation?.title} submenuItems={submenuItems}>
      {organisation && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 3 }}>
            <>
              <OrganisationDetailsBox
                organisation={organisation}
                isViewerOrganisation
              />
            </>
            <>
              <OrganisationMembersBox
                isViewerOrganisation
                organisation={organisation}
              />
            </>
            <>
              <OrganisationPaymentInformationBox organisation={organisation} />
              {(isCraftsman || isSupplier || isManufacturer) && (
                <OrganisationRegistrationDetailsBox
                  organisation={organisation}
                />
              )}
            </>
            <>
              {(isSupplier || isManufacturer) && (
                <PimImportations supplierId={viewer.organisation.id} />
              )}
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
}
