import { gql, useApolloClient } from "@apollo/client";
import { Modal, useFormatting } from "@msys/ui";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContentText,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import { flatten, uniq } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useLatest } from "react-use";
import {
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../../commons/modals/ConfirmProcess";
import { QUOTE_ITEM_TYPES } from "../../../constants";
import { size } from "../../../../common/MuiThemeProvider";
import { DocType } from "../../../../clients/graphqlTypes";
import { ROOT_ITEM_ID } from "../../../trees-virtual/helpers";
import { ExpandedStore } from "../../../trees-virtual/hooks/useExpandedStore";
import { VirtualTableTree } from "../../../trees-virtual/VirtualTableTree";
import { BareTreeTableItem } from "../../../trees/BareTreeTableItem";
import { TreeToggleAllExpandedButton } from "../../../trees/components/TreeToggleButton";
import {
  getAllParentItems,
  getItemChildren,
  isOrphanedItem,
} from "../../../trees/helpers";
import { ItemComponentProps } from "../../../trees/types";
import { assertNever } from "../../../utils";
import { DecisionContingencyCheckbox } from "../fields/DecisionContingencyCheckbox";
import { DecisionOptionRadioOrCheckbox } from "../fields/DecisionOptionRadioOrCheckbox";
import {
  canItemBeFinalized,
  shouldAskQuestions,
  shouldAskForContingencyOrDecision,
} from "../helpers";
import {
  CanItemBeDecidedUpon_ItemFragment,
  DecisionsInBulkProcess_ItemFragment,
  IsItemPreselected_ItemFragment,
  useDocumentBulkFinaliseMutation,
} from "./DecisionsInBulkProcess.generated";

export interface DecisionsInBulkProcessRef {
  startDecisionsInBulkProcess: () => void;
}

interface Props<Item extends DecisionsInBulkProcess_ItemFragment> {
  docType: DocType;
  docId: string;
  projectId: string | null;
  shouldRenderItem?: (item: Item, allItems: Item[]) => boolean;
  expandedStore: ExpandedStore;
  externalExpandedItemIds: string[] | undefined;
  fetchItems: () => Promise<Item[]>;
  isLoading: boolean;
  canFinalize: boolean;
  handleComplete?: () => Promise<unknown> | unknown;
}

export const DecisionsInBulkProcess = React.memo(
  React.forwardRef(_DecisionsInBulkProcess)
);

function _DecisionsInBulkProcess<
  Item extends DecisionsInBulkProcess_ItemFragment
>(
  {
    shouldRenderItem,
    docId,
    projectId,
    docType,
    expandedStore,
    externalExpandedItemIds,
    fetchItems,
    isLoading,
    canFinalize,
    handleComplete,
  }: Props<Item>,
  forwardedRef: React.Ref<DecisionsInBulkProcessRef>
) {
  const [
    { allDocItems, filteredItems, toFinalizeItemIds, availableItemIds },
    setState,
  ] = React.useState<{
    allDocItems: Item[];
    filteredItems: Item[];
    toFinalizeItemIds: string[];
    availableItemIds: string[];
  }>({
    allDocItems: [],
    filteredItems: [],
    toFinalizeItemIds: [],
    availableItemIds: [],
  });

  const { t } = useTranslate(["Global", "Decisions", "QuoteEdit"]);
  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const [treeContainer, setTreeContainer] = React.useState<HTMLElement | null>(
    null
  );
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const resolveRef = React.useRef<((value: boolean) => void) | null>(null);

  const client = useApolloClient();

  const [bulkFinalize, { loading: bulkFinalizeLoading }] =
    useDocumentBulkFinaliseMutation({ client });

  const handleFinalize = async () => {
    const isConfirmed = await confirmProcessRef.current!.startConfirmProcess({
      title: t("Confirm finalisation", {
        ns: "Decisions",
      }),
      text: t(
        "You are finalising {count, plural, =0 {no decisions} one {one decision} other {# decisions}}. This action is irreversible, would you like to continue?",
        {
          ns: "Decisions",
          count: toFinalizeItemIds.length,
        }
      ),
    });
    if (!isConfirmed) {
      resolveRef.current?.(false);
      return;
    }

    let finalizedAmount = 0;

    try {
      const result = await bulkFinalize({
        variables: {
          input: {
            docId: docId,
            projectId: projectId,
            expandedItemIds: externalExpandedItemIds,
            onlyItemIds: toFinalizeItemIds,
          },
        },
      });
      finalizedAmount =
        result?.data?.documentBulkFinalise.finalizedDecisions.length ?? 0;

      await handleComplete?.();
      enqueueSnackbar(
        t(
          "{count, plural, =0 {No decisions} one {One decision} other {# decisions}} were finalised",
          {
            ns: "Decisions",
            count: finalizedAmount,
          }
        )
      );
      resolveRef.current?.(true);
      setModalOpen(false);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
      resolveRef.current?.(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    resolveRef.current?.(false);
  };

  const handleSelectAll = () => {
    setState(s => ({ ...s, toFinalizeItemIds: s.availableItemIds }));
  };

  const handleUnselectAll = () => {
    setState(s => ({ ...s, toFinalizeItemIds: [] }));
  };

  const canBeFinalized = React.useCallback(
    (item: Item) =>
      canFinalize &&
      isItemPreselected(item) &&
      canItemBeDecidedUpon(item, docType),
    [canFinalize, docType]
  );

  const itemsLatest = useLatest(allDocItems);

  React.useImperativeHandle(forwardedRef, () => ({
    startDecisionsInBulkProcess: (): Promise<boolean> =>
      new Promise<boolean>(resolve => {
        resolveRef.current = resolve;
        setModalOpen(true);
        (async () => {
          const items = await fetchItems();
          const decisionItems = items
            .filter(
              item =>
                isItemPreselected(item) &&
                isItemVisible(item, items, docId, shouldRenderItem)
            )
            .filter((i, idx, arr) => !isOrphanedItem(i, arr))
            .filter(
              i => isItemPreselected(i) && canItemBeDecidedUpon(i, docType)
            );

          if (decisionItems.length === 0) {
            // enqueueSnackbar(
            //   t("No items to decide upon", {
            //     ns: "Decisions",
            //   }),
            //   { variant: "info" }
            // );
            setState({
              allDocItems: items,
              filteredItems: [],
              availableItemIds: [],
              toFinalizeItemIds: [],
            });
            resolve(true);
            return;
          }

          const filteredItems = uniq([
            ...decisionItems,
            ...flatten(
              decisionItems.map(item => getAllParentItems(item, items))
            ),
            ...flatten(
              decisionItems
                .filter(i => i.type === "decision")
                .map(item =>
                  getItemChildren(item, items).filter(item =>
                    isItemVisible(item, items, docId, shouldRenderItem)
                  )
                )
            ),
          ]).map(item => {
            if (!item.isRootItem) return item;
            return { ...item, parentId: ROOT_ITEM_ID };
          });

          const availableIds = decisionItems
            .filter(canBeFinalized)
            .map(i => i.id);

          setState({
            allDocItems: items,
            filteredItems: filteredItems,
            availableItemIds: availableIds,
            toFinalizeItemIds: availableIds,
          });
        })();
      }),
  }));

  const EmptyRow = React.useMemo(() => <TreeEmptyRow />, []);
  const HeaderRow = React.useMemo(() => <TreeHeaderRow />, []);
  const TreeItem = React.useMemo(
    () =>
      createTreeTableItem(
        projectId,
        docId,
        docType,
        toFinalizeItemIds,
        (fn: (existingIds: string[]) => string[]) =>
          setState(s => ({ ...s, toFinalizeItemIds: fn(s.toFinalizeItemIds) })),
        canBeFinalized,
        externalExpandedItemIds
      ),
    [
      projectId,
      docId,
      docType,
      toFinalizeItemIds,
      setState,
      canBeFinalized,
      externalExpandedItemIds,
    ]
  );

  return isModalOpen && docId ? (
    <Modal
      title={t("Finalize decisions", { ns: "Decisions" })}
      handleClose={handleClose}
      isLoading={isLoading}
      dialogProps={{ maxWidth: "md" }}
      contentContainerRef={setTreeContainer}
      dialogActions={
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={handleClose}
            disabled={bulkFinalizeLoading}
          >
            {t("Cancel", { ns: "Global" })}
          </Button>
          <LoadingButton
            loading={bulkFinalizeLoading}
            variant="contained"
            color="primary"
            onClick={handleFinalize}
            disabled={toFinalizeItemIds.length === 0}
          >
            {t(
              "Finalize {count, plural, =0 {no decisions} one {one decision} other {# decisions}}",
              {
                ns: "Decisions",
                count: toFinalizeItemIds.length,
              }
            )}
          </LoadingButton>
        </DialogActions>
      }
    >
      {filteredItems.length > 0 ? (
        <>
          <DialogContentText>
            {t(
              "Use select boxes to decide which decisions should be finalised. You can not change selections in the decisions options in this window.",
              { ns: "Decisions" }
            )}
          </DialogContentText>
          <Stack mb={1} direction="row" alignItems="center" spacing={1}>
            <TreeToggleAllExpandedButton
              type="button"
              areAllItemsExpanded={expandedStore.areAllItemsExpanded}
              setAllItemsExpanded={expandedStore.setAllItemsExpanded}
            />
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={handleSelectAll}
              disabled={bulkFinalizeLoading}
            >
              {t("Select all", { ns: "Global" })}
            </Button>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={handleUnselectAll}
              disabled={bulkFinalizeLoading}
            >
              {t("Unselect all", { ns: "Global" })}
            </Button>
          </Stack>
          <VirtualTableTree<Item, false>
            key={expandedStore.expandedItemIds?.join()}
            minWidth={720}
            docId={null}
            projectId={null}
            items={filteredItems}
            allItems={allDocItems}
            rootItemId={ROOT_ITEM_ID}
            selectedItemId={null}
            enableCreating={false}
            enableDragging={false}
            documentItemTypes={QUOTE_ITEM_TYPES}
            container={treeContainer}
            itemComponent={TreeItem}
            inputComponent={nullFn}
            EmptyRow={EmptyRow}
            HeaderRow={HeaderRow}
            headerTitle={t("Title", {
              ns: "QuoteEdit",
            })}
            allowHaveChildren={falseFn}
            shouldRenderCreateInput={falseFn}
            expandedStore={expandedStore}
          />
        </>
      ) : (
        <Box py={2}>
          <Typography variant="body2" color="grey.400" textAlign="center">
            {t("No items to decide upon", { ns: "Decisions" })}
          </Typography>
        </Box>
      )}

      <ConfirmProcess ref={confirmProcessRef} />
    </Modal>
  ) : null;
}

const TreeHeaderRow = () => {
  const { t } = useTranslate(["QuoteItem"]);
  return (
    <FormRow style={{ marginLeft: 8 }}>
      <Divider
        orientation="vertical"
        style={{ height: size.tableItemFullHeight }}
      />
      {/* Quantity, time & price per unit  */}
      <div style={{ width: 228 }}>
        <Typography>{t("Quantity", { ns: "QuoteItem" })}</Typography>
      </div>
      <Divider
        orientation="vertical"
        style={{ height: size.tableItemFullHeight }}
      />
      {/* Total */}
      <div style={{ width: 174 }}>
        <Typography style={{ fontWeight: "bold" }}>
          {t("Total", { ns: "QuoteItem" })}
        </Typography>
      </div>
    </FormRow>
  );
};

const TreeEmptyRow = () => (
  <FormRow style={{ marginLeft: 8 }}>
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Quantity, time & price per unit  */}
    <div style={{ width: 228 }} />
    <Divider
      orientation="vertical"
      style={{ height: size.tableItemFullHeight }}
    />
    {/* Total */}
    <div style={{ width: 174 }} />
  </FormRow>
);

const FormRow = styled("div")`
  height: ${size.tableItemFullHeight}px;

  & {
    position: relative;
    display: flex;
    align-items: center;
  }

  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }

  & > * + * {
    margin-left: 4px;
  }
`;

const CalculationRow = <T extends DecisionsInBulkProcess_ItemFragment>({
  item,
}: {
  item: T;
}) => {
  const { getFormattedPrice, getFormattedDuration } = useFormatting();

  const calculatedItem =
    item?.proposedCalculation &&
    (item?.type === "paid" ||
      item?.type === "section" ||
      item?.type === "decision")
      ? item.proposedCalculation
      : undefined;

  switch (item.type) {
    case "paid": {
      const calculateForQuote = item.proposedCalculation;
      if (!calculateForQuote) return <TreeEmptyRow />;
      return (
        <FormRow style={{ marginLeft: 8 }}>
          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Quantity, time & price per unit  */}
          <div style={{ width: 60 }}>{calculatedItem?.quantity}x</div>
          <div style={{ width: 60 }}>
            {getFormattedDuration(calculatedItem?.timePerUnit ?? 0)}h
          </div>
          <div style={{ width: 100, textAlign: "right", paddingRight: 4 }}>
            {getFormattedPrice(calculatedItem?.pricePerUnit ?? 0)}
          </div>

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Totals */}
          <div
            style={{
              width: 60,
              fontWeight:
                (calculatedItem?.timeTotal ?? 0) > 0 ? "bold" : "normal",
            }}
          >
            {getFormattedDuration(calculatedItem?.timeTotal ?? 0)}h
          </div>
          <div
            style={{
              width: 110,
              fontWeight:
                (calculatedItem?.priceSubTotal ?? 0) > 0 ? "bold" : "normal",
              textAlign: "right",
            }}
          >
            {getFormattedPrice(calculatedItem?.priceSubTotal ?? 0)}
          </div>
        </FormRow>
      );
    }
    case "section":
    case "decision": {
      const calculateForQuote = item.proposedCalculation;
      if (!calculateForQuote) return <TreeEmptyRow />;
      if (
        calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return <TreeEmptyRow />;
      }
      return (
        <FormRow style={{ marginLeft: 8 }}>
          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Quantity, time & price per unit  */}
          {calculatedItem?.quantity !== 1 ? (
            <>
              <div style={{ width: 60 }}>{calculatedItem?.quantity}x</div>
              <div style={{ width: 60 }}>
                {getFormattedDuration(calculatedItem?.timePerUnit ?? 0)}h
              </div>
              <div style={{ width: 100, textAlign: "right", paddingRight: 4 }}>
                {getFormattedPrice(calculatedItem?.pricePerUnit ?? 0)}
              </div>
            </>
          ) : (
            <div style={{ width: 228 }} />
          )}

          <Divider
            orientation="vertical"
            style={{ height: size.tableItemFullHeight }}
          />

          {/* Totals */}
          <div
            style={{
              width: 60,
              fontWeight:
                (calculatedItem?.timeTotal ?? 0) > 0 ? "bold" : "normal",
            }}
          >
            {getFormattedDuration(calculatedItem?.timeTotal ?? 0)}h
          </div>
          <div
            style={{
              width: 110,
              fontWeight:
                (calculatedItem?.priceSubTotal ?? 0) > 0 ? "bold" : "normal",
              textAlign: "right",
            }}
          >
            {getFormattedPrice(calculatedItem?.priceSubTotal ?? 0)}
          </div>
        </FormRow>
      );
    }
    case "unpaid":
    case "defect": {
      return <TreeEmptyRow />;
    }
    default:
      throw assertNever(item.type);
  }
};

const createTreeTableItem =
  <T extends DecisionsInBulkProcess_ItemFragment>(
    projectId: string | null,
    docId: string,
    docType: DocType,
    toFinalizeItemIds: string[],
    setToFinalizeItemIds: (fn: (existingIds: string[]) => string[]) => void,
    canItemBeFinalized: (item: T) => boolean,
    expandedItemIds: string[] | undefined
  ) =>
  (itemProps: ItemComponentProps<T>) => {
    const IconRightButtons = React.useMemo(
      () => (
        <>
          {itemProps.item.decisionIsContingentItem && (
            <DecisionContingencyCheckbox
              projectId={projectId}
              docId={docId}
              itemId={itemProps.item.id}
              item={itemProps.item}
              disabled={true}
            />
          )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={docId}
                item={itemProps.item}
                disabled={true}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const IconLeftButtons = React.useMemo(
      () =>
        isItemPreselected(itemProps.item) &&
        canItemBeDecidedUpon(itemProps.item, docType) ? (
          <Checkbox
            checked={toFinalizeItemIds.includes(itemProps.item.id)}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              event.preventDefault();
              event.stopPropagation();
              if (checked) {
                setToFinalizeItemIds(ids => [...ids, itemProps.item.id]);
              } else {
                setToFinalizeItemIds(ids =>
                  ids.filter(id => id !== itemProps.item.id)
                );
              }
            }}
            disabled={!canItemBeFinalized(itemProps.item)}
            size="small"
            sx={{ flexGrow: 0, flexShrink: 0, mr: 1 }}
          />
        ) : null,
      [itemProps.item]
    );

    const FormRow = React.useMemo(
      () => <CalculationRow item={itemProps.item} />,
      [itemProps.item]
    );

    return (
      <BareTreeTableItem
        docAgreement={"NONE"}
        FormRow={FormRow}
        IconRightButtons={IconRightButtons}
        IconLeftButtons={IconLeftButtons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        title={
          itemProps.item.pendingChangeAttributes?.title ?? itemProps.item.title
        }
        pathForPdf={
          itemProps.item.pendingChangeAttributes?.pathForPdf ??
          itemProps.item.pathForPdf
        }
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        subcontractPath={itemProps.subcontractPath}
        collapseButton={itemProps.collapseButton}
      />
    );
  };

const falseFn = () => false;
const nullFn = () => null;

const isItemVisible = <Item extends DecisionsInBulkProcess_ItemFragment>(
  item: Item,
  allDocItems: Item[],
  docId: string | null,
  shouldRenderItem?: (item: Item, allItems: Item[]) => boolean
) =>
  shouldRenderItem?.(item, allDocItems) !== false &&
  // allow make decisions only for items of current document, not for unnested items
  item.originDocId === docId;

const canItemBeDecidedUpon = (
  item: CanItemBeDecidedUpon_ItemFragment,
  docType: DocType
) =>
  shouldAskQuestions(item, docType) &&
  shouldAskForContingencyOrDecision(item, undefined) &&
  canItemBeFinalized(item);

export const areItemsAllowedToBulkDecision = <
  Item extends DecisionsInBulkProcess_ItemFragment
>(
  allDocItems: Item[],
  docId: string | null,
  docType: DocType,
  shouldRenderItem?: (item: Item, allItems: Item[]) => boolean
) =>
  allDocItems
    .filter(
      item =>
        isItemPreselected(item) &&
        isItemVisible(item, allDocItems, docId, shouldRenderItem)
    )
    .filter((i, idx, arr) => !isOrphanedItem(i, arr))
    .filter(i => isItemPreselected(i) && canItemBeDecidedUpon(i, docType))
    .length > 0;

const isItemPreselected = (item: IsItemPreselected_ItemFragment) => {
  return (
    !item.deletedAt &&
    !item.isHidden &&
    !item.isParentDecisionNotPreselected &&
    !item.isParentContingencyNotPreselected &&
    !(
      item.decisionIsContingentItem &&
      item.decisionContingentItemPreselection !== true &&
      item.decisionContingentItemPreselection !== false
    )
  );
};
