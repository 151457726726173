/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationSettingBillOfMaterialsExportSettingsFragment = { __typename: 'OrganisationSettingBillOfMaterialsExportSettings', dataFormat: Types.BillOfMaterialsDataFormat, sections: Array<Types.BillOfMaterialsSection>, csvFields: Array<Types.BillOfMaterialsField>, pdfFields: Array<Types.BillOfMaterialsField> };

export type OrganisationBillOfMaterialsSettingsBox_SettingsFragment = { __typename: 'OrganisationSetting', id: string, billOfMaterialsExportSettings: { __typename: 'OrganisationSettingBillOfMaterialsExportSettings', dataFormat: Types.BillOfMaterialsDataFormat, sections: Array<Types.BillOfMaterialsSection>, csvFields: Array<Types.BillOfMaterialsField>, pdfFields: Array<Types.BillOfMaterialsField> } };

export type OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationVariables = Types.Exact<{
  input: Types.OrganisationSettingsUpdateInput;
}>;


export type OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation = { updateOrganisationSettings: { __typename: 'OrganisationSettingsUpdateResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, billOfMaterialsExportSettings: { __typename: 'OrganisationSettingBillOfMaterialsExportSettings', dataFormat: Types.BillOfMaterialsDataFormat, sections: Array<Types.BillOfMaterialsSection>, csvFields: Array<Types.BillOfMaterialsField>, pdfFields: Array<Types.BillOfMaterialsField> } } } };

export const OrganisationSettingBillOfMaterialsExportSettingsFragmentDoc = gql`
    fragment OrganisationSettingBillOfMaterialsExportSettings on OrganisationSettingBillOfMaterialsExportSettings {
  dataFormat
  sections
  csvFields
  pdfFields
}
    `;
export const OrganisationBillOfMaterialsSettingsBox_SettingsFragmentDoc = gql`
    fragment OrganisationBillOfMaterialsSettingsBox_Settings on OrganisationSetting {
  id
  billOfMaterialsExportSettings {
    ...OrganisationSettingBillOfMaterialsExportSettings
  }
}
    ${OrganisationSettingBillOfMaterialsExportSettingsFragmentDoc}`;
export const OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsDocument = gql`
    mutation OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettings($input: OrganisationSettingsUpdateInput!) {
  updateOrganisationSettings(input: $input) {
    organisationSettings {
      id
      ...OrganisationBillOfMaterialsSettingsBox_Settings
    }
  }
}
    ${OrganisationBillOfMaterialsSettingsBox_SettingsFragmentDoc}`;
export type OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationFn = Apollo.MutationFunction<OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation, OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationVariables>;

/**
 * __useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation__
 *
 * To run a mutation, you first call `useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationBillOfMaterialsSettingsBoxModifyOrganisationSettingsMutation, { data, loading, error }] = useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation, OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation, OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationVariables>(OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsDocument, options);
      }
export type OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationHookResult = ReturnType<typeof useOrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation>;
export type OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationResult = Apollo.MutationResult<OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation>;
export type OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationOptions = Apollo.BaseMutationOptions<OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutation, OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettingsMutationVariables>;