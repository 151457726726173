import { StatusChip, StatusLabel } from "@msys/ui";
import React from "react";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes";
import {
  projectStateChipColors,
  projectStateTextColors,
  useProjectStates,
} from "../useProjectStates";

interface Props {
  projectState: ProjectStateMachineStatus;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
  component?: "div" | "span";
}

export const ProjectStatusBadge = ({
  projectState,
  align,
  small,
  component,
}: Props) => {
  const { projectStateLabels } = useProjectStates();
  return (
    <StatusLabel
      color={projectStateTextColors[projectState]}
      align={align}
      small={small}
      component={component}
    >
      {projectStateLabels[projectState]}
    </StatusLabel>
  );
};

export const ProjectStatusChip = ({
  projectState,
  size,
}: {
  projectState: ProjectStateMachineStatus;
  size?: React.ComponentProps<typeof StatusChip>["size"];
}) => {
  const { projectStateLabels } = useProjectStates();
  return (
    <StatusChip
      label={projectStateLabels[projectState]}
      color={projectStateChipColors[projectState]}
      size={size}
    />
  );
};
