/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { VanMarckeProfitability_ItemFragmentDoc } from './calculateProfitability.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VanMarckeProfitabilityModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type VanMarckeProfitabilityModalQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, isRootItem: boolean, type: Types.ItemType, product?: { __typename: 'ItemProduct', articleNumber?: string | null } | null, proposedCalculation?: { __typename: 'ItemCalculation', quantity: number, priceSubTotal: number } | null }> } | null } };


export const VanMarckeProfitabilityModalDocument = gql`
    query VanMarckeProfitabilityModal($projectId: ID!, $quoteId: ID!) {
  quote(projectId: $projectId, docId: $quoteId) {
    ... on QuoteResult {
      quote {
        id
        items {
          id
          originVersionNumber
          ...VanMarckeProfitability_Item
        }
      }
    }
  }
}
    ${VanMarckeProfitability_ItemFragmentDoc}`;

/**
 * __useVanMarckeProfitabilityModalQuery__
 *
 * To run a query within a React component, call `useVanMarckeProfitabilityModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useVanMarckeProfitabilityModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVanMarckeProfitabilityModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useVanMarckeProfitabilityModalQuery(baseOptions: Apollo.QueryHookOptions<VanMarckeProfitabilityModalQuery, VanMarckeProfitabilityModalQueryVariables> & ({ variables: VanMarckeProfitabilityModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VanMarckeProfitabilityModalQuery, VanMarckeProfitabilityModalQueryVariables>(VanMarckeProfitabilityModalDocument, options);
      }
export function useVanMarckeProfitabilityModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VanMarckeProfitabilityModalQuery, VanMarckeProfitabilityModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VanMarckeProfitabilityModalQuery, VanMarckeProfitabilityModalQueryVariables>(VanMarckeProfitabilityModalDocument, options);
        }
export function useVanMarckeProfitabilityModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VanMarckeProfitabilityModalQuery, VanMarckeProfitabilityModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VanMarckeProfitabilityModalQuery, VanMarckeProfitabilityModalQueryVariables>(VanMarckeProfitabilityModalDocument, options);
        }
export type VanMarckeProfitabilityModalQueryHookResult = ReturnType<typeof useVanMarckeProfitabilityModalQuery>;
export type VanMarckeProfitabilityModalLazyQueryHookResult = ReturnType<typeof useVanMarckeProfitabilityModalLazyQuery>;
export type VanMarckeProfitabilityModalSuspenseQueryHookResult = ReturnType<typeof useVanMarckeProfitabilityModalSuspenseQuery>;
export type VanMarckeProfitabilityModalQueryResult = Apollo.QueryResult<VanMarckeProfitabilityModalQuery, VanMarckeProfitabilityModalQueryVariables>;