import { CardContainer, ListItem } from "@msys/ui";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { useRestrictionFilter } from "../../../auth/useRestrictionFilter";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam";
import { getUrlSearchParamsByParamsName } from "../../../commons/hooks/useStateWithUrlParams";
import { TabsWithRestriction } from "../../../commons/TabsWithRestriction";
import { QuoteIcon } from "../../quotes/QuoteIcon";
import { QuoteListItem } from "../../quotes/QuoteListItem";
import {
  QuoteListItem_IncomingQuoteFragment,
  QuoteListItem_OutgoingQuoteFragment,
} from "../../quotes/QuoteListItem.generated";

interface Props {
  crmCompanyId: string;
  quotesOutgoing: (QuoteListItem_OutgoingQuoteFragment & {
    projectId: string;
  })[];
  quotesOutgoingTotalCount: number;
  quotesIncoming: (QuoteListItem_IncomingQuoteFragment & {
    projectId: string;
  })[];
  quotesIncomingTotalCount: number;
  isLoading: boolean;
}

type QuickFilterValue = "OUTGOING" | "INCOMING";

export const CrmCompanyQuotesBox = ({
  crmCompanyId,
  quotesOutgoing,
  quotesOutgoingTotalCount,
  quotesIncoming,
  quotesIncomingTotalCount,
  isLoading,
}: Props) => {
  const { t } = useTranslate(["Quotes", "Global"]);

  const initialOptions = [
    {
      value: "OUTGOING" as const,
      label: t("Outgoing", { ns: "Quotes" }),
      count: quotesOutgoingTotalCount,
      capability: "QUOTING" as const,
    },
    {
      value: "INCOMING" as const,
      label: t("Incoming", { ns: "Quotes" }),
      count: quotesIncomingTotalCount,
    },
  ];
  const restrictionFilter = useRestrictionFilter();
  const options = initialOptions.filter(restrictionFilter);

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "quotesBoxQuickFilter",
    options[0].value
  );
  const quotes =
    quickFilter === "OUTGOING" && options.some(o => o.value === "OUTGOING")
      ? quotesOutgoing
      : quickFilter === "INCOMING"
        ? quotesIncoming
        : [];

  const quotesPath = `/documents/quotes`;

  return (
    <CardContainer
      Icon={<QuoteIcon />}
      title={t("Quotes", { ns: "Quotes" })}
      isLoading={isLoading}
      isExpandable
    >
      <Box p={1}>
        <TabsWithRestriction
          condensed
          useSelectOnMobile
          options={initialOptions}
          value={quickFilter}
          onChange={(newQuickFilter: QuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Box>
      {quotes.length === 0 && (
        <Box p={1} py={3}>
          <Typography align="center" sx={{ color: "grey.500" }} variant="body2">
            {t("There are no items to display", { ns: "Global" })}
          </Typography>
        </Box>
      )}
      <Stack width="100%" direction="column" spacing={0}>
        {quotes.length > 0 &&
          quotes.map(quote => (
            <ListItem
              key={quote.id}
              component={Link}
              //@ts-ignore
              to={`/projects/${quote.projectId}/quotes/${quote.id}`}
            >
              <QuoteListItem quote={quote} />
            </ListItem>
          ))}
      </Stack>
      {quotes.length > 0 && (
        <Box>
          <Button
            component={Link}
            to={{
              pathname: quotesPath,
              search: getUrlSearchParamsByParamsName(
                {
                  filterByDocActors: [
                    { type: "CLIENT", crmOrganisationId: crmCompanyId },
                    { type: "CONTRACTEE", crmOrganisationId: crmCompanyId },
                  ],
                },
                "filters",
                { tab: quickFilter }
              ).toString(),
            }}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </CardContainer>
  );
};
