/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationVariables = Types.Exact<{
  input: Types.PimSetSupplierProductsFavouriteInput;
}>;


export type OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation = { pimSetSupplierProductsFavourite: { __typename: 'PimSetSupplierProductsFavouriteResult', products: Array<{ __typename: 'PimSetSupplierProductsFavouriteProduct', product: { __typename: 'PimCustomSupplierProduct', id: string, organisationFavourite: boolean } | { __typename: 'PimSupplierProduct', id: string, organisationFavourite: boolean }, productSearchResult: { __typename: 'PimCustomSupplierProductSearchResult', id: string, organisationFavourite: boolean } | { __typename: 'PimSupplierProductSearchResult', id: string, organisationFavourite: boolean } } | null> } };


export const OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteDocument = gql`
    mutation OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavourite($input: PimSetSupplierProductsFavouriteInput!) {
  pimSetSupplierProductsFavourite(input: $input) {
    products {
      product {
        id
        ... on PimCustomSupplierProduct {
          id
          organisationFavourite
        }
        ... on PimSupplierProduct {
          id
          organisationFavourite
        }
      }
      productSearchResult {
        id
        ... on PimCustomSupplierProductSearchResult {
          id
          organisationFavourite
        }
        ... on PimSupplierProductSearchResult {
          id
          organisationFavourite
        }
      }
    }
  }
}
    `;
export type OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationFn = Apollo.MutationFunction<OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation, OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationVariables>;

/**
 * __useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation__
 *
 * To run a mutation, you first call `useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationSupplierProductFavouriteButtonPimSetSupplierProductsFavouriteMutation, { data, loading, error }] = useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation, OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation, OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationVariables>(OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteDocument, options);
      }
export type OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationHookResult = ReturnType<typeof useOrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation>;
export type OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationResult = Apollo.MutationResult<OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation>;
export type OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationOptions = Apollo.BaseMutationOptions<OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutation, OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavouriteMutationVariables>;