import { PopoverOpenButton, useSyncStates } from "@msys/ui";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { FilterProps } from "../../../commons/filters";
import {
  FilterChip,
  FilterChipProps,
} from "../../../commons/filters/FilterChip";
import { ProjectFilterKind } from "../../../../clients/graphqlTypes";
import { useProjectKinds } from "../useProjectKinds";

export function FilterProjectKindForm({
  value: externalValue,
  setValue: setExternalValue,
}: FilterProps<ProjectFilterKind[]>) {
  const { t } = useTranslate(["Global"]);
  const { projectKindOptions } = useProjectKinds();
  const [value, setValue, error] = useSyncStates(
    externalValue,
    setExternalValue,
    value => {
      if (value.length === 0)
        return t("Please select at least one value", { ns: "Global" });
    }
  );
  return (
    <Box
      p={1}
      pr={2}
      sx={theme => ({ width: `${theme.layout.filters.popoverWidth.md}px` })}
    >
      <FormControl
        component="fieldset"
        variant="standard"
        error={Boolean(error)}
      >
        <FormGroup>
          {projectKindOptions.map((option, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  checked={value.includes(option.value)}
                  onChange={(event, checked) => {
                    if (checked) {
                      setValue(Array.from(new Set([...value, option.value])));
                    } else {
                      setValue(value.filter(v => v !== option.value));
                    }
                  }}
                />
              }
              label={<Typography variant="body1">{option.label}</Typography>}
              sx={{ ml: 0, mr: 0 }}
              key={`${option.value}-${index}`}
            />
          ))}
        </FormGroup>
        {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export function FilterProjectKindButton(
  props: FilterProps<ProjectFilterKind[]>
) {
  const { t } = useTranslate("ProjectTypes");
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <PopoverOpenButton
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      content={<FilterProjectKindForm {...props} />}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Button
        size="extra-small"
        variant="text"
        color="primary"
        sx={{ textTransform: "none" }}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: "transform 0.2s ease-out",
              ...(open ? { transform: `rotate(-180deg)` } : undefined),
            }}
          />
        }
      >
        {t("Type")}
      </Button>
    </PopoverOpenButton>
  );
}

export function FilterProjectKindChip(
  props: Omit<FilterChipProps<ProjectFilterKind>, "label" | "getValueLabel">
) {
  const { t } = useTranslate("ProjectTypes");
  const { projectKindLabels } = useProjectKinds();

  return (
    <FilterChip
      label={t("Type")}
      resetValue={null}
      setValue={props.setValue}
      value={props.value}
      getValueLabel={value => projectKindLabels[value]}
    />
  );
}
