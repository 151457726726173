import { StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import React, { FC } from "react";
import { TimeReport } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

interface Props {
  report: Pick<TimeReport, "approvedAt">;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: boolean;
}

export const TimeReportStatusBadge: FC<Props> = ({ report, align, small }) => {
  const { t } = useTranslate("TimeReports");
  const theme = useTheme();

  if (report.approvedAt) {
    return (
      <StatusLabel
        align={align}
        small={small}
        color={theme.palette.success.main}
      >
        {t("Approved")}
      </StatusLabel>
    );
  }

  return (
    <StatusLabel align={align} small={small} color={theme.palette.grey[600]}>
      {t("Draft")}
    </StatusLabel>
  );
};
