/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type InvoiceExtraItemDetailsModal_InvoiceFragment = { __typename: 'Invoice', id: string };

export type InvoiceExtraItemDetailsModal_QuoteFragment = { __typename: 'Quote', id: string, vatRate: number, vatInfo: { __typename: 'VatInfo', rateType: Types.VatRateType, countryCode: string } };

export type InvoiceExtraItemDetailsModal_InvoiceExtraItemFragment = { __typename: 'InvoiceExtraItem', id: string, title: string, description: string, quantity: number, quantityUnit: Types.QuantityUnit, pricePerUnit: number };

export const InvoiceExtraItemDetailsModal_InvoiceFragmentDoc = gql`
    fragment InvoiceExtraItemDetailsModal_Invoice on Invoice {
  id
}
    `;
export const InvoiceExtraItemDetailsModal_QuoteFragmentDoc = gql`
    fragment InvoiceExtraItemDetailsModal_Quote on Quote {
  id
  vatRate
  vatInfo {
    rateType
    countryCode
  }
}
    `;
export const InvoiceExtraItemDetailsModal_InvoiceExtraItemFragmentDoc = gql`
    fragment InvoiceExtraItemDetailsModal_InvoiceExtraItem on InvoiceExtraItem {
  id
  title
  description
  quantity
  quantityUnit
  pricePerUnit
}
    `;