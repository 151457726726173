import {
  CardContainer,
  LabeledDateValue,
  LabeledSelectValue,
  LabeledSpecializedValue,
  TextWithBreaks,
  TruncatedTextValue,
  useScreenWidth,
} from "@msys/ui";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EuroIcon from "@mui/icons-material/Euro";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { LabeledContactValue } from "../../../commons/labeled-values/LabeledContactValue";
import { Stack } from "../../../commons/layout/Stack";
import { LabeledAddressValue } from "../../addresses/LabeledAddressValue";
import { useProjectTypes } from "../../projects/useProjectTypes";
import { LabeledCategoriesValue } from "../../skill-categories/LabeledCategoriesValue";
import { RequestOverviewHeaderBoxFragment } from "./RequestOverviewHeaderBox.generated";

export const RequestOverviewHeaderBox = ({
  request,
}: {
  request: RequestOverviewHeaderBoxFragment;
}) => {
  const { t } = useTranslate(["RequestOverview", "Global"]);

  const { isMinTablet } = useScreenWidth();

  const { projectTypeOptions: opportunityTypeOptions } = useProjectTypes();

  return (
    <CardContainer>
      <Stack
        p={isMinTablet ? 2 : 1}
        flexDirection="column"
        spacing={isMinTablet ? 2 : 1}
      >
        <Stack flexDirection="column" spacing={1}>
          <Typography variant="h2">
            {t("Quote request", {
              ns: "RequestOverview",
            })}
          </Typography>
          {request.instructions && (
            <Typography>
              <TextWithBreaks text={request.instructions} />
            </Typography>
          )}
        </Stack>

        <Divider />

        {request.description && (
          <Box>
            <TruncatedTextValue
              text={request.description}
              lines={3}
              showMoreLabel={t("Show more", { ns: "Global" })}
              showLessLabel={t("Show less", { ns: "Global" })}
              notSetLabel={t("Not set", { ns: "Global" })}
            />
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={6} md={4} xl={3}>
            <LabeledCategoriesValue
              label={t("Categories", {
                ns: "RequestOverview",
              })}
              value={request.categories}
              chipSize="small"
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <LabeledSelectValue
              label={t("Project type", {
                ns: "RequestOverview",
              })}
              options={opportunityTypeOptions}
              value={request.type}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <LabeledAddressValue
              label={t("Project location", {
                ns: "RequestOverview",
              })}
              address={request.address}
              variant="body2"
              icon={LocationOnIcon}
              hideStreetLines={!request.marketplaceFullAddress}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <LabeledContactValue
              label={t("Client", {
                ns: "RequestOverview",
              })}
              contact={request.requestorDoc.contractee}
              icon={PersonIcon}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <LabeledSpecializedValue
              label={t("Budget", {
                ns: "RequestOverview",
              })}
              value={request.budget}
              type="money"
              icon={EuroIcon}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <LabeledDateValue
              label={t("Earliest project start", {
                ns: "RequestOverview",
              })}
              value={request.earliestStart}
              icon={CalendarTodayIcon}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <LabeledDateValue
              label={t("Work deadline", {
                ns: "RequestOverview",
              })}
              value={request.deadline}
              icon={CalendarTodayIcon}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          </Grid>
        </Grid>
      </Stack>
    </CardContainer>
  );
};
