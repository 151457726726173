import { SelectOption } from "../inputs/Select";
import { LabeledValue } from "./LabeledValue";

interface Props<Value> extends React.ComponentProps<typeof LabeledValue> {
  label: string;
  value?: Value;
  options: SelectOption<Value>[];
  notSetText: string;
}

export const LabeledSelectValue = <Value,>({
  label,
  value,
  options,
  notSetText,
  ...props
}: Props<Value>) => {
  const selectedOption = options.find(o => o.value === value);

  return (
    <LabeledValue label={label} notSet={!selectedOption} {...props}>
      {selectedOption ? selectedOption.label : notSetText}
    </LabeledValue>
  );
};
