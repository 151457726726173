import { useApolloClient } from "@apollo/client";
import { getNameWithCopy } from "@msys/common";
import { MenuButton, MenuItemWithIcon } from "@msys/ui";
import BugReportIcon from "@mui/icons-material/BugReport";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteIcon from "@mui/icons-material/Delete";
import { Divider, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { ExportTemplatesMenuItem } from "../buttons/ExportTemplatesMenuItem";
import {
  useDeleteQuoteTemplateMutation,
  useDuplicateQuoteTemplateMutation,
} from "./TemplateQuotes.generated";
import { TemplateQuoteTemplateMenu_QuoteTemplateFragment } from "./TemplatesQuoteTemplateMenu.generated";
import { QuoteTemplateRestoreTemplateVersionMenuItem } from "./buttons/QuoteTemplateRestoreTemplateVersionMenuItem";
import { ToggleQuoteTemplateBindingMenuItem } from "./buttons/ToggleQuoteTemplateBindingMenuItem";
import { TemplateBulkUpdateProductsButton } from "../buttons/TemplateBulkUpdateProductsButton";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";

interface Props {
  doc: TemplateQuoteTemplateMenu_QuoteTemplateFragment;
  pathToDocList: string;
  pathToDocVersion: string;
  onDuplicateRefetchQueries?: string[];
  onUpdateDataRefetchQueries?: string[];
}

/* TODO: enable for Store templates */
export const TemplatesQuoteTemplateMenu = ({
  doc,
  pathToDocList,
  pathToDocVersion,
  onDuplicateRefetchQueries,
  onUpdateDataRefetchQueries,
}: Props) => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const { t } = useTranslate(["Quote", "Global"]);

  const { enqueueSnackbar } = useSnackbar();

  const [
    duplicateQuoteTemplateMutation,
    { loading: duplicateQuoteTemplateLoading },
  ] = useDuplicateQuoteTemplateMutation({
    client,
  });

  const [deleteQuoteTemplateMutation, { loading: deleteQuoteTemplateLoading }] =
    useDeleteQuoteTemplateMutation({
      client,
    });

  const isPublishedWithOtherOrgs =
    doc.hasAnyPublishedVersion &&
    (doc.sharedPublicly || doc.sharedWithOrganisations.length > 0);
  const isUsedInOtherTemplates = doc.usedInOtherTemplates;

  const duplicateQuoteTemplate = async () => {
    try {
      const res = await duplicateQuoteTemplateMutation({
        variables: {
          input: {
            title: getNameWithCopy(
              doc.title,
              t("copy", {
                ns: "Global",
              })
            ),
            quoteTemplateId: doc.id,
          },
        },
        refetchQueries: onDuplicateRefetchQueries,
        awaitRefetchQueries: true,
      });

      const newQuoteTemplateId =
        res?.data?.duplicateQuoteTemplate?.quoteTemplate?.id;

      if (!newQuoteTemplateId) return;

      navigate(`${pathToDocList}/${newQuoteTemplateId}/latest`);
      enqueueSnackbar(
        t("Quote template duplicated", {
          ns: "Quote",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const deleteQuoteTemplate = async () => {
    if (isPublishedWithOtherOrgs || isUsedInOtherTemplates) return;
    try {
      await deleteQuoteTemplateMutation({
        variables: {
          input: {
            quoteTemplateId: doc.id,
          },
        },
      });

      navigate(pathToDocList, { replace: true });
      enqueueSnackbar(
        t("Quote template deleted", {
          ns: "Quote",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <MenuButton>
      <RestrictedByOrganisationPermissionWithDebug
        permission="MANAGE_TEMPLATE_SETTINGS"
        otherwise={
          <ToggleQuoteTemplateBindingMenuItem
            doc={doc}
            docId={doc.id}
            disabled={true}
          />
        }
      >
        <ToggleQuoteTemplateBindingMenuItem doc={doc} docId={doc.id} />
      </RestrictedByOrganisationPermissionWithDebug>
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
        <Divider />
        <ExportTemplatesMenuItem
          docIds={[
            {
              docId: doc.id,
              versionNumber: doc.resolvedAsReadModelVersionNumber ?? null,
            },
          ]}
          title={t("Export template", {
            ns: "Quote",
          })}
        />
      </RestrictedByOrganisationPermissionWithDebug>
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
        <MenuItemWithIcon
          icon={<ControlPointDuplicateIcon />}
          onClick={duplicateQuoteTemplate}
          disabled={duplicateQuoteTemplateLoading}
        >
          {t("Duplicate template", {
            ns: "Quote",
          })}
        </MenuItemWithIcon>
      </RestrictedByOrganisationPermissionWithDebug>
      <RestrictedByCapabilityWithDebug capability="PIM">
        <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
          <TemplateBulkUpdateProductsButton docId={doc.id} />
        </RestrictedByOrganisationPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
      {doc.hasAnyPublishedVersion && (
        <QuoteTemplateRestoreTemplateVersionMenuItem
          docId={doc.id}
          handleComplete={async () => {
            await client.refetchQueries({
              include: onUpdateDataRefetchQueries,
            });
          }}
        />
      )}
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
        <Divider />
        <MenuItemWithIcon
          icon={<BugReportIcon />}
          onClick={() => navigate(`${pathToDocVersion}/troubleshoot`)}
        >
          {t("Troubleshoot", {
            ns: "Global",
          })}
        </MenuItemWithIcon>
      </RestrictedByOrganisationPermissionWithDebug>
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
        <Divider />
        {(() => {
          const deleteButton = (
            <MenuItemWithIcon
              icon={<DeleteIcon />}
              onClick={deleteQuoteTemplate}
              disabled={
                deleteQuoteTemplateLoading ||
                isPublishedWithOtherOrgs ||
                isUsedInOtherTemplates
              }
            >
              {t("Delete Template", {
                ns: "Quote",
              })}
            </MenuItemWithIcon>
          );

          if (isPublishedWithOtherOrgs) {
            return (
              <Tooltip
                title={t("Template is published and can not be deleted", {
                  ns: "Quote",
                })}
              >
                <div>{deleteButton}</div>
              </Tooltip>
            );
          }
          if (isUsedInOtherTemplates) {
            return (
              <Tooltip
                title={t(
                  "Template is used in other templates and can not be deleted",
                  {
                    ns: "Quote",
                  }
                )}
              >
                <div>{deleteButton}</div>
              </Tooltip>
            );
          }

          return deleteButton;
        })()}
      </RestrictedByOrganisationPermissionWithDebug>
    </MenuButton>
  );
};
