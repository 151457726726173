import React from "react";
import { useLocalStorageAsState } from "@msys/ui";

const CLIPBOARD_KEY = "msys-clipboard";

export type ClipboardRecordType = Record<string, unknown>;

const ClipboardContext = React.createContext<{
  clipboard: ClipboardRecordType;
  setClipboard: React.Dispatch<
    React.SetStateAction<ClipboardRecordType | undefined>
  >;
}>({ clipboard: {}, setClipboard: () => {} });

export const ClipboardProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [value, setValue] = useLocalStorageAsState<ClipboardRecordType>(
    CLIPBOARD_KEY,
    {},
    true
  );

  const valueRef = React.useRef(value);
  valueRef.current = value;

  const updateValue: React.Dispatch<
    React.SetStateAction<ClipboardRecordType | undefined>
  > = React.useCallback(
    (
      valueOrFunction: React.SetStateAction<ClipboardRecordType | undefined>
    ) => {
      const newState: ClipboardRecordType | undefined =
        typeof valueOrFunction === "function"
          ? (valueOrFunction as Function)(valueRef.current)
          : valueOrFunction;
      setValue(newState);
    },
    [setValue]
  );

  const contextValue = {
    clipboard: value,
    setClipboard: updateValue,
  };

  return (
    <ClipboardContext.Provider value={contextValue}>
      {children}
    </ClipboardContext.Provider>
  );
};

export const useClipboard = (): [
  ClipboardRecordType,
  React.Dispatch<React.SetStateAction<ClipboardRecordType | undefined>>
] => {
  const { clipboard, setClipboard } = React.useContext(ClipboardContext);

  return [clipboard, setClipboard];
};
