import {
  CardContainer,
  CollapseSection,
  LabeledValue,
  useFormatting,
} from "@msys/ui";
import { Divider, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { FC } from "react";
import { Stack } from "../../../commons/layout/Stack";
import { BillOfMaterialsItemFragment } from "../../bill-of-materials/BillOfMaterials.generated";
import { useTranslate } from "@tolgee/react";

export type PurchaseOrderEditItem = BillOfMaterialsItemFragment & {
  orderItemId?: string;
  orderQuantity?: number;
  orderUnitPrice?: number;
};

interface Props {
  purchaseOrderItem: PurchaseOrderEditItem;
  QuantityInput: React.ReactElement;
  PriceInput: React.ReactElement;
}

export const OrderDetailsBox: FC<Props> = ({
  purchaseOrderItem,
  QuantityInput,
  PriceInput,
}) => {
  const { t } = useTranslate(["Materials", "PurchaseOrders"]);
  const { getFormattedPrice } = useFormatting();

  return (
    <CardContainer
      title={t("Order details", {
        ns: "Materials",
      })}
      isExpandable
    >
      <Stack flexDirection="column" margin={1} spacing={0}>
        <CollapseSection
          title={t("Material quantity", {
            ns: "Materials",
          })}
          titleVariant="h4"
          isInitiallyExpanded
        >
          <Stack flexDirection={"column"} padding={1}>
            <Stack justifyContent={"space-between"}>
              <Typography>
                {t("Total required", {
                  ns: "Materials",
                })}
              </Typography>
              <Typography>{`${purchaseOrderItem.quantityRequired} ${purchaseOrderItem.quantityUnit}`}</Typography>
            </Stack>
            <Stack flexDirection="column" paddingLeft={1}>
              <Stack justifyContent={"space-between"}>
                <Typography variant="body2">
                  {t("Accepted quote items", {
                    ns: "Materials",
                  })}
                </Typography>
                <Typography variant="body2">
                  {`${purchaseOrderItem.quantityRequiredAccepted} ${purchaseOrderItem.quantityUnit}`}
                </Typography>
              </Stack>
              <Stack justifyContent={"space-between"}>
                <Typography variant="body2">
                  {t("Draft quote items", {
                    ns: "Materials",
                  })}
                </Typography>
                <Typography variant="body2">
                  {`${purchaseOrderItem.quantityRequiredDraft} ${purchaseOrderItem.quantityUnit}`}
                </Typography>
              </Stack>
              <Stack justifyContent={"space-between"}>
                <Typography variant="body2">
                  {t("Pending quote items", {
                    ns: "Materials",
                  })}
                </Typography>
                <Typography variant="body2">
                  {`${purchaseOrderItem.quantityRequiredPending} ${purchaseOrderItem.quantityUnit}`}
                </Typography>
              </Stack>
              <Stack justifyContent={"space-between"}>
                <Typography variant="body2">
                  {t("Items with pending change order", {
                    ns: "Materials",
                  })}
                </Typography>
                <Typography variant="body2">
                  {`${purchaseOrderItem.quantityRequiredChange} ${purchaseOrderItem.quantityUnit}`}
                </Typography>
              </Stack>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>
                {t("Ordered", {
                  ns: "Materials",
                })}
              </Typography>
              <Typography>{`${purchaseOrderItem.quantityOrdered} ${purchaseOrderItem.quantityUnit}`}</Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>
                {t("To be ordered", {
                  ns: "Materials",
                })}
              </Typography>
              <Typography>{`${Math.max(
                purchaseOrderItem.quantityRequired -
                  purchaseOrderItem.quantityOrdered,
                0
              )} ${purchaseOrderItem.quantityUnit}`}</Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>
                {t("Order quantity", {
                  ns: "PurchaseOrders",
                })}
              </Typography>
              {QuantityInput}
            </Stack>
          </Stack>
        </CollapseSection>
        <CollapseSection
          title={t("Material pricing", {
            ns: "Materials",
          })}
          titleVariant="h4"
          isInitiallyExpanded
        >
          <Stack flexDirection={"column"} padding={1}>
            <Stack justifyContent={"space-between"}>
              <Typography>
                {t("List price", {
                  ns: "Materials",
                })}
              </Typography>
              <Typography>
                {!isNil(purchaseOrderItem.listPrice)
                  ? getFormattedPrice(purchaseOrderItem.listPrice)
                  : "-"}
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>
                {t("Net price", {
                  ns: "Materials",
                })}
              </Typography>
              <Typography>
                {!isNil(purchaseOrderItem.netPrice)
                  ? getFormattedPrice(purchaseOrderItem.netPrice)
                  : "-"}
              </Typography>
            </Stack>

            <Stack justifyContent={"space-between"}>
              <Typography>
                {t("Order price", {
                  ns: "PurchaseOrders",
                })}
              </Typography>
              {PriceInput}
            </Stack>
          </Stack>
        </CollapseSection>
      </Stack>
      <Divider />
      <Stack flexDirection="column" padding={1}>
        <Typography variant="h4">
          {t("Order summary", {
            ns: "PurchaseOrders",
          })}
        </Typography>
        <Stack justifyContent="space-between">
          <LabeledValue
            label={t("Order quantity", {
              ns: "PurchaseOrders",
            })}
          >
            {purchaseOrderItem.orderQuantity ?? 0}
          </LabeledValue>
          <Typography color="secondary">x</Typography>
          <LabeledValue
            label={t("Order price", {
              ns: "PurchaseOrders",
            })}
          >
            {getFormattedPrice(purchaseOrderItem.orderUnitPrice ?? 0)}
          </LabeledValue>
          <Typography color="secondary">=</Typography>
          <LabeledValue
            label={t("Total price", {
              ns: "PurchaseOrders",
            })}
            align="right"
            style={{ fontSize: "18px", fontWeight: 500 }}
          >
            {getFormattedPrice(
              (purchaseOrderItem.orderQuantity ?? 0) *
                (purchaseOrderItem.orderUnitPrice ?? 0)
            )}
          </LabeledValue>
        </Stack>
      </Stack>
    </CardContainer>
  );
};
