import { TagChips } from "@msys/ui";
import { BoxProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";

export const RESERVED_TAGS = [
  "LEAD",
  "CLIENT",
  "CUSTOMER",
  "CRAFTSMAN",
  "SUPPLIER",
  "MANUFACTURER",
];

export type ReservedTags =
  | "LEAD"
  | "CLIENT"
  | "CUSTOMER"
  | "CRAFTSMAN"
  | "SUPPLIER"
  | "MANUFACTURER";

export const isTagReserved = (tag: string): tag is ReservedTags =>
  RESERVED_TAGS.includes(tag.trim().toLocaleUpperCase());

export const useReservedTagLabels = () => {
  const { t } = useTranslate("CrmUsers");

  const reservedTagLabels: Record<ReservedTags, string> = {
    LEAD: t("LEAD"),
    CLIENT: t("CLIENT"),
    CUSTOMER: t("CUSTOMER"),
    CRAFTSMAN: t("CRAFTSMAN"),
    SUPPLIER: t("SUPPLIER"),
    MANUFACTURER: t("MANUFACTURER"),
  };

  return { reservedTagLabels };
};

interface Props {
  tags: string[];
  wrap?: boolean;
  boxProps?: BoxProps;
}

export const CrmTagChips = ({ tags, wrap = true, boxProps }: Props) => {
  const { reservedTagLabels } = useReservedTagLabels();
  return (
    <TagChips
      tags={tags.map(tag =>
        isTagReserved(tag) ? reservedTagLabels[tag] : tag
      )}
      wrap={wrap}
      boxProps={boxProps}
    />
  );
};
