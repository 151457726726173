import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { ProjectFilterKind } from "../../../clients/graphqlTypes";

const ALL_PROJECT_KINDS: ProjectFilterKind[] = ["DEFAULT", "TICKET"];

export function useProjectKinds() {
  const { t } = useTranslate("ProjectTypes");

  const projectKindLabels: Record<ProjectFilterKind, string> = useMemo(
    () => ({
      DEFAULT: t("Project"),
      TICKET: t("Ticket"),
    }),
    [t]
  );

  const projectKindOptions = useMemo(
    () =>
      ALL_PROJECT_KINDS.map(projectKind => ({
        value: projectKind,
        label: projectKindLabels[projectKind],
      })),
    [projectKindLabels]
  );

  return { projectKindLabels, projectKindOptions };
}
