import React from "react";
import { TruncatedHtmlValue } from "../text/TruncatedHtmlValue";
import { LabeledValue } from "./LabeledValue";

interface Props {
  label: string;
  html?: string;
  lines?: number;
  showMoreLabel: string;
  showLessLabel: string;
  notSetLabel: string;
}

export const LabeledHtmlValue = ({
  lines = 3,
  label,
  html,
  showMoreLabel,
  showLessLabel,
  notSetLabel,
}: Props) => {
  return (
    <LabeledValue label={label}>
      <TruncatedHtmlValue
        html={html}
        lines={lines}
        showMoreLabel={showMoreLabel}
        showLessLabel={showLessLabel}
        notSetLabel={notSetLabel}
      />
    </LabeledValue>
  );
};
