import { MenuItemWithIcon } from "@msys/ui";
import EditOffIcon from "@mui/icons-material/EditOff";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ViewEditMode, ViewMode } from "./ViewModeMenuItem";

interface Props {
  viewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
}

export const ViewModeEditMenuItem = ({ viewMode, onViewModeChange }: Props) => {
  const viewEditMode: ViewEditMode = viewMode === "edit" ? "edit" : null;
  const { t } = useTranslate(["Global", "FilesBoxTable"]);
  return viewEditMode === null ? (
    <MenuItemWithIcon
      icon={<EditIcon />}
      onClick={() => onViewModeChange("edit")}
    >
      {t("Edit", { ns: "Global" })}
    </MenuItemWithIcon>
  ) : viewEditMode === "edit" ? (
    <MenuItemWithIcon
      icon={<EditOffIcon />}
      onClick={() => onViewModeChange(null)}
    >
      {t("Cancel edit", { ns: "FilesBoxTable" })}
    </MenuItemWithIcon>
  ) : null;
};
