/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestImportXiopdUploadUrlMutationVariables = Types.Exact<{
  input: Types.RequestXiopdImportUploadUrlInput;
}>;


export type RequestImportXiopdUploadUrlMutation = { requestXiopdImportUploadUrl: { __typename: 'RequestUploadUrlResult', uploadUrl: string } };


export const RequestImportXiopdUploadUrlDocument = gql`
    mutation RequestImportXiopdUploadUrl($input: RequestXiopdImportUploadUrlInput!) {
  requestXiopdImportUploadUrl(input: $input) {
    uploadUrl
  }
}
    `;
export type RequestImportXiopdUploadUrlMutationFn = Apollo.MutationFunction<RequestImportXiopdUploadUrlMutation, RequestImportXiopdUploadUrlMutationVariables>;

/**
 * __useRequestImportXiopdUploadUrlMutation__
 *
 * To run a mutation, you first call `useRequestImportXiopdUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestImportXiopdUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestImportXiopdUploadUrlMutation, { data, loading, error }] = useRequestImportXiopdUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestImportXiopdUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<RequestImportXiopdUploadUrlMutation, RequestImportXiopdUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestImportXiopdUploadUrlMutation, RequestImportXiopdUploadUrlMutationVariables>(RequestImportXiopdUploadUrlDocument, options);
      }
export type RequestImportXiopdUploadUrlMutationHookResult = ReturnType<typeof useRequestImportXiopdUploadUrlMutation>;
export type RequestImportXiopdUploadUrlMutationResult = Apollo.MutationResult<RequestImportXiopdUploadUrlMutation>;
export type RequestImportXiopdUploadUrlMutationOptions = Apollo.BaseMutationOptions<RequestImportXiopdUploadUrlMutation, RequestImportXiopdUploadUrlMutationVariables>;