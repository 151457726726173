import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps as MuiAutocompleteProps,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import { identity, merge } from "lodash";
import React, { SyntheticEvent } from "react";

export interface AutocompleteProps<Option, FreeSolo extends boolean = false>
  extends Omit<
    MuiAutocompleteProps<Option, true, false, FreeSolo>,
    "onChange" | "renderInput"
  > {
  onChange: (
    value: (Option | AutocompleteFreeSoloValueMapping<FreeSolo>)[],
    event: React.ChangeEvent<{}>,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option> | undefined
  ) => void;
  inputLabel: string;
  required?: boolean;
  autoFocus?: boolean;
  error?: string;
  loading?: boolean;
  TextFieldProps?: React.ComponentProps<typeof TextField>;
}

export const AutocompleteMultiple = <Option, FreeSolo extends boolean = false>({
  value = [],
  onChange,
  placeholder,
  inputLabel,
  autoFocus,
  required,
  error,
  loading,
  TextFieldProps,
  filterOptions,
  renderTags,
  ...props
}: AutocompleteProps<Option, FreeSolo>) => {
  const handleChange = React.useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      value: (Option | AutocompleteFreeSoloValueMapping<FreeSolo>)[],
      reason: AutocompleteChangeReason,
      details: AutocompleteChangeDetails<Option> | undefined
    ) => {
      onChange(value, event, reason, details);
    },
    [onChange]
  );

  const handleReset = React.useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      onChange([], event);
    },
    [onChange]
  );

  return (
    <MuiAutocomplete<Option, true, false, FreeSolo>
      {...props}
      multiple
      openOnFocus
      disableCloseOnSelect
      renderInput={params => {
        return (
          <TextField
            {...merge(params, TextFieldProps)}
            placeholder={placeholder}
            label={inputLabel}
            autoFocus={autoFocus}
            required={required}
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      renderTags={
        renderTags ??
        ((value: Option[], getTagProps: any) =>
          value.map((value, index) => (
            <Chip
              key={props?.getOptionLabel?.(value) ?? value}
              variant="outlined"
              size="small"
              label={props?.getOptionLabel?.(value) ?? value}
              {...getTagProps({ index })}
            />
          )))
      }
      value={value}
      onChange={handleChange}
      onReset={handleReset}
      filterOptions={filterOptions ?? identity}
      loading={loading}
    />
  );
};
