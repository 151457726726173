/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypeSelectSearchProductTypesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  productTypeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type ProductTypeSelectSearchProductTypesQuery = { pimSearchProductTypes: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchProductTypesResult', totalCount: number, productTypes: Array<{ __typename: 'PimProductType', id: string, key: string, label: string, typeSystem: Types.PimProductTypeSystemSystemType }> } };

export type ProductTypeSelect_ProductTypeFragment = { __typename: 'PimProductType', id: string, key: string, label: string, typeSystem: Types.PimProductTypeSystemSystemType };

export const ProductTypeSelect_ProductTypeFragmentDoc = gql`
    fragment ProductTypeSelect_ProductType on PimProductType {
  id
  key
  label
  typeSystem
}
    `;
export const ProductTypeSelectSearchProductTypesDocument = gql`
    query ProductTypeSelectSearchProductTypes($limit: Int!, $searchTerm: String, $productTypeIds: [ID!]) {
  pimSearchProductTypes(
    limit: $limit
    searchTerm: $searchTerm
    productTypeIds: $productTypeIds
  ) {
    ... on PimSearchProductTypesResult {
      productTypes {
        id
        ...ProductTypeSelect_ProductType
      }
      totalCount
    }
  }
}
    ${ProductTypeSelect_ProductTypeFragmentDoc}`;

/**
 * __useProductTypeSelectSearchProductTypesQuery__
 *
 * To run a query within a React component, call `useProductTypeSelectSearchProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeSelectSearchProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeSelectSearchProductTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      productTypeIds: // value for 'productTypeIds'
 *   },
 * });
 */
export function useProductTypeSelectSearchProductTypesQuery(baseOptions: Apollo.QueryHookOptions<ProductTypeSelectSearchProductTypesQuery, ProductTypeSelectSearchProductTypesQueryVariables> & ({ variables: ProductTypeSelectSearchProductTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypeSelectSearchProductTypesQuery, ProductTypeSelectSearchProductTypesQueryVariables>(ProductTypeSelectSearchProductTypesDocument, options);
      }
export function useProductTypeSelectSearchProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypeSelectSearchProductTypesQuery, ProductTypeSelectSearchProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypeSelectSearchProductTypesQuery, ProductTypeSelectSearchProductTypesQueryVariables>(ProductTypeSelectSearchProductTypesDocument, options);
        }
export function useProductTypeSelectSearchProductTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductTypeSelectSearchProductTypesQuery, ProductTypeSelectSearchProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductTypeSelectSearchProductTypesQuery, ProductTypeSelectSearchProductTypesQueryVariables>(ProductTypeSelectSearchProductTypesDocument, options);
        }
export type ProductTypeSelectSearchProductTypesQueryHookResult = ReturnType<typeof useProductTypeSelectSearchProductTypesQuery>;
export type ProductTypeSelectSearchProductTypesLazyQueryHookResult = ReturnType<typeof useProductTypeSelectSearchProductTypesLazyQuery>;
export type ProductTypeSelectSearchProductTypesSuspenseQueryHookResult = ReturnType<typeof useProductTypeSelectSearchProductTypesSuspenseQuery>;
export type ProductTypeSelectSearchProductTypesQueryResult = Apollo.QueryResult<ProductTypeSelectSearchProductTypesQuery, ProductTypeSelectSearchProductTypesQueryVariables>;