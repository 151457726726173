/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateFilterFields_AvailableFiltersQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filters?: Types.InputMaybe<Types.TemplateSearchFiltersInput>;
}>;


export type TemplateFilterFields_AvailableFiltersQuery = { searchTemplatesAvailableFilters: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'SearchTemplatesAvailableFiltersResult', templateTypeIds: Array<{ __typename: 'TemplateSearchAvailableFilterTemplateType', id: string, count: number, templateType: { __typename: 'TemplateType', id: string, key: string, title: string } }>, propertiesBool: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyBool', key: string, label: string, values: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyValueBool', count: number, value: boolean }> }>, propertiesNumber: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyNumber', key: string, label: string, values: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyNumberValue', count: number, value: number }> }>, propertiesNumberArray: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyNumberArray', key: string, label: string, values: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyNumberArrayValue', count: number, value: number }> }>, propertiesText: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyText', key: string, label: string, values: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyTextValue', count: number, value: string, label: string }> }>, propertiesTextArray: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyTextArray', key: string, label: string, values: Array<{ __typename: 'SearchTemplatesAvailableFilterPropertyTextArrayValue', count: number, value: string, label: string }> }> } };


export const TemplateFilterFields_AvailableFiltersDocument = gql`
    query TemplateFilterFields_AvailableFilters($searchTerm: String, $filters: TemplateSearchFiltersInput) {
  searchTemplatesAvailableFilters(filters: $filters, searchTerm: $searchTerm) {
    ... on SearchTemplatesAvailableFiltersResult {
      templateTypeIds {
        id
        templateType {
          id
          key
          title
        }
        count
      }
      propertiesBool {
        key
        label
        values {
          count
          value
        }
      }
      propertiesNumber {
        key
        label
        values {
          count
          value
        }
      }
      propertiesNumberArray {
        key
        label
        values {
          count
          value
        }
      }
      propertiesText {
        key
        label
        values {
          count
          value
          label
        }
      }
      propertiesTextArray {
        key
        label
        values {
          count
          value
          label
        }
      }
    }
  }
}
    `;

/**
 * __useTemplateFilterFields_AvailableFiltersQuery__
 *
 * To run a query within a React component, call `useTemplateFilterFields_AvailableFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateFilterFields_AvailableFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateFilterFields_AvailableFiltersQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTemplateFilterFields_AvailableFiltersQuery(baseOptions?: Apollo.QueryHookOptions<TemplateFilterFields_AvailableFiltersQuery, TemplateFilterFields_AvailableFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateFilterFields_AvailableFiltersQuery, TemplateFilterFields_AvailableFiltersQueryVariables>(TemplateFilterFields_AvailableFiltersDocument, options);
      }
export function useTemplateFilterFields_AvailableFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateFilterFields_AvailableFiltersQuery, TemplateFilterFields_AvailableFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateFilterFields_AvailableFiltersQuery, TemplateFilterFields_AvailableFiltersQueryVariables>(TemplateFilterFields_AvailableFiltersDocument, options);
        }
export function useTemplateFilterFields_AvailableFiltersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateFilterFields_AvailableFiltersQuery, TemplateFilterFields_AvailableFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateFilterFields_AvailableFiltersQuery, TemplateFilterFields_AvailableFiltersQueryVariables>(TemplateFilterFields_AvailableFiltersDocument, options);
        }
export type TemplateFilterFields_AvailableFiltersQueryHookResult = ReturnType<typeof useTemplateFilterFields_AvailableFiltersQuery>;
export type TemplateFilterFields_AvailableFiltersLazyQueryHookResult = ReturnType<typeof useTemplateFilterFields_AvailableFiltersLazyQuery>;
export type TemplateFilterFields_AvailableFiltersSuspenseQueryHookResult = ReturnType<typeof useTemplateFilterFields_AvailableFiltersSuspenseQuery>;
export type TemplateFilterFields_AvailableFiltersQueryResult = Apollo.QueryResult<TemplateFilterFields_AvailableFiltersQuery, TemplateFilterFields_AvailableFiltersQueryVariables>;