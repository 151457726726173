import { LoadingSpinner, useScreenWidth } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../auth/useAuth";
import { useSelectedUser } from "../../auth/useSelectedUser";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal";
import { SELECTED_USER_HTTP_HEADER } from "../../constants";
import { getM1CalculatorUrl } from "../../features/m1-calculators/getM1CalculatorUrl";
import { useM1Flows } from "../../features/m1-calculators/useM1Integration";

interface Props {
  organisationId: string;
  submenuItems: PageTopbarItem[];
}

export const OrganisationCalculator = ({
  organisationId,
  submenuItems,
}: Props) => {
  const { calculatorId } = useParams();
  if (!calculatorId) throw new Error("Calculator id is missing");

  const navigate = useNavigate();

  const { t } = useTranslate(["OrganisationPageTopbar"]);
  const { isMinTablet } = useScreenWidth();

  const { meister1Flows } = useM1Flows();

  const meister1Flow = meister1Flows.find(f => f.id === calculatorId);

  const [url, setUrl] = React.useState<string | null>(null);
  const { auth } = useAuth();
  const { selectedUserId } = useSelectedUser();

  React.useEffect(() => {
    if (auth.token) {
      fetch(getM1CalculatorUrl(calculatorId), {
        headers: {
          authorization: `Bearer ${auth.token}`,
          ...(selectedUserId
            ? { [SELECTED_USER_HTTP_HEADER]: selectedUserId }
            : undefined),
        },
      })
        .then(response => response.json())
        .then(result => {
          setUrl(result.url);
        });
    }
  }, [auth.token, calculatorId, selectedUserId]);

  return (
    <Page
      submenuItems={submenuItems}
      title={
        meister1Flow?.label ?? t("Calculator", { ns: "OrganisationPageTopbar" })
      }
    >
      {isMinTablet ? (
        <PageContainer $noPadding $fullHeight>
          {url ? (
            <iframe
              title="M1 Calculator"
              src={url}
              allowFullScreen
              height="100%"
              width="100%"
              frameBorder="0"
              style={{ flexGrow: 1, flexShrink: 1, border: 0 }}
            />
          ) : (
            <LoadingSpinner />
          )}
        </PageContainer>
      ) : (
        <PageFullScreenModal
          title={
            meister1Flow?.label ??
            t("Calculator", { ns: "OrganisationPageTopbar" })
          }
          onCloseButtonClick={() =>
            navigate("/organisation/settings/integrations")
          }
          noPadding
        >
          {url ? (
            <iframe
              title="M1 Calculator"
              src={url}
              allowFullScreen
              height="100%"
              width="100%"
              frameBorder="0"
              style={{ flexGrow: 1, flexShrink: 1, border: 0 }}
            />
          ) : (
            <LoadingSpinner />
          )}
        </PageFullScreenModal>
      )}
    </Page>
  );
};
