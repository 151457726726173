import AddIcon from "@mui/icons-material/Add";
import TimerIcon from "@mui/icons-material/Timer";
import { Button, IconButton, Tooltip } from "@mui/material";
import React, { CSSProperties } from "react";
import { useTranslate } from "@tolgee/react";

export const AddWorkSessionButton = ({
  itemId,
  docId,
  projectId,
  type = "button",
  style,
  addWorkSession,
}: {
  addWorkSession: (itemId: string, projectId: string, docId: string) => void;
  projectId: string;
  docId: string;
  itemId: string;
  type?: "button" | "icon" | "icon-button";
  style?: CSSProperties;
}) => {
  const { t } = useTranslate("Task");
  return type === "button" ? (
    <Button
      startIcon={<AddIcon />}
      style={style}
      color="secondary"
      variant="text"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        addWorkSession(itemId, projectId, docId);
      }}
    >
      {t("Add task work session")}
    </Button>
  ) : type === "icon" ? (
    <Tooltip title={t("Add task work session")}>
      <IconButton
        color="secondary"
        size="small"
        style={{ width: 30, height: 30, flexShrink: 0, ...style }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          addWorkSession(itemId, projectId, docId);
        }}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  ) : type === "icon-button" ? (
    <Tooltip title={t("Add task work session")}>
      <IconButton
        style={{ width: 40, height: 40, ...style }}
        color="secondary"
        size="small"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          addWorkSession(itemId, projectId, docId);
        }}
      >
        <TimerIcon style={{ width: 32, height: 32 }} />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );
};
