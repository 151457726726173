import { useScreenWidth } from "@msys/ui";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { SelectInfoMessageItem } from "../../features/doc-items/items/SelectInfoMessageItem";
import { PageNotFound } from "../../commons/PageNotFound";
import { ExampleLayoutBothSidebarsPreview } from "./ExampleLayoutBothSidebars";
import {
  ExampleLayoutFluid,
  ExampleLayoutFluidChat,
  ExampleLayoutFluidChatWithSidebar,
} from "./ExampleLayoutFluid";
import {
  ExampleLayoutLeftSidebarExampleWithEditing,
  ExampleLayoutLeftSidebarExampleWithEditingSection,
  ExampleLayoutLeftSidebarExampleWithTimesheet,
  ExampleLayoutLeftSidebarList,
  ExampleLayoutLeftSidebarPreview,
  ExampleLayoutLeftSidebarPreviewFluid,
  ExampleLayoutLeftSidebarPreviewWithEditing,
} from "./ExampleLayoutLeftSidebar";
import {
  ExampleLayoutLg1Column,
  ExampleLayoutLg3ColumnsProfile,
} from "./ExampleLayoutLg";
import { ExampleLayoutMd1Column } from "./ExampleLayoutMd";
import { ExampleLayoutRightSidebarCard } from "./ExampleLayoutRightSidebar";
import { ExampleLayoutSettings } from "./ExampleLayoutSettings";
import { ExampleLayoutSm1Column } from "./ExampleLayoutSm";
import {
  ExampleLayoutXl1Column,
  ExampleLayoutXl4Columns,
  ExampleLayoutXl4ColumnsMasonry,
} from "./ExampleLayoutXl";
import { ExamplesIndex } from "./index";

export const ExamplesRoutes: React.FC = () => {
  const { isMinDesktop, isMinTablet, isMinLargeDesktop } = useScreenWidth();

  return (
    <Routes>
      <Route index element={<ExamplesIndex />} />
      <Route
        path={`layout-xl-4-columns`}
        element={<ExampleLayoutXl4Columns />}
      />
      <Route
        path={`layout-xl-4-columns-masonry`}
        element={<ExampleLayoutXl4ColumnsMasonry />}
      />
      <Route path={`layout-xl-1-column`} element={<ExampleLayoutXl1Column />} />
      <Route
        path={`layout-lg-3-columns-profile`}
        element={<ExampleLayoutLg3ColumnsProfile />}
      />
      <Route path={`layout-lg-1-column`} element={<ExampleLayoutLg1Column />} />
      <Route path={`layout-md-1-column`} element={<ExampleLayoutMd1Column />} />
      <Route path={`layout-sm-1-column`} element={<ExampleLayoutSm1Column />} />
      <Route
        path={`layout-left-sidebar-preview`}
        element={<ExampleLayoutLeftSidebarPreview />}
      />
      <Route
        path={`layout-left-sidebar-preview-fluid`}
        element={<ExampleLayoutLeftSidebarPreviewFluid />}
      />
      <Route
        path={`layout-left-sidebar-preview-editing`}
        element={<ExampleLayoutLeftSidebarPreviewWithEditing />}
      />
      <Route
        path={`layout-left-sidebar-example-editing`}
        element={<ExampleLayoutLeftSidebarExampleWithEditing />}
      >
        <Route
          path={`:section`}
          element={<ExampleLayoutLeftSidebarExampleWithEditingSection />}
        />
        {isMinTablet && (isMinLargeDesktop || !isMinDesktop) && (
          <Route path={"*"} element={<SelectInfoMessageItem />} />
        )}
      </Route>
      <Route
        path={`layout-left-sidebar-example-timesheet`}
        element={<ExampleLayoutLeftSidebarExampleWithTimesheet />}
      >
        <Route
          path={`:section`}
          element={<ExampleLayoutLeftSidebarExampleWithTimesheet />}
        />
        {isMinDesktop && <Route element={<SelectInfoMessageItem />} />}
      </Route>
      <Route
        path={`layout-left-sidebar-list`}
        element={<ExampleLayoutLeftSidebarList />}
      />
      <Route
        path={`layout-right-sidebar-card`}
        element={<ExampleLayoutRightSidebarCard />}
      />
      <Route
        path={`layout-both-sidebars-preview`}
        element={<ExampleLayoutBothSidebarsPreview />}
      />
      <Route path={`layout-fluid`} element={<ExampleLayoutFluid />} />
      <Route path={`layout-fluid-chat`} element={<ExampleLayoutFluidChat />} />
      <Route
        path={`layout-fluid-chat-sidebar`}
        element={<ExampleLayoutFluidChatWithSidebar />}
      />
      <Route path={`layout-settings`} element={<ExampleLayoutSettings />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
