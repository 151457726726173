import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";

interface Props extends React.ComponentProps<typeof Modal> {}

export const DocumentActorsModal = ({
  handleClose,
  children,
  ...props
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslate(["Global"]);

  return (
    <Modal
      title={t("Relevant parties", { ns: "Global" })}
      handleClose={handleClose}
      {...props}
    >
      <Stack spacing={2}>{children}</Stack>
    </Modal>
  );
};
