import React, { FC } from "react";
import { Page } from "../../commons/layout/Page";
import { Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import { PageContainer } from "../../commons/layout/PageContainer";

export const ExamplesIndex: FC = () => (
  <Page>
    <PageContainer>
      <MuiLink component={Link} to="/examples/layout-xl-4-columns">
        Xl-size 4-columns layout
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-xl-4-columns-masonry">
        Xl-size 4-columns layout masonry
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-xl-1-column">
        Xl-size 1-column layout for table
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-lg-3-columns-profile">
        Lg-size 3-columns layout for profile
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-lg-1-column">
        Lg-size 1-column layout for table
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-md-1-column">
        Md-size 1-column layout for print preview
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-sm-1-column">
        Sm-size 1-column layout for print preview
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-left-sidebar-preview">
        Left sidebar layout for preview
      </MuiLink>
      <MuiLink
        component={Link}
        to="/examples/layout-left-sidebar-preview-fluid"
      >
        Left sidebar layout for preview fluid
      </MuiLink>
      <MuiLink
        component={Link}
        to="/examples/layout-left-sidebar-preview-editing"
      >
        Left sidebar layout for preview and editing
      </MuiLink>
      <MuiLink
        component={Link}
        to="/examples/layout-left-sidebar-example-editing"
      >
        Left sidebar layout for preview and editing working example
      </MuiLink>
      <MuiLink
        component={Link}
        to="/examples/layout-left-sidebar-example-timesheet"
      >
        Left sidebar layout for timesheet working example
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-left-sidebar-list">
        Left sidebar layout for list
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-right-sidebar-card">
        Right sidebar layout for card item
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-both-sidebars-preview">
        Both sidebars layout for preview
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-fluid">
        Fluid layout
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-fluid-chat">
        Fluid chat layout
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-fluid-chat-sidebar">
        Fluid chat layout with sidebar
      </MuiLink>
      <MuiLink component={Link} to="/examples/layout-settings">
        Settings layout
      </MuiLink>
    </PageContainer>
  </Page>
);
