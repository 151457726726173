/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
export type ProjectOverviewFilesBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, city: string } | null } | null };

export const ProjectOverviewFilesBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewFilesBox_Project on Project {
  id
  viewerPermissions
  attachments {
    id
    ...Attachment
  }
  building {
    id
    buildingAddress {
      id
      city
    }
  }
}
    ${AttachmentFragmentDoc}`;