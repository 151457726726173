import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import {
  getFormattedDate,
  getFormattedDateTime,
  LabeledValue,
  TableInner,
  TextWithBreaks,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isNil, keyBy } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { PermissionName } from "../../../clients/graphqlTypes";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { AutocompleteField } from "../../commons/form-fields/AutocompleteField";
import { DatePickerField } from "../../commons/form-fields/DatePickerField";
import { ManualSave } from "../../commons/form-fields/ManualSave";
import { ProfileListItem } from "../../commons/ProfileListItem";
import { ViewMode } from "../../commons/ViewModeMenuItem";
import { CrmCompanySelect } from "../crm-companies/CrmCompanySelect";
import { OrganisationAvatar } from "../organisations/OrganisationAvatar";
import {
  S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartnerFragment,
  S4HanaQuoteDataBox_QuoteSapS4HanaDataFragment,
  useModifySapS4HanaSalesQuoteDataMutation,
  useS4HanaQuoteDataBoxQuery,
} from "./S4HanaQuoteDataBox.generated";

interface FormValues {
  xBindingPeriodValidityStartDate: moment.Moment | null;
  xRequestedDeliveryDate: moment.Moment | null;
  xPurchaseOrderByCustomer: string;
  xSdDocumentReason: string | null;
  xPriceListType: string | null;
  xPricingDate: moment.Moment | null;
  xCustomerPriceGroup: string | null;
  salesOffice: string | null;
  headerTexts: {
    xLongTextId: string;
    xLongTextValue: string;
  }[];
  businessPartners: {
    partnerFunction: string;
    crmCompany: { id: string; title: string } | null;
  }[];
  customFields: Array<
    | { key: string; valueDate: moment.Moment | null }
    | { key: string; valueText: string | null }
  >;
}

interface Props {
  quote: { viewerPermissions: PermissionName[] };
  projectId: string;
  quoteId: string;
  canEdit: boolean;
}

export const S4HanaQuoteDataBox = ({
  quote,
  projectId,
  quoteId,
  canEdit,
}: Props) => {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();
  const query = useS4HanaQuoteDataBoxQuery({
    client,
    variables: { projectId, quoteId },
  });
  const s4HanaQuoteData = query.data?.quoteSapS4HanaData;

  const sapS4HanaDataFieldMetadataByKey = React.useMemo(() => {
    return keyBy(s4HanaQuoteData?.sapS4HanaDataFieldMetadata ?? [], d => d.key);
  }, [s4HanaQuoteData?.sapS4HanaDataFieldMetadata]);

  const sapS4HanaCustomFieldMetadataByKey = React.useMemo(() => {
    return keyBy(
      s4HanaQuoteData?.sapS4HanaCustomFieldMetadata ?? [],
      d => d.key
    );
  }, [s4HanaQuoteData?.sapS4HanaCustomFieldMetadata]);

  if (!s4HanaQuoteData) return null;

  const sections = [
    {
      label: t("Document data", { ns: "SapS4Hana" }),
      values: [
        {
          label: t("ID", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xId || "–",
        },
        {
          label: t("Version ID", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xVersionIdentifier || "–",
        },
        {
          label: t("Reference document ID", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xReferenceDocumentId || "–",
        },
        {
          label: t("Binding period validity start date", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xBindingPeriodValidityStartDate
            ? getFormattedDate(
                s4HanaQuoteData.xBindingPeriodValidityStartDate,
                language
              )
            : "–",
          fieldName: sapS4HanaDataFieldMetadataByKey[
            "xBindingPeriodValidityStartDate"
          ]?.editable
            ? "xBindingPeriodValidityStartDate"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["xBindingPeriodValidityStartDate"]
              ?.required ?? false,
          fieldType: "date" as const,
        },
        {
          label: t("Requested delivery date", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xRequestedDeliveryDate
            ? getFormattedDate(s4HanaQuoteData.xRequestedDeliveryDate, language)
            : "–",
          fieldName: sapS4HanaDataFieldMetadataByKey["xRequestedDeliveryDate"]
            ?.editable
            ? "xRequestedDeliveryDate"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["xRequestedDeliveryDate"]
              ?.required ?? false,
          fieldType: "date" as const,
        },
        {
          label: t("Created by user", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xCreatedByUser || "–",
        },
        {
          label: t("Created", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xCreatedAt
            ? getFormattedDate(s4HanaQuoteData.xCreatedAt, language)
            : "–",
        },
        {
          label: t("Updated", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xUpdatedAt
            ? getFormattedDateTime(s4HanaQuoteData.xUpdatedAt, language)
            : "–",
        },
      ],
    },
    {
      label: t("Order data", { ns: "SapS4Hana" }),
      values: [
        {
          label: t("Customer payment terms", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xCustomerPaymentTerms || "–",
        },
        {
          label: t("Purchase order by customer", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xPurchaseOrderByCustomer || "–",
          fieldName: sapS4HanaDataFieldMetadataByKey["xPurchaseOrderByCustomer"]
            ?.editable
            ? "xPurchaseOrderByCustomer"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["xPurchaseOrderByCustomer"]
              ?.required ?? false,
          fieldType: "text" as const,
        },
        {
          label: t("Order reason", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xSdDocumentReason || "–",
          fieldName: sapS4HanaDataFieldMetadataByKey["xSdDocumentReason"]
            ?.editable
            ? "xSdDocumentReason"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["xSdDocumentReason"]?.required ??
            false,
          fieldType: "text" as const,
          availableValues:
            sapS4HanaDataFieldMetadataByKey["xSdDocumentReason"]
              ?.allowedValues ?? [],
        },
        {
          label: t("Transaction currency", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xTransactionCurrency || "–",
        },
        {
          label: t("Sales quotation date", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xSalesQuotationDate
            ? getFormattedDate(s4HanaQuoteData.xSalesQuotationDate, language)
            : "–",
        },

        {
          label: t("Pricing date", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xPricingDate
            ? getFormattedDate(s4HanaQuoteData.xPricingDate, language)
            : "–",
          fieldName: sapS4HanaDataFieldMetadataByKey["xPricingDate"]?.editable
            ? "xPricingDate"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["xPricingDate"]?.required ?? false,
          fieldType: "date" as const,
        },
        {
          label: t("Customer price group", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xCustomerPriceGroup || "–",
          fieldName: sapS4HanaDataFieldMetadataByKey["xCustomerPriceGroup"]
            ?.editable
            ? "xCustomerPriceGroup"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["xCustomerPriceGroup"]?.required ??
            false,
          fieldType: "text" as const,
          availableValues:
            sapS4HanaDataFieldMetadataByKey["xCustomerPriceGroup"]
              ?.allowedValues ?? [],
        },
        {
          label: t("Price list type", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.xPriceListType || "–",
          fieldName: sapS4HanaDataFieldMetadataByKey["xPriceListType"]?.editable
            ? "xPriceListType"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["xPriceListType"]?.required ??
            false,
          fieldType: "text" as const,
          availableValues:
            sapS4HanaDataFieldMetadataByKey["xPriceListType"]?.allowedValues ??
            [],
        },
      ],
    },
    {
      label: t("Custom fields", { ns: "SapS4Hana" }),
      values: s4HanaQuoteData.customFields.map((cf, index) => {
        const metadata = sapS4HanaCustomFieldMetadataByKey[cf.key];
        return {
          label: t(cf.translationKey as any, { ns: "SapS4Hana" }),
          value:
            cf.__typename === "QuoteSapS4HanaCustomFieldDate"
              ? cf.valueDate
                ? getFormattedDate(cf.valueDate, language)
                : "-"
              : cf.__typename === "QuoteSapS4HanaCustomFieldText"
                ? cf.valueText
                  ? cf.valueText
                  : "-"
                : assertNever(cf),
          fieldName: metadata?.editable
            ? cf.__typename === "QuoteSapS4HanaCustomFieldDate"
              ? `customFields.[${index}].valueDate`
              : cf.__typename === "QuoteSapS4HanaCustomFieldText"
                ? `customFields.[${index}].valueText`
                : assertNever(cf)
            : undefined,
          required: metadata?.required ?? false,
          fieldType:
            cf.__typename === "QuoteSapS4HanaCustomFieldDate"
              ? ("date" as const)
              : cf.__typename === "QuoteSapS4HanaCustomFieldText"
                ? ("text" as const)
                : assertNever(cf),
          availableValues: metadata?.allowedValues ?? [],
        };
      }),
    },
    {
      label: t("Organisation data", { ns: "SapS4Hana" }),
      values: [
        {
          label: t("Sales organisation", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.salesOrganisation || "–",
        },
        {
          label: t("Organisation division", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.organisationDivision || "–",
        },
        {
          label: t("Distribution channel", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.distributionChannel || "–",
        },
        {
          label: t("Sales quotation type", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.salesQuotationType || "–",
        },
        {
          label: t("Sales office", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.salesOffice || "–",
          fieldType: "text" as const,
          fieldName: sapS4HanaDataFieldMetadataByKey["salesOffice"]?.editable
            ? "salesOffice"
            : undefined,
          required:
            sapS4HanaDataFieldMetadataByKey["salesOffice"]?.required ?? false,
          availableValues:
            sapS4HanaDataFieldMetadataByKey["salesOffice"]?.allowedValues ?? [],
        },
      ],
    },
    {
      label: t("System data", { ns: "SapS4Hana" }),
      values: [
        {
          label: t("Created", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.createdAt
            ? getFormattedDateTime(s4HanaQuoteData.createdAt, language)
            : "–",
        },
        {
          label: t("Updated", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.updatedAt
            ? getFormattedDateTime(s4HanaQuoteData.updatedAt, language)
            : "–",
        },
        {
          label: t("Last synced", { ns: "SapS4Hana" }),
          value: s4HanaQuoteData.lastSyncedFromSapAt
            ? getFormattedDateTime(
                s4HanaQuoteData.lastSyncedFromSapAt,
                language
              )
            : "–",
        },
      ],
    },
  ];

  const headerTextTypes = s4HanaQuoteData.xAvailableSalesQuotationTexts ?? [];
  const headerTexts = s4HanaQuoteData.headerTexts ?? [];
  const businessPartners = s4HanaQuoteData.businessPartners ?? [];
  const businessPartnerFunctions =
    s4HanaQuoteData.xAvailablePartnerFunctions ?? [];
  const customFields = s4HanaQuoteData.customFields ?? [];

  return (
    <Paper>
      {canEdit && viewMode === null && (
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={quote}
        >
          <IconButton
            color="secondary"
            size="small"
            onClick={() => setViewMode("edit")}
            sx={{
              float: "right",
              position: "relative",
              zIndex: 3,
              mt: 1.5,
              mr: 2,
            }}
          >
            <EditIcon />
          </IconButton>
        </RestrictedByDocumentPermissionWithDebug>
      )}
      <Stack direction="column" padding={2} spacing={2} position="relative">
        <Typography variant="h3">
          {t("S/4HANA quote data", { ns: "SapS4Hana" })}
        </Typography>

        {viewMode === null && (
          <S4HanaQuoteData
            sections={sections}
            headerTexts={headerTexts}
            headerTextTypes={headerTextTypes}
            businessPartners={businessPartners}
            businessPartnerFunctions={businessPartnerFunctions}
          />
        )}
        {viewMode === "edit" && (
          <S4HanaQuoteForm
            sections={sections}
            s4HanaQuoteData={s4HanaQuoteData}
            setViewMode={setViewMode}
            docId={quoteId}
            projectId={projectId}
            headerTextTypes={headerTextTypes}
            businessPartnerFunctions={businessPartnerFunctions}
          />
        )}
      </Stack>
    </Paper>
  );
};

function S4HanaQuoteData({
  sections,
  headerTexts,
  headerTextTypes,
  businessPartners,
  businessPartnerFunctions,
}: {
  sections: { label: string; values: { label: string; value: string }[] }[];
  headerTexts: { xLongTextId: string; xLongTextValue: string }[];
  headerTextTypes: { label: string; value: string }[];
  businessPartners: S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartnerFragment[];
  businessPartnerFunctions: { label: string; value: string }[];
}) {
  const { t } = useTranslate(["SapS4Hana", "Global"]);
  return (
    <Stack direction="column" spacing={1}>
      {sections.map((section, sectionIdx) => (
        <Stack direction="column" spacing={1} key={`section-${sectionIdx}`}>
          <Typography variant="h4">{section.label}</Typography>

          <Box>
            <Grid container columns={12} spacing={1}>
              {section.values.map((value, valueIdx) => (
                <Grid
                  key={`section-${sectionIdx}-value-${valueIdx}`}
                  item
                  xs={6}
                  lg={4}
                  xl={3}
                >
                  <LabeledValue label={value.label}>{value.value}</LabeledValue>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      ))}

      <Typography variant="h4">
        {t("Header texts", { ns: "SapS4Hana" })}
      </Typography>

      {headerTexts.length > 0 ? (
        <TableInner<{ id: string; value: string }>
          size="extra-small"
          columns={[
            {
              id: "type",
              title: t("Type", { ns: "Global" }),
              cellStyle: { minWidth: "100px" },
              width: "35%",
              render: row => {
                const type = headerTextTypes.find(v => v.value === row.id);
                if (!type) return row.id;
                return type.label;
              },
            },
            {
              id: "value",
              title: t("Value", { ns: "Global" }),
              width: "65%",
              render: row => <TextWithBreaks text={row.value} />,
            },
          ]}
          items={headerTexts.map(t => ({
            id: t.xLongTextId,
            value: t.xLongTextValue,
          }))}
        />
      ) : (
        <Typography>{t("Not set", { ns: "Global" })}</Typography>
      )}

      <Typography variant="h4">
        {t("Business partners", { ns: "SapS4Hana" })}
      </Typography>

      {businessPartners.length > 0 ? (
        <TableInner<S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartnerFragment>
          size="extra-small"
          columns={[
            {
              id: "type",
              title: t("Type", { ns: "Global" }),
              cellStyle: { minWidth: "100px" },
              width: "35%",
              render: row => {
                const type = businessPartnerFunctions.find(
                  v => v.value === row.partnerFunction
                );
                if (!type) return row.partnerFunction;
                return type.label;
              },
            },
            {
              id: "partner",
              title: t("Partner", { ns: "SapS4Hana" }),
              width: "65%",
              render: row => (
                <ProfileListItem
                  key={row.id}
                  button
                  component={Link}
                  to={`/crm/organisations/${row.crmCompany.id}`}
                  avatar={
                    <OrganisationAvatar
                      size="s"
                      organisationAvatar={row.crmCompany}
                    />
                  }
                  primary={row.crmCompany.title}
                />
              ),
            },
          ]}
          items={businessPartners}
        />
      ) : (
        <Typography>{t("Not set", { ns: "Global" })}</Typography>
      )}
    </Stack>
  );
}

function S4HanaQuoteForm({
  docId,
  projectId,
  s4HanaQuoteData,
  sections,
  setViewMode,
  headerTextTypes,
  businessPartnerFunctions,
}: {
  docId: string;
  projectId: string;
  s4HanaQuoteData: S4HanaQuoteDataBox_QuoteSapS4HanaDataFragment;
  sections: {
    label: string;
    values: {
      label: string;
      value: string;
      required?: boolean;
      fieldName?: string;
      fieldType?: "text" | "date";
      availableValues?: { label: string; value: string }[];
    }[];
  }[];
  headerTextTypes: { label: string; value: string }[];
  businessPartnerFunctions: { label: string; value: string }[];
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
}) {
  const { t } = useTranslate(["SapS4Hana", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormValues = {
    xBindingPeriodValidityStartDate:
      s4HanaQuoteData.xBindingPeriodValidityStartDate
        ? moment(s4HanaQuoteData.xBindingPeriodValidityStartDate)
        : null,
    xRequestedDeliveryDate: s4HanaQuoteData.xRequestedDeliveryDate
      ? moment(s4HanaQuoteData.xRequestedDeliveryDate)
      : null,
    xPurchaseOrderByCustomer: s4HanaQuoteData.xPurchaseOrderByCustomer || "",
    xSdDocumentReason: s4HanaQuoteData.xSdDocumentReason || "",
    headerTexts: s4HanaQuoteData.headerTexts || [],
    businessPartners: s4HanaQuoteData.businessPartners || [],
    salesOffice: s4HanaQuoteData.salesOffice ?? null,
    xCustomerPriceGroup: s4HanaQuoteData.xCustomerPriceGroup || null,
    xPriceListType: s4HanaQuoteData.xPriceListType || null,
    xPricingDate: s4HanaQuoteData.xPricingDate
      ? moment(s4HanaQuoteData.xPricingDate)
      : null,
    customFields: s4HanaQuoteData.customFields.map(cf => {
      switch (cf.__typename) {
        case "QuoteSapS4HanaCustomFieldDate": {
          return {
            key: cf.key,
            valueDate: cf.valueDate ? moment(cf.valueDate) : null,
          };
        }
        case "QuoteSapS4HanaCustomFieldText": {
          return {
            key: cf.key,
            valueText: !isNil(cf.valueText) ? cf.valueText : null,
          };
        }
        default:
          assertNever(cf);
      }
    }),
  };

  const client = useApolloClient();
  const [modifyQuoteData, { loading }] =
    useModifySapS4HanaSalesQuoteDataMutation({
      client,
    });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyQuoteData({
        variables: {
          input: {
            docId,
            projectId,
            values: {
              xBindingPeriodValidityStartDate:
                values.xBindingPeriodValidityStartDate
                  ? values.xBindingPeriodValidityStartDate.format("YYYY-MM-DD")
                  : null,
              xRequestedDeliveryDate: values.xRequestedDeliveryDate
                ? values.xRequestedDeliveryDate.format("YYYY-MM-DD")
                : null,
              xPurchaseOrderByCustomer: values.xPurchaseOrderByCustomer,
              xSdDocumentReason: values.xSdDocumentReason,
              headerTexts: values.headerTexts.map(t => ({
                xLongTextId: t.xLongTextId,
                xLongTextValue: t.xLongTextValue,
              })),
              xPricingDate: values.xPricingDate
                ? values.xPricingDate.format("YYYY-MM-DD")
                : null,
              xPriceListType: values.xPriceListType,
              xCustomerPriceGroup: values.xCustomerPriceGroup,
              businessPartners: values.businessPartners.map(bp => ({
                partnerType: "CUSTOMER", // TODO: allow other partner types too
                crmOrganisationId: bp.crmCompany!.id,
                partnerFunction: bp.partnerFunction,
              })),
              salesOffice: values.salesOffice ?? null,
              customFields: values.customFields.flatMap(cf => {
                if ("valueDate" in cf) {
                  return {
                    date: {
                      key: cf.key,
                      valueDate: cf.valueDate
                        ? cf.valueDate.format("YYYY-MM-DD")
                        : null,
                    },
                  };
                }

                if ("valueText" in cf) {
                  return {
                    text: {
                      key: cf.key,
                      valueText: cf.valueText,
                    },
                  };
                }

                return [];
              }),
            },
          },
        },
      });
      enqueueSnackbar(t("Data updated", { ns: "SapS4Hana" }));
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = Yup.object().shape({
    headerTexts: Yup.array().of(
      Yup.object().shape({
        xLongTextId: Yup.string()
          .required()
          .nullable()
          .label(t("Type", { ns: "Global" }))
          .test(
            "unique",
            t("Value must be unique", { ns: "Global" }),
            function (value: string | undefined | null) {
              // @ts-ignore
              const headerTexts = (this.from as any)[1].value.headerTexts as {
                xLongTextId: string;
              }[];
              const sameValues = headerTexts.filter(
                p => p.xLongTextId === value
              );
              return sameValues.length <= 1;
            }
          ),
        xLongTextValue: Yup.string()
          .required()
          .label(t("Value", { ns: "Global" })),
      })
    ),
    businessPartners: Yup.array().of(
      Yup.object().shape({
        partnerFunction: Yup.string()
          .required()
          .nullable()
          .label(t("Type", { ns: "Global" }))
          .test(
            "unique",
            t("Value must be unique", { ns: "Global" }),
            function (value: string | undefined | null) {
              // @ts-ignore
              const businessPartners = (this.from as any)[1].value
                .businessPartners as {
                partnerFunction: string;
              }[];
              const sameValues = businessPartners.filter(
                p => p.partnerFunction === value
              );
              return sameValues.length <= 1;
            }
          ),
        crmCompany: Yup.object()
          .shape({
            id: Yup.string().required(),
            title: Yup.string().required(),
          })
          .nullable()
          .required()
          .label(t("Partner", { ns: "SapS4Hana" })),
      })
    ),
  });

  const salesQuotationBusinessPartnerFilterByRolesByPartnerFunction =
    React.useMemo(() => {
      return Object.fromEntries(
        s4HanaQuoteData.salesQuotationBusinessPartnerFilterByRoles.map(r => [
          r.partnerFunction,
          r.filterByRoles,
        ])
      );
    }, [s4HanaQuoteData]);

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Form>
          <Stack direction="column" spacing={1}>
            {sections.map((section, sectionIdx) => (
              <Stack
                direction="column"
                spacing={1}
                key={`section-${sectionIdx}`}
              >
                <Typography variant="h4">{section.label}</Typography>
                <Box>
                  <Grid container columns={12} spacing={1}>
                    {section.values.map((value, valueIdx) => (
                      <Grid
                        key={`section-${sectionIdx}-value-${valueIdx}`}
                        item
                        xs={6}
                        lg={4}
                        xl={3}
                      >
                        {value.fieldName ? (
                          value.fieldType === "date" ? (
                            <DatePickerField
                              name={value.fieldName}
                              label={value.label}
                              disabled={formikProps.isSubmitting}
                              required={value.required}
                            />
                          ) : value.fieldType === "text" ? (
                            value.availableValues &&
                            value.availableValues.length > 0 ? (
                              <AutocompleteField
                                inputLabel={value.label}
                                name={value.fieldName}
                                disabled={formikProps.isSubmitting}
                                options={value.availableValues.map(
                                  v => v.value
                                )}
                                getOptionLabel={option => {
                                  const availableValue = (
                                    value.availableValues ?? []
                                  ).find(v => v.value === option);
                                  if (!availableValue) return option;
                                  return availableValue.label;
                                }}
                                isRequired={value.required}
                              />
                            ) : (
                              <Field
                                component={TextField}
                                label={value.label}
                                name={value.fieldName}
                                disabled={formikProps.isSubmitting}
                                required={value.required}
                              />
                            )
                          ) : null
                        ) : (
                          <LabeledValue label={value.label}>
                            {value.value}
                          </LabeledValue>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            ))}

            <Typography variant="h4">
              {t("Header texts", { ns: "SapS4Hana" })}
            </Typography>

            <FieldArray name="headerTexts">
              {arrayHelpers => (
                <>
                  {formikProps.values.headerTexts.length > 0 &&
                    formikProps.values.headerTexts.map((headerText, idx) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        key={`header-text-${idx}`}
                      >
                        {headerTextTypes.length > 0 ? (
                          <AutocompleteField
                            inputLabel={t("Type", { ns: "Global" })}
                            name={`headerTexts.${idx}.xLongTextId`}
                            disabled={formikProps.isSubmitting}
                            options={headerTextTypes.map(v => v.value)}
                            getOptionLabel={option => {
                              const type = headerTextTypes.find(
                                v => v.value === option
                              );
                              if (!type) return option;
                              return type.label;
                            }}
                            sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                          />
                        ) : (
                          <Field
                            component={TextField}
                            label={t("Type", { ns: "Global" })}
                            name={`headerTexts.${idx}.xLongTextId`}
                            disabled={formikProps.isSubmitting}
                            sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                          />
                        )}
                        <Field
                          component={TextField}
                          label={t("Value", { ns: "Global" })}
                          name={`headerTexts.${idx}.xLongTextValue`}
                          disabled={formikProps.isSubmitting}
                          multiline
                          sx={{
                            flex: 1,
                            alignSelf: "flex-start",
                            flexBasis: "65%",
                          }}
                        />
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => {
                            arrayHelpers.remove(idx);
                          }}
                          sx={{ flexGrow: 0, flexShrink: 0 }}
                          disabled={formikProps.isSubmitting}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    ))}

                  <Button
                    type="button"
                    color="secondary"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      arrayHelpers.push({
                        xLongTextId: null,
                        xLongTextValue: "",
                      })
                    }
                    sx={{ alignSelf: "flex-start" }}
                  >
                    {t("Add header text", { ns: "SapS4Hana" })}
                  </Button>
                </>
              )}
            </FieldArray>

            <Typography variant="h4">
              {t("Business partners", { ns: "SapS4Hana" })}
            </Typography>

            <FieldArray name="businessPartners">
              {arrayHelpers => (
                <>
                  {formikProps.values.businessPartners.length > 0 &&
                    formikProps.values.businessPartners.map(
                      (businessPartner, idx) => {
                        const filterByRoles: string[] | undefined =
                          salesQuotationBusinessPartnerFilterByRolesByPartnerFunction[
                            businessPartner.partnerFunction
                          ] ?? undefined;

                        return (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            key={`business-partner-${idx}`}
                          >
                            {businessPartnerFunctions.length > 0 ? (
                              <AutocompleteField
                                inputLabel={t("Type", { ns: "Global" })}
                                name={`businessPartners.${idx}.partnerFunction`}
                                disabled={
                                  // TODO: hard coded partnerFunction, make configurable!
                                  businessPartner.partnerFunction === "YM" &&
                                  !!s4HanaQuoteData.xId
                                    ? true
                                    : formikProps.isSubmitting
                                }
                                options={businessPartnerFunctions.map(
                                  v => v.value
                                )}
                                getOptionLabel={option => {
                                  const type = businessPartnerFunctions.find(
                                    v => v.value === option
                                  );
                                  if (!type) return option;
                                  return type.label;
                                }}
                                sx={{
                                  alignSelf: "flex-start",
                                  flexBasis: "35%",
                                }}
                              />
                            ) : (
                              <Field
                                component={TextField}
                                label={t("Type", { ns: "Global" })}
                                name={`businessPartnerFunctions.${idx}.partnerFunction`}
                                disabled={
                                  // TODO: hard coded partnerFunction, make configurable!
                                  businessPartner.partnerFunction === "YM" &&
                                  !!s4HanaQuoteData.xId
                                    ? true
                                    : formikProps.isSubmitting
                                }
                                sx={{
                                  alignSelf: "flex-start",
                                  flexBasis: "35%",
                                }}
                              />
                            )}
                            <CrmCompanySelect
                              crmCompanyId={businessPartner.crmCompany?.id}
                              inputLabel={t("Select crm company", {
                                ns: "SapS4Hana",
                              })}
                              required
                              disabled={
                                // TODO: hard coded partnerFunction, make configurable!
                                businessPartner.partnerFunction === "YM" &&
                                !!s4HanaQuoteData.xId
                                  ? true
                                  : formikProps.isSubmitting
                              }
                              onChange={crmCompany => {
                                formikProps.setFieldValue(
                                  `businessPartners.${idx}.crmCompany`,
                                  crmCompany
                                );
                              }}
                              filterBusinessPartnerRolesAll={filterByRoles}
                              disableBlockedBusinessPartners
                              sx={{ alignSelf: "flex-start", flexBasis: "65%" }}
                            />
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => {
                                arrayHelpers.remove(idx);
                              }}
                              sx={{ flexGrow: 0, flexShrink: 0 }}
                              disabled={formikProps.isSubmitting}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        );
                      }
                    )}

                  <Button
                    type="button"
                    color="secondary"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      arrayHelpers.push({
                        partnerFunction: "",
                        crmCompany: null,
                      })
                    }
                    sx={{ alignSelf: "flex-start" }}
                  >
                    {t("Add business partner", { ns: "SapS4Hana" })}
                  </Button>
                </>
              )}
            </FieldArray>

            <ManualSave
              disabled={formikProps.isSubmitting}
              onCancel={() => {
                setViewMode(null);
              }}
              onSubmitted={() => {
                setViewMode(null);
              }}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
