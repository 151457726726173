import {
  DecisionBehaviorOfSubitems,
  ItemType,
} from "../../../../clients/graphqlTypes";
// import { getAllParentItems, getParentItem } from "../../../trees/helpers";

export function itemCanHaveVerticalSelection<
  T extends {
    type: ItemType;
    decisionBehaviorOfSubitems: DecisionBehaviorOfSubitems;
  }
>(item: T) {
  return (
    item.type === "paid" ||
    item.type === "section" ||
    (item.type === "decision" &&
      item.decisionBehaviorOfSubitems === "SELECT_MANY")
  );
}

export function itemCanBeReplacedWithTemplates<
  T extends {
    type: ItemType;
    isRootItem: boolean;
  }
>(item: T, documentItemTypes: ItemType[]) {
  return (
    (item.type === "paid" ||
      (item.type === "section" && !item.isRootItem) ||
      item.type === "decision") &&
    documentItemTypes.includes("section")
  );
}
