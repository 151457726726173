import { StatusChip, StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";

export function RequirementStatusBadge({
  requirement,
  align,
  small,
}: {
  requirement: { isPublished: boolean };
  align?: React.ComponentProps<typeof RequirementDraftBadge>["align"];
  small?: React.ComponentProps<typeof RequirementDraftBadge>["small"];
}) {
  if (requirement.isPublished) {
    return <RequirementPublishedBadge align={align} small={small} />;
  }
  return <RequirementDraftBadge align={align} small={small} />;
}

export const RequirementDraftBadge = (props: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: React.ComponentProps<typeof StatusLabel>["small"];
}) => {
  const theme = useTheme();
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color={theme.palette.grey[600]} {...props}>
      {t("Draft")}
    </StatusLabel>
  );
};

export const RequirementPublishedBadge = (props: {
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  small?: React.ComponentProps<typeof StatusLabel>["small"];
}) => {
  const theme = useTheme();
  const { t } = useTranslate("ProjectOverview");

  return (
    <StatusLabel color={theme.palette.success.main} {...props}>
      {t("Published")}
    </StatusLabel>
  );
};

export const RequirementStatusChip = ({
  requirement,
  size,
}: {
  requirement: { isPublished: boolean };
  size?: React.ComponentProps<typeof StatusChip>["size"];
}) => {
  const { t } = useTranslate("ProjectOverview");

  if (requirement.isPublished) {
    return <StatusChip label={t("Published")} color={"success"} size={size} />;
  }

  return <StatusChip label={t("Draft")} color={"default"} size={size} />;
};
