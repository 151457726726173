import React, {
  FC,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useState,
} from "react";

const ScreenFeatureContext = React.createContext<{
  isFixedHeight: boolean;
  setFixedHeight(isFullWidth: boolean): void;
}>(null as any);

export const ScreenFeatureProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isFixedHeight, setFixedHeight] = useState<boolean>(false);

  return (
    <ScreenFeatureContext.Provider
      value={{
        isFixedHeight,
        setFixedHeight,
      }}
    >
      {children}
    </ScreenFeatureContext.Provider>
  );
};

export const useScreenFeature = () => useContext(ScreenFeatureContext);

export const useFixedHeight = () => {
  const { setFixedHeight } = useContext(ScreenFeatureContext);
  useLayoutEffect(() => {
    setFixedHeight(true);
    return () => {
      setFixedHeight(false);
    };
  }, [setFixedHeight]);
};
