import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { omit } from "lodash";
import { useSnackbar } from "notistack";
import React, { FC } from "react";
import { AddressInput } from "../../../../clients/graphqlTypes";
import { AddressDetails__AddressFragment } from "../../addresses/Addresses.generated";
import { useModifyBuildingMutation } from "../Buildings.generated";
import { useTranslate } from "@tolgee/react";
import { transientOptions } from "../../../styles";
import { Address } from "../../addresses/Address";
import { GetAddressModal } from "../../addresses/GetAddressModal";
import {
  getAddressImageSrc,
  getAddressSearchUrl,
} from "../../addresses/helpers";

const WIDTH = 88;
const HEIGHT = 72;

const DEFAULT_POINT = { lat: 50.701606, lng: 7.37573 };
const DEFAULT_ZOOM = 5;

const ADDRESS_ZOOM = 15;

/* prettier-ignore */
const DEFAULT_ADDRESS_URL = getAddressImageSrc({location: {__typename: "Location", ...DEFAULT_POINT}}, WIDTH * 2, HEIGHT * 2, DEFAULT_ZOOM, true);

const StyledCard = styled(Paper)({
  minHeight: `${HEIGHT}px`,
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
});

const StyledMap = styled(
  Box,
  transientOptions
)<{ $url: string }>(({ $url }) => ({
  width: `${WIDTH}px`,
  flexShrink: 0,
  flexGrow: 0,
  background: `url(${$url}) no-repeat center`,
  backgroundSize: "cover",
  textTransform: "none",
}));

interface Props {
  building: {
    id: string;
    buildingAddress?: AddressDetails__AddressFragment | null;
  };
}

export const BuildingAddressBox: FC<Props> = ({ building }) => {
  const { t } = useTranslate("BuildingDetails");

  const client = useApolloClient();
  const [modifyBuilding] = useModifyBuildingMutation({ client });
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (address: AddressInput) => {
    if (address)
      try {
        await modifyBuilding({
          variables: {
            input: {
              id: building.id,
              values: {
                buildingAddress: omit(address, "id", "__typename"),
              },
            },
          },
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
  };

  const addressMapUrl =
    building.buildingAddress && building.buildingAddress.location
      ? getAddressImageSrc(
          { location: building.buildingAddress.location },
          WIDTH * 2,
          HEIGHT * 2,
          ADDRESS_ZOOM,
          false,
          true
        )
      : DEFAULT_ADDRESS_URL;

  return (
    <StyledCard>
      {building.buildingAddress ? (
        <StyledMap
          component="a"
          // @ts-ignore // FIXME
          target="_blank"
          rel="noreferrer nofollow"
          href={getAddressSearchUrl(building.buildingAddress)}
          $url={addressMapUrl}
        />
      ) : (
        <StyledMap $url={addressMapUrl} />
      )}
      <Box p={1} minWidth={0} flex={1}>
        <Address
          label={t("Location")}
          address={building.buildingAddress || null}
          actionButtons={
            <ModalOpenButton
              Modal={GetAddressModal}
              modalProps={{
                inputLabel: t("Location"),
                value: building.buildingAddress,
                handleComplete: async (address, handleClose) => {
                  await handleSubmit(address);
                  handleClose();
                },
                allowCustom: true,
              }}
            >
              <IconButton color="secondary" size="small">
                <EditIcon />
              </IconButton>
            </ModalOpenButton>
          }
        />
      </Box>
    </StyledCard>
  );
};
