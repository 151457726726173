import { Paper, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import React from "react";

interface Props extends Omit<React.ComponentProps<typeof Paper>, "sx"> {
  selected?: boolean;
  disabled?: boolean;
  padding?: number;
  sx?: (theme: Theme) => SystemStyleObject<Theme>;
}

export function CardItem({
  children,
  padding = 1,
  selected,
  disabled,
  onClick,
  sx,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <Paper
      onClick={!disabled ? onClick : undefined}
      sx={theme => ({
        display: "block",
        color: "inherit",
        textDecoration: "none",
        cursor: onClick && !disabled ? "pointer" : undefined,
        boxShadow: selected ? "inset 0 0 0 2px #377dc3" : undefined,
        opacity: disabled ? theme.palette.action.disabledOpacity : undefined,
        padding,
        ...sx?.(theme),
      })}
      {...props}
    >
      {children}
    </Paper>
  );
}
