import { CardContainer, ModalOpenButton } from "@msys/ui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../../../commons/layout/Stack";
import { ModifyOrganisationInvoicesTextsModal } from "../modals/ModifyOrganisationInvoicesTextsModal";

interface Props {
  organisationId: string;
}

export const OrganisationInvoicePdfSettingsBox = ({
  organisationId,
}: Props) => {
  const { t } = useTranslate("OrganisationSettings");

  return (
    <CardContainer isExpandable title={t("Invoice PDF settings")}>
      <Stack flexDirection="column" p={1} width="100%">
        <div>
          <ModalOpenButton
            Modal={ModifyOrganisationInvoicesTextsModal}
            modalProps={{
              organisationId,
              title: t("Customize invoice text"),
            }}
          >
            <Button
              type="button"
              color="secondary"
              variant="text"
              size="small"
              endIcon={<ChevronRightIcon />}
            >
              {t("Customize invoice text")}
            </Button>
          </ModalOpenButton>
        </div>
        {/*<Divider />*/}
        {/*<OrganisationPdfTextsAttachment*/}
        {/*  organisationId={props.organisationId}*/}
        {/*  type={OrganisationPdfTextsType.Invoice}*/}
        {/*/>*/}
      </Stack>
    </CardContainer>
  );
};
