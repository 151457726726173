import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DurationType,
  getDurationInSeconds,
  getNormalizedDuration,
  Modal,
} from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { DatePickerField } from "../../commons/form-fields/DatePickerField";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField";
import { SelectField } from "../../commons/form-fields/SelectField";
import { SwitchField } from "../../commons/form-fields/SwitchField";
import { useInvoiceSettingsModalQuery } from "./InvoiceSettingsModal.generated";
import { useModifyInvoiceMutation } from "./Invoices.generated";
import { useTranslate } from "@tolgee/react";
import {
  DEFAULT_INVOICE_PAYMENT_TERM_DURATION,
  DURATION_MAX_VALUES,
} from "../../utils";
import { getMemberOptions } from "../users/helpers";

interface FormValues {
  deliveryOfServiceStart: Moment | null;
  deliveryOfServiceEnd: Moment | null;
  contactPersonId: string;
  showLaborCostSeparately: boolean;
  paymentTermDurationType: DurationType;
  paymentTermDurationAmount: number;
}

interface Props {
  id?: string;
  projectId: string;
  invoiceId: string;
  docId: string;
  handleClose: () => void;
  handleComplete?: () => Promise<void> | void;
}

export const InvoiceSettingsModal = ({
  id,
  projectId,
  docId,
  invoiceId,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Invoices", "Global", "QuoteEditFooter"]);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useInvoiceSettingsModalQuery({
    client,
    variables: { invoiceId, projectId },
  });
  const members = query.data?.organisationMemberships ?? [];
  const invoice = getDataOrNull(query.data?.outgoingInvoice);

  const [modifyInvoice] = useModifyInvoiceMutation({ client });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyInvoice({
        variables: {
          input: {
            projectId,
            docId,
            invoiceId,
            values: {
              contactPersonId: values.contactPersonId,
              deliveryOfServiceStart: values.deliveryOfServiceStart
                ? values.deliveryOfServiceStart.format("YYYY-MM-DD")
                : null,
              deliveryOfServiceEnd: values.deliveryOfServiceEnd
                ? values.deliveryOfServiceEnd.format("YYYY-MM-DD")
                : null,
              showLaborCostSeparately: values.showLaborCostSeparately,
              paymentTermDuration: getDurationInSeconds({
                durationAmount: values.paymentTermDurationAmount,
                durationType: values.paymentTermDurationType,
              }),
            },
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
    await handleComplete?.();
    enqueueSnackbar(t("Settings saved", { ns: "QuoteEditFooter" }));
    handleClose();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    contactPersonId: Yup.string()
      .label(
        t("Contact person", {
          ns: "Invoices",
        })
      )
      .required(),
    deliveryOfServiceStart: Yup.object()
      .label(
        t("Delivery of service start", {
          ns: "Invoices",
        })
      )
      .nullable(),
    deliveryOfServiceEnd: Yup.object()
      .label(
        t("Delivery of service end", {
          ns: "Invoices",
        })
      )
      .nullable(),
    showLaborCostSeparately: Yup.boolean()
      .label(
        t("Show labor cost separately", {
          ns: "Invoices",
        })
      )
      .required(),
    paymentTermDurationAmount: Yup.number()
      .label(
        t("Payment term", {
          ns: "Invoices",
        })
      )
      .min(1)
      .required(),
    paymentTermDurationType: Yup.string()
      .label(
        t("Units", {
          ns: "QuoteEditFooter",
        })
      )
      .oneOf(["days", "weeks", "months"]),
  });

  const initialValues: FormValues = React.useMemo(() => {
    if (!invoice) {
      return {
        contactPersonId: "",
        deliveryOfServiceStart: null,
        deliveryOfServiceEnd: null,
        showLaborCostSeparately: false,
        paymentTermDurationAmount: 2,
        paymentTermDurationType: "weeks",
      };
    }
    const [durationAmount, durationType] = getNormalizedDuration(
      invoice.paymentTermDuration || DEFAULT_INVOICE_PAYMENT_TERM_DURATION
    );
    return {
      contactPersonId: "", // invoice.contactPerson.id,
      deliveryOfServiceStart: invoice.deliveryOfServiceStart
        ? moment(invoice.deliveryOfServiceStart)
        : null,
      deliveryOfServiceEnd: invoice.deliveryOfServiceEnd
        ? moment(invoice.deliveryOfServiceEnd)
        : null,
      showLaborCostSeparately: invoice.showLaborCostSeparately,
      paymentTermDurationAmount: durationAmount,
      paymentTermDurationType: durationType,
    };
  }, [invoice]);

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, isSubmitting, values }) => (
        <Modal
          id={id}
          title={t("Invoice settings", {
            ns: "Invoices",
          })}
          dialogProps={{ maxWidth: "xs" }}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text", disabled: isSubmitting },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: !isValid,
                loading: query.loading || isSubmitting,
              },
            },
          ]}
          isLoading={query.loading}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <SelectField
                name="contactPersonId"
                options={getMemberOptions(members)}
                label={t("Contact person", {
                  ns: "Invoices",
                })}
                disabled={isSubmitting}
              />
              <Stack direction={"row"} spacing={1}>
                <DatePickerField
                  name={"deliveryOfServiceStart"}
                  label={t("Delivery of service start", {
                    ns: "Invoices",
                  })}
                  disabled={isSubmitting}
                />
                <DatePickerField
                  name={"deliveryOfServiceEnd"}
                  label={t("Delivery of service end", {
                    ns: "Invoices",
                  })}
                  disabled={isSubmitting}
                />
              </Stack>

              <Stack direction={"row"} spacing={1}>
                <FormattedFloatField
                  name="paymentTermDurationAmount"
                  label={t("Payment term", {
                    ns: "Invoices",
                  })}
                  type="float"
                  min={0}
                  max={DURATION_MAX_VALUES[values.paymentTermDurationType]}
                  disabled={isSubmitting}
                />
                <SelectField
                  name="paymentTermDurationType"
                  label={t("Units", {
                    ns: "QuoteEditFooter",
                  })}
                  disabled={isSubmitting}
                  options={[
                    {
                      label: t("Days", {
                        ns: "QuoteEditFooter",
                      }),
                      value: "days",
                    },
                    {
                      label: t("Weeks", {
                        ns: "QuoteEditFooter",
                      }),
                      value: "weeks",
                    },
                    {
                      label: t("Months", {
                        ns: "QuoteEditFooter",
                      }),
                      value: "months",
                    },
                  ]}
                />
              </Stack>

              <div>
                <Typography>
                  {t("Display options", {
                    ns: "Invoices",
                  })}
                </Typography>
                <SwitchField
                  name="showLaborCostSeparately"
                  label={t("Show labor cost separately", {
                    ns: "Invoices",
                  })}
                  disabled={isSubmitting}
                />
              </div>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
