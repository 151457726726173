import { useMemo } from "react";
import { AbsenceReason } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

const ALL_ABSENCE_REASONS: AbsenceReason[] = [
  "VACATION",
  "SICK_LEAVE",
  "TRAINING",
  "OTHER",
];

export const useAbsenceReasons = () => {
  const { t } = useTranslate("AbsenceReason");

  const absenceReasonLabels: Record<AbsenceReason, string> = useMemo(
    () => ({
      VACATION: t("Vacation"),
      SICK_LEAVE: t("Sick leave"),
      TRAINING: t("Training"),
      OTHER: t("Other"),
    }),
    [t]
  );

  const absenceReasonOptions = useMemo(
    () =>
      ALL_ABSENCE_REASONS.map(reason => ({
        value: reason,
        label: absenceReasonLabels[reason],
      })),
    [absenceReasonLabels]
  );

  return { absenceReasonLabels, absenceReasonOptions };
};
