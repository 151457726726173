import { gql } from "@apollo/client";
import { Attachment3dModel, AttachmentImage } from "@msys/ui";
import { TreeHelperItem } from "../../trees/types";
import { ItemAttachmentFragment } from "../attachments/Attachments.generated";
import { AllowHaveChildren_ItemFragment } from "./helpers.generated";
import { QuoteProductsVisibilityFragment } from "./Quotes.generated";

export const allowHaveChildren = (item: AllowHaveChildren_ItemFragment) =>
  !(item.decisionOptionElimination || item.isItemEliminated);

export const shouldRenderCreateInput = allowHaveChildren;

export function getSubcontractPath<
  T extends TreeHelperItem & {
    selfOrClosestSubcontract?:
      | {
          subProject: { id: string };
          subRequirement: { id: string };
        }
      | null
      | undefined;
  }
>(item: T): string | null {
  if (item.selfOrClosestSubcontract) {
    /* prettier-ignore */
    return `/projects/${item.selfOrClosestSubcontract.subProject.id}/requirements/${item.selfOrClosestSubcontract.subRequirement.id}/edit`;
  }
  return null;
}

export function filterItemProductImages(
  attachments: ((AttachmentImage | Attachment3dModel) &
    ItemAttachmentFragment)[],
  productsVisibility: QuoteProductsVisibilityFragment
) {
  if (productsVisibility.showImages === "all") return attachments;
  let counter: number = 0;
  return attachments.filter(attachment => {
    if (attachment.isProductAttachment) {
      counter++;
      if (productsVisibility.showImages === "no") return false;
      if (productsVisibility.showImages === "one" && counter > 1) return false;
      return true;
    }
    return true;
  });
}
