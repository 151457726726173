import { useMediaQuery, useTheme } from "@mui/material";

interface State {
  isMinOneColumnWithPreview: boolean;
  isMinTwoColumnsWithPreview: boolean;
}

export const usePageWidth = (): State => {
  const theme = useTheme();
  const isMinOneColumnWithPreview = useMediaQuery(
    theme.breakpoints.up(theme.layout.pageWidth.oneColumnWithPreview),
    { noSsr: true }
  );
  const isMinTwoColumnsWithPreview = useMediaQuery(
    theme.breakpoints.up(theme.layout.pageWidth.twoColumnsWithPreview),
    { noSsr: true }
  );

  return {
    isMinOneColumnWithPreview,
    isMinTwoColumnsWithPreview,
  };
};
