import { CollapseSection, SelectMultiple } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormikContext } from "formik";
import { TextField } from "../../commons/form-fields/TextField";
import { AskWhen, AskWhom } from "../../../clients/graphqlTypes";
import { useQuestionContol } from "./useQuestionContol";

export interface QuestionControlSectionFormValues {
  askWhom: AskWhom[];
  askWhen: AskWhen[];
  prompt: string;
}

interface Props {
  isInitiallyExpanded?: boolean;
  defaultPrompt?: string;
  allowedAskWhen?: AskWhen[];
  readOnly?: boolean;
  disabled?: boolean;
}

export const QuestionControlSection = ({
  isInitiallyExpanded = false,
  defaultPrompt,
  allowedAskWhen,
  readOnly,
  disabled,
}: Props) => {
  const { t } = useTranslate(["QuoteItem"]);
  const { askWhomOptions, askWhenOptions } = useQuestionContol(allowedAskWhen);
  const formikProps = useFormikContext<QuestionControlSectionFormValues>();

  return (
    <CollapseSection
      title={t("Question control", {
        ns: "QuoteItem",
      })}
      isInitiallyExpanded={isInitiallyExpanded}
    >
      <Stack direction="column" spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <SelectMultiple
            name={"askWhen"}
            label={t("Ask when", {
              ns: "QuoteItem",
            })}
            options={askWhenOptions}
            value={formikProps.values.askWhen}
            onChange={value => {
              formikProps.setFieldValue("askWhen", value);
            }}
            inputProps={{ readOnly }}
            disabled={disabled}
          />
          <SelectMultiple
            name={"askWhom"}
            options={askWhomOptions}
            label={t("Ask whom", {
              ns: "QuoteItem",
            })}
            value={formikProps.values.askWhom}
            onChange={value => {
              formikProps.setFieldValue("askWhom", value);
            }}
            inputProps={{ readOnly }}
            disabled={disabled}
          />
        </Stack>
        <TextField
          name={"prompt"}
          label={t("Prompt", {
            ns: "QuoteItem",
          })}
          placeholder={defaultPrompt}
          inputProps={{ readOnly }}
          disabled={disabled}
        />
      </Stack>
    </CollapseSection>
  );
};

export const defaultQuestionControlValues: QuestionControlSectionFormValues = {
  askWhen: [],
  askWhom: ["contractor"],
  prompt: "",
};
