import { gql, useApolloClient } from "@apollo/client";
import { Modal, useLocalStorageAsState } from "@msys/ui";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import React from "react";
import * as uuid from "uuid";
import * as Yup from "yup";
import { PasswordField } from "../../commons/form-fields/PasswordField";
import { useCreateIdsConnectOrderMutation } from "./OrderViaIdsConnectModal.generated";

interface FormValues {
  clientId: string;
  username: string;
  password: string;
}

interface Props {
  projectId: string;
  orderId: string;
  systemSupplierOrganisationId: string;
  clientId?: string | undefined;
  idsConnectInfo: {
    clientIdRequired: boolean;
    usernameRequired: boolean;
    passwordRequired: boolean;
  };
  handleClose: () => void;
  handleComplete: (
    url: string,
    data: { [key: string]: string }
  ) => Promise<void> | void;
}

export function OrderViaIdsConnectModal({
  projectId,
  orderId,
  systemSupplierOrganisationId,
  idsConnectInfo,
  clientId: passedClientId = "",
  handleClose,
  handleComplete,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["PurchaseOrders", "Global"]);

  const client = useApolloClient();
  const [createIdsConnectOrder] = useCreateIdsConnectOrderMutation({
    client,
  });

  const [idsConnectLogin, setIdsConnectLogin] = useLocalStorageAsState<{
    clientId: string;
    username: string;
  }>("ids-connect-login", { clientId: passedClientId, username: "" });

  const handleSubmit = React.useCallback(
    async function handleSubmit({ clientId, username, password }: FormValues) {
      const idsConnectOrderResult = await createIdsConnectOrder({
        variables: {
          input: {
            msOrganisationId: systemSupplierOrganisationId,
            orderId,
            clientId,
            username,
            password,
            redirectUrl: `${window.location.origin}/projects/${projectId}/materials/purchase-orders/${orderId}`,
            fallbackRedirectUrl: `${window.location.origin}/projects/${projectId}/materials/purchase-orders`,
          },
        },
      });

      if (!idsConnectOrderResult.data) {
        enqueueSnackbar(
          "PurchaseOrders::Something went wrong, please try again"
        );
        return;
      }

      setIdsConnectLogin({ clientId, username });
      handleComplete(
        idsConnectOrderResult.data.createIdsConnectOrder.url,
        idsConnectOrderResult.data.createIdsConnectOrder.data
      );
      handleClose();
    },
    [
      createIdsConnectOrder,
      enqueueSnackbar,
      handleClose,
      handleComplete,
      orderId,
      projectId,
      setIdsConnectLogin,
      systemSupplierOrganisationId,
    ]
  );

  const initialValues = {
    clientId: idsConnectLogin?.clientId ?? "",
    username: idsConnectLogin?.username ?? "",
    password: "",
  };

  let validationSchema = Yup.object().shape({});
  if (idsConnectInfo.clientIdRequired) {
    validationSchema = validationSchema.shape({
      clientId: Yup.string()
        .label(
          t("Client id", {
            ns: "PurchaseOrders",
          })
        )
        .required(),
    });
  }
  if (idsConnectInfo.usernameRequired) {
    validationSchema = validationSchema.shape({
      username: Yup.string()
        .label(
          t("Username", {
            ns: "PurchaseOrders",
          })
        )
        .required(),
    });
  }
  if (idsConnectInfo.passwordRequired) {
    validationSchema = validationSchema.shape({
      password: Yup.string()
        .label(
          t("Password", {
            ns: "PurchaseOrders",
          })
        )
        .required(),
    });
  }

  const formId = React.useMemo(() => uuid.v4(), []);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {formik => (
        <Modal
          title={t("IDS Connect", {
            ns: "PurchaseOrders",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Submit", {
                ns: "Global",
              }),
              buttonProps: {
                disabled: !formik.isValid,
                endIcon: <ArrowForwardIcon />,
                type: "submit",
                form: formId,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              {idsConnectInfo.clientIdRequired && (
                <Field
                  component={TextField}
                  label={t("Client id", {
                    ns: "PurchaseOrders",
                  })}
                  name="clientId"
                  required
                />
              )}
              {idsConnectInfo.usernameRequired && (
                <Field
                  component={TextField}
                  label={t("Username", {
                    ns: "PurchaseOrders",
                  })}
                  name="username"
                  required
                />
              )}
              {idsConnectInfo.passwordRequired && (
                <PasswordField
                  name="password"
                  label={t("Password", {
                    ns: "PurchaseOrders",
                  })}
                />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
