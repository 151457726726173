/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectFromXiopdImportMutationVariables = Types.Exact<{
  input: Types.CreateProjectFromXiopdImportInput;
}>;


export type CreateProjectFromXiopdImportMutation = { createProjectFromXiopdImport: { __typename: 'CreateProjectFromXiopdImportResult', project: { __typename: 'Project', id: string } } };

export type CreateQuoteFromXiopdImportMutationVariables = Types.Exact<{
  input: Types.CreateQuoteFromXiopdImportInput;
}>;


export type CreateQuoteFromXiopdImportMutation = { createQuoteFromXiopdImport: { __typename: 'CreateQuoteFromXiopdImportResult', doc: { __typename: 'Quote', id: string } } };


export const CreateProjectFromXiopdImportDocument = gql`
    mutation CreateProjectFromXiopdImport($input: CreateProjectFromXiopdImportInput!) {
  createProjectFromXiopdImport(input: $input) {
    project {
      id
    }
  }
}
    `;
export type CreateProjectFromXiopdImportMutationFn = Apollo.MutationFunction<CreateProjectFromXiopdImportMutation, CreateProjectFromXiopdImportMutationVariables>;

/**
 * __useCreateProjectFromXiopdImportMutation__
 *
 * To run a mutation, you first call `useCreateProjectFromXiopdImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFromXiopdImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFromXiopdImportMutation, { data, loading, error }] = useCreateProjectFromXiopdImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectFromXiopdImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectFromXiopdImportMutation, CreateProjectFromXiopdImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectFromXiopdImportMutation, CreateProjectFromXiopdImportMutationVariables>(CreateProjectFromXiopdImportDocument, options);
      }
export type CreateProjectFromXiopdImportMutationHookResult = ReturnType<typeof useCreateProjectFromXiopdImportMutation>;
export type CreateProjectFromXiopdImportMutationResult = Apollo.MutationResult<CreateProjectFromXiopdImportMutation>;
export type CreateProjectFromXiopdImportMutationOptions = Apollo.BaseMutationOptions<CreateProjectFromXiopdImportMutation, CreateProjectFromXiopdImportMutationVariables>;
export const CreateQuoteFromXiopdImportDocument = gql`
    mutation CreateQuoteFromXiopdImport($input: CreateQuoteFromXiopdImportInput!) {
  createQuoteFromXiopdImport(input: $input) {
    doc {
      id
    }
  }
}
    `;
export type CreateQuoteFromXiopdImportMutationFn = Apollo.MutationFunction<CreateQuoteFromXiopdImportMutation, CreateQuoteFromXiopdImportMutationVariables>;

/**
 * __useCreateQuoteFromXiopdImportMutation__
 *
 * To run a mutation, you first call `useCreateQuoteFromXiopdImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteFromXiopdImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteFromXiopdImportMutation, { data, loading, error }] = useCreateQuoteFromXiopdImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteFromXiopdImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteFromXiopdImportMutation, CreateQuoteFromXiopdImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteFromXiopdImportMutation, CreateQuoteFromXiopdImportMutationVariables>(CreateQuoteFromXiopdImportDocument, options);
      }
export type CreateQuoteFromXiopdImportMutationHookResult = ReturnType<typeof useCreateQuoteFromXiopdImportMutation>;
export type CreateQuoteFromXiopdImportMutationResult = Apollo.MutationResult<CreateQuoteFromXiopdImportMutation>;
export type CreateQuoteFromXiopdImportMutationOptions = Apollo.BaseMutationOptions<CreateQuoteFromXiopdImportMutation, CreateQuoteFromXiopdImportMutationVariables>;