/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TasksTimesheetBreakForm_ItemFragment = { __typename: 'Item', id: string, timeTrackingRequired: boolean };

export const TasksTimesheetBreakForm_ItemFragmentDoc = gql`
    fragment TasksTimesheetBreakForm_Item on Item {
  id
  timeTrackingRequired
}
    `;