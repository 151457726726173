/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TimeReportsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type TimeReportsQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, timeReports: Array<{ __typename: 'TimeReport', id: string, number: string, approvedAt?: any | null, createdAt: any, fromDate: any, tillDate: any }> } | null } };

export type TimeReports__ReportFragment = { __typename: 'TimeReport', id: string, number: string, approvedAt?: any | null, createdAt: any, fromDate: any, tillDate: any };

export const TimeReports__ReportFragmentDoc = gql`
    fragment TimeReports__Report on TimeReport {
  id
  number
  approvedAt
  createdAt
  fromDate
  tillDate
}
    `;
export const TimeReportsDocument = gql`
    query TimeReports($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        state
        timeReports {
          id
          ...TimeReports__Report
        }
      }
    }
  }
}
    ${TimeReports__ReportFragmentDoc}`;

/**
 * __useTimeReportsQuery__
 *
 * To run a query within a React component, call `useTimeReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeReportsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useTimeReportsQuery(baseOptions: Apollo.QueryHookOptions<TimeReportsQuery, TimeReportsQueryVariables> & ({ variables: TimeReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeReportsQuery, TimeReportsQueryVariables>(TimeReportsDocument, options);
      }
export function useTimeReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeReportsQuery, TimeReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeReportsQuery, TimeReportsQueryVariables>(TimeReportsDocument, options);
        }
export function useTimeReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TimeReportsQuery, TimeReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TimeReportsQuery, TimeReportsQueryVariables>(TimeReportsDocument, options);
        }
export type TimeReportsQueryHookResult = ReturnType<typeof useTimeReportsQuery>;
export type TimeReportsLazyQueryHookResult = ReturnType<typeof useTimeReportsLazyQuery>;
export type TimeReportsSuspenseQueryHookResult = ReturnType<typeof useTimeReportsSuspenseQuery>;
export type TimeReportsQueryResult = Apollo.QueryResult<TimeReportsQuery, TimeReportsQueryVariables>;