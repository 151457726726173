import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DebouncedSearchInput,
  LoadingSpinner as LoadingIndicator,
  Modal,
} from "@msys/ui";
import { Box, List, ListItemButton, Typography, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { uniqueId } from "lodash";
import React from "react";
import { Stack } from "../../../commons/layout/Stack";
import {
  PickProductTypeModalSearchProductTypes_ProductTypeFragment,
  usePickProductTypeModalSearchProductTypesQuery,
} from "./PickProductTypeModal.generated";

export interface ProductType {
  id: string;
  key: string;
  label: string;
  typeSystem: "ETIM8";
}

interface Props {
  title?: string;
  productType?: ProductType | undefined | null;
  handleClose: () => void;
  handleComplete?: (productType: ProductType, handleClose: () => void) => void;
  submitButtonLabel?: string;
}

export function PickProductTypeModal({
  title,
  productType,
  handleClose,
  handleComplete,
  submitButtonLabel,
}: Props) {
  const { t } = useTranslate(["ProductOverview", "Global", "QuoteCreate"]);
  const client = useApolloClient();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedProductType, setSelectedProductType] = React.useState(
    productType ?? null
  );

  const query = usePickProductTypeModalSearchProductTypesQuery({
    client,
    variables: {
      searchTerm,
    },
  });

  const formId = React.useMemo(() => uniqueId(), []);

  const productTypes = getDataOrNull(
    query.data?.pimSearchProductTypes
  )?.productTypes;

  return (
    <Modal
      title={
        title ??
        t("Pick product type", {
          ns: "ProductOverview",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label:
            submitButtonLabel ??
            t("Save", {
              ns: "Global",
            }),
          buttonProps: {
            disabled:
              productType?.id === selectedProductType?.id ||
              !selectedProductType,
            form: formId,
            type: "submit",
          },
        },
      ]}
    >
      <form
        id={formId}
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();
          if (selectedProductType) {
            handleComplete?.(selectedProductType, handleClose);
          } else {
            handleClose();
          }
        }}
        style={{ maxHeight: "100%" }}
      >
        <Stack flexDirection="column">
          <DebouncedSearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            defaultValue={searchTerm}
            onChangeSearchTerm={setSearchTerm}
            autoFocus
          />
          {productTypes?.length === 10 && (
            <Typography color="secondary" variant="caption">
              {t("Showing only top 10 results", {
                ns: "QuoteCreate",
              })}
            </Typography>
          )}
          <Box minHeight="20em">
            <SearchResults
              isLoading={query.loading}
              results={productTypes}
              selectedProductType={selectedProductType}
              selectProductType={setSelectedProductType}
            />
          </Box>
        </Stack>
      </form>
    </Modal>
  );
}

const SearchResults: React.FC<{
  isLoading: boolean;
  results:
    | PickProductTypeModalSearchProductTypes_ProductTypeFragment[]
    | undefined;
  selectedProductType: ProductType | null;
  selectProductType: (productType: ProductType) => void;
}> = ({ isLoading, results = [], selectedProductType, selectProductType }) => {
  const theme = useTheme();
  const { t } = useTranslate("QuoteCreate");

  if (isLoading) return <LoadingIndicator />;
  if (results.length === 0)
    return (
      <Box paddingX={2} lineHeight={2} color={theme.palette.secondary.main}>
        {t("No results")}
      </Box>
    );

  return (
    <List dense disablePadding>
      {results.map(result => (
        <ListItemButton
          key={result.id}
          onClick={() => selectProductType(result)}
          selected={selectedProductType?.id === result.id}
        >
          {`${result.label} (${result.key})`}
        </ListItemButton>
      ))}
    </List>
  );
};
