import { CardContainer, useScreenWidth } from "@msys/ui";
import { Box, Container } from "@mui/material";
import React, { FC } from "react";
import { Page } from "../../commons/layout/Page";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageContainer } from "../../commons/layout/PageContainer";

export const ExampleLayoutBothSidebarsPreview: FC = () => {
  const { isMinDesktop } = useScreenWidth();
  return (
    <Page>
      <PageColumn $hasBackground $hasRightBorder>
        <CardContainer title="Tree">
          <Box height={2000} />
        </CardContainer>
      </PageColumn>
      {isMinDesktop && (
        <PageContainer>
          <Container maxWidth="md">
            <CardContainer title="Preview">
              <Box height={2000} />
            </CardContainer>
          </Container>
        </PageContainer>
      )}
      <PageColumn $hasBackground $hasLeftBorder={isMinDesktop}>
        <CardContainer title="Settings">
          <Box height={200} />
        </CardContainer>
        <CardContainer title="Summary">
          <Box height={400} />
        </CardContainer>
        <CardContainer title="Settings">
          <Box height={300} />
        </CardContainer>
      </PageColumn>
    </Page>
  );
};
