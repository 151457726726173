import { getDataOrNull } from "@msys/common";
import {
  ErrorMessage,
  FormattedPercentage,
  FormattedPrice,
  LabeledValue,
  LoadingSpinner,
  Modal,
} from "@msys/ui";
import { Stack } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";
import {
  calculateProfitability,
  ProductData,
  Profitability,
} from "./calculateProfitability";
import { gql, useApolloClient } from "@apollo/client";
import { useVanMarckeProfitabilityModalQuery } from "./VanMarckeProfitabilityModal.generated";

export function VanMarckeProfitabilityModal({
  projectId,
  docId,
  getItemData,
  handleClose,
}: {
  projectId: string;
  docId: string;
  getItemData: (articleNumber: string) => Promise<ProductData>;
  handleClose: () => void;
}) {
  const { t } = useTranslate(["VanMarcke"]);

  const client = useApolloClient();
  const query = useVanMarckeProfitabilityModalQuery({
    client,
    variables: { projectId, quoteId: docId },
    fetchPolicy: "no-cache",
  });
  const items = getDataOrNull(query.data?.quote)?.quote?.items;

  const [profitability, setProfitability] = React.useState<
    Profitability | Error | null
  >(null);

  React.useEffect(() => {
    if (items) {
      calculateProfitability(items, getItemData)
        .then(setProfitability)
        .catch(error => {
          if (error instanceof Error) {
            setProfitability(error);
          }
          throw new Error("Unknown error");
        });
    }
  }, [getItemData, items]);

  return (
    <Modal
      title={t("Profitability", {
        ns: "VanMarcke",
      })}
      handleClose={handleClose}
      isLoading={query.loading}
    >
      {profitability === null ? (
        <LoadingSpinner />
      ) : profitability instanceof Error ? (
        <ErrorMessage message={profitability.message} />
      ) : (
        <Stack spacing={1}>
          <LabeledValue
            label={t("Revenue", {
              ns: "VanMarcke",
            })}
          >
            <FormattedPrice value={profitability.revenue} />
          </LabeledValue>
          <LabeledValue
            label={t("Cost products", {
              ns: "VanMarcke",
            })}
          >
            <FormattedPrice value={profitability.costProducts} />
          </LabeledValue>
          <LabeledValue
            label={t("Cost installer", {
              ns: "VanMarcke",
            })}
          >
            <FormattedPrice value={profitability.costInstaller} />
          </LabeledValue>
          <LabeledValue
            label={t("Margin €", {
              ns: "VanMarcke",
            })}
          >
            <FormattedPrice value={profitability.marginValue} />
          </LabeledValue>
          <LabeledValue
            label={t("Margin %", {
              ns: "VanMarcke",
            })}
          >
            <FormattedPercentage value={profitability.marginPercentage} />
          </LabeledValue>
          <LabeledValue
            label={t("Max discount possible", {
              ns: "VanMarcke",
            })}
          >
            <FormattedPrice value={profitability.maxDiscountPossible} />
          </LabeledValue>
        </Stack>
      )}
    </Modal>
  );
}
