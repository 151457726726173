import {
  getEditableFloat,
  getFloat,
  getFormattedPrice,
} from "@msys/formatting";
import Euro from "@mui/icons-material/Euro";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { useLocale } from "../LocaleProvider";

interface Props extends Omit<TextFieldProps, "value" | "onChange"> {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  showAdornment?: boolean;
}

// TODO: extends FormattedInput
export function PriceInput({
  value,
  onChange,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  showAdornment = false,
  InputProps,
  onFocus,
  onBlur,
  ...props
}: Props) {
  const locale = useLocale();

  const [isFocused, setIsFocused] = React.useState(false);
  const [dirtyValue, setDirtyValue] = React.useState(
    getEditableFloat(value ?? 0, locale)
  );
  const floatFromDirty = getFloat(dirtyValue, locale);
  const isValid = floatFromDirty >= min && floatFromDirty <= max;

  function getValue(value: number) {
    if (isFocused || !isValid) {
      return dirtyValue;
    }
    return getFormattedPrice(value ?? 0, locale);
  }

  return (
    <TextField
      {...props}
      type="text"
      value={getValue(value)}
      onChange={async event => {
        setDirtyValue(event.target.value);
        const value = getFloat(event.target.value, locale);
        if (value >= min && value <= max) {
          onChange(value);
        }
      }}
      onFocus={event => {
        setIsFocused(true);
        if (isValid) {
          setDirtyValue(getEditableFloat(value ?? 0, locale));
        }
        onFocus?.(event);
      }}
      onBlur={event => {
        setIsFocused(false);
        onBlur?.(event);
      }}
      InputProps={{
        ...InputProps,
        endAdornment: showAdornment && (
          <InputAdornment position="end">
            <Euro />
          </InputAdornment>
        ),
      }}
      error={!isValid}
    />
  );
}
