import { CircularProgress, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { AttachmentInput, _3d_ShapeInput } from "../../../clients/graphqlTypes";
import {
  ThreeDShapeDetailsFragment,
  ThreeD__3d_ItemFragment,
} from "./ThreeD.generated";
import { useTranslate } from "@tolgee/react";
import { prepareDateShape, useThreeDCommunicate } from "./useThreeDCommunicate";

export interface ThreeDEditorRef {
  saveSnapshotAndClose(): Promise<void>;
}

interface Props {
  threeDUrl: string;
  threeDRoomSnapshot?: ThreeDShapeDetailsFragment | undefined | null;
  threeDSelectedRoomItems: ThreeD__3d_ItemFragment[];
  threeDInitialStepKey?:
    | "select-room"
    | "select-shape"
    | "transform-room-shape"
    | "transform-fittings-in-room"
    | "module-3d";
  threeDGuiMode?:
    | "complete-ui"
    | "no-right-menu-ui"
    | "no-right-footer-menu-ui"
    | "no-ui";
  onClose: () => void;
  onStart?: (inputDataShape: _3d_ShapeInput) => Promise<void> | void;
  onDataChange: (inputDataShape: _3d_ShapeInput) => Promise<void> | void;
  onSnapshots: (inputSnapshots: AttachmentInput[]) => Promise<void> | void;
}

export const ThreeDEditor = forwardRef<ThreeDEditorRef, Props>(
  (
    {
      threeDUrl,
      threeDRoomSnapshot,
      threeDSelectedRoomItems,
      threeDInitialStepKey,
      threeDGuiMode,
      onClose,
      onStart,
      onDataChange,
      onSnapshots,
    }: Props,
    forwardedRef
  ) => {
    const { t } = useTranslate("Global");

    const [
      threeDCommunicate,
      uploading,
      saveSnapshotAndClose,
      threeDUpdateRoomItems,
    ] = useThreeDCommunicate(
      threeDUrl,
      threeDRoomSnapshot,
      threeDSelectedRoomItems,
      threeDInitialStepKey,
      threeDGuiMode,
      onClose,
      onDataChange,
      onSnapshots
    );

    useEffect(() => {
      window.addEventListener("message", threeDCommunicate);
      return () => window.removeEventListener("message", threeDCommunicate);
    }, [threeDCommunicate]);

    useEffect(() => {
      if (threeDRoomSnapshot) onStart?.(prepareDateShape(threeDRoomSnapshot));
    }, [onStart, threeDRoomSnapshot]);

    useImperativeHandle(forwardedRef, () => ({
      saveSnapshotAndClose,
    }));

    useEffect(() => {
      threeDUpdateRoomItems(threeDSelectedRoomItems);
    }, [threeDUpdateRoomItems, threeDSelectedRoomItems]);

    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <iframe
          id={"3d-editor"}
          title={"3D"}
          src={threeDUrl}
          allowFullScreen
          height={"100%"}
          width={"100%"}
          frameBorder="0"
          onAbort={() =>
            window.removeEventListener("message", threeDCommunicate)
          }
          style={{
            flexGrow: 1,
            flexShrink: 1,
            border: 0,
            position: "absolute",
          }}
        ></iframe>
        {uploading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 99,
            }}
          >
            <CircularProgress size={"3rem"} />
            <Typography variant="h3" color="primary" sx={{ mt: 3 }}>
              {t("Saving...")}
            </Typography>
          </div>
        )}
      </div>
    );
  }
);
