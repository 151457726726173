/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type DocItemContextMenuItems_ItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, deletedAt?: any | null, isRootItem: boolean, decisionIsContingentItem: boolean, decisionContingentItemPreselection?: boolean | null, linkedQuoteTemplate?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null } | null };

export const DocItemContextMenuItems_ItemFragmentDoc = gql`
    fragment DocItemContextMenuItems_Item on Item {
  id
  type
  deletedAt
  isRootItem
  decisionIsContingentItem
  decisionContingentItemPreselection
  linkedQuoteTemplate {
    id
    resolvedAsReadModelVersionNumber
  }
}
    `;