import React, { FC } from "react";
import { Stack } from "../../commons/layout/Stack";
import { LabeledAddressValue } from "./LabeledAddressValue";

interface Props {
  label: string;
  address?: React.ComponentProps<typeof LabeledAddressValue>["address"];
  actionButtons?: React.ReactNode;
}

export const Address: FC<Props> = ({
  label,
  address,
  actionButtons = null,
}) => {
  return (
    <Stack alignItems="center" justifyContent="space-between">
      <LabeledAddressValue label={label} address={address} />
      {actionButtons}
    </Stack>
  );
};
