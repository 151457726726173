import { setup } from "xstate";

export type ProjectStateEvents =
  | { type: "CONTRACTOR_DECLINE" }
  | { type: "CONTRACTEE_DECLINE" }
  | { type: "WIN" }
  | { type: "CANCEL" }
  | { type: "COMPLETE" };

export type ProjectState =
  | "contractor_declined"
  | "contractee_declined"
  | "contracted"
  | "legacy_closed"
  | "cancelled"
  | "completed"
  | "opportunity";

export type ProjectStateMachineGuards = {
  isContractor: boolean;
};

export type ProjectStateMachineOptions = { guards: ProjectStateMachineGuards };

export const PROJECT_STATE_MACHINE_INITIAL = "opportunity";

export function makeProjectStateMachine(
  initial: ProjectState,
  { guards }: ProjectStateMachineOptions
) {
  return setup({
    types: {
      context: {} as {},
      events: {} as ProjectStateEvents,
    },
  })
    .createMachine({
      strict: true,
      id: "projectState",
      initial,
      context: {},
      states: {
        opportunity: {
          on: {
            CONTRACTOR_DECLINE: {
              target: "contractor_declined",
              guard: "isContractor",
            },
            CONTRACTEE_DECLINE: {
              target: "contractee_declined",
            },
            WIN: {
              target: "contracted",
            },
          },
        },
        contractor_declined: {},
        contractee_declined: {},
        contracted: {
          on: {
            CANCEL: { target: "cancelled" },
            COMPLETE: { target: "completed" },
          },
        },
        legacy_closed: { type: "final" },
        cancelled: { type: "final" },
        completed: { type: "final" },
      },
    })
    .provide({
      guards: { isContractor: () => guards.isContractor === true },
    });
}

// const actor = createActor(projectStateMachine);
// actor.start();

// const nextEvents = actor.getSnapshot()._nodes.flatMap(sn => sn.ownEvents);

// actor.send({ type: "COMPLETE" });
