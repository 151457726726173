/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TemplateQuoteWarnings_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, latestPublishedVersionNumber?: number | null, containsUnpublishedChanged: boolean, owningSystemOrganisationId: string };

export const TemplateQuoteWarnings_QuoteTemplateFragmentDoc = gql`
    fragment TemplateQuoteWarnings_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  latestPublishedVersionNumber
  containsUnpublishedChanged
  owningSystemOrganisationId
}
    `;