import { Table, TableColumn, useFormatting } from "@msys/ui";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { color } from "../../../../common/MuiThemeProvider";
import { TimeReportItem__EntityFragment } from "../../../main-routes/projects/ProjectTimeReportPreview.generated";
import { useTranslate } from "@tolgee/react";
import { TimeReportItemMobileRow } from "../preview/TimeReportItemMobileRow";

const cellStyle = { verticalAlign: "top" };

interface Props {
  items: TimeReportItem__EntityFragment[];
}

export const TimeReportItemsTable: FC<Props> = ({ items }) => {
  const { t } = useTranslate("TimeReports");

  const { getFormattedDate, getFormattedDuration } = useFormatting();

  const columns: TableColumn<TimeReportItem__EntityFragment>[] = [
    {
      cellStyle,
      id: "position",
      title: t("Pos."),
      render: (item, index) => `${index + 1}.`,
      width: 60,
    },
    {
      cellStyle,
      id: "assignee",
      title: t("Installer"),
      render: item => `${item.assignee.firstname} ${item.assignee.familyname}`,
    },
    {
      cellStyle,
      id: "date",
      title: t("Date"),
      render: item => getFormattedDate(item.date),
      width: 120,
    },
    {
      cellStyle,
      id: "duration",
      title: t("Time"),
      render: item => `${getFormattedDuration(item.duration)} h`,
      width: 100,
      textAlign: "right",
    },
  ];

  return (
    <Table
      columns={columns}
      items={items}
      mobileStackSpacing={0}
      renderPhoneRow={(item, index) => (
        <>
          {index > 0 ? <Divider /> : null}
          <TimeReportItemMobileRow item={item} index={index} />
        </>
      )}
      renderEmptyScreen={() => (
        <Paper>
          <Box p={2}>
            <Typography component="div" style={{ color: color.grey }}>
              {t("No time report items")}
            </Typography>
          </Box>
        </Paper>
      )}
    />
  );
};
