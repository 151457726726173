import { getImageUrl } from "@msys/common";
import { LabeledValue, useFormatting } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import moment from "moment";
import { CSSProperties, FC, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Attachment } from "../../features/attachments/helpers";
import { Stack } from "../layout/Stack";
import { AddSignatureModal } from "../modals/AddSignatureModal";

export type SignatureValue = {
  signature: string;
  place: string;
  date: moment.Moment;
};

interface Props {
  label: string;
  value?: {
    place: string;
    date: any;
    attachment: Attachment;
  } | null;
  canEdit?: boolean;
  style?: CSSProperties;
  onChange?: (value: SignatureValue) => void | Promise<void>;
  modalTitle?: string;
  modalDescription?: string;
  defaultPlace?: string;
  defaultDate?: any;
  disabled?: boolean;
}

export const LabeledSignatureValue: FC<Props> = ({
  label,
  value,
  onChange,
  style,
  canEdit = false,
  disabled = false,
  modalTitle,
  modalDescription,
  defaultPlace,
  defaultDate,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslate("SignatureModal");
  const { getFormattedDate } = useFormatting();
  return (
    <div style={{ position: "relative", ...(style ?? {}) }}>
      <Stack flexDirection="column">
        <LabeledValue label={label} inline>
          {value ? (
            <div style={{ width: "100%", height: 80, pointerEvents: "none" }}>
              <img
                src={getImageUrl({ url: value.attachment.url, width: 320 })}
                alt={value.attachment.title}
                height={80}
                style={{ objectFit: "contain" }}
              />
            </div>
          ) : (
            ""
          )}
        </LabeledValue>
        <LabeledValue label={t("Place, date")} inline>
          {value ? `${value.place}, ${getFormattedDate(value.date)}` : ""}
        </LabeledValue>
      </Stack>
      {canEdit && (
        <IconButton
          size="small"
          color="secondary"
          style={{
            position: "absolute",
            right: 0,
            top: -4,
            width: 30,
            height: 30,
          }}
          onClick={() => setOpen(true)}
          disabled={disabled || isOpen}
        >
          <EditIcon style={{ fontSize: "20px" }} />
        </IconButton>
      )}
      {isOpen && (
        <AddSignatureModal
          handleClose={() => setOpen(false)}
          handleComplete={async values => {
            await onChange?.(values);
            setOpen(false);
          }}
          defaultPlace={defaultPlace}
          defaultDate={defaultDate}
          title={modalTitle}
          description={modalDescription}
        />
      )}
    </div>
  );
};
