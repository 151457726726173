/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchSuppliers__SupplierFragment = { __typename: 'Company', id: string, title: string };

export type SupplierSelect__SearchSuppliersQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  excludeIfAlreadyCrm: Types.Scalars['Boolean']['input'];
  organisationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sorting?: Types.InputMaybe<Array<Types.QuerySearchSuppliersSorting>>;
}>;


export type SupplierSelect__SearchSuppliersQuery = { searchSuppliers: { __typename: 'SupplierSearchResultConnection', totalCount: number, edges: Array<{ __typename: 'SupplierSearchResultEdge', node: { __typename: 'SupplierSearchResult', distance?: number | null, organisation: { __typename: 'Company', id: string, title: string } } }> } };

export const SearchSuppliers__SupplierFragmentDoc = gql`
    fragment SearchSuppliers__Supplier on Company {
  id
  title
}
    `;
export const SupplierSelect__SearchSuppliersDocument = gql`
    query SupplierSelect__SearchSuppliers($limit: Int!, $excludeIfAlreadyCrm: Boolean!, $organisationIds: [ID!], $search: String, $sorting: [QuerySearchSuppliersSorting!]) {
  searchSuppliers(
    limit: $limit
    sorting: $sorting
    filters: {excludeIfAlreadyCrm: $excludeIfAlreadyCrm, organisationIds: $organisationIds}
    search: $search
  ) {
    edges {
      node {
        distance
        organisation {
          ...SearchSuppliers__Supplier
        }
      }
    }
    totalCount
  }
}
    ${SearchSuppliers__SupplierFragmentDoc}`;

/**
 * __useSupplierSelect__SearchSuppliersQuery__
 *
 * To run a query within a React component, call `useSupplierSelect__SearchSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierSelect__SearchSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierSelect__SearchSuppliersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      excludeIfAlreadyCrm: // value for 'excludeIfAlreadyCrm'
 *      organisationIds: // value for 'organisationIds'
 *      search: // value for 'search'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSupplierSelect__SearchSuppliersQuery(baseOptions: Apollo.QueryHookOptions<SupplierSelect__SearchSuppliersQuery, SupplierSelect__SearchSuppliersQueryVariables> & ({ variables: SupplierSelect__SearchSuppliersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierSelect__SearchSuppliersQuery, SupplierSelect__SearchSuppliersQueryVariables>(SupplierSelect__SearchSuppliersDocument, options);
      }
export function useSupplierSelect__SearchSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierSelect__SearchSuppliersQuery, SupplierSelect__SearchSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierSelect__SearchSuppliersQuery, SupplierSelect__SearchSuppliersQueryVariables>(SupplierSelect__SearchSuppliersDocument, options);
        }
export function useSupplierSelect__SearchSuppliersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SupplierSelect__SearchSuppliersQuery, SupplierSelect__SearchSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplierSelect__SearchSuppliersQuery, SupplierSelect__SearchSuppliersQueryVariables>(SupplierSelect__SearchSuppliersDocument, options);
        }
export type SupplierSelect__SearchSuppliersQueryHookResult = ReturnType<typeof useSupplierSelect__SearchSuppliersQuery>;
export type SupplierSelect__SearchSuppliersLazyQueryHookResult = ReturnType<typeof useSupplierSelect__SearchSuppliersLazyQuery>;
export type SupplierSelect__SearchSuppliersSuspenseQueryHookResult = ReturnType<typeof useSupplierSelect__SearchSuppliersSuspenseQuery>;
export type SupplierSelect__SearchSuppliersQueryResult = Apollo.QueryResult<SupplierSelect__SearchSuppliersQuery, SupplierSelect__SearchSuppliersQueryVariables>;