import { MenuButton, StatusChip } from "@msys/ui";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem } from "@mui/material";
import React from "react";
import { useUserData } from "../../../auth/useUserData";
import {
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../../commons/modals/ConfirmProcess";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes";
import { useProjectChangeState } from "../useProjectChangeState";
import {
  ALL_PROJECT_EVENTS,
  getPossibleProjectStateEvents,
  projectStateChipColors,
  useProjectStates,
} from "../useProjectStates";
import { ProjectStatusChip } from "./ProjectStatusBadge";

interface Props {
  canEdit: boolean;
  projectId: string;
  projectState: ProjectStateMachineStatus;
  size?: React.ComponentProps<typeof StatusChip>["size"];
}

export const ProjectStatusChipWithEditing = ({
  canEdit,
  projectId,
  projectState,
  size,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const events = getPossibleProjectStateEvents(projectState, {
    guards: { isContractor: viewer.organisation.isCraftsmanOrganisation },
  });
  const { projectStateLabels, projectStateEventLabels } = useProjectStates();
  const [open, setOpen] = React.useState<boolean>(false);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const { handleStateChange } = useProjectChangeState({
    projectId,
    projectState,
    confirmProcessRef,
  });

  return canEdit && events.length > 0 ? (
    <>
      <MenuButton
        Button={
          <StatusChip
            label={projectStateLabels[projectState]}
            color={projectStateChipColors[projectState]}
            size={size}
            endIcon={
              <KeyboardArrowDown
                fontSize="small"
                sx={{
                  transition: "transform 0.2s ease-out",
                  ...(open ? { transform: `rotate(-180deg)` } : undefined),
                }}
              />
            }
          />
        }
        onToggle={setOpen}
      >
        {ALL_PROJECT_EVENTS.filter(e => events.includes(e)).map(event => (
          <MenuItem
            key={event}
            onClick={async () => {
              await handleStateChange(event);
            }}
          >
            {projectStateEventLabels[event]}
          </MenuItem>
        ))}
      </MenuButton>
      <ConfirmProcess ref={confirmProcessRef} />
    </>
  ) : (
    <ProjectStatusChip projectState={projectState} size={size} />
  );
};
