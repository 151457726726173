import React from "react";

/** Cubic easing function. */
const ease = (n: number) => Math.pow(n, 5);

const getAutoscroll = (container?: HTMLElement | null) => {
  // if true, the window will continue to be scrolled at the current rate without user interaction
  let autoscrolling = false;

  // scroll speed (-1 to 1)
  let rate = 1;

  /** Scroll vertically in the direction given by rate until stop is called. */
  const scroll = () => {
    if (container) {
      container.scrollTo(0, container.scrollTop + rate);
    } else {
      window.scrollTo(0, document.documentElement.scrollTop + rate);
    }

    window.requestAnimationFrame(() => {
      if (autoscrolling) {
        scroll();
      }
    });
  };

  /** Starts the autoscroll or, if already scrolling, updates the scroll rate (-1 to 1). */
  const startOrUpdate = (rateNew: number) => {
    // update the scroll rate
    rate = ease(rateNew);

    // if we are already autoscrolling, do nothing
    if (autoscrolling) return;

    // otherwise kick off the autoscroll
    autoscrolling = true;
    scroll();
  };

  /** Stops scrolling. */
  startOrUpdate.stop = () => {
    autoscrolling = false;
  };

  return startOrUpdate;
};

let dragEnd: (() => void) | null = null;

// source: https://stackoverflow.com/questions/60474771/react-dnd-dragging-an-element-to-the-top-of-the-screen-when-theres-a-fixed-el/73197415#73197415
export const useScrollZone = (container?: HTMLElement | null) => {
  const startDrag = () => {
    if (dragEnd) return;

    const windowHeight = container
      ? container.offsetHeight
      : window.innerHeight;

    const distanceFromTheEdge = Math.min(Math.max(windowHeight / 6, 100), 300);

    /** An autoscroll function that will continue scrolling smoothly in a given direction until autoscroll.stop is called. Takes a number of pixels to scroll each iteration. */
    const autoscroll = getAutoscroll(container);

    /** Handles auto scroll on drag near the edge of the screen on mobile. */
    const onTouchMove = (e: TouchEvent | React.TouchEvent) => {
      // e.preventDefault();
      // e.stopPropagation();

      // distance of touch from top of screen (or container)
      let y = e.touches[0].clientY;
      if (container) {
        const { y: containerY } = container.getBoundingClientRect();
        y = Math.min(Math.max(y - containerY, 0), windowHeight);
      }

      // scroll down
      if (y < distanceFromTheEdge) {
        const rate =
          1 + Math.min(distanceFromTheEdge - y, 100) / distanceFromTheEdge;
        autoscroll(-rate);
      }
      // scroll up
      else if (y > windowHeight - distanceFromTheEdge) {
        const rate =
          1 +
          Math.min(y - windowHeight + distanceFromTheEdge, 100) /
            distanceFromTheEdge;
        autoscroll(rate);
      }
      // stop scrolling when not near the edge of the screen
      else {
        autoscroll.stop();
      }
    };

    const cancel = () => {
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", cancel);
      window.removeEventListener("touchcancel", cancel);
      window.removeEventListener("lostpointercapture", cancel);
      autoscroll.stop();
    };

    const start = () => {
      window.addEventListener("touchmove", onTouchMove, { passive: false });
      window.addEventListener("touchend", cancel);
      window.addEventListener("touchcancel", cancel);
      window.addEventListener("lostpointercapture", cancel);
    };

    // kick off
    start();
    dragEnd = cancel;
  };

  const endDrag = () => {
    dragEnd?.();
    dragEnd = null;
  };

  return { startDrag, endDrag };
};
