import { useApolloClient } from "@apollo/client";
import {
  CollapseSection,
  ModalOpenButton,
  useLocalStorageAsState,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useOutletContext } from "react-router-dom";
import {
  DefineItemProps2Entry,
  DocType,
  EnterItemProps2ValueEntry,
  Props2,
} from "../../../../clients/graphqlTypes";
import { ExpandCollapseIconButton } from "../../../commons/button/ExpandCollapseIconButton";
import { PropertiesManageModal } from "../modals/PropertiesManageModal";
import { PropertyAddModal } from "../modals/PropertyAddModal";
import { getPropInputEntryKey, propToDefineInput } from "../properties";
import { PropertyField } from "../PropertyField";
import {
  PropertiesSection_ItemFragment,
  usePropertiesSection_DefineItemProps2Mutation,
  usePropertiesSection_EnterItemProps2ValueMutation,
} from "./PropertiesSection.generated";

export function PropertiesSection({
  projectId,
  docId,
  docType,
  itemId,
  item,
  readOnly = false,
  refetchQueries,
}: {
  projectId: string | null;
  docId: string;
  docType: DocType;
  itemId: string;
  item: PropertiesSection_ItemFragment;
  readOnly?: boolean;
  refetchQueries?: string[];
}) {
  const { t } = useTranslate(["QuoteItem"]);

  const [isExpanded, setIsExpanded] = useLocalStorageAsState<boolean>(
    "msys-properties-expanded",
    false,
    true
  );

  const properties = React.useMemo(() => item.props2 ?? [], [item.props2]);

  const groups = React.useMemo(
    () =>
      properties.reduce(
        (acc, prop) => {
          const group = acc.find(g => g.name === prop.group);
          if (group) {
            // already exists
            group.properties.push(prop);
            return acc;
          }
          return [
            ...acc,
            {
              properties: [prop],
              name: prop.group,
            },
          ];
        },
        [] as {
          name: string;
          properties: Props2[];
        }[]
      ),
    [properties]
  );

  const client = useApolloClient();
  const [defineItemProps2] = usePropertiesSection_DefineItemProps2Mutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });
  const addProperty = React.useCallback(
    async (property: DefineItemProps2Entry) => {
      if (
        properties.some(prop => prop.key === getPropInputEntryKey(property))
      ) {
        throw new Error(
          t(
            "A property already exists with this name. Please enter another name",
            {
              ns: "QuoteItem",
            }
          )
        );
      }
      await defineItemProps2({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            entries: [...properties.map(propToDefineInput), property],
          },
        },
      });
    },
    [t, defineItemProps2, docId, itemId, projectId, properties]
  );
  const defineProperties = React.useCallback(
    async (properties: DefineItemProps2Entry[]) => {
      await defineItemProps2({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            entries: properties,
          },
        },
      });
    },
    [defineItemProps2, docId, itemId, projectId]
  );
  const setVisibility = React.useCallback(
    async (key: string, isVisible: boolean) => {
      const inputEntries = properties.map(property =>
        property.key === key
          ? propToDefineInput({ ...property, clientVisibility: isVisible })
          : propToDefineInput(property)
      );
      defineProperties(inputEntries);
    },
    [defineProperties, properties]
  );
  const [enterItemProps2] = usePropertiesSection_EnterItemProps2ValueMutation({
    client,
  });

  const { expandedItemIds } = useOutletContext<{
    expandedItemIds: string[] | undefined;
  }>();

  const setPropertyValue = React.useCallback(
    async (property: EnterItemProps2ValueEntry) => {
      await enterItemProps2({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
            entries: [property],
            expandedItemIds,
          },
        },
      });
    },
    [docId, enterItemProps2, itemId, projectId, expandedItemIds]
  );

  return (
    <CollapseSection
      title={t("Properties", {
        ns: "QuoteItem",
      })}
      titleVariant="h4"
      hideItemCountOnExpand={true}
      ActionButtons={
        <Stack alignItems="center" direction={"row"} spacing={1}>
          {properties.length > 0 && (
            <ExpandCollapseIconButton
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          )}
          {!readOnly && (
            <ModalOpenButton
              Modal={PropertiesManageModal}
              modalProps={{
                projectId,
                docId,
                docType,
                itemId,
                itemTitle: item.title,
                properties,
                defineProperties,
              }}
            >
              <IconButton color={"primary"} size={"small"}>
                <EditIcon />
              </IconButton>
            </ModalOpenButton>
          )}
          {!readOnly && (
            <ModalOpenButton
              Modal={PropertyAddModal}
              modalProps={{
                docType,
                handleComplete: async property => {
                  await addProperty(property);
                },
              }}
            >
              <IconButton size="small" color="primary">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          )}
        </Stack>
      }
    >
      {groups.length > 1 ? (
        groups.map(group => (
          <CollapseSection
            title={group.name || t("Non-grouped", { ns: "QuoteItem" })}
            titleVariant="body1"
            isInitiallyExpanded
            key={`properties-group-${group.name ?? "__other__"}`}
          >
            <Grid container spacing={1} columns={isExpanded ? 1 : 2}>
              {group.properties.map(property => (
                <Grid key={property.key} item xs={1}>
                  <PropertyField
                    projectId={projectId}
                    docId={docId}
                    itemId={itemId}
                    property={property}
                    setPropertyValue={setPropertyValue}
                    setVisibility={setVisibility}
                    readOnly={readOnly}
                  />
                </Grid>
              ))}
            </Grid>
          </CollapseSection>
        ))
      ) : (
        <Grid container spacing={1} columns={isExpanded ? 1 : 2}>
          {properties.map(property => (
            <Grid key={property.key} item xs={1}>
              <PropertyField
                projectId={projectId}
                docId={docId}
                itemId={itemId}
                property={property}
                setPropertyValue={setPropertyValue}
                setVisibility={setVisibility}
                readOnly={readOnly}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </CollapseSection>
  );
}
