/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../Fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MaterialCalculationBox_ItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, canBeWorkedOn: boolean, estimatedQuantity: number, quantityUnit: Types.QuantityUnit, invoiceAgreedOrProposed: Types.AgreedOrProposedCalculation, actualQuantity: number, averageMaterialBuyingPrice: number, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type MaterialCalculationBox_ProjectFragment = { __typename: 'Project', id: string };

export type QuoteModifyItem_MaterialCalculationBoxMutationVariables = Types.Exact<{
  input: Types.ModifyItemActualCalculationInput;
}>;


export type QuoteModifyItem_MaterialCalculationBoxMutation = { modifyItemActualCalculation: { __typename: 'ModifyItemActualCalculationResult', item: { __typename: 'Item', id: string, originVersionNumber?: number | null, actualQuantity: number } } };

export type MaterialCalculationEstimatedRow_ItemFragment = { __typename: 'Item', id: string, estimatedQuantity: number, quantityUnit: Types.QuantityUnit, invoiceAgreedOrProposed: Types.AgreedOrProposedCalculation, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type MaterialCalculationActualRow_ItemFragment = { __typename: 'Item', id: string, estimatedQuantity: number, actualQuantity: number, quantityUnit: Types.QuantityUnit, averageMaterialBuyingPrice: number };

export const MaterialCalculationEstimatedRow_ItemFragmentDoc = gql`
    fragment MaterialCalculationEstimatedRow_Item on Item {
  id
  estimatedQuantity
  quantityUnit
  invoiceAgreedOrProposed
  agreedCalculation {
    ...ItemCalculation
  }
  proposedCalculation {
    ...ItemCalculation
  }
}
    ${ItemCalculationFragmentDoc}`;
export const MaterialCalculationActualRow_ItemFragmentDoc = gql`
    fragment MaterialCalculationActualRow_Item on Item {
  id
  estimatedQuantity
  actualQuantity
  quantityUnit
  averageMaterialBuyingPrice
}
    `;
export const MaterialCalculationBox_ItemFragmentDoc = gql`
    fragment MaterialCalculationBox_Item on Item {
  id
  type
  canBeWorkedOn
  ...MaterialCalculationEstimatedRow_Item
  ...MaterialCalculationActualRow_Item
}
    ${MaterialCalculationEstimatedRow_ItemFragmentDoc}
${MaterialCalculationActualRow_ItemFragmentDoc}`;
export const MaterialCalculationBox_ProjectFragmentDoc = gql`
    fragment MaterialCalculationBox_Project on Project {
  id
}
    `;
export const QuoteModifyItem_MaterialCalculationBoxDocument = gql`
    mutation QuoteModifyItem_MaterialCalculationBox($input: ModifyItemActualCalculationInput!) {
  modifyItemActualCalculation(input: $input) {
    item {
      id
      originVersionNumber
      actualQuantity
    }
  }
}
    `;
export type QuoteModifyItem_MaterialCalculationBoxMutationFn = Apollo.MutationFunction<QuoteModifyItem_MaterialCalculationBoxMutation, QuoteModifyItem_MaterialCalculationBoxMutationVariables>;

/**
 * __useQuoteModifyItem_MaterialCalculationBoxMutation__
 *
 * To run a mutation, you first call `useQuoteModifyItem_MaterialCalculationBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteModifyItem_MaterialCalculationBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteModifyItemMaterialCalculationBoxMutation, { data, loading, error }] = useQuoteModifyItem_MaterialCalculationBoxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteModifyItem_MaterialCalculationBoxMutation(baseOptions?: Apollo.MutationHookOptions<QuoteModifyItem_MaterialCalculationBoxMutation, QuoteModifyItem_MaterialCalculationBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteModifyItem_MaterialCalculationBoxMutation, QuoteModifyItem_MaterialCalculationBoxMutationVariables>(QuoteModifyItem_MaterialCalculationBoxDocument, options);
      }
export type QuoteModifyItem_MaterialCalculationBoxMutationHookResult = ReturnType<typeof useQuoteModifyItem_MaterialCalculationBoxMutation>;
export type QuoteModifyItem_MaterialCalculationBoxMutationResult = Apollo.MutationResult<QuoteModifyItem_MaterialCalculationBoxMutation>;
export type QuoteModifyItem_MaterialCalculationBoxMutationOptions = Apollo.BaseMutationOptions<QuoteModifyItem_MaterialCalculationBoxMutation, QuoteModifyItem_MaterialCalculationBoxMutationVariables>;