import { gql, useApolloClient } from "@apollo/client";
import { FormattedPrice } from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { PermissionName } from "../../../clients/graphqlTypes";
import {
  InvoiceExtraTreeItem_InvoiceExtraItemFragment,
  InvoiceExtraTreeItem_InvoiceFragment,
} from "./InvoiceExtraTreeItem.generated";
import {
  InvoiceDetails__InvoiceFragment,
  InvoiceExtraItemDetails_InvoiceExtraItemFragment,
  useRemoveInvoiceExtraItemMutation,
} from "./Invoices.generated";
import { useTranslate } from "@tolgee/react";
import { VirtualBareTreeItem } from "../../trees-virtual/components/VirtualBareTreeItem";
import { BareTreeItem } from "../../trees/BareTreeItem";
import { TreeDataContainer } from "../../trees/components/TreeDataContainer";
import { useQuantityUnits } from "../doc-items/useQuantityUnits";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";

interface Props {
  invoice: InvoiceExtraTreeItem_InvoiceFragment;
  extraItem: InvoiceExtraTreeItem_InvoiceExtraItemFragment;
  onClick: () => void;
  selectedExtraItemId: string | null;
  project: { viewerPermissions: PermissionName[] };
}

export const InvoiceExtraTreeItem = ({
  extraItem,
  invoice,
  onClick,
  selectedExtraItemId,
  project,
}: Props) => {
  const { t } = useTranslate("InvoiceDetailsForm");
  const { enqueueSnackbar } = useSnackbar();
  const { quantityUnitLabels } = useQuantityUnits();

  const client = useApolloClient();
  const [removeInvoiceExtraItem] = useRemoveInvoiceExtraItemMutation({
    client,
  });

  const onRemoveClick = async () => {
    try {
      await removeInvoiceExtraItem({
        variables: {
          input: {
            docId: invoice.quote.id,
            projectId: invoice.project.id,
            invoiceId: invoice.id,
            invoiceExtraItemId: extraItem.id,
          },
        },
      });
      enqueueSnackbar(t("Extra item removed"));
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <VirtualBareTreeItem
      selected={selectedExtraItemId === extraItem.id}
      clickable
      onClick={onClick}
    >
      <BareTreeItem
        docAgreement={"NONE"}
        item={{
          id: extraItem.id,
          rank: 0,
          type: "paid",
          title: extraItem.title,
          binding: "binding",
          needsAgreement: false,
          decisionIsContingentItem: false,
          pathForPdf: "",
        }}
        showPath={false}
        Data123Left={
          <TreeDataContainer>
            <span>
              {extraItem.quantity} {quantityUnitLabels[extraItem.quantityUnit]}
            </span>
            <span>
              <FormattedPrice value={extraItem.pricePerUnit} />
            </span>
            <span style={{ fontWeight: "bold" }}>
              <FormattedPrice
                value={extraItem.pricePerUnit * extraItem.quantity}
              />
            </span>
          </TreeDataContainer>
        }
        IconRightButtons={
          <RestrictedByProjectPermissionWithDebug
            project={project}
            permission="MANAGE_INVOICES"
          >
            <IconButton
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onRemoveClick();
              }}
              color="primary"
              aria-label="Remove extra item"
              style={{ width: 42, height: 42, flexShrink: 0, flexGrow: 0 }}
              size="large"
            >
              <Tooltip title={t("Remove extra item")}>
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </RestrictedByProjectPermissionWithDebug>
        }
        isRootItem={false}
        depth={2}
        isGreyedOut={false}
        isHidden={false}
        isPriceHidden={false}
        subcontractPath={null}
      />
    </VirtualBareTreeItem>
  );
};
