import { MenuButton, StatusChip } from "@msys/ui";
import { Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../commons/layout/PageSubHeader";
import { OrganisationSupplierProductDeleteOrReenableMenuButton } from "../../features/products/buttons/OrganisationSupplierProductDeleteMenuButton";
import { OrganisationSupplierProductFavouriteButton } from "../../features/products/buttons/OrganisationSupplierProductFavouriteButton";
import { SupplierProductEditMenuButton } from "../../features/products/buttons/SupplierProductEditMenuButton";
import { SyncViaOpenMasterdataButton } from "../../features/products/buttons/SyncViaOpenMasterdataButton";
import { ProductOverview__ProductFragment } from "../../features/products/Product.generated";
import { namedOperations } from "../../../clients/graphqlTypes";

interface Props {
  canEdit: boolean;
  product: ProductOverview__ProductFragment;
  refetchQueries?: string[];
}

export const ProductOverviewSubHeader = ({
  product,
  canEdit,
  refetchQueries,
  isHeaderVisible,
  setHeaderVisible,
}: Props & PageSubHeaderInjectedProps) => {
  const { t } = useTranslate(["Product"]);

  return (
    <PageSubHeader
      title={product.texts?.title ?? product.articleNumber}
      hideTitleOnMobile={false}
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
      status={
        product.deletedAt ? (
          <StatusChip
            data-test-id="product-status-chip"
            label={t("Deleted", { ns: "Product" })}
            color={"warning"}
          />
        ) : null
      }
      entityNumber={
        product?.articleNumber ? (
          <Typography variant="body2">
            {t("Article no.", { ns: "Product" })}: {product?.articleNumber}
          </Typography>
        ) : undefined
      }
      actionMenu={
        product.organisationCanEdit && canEdit ? (
          <MenuButton>
            <SupplierProductEditMenuButton product={product} />
            <OrganisationSupplierProductDeleteOrReenableMenuButton
              product={product}
              refetchQueries={refetchQueries}
            />
          </MenuButton>
        ) : undefined
      }
      actionButtons={[
        <OrganisationSupplierProductFavouriteButton
          product={product}
          canEdit={canEdit}
          refetchQueries={[
            namedOperations.Query.PimGetProductByArticleNumberIdentifier,
          ]}
        />,
        product.__typename === "PimSupplierProduct" ? (
          <SyncViaOpenMasterdataButton
            product={product}
            canEdit={canEdit}
            refetchQueries={[
              namedOperations.Query.PimGetProductByArticleNumberIdentifier,
              namedOperations.Query
                .ProductOverview__SupplierProductAlternatives,
            ]}
          />
        ) : undefined,
      ]}
    />
  );
};
