import { useForkRef } from "@mui/material";
import {
  DataGridPremium,
  DataGridPremiumProps,
  deDE,
  enUS,
  frFR,
  GridSortModel,
  GridValidRowModel,
  nlNL,
  plPL,
  ruRU,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { isEqual, merge, omit } from "lodash";
import React from "react";
import { LoadingSpinner } from "../loading/LoadingSpinner";
import { useLocale } from "../LocaleProvider";
import { useDataGridColumns } from "./useDataGridColumns";

export { GridTreeDataGroupingCell } from "@mui/x-data-grid-premium";
export type {
  GridColDef,
  GridRowId,
  GridRowParams,
  GridSortDirection,
  GridSortItem,
  GridSortModel,
} from "@mui/x-data-grid-premium";

const DATAGRID_LOCALES: Record<string, typeof enUS> = {
  en: enUS,
  "en-GB": enUS,
  de: deDE,
  "de-DE": deDE,
  fr: frFR,
  "fr-FR": frFR,
  nl: nlNL,
  "nl-NL": nlNL,
  pl: plPL,
  "pl-PL": plPL,
  ru: ruRU,
  "ru-RU": ruRU,
};

const RESULTS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export type StateStore = {
  value: GridInitialStatePremium | undefined;
  saveValue: (newValue: GridInitialStatePremium | undefined) => void;
  loading: boolean;
};

interface Props<RowModel extends GridValidRowModel>
  extends DataGridPremiumProps<RowModel> {
  stateStore?: StateStore;
  borderless?: boolean;
  /**
   * Use stricly only when necessary and memoize object to reduce re-renders.
   * DataGrid is providing build-in localTexts based on locale value.
   */
  localeText?: DataGridPremiumProps<RowModel>["localeText"];
}

// could be used for testing or situations where we don't want to use user preferences store
export function useLocalStateStore(): StateStore {
  const [state, setState] = React.useState<GridInitialStatePremium | undefined>(
    undefined
  );
  return React.useMemo(
    () => ({ value: state, saveValue: setState, loading: false }),
    [state, setState]
  );
}

export function extendDataGridState(
  existingState: GridInitialStatePremium,
  overrideState: GridInitialStatePremium
): GridInitialStatePremium {
  const newState = {
    columns: {
      ...existingState?.columns,
      ...overrideState?.columns,
      dimensions: {
        ...existingState?.columns?.dimensions,
        ...overrideState?.columns?.dimensions,
      },
      pinnedColumns: {
        // @ts-ignore
        ...existingState?.columns?.pinnedColumns,
        // @ts-ignore
        ...overrideState?.columns?.pinnedColumns,
      },
      columnVisibilityModel: {
        ...existingState?.columns?.columnVisibilityModel,
        ...overrideState?.columns?.columnVisibilityModel,
      },
    },
    rowGrouping: {
      ...existingState?.rowGrouping,
      ...overrideState?.rowGrouping,
    },
    aggregation: {
      ...existingState?.aggregation,
      ...overrideState?.aggregation,
      model: {
        ...existingState?.aggregation?.model,
        ...overrideState?.aggregation?.model,
      },
    },
    filter: { ...existingState.filter, ...overrideState?.filter },
    pinnedColumns: {
      ...existingState.pinnedColumns,
      ...overrideState?.pinnedColumns,
    },
    preferencePanel: {
      open: false,
      ...existingState.preferencePanel,
      ...overrideState?.preferencePanel,
    },
  };
  return newState;
}

export function DataGrid<RowModel extends GridValidRowModel>({
  stateStore,
  initialState,
  apiRef,
  autoHeight = false,
  density = "compact",
  loading,
  hideFooter,
  localeText: passedLocaleText,
  columns,
  groupingColDef,
  disableColumnFilter = true,
  rows,
  onRowClick,
  slotProps,
  // SORTING
  sortingMode = "server",
  sortModel,
  onSortModelChange,
  // PAGINATION
  pagination = true,
  paginationMode,
  paginationModel,
  onPaginationModelChange,
  pageSizeOptions = RESULTS_PER_PAGE_OPTIONS,
  rowCount,
  // SELECTION
  rowSelectionModel,
  onRowSelectionModelChange,
  keepNonExistentRowsSelected = true,
  disableRowSelectionOnClick = true,
  disableMultipleRowSelection = true,
  disableAggregation = true,
  disableRowGrouping = true,
  // OTHER
  onResize,
  onStateChange,
  onColumnOrderChange,
  onColumnWidthChange,
  onColumnVisibilityModelChange,
  onRowGroupingModelChange,
  onAggregationModelChange,
  onFilterModelChange,
  onPinnedColumnsChange,
  borderless = false,
  ...other
}: Props<RowModel>) {
  const locale = useLocale();
  const datagridLocale = DATAGRID_LOCALES[locale] ?? enUS;

  const localeText = React.useMemo(() => {
    return merge(
      {},
      datagridLocale.components.MuiDataGrid.defaultProps.localeText,
      passedLocaleText
    );
  }, [passedLocaleText, datagridLocale]);

  const gridApiRef = useGridApiRef();

  const localStoreState = useLocalStateStore();

  const stateStoreRef = React.useRef<StateStore>(stateStore ?? localStoreState);
  stateStoreRef.current = stateStore ?? localStoreState;

  const state = (stateStore ?? localStoreState).value ?? initialState;

  const saveStateSnapshot = React.useCallback(
    (overrideState: GridInitialStatePremium) => {
      const newState = extendDataGridState(
        stateStoreRef.current?.value ?? {},
        overrideState ?? {}
      );
      if (
        stateStoreRef.current &&
        (!stateStoreRef.current.value ||
          !isEqual(stateStoreRef.current.value, newState))
      ) {
        stateStoreRef.current.saveValue(newState);
      }
    },
    []
  );

  const { columnsExtended, groupingColDefExtended } =
    useDataGridColumns<RowModel>(state, columns, groupingColDef);

  const sortModelFiltered = React.useMemo((): GridSortModel | undefined => {
    return sortModel?.filter(model =>
      // filtering out sort models which are not in columns definition
      columns.some(col => col.field === model.field)
    );
  }, [sortModel, columns]);

  const ref = useForkRef(apiRef, gridApiRef);

  // we don't want to render any table unless state is loaded – to prevent having callbacks with wrong state
  if ((stateStore ?? localStoreState).loading) return <LoadingSpinner />;

  return (
    <DataGridPremium<RowModel>
      // @ts-ignore
      apiRef={ref}
      autoHeight={autoHeight}
      density={density}
      loading={loading}
      hideFooter={hideFooter}
      localeText={localeText}
      columns={columnsExtended}
      groupingColDef={groupingColDefExtended}
      disableColumnFilter={disableColumnFilter}
      rows={rows}
      onRowClick={onRowClick}
      // SORTING
      sortingMode={sortingMode}
      sortModel={sortModelFiltered}
      onSortModelChange={onSortModelChange}
      // PAGINATION
      pagination={pagination}
      paginationMode={pagination ? paginationMode ?? "server" : undefined}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      pageSizeOptions={pageSizeOptions}
      rowCount={rowCount}
      // SELECTION
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      keepNonExistentRowsSelected={keepNonExistentRowsSelected}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      disableMultipleRowSelection={disableMultipleRowSelection}
      disableAggregation={disableAggregation}
      disableRowGrouping={disableRowGrouping}
      initialState={state}
      columnVisibilityModel={state?.columns?.columnVisibilityModel}
      rowGroupingModel={state?.rowGrouping?.model}
      aggregationModel={state?.aggregation?.model}
      filterModel={state?.filter?.filterModel}
      slotProps={{
        ...slotProps,
        columnsPanel: {
          disableHideAllButton: true,
          disableShowAllButton: true,
          ...slotProps?.columnsPanel,
        },
      }}
      // @ts-ignore
      pinnedColumns={state?.columns?.pinnedColumns ?? {}}
      onPinnedColumnsChange={(...args) => {
        saveStateSnapshot({
          // @ts-ignore
          columns: { pinnedColumns: args[0] },
        });
        return onPinnedColumnsChange?.(...args);
      }}
      onColumnOrderChange={(...args) => {
        const state = gridApiRef.current.exportState();
        if (state?.columns?.orderedFields) {
          saveStateSnapshot({
            columns: { orderedFields: state?.columns?.orderedFields },
          });
        }
        return onColumnOrderChange?.(...args);
      }}
      onColumnWidthChange={(...args) => {
        if (!args[0].colDef.width) return;
        saveStateSnapshot({
          columns: {
            dimensions: {
              [args[0].colDef.field]: {
                width: Math.round(args[0].colDef.width),
              },
            },
          },
        });
        return onColumnWidthChange?.(...args);
      }}
      onColumnVisibilityModelChange={(...args) => {
        saveStateSnapshot({ columns: { columnVisibilityModel: args[0] } });
        return onColumnVisibilityModelChange?.(...args);
      }}
      onRowGroupingModelChange={(...args) => {
        saveStateSnapshot({ rowGrouping: { model: args[0] } });
        return onRowGroupingModelChange?.(...args);
      }}
      onAggregationModelChange={(...args) => {
        saveStateSnapshot({ aggregation: { model: args[0] } });
        return onAggregationModelChange?.(...args);
      }}
      onFilterModelChange={(...args) => {
        saveStateSnapshot({ filter: { filterModel: args[0] } });
        return onFilterModelChange?.(...args);
      }}
      onStateChange={onStateChange}
      onResize={onResize}
      /*
      onStateChange={(...args) => {
        saveStateSnapshot();
        return onStateChange?.(...args);
      }}*/
      {...(borderless
        ? {
            sx: {
              "&": { border: "none" },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-cell": {
                padding: "0 4px",
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeader": {
                padding: "0 6px",
              },
            },
          }
        : undefined)}
      {...other}
    />
  );
}
