import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { DurationCounter, DurationValue, useFormatting } from "@msys/ui";
import WarningIcon from "@mui/icons-material/Warning";
import { Divider, Grid, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { flatten, omit } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import * as Yup from "yup";
import { ModifyProjectWorkSessionInput } from "../../../../clients/graphqlTypes";
import { color } from "../../../../common/MuiThemeProvider";
import {
  Project,
  RestrictedByProjectPermissionWithDebug,
} from "../../../auth/RestrictedByProjectPermission";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { DurationDailyField } from "../../../commons/form-fields/DurationDailyField";
import { Stack } from "../../../commons/layout/Stack";
import { WorkSession__EntityFragment } from "../../projects/boxes/ProjectOverviewWorkSessionsBox.generated";
import { processWorkSessions } from "../../sessions/helpers";
import {
  useModifyMyProjectWorkSessionMutation,
  useModifyProjectWorkSessionMutation,
} from "../boxes/TasksTimesheetBox.generated";
import { TasksTimesheetBreakForm_ItemFragment } from "./TasksTimesheetBreakForm.generated";
import { useTasksTimesheetTasksFormQuery } from "./TasksTimesheetTasksForm.generated";

interface Props {
  loading?: boolean;
  projectId: string;
  assigneeId: string;
  date: moment.Moment;
  workSessions: WorkSession__EntityFragment[];
  tasksItems: TasksTimesheetBreakForm_ItemFragment[];
}

export const TasksTimesheetBreakForm = ({
  project,
  ...props
}: Props & { project: Project }) => {
  const client = useApolloClient();
  const [modifySession] = useModifyProjectWorkSessionMutation({ client });
  const [modifyMySession] = useModifyMyProjectWorkSessionMutation({ client });

  return (
    <RestrictedByProjectPermissionWithDebug
      permission="MANAGE_PROJECT"
      project={project}
      otherwise={
        <RestrictedByProjectPermissionWithDebug
          permission="EXECUTE_TASK"
          project={project}
        >
          <TasksTimesheetBreakFormInternal
            {...props}
            modifySession={values =>
              modifyMySession({
                ...values,
                variables: {
                  ...values.variables,
                  input: omit(values.variables.input, "assigneeId"),
                },
              })
            }
          />
        </RestrictedByProjectPermissionWithDebug>
      }
    >
      <TasksTimesheetBreakFormInternal
        {...props}
        modifySession={modifySession}
      />
    </RestrictedByProjectPermissionWithDebug>
  );
};

interface FormValues {
  timeBreak: number;
}

const TasksTimesheetBreakFormInternal = ({
  loading = false,
  projectId,
  assigneeId,
  date,
  workSessions,
  tasksItems,
  modifySession,
}: Props & {
  modifySession: (values: {
    variables: {
      input: ModifyProjectWorkSessionInput;
      assigneeId: string;
      date: string;
    };
  }) => Promise<unknown>;
}) => {
  const { t } = useTranslate("WorkSessions");
  const { getFormattedDuration } = useFormatting();

  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const now = useMemo(() => moment(), []);

  const isPast = useMemo(
    () => moment(date).startOf("day") < moment(now).startOf("day"),
    [now, date]
  );

  const query = useTasksTimesheetTasksFormQuery({
    client,
    variables: { projectId, assigneeId, date: date.format("YYYY-MM-DD") },
    fetchPolicy: "network-only",
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const tasks = project?.tasks;

  const workSessionsDuration = flatten(
    tasks?.map(tasksDoc => tasksDoc.workSessions) ?? []
  ).reduce((acc, s) => {
    const d = s.duration ? s.duration : moment(s.till).diff(s.from, "minutes");
    return acc + d;
  }, 0);

  const {
    activeBreakSession,
    activeWorkSession,
    totalBreakDurationMs,
    activeWorkDurationMs,
  } = processWorkSessions(workSessions, isPast);

  const initialValues: FormValues = {
    timeBreak: Math.floor(totalBreakDurationMs / (60 * 1000)),
  };

  const modifyBreak = async (
    values: Pick<ModifyProjectWorkSessionInput, "duration">
  ) => {
    try {
      await modifySession({
        variables: {
          input: {
            ...values,
            isBreak: true,
            projectId,
            assigneeId,
            date: date.format("YYYY-MM-DD"),
          },
          assigneeId,
          date: date.format("YYYY-MM-DD"),
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const filteredTasksItems = tasksItems.filter(i => i.timeTrackingRequired);

  const canShowDiscrepancy =
    activeWorkSession &&
    activeWorkSession.till &&
    !activeBreakSession &&
    filteredTasksItems.length > 0 &&
    workSessionsDuration > 0;

  const discrepancyDuration = canShowDiscrepancy
    ? Math.abs(
        Math.floor(activeWorkDurationMs / (60 * 1000)) -
          Math.floor(totalBreakDurationMs / (60 * 1000)) -
          workSessionsDuration
      )
    : 0;

  const validationSchema = Yup.object().shape({
    timeBreak: Yup.number().label(t("Breaks")),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        await modifyBreak({
          duration: values.timeBreak,
        });
      }}
    >
      <Form>
        <Stack flexDirection="column">
          {filteredTasksItems.length > 0 && (
            <Grid container>
              <Grid
                item
                container
                xs={9}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>{t("Time on tasks")}</Typography>
              </Grid>
              <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography align="right">
                  {getFormattedDuration(workSessionsDuration)} h
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid
              item
              container
              xs={9}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography>{t("Breaks")}</Typography>
            </Grid>
            <Grid
              item
              container
              xs={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              {activeBreakSession ? (
                <Typography align="right">
                  <DurationCounter
                    isSimplified
                    noNegative
                    from={activeBreakSession.from}
                    deltaMs={totalBreakDurationMs}
                  />
                </Typography>
              ) : (
                <DurationDailyField
                  style={{ width: 82 }}
                  disabled={loading}
                  name="timeBreak"
                  label={t("Time")}
                />
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Divider />
            </Grid>
          </Grid>
          {filteredTasksItems.length > 0 && (
            <Grid container>
              <Grid
                item
                container
                xs={9}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {t("Day total")}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography align="right" style={{ fontWeight: "bold" }}>
                  {activeBreakSession ? (
                    <DurationCounter
                      isSimplified
                      noNegative
                      from={activeBreakSession.from}
                      deltaMs={
                        totalBreakDurationMs + workSessionsDuration * 60 * 1000
                      }
                    />
                  ) : (
                    <DurationValue
                      valueMs={
                        totalBreakDurationMs + workSessionsDuration * 60 * 1000
                      }
                      isSimplified
                      noNegative
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item container xs={9} justifyContent="flex-start">
              <Typography>{t("Time on site")}</Typography>
            </Grid>
            <Grid item container xs={3} justifyContent="flex-end">
              <Typography align="right">
                {activeWorkSession ? (
                  activeWorkSession.till ? (
                    <DurationValue
                      isSimplified
                      noNegative
                      valueMs={activeWorkDurationMs}
                    />
                  ) : (
                    <DurationCounter
                      isSimplified
                      noNegative
                      from={activeWorkSession.from}
                    />
                  )
                ) : (
                  <DurationValue valueMs={0} isSimplified noNegative />
                )}
              </Typography>
            </Grid>
          </Grid>
          {discrepancyDuration > 0 && (
            <>
              <Grid container>
                <Grid item xs={9} />
                <Grid item xs={3}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item container xs={9} justifyContent="flex-start">
                  <Typography>{t("Discrepancy")}</Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end">
                  <Stack spacing={1 / 2} alignItems="center">
                    <WarningIcon
                      style={{ color: color.orange, fontSize: "18px" }}
                    />
                    <Typography align="right" style={{ color: color.orange }}>
                      {getFormattedDuration(discrepancyDuration)} h
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
          <AutoSave enableReinitialize initialValues={initialValues} />
        </Stack>
      </Form>
    </Formik>
  );
};
