/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TaskShouldRender_ItemFragment = { __typename: 'Item', id: string, parentId?: string | null, isTreePreviewItemVisible: boolean, isItemEliminated: boolean, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export const TaskShouldRender_ItemFragmentDoc = gql`
    fragment TaskShouldRender_Item on Item {
  id
  parentId
  isTreePreviewItemVisible(showDecisionOptions: true)
  decisionOptionElimination {
    reason
  }
  isItemEliminated
}
    `;