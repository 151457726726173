/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserCalendarTokenQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type UserCalendarTokenQuery = { userCalendarToken?: string | null };

export type EnableUserCalendarMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type EnableUserCalendarMutation = { enableUserCalendar: { __typename: 'EnableUserCalendarResult', token: string } };

export type DisableUserCalendarMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type DisableUserCalendarMutation = { disableUserCalendar: { __typename: 'DisableUserCalendarResult', ok?: boolean | null } };


export const UserCalendarTokenDocument = gql`
    query UserCalendarToken($userId: ID!) {
  userCalendarToken(userId: $userId)
}
    `;

/**
 * __useUserCalendarTokenQuery__
 *
 * To run a query within a React component, call `useUserCalendarTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCalendarTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCalendarTokenQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserCalendarTokenQuery(baseOptions: Apollo.QueryHookOptions<UserCalendarTokenQuery, UserCalendarTokenQueryVariables> & ({ variables: UserCalendarTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCalendarTokenQuery, UserCalendarTokenQueryVariables>(UserCalendarTokenDocument, options);
      }
export function useUserCalendarTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCalendarTokenQuery, UserCalendarTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCalendarTokenQuery, UserCalendarTokenQueryVariables>(UserCalendarTokenDocument, options);
        }
export function useUserCalendarTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserCalendarTokenQuery, UserCalendarTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserCalendarTokenQuery, UserCalendarTokenQueryVariables>(UserCalendarTokenDocument, options);
        }
export type UserCalendarTokenQueryHookResult = ReturnType<typeof useUserCalendarTokenQuery>;
export type UserCalendarTokenLazyQueryHookResult = ReturnType<typeof useUserCalendarTokenLazyQuery>;
export type UserCalendarTokenSuspenseQueryHookResult = ReturnType<typeof useUserCalendarTokenSuspenseQuery>;
export type UserCalendarTokenQueryResult = Apollo.QueryResult<UserCalendarTokenQuery, UserCalendarTokenQueryVariables>;
export const EnableUserCalendarDocument = gql`
    mutation EnableUserCalendar($userId: ID!) {
  enableUserCalendar(input: {userId: $userId}) {
    token
  }
}
    `;
export type EnableUserCalendarMutationFn = Apollo.MutationFunction<EnableUserCalendarMutation, EnableUserCalendarMutationVariables>;

/**
 * __useEnableUserCalendarMutation__
 *
 * To run a mutation, you first call `useEnableUserCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserCalendarMutation, { data, loading, error }] = useEnableUserCalendarMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEnableUserCalendarMutation(baseOptions?: Apollo.MutationHookOptions<EnableUserCalendarMutation, EnableUserCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableUserCalendarMutation, EnableUserCalendarMutationVariables>(EnableUserCalendarDocument, options);
      }
export type EnableUserCalendarMutationHookResult = ReturnType<typeof useEnableUserCalendarMutation>;
export type EnableUserCalendarMutationResult = Apollo.MutationResult<EnableUserCalendarMutation>;
export type EnableUserCalendarMutationOptions = Apollo.BaseMutationOptions<EnableUserCalendarMutation, EnableUserCalendarMutationVariables>;
export const DisableUserCalendarDocument = gql`
    mutation DisableUserCalendar($userId: ID!) {
  disableUserCalendar(input: {userId: $userId}) {
    ok
  }
}
    `;
export type DisableUserCalendarMutationFn = Apollo.MutationFunction<DisableUserCalendarMutation, DisableUserCalendarMutationVariables>;

/**
 * __useDisableUserCalendarMutation__
 *
 * To run a mutation, you first call `useDisableUserCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserCalendarMutation, { data, loading, error }] = useDisableUserCalendarMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDisableUserCalendarMutation(baseOptions?: Apollo.MutationHookOptions<DisableUserCalendarMutation, DisableUserCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableUserCalendarMutation, DisableUserCalendarMutationVariables>(DisableUserCalendarDocument, options);
      }
export type DisableUserCalendarMutationHookResult = ReturnType<typeof useDisableUserCalendarMutation>;
export type DisableUserCalendarMutationResult = Apollo.MutationResult<DisableUserCalendarMutation>;
export type DisableUserCalendarMutationOptions = Apollo.BaseMutationOptions<DisableUserCalendarMutation, DisableUserCalendarMutationVariables>;