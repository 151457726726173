import { useApolloClient } from "@apollo/client";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { CircularProgress, Icon } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import {
  useCreateProjectFromGaebImportMutation,
  useCreateQuoteFromGaebImportMutation,
} from "./GaebPImport.generated";
import { useTranslate } from "@tolgee/react";
import { GaebFileUploader } from "./GaebPFileUploader";

type Props = {
  refetchQueries?: string[];
  projectParams?: { isTentative?: boolean };
};

export const ImportCustomGaebP84Button = ({
  refetchQueries,
  projectParams,
}: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate("Projects");
  const [createProjectFromGaebImportMutation] =
    useCreateProjectFromGaebImportMutation({
      client,
    });
  const [createQuoteFromGaebImportMutation] =
    useCreateQuoteFromGaebImportMutation({
      client,
    });
  const { enqueueSnackbar } = useSnackbar();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

  async function importGaebP84(filename: string) {
    const createProjectFromGaebImportResult =
      await createProjectFromGaebImportMutation({
        variables: {
          input: {
            filename,
            projectParams,
            gaebType: "p84_custom",
          },
        },
        refetchQueries,
      });

    if (!createProjectFromGaebImportResult.data) {
      throw new Error("Import not successful");
    }

    await createQuoteFromGaebImportMutation({
      variables: {
        input: {
          filename,
          projectId:
            createProjectFromGaebImportResult.data?.createProjectFromGaebImport
              .project.id,
          gaebType: "p84_custom",
        },
      },
      refetchQueries,
    });
    return undefined;
  }

  return (
    <MenuItemWithIcon
      icon={
        status !== "loading" ? (
          <Icon>
            <FontAwesomeIcon icon={faFileImport} />
          </Icon>
        ) : (
          <CircularProgress size={"1rem"} />
        )
      }
      onClick={async () => {
        fileInputRef.current!.click();
      }}
      disabled={status === "loading"}
    >
      <GaebFileUploader
        innerRef={fileInputRef}
        onStart={() => setStatus("loading")}
        onCancel={() => setStatus("idle")}
        onError={e => {
          if (e instanceof Error)
            enqueueSnackbar(e.message, { variant: "error" });
          Sentry.captureException(e);
          setStatus("error");
        }}
        onUploadFile={async file => {
          setStatus("loading");
          try {
            await importGaebP84(file.name);
            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            Sentry.captureException(e);
            setStatus("error");
          }
        }}
        type={"p84"}
      />
      {`${t("Import")} GAEB P84`}
    </MenuItemWithIcon>
  );
};
