/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestImportGaebUploadUrlMutationVariables = Types.Exact<{
  input: Types.RequestGaebImportUploadUrlInput;
}>;


export type RequestImportGaebUploadUrlMutation = { requestGaebImportUploadUrl: { __typename: 'RequestUploadUrlResult', uploadUrl: string } };


export const RequestImportGaebUploadUrlDocument = gql`
    mutation RequestImportGaebUploadUrl($input: RequestGaebImportUploadUrlInput!) {
  requestGaebImportUploadUrl(input: $input) {
    uploadUrl
  }
}
    `;
export type RequestImportGaebUploadUrlMutationFn = Apollo.MutationFunction<RequestImportGaebUploadUrlMutation, RequestImportGaebUploadUrlMutationVariables>;

/**
 * __useRequestImportGaebUploadUrlMutation__
 *
 * To run a mutation, you first call `useRequestImportGaebUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestImportGaebUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestImportGaebUploadUrlMutation, { data, loading, error }] = useRequestImportGaebUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestImportGaebUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<RequestImportGaebUploadUrlMutation, RequestImportGaebUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestImportGaebUploadUrlMutation, RequestImportGaebUploadUrlMutationVariables>(RequestImportGaebUploadUrlDocument, options);
      }
export type RequestImportGaebUploadUrlMutationHookResult = ReturnType<typeof useRequestImportGaebUploadUrlMutation>;
export type RequestImportGaebUploadUrlMutationResult = Apollo.MutationResult<RequestImportGaebUploadUrlMutation>;
export type RequestImportGaebUploadUrlMutationOptions = Apollo.BaseMutationOptions<RequestImportGaebUploadUrlMutation, RequestImportGaebUploadUrlMutationVariables>;