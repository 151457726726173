/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { QuoteListItem_IncomingQuoteFragmentDoc, QuoteListItem_OutgoingQuoteFragmentDoc } from '../../features/quotes/QuoteListItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentsIncomingQuotesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sorting: Array<Types.IncomingQuotesSorting>;
  filterIsBinding?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterAgreement?: Types.InputMaybe<Types.Agreement>;
  filterisChangePending?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterByDocActors?: Types.InputMaybe<Array<Types.DocActorFilterInput>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DocumentsIncomingQuotesQuery = { incomingQuotes: { __typename: 'IncomingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'IncomingQuoteEdge', node: { __typename: 'IncomingQuote', id: string, number: string, title: string, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, projectId: string, projectNumber: string, docActorContractorName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean, request?: { __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } | null } }> } | { __typename: 'MissingPermissions' } };

export type IncomingQuoteFragment = { __typename: 'IncomingQuote', id: string, number: string, title: string, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, projectId: string, projectNumber: string, docActorContractorName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean, request?: { __typename: 'Request', id: string, title: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } | null };

export type DocumentsOutgoingQuotesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sorting: Array<Types.OutgoingQuotesSorting>;
  filterIsPublished?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterIsBinding?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterAgreement?: Types.InputMaybe<Types.Agreement>;
  filterisChangePending?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterByDocActors?: Types.InputMaybe<Array<Types.DocActorFilterInput>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DocumentsOutgoingQuotesQuery = { outgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'OutgoingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'OutgoingQuoteEdge', node: { __typename: 'OutgoingQuote', id: string, number: string, title: string, createdAt: any, createdBy: string, publishedAt?: any | null, acceptedAt?: any | null, projectId: string, projectNumber: string, docActorClientName: string, docActorContracteeName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, isPublished: boolean, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean, xStatus?: string | null } }> } };

export type OutgoingQuoteFragment = { __typename: 'OutgoingQuote', id: string, number: string, title: string, createdAt: any, createdBy: string, publishedAt?: any | null, acceptedAt?: any | null, projectId: string, projectNumber: string, docActorClientName: string, docActorContracteeName: string, proposedTotalPrice: number, agreedTotalPrice?: number | null, isPublished: boolean, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean, xStatus?: string | null };

export const IncomingQuoteFragmentDoc = gql`
    fragment IncomingQuote on IncomingQuote {
  id
  number
  title
  createdAt
  publishedAt
  acceptedAt
  projectId
  projectNumber
  docActorContractorName
  proposedTotalPrice
  agreedTotalPrice
  isBinding
  agreement
  needsAgreement
  request {
    id
    title
    status
    wonBySystemOrganisationId
    owningSystemOrganisationId
  }
}
    `;
export const OutgoingQuoteFragmentDoc = gql`
    fragment OutgoingQuote on OutgoingQuote {
  id
  number
  title
  createdAt
  createdBy
  publishedAt
  acceptedAt
  projectId
  projectNumber
  docActorClientName
  docActorContracteeName
  proposedTotalPrice
  agreedTotalPrice
  isPublished
  isBinding
  agreement
  needsAgreement
  xStatus
}
    `;
export const DocumentsIncomingQuotesDocument = gql`
    query DocumentsIncomingQuotes($limit: Int!, $offset: Int!, $sorting: [IncomingQuotesSorting!]!, $filterIsBinding: Boolean, $filterAgreement: Agreement, $filterisChangePending: Boolean, $filterByDocActors: [DocActorFilterInput!], $searchTerm: String) {
  incomingQuotes(
    limit: $limit
    offset: $offset
    sorting: $sorting
    filters: {isBinding: $filterIsBinding, agreement: $filterAgreement, isChangePending: $filterisChangePending, docActors: $filterByDocActors}
    search: $searchTerm
  ) {
    ... on IncomingQuoteConnection {
      edges {
        node {
          id
          ...IncomingQuote
          ...QuoteListItem_IncomingQuote
        }
      }
      totalCount
    }
  }
}
    ${IncomingQuoteFragmentDoc}
${QuoteListItem_IncomingQuoteFragmentDoc}`;

/**
 * __useDocumentsIncomingQuotesQuery__
 *
 * To run a query within a React component, call `useDocumentsIncomingQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsIncomingQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsIncomingQuotesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorting: // value for 'sorting'
 *      filterIsBinding: // value for 'filterIsBinding'
 *      filterAgreement: // value for 'filterAgreement'
 *      filterisChangePending: // value for 'filterisChangePending'
 *      filterByDocActors: // value for 'filterByDocActors'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDocumentsIncomingQuotesQuery(baseOptions: Apollo.QueryHookOptions<DocumentsIncomingQuotesQuery, DocumentsIncomingQuotesQueryVariables> & ({ variables: DocumentsIncomingQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsIncomingQuotesQuery, DocumentsIncomingQuotesQueryVariables>(DocumentsIncomingQuotesDocument, options);
      }
export function useDocumentsIncomingQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsIncomingQuotesQuery, DocumentsIncomingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsIncomingQuotesQuery, DocumentsIncomingQuotesQueryVariables>(DocumentsIncomingQuotesDocument, options);
        }
export function useDocumentsIncomingQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsIncomingQuotesQuery, DocumentsIncomingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentsIncomingQuotesQuery, DocumentsIncomingQuotesQueryVariables>(DocumentsIncomingQuotesDocument, options);
        }
export type DocumentsIncomingQuotesQueryHookResult = ReturnType<typeof useDocumentsIncomingQuotesQuery>;
export type DocumentsIncomingQuotesLazyQueryHookResult = ReturnType<typeof useDocumentsIncomingQuotesLazyQuery>;
export type DocumentsIncomingQuotesSuspenseQueryHookResult = ReturnType<typeof useDocumentsIncomingQuotesSuspenseQuery>;
export type DocumentsIncomingQuotesQueryResult = Apollo.QueryResult<DocumentsIncomingQuotesQuery, DocumentsIncomingQuotesQueryVariables>;
export const DocumentsOutgoingQuotesDocument = gql`
    query DocumentsOutgoingQuotes($limit: Int!, $offset: Int!, $sorting: [OutgoingQuotesSorting!]!, $filterIsPublished: Boolean, $filterIsBinding: Boolean, $filterAgreement: Agreement, $filterisChangePending: Boolean, $filterByDocActors: [DocActorFilterInput!], $searchTerm: String) {
  outgoingQuotes(
    limit: $limit
    offset: $offset
    sorting: $sorting
    filters: {isPublished: $filterIsPublished, isBinding: $filterIsBinding, agreement: $filterAgreement, isChangePending: $filterisChangePending, docActors: $filterByDocActors}
    search: $searchTerm
  ) {
    ... on OutgoingQuoteConnection {
      edges {
        node {
          id
          ...OutgoingQuote
          ...QuoteListItem_OutgoingQuote
        }
      }
      totalCount
    }
  }
}
    ${OutgoingQuoteFragmentDoc}
${QuoteListItem_OutgoingQuoteFragmentDoc}`;

/**
 * __useDocumentsOutgoingQuotesQuery__
 *
 * To run a query within a React component, call `useDocumentsOutgoingQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsOutgoingQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsOutgoingQuotesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorting: // value for 'sorting'
 *      filterIsPublished: // value for 'filterIsPublished'
 *      filterIsBinding: // value for 'filterIsBinding'
 *      filterAgreement: // value for 'filterAgreement'
 *      filterisChangePending: // value for 'filterisChangePending'
 *      filterByDocActors: // value for 'filterByDocActors'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDocumentsOutgoingQuotesQuery(baseOptions: Apollo.QueryHookOptions<DocumentsOutgoingQuotesQuery, DocumentsOutgoingQuotesQueryVariables> & ({ variables: DocumentsOutgoingQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsOutgoingQuotesQuery, DocumentsOutgoingQuotesQueryVariables>(DocumentsOutgoingQuotesDocument, options);
      }
export function useDocumentsOutgoingQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsOutgoingQuotesQuery, DocumentsOutgoingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsOutgoingQuotesQuery, DocumentsOutgoingQuotesQueryVariables>(DocumentsOutgoingQuotesDocument, options);
        }
export function useDocumentsOutgoingQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsOutgoingQuotesQuery, DocumentsOutgoingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentsOutgoingQuotesQuery, DocumentsOutgoingQuotesQueryVariables>(DocumentsOutgoingQuotesDocument, options);
        }
export type DocumentsOutgoingQuotesQueryHookResult = ReturnType<typeof useDocumentsOutgoingQuotesQuery>;
export type DocumentsOutgoingQuotesLazyQueryHookResult = ReturnType<typeof useDocumentsOutgoingQuotesLazyQuery>;
export type DocumentsOutgoingQuotesSuspenseQueryHookResult = ReturnType<typeof useDocumentsOutgoingQuotesSuspenseQuery>;
export type DocumentsOutgoingQuotesQueryResult = Apollo.QueryResult<DocumentsOutgoingQuotesQuery, DocumentsOutgoingQuotesQueryVariables>;