/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemAttachmentFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequirementSendViaEmailModal_RequirementFragment = { __typename: 'Requirement', id: string };

export type RequirementSendViaEmailModal_ProjectFragment = { __typename: 'Project', id: string };

export type SendRequirementViaEmailQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  requirementId: Types.Scalars['ID']['input'];
}>;


export type SendRequirementViaEmailQuery = { requirement: { __typename: 'MissingPermissions' } | { __typename: 'RequirementResult', requirement?: { __typename: 'Requirement', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, isVisibleToOtherSide: boolean, isRootItem: boolean, attachments: Array<{ __typename: 'ItemAttachment', id: string, title: string, mimeType: string, url: string, group: string, clientVisibility: boolean, isProductAttachment: boolean }> }> } | null } };

export const RequirementSendViaEmailModal_RequirementFragmentDoc = gql`
    fragment RequirementSendViaEmailModal_Requirement on Requirement {
  id
}
    `;
export const RequirementSendViaEmailModal_ProjectFragmentDoc = gql`
    fragment RequirementSendViaEmailModal_Project on Project {
  id
}
    `;
export const SendRequirementViaEmailDocument = gql`
    query SendRequirementViaEmail($projectId: ID!, $requirementId: ID!) {
  requirement(projectId: $projectId, docId: $requirementId) {
    ... on RequirementResult {
      requirement {
        id
        items {
          id
          originVersionNumber
          isVisibleToOtherSide
          isRootItem
          attachments {
            ...ItemAttachment
          }
        }
      }
    }
  }
}
    ${ItemAttachmentFragmentDoc}`;

/**
 * __useSendRequirementViaEmailQuery__
 *
 * To run a query within a React component, call `useSendRequirementViaEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendRequirementViaEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendRequirementViaEmailQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useSendRequirementViaEmailQuery(baseOptions: Apollo.QueryHookOptions<SendRequirementViaEmailQuery, SendRequirementViaEmailQueryVariables> & ({ variables: SendRequirementViaEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendRequirementViaEmailQuery, SendRequirementViaEmailQueryVariables>(SendRequirementViaEmailDocument, options);
      }
export function useSendRequirementViaEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendRequirementViaEmailQuery, SendRequirementViaEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendRequirementViaEmailQuery, SendRequirementViaEmailQueryVariables>(SendRequirementViaEmailDocument, options);
        }
export function useSendRequirementViaEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SendRequirementViaEmailQuery, SendRequirementViaEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SendRequirementViaEmailQuery, SendRequirementViaEmailQueryVariables>(SendRequirementViaEmailDocument, options);
        }
export type SendRequirementViaEmailQueryHookResult = ReturnType<typeof useSendRequirementViaEmailQuery>;
export type SendRequirementViaEmailLazyQueryHookResult = ReturnType<typeof useSendRequirementViaEmailLazyQuery>;
export type SendRequirementViaEmailSuspenseQueryHookResult = ReturnType<typeof useSendRequirementViaEmailSuspenseQuery>;
export type SendRequirementViaEmailQueryResult = Apollo.QueryResult<SendRequirementViaEmailQuery, SendRequirementViaEmailQueryVariables>;