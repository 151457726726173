import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { MediaCardItem, useFormatting, useScreenWidth } from "@msys/ui";
import { Box, Grid, TablePagination, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Page } from "../commons/layout/Page";
import { PageContainer } from "../commons/layout/PageContainer";
import { PageSectionHeader } from "../commons/layout/PageSectionHeader";
import { usePaginationParams } from "../commons/pagination/usePaginationParams";
import {
  useThreeDStart_OrganisationProfileQuery,
  useThreeDStart_TemplatesListQuery,
} from "./OrganisationThreeDList.generated";

export const OrganisationThreeDList = () => {
  const { slug } = useParams();
  if (!slug) throw new Error("Slug is missing");

  const { getFormattedPrice } = useFormatting();
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["Global"]);

  const client = useApolloClient();
  const organisationQuery = useThreeDStart_OrganisationProfileQuery({
    client,
    variables: { slug },
  });
  const organisationProfile = getDataOrNull(
    organisationQuery?.data?.organisationProfile
  )?.profile;
  const organisation = organisationProfile?.organisation;

  const { offset, limit, page, perPage, setPerPage, setPage } =
    usePaginationParams({
      pageUrlParam: "3dPage",
      perPageUrlParam: "3dPageSize",
    });
  const templatesQuery = useThreeDStart_TemplatesListQuery({
    client,
    variables: {
      slug,
      offset,
      limit,
    },
  });
  const templates =
    getDataOrNull(
      templatesQuery?.data?.organisationProfile
    )?.profile?.shoppableTemplates.edges.map(edge => edge.node) ?? [];

  if (organisationProfile && !organisationProfile.has3DShop)
    return <Navigate to={`/org/${slug}`} replace />;

  const totalCount =
    getDataOrNull(templatesQuery.data?.organisationProfile)?.profile
      ?.shoppableTemplates.totalCount ?? 0;

  return (
    <Page
      isTopBarVisible
      subtitle={organisation?.title}
      title={"3D templates"} // FIXME: add translation, also 6 lines below
    >
      <PageContainer>
        {isMinTablet && <PageSectionHeader title={"3D templates"} mb={1} />}
        <Box mb={2}>
          <Typography variant="body1">
            Select a template to start a planning process
          </Typography>
        </Box>
        <Grid container spacing={isMinTablet ? 2 : 1}>
          {templates.map(template => (
            <Grid key={template.id} item xs={6} md={4} lg={3} xl={3}>
              <MediaCardItem
                key={template.id}
                // @ts-ignore
                component={Link}
                to={`/org/${slug}/3d/${template.id}`}
                title={template.title}
                description={template.description}
                price={getFormattedPrice(
                  template.proposedCalculation?.priceTotal || 0
                )}
                imageUrl={template.shopCatalogProductImage?.url}
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={1}>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={(event, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={perPage}
            labelRowsPerPage={t("Items per page:", {
              ns: "Global",
            })}
            onRowsPerPageChange={event => {
              setPerPage(parseInt(event.target.value, 10));
            }}
          />
        </Box>
      </PageContainer>
    </Page>
  );
};
