import { gql, useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { assign, omit } from "lodash";
import {
  DefineItemProductSearchFilterDefinitionsInput,
  ItemProductSearchFilterBrandFilter,
  ItemProductSearchFilterBrandLineFilter,
  ItemProductSearchFilterIdFilter,
  ItemProductSearchFilterPriceFilter,
  ItemProductSearchFilterPropertyFilter,
} from "../../../clients/graphqlTypes";
import { useProductSearchFiltersSection_DefineProductSearchFilterDefinitionsMutation } from "./useProductSearchFilterDefinitionMutations.generated";

export function useDefineItemProductSearchFilteDefinitionsMutation(
  refetchQueries?: string[]
) {
  const client = useApolloClient();
  const [defineItemProductSearchFilterDefinitions, query] =
    useProductSearchFiltersSection_DefineProductSearchFilterDefinitionsMutation(
      {
        client,
        refetchQueries,
      }
    );

  return [defineItemProductSearchFilterDefinitions, query] as const;
}

type AllowedProductSearchFilterDefinitionKey = keyof Omit<
  DefineItemProductSearchFilterDefinitionsInput,
  "projectId" | "docId" | "itemId"
>;
type ProductSearchFilterDefinitionValue =
  DefineItemProductSearchFilterDefinitionsInput[AllowedProductSearchFilterDefinitionKey];

export function useProductSearchFilterDefinitionMutations({
  projectId,
  docId,
  itemId,
  productTypeSearchFilter,
  supplierSearchFilterDefinition,
  brandSearchFilterDefinition,
  brandLineSearchFilterDefinition,
  listPriceSearchFilterDefinition,
  propertySearchFiltersDefinitions,
  refetchQueries,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  productTypeSearchFilter: { id: string } | undefined | null;
  supplierSearchFilterDefinition:
    | ItemProductSearchFilterIdFilter
    | undefined
    | null;
  listPriceSearchFilterDefinition:
    | ItemProductSearchFilterPriceFilter
    | undefined
    | null;
  brandSearchFilterDefinition:
    | ItemProductSearchFilterBrandFilter
    | undefined
    | null;
  brandLineSearchFilterDefinition:
    | ItemProductSearchFilterBrandLineFilter
    | undefined
    | null;
  propertySearchFiltersDefinitions: ItemProductSearchFilterPropertyFilter[];
  refetchQueries?: string[];
}) {
  const [defineItemProductSearchFilteDefinitions] =
    useDefineItemProductSearchFilteDefinitionsMutation(refetchQueries);

  const handleSetProductSearchFilterDefinition = async (
    key: AllowedProductSearchFilterDefinitionKey,
    value: ProductSearchFilterDefinitionValue
  ) => {
    const existingDefinitions: Pick<
      DefineItemProductSearchFilterDefinitionsInput,
      AllowedProductSearchFilterDefinitionKey
    > = {
      itemProductSearchFilterProductTypeIdFilterDefinition:
        productTypeSearchFilter
          ? {
              idFilter: {
                operatorId: "eq",
                valueId: productTypeSearchFilter.id,
              },
            }
          : undefined,

      itemProductSearchFilterSupplierIdFilterDefinition:
        supplierSearchFilterDefinition
          ? supplierSearchFilterDefinition.__typename ===
            "EntitySearchIdInFilter"
            ? {
                idInFilter: omit(supplierSearchFilterDefinition, "__typename"),
              }
            : supplierSearchFilterDefinition.__typename ===
                "EntitySearchIdFilter"
              ? {
                  idFilter: omit(supplierSearchFilterDefinition, "__typename"),
                }
              : assertNever(supplierSearchFilterDefinition)
          : undefined,
      itemProductSearchFilterBrandFilterDefinition: brandSearchFilterDefinition
        ? brandSearchFilterDefinition.__typename === "EntitySearchTextInFilter"
          ? {
              textInFilter: omit(brandSearchFilterDefinition, "__typename"),
            }
          : brandSearchFilterDefinition.__typename === "EntitySearchTextFilter"
            ? { textFilter: omit(brandSearchFilterDefinition, "__typename") }
            : assertNever(brandSearchFilterDefinition)
        : undefined,
      itemProductSearchFilterBrandLineFilterDefinition:
        brandLineSearchFilterDefinition
          ? brandLineSearchFilterDefinition.__typename ===
            "EntitySearchTextInFilter"
            ? {
                textInFilter: omit(
                  brandLineSearchFilterDefinition,
                  "__typename"
                ),
              }
            : brandLineSearchFilterDefinition.__typename ===
                "EntitySearchTextFilter"
              ? {
                  textFilter: omit(
                    brandLineSearchFilterDefinition,
                    "__typename"
                  ),
                }
              : assertNever(brandLineSearchFilterDefinition)
          : undefined,
      itemProductSearchFilterListPriceFilterDefinition:
        listPriceSearchFilterDefinition
          ? listPriceSearchFilterDefinition.__typename ===
            "EntitySearchNumberBetweenFilter"
            ? {
                numberBetweenFilter: omit(
                  listPriceSearchFilterDefinition,
                  "__typename"
                ),
              }
            : listPriceSearchFilterDefinition.__typename ===
                "EntitySearchNumberFilter"
              ? {
                  numberFilter: omit(
                    listPriceSearchFilterDefinition,
                    "__typename"
                  ),
                }
              : assertNever(listPriceSearchFilterDefinition)
          : undefined,
      itemProductSearchFilterPropertyFilterDefinitions:
        propertySearchFiltersDefinitions?.map(
          ProductSearchFilterPropertyFilterDefinitionToInputValue
        ),
    };

    await defineItemProductSearchFilteDefinitions({
      variables: {
        input: {
          projectId,
          docId,
          itemId,
          ...assign(existingDefinitions, { [key]: value }),
        },
      },
    });
  };
  const handleDeleteProductSearchFilterDefinition = async (
    key: AllowedProductSearchFilterDefinitionKey
  ) => {
    return handleSetProductSearchFilterDefinition(key, null);
  };

  return {
    handleSetProductSearchFilterDefinition,
    handleDeleteProductSearchFilterDefinition,
    defineItemProductSearchFilteDefinitions,
  };
}

export function ProductSearchFilterPropertyFilterDefinitionToInputValue(
  filter: ItemProductSearchFilterPropertyFilter
) {
  switch (filter.__typename) {
    case "EntitySearchPropertyFilterBoolInFilter": {
      return {
        boolInFilter: omit(filter, "__typename"),
      };
    }
    case "EntitySearchPropertyFilterBoolFilter": {
      return {
        boolFilter: omit(filter, "__typename"),
      };
    }
    case "EntitySearchPropertyFilterNumberInFilter": {
      return {
        numberInFilter: omit(filter, "__typename"),
      };
    }
    case "EntitySearchPropertyFilterNumberBetweenFilter": {
      return {
        numberBetweenFilter: omit(filter, "__typename"),
      };
    }
    case "EntitySearchPropertyFilterNumberFilter": {
      return {
        numberFilter: omit(filter, "__typename"),
      };
    }
    case "EntitySearchPropertyFilterTextInFilter": {
      return {
        textInFilter: omit(filter, "__typename"),
      };
    }
    case "EntitySearchPropertyFilterTextFilter": {
      return {
        textFilter: omit(filter, "__typename"),
      };
    }
  }
}
