/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocShareBox_ShareDocMutationVariables = Types.Exact<{
  input: Types.ShareDocInput;
}>;


export type DocShareBox_ShareDocMutation = { shareDoc: { __typename: 'ShareDocResult', ok?: boolean | null } };

export type DocShareBox_CancelPendingShareDocMutationVariables = Types.Exact<{
  input: Types.CancelPendingShareDocInput;
}>;


export type DocShareBox_CancelPendingShareDocMutation = { cancelPendingShareDoc: { __typename: 'CancelPendingShareDocResult', ok?: boolean | null } };

export type DocShareBox_UnshareDocMutationVariables = Types.Exact<{
  input: Types.UnshareDocInput;
}>;


export type DocShareBox_UnshareDocMutation = { unshareDoc: { __typename: 'UnshareDocResult', ok?: boolean | null } };

export type DocShareBox_Quote_SharingQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  docId: Types.Scalars['ID']['input'];
}>;


export type DocShareBox_Quote_SharingQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, docShares: Array<{ __typename: 'DocShare', id: string, sharedWithSystemOrganisationId: string, sharedWithProject__DEBUGONLY__Title: string, sharedWithSystemOrganisationTitle: string, sharedWithPermissions: Array<Types.PermissionName>, docOwningSystemOrganisationId: string, docOwningSystemOrganisationTitle: string }>, pendingDocShares: Array<{ __typename: 'PendingDocShare', id: string, sharingSystemOrganisationId: string, sharingSystemOrganisationTitle: string, recipientSystemOrganisationId: string, recipientSystemOrganisationTitle: string, recipientPermissions: Array<Types.PermissionName>, docOwningSystemOrganisationId: string, docOwningSystemOrganisationTitle: string }> } | null } };

export type DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery = { crmCompanies: { __typename: 'CrmCompanyConnection', edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, title: string, linkedSystemOrganisationId?: string | null } }> } | { __typename: 'MissingPermissions' } };

export type DocShareBox_Requirement_SharingQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  docId: Types.Scalars['ID']['input'];
}>;


export type DocShareBox_Requirement_SharingQuery = { requirement: { __typename: 'MissingPermissions' } | { __typename: 'RequirementResult', requirement?: { __typename: 'Requirement', id: string, docShares: Array<{ __typename: 'DocShare', id: string, sharedWithSystemOrganisationId: string, sharedWithProject__DEBUGONLY__Title: string, sharedWithSystemOrganisationTitle: string, sharedWithPermissions: Array<Types.PermissionName>, docOwningSystemOrganisationId: string, docOwningSystemOrganisationTitle: string }>, pendingDocShares: Array<{ __typename: 'PendingDocShare', id: string, sharingSystemOrganisationId: string, sharingSystemOrganisationTitle: string, recipientSystemOrganisationId: string, recipientSystemOrganisationTitle: string, recipientPermissions: Array<Types.PermissionName>, docOwningSystemOrganisationId: string, docOwningSystemOrganisationTitle: string }> } | null } };


export const DocShareBox_ShareDocDocument = gql`
    mutation DocShareBox_shareDoc($input: ShareDocInput!) {
  shareDoc(input: $input) {
    ok
  }
}
    `;
export type DocShareBox_ShareDocMutationFn = Apollo.MutationFunction<DocShareBox_ShareDocMutation, DocShareBox_ShareDocMutationVariables>;

/**
 * __useDocShareBox_ShareDocMutation__
 *
 * To run a mutation, you first call `useDocShareBox_ShareDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocShareBox_ShareDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [docShareBoxShareDocMutation, { data, loading, error }] = useDocShareBox_ShareDocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocShareBox_ShareDocMutation(baseOptions?: Apollo.MutationHookOptions<DocShareBox_ShareDocMutation, DocShareBox_ShareDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocShareBox_ShareDocMutation, DocShareBox_ShareDocMutationVariables>(DocShareBox_ShareDocDocument, options);
      }
export type DocShareBox_ShareDocMutationHookResult = ReturnType<typeof useDocShareBox_ShareDocMutation>;
export type DocShareBox_ShareDocMutationResult = Apollo.MutationResult<DocShareBox_ShareDocMutation>;
export type DocShareBox_ShareDocMutationOptions = Apollo.BaseMutationOptions<DocShareBox_ShareDocMutation, DocShareBox_ShareDocMutationVariables>;
export const DocShareBox_CancelPendingShareDocDocument = gql`
    mutation DocShareBox_cancelPendingShareDoc($input: CancelPendingShareDocInput!) {
  cancelPendingShareDoc(input: $input) {
    ok
  }
}
    `;
export type DocShareBox_CancelPendingShareDocMutationFn = Apollo.MutationFunction<DocShareBox_CancelPendingShareDocMutation, DocShareBox_CancelPendingShareDocMutationVariables>;

/**
 * __useDocShareBox_CancelPendingShareDocMutation__
 *
 * To run a mutation, you first call `useDocShareBox_CancelPendingShareDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocShareBox_CancelPendingShareDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [docShareBoxCancelPendingShareDocMutation, { data, loading, error }] = useDocShareBox_CancelPendingShareDocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocShareBox_CancelPendingShareDocMutation(baseOptions?: Apollo.MutationHookOptions<DocShareBox_CancelPendingShareDocMutation, DocShareBox_CancelPendingShareDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocShareBox_CancelPendingShareDocMutation, DocShareBox_CancelPendingShareDocMutationVariables>(DocShareBox_CancelPendingShareDocDocument, options);
      }
export type DocShareBox_CancelPendingShareDocMutationHookResult = ReturnType<typeof useDocShareBox_CancelPendingShareDocMutation>;
export type DocShareBox_CancelPendingShareDocMutationResult = Apollo.MutationResult<DocShareBox_CancelPendingShareDocMutation>;
export type DocShareBox_CancelPendingShareDocMutationOptions = Apollo.BaseMutationOptions<DocShareBox_CancelPendingShareDocMutation, DocShareBox_CancelPendingShareDocMutationVariables>;
export const DocShareBox_UnshareDocDocument = gql`
    mutation DocShareBox_unshareDoc($input: UnshareDocInput!) {
  unshareDoc(input: $input) {
    ok
  }
}
    `;
export type DocShareBox_UnshareDocMutationFn = Apollo.MutationFunction<DocShareBox_UnshareDocMutation, DocShareBox_UnshareDocMutationVariables>;

/**
 * __useDocShareBox_UnshareDocMutation__
 *
 * To run a mutation, you first call `useDocShareBox_UnshareDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocShareBox_UnshareDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [docShareBoxUnshareDocMutation, { data, loading, error }] = useDocShareBox_UnshareDocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocShareBox_UnshareDocMutation(baseOptions?: Apollo.MutationHookOptions<DocShareBox_UnshareDocMutation, DocShareBox_UnshareDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocShareBox_UnshareDocMutation, DocShareBox_UnshareDocMutationVariables>(DocShareBox_UnshareDocDocument, options);
      }
export type DocShareBox_UnshareDocMutationHookResult = ReturnType<typeof useDocShareBox_UnshareDocMutation>;
export type DocShareBox_UnshareDocMutationResult = Apollo.MutationResult<DocShareBox_UnshareDocMutation>;
export type DocShareBox_UnshareDocMutationOptions = Apollo.BaseMutationOptions<DocShareBox_UnshareDocMutation, DocShareBox_UnshareDocMutationVariables>;
export const DocShareBox_Quote_SharingDocument = gql`
    query DocShareBox_quote_sharing($projectId: ID!, $docId: ID!) {
  quote(docId: $docId, projectId: $projectId) {
    ... on QuoteResult {
      quote {
        id
        docShares {
          id
          sharedWithSystemOrganisationId
          sharedWithProject__DEBUGONLY__Title
          sharedWithSystemOrganisationTitle
          sharedWithPermissions
          docOwningSystemOrganisationId
          docOwningSystemOrganisationTitle
        }
        pendingDocShares {
          id
          sharingSystemOrganisationId
          sharingSystemOrganisationTitle
          recipientSystemOrganisationId
          recipientSystemOrganisationTitle
          recipientPermissions
          docOwningSystemOrganisationId
          docOwningSystemOrganisationTitle
        }
      }
    }
  }
}
    `;

/**
 * __useDocShareBox_Quote_SharingQuery__
 *
 * To run a query within a React component, call `useDocShareBox_Quote_SharingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocShareBox_Quote_SharingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocShareBox_Quote_SharingQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useDocShareBox_Quote_SharingQuery(baseOptions: Apollo.QueryHookOptions<DocShareBox_Quote_SharingQuery, DocShareBox_Quote_SharingQueryVariables> & ({ variables: DocShareBox_Quote_SharingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocShareBox_Quote_SharingQuery, DocShareBox_Quote_SharingQueryVariables>(DocShareBox_Quote_SharingDocument, options);
      }
export function useDocShareBox_Quote_SharingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocShareBox_Quote_SharingQuery, DocShareBox_Quote_SharingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocShareBox_Quote_SharingQuery, DocShareBox_Quote_SharingQueryVariables>(DocShareBox_Quote_SharingDocument, options);
        }
export function useDocShareBox_Quote_SharingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocShareBox_Quote_SharingQuery, DocShareBox_Quote_SharingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocShareBox_Quote_SharingQuery, DocShareBox_Quote_SharingQueryVariables>(DocShareBox_Quote_SharingDocument, options);
        }
export type DocShareBox_Quote_SharingQueryHookResult = ReturnType<typeof useDocShareBox_Quote_SharingQuery>;
export type DocShareBox_Quote_SharingLazyQueryHookResult = ReturnType<typeof useDocShareBox_Quote_SharingLazyQuery>;
export type DocShareBox_Quote_SharingSuspenseQueryHookResult = ReturnType<typeof useDocShareBox_Quote_SharingSuspenseQuery>;
export type DocShareBox_Quote_SharingQueryResult = Apollo.QueryResult<DocShareBox_Quote_SharingQuery, DocShareBox_Quote_SharingQueryVariables>;
export const DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdDocument = gql`
    query DocShareBox_crmOrganisationWithLinkedSystemOrganisationId {
  crmCompanies(
    filters: {linkedSystemOrganisationId: true}
    limit: 1000
    sorting: [{column: organisationName, direction: asc}]
  ) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          title
          linkedSystemOrganisationId
        }
      }
    }
  }
}
    `;

/**
 * __useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery__
 *
 * To run a query within a React component, call `useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery(baseOptions?: Apollo.QueryHookOptions<DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery, DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery, DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables>(DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdDocument, options);
      }
export function useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery, DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery, DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables>(DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdDocument, options);
        }
export function useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery, DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery, DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables>(DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdDocument, options);
        }
export type DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryHookResult = ReturnType<typeof useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery>;
export type DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdLazyQueryHookResult = ReturnType<typeof useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdLazyQuery>;
export type DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdSuspenseQueryHookResult = ReturnType<typeof useDocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdSuspenseQuery>;
export type DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryResult = Apollo.QueryResult<DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQuery, DocShareBox_CrmOrganisationWithLinkedSystemOrganisationIdQueryVariables>;
export const DocShareBox_Requirement_SharingDocument = gql`
    query DocShareBox_requirement_sharing($projectId: ID!, $docId: ID!) {
  requirement(docId: $docId, projectId: $projectId) {
    ... on RequirementResult {
      requirement {
        id
        docShares {
          id
          sharedWithSystemOrganisationId
          sharedWithProject__DEBUGONLY__Title
          sharedWithSystemOrganisationTitle
          sharedWithPermissions
          docOwningSystemOrganisationId
          docOwningSystemOrganisationTitle
        }
        pendingDocShares {
          id
          sharingSystemOrganisationId
          sharingSystemOrganisationTitle
          recipientSystemOrganisationId
          recipientSystemOrganisationTitle
          recipientPermissions
          docOwningSystemOrganisationId
          docOwningSystemOrganisationTitle
        }
      }
    }
  }
}
    `;

/**
 * __useDocShareBox_Requirement_SharingQuery__
 *
 * To run a query within a React component, call `useDocShareBox_Requirement_SharingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocShareBox_Requirement_SharingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocShareBox_Requirement_SharingQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useDocShareBox_Requirement_SharingQuery(baseOptions: Apollo.QueryHookOptions<DocShareBox_Requirement_SharingQuery, DocShareBox_Requirement_SharingQueryVariables> & ({ variables: DocShareBox_Requirement_SharingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocShareBox_Requirement_SharingQuery, DocShareBox_Requirement_SharingQueryVariables>(DocShareBox_Requirement_SharingDocument, options);
      }
export function useDocShareBox_Requirement_SharingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocShareBox_Requirement_SharingQuery, DocShareBox_Requirement_SharingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocShareBox_Requirement_SharingQuery, DocShareBox_Requirement_SharingQueryVariables>(DocShareBox_Requirement_SharingDocument, options);
        }
export function useDocShareBox_Requirement_SharingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocShareBox_Requirement_SharingQuery, DocShareBox_Requirement_SharingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocShareBox_Requirement_SharingQuery, DocShareBox_Requirement_SharingQueryVariables>(DocShareBox_Requirement_SharingDocument, options);
        }
export type DocShareBox_Requirement_SharingQueryHookResult = ReturnType<typeof useDocShareBox_Requirement_SharingQuery>;
export type DocShareBox_Requirement_SharingLazyQueryHookResult = ReturnType<typeof useDocShareBox_Requirement_SharingLazyQuery>;
export type DocShareBox_Requirement_SharingSuspenseQueryHookResult = ReturnType<typeof useDocShareBox_Requirement_SharingSuspenseQuery>;
export type DocShareBox_Requirement_SharingQueryResult = Apollo.QueryResult<DocShareBox_Requirement_SharingQuery, DocShareBox_Requirement_SharingQueryVariables>;