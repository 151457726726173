import { useTranslate } from "@tolgee/react";
import { DocActorType } from "../../../clients/graphqlTypes";

export function useActorTypes() {
  const { t } = useTranslate(["Global"]);

  const actorTypeLabels: Record<DocActorType, string> = {
    CONTRACTOR: t("Contractor", { ns: "Global" }),
    CONTRACTEE: t("Contractee", { ns: "Global" }),
    CLIENT: t("End client", { ns: "Global" }),
  };

  return { actorTypeLabels };
}
