/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type InviteContractee_ProjectFragment = { __typename: 'Project', id: string, title: string, canInviteContractee: boolean, crmOrganisation?: { __typename: 'CrmCompany', id: string, members: Array<{ __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any }> } | null, contracteeInvitations: Array<{ __typename: 'ProjectContracteeInvitation', id: string, contact: { __typename: 'InvitationContact', id: string } }> };

export const InviteContractee_ProjectFragmentDoc = gql`
    fragment InviteContractee_Project on Project {
  id
  title
  crmOrganisation {
    id
    members {
      id
      title
      firstname
      familyname
      email
    }
  }
  canInviteContractee
  contracteeInvitations {
    id
    contact {
      id
    }
  }
}
    `;