import { getFormattedPercentage } from "@msys/ui";
import {
  getAvailableVatRateTypes,
  getAvailableVatRateValues,
  getVatParameters,
  VatRateType,
} from "@msys/vatutils";
import { useTolgee } from "@tolgee/react";
import { useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";
import { FormattedPercentageField } from "../../commons/form-fields/FormattedPercentageField";
import { SelectField } from "../../commons/form-fields/SelectField";
import { Stack } from "../../commons/layout/Stack";
import { useTranslate } from "@tolgee/react";
import { useVatCountryCodes } from "../hooks/useVatCountryCodes";
import { useVatRateTypes } from "../hooks/useVatRateTypes";

export interface VatFieldFormValues {
  vatRate: number;
  vatInfo: { countryCode: string; rateType: VatRateType };
}

export const useVatFieldValidationSchema = () => {
  const { t } = useTranslate(["Vat", "Global"]);
  return React.useMemo(
    () => ({
      vatRate: Yup.number()
        .label(
          t("VAT value", {
            ns: "Vat",
          })
        )
        .required(),
      vatInfo: Yup.object()
        .shape({
          countryCode: Yup.string()
            .label(
              t("VAT country", {
                ns: "Vat",
              })
            )
            .required(),
          rateType: Yup.string()
            .label(
              t("VAT rate", {
                ns: "Vat",
              })
            )
            .required(),
        })
        .required(),
    }),
    [t]
  );
};

export const getVatValues = (
  countryCode: string = "DE",
  rateType: VatRateType = "standard",
  rate?: number | null
): VatFieldFormValues => {
  const values = getVatParameters(countryCode, rateType, rate);
  return {
    vatRate: values.rate,
    vatInfo: { countryCode: values.countryCode, rateType: values.rateType },
  };
};

export const VatField = ({
  disabled,
  showCountryCodeSelect = true,
  orientation = "horizontal",
}: {
  disabled?: boolean;
  showCountryCodeSelect?: boolean;
  orientation?: "horizontal" | "vertical";
}) => {
  const { t } = useTranslate(["Vat", "Global"]);
  const locale = useTolgee(["language"]).getLanguage()!;
  const { vatCountryCodeOptions } = useVatCountryCodes();
  const { vatRateTypeOptions, vatRateTypeLabels } = useVatRateTypes();

  const { values, setValues } = useFormikContext<VatFieldFormValues>();

  const availableRateTypes = getAvailableVatRateTypes(
    values.vatInfo.countryCode
  );

  const rateTypeValues = getAvailableVatRateValues(values.vatInfo.countryCode);

  return (
    <Stack
      flexDirection={orientation === "horizontal" ? "row" : "column"}
      alignItems={orientation === "horizontal" ? "center" : "stretch"}
      spacing={1}
    >
      {showCountryCodeSelect && (
        <SelectField
          name="vatInfo.countryCode"
          required
          disabled={disabled}
          options={vatCountryCodeOptions}
          label={t("VAT country", {
            ns: "Vat",
          })}
          onChange={e => {
            const vatCountryCode = e.target.value;
            setValues({
              ...values,
              ...getVatValues(vatCountryCode, "standard"),
            });
          }}
        />
      )}
      <SelectField
        name="vatInfo.rateType"
        required
        disabled={disabled}
        options={[
          ...vatRateTypeOptions
            .filter(o => availableRateTypes.includes(o.value))
            .map(o => ({
              value: o.value,
              label: `${o.label}${
                o.value !== "custom"
                  ? ` – ${getFormattedPercentage(
                      rateTypeValues[o.value] ?? 0,
                      locale
                    )}`
                  : ""
              }`,
            })),
          {
            value: "custom" as VatRateType,
            label: t("custom", {
              ns: "Vat",
            }),
          },
        ]}
        label={t("VAT rate", {
          ns: "Vat",
        })}
        onChange={e => {
          const vatRateType = e.target.value as VatRateType;
          setValues({
            ...values,
            ...getVatValues(values.vatInfo.countryCode, vatRateType),
          });
        }}
      />
      {values.vatInfo.rateType === "custom" && (
        <FormattedPercentageField
          name="vatRate"
          label={t("VAT rate", {
            ns: "Vat",
          })}
          disabled={disabled}
        />
      )}
    </Stack>
  );
};
