import { useApolloClient } from "@apollo/client";
import { CollapseSection, Modal } from "@msys/ui";
import { DialogContentText } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import * as Yup from "yup";
import { Salutation } from "../../../clients/graphqlTypes";
import { useUserData } from "../../auth/useUserData";
import { SelectField } from "../../commons/form-fields/SelectField";
import { SelectMultipleField } from "../../commons/form-fields/SelectMultipleField";
import { Stack } from "../../commons/layout/Stack";
import { UserTitleWithNameField } from "../users/UserTitleWithNameField";
import { useOrganisationRoles, useProjectRoles } from "../users/useRoles";
import { useInviteOrganisationMemberMutation } from "./InviteOrganisationMemberModal.generated";

interface FormValues {
  title: Salutation;
  firstName: string;
  familyName: string;
  email: string;
  roleIds: string[];
  defaultProjectRoleId?: string;
  message: string;
  jobTitle: string;
}

interface Props {
  title?: string;
  handleClose: () => void;
  handleComplete?: () => Promise<void>;
  refetchQueries: string[];
}

export const InviteOrganisationMemberModal = ({
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["OrganisationInvite", "UserProfile", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [invite, { loading: isSendingInvite }] =
    useInviteOrganisationMemberMutation({
      client,
      refetchQueries,
      awaitRefetchQueries: true,
    });

  const isLoading = isSendingInvite;

  const onSubmit = useCallback(
    async (values: FormValues) => {
      await invite({
        variables: {
          input: {
            defaultProjectRoleIds: values.defaultProjectRoleId
              ? [values.defaultProjectRoleId]
              : [],
            inviteeEmail: values.email,
            inviteeFamilyname: values.familyName,
            inviteeFirstname: values.firstName,
            inviteeJobtitle: values.jobTitle,
            inviteeSalutation: values.title,
            organisationRoleIds: values.roleIds,
            messageBody: values.message,
            messageSubject: t("Join {organisationName} on MeisterSystems", {
              ns: "OrganisationInvite",
              organisationName: viewer.organisation.title,
            }),
            attachments: [],
          },
        },
      });

      handleClose();
      await handleComplete?.();
      enqueueSnackbar(
        t("Member invited", {
          ns: "OrganisationInvite",
        })
      );
    },
    [
      viewer.organisation.title,
      invite,
      handleClose,
      handleComplete,
      enqueueSnackbar,
      t,
    ]
  );

  const { getOrganisationRoleOptions } = useOrganisationRoles();
  const { getProjectRoleOptions } = useProjectRoles();

  const roles = getOrganisationRoleOptions(viewer.organisation.roles);
  const projectRoles = getProjectRoleOptions(viewer.organisation.projectRoles);

  const defaultRoleId = viewer.organisation.roles.find(r =>
    r.internalName.endsWith("_MEMBER")
  )?.id;
  const defaultProjectRoleId = viewer.organisation.projectRoles.find(r =>
    r.internalName.endsWith("_MEMBER")
  )?.id;

  const initialValues: FormValues = {
    title: "mr",
    jobTitle: "",
    firstName: "",
    familyName: "",
    email: "",
    roleIds: defaultRoleId ? [defaultRoleId] : [],
    defaultProjectRoleId,
    message: t(
      "Hello, Join our organisation {organisationName} on MeisterSystems. Kind regards, {senderName}",
      {
        ns: "OrganisationInvite",
        organisationName: viewer.organisation.title,
        senderName: (viewer.firstname + " " + viewer.familyname).trim(),
      }
    ),
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(
            t("Salutation", {
              ns: "Global",
            })
          )
          .required(),
        firstName: Yup.string().label(
          t("First name", {
            ns: "UserProfile",
          })
        ),
        familyName: Yup.string()
          .label(
            t("Last name", {
              ns: "UserProfile",
            })
          )
          .required(),
        email: Yup.string()
          .label(
            t("Email", {
              ns: "OrganisationInvite",
            })
          )
          .trim()
          .email()
          .required(),
        jobTitle: Yup.string().label(
          t("Job title", {
            ns: "UserProfile",
          })
        ),
        roleIds: Yup.array()
          .label(
            t("Organisation role", {
              ns: "UserProfile",
            })
          )
          .of(Yup.string())
          .min(1)
          .required(
            t("At least one organisation role is required", {
              ns: "UserProfile",
            })
          ),
        defaultProjectRoleId: Yup.string()
          .label(
            t("Default project role", {
              ns: "UserProfile",
            })
          )
          .required(
            t("Default project role is required", {
              ns: "UserProfile",
            })
          ),
        message: Yup.string()
          .label(
            t("Personal message", {
              ns: "OrganisationInvite",
            })
          )
          .required(),
      }),
    [t]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Modal
          id="invite-ogranisation-member-modal"
          title={
            title ??
            t("Add team member", {
              ns: "OrganisationInvite",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Invite", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: !formikProps.dirty || !formikProps.isValid,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={isLoading}
        >
          <DialogContentText>
            {t(
              "Here you can invite others to your organization (e.g. craftsman you work alongside with on the project, or others managing your projects",
              {
                ns: "OrganisationInvite",
              }
            )}
          </DialogContentText>
          <Form id={formId}>
            <Stack flexDirection="column" width="100%">
              <UserTitleWithNameField />
              <Field
                label={t("Email", {
                  ns: "OrganisationInvite",
                })}
                name="email"
                type="email"
                component={TextField}
                required
              />
              <SelectMultipleField
                label={t("Organisation role", {
                  ns: "UserProfile",
                })}
                name="roleIds"
                options={roles}
                required
                validate={value => {
                  let message;
                  if (value.length === 0) {
                    message = t("At least one organisation role is required", {
                      ns: "UserProfile",
                    });
                  }
                  return message;
                }}
                disabled={false}
              />

              <CollapseSection
                title={t("More options", {
                  ns: "Global",
                })}
                isInitiallyExpanded={false}
              >
                <Stack flexDirection="column">
                  <Field
                    component={TextField}
                    name="jobTitle"
                    label={t("Job title", {
                      ns: "UserProfile",
                    })}
                    disabled={false}
                  />
                  <SelectField
                    label={t("Default project role", {
                      ns: "UserProfile",
                    })}
                    name="defaultProjectRoleId"
                    options={projectRoles}
                    disabled={false}
                  />
                  <Field
                    label={t("Personal message", {
                      ns: "OrganisationInvite",
                    })}
                    name="message"
                    required
                    component={TextField}
                    multiline
                    rows={8}
                  />
                </Stack>
              </CollapseSection>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
