import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Divider, IconButton } from "@mui/material";
import React from "react";

interface Props {
  open: boolean;
  onToggle(open: boolean): void;
}

export const DividerWithToggle = ({ open, onToggle }: Props) => {
  return (
    <Divider
      orientation="vertical"
      flexItem
      sx={{ "&::before": { height: "16px" }, "&::after": { height: "100%" } }}
    >
      <IconButton
        size="small"
        onClick={() => {
          onToggle(!open);
        }}
      >
        <KeyboardArrowLeft
          sx={{
            transition: "transform 0.2s ease-out",
            ...(!open ? { transform: `rotate(-180deg)` } : undefined),
          }}
        />
      </IconButton>
    </Divider>
  );
};
