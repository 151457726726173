import { useApolloClient } from "@apollo/client";
import {
  FormattedPrice,
  RichTextValue,
  isImageOr3dModel,
  processAttachment,
  useScreenWidth,
} from "@msys/ui";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { PropertiesValue } from "../../../commons/PropertiesValue";
import { GalleryModal } from "../../../commons/images/GalleryModal";
import { GalleryRow } from "../../../commons/images/GalleryRow";
import { GallerySlider } from "../../../commons/images/GallerySlider";
import { Stack } from "../../../commons/layout/Stack";
import { useItemProps2 } from "../../item-properties/itemProperties";
import { useTemplatesQuoteHeaderBoxQuery } from "./ShopHeaderBox.generated";

interface Props {
  initialPictureIndex?: number;
  docId: string;
}

export const ShopHeaderBox = ({ docId, initialPictureIndex }: Props) => {
  const { t } = useTranslate("Shop");
  const theme = useTheme();

  const [currentImageIndex, setCurrentImageIndex] = React.useState(
    initialPictureIndex ?? 0
  );
  const { isMinTablet, isMinDesktop } = useScreenWidth();
  const client = useApolloClient();

  const [modalImageIndex, setModalImageIndex] = React.useState<number | null>(
    null
  );

  const query = useTemplatesQuoteHeaderBoxQuery({
    client,
    variables: { docId },
  });

  const doc = query.data?.viewer?.organisation.shoppableTemplate;

  const properties = useItemProps2(doc?.rootItem?.props2 ?? []).concat(
    useItemProps2(doc?.rootItem?.product?.props2 ?? [])
  );

  if (!doc) return null;

  const images = doc.shopCatalogProductImages
    .map(processAttachment)
    .filter(isImageOr3dModel);

  return (
    <Paper>
      {images.length > 0 && (
        <>
          <GallerySlider
            images={images}
            slideIndex={currentImageIndex}
            onSlideIndexChange={setCurrentImageIndex}
            handleClick={index => setModalImageIndex(index)}
            height={
              isMinDesktop
                ? theme.layout.galleryHeight.lg
                : isMinTablet
                  ? theme.layout.galleryHeight.md
                  : theme.layout.galleryHeight.sm
            }
            threeDInteractive={false}
          />
          {images.length > 1 && (
            <GalleryRow
              images={images}
              selectedIndex={currentImageIndex}
              handleClick={index => setModalImageIndex(index)}
              maxItems={isMinTablet ? 8 : 6}
              style={{ marginTop: "4px" }}
            />
          )}
          {modalImageIndex !== null && (
            <GalleryModal
              images={images}
              initialIndex={modalImageIndex}
              handleClose={() => setModalImageIndex(null)}
            />
          )}
        </>
      )}
      <Stack p={isMinTablet ? 2 : 1} spacing={2} flexDirection="column">
        {doc.description ? (
          <RichTextValue htmlContent={doc.description} />
        ) : null}
        {properties.length > 0 && (
          <PropertiesValue
            properties={properties}
            columns={isMinDesktop ? 3 : isMinTablet ? 2 : 1}
          />
        )}
        <Box>
          <Typography variant="h1" component="div">
            {t("Price")}:{" "}
            <FormattedPrice value={doc.proposedCalculation?.priceTotal || 0} />
          </Typography>
          <Typography
            variant="caption"
            component="div"
            color={theme => theme.palette.grey[400]}
          >
            {t(
              "Includes VAT. Final price in the quote may be different depending on selected options."
            )}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};
