/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TemplateQuoteAddedToShopStatus_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, addedToShop: boolean };

export const TemplateQuoteAddedToShopStatus_QuoteTemplateFragmentDoc = gql`
    fragment TemplateQuoteAddedToShopStatus_QuoteTemplate on QuoteTemplate {
  id
  addedToShop
}
    `;