import { gql, useApolloClient } from "@apollo/client";
import { getFormattedPrice, Modal } from "@msys/ui";
import {
  Checkbox,
  DialogContentText,
  List,
  ListItemButton,
  ListItemText,
  ListProps,
  Stack,
  Typography,
} from "@mui/material";
import { useTolgee } from "@tolgee/react";
import { useFormikContext } from "formik";
import { includes, uniq, without } from "lodash";
import { ChangeEvent, useState } from "react";
import {
  AgreedOrProposedCalculation,
  ItemType,
  namedOperations,
} from "../../../../clients/graphqlTypes";
import { useInvoiceQuoteModifyItemMutation } from "../Invoices.generated";
import {
  InvoiceSelectAgreedOrProposedList_ItemFragment,
  InvoiceSelectButton__QuoteFragment,
} from "./InvoiceSelectButton.generated";
import { useTranslate } from "@tolgee/react";
import { getAllItemChildren, getAllParentItems } from "../../../trees/helpers";
import { assertNever } from "../../../utils";
import { InvoiceFormValues } from "../types";
import { ItemCalculationFragment } from "../../doc-items/Fragments.generated";

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  invoiceId: string;
  doc: InvoiceSelectButton__QuoteFragment;
  disabled?: boolean;
  invoiceableQuoteItemIds: string[];
  invoiceableInInvoiceQuoteItemIds: string[];
}

export const InvoiceSelectButton = ({
  projectId,
  docId,
  itemId,
  invoiceId,
  doc: quote,
  disabled,
  invoiceableQuoteItemIds,
  invoiceableInInvoiceQuoteItemIds,
}: Props) => {
  const { values, setFieldValue, submitForm } =
    useFormikContext<InvoiceFormValues>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslate("InvoiceDetailsForm");

  const allItems = quote.items;
  const item = allItems.find(e => e.id === itemId);

  if (!item) return <div>item not found</div>;

  if (
    !invoiceableQuoteItemIds.includes(item.id) &&
    !invoiceableInInvoiceQuoteItemIds.includes(item.id) &&
    !includes(values.selectedItemIds, item.id)
  )
    return null;

  const allParentIds = getAllParentItems(item, allItems).map(e => e.id);
  const allChildren = getAllItemChildren(item, allItems);
  const allInvoiceableChildren = allChildren.filter(
    e =>
      (invoiceableQuoteItemIds.includes(e.id) ||
        invoiceableInInvoiceQuoteItemIds.includes(e.id) ||
        includes(values.selectedItemIds, item.id)) &&
      (e.type === "paid" ||
        (e.type === "section" && e.childrenVisibility === "HIDE_CHILDREN"))
  );
  const allInvoiceableChildrenIds = allInvoiceableChildren.map(e => e.id);
  const checked =
    includes(values.selectedItemIds, item.id) ||
    (allInvoiceableChildren.length > 0 &&
      allInvoiceableChildren.every(item =>
        includes(values.selectedItemIds, item.id)
      ));
  const indeterminate =
    !checked &&
    allChildren.length > 0 &&
    allChildren.some(item => includes(values.selectedItemIds, item.id));

  const onSelect = (checked: boolean, checkAgreement: boolean) => {
    // check this to be aligned with dialog for proposed or agreed!
    if (checked && item.needsAgreement && checkAgreement) {
      setIsModalOpen(true);
      return;
    }

    if (checked) {
      if (allInvoiceableChildren.length === 0) {
        setFieldValue("selectedItemIds", [...values.selectedItemIds, item.id]);
      } else {
        setFieldValue(
          "selectedItemIds",
          uniq([...values.selectedItemIds, ...allInvoiceableChildrenIds])
        );
      }
    } else {
      setFieldValue(
        "selectedItemIds",
        without(
          values.selectedItemIds,
          item.id,
          ...allParentIds,
          ...allInvoiceableChildrenIds
        )
      );
    }
    submitForm();
  };

  return (
    <>
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
          onSelect(checked, true)
        }
        onClick={e => e.stopPropagation()}
        disabled={disabled}
      />

      {isModalOpen && (
        <Modal
          title={t("Calculation type")}
          dialogProps={{ maxWidth: "xs" }}
          handleClose={() => {
            setIsModalOpen(false);
          }}
        >
          <DialogContentText>
            {t(
              "Please select which calculation type should be applied for this invoice item:"
            )}
          </DialogContentText>
          <InvoiceSelectAgreedOrProposedList
            docId={docId}
            projectId={projectId}
            itemId={itemId}
            invoiceId={invoiceId}
            handleComplete={() => {
              setIsModalOpen(false);
              onSelect(true, false);
            }}
            item={item}
            refetchQueries={[
              namedOperations.Query
                .ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export const InvoiceSelectAgreedOrProposedList = ({
  docId,
  projectId,
  itemId,
  invoiceId,
  refetchQueries,
  handleComplete,
  item,
  listProps,
  selectedAgreedOrProposed,
}: {
  docId: string;
  projectId: string;
  itemId: string;
  invoiceId: string;
  refetchQueries?: string[];
  handleComplete?: () => void;
  item: InvoiceSelectAgreedOrProposedList_ItemFragment;
  listProps?: ListProps;
  selectedAgreedOrProposed?: AgreedOrProposedCalculation;
}) => {
  const { t } = useTranslate("InvoiceDetailsForm");

  const client = useApolloClient();

  const [modifyItem] = useInvoiceQuoteModifyItemMutation({
    client,
    refetchQueries: [
      namedOperations.Query.ProjectOutgoingInvoice_QuoteInvoiceCalculation, // FIXME: why is this needed?
    ],
  });

  const setItemAgreedOrProposed = async (
    status: AgreedOrProposedCalculation
  ) => {
    await modifyItem({
      variables: {
        input: {
          docId,
          projectId,
          itemId,
          invoiceId,
          values: {
            invoiceAgreedOrProposed: status,
          },
        },
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });
    handleComplete?.();
  };

  return (
    <List {...listProps}>
      <ListItemButton
        onClick={async e => {
          await setItemAgreedOrProposed("agreed");
        }}
        selected={selectedAgreedOrProposed === "agreed"}
      >
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <ListItemText
            primary={t("Agreed")}
            primaryTypographyProps={{ style: { fontWeight: "bold" } }}
          />
          <InvoiceItemCalculationLine
            itemType={item.type}
            calculation={item.agreedCalculation}
          />
        </Stack>
      </ListItemButton>
      <ListItemButton
        onClick={async e => {
          await setItemAgreedOrProposed("proposed");
        }}
        selected={selectedAgreedOrProposed === "proposed"}
      >
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <ListItemText
            primary={t("Proposed")}
            primaryTypographyProps={{ style: { fontWeight: "bold" } }}
          />
          <InvoiceItemCalculationLine
            itemType={item.type}
            calculation={item.proposedCalculation}
          />
        </Stack>
      </ListItemButton>
    </List>
  );
};

export const InvoiceItemCalculationLine = ({
  itemType,
  calculation,
}: {
  itemType: ItemType;
  calculation?: ItemCalculationFragment | null;
}) => {
  const language = useTolgee(["language"]).getLanguage()!;

  if (!calculation) return null;

  switch (itemType) {
    case "paid": {
      return (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Typography variant="caption" color="textPrimary" component="span">
            {calculation.quantity}x
          </Typography>
          {calculation.quantity !== 1 && (
            <Typography variant="caption" color="textPrimary" component="span">
              {getFormattedPrice(calculation.pricePerUnit, language)}
            </Typography>
          )}
          <Typography
            variant="caption"
            color="textPrimary"
            component="span"
            style={{ fontWeight: "bold" }}
          >
            {getFormattedPrice(calculation.priceSubTotal, language)}
          </Typography>
        </Stack>
      );
    }

    case "section": {
      if (calculation.pricePerUnit === 0 && calculation.quantity === 1) {
        return null;
      }
      return (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          {calculation.quantity !== 1 && (
            <>
              <Typography
                variant="caption"
                color="textPrimary"
                component="span"
              >
                {calculation.quantity}x
              </Typography>
              <Typography
                variant="caption"
                color="textPrimary"
                component="span"
              >
                {getFormattedPrice(calculation.pricePerUnit, language)}
              </Typography>
            </>
          )}
          <Typography
            variant="caption"
            color="textPrimary"
            component="span"
            style={{ fontWeight: "bold" }}
          >
            {getFormattedPrice(calculation.priceSubTotal, language)}
          </Typography>
        </Stack>
      );
    }
    case "unpaid":
    case "defect":
    case "decision": {
      return null;
    }
    default:
      throw assertNever(itemType);
  }
};
