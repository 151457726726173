import { useApolloClient } from "@apollo/client";
import { MenuButton, MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import DownloadIcon from "@mui/icons-material/GetApp";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { EntityNumber } from "../../commons/EntityNumber";
import { useOpenFile } from "../../commons/hooks/useOpenFile";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../commons/layout/PageSubHeader";
import { InvoiceDraftDeleteMenuItem } from "./InvoiceDraftDeleteMenuItem";
import { useModifyInvoiceMutation } from "./Invoices.generated";
import { InvoiceSettingsModal } from "./InvoiceSettingsModal";
import { InvoiceStatusChip } from "./InvoiceStatusBadge";
import {
  InvoiceSubHeader_InvoiceFragment,
  InvoiceSubHeader_ProjectFragment,
} from "./InvoiceSubHeader.generated";

interface Props {
  invoice: InvoiceSubHeader_InvoiceFragment;
  project: InvoiceSubHeader_ProjectFragment;
}

export const InvoiceSubHeader = ({
  invoice,
  project,
  isHeaderVisible,
  setHeaderVisible,
}: Props & PageSubHeaderInjectedProps) => {
  const { t } = useTranslate(["Invoices", "Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const { openPdf } = useOpenFile();
  const navigate = useNavigate();

  const client = useApolloClient();
  const [modifyInvoice] = useModifyInvoiceMutation({ client });
  const handleTitleChange = async (title: string) => {
    if (!project || !invoice) return;
    await modifyInvoice({
      variables: {
        input: {
          projectId: project?.id,
          docId: invoice?.quote?.id,
          invoiceId: invoice?.id,
          values: { title },
        },
      },
    });
  };

  return (
    <PageSubHeader
      title={invoice.title}
      hideTitleOnMobile
      handleTitleChange={
        invoice.status === "draft"
          ? async newValue => {
              await handleTitleChange(newValue);
              enqueueSnackbar(t("Title updated", { ns: "Global" }));
            }
          : undefined
      }
      titleEditTooltip={
        invoice.status === "draft" ? t("Rename", { ns: "Global" }) : undefined
      }
      status={<InvoiceStatusChip status={invoice.status} />}
      actionButtons={
        invoice.status === "draft" ? (
          <MenuButton>
            <RestrictedByProjectPermissionWithDebug
              permission="MANAGE_INVOICES_SETTINGS"
              project={project}
            >
              <ModalOpenButton
                Modal={InvoiceSettingsModal}
                modalProps={{
                  projectId: project.id,
                  invoiceId: invoice.id,
                  docId: invoice.quote.id,
                }}
              >
                <MenuItemWithIcon icon={<SettingsIcon />}>
                  {t("Invoice settings", { ns: "Invoices" })}
                </MenuItemWithIcon>
              </ModalOpenButton>
            </RestrictedByProjectPermissionWithDebug>
            <RestrictedByProjectPermissionWithDebug
              permission="MANAGE_INVOICES"
              project={project}
            >
              <InvoiceDraftDeleteMenuItem
                projectId={project.id}
                docId={invoice.quote.id}
                invoiceId={invoice.id}
                handleComplete={() => {
                  navigate(`/projects/${project.id}/invoices/outgoing`);
                }}
              />
            </RestrictedByProjectPermissionWithDebug>
          </MenuButton>
        ) : (
          <RestrictedByProjectPermissionWithDebug
            permission="READ_INVOICES"
            project={project}
          >
            <Tooltip title={t("Download PDF", { ns: "Invoices" })}>
              <IconButton
                size="small"
                onClick={async () => {
                  if (invoice.file?.url)
                    await openPdf(invoice.file.url, `Invoice_${invoice.id}`);
                }}
                color="primary"
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </RestrictedByProjectPermissionWithDebug>
        )
      }
      entityNumber={
        invoice.status !== "draft" && (
          <Typography variant="body2">
            <EntityNumber number={invoice.number ?? undefined} />
          </Typography>
        )
      }
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
    />
  );
};
