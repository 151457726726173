import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";

export function FavouritesIconButton({
  isActive,
  toggleActive,
}: {
  isActive: boolean;
  toggleActive: (active: boolean) => void;
}) {
  const { t } = useTranslate(["ProductSearch"]);
  return (
    <Tooltip placement="top" title={t("Favourites", { ns: "ProductSearch" })}>
      <Box display="flex">
        <IconButton
          size="small"
          color="primary"
          onClick={() => toggleActive(!isActive)}
        >
          {isActive ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
      </Box>
    </Tooltip>
  );
}
