import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  ContentBlock,
  ContentState,
  convertToRaw,
  genKey,
  RawDraftContentState,
} from "draft-js";
import { Form, Formik, FormikProps } from "formik";
import { uniqueId } from "lodash";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import { FC, useMemo } from "react";
import * as Yup from "yup";
import { DatePickerField } from "../../commons/form-fields/DatePickerField";
import { Stack } from "../../commons/layout/Stack";
import {
  useAddTimeReportModalQuery,
  useCreateTimeReportMutation,
} from "./AddTimeReportModal.generated";
import { useTranslate } from "@tolgee/react";
import { isOrphanedItem } from "../../trees/helpers";
import { isTreePreviewItemVisible } from "../doc-items/helpers";

interface FormValues {
  fromDate: Moment;
  tillDate: Moment;
}

interface Props {
  projectId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (reportId?: string) => void;
}

export const AddTimeReportModal: FC<Props> = ({
  projectId,
  title,
  handleClose,
  handleComplete,
}) => {
  const { t } = useTranslate(["TimeReportDetailsForm", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const formId = useMemo(() => uniqueId(), []);
  const now = useMemo(() => moment(), []);

  const client = useApolloClient();
  const query = useAddTimeReportModalQuery({
    client,
    variables: { projectId },
  });

  const [addTimeReport, { loading: addTimeReportLoading }] =
    useCreateTimeReportMutation({
      client,
      refetchQueries: ["TimeReports"],
      awaitRefetchQueries: true,
    });

  const project = getDataOrNull(query.data?.project)?.project;

  const tasks = project?.tasks;

  const initialValues: FormValues = {
    fromDate: moment(now).add(-1, "weeks").isoWeekday(1),
    tillDate: moment(now).add(-1, "weeks").isoWeekday(7),
  };

  const validationSchema = Yup.object().shape({
    fromDate: Yup.date()
      .label(
        t("From", {
          ns: "TimeReportDetailsForm",
        })
      )
      .required(),
    tillDate: Yup.date()
      .label(
        t("Until", {
          ns: "TimeReportDetailsForm",
        })
      )
      .required()
      .test(
        "laterThan",
        t("End date cannot be earlier than start date", {
          ns: "Global",
        }),
        function (tillDate) {
          const fromDate: Date = this.resolve(Yup.ref("fromDate"));
          return !tillDate || fromDate < tillDate;
        }
      ),
  });

  return (
    <Modal
      title={
        title ??
        t("Create time report", {
          ns: "TimeReportDetailsForm",
        })
      }
      dialogProps={{ maxWidth: "xs" }}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Next", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
            disabled: addTimeReportLoading,
            endIcon: <ArrowForwardIcon />,
          },
        },
      ]}
      handleClose={handleClose}
      isLoading={query.loading}
    >
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormValues) => {
          if (!values.fromDate || !values.tillDate) return;

          const fromDate = values.fromDate.format("YYYY-MM-DD");
          const tillDate = values.tillDate.format("YYYY-MM-DD");

          const fromMs = new Date(fromDate).valueOf();
          const tillMs = new Date(tillDate).valueOf();

          // done items list visible by user
          const items = tasks
            ? tasks.map(doc => ({
                title: doc.title,
                items: doc.items
                  .filter((i, idx, arr) =>
                    isTreePreviewItemVisible(true, i, arr, true, false)
                  )
                  .filter((i, idx, arr) => !isOrphanedItem(i, arr))
                  .filter(i => !i.isRootItem && i.isDone && i.doneDate)
                  .filter(i => {
                    const doneMs = new Date(i.doneDate).valueOf();
                    return doneMs >= fromMs && doneMs <= tillMs;
                  })
                  .map(i => `${i.pathForPdf} ${i.title}`),
              }))
            : [];

          const tasksDescription =
            items.length > 0
              ? formTasksDescription(
                  t("Completed tasks", {
                    ns: "TimeReportDetailsForm",
                  }),
                  items
                )
              : null;

          const input = {
            projectId,
            fromDate,
            tillDate,
            tasksDescription: tasksDescription
              ? JSON.stringify(tasksDescription)
              : null,
          };

          try {
            const { data } = await addTimeReport({ variables: { input } });
            const reportId = data?.createTimeReport.timeReport.id;
            enqueueSnackbar(
              t("Time report created", {
                ns: "TimeReportDetailsForm",
              })
            );
            handleComplete?.(reportId);
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
          }
        }}
      >
        {({ setFieldValue, values }: FormikProps<FormValues>) => (
          <Form id={formId}>
            <Stack>
              <DatePickerField
                required
                disableFuture
                name="fromDate"
                label={t("From", {
                  ns: "TimeReportDetailsForm",
                })}
              />
              <DatePickerField
                required
                disableFuture
                name="tillDate"
                label={t("Until", {
                  ns: "TimeReportDetailsForm",
                })}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const formTasksDescription = (
  title: string,
  tasks: { title: string; items: string[] }[]
): RawDraftContentState | null => {
  const contentBlocksArray: ContentBlock[] = [];
  let taskIndex = 0;

  tasks.forEach(task => {
    if (task.items.length <= 0) return;

    const blocksArray = [
      ...(taskIndex > 0
        ? [
            new ContentBlock({
              key: genKey(),
              type: "header-four",
              text: "",
            }),
          ]
        : []),
      new ContentBlock({
        key: genKey(),
        type: "header-four",
        text: task.title,
      }),
      ...task.items.map(item => {
        return new ContentBlock({
          key: genKey(),
          type: "unordered-list-item",
          text: item,
        });
      }),
    ];
    contentBlocksArray.push(...blocksArray);

    taskIndex++;
  });

  if (!contentBlocksArray.length) return null;

  const titleBlocksArray = [
    new ContentBlock({
      key: genKey(),
      type: "header-three",
      text: title,
    }),
    new ContentBlock({
      key: genKey(),
      type: "header-three",
      text: "",
    }),
  ];

  contentBlocksArray.unshift(...titleBlocksArray);

  return convertToRaw(ContentState.createFromBlockArray(contentBlocksArray));
};
