/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvoiceSettingsModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  invoiceId: Types.Scalars['ID']['input'];
}>;


export type InvoiceSettingsModalQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, firstname: string, familyname: string }>, outgoingInvoice: { __typename: 'Invoice', id: string, deliveryOfServiceStart?: any | null, deliveryOfServiceEnd?: any | null, showLaborCostSeparately: boolean, paymentTermDuration: number } | { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } };


export const InvoiceSettingsModalDocument = gql`
    query InvoiceSettingsModal($projectId: ID!, $invoiceId: ID!) {
  organisationMemberships(filters: {active: true}) {
    id
    firstname
    familyname
  }
  outgoingInvoice(projectId: $projectId, invoiceId: $invoiceId) {
    ... on Invoice {
      id
      deliveryOfServiceStart
      deliveryOfServiceEnd
      showLaborCostSeparately
      paymentTermDuration
    }
  }
}
    `;

/**
 * __useInvoiceSettingsModalQuery__
 *
 * To run a query within a React component, call `useInvoiceSettingsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceSettingsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceSettingsModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoiceSettingsModalQuery(baseOptions: Apollo.QueryHookOptions<InvoiceSettingsModalQuery, InvoiceSettingsModalQueryVariables> & ({ variables: InvoiceSettingsModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceSettingsModalQuery, InvoiceSettingsModalQueryVariables>(InvoiceSettingsModalDocument, options);
      }
export function useInvoiceSettingsModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceSettingsModalQuery, InvoiceSettingsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceSettingsModalQuery, InvoiceSettingsModalQueryVariables>(InvoiceSettingsModalDocument, options);
        }
export function useInvoiceSettingsModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvoiceSettingsModalQuery, InvoiceSettingsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoiceSettingsModalQuery, InvoiceSettingsModalQueryVariables>(InvoiceSettingsModalDocument, options);
        }
export type InvoiceSettingsModalQueryHookResult = ReturnType<typeof useInvoiceSettingsModalQuery>;
export type InvoiceSettingsModalLazyQueryHookResult = ReturnType<typeof useInvoiceSettingsModalLazyQuery>;
export type InvoiceSettingsModalSuspenseQueryHookResult = ReturnType<typeof useInvoiceSettingsModalSuspenseQuery>;
export type InvoiceSettingsModalQueryResult = Apollo.QueryResult<InvoiceSettingsModalQuery, InvoiceSettingsModalQueryVariables>;