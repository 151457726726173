import React from "react";
import { Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";

interface Props {
  handleClose: () => void;
}

export const DashboardNewUserModal = ({ handleClose }: Props) => {
  const { t } = useTranslate(["Dashboard", "Global"]);
  return (
    <Modal
      title={t("Welcome to MeiserSystems!", {
        ns: "Dashboard",
      })}
      actionButtons={[
        {
          label: t("OK", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", autoFocus: true },
        },
      ]}
      handleClose={handleClose}
      dialogProps={{
        onBackdropClick: handleClose,
        fullScreen: false,
        fullWidth: false,
      }}
    >
      {/*<DialogContent>*/}
      {/*  <DialogContentText id="new-user-dialog-description">*/}
      {/*    <EmptyScreen slug="fallback" />*/}
      {/*  </DialogContentText>*/}
      {/*</DialogContent>*/}
    </Modal>
  );
};
