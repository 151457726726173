import { Alert, AlertTitle, Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";

export function QuoteTemplatePriceAlert() {
  const { t } = useTranslate("Templates");

  return (
    <Box>
      <Alert variant="outlined" severity="warning">
        <AlertTitle>{t("Price may differ!")}</AlertTitle>
        {t(
          "Final price may differ due to differences in material margins and hourly rates."
        )}
      </Alert>
    </Box>
  );
}
