/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PurchaseOrderListItem_OrderNodeFragmentDoc } from '../../purchase-orders/PurchaseOrderListItem.generated';
import { RequirementRowFragmentDoc } from '../../../main-routes/projects/ProjectRequirements.generated';
import { InvoiceListItem_OutgoingInvoiceFragmentDoc, InvoiceListItem_IncomingInvoiceFragmentDoc } from '../../invoices/InvoiceListItem.generated';
import { QuoteListItem_OutgoingQuoteFragmentDoc, QuoteListItem_IncomingQuoteFragmentDoc } from '../../quotes/QuoteListItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectOverviewDocumentsBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, owningSystemOrganisationId: string, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, status: Types.RequestStatus, wonBySystemOrganisationId?: string | null }>, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null };

export type ProjectOverviewPurchaseOrdersQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
  filterStatus?: Types.InputMaybe<Types.OrderStatus>;
}>;


export type ProjectOverviewPurchaseOrdersQuery = { orders: { __typename: 'MissingCapabilities' } | { __typename: 'OrderConnection', totalCount: number, edges: Array<{ __typename: 'OrderEdge', node: { __typename: 'OrderNode', id: string, number: string, createdAt: any, orderedDate?: any | null, supplierName?: string | null, clientName: string } }> } };

export type ProjectOverview_OrderNodeFragment = { __typename: 'OrderNode', id: string, number: string, createdAt: any, orderedDate?: any | null, supplierName?: string | null };

export type ProjectOverviewRequirementsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
  origin: Types.ProjectDocumentOrigin;
}>;


export type ProjectOverviewRequirementsQuery = { projectRequirements: { __typename: 'MissingPermissions' } | { __typename: 'ProjectRequirementsConnection', edges: Array<{ __typename: 'RequirementEdge', node: { __typename: 'RequirementNode', id: string, title: string, number: string, isPublished: boolean, createdAt: any } }> } };

export type ProjectOverviewOutgoingInvoicesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
  filterStatus?: Types.InputMaybe<Array<Types.InvoiceStatus>>;
  filterOverdue?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ProjectOverviewOutgoingInvoicesQuery = { projectOutgoingInvoices: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingInvoiceConnection', edges: Array<{ __typename: 'OutgoingInvoiceEdge', node: { __typename: 'OutgoingInvoice', id: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate?: any | null, issueDate?: any | null, createdAt: any, totalGrossPrice: number, clientName: string } }> } };

export type ProjectOverview_OutgoingInvoiceFragment = { __typename: 'OutgoingInvoice', id: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate?: any | null, issueDate?: any | null, createdAt: any, totalGrossPrice: number };

export type ProjectOverviewIncomingInvoicesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
  filterOverdue?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterStatus?: Types.InputMaybe<Array<Types.InvoiceStatus>>;
}>;


export type ProjectOverviewIncomingInvoicesQuery = { projectIncomingInvoices: { __typename: 'MissingPermissions' } | { __typename: 'ProjectIncomingInvoiceConnection', edges: Array<{ __typename: 'IncomingInvoiceEdge', node: { __typename: 'IncomingInvoice', id: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate: any, issueDate?: any | null, createdAt: any, totalGrossPrice: number, contractorName: string } }> } };

export type ProjectOverview_IncomingInvoiceFragment = { __typename: 'IncomingInvoice', id: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, dueDate: any, issueDate?: any | null, createdAt: any, totalGrossPrice: number };

export type ProjectOverviewOutgoingQuotesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  projectId: Types.Scalars['ID']['input'];
  filterIsPublished?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterIsBinding?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterAgreement?: Types.InputMaybe<Types.Agreement>;
  filterisChangePending?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ProjectOverviewOutgoingQuotesQuery = { projectOutgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingQuoteConnection', edges: Array<{ __typename: 'OutgoingQuoteEdge', node: { __typename: 'OutgoingQuote', id: string, title: string, createdAt: any, createdBy: string, publishedAt?: any | null, acceptedAt?: any | null, proposedTotalPrice: number, isPublished: boolean, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean, docActorContracteeName: string, docActorClientName: string } }> } };

export type ProjectOverview_OutgoingQuoteFragment = { __typename: 'OutgoingQuote', id: string, title: string, createdAt: any, createdBy: string, publishedAt?: any | null, acceptedAt?: any | null, proposedTotalPrice: number, isPublished: boolean, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean };

export type ProjectOverviewIncomingQuotesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  limit: Types.Scalars['Int']['input'];
  filterIsBinding?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterAgreement?: Types.InputMaybe<Types.Agreement>;
  filterisChangePending?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ProjectOverviewIncomingQuotesQuery = { projectIncomingQuotes: { __typename: 'MissingPermissions' } | { __typename: 'ProjectIncomingQuoteConnection', edges: Array<{ __typename: 'IncomingQuoteEdge', node: { __typename: 'IncomingQuote', id: string, title: string, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, docActorContractorName: string, proposedTotalPrice: number, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean } }> } };

export type ProjectOverview_IncomingQuoteFragment = { __typename: 'IncomingQuote', id: string, title: string, createdAt: any, publishedAt?: any | null, acceptedAt?: any | null, docActorContractorName: string, proposedTotalPrice: number, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean };

export const ProjectOverviewDocumentsBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewDocumentsBox_Project on Project {
  id
  viewerPermissions
  state
  incomingQuoteRequests {
    id
    status
    wonBySystemOrganisationId
  }
  crmOrganisation {
    id
  }
  owningSystemOrganisationId
}
    `;
export const ProjectOverview_OrderNodeFragmentDoc = gql`
    fragment ProjectOverview_OrderNode on OrderNode {
  id
  number
  createdAt
  orderedDate
  supplierName
}
    `;
export const ProjectOverview_OutgoingInvoiceFragmentDoc = gql`
    fragment ProjectOverview_OutgoingInvoice on OutgoingInvoice {
  id
  type
  status
  title
  dueDate
  issueDate
  createdAt
  totalGrossPrice
}
    `;
export const ProjectOverview_IncomingInvoiceFragmentDoc = gql`
    fragment ProjectOverview_IncomingInvoice on IncomingInvoice {
  id
  type
  status
  title
  dueDate
  issueDate
  createdAt
  totalGrossPrice
}
    `;
export const ProjectOverview_OutgoingQuoteFragmentDoc = gql`
    fragment ProjectOverview_OutgoingQuote on OutgoingQuote {
  id
  title
  createdAt
  createdBy
  publishedAt
  acceptedAt
  proposedTotalPrice
  isPublished
  isBinding
  agreement
  needsAgreement
}
    `;
export const ProjectOverview_IncomingQuoteFragmentDoc = gql`
    fragment ProjectOverview_IncomingQuote on IncomingQuote {
  id
  title
  createdAt
  publishedAt
  acceptedAt
  docActorContractorName
  proposedTotalPrice
  isBinding
  agreement
  needsAgreement
}
    `;
export const ProjectOverviewPurchaseOrdersDocument = gql`
    query ProjectOverviewPurchaseOrders($projectId: ID!, $limit: Int!, $filterStatus: OrderStatus) {
  orders(
    filterProjectId: $projectId
    limit: $limit
    sorting: [{column: createdAt, direction: desc}]
    filterStatus: $filterStatus
  ) {
    ... on OrderConnection {
      totalCount
      edges {
        node {
          id
          ...ProjectOverview_OrderNode
          ...PurchaseOrderListItem_OrderNode
        }
      }
    }
  }
}
    ${ProjectOverview_OrderNodeFragmentDoc}
${PurchaseOrderListItem_OrderNodeFragmentDoc}`;

/**
 * __useProjectOverviewPurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useProjectOverviewPurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewPurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewPurchaseOrdersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      filterStatus: // value for 'filterStatus'
 *   },
 * });
 */
export function useProjectOverviewPurchaseOrdersQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewPurchaseOrdersQuery, ProjectOverviewPurchaseOrdersQueryVariables> & ({ variables: ProjectOverviewPurchaseOrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewPurchaseOrdersQuery, ProjectOverviewPurchaseOrdersQueryVariables>(ProjectOverviewPurchaseOrdersDocument, options);
      }
export function useProjectOverviewPurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewPurchaseOrdersQuery, ProjectOverviewPurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewPurchaseOrdersQuery, ProjectOverviewPurchaseOrdersQueryVariables>(ProjectOverviewPurchaseOrdersDocument, options);
        }
export function useProjectOverviewPurchaseOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectOverviewPurchaseOrdersQuery, ProjectOverviewPurchaseOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOverviewPurchaseOrdersQuery, ProjectOverviewPurchaseOrdersQueryVariables>(ProjectOverviewPurchaseOrdersDocument, options);
        }
export type ProjectOverviewPurchaseOrdersQueryHookResult = ReturnType<typeof useProjectOverviewPurchaseOrdersQuery>;
export type ProjectOverviewPurchaseOrdersLazyQueryHookResult = ReturnType<typeof useProjectOverviewPurchaseOrdersLazyQuery>;
export type ProjectOverviewPurchaseOrdersSuspenseQueryHookResult = ReturnType<typeof useProjectOverviewPurchaseOrdersSuspenseQuery>;
export type ProjectOverviewPurchaseOrdersQueryResult = Apollo.QueryResult<ProjectOverviewPurchaseOrdersQuery, ProjectOverviewPurchaseOrdersQueryVariables>;
export const ProjectOverviewRequirementsDocument = gql`
    query ProjectOverviewRequirements($projectId: ID!, $limit: Int!, $origin: ProjectDocumentOrigin!) {
  projectRequirements(
    projectId: $projectId
    filters: {origin: $origin}
    limit: $limit
    offset: 0
    sorting: [{column: createdAt, direction: desc}]
  ) {
    ... on ProjectRequirementsConnection {
      edges {
        node {
          id
          ...RequirementRow
        }
      }
    }
  }
}
    ${RequirementRowFragmentDoc}`;

/**
 * __useProjectOverviewRequirementsQuery__
 *
 * To run a query within a React component, call `useProjectOverviewRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewRequirementsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useProjectOverviewRequirementsQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewRequirementsQuery, ProjectOverviewRequirementsQueryVariables> & ({ variables: ProjectOverviewRequirementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewRequirementsQuery, ProjectOverviewRequirementsQueryVariables>(ProjectOverviewRequirementsDocument, options);
      }
export function useProjectOverviewRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewRequirementsQuery, ProjectOverviewRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewRequirementsQuery, ProjectOverviewRequirementsQueryVariables>(ProjectOverviewRequirementsDocument, options);
        }
export function useProjectOverviewRequirementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectOverviewRequirementsQuery, ProjectOverviewRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOverviewRequirementsQuery, ProjectOverviewRequirementsQueryVariables>(ProjectOverviewRequirementsDocument, options);
        }
export type ProjectOverviewRequirementsQueryHookResult = ReturnType<typeof useProjectOverviewRequirementsQuery>;
export type ProjectOverviewRequirementsLazyQueryHookResult = ReturnType<typeof useProjectOverviewRequirementsLazyQuery>;
export type ProjectOverviewRequirementsSuspenseQueryHookResult = ReturnType<typeof useProjectOverviewRequirementsSuspenseQuery>;
export type ProjectOverviewRequirementsQueryResult = Apollo.QueryResult<ProjectOverviewRequirementsQuery, ProjectOverviewRequirementsQueryVariables>;
export const ProjectOverviewOutgoingInvoicesDocument = gql`
    query ProjectOverviewOutgoingInvoices($projectId: ID!, $limit: Int!, $filterStatus: [InvoiceStatus!], $filterOverdue: Boolean) {
  projectOutgoingInvoices(
    projectId: $projectId
    limit: $limit
    offset: 0
    sorting: [{column: createdAt, direction: desc}]
    filters: {overdue: $filterOverdue, status: $filterStatus}
  ) {
    ... on ProjectOutgoingInvoiceConnection {
      edges {
        node {
          id
          ...ProjectOverview_OutgoingInvoice
          ...InvoiceListItem_OutgoingInvoice
        }
      }
    }
  }
}
    ${ProjectOverview_OutgoingInvoiceFragmentDoc}
${InvoiceListItem_OutgoingInvoiceFragmentDoc}`;

/**
 * __useProjectOverviewOutgoingInvoicesQuery__
 *
 * To run a query within a React component, call `useProjectOverviewOutgoingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewOutgoingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewOutgoingInvoicesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      filterStatus: // value for 'filterStatus'
 *      filterOverdue: // value for 'filterOverdue'
 *   },
 * });
 */
export function useProjectOverviewOutgoingInvoicesQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewOutgoingInvoicesQuery, ProjectOverviewOutgoingInvoicesQueryVariables> & ({ variables: ProjectOverviewOutgoingInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewOutgoingInvoicesQuery, ProjectOverviewOutgoingInvoicesQueryVariables>(ProjectOverviewOutgoingInvoicesDocument, options);
      }
export function useProjectOverviewOutgoingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewOutgoingInvoicesQuery, ProjectOverviewOutgoingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewOutgoingInvoicesQuery, ProjectOverviewOutgoingInvoicesQueryVariables>(ProjectOverviewOutgoingInvoicesDocument, options);
        }
export function useProjectOverviewOutgoingInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectOverviewOutgoingInvoicesQuery, ProjectOverviewOutgoingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOverviewOutgoingInvoicesQuery, ProjectOverviewOutgoingInvoicesQueryVariables>(ProjectOverviewOutgoingInvoicesDocument, options);
        }
export type ProjectOverviewOutgoingInvoicesQueryHookResult = ReturnType<typeof useProjectOverviewOutgoingInvoicesQuery>;
export type ProjectOverviewOutgoingInvoicesLazyQueryHookResult = ReturnType<typeof useProjectOverviewOutgoingInvoicesLazyQuery>;
export type ProjectOverviewOutgoingInvoicesSuspenseQueryHookResult = ReturnType<typeof useProjectOverviewOutgoingInvoicesSuspenseQuery>;
export type ProjectOverviewOutgoingInvoicesQueryResult = Apollo.QueryResult<ProjectOverviewOutgoingInvoicesQuery, ProjectOverviewOutgoingInvoicesQueryVariables>;
export const ProjectOverviewIncomingInvoicesDocument = gql`
    query ProjectOverviewIncomingInvoices($projectId: ID!, $limit: Int!, $filterOverdue: Boolean, $filterStatus: [InvoiceStatus!]) {
  projectIncomingInvoices(
    projectId: $projectId
    limit: $limit
    offset: 0
    sorting: [{column: createdAt, direction: desc}]
    filters: {overdue: $filterOverdue, status: $filterStatus}
  ) {
    ... on ProjectIncomingInvoiceConnection {
      edges {
        node {
          id
          ...ProjectOverview_IncomingInvoice
          ...InvoiceListItem_IncomingInvoice
        }
      }
    }
  }
}
    ${ProjectOverview_IncomingInvoiceFragmentDoc}
${InvoiceListItem_IncomingInvoiceFragmentDoc}`;

/**
 * __useProjectOverviewIncomingInvoicesQuery__
 *
 * To run a query within a React component, call `useProjectOverviewIncomingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewIncomingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewIncomingInvoicesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      filterOverdue: // value for 'filterOverdue'
 *      filterStatus: // value for 'filterStatus'
 *   },
 * });
 */
export function useProjectOverviewIncomingInvoicesQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewIncomingInvoicesQuery, ProjectOverviewIncomingInvoicesQueryVariables> & ({ variables: ProjectOverviewIncomingInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewIncomingInvoicesQuery, ProjectOverviewIncomingInvoicesQueryVariables>(ProjectOverviewIncomingInvoicesDocument, options);
      }
export function useProjectOverviewIncomingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewIncomingInvoicesQuery, ProjectOverviewIncomingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewIncomingInvoicesQuery, ProjectOverviewIncomingInvoicesQueryVariables>(ProjectOverviewIncomingInvoicesDocument, options);
        }
export function useProjectOverviewIncomingInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectOverviewIncomingInvoicesQuery, ProjectOverviewIncomingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOverviewIncomingInvoicesQuery, ProjectOverviewIncomingInvoicesQueryVariables>(ProjectOverviewIncomingInvoicesDocument, options);
        }
export type ProjectOverviewIncomingInvoicesQueryHookResult = ReturnType<typeof useProjectOverviewIncomingInvoicesQuery>;
export type ProjectOverviewIncomingInvoicesLazyQueryHookResult = ReturnType<typeof useProjectOverviewIncomingInvoicesLazyQuery>;
export type ProjectOverviewIncomingInvoicesSuspenseQueryHookResult = ReturnType<typeof useProjectOverviewIncomingInvoicesSuspenseQuery>;
export type ProjectOverviewIncomingInvoicesQueryResult = Apollo.QueryResult<ProjectOverviewIncomingInvoicesQuery, ProjectOverviewIncomingInvoicesQueryVariables>;
export const ProjectOverviewOutgoingQuotesDocument = gql`
    query ProjectOverviewOutgoingQuotes($limit: Int!, $projectId: ID!, $filterIsPublished: Boolean, $filterIsBinding: Boolean, $filterAgreement: Agreement, $filterisChangePending: Boolean) {
  projectOutgoingQuotes(
    projectId: $projectId
    limit: $limit
    offset: 0
    sorting: [{column: createdAt, direction: desc}]
    filters: {isPublished: $filterIsPublished, isBinding: $filterIsBinding, agreement: $filterAgreement, isChangePending: $filterisChangePending}
  ) {
    ... on ProjectOutgoingQuoteConnection {
      edges {
        node {
          id
          ...ProjectOverview_OutgoingQuote
          ...QuoteListItem_OutgoingQuote
        }
      }
    }
  }
}
    ${ProjectOverview_OutgoingQuoteFragmentDoc}
${QuoteListItem_OutgoingQuoteFragmentDoc}`;

/**
 * __useProjectOverviewOutgoingQuotesQuery__
 *
 * To run a query within a React component, call `useProjectOverviewOutgoingQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewOutgoingQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewOutgoingQuotesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      projectId: // value for 'projectId'
 *      filterIsPublished: // value for 'filterIsPublished'
 *      filterIsBinding: // value for 'filterIsBinding'
 *      filterAgreement: // value for 'filterAgreement'
 *      filterisChangePending: // value for 'filterisChangePending'
 *   },
 * });
 */
export function useProjectOverviewOutgoingQuotesQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewOutgoingQuotesQuery, ProjectOverviewOutgoingQuotesQueryVariables> & ({ variables: ProjectOverviewOutgoingQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewOutgoingQuotesQuery, ProjectOverviewOutgoingQuotesQueryVariables>(ProjectOverviewOutgoingQuotesDocument, options);
      }
export function useProjectOverviewOutgoingQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewOutgoingQuotesQuery, ProjectOverviewOutgoingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewOutgoingQuotesQuery, ProjectOverviewOutgoingQuotesQueryVariables>(ProjectOverviewOutgoingQuotesDocument, options);
        }
export function useProjectOverviewOutgoingQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectOverviewOutgoingQuotesQuery, ProjectOverviewOutgoingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOverviewOutgoingQuotesQuery, ProjectOverviewOutgoingQuotesQueryVariables>(ProjectOverviewOutgoingQuotesDocument, options);
        }
export type ProjectOverviewOutgoingQuotesQueryHookResult = ReturnType<typeof useProjectOverviewOutgoingQuotesQuery>;
export type ProjectOverviewOutgoingQuotesLazyQueryHookResult = ReturnType<typeof useProjectOverviewOutgoingQuotesLazyQuery>;
export type ProjectOverviewOutgoingQuotesSuspenseQueryHookResult = ReturnType<typeof useProjectOverviewOutgoingQuotesSuspenseQuery>;
export type ProjectOverviewOutgoingQuotesQueryResult = Apollo.QueryResult<ProjectOverviewOutgoingQuotesQuery, ProjectOverviewOutgoingQuotesQueryVariables>;
export const ProjectOverviewIncomingQuotesDocument = gql`
    query ProjectOverviewIncomingQuotes($projectId: ID!, $limit: Int!, $filterIsBinding: Boolean, $filterAgreement: Agreement, $filterisChangePending: Boolean) {
  projectIncomingQuotes(
    limit: $limit
    offset: 0
    sorting: [{column: createdAt, direction: desc}]
    filters: {isBinding: $filterIsBinding, agreement: $filterAgreement, isChangePending: $filterisChangePending}
    projectId: $projectId
  ) {
    ... on ProjectIncomingQuoteConnection {
      edges {
        node {
          id
          ...ProjectOverview_IncomingQuote
          ...QuoteListItem_IncomingQuote
        }
      }
    }
  }
}
    ${ProjectOverview_IncomingQuoteFragmentDoc}
${QuoteListItem_IncomingQuoteFragmentDoc}`;

/**
 * __useProjectOverviewIncomingQuotesQuery__
 *
 * To run a query within a React component, call `useProjectOverviewIncomingQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewIncomingQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewIncomingQuotesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      filterIsBinding: // value for 'filterIsBinding'
 *      filterAgreement: // value for 'filterAgreement'
 *      filterisChangePending: // value for 'filterisChangePending'
 *   },
 * });
 */
export function useProjectOverviewIncomingQuotesQuery(baseOptions: Apollo.QueryHookOptions<ProjectOverviewIncomingQuotesQuery, ProjectOverviewIncomingQuotesQueryVariables> & ({ variables: ProjectOverviewIncomingQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewIncomingQuotesQuery, ProjectOverviewIncomingQuotesQueryVariables>(ProjectOverviewIncomingQuotesDocument, options);
      }
export function useProjectOverviewIncomingQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewIncomingQuotesQuery, ProjectOverviewIncomingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewIncomingQuotesQuery, ProjectOverviewIncomingQuotesQueryVariables>(ProjectOverviewIncomingQuotesDocument, options);
        }
export function useProjectOverviewIncomingQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectOverviewIncomingQuotesQuery, ProjectOverviewIncomingQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectOverviewIncomingQuotesQuery, ProjectOverviewIncomingQuotesQueryVariables>(ProjectOverviewIncomingQuotesDocument, options);
        }
export type ProjectOverviewIncomingQuotesQueryHookResult = ReturnType<typeof useProjectOverviewIncomingQuotesQuery>;
export type ProjectOverviewIncomingQuotesLazyQueryHookResult = ReturnType<typeof useProjectOverviewIncomingQuotesLazyQuery>;
export type ProjectOverviewIncomingQuotesSuspenseQueryHookResult = ReturnType<typeof useProjectOverviewIncomingQuotesSuspenseQuery>;
export type ProjectOverviewIncomingQuotesQueryResult = Apollo.QueryResult<ProjectOverviewIncomingQuotesQuery, ProjectOverviewIncomingQuotesQueryVariables>;