/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TemplateTypeOverviewSubHeader__TemplateTypeFragment = { __typename: 'TemplateType', id: string, title: string, key: string, revision: number, createdAt: any, deletedAt?: any | null };

export const TemplateTypeOverviewSubHeader__TemplateTypeFragmentDoc = gql`
    fragment TemplateTypeOverviewSubHeader__TemplateType on TemplateType {
  id
  title
  key
  revision
  createdAt
  deletedAt
}
    `;