import { Box, Typography } from "@mui/material";
import React from "react";
import { Stack } from "../../commons/layout/Stack";
import { BillOfMaterialsItemStatus } from "../../../clients/graphqlTypes";
import { BillOfMaterialsItemStatusLabel } from "./BillOfMaterialsItemStatus";

interface Props {
  item: {
    title: string;
    itemStatus: BillOfMaterialsItemStatus;
    quantityRequiredTotal: number;
    quantityOrdered: number;
  };
}

export function BillOfMaterialsListItem({
  item: { title, itemStatus, quantityRequiredTotal, quantityOrdered },
}: Props) {
  return (
    <Stack flexDirection="column" spacing={0} flex={1}>
      <Typography variant="body1">{title}</Typography>
      <Stack alignItems="center">
        <BillOfMaterialsItemStatusLabel status={itemStatus} />
        <Typography variant="caption">{`ordered ${quantityOrdered}/${quantityRequiredTotal}`}</Typography>
      </Stack>
    </Stack>
  );
}

interface EditProps extends Props {
  Checkbox: React.ReactElement;
  QuantityInput: React.ReactElement;
}

export function BillOfMaterialsListItemEdit({
  item,
  Checkbox,
  QuantityInput,
}: EditProps) {
  return (
    <Stack alignItems="center">
      {Checkbox}
      <BillOfMaterialsListItem item={item} />
      <Box maxWidth="10ch">{QuantityInput}</Box>
    </Stack>
  );
}
