import { LabeledValue } from "@msys/ui";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid } from "@mui/material";
import React from "react";
import { isDefinedProperty } from "../features/item-properties/itemProperties";

interface Props {
  properties: { key: string; value: string }[];
  columns?: number;
}

export const PropertiesGridLabeledValue = ({ properties, columns }: Props) => {
  return (
    <Grid container columns={columns} spacing={1}>
      {properties.map(property => (
        <Grid key={property.key} item xs={1}>
          <LabeledValue
            label={property.key}
            labelIcon={!isDefinedProperty(property) ? WarningIcon : undefined}
            labelIconProps={
              !isDefinedProperty(property) ? { color: "warning" } : undefined
            }
          >
            {property.value}
          </LabeledValue>
        </Grid>
      ))}
    </Grid>
  );
};
