import { useApolloClient } from "@apollo/client";
import { CardContainer, ModalOpenButton } from "@msys/ui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Formik } from "formik";
import { omit } from "lodash";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { VatVisibility } from "../../../../clients/graphqlTypes";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";
import { SwitchField } from "../../../commons/form-fields/SwitchField";
import {
  QuoteHeaderVisibility,
  QuoteHeaderVisibilityField,
  useQuoteHeaderVisibilityValidationSchema,
} from "../../quotes/form-fields/QuoteHeaderVisibilityField";
import {
  QuoteProductsVisibility,
  QuoteProductsVisibilityField,
  useQuoteProductsVisibilityValidationSchema,
} from "../../quotes/form-fields/QuoteProductsVisibilityField";
import { OrganisationContractingPdfsSection } from "../sections/OrganisationContractingPdfsSection";
import { OrganisationContractingPdfFragment } from "../sections/OrganisationContractingPdfsSection.generated";
import {
  OrganisationQuotePdfSettingsBox_SettingsFragment,
  useUpdateOrganisationQuotePdfSettingsMutation,
} from "./OrganisationQuotePdfSettingsBox.generated";
import { ModifyOrganisationQuotesTextsModal } from "../modals/ModifyOrganisationQuotesTextsModal";

interface FormValues {
  quoteIsSignatureVisible: boolean;
  quoteVatVisibility: VatVisibility;
  quoteHeaderVisibility: QuoteHeaderVisibility;
  quoteProductsVisibility: QuoteProductsVisibility;
}

interface Props {
  organisationId: string;
  organisationSettings: OrganisationQuotePdfSettingsBox_SettingsFragment;
  organisationContractingPdfs: OrganisationContractingPdfFragment[];
}

export function OrganisationQuotePdfSettingsBox({
  organisationId,
  organisationSettings,
  organisationContractingPdfs,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["OrganisationSettings", "VatVisibility"]);

  const client = useApolloClient();
  const [modifySettings] = useUpdateOrganisationQuotePdfSettingsMutation({
    client,
  });

  const quoteHeaderVisibilityValidationSchema =
    useQuoteHeaderVisibilityValidationSchema();
  const quoteProductsVisibilityValidationSchema =
    useQuoteProductsVisibilityValidationSchema();

  const validationSchema = Yup.object().shape({
    quoteIsSignatureVisible: Yup.boolean().required(),
    quoteVatVisibility: Yup.string().required(),
    quoteHeaderVisibility: quoteHeaderVisibilityValidationSchema,
    quoteProductsVisibility: quoteProductsVisibilityValidationSchema,
  });

  const initialValues: FormValues = {
    quoteIsSignatureVisible: organisationSettings.quoteIsSignatureVisible,
    quoteVatVisibility: organisationSettings.quoteVatVisibility,
    quoteHeaderVisibility: omit(
      organisationSettings.quoteHeaderVisibility,
      "__typename"
    ),
    quoteProductsVisibility: omit(
      organisationSettings.quoteProductsVisibility,
      "__typename"
    ),
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifySettings({
        variables: {
          input: {
            quoteIsSignatureVisible: values.quoteIsSignatureVisible,
            quoteVatVisibility: values.quoteVatVisibility,
            quoteHeaderVisibility: values.quoteHeaderVisibility,
            quoteProductsVisibility: values.quoteProductsVisibility,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <CardContainer
        isExpandable
        title={t("Quote PDF settings", { ns: "OrganisationSettings" })}
      >
        <Stack direction="column" p={1} spacing={1} width="100%">
          <QuoteHeaderVisibilityField
            name="quoteHeaderVisibility"
            disabled={false}
          />
          <QuoteProductsVisibilityField
            name="quoteProductsVisibility"
            disabled={false}
          />

          <Typography>
            {t("VAT visibility", {
              ns: "OrganisationSettings",
            })}
          </Typography>
          <RadioGroupField
            name="quoteVatVisibility"
            options={[
              {
                value: "SHOW",
                label: t("Show", {
                  ns: "VatVisibility",
                }),
              },
              {
                value: "SHOW_SMALL",
                label: t("Show in small print", {
                  ns: "VatVisibility",
                }),
              },
              {
                value: "HIDE",
                label: t("Hide", {
                  ns: "VatVisibility",
                }),
              },
            ]}
            condensed
          />

          <div>
            <Typography>
              {t("Signature area", {
                ns: "OrganisationSettings",
              })}
            </Typography>
            <SwitchField
              label={t("Signature area is visible by default", {
                ns: "OrganisationSettings",
              })}
              name="quoteIsSignatureVisible"
              disabled={false}
            />
          </div>

          <AutoSave />

          <Divider />
          <OrganisationContractingPdfsSection
            organisationId={organisationId}
            contractingPdfs={organisationContractingPdfs}
          />
          <Divider />
          <div>
            <ModalOpenButton
              Modal={ModifyOrganisationQuotesTextsModal}
              modalProps={{
                organisationId,
                title: t("Customize quote text", {
                  ns: "OrganisationSettings",
                }),
              }}
            >
              <Button
                type="button"
                color="secondary"
                variant="text"
                size="small"
                endIcon={<ChevronRightIcon />}
              >
                {t("Customize quote text", {
                  ns: "OrganisationSettings",
                })}
              </Button>
            </ModalOpenButton>
          </div>
        </Stack>
      </CardContainer>
    </Formik>
  );
}
