import React, { FC } from "react";
import { Switch } from "formik-mui";
import { Field } from "formik";
import type { SwitchProps } from "@mui/material";
import { FormControlLabel, Typography } from "@mui/material";

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
}

export const SwitchField: FC<SwitchProps & Props> = ({
  name,
  label,
  disabled,
  ...props
}) => (
  <FormControlLabel
    control={
      <Field
        component={Switch}
        type="checkbox"
        name={name}
        disabled={disabled}
        {...props}
      />
    }
    label={<Typography variant="body2">{label}</Typography>}
    disabled={disabled}
  />
);
