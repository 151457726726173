import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  TableAggregates,
  useFormatting,
} from "@msys/ui";
import DownloadIcon from "@mui/icons-material/GetApp";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { EntityNumber } from "../../commons/EntityNumber";
import {
  TabOptionWithRestriction,
  TabsWithRestriction,
} from "../../commons/TabsWithRestriction";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { useOpenFile } from "../../commons/hooks/useOpenFile";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants";
import { InvoiceListItem } from "../../features/invoices/InvoiceListItem";
import { InvoiceStatusBadge } from "../../features/invoices/InvoiceStatusBadge";
import { CreateInvoiceButton } from "../../features/invoices/buttons/CreateInvoiceButton";
import { useInvoiceTypes } from "../../features/invoices/hooks/useInvoiceTypes";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  ProjectIncomingInvoices_InvoiceFragment,
  ProjectOutgoingInvoices_InvoiceFragment,
  useProjectIncomingInvoicesQuery,
  useProjectOutgoingInvoicesQuery,
} from "./ProjectInvoices.generated";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

interface InvoiceListProps {
  projectId: string;
  projectTitle: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
  tabOptions: TabOptionWithRestriction<Tab>[];
}

export const ProjectOutgoingInvoices = ({
  projectId,
  projectTitle,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
  tabOptions,
}: InvoiceListProps) => {
  const navigate = useNavigate();

  const { t } = useTranslate(["Invoices", "Global", "ProjectOverview"]);
  const { getFormattedPrice, getFormattedDate, getFormattedDateTime } =
    useFormatting();

  const { invoicePaymentLabels } = useInvoiceTypes();
  const { openPdf } = useOpenFile();

  const subtitle = t("Outgoing invoices", {
    ns: "Invoices",
  });

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "project-invoices",
    "table",
    "list"
  );

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: subtitle,
        to: `/projects/${projectId}/invoices/outgoing`,
      },
    ],
    [prefixBreadcrumbs, projectId, subtitle]
  );

  const client = useApolloClient();
  const query = useProjectOutgoingInvoicesQuery({
    client,
    variables: {
      projectId,
      limit: PAGE_LIST_RESULTS_PER_PAGE,
    },
  });

  const columns: GridColDef<ProjectOutgoingInvoices_InvoiceFragment>[] = [
    {
      field: "number",
      headerName: t("No.", {
        ns: "Global",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        invoice.number ? <EntityNumber noLabel number={invoice.number} /> : "–",
    },
    {
      field: "type",
      headerName: t("Type", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) => invoicePaymentLabels[invoice.type],
    },
    {
      field: "title",
      headerName: t("Invoice title", {
        ns: "Invoices",
      }),
      flex: 5,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) => invoice.title,
    },
    {
      field: "status",
      headerName: t("Status", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) => (
        <InvoiceStatusBadge status={invoice.status} />
      ),
    },
    {
      field: "issueDate",
      headerName: t("Issue Date", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        invoice.issueDate
          ? getFormattedDate(invoice.issueDate)
          : t("Not issued", {
              ns: "Invoices",
            }),
    },
    {
      field: "dueDate",
      headerName: t("Due date", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        invoice.dueDate
          ? getFormattedDate(invoice.dueDate)
          : t("Not set", {
              ns: "Global",
            }),
    },
    {
      field: "createdAt",
      headerName: t("Created", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      valueGetter: ({ row: invoice }) =>
        invoice.createdAt ? new Date(invoice.createdAt) : null,
      renderCell: ({ row: invoice }) =>
        invoice.createdAt ? getFormattedDateTime(invoice.createdAt) : "-",
    },
    {
      field: "totalNetPrice",
      headerName: t("Total net", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        getFormattedPrice(invoice.totalNetPrice),
    },
    {
      field: "totalGrossPrice",
      headerName: t("Total gross", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        getFormattedPrice(invoice.totalGrossPrice),
    },
    {
      field: "download",
      headerName: "",
      flex: 0.1,
      minWidth: 50,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        invoice.pdfUrl ? (
          <Tooltip
            title={t("Download PDF", {
              ns: "Invoices",
            })}
          >
            <IconButton
              size="small"
              color="primary"
              onClick={e => {
                e.stopPropagation();
                openPdf(invoice.pdfUrl!, `Invoice_${invoice.id}`);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        ) : null,
    },
  ];

  const invoices =
    getDataOrNull(query.data?.projectOutgoingInvoices)?.edges.map(
      edge => edge.node
    ) ?? [];
  const totalCount =
    getDataOrNull(query.data?.projectOutgoingInvoices)?.totalCount ?? 0;
  const totalAmountOpen = getDataOrNull(
    query.data?.projectOutgoingInvoices
  )?.totalAmountOpen;
  const totalAmountOverdue = getDataOrNull(
    query.data?.projectOutgoingInvoices
  )?.totalAmountOverdue;
  const projectViewerPermissions = getDataOrNull(query.data?.project)?.project
    ?.viewerPermissions;

  const stateStore = useDataGridStateStore("ProjectOutgoingInvoices");

  return (
    <Page
      subtitle={projectTitle}
      title={subtitle}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      <PageContainer>
        <Stack spacing={2}>
          <ListHeader
            QuickFilter={
              tabOptions.length > 1 ? (
                <InvoicesTabs
                  tab={"outgoing"}
                  projectId={projectId}
                  options={tabOptions}
                />
              ) : undefined
            }
            SwitchViewButton={
              <SwitchCollectionViewButton
                allowedViews={ALLOWED_VIEWS}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            }
            Info={
              <TableAggregates
                aggregates={[
                  {
                    label: t("Open", {
                      ns: "ProjectOverview",
                    }),
                    value: totalAmountOpen,
                    color: "secondary",
                  },
                  {
                    label: t("Overdue", {
                      ns: "ProjectOverview",
                    }),
                    value: totalAmountOverdue,
                    color: "error",
                  },
                ]}
              />
            }
            CreateButton={
              projectViewerPermissions && (
                <RestrictedByProjectPermissionWithDebug
                  permission="MANAGE_INVOICES"
                  project={{ viewerPermissions: projectViewerPermissions }}
                >
                  <CreateInvoiceButton
                    projectId={projectId}
                    refetchQueries={[
                      namedOperations.Query.ProjectOutgoingInvoices,
                    ]}
                  />
                </RestrictedByProjectPermissionWithDebug>
              )
            }
          />
          {/* <ShowBox box={{ name: "project-invoices-list-item", project }}> */}
          {(() => {
            switch (activeView) {
              case "table":
                return (
                  <DataGrid
                    stateStore={stateStore}
                    density="standard"
                    disableColumnFilter
                    hideFooter
                    loading={query.loading}
                    columns={columns}
                    rows={invoices}
                    onRowClick={({ row: invoice }) => {
                      navigate(
                        `/projects/${projectId}/invoices/outgoing/${invoice.id}`
                      );
                    }}
                    rowCount={totalCount}
                    disableRowSelectionOnClick
                  />
                );
              case "list":
                if (query.loading) return <LoadingSpinner />;
                if (totalCount === 0)
                  return (
                    <ErrorMessage
                      message={t("There are no items to display", {
                        ns: "Global",
                      })}
                    />
                  );
                return (
                  <Stack width="100%" direction="column" spacing={1}>
                    {invoices.map(invoice => (
                      <CardItem
                        key={invoice.id}
                        //@ts-ignore
                        component={Link}
                        to={`/projects/${projectId}/invoices/outgoing/${invoice.id}`}
                      >
                        <InvoiceListItem invoice={invoice} />
                      </CardItem>
                    ))}
                  </Stack>
                );
            }
          })()}

          {/* </ShowBox> */}
        </Stack>
      </PageContainer>
    </Page>
  );
};

export const ProjectIncomingInvoices = ({
  projectId,
  projectTitle,
  prefixBreadcrumbs,
  submenuItems,
  activeSubmenuItem,
  tabOptions,
}: InvoiceListProps) => {
  const navigate = useNavigate();

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "project-invoices",
    "table",
    "list"
  );

  const { t } = useTranslate(["Invoices", "Global", "ProjectOverview"]);
  const { getFormattedPrice, getFormattedDate } = useFormatting();

  const { invoicePaymentLabels } = useInvoiceTypes();
  const { openPdf } = useOpenFile();

  const subtitle = t("Incoming invoices", {
    ns: "Invoices",
  });

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: subtitle,
        to: `/projects/${projectId}/invoices/incoming`,
      },
    ],
    [prefixBreadcrumbs, projectId, subtitle]
  );

  const client = useApolloClient();
  const query = useProjectIncomingInvoicesQuery({
    client,
    variables: { projectId, limit: PAGE_LIST_RESULTS_PER_PAGE },
  });

  const columns: GridColDef<ProjectIncomingInvoices_InvoiceFragment>[] = [
    {
      field: "number",
      headerName: t("No.", {
        ns: "Global",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        invoice.number ? <EntityNumber noLabel number={invoice.number} /> : "–",
    },
    {
      field: "type",
      headerName: t("Type", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) => invoicePaymentLabels[invoice.type],
    },
    {
      field: "title",
      headerName: t("Invoice title", {
        ns: "Invoices",
      }),
      flex: 5,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) => invoice.title,
    },
    {
      field: "status",
      headerName: t("Status", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) => (
        <InvoiceStatusBadge status={invoice.status} />
      ),
    },
    {
      field: "issueDate",
      headerName: t("Issue Date", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        invoice.issueDate
          ? getFormattedDate(invoice.issueDate)
          : t("Not issued", {
              ns: "Invoices",
            }),
    },
    {
      field: "dueDate",
      headerName: t("Due date", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        invoice.dueDate
          ? getFormattedDate(invoice.dueDate)
          : t("Not set", {
              ns: "Global",
            }),
    },
    {
      field: "totalNetPrice",
      headerName: t("Total net", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        getFormattedPrice(invoice.totalNetPrice),
    },
    {
      field: "totalGrossPrice",
      headerName: t("Total gross", {
        ns: "Invoices",
      }),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row: invoice }) =>
        getFormattedPrice(invoice.totalGrossPrice),
    },
    {
      field: "download",
      headerName: "",
      flex: 0.1,
      minWidth: 50,
      sortable: false,
      renderCell: ({ row: invoice }) => (
        <Tooltip
          title={t("Download PDF", {
            ns: "Invoices",
          })}
        >
          <IconButton
            size="small"
            color="primary"
            onClick={e => {
              e.stopPropagation();
              openPdf(invoice.pdfUrl!, `Invoice_${invoice.id}`);
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const invoices =
    getDataOrNull(query.data?.projectIncomingInvoices)?.edges.map(
      edge => edge.node
    ) ?? [];
  const totalCount = getDataOrNull(
    query.data?.projectIncomingInvoices
  )?.totalCount;
  const totalAmountOpen = getDataOrNull(
    query.data?.projectIncomingInvoices
  )?.totalAmountOpen;
  const totalAmountOverdue = getDataOrNull(
    query.data?.projectIncomingInvoices
  )?.totalAmountOverdue;

  const stateStore = useDataGridStateStore("ProjectIncomingInvoices");

  return (
    <Page
      subtitle={projectTitle}
      title={subtitle}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      <PageContainer>
        <Stack spacing={2}>
          <ListHeader
            QuickFilter={
              tabOptions.length > 1 ? (
                <InvoicesTabs
                  tab={"incoming"}
                  projectId={projectId}
                  options={tabOptions}
                />
              ) : undefined
            }
            SwitchViewButton={
              <SwitchCollectionViewButton
                allowedViews={ALLOWED_VIEWS}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            }
            Info={
              <TableAggregates
                aggregates={[
                  {
                    label: t("Open", {
                      ns: "ProjectOverview",
                    }),
                    value: totalAmountOpen,
                    color: "secondary",
                  },
                  {
                    label: t("Overdue", {
                      ns: "ProjectOverview",
                    }),
                    value: totalAmountOverdue,
                    color: "error",
                  },
                ]}
              />
            }
          />
          {/* <ShowBox box={{ name: "project-invoices-list-item", project }}> */}
          {(() => {
            switch (activeView) {
              case "table":
                return (
                  <DataGrid
                    stateStore={stateStore}
                    density="standard"
                    disableColumnFilter
                    hideFooter
                    loading={query.loading}
                    columns={columns}
                    rows={invoices}
                    onRowClick={({ row: invoice }) => {
                      navigate(
                        `/projects/${projectId}/invoices/incoming/${invoice.id}`
                      );
                    }}
                    rowCount={totalCount ?? 0}
                    disableRowSelectionOnClick
                  />
                );
              case "list":
                if (query.loading) return <LoadingSpinner />;
                if (totalCount === 0)
                  return (
                    <ErrorMessage
                      message={t("There are no items to display", {
                        ns: "Global",
                      })}
                    />
                  );
                return (
                  <Stack width="100%" direction="column" spacing={1}>
                    {invoices.map(invoice => (
                      <CardItem
                        key={invoice.id}
                        //@ts-ignore
                        component={Link}
                        to={`/projects/${projectId}/invoices/incoming/${invoice.id}`}
                      >
                        <InvoiceListItem invoice={invoice} />
                      </CardItem>
                    ))}
                  </Stack>
                );
            }
          })()}
          {/* </ShowBox> */}
        </Stack>
      </PageContainer>
    </Page>
  );
};

export type Tab = "incoming" | "outgoing";

function InvoicesTabs({
  tab,
  projectId,
  options,
}: {
  tab: Tab;
  projectId: string;
  options: TabOptionWithRestriction<Tab>[];
}) {
  const navigate = useNavigate();

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      spacing={1}
    >
      <TabsWithRestriction
        condensed
        useSelectOnMobile
        options={options}
        value={tab}
        onChange={tab => {
          navigate(`/projects/${projectId}/invoices/${tab}`);
        }}
      />
    </Stack>
  );
}
