import { NetworkStatus, useApolloClient } from "@apollo/client";
import { ErrorMessage, LoadingSpinner as LoadingIndicator } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useEffect, useRef } from "react";
import { useEffectOnce, useInterval } from "react-use";
import { useBubbleContext } from "../../commons/hooks/useBubbleContext";
import { useScrollToKeyboard } from "../../global/NativeFeatureProvider";
import { useChannelQuery } from "./Channel.generated";
import { ChannelForm } from "./ChannelForm";
import { ChannelMessages, Layout } from "./ChannelMessages";

const MESSAGES_COUNT = 20;

interface Props {
  channelId: string;
  inputPlaceholder?: string;
  loadPreviousMessagesLabel?: string;
  noMessagesTitle?: string;
  layout?: Layout;
}

export const Channel = ({
  channelId,
  inputPlaceholder,
  loadPreviousMessagesLabel,
  noMessagesTitle,
  layout,
}: Props) => {
  const { markChannelAsRead } = useBubbleContext();
  const client = useApolloClient();

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const query = useChannelQuery({
    client,
    fetchPolicy: "network-only",
    variables: { channelId, first: MESSAGES_COUNT },
  });

  const { t } = useTranslate("Channel");

  useEffectOnce(() => {
    markChannelAsRead(channelId);
  });

  // Scroll down on keyboard appearing
  // useEffect(() => {
  //   if (keyboardHeight > 0) {
  //     setTimeout(() => {
  //       scrollContainerRef.current?.scroll({ top: scrollContainerRef.current?.scrollHeight, behavior: 'auto' });
  //     });
  //   }
  // }, [keyboardHeight]);

  const loadPreviousMessages = async () => {
    if (!query?.data?.channel?.messages.pageInfo.hasNextPage) return;
    await query?.fetchMore({
      variables: {
        after: query?.data?.channel?.messages.pageInfo.endCursor,
        before: undefined,
        first: MESSAGES_COUNT,
        last: undefined,
      },
    });
  };

  const checkForNextMessages = async () => {
    let isAnyNewMessageReceived: boolean;
    const el = scrollContainerRef.current;

    const isScrollAtBottom = el
      ? el.scrollHeight - el.scrollTop - el.clientHeight < 1 // https://stackoverflow.com/questions/876115/how-can-i-determine-if-a-div-is-scrolled-to-the-bottom
      : false;

    if (query?.data?.channel?.messages.pageInfo.startCursor) {
      const result = await query.fetchMore({
        variables: {
          before: query?.data?.channel?.messages.pageInfo.startCursor,
          after: undefined,
          first: undefined,
          last: MESSAGES_COUNT,
        },
      });
      isAnyNewMessageReceived =
        (result?.data?.channel?.messages.edges ?? []).length > 0;
    } else {
      const result = await query?.fetchMore({
        variables: {
          before: undefined,
          after: undefined,
          first: MESSAGES_COUNT,
          last: undefined,
        },
      });
      isAnyNewMessageReceived =
        (result?.data?.channel?.messages.edges ?? []).length > 0;
    }

    if (isAnyNewMessageReceived) {
      if (isScrollAtBottom) {
        // scrolling to bottom if previously scroll was at bottom
        setTimeout(() => {
          scrollContainerRef.current?.scroll({
            top: scrollContainerRef.current?.scrollHeight,
            behavior: "auto",
          });
        });
      }

      await markChannelAsRead(channelId);
    }
  };

  useInterval(checkForNextMessages, 5000);

  // Scroll to previous position when keyboard appears
  useScrollToKeyboard(scrollContainerRef, !query.loading && !query.error);

  // Scroll down when query finishes loading initially only
  useEffect(() => {
    if (!query.loading) {
      setTimeout(() => {
        scrollContainerRef.current?.scroll({
          top: scrollContainerRef.current?.scrollHeight,
          behavior: "auto",
        });
      });
    }
  }, [query.loading]);

  if (query.loading) return <LoadingIndicator />;
  if (query.error) return <ErrorMessage message={query.error.message} />;
  if (query.networkStatus === NetworkStatus.error)
    return <ErrorMessage message="Network error" />;

  const channel = query?.data?.channel;

  if (!channel) return <div>{t("Chat doesn't exist")}</div>;

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="stretch"
      overflow="auto"
    >
      <Box
        ref={scrollContainerRef}
        display="flex"
        flexDirection="column"
        overflow="auto"
        flexGrow={1}
        flexShrink={1}
      >
        <ChannelMessages
          channel={channel}
          hasPreviousPage={channel.messages.pageInfo.hasNextPage ?? false}
          onLoadMore={loadPreviousMessages}
          loadPreviousMessagesLabel={loadPreviousMessagesLabel}
          noMessagesTitle={noMessagesTitle}
          layout={layout}
        />
      </Box>
      <ChannelForm
        channelId={channelId}
        onMessageSent={async () => {
          await checkForNextMessages();
          scrollContainerRef.current?.scroll({
            top: scrollContainerRef.current?.scrollHeight,
            behavior: "auto",
          });
        }}
        inputPlaceholder={inputPlaceholder}
      />
    </Box>
  );
};
