/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseAvailabilityFilter_MembersQueryVariables = Types.Exact<{
  rangeStart?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  rangeEnd?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  excludeProjectIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterFromProjectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterWithinRadiusKm?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type UseAvailabilityFilter_MembersQuery = { availableMembers: Array<{ __typename: 'User', id: string }> };


export const UseAvailabilityFilter_MembersDocument = gql`
    query UseAvailabilityFilter_Members($rangeStart: DateTime, $rangeEnd: DateTime, $excludeProjectIds: [ID!], $filterFromProjectId: ID, $filterWithinRadiusKm: Float) {
  availableMembers: organisationMembersAvailability(
    filters: {active: true, rangeStart: $rangeStart, rangeEnd: $rangeEnd, excludeProjectIds: $excludeProjectIds, fromProjectId: $filterFromProjectId, withinRadiusKm: $filterWithinRadiusKm}
  ) {
    id
  }
}
    `;

/**
 * __useUseAvailabilityFilter_MembersQuery__
 *
 * To run a query within a React component, call `useUseAvailabilityFilter_MembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseAvailabilityFilter_MembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseAvailabilityFilter_MembersQuery({
 *   variables: {
 *      rangeStart: // value for 'rangeStart'
 *      rangeEnd: // value for 'rangeEnd'
 *      excludeProjectIds: // value for 'excludeProjectIds'
 *      filterFromProjectId: // value for 'filterFromProjectId'
 *      filterWithinRadiusKm: // value for 'filterWithinRadiusKm'
 *   },
 * });
 */
export function useUseAvailabilityFilter_MembersQuery(baseOptions?: Apollo.QueryHookOptions<UseAvailabilityFilter_MembersQuery, UseAvailabilityFilter_MembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseAvailabilityFilter_MembersQuery, UseAvailabilityFilter_MembersQueryVariables>(UseAvailabilityFilter_MembersDocument, options);
      }
export function useUseAvailabilityFilter_MembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseAvailabilityFilter_MembersQuery, UseAvailabilityFilter_MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseAvailabilityFilter_MembersQuery, UseAvailabilityFilter_MembersQueryVariables>(UseAvailabilityFilter_MembersDocument, options);
        }
export function useUseAvailabilityFilter_MembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseAvailabilityFilter_MembersQuery, UseAvailabilityFilter_MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseAvailabilityFilter_MembersQuery, UseAvailabilityFilter_MembersQueryVariables>(UseAvailabilityFilter_MembersDocument, options);
        }
export type UseAvailabilityFilter_MembersQueryHookResult = ReturnType<typeof useUseAvailabilityFilter_MembersQuery>;
export type UseAvailabilityFilter_MembersLazyQueryHookResult = ReturnType<typeof useUseAvailabilityFilter_MembersLazyQuery>;
export type UseAvailabilityFilter_MembersSuspenseQueryHookResult = ReturnType<typeof useUseAvailabilityFilter_MembersSuspenseQuery>;
export type UseAvailabilityFilter_MembersQueryResult = Apollo.QueryResult<UseAvailabilityFilter_MembersQuery, UseAvailabilityFilter_MembersQueryVariables>;