import { gql } from "@apollo/client";
import {
  getFormattedDate,
  getFormattedPercentage,
  getFormattedPrice,
  LabeledValueHorizontal,
  TableColumn,
  TableInner,
  underlineDoubleStyle,
  useScreenWidth,
} from "@msys/ui";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import React from "react";
import { InvoiceReferenceFragment } from "./InvoiceFinalTotals.generated";
import { useTranslate } from "@tolgee/react";
import { InvoiceCalculationFragment } from "../Fragments.generated";

const priceCellWidth = 105;
const vatCellWidth = 100;
const dateCellWidth = 90;
const numberCellWidth = 90;

const labelWidth = 60;

const tableMinWidth = "760px";

const cellStyle = { verticalAlign: "top" };

interface Props {
  invoiceId: string;
  calculation: InvoiceCalculationFragment;
  invoiceReferences: InvoiceReferenceFragment[];
}

export const InvoiceFinalTotals = ({
  invoiceId,
  calculation,
  invoiceReferences,
}: Props) => {
  const { isMaxPhone } = useScreenWidth();
  const { t } = useTranslate(["Invoices"]);
  return (
    <Paper sx={{ overflowX: "auto" }}>
      <Stack
        direction="column"
        p={2}
        spacing={2}
        minWidth={isMaxPhone ? undefined : tableMinWidth}
      >
        <Stack direction="column" spacing={0.5}>
          <Typography variant="h1" component="div">
            {t("Final invoice totals calculation", {
              ns: "Invoices",
            })}
          </Typography>
          <TotalsCalculation invoiceId={invoiceId} calculation={calculation} />
        </Stack>

        {invoiceReferences.length > 0 && (
          <Stack direction="column" spacing={0.5}>
            <Typography variant="h3" component="div">
              {t("Minus already issued invoices", {
                ns: "Invoices",
              })}
            </Typography>
            <InvoiceReferencesCalculation
              invoiceReferences={invoiceReferences}
              calculation={calculation}
            />
          </Stack>
        )}

        <Stack direction="column" spacing={0.5}>
          {isMaxPhone && (
            <Typography variant="h3" component="div">
              {t("Final bill amount", {
                ns: "Invoices",
              })}
            </Typography>
          )}
          <FinalCalculation invoiceId={invoiceId} calculation={calculation} />
        </Stack>

        <Stack direction="column" spacing={0.5}>
          {isMaxPhone && (
            <Typography variant="h1" component="div">
              {t("Open amount to be paid", {
                ns: "Invoices",
              })}
            </Typography>
          )}
          <OpenCalculation invoiceId={invoiceId} calculation={calculation} />
        </Stack>
      </Stack>
    </Paper>
  );
};

function TotalsCalculation({
  invoiceId,
  calculation,
}: {
  invoiceId: string;
  calculation: InvoiceCalculationFragment;
}) {
  const { t } = useTranslate(["Invoices"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const { isMaxPhone } = useScreenWidth();

  const columnConfig: TableColumn<InvoiceCalculationFragment>[] = isMaxPhone
    ? [
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation =>
            getFormattedPrice(calculation.priceNetTotal, language),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",
            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: calculation =>
            getFormattedPrice(calculation.priceVatTotal, language),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation =>
            getFormattedPrice(calculation.priceTotal, language),
        },
      ]
    : [
        {
          id: "title",
          title: t("Total amount", {
            ns: "Invoices",
          }),
          render: calculation => <></>,
        },
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation =>
            getFormattedPrice(calculation.priceNetTotal, language),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",

            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: calculation =>
            getFormattedPrice(calculation.priceVatTotal, language),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation =>
            getFormattedPrice(calculation.priceTotal, language),
        },
        {
          cellStyle,
          id: "paidPrice",
          title: "",
          width: priceCellWidth,
          render: calculation => <></>,
        },
      ];

  return (
    <TableInner
      size="extra-small"
      columns={columnConfig}
      items={[{ id: invoiceId, ...calculation }]}
    />
  );
}

function InvoiceReferencesCalculation({
  calculation,
  invoiceReferences,
}: {
  invoiceReferences: InvoiceReferenceFragment[];
  calculation: InvoiceCalculationFragment;
}) {
  const { t } = useTranslate(["Invoices", "Global"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const { isMaxPhone } = useScreenWidth();

  const columnConfig: TableColumn<InvoiceReferenceFragment>[] = isMaxPhone
    ? [
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: reference =>
            getFormattedPrice(
              reference.invoiceCalculation.priceNetTotal,
              language
            ),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",

            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: reference =>
            getFormattedPrice(
              reference.invoiceCalculation.priceVatTotal,
              language
            ),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: reference =>
            getFormattedPrice(
              reference.invoiceCalculation.priceTotal,
              language
            ),
        },
      ]
    : [
        {
          cellStyle,
          id: "title",
          title: t("Invoice title", {
            ns: "Invoices",
          }),
          render: reference => reference.invoiceTitle,
        },
        {
          cellStyle,
          id: "number",
          width: numberCellWidth,
          title: t("No.", {
            ns: "Global",
          }),
          render: reference => reference.invoiceNumber,
        },
        {
          cellStyle,
          id: "openedAt",
          width: dateCellWidth,
          title: t("Date", {
            ns: "Global",
          }),
          render: reference =>
            getFormattedDate(reference.invoiceOpenedAt, language),
        },
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: reference =>
            getFormattedPrice(
              reference.invoiceCalculation.priceNetTotal,
              language
            ),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",

            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: reference =>
            getFormattedPrice(
              reference.invoiceCalculation.priceVatTotal,
              language
            ),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: reference =>
            getFormattedPrice(
              reference.invoiceCalculation.priceTotal,
              language
            ),
        },
        {
          cellStyle,
          id: "paidPrice",
          title: t("Paid", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: reference =>
            reference.isPaid
              ? getFormattedPrice(
                  reference.invoiceCalculation.priceTotal,
                  language
                )
              : getFormattedPrice(0, language),
        },
      ];

  if (isMaxPhone) {
    return (
      <Stack width="100%" direction="column" spacing={2}>
        {invoiceReferences.map((reference, index) => (
          <React.Fragment key={reference.id}>
            {index > 0 ? <Divider /> : null}
            <Stack width="100%" direction="column" spacing={1}>
              <Typography>{reference.invoiceTitle}</Typography>
              <LabeledValueHorizontal
                label={t("No.", {
                  ns: "Global",
                })}
                labelWidth={labelWidth}
              >
                {reference.invoiceNumber}
              </LabeledValueHorizontal>
              <LabeledValueHorizontal
                label={t("Date", {
                  ns: "Global",
                })}
                labelWidth={labelWidth}
              >
                {getFormattedDate(reference.invoiceOpenedAt, language)}
              </LabeledValueHorizontal>
              <LabeledValueHorizontal
                label={t("Paid", {
                  ns: "Invoices",
                })}
                labelWidth={labelWidth}
              >
                {reference.isPaid
                  ? getFormattedPrice(
                      reference.invoiceCalculation.priceTotal,
                      language
                    )
                  : getFormattedPrice(0, language)}
              </LabeledValueHorizontal>
              <TableInner
                size="extra-small"
                columns={columnConfig}
                items={[reference]}
              />
            </Stack>
          </React.Fragment>
        ))}
      </Stack>
    );
  }

  return (
    <TableInner
      size="extra-small"
      columns={columnConfig}
      items={invoiceReferences}
    />
  );
}

function FinalCalculation({
  invoiceId,
  calculation,
}: {
  invoiceId: string;
  calculation: InvoiceCalculationFragment;
}) {
  const { t } = useTranslate(["Invoices"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const { isMaxPhone } = useScreenWidth();

  const columnConfig: TableColumn<InvoiceCalculationFragment>[] = isMaxPhone
    ? [
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body2">
              {getFormattedPrice(calculation.finalPriceNetTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",

            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body2">
              {getFormattedPrice(calculation.finalPriceVatTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body2">
              {getFormattedPrice(calculation.finalPriceTotal, language)}
            </Typography>
          ),
        },
      ]
    : [
        {
          id: "title",
          title: (
            <Typography variant="h3" component="div">
              {t("Final bill amount", {
                ns: "Invoices",
              })}
            </Typography>
          ),
          render: calculation => <></>,
        },
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body2">
              {getFormattedPrice(calculation.finalPriceNetTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",

            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body2">
              {getFormattedPrice(calculation.finalPriceVatTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body2">
              {getFormattedPrice(calculation.finalPriceTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "paidPrice",
          title: "",
          width: priceCellWidth,
          render: calculation => <></>,
        },
      ];

  return (
    <TableInner
      size="extra-small"
      columns={columnConfig}
      items={[{ id: invoiceId, ...calculation }]}
    />
  );
}

function OpenCalculation({
  invoiceId,
  calculation,
}: {
  invoiceId: string;
  calculation: InvoiceCalculationFragment;
}) {
  const { t } = useTranslate(["Invoices"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const { isMaxPhone } = useScreenWidth();

  const columnConfig: TableColumn<InvoiceCalculationFragment>[] = isMaxPhone
    ? [
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography
              component="span"
              fontWeight="bold"
              variant="body1"
              style={{ textDecoration: "underline" }}
            >
              {getFormattedPrice(calculation.openPriceNetTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",

            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body1">
              {getFormattedPrice(calculation.openPriceVatTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography
              component="span"
              fontWeight="bold"
              variant="body1"
              style={underlineDoubleStyle}
            >
              {getFormattedPrice(calculation.openPriceTotal, language)}
            </Typography>
          ),
        },
      ]
    : [
        {
          id: "title",
          title: (
            <Typography variant="h1" component="div">
              {t("Open amount to be paid", {
                ns: "Invoices",
              })}
            </Typography>
          ),
          render: calculation => <></>,
        },
        {
          cellStyle,
          id: "netPrice",
          title: t("Net", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography
              component="span"
              fontWeight="bold"
              variant="body1"
              style={{ textDecoration: "underline" }}
            >
              {getFormattedPrice(calculation.openPriceNetTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "vatPrice",
          title: t("VAT ({percentage})", {
            ns: "Invoices",

            percentage:
              getFormattedPercentage(calculation.vatRate, language) ?? "",
          }),
          width: vatCellWidth,
          render: calculation => (
            <Typography component="span" fontWeight="bold" variant="body1">
              {getFormattedPrice(calculation.openPriceVatTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "grossPrice",
          title: t("Gross", {
            ns: "Invoices",
          }),
          width: priceCellWidth,
          render: calculation => (
            <Typography
              component="span"
              fontWeight="bold"
              variant="body1"
              style={underlineDoubleStyle}
            >
              {getFormattedPrice(calculation.openPriceTotal, language)}
            </Typography>
          ),
        },
        {
          cellStyle,
          id: "paidPrice",
          title: "",
          width: priceCellWidth,
          render: calculation => <></>,
        },
      ];

  return (
    <TableInner
      size="extra-small"
      columns={columnConfig}
      items={[{ id: invoiceId, ...calculation }]}
    />
  );
}
