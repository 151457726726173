import { MenuButton, MenuItemWithIcon, useScreenWidth } from "@msys/ui";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";

export enum EditorView {
  List = "list",
  Map = "map",
  Calendar = "calendar",
}

interface Props {
  value: EditorView;
  setValue: React.Dispatch<React.SetStateAction<EditorView>>;
}

export const EditorViewSwitch = ({ value, setValue }: Props) => {
  const theme = useTheme();
  const isMinSm = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm),
    { noSsr: true }
  );
  const { t } = useTranslate(["PlanningModal"]);
  const views: {
    icon: React.ReactElement;
    label: string;
    value: EditorView;
  }[] = [
    {
      icon: <EditIcon />,
      label: t("List", { ns: "PlanningModal" }),
      value: EditorView.List,
    },
    {
      icon: <MapIcon />,
      label: t("Map", { ns: "PlanningModal" }),
      value: EditorView.Map,
    },
    {
      icon: <CalendarTodayIcon />,
      label: t("Calendar", { ns: "PlanningModal" }),
      value: EditorView.Calendar,
    },
  ];
  const selectedView = views.find(v => v.value === value);
  if (!isMinSm) {
    return (
      <MenuButton Icon={selectedView ? selectedView.icon : <MoreHorizIcon />}>
        {views.map(v => (
          <MenuItemWithIcon
            key={`${v.value}-editor-view-item`}
            icon={value === v.value ? <CheckIcon /> : undefined}
            inset={value !== v.value}
            onClick={() => {
              if (value !== v.value) {
                setValue(v.value);
              }
            }}
          >
            {v.label}
          </MenuItemWithIcon>
        ))}
      </MenuButton>
    );
  }
  return (
    <ToggleButtonGroup
      exclusive
      size="extra-small"
      value={value}
      onChange={(e, value) => setValue(value as EditorView)}
    >
      {views.map(v => (
        <ToggleButton value={v.value} key={v.value}>
          <Tooltip title={v.label}>{v.icon}</Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
