/* eslint-disable jsx-a11y/anchor-has-content */
import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  ErrorMessage,
  MenuButton,
  MenuItemWithIcon,
  useScreenWidth,
} from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { flatten, values } from "lodash";
import { useSnackbar } from "notistack";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import {
  Agreement,
  AttachmentInput,
  ItemType,
  namedOperations,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { useFeature } from "../../../common/FeatureFlags";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../auth/useUserData";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageHeader } from "../../commons/layout/PageHeader";
import { Stack } from "../../commons/layout/Stack";
import { isTreePreviewItemVisible } from "../../features/doc-items/helpers";
import {
  CreateItemsVariables,
  useCreateItem,
} from "../../features/doc-items/hooks/useCreateItem";
import { TasksTimesheetBox } from "../../features/tasks/boxes/TasksTimesheetBox";
import {
  shouldRenderItem,
  shouldRenderTaskCreateInput,
} from "../../features/tasks/helpers";
import { DoneModalRef } from "../../features/tasks/modals/DoneModal";
import {
  PhotoApprovalProcess,
  PhotoApprovalProcessRef,
} from "../../features/tasks/PhotoApprovalProcess";
import { usePhotoApproveTaskItemMutation } from "../../features/tasks/Tasks.generated";
import { useTasksQuickFilters } from "../../features/tasks/TasksQuickFilters";
import {
  createTreeItem as createTreeTasksItem,
  createTreeItemInput as createTreeTasksItemInput,
  createTreeRootItem as createTreeTasksRootItem,
} from "../../features/tasks/trees";
import { AddTimeReportButton } from "../../features/time-reports/buttons/AddTimeReportButton";
import { TimesheetIcon } from "../../features/time-reports/TimesheetIcon";
import { TodosBox } from "../../features/todos/TodosBox";
import {
  AddTaskWorkSessionProcess,
  AddTaskWorkSessionProcessRef,
} from "../../features/work-sessions/AddTaskWorkSessionProcess";
import {
  AddTaskWorkSessionsProcess,
  AddTaskWorkSessionsProcessRef,
} from "../../features/work-sessions/AddTaskWorkSessionsProcess";
import { useFixedHeight } from "../../global/ScreenFeatureProvider";
import { ROOT_ITEM_ID } from "../../trees-virtual/helpers";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton";
import { isOrphanedItem } from "../../trees/helpers";
import {
  ProjectTasks_ItemFragment,
  useProjectTasksQuery,
  useUseCreateTasksItems_CreateItemsDefectMutation,
  useUseCreateTasksItems_CreateItemsMutation,
  useUseCreateTasksItems_CreateItemsUnpaidMutation,
} from "./ProjectTasks.generated";

const DoneModal = React.lazy(
  () => import("../../features/tasks/modals/DoneModal")
);

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export function ProjectTasks({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) {
  const pathToDoc = `/projects/${projectId}/tasks`;
  const pathToDocPage = `${pathToDoc}/edit`;
  const matchDoc = useMatch(`${pathToDocPage}/:docId`);
  const matchItem = useMatch(`${pathToDocPage}/:docId/items/:itemId`);
  const itemId = matchItem?.params?.itemId ?? null;
  const docId = matchItem?.params?.docId ?? matchDoc?.params?.docId;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;

  const { isMinTablet } = useScreenWidth();

  const documentItemTypes: ItemType[] = ["unpaid", "defect"];

  const photoApprovalRef = useRef<PhotoApprovalProcessRef>(null);
  const checkPhotoApprove = useCallback(
    (itemId: string, docId: string, checkChildren: boolean) => {
      photoApprovalRef.current?.checkPhotoApprove(itemId, docId, checkChildren);
    },
    []
  );

  const addTaskWorkSessionRef = useRef<AddTaskWorkSessionProcessRef>(null);
  const addTaskWorkSessionsRef = useRef<AddTaskWorkSessionsProcessRef>(null);
  const addWorkSession = useCallback(
    (itemId: string, projectId: string, docId: string) => {
      addTaskWorkSessionRef.current?.addWorkSession(itemId, projectId, docId);
    },
    []
  );

  useFixedHeight();

  const [treeContainer, setTreeContainer] = useState<HTMLDivElement | null>(
    null
  );

  const tasksTreeBox = useRef<HTMLDivElement>(null);
  const timesheetBox = useRef<HTMLDivElement>(null);

  const { t } = useTranslate([
    "ActivitiesRouter",
    "Global",
    "QuoteItemTaskManagementBox",
  ]);
  const location = useLocation();

  const [TasksQuickFiltersTabs, tasksQuickFilter] = useTasksQuickFilters<
    ProjectTasks_ItemFragment & {
      docAgreement: Agreement;
      docViewerPermissions: PermissionName[];
    }
  >();

  const breadcrumbs = useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Tasks", {
          ns: "ActivitiesRouter",
        }),
        to: `/projects/${projectId}/tasks`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const expandedStore = useExpandedStoreWithLocalStorage(
    "tasks",
    projectId,
    itemId
  );

  const client = useApolloClient();
  const query = useProjectTasksQuery({
    client,
    variables: {
      projectId,
    },
  });

  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);
  const project = getDataOrNull(query.data?.project)?.project;
  const tasks = project?.tasks;

  const navigateToItem = React.useCallback(
    (itemId: string) => {
      const document = tasks?.find(document =>
        document.items.some(item => item.id === itemId)
      );
      if (!document) throw new Error("Task document not found");
      navigate(`${pathToDocPage}/${document.id}/items/${itemId}`, {
        replace: true,
      });
    },
    [navigate, pathToDocPage, tasks]
  );

  const areAnimationsEnabled = useFeature("Animations");
  const isClientVisibilityEnabled = useFeature("ClientVisibility");

  const selectedItemId = useMemo(() => {
    if (itemId) return itemId;
    if (docId) {
      const tasksDoc = tasks?.find(task => task.id === docId);
      if (tasksDoc) {
        const rootItem = tasksDoc.items.find(item => item.isRootItem);
        if (rootItem) return rootItem.id;
      }
    }
  }, [tasks, docId, itemId]);

  const [allTasksItems, filteredTasksItems, allItems] = useMemo(() => {
    const allItems: {
      [taskDocId: string]: (ProjectTasks_ItemFragment & {
        docAgreement: Agreement;
        docViewerPermissions: PermissionName[];
      })[];
    } = tasks
      ? tasks.reduce(
          (acc, tasksDoc) => {
            const doc = {
              agreement: tasksDoc.agreement,
              needsAgreement: tasksDoc.needsAgreement,
              isPublished: tasksDoc.isPublished,
              isBinding: tasksDoc.isBinding,
              contractor: tasksDoc.contractor,
            };
            const taskDocItems = tasksDoc.items.map(item => ({
              ...item,
              docAgreement: tasksDoc.agreement,
              docViewerPermissions: tasksDoc.viewerPermissions,
              doc,
            }));
            acc[tasksDoc.id] = taskDocItems
              .filter((item, _, allItems) => shouldRenderItem(item, allItems))
              .filter(
                (i, idx, arr) =>
                  tasksDoc.contractor.isMyOrganisation ||
                  isTreePreviewItemVisible(false, i, arr, doc.isBinding, false)
              )
              .filter((i, idx, arr) => !isOrphanedItem(i, arr))
              .map(item => {
                if (!item.isRootItem) return item;
                return { ...item, parentId: ROOT_ITEM_ID };
              });
            return acc;
          },
          {} as {
            [taskDocId: string]: (ProjectTasks_ItemFragment & {
              docAgreement: Agreement;
              docViewerPermissions: PermissionName[];
            })[];
          }
        )
      : {};

    const items = flatten(values(allItems));

    const filteredItems = tasksQuickFilter(items);

    return [items, filteredItems, allItems];
  }, [tasks, tasksQuickFilter]);

  const selectedItem = React.useMemo(
    () =>
      selectedItemId
        ? allTasksItems.find(i => i.id === selectedItemId) ?? null
        : null,
    [selectedItemId, allTasksItems]
  );

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    selectedItem ? allItems[selectedItem.docId] ?? [] : [],
    selectedItem?.id ?? null,
    !query.loading && !query.error
  );

  useEffect(() => {
    if (
      !query.loading &&
      selectedItemId &&
      !filteredTasksItems.some(item => item.id === selectedItemId)
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    pathToDocPage,
    filteredTasksItems,
    query.loading,
    navigate,
  ]);

  const viewerPermissions: PermissionName[] = useMemo(
    () => project?.viewerPermissions ?? [],
    [project?.viewerPermissions]
  );

  const doneRef = useRef<DoneModalRef>(null);

  const onBeforeTaskDone = useCallback(
    async (
      itemId: string,
      docId: string,
      projectId: string,
      newIsDone: boolean
    ) => {
      if (newIsDone) {
        if (organisationDefaults?.defaultTaskPhotoIsRequired) {
          const areAllPhotosAdded: boolean =
            (await photoApprovalRef.current?.checkPhotoApprove(
              itemId,
              docId,
              true
            )) ?? true;
          if (!areAllPhotosAdded) {
            enqueueSnackbar(
              t("Please upload all photos to mark task as completed", {
                ns: "QuoteItemTaskManagementBox",
              }),
              { variant: "error" }
            );
            return false;
          }
        }
      }
      return true;
    },
    [organisationDefaults?.defaultTaskPhotoIsRequired, enqueueSnackbar, t]
  );

  const onAfterTaskDone = useCallback(
    async (
      itemId: string,
      docId: string,
      projectId: string,
      newIsDone: boolean
    ) => {
      if (newIsDone) {
        await doneRef.current?.open();
        if (!organisationDefaults?.defaultTaskPhotoIsRequired) {
          await photoApprovalRef.current?.checkPhotoApprove(
            itemId,
            docId,
            true
          );
        }
        await addTaskWorkSessionsRef.current?.addWorkSessions(
          itemId,
          docId,
          projectId
        );
      }
    },
    [organisationDefaults?.defaultTaskPhotoIsRequired]
  );

  const TreeTasksItem = React.useMemo(
    () =>
      createTreeTasksItem({
        pathToDocPage,
        projectId,
        onBeforeTaskDone,
        onAfterTaskDone,
        checkPhotoApprove,
        addWorkSession,
        navigateToItem,
        setItemExpanded: expandedStore.setItemExpanded,
      }),
    [
      pathToDocPage,
      projectId,
      onBeforeTaskDone,
      onAfterTaskDone,
      checkPhotoApprove,
      addWorkSession,
      navigateToItem,
      expandedStore.setItemExpanded,
    ]
  );

  const TreeTasksRootItem = React.useMemo(
    () =>
      tasks
        ? createTreeTasksRootItem({
            projectId,
            tasks,
            checkPhotoApprove,
            addWorkSession,
            navigateToItem,
            setItemExpanded: expandedStore.setItemExpanded,
          })
        : undefined,
    [
      tasks,
      projectId,
      checkPhotoApprove,
      addWorkSession,
      navigateToItem,
      expandedStore.setItemExpanded,
    ]
  );

  const { createItem, createItemByType } = useCreateTasksItems({
    projectId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const TreeTasksItemInput = React.useMemo(
    () =>
      createTreeTasksItemInput({
        createItem,
        createItemByType,
      }),
    [createItem, createItemByType]
  );

  const [
    photoApproveTaskItemMutation,
    { loading: photoApproveTaskItemLoading },
  ] = usePhotoApproveTaskItemMutation({ client });

  const photoApproveTaskItem = async (
    itemId: string,
    docId: string,
    photo: AttachmentInput
  ) => {
    try {
      await photoApproveTaskItemMutation({
        variables: { input: { projectId, docId, itemId, photo } },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (!query.loading && !query.error) {
      setTimeout(() => {
        if (location.hash === "#list") {
          tasksTreeBox.current?.scrollIntoView();
        } else if (location.hash === "#timesheet") {
          timesheetBox.current?.scrollIntoView();
        }
      });
    }
  }, [location.hash, query.error, query.loading]);

  const filterItem = useCallback(
    (i: ProjectTasks_ItemFragment) => {
      if (!tasks) return false;
      const task = tasks.find(task => task.id === i.docId);
      if (!task) return false;

      const viewerIsContractor = task.contractor.isMyOrganisation;
      if (!viewerIsContractor) return true;

      return isTreePreviewItemVisible(
        viewerIsContractor,
        i,
        allItems[i.docId],
        task.isBinding,
        false
      );
    },
    [allItems, tasks]
  );

  return (
    <Page
      subtitle={project?.title}
      title={t("Tasks", {
        ns: "ActivitiesRouter",
      })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      {project && (
        <PageColumn
          $hasRightBorder
          $hasBackground
          $display="block"
          ref={isMinTablet ? setTreeContainer : undefined}
        >
          <Stack flexDirection={"column"} spacing={2}>
            {tasks?.length ? (
              <>
                <Stack
                  width="100%"
                  spacing={1 / 2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack spacing={1 / 2} alignItems="center">
                    <TreeToggleAllExpandedButton
                      areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                      setAllItemsExpanded={expandedStore.setAllItemsExpanded}
                    />
                    <TasksQuickFiltersTabs />
                  </Stack>
                  <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
                    <MenuButton>
                      <RestrictedByProjectPermissionWithDebug
                        permission="MANAGE_QUOTES"
                        project={project}
                      >
                        <AddTimeReportButton
                          projectId={projectId}
                          type="menu-item"
                        />
                      </RestrictedByProjectPermissionWithDebug>
                      <RestrictedByProjectPermissionWithDebug
                        permission="READ_QUOTES"
                        project={project}
                      >
                        <MenuItemWithIcon
                          component={Link}
                          icon={<TimesheetIcon />}
                          to={`/projects/${projectId}/tasks/reports`}
                        >
                          {t("View all time reports", {
                            ns: "ActivitiesRouter",
                          })}
                        </MenuItemWithIcon>
                      </RestrictedByProjectPermissionWithDebug>
                    </MenuButton>
                  </RestrictedByCapabilityWithDebug>
                </Stack>
                {/* @ts-ignore */}
                <Box ref={tasksTreeBox}>
                  {filteredTasksItems.length > 0 ? (
                    <VirtualItemTree<
                      ProjectTasks_ItemFragment & {
                        docAgreement: Agreement;
                        docViewerPermissions: PermissionName[];
                      },
                      false
                    >
                      docId={null}
                      projectId={null}
                      rootItemId={ROOT_ITEM_ID}
                      items={filteredTasksItems}
                      allItems={allTasksItems}
                      filterItem={
                        isClientVisibilityEnabled ? filterItem : undefined
                      }
                      selectedItemId={selectedItemId ?? null}
                      shouldRenderCreateInput={shouldRenderTaskCreateInput}
                      enableCreating={true}
                      enableDragging={false}
                      documentItemTypes={documentItemTypes}
                      container={treeContainer}
                      rootItemComponent={TreeTasksRootItem}
                      itemComponent={TreeTasksItem}
                      inputComponent={TreeTasksItemInput}
                      expandedStore={expandedStore}
                    />
                  ) : (
                    <Box mt={2}>
                      <ErrorMessage
                        message={t("No tasks for selected filter", {
                          ns: "ActivitiesRouter",
                        })}
                      />
                    </Box>
                  )}
                </Box>
              </>
            ) : null}

            <TodosBox linkedProject={project} />

            <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
              <div ref={timesheetBox}>
                <RestrictedByProjectPermissionWithDebug
                  permission="EXECUTE_TASK"
                  project={project}
                >
                  <TasksTimesheetBox
                    selectedItemId={selectedItemId}
                    projectId={projectId}
                    tasksItems={allTasksItems}
                  />
                </RestrictedByProjectPermissionWithDebug>
              </div>
            </RestrictedByCapabilityWithDebug>
          </Stack>

          <Suspense fallback={null}>
            <DoneModal ref={doneRef} enabled={areAnimationsEnabled} />
          </Suspense>

          <RestrictedByProjectPermissionWithDebug
            permission="EXECUTE_TASK"
            project={project}
          >
            <PhotoApprovalProcess
              ref={photoApprovalRef}
              loading={photoApproveTaskItemLoading}
              photoApproveTaskItem={photoApproveTaskItem}
              projectId={project.id}
            />
          </RestrictedByProjectPermissionWithDebug>

          <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
            <RestrictedByProjectPermissionWithDebug
              permission="EXECUTE_TASK"
              project={project}
            >
              <AddTaskWorkSessionProcess ref={addTaskWorkSessionRef} />
            </RestrictedByProjectPermissionWithDebug>
          </RestrictedByCapabilityWithDebug>

          <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
            <RestrictedByProjectPermissionWithDebug
              permission="EXECUTE_TASK"
              project={project}
            >
              <AddTaskWorkSessionsProcess ref={addTaskWorkSessionsRef} />
            </RestrictedByProjectPermissionWithDebug>
          </RestrictedByCapabilityWithDebug>
        </PageColumn>
      )}
      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeTasksItemInput,
        }}
      />
    </Page>
  );
}

function useCreateTasksItems({
  projectId,
  expandedItemIds,
}: {
  projectId: string;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  // allowed for craftsmen managers
  const [createItems] = useUseCreateTasksItems_CreateItemsMutation({
    client,
    refetchQueries: [namedOperations.Query.ProjectTasks], // need to refetch here since createItems doesn't return project.tasks ([TaskDocument!])
    awaitRefetchQueries: true,
  });
  // allowed for clients/installers
  const [createItemsDefect] = useUseCreateTasksItems_CreateItemsDefectMutation({
    client,
    refetchQueries: [namedOperations.Query.ProjectTasks], // need to refetch here since createItems doesn't return project.tasks ([TaskDocument!])
    awaitRefetchQueries: true,
  });
  // allowed for installers
  const [createItemsUnpaid] = useUseCreateTasksItems_CreateItemsUnpaidMutation({
    client,
    refetchQueries: [namedOperations.Query.ProjectTasks], // need to refetch here since createItems doesn't return project.tasks ([TaskDocument!])
    awaitRefetchQueries: true,
  });

  const { createItem, createItemByType } = useCreateItem({
    projectId,
    createDocumentItems: async (args: { variables: CreateItemsVariables }) => {
      if (args.variables.input.items.every(i => i.type === "defect")) {
        const r = await createItemsDefect(args);
        return r.data?.createItemsDefect
          ? { data: { createItems: r.data.createItemsDefect } }
          : { data: null };
      }
      if (args.variables.input.items.every(i => i.type === "unpaid")) {
        const r = await createItemsUnpaid(args);
        return r.data?.createItemsUnpaid
          ? { data: { createItems: r.data.createItemsUnpaid } }
          : { data: null };
      }
      return await createItems(args);
    },
    expandedItemIds,
  });

  return { createItem, createItemByType };
}
