/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import { DocumentStatus_DocumentFragmentDoc, ProposedCalculations_ItemFragmentDoc } from '../hooks/useDecisionItemMutations.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemFilesBox_ItemFragment = { __typename: 'Item', id: string, authorOrganisationId: string, attachments: Array<{ __typename: 'ItemAttachment', id: string, title: string, mimeType: string, url: string, group: string, clientVisibility: boolean, isProductAttachment: boolean }> };

export type SetItemAttachmentClientVisbilityMutationVariables = Types.Exact<{
  input: Types.SetItemAttachmentClientVisibilityInput;
}>;


export type SetItemAttachmentClientVisbilityMutation = { setItemAttachmentClientVisibility: { __typename: 'SetItemAttachmentClientVisibilityResult', doc: { __typename: 'Building', id: string } | { __typename: 'Quote', id: string, isPublished: boolean, isBinding: boolean, needsAgreement: boolean, agreement: Types.Agreement } | { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, containsUnpublishedChanged: boolean } | { __typename: 'Requirement', id: string, isPublished: boolean }, item: { __typename: 'Item', id: string, originVersionNumber?: number | null, attachments: Array<{ __typename: 'ItemAttachment', id: string, title: string, mimeType: string, url: string, group: string, clientVisibility: boolean, isProductAttachment: boolean }> } } };

export const ItemFilesBox_ItemFragmentDoc = gql`
    fragment ItemFilesBox_Item on Item {
  id
  authorOrganisationId
  ...WithItemAttachments
}
    ${WithItemAttachmentsFragmentDoc}`;
export const SetItemAttachmentClientVisbilityDocument = gql`
    mutation SetItemAttachmentClientVisbility($input: SetItemAttachmentClientVisibilityInput!) {
  setItemAttachmentClientVisibility(input: $input) {
    doc {
      ... on WithDocumentInfo {
        id
      }
      ... on QuoteTemplate {
        resolvedAsReadModelVersionNumber
      }
      ...DocumentStatus_Document
    }
    item {
      id
      originVersionNumber
      ...WithItemAttachments
    }
  }
}
    ${DocumentStatus_DocumentFragmentDoc}
${WithItemAttachmentsFragmentDoc}`;
export type SetItemAttachmentClientVisbilityMutationFn = Apollo.MutationFunction<SetItemAttachmentClientVisbilityMutation, SetItemAttachmentClientVisbilityMutationVariables>;

/**
 * __useSetItemAttachmentClientVisbilityMutation__
 *
 * To run a mutation, you first call `useSetItemAttachmentClientVisbilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetItemAttachmentClientVisbilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setItemAttachmentClientVisbilityMutation, { data, loading, error }] = useSetItemAttachmentClientVisbilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetItemAttachmentClientVisbilityMutation(baseOptions?: Apollo.MutationHookOptions<SetItemAttachmentClientVisbilityMutation, SetItemAttachmentClientVisbilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetItemAttachmentClientVisbilityMutation, SetItemAttachmentClientVisbilityMutationVariables>(SetItemAttachmentClientVisbilityDocument, options);
      }
export type SetItemAttachmentClientVisbilityMutationHookResult = ReturnType<typeof useSetItemAttachmentClientVisbilityMutation>;
export type SetItemAttachmentClientVisbilityMutationResult = Apollo.MutationResult<SetItemAttachmentClientVisbilityMutation>;
export type SetItemAttachmentClientVisbilityMutationOptions = Apollo.BaseMutationOptions<SetItemAttachmentClientVisbilityMutation, SetItemAttachmentClientVisbilityMutationVariables>;