import { gql } from "@apollo/client";
import { LabeledValue } from "@msys/ui";
import { Stack } from "../../commons/layout/Stack";
import {
  UserInformationBox_UserFragment,
  UserInformationBox_ViewerFragment,
} from "./UserInformationBox.generated";
import { useTranslate } from "@tolgee/react";
import { LabeledAddressValue } from "../addresses/LabeledAddressValue";
import { LabeledPhoneValue } from "../phones/LabeledPhoneValue";

type User = UserInformationBox_UserFragment | UserInformationBox_ViewerFragment;

interface Props {
  user: User;
}

export const UserInformationBox = ({ user }: Props) => {
  const { t } = useTranslate(["UserProfile", "Global"]);

  return (
    <Stack flexDirection="column">
      <LabeledValue
        label={t("Job title", {
          ns: "UserProfile",
        })}
      >
        {user.jobTitle ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("Email", {
          ns: "UserProfile",
        })}
      >
        {user.email ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledAddressValue
        label={t("Home address", {
          ns: "UserProfile",
        })}
        address={user.homeAddress}
      />
      <LabeledPhoneValue
        label={t("Phone", {
          ns: "Global",
        })}
        phones={user.phones}
      />
    </Stack>
  );
};
