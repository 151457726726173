import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import {
  AttachmentSnapshot,
  EmailTemplateContext,
} from "../../../clients/graphqlTypes";
import {
  CrmSendEmailForm,
  type EmailContext,
  type FormValues,
  RecipientOption,
  type UrlAttachment,
  useCrmSendEmail,
} from "./CrmSendEmailForm";

interface Props {
  handleClose: () => void;
  handleComplete?: (
    handleClose: () => void,
    values: FormValues
  ) => void | Promise<void>;
  title?: string;
  recipient?: RecipientOption;
  recipientOptions: RecipientOption[];
  urlAttachments?: UrlAttachment[];
  context: EmailContext | null;
  isLoading?: boolean;
  getAttachmentsOnSubmit?: () => Promise<AttachmentSnapshot[]>;
  additionalFields?: React.ReactNode;
  emailTemplateContext: EmailTemplateContext;
  refetchQueries?: string[];
  projectId?: string;
}

export const CrmSendEmailModal = (props: Props) => {
  const { t } = useTranslate(["CrmOrganisations", "Global"]);

  const {
    handleClose,
    handleComplete,
    title,
    recipient,
    recipientOptions,
    urlAttachments,
    context,
    isLoading,
    getAttachmentsOnSubmit,
    additionalFields,
    emailTemplateContext,
    refetchQueries,
  } = props;

  const { initialValues, validationSchema, handleSendEmail, emailTemplates } =
    useCrmSendEmail({
      recipient,
      urlAttachments,
      context,
      getAttachmentsOnSubmit,
      emailTemplateContext,
      refetchQueries,
    });

  const handleSubmit = async (values: FormValues) => {
    if (!values.recipient || !values.recipient.crmCompanyId) return;

    // TODO error handling
    await handleSendEmail(values);

    if (handleComplete) {
      await handleComplete(handleClose, values);
    } else {
      handleClose();
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Modal
          id={"send-crm-mail-modal"}
          title={title ?? t("Send email", { ns: "CrmOrganisations" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Send", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
                disabled: !formikProps.isValid,
              },
            },
          ]}
          isLoading={isLoading}
          maxWidth="md"
        >
          <Stack direction="column" spacing={2}>
            <CrmSendEmailForm
              recipientOptions={recipientOptions}
              additionalFields={additionalFields}
              formId={formId}
              availableTemplates={emailTemplates}
              context={context}
            />
          </Stack>
        </Modal>
      )}
    </Formik>
  );
};
