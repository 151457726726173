/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type PdfTemplateSelectModal_OrganisationContractingPdfFragment = { __typename: 'OrganisationContractingPdf', id: string, title: string };

export const PdfTemplateSelectModal_OrganisationContractingPdfFragmentDoc = gql`
    fragment PdfTemplateSelectModal_OrganisationContractingPdf on OrganisationContractingPdf {
  id
  title
}
    `;