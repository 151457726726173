import { last, sortBy } from "lodash";
import {
  find,
  getDescendantCount,
  getTreeFromFlatData,
  TreeItem,
} from "react-sortable-tree";
import { color } from "../../common/MuiThemeProvider";
import { ItemType } from "../../clients/graphqlTypes";
import { getDefaultCreatableSubItemTypes } from "../features/doc-items/constraints";
import {
  getAllParentItemIds,
  getParentItem,
  isAnyParentMatching,
} from "../trees/helpers";
import { VirtualItem, VirtualTreeItem } from "./types";

export const LINE_COLOR = "#e0e0e0";
export const ARROW_COLOR = color.primary;
export const ROOT_ITEM_ID = "root-synthetic-id";

export const getTreeFromItems = <T extends VirtualItem>(
  items: T[],
  rootItem: T | undefined,
  selectedItem: T | null,
  filterItem: ((item: T) => boolean) | undefined,
  enableCreating: boolean,
  isItemExpanded: (itemId: string, defaultValue?: boolean) => boolean,
  documentItemTypes: ItemType[],
  shouldRenderCreateInput?: (item: T) => boolean
): TreeItem[] => {
  const selectItemParent =
    selectedItem &&
    selectedItem.parentId !== null &&
    selectedItem.parentId !== rootItem?.id
      ? getParentItem(selectedItem, items)
      : null;

  const selectedItemParentIds = (
    selectedItem ? getAllParentItemIds(selectedItem, items) : []
  ).reduce((acc, id, index) => {
    acc[id] = true;
    return acc;
  }, {} as { [key: string]: boolean });

  const canHaveCreateInput = (
    itemType: ItemType,
    documentItemTypes: ItemType[],
    itemHasLinkedTemplate: boolean
  ): boolean => {
    if (itemHasLinkedTemplate) return false;

    return (
      getDefaultCreatableSubItemTypes(itemType, documentItemTypes).length > 0
    );
  };

  const inputItems: VirtualTreeItem<T>[] = enableCreating
    ? [
        // input added to root item
        ...(rootItem &&
        !rootItem.deletedAt &&
        rootItem.id !== ROOT_ITEM_ID &&
        shouldRenderCreateInput?.(rootItem) !== false &&
        canHaveCreateInput(rootItem.type, documentItemTypes, false)
          ? [
              {
                id: `input-${rootItem.id}`,
                parentId: rootItem.id,
                type: null,
                expanded: false,
                selected: false,
                isInput: true,
                item: rootItem,
              },
            ]
          : []),
        // input added to selected item - able to create children
        ...(selectedItem &&
        !selectedItem.deletedAt &&
        selectedItem.id !== rootItem?.id &&
        shouldRenderCreateInput?.(selectedItem) !== false &&
        canHaveCreateInput(
          selectedItem.type,
          documentItemTypes,
          "linkedQuoteTemplate" in selectedItem &&
            Boolean(selectedItem.linkedQuoteTemplate)
        )
          ? [
              {
                id: `input-${selectedItem.id}`,
                parentId: selectedItem.id,
                type: null,
                expanded: false,
                selected: false,
                isInput: true,
                item: selectedItem,
              },
            ]
          : []),
        // input added to selected parent item - able to create siblings
        ...(selectItemParent &&
        !selectItemParent.deletedAt &&
        selectItemParent.id !== rootItem?.id &&
        shouldRenderCreateInput?.(selectItemParent) !== false &&
        canHaveCreateInput(
          selectItemParent.type,
          documentItemTypes,
          "linkedQuoteTemplate" in selectItemParent &&
            Boolean(selectItemParent.linkedQuoteTemplate)
        )
          ? [
              {
                id: `input-${selectItemParent.id}`,
                parentId: selectItemParent.id,
                type: null,
                expanded: false,
                selected: false,
                isInput: true,
                item: selectItemParent,
              },
            ]
          : []),
      ]
    : [];

  const treeFlatItems: VirtualTreeItem<T>[] = items
    .filter(item => {
      if (filterItem?.(item) === false) return false;
      return true;
    })
    .map(item => ({
      id: item.id,
      type: item.type,
      rank: item.rank,
      parentId: item.parentId ?? null,
      expanded: isItemExpanded(item.id),
      selected: selectedItem?.id === item.id,
      isInput: false,
      item,
    })) as VirtualTreeItem<T>[];

  return getTreeFromFlatData({
    flatData: sortBy(treeFlatItems, n => n.rank).concat(inputItems),
    getKey: item => item.id,
    getParentKey: item => item.parentId,
    rootKey: rootItem?.id,
  });
};

export const getLastSelectedNode = <T extends VirtualItem>(
  treeData: TreeItem[],
  items: T[],
  selectedItem: T | null
): [number | null, string | null, (string | number)[]] => {
  const selectedItemParentIds = (
    selectedItem ? getAllParentItemIds(selectedItem, items) : []
  ).reduce((acc, id, index) => {
    acc[id] = true;
    return acc;
  }, {} as { [key: string]: boolean });

  const { matches: matchesSelectedNodes } = find({
    getNodeKey: ({ node }) => node.id,
    treeData,
    searchMethod: ({ node }) =>
      node.id === selectedItem?.id || !!selectedItemParentIds[node.id],
    searchFocusOffset: 0,
    expandAllMatchPaths: false,
    expandFocusMatchPaths: false,
  });

  const selectedNodes = matchesSelectedNodes.filter(m => m.treeIndex !== null);
  const selectedNode = last(matchesSelectedNodes);
  const lastSelectedNode = last(selectedNodes);

  return [
    lastSelectedNode?.treeIndex ?? null,
    lastSelectedNode?.node.id ?? null,
    selectedNode?.path ?? [],
  ];
};

export const isNodePlacedAfterInput = (
  quoteItems: TreeItem[],
  node: TreeItem,
  nextPath: (string | number)[],
  prevPath: (string | number)[]
) => {
  const descendants = getDescendantCount({ node });

  const { matches: matchesInput } = find({
    getNodeKey: ({ node }) => node.id,
    treeData: quoteItems,
    searchMethod: ({ node }) => node.isInput,
    searchFocusOffset: 0,
    expandAllMatchPaths: false,
    expandFocusMatchPaths: false,
  });

  const isMovedDown =
    (prevPath[prevPath.length - 1] as number) <
    (nextPath[nextPath.length - 1] as number);

  const newTreeIndex = nextPath[nextPath.length - 1] as number;

  if (
    matchesInput.some(match => {
      if (match.path.length !== nextPath.length) {
        return false;
      }
      const inputIndex = match.treeIndex;
      if (isMovedDown) {
        return newTreeIndex + descendants === inputIndex;
      } else {
        return newTreeIndex - 1 === inputIndex;
      }
    })
  ) {
    return true;
  }

  return false;
};

export function getNonLeafChildrenIdsRecurively(node: TreeItem): string[] {
  if (node.children === undefined) return [];
  if (typeof node.children === "function") return []; // FIXME

  return [node.id, ...node.children.flatMap(getNonLeafChildrenIdsRecurively)];
}

export const getSyntheticRootItem = (
  //__typename: Exclude<DocItemTypename, "UnnestedQuoteTemplateItem">,
  __typename: "Item",
  syntheticRootItemId: string
): VirtualItem => {
  return {
    __typename,
    id: syntheticRootItemId,
    // docId: "",
    // title: "",
    // description: "",
    type: "section",
    // docType,
    parentId: null,
    deletedAt: null,
    isRootItem: true,
    rank: 0,
    level: 0,
    title: "",
    pathForPdf: "",
    pathSortable: "",
    decisionIsContingentItem: false,
    decisionContingentItemPreselection: null,
    decisionSubitemsPreselection: [],
    decisionBehaviorOfSubitems: "NONE",
    isItemEliminated: false,
    isExplicitHidden: false,
    childrenVisibility: "SHOW_CHILDREN",
    binding: "binding",
    // needsAgreement: false,
    // agreement: "NONE",
    isHidden: false,
    isPriceHidden: false,
    isParentDecisionPreselected: false,
    isParentDecisionNotPreselected: false,
    isParentContingencyNotPreselected: false,
    // pendingChangeAttributes: null,
    // approvalRequired: false,
    // timeTrackingRequired: false,
    // qaApprovalRequired: false,
    // photoApprovalRequired: false,
    // authorOrganisationId: "",
    // originDocId: "",
    // originItemId: "",
    hasChildren: false,
  };
};

export const getOutOfScreen = (
  index: number | null,
  itemHeight: number,
  scrollPosition: number,
  windowHeight: number
) => {
  if (index === null || !windowHeight) {
    return [false, false];
  }
  const aboveScreen = (index + 0.5) * itemHeight < scrollPosition;
  const belowScreen =
    (index + 0.5) * itemHeight > scrollPosition + windowHeight;
  return [aboveScreen, belowScreen];
};

export const isItemVisibleToOtherSide = (
  item: VirtualItem,
  allDocItems: VirtualItem[]
) => {
  return (
    !item.isExplicitHidden &&
    item.type !== "unpaid" &&
    item.binding !== "draft" &&
    !isAnyParentMatching(item, allDocItems, parentItem => {
      return (
        parentItem.isExplicitHidden ||
        parentItem.childrenVisibility === "HIDE_CHILDREN"
      );
    })
  );
};
