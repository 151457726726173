import { gql } from "@apollo/client";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ThumbsUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import TimerIcon from "@mui/icons-material/Timer";
import { ReactComponent as QAIcon } from "../../assets/icons/icon-qa.svg";
import { PermissionName } from "../../../clients/graphqlTypes";
import { TreeItemRightIconsFragment } from "./TreeRightIcons.generated";
import { RIGHT_ICON_STYLE } from "../BareTreeItem";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";

interface Props {
  item: TreeItemRightIconsFragment;
  isVisibleToOtherSide: boolean;
  document: {
    viewerPermissions: PermissionName[];
  };
}

export const TreeRightIcons = ({
  item,
  isVisibleToOtherSide,
  document,
}: Props) => (
  <>
    <RestrictedByDocumentPermissionWithDebug
      permission="EXECUTE_TASK"
      document={document}
    >
      {item.timeTrackingRequired && <TimerIcon style={RIGHT_ICON_STYLE} />}
    </RestrictedByDocumentPermissionWithDebug>
    {item.photoApprovalRequired && <AddAPhotoIcon style={RIGHT_ICON_STYLE} />}
    <RestrictedByDocumentPermissionWithDebug
      permission="EXECUTE_TASK"
      document={document}
    >
      {item.qaApprovalRequired && <QAIcon style={RIGHT_ICON_STYLE} />}
    </RestrictedByDocumentPermissionWithDebug>
    {isVisibleToOtherSide && item.approvalRequired && (
      <ThumbsUpOutlinedIcon style={RIGHT_ICON_STYLE} />
    )}
  </>
);
