import { SplitButton } from "@msys/ui";
import { Alert, Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useUserData } from "../../../auth/useUserData";
import { AskWhen } from "../../../../clients/graphqlTypes";
import { DecisionWizardButton } from "../../doc-items/buttons/DecisionWizardButton";
import { useDecisionItemMutations } from "../../doc-items/hooks/useDecisionItemMutations";
import { useDecisionProcess } from "../../doc-items/hooks/useDecisionProcess";
import { DecisionModal_DecisionItemFragment } from "../../doc-items/modals/DecisionModal.generated";
import { DecisionWizardModal } from "../../doc-items/modals/DecisionWizardModal";

const EMPTY_ARRAY: any[] = [];
const PROCESS_CONTEXTS: AskWhen[] = [
  "onQuoteCreate",
  "onQuoteRefinement",
  "onEmbeddableWizard",
];

interface Args {
  templateId: string;
}

export function useTemplateDecisionWizardPreview({ templateId }: Args) {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Decisions"]);
  const [decisionContext, setDecisionContext] = React.useState<AskWhen>(
    PROCESS_CONTEXTS[0]
  );

  const defaultDecisionModalActionImpl_part1 =
    useDecisionItemMutations<DecisionModal_DecisionItemFragment>(
      null,
      templateId,
      true,
      [],
      [],
      []
    );

  const decisionProcess = useDecisionProcess({
    projectId: null,
    docId: templateId,
    embeddedMode: decisionContext === "onEmbeddableWizard",
    contractorId: viewer.organisation.id,
    itemUuidSeed: "",
    viewerDecisionRole: undefined,
    decisionContext,
  });

  const wizardModal = (
    <DecisionWizardModal
      processState={decisionProcess.state}
      projectId={null}
      docType="TEMPLATE"
      docId={templateId}
      viewerDecisionRole={undefined}
      decisionContext={decisionProcess.decisionContext}
      embeddedMode={decisionProcess.decisionContext === "onEmbeddableWizard"}
      contractorId={viewer.organisation.id}
      decisionActions={{
        handleContingencyPreselectionChange: asyncNoop,
        canFinalizeContingencyDecision:
          defaultDecisionModalActionImpl_part1.canFinalizeContingencyDecision,
        handleFinalizeContingencyDecision: asyncNoop,
        finalizeContingencyDecisionLoading: false,

        handleDecisionPreselectionChange: asyncNoop,
        isSubitemFinalizeDisabled:
          defaultDecisionModalActionImpl_part1.isSubitemFinalizeDisabled,
        canFinalizeSubitemDecision:
          defaultDecisionModalActionImpl_part1.canFinalizeSubitemDecision,
        canForceFinalizeSubitemDecision:
          defaultDecisionModalActionImpl_part1.canForceFinalizeSubitemDecision,
        handleFinalizeSubitemDecision: asyncNoop,
        finalizeSubitemDecisionLoading: false,

        handleEliminateOption: asyncNoop,
        handleResetEliminateOption: asyncNoop,

        setPropertyValues: asyncNoop,
        enterPropertiesLoading: false,
        setItemProduct: asyncNoop,
        setItemProductLoading: false,
        finalizeProductSelect: asyncNoop,
        finalizeProductSelectLoading: false,

        isItemGoingToBeRemoved:
          defaultDecisionModalActionImpl_part1.isItemGoingToBeRemoved,
        loading: false,
      }}
      expandedItemIds={EMPTY_ARRAY}
      canFinalize={false}
      handleClose={decisionProcess.close}
      handleNextItem={decisionProcess.next}
      handlePreviousItem={decisionProcess.previous}
      isOptionHidden={item => item.isHidden}
      info={
        <Alert
          title={t("Wizard preview", { ns: "Decisions" })}
          severity="warning"
        >
          {t("This is a preview of the wizard. No changes will be saved!", {
            ns: "Decisions",
          })}
        </Alert>
      }
    />
  );

  const buttonLabels: Record<AskWhen, string> = {
    onQuoteCreate: t("Quote create wizard", { ns: "Decisions" }),
    onQuoteRefinement: t("Quote refinement wizard", { ns: "Decisions" }),
    onEmbeddableWizard: t("Embeddable wizard", { ns: "Decisions" }),
  };

  const openWizardButton = (
    <SplitButton
      buttons={PROCESS_CONTEXTS.map(context => (
        <DecisionWizardButton
          key={context}
          start={decisionProcess.start}
          label={buttonLabels[context]}
        />
      ))}
      selectedIndex={PROCESS_CONTEXTS.indexOf(decisionContext)}
      setSelectedIndex={index => setDecisionContext(PROCESS_CONTEXTS[index])}
    />
  );

  return (
    <Box key="open-wizard-button">
      {openWizardButton}
      {wizardModal}
    </Box>
  );
}

async function asyncNoop() {}
