import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React, {
  Ref,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useCounter } from "react-use";
import {
  Agreement,
  AttachmentInput,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { getAllItemChildren } from "../../trees/helpers";
import {
  PhotoApprovalProcessFragment,
  usePhotoApprovalProcessLazyQuery,
} from "./PhotoApprovalProcess.generated";
import { PhotoApprovalModal } from "./modals/PhotoApprovalModal";
import { PhotoApprovalMultipleModal } from "./modals/PhotoApprovalMultipleModal";

export interface PhotoApprovalProcessRef {
  checkPhotoApprove: (
    itemId: string,
    docId: string,
    checkChildren?: boolean,
    forceCheck?: boolean
  ) => Promise<boolean>;
}

interface Props {
  photoApproveTaskItem: (
    itemId: string,
    docId: string,
    photo: AttachmentInput
  ) => void;
  loading?: boolean;
  projectId: string;
}

export const PhotoApprovalProcess = React.forwardRef<
  PhotoApprovalProcessRef,
  Props
>(
  (
    { loading, photoApproveTaskItem, projectId }: Props,
    forwardedRef: Ref<PhotoApprovalProcessRef>
  ) => {
    const [current, { inc }] = useCounter(0);
    const [
      {
        checkChildren,
        isOpen,
        isOpenChildren,
        item: itemToApprove,
        items: itemsToApprove,
        allDocItems,
        onComplete,
      },
      setState,
    ] = useState<{
      checkChildren: boolean;
      isOpen: boolean;
      isOpenChildren: boolean;
      doc: { viewerPermissions: PermissionName[] };
      item:
        | (PhotoApprovalProcessFragment & {
            docAgreement: Agreement;
            docViewerPermissions: PermissionName[];
          })
        | null;
      items:
        | (PhotoApprovalProcessFragment & {
            docAgreement: Agreement;
            docViewerPermissions: PermissionName[];
          })[]
        | null;
      allDocItems:
        | (PhotoApprovalProcessFragment & {
            docAgreement: Agreement;
            docViewerPermissions: PermissionName[];
          })[]
        | null;
      onComplete?: (areAllPhotosAdded: boolean) => void;
    }>({
      checkChildren: false,
      doc: { viewerPermissions: [] },
      item: null,
      items: null,
      allDocItems: null,
      isOpen: false,
      isOpenChildren: false,
    });

    const setIsOpen = useCallback(
      (newIsOpen: boolean) => setState(s => ({ ...s, isOpen: newIsOpen })),
      []
    );
    const setIsOpenChildren = useCallback(
      (newIsOpen: boolean) =>
        setState(s => ({ ...s, isOpenChildren: newIsOpen })),
      []
    );

    const isPhotoAdded = useRef<boolean>(false);
    const areAllPhotosAdded = useRef<boolean>(false);

    const client = useApolloClient();
    const [fetch, query] = usePhotoApprovalProcessLazyQuery({ client });

    const checkPhotoApprove = useCallback(
      async (
        itemId: string,
        docId: string,
        checkChildren: boolean = false,
        forceCheck: boolean = false
      ) => {
        const result = await fetch({
          variables: { projectId: projectId, docId },
        });
        const document = getDataOrNull(result.data?.project)?.project?.tasks[0];
        const allDocItems = document?.allDocItems;

        return new Promise<boolean>(resolve => {
          if (!allDocItems) {
            resolve?.(true);
            return;
          }
          const currentItem = allDocItems.find(i => i.id === itemId);
          if (currentItem) {
            const childItems = getAllItemChildren(currentItem, allDocItems);
            const childItemsToApprove = childItems.filter(
              item =>
                item.photoApprovalRequired &&
                item.canBeWorkedOn &&
                !item.photoApproved &&
                !item.deletedAt
            );
            const needApprove =
              (currentItem.photoApprovalRequired || forceCheck) &&
              currentItem.canBeWorkedOn &&
              !currentItem.photoApproved &&
              !currentItem.deletedAt;
            if (
              needApprove ||
              (checkChildren && childItemsToApprove.length > 0)
            ) {
              inc();
              isPhotoAdded.current = needApprove ? false : true;
              areAllPhotosAdded.current =
                childItemsToApprove.length > 0 ? false : true;
              setState({
                checkChildren,
                isOpen: needApprove,
                isOpenChildren:
                  checkChildren &&
                  !needApprove &&
                  childItemsToApprove.length > 0,
                doc: document,
                item: needApprove
                  ? {
                      ...currentItem,
                      docViewerPermissions: document.viewerPermissions,
                      docAgreement: document.agreement,
                    }
                  : null,
                items: childItemsToApprove.length
                  ? childItemsToApprove.map(item => ({
                      ...item,
                      docViewerPermissions: document.viewerPermissions,
                      docAgreement: document.agreement,
                    }))
                  : null,
                allDocItems: allDocItems.map(item => ({
                  ...item,
                  docViewerPermissions: document.viewerPermissions,
                  docAgreement: document.agreement,
                })),
                onComplete: resolve,
              });
            } else {
              resolve?.(true);
            }
          } else {
            resolve?.(true);
          }
        });
      },
      [fetch, inc, projectId]
    );

    useImperativeHandle(forwardedRef, () => ({ checkPhotoApprove }));

    return isOpen && allDocItems ? (
      <PhotoApprovalModal
        key={`single-approve-modal-${current}`}
        loading={loading}
        handleComplete={(isAdded: boolean) => {
          isPhotoAdded.current = isAdded;
        }}
        handleClose={() => {
          setIsOpen(false);
          if (checkChildren && itemsToApprove?.length) {
            setIsOpenChildren(true);
          } else {
            onComplete?.(isPhotoAdded.current);
          }
        }}
        photoApproveTaskItem={photoApproveTaskItem}
        item={itemToApprove}
        allDocItems={allDocItems}
      />
    ) : isOpenChildren && allDocItems ? (
      <PhotoApprovalMultipleModal
        key={`multiple-approve-modal-${current}`}
        loading={loading}
        handleComplete={(areAllAdded: boolean) => {
          areAllPhotosAdded.current = areAllAdded;
        }}
        handleClose={() => {
          setIsOpenChildren(false);
          onComplete?.(isPhotoAdded.current && areAllPhotosAdded.current);
        }}
        photoApproveTaskItem={photoApproveTaskItem}
        items={itemsToApprove}
        allDocItems={allDocItems}
      />
    ) : (
      <></>
    );
  }
);
