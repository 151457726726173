import { gql, useApolloClient } from "@apollo/client";
import { CollapseSection, Modal, ModalOpenButton } from "@msys/ui";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  SwitchProps,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import { fieldToSwitch, TextField } from "formik-mui";
import { groupBy, uniqueId } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { DatePickerField } from "../../commons/form-fields/DatePickerField";
import { SelectField } from "../../commons/form-fields/SelectField";
import {
  CustomFieldDataType,
  CustomFieldObjectType,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { assertNever } from "../../utils";
import {
  CustomFieldConfigFragment,
  useCustomFieldConfigQuery,
} from "./customFieldConfigs.generated";
import { EditCustomFieldConfigModal } from "./EditCustomFieldConfigModal";
import { useSetCustomFieldMutation } from "./EditCustomFieldModal.generated";

interface CustomFields {
  [group: string]: {
    [key: string]: string | number;
  };
}

export interface CustomFieldsFormValues {
  customFields: CustomFields;
}

type CustomField = {
  key: string;
  value: string;
  dataType: CustomFieldDataType;
  group: string;
};

export const EditCustomFieldModal = ({
  title,
  objectId,
  objectType,
  customFields,
  handleClose,
  refetchQueries,
}: {
  title?: string;
  objectId: string;
  objectType: CustomFieldObjectType;
  customFields: CustomField[];
  handleClose: () => void;
  refetchQueries: string[];
}) => {
  const { t } = useTranslate(["CustomFields", "Global"]);

  const client = useApolloClient();
  const customFieldConfigQuery = useCustomFieldConfigQuery({
    client,
    variables: { filterObjectType: objectType },
  });

  const formId = React.useMemo(() => uniqueId(), []);

  const customFieldConfigs =
    customFieldConfigQuery.data?.customFieldConfig ?? [];

  const { handleSubmit, validationSchema, initialValues } = useCustomFieldsForm(
    {
      objectId,
      objectType,
      customFields,
      customFieldConfigs,
      handleComplete: handleClose,
      refetchQueries,
      useAutoSave: false,
    }
  );

  if (!customFieldConfigs) return null;

  return (
    <Formik<CustomFieldsFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, isValid, isSubmitting }) => {
        return (
          <Modal
            title={title ?? t("Edit custom fields", { ns: "CustomFields" })}
            handleClose={handleClose}
            actionButtons={[
              {
                label: t("Cancel", { ns: "Global" }),
                handleClick: handleClose,
                buttonProps: { variant: "text", disabled: isSubmitting },
              },
              {
                label: t("Save", { ns: "Global" }),
                buttonProps: {
                  form: formId,
                  type: "submit",
                  disabled: !isValid,
                  loading: isSubmitting,
                },
              },
            ]}
            headerActions={
              <ModalOpenButton
                Modal={EditCustomFieldConfigModal}
                modalProps={{
                  refetchQueries: [namedOperations.Query.CustomFieldConfig],
                }}
              >
                <Button
                  startIcon={<SettingsIcon />}
                  color="secondary"
                  size="extra-small"
                  variant="outlined"
                >
                  {t("Configurator", { ns: "CustomFields" })}
                </Button>
              </ModalOpenButton>
            }
          >
            <Form id={formId}>
              <CustomFieldsArray
                values={values}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                customFieldConfigs={customFieldConfigs}
                objectType={objectType}
                useAutoSave={false}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export const getCustomFieldsValidationSchema = (
  customFieldConfigs: CustomFieldConfigFragment[]
) => {
  return Yup.object().shape(
    Object.entries(groupBy(customFieldConfigs, "group")).reduce(
      (acc, [group, fields]) => ({
        ...acc,
        [group]: Yup.object().shape(
          fields.reduce((acc, field) => {
            return {
              ...acc,
              [field.key]: getFieldSchema(field.dataType, field.key),
            };
          }, {})
        ),
      }),
      {}
    )
  );
};

export const getCustomFieldsInitialValues = (
  customFieldConfigs: CustomFieldConfigFragment[],
  customFields: CustomField[]
) => {
  return [...customFieldConfigs]
    .sort((a, b) => a.key.localeCompare(b.key))
    .reduce((acc, current) => {
      const customField = customFields.find(field => field.key === current.key);

      const cfg = customFieldConfigs.find(e => e.key === current.key);

      if (!cfg) return acc;

      return {
        ...acc,
        [current.group ?? ""]: {
          ...acc[current.group],
          [current.key]: customField
            ? JSON.parse(customField.value)
            : undefined,
        },
      };
    }, {} as CustomFields);
};

export const getCustomFieldsSubmitValues = (
  customFieldConfigs: CustomFieldConfigFragment[],
  customFields: CustomFields
) => {
  return Object.values(customFields)
    .flatMap(Object.entries)
    .filter(([, value]) => value || value === 0 || value === false)
    .filter(([key, value]) => {
      // for some reason formik "array values" get not properly reinitialized
      return customFieldConfigs.some(e => e.key === key);
    })
    .map(([key, value]) => ({
      key: key,
      value: JSON.stringify(value),
    }));
};

export function useCustomFieldsForm({
  customFieldConfigs,
  customFields,
  refetchQueries,
  objectId,
  objectType,
  handleComplete,
  useAutoSave,
}: {
  customFieldConfigs: CustomFieldConfigFragment[];
  customFields: CustomField[];
  refetchQueries: string[];
  objectId: string;
  objectType: CustomFieldObjectType;
  handleComplete?: () => Promise<void> | void;
  useAutoSave: boolean;
}) {
  const { t } = useTranslate(["CustomFields", "Global"]);

  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const [setCustomField] = useSetCustomFieldMutation({
    client,
    refetchQueries,
  });

  const validationSchema = Yup.object().shape({
    customFields: getCustomFieldsValidationSchema(customFieldConfigs),
  });

  const initialValues = {
    customFields: getCustomFieldsInitialValues(
      customFieldConfigs,
      customFields
    ),
  };

  const handleSubmit = async (values: CustomFieldsFormValues) => {
    try {
      if (!customFieldConfigs.length)
        throw new Error("Custom fields config is missing!");

      await setCustomField({
        variables: {
          input: {
            objectId,
            objectType,
            values: getCustomFieldsSubmitValues(
              customFieldConfigs,
              values.customFields
            ),
          },
        },
      });
      if (!useAutoSave)
        enqueueSnackbar(
          t("Custom fields set", {
            ns: "CustomFields",
          })
        );
      await handleComplete?.();
    } catch (error) {
      enqueueSnackbar(
        t("Failed to set custom fields", {
          ns: "CustomFields",
        }),
        {
          variant: "error",
        }
      );
    }
  };

  return { validationSchema, initialValues, handleSubmit };
}

function getDataType(
  key: string,
  customFieldConfigs: CustomFieldConfigFragment[]
) {
  const fieldConfig = customFieldConfigs?.find(field => field.key === key);
  return fieldConfig?.dataType;
}

function getFieldSchema(dataType: CustomFieldDataType, key: string) {
  switch (dataType) {
    case "t_boolean":
      return Yup.boolean().label(key);
    case "t_number":
    case "t_m":
    case "t_cm":
    case "t_mm":
      return Yup.number().label(key);
    default:
      return Yup.string().label(key);
  }
}

function getInputField(
  key: string,
  name: string,
  value: any,
  isSubmitting: boolean,
  setFieldValue: FormikHelpers<CustomFieldsFormValues>["setFieldValue"],
  customFieldConfigs: CustomFieldConfigFragment[],
  objectType: CustomFieldObjectType,
  useAutoSave: boolean
) {
  const defaultInput = (
    <Field
      name={name}
      label={key}
      component={TextField}
      disabled={useAutoSave ? false : undefined}
    />
  );

  if (!key) return defaultInput;

  const dataType = getDataType(key, customFieldConfigs);

  if (!dataType) {
    throw new Error(`Field config for key ${key} on ${objectType} not found`);
  }

  switch (dataType) {
    case "t_date":
      return (
        <DatePickerField
          name={name}
          label={key}
          value={value ? moment(value) : null}
          onChange={(event, value) =>
            setFieldValue(name, value?.format("YYYY-MM-DD") ?? null)
          }
          disabled={useAutoSave ? false : isSubmitting}
        />
      );
    case "t_boolean":
      return (
        <Field
          name={name}
          label={key}
          component={(props: FieldProps) => (
            <FormControlLabel
              control={<Switch {...fieldToSwitch(props)} />}
              label={key}
            />
          )}
          type="checkbox"
          onChange={(
            event: Parameters<Exclude<SwitchProps["onChange"], undefined>>[0],
            checked: boolean
          ) => {
            setFieldValue(name, checked);
          }}
          disabled={useAutoSave ? false : undefined}
        />
      );
    case "t_number":
    case "t_m":
    case "t_cm":
    case "t_mm":
      return (
        <Field
          name={name}
          label={key}
          component={TextField}
          type="number"
          disabled={useAutoSave ? false : undefined}
        />
      );

    case "t_text": {
      const cfg = customFieldConfigs?.find(e => e.key === key);

      if (!cfg) throw new Error(`no cfg for field: ${key}`);

      if (cfg.allowedValues) {
        return (
          <SelectField
            name={name}
            label={key}
            options={cfg.allowedValues.split("\n").map(allowedValue => ({
              label: allowedValue,
              value: allowedValue,
            }))}
            disabled={useAutoSave ? false : undefined}
          />
        );
      }

      return defaultInput;
    }

    default:
      return defaultInput;
  }
}

function getColumnsForCustomField(dataType: CustomFieldDataType) {
  switch (dataType) {
    case "t_external_url":
    case "t_youtube_video_id":
      return 2;
    case "t_text":
    case "t_number":
    case "t_date":
    case "t_boolean":
    case "t_mm":
    case "t_cm":
    case "t_m":
      return 1;
    default:
      return assertNever(dataType);
  }
}

export function CustomFieldsArray({
  values,
  isSubmitting,
  setFieldValue,
  customFieldConfigs,
  objectType,
  useAutoSave,
}: {
  values: CustomFieldsFormValues;
  isSubmitting: boolean;
  setFieldValue: FormikHelpers<CustomFieldsFormValues>["setFieldValue"];
  customFieldConfigs: CustomFieldConfigFragment[];
  objectType: CustomFieldObjectType;
  useAutoSave: boolean;
}) {
  const { t } = useTranslate(["CustomFields", "Global"]);

  const hasGroups = Object.entries(values.customFields).some(([group]) =>
    Boolean(group)
  );

  const renderField = (group: string, key: string, value: string | number) => {
    // for some reason formik "array values" get not properly reinitialized
    const config = customFieldConfigs.find(e => e.key === key);
    if (!config) return null;
    return (
      <Grid
        key={key}
        item
        xs={getColumnsForCustomField(config.dataType)}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {getInputField(
          key,
          `customFields.${group}.${key}`,
          value,
          isSubmitting,
          setFieldValue,
          customFieldConfigs,
          objectType,
          useAutoSave
        )}
      </Grid>
    );
  };
  const renderFields = (
    group: string,
    fields: {
      [key: string]: string | number;
    }
  ) => {
    return (
      <Grid container columns={2} spacing={1}>
        {Object.entries(fields).map(([key, value]) =>
          renderField(group, key, value)
        )}
      </Grid>
    );
  };
  return (
    <FieldArray
      name="customFields"
      render={() => (
        <Stack direction="column" spacing={1}>
          {Object.entries(values.customFields).map(([group, fields]) => {
            return group ? (
              <CollapseSection title={group} minHeight="auto" key={group}>
                {renderFields(group, fields)}
              </CollapseSection>
            ) : hasGroups ? (
              <CollapseSection
                title={t("Other", { ns: "Global" })}
                minHeight="auto"
                key="other"
              >
                {renderFields(group, fields)}
              </CollapseSection>
            ) : (
              <Box key="all">{renderFields(group, fields)}</Box>
            );
          })}
        </Stack>
      )}
    />
  );
}
