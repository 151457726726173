/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectCommentsBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, channels: Array<{ __typename: 'Channel', id: string }> };

export const ProjectCommentsBox_ProjectFragmentDoc = gql`
    fragment ProjectCommentsBox_Project on Project {
  id
  viewerPermissions
  channels(filters: {channelType: CHANNEL}) {
    id
  }
}
    `;