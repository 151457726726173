import { gql } from "@apollo/client";
import { Alert } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ProjectOverdueAlertFragment } from "./ProjectOverdueAlert.generated";

interface Props {
  project: ProjectOverdueAlertFragment;
}

export const ProjectOverdueAlert = ({ project }: Props) => {
  const { t } = useTranslate(["ProjectOverview", "Global"]);

  if (
    ["opportunity", "contracted"].includes(project.state) &&
    project.overdue
  ) {
    return (
      <Alert variant="outlined" severity="warning">
        {project.ticket
          ? t("This ticket is overdue", { ns: "ProjectOverview" })
          : t("This project is overdue", { ns: "ProjectOverview" })}
      </Alert>
    );
  }

  return null;
};
