/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type CrmEmailListItem_CrmMailMessageFragment = { __typename: 'CrmMailMessage', id: string, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, createdAt: any, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> };

export const CrmEmailListItem_CrmMailMessageFragmentDoc = gql`
    fragment CrmEmailListItem_CrmMailMessage on CrmMailMessage {
  id
  direction
  from
  to
  subject
  createdAt
  attachments {
    filename
    mimeType
    url
  }
}
    `;