import { gql, useApolloClient } from "@apollo/client";
import { CollapseSection, Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Stack } from "../../../commons/layout/Stack";
import { Props2NonComputed } from "../../../../clients/graphqlTypes";
import { LabeledPropertiesValue } from "../../item-properties/LabeledPropertiesValue";
import { ProductConfirmPropertiesModal } from "./ProductConfirmPropertiesModal";
import { usePimRunPropertyExtractionMutation } from "./ProductExtractPropertiesModal.generated";

interface Props {
  title?: string;
  isOpen?: boolean;
  productArticleNumber: string;
  productSupplierId: string;
  currentProperties: Props2NonComputed[];
  refetchQueries?: string[];
  handleClose: () => void;
  handleComplete: () => void | Promise<void>;
}

interface FormValues {
  properties: Props2NonComputed[];
}

export const ProductExtractPropertiesModal = ({
  title,
  isOpen,
  productArticleNumber,
  productSupplierId,
  currentProperties,
  refetchQueries,
  handleClose,
  handleComplete,
}: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslate(["Global", "ItemPropertyField"]); // TODO: edit namespace

  const client = useApolloClient();

  const formId = useMemo(() => uniqueId(), []);

  const [runPropertyExtraction] = usePimRunPropertyExtractionMutation({
    client,
  });

  const [initialValues, setInitialValues] = useState<FormValues>({
    properties: [],
  });

  const handleCloseInt = () => {
    setSubmitting(false);
    handleClose();
  };

  const handleSubmit = async (values: FormValues) => {
    if (isSubmitting) return;
  };

  useEffect(() => {
    (async () => {
      if (isOpen) {
        const propertiesRes = await runPropertyExtraction({
          variables: {
            input: {
              articleNumber: productArticleNumber,
              supplierId: productSupplierId,
            },
          },
        });

        if (propertiesRes.data) {
          const newInitialValues = {
            properties: propertiesRes.data.pimRunPropertyExtraction,
          };
          setInitialValues(newInitialValues);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, productArticleNumber, productSupplierId]);

  if (!isOpen) return null;

  return (
    <Modal
      title={
        title ??
        t("Edit services", {
          ns: "Global",
        })
      }
      dialogProps={{ maxWidth: "sm" }}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleCloseInt,
          buttonProps: {
            variant: "text",
            disabled: isSubmitting,
          },
        },
        {
          label: t("Next", {
            ns: "Global",
          }),
          buttonProps: {
            type: "submit",
            loading: isSubmitting,
            disabled: isSubmitting,
          },
          handleClick: () => {
            setSubmitting(true);
          },
        },
      ]}
      handleClose={handleCloseInt}
    >
      <Formik<FormValues>
        validationSchema={Yup.mixed()}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form id={formId}>
            <Stack flexDirection="column" m={1}>
              <CollapseSection
                title={t("Extracted product properties", {
                  ns: "Global",
                })}
                isInitiallyExpanded={values.properties.length > 0}
                titleVariant="h4"
                itemCount={values.properties.length}
                hideItemCountOnExpand={true}
              >
                <LabeledPropertiesValue
                  properties={values.properties}
                  viewerIsContractor={true}
                />
              </CollapseSection>
            </Stack>
            <ProductConfirmPropertiesModal
              isOpen={isSubmitting}
              title={t("Extracted product properties", {
                ns: "Global",
              })}
              productArticleNumber={productArticleNumber}
              productSupplierId={productSupplierId}
              newProperties={values.properties.map(p => ({
                ...p,
              }))}
              currentProperties={currentProperties}
              refetchQueries={refetchQueries}
              handleClose={() => setSubmitting(false)}
              handleComplete={async () => {
                setSubmitting(false);
                handleComplete();
              }}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
