import { getPictures, stripHtmlTags } from "@msys/common";
import {
  CollapseChip,
  getFormattedPrice,
  InfoMessage,
  LabeledValue,
  ModalOpenButton,
  TypographyWithMaxNumberOfLines,
} from "@msys/ui";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { FormikProps, useFormikContext } from "formik";
import { without } from "lodash";
import defaultImageUrl from "../../assets/images/no-image.png";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField";
import { ConfirmModal } from "../../commons/modals/ConfirmModal";
import { ModifyItemProductValuesInput } from "../../../clients/graphqlTypes";
import { track } from "../../track";
import { getProductFullDescription } from "../products/helper";
import { ProductSearchItem__ProductSearchResultFragment } from "../products/Product.generated";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../templates/quote/TemplatesQuoteSelectMultipleModal.generated";

export type SectionTab = "landing" | "results";
export type SearchTab = "products" | "templates";
export type CartTab = "cart";

export interface FormValues {
  templates: {
    quantity: number;
    template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
  }[];
  products: {
    quantity: number;
    product:
      | ProductSearchItem__ProductSearchResultFragment
      | ModifyItemProductValuesInput;
  }[];
}

export function getProductUniqueId(
  product:
    | ProductSearchItem__ProductSearchResultFragment
    | ModifyItemProductValuesInput
) {
  const articleNumber =
    "__typename" in product
      ? product.articleNumber
      : product.productArticleNumber;
  const supplierId =
    "__typename" in product ? product.supplierId : product.productSupplierId;
  const productId = "__typename" in product ? product.id : "";
  return `${
    productId ? `${productId}///` : ""
  }${articleNumber}///${supplierId}`;
}

const TemplateItem = ({
  formikProps,
  tpl,
  index,
  showQuantityInput = true,
}: {
  formikProps: FormikProps<FormValues>;
  tpl: {
    quantity: number;
    template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
  };
  index: number;
  showQuantityInput?: boolean;
}) => {
  const { t } = useTranslate(["TemplatesSearch"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const pictures = getPictures(tpl.template.attachments);
  const onRemove = () => {
    formikProps.setFieldValue(
      "templates",
      without(formikProps.values.templates, tpl)
    );
  };
  return (
    <Card
      key={tpl.template.id}
      sx={{
        display: "flex",
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: theme => theme.layout.imageSize.sm,
          flexGrow: 0,
          flexShrink: 0,
        }}
        image={pictures[0]?.url ?? defaultImageUrl}
        alt={tpl.template.title}
      />
      <Stack direction="column" spacing={0.5} p={1} flex={1}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <TypographyWithMaxNumberOfLines variant={"body1"} $maxLines={2}>
            {tpl.template.title}
          </TypographyWithMaxNumberOfLines>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onRemove();
            }}
            color="primary"
            size={"small"}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Grid container columns={100}>
          {showQuantityInput && (
            <Grid item xs={32} alignItems="center" display="flex">
              <LabeledValue
                label={t("Template price", {
                  ns: "TemplatesSearch",
                })}
                variant="body2"
              >
                {getFormattedPrice(
                  tpl.template.proposedCalculation?.priceNetTotal || 0,
                  language
                )}
              </LabeledValue>
            </Grid>
          )}
          {showQuantityInput && (
            <Grid
              xs={4}
              item
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              ×
            </Grid>
          )}
          {showQuantityInput && (
            <Grid item xs={25} alignItems="center" display="flex">
              <FormattedFloatField
                disabled={false}
                label={t("Quantity", {
                  ns: "TemplatesSearch",
                })}
                placeholder={t("Quantity", {
                  ns: "TemplatesSearch",
                })}
                name={`templates[${index}].quantity`}
                type="float"
                min={0}
                size="extra-small"
              />
            </Grid>
          )}
          <Grid
            item
            xs={showQuantityInput ? 39 : 100}
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
          >
            <Typography variant="h4" align="right">
              {getFormattedPrice(
                (tpl.template.proposedCalculation?.priceNetTotal || 0) *
                  (tpl.quantity ?? 0),
                language
              )}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

const ProductItem = ({
  formikProps,
  prd,
  index,
  showQuantityInput = true,
}: {
  formikProps: FormikProps<FormValues>;
  prd: {
    quantity: number;
    product:
      | ProductSearchItem__ProductSearchResultFragment
      | ModifyItemProductValuesInput;
  };
  index: number;
  showQuantityInput?: boolean;
}) => {
  const { t } = useTranslate(["Product", "ProductSearch"]);
  const language = useTolgee(["language"]).getLanguage()!;
  const onRemove = () => {
    formikProps.setFieldValue(
      "products",
      without(formikProps.values.products, prd)
    );
    track({
      eventName: "UnselectProduct",
      data: { product: prd.product },
    });
  };

  if ("__typename" in prd.product) {
    // product from catalogue
    const attachments = prd.product.attachments.filter(attachment => {
      if (attachment.group === "3dItemDefinition") {
        return (
          attachment.title === "thumbnail.jpg" ||
          attachment.title === "thumbnail.png"
        );
      }
      return true;
    });
    const pictures = getPictures(attachments);
    return (
      <Card
        key={prd.product.id}
        sx={{
          display: "flex",
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: theme => theme.layout.imageSize.sm,
            flexGrow: 0,
            flexShrink: 0,
          }}
          image={pictures[0]?.url ?? defaultImageUrl}
          alt={prd.product.texts?.title ?? ""}
        />
        <Stack direction="column" spacing={0.5} p={1} minWidth={0} flex={1}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="flex-start"
            minWidth={0}
          >
            <Stack direction="column" spacing={0} minWidth={0}>
              <Typography variant={"body1"} noWrap>
                {prd.product.texts?.title}
              </Typography>
              <Typography variant={"caption"} noWrap>
                {stripHtmlTags(getProductFullDescription(prd.product))}
              </Typography>
              <Typography variant={"caption"}>
                {t("Article no.", { ns: "Product" })}:{" "}
                {prd.product.articleNumber || "–"}
              </Typography>
            </Stack>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                onRemove();
              }}
              color="primary"
              size={"small"}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Grid container columns={100}>
            {showQuantityInput && (
              <Grid item xs={32} alignItems="center" display="flex">
                <LabeledValue
                  label={t("Unit price", { ns: "ProductSearch" })}
                  variant="body2"
                >
                  {getFormattedPrice(
                    prd.product.pricing?.netPrice ??
                      prd.product.pricing?.listPrice ??
                      0,
                    language
                  )}
                </LabeledValue>
              </Grid>
            )}
            {showQuantityInput && (
              <Grid
                item
                xs={4}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                ×
              </Grid>
            )}
            {showQuantityInput && (
              <Grid item xs={25} alignItems="center" display="flex">
                <FormattedFloatField
                  disabled={false}
                  label={t("Quantity", { ns: "ProductSearch" })}
                  placeholder={t("Quantity", { ns: "ProductSearch" })}
                  name={`products[${index}].quantity`}
                  type="float"
                  min={0}
                  size="extra-small"
                />
              </Grid>
            )}
            <Grid
              item
              xs={showQuantityInput ? 32 : 100}
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="h4" align="right">
                {getFormattedPrice(
                  (prd.product.pricing?.netPrice ??
                    prd.product.pricing?.listPrice ??
                    0) * (prd.quantity ?? 0),
                  language
                )}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Card>
    );
  } else {
    // one-time product
    return (
      <Card
        key={`product-${index}-${prd.product.productArticleNumber}`}
        sx={{
          display: "flex",
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: theme => theme.layout.imageSize.sm,
            flexGrow: 0,
            flexShrink: 0,
          }}
          image={defaultImageUrl}
          alt={prd.product.productTitle ?? ""}
        />
        <Stack direction="column" spacing={0.5} p={1} minWidth={0} flex={1}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="flex-start"
            minWidth={0}
          >
            <Stack direction="column" spacing={0} minWidth={0}>
              <Typography variant={"body1"} noWrap>
                {prd.product.productTitle}
              </Typography>
              <Typography variant={"caption"} noWrap>
                {prd.product.productDescription
                  ? stripHtmlTags(prd.product.productDescription)
                  : ""}
              </Typography>
              <Typography variant={"caption"}>
                {t("Article no.", { ns: "Product" })}:{" "}
                {prd.product.productArticleNumber || "–"}
              </Typography>
            </Stack>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                onRemove();
              }}
              color="primary"
              size={"small"}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Grid container>
            <Grid item xs={4} alignItems="center" display="flex">
              <LabeledValue
                label={t("Unit price", { ns: "ProductSearch" })}
                variant="body2"
              >
                {getFormattedPrice(0, language)}
              </LabeledValue>
            </Grid>
            <Grid
              item
              sx={theme => ({ width: theme.spacing(2) })}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              ×
            </Grid>
            <Grid
              item
              xs={3}
              alignItems="center"
              display="flex"
              sx={theme => ({
                flexBasis: `calc(25% - ${theme.spacing(1)}) !important`,
                maxWidth: `calc(25% - ${theme.spacing(1)}) !important`,
              })}
            >
              <FormattedFloatField
                disabled={false}
                label={t("Quantity", {
                  ns: "ProductSearch",
                })}
                placeholder={t("Quantity", {
                  ns: "ProductSearch",
                })}
                name={`products[${index}].quantity`}
                type="float"
                min={0}
                size="extra-small"
              />
            </Grid>
            <Grid
              item
              xs={5}
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              sx={theme => ({
                flexBasis: `calc(41.666667% - ${theme.spacing(1)}) !important`,
                maxWidth: `calc(41.666667% - ${theme.spacing(1)}) !important`,
              })}
            >
              <Typography variant="h4" align="right">
                {getFormattedPrice(0, language)}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Card>
    );
  }
};

export const SearchProductsAndTemplatesFormItems = ({
  searchTab,
  showQuantityInput,
}: {
  searchTab: SearchTab | null;
  showQuantityInput?: boolean;
}) => {
  const { t } = useTranslate(["TemplatesSearch", "ProductSearch"]);
  const formikProps = useFormikContext<FormValues>();
  if (
    formikProps.values.products.length === 0 &&
    formikProps.values.templates.length === 0
  ) {
    return (
      <Box p={4} alignSelf={"center"} flex={1} display="flex">
        <InfoMessage
          message={
            searchTab === "products"
              ? t(
                  "Item list is currently empty.\nAdd an item from Product search.",
                  {
                    ns: "ProductSearch",
                  }
                )
              : searchTab === "templates"
              ? t(
                  "Item list is currently empty.\nAdd an item from Template search.",
                  {
                    ns: "TemplatesSearch",
                  }
                )
              : t(
                  "Item list is currently empty. Add an item from the search.",
                  {
                    ns: "ProductSearch",
                  }
                )
          }
        />
      </Box>
    );
  }

  return (
    <Stack
      direction="column"
      spacing={1}
      overflow="auto"
      minHeight={0}
      flex={1}
    >
      {formikProps.values.products.length > 0 &&
        formikProps.values.templates.length > 0 && (
          <Typography variant="body2" color="grey.600">
            {t("Products", { ns: "ProductSearch" })}
          </Typography>
        )}
      {formikProps.values.products.map((prd, index) => (
        <ProductItem
          key={getProductUniqueId(prd.product)}
          prd={prd}
          index={index}
          formikProps={formikProps}
          showQuantityInput={showQuantityInput}
        />
      ))}
      {formikProps.values.products.length > 0 &&
        formikProps.values.templates.length > 0 && (
          <Typography variant="body2" color="grey.600">
            {t("Templates", { ns: "TemplatesSearch" })}
          </Typography>
        )}
      {formikProps.values.templates.map((tpl, index) => (
        <TemplateItem
          key={tpl.template.id}
          tpl={tpl}
          index={index}
          formikProps={formikProps}
          showQuantityInput={showQuantityInput}
        />
      ))}
    </Stack>
  );
};

export const SearchProductsAndTemplatesForm = ({
  searchTab,
  showQuantityInput,
}: {
  searchTab: SearchTab | null;
  showQuantityInput?: boolean;
}) => {
  return (
    <>
      <SearchProductsAndTemplatesFormHeader />
      <SearchProductsAndTemplatesFormItems
        searchTab={searchTab}
        showQuantityInput={showQuantityInput}
      />
      <SearchProductsAndTemplatesFormSubmitButton />
    </>
  );
};

export const SearchProductsAndTemplatesFormHeader = () => {
  const { t } = useTranslate(["ProductSearch", "TemplatesSearch", "Global"]);
  const formikProps = useFormikContext<FormValues>();
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems={"center"}
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <ShoppingCartOutlinedIcon fontSize="small" />
        <Typography variant="h3">
          {t("Selected items", { ns: "ProductSearch" })}
        </Typography>
        <CollapseChip
          variant="outlined"
          size="small"
          label={
            formikProps.values.products.length +
            formikProps.values.templates.length
          }
        />
      </Stack>
      {(formikProps.values.products.length > 0 ||
        formikProps.values.templates.length > 0) && (
        <ModalOpenButton
          Modal={ConfirmModal}
          modalProps={{
            title: t("Clearing selected items", { ns: "ProductSearch" }),
            text: t("Are you sure you want to clear the list?", {
              ns: "ProductSearch",
            }),
            handleConfirm: () => {
              formikProps.setValues({ products: [], templates: [] });
            },
          }}
        >
          <Button size="extra-small" variant="outlined" color="primary">
            {t("Clear list", { ns: "ProductSearch" })}
          </Button>
        </ModalOpenButton>
      )}
    </Stack>
  );
};

export const SearchProductsAndTemplatesFormSubmitButton = () => {
  const { t } = useTranslate(["ProductSearch", "TemplatesSearch", "Global"]);
  const formikProps = useFormikContext<FormValues>();
  const language = useTolgee(["language"]).getLanguage()!;
  const totalProductsPrice = formikProps.values.products.reduce((acc, p) => {
    if (!("__typename" in p.product)) return acc;
    return (
      acc +
      p.quantity *
        (p.product.pricing?.netPrice ?? p.product.pricing?.listPrice ?? 0)
    );
  }, 0);
  const hasSelectedProducts = formikProps.values.products.some(
    p => p.quantity > 0,
    0
  );
  const totalTemplatesPrice = formikProps.values.templates.reduce((acc, t) => {
    return (
      acc + t.quantity * (t.template.proposedCalculation?.priceNetTotal || 0)
    );
  }, 0);
  const hasSelectedTemplates = formikProps.values.templates.some(
    t => t.quantity > 0,
    0
  );

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography variant="h3">
          {t("Total price", {
            ns: "ProductSearch",
          })}
          :
        </Typography>
        <Typography variant="h3">
          {getFormattedPrice(
            totalProductsPrice + totalTemplatesPrice,
            language
          )}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          color="primary"
          loading={formikProps.isSubmitting}
          disabled={
            (!hasSelectedProducts && !hasSelectedTemplates) ||
            formikProps.isSubmitting
          }
          onClick={() => {
            formikProps.submitForm();
          }}
        >
          {t("Add", { ns: "Global" })}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
