import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { CardContainer } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, List, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ItemType } from "../../../../clients/graphqlTypes";
import { TemplateIcon } from "../../templates/TemplateIcon";
import { TemplateTypeIcon } from "../../templateTypes/TemplateTypeIcon";
import { getDefaultCreatableSubItemTypes } from "../constraints";
import { RecommendationListItem } from "./RecommendationListItem";
import {
  RecommendationsBox_ItemFragment,
  useCreateItemFromItemRecommendationMutation,
} from "./RecommendationsBox.generated";

interface Props {
  projectId: string;
  item: RecommendationsBox_ItemFragment;
  documentItemTypes: ItemType[];
  expandedItemIds?: string[];
  onUpdateDataRefetchQueries?: string[];
}

export const RecommendationsBox = ({
  projectId,
  item,
  documentItemTypes,
  expandedItemIds,
  onUpdateDataRefetchQueries,
}: Props) => {
  const { t } = useTranslate(["QuoteItem"]);

  const allowedItemTypes = getDefaultCreatableSubItemTypes(
    item.type,
    documentItemTypes
  );

  const { recommendations = [] } = item;

  if (allowedItemTypes.length === 0) return null;

  return (
    <CardContainer
      title={t("Recommended templates", { ns: "QuoteItem" })}
      isExpandable
    >
      {recommendations.length > 0 ? (
        <List dense disablePadding>
          {recommendations.map(recommendation => {
            if (
              recommendation.recommendedEntity.__typename ===
              "RecommendedTemplate"
            ) {
              return (
                <RecommendationListItem
                  key={recommendation.id}
                  icon={<TemplateIcon />}
                  text={recommendation.teaserTitle}
                  link={`/templates/documents/${recommendation.recommendedEntity.templateId}/${recommendation.recommendedEntity.templateVersionNumber}`}
                  secondaryAction={
                    <QuoteCreateItemFromRecommendationButton
                      itemRecommendationId={recommendation.id}
                      parentProjectId={projectId}
                      parentDocId={item.docId}
                      parentItemId={item.id}
                      expandedItemIds={expandedItemIds}
                      refetchQueries={onUpdateDataRefetchQueries}
                    />
                  }
                />
              );
            } else if (
              recommendation.recommendedEntity.__typename ===
              "RecommendedTemplateType"
            ) {
              return (
                <RecommendationListItem
                  key={recommendation.id}
                  icon={<TemplateTypeIcon />}
                  text={recommendation.teaserTitle}
                  link={`/templates/types/${recommendation.recommendedEntity.templateTypeId}`}
                  secondaryAction={
                    <QuoteCreateItemFromRecommendationButton
                      itemRecommendationId={recommendation.id}
                      parentProjectId={projectId}
                      parentDocId={item.docId}
                      parentItemId={item.id}
                      expandedItemIds={expandedItemIds}
                      refetchQueries={onUpdateDataRefetchQueries}
                    />
                  }
                />
              );
            } else {
              assertNever(recommendation.recommendedEntity);
            }
          })}
        </List>
      ) : (
        <Stack padding={2} alignItems="center" justifyContent={"center"}>
          <Typography color={theme => theme.palette.grey[600]} variant="body2">
            {t("No recommendations", { ns: "QuoteItem" })}
          </Typography>
        </Stack>
      )}
    </CardContainer>
  );
};

function QuoteCreateItemFromRecommendationButton({
  parentProjectId,
  parentDocId,
  parentItemId,
  expandedItemIds,
  itemRecommendationId,
  refetchQueries,
}: {
  parentProjectId: string;
  parentDocId: string;
  parentItemId: string;
  expandedItemIds: string[] | undefined;
  itemRecommendationId: string;
  refetchQueries?: string[];
}) {
  const client = useApolloClient();
  const [createItemFromItemRecommendation] =
    useCreateItemFromItemRecommendationMutation({
      client,
    });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  return (
    <IconButton
      color="primary"
      size="small"
      disabled={isLoading}
      onClick={async event => {
        event.stopPropagation();
        event.preventDefault();
        if (isLoading) return;
        setIsLoading(true);
        try {
          await createItemFromItemRecommendation({
            variables: {
              input: {
                projectId: parentProjectId,
                docId: parentDocId,
                itemId: parentItemId,
                itemRecommendationId,
                expandedItemIds,
              },
            },
            refetchQueries,
            awaitRefetchQueries: true,
          });
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <AddIcon />
    </IconButton>
  );
}
