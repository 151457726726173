import { useTranslate } from "@tolgee/react";
import {
  FilterByOrganisationUsers,
  FilterProps,
} from "../../../commons/filters";
import { FilterChipGroupProps } from "../../../commons/filters/FilterChip";
import { FilterOrganisationUsersChips } from "../../organisations/OrganisationUsersSelect";

export function FilterAssigneesChips(
  props: Omit<FilterChipGroupProps<string>, "label" | "getValueLabel">
) {
  const { t } = useTranslate("ProjectMembers");
  return <FilterOrganisationUsersChips {...props} label={t("Assignee")} />;
}

export const FilterAssignees = (props: FilterProps<string[]>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByOrganisationUsers
      label={t("Assignees")}
      placeholder={t("Select assignees")}
      {...props}
    />
  );
};
