import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  ErrorMessage,
  FormattedPrice,
  GridColDef,
  LoadingSpinner,
  StateStore,
  useFormatting,
} from "@msys/ui";
import { Chip, Stack, TablePagination, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  OrganisationProjectsSortBy,
  OrganisationProjectsSorting,
  QuotingStatus,
} from "../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { CollectionView } from "../../commons/hooks/useCollectionView";
import { RESULTS_PER_PAGE_OPTIONS } from "../../constants";
import { getAddressLabel } from "../addresses/helpers";
import { getProjectCrmLinksLabel } from "../contact-links/helper";
import { useRelationshipRoles } from "../contact-links/useRelationshipRoles";
import { ProjectAssigneeEditButton } from "../projects/ProjectAssigneeEditButton";
import { ProjectPhaseChip } from "../projects/ProjectPhaseChip";
import { ProjectStatusBadge } from "../projects/badges/ProjectStatusBadge";
import { ProjectSourceCreatedBy } from "../projects/components/ProjectSourceCreatedBy";
import { ProjectSourceEntity } from "../projects/components/ProjectSourceEntity";
import { ProjectSourceFilterType } from "../projects/useProjectSources";
import { useCategories } from "../skill-categories/useCategories";
import { getPersonLabel } from "../users/helpers";
import {
  OpportunitiesListQueryVariables,
  OpportunitiesList_ProjectFragment,
  useOpportunitiesListQuery,
} from "./OpportunitiesList.generated";
import {
  OpportunityListItem,
  OpportunityListItemDisplayConfig,
} from "./components/OpportunityListItem";

export type Filters = Omit<
  OpportunitiesListQueryVariables,
  "limit" | "offset" | "filterBySources"
> & { sources?: ProjectSourceFilterType[] | undefined | null };

export const DEFAULT_SORTING: OrganisationProjectsSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

interface Props {
  sorting: OrganisationProjectsSorting[];
  setSorting: React.Dispatch<
    React.SetStateAction<OrganisationProjectsSorting[]>
  >;
  activeView: CollectionView;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  showStatus?: boolean;
  showPhase?: boolean;
  stateStore: StateStore;
  displayConfig: OpportunityListItemDisplayConfig;
  variables: OpportunitiesListQueryVariables;
}

export function OpportunitiesList({
  sorting,
  setSorting,
  activeView,
  paginationModel,
  setPaginationModel,
  showStatus = true,
  showPhase = false,
  stateStore,
  displayConfig,
  variables,
}: Props) {
  const client = useApolloClient();
  const query = useOpportunitiesListQuery({
    client,
    variables,
  });

  const opportunities =
    getDataOrNull((query.data ?? query.previousData)?.projects)?.edges.map(
      e => e.node
    ) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.projects)?.totalCount ??
    0;
  const phases = query.data?.organisationSettings.projectPhases;

  const navigate = useNavigate();
  const { t } = useTranslate([
    "Opportunities",
    "Categories",
    "Global",
    "ProjectMembers",
  ]);

  const { categoryLabels } = useCategories();
  const { getRoleLabel } = useRelationshipRoles();

  const { getFormattedDateTime } = useFormatting();
  const quotingStatusLabels = React.useMemo((): Record<
    QuotingStatus,
    string
  > => {
    return {
      none: t("None", { ns: "Global" }),
      non_binding: t("Non-binding", { ns: "Global" }),
      binding: t("Binding", { ns: "Global" }),
    };
  }, [t]);

  switch (activeView) {
    case "table": {
      const columns: GridColDef<OpportunitiesList_ProjectFragment>[] = [
        {
          field: "phase",
          headerName: t("Phase", { ns: "Opportunities" }),
          editable: false,
          sortable: true,
          flex: 1,
          minWidth: 100,
          renderCell: ({ row: opportunity }) => (
            <ProjectPhaseChip
              projectId={opportunity.id}
              currentState={opportunity.state}
              currentPhase={opportunity.phase}
              availablePhases={phases}
            />
          ),
        },
        {
          field: "title",
          headerName: t("Title", { ns: "Opportunities" }),
          editable: false,
          sortable: true,
          flex: 3,
          minWidth: 100,
          valueGetter: ({ row: opportunity }) => opportunity.title,
        },
        {
          field: "organisationName",
          headerName: t("Client", { ns: "Opportunities" }),
          editable: false,
          sortable: true,
          flex: 3,
          minWidth: 100,
          valueGetter: ({ row: opportunity }) =>
            [
              opportunity.crmOrganisation?.title,
              `${opportunity.crmOrganisation?.contactPerson?.firstname ?? ""} ${
                opportunity.crmOrganisation?.contactPerson?.familyname ?? ""
              }`.trim(),
            ]
              .filter(Boolean)
              .join(" - "),
          renderCell: ({ row: opportunity }) =>
            [
              opportunity.crmOrganisation?.title,
              `${opportunity.crmOrganisation?.contactPerson?.firstname ?? ""} ${
                opportunity.crmOrganisation?.contactPerson?.familyname ?? ""
              }`.trim(),
            ]
              .filter(Boolean)
              .join(" - "),
        },
        {
          field: "organisationEmail",
          headerName: t("Email", { ns: "Global" }),
          editable: false,
          sortable: true,
          flex: 2,
          minWidth: 100,
          valueGetter: ({ row: opportunity }) =>
            opportunity.crmOrganisation?.email ?? "–",
        },
        {
          field: "organisationPhone",
          headerName: t("Phone", { ns: "Global" }),
          editable: false,
          sortable: false,
          flex: 2,
          minWidth: 100,
          valueGetter: ({ row: opportunity }) =>
            opportunity.crmOrganisation?.phones?.[0]?.number ?? "–",
        },
        {
          field: "buildingStreet",
          headerName: t("Project address", { ns: "Opportunities" }),
          editable: false,
          sortable: true,
          flex: 3,
          minWidth: 100,
          valueGetter: ({ row: opportunity }) =>
            getAddressLabel(opportunity.address),
          renderCell: ({ row: opportunity }) =>
            getAddressLabel(opportunity.address),
        },
        {
          field: "assignee",
          headerName: t("Assignee", {
            ns: "ProjectMembers",
          }),
          editable: false,
          sortable: true,
          flex: 2,
          minWidth: 100,
          valueGetter: ({ row: opportunity }) =>
            opportunity.assignee
              ? getPersonLabel(opportunity.assignee.user, false)
              : "–",
          renderCell: ({ row: opportunity }) => (
            <RestrictedByProjectPermissionWithDebug
              permission="MANAGE_PROJECT"
              project={opportunity}
              otherwise={
                <ProjectAssigneeEditButton
                  assignee={opportunity.assignee ?? null}
                  projectId={opportunity.id}
                  projectTitle={opportunity.title}
                  readOnly={true}
                  type="button"
                />
              }
            >
              <ProjectAssigneeEditButton
                assignee={opportunity.assignee ?? null}
                projectId={opportunity.id}
                projectTitle={opportunity.title}
                type="button"
              />
            </RestrictedByProjectPermissionWithDebug>
          ),
        },
        {
          field: "minBudget",
          headerName: t("Budget", { ns: "Opportunities" }),
          editable: false,
          sortable: true,
          flex: 1,
          minWidth: 100,
          align: "right",
          headerAlign: "right",
          valueGetter: ({ row: opportunity }) =>
            opportunity.budget?.amount ?? 0,
          renderCell: ({ row: opportunity }) => {
            return (
              <Typography
                component="span"
                align="right"
                variant="body2"
                style={{ fontWeight: 500 }}
              >
                <FormattedPrice value={opportunity.budget} />
              </Typography>
            );
          },
        },
        {
          field: "state",
          headerName: t("Status", { ns: "Projects" }),
          minWidth: 120,
          sortable: true,
          flex: 1.5,
          renderCell: ({ row: project }) => (
            <ProjectStatusBadge projectState={project.state} />
          ),
        },
        {
          field: "crmLinks",
          headerName: t("Other contacts", { ns: "Projects" }),
          minWidth: 100,
          sortable: false,
          flex: 1,
          renderCell: ({ row: project }) =>
            getProjectCrmLinksLabel(project, getRoleLabel),
        },
        {
          field: "category",
          headerName: t("Category", { ns: "Categories" }),
          editable: false,
          sortable: false,
          flex: 1,
          minWidth: 200,
          valueGetter: ({ row: opportunity }) => opportunity.categories,
          renderCell: ({ row: opportunity }) =>
            opportunity.categories.map(category => (
              <Chip
                label={categoryLabels[category]}
                size="small"
                color="primary"
              />
            )),
        },
        {
          field: "source",
          headerName: t("Source", { ns: "Opportunities" }),
          editable: false,
          sortable: false,
          flex: 1,
          minWidth: 100,
          renderCell: ({ row: project }) => (
            <ProjectSourceEntity
              projectSource={project.source}
              projectId={project.id}
            />
          ),
        },
        {
          field: "createdBy",
          headerName: t("Created by", {
            ns: "Opportunities",
          }),
          editable: false,
          sortable: true,
          flex: 1,
          minWidth: 100,
          renderCell: ({ row: project }) => (
            <ProjectSourceCreatedBy projectSource={project.source} />
          ),
        },
        {
          field: "createdAt",
          headerName: t("Created at", {
            ns: "Opportunities",
          }),
          editable: false,
          flex: 1,
          minWidth: 80,
          valueGetter: ({ row: opportunity }) =>
            opportunity.createdAt ? new Date(opportunity.createdAt) : null,
          renderCell: ({ row: opportunity }) =>
            opportunity.createdAt
              ? getFormattedDateTime(opportunity.createdAt)
              : "-",
        },
        {
          field: "quotingStatus",
          headerName: t("Quoting status", { ns: "Opportunities" }),
          editable: false,
          sortable: false,
          flex: 1,
          minWidth: 100,
          valueGetter: ({ row: opportunity }) => opportunity.quotingStatus,
          renderCell: ({ row: opportunity }) => (
            <Chip
              label={quotingStatusLabels[opportunity.quotingStatus]}
              size={"small"}
              color={"primary"}
              sx={{ textDecoration: "uppercase" }}
            />
          ),
        },
        {
          field: "leadId",
          headerName: t("Lead ID", { ns: "Projects" }),
          flex: 1,
          hideable: true,
          sortable: false,
          valueGetter: ({ row: opportunity }) => opportunity.leadId ?? "-",
        },
      ];
      return (
        <DataGrid
          stateStore={stateStore}
          loading={query.loading}
          hideFooter={totalCount === 0}
          columns={columns}
          rows={opportunities}
          onRowClick={({ row }) => {
            navigate(`/projects/${row.id}`);
          }}
          disableColumnFilter
          sortModel={sorting.map(s => ({
            field: s.column,
            sort: s.direction,
          }))}
          onSortModelChange={newModel => {
            setSorting(
              newModel.map(({ field, sort }) => ({
                column: field as OrganisationProjectsSortBy,
                direction: sort ?? "asc",
              }))
            );
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...(!showStatus ? { status: false } : {}),
                ...(!showPhase ? { phase: false } : {}),
              },
            },
          }}
        />
      );
    }
    case "list": {
      if (query.loading) return <LoadingSpinner />;
      if (totalCount === 0)
        return (
          <ErrorMessage
            message={t("There are no items to display", {
              ns: "Global",
            })}
          />
        );
      return (
        <Stack direction="column" spacing={1}>
          {opportunities.map(opportunity => (
            <CardItem
              key={opportunity.id}
              //@ts-ignore
              component={Link}
              to={`/projects/${opportunity.id}`}
            >
              <OpportunityListItem
                project={opportunity}
                showAssignee
                showStatus={showStatus}
                showPhase={showPhase}
                displayConfig={displayConfig}
                availablePhases={phases}
              />
            </CardItem>
          ))}
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", { ns: "Global" })}
          />
        </Stack>
      );
    }
    default:
      return null;
  }
}
