import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useEffectOnce, useLatest } from "react-use";

const NativeFeatureContext = React.createContext<{
  keyboardHeight: number;
}>(null as any);

export const NativeFeatureProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
  const keyboardHeightLatest = useLatest(keyboardHeight);

  useEffectOnce(() => {
    if (!Capacitor.isNativePlatform()) return;

    Keyboard.addListener("keyboardWillShow", info => {
      console.log("keyboard will show with height:", info.keyboardHeight);
      dispatchEvent("keyboardWillShow", info);
      if (keyboardHeightLatest.current !== info.keyboardHeight)
        setKeyboardHeight(info.keyboardHeight);
    });
    Keyboard.addListener("keyboardDidShow", info => {
      console.log("keyboard did show with height:", info.keyboardHeight);
      dispatchEvent("keyboardDidShow", info);
      if (keyboardHeightLatest.current !== info.keyboardHeight)
        setKeyboardHeight(info.keyboardHeight);
    });
    Keyboard.addListener("keyboardWillHide", () => {
      console.log("keyboard will hide");
      dispatchEvent("keyboardWillHide");
      if (keyboardHeightLatest.current !== 0) setKeyboardHeight(0);
    });
    Keyboard.addListener("keyboardDidHide", () => {
      dispatchEvent("keyboardDidHide");
      console.log("keyboard did hide");
      if (keyboardHeightLatest.current !== 0) setKeyboardHeight(0);
    });

    return () => {
      Keyboard.removeAllListeners();
    };
  });

  return (
    <NativeFeatureContext.Provider
      value={{
        keyboardHeight,
      }}
    >
      {children}
    </NativeFeatureContext.Provider>
  );
};

export const useNativeFeature = () => useContext(NativeFeatureContext);

function dispatchEvent<T = any>(type: string, detail?: T) {
  const event = new CustomEvent(type, {
    detail,
    bubbles: true,
    cancelable: true,
    composed: false,
  });
  window.dispatchEvent(event);
}

export const useScrollToKeyboard = (
  scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>,
  couldBeMeasured: boolean
) => {
  const lastIsOnBottom = useRef<boolean>(false);
  const lastClientHeight = useRef<number | null>(null);
  const lastScrollTop = useRef<number | null>(null);
  const initialClientHeight = useRef<number | null>(null);

  useEffect(() => {
    if (!Capacitor.isNativePlatform() || !couldBeMeasured) return;
    setTimeout(() => {
      initialClientHeight.current =
        scrollContainerRef.current?.clientHeight ?? null;
    });
  }, [couldBeMeasured, scrollContainerRef]);

  // Scroll to initial point when keyboard is appearing
  useEffectOnce(() => {
    if (!Capacitor.isNativePlatform()) return;

    const onWillShow = () => {
      if (scrollContainerRef.current && initialClientHeight.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        lastIsOnBottom.current = scrollTop + clientHeight >= scrollHeight;
        lastClientHeight.current = initialClientHeight.current;
        lastScrollTop.current = scrollTop;
      }
    };

    const onDidShow = () => {
      setTimeout(() => {
        if (
          lastScrollTop.current !== null &&
          lastClientHeight.current !== null &&
          scrollContainerRef.current &&
          scrollContainerRef.current.clientHeight &&
          scrollContainerRef.current.clientHeight < lastClientHeight.current
        ) {
          if (lastIsOnBottom.current) {
            scrollContainerRef.current.scroll({
              top: scrollContainerRef.current?.scrollHeight,
              behavior: "auto",
            });
          } else {
            scrollContainerRef.current.scrollTop =
              lastScrollTop.current +
              (lastClientHeight.current -
                scrollContainerRef.current.clientHeight);
          }
          lastIsOnBottom.current = false;
          lastClientHeight.current = null;
          lastScrollTop.current = null;
        }
      }, 100);
    };

    // const onWillHide = () => {
    //   if (scrollContainerRef.current) {
    //     const { scrollTop, scrollHeight, clientHeight } =
    //       scrollContainerRef.current;
    //     lastIsOnBottom.current = scrollTop + clientHeight >= scrollHeight;
    //     lastClientHeight.current = condensedClientHeight.current;
    //     lastScrollTop.current = scrollTop;
    //   }
    // };
    //
    // const onDidHide = () => {
    //   setTimeout(() => {
    //     if (
    //       lastScrollTop.current !== null &&
    //       lastClientHeight.current !== null &&
    //       scrollContainerRef.current &&
    //       scrollContainerRef.current.clientHeight &&
    //       scrollContainerRef.current.clientHeight > lastClientHeight.current
    //     ) {
    //       if (lastIsOnBottom.current) {
    //         scrollContainerRef.current.scroll({ top: scrollContainerRef.current?.scrollHeight, behavior: "auto", });
    //       } else {
    //         scrollContainerRef.current.scrollTop =
    //           lastScrollTop.current -
    //           (scrollContainerRef.current.clientHeight -
    //             lastClientHeight.current);
    //       }
    //       normalClientHeight.current = scrollContainerRef.current.clientHeight;
    //       lastIsOnBottom.current = false;
    //       lastClientHeight.current = null;
    //       lastScrollTop.current = null;
    //     }
    //   });
    // };

    window.addEventListener("keyboardWillShow", onWillShow);
    window.addEventListener("keyboardDidShow", onDidShow);
    // window.addEventListener("keyboardWillHide", onWillHide);
    // window.addEventListener("keyboardDidHide", onDidHide);

    return () => {
      window.removeEventListener("keyboardWillShow", onWillShow);
      window.removeEventListener("keyboardDidShow", onDidShow);
      // window.removeEventListener("keyboardWillHide", onWillHide);
      // window.removeEventListener("keyboardDidHide", onDidHide);
    };
  });
};
