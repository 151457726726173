/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TemplateQuotePublishingStatus_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, owningSystemOrganisationId: string, sharedWithOrganisations: Array<{ __typename: 'Company', id: string }> };

export const TemplateQuotePublishingStatus_QuoteTemplateFragmentDoc = gql`
    fragment TemplateQuotePublishingStatus_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  hasAnyPublishedVersion
  sharedPublicly
  sharedWithOrganisations {
    id
  }
  owningSystemOrganisationId
}
    `;