import { useApolloClient } from "@apollo/client";
import { assert } from "@msys/common";
import { Modal } from "@msys/ui";
import { DialogContentText } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { Stack } from "../../../commons/layout/Stack";
import {
  useCreateManufacturerMutation,
  useCreateSupplierMutation,
} from "../../../admin/admin.generated";
import { useTranslate } from "@tolgee/react";
import { assertNever } from "../../../utils";
import { useOrganisationTypes } from "../useOrganisationTypes";

interface FormValues {
  title: string;
  organisationType: "MANUFACTURER" | "SUPPLIER";
}

interface Props {
  predefinedOrganisationType: "MANUFACTURER" | "SUPPLIER";
  title?: string;
  handleClose: () => void;
  handleComplete?: (userId: string, organisationId: string) => Promise<void>;
}

export const CreateOrganisationModal: React.FC<Props> = ({
  predefinedOrganisationType,
  title,
  handleClose,
  handleComplete,
}) => {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);
  const { organisationTypeLabels } = useOrganisationTypes();
  const { currentUser } = useUserData();
  const viewer = currentUser!;

  const client = useApolloClient();
  // const [createOrganisation] = useCreateOrganisationMutation({ client });

  const [createSupplier] = useCreateSupplierMutation({ client });
  const [createManufacturer] = useCreateManufacturerMutation({ client });

  const organisationTypeOptions = [
    {
      value: predefinedOrganisationType,
      label: organisationTypeLabels[predefinedOrganisationType],
    },
  ];

  // const defaultQuoteOpeningText = useDefaultQuoteOpeningText();
  // const defaultQuoteClosingText = useDefaultQuoteClosingText();
  // const defaultInvoiceOpeningText = useDefaultInvoiceOpeningText();
  // const defaultInvoiceClosingText = useDefaultInvoiceClosingText();

  async function onSubmit({ title, organisationType }: FormValues) {
    // const quoteTexts =
    //   organisationType === OrganisationType.Craftsman
    //     ? {
    //         opening: JSON.stringify(defaultQuoteOpeningText),
    //         closing: JSON.stringify(defaultQuoteClosingText),
    //       }
    //     : undefined;
    // const invoiceTexts =
    //   organisationType === OrganisationType.Craftsman
    //     ? {
    //         opening: JSON.stringify(defaultInvoiceOpeningText),
    //         closing: JSON.stringify(defaultInvoiceClosingText),
    //       }
    //     : undefined;

    switch (organisationType) {
      case "SUPPLIER": {
        const organisationRes = await createSupplier({
          variables: {
            input: {
              title,
            },
          },
        });

        assert(
          organisationRes.data?.createSupplier,
          "organisationRes.data?.createSupplier"
        );

        handleComplete?.(
          organisationRes.data!.createSupplier.organisation.id,
          viewer.id
        );

        break;
      }

      case "MANUFACTURER": {
        const organisationRes = await createManufacturer({
          variables: {
            input: {
              title,
            },
          },
        });

        assert(
          organisationRes.data?.createManufacturer,
          "organisationRes.data?.createManufacturer"
        );

        handleComplete?.(
          organisationRes.data!.createManufacturer.organisation.id,
          viewer.id
        );

        break;
      }

      default: {
        assertNever(organisationType);
      }
    }
  }

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .label(
            t("Name", {
              ns: "OrganisationProfile",
            })
          )
          .required(),
        organisationType: Yup.mixed()
          .label(
            t("Organisation Type", {
              ns: "OrganisationProfile",
            })
          )
          .oneOf(["CLIENT", "CRAFTSMAN", "SUPPLIER", "MANUFACTURER"]),
      }),
    [t]
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
      initialValues={{
        title: "",
        organisationType: predefinedOrganisationType,
      }}
    >
      {({ isValid, isSubmitting, setFieldValue, values }) => (
        <Modal
          title={title}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Create", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                loading: isSubmitting,
                disabled: !isValid,
              },
            },
          ]}
        >
          <DialogContentText>
            {t(
              "Create a new organisation to manage projects and tickets for that organisation.",
              {
                ns: "OrganisationProfile",
              }
            )}
          </DialogContentText>
          <Form id={formId}>
            <Stack flexDirection="column">
              <Field
                component={TextField}
                autoFocus
                label={t("Name", {
                  ns: "OrganisationProfile",
                })}
                name="title"
              />
              <SelectField
                options={organisationTypeOptions}
                name="organisationType"
                label={t("Organisation Type", {
                  ns: "OrganisationProfile",
                })}
                required
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
