import { useApolloClient } from "@apollo/client";
import { ErrorMessage, LoadingSpinner, useScreenWidth } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useUserData } from "../../auth/useUserData";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageFullScreenModal } from "../../commons/layout/PageFullScreenModal";
import { Channel } from "../../features/channels/Channel";
import { useOrganisationChannelQuery } from "../../features/channels/Channels.generated";

interface Props {
  organisationId: string;
  submenuItems: PageTopbarItem[];
}

export const OrganisationChats = ({ organisationId, submenuItems }: Props) => {
  const { t } = useTranslate(["OrganisationChannel", "OrganisationPageTopbar"]);
  const { isMinTablet } = useScreenWidth();
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const query = useOrganisationChannelQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const channelId = query.data?.organisationChannels[0].id;

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;
  if (query.loading) return <LoadingSpinner />;
  if (query.error) return <ErrorMessage message={query.error.message} />;
  if (!channelId)
    return (
      <ErrorMessage
        message={t("Chat is not available", { ns: "OrganisationChannel" })}
      />
    );

  return (
    <Page
      submenuItems={submenuItems}
      title={t("Internal chat", { ns: "OrganisationPageTopbar" })}
    >
      {isMinTablet ? (
        <PageContainer $noPadding $fullHeight>
          <Channel channelId={channelId} />
        </PageContainer>
      ) : (
        <PageFullScreenModal
          title={t("Internal chat", { ns: "OrganisationPageTopbar" })}
          noPadding
        >
          <Channel channelId={channelId} />
        </PageFullScreenModal>
      )}
    </Page>
  );
};
