import React from "react";
import { useTranslate } from "@tolgee/react";
import { InvoiceType } from "../../../../clients/graphqlTypes";

export const useInvoiceTypes = () => {
  const { t } = useTranslate("InvoiceDetailsForm");

  const invoiceTypeLabels = React.useMemo(
    (): Record<InvoiceType, string> => ({
      partial_invoice: t("Partial invoice"),
      down_payment_invoice: t("Down payment invoice"),
      advance_payment_invoice: t("Advance payment invoice"),
      final_invoice: t("Final invoice"),
    }),
    [t]
  );

  const invoicePaymentLabels = React.useMemo(
    (): Record<InvoiceType, string> => ({
      partial_invoice: t("Partial payment"),
      down_payment_invoice: t("Down payment"),
      advance_payment_invoice: t("Advance payment"),
      final_invoice: t("Final payment"),
    }),
    [t]
  );

  const invoiceTypeOptions = React.useMemo(
    (): { value: InvoiceType; label: string }[] =>
      (
        [
          "partial_invoice",
          "down_payment_invoice",
          "advance_payment_invoice",
          "final_invoice",
        ] as InvoiceType[]
      ) // we need persist the order
        .map(type => ({
          value: type,
          label: invoiceTypeLabels[type],
        })),
    [invoiceTypeLabels]
  );

  return { invoiceTypeLabels, invoiceTypeOptions, invoicePaymentLabels };
};
