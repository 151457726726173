import { gql, NetworkStatus, useApolloClient } from "@apollo/client";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@msys/ui";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import {
  OpenMasterdataAvailabilityDocument,
  OpenMasterdataAvailabilityQuery,
  OpenMasterdataAvailabilityQueryVariables,
  useOpenMasterdataAvailabilityQuery,
  useOpenMasterdataUpdateSupplierProductMutation,
} from "./SyncViaOpenMasterdataButton.generated";
import { useTranslate } from "@tolgee/react";
import { SyncViaOpenMasterdataModal } from "../modals/SyncViaOpenMasterdataAuthenticationModal";
import { ProductOverview__ProductFragment } from "../Product.generated";

export const SyncViaOpenMasterdataButton = ({
  product,
  canEdit,
  refetchQueries,
}: {
  product: ProductOverview__ProductFragment;
  canEdit?: boolean;
  refetchQueries?: string[];
}) => {
  const { t } = useTranslate(["Product", "Global"]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const client = useApolloClient();

  const query = useOpenMasterdataAvailabilityQuery({
    client,
    variables: {
      msOrganisationId: product.supplierId,
    },
    fetchPolicy: "network-only",
  });

  const availability = query.data?.openMasterdataAvailability;

  const [openMasterdataUpdateSupplierProduct] =
    useOpenMasterdataUpdateSupplierProductMutation({
      client,
      refetchQueries: refetchQueries,
    });

  if (product.__typename === "PimSupplierProduct") {
    return null;
  }

  if (query.loading) return null;
  if (query.error) return <ErrorMessage message={query.error.message} />;
  if (query.networkStatus === NetworkStatus.error)
    return <ErrorMessage message="Network error" />;

  if (!availability) return null;

  return (
    <IconButton
      color="primary"
      size="small"
      disabled={!canEdit}
      onClick={async () => {
        const { data } = await client.query<
          OpenMasterdataAvailabilityQuery,
          OpenMasterdataAvailabilityQueryVariables
        >({
          query: OpenMasterdataAvailabilityDocument,
          variables: {
            msOrganisationId: product.supplierId,
          },
        });

        if (data && data.openMasterdataAvailability?.isAuthenticated) {
          await openMasterdataUpdateSupplierProduct({
            variables: {
              input: {
                articleNumber: product.articleNumber,
                msOrganisationId: product.supplierId,
              },
            },
          });
        } else {
          setIsModalOpen(true);
        }
      }}
    >
      <Tooltip
        title={t("Sync via open masterdata", {
          ns: "Product",
        })}
      >
        <Icon>
          <FontAwesomeIcon icon={faCloudDownloadAlt} />
        </Icon>
      </Tooltip>
      {isModalOpen && (
        <SyncViaOpenMasterdataModal
          systemSupplierOrganisationId={product.supplierId}
          openMasterdataInfo={availability}
          handleComplete={async handleClose => {
            handleClose();

            await openMasterdataUpdateSupplierProduct({
              variables: {
                input: {
                  articleNumber: product.articleNumber,
                  msOrganisationId: product.supplierId,
                },
              },
            });
          }}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </IconButton>
  );
};
