import { LabeledValue } from "@msys/ui";
import { SvgIcon, Typography } from "@mui/material";
import React from "react";
import { Address } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { AddressWithBreaks } from "./AddressWithBreaks";

interface Props {
  inline?: boolean;
  label: string;
  address?: Address | null;
  variant?: React.ComponentProps<typeof Typography>["variant"];
  icon?: typeof SvgIcon;
  useCountry?: boolean;
  useStreet?: boolean;
}

export const LabeledAddressInlineValue = ({
  label,
  address,
  inline = true,
  variant = "body2",
  icon,
  useCountry = false,
  useStreet = true,
}: Props) => {
  const { t } = useTranslate("Global");
  return (
    <LabeledValue inline={inline} label={label} icon={icon} variant={variant}>
      {!address ? (
        <div>{t("Not set")}</div>
      ) : (
        <div>
          <AddressWithBreaks
            address={address}
            useCountry={useCountry}
            useStreet={useStreet}
          />
        </div>
      )}
    </LabeledValue>
  );
};
