import { MutableRefObject } from "react";
import { find, TreeItem } from "react-sortable-tree";
import { size } from "../../../common/MuiThemeProvider";
import { getAllParentItemIds } from "../../trees/helpers";
import { VirtualItem } from "../types";

export const useGoToItem = <T extends VirtualItem>(
  windowScrollerRef: MutableRefObject<any>,
  updateTree: () => TreeItem[],
  setExpandedState: (state: { [key: string]: boolean }) => void,
  container: HTMLElement | null | undefined
): ((item: T | null, items: T[]) => void) => {
  return (item: T | null, items) => {
    if (!item) {
      return;
    }

    const itemParentIds = (item ? getAllParentItemIds(item, items) : []).reduce(
      (acc, id, index) => {
        acc[id] = true;
        return acc;
      },
      {} as { [key: string]: boolean }
    );

    setExpandedState(itemParentIds);
    const treeData = updateTree();

    const { matches: matchesSelectedItem } = find({
      getNodeKey: ({ node }) => node.id,
      treeData,
      searchMethod: ({ node }) => node.id === item.id,
      searchFocusOffset: 0,
      expandAllMatchPaths: false,
      expandFocusMatchPaths: false,
    });

    setTimeout(() => {
      const treeIndex = matchesSelectedItem?.[0]?.treeIndex ?? null;
      if (treeIndex !== null) {
        if (container) {
          const innerHeight = container.clientHeight;

          // scroll to window center screen
          const scrollTop =
            treeIndex * size.treeItemFullHeight +
            (windowScrollerRef.current?._positionFromTop ?? 0) -
            (innerHeight - size.treeItemFullHeight) / 2;

          container.scroll({
            top: scrollTop,
            left: 0,
            behavior: "smooth",
          });
        } else {
          const innerHeight = window.innerHeight;

          // scroll to window center screen
          const scrollTop =
            treeIndex * size.treeItemFullHeight +
            (windowScrollerRef.current?._positionFromTop ?? 0) -
            (innerHeight - size.treeItemFullHeight) / 2;
          window.scroll({
            top: scrollTop,
            left: window.scrollX,
            behavior: "smooth",
          });
        }
      }
    });
  };
};
