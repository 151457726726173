import BathtubIcon from "@mui/icons-material/Bathtub";
import CalculateIcon from "@mui/icons-material/Calculate";
import ChairIcon from "@mui/icons-material/Chair";
import DeckIcon from "@mui/icons-material/Deck";
import { SvgIcon } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ReactComponent as CarpentryIcon } from "../../assets/icons/icon-carpenter.svg";
import { ReactComponent as RepairIcon } from "../../assets/icons/icon-construction.svg";
import { ReactComponent as KitchenIcon } from "../../assets/icons/icon-countertops.svg";
import { ReactComponent as HeatingIcon } from "../../assets/icons/icon-heating.svg";
import { ReactComponent as MaintenanceIcon } from "../../assets/icons/icon-home-repair-service.svg";
import { ReactComponent as FacadeIcon } from "../../assets/icons/icon-house-siding.svg";
import { ReactComponent as SolarSystemIcon } from "../../assets/icons/icon-solarsystem.svg";
import { ReactComponent as BathroomImage } from "../../assets/images/bathroom.svg";
import { ReactComponent as CarpentryImage } from "../../assets/images/carpentry.svg";
import { ReactComponent as FacadeImage } from "../../assets/images/facade.svg";
import { ReactComponent as HeatingImage } from "../../assets/images/heating.svg";
import { ReactComponent as KitchenImage } from "../../assets/images/kitchen.svg";
import { ReactComponent as LivingRoomImage } from "../../assets/images/living-room.svg";
import { ReactComponent as MaintenanceImage } from "../../assets/images/maintenance.svg";
import { ReactComponent as OutdoorImage } from "../../assets/images/outdoor.svg";
import { ReactComponent as RepairImage } from "../../assets/images/repair.svg";
import { ReactComponent as SolarSystemImage } from "../../assets/images/solarsystem.svg";

export enum M1ClientCalculator {
  Heating = "Heizung - Online-Assistent",
  HeatingFrance = "Heizung - Formulaire clientèle",
  Bathroom = "Badezimmer - Online-Assistent",
  Kitchen = "Küche - Online-Assistent",
  SolarSystem = "Photovoltaik - Online Dialog",
  Repair = "Reparatur - Online Dialog",
  Maintenance = "Wartung - Online-Assistent",
  Facade = "Fassade - Online Dialog",
  Carpentry = "Möbel - Online Dialog",
  Outdoor = "Outdoor-Living - Outdoor-Living",
  LivingRoom = "Wohnraum - Online Dialog",
}

export enum M1CraftsmanCalculator {
  Heating = "Heizung - Vor-Ort-Assistent",
  Bathroom1 = "Badezimmer - Vor-Ort-Assistent",
  Bathroom2 = "Badezimmer - Beratungs-Assistent",
}

export type M1Calculator = M1ClientCalculator | M1CraftsmanCalculator;

type CalculatorDefinition = {
  displayName: string;
  icon: React.ReactElement;
  image: React.ReactElement;
};

function isClientCalculator(value: any): value is M1ClientCalculator {
  return Object.values(M1ClientCalculator).includes(value);
}

export function useM1Calculators() {
  const { t } = useTranslate("M1Calculators");

  const clientCalculators: Record<M1ClientCalculator, CalculatorDefinition> =
    React.useMemo(
      () => ({
        [M1ClientCalculator.Heating]: {
          displayName: t("Heating"),
          icon: (
            <SvgIcon
              component={HeatingIcon}
              viewBox="0 0 24 24"
              color="primary"
            />
          ),
          image: <HeatingImage />,
        },
        [M1ClientCalculator.HeatingFrance]: {
          displayName: t("Heating"),
          icon: (
            <SvgIcon
              component={HeatingIcon}
              viewBox="0 0 24 24"
              color="primary"
            />
          ),
          image: <HeatingImage />,
        },
        [M1ClientCalculator.Bathroom]: {
          displayName: t("Bathroom"),
          icon: <BathtubIcon />,
          image: <BathroomImage />,
        },
        [M1ClientCalculator.Kitchen]: {
          displayName: t("Kitchen"),
          icon: <SvgIcon component={KitchenIcon} color="primary" />,
          image: <KitchenImage />,
        },
        [M1ClientCalculator.SolarSystem]: {
          displayName: t("Photovoltaic"),
          icon: (
            <SvgIcon
              component={SolarSystemIcon}
              viewBox="0 0 24 24"
              color="primary"
            />
          ),
          image: <SolarSystemImage />,
        },
        [M1ClientCalculator.Repair]: {
          displayName: t("Repair"),
          icon: <SvgIcon component={RepairIcon} color="primary" />,
          image: <RepairImage />,
        },
        [M1ClientCalculator.Maintenance]: {
          displayName: t("Maintenance"),
          icon: <SvgIcon component={MaintenanceIcon} color="primary" />,
          image: <MaintenanceImage />,
        },
        [M1ClientCalculator.Facade]: {
          displayName: t("Facade"),
          icon: <SvgIcon component={FacadeIcon} color="primary" />,
          image: <FacadeImage />,
        },
        [M1ClientCalculator.Carpentry]: {
          displayName: t("Carpentry"),
          icon: <SvgIcon component={CarpentryIcon} color="primary" />,
          image: <CarpentryImage />,
        },
        [M1ClientCalculator.Outdoor]: {
          displayName: t("Outdoor"),
          icon: <DeckIcon />,
          image: <OutdoorImage />,
        },
        [M1ClientCalculator.LivingRoom]: {
          displayName: t("Living room"),
          icon: <ChairIcon />,
          image: <LivingRoomImage />,
        },
      }),
      [t]
    );

  const calculators: Record<
    M1ClientCalculator | M1CraftsmanCalculator,
    CalculatorDefinition
  > = React.useMemo(
    () => ({
      ...clientCalculators,
      [M1CraftsmanCalculator.Heating]: {
        displayName: t("Heating - expert flow"),
        icon: (
          <SvgIcon
            component={HeatingIcon}
            viewBox="0 0 24 24"
            color="primary"
          />
        ),
        image: <HeatingImage />,
      },
      [M1CraftsmanCalculator.Bathroom1]: {
        displayName: t("Bathroom - expert flow"),
        icon: <BathtubIcon />,
        image: <BathroomImage />,
      },
      [M1CraftsmanCalculator.Bathroom2]: {
        displayName: t("Bathroom - advisor flow"),
        icon: <BathtubIcon />,
        image: <BathroomImage />,
      },
    }),
    [t, clientCalculators]
  );

  const fallback = React.useCallback(
    (label: string): CalculatorDefinition => {
      return {
        displayName: label,
        icon: <CalculateIcon color="primary" />,
        image: <MaintenanceImage />,
      };
    },
    [t]
  );

  return { calculators, clientCalculators, isClientCalculator, fallback };
}
