/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectOverviewPlanningBox_OrganisationDefaultsFragment = { __typename: 'OrganisationDefaults', id: string, defaultStartWorkDay?: string | null, defaultEndWorkDay?: string | null };

export type ProjectOverviewPlanningBox_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, owningSystemOrganisationId: string, ticket: boolean, planSessions: Array<{ __typename: 'PlanSession', id: string, isTentative: boolean, from: any, till: any, who: { __typename: 'User', id: string, familyname: string, firstname: string } }> };

export const ProjectOverviewPlanningBox_OrganisationDefaultsFragmentDoc = gql`
    fragment ProjectOverviewPlanningBox_OrganisationDefaults on OrganisationDefaults {
  id
  defaultStartWorkDay
  defaultEndWorkDay
}
    `;
export const ProjectOverviewPlanningBox_ProjectFragmentDoc = gql`
    fragment ProjectOverviewPlanningBox_Project on Project {
  id
  viewerPermissions
  owningSystemOrganisationId
  ticket
  planSessions {
    id
    isTentative
    who {
      id
      familyname
      firstname
    }
    from
    till
  }
}
    `;