import { gql, NetworkStatus, useApolloClient } from "@apollo/client";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, ModalOpenButton } from "@msys/ui";
import { Icon, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useDatanormOnlineAvailabilityQuery } from "./AddDatanormOnlineImportationButton.generated";
import { useTranslate } from "@tolgee/react";
import { ImportViaDatanormOnlineModal } from "../ImportViaDatanormOnlineModal";

export const AddDatanormOnlineImportationButton = ({
  supplierId,
}: {
  supplierId: string;
}) => {
  const { t } = useTranslate(["PimImportations", "Global"]);

  const client = useApolloClient();

  const query = useDatanormOnlineAvailabilityQuery({
    client,
    variables: {
      msOrganisationId: supplierId,
    },
    fetchPolicy: "network-only",
  });

  const availability = query.data?.datanormOnlineAvailability;

  if (query.loading) return null;
  if (query.error) return <ErrorMessage message={query.error.message} />;
  if (query.networkStatus === NetworkStatus.error)
    return <ErrorMessage message="Network error" />;

  if (!availability) return null;

  return (
    <ModalOpenButton
      Modal={ImportViaDatanormOnlineModal}
      modalProps={{
        supplierId: supplierId,
        datanormOnlineInfo: availability,
      }}
    >
      <IconButton color="primary" size="small">
        <Tooltip
          title={t("Import via Datanorm Online", {
            ns: "PimImportations",
          })}
        >
          <Icon>
            <FontAwesomeIcon icon={faCloudDownloadAlt} />
          </Icon>
        </Tooltip>
      </IconButton>
    </ModalOpenButton>
  );
};
