/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { RequirementMenu_RequirementFragmentDoc, RequirementMenu_ProjectFragmentDoc } from './RequirementMenu.generated';
export type RequirementSubHeader_RequirementFragment = { __typename: 'Requirement', id: string, title: string, number: string, isPublished: boolean, owningSystemOrganisationId: string, viewerPermissions: Array<Types.PermissionName>, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null }, outgoingRequests: Array<{ __typename: 'Request', id: string }>, mainContract?: { __typename: 'Subcontract', id: string, originProject: { __typename: 'ProjectInfo', id: string, state: Types.ProjectStateMachineStatus }, originQuote: { __typename: 'Quote', id: string }, originItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } | null };

export type RequirementSubHeader_ProjectFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any }> } | null };

export const RequirementSubHeader_RequirementFragmentDoc = gql`
    fragment RequirementSubHeader_Requirement on Requirement {
  id
  title
  number
  isPublished
  owningSystemOrganisationId
  rootItem {
    id
    originVersionNumber
  }
  ...RequirementMenu_Requirement
}
    ${RequirementMenu_RequirementFragmentDoc}`;
export const RequirementSubHeader_ProjectFragmentDoc = gql`
    fragment RequirementSubHeader_Project on Project {
  id
  viewerPermissions
  ...RequirementMenu_Project
}
    ${RequirementMenu_ProjectFragmentDoc}`;