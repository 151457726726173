import { notNull } from "@msys/common";
import { Modal, TabOption, Tabs, useScreenWidth } from "@msys/ui";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Box, Stack, SvgIconProps, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Formik, FormikProps, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { ItemType } from "../../../clients/graphqlTypes";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { track } from "../../track";
import { ProductFilterFields_AvailableFiltersQueryVariables } from "../products/filters/ProductFilterFields.generated";
import { ProductSelectMultipleComponent } from "../products/modals/ProductSelectMultipleModal";
import { TemplatesQuoteSelectMultipleComponent } from "../templates/quote/TemplatesQuoteSelectMultipleModal";
import { TemplateIcon } from "../templates/TemplateIcon";
import {
  CartTab,
  FormValues,
  SearchProductsAndTemplatesForm,
  SearchTab,
} from "./SearchProductsAndTemplates";

export const SearchItemInputModal = ({
  handleClose,
  handleSelect,
  ...props
}: {
  handleSelect(values: FormValues): Promise<void> | void;
  handleClose(): void;
  initialSearchValue: string;
  initialSearchTab: SearchTab;
  excludeTemplateIds?: string[];
  rootItemTypes?: ItemType[];
  allowedSearchTabs?: SearchTab[];
  AddToListIcon?: React.ComponentType<SvgIconProps>;
  showQuantityInput?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["ProductSearch", "TemplatesSearch", "Global"]);

  const initialValues = React.useMemo(
    (): FormValues => ({
      products: [],
      templates: [],
    }),
    []
  );

  const [variables, setVariables] =
    React.useState<ProductFilterFields_AvailableFiltersQueryVariables>({});

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const handleCloseWithCheck = async () => {
    if (
      (formikRef.current?.values.products.length ?? 0) +
        (formikRef.current?.values.templates.length ?? 0) >
      0
    ) {
      const result = await startConfirmProcess({
        title: t("Add selected items?", {
          ns: "ProductSearch",
        }),
        text: t(
          "You have selected items but have not added them to your document. Would you like to add them?",
          {
            ns: "ProductSearch",
          }
        ),
        confirmButtonLabel: t("Yes, add items", {
          ns: "ProductSearch",
        }),
        cancelButtonLabel: t("Discard items", {
          ns: "ProductSearch",
        }),
      });
      if (result) {
        await formikRef.current?.submitForm();
      }
      if (result !== undefined) {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  return (
    <>
      <Formik<FormValues>
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={async values => {
          try {
            const products = values.products.filter(p => p.quantity > 0);
            const templates = values.templates.filter(p => p.quantity > 0);
            if (products.length === 0 && templates.length === 0) return;

            track({
              eventName: "AddSelectedProducts",
              data: { products, variables },
            });
            await handleSelect({ products, templates });
            handleClose();
          } catch (e) {
            if (e instanceof Error) {
              if (e.message.includes("Cycle in templates detected"))
                enqueueSnackbar(
                  t("Creating cycle linking in templates is not allowed", {
                    ns: "TemplatesSearch",
                  }),
                  { variant: "error" }
                );
              else enqueueSnackbar(e.message, { variant: "error" });
            }
            throw e;
          }
        }}
      >
        <SearchItemInputModalComponent
          handleClose={handleCloseWithCheck}
          onProductSearchVariablesChange={setVariables}
          {...props}
        />
      </Formik>
      <ConfirmProcess ref={confirmProcessRef} />
    </>
  );
};

interface Props {
  initialSearchValue: string;
  initialSearchTab: SearchTab;
  excludeTemplateIds?: string[];
  rootItemTypes?: ItemType[];
  handleClose(): void;
  allowedSearchTabs?: SearchTab[];
  onProductSearchVariablesChange?(
    variables: ProductFilterFields_AvailableFiltersQueryVariables
  ): void;
  AddToListIcon?: React.ComponentType<SvgIconProps>;
  showQuantityInput?: boolean;
}

export const SearchItemInputModalComponent = ({
  initialSearchValue,
  initialSearchTab,
  excludeTemplateIds,
  rootItemTypes,
  handleClose,
  allowedSearchTabs,
  onProductSearchVariablesChange,
  AddToListIcon,
  showQuantityInput,
}: Props) => {
  const { t } = useTranslate(["TemplatesSearch", "ProductSearch"]);
  const { isMaxPhone } = useScreenWidth();

  const formikProps = useFormikContext<FormValues>();

  const [searchTab, setSearchTab] = React.useState<SearchTab | null>(
    initialSearchTab
  );
  const [searchValue, setSearchValue] =
    React.useState<string>(initialSearchValue);

  const latestSearchValue = React.useRef<string>(initialSearchValue);

  const handleSearchValueChange = React.useCallback((value: string) => {
    latestSearchValue.current = value;
  }, []);

  const handleSearchTabChange = React.useCallback(
    (newTab: SearchTab | null) => {
      // first setting new search value, then setting new tab
      setSearchValue(latestSearchValue.current);
      setSearchTab(newTab);
    },
    []
  );

  const searchTabsOptions = React.useMemo(
    () =>
      [
        !allowedSearchTabs || allowedSearchTabs.includes("products")
          ? {
              value: "products" as SearchTab,
              // used in case if it's a single tab
              title: t("Select Products", {
                ns: "ProductSearch",
              }),
              label: t("Products", {
                ns: "ProductSearch",
              }),
              icon: <CategoryIcon />,
            }
          : null,
        !allowedSearchTabs || allowedSearchTabs.includes("templates")
          ? {
              value: "templates" as SearchTab,
              label: t("Templates", {
                ns: "TemplatesSearch",
              }),
              // used in case if it's a single tab
              title: t("Select Templates", {
                ns: "TemplatesSearch",
              }),
              icon: <TemplateIcon />,
            }
          : null,
      ].filter(notNull),
    [t, allowedSearchTabs]
  );

  const cartTabsOptions = React.useMemo(
    () => [
      {
        value: "cart" as CartTab,
        label: "",
        count:
          formikProps.values.products.length +
          formikProps.values.templates.length,
        icon: <ShoppingCartOutlinedIcon />,
      },
    ],
    [formikProps.values.products.length, formikProps.values.templates.length]
  );

  const Form = (
    <SearchProductsAndTemplatesForm
      searchTab={searchTab}
      showQuantityInput={showQuantityInput}
    />
  );

  React.useEffect(() => {
    if (!searchTab && !isMaxPhone) {
      handleSearchTabChange(allowedSearchTabs?.[0] || "products");
    }
  }, [searchTab, isMaxPhone, allowedSearchTabs, handleSearchTabChange]);

  const theme = useTheme();

  return (
    <Modal
      dialogProps={{
        maxWidth: "xl",
        PaperProps: { style: { height: "100%" } },
      }}
      titleProps={{
        sx: {
          position: "relative",
          zIndex: 2,
          backgroundColor: theme.palette.common.white,
        },
      }}
      handleClose={handleClose}
      title={
        searchTabsOptions.length === 1 && !isMaxPhone ? (
          <>
            <Box mr={1} display="flex" flexGrow={0} flexShrink={0}>
              {searchTabsOptions[0].icon}
            </Box>
            <span>{searchTabsOptions[0].title}</span>
          </>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <Tabs<SearchTab, TabOption<SearchTab>>
              onChange={handleSearchTabChange}
              value={searchTab ?? undefined}
              options={searchTabsOptions}
            />
            {isMaxPhone && (
              <Tabs<CartTab, TabOption<CartTab>>
                onChange={() => handleSearchTabChange(null)}
                value={!searchTab ? "cart" : undefined}
                options={cartTabsOptions}
              />
            )}
          </Stack>
        )
      }
      alwaysVisible
    >
      {searchTab === "products" && (
        <ProductSelectMultipleComponent
          initialSearchValue={searchValue}
          handleSearchValueChange={handleSearchValueChange}
          Form={Form}
          onProductSearchVariablesChange={onProductSearchVariablesChange}
          AddToListIcon={AddToListIcon}
        />
      )}
      {searchTab === "templates" && (
        <TemplatesQuoteSelectMultipleComponent
          excludeTemplateIds={excludeTemplateIds}
          rootItemTypes={rootItemTypes}
          initialSearchValue={searchValue}
          handleSearchValueChange={handleSearchValueChange}
          Form={Form}
          AddToListIcon={AddToListIcon}
          showQuantityInput={showQuantityInput}
        />
      )}
      {searchTab === null && isMaxPhone && (
        <Stack
          direction="column"
          spacing={1}
          flex={1}
          width="100%"
          height="100%"
        >
          {Form}
        </Stack>
      )}
    </Modal>
  );
};
