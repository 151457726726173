import { CollapseSection, DataGrid, GridColDef } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, useField } from "formik";
import { omit } from "lodash";
import React from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { useVerticals } from "../hooks/useVerticals";
import { Vertical, verticals } from "../types";
import {
  OrganisationVerticalSettingFragment,
  QuoteVerticalSettingFragment,
} from "./VerticalsFormField.generated";

export interface VerticalFormValues {
  id: string;
  vertical: Vertical;
  averageWorkBuyingPrice: number;
  averageWorkSellingPrice: number;
}

export const defaultVerticalFormValues: VerticalFormValues = {
  id: v4(),
  vertical: "general",
  averageWorkBuyingPrice: 30,
  averageWorkSellingPrice: 45,
};

export const getVerticalFormValues = (
  verticalSettings: (
    | OrganisationVerticalSettingFragment
    | QuoteVerticalSettingFragment
  )[]
): VerticalFormValues[] => {
  const values = verticalSettings.map(setting => omit(setting, "__typename"));
  return values.length > 0 ? values : [defaultVerticalFormValues];
};

export const getVerticalInputValues = (values: VerticalFormValues[]) => {
  return values;
};

export const useVerticalsFieldValidationSchema = (name: string) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  return React.useMemo(
    () =>
      Yup.array()
        .of(
          Yup.object().shape({
            vertical: Yup.string()
              .label(
                t("Vertical", {
                  ns: "OrganisationSettings",
                })
              )
              .required()
              .test(
                "unique",
                t("Value must be unique", {
                  ns: "Global",
                }),
                function (value: string | undefined) {
                  // @ts-ignore
                  const verticals = (this.from as any)[1].value[name] as {
                    vertical: string;
                  }[];
                  const sameValues = verticals.filter(
                    v => v.vertical === value
                  );
                  return sameValues.length <= 1;
                }
              ),
            averageWorkBuyingPrice: Yup.number()
              .label(
                t("Average work cost", {
                  ns: "OrganisationSettings",
                })
              )
              .min(0)
              .required(),
            averageWorkSellingPrice: Yup.number()
              .label(
                t("Average work selling price", {
                  ns: "OrganisationSettings",
                })
              )
              .min(0)
              .required(),
          })
        )
        .min(1)
        .test(
          "generalRequired",
          t("At least general vertical should be set", {
            ns: "OrganisationSettings",
          }),
          function (value: { vertical: string | undefined }[] | undefined) {
            return (
              !!value &&
              value.length > 0 &&
              value.some(v => v.vertical === "general")
            );
          }
        )
        .label(
          t("Verticals", {
            ns: "OrganisationSettings",
          })
        ),
    [t, name]
  );
};

interface Props {
  name: string;
  disabled?: boolean;
  defaultValue?: {
    averageWorkBuyingPrice: number;
    averageWorkSellingPrice: number;
  };
}

export const VerticalsFormField = ({ name, disabled, defaultValue }: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const [{ value }, { error }, { setValue, setTouched }] =
    useField<VerticalFormValues[]>(name);

  const { verticalOptions } = useVerticals();
  const verticalOptionsWithoutGeneral = React.useMemo(
    () => verticalOptions.filter(o => o.value !== "general"),
    [verticalOptions]
  );

  const columns = React.useMemo(
    () =>
      [
        {
          field: "vertical",
          headerName: t("Vertical", {
            ns: "OrganisationSettings",
          }),
          flex: 1.5,
          minWidth: 30,
          sortable: false,
          resizable: false,
          renderCell: ({ row, api }) => (
            <SelectField
              size="extra-small"
              name={`${name}.${api.getRowIndexRelativeToVisibleRows(row.id)}.vertical`}
              required
              readOnly={row.vertical === "general"}
              options={
                row.vertical === "general"
                  ? verticalOptions
                  : verticalOptionsWithoutGeneral
              }
              label={t("Vertical", {
                ns: "OrganisationSettings",
              })}
              disabled={disabled}
              onKeyDown={e => {
                e.stopPropagation();
              }}
              onKeyPress={e => {
                e.stopPropagation();
              }}
            />
          ),
        },
        {
          field: "averageWorkBuyingPrice",
          headerName: t("Average work cost", {
            ns: "OrganisationSettings",
          }),
          flex: 1,
          minWidth: 30,
          sortable: false,
          resizable: false,
          renderCell: ({ row, api }) => (
            <FormattedPriceField
              size="extra-small"
              name={`${name}.${api.getRowIndexRelativeToVisibleRows(row.id)}.averageWorkBuyingPrice`}
              type="price"
              label={t("Average work cost", {
                ns: "OrganisationSettings",
              })}
              disabled={disabled}
              onKeyDown={e => {
                e.stopPropagation();
              }}
              onKeyPress={e => {
                e.stopPropagation();
              }}
            />
          ),
        },
        {
          field: "averageWorkSellingPrice",
          headerName: t("Average work selling price", {
            ns: "OrganisationSettings",
          }),
          flex: 1,
          minWidth: 30,
          sortable: false,
          resizable: false,
          renderCell: ({ row, api }) => (
            <FormattedPriceField
              size="extra-small"
              name={`${name}.${api.getRowIndexRelativeToVisibleRows(row.id)}.averageWorkSellingPrice`}
              type="price"
              label={t("Average work selling price", {
                ns: "OrganisationSettings",
              })}
              disabled={disabled}
              onKeyDown={e => {
                e.stopPropagation();
              }}
              onKeyPress={e => {
                e.stopPropagation();
              }}
            />
          ),
        },
        {
          field: "actions",
          headerName: "",
          width: 20,
          sortable: false,
          resizable: false,
          renderCell: ({ row, api }) =>
            row.vertical === "general" ? null : (
              <IconButton
                size="small"
                color="primary"
                disabled={disabled}
                onClick={() => {
                  setValue(
                    value.filter(
                      (v, index) =>
                        index !== api.getRowIndexRelativeToVisibleRows(row.id)
                    )
                  );
                  setTouched(true);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            ),
        },
      ] as GridColDef<VerticalFormValues>[],
    [
      disabled,
      name,
      setValue,
      setTouched,
      t,
      value,
      verticalOptions,
      verticalOptionsWithoutGeneral,
    ]
  );

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        const getNextVertical = (): Vertical | null => {
          const vertical = verticals.find(
            v => !value.find(({ vertical }) => vertical === v)
          );
          return vertical ?? null;
        };
        const handleCreate = () => {
          const newValue: {
            averageWorkBuyingPrice: number;
            averageWorkSellingPrice: number;
          } = defaultValue ??
            value.find(v => v.vertical === "general") ?? {
              averageWorkBuyingPrice: 30,
              averageWorkSellingPrice: 45,
            };
          const newVertical = getNextVertical();
          if (!newVertical) return;

          const newItem =
            value?.length > 0
              ? {
                  id: v4(),
                  vertical: getNextVertical(),
                  averageWorkBuyingPrice: newValue.averageWorkBuyingPrice,
                  averageWorkSellingPrice: newValue.averageWorkSellingPrice,
                }
              : {
                  id: v4(),
                  vertical: "general",
                  averageWorkBuyingPrice: newValue.averageWorkBuyingPrice,
                  averageWorkSellingPrice: newValue.averageWorkSellingPrice,
                };
          arrayHelpers.push(newItem);
        };
        return (
          <CollapseSection
            title={t("Verticals", {
              ns: "OrganisationSettings",
            })}
            titleVariant="h4"
            isInitiallyExpanded={value.length > 0}
            itemCount={value.length}
            hideItemCountOnExpand={true}
            ActionButtons={
              value.length < verticals.length ? (
                <IconButton
                  disabled={disabled}
                  size="small"
                  color="primary"
                  onClick={handleCreate}
                >
                  <AddIcon />
                </IconButton>
              ) : undefined
            }
          >
            {value.length > 0 && (
              <DataGrid
                density="compact"
                hideFooter={true}
                columns={columns}
                rows={value}
                rowCount={value.length}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableMultipleColumnsFiltering
                disableMultipleRowSelection
                disableMultipleColumnsSorting
                disableRowSelectionOnClick
                disableVirtualization
                disableColumnReorder
                isCellEditable={() => false}
                isRowSelectable={() => false}
                borderless
              />
            )}
            {error && typeof error === "string" && (
              <Typography sx={{ mb: 1 }} variant="caption" color="error">
                {error}
              </Typography>
            )}
            {value.length < verticals.length ? (
              <Button
                color="secondary"
                variant="text"
                size="small"
                startIcon={<AddIcon />}
                disabled={disabled}
                onClick={handleCreate}
                sx={{ mt: 0.5 }}
              >
                {t("Add new vertical setting", {
                  ns: "OrganisationSettings",
                })}
              </Button>
            ) : null}
          </CollapseSection>
        );
      }}
    />
  );
};
