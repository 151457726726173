/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestProjectInfoModalFragment = { __typename: 'Request', id: string, title: string, description: string, categories: Array<Types.Skill>, type: Types.ProjectType, marketplaceFullAddress: boolean, earliestStart?: any | null, deadline?: any | null, budget?: { __typename: 'Money', currency: string, amount: number } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }>, address?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type ModifyRequestProjectInfoMutationVariables = Types.Exact<{
  input: Types.ModifyRequestInput;
}>;


export type ModifyRequestProjectInfoMutation = { modifyRequest: { __typename: 'ModifyRequestResult', request: { __typename: 'Request', id: string, title: string, description: string, categories: Array<Types.Skill>, type: Types.ProjectType, marketplaceFullAddress: boolean, earliestStart?: any | null, deadline?: any | null, budget?: { __typename: 'Money', currency: string, amount: number } | null, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> } } };

export const RequestProjectInfoModalFragmentDoc = gql`
    fragment RequestProjectInfoModal on Request {
  id
  title
  description
  categories
  type
  marketplaceFullAddress
  budget {
    currency
    amount
  }
  attachments {
    ...Attachment
  }
  earliestStart
  deadline
  address {
    ...AddressDetails__Address
  }
}
    ${AttachmentFragmentDoc}
${AddressDetails__AddressFragmentDoc}`;
export const ModifyRequestProjectInfoDocument = gql`
    mutation ModifyRequestProjectInfo($input: ModifyRequestInput!) {
  modifyRequest(input: $input) {
    request {
      id
      title
      description
      categories
      type
      marketplaceFullAddress
      budget {
        currency
        amount
      }
      attachments {
        ...Attachment
      }
      earliestStart
      deadline
    }
  }
}
    ${AttachmentFragmentDoc}`;
export type ModifyRequestProjectInfoMutationFn = Apollo.MutationFunction<ModifyRequestProjectInfoMutation, ModifyRequestProjectInfoMutationVariables>;

/**
 * __useModifyRequestProjectInfoMutation__
 *
 * To run a mutation, you first call `useModifyRequestProjectInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyRequestProjectInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyRequestProjectInfoMutation, { data, loading, error }] = useModifyRequestProjectInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyRequestProjectInfoMutation(baseOptions?: Apollo.MutationHookOptions<ModifyRequestProjectInfoMutation, ModifyRequestProjectInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyRequestProjectInfoMutation, ModifyRequestProjectInfoMutationVariables>(ModifyRequestProjectInfoDocument, options);
      }
export type ModifyRequestProjectInfoMutationHookResult = ReturnType<typeof useModifyRequestProjectInfoMutation>;
export type ModifyRequestProjectInfoMutationResult = Apollo.MutationResult<ModifyRequestProjectInfoMutation>;
export type ModifyRequestProjectInfoMutationOptions = Apollo.BaseMutationOptions<ModifyRequestProjectInfoMutation, ModifyRequestProjectInfoMutationVariables>;