/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkQuoteAsAcceptedModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  docId: Types.Scalars['ID']['input'];
}>;


export type MarkQuoteAsAcceptedModalQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, contractee: { __typename: 'DocActor', id: string, address?: { __typename: 'AddressSnapshot', city: string } | null, viewerCrmCompany?: { __typename: 'CrmCompany', id: string, buildings: Array<{ __typename: 'Building', id: string, title: string, buildingAddress?: { __typename: 'Address', id: string, countryCode: string, postalCode: string, city: string, streetLines1: string } | null }> } | null }, contractor: { __typename: 'DocActor', id: string, address?: { __typename: 'AddressSnapshot', city: string } | null } } | null }, project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, building?: { __typename: 'Building', id: string, buildingAddress?: { __typename: 'Address', id: string, city: string } | null } | null } | null } };

export type MarkQuoteAsAcceptedModal_SetDocActorAddressMutationVariables = Types.Exact<{
  input: Types.ModifyQuoteDocActorInput;
}>;


export type MarkQuoteAsAcceptedModal_SetDocActorAddressMutation = { modifyQuoteDocActor: { __typename: 'ModifyQuoteDocActorResult', docActor: { __typename: 'DocActor', id: string, address?: { __typename: 'AddressSnapshot', countryCode: string, postalCode: string, city: string, streetLines1: string } | null } } };


export const MarkQuoteAsAcceptedModalDocument = gql`
    query MarkQuoteAsAcceptedModal($projectId: ID!, $docId: ID!) {
  quote(projectId: $projectId, docId: $docId) {
    ... on QuoteResult {
      quote {
        id
        contractee {
          id
          address {
            city
          }
          viewerCrmCompany {
            id
            buildings {
              id
              title
              buildingAddress {
                id
                countryCode
                postalCode
                city
                streetLines1
              }
            }
          }
        }
        contractor {
          id
          address {
            city
          }
        }
      }
    }
  }
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        building {
          id
          buildingAddress {
            id
            city
            __typename
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMarkQuoteAsAcceptedModalQuery__
 *
 * To run a query within a React component, call `useMarkQuoteAsAcceptedModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarkQuoteAsAcceptedModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarkQuoteAsAcceptedModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useMarkQuoteAsAcceptedModalQuery(baseOptions: Apollo.QueryHookOptions<MarkQuoteAsAcceptedModalQuery, MarkQuoteAsAcceptedModalQueryVariables> & ({ variables: MarkQuoteAsAcceptedModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarkQuoteAsAcceptedModalQuery, MarkQuoteAsAcceptedModalQueryVariables>(MarkQuoteAsAcceptedModalDocument, options);
      }
export function useMarkQuoteAsAcceptedModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarkQuoteAsAcceptedModalQuery, MarkQuoteAsAcceptedModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarkQuoteAsAcceptedModalQuery, MarkQuoteAsAcceptedModalQueryVariables>(MarkQuoteAsAcceptedModalDocument, options);
        }
export function useMarkQuoteAsAcceptedModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MarkQuoteAsAcceptedModalQuery, MarkQuoteAsAcceptedModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarkQuoteAsAcceptedModalQuery, MarkQuoteAsAcceptedModalQueryVariables>(MarkQuoteAsAcceptedModalDocument, options);
        }
export type MarkQuoteAsAcceptedModalQueryHookResult = ReturnType<typeof useMarkQuoteAsAcceptedModalQuery>;
export type MarkQuoteAsAcceptedModalLazyQueryHookResult = ReturnType<typeof useMarkQuoteAsAcceptedModalLazyQuery>;
export type MarkQuoteAsAcceptedModalSuspenseQueryHookResult = ReturnType<typeof useMarkQuoteAsAcceptedModalSuspenseQuery>;
export type MarkQuoteAsAcceptedModalQueryResult = Apollo.QueryResult<MarkQuoteAsAcceptedModalQuery, MarkQuoteAsAcceptedModalQueryVariables>;
export const MarkQuoteAsAcceptedModal_SetDocActorAddressDocument = gql`
    mutation MarkQuoteAsAcceptedModal_SetDocActorAddress($input: ModifyQuoteDocActorInput!) {
  modifyQuoteDocActor(input: $input) {
    docActor {
      id
      address {
        countryCode
        postalCode
        city
        streetLines1
      }
    }
  }
}
    `;
export type MarkQuoteAsAcceptedModal_SetDocActorAddressMutationFn = Apollo.MutationFunction<MarkQuoteAsAcceptedModal_SetDocActorAddressMutation, MarkQuoteAsAcceptedModal_SetDocActorAddressMutationVariables>;

/**
 * __useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation__
 *
 * To run a mutation, you first call `useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markQuoteAsAcceptedModalSetDocActorAddressMutation, { data, loading, error }] = useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation(baseOptions?: Apollo.MutationHookOptions<MarkQuoteAsAcceptedModal_SetDocActorAddressMutation, MarkQuoteAsAcceptedModal_SetDocActorAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkQuoteAsAcceptedModal_SetDocActorAddressMutation, MarkQuoteAsAcceptedModal_SetDocActorAddressMutationVariables>(MarkQuoteAsAcceptedModal_SetDocActorAddressDocument, options);
      }
export type MarkQuoteAsAcceptedModal_SetDocActorAddressMutationHookResult = ReturnType<typeof useMarkQuoteAsAcceptedModal_SetDocActorAddressMutation>;
export type MarkQuoteAsAcceptedModal_SetDocActorAddressMutationResult = Apollo.MutationResult<MarkQuoteAsAcceptedModal_SetDocActorAddressMutation>;
export type MarkQuoteAsAcceptedModal_SetDocActorAddressMutationOptions = Apollo.BaseMutationOptions<MarkQuoteAsAcceptedModal_SetDocActorAddressMutation, MarkQuoteAsAcceptedModal_SetDocActorAddressMutationVariables>;