import React from "react";
import { DocType, PermissionName } from "../../clients/graphqlTypes";
import { Restricted, RestrictedWithDebug } from "./Restricted";

export interface DocumentByType {
  docType: DocType;
  viewerPermissions: PermissionName[];
}

interface Props
  extends Omit<React.ComponentProps<typeof Restricted>, "isRestricted"> {
  document: DocumentByType;
  permissionByType: Partial<Record<DocType, PermissionName>>;
}

export const RestrictedByDocumentPermissionByTypeWithDebug = ({
  document,
  permissionByType,
  children,
  ...props
}: Props) => {
  const permission = permissionByType[document.docType];
  if (!permission) return children;

  const isPermitted = document.viewerPermissions.includes(permission);
  return (
    <RestrictedWithDebug
      {...props}
      isRestricted={!isPermitted}
      debugLabel={`Document permission [${document.docType}]: ${permission}`}
      debugColor={"#DA70D6"}
    >
      {children}
    </RestrictedWithDebug>
  );
};
