import { gql } from "@apollo/client";
import { useState } from "react";

gql`
  fragment BubbleContextFragment on Project {
    id
    title
    channels {
      id
      messagesSinceLastRead
    }
  }

  query BubbleContextQuery {
    projects(limit: 0, filters: { kind: DEFAULT }) {
      ... on ProjectConnection {
        edges {
          node {
            id
            ...BubbleContextFragment
          }
        }
      }
    }
  }

  mutation BubbleContextMarkChannelAsRead($channelId: ID!) {
    markChannelAsRead(channelId: $channelId) {
      channel {
        id
        ...ChannelMessages__Channel
      }
    }
  }
`;

export type BubblesValue = {
  projectsTotal: number;
  // opportunitiesTotal: number;
  directQuoteRequest: number;
  perProject: { [projectId: string]: number | undefined };
  perChannel: { [channelId: string]: number | undefined };
};

const initialValue: BubblesValue = {
  projectsTotal: 0,
  // opportunitiesTotal: 0,
  directQuoteRequest: 0,
  perProject: {},
  perChannel: {},
};

export function useBubbleContext() {
  // const client = useApolloClient();
  const [numBubbles] = useState<BubblesValue>(initialValue);

  return {
    bubble: numBubbles,
    markChannelAsRead: async (channelId: string) => {
      // await markChannelAsRead({ variables: { channelId } });
      // // TODO react-query typings not up2date
      // refetch({ force: true } as any);
    },
  };
}
