import { gql, useApolloClient } from "@apollo/client";
import {
  CollapseChip,
  DataGrid,
  InfoMessage,
  Modal,
  ModalOpenButton,
  SearchInput,
  Tabs,
  useScreenWidth,
} from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { debounce, unionBy } from "lodash";
import React, { useMemo, useState } from "react";
import { useUserData } from "../../auth/useUserData";
import { Avatar } from "../../commons/Avatar";
import { FilterButton } from "../../commons/filters/FilterButton";
import {
  FilterChipBoolean,
  FilterChipGroup,
} from "../../commons/filters/FilterChip";
import { SwitchField } from "../../commons/form-fields/SwitchField";
import { Stack } from "../../commons/layout/Stack";
import { ProfileListItem } from "../../commons/ProfileListItem";
import { RESULTS_PER_PAGE_OPTIONS } from "../../constants";
import { Skill } from "../../../clients/graphqlTypes";
import { AddressWithBreaks } from "../addresses/AddressWithBreaks";
import { getAddressLabel } from "../addresses/helpers";
import CategoriesField from "../skill-categories/CategoriesField";
import { useCategories } from "../skill-categories/useCategories";
import { useDataGridStateStore } from "../users/useDataGridStateStore";
import {
  CraftsmenSearchOrganisationFragment,
  CraftsmenSearchQueryVariables,
  useCraftsmenSearchQuery,
} from "./CraftsmenSelectModal.generated";

interface Props {
  id?: string;
  projectId?: string;
  title?: string;
  handleClose: () => void;
  handleComplete: (
    selectedOrganisations: CraftsmenSearchOrganisationFragment[]
  ) => void | Promise<void>;
  loading?: boolean;
  omitOrganisationIds?: string[];
}

export function CraftsmenSelectModal({
  id,
  projectId,
  title,
  handleClose,
  handleComplete,
  loading,
  omitOrganisationIds,
}: Props) {
  const [selectedCraftsmen, setSelectedCraftsmen] = React.useState<
    CraftsmenSearchOrganisationFragment[]
  >([]);

  return (
    <Modal
      id={id}
      title={title} // ?? t("Craftsmen select")}
      handleClose={handleClose}
      maxWidth="xl"
      dialogProps={{
        PaperProps: { style: { height: "100%" } },
      }}
      alwaysVisible
      // actionButtons={[
      //   {
      //     label: t("Global::Cancel"),
      //     handleClick: handleClose,
      //     buttonProps: { variant: "text", disabled: loading },
      //   },
      //   {
      //     label: t("Global::Add"),
      //     handleClick: async () => {
      //       await handleComplete(
      //         selectedCraftsmen.map(craftsman => craftsman.id)
      //       );
      //       handleClose();
      //     },
      //     buttonProps: {
      //       loading,
      //       disabled: selectedCraftsmen.length === 0,
      //     },
      //   },
      // ]}
    >
      <CraftsmenSelect
        projectId={projectId}
        selectedCraftsmen={selectedCraftsmen}
        setSelectedCraftsmen={setSelectedCraftsmen}
        omitOrganisationIds={omitOrganisationIds}
        handleClose={handleClose}
        handleComplete={handleComplete}
        loading={loading}
      />
    </Modal>
  );
}

type QuickFilterValue = "ALL" | "MY_CRAFTSMEN" | "MY_PARTNERS" | "MARKETPLACE";

type Filters = Omit<
  CraftsmenSearchQueryVariables,
  "limit" | "offset" | "projectId"
>;

enum ContextView {
  Search = "search",
  List = "list",
}

function CraftsmenSelect({
  projectId,
  selectedCraftsmen,
  setSelectedCraftsmen,
  omitOrganisationIds,
  handleClose,
  handleComplete,
  loading,
}: {
  projectId?: string;
  selectedCraftsmen: CraftsmenSearchOrganisationFragment[];
  setSelectedCraftsmen: React.Dispatch<
    React.SetStateAction<CraftsmenSearchOrganisationFragment[]>
  >;
  omitOrganisationIds?: string[];
  handleClose: () => void;
  handleComplete: (
    selectedOrganisations: CraftsmenSearchOrganisationFragment[]
  ) => void | Promise<void>;
  loading?: boolean;
}) {
  const { t } = useTranslate([
    "Global",
    "CrmOrganisations",
    "OrganisationProfile",
    "SearchCraftsman",
  ]);
  const viewer = useUserData().currentUser!;
  const categories = useCategories();
  const { isMinTablet } = useScreenWidth();

  const [view, setView] = useState<ContextView>(ContextView.Search);

  const quickFilterSettings: Record<
    QuickFilterValue,
    { label: string; values: Filters }
  > = {
    ALL: {
      label: t("All", {
        ns: "Global",
      }),
      values: {},
    },
    MY_CRAFTSMEN: {
      label: t("My craftsmen", {
        ns: "SearchCraftsman",
      }),
      values: { filterMyContacts: true },
    },
    ...(viewer.organisation.organisationType !== "CLIENT"
      ? {
          MY_PARTNERS: {
            label: t("My partners", {
              ns: "SearchCraftsman",
            }),
            values: {
              filterMyContacts: true,
            },
          },
        }
      : ({} as Record<QuickFilterValue, { label: string; values: Filters }>)),
    MARKETPLACE: {
      label: t("Marketplace", {
        ns: "SearchCraftsman",
      }),
      values: { filterMyContacts: false },
    },
  };
  const [quickFilter, setQuickFilter] = React.useState<QuickFilterValue>("ALL");
  const [filters, setFilters] = React.useState<Filters>({});
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 25 });
  React.useEffect(() => {
    setPaginationModel(pm => ({ ...pm, page: 0 }));
  }, [filters]);

  const client = useApolloClient();
  const query = useCraftsmenSearchQuery({
    client,
    variables: {
      ...quickFilterSettings[quickFilter]?.values,
      ...filters,
      filterCategories:
        quickFilterSettings[quickFilter]?.values?.filterCategories ||
        filters.filterCategories
          ? (
              quickFilterSettings[quickFilter]?.values?.filterCategories ?? []
            ).concat(filters.filterCategories ?? [])
          : null,
      filterOmitOrganisationIds: omitOrganisationIds,
      projectId,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
    },
  });

  const searchResults =
    (query.data ?? query.previousData)?.searchCraftsmen?.edges?.map(
      e => e.node.organisation
    ) ?? [];
  const total =
    (query.data ?? query.previousData)?.searchCraftsmen?.totalCount ?? 0;

  function handleToggle(craftsman: CraftsmenSearchOrganisationFragment) {
    const currentIndex = selectedCraftsmen.findIndex(
      selectedCraftsman => selectedCraftsman.id === craftsman.id
    );
    const newSelected = [...selectedCraftsmen];

    if (currentIndex === -1) {
      newSelected.push(craftsman);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedCraftsmen(newSelected);
  }

  const [isDebouncing, setIsDebouncing] = useState(false);

  const debouncedSetFilters = useMemo(() => {
    return debounce((newValue: string) => {
      setFilters(filters => ({
        ...filters,
        filterSearchTerm: newValue,
      }));
      setIsDebouncing(false);
    }, 500);
  }, []);

  const isCheckboxDisabled = query.loading || isDebouncing;

  const stateStore = useDataGridStateStore("CraftsmenSelectModal");

  const list = (
    <>
      <SearchInput
        autoFocus
        placeholder={t("Search craftsmen", {
          ns: "SearchCraftsman",
        })}
        onChangeSearchTerm={(newValue: string) => {
          setIsDebouncing(true);
          debouncedSetFilters(newValue);
        }}
      />
      <Stack alignItems={"center"}>
        <Tabs
          options={Object.entries(quickFilterSettings).map(([key, value]) => {
            return {
              value: key as QuickFilterValue,
              label: value.label,
            };
          })}
          value={quickFilter}
          onChange={(newQuickFilter: QuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
          useSelectOnMobile
        />
        <ModalOpenButton
          Modal={FilterModal}
          modalProps={{
            selectedServices: filters.filterCategories ?? [],
            onlyMeisterCraftsmen: !!filters.filterOnlyMeisterCraftsmen,
            handleReset: () => setFilters(quickFilterSettings.ALL.values),
            handleApply: ({
              // selectedRelationships,
              selectedServices,
              onlyMeisterCraftsmen,
            }) =>
              setFilters(filters => ({
                ...filters,
                filterOnlyMeisterCraftsmen: onlyMeisterCraftsmen,
                filterCategories:
                  selectedServices.length > 0 ? selectedServices : null,
                // filterRelationships:
                //   selectedRelationships.length > 0
                //     ? selectedRelationships
                //     : null,
              })),
          }}
        >
          <FilterButton />
        </ModalOpenButton>
      </Stack>
      {((filters.filterCategories && filters.filterCategories.length > 0) ||
        filters.filterOnlyMeisterCraftsmen) && (
        <Stack flexWrap="wrap">
          {/* <FilterChipGroup
            label={t("CrmOrganisations::Relationship type")}
            values={filters.filterRelationships}
            getValueLabel={value => value}
            setValues={value =>
              setFilters(filters => ({
                ...filters,
                filterRelationships: value,
              }))
            }
          /> */}
          <FilterChipGroup
            label={t("Services", {
              ns: "OrganisationProfile",
            })}
            values={filters.filterCategories}
            getValueLabel={value => categories.categoryLabels[value]}
            setValues={value =>
              setFilters(filters => ({
                ...filters,
                filterCategories: value,
              }))
            }
          />
          {filters.filterOnlyMeisterCraftsmen && (
            <FilterChipBoolean
              value={filters.filterOnlyMeisterCraftsmen}
              getValueLabel={value =>
                value === true
                  ? t("Only Meister craftsmen", {
                      ns: "SearchCraftsman",
                    })
                  : ""
              }
              setValue={value =>
                setFilters(filters => ({
                  ...filters,
                  filterOnlyMeisterCraftsmen: value,
                }))
              }
            />
          )}
        </Stack>
      )}
      <DataGrid
        stateStore={stateStore}
        density="standard"
        loading={query.loading}
        hideFooter={total === 0}
        disableColumnFilter
        columns={[
          {
            field: "title",
            headerName: t("Craftsman company", {
              ns: "SearchCraftsman",
            }),
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: ({ row }) => (
              <Stack alignItems="center">
                <Avatar
                  size="s"
                  url={row.logo?.url}
                  initials={row.title.substring(0, 2)}
                />
                <Typography>{row.title}</Typography>
              </Stack>
            ),
          },
          {
            field: "address",
            headerName: t("Location", {
              ns: "SearchCraftsman",
            }),
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: ({ row }) =>
              row.branchAddress && (
                <AddressWithBreaks
                  useCountry={false}
                  useStreet={false}
                  address={row.branchAddress}
                />
              ),
          },
        ]}
        rows={searchResults}
        isRowSelectable={() => !isCheckboxDisabled}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={newPaginationModel => {
          setPaginationModel(newPaginationModel);
        }}
        pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
        rowCount={total}
        checkboxSelection
        rowSelectionModel={selectedCraftsmen.map(graftsman => graftsman.id)}
        onRowSelectionModelChange={newSelectionModel => {
          setSelectedCraftsmen(selectedCraftsmen =>
            unionBy(
              selectedCraftsmen.filter(
                craftsman =>
                  !searchResults.map(result => result.id).includes(craftsman.id)
              ),
              searchResults.filter(result =>
                newSelectionModel.includes(result.id)
              ),
              item => item.id
            )
          );
        }}
        keepNonExistentRowsSelected
      />
    </>
  );

  const form = (
    <>
      <Stack alignItems={"center"}>
        <Typography variant="h3">
          {t("Selected companies", {
            ns: "SearchCraftsman",
          })}
        </Typography>
        <CollapseChip
          variant="outlined"
          size="small"
          label={selectedCraftsmen.length}
        />
      </Stack>
      <Stack
        flexDirection={"column"}
        justifyContent={selectedCraftsmen.length > 0 ? "flex-start" : "center"}
        overflow="auto"
        flex={1}
        minHeight={0}
      >
        {selectedCraftsmen.length > 0 ? (
          <Paper>
            <List disablePadding>
              {selectedCraftsmen.map((craftsman, index, array) => (
                <ProfileListItem
                  key={craftsman.id}
                  divider={index < array.length - 1}
                  size="s"
                  avatar={
                    <Avatar
                      size="s"
                      url={craftsman.logo?.url}
                      initials={craftsman.title.substring(0, 2)}
                    />
                  }
                  primary={craftsman.title}
                  secondary={getAddressLabel(
                    craftsman.branchAddress,
                    undefined,
                    undefined,
                    undefined,
                    true
                  )}
                  ActionButton={
                    <IconButton
                      color="primary"
                      onClick={() => handleToggle(craftsman)}
                      edge="end"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                />
              ))}
            </List>
          </Paper>
        ) : (
          <Box p={4} alignSelf={"center"}>
            <InfoMessage
              message={t(
                "Search and add companies from the list on the left.",
                {
                  ns: "SearchCraftsman",
                }
              )}
            />
          </Box>
        )}
      </Stack>
      <Stack flexDirection="row" spacing={1} justifyContent="flex-end">
        <Button variant="text" onClick={handleClose} disabled={loading}>
          {t("Cancel", {
            ns: "Global",
          })}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          disabled={loading || selectedCraftsmen.length === 0}
          onClick={async () => {
            await handleComplete(selectedCraftsmen);
            handleClose();
          }}
        >
          {t("Add", {
            ns: "Global",
          })}
        </LoadingButton>
      </Stack>
    </>
  );

  return isMinTablet ? (
    <Stack alignItems={"stretch"} height="100%" spacing={2}>
      <Stack
        flexDirection={"column"}
        flex={1}
        height={"100%"}
        overflow={"auto"}
      >
        {list}
      </Stack>
      <Divider orientation="vertical" flexItem />
      <Stack
        flexDirection={"column"}
        height={"100%"}
        overflow={"hidden"}
        sx={{ width: theme => theme.layout.columnWidth.sm }}
      >
        {form}
      </Stack>
    </Stack>
  ) : (
    <TabContext value={view}>
      <Stack
        height="100%"
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
      >
        <TabList
          onChange={(event: React.ChangeEvent<{}>, newValue: ContextView) => {
            setView(newValue);
          }}
          variant="fullWidth"
          scrollButtons={false}
          sx={{ mt: -1.5 }}
        >
          <Tab
            icon={<SearchIcon />}
            value={ContextView.Search}
            label={t("Search", {
              ns: "Global",
            })}
            aria-label="Search"
          />
          <Tab
            icon={<EditIcon />}
            value={ContextView.List}
            label={`${t("Companies", {
              ns: "SearchCraftsman",
            })} (${selectedCraftsmen.length})`}
            aria-label="List"
          />
        </TabList>

        <TabPanel
          style={{
            height: "100%",
            flexShrink: 1,
            flexGrow: 1,
            overflow: "auto",
            padding: 0,
          }}
          value={ContextView.Search}
        >
          <Stack
            flexDirection={"column"}
            spacing={1}
            height="100%"
            width="100%"
            pt={1}
          >
            {list}
          </Stack>
        </TabPanel>
        <TabPanel
          style={{
            height: "100%",
            flexShrink: 1,
            flexGrow: 1,
            overflow: "hidden",
            padding: 0,
          }}
          value={ContextView.List}
        >
          <Stack
            flexDirection={"column"}
            overflow="hidden"
            height="100%"
            width="100%"
            spacing={1}
            pt={1}
          >
            {form}
          </Stack>
        </TabPanel>
      </Stack>
    </TabContext>
  );
}

function FilterModal({
  selectedServices,
  // selectedRelationships,
  onlyMeisterCraftsmen,
  handleClose,
  handleReset,
  handleApply,
}: {
  selectedServices: Skill[];
  onlyMeisterCraftsmen: boolean;
  handleClose: () => void;
  handleReset: () => void;
  handleApply: ({
    selectedServices,
    onlyMeisterCraftsmen,
  }: {
    selectedServices: Skill[];
    onlyMeisterCraftsmen: boolean;
  }) => void;
}) {
  const { t } = useTranslate([
    "Global",
    "CrmOrganisations",
    "OrganisationProfile",
    "SearchCraftsman",
  ]);

  return (
    <Formik
      initialValues={{
        services: selectedServices,
        onlyMeisterCraftsmen: onlyMeisterCraftsmen,
      }}
      onSubmit={() => {}}
    >
      {formikProps => {
        return (
          <Modal
            title={t("Filters", {
              ns: "Global",
            })}
            handleClose={handleClose}
            actionButtons={[
              {
                label: t("Reset", {
                  ns: "Global",
                }),
                handleClick: () => {
                  handleReset();
                  handleClose();
                },
                buttonProps: { variant: "text" },
              },
              {
                label: t("Apply", {
                  ns: "Global",
                }),
                handleClick: () => {
                  handleApply({
                    selectedServices: formikProps.values.services,
                    // selectedRelationships: formikProps.values.relationships,
                    onlyMeisterCraftsmen:
                      formikProps.values.onlyMeisterCraftsmen,
                  });
                  handleClose();
                },
                buttonProps: {
                  color: "primary",
                  variant: "contained",
                },
              },
            ]}
          >
            <Form>
              <Stack flexDirection={"column"}>
                <CategoriesField
                  name="services"
                  label={t("Services", {
                    ns: "OrganisationProfile",
                  })}
                />
                {/* <Field component={TextField} label={"Tags"} name="tags" /> */}

                <SwitchField
                  label={t("Only Meister craftsmen", {
                    ns: "SearchCraftsman",
                  })}
                  name="onlyMeisterCraftsmen"
                />
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
