import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, ErrorMessage, LoadingSpinner } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { DiscardPendingChangesButton } from "../../../quotes/buttons/DiscardPendingChangesButton";
import { usePendingChangesBoxQuery } from "./PendingChangesBox.generated";

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
}

export const PendingChangesBox = ({ itemId, docId, projectId }: Props) => {
  const { t } = useTranslate("QuoteItem");

  const client = useApolloClient();
  const query = usePendingChangesBoxQuery({
    client,
    variables: {
      projectId,
      docId,
      itemId,
    },
    fetchPolicy: "cache-and-network",
  });

  const item = getDataOrNull(query.data?.item);

  if (query.loading) {
    return <LoadingSpinner />;
  } else if (query.error) {
    return <ErrorMessage message={query.error.message} />;
  }

  if (!item) return <div>item not found</div>;
  if (Object.keys(item.pendingChangeAttributes).length === 0) return null;

  return (
    <CardContainer title={t("Pending changes")}>
      {Object.keys(item.pendingChangeAttributes).length > 0 && (
        <textarea
          style={{ width: "200px", height: "200px" }}
          value={JSON.stringify(item.pendingChangeAttributes, null, 2)}
          readOnly
        />
      )}
      <DiscardPendingChangesButton
        projectId={projectId}
        quoteId={docId}
        itemId={itemId}
        type="button"
        color="secondary"
        variant="outlined"
        size="medium"
      />
    </CardContainer>
  );
};
