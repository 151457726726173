import { usePaginationParams } from "../pagination/usePaginationParams";
import { useStateWithUrlParams } from "../hooks/useStateWithUrlParams";

export function useFiltersAndPagination<Sorting = {}, Filters = {}>(
  defaultFilters: Filters,
  defaultSortings: Sorting[],
  defaultItemsPerPage: number = 25,
  pageUrlParam: string = "page",
  perPageUrlParam: string = "pageSize"
) {
  const {
    offset,
    limit,
    page,
    setPage,
    perPage,
    setPerPage,
    paginationModel,
    setPaginationModel,
  } = usePaginationParams({
    perPageDefaultAmount: defaultItemsPerPage,
    pageUrlParam,
    perPageUrlParam,
  });

  const toRemoveParams: string[] = [pageUrlParam];

  const [filters, setFilters] = useStateWithUrlParams<Filters>(
    "filters",
    defaultFilters,
    toRemoveParams
  );
  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const [sorting, setSorting] = useStateWithUrlParams<Sorting[]>(
    "sortings",
    defaultSortings,
    toRemoveParams
  );

  return {
    offset,
    limit,
    page,
    setPage,
    perPage,
    setPerPage,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  };
}
