/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { OrganisationVerticalSettingFragmentDoc, QuoteVerticalSettingFragmentDoc } from '../../verticals/form-fields/VerticalsFormField.generated';
import { BuildingRow__BuildingFragmentDoc } from '../../buildings/Buildings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketCreateModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TicketCreateModalQuery = { organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultVerticalSettings: Array<{ __typename: 'OrganisationVerticalSetting', id: string, vertical: Types.Vertical, averageWorkBuyingPrice: number, averageWorkSellingPrice: number }> } };

export type TicketCreateModalCrmOrganisationsBuildingQueryVariables = Types.Exact<{
  crmCompanyId: Types.Scalars['ID']['input'];
}>;


export type TicketCreateModalCrmOrganisationsBuildingQuery = { crmCompany: { __typename: 'CrmCompanyResult', crmCompany?: { __typename: 'CrmCompany', id: string, title: string, buildings: Array<{ __typename: 'Building', id: string, title: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, pathForPdf: string }>, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } }> } | null } | { __typename: 'MissingPermissions' } };

export type CreateTicketModal_CreateDocumentItemMutationVariables = Types.Exact<{
  input: Types.CreateItemsInput;
}>;


export type CreateTicketModal_CreateDocumentItemMutation = { createItems: { __typename: 'CreateItemsResult', items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } };


export const TicketCreateModalDocument = gql`
    query TicketCreateModal {
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      defaultVerticalSettings {
        id
        ...OrganisationVerticalSetting
      }
    }
  }
}
    ${OrganisationVerticalSettingFragmentDoc}`;

/**
 * __useTicketCreateModalQuery__
 *
 * To run a query within a React component, call `useTicketCreateModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketCreateModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketCreateModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketCreateModalQuery(baseOptions?: Apollo.QueryHookOptions<TicketCreateModalQuery, TicketCreateModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketCreateModalQuery, TicketCreateModalQueryVariables>(TicketCreateModalDocument, options);
      }
export function useTicketCreateModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketCreateModalQuery, TicketCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketCreateModalQuery, TicketCreateModalQueryVariables>(TicketCreateModalDocument, options);
        }
export function useTicketCreateModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TicketCreateModalQuery, TicketCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketCreateModalQuery, TicketCreateModalQueryVariables>(TicketCreateModalDocument, options);
        }
export type TicketCreateModalQueryHookResult = ReturnType<typeof useTicketCreateModalQuery>;
export type TicketCreateModalLazyQueryHookResult = ReturnType<typeof useTicketCreateModalLazyQuery>;
export type TicketCreateModalSuspenseQueryHookResult = ReturnType<typeof useTicketCreateModalSuspenseQuery>;
export type TicketCreateModalQueryResult = Apollo.QueryResult<TicketCreateModalQuery, TicketCreateModalQueryVariables>;
export const TicketCreateModalCrmOrganisationsBuildingDocument = gql`
    query TicketCreateModalCrmOrganisationsBuilding($crmCompanyId: ID!) {
  crmCompany(id: $crmCompanyId) {
    ... on CrmCompanyResult {
      crmCompany {
        id
        title
        buildings {
          id
          ...BuildingRow__Building
          items {
            id
            originVersionNumber
            title
            pathForPdf
          }
        }
      }
    }
  }
}
    ${BuildingRow__BuildingFragmentDoc}`;

/**
 * __useTicketCreateModalCrmOrganisationsBuildingQuery__
 *
 * To run a query within a React component, call `useTicketCreateModalCrmOrganisationsBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketCreateModalCrmOrganisationsBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketCreateModalCrmOrganisationsBuildingQuery({
 *   variables: {
 *      crmCompanyId: // value for 'crmCompanyId'
 *   },
 * });
 */
export function useTicketCreateModalCrmOrganisationsBuildingQuery(baseOptions: Apollo.QueryHookOptions<TicketCreateModalCrmOrganisationsBuildingQuery, TicketCreateModalCrmOrganisationsBuildingQueryVariables> & ({ variables: TicketCreateModalCrmOrganisationsBuildingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketCreateModalCrmOrganisationsBuildingQuery, TicketCreateModalCrmOrganisationsBuildingQueryVariables>(TicketCreateModalCrmOrganisationsBuildingDocument, options);
      }
export function useTicketCreateModalCrmOrganisationsBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketCreateModalCrmOrganisationsBuildingQuery, TicketCreateModalCrmOrganisationsBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketCreateModalCrmOrganisationsBuildingQuery, TicketCreateModalCrmOrganisationsBuildingQueryVariables>(TicketCreateModalCrmOrganisationsBuildingDocument, options);
        }
export function useTicketCreateModalCrmOrganisationsBuildingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TicketCreateModalCrmOrganisationsBuildingQuery, TicketCreateModalCrmOrganisationsBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TicketCreateModalCrmOrganisationsBuildingQuery, TicketCreateModalCrmOrganisationsBuildingQueryVariables>(TicketCreateModalCrmOrganisationsBuildingDocument, options);
        }
export type TicketCreateModalCrmOrganisationsBuildingQueryHookResult = ReturnType<typeof useTicketCreateModalCrmOrganisationsBuildingQuery>;
export type TicketCreateModalCrmOrganisationsBuildingLazyQueryHookResult = ReturnType<typeof useTicketCreateModalCrmOrganisationsBuildingLazyQuery>;
export type TicketCreateModalCrmOrganisationsBuildingSuspenseQueryHookResult = ReturnType<typeof useTicketCreateModalCrmOrganisationsBuildingSuspenseQuery>;
export type TicketCreateModalCrmOrganisationsBuildingQueryResult = Apollo.QueryResult<TicketCreateModalCrmOrganisationsBuildingQuery, TicketCreateModalCrmOrganisationsBuildingQueryVariables>;
export const CreateTicketModal_CreateDocumentItemDocument = gql`
    mutation CreateTicketModal_CreateDocumentItem($input: CreateItemsInput!) {
  createItems(input: $input) {
    items {
      id
      originVersionNumber
    }
  }
}
    `;
export type CreateTicketModal_CreateDocumentItemMutationFn = Apollo.MutationFunction<CreateTicketModal_CreateDocumentItemMutation, CreateTicketModal_CreateDocumentItemMutationVariables>;

/**
 * __useCreateTicketModal_CreateDocumentItemMutation__
 *
 * To run a mutation, you first call `useCreateTicketModal_CreateDocumentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketModal_CreateDocumentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketModalCreateDocumentItemMutation, { data, loading, error }] = useCreateTicketModal_CreateDocumentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketModal_CreateDocumentItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateTicketModal_CreateDocumentItemMutation, CreateTicketModal_CreateDocumentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTicketModal_CreateDocumentItemMutation, CreateTicketModal_CreateDocumentItemMutationVariables>(CreateTicketModal_CreateDocumentItemDocument, options);
      }
export type CreateTicketModal_CreateDocumentItemMutationHookResult = ReturnType<typeof useCreateTicketModal_CreateDocumentItemMutation>;
export type CreateTicketModal_CreateDocumentItemMutationResult = Apollo.MutationResult<CreateTicketModal_CreateDocumentItemMutation>;
export type CreateTicketModal_CreateDocumentItemMutationOptions = Apollo.BaseMutationOptions<CreateTicketModal_CreateDocumentItemMutation, CreateTicketModal_CreateDocumentItemMutationVariables>;