import { useApolloClient } from "@apollo/client";
import { MenuButton, StatusChip } from "@msys/ui";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem } from "@mui/material";
import React from "react";
import {
  useQuoteSapS4HanaDataQuery,
  useSetQuoteSapS4HanaStatusMutation,
} from "./QuoteSapStatusChip.generated";

interface Props {
  projectId: string;
  quoteId: string;
  canEdit?: boolean;
}

export const QuoteSapStatusChip = ({
  canEdit = true,
  projectId,
  quoteId,
}: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const client = useApolloClient();
  const query = useQuoteSapS4HanaDataQuery({
    client,
    variables: { projectId, quoteId },
  });
  const [handleStateChange] = useSetQuoteSapS4HanaStatusMutation({ client });

  const quoteSapS4HanaData = query.data?.quoteSapS4HanaData;
  const currentStatus = quoteSapS4HanaData?.xStatus ?? null;
  const xStatusMetadata =
    query.data?.quoteSapS4HanaData?.sapS4HanaDataFieldMetadata?.find(
      d => d.key === "xStatus"
    );

  const availableStatuses = xStatusMetadata?.allowedValues ?? [];

  if (!quoteSapS4HanaData) return null;

  return !canEdit ? (
    <StatusChip
      disabled={
        !xStatusMetadata ||
        !xStatusMetadata.editable ||
        availableStatuses.length < 1
      }
      label={currentStatus ?? "–"}
    />
  ) : (
    <MenuButton
      Button={
        <StatusChip
          disabled={
            !xStatusMetadata ||
            !xStatusMetadata.editable ||
            availableStatuses.length < 1
          }
          label={currentStatus ?? "–"}
          endIcon={
            <KeyboardArrowDown
              fontSize="small"
              sx={{
                transition: "transform 0.2s ease-out",
                ...(open ? { transform: `rotate(-180deg)` } : undefined),
              }}
            />
          }
        />
      }
      onToggle={setOpen}
      hideWhenEmpty={false}
    >
      {availableStatuses
        .filter(status => status.value !== currentStatus)
        .map(status => (
          <MenuItem
            key={status.value}
            onClick={async () => {
              await handleStateChange({
                variables: {
                  input: {
                    projectId,
                    docId: quoteId,
                    values: { xStatus: status.value },
                  },
                },
              });
            }}
          >
            {status.label}
          </MenuItem>
        ))}
    </MenuButton>
  );
};
