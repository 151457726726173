import { VatCountryCode, vatRates } from "@msys/vatutils";
import React from "react";
import { useTranslate } from "@tolgee/react";

export function useVatCountryCodes() {
  const { t } = useTranslate("Countries");

  const vatCountryCodeLabels: Record<VatCountryCode, string> = React.useMemo(
    () =>
      vatRates.reduce(
        (acc, rate) => ({ ...acc, [rate.countryCode]: t(rate.countryName) }),
        {} as Record<VatCountryCode, string>
      ),
    [t]
  );

  const vatCountryCodeOptions: {
    value: VatCountryCode;
    label: string;
  }[] = React.useMemo(() => {
    const options = vatRates.map(rate => ({
      value: rate.countryCode,
      label: t(rate.countryName),
    }));
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  }, [t]);

  return { vatCountryCodeLabels, vatCountryCodeOptions };
}
