import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  getFormattedFloat,
  getFormattedNumber,
  LabeledValue,
  MenuButton,
  MenuItemWithIcon,
  TableColumn,
  TableInner,
  TextWithBreaks,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isNil, keyBy } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { namedOperations, PermissionName } from "../../../clients/graphqlTypes";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { ViewMode } from "../../commons/ViewModeMenuItem";
import { AutocompleteField } from "../../commons/form-fields/AutocompleteField";
import { ManualSave } from "../../commons/form-fields/ManualSave";
import {
  S4HanaCancelReasonSelectProcess,
  S4HanaCancelReasonSelectProcessRef,
} from "./S4HanaCancelReasonSelectProcess";
import {
  useS4HanaQuoteItemDataBoxQuery,
  useSapS4HanaCancelItemMutation,
  useSapS4HanaModifyItemDataMutation,
  useSapS4HanaUncancelItemMutation,
} from "./S4HanaQuoteItemDataBox.generated";
import {
  ItemSapS4HanaDataFragment,
  ItemSapS4HanaPricingDataFragment,
  S4HanaEntityFieldMetadataFragment,
} from "./S4HanaQuoteMenuButton.generated";

interface FormValues {
  itemTexts: {
    xLongTextId: string;
    xLongTextValue: string;
  }[];
  xPlant: string | null;
  xMaterialGroup: string | null;
  xMaterialByCustomer: string | null;
}

interface Props {
  projectId: string;
  quoteId: string;
  itemId: string;
  docViewerPermissions: PermissionName[];
  expandedItemIds: string[] | undefined;
  canEdit: boolean;
}

export const S4HanaQuoteItemDataBox = ({
  projectId,
  quoteId,
  itemId,
  docViewerPermissions,
  expandedItemIds,
  canEdit,
}: Props) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const cancelReasonSelectProcessRef =
    React.useRef<S4HanaCancelReasonSelectProcessRef>(null);

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();

  const query = useS4HanaQuoteItemDataBoxQuery({
    client,
    variables: {
      projectId,
      quoteId,
      itemId,
    },
  });

  const sapDocData = query.data?.quoteSapS4HanaData;

  const sapItemData = getDataOrNull(
    query.data?.quoteItemSapS4HanaData
  )?.items?.at(0);

  const [cancelItem, { loading: cancelItemIsLoading }] =
    useSapS4HanaCancelItemMutation({
      client,
      variables: {
        input: { projectId, docId: quoteId, itemId, expandedItemIds },
      },
    });
  const [uncancelItem, { loading: uncancelItemIsLoading }] =
    useSapS4HanaUncancelItemMutation({
      client,
      variables: {
        input: { projectId, docId: quoteId, itemId, expandedItemIds },
      },
    });

  const availableCancelReasons =
    query.data?.quoteSapS4HanaData
      ?.xAvailableSalesQuotationItemSalesDocumentRejectionReasons ?? [];
  const defaultCancelReason =
    query.data?.quoteSapS4HanaData
      ?.xSalesQuotationItemSalesDocumentRejectionReasonDefault;
  const availableTexts =
    query.data?.quoteSapS4HanaData?.xAvailableSalesQuotationItemTexts ?? [];

  return (
    <CardContainer
      isExpandable
      title={t("SAP details", { ns: "SapS4Hana" })}
      ActionButton={
        <RestrictedByDocumentPermissionWithDebug
          permission="MANAGE_QUOTES"
          document={{ viewerPermissions: docViewerPermissions }}
        >
          {
            <MenuButton>
              {canEdit && viewMode === null && (
                <MenuItemWithIcon
                  icon={<EditIcon />}
                  onClick={() => setViewMode("edit")}
                >
                  {t("Edit", { ns: "Global" })}
                </MenuItemWithIcon>
              )}
              {sapItemData &&
                sapItemData.xId &&
                (!sapItemData.xSalesDocumentRejectionReason ? (
                  <MenuItem
                    onClick={async () => {
                      const cancelReasonCode =
                        await cancelReasonSelectProcessRef.current?.startProcess(
                          {
                            availableReasons: availableCancelReasons ?? [],
                            defaultReason: defaultCancelReason || null,
                            isReasonRequired: true,
                          }
                        );
                      if (cancelReasonCode === undefined) return;
                      try {
                        await cancelItem({
                          variables: {
                            input: {
                              projectId,
                              docId: quoteId,
                              itemId,
                              reason: cancelReasonCode,
                              expandedItemIds,
                            },
                          },
                        });
                        enqueueSnackbar(
                          t("Item cancelled", { ns: "SapS4Hana" })
                        );
                      } catch (e) {
                        if (e instanceof Error)
                          enqueueSnackbar(e.message, { variant: "error" });
                      }
                    }}
                    disabled={cancelItemIsLoading}
                  >
                    {t("Cancel SAP item", { ns: "SapS4Hana" })}
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={async () => {
                      try {
                        await uncancelItem({
                          variables: {
                            input: {
                              projectId,
                              docId: quoteId,
                              itemId,
                              expandedItemIds,
                            },
                          },
                        });
                        enqueueSnackbar(
                          t("Item restored", { ns: "SapS4Hana" })
                        );
                      } catch (e) {
                        if (e instanceof Error)
                          enqueueSnackbar(e.message, { variant: "error" });
                      }
                    }}
                    disabled={uncancelItemIsLoading}
                  >
                    {t("Restore SAP item", { ns: "SapS4Hana" })}
                  </MenuItem>
                ))}
            </MenuButton>
          }
        </RestrictedByDocumentPermissionWithDebug>
      }
    >
      {viewMode === null && (
        <S4HanaQuoteItemData
          sapItemData={sapItemData}
          availableCancelReasons={availableCancelReasons}
          availableItemConditionTypes={
            sapDocData?.availableItemConditionTypes ?? []
          }
          availableTexts={availableTexts}
        />
      )}
      {viewMode === "edit" && (
        <S4HanaQuoteItemForm
          docId={quoteId}
          projectId={projectId}
          itemId={itemId}
          sapItemData={sapItemData}
          availableItemConditionTypes={
            sapDocData?.availableItemConditionTypes ?? []
          }
          availableCancelReasons={availableCancelReasons}
          availableTexts={availableTexts}
          setViewMode={setViewMode}
        />
      )}
      <S4HanaCancelReasonSelectProcess ref={cancelReasonSelectProcessRef} />
    </CardContainer>
  );
};

function S4HanaQuoteItemData({
  sapItemData,
  availableCancelReasons,
  availableItemConditionTypes,
  availableTexts,
}: {
  sapItemData: ItemSapS4HanaDataFragment | undefined | null;
  availableCancelReasons: { label: string; value: string }[];
  availableItemConditionTypes: { label: string; value: string }[];
  availableTexts: { label: string; value: string }[];
}) {
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const sapS4HanaDataFieldMetadataByKey = React.useMemo(() => {
    return keyBy(sapItemData?.sapS4HanaDataFieldMetadata ?? [], d => d.key);
  }, [sapItemData?.sapS4HanaDataFieldMetadata]);

  const xPlantLabel = React.useMemo(() => {
    return sapS4HanaDataFieldMetadataByKey["xPlant"]?.allowedValues?.find(
      v => v.value === sapItemData?.xPlant
    )?.label;
  }, [sapS4HanaDataFieldMetadataByKey, sapItemData?.xPlant]);

  return (
    <Stack direction="column" spacing={1} p={1}>
      <Box>
        <Grid container columns={2} spacing={1}>
          <Grid item xs={1}>
            <LabeledValue label={t("Reference no.", { ns: "SapS4Hana" })}>
              {sapItemData?.xId || "–"}
            </LabeledValue>
          </Grid>
          <Grid item xs={1}>
            <LabeledValue label={t("Item category", { ns: "SapS4Hana" })}>
              {sapItemData?.xSalesQuotationItemCategoryLatest || "–"}
            </LabeledValue>
          </Grid>
          <Grid item xs={1}>
            <LabeledValue
              label={t("Reference document ID", { ns: "SapS4Hana" })}
            >
              {sapItemData?.xReferenceDocumentId || "–"}
            </LabeledValue>
          </Grid>
          <Grid item xs={1}>
            <LabeledValue label={t("Reference item ID", { ns: "SapS4Hana" })}>
              {sapItemData?.xReferenceItemId || "–"}
            </LabeledValue>
          </Grid>
          <Grid item xs={1}>
            <LabeledValue label={t("Plant", { ns: "SapS4Hana" })}>
              {sapItemData?.xPlant ? xPlantLabel ?? sapItemData.xPlant : "–"}
            </LabeledValue>
          </Grid>
          <Grid item xs={1}>
            <LabeledValue label={t("Material group", { ns: "SapS4Hana" })}>
              {sapItemData?.xMaterialGroup || "–"}
            </LabeledValue>
          </Grid>
          <Grid item xs={1}>
            <LabeledValue
              label={t("Material by customer", { ns: "SapS4Hana" })}
            >
              {sapItemData?.xMaterialByCustomer || "–"}
            </LabeledValue>
          </Grid>
          {sapItemData?.xSalesDocumentRejectionReason && (
            <Grid item xs={2}>
              <LabeledValue
                label={t("Sales rejection reason", {
                  ns: "SapS4Hana",
                })}
              >
                {availableCancelReasons?.find(
                  r => r.value === sapItemData.xSalesDocumentRejectionReason
                )?.label ?? sapItemData.xSalesDocumentRejectionReason}
              </LabeledValue>
            </Grid>
          )}
        </Grid>
      </Box>

      {sapItemData && sapItemData.pricingData.length > 0 && (
        <Box sx={{ overflow: "auto" }}>
          <S4HanaQuoteItemPriceTable
            pricingData={sapItemData.pricingData}
            availableItemConditionTypes={availableItemConditionTypes}
          />
        </Box>
      )}

      <Box sx={{ overflow: "auto" }}>
        <Typography variant="h4">
          {t("Item texts", { ns: "SapS4Hana" })}
        </Typography>

        {sapItemData && sapItemData.itemTexts.length > 0 ? (
          <TableInner<{ id: string; value: string }>
            size="extra-small"
            columns={[
              {
                id: "type",
                title: t("Type", { ns: "Global" }),
                cellStyle: { minWidth: "100px" },
                width: "35%",
                render: row => {
                  const type = availableTexts.find(v => v.value === row.id);
                  if (!type) return row.id;
                  return type.label;
                },
              },
              {
                id: "value",
                title: t("Value", { ns: "Global" }),
                width: "65%",
                render: row => <TextWithBreaks text={row.value} />,
              },
            ]}
            items={sapItemData.itemTexts.map(t => ({
              id: t.xLongTextId,
              value: t.xLongTextValue,
            }))}
          />
        ) : (
          <Typography>{t("Not set", { ns: "Global" })}</Typography>
        )}
      </Box>
    </Stack>
  );
}

function S4HanaQuoteItemForm({
  itemId,
  docId,
  projectId,
  sapItemData,
  availableCancelReasons,
  availableItemConditionTypes,
  availableTexts,
  setViewMode,
}: {
  itemId: string;
  docId: string;
  projectId: string;
  sapItemData: ItemSapS4HanaDataFragment | undefined | null;
  availableItemConditionTypes: { label: string; value: string }[];
  availableCancelReasons: { label: string; value: string }[];
  availableTexts: { label: string; value: string }[];
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
}) {
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormValues = {
    itemTexts: sapItemData?.itemTexts || [],
    xMaterialByCustomer: sapItemData?.xMaterialByCustomer || null,
    xMaterialGroup: sapItemData?.xMaterialGroup || null,
    xPlant: sapItemData?.xPlant || null,
  };

  const sapS4HanaDataFieldMetadataByKey = React.useMemo(() => {
    return keyBy(sapItemData?.sapS4HanaDataFieldMetadata ?? [], d => d.key);
  }, [sapItemData?.sapS4HanaDataFieldMetadata]);

  const client = useApolloClient();
  const [modifyItemData, { loading }] = useSapS4HanaModifyItemDataMutation({
    client,
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await modifyItemData({
        variables: {
          input: {
            docId,
            itemId,
            projectId,
            values: {
              itemTexts: values.itemTexts.map(t => ({
                xLongTextId: t.xLongTextId,
                xLongTextValue: t.xLongTextValue,
              })),
              xMaterialByCustomer: values.xMaterialByCustomer,
              xMaterialGroup: values.xMaterialGroup,
              xPlant: values.xPlant,
            },
          },
        },
        refetchQueries: [namedOperations.Query.S4HanaQuoteItemDataBox],
      });
      enqueueSnackbar(t("Data updated", { ns: "SapS4Hana" }));
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = Yup.object().shape({
    itemTexts: Yup.array().of(
      Yup.object().shape({
        xLongTextId: Yup.string()
          .required()
          .nullable()
          .label(t("Type", { ns: "Global" }))
          .test(
            "unique",
            t("Value must be unique", { ns: "Global" }),
            function (value: string | undefined | null) {
              // @ts-ignore
              const itemTexts = (this.from as any)[1].value.itemTexts as {
                xLongTextId: string;
              }[];
              const sameValues = itemTexts.filter(p => p.xLongTextId === value);
              return sameValues.length <= 1;
            }
          ),
        xLongTextValue: Yup.string()
          .required()
          .label(t("Value", { ns: "Global" })),
      })
    ),
    xPlant: Yup.string().nullable(),
    xMaterialByCustomer: Yup.string().nullable(),
    xMaterialGroup: Yup.string().nullable(),
  });

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Form>
          <Stack direction="column" spacing={1} p={1}>
            <Box>
              <Grid container columns={2} spacing={1}>
                <Grid item xs={1}>
                  <LabeledValue label={t("Reference no.", { ns: "SapS4Hana" })}>
                    {sapItemData?.xId || "–"}
                  </LabeledValue>
                </Grid>
                <Grid item xs={1}>
                  <LabeledValue label={t("Item category", { ns: "SapS4Hana" })}>
                    {sapItemData?.xSalesQuotationItemCategoryLatest || "–"}
                  </LabeledValue>
                </Grid>
                <Grid item xs={1}>
                  <LabeledValue
                    label={t("Reference document ID", { ns: "SapS4Hana" })}
                  >
                    {sapItemData?.xReferenceDocumentId || "–"}
                  </LabeledValue>
                </Grid>
                <Grid item xs={1}>
                  <LabeledValue
                    label={t("Reference item ID", { ns: "SapS4Hana" })}
                  >
                    {sapItemData?.xReferenceItemId || "–"}
                  </LabeledValue>
                </Grid>
                <Grid item xs={1}>
                  {sapS4HanaDataFieldMetadataByKey["xPlant"]?.allowedValues &&
                  sapS4HanaDataFieldMetadataByKey["xPlant"]?.allowedValues
                    ?.length > 0 ? (
                    <AutocompleteField
                      inputLabel={t("Plant", { ns: "SapS4Hana" })}
                      name={"xPlant"}
                      disabled={
                        !sapS4HanaDataFieldMetadataByKey["xPlant"]?.editable ||
                        formikProps.isSubmitting
                      }
                      options={sapS4HanaDataFieldMetadataByKey[
                        "xPlant"
                      ]?.allowedValues?.map(v => v.value)}
                      getOptionLabel={option => {
                        const value = sapS4HanaDataFieldMetadataByKey[
                          "xPlant"
                        ]?.allowedValues?.find(v => v.value === option);
                        if (!value) return option;
                        return value.label;
                      }}
                      sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      label={t("Plant", { ns: "SapS4Hana" })}
                      name={"xPlant"}
                      disabled={
                        !sapS4HanaDataFieldMetadataByKey["xPlant"]?.editable ||
                        formikProps.isSubmitting
                      }
                      sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                    />
                  )}
                </Grid>
                <Grid item xs={1}>
                  {sapS4HanaDataFieldMetadataByKey["xMaterialGroup"]
                    ?.allowedValues &&
                  sapS4HanaDataFieldMetadataByKey["xMaterialGroup"]
                    ?.allowedValues?.length > 0 ? (
                    <AutocompleteField
                      inputLabel={t("Material group", { ns: "SapS4Hana" })}
                      name={"xMaterialGroup"}
                      disabled={
                        !sapS4HanaDataFieldMetadataByKey["xMaterialGroup"]
                          ?.editable || formikProps.isSubmitting
                      }
                      options={sapS4HanaDataFieldMetadataByKey[
                        "xMaterialGroup"
                      ]?.allowedValues?.map(v => v.value)}
                      getOptionLabel={option => {
                        const value = sapS4HanaDataFieldMetadataByKey[
                          "xMaterialGroup"
                        ]?.allowedValues?.find(v => v.value === option);
                        if (!value) return option;
                        return value.label;
                      }}
                      sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      label={t("Material group", { ns: "SapS4Hana" })}
                      name={"xMaterialGroup"}
                      disabled={
                        !sapS4HanaDataFieldMetadataByKey["xMaterialGroup"]
                          ?.editable || formikProps.isSubmitting
                      }
                      sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                    />
                  )}
                </Grid>
                <Grid item xs={1}>
                  {sapS4HanaDataFieldMetadataByKey["xMaterialByCustomer"]
                    ?.allowedValues &&
                  sapS4HanaDataFieldMetadataByKey["xMaterialByCustomer"]
                    ?.allowedValues?.length > 0 ? (
                    <AutocompleteField
                      inputLabel={t("Material by customer", {
                        ns: "SapS4Hana",
                      })}
                      name={"xMaterialByCustomer"}
                      disabled={
                        !sapS4HanaDataFieldMetadataByKey["xMaterialByCustomer"]
                          ?.editable || formikProps.isSubmitting
                      }
                      options={sapS4HanaDataFieldMetadataByKey[
                        "xPlant"
                      ]!.allowedValues!.map(v => v.value)}
                      getOptionLabel={option => {
                        const value = sapS4HanaDataFieldMetadataByKey[
                          "xMaterialByCustomer"
                        ]?.allowedValues?.find(v => v.value === option);
                        if (!value) return option;
                        return value.label;
                      }}
                      sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      label={t("Material by customer", { ns: "SapS4Hana" })}
                      name={"xMaterialByCustomer"}
                      disabled={
                        !sapS4HanaDataFieldMetadataByKey["xMaterialByCustomer"]
                          ?.editable || formikProps.isSubmitting
                      }
                      sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                    />
                  )}
                </Grid>
                {sapItemData?.xSalesDocumentRejectionReason && (
                  <Grid item xs={2}>
                    <LabeledValue
                      label={t("Sales rejection reason", {
                        ns: "SapS4Hana",
                      })}
                    >
                      {availableCancelReasons?.find(
                        r =>
                          r.value === sapItemData.xSalesDocumentRejectionReason
                      )?.label ?? sapItemData.xSalesDocumentRejectionReason}
                    </LabeledValue>
                  </Grid>
                )}
              </Grid>
            </Box>

            {sapItemData && sapItemData.pricingData.length > 0 && (
              <Box sx={{ overflow: "auto" }}>
                <S4HanaQuoteItemPriceTable
                  pricingData={sapItemData.pricingData}
                  availableItemConditionTypes={availableItemConditionTypes}
                />
              </Box>
            )}

            <Box sx={{ overflow: "auto" }}>
              <Typography variant="h4">
                {t("Item texts", { ns: "SapS4Hana" })}
              </Typography>

              <FieldArray name="itemTexts">
                {arrayHelpers => (
                  <>
                    {formikProps.values.itemTexts.length > 0 &&
                      formikProps.values.itemTexts.map((itemText, idx) => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          key={`header-text-${idx}`}
                        >
                          {availableTexts.length > 0 ? (
                            <AutocompleteField
                              inputLabel={t("Type", { ns: "Global" })}
                              name={`itemTexts.${idx}.xLongTextId`}
                              disabled={formikProps.isSubmitting}
                              options={availableTexts.map(v => v.value)}
                              getOptionLabel={option => {
                                const type = availableTexts.find(
                                  v => v.value === option
                                );
                                if (!type) return option;
                                return type.label;
                              }}
                              sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                            />
                          ) : (
                            <Field
                              component={TextField}
                              label={t("Type", { ns: "Global" })}
                              name={`itemTexts.${idx}.xLongTextId`}
                              disabled={formikProps.isSubmitting}
                              sx={{ alignSelf: "flex-start", flexBasis: "35%" }}
                            />
                          )}
                          <Field
                            component={TextField}
                            label={t("Value", { ns: "Global" })}
                            name={`itemTexts.${idx}.xLongTextValue`}
                            disabled={formikProps.isSubmitting}
                            multiline
                            sx={{
                              flex: 1,
                              alignSelf: "flex-start",
                              flexBasis: "65%",
                            }}
                          />
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              arrayHelpers.remove(idx);
                            }}
                            sx={{ flexGrow: 0, flexShrink: 0 }}
                            disabled={formikProps.isSubmitting}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                    <Button
                      type="button"
                      color="secondary"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() =>
                        arrayHelpers.push({
                          xLongTextId: null,
                          xLongTextValue: "",
                        })
                      }
                      sx={{ alignSelf: "flex-start" }}
                    >
                      {t("Add header text", { ns: "SapS4Hana" })}
                    </Button>
                  </>
                )}
              </FieldArray>
            </Box>
            <ManualSave
              disabled={formikProps.isSubmitting}
              onCancel={() => {
                setViewMode(null);
              }}
              onSubmitted={() => {
                setViewMode(null);
              }}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

const S4HanaQuoteItemPriceTable = ({
  pricingData,
  availableItemConditionTypes,
}: {
  pricingData: ItemSapS4HanaPricingDataFragment[];
  availableItemConditionTypes: { label: string; value: string }[];
}) => {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["SapS4HanaPriceTable", "Global"]);
  const priceTableLabels = React.useMemo(
    () =>
      Object.fromEntries(
        availableItemConditionTypes.map(e => [e.value, e.label])
      ),
    [availableItemConditionTypes]
  );

  const columnConfig: TableColumn<ItemSapS4HanaPricingDataFragment>[] = [
    {
      id: "typeCode",
      title: t("Type code", { ns: "SapS4HanaPriceTable" }),
      width: 90,
      render: data => data.conditionType || "–",
    },
    {
      id: "description",
      title: t("Description", { ns: "SapS4HanaPriceTable" }),
      width: 180,
      render: data =>
        data.conditionType ? priceTableLabels[data.conditionType] || "" : "",
    },
    {
      id: "amountOrRate",
      title: t("Amount/rate", { ns: "SapS4HanaPriceTable" }),
      width: 120,
      render: data =>
        !isNil(data.conditionRateValue)
          ? data.conditionCalculationType === "A"
            ? getFormattedFloat(data.conditionRateValue, language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "%"
            : getFormattedFloat(data.conditionRateValue, language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
          : "-",
    },
    {
      id: "amountOrRateCurrency",
      title: t("Currency", { ns: "SapS4HanaPriceTable" }),
      width: 90,
      render: data =>
        !isNil(data.conditionRateValue) && data.conditionCurrency
          ? data.conditionCurrency
          : "–",
    },
    {
      id: "per",
      title: t("Per", { ns: "SapS4HanaPriceTable" }),
      width: 50,
      render: data =>
        data.conditionQuantity
          ? getFormattedNumber(data.conditionQuantity, language)
          : 0,
    },
    {
      id: "unit",
      title: t("Unit", { ns: "SapS4HanaPriceTable" }),
      width: 50,
      render: data => data.conditionQuantityUnit || "",
    },
    {
      id: "conditionAmount",
      title: t("Condition amount", { ns: "SapS4HanaPriceTable" }),
      width: 120,
      render: data =>
        getFormattedFloat(data.conditionAmount || 0, language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      id: "conditionCurrency",
      title: t("Currency", { ns: "SapS4HanaPriceTable" }),
      width: 90,
      render: data => data.transactionCurrency || "–",
    },
    {
      id: "conversionFactor",
      title: t("Conversion factor", { ns: "SapS4HanaPriceTable" }),
      width: 120,
      render: data => "",
    },
  ];

  return (
    <TableInner
      size="extra-small"
      columns={columnConfig}
      items={pricingData}
      sx={{ minWidth: "900px" }}
    />
  );
};
