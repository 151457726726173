import { SelectOption } from "@msys/ui";
import {
  EntitySearchArrayOfFilterOperator,
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
} from "../../../clients/graphqlTypes";

export const AllOperatorOptions: SelectOption<EntitySearchFilterOperator>[] = [
  { label: "in", value: "in" },
  { label: "=", value: "eq" },
  { label: "<", value: "lt" },
  { label: "<=", value: "lte" },
  { label: ">", value: "gt" },
  { label: ">=", value: "gte" },
  { label: "between", value: "between" },
  { label: "any of", value: "anyOf" },
  { label: "all of", value: "allOf" },
];
export const ArrayFilterOperatorOptions: SelectOption<EntitySearchArrayOfFilterOperator>[] =
  [
    { label: "any of", value: "anyOf" },
    { label: "all of", value: "allOf" },
  ];
export const TextFilterOperatorOptions: SelectOption<
  EntitySearchTextFilterOperator | EntitySearchTextInFilterOperator
>[] = [
  { label: "=", value: "eq" },
  { label: "in", value: "in" },
];
export const PriceFilterOperatorOptions: SelectOption<
  EntitySearchNumberFilterOperator | EntitySearchNumberBetweenFilterOperator
>[] = [
  { label: "=", value: "eq" },
  { label: "<", value: "lt" },
  { label: "<=", value: "lte" },
  { label: ">", value: "gt" },
  { label: ">=", value: "gte" },
  { label: "between", value: "between" },
];
export const NumberFilterOperatorOptions: SelectOption<
  | EntitySearchNumberFilterOperator
  | EntitySearchNumberInFilterOperator
  | EntitySearchNumberBetweenFilterOperator
>[] = [
  { label: "=", value: "eq" },
  { label: "<", value: "lt" },
  { label: "<=", value: "lte" },
  { label: ">", value: "gt" },
  { label: ">=", value: "gte" },
  { label: "between", value: "between" },
  { label: "in", value: "in" },
];
export const BoolFilterOperatorOptions: SelectOption<
  EntitySearchBoolFilterOperator | EntitySearchBoolInFilterOperator
>[] = [
  { label: "=", value: "eq" },
  { label: "in", value: "in" },
];
