import { Currency, useFormatting } from "@msys/ui";
import { useFormikContext } from "formik";
import { clamp } from "lodash";
import { Money } from "../../../clients/graphqlTypes";
import { FormattedField } from "./FormattedField";

export const FormattedMoneyField = ({
  name,
  unit,
  min,
  max,
  multiplier = 1,
  ...props
}: {
  name: string;
  unit?: string;
  min?: number;
  max?: number;
  multiplier?: number;
} & Omit<
  React.ComponentProps<typeof FormattedField<Money>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue"
>) => {
  const { getFieldProps } = useFormikContext<{
    [key: string]: unknown;
  }>();
  const fieldProps = getFieldProps<Money>(name);
  const value = fieldProps.value;

  const { getFormattedPrice, getEditableFloat, getFloat } = useFormatting();

  function getFormattedValue(value: Money | null | undefined) {
    return getFormattedPrice(value ? value.amount : 0);
  }

  function getActualValue(value: string) {
    const newValue = clamp(
      getFloat(value) / multiplier,
      min ?? Number.MIN_SAFE_INTEGER,
      max ?? Number.MAX_SAFE_INTEGER
    );

    const money: Money = {
      __typename: "Money",
      amount: newValue,
      currency: Currency.Eur,
    };

    return money;
  }

  function getEditableValue() {
    return getEditableFloat(value ? value.amount * multiplier : 0);
  }

  return (
    <FormattedField
      {...props}
      name={name}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
    />
  );
};
