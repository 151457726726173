import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal, ModalOpenButton, Select } from "@msys/ui";
import { Button, DialogContentText, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { getAllItemChildren } from "../../../trees/helpers";
import { ProjectAssignBuildingModal } from "../../projects/modals/ProjectAssignBuildingModal";
import { useBuildingPlacementBoxSetMutation } from "../boxes/BuildingPlacementBox.generated";
import { useAddPlacementInProjectBuildingModalQuery } from "./AddPlacementInProjectBuildingModal.generated";

function useData(projectId: string, docId: string, itemId: string) {
  const client = useApolloClient();
  const query = useAddPlacementInProjectBuildingModalQuery({
    client,
    variables: { projectId, docId, itemId },
  });
  return { data: query.data, isLoading: query.loading, refetch: query.refetch };
}

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: () => void;
}

export const AddPlacementInProjectBuildingModal: React.FC<Props> = ({
  projectId,
  docId,
  itemId,
  title,
  handleClose,
  handleComplete,
}) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  const { data, isLoading } = useData(projectId, docId, itemId);
  const project = getDataOrNull(data?.project)?.project;
  const contractee = project?.crmOrganisation; // TODO-MODEL2022 is this correct?
  const placements =
    project?.buildingItem && project?.building
      ? [project.buildingItem].concat(
          getAllItemChildren(
            project.buildingItem,
            project.building?.items ?? []
          )
        )
      : [];
  const item = getDataOrNull(data?.item);
  const itemPlacement = item?.placement;

  const [placement, selectPlacement] = React.useState(
    itemPlacement ?? project?.buildingItem
  );
  React.useEffect(() => {
    selectPlacement(itemPlacement ?? project?.buildingItem);
  }, [itemPlacement, project?.buildingItem]);

  const client = useApolloClient();
  const [setPlacement, { loading: setPlacementLoading }] =
    useBuildingPlacementBoxSetMutation({ client });

  const handleSetPlacement = async () => {
    await setPlacement({
      refetchQueries: ["BuildingPlacementBox"],
      variables: {
        input: {
          docId,
          projectId,
          itemId,
          placementId: placement!.id,
        },
      },
    });
    handleClose();
    handleComplete?.();
  };

  return (
    <Modal
      title={
        title ??
        t("Add location in the building", {
          ns: "QuoteItem",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", {
            ns: "Global",
          }),
          handleClick: handleSetPlacement,
          buttonProps: {
            disabled: !placement || isLoading || setPlacementLoading,
            color: "primary",
            variant: "contained",
          },
        },
      ]}
      isLoading={isLoading}
    >
      {!project?.building && contractee && (
        <>
          <DialogContentText>
            <Typography>
              {t("Project has no building assigned", { ns: "QuoteItem" })}
            </Typography>
          </DialogContentText>
          <ModalOpenButton
            Modal={ProjectAssignBuildingModal}
            modalProps={{
              projectId,
              buildingOwningOrganisationId: contractee.id,
              refetchQueries: [
                namedOperations.Query.AddPlacementInProjectBuildingModal,
              ],
            }}
          >
            <Button variant="text" color="secondary">
              {t("Assign building", { ns: "QuoteItem" })}
            </Button>
          </ModalOpenButton>
        </>
      )}
      {project?.building && (
        <Select
          label={t("Placement", { ns: "QuoteItem" })}
          value={placement?.id ?? null}
          options={placements.map(e => ({
            label: `${e.pathForPdf} ${e.title}`,
            value: e.id,
          }))}
          onChange={value => {
            const newPlacementId = value;
            selectPlacement(
              placements.find(placement => placement.id === newPlacementId)
            );
          }}
        />
      )}
    </Modal>
  );
};
