/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { BillOfMaterialsItemFragmentDoc } from '../../features/bill-of-materials/BillOfMaterials.generated';
import { OrganisationSettingBillOfMaterialsExportSettingsFragmentDoc } from '../../features/organisations/boxes/OrganisationBillOfMaterialsSettingsBox.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectBillOfMaterialsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  filterStatus?: Types.InputMaybe<Array<Types.BillOfMaterialsItemStatus>>;
  filterQuoteId?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterQuoteItemStatus?: Types.InputMaybe<Types.BillOfMaterialsQuoteItemStatusFilter>;
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterType?: Types.InputMaybe<Array<Types.BillOfMaterialsItemType>>;
  aggregateProducts: Types.Scalars['Boolean']['input'];
}>;


export type ProjectBillOfMaterialsQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, billOfMaterialsItems: Array<{ __typename: 'BillOfMaterialsItem', id: string, itemType: Types.BillOfMaterialsItemType, title: string, itemStatus: Types.BillOfMaterialsItemStatus, quantityRequired: number, quantityRequiredAccepted: number, quantityRequiredPending: number, quantityRequiredChange: number, quantityRequiredDraft: number, quantityRequiredTotal: number, quantityOrdered: number, discountValue?: number | null, minInstallationTime?: number | null, maxInstallationTime?: number | null, listPrice?: number | null, netPrice?: number | null, quantityUnit: Types.QuantityUnit, quoteItemId?: string | null, productArticleNumber?: string | null, productManufacturerArticleNumber?: string | null, product?: { __typename: 'PimCustomSupplierProduct', id: string, articleNumber: string, supplierId: string } | { __typename: 'PimSupplierProduct', id: string, articleNumber: string, supplierId: string } | null, productSupplier?: { __typename: 'Company', id: string, title: string } | null }> } | null }, organisationSettings: { __typename: 'OrganisationSetting', id: string, billOfMaterialsExportSettings: { __typename: 'OrganisationSettingBillOfMaterialsExportSettings', dataFormat: Types.BillOfMaterialsDataFormat, sections: Array<Types.BillOfMaterialsSection>, csvFields: Array<Types.BillOfMaterialsField>, pdfFields: Array<Types.BillOfMaterialsField> } } };


export const ProjectBillOfMaterialsDocument = gql`
    query ProjectBillOfMaterials($projectId: ID!, $filterStatus: [BillOfMaterialsItemStatus!], $filterQuoteId: [ID!], $filterQuoteItemStatus: BillOfMaterialsQuoteItemStatusFilter, $filterSearchTerm: String, $filterType: [BillOfMaterialsItemType!], $aggregateProducts: Boolean!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        billOfMaterialsItems(
          itemStatus: $filterStatus
          quoteId: $filterQuoteId
          quoteItemStatus: $filterQuoteItemStatus
          filterSearchTerm: $filterSearchTerm
          itemType: $filterType
          aggregateProducts: $aggregateProducts
        ) {
          id
          ...BillOfMaterialsItem
        }
      }
    }
  }
  organisationSettings {
    id
    billOfMaterialsExportSettings {
      ...OrganisationSettingBillOfMaterialsExportSettings
    }
  }
}
    ${BillOfMaterialsItemFragmentDoc}
${OrganisationSettingBillOfMaterialsExportSettingsFragmentDoc}`;

/**
 * __useProjectBillOfMaterialsQuery__
 *
 * To run a query within a React component, call `useProjectBillOfMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBillOfMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBillOfMaterialsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      filterStatus: // value for 'filterStatus'
 *      filterQuoteId: // value for 'filterQuoteId'
 *      filterQuoteItemStatus: // value for 'filterQuoteItemStatus'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *      filterType: // value for 'filterType'
 *      aggregateProducts: // value for 'aggregateProducts'
 *   },
 * });
 */
export function useProjectBillOfMaterialsQuery(baseOptions: Apollo.QueryHookOptions<ProjectBillOfMaterialsQuery, ProjectBillOfMaterialsQueryVariables> & ({ variables: ProjectBillOfMaterialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectBillOfMaterialsQuery, ProjectBillOfMaterialsQueryVariables>(ProjectBillOfMaterialsDocument, options);
      }
export function useProjectBillOfMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectBillOfMaterialsQuery, ProjectBillOfMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectBillOfMaterialsQuery, ProjectBillOfMaterialsQueryVariables>(ProjectBillOfMaterialsDocument, options);
        }
export function useProjectBillOfMaterialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectBillOfMaterialsQuery, ProjectBillOfMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectBillOfMaterialsQuery, ProjectBillOfMaterialsQueryVariables>(ProjectBillOfMaterialsDocument, options);
        }
export type ProjectBillOfMaterialsQueryHookResult = ReturnType<typeof useProjectBillOfMaterialsQuery>;
export type ProjectBillOfMaterialsLazyQueryHookResult = ReturnType<typeof useProjectBillOfMaterialsLazyQuery>;
export type ProjectBillOfMaterialsSuspenseQueryHookResult = ReturnType<typeof useProjectBillOfMaterialsSuspenseQuery>;
export type ProjectBillOfMaterialsQueryResult = Apollo.QueryResult<ProjectBillOfMaterialsQuery, ProjectBillOfMaterialsQueryVariables>;