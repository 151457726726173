import { CardContainer } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { Stack } from "../../../commons/layout/Stack";
import { SupplierProductAlternativePicker } from "../SupplierProductAlternativePicker";

export function ProductOverviewAlternativePickerBox({
  product,
  productAlternatives,
}: Pick<
  React.ComponentProps<typeof SupplierProductAlternativePicker>,
  "product" | "productAlternatives"
>) {
  const navigate = useNavigate();

  const { t } = useTranslate(["ProductOverview"]);

  return (
    <CardContainer
      title={t("Product alternatives", {
        ns: "ProductOverview",
      })}
    >
      <Stack p={1} flexDirection="column">
        <SupplierProductAlternativePicker
          product={product}
          productAlternatives={productAlternatives}
          onProductSelect={product =>
            navigate(
              `/products/catalogues/supplier/${
                product.supplierId
              }/${encodeURIComponent(product.articleNumber)}/overview`
            )
          }
        />
      </Stack>
    </CardContainer>
  );
}
