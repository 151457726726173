/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyInviteModalQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CrmCompanyInviteModalQuery = { crmCompany: { __typename: 'CrmCompanyResult', crmCompany?: { __typename: 'CrmCompany', id: string, email: any, title: string, members: Array<{ __typename: 'CrmPerson', id: string, title: Types.Salutation, firstname: string, familyname: string, email: any }>, contactPerson?: { __typename: 'CrmPerson', id: string } | null } | null } | { __typename: 'MissingPermissions' } };

export type CrmCompanyInviteModal__ModifyCrmCompanyMutationVariables = Types.Exact<{
  crmCompanies: Array<Types.CrmOrganisationModifyInput>;
}>;


export type CrmCompanyInviteModal__ModifyCrmCompanyMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, email: any }> } };

export type SendCrmLinkingInvitationMutationVariables = Types.Exact<{
  input: Types.SendCrmLinkingInvitationInput;
}>;


export type SendCrmLinkingInvitationMutation = { sendCrmLinkingInvitation: { __typename: 'SendCrmLinkingInvitationResult', crmOrganisation: { __typename: 'CrmCompany', id: string, linkedSystemOrganisationId?: string | null } } };


export const CrmCompanyInviteModalDocument = gql`
    query CrmCompanyInviteModal($id: ID!) {
  crmCompany(id: $id) {
    ... on CrmCompanyResult {
      crmCompany {
        id
        email
        title
        members {
          id
          title
          firstname
          familyname
          email
        }
        contactPerson {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCrmCompanyInviteModalQuery__
 *
 * To run a query within a React component, call `useCrmCompanyInviteModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyInviteModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanyInviteModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCrmCompanyInviteModalQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanyInviteModalQuery, CrmCompanyInviteModalQueryVariables> & ({ variables: CrmCompanyInviteModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanyInviteModalQuery, CrmCompanyInviteModalQueryVariables>(CrmCompanyInviteModalDocument, options);
      }
export function useCrmCompanyInviteModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanyInviteModalQuery, CrmCompanyInviteModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanyInviteModalQuery, CrmCompanyInviteModalQueryVariables>(CrmCompanyInviteModalDocument, options);
        }
export function useCrmCompanyInviteModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmCompanyInviteModalQuery, CrmCompanyInviteModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanyInviteModalQuery, CrmCompanyInviteModalQueryVariables>(CrmCompanyInviteModalDocument, options);
        }
export type CrmCompanyInviteModalQueryHookResult = ReturnType<typeof useCrmCompanyInviteModalQuery>;
export type CrmCompanyInviteModalLazyQueryHookResult = ReturnType<typeof useCrmCompanyInviteModalLazyQuery>;
export type CrmCompanyInviteModalSuspenseQueryHookResult = ReturnType<typeof useCrmCompanyInviteModalSuspenseQuery>;
export type CrmCompanyInviteModalQueryResult = Apollo.QueryResult<CrmCompanyInviteModalQuery, CrmCompanyInviteModalQueryVariables>;
export const CrmCompanyInviteModal__ModifyCrmCompanyDocument = gql`
    mutation CrmCompanyInviteModal__ModifyCrmCompany($crmCompanies: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $crmCompanies) {
    crmOrganisations {
      id
      email
    }
  }
}
    `;
export type CrmCompanyInviteModal__ModifyCrmCompanyMutationFn = Apollo.MutationFunction<CrmCompanyInviteModal__ModifyCrmCompanyMutation, CrmCompanyInviteModal__ModifyCrmCompanyMutationVariables>;

/**
 * __useCrmCompanyInviteModal__ModifyCrmCompanyMutation__
 *
 * To run a mutation, you first call `useCrmCompanyInviteModal__ModifyCrmCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyInviteModal__ModifyCrmCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmCompanyInviteModalModifyCrmCompanyMutation, { data, loading, error }] = useCrmCompanyInviteModal__ModifyCrmCompanyMutation({
 *   variables: {
 *      crmCompanies: // value for 'crmCompanies'
 *   },
 * });
 */
export function useCrmCompanyInviteModal__ModifyCrmCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CrmCompanyInviteModal__ModifyCrmCompanyMutation, CrmCompanyInviteModal__ModifyCrmCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmCompanyInviteModal__ModifyCrmCompanyMutation, CrmCompanyInviteModal__ModifyCrmCompanyMutationVariables>(CrmCompanyInviteModal__ModifyCrmCompanyDocument, options);
      }
export type CrmCompanyInviteModal__ModifyCrmCompanyMutationHookResult = ReturnType<typeof useCrmCompanyInviteModal__ModifyCrmCompanyMutation>;
export type CrmCompanyInviteModal__ModifyCrmCompanyMutationResult = Apollo.MutationResult<CrmCompanyInviteModal__ModifyCrmCompanyMutation>;
export type CrmCompanyInviteModal__ModifyCrmCompanyMutationOptions = Apollo.BaseMutationOptions<CrmCompanyInviteModal__ModifyCrmCompanyMutation, CrmCompanyInviteModal__ModifyCrmCompanyMutationVariables>;
export const SendCrmLinkingInvitationDocument = gql`
    mutation SendCrmLinkingInvitation($input: SendCrmLinkingInvitationInput!) {
  sendCrmLinkingInvitation(input: $input) {
    crmOrganisation {
      id
      linkedSystemOrganisationId
    }
  }
}
    `;
export type SendCrmLinkingInvitationMutationFn = Apollo.MutationFunction<SendCrmLinkingInvitationMutation, SendCrmLinkingInvitationMutationVariables>;

/**
 * __useSendCrmLinkingInvitationMutation__
 *
 * To run a mutation, you first call `useSendCrmLinkingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCrmLinkingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCrmLinkingInvitationMutation, { data, loading, error }] = useSendCrmLinkingInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCrmLinkingInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendCrmLinkingInvitationMutation, SendCrmLinkingInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCrmLinkingInvitationMutation, SendCrmLinkingInvitationMutationVariables>(SendCrmLinkingInvitationDocument, options);
      }
export type SendCrmLinkingInvitationMutationHookResult = ReturnType<typeof useSendCrmLinkingInvitationMutation>;
export type SendCrmLinkingInvitationMutationResult = Apollo.MutationResult<SendCrmLinkingInvitationMutation>;
export type SendCrmLinkingInvitationMutationOptions = Apollo.BaseMutationOptions<SendCrmLinkingInvitationMutation, SendCrmLinkingInvitationMutationVariables>;