/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProductSearchItem__ProductSearchResultFragmentDoc } from '../products/Product.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateItemAutoCompleteTemplatesQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  applicableFor?: Types.InputMaybe<Array<Types.TplApplicableFor>>;
  excludeTemplateId: Types.Scalars['ID']['input'];
}>;


export type CreateItemAutoCompleteTemplatesQuery = { quoteTemplates: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'QuoteTemplateConnection', edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, sharedOnGallery: boolean } }> } };

export type CreateItemAutoCompleteProductsQueryVariables = Types.Exact<{
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CreateItemAutoCompleteProductsQuery = { pimSearchProducts: { __typename: 'MissingPermissions' } | { __typename: 'PimSearchProductConnection', edges: Array<{ __typename: 'PimSearchProductsEdge', node: { __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } }> } };


export const CreateItemAutoCompleteTemplatesDocument = gql`
    query CreateItemAutoCompleteTemplates($searchTerm: String, $applicableFor: [TplApplicableFor!], $excludeTemplateId: ID!) {
  quoteTemplates(
    limit: 5
    search: $searchTerm
    filters: {sources: [available], applicableFor: $applicableFor, excludeTemplateIds: [$excludeTemplateId]}
    sorting: {column: title, direction: asc}
  ) {
    ... on QuoteTemplateConnection {
      edges {
        node {
          id
          resolvedAsReadModelVersionNumber
          title
          sharedOnGallery
        }
      }
    }
  }
}
    `;

/**
 * __useCreateItemAutoCompleteTemplatesQuery__
 *
 * To run a query within a React component, call `useCreateItemAutoCompleteTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateItemAutoCompleteTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateItemAutoCompleteTemplatesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      applicableFor: // value for 'applicableFor'
 *      excludeTemplateId: // value for 'excludeTemplateId'
 *   },
 * });
 */
export function useCreateItemAutoCompleteTemplatesQuery(baseOptions: Apollo.QueryHookOptions<CreateItemAutoCompleteTemplatesQuery, CreateItemAutoCompleteTemplatesQueryVariables> & ({ variables: CreateItemAutoCompleteTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateItemAutoCompleteTemplatesQuery, CreateItemAutoCompleteTemplatesQueryVariables>(CreateItemAutoCompleteTemplatesDocument, options);
      }
export function useCreateItemAutoCompleteTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateItemAutoCompleteTemplatesQuery, CreateItemAutoCompleteTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateItemAutoCompleteTemplatesQuery, CreateItemAutoCompleteTemplatesQueryVariables>(CreateItemAutoCompleteTemplatesDocument, options);
        }
export function useCreateItemAutoCompleteTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateItemAutoCompleteTemplatesQuery, CreateItemAutoCompleteTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateItemAutoCompleteTemplatesQuery, CreateItemAutoCompleteTemplatesQueryVariables>(CreateItemAutoCompleteTemplatesDocument, options);
        }
export type CreateItemAutoCompleteTemplatesQueryHookResult = ReturnType<typeof useCreateItemAutoCompleteTemplatesQuery>;
export type CreateItemAutoCompleteTemplatesLazyQueryHookResult = ReturnType<typeof useCreateItemAutoCompleteTemplatesLazyQuery>;
export type CreateItemAutoCompleteTemplatesSuspenseQueryHookResult = ReturnType<typeof useCreateItemAutoCompleteTemplatesSuspenseQuery>;
export type CreateItemAutoCompleteTemplatesQueryResult = Apollo.QueryResult<CreateItemAutoCompleteTemplatesQuery, CreateItemAutoCompleteTemplatesQueryVariables>;
export const CreateItemAutoCompleteProductsDocument = gql`
    query CreateItemAutoCompleteProducts($searchTerm: String) {
  pimSearchProducts(offset: 0, limit: 5, search: $searchTerm) {
    ... on PimSearchProductConnection {
      edges {
        node {
          id
          ...ProductSearchItem__ProductSearchResult
        }
      }
    }
  }
}
    ${ProductSearchItem__ProductSearchResultFragmentDoc}`;

/**
 * __useCreateItemAutoCompleteProductsQuery__
 *
 * To run a query within a React component, call `useCreateItemAutoCompleteProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateItemAutoCompleteProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateItemAutoCompleteProductsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCreateItemAutoCompleteProductsQuery(baseOptions?: Apollo.QueryHookOptions<CreateItemAutoCompleteProductsQuery, CreateItemAutoCompleteProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateItemAutoCompleteProductsQuery, CreateItemAutoCompleteProductsQueryVariables>(CreateItemAutoCompleteProductsDocument, options);
      }
export function useCreateItemAutoCompleteProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateItemAutoCompleteProductsQuery, CreateItemAutoCompleteProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateItemAutoCompleteProductsQuery, CreateItemAutoCompleteProductsQueryVariables>(CreateItemAutoCompleteProductsDocument, options);
        }
export function useCreateItemAutoCompleteProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateItemAutoCompleteProductsQuery, CreateItemAutoCompleteProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateItemAutoCompleteProductsQuery, CreateItemAutoCompleteProductsQueryVariables>(CreateItemAutoCompleteProductsDocument, options);
        }
export type CreateItemAutoCompleteProductsQueryHookResult = ReturnType<typeof useCreateItemAutoCompleteProductsQuery>;
export type CreateItemAutoCompleteProductsLazyQueryHookResult = ReturnType<typeof useCreateItemAutoCompleteProductsLazyQuery>;
export type CreateItemAutoCompleteProductsSuspenseQueryHookResult = ReturnType<typeof useCreateItemAutoCompleteProductsSuspenseQuery>;
export type CreateItemAutoCompleteProductsQueryResult = Apollo.QueryResult<CreateItemAutoCompleteProductsQuery, CreateItemAutoCompleteProductsQueryVariables>;