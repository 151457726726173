import { DatePickerMui, DatePickerNative, DatePickerProps } from "@msys/ui";
import { FormControl, FormHelperText } from "@mui/material";
import { useFormikContext } from "formik";
import { get, isString } from "lodash";
import { Moment } from "moment";
import React, { ChangeEvent } from "react";
import { browserHasInputDate } from "../../featureDetection";

export interface Props extends Omit<DatePickerProps, "value" | "onChange"> {
  id?: string;
  fullWidth?: boolean;
  required?: boolean;
  name: string;
  value?: Moment | null;
  onChange?: (
    e: ChangeEvent<HTMLInputElement> | null,
    newValue: Moment | null
  ) => void;
}

export const DatePickerField: React.FC<Props> = ({
  fullWidth = true,
  name,
  value,
  onChange,
  ...otherProps
}) => {
  const { errors, values, setFieldValue } =
    useFormikContext<Record<string, any>>();

  const error = get(errors, name);

  const Component = browserHasInputDate ? DatePickerNative : DatePickerMui;

  return (
    <FormControl
      fullWidth={fullWidth}
      required={otherProps.required}
      error={Boolean(error)}
      disabled={otherProps.disabled}
    >
      <Component
        error={Boolean(error)}
        name={name}
        value={value ?? get(values, name) ?? null}
        onChange={
          onChange ??
          ((e, date) => {
            if (otherProps.isClearable || (date && date.isValid())) {
              setFieldValue(name, date ? (date.isValid() ? date : null) : null);
            }
          })
        }
        {...otherProps}
      />
      {Boolean(error) && isString(error) && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};
