/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
export type TimeReportHeader__ProjectFragment = { __typename: 'Project', id: string, number: string, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, description: string, website: string, isMeister: boolean, number: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null };

export const TimeReportHeader__ProjectFragmentDoc = gql`
    fragment TimeReportHeader__Project on Project {
  id
  number
  crmOrganisation {
    id
    title
    description
    website
    isMeister
    contactPerson {
      id
      title
      description
      website
      isMeister
      familyname
      firstname
      fullname
      locale
      skillset
    }
    number
    branchAddress {
      ...AddressDetails__Address
    }
    billingAddress {
      ...AddressDetails__Address
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;