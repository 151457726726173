import { useApolloClient } from "@apollo/client";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useOpenInvoiceMutation } from "../Invoices.generated";
import { useTranslate } from "@tolgee/react";
import { DEFAULT_INVOICE_PAYMENT_TERM_DURATION } from "../../../utils";

interface Props {
  invoiceId: string;
  projectId: string;
  docId: string;
  disabled?: boolean;
  paymentTermDuration: number;
  deliveryOfServiceStart?: string | null;
  deliveryOfServiceEnd?: string | null;
}

export const OpenInvoiceButton = ({
  invoiceId,
  projectId,
  docId,
  disabled = false,
  paymentTermDuration,
  deliveryOfServiceStart,
  deliveryOfServiceEnd,
}: Props) => {
  const { t } = useTranslate("InvoiceDetailsForm");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [openInvoice, { loading: isSubmittingOpenInvoice }] =
    useOpenInvoiceMutation({ client });

  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

  const handleSubmit = async (values: FormValues) => {
    try {
      await openInvoice({
        variables: {
          input: {
            projectId,
            docId,
            invoiceId,
            dueDate: values.dueDate.format("YYYY-MM-DD"),
            deliveryOfServiceStart:
              values.deliveryOfServiceStart.format("YYYY-MM-DD"),
            deliveryOfServiceEnd:
              values.deliveryOfServiceEnd.format("YYYY-MM-DD"),
          },
        },
      });

      enqueueSnackbar(t("Invoice opened"));
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setIsUploadingFile(false);
    }
  };

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      loading={isSubmittingOpenInvoice || isUploadingFile}
      startIcon={<SendIcon />}
      disabled={disabled}
      onClick={async () => {
        await handleSubmit({
          dueDate: moment().add(
            paymentTermDuration || DEFAULT_INVOICE_PAYMENT_TERM_DURATION,
            "seconds"
          ),
          deliveryOfServiceStart: deliveryOfServiceStart
            ? moment(deliveryOfServiceStart)
            : moment(),
          deliveryOfServiceEnd: deliveryOfServiceEnd
            ? moment(deliveryOfServiceEnd)
            : moment(),
        });
      }}
    >
      {t("Send to Client")}
    </LoadingButton>
  );
};

interface FormValues {
  dueDate: Moment;
  deliveryOfServiceStart: Moment;
  deliveryOfServiceEnd: Moment;
}
