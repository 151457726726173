/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ToggleTemplatesQuoteBindingButton_QuoteTemplateFragmentDoc } from './buttons/ToggleQuoteTemplateBindingMenuItem.generated';
export type TemplateQuoteTemplateMenu_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string, has3dRoomSnapshot: boolean, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, usedInOtherTemplates: boolean, isBinding: boolean, sharedWithOrganisations: Array<{ __typename: 'Company', id: string }> };

export const TemplateQuoteTemplateMenu_QuoteTemplateFragmentDoc = gql`
    fragment TemplateQuoteTemplateMenu_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  title
  has3dRoomSnapshot
  hasAnyPublishedVersion
  sharedPublicly
  sharedWithOrganisations {
    id
  }
  usedInOtherTemplates
  ...ToggleTemplatesQuoteBindingButton_QuoteTemplate
}
    ${ToggleTemplatesQuoteBindingButton_QuoteTemplateFragmentDoc}`;