/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { RowMember_ProjectMembershipFragmentDoc } from './boxes/ProjectOverviewParticipantsBox.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectAssigneeSelectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectAssigneeSelectQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> }>, organisationSettings: { __typename: 'OrganisationSetting', id: string, projectCouldBeUnassigned: boolean }, project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string } }>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> } | null, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> } | null } };

export type SetProjectAssigneeMutationVariables = Types.Exact<{
  membershipId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  projectId: Types.Scalars['ID']['input'];
}>;


export type SetProjectAssigneeMutation = { setProjectAssignee: { __typename: 'SetProjectAssigneeResult', project: { __typename: 'Project', id: string, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string }>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> } | null }, tentativeProject?: { __typename: 'Project', id: string, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, roles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> } | null } | null } };


export const ProjectAssigneeSelectDocument = gql`
    query ProjectAssigneeSelect($projectId: ID!) {
  organisationMemberships(filters: {active: true}) {
    id
    defaultProjectRoles {
      id
      internalName
    }
  }
  organisationSettings {
    id
    projectCouldBeUnassigned
  }
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        internalStakeholders {
          id
          user {
            id
          }
        }
        assignee {
          id
          ...RowMember_ProjectMembership
        }
        roles {
          id
          internalName
        }
      }
    }
  }
}
    ${RowMember_ProjectMembershipFragmentDoc}`;

/**
 * __useProjectAssigneeSelectQuery__
 *
 * To run a query within a React component, call `useProjectAssigneeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAssigneeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAssigneeSelectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectAssigneeSelectQuery(baseOptions: Apollo.QueryHookOptions<ProjectAssigneeSelectQuery, ProjectAssigneeSelectQueryVariables> & ({ variables: ProjectAssigneeSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectAssigneeSelectQuery, ProjectAssigneeSelectQueryVariables>(ProjectAssigneeSelectDocument, options);
      }
export function useProjectAssigneeSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectAssigneeSelectQuery, ProjectAssigneeSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectAssigneeSelectQuery, ProjectAssigneeSelectQueryVariables>(ProjectAssigneeSelectDocument, options);
        }
export function useProjectAssigneeSelectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectAssigneeSelectQuery, ProjectAssigneeSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectAssigneeSelectQuery, ProjectAssigneeSelectQueryVariables>(ProjectAssigneeSelectDocument, options);
        }
export type ProjectAssigneeSelectQueryHookResult = ReturnType<typeof useProjectAssigneeSelectQuery>;
export type ProjectAssigneeSelectLazyQueryHookResult = ReturnType<typeof useProjectAssigneeSelectLazyQuery>;
export type ProjectAssigneeSelectSuspenseQueryHookResult = ReturnType<typeof useProjectAssigneeSelectSuspenseQuery>;
export type ProjectAssigneeSelectQueryResult = Apollo.QueryResult<ProjectAssigneeSelectQuery, ProjectAssigneeSelectQueryVariables>;
export const SetProjectAssigneeDocument = gql`
    mutation SetProjectAssignee($membershipId: ID, $projectId: ID!) {
  setProjectAssignee(membershipId: $membershipId, projectId: $projectId) {
    project {
      id
      internalStakeholders {
        id
      }
      assignee {
        id
        ...RowMember_ProjectMembership
      }
    }
    tentativeProject {
      id
      assignee {
        id
        ...RowMember_ProjectMembership
      }
    }
  }
}
    ${RowMember_ProjectMembershipFragmentDoc}`;
export type SetProjectAssigneeMutationFn = Apollo.MutationFunction<SetProjectAssigneeMutation, SetProjectAssigneeMutationVariables>;

/**
 * __useSetProjectAssigneeMutation__
 *
 * To run a mutation, you first call `useSetProjectAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectAssigneeMutation, { data, loading, error }] = useSetProjectAssigneeMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSetProjectAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectAssigneeMutation, SetProjectAssigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectAssigneeMutation, SetProjectAssigneeMutationVariables>(SetProjectAssigneeDocument, options);
      }
export type SetProjectAssigneeMutationHookResult = ReturnType<typeof useSetProjectAssigneeMutation>;
export type SetProjectAssigneeMutationResult = Apollo.MutationResult<SetProjectAssigneeMutation>;
export type SetProjectAssigneeMutationOptions = Apollo.BaseMutationOptions<SetProjectAssigneeMutation, SetProjectAssigneeMutationVariables>;