import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Select, SelectMultiple } from "@msys/ui";
import React from "react";
import { OrganisationCrmOrganisationsSorting } from "../../../clients/graphqlTypes";
import { useSelectCrmSuppliersQuery } from "./CrmSuppliersSelect.generated";
import { useTranslate } from "@tolgee/react";

const CRM_SUPPLIERS_SORTING: OrganisationCrmOrganisationsSorting[] = [
  {
    column: "organisationName",
    direction: "asc",
  },
];

interface Props {
  useSystemOrganisationId?: boolean;
  counters?: Record<string, number> | undefined;
}

export function CrmSuppliersSelect({
  counters,
  useSystemOrganisationId = false,
  label,
  ...props
}: Props &
  Omit<React.ComponentProps<typeof SelectMultiple<string>>, "options">) {
  const { t } = useTranslate("Product");

  const client = useApolloClient();
  const query = useSelectCrmSuppliersQuery({
    client,
    variables: { sorting: CRM_SUPPLIERS_SORTING },
  });

  const options = React.useMemo(
    () =>
      getDataOrNull(query.data?.crmSuppliers)?.edges.map(edge => {
        const id = useSystemOrganisationId
          ? edge.node.linkedSystemOrganisationId!
          : edge.node.id;
        return {
          value: id,
          label: edge.node.title,
          counter: counters?.[id] ?? undefined,
        };
      }),
    [
      useSystemOrganisationId,
      getDataOrNull(query.data?.crmSuppliers)?.edges,
      counters,
    ]
  );

  return (
    <SelectMultiple
      label={label ?? t("Suppliers")}
      options={options ?? []}
      {...props}
    />
  );
}

export function CrmSupplierSelect({
  counters,
  useSystemOrganisationId = false,
  label,
  ...props
}: Props & Omit<React.ComponentProps<typeof Select<string>>, "options">) {
  const { t } = useTranslate("Product");

  const client = useApolloClient();
  const query = useSelectCrmSuppliersQuery({
    client,
    variables: { sorting: CRM_SUPPLIERS_SORTING },
  });

  const options = React.useMemo(
    () =>
      getDataOrNull(query.data?.crmSuppliers)?.edges.map(edge => {
        const id = useSystemOrganisationId
          ? edge.node.linkedSystemOrganisationId!
          : edge.node.id;
        return {
          value: id,
          label: edge.node.title,
          counter: counters?.[id] ?? undefined,
        };
      }),
    [
      useSystemOrganisationId,
      getDataOrNull(query.data?.crmSuppliers)?.edges,
      counters,
    ]
  );

  return (
    <Select label={label ?? t("Supplier")} options={options ?? []} {...props} />
  );
}
