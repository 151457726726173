import { useApolloClient } from "@apollo/client";
import { MenuButton, MenuItemWithIcon, ModalOpenButton, theme } from "@msys/ui";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { useFeature } from "../../../common/FeatureFlags";
import { SplashScreen } from "../../../common/SplashScreen";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../auth/useUserData";
import { ButtonLink } from "../../commons/button/Button";
import { Page } from "../../commons/layout/Page";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageGrid } from "../../commons/layout/PageGrid";
import { BrandingAd } from "../../features/branding/BrandingAd";
import { useBranding } from "../../features/branding/context";
import { useInvitationListQuery } from "../../features/invitations/Invitations.generated";
import { TodosBox } from "../../features/todos/TodosBox";
import { VisibilityEditModal } from "../../features/users/VisibilityEditModal";
import { PendingInvitationsBox } from "../../features/users/boxes/PendingInvitationsBox";
import { useVisibilityStore } from "../../features/users/useVisibilityStore";
import { DashboardAppNotificationsBox } from "./boxes/DashboardAppNotificationsBox";
import { DashboardOpportunitiesBox } from "./boxes/DashboardOpportunitesBox";
import { DashboardPersonalCalendarBox } from "./boxes/DashboardPersonalCalendarBox";
import { DashboardPersonalScheduleBox } from "./boxes/DashboardPersonalScheduleBox";
import { DashboardProjectsBox } from "./boxes/DashboardProjectsBox";
import { PendingDocSharesBox } from "./boxes/PendingDocSharesBox";
import { DashboardNewCompanyModal } from "./modals/DashboardNewCompanyModal";
import { DashboardNewUserModal } from "./modals/DashboardNewUserModal";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";

export type VisibilityKeys =
  | "calendar"
  | "projects"
  | "opportunities"
  | "todos"
  | "schedule";

// need to override topbar theme
const renderMenu = (menu: React.ReactElement): React.ReactElement => (
  <ThemeProvider theme={theme}>{menu}</ThemeProvider>
);

export const Dashboard = () => {
  const { t } = useTranslate("Dashboard");

  const viewer = useUserData().currentUser!;

  const { dashboardSideTopAd, dashboardLeftTopAd, dashboardRightTopAd } =
    useBranding();

  const { search } = useLocation();
  const [showNewCompanyPopup, setShowNewCompanyPopup] = React.useState(
    search.includes("newCompany")
  );
  const [showNewUserPopup, setShowNewUserPopup] = React.useState(
    search.includes("newUser")
  );

  const visibilityStore = useVisibilityStore<VisibilityKeys>("Dashboard");
  const { value, saveValue, loading } = visibilityStore;

  const client = useApolloClient();
  const invitationQuery = useInvitationListQuery({
    client,
  });
  const projectContracteeInvitations =
    invitationQuery?.data?.viewer?.projectContracteeInvitations;
  const receivedLinkingInvitations =
    invitationQuery?.data?.viewer?.organisation.receivedLinkingInvitations;

  const docSharingEnabled = useFeature("DocSharing");

  if (loading) return <SplashScreen />;

  const isCalendarVisible = value.calendar !== false;

  return (
    <Page
      isTopBarVisible
      isBrandingVisible
      title={t("Dashboard")}
      topbarItems={
        <MenuButton buttonProps={{ color: "inherit" }} renderMenu={renderMenu}>
          <ModalOpenButton
            Modal={VisibilityEditModal}
            modalProps={{
              visibilityStore,
              labels: {
                calendar: t("Calendar"),
                projects: t("Projects"),
                opportunities: t("Opportunities"),
                todos: t("To-do list"),
                schedule: t("My Schedule"),
              },
            }}
          >
            <MenuItemWithIcon icon={<ToggleOnIcon />}>
              {t("Configure dashboard")}
            </MenuItemWithIcon>
          </ModalOpenButton>
        </MenuButton>
      }
    >
      <PageContainer>
        <Stack spacing={2} direction="column">
          {docSharingEnabled ? <PendingDocSharesBox /> : null}

          {/* Visible only in app */}
          <DashboardAppNotificationsBox />

          {/* Invitations */}
          {projectContracteeInvitations &&
            receivedLinkingInvitations &&
            (projectContracteeInvitations.length > 0 ||
              receivedLinkingInvitations.length > 0) && (
              <Box>
                <PageGrid
                  columns={{ xs: 1, lg: 2, xl: isCalendarVisible ? 3 : 4 }}
                >
                  <>
                    <PendingInvitationsBox
                      projectContracteeInvitations={
                        projectContracteeInvitations
                      }
                      receivedLinkingInvitations={receivedLinkingInvitations}
                    />
                  </>
                </PageGrid>
              </Box>
            )}

          <Box>
            <PageGrid columns={{ xs: 1, lg: 2, xl: isCalendarVisible ? 3 : 4 }}>
              <>
                {dashboardLeftTopAd && <BrandingAd ad={dashboardLeftTopAd} />}
                {value.projects !== false && <DashboardProjectsBox />}
              </>
              {value.opportunities !== false &&
              viewer.organisation.isCraftsmanOrganisation ? (
                <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
                  <DashboardOpportunitiesBox />
                </RestrictedByOrganisationPermissionWithDebug>
              ) : null}
              {value.todos !== false || dashboardRightTopAd ? (
                <>
                  {dashboardRightTopAd && (
                    <BrandingAd ad={dashboardRightTopAd} />
                  )}
                  {value.todos !== false && (
                    <TodosBox
                      renderSeeMoreButton={activeQuickFilter => (
                        <ButtonLink
                          isLast
                          fullWidth
                          to={`/tasks${
                            activeQuickFilter
                              ? `?quickfilter=${activeQuickFilter}`
                              : ""
                          }`}
                          color="secondary"
                          variant="text"
                        >
                          {t("See more", { ns: "Global" })}
                        </ButtonLink>
                      )}
                    />
                  )}
                </>
              ) : null}
              {value.schedule !== false &&
              viewer.organisation.capabilities.includes("PLANNING") ? (
                <>
                  <RestrictedByCapabilityWithDebug capability="PLANNING">
                    <DashboardPersonalScheduleBox />
                  </RestrictedByCapabilityWithDebug>
                </>
              ) : null}
            </PageGrid>
          </Box>
        </Stack>
      </PageContainer>

      {((isCalendarVisible &&
        viewer.organisation.capabilities.includes("PLANNING")) ||
        dashboardSideTopAd) && (
        <PageColumn $hasBackground $hasLeftBorder>
          {dashboardSideTopAd && (
            <BrandingAd ad={dashboardSideTopAd} style={{ marginBottom: 12 }} />
          )}
          {isCalendarVisible && (
            <RestrictedByCapabilityWithDebug capability="PLANNING">
              <DashboardPersonalCalendarBox />
            </RestrictedByCapabilityWithDebug>
          )}
        </PageColumn>
      )}

      {showNewCompanyPopup && (
        <DashboardNewCompanyModal
          handleClose={() => setShowNewCompanyPopup(false)}
          organisation={viewer.organisation}
        />
      )}

      {showNewUserPopup && (
        <DashboardNewUserModal handleClose={() => setShowNewUserPopup(false)} />
      )}
    </Page>
  );
};
