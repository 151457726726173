import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Link } from "react-router-dom";
import { useFeature } from "../../../../common/FeatureFlags";
import { useUserData } from "../../../auth/useUserData";
import { buildDocPath } from "../../../utils";
import { TemplateQuoteAction_QuoteTemplateFragment } from "./TemplatesQuoteTemplateAction.generated";
import { QuoteTemplateAddToShopButton } from "./buttons/QuoteTemplateAddToShopButton";
import { QuoteTemplateDiscardUnpublishedChangesButton } from "./buttons/QuoteTemplateDiscardUnpublishedChangesButton";
import { QuoteTemplatePublishButton } from "./buttons/QuoteTemplatePublishButton";
import { QuoteTemplateShareButton } from "./buttons/QuoteTemplateShareButton";
import { QuoteTemplateSubscribeButton } from "./buttons/QuoteTemplateSubscribeButton";
import { QuoteTemplateUnsubscribeButton } from "./buttons/QuoteTemplateUnsubscribeButton";
import { QuoteTemplateUpdateSubscribedVersionButton } from "./buttons/QuoteTemplateUpdateSubscribedVersionButton";
import { useTemplateDecisionWizardPreview } from "./useTemplateDecisionWizardPreview";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";

interface Props {
  doc: TemplateQuoteAction_QuoteTemplateFragment;
  pathToDoc: string;
}

export const TemplatesQuoteTemplateAction = ({ doc, pathToDoc }: Props) => {
  const { t } = useTranslate(["Global", "Templates", "Decisions"]);
  const viewer = useUserData().currentUser!;
  const isShopEnabled = useFeature("Shop");

  const isOwnTemplate =
    doc.owningSystemOrganisationId === viewer.organisation.id;
  const isVersion = doc.resolvedAsReadModelVersionNumber !== null;
  const haveExplicitAccess = doc.accessType === "explicit";
  const latestPublishedVersionNumber = doc.latestPublishedVersionNumber ?? null;
  const isLatestPublishedVersion =
    latestPublishedVersionNumber !== null &&
    doc.resolvedAsReadModelVersionNumber === latestPublishedVersionNumber;
  const subscribedVersion =
    doc.subscribedToTemplate?.subscribedVersionNumber ?? null;
  const isSubscribedVersion =
    subscribedVersion !== null &&
    doc.resolvedAsReadModelVersionNumber === subscribedVersion;
  const showEditButton = isOwnTemplate && isVersion;
  const showSubscribedVersionButton =
    !isOwnTemplate && subscribedVersion !== null && !isSubscribedVersion;
  const showLatestVersionButton =
    (isOwnTemplate &&
      latestPublishedVersionNumber &&
      !isLatestPublishedVersion) ||
    (!isOwnTemplate &&
      latestPublishedVersionNumber !== null &&
      !isLatestPublishedVersion &&
      (!showSubscribedVersionButton ||
        subscribedVersion !== latestPublishedVersionNumber));
  const canShare =
    isOwnTemplate &&
    !isVersion &&
    doc.hasAnyPublishedVersion &&
    !doc.sharedPublicly;
  const canPublish =
    isOwnTemplate && !isVersion && doc.containsUnpublishedChanged;
  const canDiscardUnpublishedChanges = doc.hasAnyPublishedVersion && canPublish;
  const canSubscribe =
    !isOwnTemplate &&
    subscribedVersion === null &&
    isLatestPublishedVersion &&
    haveExplicitAccess;
  const canUnsubscribe = !isOwnTemplate && isSubscribedVersion;
  const canUpdateSubscribedVersion =
    !isOwnTemplate &&
    doc.subscribedToTemplate?.updateAvailable &&
    !isSubscribedVersion &&
    isLatestPublishedVersion;
  const canAddToShop =
    doc.hasAnyPublishedVersion &&
    (isOwnTemplate
      ? !isVersion
      : isLatestPublishedVersion && haveExplicitAccess);

  const openWizardButton = useTemplateDecisionWizardPreview({
    templateId: doc.id,
  });

  const actionButtons: React.ReactNode[] = doc
    ? [
        canShare && (
          <RestrictedByOrganisationPermissionWithDebug
            permission="SHARE_TEMPLATE"
            key={"share-button"}
          >
            <QuoteTemplateShareButton doc={doc} />
          </RestrictedByOrganisationPermissionWithDebug>
        ),
        canPublish && (
          <RestrictedByOrganisationPermissionWithDebug
            permission="MANAGE_TEMPLATE"
            key={"publish-button"}
          >
            <QuoteTemplatePublishButton docId={doc.id} pathToDoc={pathToDoc} />
          </RestrictedByOrganisationPermissionWithDebug>
        ),
        showEditButton && (
          <RestrictedByOrganisationPermissionWithDebug
            permission="MANAGE_TEMPLATE"
            key={"edit-button"}
          >
            <Button
              component={Link}
              to={buildDocPath(pathToDoc, null)}
              variant="contained"
            >
              {t("Edit", { ns: "Global" })}
            </Button>
          </RestrictedByOrganisationPermissionWithDebug>
        ),
        showLatestVersionButton && (
          <Button
            key={"see-latest"}
            component={Link}
            to={buildDocPath(pathToDoc, latestPublishedVersionNumber)}
            variant="contained"
          >
            {t("See latest version", { ns: "Templates" })}
          </Button>
        ),
        showSubscribedVersionButton && (
          <Button
            key={"see-subscribed"}
            component={Link}
            to={buildDocPath(pathToDoc, subscribedVersion)}
            variant="contained"
          >
            {t("See subscribed version", { ns: "Templates" })}
          </Button>
        ),
        canDiscardUnpublishedChanges && (
          <RestrictedByOrganisationPermissionWithDebug
            permission="MANAGE_TEMPLATE"
            key={"discard-unpublished-changes-button"}
          >
            <QuoteTemplateDiscardUnpublishedChangesButton
              docId={doc.id}
              pathToDoc={pathToDoc}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        ),
        canSubscribe && (
          <RestrictedByOrganisationPermissionWithDebug
            permission="SHARE_TEMPLATE"
            key={"subscribe-button"}
          >
            <QuoteTemplateSubscribeButton doc={doc} />
          </RestrictedByOrganisationPermissionWithDebug>
        ),
        canUnsubscribe && (
          <RestrictedByOrganisationPermissionWithDebug
            permission="SHARE_TEMPLATE"
            key={"subscribe-button"}
          >
            <QuoteTemplateUnsubscribeButton doc={doc} />
          </RestrictedByOrganisationPermissionWithDebug>
        ),
        isShopEnabled && canAddToShop && (
          <RestrictedByCapabilityWithDebug
            capability="SHOP"
            key={"add-to-shop-button"}
          >
            <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
              <QuoteTemplateAddToShopButton doc={doc} />
            </RestrictedByOrganisationPermissionWithDebug>
          </RestrictedByCapabilityWithDebug>
        ),
        canUpdateSubscribedVersion && (
          <RestrictedByOrganisationPermissionWithDebug
            permission="MANAGE_TEMPLATE"
            key={"update-subscribed-version"}
          >
            <QuoteTemplateUpdateSubscribedVersionButton
              doc={doc}
              pathToDoc={pathToDoc}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        ),
        haveExplicitAccess && openWizardButton,
      ].filter(Boolean)
    : [];

  return actionButtons;
};
