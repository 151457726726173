import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import React from "react";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { useWithdrawRequestMutation } from "../Requests.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  requestId: string;
  projectId: string;
  needsConfirm?: boolean;
  refetchQueries?: string[];
}

export const RequestCancelButton = ({
  requestId,
  projectId,
  needsConfirm,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["Requests", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [closeRequest, { loading }] = useWithdrawRequestMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handleCloseRequest = async () => {
    try {
      await closeRequest({
        variables: {
          input: { projectId, requestId },
        },
      });
      enqueueSnackbar(
        t("Request cancelled", {
          ns: "Requests",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  if (needsConfirm) {
    return (
      <ModalOpenButton
        Modal={ConfirmModal}
        modalProps={{
          title: t("Cancel request", {
            ns: "Requests",
          }),
          text: t("Are you sure you want to cancel this request?", {
            ns: "Requests",
          }),
          confirmButtonLabel: t("Yes, cancel", {
            ns: "Requests",
          }),
          handleConfirm: handleCloseRequest,
          cancelButtonLabel: t("No", {
            ns: "Global",
          }),
        }}
      >
        <LoadingButton
          startIcon={<LockIcon />}
          variant="outlined"
          color="primary"
          loading={loading}
        >
          {t("Cancel", {
            ns: "Requests",
          })}
        </LoadingButton>
      </ModalOpenButton>
    );
  }

  return (
    <LoadingButton
      startIcon={<LockIcon />}
      variant="outlined"
      color="primary"
      loading={loading}
      onClick={handleCloseRequest}
    >
      {t("Cancel", {
        ns: "Requests",
      })}
    </LoadingButton>
  );
};
