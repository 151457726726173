import { useApolloClient } from "@apollo/client";
import { CardItem, Modal, ModalOpenButton } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Formik, FormikProps } from "formik";
import { noop, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { QuotePdfCalculationType } from "../../../../clients/graphqlTypes";
import {
  CrmSendEmailForm,
  FormValues,
  useCrmSendEmail,
} from "../../crm/CrmSendEmailForm";
import { useProjectContracteeInvite } from "../../projects/useProjectContracteeInvite";
import { QuotePreviewListItem } from "../QuotePreviewListItem";
import { usePublishQuoteMutation } from "../buttons/PublishQuoteButton.generated";
import { InviteContracteeField } from "../form-fields/InviteContracteeField";
import { PdfTemplateRadioGroup } from "./PdfTemplateSelectModal";
import {
  PublishQuoteAndSendViaEmailModal_ProjectFragment,
  PublishQuoteAndSendViaEmailModal_QuoteFragment,
} from "./PublishQuoteAndSendViaEmailModal.generated";
import { QuotePublishingInfoModal } from "./QuotePublishingInfoModal";
import { useSendQuoteViaEmail } from "./SendQuoteViaEmailModal";

interface Props {
  quoteId: string;
  quote: PublishQuoteAndSendViaEmailModal_QuoteFragment;
  projectId: string;
  project: PublishQuoteAndSendViaEmailModal_ProjectFragment;
  calculationType: QuotePdfCalculationType;
  pdfTemplateId?: string | null;
  handleClose: () => void;
  handleComplete?: (handleClose: () => void) => Promise<void>;
}

export const PublishQuoteAndSendViaEmailModal = ({
  quote,
  project,
  projectId,
  quoteId,
  handleClose,
  handleComplete,
  calculationType,
  pdfTemplateId: passedPdfTemplateId,
}: Props) => {
  const { t } = useTranslate([
    "QuoteSend",
    "Global",
    "ProjectMembers",
    "CrmOrganisations",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [publishQuote] = usePublishQuoteMutation({
    client,
  });

  const handlePublish = async () => {
    await publishQuote({
      variables: {
        input: {
          projectId,
          docId: quoteId,
        },
      },
    });
    enqueueSnackbar(t("Quote published", { ns: "QuoteSend" }));
  };

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [needSendQuote, setNeedSendQuote] = React.useState<boolean>(false);

  const {
    recipientOptions,
    canSelectPdfTemplate,
    organisationQuoteTemplates,
    urlAttachments,
    getAttachmentsOnSubmit,
    query,
    pdfTemplateId,
    setPdfTemplateId,
  } = useSendQuoteViaEmail({
    projectId,
    quoteId,
    calculationType,
    pdfTemplateId: passedPdfTemplateId,
  });

  const { initialValues, validationSchema, handleSendEmail, emailTemplates } =
    useCrmSendEmail({
      urlAttachments,
      getAttachmentsOnSubmit,
      emailTemplateContext: "QUOTE",
      context: { type: "QUOTE", id: quoteId },
    });

  const [needInviteContractee, setNeedInviteContractee] =
    React.useState<boolean>(false);
  const { canInviteContractee, handleInviteContractee } =
    useProjectContracteeInvite(project);

  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const formId = React.useMemo(() => uniqueId(), []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const values = formikRef.current?.values;

      // validate for sending quote
      if (needSendQuote) {
        if (!values) return;
        formikRef.current?.setTouched({
          recipient: true,
          subject: true,
          body: true,
        });
        await formikRef.current?.validateForm();
        if (!formikRef.current?.isValid) return;
        if (!values.recipient || !values.recipient.crmCompanyId) return;
      }

      await handlePublish();

      if (needSendQuote) {
        if (values) {
          await handleSendEmail(values);
          enqueueSnackbar(
            t("Quote was sent to client", {
              ns: "QuoteSend",
            })
          );
        }
      }

      if (needSendQuote && needInviteContractee) {
        if (values && values.recipient?.crmPersonId) {
          await handleInviteContractee(values.recipient.crmPersonId);
        }
      }

      if (handleComplete) {
        await handleComplete(handleClose);
      } else {
        handleClose();
      }
    } catch (e) {
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      title={t("Publish quote", { ns: "QuoteSend" })}
      isLoading={query.loading}
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
            disabled: isSubmitting,
          },
        },
        {
          label: t("Publish", { ns: "Global" }),
          handleClick: handleSubmit,
          buttonProps: {
            loading: isSubmitting,
          },
        },
      ]}
      maxWidth="md"
    >
      <Stack direction="column" spacing={2}>
        <CardItem>
          <QuotePreviewListItem
            quote={quote}
            Action={
              <ModalOpenButton
                Modal={QuotePublishingInfoModal}
                modalProps={{ projectId, quoteId }}
              >
                <IconButton size="small" color="primary">
                  <Tooltip title={t("Edit", { ns: "Global" })}>
                    <EditIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              </ModalOpenButton>
            }
          />
        </CardItem>

        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={needSendQuote}
              onChange={(event, checked) => {
                setNeedSendQuote(checked);
                if (!checked) {
                  setNeedInviteContractee(false);
                }
              }}
            />
          }
          label={t("Send to customer by email", { ns: "QuoteSend" })}
        />

        {needSendQuote && (
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={noop}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            <CrmSendEmailForm
              recipientOptions={recipientOptions}
              additionalFields={
                canSelectPdfTemplate ? (
                  <Box mt={1} mb={-1}>
                    <PdfTemplateRadioGroup
                      label={t("Choose PDF template", { ns: "QuoteSend" })}
                      organisationQuoteTemplates={organisationQuoteTemplates}
                      value={pdfTemplateId}
                      onChange={setPdfTemplateId}
                    />
                  </Box>
                ) : undefined
              }
              formId={formId}
              availableTemplates={emailTemplates}
              context={{ type: "QUOTE", id: quote.id }}
            />
          </Formik>
        )}

        {canInviteContractee && (
          <InviteContracteeField
            project={project}
            checked={needInviteContractee}
            disabled={!needSendQuote}
            handleChange={checked => {
              setNeedInviteContractee(checked);
            }}
          />
        )}
      </Stack>
    </Modal>
  );
};
