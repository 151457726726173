// import "./opentelemetry/local-trace";
import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { ModalStackProvider } from "@msys/ui";
import { Stack } from "@mui/material";
import Keycloak from "keycloak-js";
import React from "react";
import { Inspector, InspectParams } from "react-dev-inspector";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { ApolloClientProvider } from "../clients/ApolloClientProvider";
import { FeatureProvider } from "../common/FeatureFlags";
import { GlobalLoadingBar } from "../common/global-loading-state/GlobalLoadingBar";
import { GlobalLoadingStateProvider } from "../common/global-loading-state/GlobalLoadingStateProvider";
import { GoogleMapsProvider } from "../common/google-maps/GoogleMapsProvider";
import "../common/googleTagManager";
import "../common/mui";
import { suppressConsoleWarnings } from "../common/console";
import { MuiThemeProvider } from "../common/MuiThemeProvider";
import { initSentry } from "../common/sentry";
import { SnackbarProvider } from "../common/SnackbarProvider";
import { SplashScreen } from "../common/SplashScreen";
import "../common/syncfusion";
import { TranslationsProvider } from "../common/translations/TranslationsProvider";
import {
  DEPLOY_ENV,
  KEYCLOAK_AUTH_SERVER,
  KEYCLOAK_CLIENT,
  KEYCLOAK_REALM,
} from "../environment";
import { AuthProvider } from "./auth/AuthProvider";
import { AvailableUsersProvider } from "./auth/AvailableUsersProvider";
import { SelectedUserProvider } from "./auth/SelectedUserProvider";
import { UserDataProvider } from "./auth/UserDataProvider";
import { ClipboardProvider } from "./commons/hooks/useClipboard";
import { BrandingProvider } from "./features/branding/context";
import { NativeFeatureProvider } from "./global/NativeFeatureProvider";
import { OutdatedAppVersionProvider } from "./global/OutdatedAppVersionProvider";
import { OutdatedVersionDialog } from "./global/OutdatedVersionDialog";
import { PushNotificationsProvider } from "./global/PushNotificationsProvider";
import { Router } from "./global/Router";
import { ScreenFeatureProvider } from "./global/ScreenFeatureProvider";
import { SidebarProvider } from "./global/SidebarProvider";
import "./index.css";
import { InvitationTokenProvider } from "./invitation/InvitationTokenProvider";
import { AppRoutes } from "./Routes";

suppressConsoleWarnings();

const keycloak = new Keycloak({
  url: KEYCLOAK_AUTH_SERVER,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT,
});

initSentry(
  Capacitor.isNativePlatform() ? { tags: { capacitor: true } } : undefined
);

if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.notifyAppReady();
}

(window as any).global = window;

const App = () => {
  // Remove initial loader
  React.useLayoutEffect(() => {
    document.querySelector("#app-loader")?.remove();
  }, []);

  return (
    <React.Suspense fallback={<SplashScreen />}>
      <HelmetProvider>
        <GoogleMapsProvider>
          <TranslationsProvider>
            <MuiThemeProvider>
              <FeatureProvider>
                <ScreenFeatureProvider>
                  <NativeFeatureProvider>
                    <AuthProvider keycloak={keycloak}>
                      <SelectedUserProvider>
                        <Router>
                          <SnackbarProvider>
                            <OutdatedAppVersionProvider>
                              <ApolloClientProvider>
                                <AvailableUsersProvider>
                                  <UserDataProvider>
                                    <ClipboardProvider>
                                      <ModalStackProvider>
                                        <BrandingProvider>
                                          <PushNotificationsProvider>
                                            <InvitationTokenProvider>
                                              <GlobalLoadingStateProvider>
                                                <GlobalLoadingBar />
                                                <SidebarProvider>
                                                  <Stack
                                                    direction={"row"}
                                                    sx={{
                                                      // Preventing top bar from being hidden on mobile
                                                      // when scrolling down more than one fold of the screen
                                                      height: {
                                                        xs: "unset",
                                                        md: "100%",
                                                      },
                                                      minHeight: {
                                                        xs: "100%",
                                                        md: "unset",
                                                      },
                                                    }}
                                                  >
                                                    <AppRoutes />
                                                  </Stack>
                                                </SidebarProvider>
                                              </GlobalLoadingStateProvider>
                                            </InvitationTokenProvider>
                                            <OutdatedVersionDialog />
                                          </PushNotificationsProvider>
                                        </BrandingProvider>
                                      </ModalStackProvider>
                                    </ClipboardProvider>
                                  </UserDataProvider>
                                </AvailableUsersProvider>
                              </ApolloClientProvider>
                            </OutdatedAppVersionProvider>
                          </SnackbarProvider>
                        </Router>
                      </SelectedUserProvider>
                    </AuthProvider>
                  </NativeFeatureProvider>
                </ScreenFeatureProvider>
              </FeatureProvider>
            </MuiThemeProvider>
          </TranslationsProvider>
        </GoogleMapsProvider>
      </HelmetProvider>
    </React.Suspense>
  );
};

const InspectorWrapper =
  !Capacitor.isNativePlatform() && DEPLOY_ENV === "local"
    ? Inspector
    : React.Fragment;

const root = createRoot(document.getElementById("app-root")!);
root.render(
  <React.StrictMode>
    <InspectorWrapper
      // props docs see:
      // https://github.com/zthxxx/react-dev-inspector#inspector-component-props
      keys={["control", "shift", "command", "c"]}
      disableLaunchEditor={false}
      onHoverElement={(params: InspectParams) => {}}
      onClickElement={(params: InspectParams) => {}}
    >
      <App />
    </InspectorWrapper>
  </React.StrictMode>
);
