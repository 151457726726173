import { useScreenWidth } from "@msys/ui";
import { useFormikContext } from "formik";
import moment from "moment";
import { Stack } from "../layout/Stack";
import { DatePickerField } from "./DatePickerField";
import { TimePickerField } from "./TimePickerField";

export function DateAndTimePickersField({
  name,
  dateLabel,
  timeLabel,
}: {
  name: string;
  dateLabel: string;
  timeLabel: string;
}) {
  const { isMaxPhone } = useScreenWidth();
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<Record<string, any>>();

  return (
    <Stack width={"100%"} flexDirection={isMaxPhone ? "column" : "row"}>
      <DatePickerField
        label={dateLabel}
        name={name}
        value={values.time}
        onChange={(event, value) => {
          setFieldValue(
            name,
            moment(value).set({
              hours: values.time?.hours(),
              minutes: values.time?.minutes(),
            })
          );
        }}
      />
      <TimePickerField
        label={timeLabel}
        name={name}
        value={values.time ? moment(values.time).format("HH:mm") : ""}
        onChange={(event, value) => {
          const [hours, minutes] = value
            ?.split(":")
            .map(element => parseInt(element)) ?? [0, 0];
          setFieldValue(name, moment(values.time).set({ hours, minutes }));
        }}
        disabled={!values.time}
      />
    </Stack>
  );
}
