/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { UserAvatarFragmentDoc } from '../../features/users/UserAvatar.generated';
import { CrmPersonInformationForm_CrmPersonFragmentDoc } from '../../features/crm-persons/CrmPersonInformationForm.generated';
import { CrmPersonCustomFieldsBox_CrmPersonFragmentDoc } from '../../features/crm-persons/boxes/CrmPersonCustomFieldsBox.generated';
import { TodosBox_LinkedCrmUserFragmentDoc } from '../../features/todos/TodosBox.generated';
import { CrmEmailMessagesBox_CrmPersonFragmentDoc } from '../../features/crm/CrmEmailMessagesBox.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../features/attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmPersonProfileQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type CrmPersonProfileQuery = { crmPerson: { __typename: 'CrmPersonResult', crmPerson?: { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, title: Types.Salutation, email: any, isEmailValid?: boolean | null, jobTitle: string, customTags: Array<string>, notices: string, fullname: string, crmCompany: { __typename: 'CrmCompany', id: string, title: string }, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string }, phones: Array<{ __typename: 'Phone', id: string, type: Types.PhoneType, main: boolean, number: string }>, mailMessages: Array<{ __typename: 'CrmMailMessage', id: string, createdAt: any, threadId: string, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, body: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> }>, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> } | null } | { __typename: 'MissingPermissions' } };

export type ModifyCrmUserAvatarMutationVariables = Types.Exact<{
  users: Array<Types.CrmUserModifyInput>;
}>;


export type ModifyCrmUserAvatarMutation = { modifyCrmUser: { __typename: 'CrmUserModifyResult', users: Array<{ __typename: 'CrmPerson', id: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> } };


export const CrmPersonProfileDocument = gql`
    query CrmPersonProfile($userId: ID!) {
  crmPerson(id: $userId) {
    ... on CrmPersonResult {
      crmPerson {
        id
        firstname
        familyname
        crmCompany {
          id
          title
        }
        ...UserAvatar
        ...CrmPersonInformationForm_CrmPerson
        ...CrmPersonCustomFieldsBox_CrmPerson
        ...TodosBox_LinkedCrmUser
        ...CrmEmailMessagesBox_CrmPerson
      }
    }
  }
}
    ${UserAvatarFragmentDoc}
${CrmPersonInformationForm_CrmPersonFragmentDoc}
${CrmPersonCustomFieldsBox_CrmPersonFragmentDoc}
${TodosBox_LinkedCrmUserFragmentDoc}
${CrmEmailMessagesBox_CrmPersonFragmentDoc}`;

/**
 * __useCrmPersonProfileQuery__
 *
 * To run a query within a React component, call `useCrmPersonProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmPersonProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmPersonProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCrmPersonProfileQuery(baseOptions: Apollo.QueryHookOptions<CrmPersonProfileQuery, CrmPersonProfileQueryVariables> & ({ variables: CrmPersonProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmPersonProfileQuery, CrmPersonProfileQueryVariables>(CrmPersonProfileDocument, options);
      }
export function useCrmPersonProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmPersonProfileQuery, CrmPersonProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmPersonProfileQuery, CrmPersonProfileQueryVariables>(CrmPersonProfileDocument, options);
        }
export function useCrmPersonProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmPersonProfileQuery, CrmPersonProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmPersonProfileQuery, CrmPersonProfileQueryVariables>(CrmPersonProfileDocument, options);
        }
export type CrmPersonProfileQueryHookResult = ReturnType<typeof useCrmPersonProfileQuery>;
export type CrmPersonProfileLazyQueryHookResult = ReturnType<typeof useCrmPersonProfileLazyQuery>;
export type CrmPersonProfileSuspenseQueryHookResult = ReturnType<typeof useCrmPersonProfileSuspenseQuery>;
export type CrmPersonProfileQueryResult = Apollo.QueryResult<CrmPersonProfileQuery, CrmPersonProfileQueryVariables>;
export const ModifyCrmUserAvatarDocument = gql`
    mutation ModifyCrmUserAvatar($users: [CrmUserModifyInput!]!) {
  modifyCrmUser(input: $users) {
    users {
      id
      avatar {
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export type ModifyCrmUserAvatarMutationFn = Apollo.MutationFunction<ModifyCrmUserAvatarMutation, ModifyCrmUserAvatarMutationVariables>;

/**
 * __useModifyCrmUserAvatarMutation__
 *
 * To run a mutation, you first call `useModifyCrmUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmUserAvatarMutation, { data, loading, error }] = useModifyCrmUserAvatarMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useModifyCrmUserAvatarMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmUserAvatarMutation, ModifyCrmUserAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmUserAvatarMutation, ModifyCrmUserAvatarMutationVariables>(ModifyCrmUserAvatarDocument, options);
      }
export type ModifyCrmUserAvatarMutationHookResult = ReturnType<typeof useModifyCrmUserAvatarMutation>;
export type ModifyCrmUserAvatarMutationResult = Apollo.MutationResult<ModifyCrmUserAvatarMutation>;
export type ModifyCrmUserAvatarMutationOptions = Apollo.BaseMutationOptions<ModifyCrmUserAvatarMutation, ModifyCrmUserAvatarMutationVariables>;