import { Stack } from "@mui/material";
import { TFunction } from "@tolgee/core";
import React from "react";
import { ViewerShapeFragment } from "../../auth/UserDataProvider.generated";
import { FilterChip } from "../../commons/filters/FilterChip";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes";
import { FilterCrmCompaniesOrPersonsChips } from "../crm-companies/CrmCompaniesOrPersonsSelect";
import { FilterCrmCompanyChips } from "../crm-companies/CrmCompaniesSelect";
import { CrmCompanyFilterChip } from "../crm-companies/CrmCompanyFilterChip";
import { FilterOrganisationUsersChips } from "../organisations/OrganisationUsersSelect";
import {
  FilterProjectCategoryChips,
  FilterProjectStateChips,
  FilterProjectTypeChips,
} from "../projects/filters";
import { FilterAssigneesChips } from "../projects/filters/ProjectAssignees";
import { FilterProjectPhasesChips } from "../projects/filters/ProjectPhases";
import { FilterSourcesChips } from "../projects/filters/ProjectSources";
import { Filters } from "./OpportunitiesList";

export function renderOpportunitiesFilterChips({
  viewer,
  t,
  filters,
  setFilters,
  allowedStates,
  showRelevantToMe = true,
}: {
  viewer: ViewerShapeFragment;
  t: TFunction<("Opportunities" | "Global")[]>;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  allowedStates: ProjectStateMachineStatus[];
  showRelevantToMe?: boolean;
}) {
  return filters?.filterCategories?.length ||
    filters?.filterType?.length ||
    filters?.filterBuildingZip ||
    filters?.filterMinBudget ||
    filters?.filterWithinRadiusKm ||
    filters?.filterByCrmLinkIds?.length ||
    filters?.filterAssigneeIds?.length ||
    filters?.filterPhaseIds?.length ||
    filters?.filterBySourceUserIds?.length ||
    filters?.filterBySourceCrmOrganisationIds?.length ||
    (filters?.filterIncludeState?.length &&
      filters?.filterIncludeState?.length !== allowedStates.length) ||
    filters?.filterByCrmOrganisationId ||
    filters?.sources?.length ||
    (showRelevantToMe &&
      filters.filterRelevantToUserIds &&
      filters.filterRelevantToUserIds.length > 0 &&
      filters.filterRelevantToUserIds.includes(viewer.id)) ? (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {showRelevantToMe &&
      filters.filterRelevantToUserIds &&
      filters.filterRelevantToUserIds.length > 0 &&
      filters.filterRelevantToUserIds.includes(viewer.id) ? (
        <FilterChip
          onDelete={() => {
            setFilters(filters => {
              let newRelevantIds = [...(filters.filterRelevantToUserIds ?? [])];
              if (newRelevantIds.includes(viewer.id))
                newRelevantIds = newRelevantIds.filter(id => id !== viewer.id);
              return {
                ...filters,
                filterRelevantToUserIds:
                  newRelevantIds.length > 0 ? newRelevantIds : undefined,
              };
            });
          }}
          value={t("Relevant to me", { ns: "Opportunities" })}
        />
      ) : null}
      <FilterProjectCategoryChips
        values={filters?.filterCategories}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            filterCategories: newValue,
          }))
        }
      />
      <FilterProjectTypeChips
        values={filters?.filterType}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            filterType: newValue,
          }))
        }
      />
      <FilterChip
        label={t("Zip Code", {
          ns: "Opportunities",
        })}
        resetValue={""}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            filterBuildingZip: newValue,
          }))
        }
        value={filters?.filterBuildingZip}
      />
      <FilterChip
        label={t("Min Budget", {
          ns: "Opportunities",
        })}
        resetValue={0}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            filterMinBudget: newValue,
          }))
        }
        value={filters?.filterMinBudget}
      />
      <FilterChip
        label={t("Within radius (km)", {
          ns: "Opportunities",
        })}
        resetValue={0}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            filterWithinRadiusKm: newValue,
          }))
        }
        value={filters?.filterWithinRadiusKm}
      />
      <FilterProjectStateChips
        values={filters?.filterIncludeState}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            filterIncludeState: newValue,
          }))
        }
        allowedStates={allowedStates}
      />
      <FilterProjectPhasesChips
        values={filters?.filterPhaseIds}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            filterPhaseIds: newValue,
          }))
        }
        allowedStates={allowedStates}
      />
      <FilterAssigneesChips
        values={filters?.filterAssigneeIds}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            filterAssigneeIds: newValue,
          }))
        }
      />
      <FilterOrganisationUsersChips
        values={filters?.filterBySourceUserIds}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterBySourceUserIds: newValue,
          }))
        }
        label={t("Created by", { ns: "Opportunities" })}
      />
      <CrmCompanyFilterChip
        label={t("Client", { ns: "Opportunities" })}
        value={filters?.filterByCrmOrganisationId}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCrmOrganisationId: newValue,
          }))
        }
      />
      <FilterCrmCompaniesOrPersonsChips
        value={filters?.filterByCrmLinkIds}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCrmLinkIds: newValue,
          }))
        }
        label={t("Contact", { ns: "Projects" })}
      />
      <FilterCrmCompanyChips
        value={filters?.filterBySourceCrmOrganisationIds}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterBySourceCrmOrganisationIds: newValue,
          }))
        }
        label={t("Created by", { ns: "Opportunities" })}
      />
      <FilterSourcesChips
        values={filters?.sources}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            sources: newValue,
          }))
        }
      />
    </Stack>
  ) : undefined;
}
