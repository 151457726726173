import { useFormikContext } from "formik";
import React from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import { ItemAttachment } from "../../../../clients/graphqlTypes";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { getAttachmentValidationSchema } from "../../attachments/AttachmentField";
import {
  FilesBoxTable,
  FilesBoxTableForm,
} from "../../attachments/FilesBoxTable";
import { Attachment, getRotatedUrl } from "../../attachments/helpers";
import { useProductAttachments } from "../../attachments/useAttachments";
import { ProductOverview__ProductFragment } from "../Product.generated";

export type FormValues = {
  attachments: (Attachment | ItemAttachment)[];
};

export function useValidationSchema() {
  return Yup.object().shape({
    attachments: Yup.array(getAttachmentValidationSchema()),
  });
}

export function ProductOverviewFilesBox({
  product,
  isEditable,
  refetchQueriesOnAction,
}: {
  product: ProductOverview__ProductFragment;
  isEditable?: boolean;
  refetchQueriesOnAction?: string[];
}) {
  const attachments = product.attachments.filter(
    attachment => attachment.group !== "3dItemDefinition"
  );

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useProductAttachments(product.id, refetchQueriesOnAction);

  return (
    <FilesBoxTable
      attachments={attachments}
      canEdit={isEditable}
      canSign={false}
      addAttachments={addAttachments}
      modifyAttachment={modifyAttachment}
      removeAttachment={removeAttachment}
      loading={loading}
      renderAddIconButton={button => (
        <RestrictedByCapabilityWithDebug capability="PIM">
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
            {button}
          </RestrictedByOrganisationPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      )}
      renderEditIconButton={button => (
        <RestrictedByCapabilityWithDebug capability="PIM">
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
            {button}
          </RestrictedByOrganisationPermissionWithDebug>
        </RestrictedByCapabilityWithDebug>
      )}
    />
  );
}

export function ProductOverviewFilesBoxForm() {
  const { values, setValues } = useFormikContext<FormValues>();

  return (
    <FilesBoxTableForm
      attachments={values.attachments}
      handleAdd={attachment => {
        setValues(values => ({
          ...values,
          attachments: values.attachments.concat({ ...attachment, id: v4() }),
        }));
      }}
      handleDelete={attachmentId => {
        setValues(values => ({
          ...values,
          attachments: values.attachments.filter(a => a.id !== attachmentId),
        }));
      }}
      handleRotate={(attachmentId, direction) => {
        setValues(values => ({
          ...values,
          attachments: values.attachments.map(a =>
            a.id === attachmentId
              ? { ...a, url: getRotatedUrl(a.url, direction) }
              : a
          ),
        }));
      }}
      canSign={false}
      renderImages={true}
      isInitiallyClosed={false}
    />
  );
}
