import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { pick } from "lodash";
import React from "react";
import {
  Outlet,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Agreement,
  ItemType,
  ModifyItemProductValuesInput,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { useUserData } from "../../auth/useUserData";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { Stack } from "../../commons/layout/Stack";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { REQUIREMENT_ITEM_TYPES } from "../../constants";
import { isAllowedToPaste } from "../../features/doc-items/constraints";
import {
  getViewerDecisionRole,
  isTreePreviewItemVisible,
} from "../../features/doc-items/helpers";
import { useCreateItem } from "../../features/doc-items/hooks/useCreateItem";
import { useDocItemsClipboard } from "../../features/doc-items/hooks/useDocItemsClipboard";
import { ProductSearchItem__ProductSearchResultFragment } from "../../features/products/Product.generated";
import {
  createTreeItem,
  createTreeItemInput,
} from "../../features/projects/requirement-trees";
import { RequirementAction } from "../../features/requirements/RequirementAction";
import { RequirementSubHeader } from "../../features/requirements/RequirementSubHeader";
import { shouldRenderRequirementCreateInput } from "../../features/requirements/helpers";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../../features/templates/quote/TemplatesQuoteSelectMultipleModal.generated";
import { ThreeD } from "../../features/threeD/ThreeD";
import { ThreeDEditorRef } from "../../features/threeD/ThreeDEditor";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree";
import { VirtualBareTreeStandaloneItem } from "../../trees-virtual/components/VirtualBareTreeStandaloneItem";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton";
import {
  ProjectRequirementThreeD_ItemFragment,
  useProjectRequirementThreeDQuery,
  useProjectRequirementThreeD_CreateItemsFromTemplatesMutation,
  useProjectRequirementThreeD_CreateItemsMutation,
  useProjectRequirementThreeD_PasteItemFromClipboardMutation,
} from "./ProjectRequirementThreeD.generated";

export function ProjectRequirementThreeD({
  projectId,
  requirementId,
  submenuItems,
  prefixBreadcrumbs,
  activeSubmenuItem,
}: {
  projectId: string;
  requirementId: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) {
  const pathToProject = `/projects/${projectId}`;
  const pathToDocList = `/projects/${projectId}/requirements`;
  const pathToDoc = `/projects/${projectId}/requirements/${requirementId}`;
  const pathToDocPage = `${pathToDoc}/3d`;

  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");

  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;

  const navigate = useNavigate();
  const { t } = useTranslate([
    "ProjectPageTopbar",
    "Quote",
    "Global",
    "QuoteEdit",
  ]);

  const threeDEditorRef = React.useRef<ThreeDEditorRef>(null);

  const viewer = useUserData().currentUser!;

  // const decisionProcessRef = React.useRef<DecisionProcessRef>(null);
  // const startDecisionProcess = useCallback((itemId: string) => {
  //   decisionProcessRef.current?.startDecisionProcess(itemId);
  // }, []);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const { isMinDesktop, isMinTablet } = useScreenWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);
  // const [treeItemsCount, setTreeItemsCount] = React.useState<number>(0);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "requirement",
    requirementId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useProjectRequirementThreeDQuery({
    client,
    variables: {
      quoteId: requirementId,
      projectId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.requirement)?.requirement;
  const docTitle = doc?.title ?? "";

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [pathToDocPage, navigate]
  );

  const viewerIsContractor = true; // project?.contractor?.id === viewer.organisation.id; // TODO-MODEL2022 fix!
  const viewerIsOwningOrganisation =
    doc?.owningSystemOrganisationId === viewer.organisation.id;

  const viewerPermissions: PermissionName[] = React.useMemo(
    () => doc?.viewerPermissions ?? [],
    [doc?.viewerPermissions]
  );

  // TODO: STRIPDOWN - why do we need agreement on the requirement doc?
  const agreement: Agreement = "NONE";

  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateRequirementItems({
    projectId,
    requirementId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const TreeItem = React.useMemo(
    () =>
      agreement !== undefined
        ? createTreeItem({
            pathToDocPage,
            requirementId,
            projectId,
            document: { viewerPermissions },
            docAgreement: agreement,
            startConfirmProcess,
            navigateToItem,
            expandedItemIds: expandedStore.expandedItemIds,
            setItemExpanded: expandedStore.setItemExpanded,
            pasteItem,
            isAllowedToPasteItem,
            viewerDecisionRole,
          })
        : () => null,
    [
      pathToDocPage,
      requirementId,
      projectId,
      viewerPermissions,
      startConfirmProcess,
      navigateToItem,
      expandedStore.expandedItemIds,
      expandedStore.setItemExpanded,
      pasteItem,
      isAllowedToPasteItem,
      viewerDecisionRole,
    ]
  );

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        requirementId,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      isAllowedToPasteItem,
      pasteItem,
      requirementId,
    ]
  );

  const [filteredItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems
      .filter(e => e.authorOrganisationId === viewer.organisation.id)
      .filter(
        item =>
          viewerIsOwningOrganisation ||
          (!item.isHidden && !item.isItemEliminated)
      )
      .filter(item => !item.isAnyParentItemEliminated);
    return [treeItems, rootItem, allDocItems];
  }, [doc, viewer.organisation.id, viewerIsOwningOrganisation]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  React.useEffect(() => {
    if (
      !query.loading &&
      selectedItemId &&
      !filteredItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredItems,
    pathToDocPage,
    rootItem,
    navigate,
    query.loading,
  ]);

  const filterItem = React.useCallback(
    (i: ProjectRequirementThreeD_ItemFragment) =>
      isTreePreviewItemVisible(viewerIsContractor, i, allDocItems, false),
    [allDocItems, viewerIsContractor]
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  const canEdit = viewer.organisation.id === doc?.owningSystemOrganisationId;

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc ? (
          <RequirementSubHeader
            project={project}
            requirement={doc}
            activeView="3d"
            canEdit={canEdit}
            pathToDoc={pathToDoc}
            pathToDocList={pathToDocList}
            pathToDocPage={pathToDocPage}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
      action={
        project && doc ? (
          <RequirementAction
            doc={doc}
            project={project}
            pathToProject={pathToProject}
          />
        ) : undefined
      }
    >
      {isMinDesktop && viewerIsOwningOrganisation && (
        <PageColumn
          $hasRightBorder
          $hasBackground
          $display="block"
          ref={isMinTablet ? setTreeContainer : undefined}
        >
          {project && doc && rootItem && (
            <Stack flexDirection={"column"} spacing={2}>
              <Box>
                <TreeToggleAllExpandedButton
                  type="button"
                  areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                  setAllItemsExpanded={expandedStore.setAllItemsExpanded}
                />
              </Box>

              <Box>
                <VirtualBareTreeStandaloneItem
                  item={rootItem}
                  items={filteredItems}
                  allItems={allDocItems}
                  depth={0}
                  itemComponent={TreeItem}
                  selected={isRootSelected}
                  sticky={isRootSelected}
                  top={0}
                  clickable
                  onClick={() => navigateToItem(rootItem.id)}
                  to={`${pathToDocPage}/items/${rootItem.id}`}
                />

                {/* <TreeConnector $isAtTheTopOfTree={treeItemsCount > 0} /> */}
                <VirtualItemTree<ProjectRequirementThreeD_ItemFragment, true>
                  docId={doc.id}
                  projectId={projectId}
                  items={filteredItems}
                  allItems={allDocItems}
                  filterItem={filterItem}
                  selectedItemId={selectedItemId}
                  enableCreating={true}
                  enableDragging={true}
                  shouldRenderCreateInput={shouldRenderRequirementCreateInput}
                  documentItemTypes={REQUIREMENT_ITEM_TYPES}
                  container={treeContainer}
                  itemComponent={TreeItem}
                  inputComponent={TreeItemInput}
                  expandedStore={expandedStore}
                  // onItemsCountChange={setTreeItemsCount}
                />
              </Box>
            </Stack>
          )}
        </PageColumn>
      )}
      <ConfirmProcess ref={confirmProcessRef} />

      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
        }}
      />

      <PageContainer $fullHeight $noPadding>
        <ThreeD
          threeDEditorRef={threeDEditorRef}
          projectId={projectId}
          docId={requirementId}
          returnPath={
            returnPath ?? `/projects/${projectId}/requirements/${requirementId}`
          }
          allDocItems={allDocItems}
          isEditable={viewerIsOwningOrganisation && isMinDesktop}
        />
      </PageContainer>
    </Page>
  );
}

function useCreateRequirementItems({
  projectId,
  requirementId,
  expandedItemIds,
}: {
  projectId: string;
  requirementId: string;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  const [createItems] = useProjectRequirementThreeD_CreateItemsMutation({
    client,
  });

  const { createItem, createItemByType } = useCreateItem({
    projectId,
    createDocumentItems: createItems,
    expandedItemIds,
  });

  const [createItemsFromTemplatesMutation] =
    useProjectRequirementThreeD_CreateItemsFromTemplatesMutation({
      client,
    });
  const createItemFromTemplate = React.useCallback(
    async (title: string, parentItemId: string, templateQuoteId: string) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: requirementId,
            parentItemId,
            templates: [
              {
                templateQuoteId,
              },
            ],
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items[0];
    },
    [
      createItemsFromTemplatesMutation,
      expandedItemIds,
      projectId,
      requirementId,
    ]
  );
  const createItemsFromTemplates = React.useCallback(
    async (
      parentItemId: string,
      templates: {
        quantity: number;
        template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
      }[]
    ) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: requirementId,
            parentItemId,
            templates: templates.map(tpl => ({
              templateQuoteId: tpl.template.id,
              templateEstimatedQuantity: tpl.quantity,
            })),
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items;
    },
    [
      createItemsFromTemplatesMutation,
      expandedItemIds,
      projectId,
      requirementId,
    ]
  );

  const createItemsWithProducts = React.useCallback(
    async (
      parentItemId: string,
      products: {
        quantity: number;
        product:
          | ProductSearchItem__ProductSearchResultFragment
          | ModifyItemProductValuesInput;
      }[]
    ) => {
      const result = await createItems({
        variables: {
          input: {
            projectId,
            docId: requirementId,
            parentItemId,
            items: products.map(opts =>
              "__typename" in opts.product
                ? {
                    title: opts.product.texts?.title ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    product: pick(opts.product, "supplierId", "articleNumber"),
                  }
                : {
                    title: opts.product.productTitle ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    productValues: opts.product,
                  }
            ),
            expandedItemIds,
          },
        },
      });
      return result.data?.createItems.items;
    },
    [createItems, expandedItemIds, projectId, requirementId]
  );

  const [copiedItem] = useDocItemsClipboard();
  const [pasteItemFromClipboardMutation] =
    useProjectRequirementThreeD_PasteItemFromClipboardMutation({ client });
  const pasteItem = React.useCallback(
    async (docId: string, parentItemId: string) => {
      if (!copiedItem) throw new Error("No item copied");

      const result = await pasteItemFromClipboardMutation({
        variables: {
          input: {
            sourceProjectId: copiedItem.projectId,
            sourceDocId: copiedItem.docId,
            sourceItemId: copiedItem.itemId,
            destProjectId: projectId,
            destDocId: docId,
            destItemId: parentItemId,
            expandedItemIds,
          },
        },
      });
    },
    [copiedItem, pasteItemFromClipboardMutation, projectId, expandedItemIds]
  );
  const isAllowedToPasteItem = React.useCallback(
    (parentItem: { type: ItemType }) => {
      if (!copiedItem?.itemType || !parentItem.type) return false;

      return isAllowedToPaste({
        documentItemTypes: REQUIREMENT_ITEM_TYPES,
        copiedItemType: copiedItem.itemType,
        parentItemType: parentItem.type,
      });
    },
    [copiedItem?.itemType]
  );

  return {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  };
}
