import { gql, useApolloClient } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { namedOperations } from "../../../../../clients/graphqlTypes";
import {
  QuoteTemplateAddToShopButton_QuoteTemplateFragment,
  useAddQuoteTemplateToShopMutation,
  useRemoveQuoteTemplateFromShopMutation,
} from "./QuoteTemplateAddToShopButton.generated";

interface Props extends ButtonProps {
  doc: QuoteTemplateAddToShopButton_QuoteTemplateFragment;
}

export const QuoteTemplateAddToShopButton = ({ doc, ...rest }: Props) => {
  const { t } = useTranslate(["Templates", "TemplatesPublishing"]);

  const client = useApolloClient();
  const [add, { loading: addLoading }] = useAddQuoteTemplateToShopMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [
      namedOperations.Query.TemplateQuoteEdit,
      namedOperations.Query.TemplateQuoteVersion,
    ],
  });
  const [remove, { loading: removeLoading }] =
    useRemoveQuoteTemplateFromShopMutation({
      client,
      awaitRefetchQueries: true,
      refetchQueries: [
        namedOperations.Query.TemplateQuoteEdit,
        namedOperations.Query.TemplateQuoteVersion,
      ],
    });

  return doc.hasAnyPublishedVersion ? (
    <LoadingButton
      loading={addLoading || removeLoading}
      startIcon={
        rest.size !== "extra-small" ? (
          doc.addedToShop ? (
            <DeleteIcon />
          ) : (
            <AddIcon />
          )
        ) : undefined
      }
      variant={doc.addedToShop ? "outlined" : "contained"}
      color="primary"
      sx={{ whiteSpace: "nowrap" }}
      onClick={async e => {
        e.preventDefault();
        e.stopPropagation();
        if (doc.addedToShop) {
          await remove({
            variables: { input: { quoteTemplateId: doc.id } },
          });
        } else {
          await add({
            variables: { input: { quoteTemplateId: doc.id } },
          });
        }
      }}
      {...rest}
    >
      {doc.addedToShop
        ? t("Remove from shop", {
            ns: "TemplatesPublishing",
          })
        : t("Add to shop", {
            ns: "TemplatesPublishing",
          })}
    </LoadingButton>
  ) : null;
};
