import { ellipsisStyle, useFormatting } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { CreatedDateLine } from "../../commons/DataItem";
import { RequirementListItemFragment } from "./RequirementListItem.generated";
import { RequirementStatusBadge } from "./RequirementStatusBadge";

interface Props {
  requirement: RequirementListItemFragment;
}

export const RequirementListItem = ({ requirement }: Props) => {
  const { getFormattedDateTime } = useFormatting();
  const { t } = useTranslate("DataItem");
  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Typography variant={"h4"}>
        <span>{requirement.title}</span>
        <span
          style={{
            display: "inline-flex",
            marginLeft: "8px",
            verticalAlign: "middle",
          }}
        >
          <RequirementStatusBadge requirement={requirement} small />
        </span>
      </Typography>
      <Box
        gap={0.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        style={ellipsisStyle}
      >
        <Tooltip title={t("Created date")} arrow placement="top">
          <div>
            <CreatedDateLine>
              {getFormattedDateTime(requirement.createdAt)}
            </CreatedDateLine>
          </div>
        </Tooltip>
      </Box>
    </Stack>
  );
};
