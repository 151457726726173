import React from "react";
import { useTranslate } from "@tolgee/react";
import { Vertical } from "../types";

export const useVerticals = () => {
  const { t } = useTranslate(["Verticals", "Global"]);

  const verticalLabels = React.useMemo(
    (): Record<Vertical, string> => ({
      general: t("general", {
        ns: "Verticals",
      }),
      concrete_and_reinforced_concrete: t("concrete_and_reinforced_concrete", {
        ns: "Verticals",
      }),
      road: t("road", {
        ns: "Verticals",
      }),
      excavation_and_earthworks: t("excavation_and_earthworks", {
        ns: "Verticals",
      }),
      well_construction: t("well_construction", {
        ns: "Verticals",
      }),
      scaffolding: t("scaffolding", {
        ns: "Verticals",
      }),
      masonry: t("masonry", {
        ns: "Verticals",
      }),
      roof: t("roof", {
        ns: "Verticals",
      }),
      heat_and_cold_insulation: t("heat_and_cold_insulation", {
        ns: "Verticals",
      }),
      sound_insulation: t("sound_insulation", {
        ns: "Verticals",
      }),
      screed: t("screed", {
        ns: "Verticals",
      }),
      stone_masonry: t("stone_masonry", {
        ns: "Verticals",
      }),
      cast_stone: t("cast_stone", {
        ns: "Verticals",
      }),
      metal: t("metal", {
        ns: "Verticals",
      }),
      drywall: t("drywall", {
        ns: "Verticals",
      }),
      sanitary: t("sanitary", {
        ns: "Verticals",
      }),
      plumbing: t("plumbing", {
        ns: "Verticals",
      }),
      heating: t("heating", {
        ns: "Verticals",
      }),
      heat_pump: t("heat_pump", {
        ns: "Verticals",
      }),
      furnace_and_air_heating: t("furnace_and_air_heating", {
        ns: "Verticals",
      }),
      electrical_installation: t("electrical_installation", {
        ns: "Verticals",
      }),
      solar: t("solar", {
        ns: "Verticals",
      }),
      chimney_sweeping: t("chimney_sweeping", {
        ns: "Verticals",
      }),
      carpentry_and_woodwork: t("carpentry_and_woodwork", {
        ns: "Verticals",
      }),
      painting: t("painting", {
        ns: "Verticals",
      }),
      stucco: t("stucco", {
        ns: "Verticals",
      }),
      glass: t("glass", {
        ns: "Verticals",
      }),
      tile: t("tile", {
        ns: "Verticals",
      }),
      parquet: t("parquet", {
        ns: "Verticals",
      }),
      interior_doors: t("interior_doors", {
        ns: "Verticals",
      }),
      interior_decoration: t("interior_decoration", {
        ns: "Verticals",
      }),
      alarm_systems: t("alarm_systems", {
        ns: "Verticals",
      }),
      windows_and_doors: t("windows_and_doors", {
        ns: "Verticals",
      }),
      shutters_and_sun_protection: t("shutters_and_sun_protection", {
        ns: "Verticals",
      }),
      gardening: t("gardening", {
        ns: "Verticals",
      }),
      swimming_pools: t("swimming_pools", {
        ns: "Verticals",
      }),
    }),
    [t]
  );

  const verticalOptions = React.useMemo(
    (): { value: Vertical; label: string }[] =>
      [
        { value: "general", label: verticalLabels.general },
        {
          value: "concrete_and_reinforced_concrete",
          label: verticalLabels.concrete_and_reinforced_concrete,
        },
        { value: "road", label: verticalLabels.road },
        {
          value: "excavation_and_earthworks",
          label: verticalLabels.excavation_and_earthworks,
        },
        {
          value: "well_construction",
          label: verticalLabels.well_construction,
        },
        { value: "scaffolding", label: verticalLabels.scaffolding },
        { value: "masonry", label: verticalLabels.masonry },
        { value: "roof", label: verticalLabels.roof },
        {
          value: "heat_and_cold_insulation",
          label: verticalLabels.heat_and_cold_insulation,
        },
        { value: "sound_insulation", label: verticalLabels.sound_insulation },
        { value: "screed", label: verticalLabels.screed },
        { value: "stone_masonry", label: verticalLabels.stone_masonry },
        { value: "cast_stone", label: verticalLabels.cast_stone },
        { value: "metal", label: verticalLabels.metal },
        { value: "drywall", label: verticalLabels.drywall },
        { value: "sanitary", label: verticalLabels.sanitary },
        { value: "plumbing", label: verticalLabels.plumbing },
        { value: "heating", label: verticalLabels.heating },
        { value: "heat_pump", label: verticalLabels.heat_pump },
        {
          value: "furnace_and_air_heating",
          label: verticalLabels.furnace_and_air_heating,
        },
        {
          value: "electrical_installation",
          label: verticalLabels.electrical_installation,
        },
        { value: "solar", label: verticalLabels.solar },
        { value: "chimney_sweeping", label: verticalLabels.chimney_sweeping },
        {
          value: "carpentry_and_woodwork",
          label: verticalLabels.carpentry_and_woodwork,
        },
        { value: "painting", label: verticalLabels.painting },
        { value: "stucco", label: verticalLabels.stucco },
        { value: "glass", label: verticalLabels.glass },
        { value: "tile", label: verticalLabels.tile },
        { value: "parquet", label: verticalLabels.parquet },
        { value: "interior_doors", label: verticalLabels.interior_doors },
        {
          value: "interior_decoration",
          label: verticalLabels.interior_decoration,
        },
        { value: "alarm_systems", label: verticalLabels.alarm_systems },
        {
          value: "windows_and_doors",
          label: verticalLabels.windows_and_doors,
        },
        {
          value: "shutters_and_sun_protection",
          label: verticalLabels.shutters_and_sun_protection,
        },
        { value: "gardening", label: verticalLabels.gardening },
        { value: "swimming_pools", label: verticalLabels.swimming_pools },
      ].sort((a, b) => a.label.localeCompare(b.label)) as {
        value: Vertical;
        label: string;
      }[],
    [verticalLabels]
  );

  return { verticalLabels, verticalOptions };
};
