import {
  FormattedPercentage,
  getFormattedPercentage,
  getFormattedPrice,
  underlineDoubleStyle,
} from "@msys/ui";
import { Divider, Paper, Typography } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import { Stack } from "../../../commons/layout/Stack";
import { VatVisibility } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { PreviewValue } from "./QuotePreviewValues";

interface Props {
  discountPercentage: number;
  discountReason: string | undefined;
  isDiscountPercentageVisible: boolean;
  calculation:
    | {
        priceSubTotal: number;
        priceNetTotal: number;
        vatRate: number;
        priceVatTotal: number;
        priceTotal: number;
      }
    | undefined
    | null;
  changedCalculation?: {
    priceSubTotal: number;
    priceNetTotal: number;
    vatRate: number;
    priceVatTotal: number;
    priceTotal: number;
  };
  vatVisibility: VatVisibility;
}

export const QuotePreviewSummary = ({
  discountPercentage,
  discountReason,
  isDiscountPercentageVisible,
  calculation,
  changedCalculation,
  vatVisibility,
}: Props) => {
  const { t } = useTranslate(["Quote", "Global"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const showDiscount = discountPercentage !== 0;
  const showVat = vatVisibility === "SHOW";
  const showSubtotal = discountPercentage !== 0 || vatVisibility === "SHOW";
  const showNet = discountPercentage !== 0 && vatVisibility === "SHOW";

  return (
    <Paper>
      <Stack flexDirection="column" p={2}>
        {showSubtotal && (
          <Typography variant="h3" component="div">
            <Stack justifyContent="space-between">
              <span>
                {t("Subtotal", {
                  ns: "Quote",
                })}
              </span>
              <span style={{ textDecoration: "underline" }}>
                <PreviewValue
                  value={getFormattedPrice(
                    calculation?.priceSubTotal,
                    language
                  )}
                  changedValue={getFormattedPrice(
                    changedCalculation?.priceSubTotal,
                    language
                  )}
                />
              </span>
            </Stack>
          </Typography>
        )}

        {showDiscount && (
          <Stack justifyContent="space-between">
            <span>
              {discountReason ||
                t("Discount", {
                  ns: "Quote",
                })}
              {isDiscountPercentageVisible && (
                <>
                  {" "}
                  (<FormattedPercentage value={discountPercentage} />)
                </>
              )}
            </span>
            <PreviewValue
              value={
                calculation
                  ? getFormattedPrice(
                      calculation.priceSubTotal - calculation.priceNetTotal,
                      language
                    )
                  : undefined
              }
              changedValue={
                changedCalculation
                  ? getFormattedPrice(
                      changedCalculation.priceSubTotal -
                        changedCalculation.priceNetTotal,
                      language
                    )
                  : undefined
              }
              prefix={"− "}
            />
          </Stack>
        )}

        {(showSubtotal || showDiscount) && <Divider />}

        {showNet && (
          <Typography variant="h3" component="div">
            <Stack justifyContent="space-between">
              <span>
                {t("Net price", {
                  ns: "Quote",
                })}
              </span>
              <PreviewValue
                value={getFormattedPrice(calculation?.priceNetTotal, language)}
                changedValue={getFormattedPrice(
                  changedCalculation?.priceNetTotal,
                  language
                )}
              />
            </Stack>
          </Typography>
        )}

        {showVat && calculation && (
          <Stack justifyContent="space-between">
            <span>
              {t("VAT", {
                ns: "Quote",
              })}{" "}
              (
              <FormattedPercentage
                value={calculation.vatRate}
                notSetText={t("Not set", {
                  ns: "Global",
                })}
              />
              )
            </span>
            <PreviewValue
              value={getFormattedPrice(calculation?.priceVatTotal, language)}
              changedValue={getFormattedPrice(
                calculation.priceVatTotal,
                language
              )}
              prefix={"+ "}
            />
          </Stack>
        )}

        {(showNet || showVat) && <Divider />}

        <Typography variant="h1" component="div">
          <Stack justifyContent="space-between">
            <div>
              <div>
                {t("Total", {
                  ns: "Quote",
                })}
              </div>
              {vatVisibility === "SHOW_SMALL" && (
                <Typography variant="caption" component="div">
                  {"* "}
                  {t("All prices plus {percent} VAT", {
                    ns: "Quote",

                    percent: getFormattedPercentage(
                      calculation?.vatRate ?? 0,
                      language
                    ),
                  })}
                </Typography>
              )}
            </div>
            <span style={underlineDoubleStyle}>
              <PreviewValue
                value={
                  vatVisibility === "SHOW"
                    ? getFormattedPrice(calculation?.priceTotal, language)
                    : getFormattedPrice(calculation?.priceNetTotal, language)
                }
                changedValue={
                  vatVisibility === "SHOW"
                    ? getFormattedPrice(
                        changedCalculation?.priceTotal,
                        language
                      )
                    : getFormattedPrice(
                        changedCalculation?.priceNetTotal,
                        language
                      )
                }
                suffix={vatVisibility === "SHOW_SMALL" ? "*" : undefined}
              />
            </span>
          </Stack>
        </Typography>
      </Stack>
    </Paper>
  );
};
