/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { OrganisationVerticalSettingFragmentDoc, QuoteVerticalSettingFragmentDoc } from '../../verticals/form-fields/VerticalsFormField.generated';
import { OrganisationQuoteSettingsForm_DefaultsFragmentDoc } from './OrganisationQuoteSettingsBox.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationPriceAndCalculationSettingsForm_DefaultsFragment = { __typename: 'OrganisationDefaults', id: string, defaultMaterialMargin: number, defaultContractType: Types.ContractType, defaultQuoteIsBinding: boolean, defaultQuoteValidityDuration: number, defaultQuoteShowOfferConditions: boolean, defaultQuoteMaterialPriceHandling: Types.QuoteMaterialPriceHandling, defaultMaterialMarginRanges: Array<{ __typename: 'QuoteMaterialMarginRange', priceFrom: number, priceTo: number, materialMargin: number }>, defaultVerticalSettings: Array<{ __typename: 'OrganisationVerticalSetting', id: string, vertical: Types.Vertical, averageWorkBuyingPrice: number, averageWorkSellingPrice: number }> };

export type OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationVariables = Types.Exact<{
  input: Types.OrganisationDefaultsUpdateInput;
}>;


export type OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation = { updateOrganisationDefaults: { __typename: 'OrganisationDefaultsUpdateResult', organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultMaterialMargin: number, defaultContractType: Types.ContractType, defaultQuoteIsBinding: boolean, defaultQuoteValidityDuration: number, defaultQuoteShowOfferConditions: boolean, defaultQuoteMaterialPriceHandling: Types.QuoteMaterialPriceHandling, defaultMaterialMarginRanges: Array<{ __typename: 'QuoteMaterialMarginRange', priceFrom: number, priceTo: number, materialMargin: number }>, defaultVerticalSettings: Array<{ __typename: 'OrganisationVerticalSetting', id: string, vertical: Types.Vertical, averageWorkBuyingPrice: number, averageWorkSellingPrice: number }> } } };

export const OrganisationPriceAndCalculationSettingsForm_DefaultsFragmentDoc = gql`
    fragment OrganisationPriceAndCalculationSettingsForm_Defaults on OrganisationDefaults {
  id
  defaultMaterialMargin
  defaultMaterialMarginRanges {
    priceFrom
    priceTo
    materialMargin
  }
  defaultContractType
  defaultQuoteIsBinding
  defaultQuoteValidityDuration
  defaultQuoteShowOfferConditions
  defaultQuoteMaterialPriceHandling
  defaultVerticalSettings {
    id
    ...OrganisationVerticalSetting
  }
}
    ${OrganisationVerticalSettingFragmentDoc}`;
export const OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsDocument = gql`
    mutation OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaults($input: OrganisationDefaultsUpdateInput!) {
  updateOrganisationDefaults(input: $input) {
    organisationDefaults {
      id
      ...OrganisationQuoteSettingsForm_Defaults
    }
  }
}
    ${OrganisationQuoteSettingsForm_DefaultsFragmentDoc}`;
export type OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationFn = Apollo.MutationFunction<OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationVariables>;

/**
 * __useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation__
 *
 * To run a mutation, you first call `useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationPriceAndCalculationSettingsFormModifyOrganisationDefaultsMutation, { data, loading, error }] = useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationVariables>(OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsDocument, options);
      }
export type OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationHookResult = ReturnType<typeof useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation>;
export type OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationResult = Apollo.MutationResult<OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation>;
export type OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationOptions = Apollo.BaseMutationOptions<OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation, OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutationVariables>;