import { Modal } from "@msys/ui";
import { OrganisationUserSelect } from "./OrganisationUserSelect";
import React from "react";
import { useTranslate } from "@tolgee/react";

interface Props {
  title: string;
  handleClose: () => void;
  handleComplete: (userId: string, handleClose: () => void) => void;
}

export const OrganisationMemberSelectModal = ({
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const [selectedMember, setSelectedMember] = React.useState<string | null>(
    null
  );

  return (
    <Modal
      title={title}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", { ns: "Global" }),
          handleClick: () => {
            if (!selectedMember) throw new Error("No member selected");
            handleComplete(selectedMember, handleClose);
          },
          buttonProps: { disabled: !selectedMember },
        },
      ]}
    >
      <OrganisationUserSelect
        userId={selectedMember}
        onChange={value => {
          setSelectedMember(value);
        }}
        inputLabel={t("Contact", { ns: "Global" })}
      />
    </Modal>
  );
};
