import { useApolloClient } from "@apollo/client";
import React from "react";
import { useExportAsJsonMutation } from "./useJsonExport.generated";

export const useJsonExport = () => {
  const client = useApolloClient();
  const [exportTemplatesMutation, { loading }] = useExportAsJsonMutation({
    client,
  });

  const exportFunction = React.useCallback(
    async (docIds?: { docId: string; versionNumber: number | null }[]) => {
      const exportTemplatesResult = await exportTemplatesMutation({
        variables: {
          input: {
            ids: docIds ?? null,
          },
        },
      });

      if (!exportTemplatesResult.data?.exportAsJson.downloadUrl)
        throw new Error("Failed to export");

      return exportTemplatesResult.data.exportAsJson;
    },
    [exportTemplatesMutation]
  );

  return [exportFunction, { isLoading: loading }] as const;
};
