import { ellipsisStyle } from "./Ellipsis";
import React from "react";
import { Tooltip } from "@mui/material";

export const TextWithOverflow = ({ children }: React.PropsWithChildren<{}>) => {
  const [isOverflow, setIsOverflow] = React.useState(false);
  return (
    <Tooltip
      title={children && isOverflow ? children : ""}
      arrow
      placement="top"
    >
      <span
        ref={ref => {
          const newIsOverflow = Boolean(
            ref && ref.scrollWidth > ref.clientWidth
          );
          if (newIsOverflow !== isOverflow) setIsOverflow(newIsOverflow);
        }}
        style={{ display: "block", width: "100%", ...ellipsisStyle }}
      >
        {children}
      </span>
    </Tooltip>
  );
};
