/* eslint-disable */
import * as Types from '../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptProjectContracteeInvitationMutationVariables = Types.Exact<{
  input: Types.AcceptProjectContracteeInvitationInput;
}>;


export type AcceptProjectContracteeInvitationMutation = { acceptProjectContracteeInvitation: { __typename: 'AcceptProjectContracteeInvitationResult', invitation: { __typename: 'ProjectContracteeInvitation', id: string } } };


export const AcceptProjectContracteeInvitationDocument = gql`
    mutation AcceptProjectContracteeInvitation($input: AcceptProjectContracteeInvitationInput!) {
  acceptProjectContracteeInvitation(input: $input) {
    invitation {
      id
    }
  }
}
    `;
export type AcceptProjectContracteeInvitationMutationFn = Apollo.MutationFunction<AcceptProjectContracteeInvitationMutation, AcceptProjectContracteeInvitationMutationVariables>;

/**
 * __useAcceptProjectContracteeInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptProjectContracteeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptProjectContracteeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptProjectContracteeInvitationMutation, { data, loading, error }] = useAcceptProjectContracteeInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptProjectContracteeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptProjectContracteeInvitationMutation, AcceptProjectContracteeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptProjectContracteeInvitationMutation, AcceptProjectContracteeInvitationMutationVariables>(AcceptProjectContracteeInvitationDocument, options);
      }
export type AcceptProjectContracteeInvitationMutationHookResult = ReturnType<typeof useAcceptProjectContracteeInvitationMutation>;
export type AcceptProjectContracteeInvitationMutationResult = Apollo.MutationResult<AcceptProjectContracteeInvitationMutation>;
export type AcceptProjectContracteeInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptProjectContracteeInvitationMutation, AcceptProjectContracteeInvitationMutationVariables>;