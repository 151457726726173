import { useApolloClient } from "@apollo/client";
import {
  CardContainer,
  MenuButton,
  MenuItemWithIcon,
  ModalOpenButton,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { groupBy } from "lodash";
import omitDeep from "omit-deep-lodash";
import React from "react";
import { Tabs } from "@msys/ui";
import { Content, ContentInput } from "../../../clients/graphqlTypes";
import { useContentsSetMutation } from "./Content.generated";
import { useTranslate } from "@tolgee/react";
import { ArticlePreviewItem } from "./ArticlePreviewItem";
import { ContentEditModal } from "./ContentEditModal";
import { ContentsModal, useContentModal } from "./ContentsModal";
import { PredefinedTopic, useContentTopics } from "./useContentTopics";

export const ContentBox = ({
  itemId,
  content,
  refetchQueries,
  isEditable,
}: {
  itemId: string;
  content: Content[];
  refetchQueries?: string[];
  isEditable?: boolean;
}) => {
  const { t } = useTranslate(["Contents", "Global"]);
  const { topicLabels } = useContentTopics();

  const [selectedTopic, setSelectedTopic] = React.useState<string>("All");

  const groupedContent = groupBy(content, "group");
  const topics = Object.keys(groupedContent);

  const client = useApolloClient();
  const [setContents] = useContentsSetMutation({
    client,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  async function addArticle(newArticle: Content) {
    await changeArticle(newArticle);
  }

  async function changeArticle(article: Content, index?: number) {
    const newContent =
      index !== undefined
        ? [...content.slice(0, index), article, ...content.slice(index + 1)]
        : [...content, article];

    await setContents({
      variables: {
        input: {
          id: itemId,
          contents: newContent.map(item =>
            omitDeep(item, ["__typename"])
          ) as ContentInput[],
        },
      },
    });
  }

  const contentToDisplay =
    selectedTopic === "All"
      ? content
      : groupedContent[selectedTopic].slice(0, 3);

  const {
    handleCloseContent,
    handleOpenContent,
    isContentOpen,
    currentArticleId,
  } = useContentModal();

  return (
    <CardContainer
      isExpandable
      title={t("Additional information", {
        ns: "Contents",
      })}
      ActionButton={
        isEditable ? (
          <ModalOpenButton
            Modal={ContentEditModal}
            modalProps={{
              title: t("Create new content", {
                ns: "Contents",
              }),
              confirmButtonLabel: t("Add", {
                ns: "Global",
              }),
              handleComplete: addArticle,
            }}
          >
            <IconButton color="primary" size="small">
              <AddIcon />
            </IconButton>
          </ModalOpenButton>
        ) : undefined
      }
      Icon={<ArticleOutlinedIcon />}
    >
      <Stack padding={1} direction="column" spacing={1}>
        {topics.length > 0 && (
          <Tabs
            condensed={true}
            onChange={setSelectedTopic}
            value={selectedTopic}
            options={[
              {
                value: "All",
                label: t("All", {
                  ns: "Global",
                }),
              },
              ...topics.map(topic => ({
                value: topic,
                label: !topic
                  ? t("Other", {
                      ns: "Contents",
                    })
                  : topicLabels[topic as PredefinedTopic] ?? topic,
              })),
            ]}
          />
        )}

        {contentToDisplay.length > 0 ? (
          contentToDisplay.map(article => (
            <ArticlePreviewItem
              key={article.id}
              title={article.title}
              group={article.group}
              previewImageUrl={article.previewImageUrl}
              MenuButton={
                isEditable ? (
                  <MenuButton>
                    <ModalOpenButton
                      Modal={ContentEditModal}
                      modalProps={{
                        title: t("Edit content", {
                          ns: "Contents",
                        }),
                        content: article,
                        confirmButtonLabel: t("Save", {
                          ns: "Global",
                        }),
                        handleComplete: async newArticle => {
                          const index = content.indexOf(article);
                          await changeArticle(newArticle, index);
                        },
                      }}
                    >
                      <MenuItemWithIcon icon={<EditIcon />}>
                        {t("Edit", { ns: "Global" })}
                      </MenuItemWithIcon>
                    </ModalOpenButton>
                  </MenuButton>
                ) : undefined
              }
              onClick={() => handleOpenContent(article.id)}
            />
          ))
        ) : (
          <Typography variant="caption" align="center" color="gray">
            {t("There is no content for this item.", {
              ns: "Contents",
            })}
          </Typography>
        )}

        <Stack justifyContent="center" direction="row" spacing={1}>
          {content.length > 0 ? (
            <Button color="secondary" onClick={() => handleOpenContent()}>
              {t("See all content", {
                ns: "Contents",
              })}
            </Button>
          ) : (
            <ModalOpenButton
              Modal={ContentEditModal}
              modalProps={{
                title: t("Create new content", {
                  ns: "Contents",
                }),
                confirmButtonLabel: t("Add", {
                  ns: "Global",
                }),
                handleComplete: addArticle,
              }}
            >
              <Button startIcon={<AddIcon />} color="secondary">
                {t("Add content", {
                  ns: "Contents",
                })}
              </Button>
            </ModalOpenButton>
          )}
        </Stack>
      </Stack>
      {isContentOpen && (
        <ContentsModal
          handleClose={handleCloseContent}
          currentArticleId={currentArticleId}
          itemId={itemId}
          content={content}
          refetchQueries={refetchQueries}
          canEdit={isEditable ?? true}
        />
      )}
    </CardContainer>
  );
};
