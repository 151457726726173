import { Capabilities } from "@msys/common";
import { useDebouncedValue, useScreenWidth } from "@msys/ui";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Location, NavLink, useLocation } from "react-router-dom";
import { PermissionName } from "../../clients/graphqlTypes";
import { color, size, theme } from "../../common/MuiThemeProvider";
import { Language } from "../../common/translations/languages";
import { useLanguages } from "../../common/translations/useLanguages";
import { ReactComponent as MSLogoLong } from "../assets/icons/logo-long.svg";
import { RestrictedByCapabilityWithDebug } from "../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermissionWithDebug } from "../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../auth/useUserData";
import { browserHasOnlyTouch } from "../featureDetection";
import { useBranding } from "../features/branding/context";
import { useNativeFeature } from "../global/NativeFeatureProvider";
import { useScreenFeature } from "../global/ScreenFeatureProvider";
import {
  SidebarGroup,
  useSidebarContext,
  type SidebarItem as ISidebarItem,
} from "../global/SidebarProvider";
import { CSSObject } from "../styles";
import { matchPathPredicate } from "../utils";
import { LanguageSelector } from "./LanguageSelector";
import { SidebarItem } from "./SidebarItem";
import { PageLeftSidebar } from "./layout/PageLeftSidebar";
import { PageLogo } from "./layout/PageLogo";
import { Stack } from "./layout/Stack";

const HOME_URL = "/";

export interface IMainMenuItem {
  name: string;
  label: string;
  icon: React.ReactNode;
  path?: string;
  isActive?: (match: any, location: Location) => boolean;
  bubbleAmount?: number;
  capability?: Capabilities;
  permission?: PermissionName;
}

interface Props {
  primaryItems: IMainMenuItem[];
  secondaryItems: IMainMenuItem[];
}

export function MainMenu({ primaryItems, secondaryItems }: Props) {
  const isMaxSm = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm),
    { noSsr: true }
  );
  const location = useLocation();
  const {
    isSidebarPinned,
    toggleSidebarPinned,
    isSidebarOpened,
    setIsSidebarOpened,
    sidebarGroups,
  } = useSidebarContext();

  const { currentUser: viewer } = useUserData();
  const { currentLanguage, selectLanguage, languages } = useLanguages();

  const MainMenuItems = React.useMemo(
    () => [
      <List key="main-routes" disablePadding>
        {primaryItems.map(item => {
          let menuItem = (
            <SidebarItem
              key={item.name}
              linkTo={item.path}
              icon={item.icon}
              badgeContent={item.bubbleAmount}
              label={item.label}
              isActive={item.isActive}
              handleClick={() => setIsSidebarOpened(false)}
            />
          );

          if (item.permission) {
            menuItem = (
              <RestrictedByOrganisationPermissionWithDebug
                permission={item.permission}
                key={item.name}
              >
                {menuItem}
              </RestrictedByOrganisationPermissionWithDebug>
            );
          }

          if (item.capability) {
            menuItem = (
              <RestrictedByCapabilityWithDebug
                capability={item.capability}
                key={item.name}
              >
                {menuItem}
              </RestrictedByCapabilityWithDebug>
            );
          }

          return menuItem;
        })}
      </List>,
      <List key="org-and-user" disablePadding>
        {secondaryItems.map(item => {
          let menuItem = (
            <SidebarItem
              key={item.name}
              linkTo={item.path}
              icon={item.icon}
              badgeContent={item.bubbleAmount}
              label={item.label}
              isActive={item.isActive}
              handleClick={() => setIsSidebarOpened(false)}
            />
          );

          if (item.permission) {
            menuItem = (
              <RestrictedByOrganisationPermissionWithDebug
                permission={item.permission}
                key={item.name}
              >
                {menuItem}
              </RestrictedByOrganisationPermissionWithDebug>
            );
          }

          if (item.capability) {
            menuItem = (
              <RestrictedByCapabilityWithDebug
                capability={item.capability}
                key={item.name}
              >
                {menuItem}
              </RestrictedByCapabilityWithDebug>
            );
          }

          return menuItem;
        })}
      </List>,
    ],
    [primaryItems, secondaryItems, setIsSidebarOpened]
  );

  if (!viewer) return null;

  const activeItem = sidebarGroups
    .map(group => group.items)
    .flat(1)
    .find(item =>
      item.activePaths
        ? item.activePaths.some(path =>
            matchPathPredicate(`${path}/*`, location)
          )
        : matchPathPredicate(`${item.path}/*`, location)
    );

  if (isMaxSm) {
    return (
      <SidebarMobile
        isSidebarOpened={isSidebarOpened}
        setIsSidebarOpened={setIsSidebarOpened}
        hideSidebar={() => setIsSidebarOpened(false)}
        languages={languages}
        currentLanguage={currentLanguage}
        selectLanguage={selectLanguage}
        sidebarGroups={sidebarGroups}
        activeItem={activeItem}
      >
        {MainMenuItems}
      </SidebarMobile>
    );
  }

  return (
    <SidebarDesktop
      isSidebarOpened={isSidebarOpened}
      setIsSidebarOpened={setIsSidebarOpened}
      isSidebarPinned={isSidebarPinned}
      toggleSidebarPinned={toggleSidebarPinned}
      languages={languages}
      currentLanguage={currentLanguage}
      selectLanguage={selectLanguage}
      sidebarGroups={sidebarGroups}
      activeItem={activeItem}
    >
      {MainMenuItems}
    </SidebarDesktop>
  );
}

const sideMenuClosedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("transform", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  transform: "translateX(100%)",
});

const SidebarMobile = ({
  isSidebarOpened,
  setIsSidebarOpened,
  hideSidebar,
  children,
  currentLanguage,
  selectLanguage,
  languages,
  sidebarGroups,
  activeItem,
}: React.PropsWithChildren<{
  isSidebarOpened: boolean;
  setIsSidebarOpened: (isOpen: boolean) => void;
  hideSidebar: () => void;
  languages: Language[];
  currentLanguage: Language;
  selectLanguage: (language: Language) => void;
  sidebarGroups: SidebarGroup[];
  activeItem: ISidebarItem | undefined;
}>) => {
  const { sidebarTheme, topbarTheme, sidebarTopLogo, sidebarBottomLogo } =
    useBranding();
  const { isFixedHeight } = useScreenFeature();
  const { keyboardHeight } = useNativeFeature();
  const [isSideMenuOpened, setIsSideMenuOpened] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (isSidebarOpened) setIsSideMenuOpened(true);
  }, [isSidebarOpened]);
  const height =
    !isFixedHeight && keyboardHeight > 0
      ? `calc(100% - ${keyboardHeight})`
      : undefined;

  return (
    <ThemeProvider theme={sidebarTheme}>
      <Drawer
        variant={"temporary"}
        anchor={"right"}
        open={isSidebarOpened}
        onClose={() => setIsSidebarOpened(false)}
        PaperProps={{
          variant: "elevation",
          elevation: 0,
          sx: {
            width: "100vw",
            maxWidth: "390px",
            height,
            backgroundColor: sidebarTheme.palette.primary.contrastText,
          },
        }}
      >
        <Stack component={"nav"} flex={1} flexDirection={"column"} spacing={0}>
          <ThemeProvider theme={topbarTheme}>
            <AppBar
              position="sticky"
              enableColorOnDark
              sx={{ paddingTop: "env(safe-area-inset-top)" }}
            >
              <Toolbar>
                {sidebarTopLogo ? (
                  <NavLink to={sidebarTopLogo?.url ?? HOME_URL}>
                    <PageLogo
                      height={size.topMenuSize}
                      width={size.topLogoMaxWidth}
                      logo={sidebarTopLogo}
                      noLink
                    />
                  </NavLink>
                ) : (
                  <NavLink to={HOME_URL}>
                    <MSLogoLong
                      style={{
                        height: size.topMenuLogoHeight,
                        color:
                          topbarTheme.palette.mode === "light"
                            ? color.white
                            : color.primary,
                      }}
                    />
                  </NavLink>
                )}
                <IconButton
                  color="inherit"
                  onClick={hideSidebar}
                  size="large"
                  sx={{ marginRight: -1.5 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </ThemeProvider>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            position="relative"
          >
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              sx={{
                overflowX: "hidden",
                // when menu above is opened, we need to set height to 0 to not affect scrolling
                ...(isSideMenuOpened && sidebarGroups.length > 0
                  ? {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      height: 0,
                      overflow: "hidden",
                    }
                  : undefined),
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                flex={1}
                sx={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {children}
              </Box>
              <List disablePadding>
                <ListItem style={{ justifyContent: "space-between" }}>
                  <ListItemIcon style={{ marginLeft: -10 }}>
                    <LanguageSelector
                      languages={languages}
                      currentLanguage={currentLanguage}
                      selectLanguage={selectLanguage}
                    />
                  </ListItemIcon>
                  {sidebarBottomLogo ? (
                    <PageLogo
                      style={{ alignItems: "flex-end" }}
                      height={40}
                      width={132}
                      logo={sidebarBottomLogo}
                      objectPosition="right center"
                    />
                  ) : null}
                </ListItem>
              </List>
            </Box>
            {sidebarGroups.length > 0 && (
              <ThemeProvider theme={theme}>
                <PageLeftSidebarMobile
                  isSideMenuOpened={isSideMenuOpened}
                  setIsSideMenuOpened={setIsSideMenuOpened}
                  sidebarGroups={sidebarGroups}
                  activeItem={activeItem}
                  hideSidebar={hideSidebar}
                />
              </ThemeProvider>
            )}
          </Box>
        </Stack>
      </Drawer>
    </ThemeProvider>
  );
};

function PageLeftSidebarMobile({
  isSideMenuOpened,
  setIsSideMenuOpened,
  sidebarGroups,
  activeItem,
  hideSidebar,
}: {
  isSideMenuOpened: boolean;
  setIsSideMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarGroups: SidebarGroup[];
  activeItem: ISidebarItem | undefined;
  hideSidebar: () => void;
}) {
  const { t } = useTranslate("Global");
  const isSideMenuOpenedDebounced = useDebouncedValue(isSideMenuOpened, 350);
  return (
    <Box
      sx={theme => ({
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        ...(!isSideMenuOpened
          ? {
              ...sideMenuClosedMixin(theme),
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "100%",
            }
          : undefined),
        ...(!isSideMenuOpened && !isSideMenuOpenedDebounced
          ? {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: 0,
              overflow: "hidden",
              pointerEvents: "none",
            }
          : undefined),
      })}
    >
      <Box height="100%">
        <ListItemButton onClick={() => setIsSideMenuOpened(false)}>
          <ListItemIcon
            sx={theme => ({
              minWidth: theme.layout.listItemMinWidth.sm,
            })}
          >
            <ArrowCircleLeftIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography noWrap>{t("Back to main menu")}</Typography>
          </ListItemText>
        </ListItemButton>
        <Divider />
        <PageLeftSidebar
          groups={sidebarGroups}
          activeItem={activeItem}
          onItemClick={hideSidebar}
        />
      </Box>
    </Box>
  );
}

const SidebarDesktop = ({
  isSidebarOpened,
  setIsSidebarOpened,
  isSidebarPinned,
  toggleSidebarPinned,
  children,
  currentLanguage,
  selectLanguage,
  languages,
  sidebarGroups,
  activeItem,
}: React.PropsWithChildren<{
  isSidebarOpened: boolean;
  setIsSidebarOpened: (value: boolean) => void;
  isSidebarPinned: boolean;
  toggleSidebarPinned: () => void;
  currentLanguage: Language;
  selectLanguage: (language: Language) => void;
  languages: Language[];
  sidebarGroups: SidebarGroup[];
  activeItem: ISidebarItem | undefined;
}>) => {
  const { sidebarTheme, topbarTheme, sidebarTopLogo, sidebarBottomLogo } =
    useBranding();
  const { isMinLargeDesktop } = useScreenWidth();
  const isMaxSm = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm),
    { noSsr: true }
  );

  return (
    <>
      <ThemeProvider theme={sidebarTheme}>
        <ClickAwayListener
          onClickAway={() => {
            if (!browserHasOnlyTouch || !isSidebarOpened) return;
            setIsSidebarOpened(false);
          }}
          mouseEvent={false}
        >
          <DesktopDrawer
            variant="permanent"
            anchor="left"
            open={isSidebarPinned || isSidebarOpened}
            sx={{
              ...(isSidebarPinned
                ? {
                    ...openedMixin(sidebarTheme),
                  }
                : {
                    ...closedMixin(sidebarTheme),
                  }),
            }}
            PaperProps={{
              variant: "elevation",
              elevation: 0,
              sx: {
                backgroundColor: sidebarTheme.palette.primary.contrastText,
              },
            }}
            onClose={() => setIsSidebarOpened(false)}
            onMouseEnter={
              browserHasOnlyTouch ? undefined : () => setIsSidebarOpened(true)
            }
            onMouseLeave={
              browserHasOnlyTouch
                ? undefined
                : () => setIsSidebarOpened(isMaxSm)
            }
          >
            <Stack
              component={"nav"}
              flex={1}
              flexDirection={"column"}
              spacing={0}
              width={"100%"}
            >
              <ThemeProvider theme={topbarTheme}>
                <AppBar position="sticky" enableColorOnDark>
                  <Toolbar
                    sx={{ paddingLeft: { sm: 3 }, paddingRight: { sm: 4 } }}
                  >
                    <ListItemIcon
                      style={{ position: "relative", pointerEvents: "none" }}
                      sx={{ marginLeft: -0.5, marginRight: 1.5 }}
                    >
                      <MenuIcon color="inherit" />
                    </ListItemIcon>
                    {sidebarTopLogo ? (
                      <NavLink to={sidebarTopLogo?.url ?? HOME_URL}>
                        <PageLogo
                          height={size.topMenuSize}
                          width={size.topLogoMaxWidth}
                          logo={sidebarTopLogo}
                          noLink
                        />
                      </NavLink>
                    ) : (
                      <NavLink to={HOME_URL}>
                        <MSLogoLong
                          style={{
                            height: size.topMenuLogoHeight,
                            color:
                              topbarTheme.palette.mode === "light"
                                ? color.white
                                : color.primary,
                          }}
                        />
                      </NavLink>
                    )}
                    {browserHasOnlyTouch && (
                      <Box
                        sx={theme => ({
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          width: `${theme.navBar.collapsedWidth}px`,
                        })}
                        onClick={() => {
                          setIsSidebarOpened(!isSidebarOpened);
                        }}
                      />
                    )}
                  </Toolbar>
                </AppBar>
              </ThemeProvider>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                flex={1}
                style={{ overflowY: "auto", overflowX: "hidden" }}
              >
                {children}
              </Box>
              <List disablePadding>
                <ListItem style={{ justifyContent: "space-between" }}>
                  <ListItemIcon style={{ marginLeft: -10 }}>
                    <LanguageSelector
                      languages={languages}
                      currentLanguage={currentLanguage}
                      selectLanguage={selectLanguage}
                    />
                  </ListItemIcon>
                  {sidebarBottomLogo ? (
                    <PageLogo
                      style={{ alignItems: "flex-end", marginLeft: 12 }}
                      height={40}
                      width={132}
                      logo={sidebarBottomLogo}
                      objectPosition="right center"
                    />
                  ) : null}
                </ListItem>
              </List>
              {isMinLargeDesktop && (
                <List disablePadding>
                  <Divider />
                  <ListItem style={{ height: 60 }}>
                    <ListItemIcon style={{ marginLeft: -10 }}>
                      <IconButton
                        onClick={toggleSidebarPinned}
                        color={
                          sidebarTheme.palette.mode === "light"
                            ? "default"
                            : "primary"
                        }
                        size="large"
                      >
                        {isSidebarPinned ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                </List>
              )}
            </Stack>
          </DesktopDrawer>
        </ClickAwayListener>
      </ThemeProvider>
      {sidebarGroups.length > 0 && (
        <Box
          height="100%"
          sx={theme => ({
            overflowY: "auto",
            overflowX: "hidden",
            width: `${theme.navBar.expandedWidth}px`,
            borderRight: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <PageLeftSidebar groups={sidebarGroups} activeItem={activeItem} />
        </Box>
      )}
    </>
  );
};

const DrawerNoScrollbar = styled(Drawer)`
  & > .MuiDrawer-paper {
    // hiding scrollbar
    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
    }
    & {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
  }
` as typeof Drawer;

const DesktopDrawer = styled(DrawerNoScrollbar, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  width: `${theme.navBar.collapsedWidth}px`,
  flexGrow: 0,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& > .MuiDrawer-paper": open ? openedMixin(theme) : closedMixin(theme),
}));

const closedMixin = (theme: Theme): CSSObject => ({
  width: `${theme.navBar.collapsedWidth}px`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const openedMixin = (theme: Theme): CSSObject => ({
  width: `${theme.navBar.expandedWidth}px`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
