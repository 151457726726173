import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { CompileDocIsolatedExpressionResultDiagnosticComputedProp } from "../../../../clients/graphqlTypes";
import {
  computedPropToDefineInput,
  convertToComputedInput,
  convertToNonComputedInput,
  getPropInputEntryKey,
  isComputedProp,
  isNonComputedProp,
  propToDefineInput,
} from "../properties";
import { ExpressionModal, PropertyOption } from "./ExpressionModal";
import {
  usePropertyExpressionModalQuery,
  usePropertyExpressionModal_DefineItemProps2Mutation,
} from "./PropertyExpressionModal.generated";
import { useExpressionValidationError } from "./useExpressionValidation";

interface Props
  extends Omit<
    React.ComponentProps<typeof ExpressionModal>,
    "handleSave" | "handleDelete" | "expressionError"
  > {
  fieldName: string;
  handleComplete?: (
    expression: string | undefined,
    handleClose: () => void
  ) => Promise<void> | void;
}

export function PropertyExpressionModal({
  projectId,
  docId,
  itemId,
  fieldName,
  handleClose,
  handleComplete,
  ...props
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = usePropertyExpressionModalQuery({
    client,
    variables: {
      projectId,
      docId,
      itemId,
    },
  });
  const item = getDataOrNull(query.data?.item);
  const props2 = item?.props2 ?? [];

  const relevantErrorPredicate = React.useCallback(
    (
      result: Parameters<Parameters<typeof useExpressionValidationError>[2]>[0]
    ) => "key" in result && result.key === fieldName,
    [fieldName]
  );

  const { expressionError, validateExpressions } = useExpressionValidationError(
    docId,
    itemId,
    relevantErrorPredicate
  );

  const filterOptions = React.useCallback(
    (option: PropertyOption) =>
      option.itemId !== itemId || option.key !== fieldName,
    [fieldName, itemId]
  );

  const [defineItemProps2] =
    usePropertyExpressionModal_DefineItemProps2Mutation({
      client,
    });

  const handleSave = async (expression: string) => {
    const result = await validateExpressions({
      docId,
      itemId,
      overrides: [
        { itemId, computedProp: { key: fieldName, expr: expression } },
      ],
    });

    const expressionCheckResult =
      result.data?.compileDocIsolatedExpression.results.find(
        relevantErrorPredicate
      );

    if (expressionCheckResult) {
      console.error("compile document error", expressionCheckResult);
      enqueueSnackbar(
        `Expression is invalid: ${expressionCheckResult.messageText}`,
        { variant: "error" }
      );
      return;
    }

    const currentEntryIndex = props2.findIndex(prop => prop.key === fieldName);
    if (currentEntryIndex === undefined)
      throw new Error("Property entry not found");

    const currentEntry = props2[currentEntryIndex];
    const newEntryInput = isComputedProp(currentEntry)
      ? computedPropToDefineInput(currentEntry, expression)
      : convertToComputedInput(currentEntry, expression);

    const inputEntries = props2.map(propToDefineInput);
    inputEntries.splice(currentEntryIndex, 1, newEntryInput);

    await defineItemProps2({
      variables: {
        input: {
          projectId,
          docId,
          itemId,
          entries: uniqBy(inputEntries, getPropInputEntryKey),
        },
      },
    });

    if (handleComplete) {
      handleComplete(expression, handleClose);
    } else {
      handleClose();
    }
  };

  const handleDelete = async () => {
    const currentEntryIndex = props2.findIndex(prop => prop.key === fieldName);
    if (currentEntryIndex === undefined)
      throw new Error("Property entry not found");

    const currentEntry = props2[currentEntryIndex];
    if (isNonComputedProp(currentEntry))
      throw new Error("Property is non-computed");

    const newEntryInput = convertToNonComputedInput(currentEntry);

    const inputEntries = props2.map(propToDefineInput);
    inputEntries.splice(currentEntryIndex, 1, newEntryInput);

    await defineItemProps2({
      variables: {
        input: {
          projectId,
          docId,
          itemId,
          entries: uniqBy(inputEntries, getPropInputEntryKey),
        },
      },
    });

    if (handleComplete) {
      handleComplete(undefined, handleClose);
    } else {
      handleClose();
    }
  };

  return (
    <ExpressionModal
      {...props}
      projectId={projectId}
      docId={docId}
      itemId={itemId}
      expressionError={expressionError}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
      filterOptions={filterOptions}
    />
  );
}
