import { ellipsisStyle, FormattedPrice, useFormatting } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  ActionLine,
  ClientLine,
  DeadlineDateLine,
} from "../../commons/DataItem";
import { QuotePreviewListItemFragment } from "./QuotePreviewListItem.generated";
import { QuoteStatusBadge } from "./QuoteStatusBadge";

interface Props {
  quote: QuotePreviewListItemFragment;
  Action?: React.ReactElement;
}

export const QuotePreviewListItem = ({ quote, Action }: Props) => {
  const { getFormattedDate } = useFormatting();
  const { t } = useTranslate("DataItem");

  const client = quote.contractee?.companyName;

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{quote.title}</span>
          <span
            style={{
              display: "inline-flex",
              marginLeft: "8px",
              verticalAlign: "middle",
            }}
          >
            <QuoteStatusBadge quote={quote} small />
          </span>
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
        style={ellipsisStyle}
      >
        <Box
          gap={0.5}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          {quote.expirationDate && (
            <Tooltip title={t("Valid until")} arrow placement="top">
              <div>
                <DeadlineDateLine>
                  {getFormattedDate(quote.expirationDate)}
                </DeadlineDateLine>
              </div>
            </Tooltip>
          )}
          {client && (
            <Tooltip title={t("Client")} arrow placement="bottom">
              <div style={ellipsisStyle}>
                <ClientLine>{client}</ClientLine>
              </div>
            </Tooltip>
          )}
        </Box>

        <Typography
          variant={"body2"}
          style={{
            float: "right",
            alignItems: "flex-end",
            display: "flex",
            shapeOutside: "inset(calc(100% - 20px) 0 0)",
            marginBottom: "-4px",
          }}
          component="div"
        >
          <FormattedPrice value={quote.proposedCalculation?.priceTotal ?? 0} />
        </Typography>
      </Stack>
    </Stack>
  );
};
