import {
  PopoverOpenButtonRef,
  PopperPopoverOpenButton,
  useScreenWidth,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Fab,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../../auth/useUserData";
import { ALLOW_INVITE_NEW_CONTRACTEE } from "../../../constants";
import { CreateInvoiceButton } from "../../invoices/buttons/CreateInvoiceButton";
import { PurchaseOrderCreateButton } from "../../purchase-orders/buttons/PurchaseOrderCreateButton";
import { CreateQuoteButton } from "../../quotes/buttons/CreateQuoteButton";
import { RequestQuoteForOutsourcingButton } from "../../requests/buttons/RequestQuoteForOutsourcingButton";
import { RequestQuoteForRequirementButton } from "../../requests/buttons/RequestQuoteForRequirementButton";
import { CreateRequirementButton } from "../../requirements/buttons/CreateRequirementButton";
import { CreateTodoItemButton } from "../../todos/CreateTodoItemButton";
import { useProjectAddEntity } from "../useProjectAddEntity";
import { AddProjectContactRelationshipButton } from "./AddProjectContactRelationshipButton";
import { AddProjectContentButton } from "./AddProjectContentButton";
import { AddProjectFilesButton } from "./AddProjectFilesButton";
import { AddProjectMemberButton } from "./AddProjectMemberButton";
import { AddProjectMemberCRMButton } from "./AddProjectMemberCRMButton";
import { ProjectAddEntityButton_ProjectFragment } from "./ProjectAddEntityButton.generated";

const iconStyle = { width: "48px", height: "48px" };

interface Props {
  outgoingQuotesCount: number;
  outgoingRequirementsWithoutWonRequestCount: number;
  project: ProjectAddEntityButton_ProjectFragment;
  refetchQueries: string[];
}

export const ProjectAddEntityButton = ({
  outgoingQuotesCount,
  outgoingRequirementsWithoutWonRequestCount,
  project,
  refetchQueries,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslate([
    "ProjectOverview",
    "Global",
    "CrmUsers",
    "Contents",
    "FilesBoxTable",
  ]);
  const { isMinTablet } = useScreenWidth();
  const viewer = useUserData().currentUser!;

  const popoverOpenButtonRef = React.useRef<PopoverOpenButtonRef>();

  const showContractee =
    viewer.organisation.capabilities.includes("QUOTING") &&
    project.crmOrganisation;
  const canInviteContractee = project.canInviteContractee;

  const hasRequirementsForRequest =
    outgoingRequirementsWithoutWonRequestCount > 0;
  const hasQuotesForRequest = outgoingQuotesCount > 0;

  const { handleNavigateToPlanning } = useProjectAddEntity({
    projectId: project.id,
  });

  return (
    <>
      <PopperPopoverOpenButton
        keepMounted
        popoverRef={popoverOpenButtonRef}
        placement="top-end"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ]}
        content={
          <Box
            display="flex"
            p={1}
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
            maxWidth={isMinTablet ? 424 : 320}
          >
            {/* requirements */}
            <RestrictedByProjectPermissionWithDebug
              permission="READ_REQUIREMENTS"
              project={project}
            >
              <RestrictedByProjectPermissionWithDebug
                permission="MANAGE_REQUIREMENTS"
                project={project}
              >
                <CreateRequirementButton
                  projectId={project.id}
                  Button={
                    <ButtonWithIcon
                      title={t("Requirement", { ns: "ProjectOverview" })}
                      icon={
                        <img
                          src="/entities/requirement.svg"
                          style={iconStyle}
                        />
                      }
                    />
                  }
                />
              </RestrictedByProjectPermissionWithDebug>
            </RestrictedByProjectPermissionWithDebug>

            {/* quotes */}
            <RestrictedByCapabilityWithDebug capability="QUOTING">
              <RestrictedByProjectPermissionWithDebug
                permission="READ_QUOTES"
                project={project}
              >
                <RestrictedByProjectPermissionWithDebug
                  permission="MANAGE_QUOTES"
                  project={project}
                >
                  {(!(project.state === "opportunity") ||
                    project.incomingQuoteRequests.length === 0 ||
                    project.incomingQuoteRequests.some(
                      request =>
                        request.status === "PUBLISHED" ||
                        request.wonBySystemOrganisationId ===
                          viewer.organisation.id
                    )) && (
                    <CreateQuoteButton
                      projectId={project.id}
                      pathToProject={`/projects/${project.id}`}
                      Button={
                        <ButtonWithIcon
                          title={t("Quote", { ns: "ProjectOverview" })}
                          icon={
                            <img src="/entities/quote.svg" style={iconStyle} />
                          }
                        />
                      }
                    />
                  )}
                </RestrictedByProjectPermissionWithDebug>
              </RestrictedByProjectPermissionWithDebug>
            </RestrictedByCapabilityWithDebug>

            {/* order */}
            <RestrictedByCapabilityWithDebug capability="ORDERING">
              <RestrictedByProjectPermissionWithDebug
                permission="MANAGE_QUOTES"
                project={project}
              >
                <PurchaseOrderCreateButton
                  projectId={project.id}
                  Button={
                    <ButtonWithIcon
                      title={t("Order", { ns: "ProjectOverview" })}
                      icon={<img src="/entities/order.svg" style={iconStyle} />}
                    />
                  }
                  handleOrderCreate={orderId => {
                    navigate(
                      `/projects/${project.id}/materials/purchase-orders/${orderId}/edit`
                    );
                  }}
                />
              </RestrictedByProjectPermissionWithDebug>
            </RestrictedByCapabilityWithDebug>

            {/* invoices */}
            <RestrictedByCapabilityWithDebug capability="INVOICING">
              <RestrictedByProjectPermissionWithDebug
                permission="READ_QUOTES"
                project={project}
              >
                <RestrictedByProjectPermissionWithDebug
                  permission="MANAGE_QUOTES"
                  project={project}
                >
                  <CreateInvoiceButton
                    projectId={project.id}
                    Button={
                      <ButtonWithIcon
                        title={t("Invoice", { ns: "ProjectOverview" })}
                        icon={
                          <img src="/entities/invoice.svg" style={iconStyle} />
                        }
                      />
                    }
                  />
                </RestrictedByProjectPermissionWithDebug>
              </RestrictedByProjectPermissionWithDebug>
            </RestrictedByCapabilityWithDebug>

            {/* my team */}
            <RestrictedByProjectPermissionWithDebug
              permission="READ_PROJECT_MEMBERS"
              project={project}
            >
              <RestrictedByProjectPermissionWithDebug
                permission="ADD_PROJECT_MEMBER"
                project={project}
              >
                <AddProjectMemberButton
                  projectId={project.id}
                  Button={
                    <ButtonWithIcon
                      title={t("My team", { ns: "ProjectOverview" })}
                      icon={
                        <img src="/entities/client-add.svg" style={iconStyle} />
                      }
                    />
                  }
                  refetchQueries={refetchQueries}
                />
              </RestrictedByProjectPermissionWithDebug>
            </RestrictedByProjectPermissionWithDebug>

            {/* client */}
            {ALLOW_INVITE_NEW_CONTRACTEE &&
              showContractee &&
              canInviteContractee && (
                <RestrictedByProjectPermissionWithDebug
                  permission="READ_PROJECT_MEMBERS"
                  project={project}
                >
                  <RestrictedByProjectPermissionWithDebug
                    permission="MANAGE_PROJECT"
                    project={project}
                  >
                    <AddProjectMemberCRMButton
                      projectId={project.id}
                      Button={
                        <ButtonWithIcon
                          title={t("Client", { ns: "ProjectOverview" })}
                          icon={
                            <img
                              src="/entities/client-add.svg"
                              style={iconStyle}
                            />
                          }
                        />
                      }
                      refetchQueries={refetchQueries}
                    />
                  </RestrictedByProjectPermissionWithDebug>
                </RestrictedByProjectPermissionWithDebug>
              )}

            {/* contact */}
            <AddProjectContactRelationshipButton
              project={project}
              Button={
                <ButtonWithIcon
                  title={t("Contact", { ns: "ProjectOverview" })}
                  icon={<img src="/entities/contact.svg" style={iconStyle} />}
                />
              }
              handleComplete={() => {
                popoverOpenButtonRef.current?.handleClose();
              }}
            />

            {/* request */}
            {viewer.organisation.capabilities.includes("QUOTING")
              ? hasQuotesForRequest && (
                  <RestrictedByProjectPermissionWithDebug
                    permission="DECIDE_QUOTES"
                    project={project}
                  >
                    <RequestQuoteForOutsourcingButton
                      projectId={project.id}
                      Button={
                        <ButtonWithIcon
                          title={t("Subcontract", { ns: "ProjectOverview" })}
                          icon={
                            <img
                              src="/entities/subcontract.svg"
                              style={iconStyle}
                            />
                          }
                        />
                      }
                    />
                  </RestrictedByProjectPermissionWithDebug>
                )
              : hasRequirementsForRequest && (
                  <RestrictedByProjectPermissionWithDebug
                    permission="DECIDE_QUOTES"
                    project={project}
                  >
                    <RequestQuoteForRequirementButton
                      projectId={project.id}
                      Button={
                        <ButtonWithIcon
                          title={t("Contract", { ns: "ProjectOverview" })}
                          icon={
                            <img
                              src="/entities/subcontract.svg"
                              style={iconStyle}
                            />
                          }
                        />
                      }
                    />
                  </RestrictedByProjectPermissionWithDebug>
                )}

            {/* planning */}
            <RestrictedByCapabilityWithDebug capability="PLANNING">
              <RestrictedByProjectPermissionWithDebug
                permission="MANAGE_PROJECT"
                project={project}
              >
                {viewer.organisation.id ===
                  project.owningSystemOrganisationId && (
                  <ButtonWithIcon
                    title={t("Planning", { ns: "ProjectOverview" })}
                    icon={
                      <img src="/entities/planning.svg" style={iconStyle} />
                    }
                    onClick={handleNavigateToPlanning}
                  />
                )}
              </RestrictedByProjectPermissionWithDebug>
            </RestrictedByCapabilityWithDebug>

            {/* to-do */}
            <CreateTodoItemButton
              linkedProjectId={project.id}
              Button={
                <ButtonWithIcon
                  title={t("To-do", { ns: "ProjectOverview" })}
                  icon={<img src="/entities/todo.svg" style={iconStyle} />}
                />
              }
              refetchQueries={refetchQueries}
            />

            {/* files */}
            <AddProjectFilesButton
              projectId={project.id}
              Button={
                <ButtonWithIcon
                  title={t("Files", { ns: "ProjectOverview" })}
                  icon={
                    <img src="/entities/attachment.svg" style={iconStyle} />
                  }
                />
              }
              handleClick={() => {
                popoverOpenButtonRef.current?.handleClose();
              }}
            />

            {/* content */}
            <AddProjectContentButton
              project={project}
              Button={
                <ButtonWithIcon
                  title={t("Content", { ns: "ProjectOverview" })}
                  icon={<img src="/entities/info.svg" style={iconStyle} />}
                />
              }
            />
          </Box>
        }
      >
        <Fab size={isMinTablet ? "large" : "medium"} color="primary">
          <AddIcon />
        </Fab>
      </PopperPopoverOpenButton>
    </>
  );
};

function ButtonWithIcon({
  title,
  icon,
  ...props
}: {
  title: string;
  icon: React.ReactElement;
} & Omit<ButtonBaseProps, "title">) {
  return (
    <ButtonBase
      sx={theme => ({
        transition: "background-color 0.2s ease-out",
        backgroundColor: theme.palette.common.white,
        ["&:hover:not(:has(button:hover)):not(:has(a:hover))"]: {
          backgroundColor: theme.palette.grey[100],
        },
        ["&:active:not(:has(button:active)):not(:has(a:active))"]: {
          backgroundColor: theme.palette.grey[200],
        },
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        width: "96px",
        minHeight: "80px",
      })}
      focusRipple
      {...props}
    >
      <div style={{ display: "flex" }}>{icon}</div>
      <Typography variant="body2" align="center">
        {title}
      </Typography>
    </ButtonBase>
  );
}
