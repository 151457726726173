import { useApolloClient } from "@apollo/client";
import { Button, Checkbox } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { Document } from "../../auth/RestrictedByDocumentPermission";
import { useUserData } from "../../auth/useUserData";
import { assertNever } from "../../utils";
import {
  useSetTaskItemDoneMutation,
  useSetTaskItemNotDoneMutation,
} from "./Tasks.generated";
import { ToggleTaskCompletionIconButton__TaskDocumentItemFragment } from "./ToggleTaskCompletionIconButton.generated";

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  item: ToggleTaskCompletionIconButton__TaskDocumentItemFragment;
  buttonType: "button" | "icon";
  disabled: boolean;
  document: Document;
  onBeforeTaskDone: (
    itemId: string,
    docId: string,
    projectId: string,
    newIsDone: boolean
  ) => boolean | Promise<boolean>;
  onAfterTaskDone: (
    itemId: string,
    docId: string,
    projectId: string,
    newIsDone: boolean
  ) => void | Promise<void>;
}

export const ToggleTaskCompletionIconButton = ({
  projectId,
  docId,
  itemId,
  buttonType,
  disabled,
  item,
  document,
  onBeforeTaskDone,
  onAfterTaskDone,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const [setTaskItemDoneMutation, { loading: setTaskItemDoneLoading }] =
    useSetTaskItemDoneMutation({ client });

  const [setTaskItemNotDoneMutation, { loading: setTaskItemNotDoneLoading }] =
    useSetTaskItemNotDoneMutation({ client });

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newIsDone = !item?.isDone;
    const isAllowed = await onBeforeTaskDone(
      itemId,
      docId,
      projectId,
      newIsDone
    );
    if (isAllowed === false) return;
    try {
      if (newIsDone) {
        await setTaskItemDoneMutation({
          variables: { input: { docId, projectId, itemId } },
        });
      } else {
        await setTaskItemNotDoneMutation({
          variables: { input: { docId, projectId, itemId } },
        });
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
    await onAfterTaskDone(itemId, docId, projectId, newIsDone);
  };

  return (
    <CompleteTaskButton
      type={buttonType}
      loading={setTaskItemDoneLoading || setTaskItemNotDoneLoading}
      disabled={disabled || !!item?.deletedAt}
      isDone={item?.isDone ?? false}
      onClick={onClick}
    />
  );
};

const CompleteTaskButton: React.FC<{
  type: "button" | "icon";
  loading: boolean;
  disabled: boolean;
  isDone: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ type, loading, disabled, isDone, onClick }) => {
  const { t } = useTranslate("TaskPriority");

  switch (type) {
    case "button": {
      return (
        <Button
          type="button"
          color="secondary"
          variant="outlined"
          size="medium"
          disabled={disabled || loading}
          onClick={onClick}
        >
          {isDone ? t("Uncheck") : t("Check")}
        </Button>
      );
    }
    case "icon": {
      return (
        <Checkbox
          checked={isDone}
          disabled={disabled || loading}
          onClick={onClick}
        />
      );
    }
    default:
      throw assertNever(type);
  }
};
