import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { useHeaderBox_ModifyItemInfoMutation } from "../../doc-items/boxes/HeaderBox.generated";
import {
  useModifyPublishingInfoMutation,
  useQuotePublishingInfoModalQuery,
} from "./QuotePublishingInfoModal.generated";

interface FormValues {
  title: string;
  isBinding: "yes" | "no";
  expirationDate?: Moment | null;
}

interface Props {
  id?: string;
  projectId: string;
  quoteId: string;
  handleClose: () => void;
  handleComplete?: (handleClose: () => void) => void | Promise<void>;
  onUpdateDataRefetchQueries?: string[];
}

export const QuotePublishingInfoModal = ({
  id,
  projectId,
  quoteId,
  handleClose,
  handleComplete,
  onUpdateDataRefetchQueries,
}: Props) => {
  const { t } = useTranslate(["QuoteEditFooter", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const query = useQuotePublishingInfoModalQuery({
    client,
    variables: {
      quoteId,
      projectId,
    },
    fetchPolicy: "network-only",
  });

  const quote = getDataOrNull(query?.data?.quote)?.quote;
  const rootItem = quote?.rootItem;

  const [modifyItemInfo] = useHeaderBox_ModifyItemInfoMutation({
    client,
    refetchQueries: onUpdateDataRefetchQueries,
    awaitRefetchQueries: true,
  });
  const [modifyPublishingInfo] = useModifyPublishingInfoMutation({
    client,
  });

  const handleSubmit = async (values: FormValues) => {
    if (!rootItem) return;
    try {
      await modifyPublishingInfo({
        variables: {
          input: {
            docId: quoteId,
            projectId,
            values: {
              isBinding: values.isBinding === "yes",
              expirationDate: values.expirationDate?.format("YYYY-MM-DD"),
            },
          },
        },
      });
      await modifyItemInfo({
        variables: {
          input: {
            itemId: rootItem.id,
            docId: quoteId,
            projectId,
            values: { title: values.title },
          },
        },
      });
      if (handleComplete) {
        await handleComplete(handleClose);
      } else {
        handleClose();
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(t("Quote name", { ns: "QuoteEditFooter" }))
      .required(),
    isBinding: Yup.string()
      .label(t("Quote state", { ns: "QuoteEditFooter" }))
      .oneOf(["yes", "no"])
      .required(),
    expirationDate: Yup.date()
      .label(t("Valid Until", { ns: "QuoteEditFooter" }))
      .nullable()
      .defined(),
  });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: quote?.title ?? "",
      isBinding: quote?.isBinding ?? false ? "yes" : "no",
      expirationDate: quote?.expirationDate
        ? moment(quote.expirationDate)
        : null,
    }),
    [quote]
  );

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      // validateOnMount
    >
      {formikProps => (
        <Modal
          id={id}
          title={t("Edit quote details", { ns: "QuoteEditFooter" })}
          maxWidth="xs"
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: !formikProps.dirty || !formikProps.isValid,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={query.loading}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <Field
                component={TextField}
                name="title"
                label={t("Quote name", { ns: "QuoteEditFooter" })}
              />

              <Stack direction="row" spacing={1}>
                <DatePickerField
                  name="expirationDate"
                  label={t("Valid Until", {
                    ns: "QuoteEditFooter",
                  })}
                  disablePast
                  disabled={formikProps.isSubmitting}
                />
                <SelectField
                  name="isBinding"
                  label={t("Quote state", {
                    ns: "QuoteEditFooter",
                  })}
                  options={[
                    {
                      label: t("binding", { ns: "QuoteEditFooter" }),
                      value: "yes",
                    },
                    {
                      label: t("not binding", { ns: "QuoteEditFooter" }),
                      value: "no",
                    },
                  ]}
                />
              </Stack>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
