/* eslint-disable */
import * as Types from '../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNewOrganisationAndUserMutationVariables = Types.Exact<{
  input: Types.CreateOrganisationAndUserInput;
}>;


export type CreateNewOrganisationAndUserMutation = { createOrganisationAndUser: { __typename: 'CreateOrganisationAndUserResult', user: { __typename: 'Viewer', id: string }, organisation: { __typename: 'ViewerOrganisation', id: string } } };


export const CreateNewOrganisationAndUserDocument = gql`
    mutation CreateNewOrganisationAndUser($input: CreateOrganisationAndUserInput!) {
  createOrganisationAndUser(input: $input) {
    user {
      id
    }
    organisation {
      id
    }
  }
}
    `;
export type CreateNewOrganisationAndUserMutationFn = Apollo.MutationFunction<CreateNewOrganisationAndUserMutation, CreateNewOrganisationAndUserMutationVariables>;

/**
 * __useCreateNewOrganisationAndUserMutation__
 *
 * To run a mutation, you first call `useCreateNewOrganisationAndUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewOrganisationAndUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewOrganisationAndUserMutation, { data, loading, error }] = useCreateNewOrganisationAndUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewOrganisationAndUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewOrganisationAndUserMutation, CreateNewOrganisationAndUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewOrganisationAndUserMutation, CreateNewOrganisationAndUserMutationVariables>(CreateNewOrganisationAndUserDocument, options);
      }
export type CreateNewOrganisationAndUserMutationHookResult = ReturnType<typeof useCreateNewOrganisationAndUserMutation>;
export type CreateNewOrganisationAndUserMutationResult = Apollo.MutationResult<CreateNewOrganisationAndUserMutation>;
export type CreateNewOrganisationAndUserMutationOptions = Apollo.BaseMutationOptions<CreateNewOrganisationAndUserMutation, CreateNewOrganisationAndUserMutationVariables>;