import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  Agenda,
  Day,
  DragAndDrop,
  Inject,
  Month,
  MonthAgenda,
  PopupOpenEventArgs,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  TimelineMonth,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import { sortBy, uniqBy } from "lodash";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { actionBegin } from "../../features/schedule/helpers";
import {
  ScheduleQuery,
  useScheduleModifyPlanSessionMutation,
  useScheduleQuery,
} from "../../features/schedule/Schedule.generated";
import { ModifyPlanSessionInput } from "../../../clients/graphqlTypes";

export const popupOpen =
  (navigate: ReturnType<typeof useNavigate>) => (args?: PopupOpenEventArgs) => {
    if (!args) return;

    args.cancel = true;

    if (args.type === "QuickInfo") {
      // noop
    } else if (args.type === "Editor") {
      const data: any = args.data;

      if (data && data.ProjectId) {
        setTimeout(() => {
          // let ScheduleComponent do its stuff and new push route on next tick
          navigate(`/projects/${data.ProjectId}/overview`);
        }, 0);
      }
    }
  };

function extractProjects(source: ScheduleQuery) {
  return (
    getDataOrNull(source.projectsAsContractor)?.edges.map(e => e.node) ?? []
  );
}

function extractData(source: ReturnType<typeof extractProjects>) {
  return source
    .map(p => {
      return p.planSessions.map(ps => {
        return {
          Id: ps.id,
          Subject: p.title,
          IsAllDay: false,
          ProjectId: p.id,
          WhoId: ps.who.id,

          StartTime: moment(ps.from).local().toDate(),
          EndTime: moment(ps.till).local().toDate(),
        };
      });
    })
    .flat(1);
}

function extractWhoData(source: ScheduleQuery) {
  return sortBy(
    uniqBy(
      source.organisationMemberships
        .map(e => {
          return {
            WhoTitle: `${e.firstname} ${e.familyname}`,
            Id: e.id,
            WhoColor: "#cb6bb2",
          };
        })
        .flat(1),
      "Id"
    ),
    "WhoTitle"
  );
}

export const Schedule = () => {
  const client = useApolloClient();
  const query = useScheduleQuery({
    client,
    fetchPolicy: "cache-and-network",
  });
  const data = query.data;

  const [modifyPlanSession] = useScheduleModifyPlanSessionMutation({
    client,
  });

  if (!data) return null;

  return (
    <ScheduleInternal
      queryRes={data}
      modifyPlanSession={planSession =>
        modifyPlanSession({ variables: { planSession } })
      }
    />
  );
};

const ScheduleInternal = React.memo(
  ({
    queryRes,
    modifyPlanSession,
  }: {
    queryRes: ScheduleQuery;
    modifyPlanSession: (
      planSession: ModifyPlanSessionInput
    ) => Promise<unknown>;
  }) => {
    const navigate = useNavigate();

    const projects = extractProjects(queryRes);
    const data = extractData(projects);
    const whoData = extractWhoData(queryRes);

    return (
      <Page>
        <PageContainer>
          <ScheduleComponent
            popupOpen={popupOpen(navigate)}
            actionBegin={actionBegin(async (data: any) => {
              const { Id, StartTime, EndTime, WhoId, IsTentative } = data;

              const from = moment(StartTime),
                till = moment(EndTime);

              try {
                await modifyPlanSession({
                  id: Id,
                  from: from.toISOString(true),
                  till: till.toISOString(true),
                  whoId: WhoId,
                  isTentative: IsTentative,
                });
              } catch (err) {
                console.error(err);
                alert("Something went wrong; reload page");
              }
            })}
            group={{
              resources: ["Whos"],
            }}
            rowAutoHeight
            height="100%"
            currentView="TimelineMonth"
            width="100%"
            eventSettings={{
              allowDeleting: false,
              dataSource: data,
              fields: {
                id: "Id",
                subject: { name: "Subject" },
                isAllDay: { name: "IsAllDay" },
              },
            }}
          >
            <ResourcesDirective>
              <ResourceDirective
                field="WhoId"
                title="Who"
                name="Whos"
                allowMultiple={true}
                dataSource={whoData}
                textField="WhoTitle"
                idField="Id"
                colorField="WhoColor"
              />
            </ResourcesDirective>

            <ViewsDirective>
              <ViewDirective option="TimelineDay" />
              <ViewDirective
                firstDayOfWeek={1}
                option="TimelineWeek"
                timeScale={{ interval: 60 * 24, enable: true, slotCount: 1 }}
              />
              <ViewDirective option="TimelineMonth" />
              <ViewDirective option="Agenda" />
              <ViewDirective option="MonthAgenda" firstDayOfWeek={1} />
            </ViewsDirective>

            <Inject
              services={[
                Day,
                Week,
                WorkWeek,
                Month,
                Agenda,
                MonthAgenda,
                TimelineViews,
                TimelineMonth,
                DragAndDrop,
                Resize,
              ]}
            />
          </ScheduleComponent>
        </PageContainer>
      </Page>
    );
  },
  () => {
    // TODO for some reason the syncfusion schedule component is not playing nice with re-rendering the <ScheduleComponent>, for now, make it behave are totally memoized
    return true;
  }
);
