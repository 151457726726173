/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type UserRolesBox_UserFragment = { __typename: 'User', id: string, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> };

export type UserRolesBox_ViewerFragment = { __typename: 'Viewer', id: string, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, internalName: string }> };

export const UserRolesBox_UserFragmentDoc = gql`
    fragment UserRolesBox_User on User {
  id
  roles {
    id
    internalName
  }
  defaultProjectRoles {
    id
    internalName
  }
}
    `;
export const UserRolesBox_ViewerFragmentDoc = gql`
    fragment UserRolesBox_Viewer on Viewer {
  id
  roles {
    id
    internalName
  }
  defaultProjectRoles {
    id
    internalName
  }
}
    `;