import React from "react";
import { PhoneType } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

export const phoneTypes: PhoneType[] = ["HOME", "HOTLINE", "MOBILE", "WORK"];

export const defaultPhoneType = {
  main: false,
  number: "",
  type: "MOBILE",
} as const;

export function usePhoneTypes() {
  const { t } = useTranslate("PhonesField");

  const phoneTypeLabels: Record<PhoneType, string> = React.useMemo(
    () => ({
      HOME: t("Home"),
      HOTLINE: t("Hotline"),
      MOBILE: t("Mobile"),
      WORK: t("Work"),
      PRIVATE: "",
    }),
    [t]
  );

  const phoneTypeOptions = React.useMemo(
    () =>
      phoneTypes.map(type => ({
        id: type,
        label: phoneTypeLabels[type],
        value: type,
      })),
    [phoneTypeLabels]
  );

  return { phoneTypeLabels, phoneTypeOptions };
}
