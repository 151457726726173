/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PimProductTypePropertyTypeFragmentDoc } from '../Product.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PickProductTypeModalSearchProductTypes_ProductTypeFragment = { __typename: 'PimProductType', id: string, key: string, label: string, labelSynonyms: Array<string>, typeSystem: Types.PimProductTypeSystemSystemType, propertyTypes: Array<{ __typename: 'PimProductTypePropertyType', id: string, propertyType: { __typename: 'PimPropertyType', id: string, key: string, label: string, kind: Types.PimPropertyTypeKind, typeSystem: Types.PimProductTypeSystemSystemType, unit?: string | null }, propertyTypeValues: Array<{ __typename: 'PimPropertyTypeValue', id: string, key: string, label: string }> }> };

export type PickProductTypeModalSearchProductTypesQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String']['input'];
}>;


export type PickProductTypeModalSearchProductTypesQuery = { pimSearchProductTypes: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchProductTypesResult', totalCount: number, productTypes: Array<{ __typename: 'PimProductType', id: string, key: string, label: string, labelSynonyms: Array<string>, typeSystem: Types.PimProductTypeSystemSystemType, propertyTypes: Array<{ __typename: 'PimProductTypePropertyType', id: string, propertyType: { __typename: 'PimPropertyType', id: string, key: string, label: string, kind: Types.PimPropertyTypeKind, typeSystem: Types.PimProductTypeSystemSystemType, unit?: string | null }, propertyTypeValues: Array<{ __typename: 'PimPropertyTypeValue', id: string, key: string, label: string }> }> }> } };

export const PickProductTypeModalSearchProductTypes_ProductTypeFragmentDoc = gql`
    fragment PickProductTypeModalSearchProductTypes_ProductType on PimProductType {
  id
  key
  label
  propertyTypes {
    ...PimProductTypePropertyType
  }
  labelSynonyms
  typeSystem
}
    ${PimProductTypePropertyTypeFragmentDoc}`;
export const PickProductTypeModalSearchProductTypesDocument = gql`
    query PickProductTypeModalSearchProductTypes($searchTerm: String!) {
  pimSearchProductTypes(limit: 10, searchTerm: $searchTerm) {
    ... on PimSearchProductTypesResult {
      productTypes {
        id
        ...PickProductTypeModalSearchProductTypes_ProductType
      }
      totalCount
    }
  }
}
    ${PickProductTypeModalSearchProductTypes_ProductTypeFragmentDoc}`;

/**
 * __usePickProductTypeModalSearchProductTypesQuery__
 *
 * To run a query within a React component, call `usePickProductTypeModalSearchProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickProductTypeModalSearchProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickProductTypeModalSearchProductTypesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function usePickProductTypeModalSearchProductTypesQuery(baseOptions: Apollo.QueryHookOptions<PickProductTypeModalSearchProductTypesQuery, PickProductTypeModalSearchProductTypesQueryVariables> & ({ variables: PickProductTypeModalSearchProductTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickProductTypeModalSearchProductTypesQuery, PickProductTypeModalSearchProductTypesQueryVariables>(PickProductTypeModalSearchProductTypesDocument, options);
      }
export function usePickProductTypeModalSearchProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickProductTypeModalSearchProductTypesQuery, PickProductTypeModalSearchProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickProductTypeModalSearchProductTypesQuery, PickProductTypeModalSearchProductTypesQueryVariables>(PickProductTypeModalSearchProductTypesDocument, options);
        }
export function usePickProductTypeModalSearchProductTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PickProductTypeModalSearchProductTypesQuery, PickProductTypeModalSearchProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PickProductTypeModalSearchProductTypesQuery, PickProductTypeModalSearchProductTypesQueryVariables>(PickProductTypeModalSearchProductTypesDocument, options);
        }
export type PickProductTypeModalSearchProductTypesQueryHookResult = ReturnType<typeof usePickProductTypeModalSearchProductTypesQuery>;
export type PickProductTypeModalSearchProductTypesLazyQueryHookResult = ReturnType<typeof usePickProductTypeModalSearchProductTypesLazyQuery>;
export type PickProductTypeModalSearchProductTypesSuspenseQueryHookResult = ReturnType<typeof usePickProductTypeModalSearchProductTypesSuspenseQuery>;
export type PickProductTypeModalSearchProductTypesQueryResult = Apollo.QueryResult<PickProductTypeModalSearchProductTypesQuery, PickProductTypeModalSearchProductTypesQueryVariables>;