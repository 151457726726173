/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyRegistrationDetailsBox_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string };

export type CrmCompanyRegistrationDetailsData_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string };

export type CrmCompanyRegistrationDetailsForm_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string };

export type ModifyCrmCompanyRegistrationDetailsMutationVariables = Types.Exact<{
  input: Array<Types.CrmOrganisationModifyInput>;
}>;


export type ModifyCrmCompanyRegistrationDetailsMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string }> } };

export const CrmCompanyRegistrationDetailsData_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyRegistrationDetailsData_CrmCompany on CrmCompany {
  id
  fullLegalName
  taxNumber
  vatNumber
  registrationNumber
  registrationOffice
  managingDirector
}
    `;
export const CrmCompanyRegistrationDetailsForm_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyRegistrationDetailsForm_CrmCompany on CrmCompany {
  id
  fullLegalName
  taxNumber
  vatNumber
  registrationNumber
  registrationOffice
  managingDirector
}
    `;
export const CrmCompanyRegistrationDetailsBox_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyRegistrationDetailsBox_CrmCompany on CrmCompany {
  id
  ...CrmCompanyRegistrationDetailsData_CrmCompany
  ...CrmCompanyRegistrationDetailsForm_CrmCompany
}
    ${CrmCompanyRegistrationDetailsData_CrmCompanyFragmentDoc}
${CrmCompanyRegistrationDetailsForm_CrmCompanyFragmentDoc}`;
export const ModifyCrmCompanyRegistrationDetailsDocument = gql`
    mutation ModifyCrmCompanyRegistrationDetails($input: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $input) {
    crmOrganisations {
      id
      ...CrmCompanyRegistrationDetailsForm_CrmCompany
    }
  }
}
    ${CrmCompanyRegistrationDetailsForm_CrmCompanyFragmentDoc}`;
export type ModifyCrmCompanyRegistrationDetailsMutationFn = Apollo.MutationFunction<ModifyCrmCompanyRegistrationDetailsMutation, ModifyCrmCompanyRegistrationDetailsMutationVariables>;

/**
 * __useModifyCrmCompanyRegistrationDetailsMutation__
 *
 * To run a mutation, you first call `useModifyCrmCompanyRegistrationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmCompanyRegistrationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmCompanyRegistrationDetailsMutation, { data, loading, error }] = useModifyCrmCompanyRegistrationDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyCrmCompanyRegistrationDetailsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmCompanyRegistrationDetailsMutation, ModifyCrmCompanyRegistrationDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmCompanyRegistrationDetailsMutation, ModifyCrmCompanyRegistrationDetailsMutationVariables>(ModifyCrmCompanyRegistrationDetailsDocument, options);
      }
export type ModifyCrmCompanyRegistrationDetailsMutationHookResult = ReturnType<typeof useModifyCrmCompanyRegistrationDetailsMutation>;
export type ModifyCrmCompanyRegistrationDetailsMutationResult = Apollo.MutationResult<ModifyCrmCompanyRegistrationDetailsMutation>;
export type ModifyCrmCompanyRegistrationDetailsMutationOptions = Apollo.BaseMutationOptions<ModifyCrmCompanyRegistrationDetailsMutation, ModifyCrmCompanyRegistrationDetailsMutationVariables>;