import { useApolloClient } from "@apollo/client";
import { Autocomplete, getFilterOptions } from "@msys/ui";
import { getMemberOptionsWithUserAvatar } from "../users/helpers";
import { UserAvatar } from "../users/UserAvatar";
import { useOrganisationUserSelectQuery } from "./OrganisationUserSelect.generated";

const filterOptionsByLabel = getFilterOptions(["label"]);

interface Props {
  userId: string | null;
  onChange: (userId: string | null) => void;
  inputLabel: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: string;
}

export const OrganisationUserSelect = ({
  userId,
  onChange,
  inputLabel,
  placeholder,
  required,
  disabled,
  readOnly,
  error,
}: Props) => {
  const client = useApolloClient();
  const query = useOrganisationUserSelectQuery({
    client,
    fetchPolicy: "network-only",
  });
  const activeMembers = query?.data?.organisationMemberships ?? [];
  const memberOptions = getMemberOptionsWithUserAvatar(activeMembers);
  const selectedMemberOption = userId
    ? memberOptions.find(m => m.value === userId) ?? null
    : null;

  return (
    <Autocomplete
      inputLabel={inputLabel}
      placeholder={placeholder}
      options={memberOptions}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => (
        <li key={option.key} {...props}>
          <UserAvatar
            size="xs"
            userAvatar={option.userAvatar}
            style={{ marginRight: "8px" }}
          />
          {option.label}
        </li>
      )}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      startAdornment={
        selectedMemberOption ? (
          <UserAvatar
            size="xs"
            userAvatar={selectedMemberOption.userAvatar}
            style={{ marginRight: "2px" }}
          />
        ) : undefined
      }
      value={selectedMemberOption}
      onChange={value => {
        if (value) {
          onChange(value.value);
        } else {
          onChange(null);
        }
      }}
      error={error}
      filterOptions={filterOptionsByLabel}
    />
  );
};
