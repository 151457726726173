import { gql, useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { isUndefined } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { ManualSave } from "../../../commons/form-fields/ManualSave";
import { Stack } from "../../../commons/layout/Stack";
import { QuantityUnit } from "../../../../clients/graphqlTypes";
import { useModifyItemEstimatedCalculationMutation } from "../CalculationMutations.generated";
import { FormattedFloatWithExpressionField } from "../FormattedFloatWithExpressionField";
import { LabeledQuantityValue } from "../LabeledQuantityValue";
import { QuantityUnitField } from "../QuantityUnitField";
import { QuantityBox_ItemFragment } from "./QuantityBox.generated";

type FormValues = {
  estimatedQuantity: number;
  quantityUnit: QuantityUnit;
};

interface Props {
  item: QuantityBox_ItemFragment;
  projectId: string;
  docId: string;
  isEditable?: boolean;
  isManualSave?: boolean;
}

export const QuantityBox = ({
  item,
  docId,
  projectId,
  isEditable = true,
  isManualSave = false,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate("QuoteItem");

  const [canEdit, setCanEdit] = React.useState<boolean>(false);

  const client = useApolloClient();
  const [modifyItemCalculation, { loading: modifyItemCalculationLoading }] =
    useModifyItemEstimatedCalculationMutation({
      client,
    });

  const onSubmit = async (values: FormValues) => {
    try {
      await modifyItemCalculation({
        variables: {
          input: {
            projectId,
            docId,
            itemId: item.id,
            values: {
              estimatedQuantity: values.estimatedQuantity,
              quantityUnit: values.quantityUnit,
            },
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        estimatedQuantity: Yup.number().label(t("Quantity")).min(1).required(),
        quantityUnit: Yup.string().required(),
      }),
    [t]
  );

  const initialValues: FormValues = React.useMemo(
    () => ({
      estimatedQuantity:
        item && !isUndefined(item.pendingChangeAttributes["estimatedQuantity"])
          ? item.pendingChangeAttributes["estimatedQuantity"]
          : item?.estimatedQuantity,
      quantityUnit:
        item && !isUndefined(item.pendingChangeAttributes["quantityUnit"])
          ? item.pendingChangeAttributes["quantityUnit"]
          : item?.quantityUnit,
    }),
    [item]
  );

  const showSelectUnit = item?.type === "paid";
  const disableSelectUnit = item?.type !== "paid";

  return isEditable && (!isManualSave || canEdit) ? (
    <CardContainer isExpandable title={t("Quantity")}>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <Stack flexDirection="column" padding={1}>
            <Stack flexDirection={"row"} spacing={1}>
              <Box flex={1}>
                <FormattedFloatWithExpressionField
                  label={t("Quantity")}
                  name={"estimatedQuantity"}
                  disabled={false}
                  projectId={projectId}
                  docId={docId}
                  itemId={item.id}
                  expression={undefined} // TODO
                />
              </Box>
              {(showSelectUnit || !disableSelectUnit) && (
                <Box flex={1}>
                  {showSelectUnit && (
                    <QuantityUnitField name={"quantityUnit"} disabled={false} />
                  )}
                </Box>
              )}
            </Stack>

            {isManualSave && (
              <ManualSave
                disabled={modifyItemCalculationLoading}
                onCancel={() => {
                  setCanEdit(false);
                }}
                onSubmitted={() => {
                  setCanEdit(false);
                }}
              />
            )}

            <AutoSave
              isAutoSaveDisabled={isManualSave}
              // enableReinitalize
              // initialValues={initialValues}
            />
          </Stack>
        </Form>
      </Formik>
    </CardContainer>
  ) : (
    <CardContainer
      isExpandable
      title={t("Quantity")}
      ActionButton={
        isManualSave ? (
          <IconButton
            color="primary"
            onClick={() => setCanEdit(true)}
            size="small"
          >
            <EditIcon />
          </IconButton>
        ) : undefined
      }
    >
      <Stack flexDirection="column" padding={1}>
        <LabeledQuantityValue
          value={initialValues.estimatedQuantity}
          unit={initialValues.quantityUnit}
          label={t("Quantity")}
        />
      </Stack>
    </CardContainer>
  );
};
