/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectEmailPreviewQueryVariables = Types.Exact<{
  messageId: Types.Scalars['ID']['input'];
}>;


export type ProjectEmailPreviewQuery = { crmEmailMessage: { __typename: 'CrmMailMessage', id: string, createdAt: any, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, body: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> } | { __typename: 'MissingPermissions' } };


export const ProjectEmailPreviewDocument = gql`
    query ProjectEmailPreview($messageId: ID!) {
  crmEmailMessage(messageId: $messageId) {
    ... on CrmMailMessage {
      id
      createdAt
      direction
      from
      to
      subject
      body
      attachments {
        filename
        mimeType
        url
      }
    }
  }
}
    `;

/**
 * __useProjectEmailPreviewQuery__
 *
 * To run a query within a React component, call `useProjectEmailPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectEmailPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectEmailPreviewQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useProjectEmailPreviewQuery(baseOptions: Apollo.QueryHookOptions<ProjectEmailPreviewQuery, ProjectEmailPreviewQueryVariables> & ({ variables: ProjectEmailPreviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectEmailPreviewQuery, ProjectEmailPreviewQueryVariables>(ProjectEmailPreviewDocument, options);
      }
export function useProjectEmailPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectEmailPreviewQuery, ProjectEmailPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectEmailPreviewQuery, ProjectEmailPreviewQueryVariables>(ProjectEmailPreviewDocument, options);
        }
export function useProjectEmailPreviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectEmailPreviewQuery, ProjectEmailPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectEmailPreviewQuery, ProjectEmailPreviewQueryVariables>(ProjectEmailPreviewDocument, options);
        }
export type ProjectEmailPreviewQueryHookResult = ReturnType<typeof useProjectEmailPreviewQuery>;
export type ProjectEmailPreviewLazyQueryHookResult = ReturnType<typeof useProjectEmailPreviewLazyQuery>;
export type ProjectEmailPreviewSuspenseQueryHookResult = ReturnType<typeof useProjectEmailPreviewSuspenseQuery>;
export type ProjectEmailPreviewQueryResult = Apollo.QueryResult<ProjectEmailPreviewQuery, ProjectEmailPreviewQueryVariables>;