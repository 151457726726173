/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc } from './QuoteTemplateSubscribeButton.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnsubscribeFromQuoteTemplateMutationVariables = Types.Exact<{
  input: Types.UnsubscribeFromQuoteTemplateInput;
}>;


export type UnsubscribeFromQuoteTemplateMutation = { unsubscribeFromQuoteTemplate: { __typename: 'UnsubscribeFromQuoteTemplateResult', quoteTemplate: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null } } };

export type QuoteTemplateUnsubscribeButton_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, addedToShop: boolean, usedInOtherTemplates: boolean, subscribedToTemplate?: { __typename: 'TemplateSubscription', subscribedVersionNumber: number, updateAvailable: boolean } | null };

export const QuoteTemplateUnsubscribeButton_QuoteTemplateFragmentDoc = gql`
    fragment QuoteTemplateUnsubscribeButton_QuoteTemplate on QuoteTemplate {
  id
  subscribedToTemplate {
    subscribedVersionNumber
    updateAvailable
  }
  addedToShop
  usedInOtherTemplates
}
    `;
export const UnsubscribeFromQuoteTemplateDocument = gql`
    mutation UnsubscribeFromQuoteTemplate($input: UnsubscribeFromQuoteTemplateInput!) {
  unsubscribeFromQuoteTemplate(input: $input) {
    quoteTemplate {
      id
      resolvedAsReadModelVersionNumber
      ...QuoteTemplateSubscribeButton_QuoteTemplate
    }
  }
}
    ${QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc}`;
export type UnsubscribeFromQuoteTemplateMutationFn = Apollo.MutationFunction<UnsubscribeFromQuoteTemplateMutation, UnsubscribeFromQuoteTemplateMutationVariables>;

/**
 * __useUnsubscribeFromQuoteTemplateMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromQuoteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromQuoteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromQuoteTemplateMutation, { data, loading, error }] = useUnsubscribeFromQuoteTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeFromQuoteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeFromQuoteTemplateMutation, UnsubscribeFromQuoteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeFromQuoteTemplateMutation, UnsubscribeFromQuoteTemplateMutationVariables>(UnsubscribeFromQuoteTemplateDocument, options);
      }
export type UnsubscribeFromQuoteTemplateMutationHookResult = ReturnType<typeof useUnsubscribeFromQuoteTemplateMutation>;
export type UnsubscribeFromQuoteTemplateMutationResult = Apollo.MutationResult<UnsubscribeFromQuoteTemplateMutation>;
export type UnsubscribeFromQuoteTemplateMutationOptions = Apollo.BaseMutationOptions<UnsubscribeFromQuoteTemplateMutation, UnsubscribeFromQuoteTemplateMutationVariables>;