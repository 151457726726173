/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteOfferConditionsModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type QuoteOfferConditionsModalQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, showOfferConditions: boolean, earliestDate?: any | null, duration?: number | null, expirationDate?: any | null } | null } };

export type ModifyQuoteOfferConditionsMutationVariables = Types.Exact<{
  input: Types.QuoteModifyInput;
}>;


export type ModifyQuoteOfferConditionsMutation = { modifyQuote: { __typename: 'ModifyQuoteResult', quote: { __typename: 'Quote', id: string, showOfferConditions: boolean, earliestDate?: any | null, duration?: number | null, expirationDate?: any | null } } };


export const QuoteOfferConditionsModalDocument = gql`
    query QuoteOfferConditionsModal($projectId: ID!, $quoteId: ID!) {
  quote(projectId: $projectId, docId: $quoteId) {
    ... on QuoteResult {
      quote {
        id
        showOfferConditions
        earliestDate
        duration
        expirationDate
      }
    }
  }
}
    `;

/**
 * __useQuoteOfferConditionsModalQuery__
 *
 * To run a query within a React component, call `useQuoteOfferConditionsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteOfferConditionsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteOfferConditionsModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuoteOfferConditionsModalQuery(baseOptions: Apollo.QueryHookOptions<QuoteOfferConditionsModalQuery, QuoteOfferConditionsModalQueryVariables> & ({ variables: QuoteOfferConditionsModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteOfferConditionsModalQuery, QuoteOfferConditionsModalQueryVariables>(QuoteOfferConditionsModalDocument, options);
      }
export function useQuoteOfferConditionsModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteOfferConditionsModalQuery, QuoteOfferConditionsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteOfferConditionsModalQuery, QuoteOfferConditionsModalQueryVariables>(QuoteOfferConditionsModalDocument, options);
        }
export function useQuoteOfferConditionsModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuoteOfferConditionsModalQuery, QuoteOfferConditionsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuoteOfferConditionsModalQuery, QuoteOfferConditionsModalQueryVariables>(QuoteOfferConditionsModalDocument, options);
        }
export type QuoteOfferConditionsModalQueryHookResult = ReturnType<typeof useQuoteOfferConditionsModalQuery>;
export type QuoteOfferConditionsModalLazyQueryHookResult = ReturnType<typeof useQuoteOfferConditionsModalLazyQuery>;
export type QuoteOfferConditionsModalSuspenseQueryHookResult = ReturnType<typeof useQuoteOfferConditionsModalSuspenseQuery>;
export type QuoteOfferConditionsModalQueryResult = Apollo.QueryResult<QuoteOfferConditionsModalQuery, QuoteOfferConditionsModalQueryVariables>;
export const ModifyQuoteOfferConditionsDocument = gql`
    mutation ModifyQuoteOfferConditions($input: QuoteModifyInput!) {
  modifyQuote(input: $input) {
    quote {
      id
      showOfferConditions
      earliestDate
      duration
      expirationDate
    }
  }
}
    `;
export type ModifyQuoteOfferConditionsMutationFn = Apollo.MutationFunction<ModifyQuoteOfferConditionsMutation, ModifyQuoteOfferConditionsMutationVariables>;

/**
 * __useModifyQuoteOfferConditionsMutation__
 *
 * To run a mutation, you first call `useModifyQuoteOfferConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyQuoteOfferConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyQuoteOfferConditionsMutation, { data, loading, error }] = useModifyQuoteOfferConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyQuoteOfferConditionsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyQuoteOfferConditionsMutation, ModifyQuoteOfferConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyQuoteOfferConditionsMutation, ModifyQuoteOfferConditionsMutationVariables>(ModifyQuoteOfferConditionsDocument, options);
      }
export type ModifyQuoteOfferConditionsMutationHookResult = ReturnType<typeof useModifyQuoteOfferConditionsMutation>;
export type ModifyQuoteOfferConditionsMutationResult = Apollo.MutationResult<ModifyQuoteOfferConditionsMutation>;
export type ModifyQuoteOfferConditionsMutationOptions = Apollo.BaseMutationOptions<ModifyQuoteOfferConditionsMutation, ModifyQuoteOfferConditionsMutationVariables>;