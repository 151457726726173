import { VatRateType } from "@msys/vatutils";
import React from "react";
import { useTranslate } from "@tolgee/react";

export function useVatRateTypes() {
  const { t } = useTranslate("Vat");

  const vatRateTypeLabels: Record<VatRateType, string> = React.useMemo(
    () => ({
      standard: t("standard"),
      reduced1: t("reduced1"),
      reduced2: t("reduced2"),
      reduced3: t("reduced3"),
      reduced: t("reduced"),
      zero: t("zero"),
      custom: t("custom"),
    }),
    [t]
  );

  const vatRateTypeOptions: { value: VatRateType; label: string }[] =
    React.useMemo(
      () => [
        { value: "standard", label: vatRateTypeLabels.standard },
        { value: "reduced1", label: vatRateTypeLabels.reduced1 },
        { value: "reduced2", label: vatRateTypeLabels.reduced2 },
        { value: "reduced3", label: vatRateTypeLabels.reduced3 },
        { value: "reduced", label: vatRateTypeLabels.reduced },
        { value: "zero", label: vatRateTypeLabels.zero },
        { value: "custom", label: vatRateTypeLabels.custom },
      ],
      [vatRateTypeLabels]
    );

  return { vatRateTypeLabels, vatRateTypeOptions };
}
