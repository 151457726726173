import { gql } from "@apollo/client";
import moment from "moment";
import React from "react";
import { MomentRange } from "../../features/planning/helpers";
import { UseSelectedDate_ProjectFragment } from "./useSelectedDate.generated";

export function useSelectedDate(
  selectedProject?: UseSelectedDate_ProjectFragment
): MomentRange {
  const [selectedDates, setSelectedDates] = React.useState<MomentRange>([
    undefined,
    undefined,
  ]);

  React.useEffect(() => {
    const earliestDate = selectedProject?.earliestPlanSessionDate;
    const latestDate = selectedProject?.latestPlanSessionDate;
    if (earliestDate && latestDate) {
      const from = moment(earliestDate).startOf("day");
      const till = moment(latestDate).endOf("day");
      if (
        !from.isSame(selectedDates[0], "day") ||
        !till.isSame(selectedDates[1], "day")
      ) {
        setSelectedDates([from, till]);
      }
    } else {
      setSelectedDates([undefined, undefined]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  return selectedDates;
}
