import { assertNever } from "@msys/common";
import { SvgIcon, Typography, TypographyProps } from "@mui/material";
import React from "react";
import { useFormatting } from "../formatting/useFormatting";
import { LabeledValue } from "./LabeledValue";

type ValueType = "integer" | "float" | "price" | "money";

interface Props extends TypographyProps<"div"> {
  label: string;
  labelProps?: Omit<
    TypographyProps<"label">,
    "children" | "variant" | "component"
  >;
  value?: any;
  unit?: string;
  multiplier?: number;
  type: ValueType;
  align?: React.ComponentProps<typeof Typography>["align"];
  icon?: typeof SvgIcon;
  notSetText: string;
}

export const LabeledSpecializedValue = ({
  label,
  value,
  unit = "",
  type,
  multiplier = 1,
  align,
  icon,
  notSetText,
  ...props
}: Props) => {
  const { getFormattedInteger, getFormattedFloat, getFormattedPrice } =
    useFormatting();

  const getFormattedUnit = (value: any, unit: string) => {
    return `${value}${unit || ""}`.trim();
  };

  const getFormattedValue = (
    value: any,
    unit: string,
    multiplier: number,
    type: ValueType
  ) => {
    switch (type) {
      case "integer":
        return getFormattedUnit(getFormattedInteger(value * multiplier), unit);
      case "float":
        return getFormattedUnit(getFormattedFloat(value * multiplier), unit);
      case "price":
        return getFormattedPrice(value);
      case "money":
        return getFormattedPrice(value ? value.amount : 0);
      default:
        assertNever(type);
    }
  };

  const formattedValue = getFormattedValue(value, unit, multiplier, type);

  return (
    <LabeledValue
      label={label}
      align={align}
      icon={icon}
      notSet={!formattedValue}
      {...props}
    >
      {formattedValue || notSetText}
    </LabeledValue>
  );
};
