import WarningIcon from "@mui/icons-material/Warning";
import {
  ButtonBase,
  FormControl,
  InputLabel,
  SvgIcon,
  SvgIconProps,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { StandardTextFieldProps as MuiTextFieldProps } from "@mui/material/TextField/TextField";
import React from "react";

export interface LabeledValueWithButtonProps extends TypographyProps<"div"> {
  label: string;
  labelProps?: Omit<
    TypographyProps<"label">,
    "children" | "variant" | "component"
  >;
  labelIcon?: typeof SvgIcon;
  iconFontSize?: SvgIconProps["fontSize"];
  onClick?: () => void;
  size?: MuiTextFieldProps["size"];
  warning?: string;
  disabled?: boolean;
}

export const LabeledValueWithButton = ({
  children,
  label,
  labelIcon: LabelIcon,
  labelProps,
  style,
  iconFontSize = "medium",
  onClick,
  size = "medium",
  warning,
  disabled,
  ...otherProps
}: React.PropsWithChildren<LabeledValueWithButtonProps>) => {
  return (
    <FormControl
      disabled={disabled}
      {...(!disabled
        ? {
            component: ButtonBase,
            focusRipple: true,
            onClick,
          }
        : undefined)}
      sx={theme => ({
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
      })}
    >
      {size !== "extra-small" && (
        <InputLabel shrink>
          {label}
          {LabelIcon && (
            <LabelIcon
              sx={{
                fontSize: "1.25em",
                verticalAlign: "text-bottom",
                marginLeft: 0.5,
              }}
            />
          )}
          {warning && (
            <Tooltip title={warning} sx={{ pointerEvents: "auto" }}>
              <span>
                <WarningIcon
                  color={"warning"}
                  sx={{
                    fontSize: "1.25rem",
                    verticalAlign: "text-bottom",
                    marginLeft: 0.5,
                  }}
                />
              </span>
            </Tooltip>
          )}
        </InputLabel>
      )}
      <Typography
        component="div"
        variant="body1"
        align="left"
        sx={theme => ({
          // copied over from input
          padding: size === "extra-small" ? "4px 4px 3px 4px" : "21px 12px 4px",
          maxWidth: "100%",
          wordWrap: "break-word",
          alignSelf: "stretch",
          boxSizing: "content-box",
          minHeight: "1.4375em",
          lineHeight: "1.4375em",
          minWidth: 0,
          width:
            size === "extra-small" ? "calc(100% - 8px)" : "calc(100% - 24px)",
          transition: "background-color 0.1s ease-out",
          backgroundColor: !disabled
            ? `rgb(232, 240, 245)`
            : `rgba(0, 0, 0, 0.12)`,
          ...(!disabled
            ? {
                ["&:hover, &:active"]: {
                  backgroundColor: `rgb(242, 246, 250)`,
                },
              }
            : { color: `rgba(0, 0, 0, 0.38)` }),
          borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
        })}
        {...otherProps}
      >
        {children}
      </Typography>
    </FormControl>
  );
};
