import { useApolloClient } from "@apollo/client";
import { CustomFieldObjectType } from "../../../clients/graphqlTypes";
import { useCustomFieldConfigQuery } from "./customFieldConfigs.generated";

export const useCustomFieldConfig = (objectType?: CustomFieldObjectType) => {
  const client = useApolloClient();
  const query = useCustomFieldConfigQuery({
    client,
    variables: { filterObjectType: objectType },
    fetchPolicy: "cache-first",
  });
  return {
    customFieldConfig: query.data?.customFieldConfig ?? [],
    loading: query.loading,
    error: query.error,
  };
};
