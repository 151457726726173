import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { CircularProgress, Icon } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useGlobalLoadingCounter } from "../../../../common/global-loading-state/useGlobalLoadingCounter";
import { download } from "../../../browserUtils";
import { useJsonExport } from "../../jsonImportExport/useJsonExport";

interface Props {
  docIds?: Array<{ docId: string; versionNumber: number | null }>;
  title?: string;
}

export const ExportTemplatesMenuItem = React.forwardRef<HTMLLIElement, Props>(
  ({ title, docIds }, ref) => {
    const { t } = useTranslate("Templates");

    const [exportTemplates, { isLoading }] = useJsonExport();
    const { increment, decrement } = useGlobalLoadingCounter();

    return (
      <MenuItemWithIcon
        ref={ref}
        icon={
          !isLoading ? (
            <Icon>
              <FontAwesomeIcon icon={faFileExport} />
            </Icon>
          ) : (
            <CircularProgress size={"1rem"} />
          )
        }
        onClick={async () => {
          try {
            increment();
            const { downloadUrl, filename } = await exportTemplates(docIds);
            download(downloadUrl, filename);
          } finally {
            decrement();
          }
        }}
        disabled={isLoading}
      >
        {title ?? t("Export templates")}
      </MenuItemWithIcon>
    );
  }
);
