import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  ErrorMessage,
  LoadingSpinner,
  useScreenWidth,
} from "@msys/ui";
import { Box } from "@mui/material";
import React from "react";
import { usePaginationParams } from "../../../commons/pagination/usePaginationParams";
import { PageNotFound } from "../../../commons/PageNotFound";
import { OrganisationQuoteTemplatesSorting } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { ShopItemsList } from "../../shop/components/ShopItemsList";
import { useOrganisationPublicShopBoxQuery } from "./OrganisationPublicShopBox.generated";

const DEFAULT_SORTING: OrganisationQuoteTemplatesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

type Props = {
  slug: string;
};

export const OrganisationPublicShopBox = ({ slug }: Props) => {
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["OrganisationPublicProfile", "PageNotFound"]);

  const { limit, offset, page, perPage, setPerPage, setPage } =
    usePaginationParams({
      pageUrlParam: "shopPage",
      perPageUrlParam: "shopPageSize",
    });

  const client = useApolloClient();
  const query = useOrganisationPublicShopBoxQuery({
    client,
    variables: {
      offset,
      limit,
      sorting: DEFAULT_SORTING,
      slug,
    },
  });
  const organisationProfile = getDataOrNull(
    query.data?.organisationProfile
  )?.profile;

  const [items, totalCount] = React.useMemo(() => {
    if (!organisationProfile) return [[], 0];

    const items = organisationProfile.shoppableTemplates.edges.map(e => e.node);
    const totalCount = organisationProfile.shoppableTemplates.totalCount;

    return [items, totalCount];
  }, [organisationProfile]);

  if (!query.data && query.loading) return <LoadingSpinner />;
  if (query.error) return <ErrorMessage message={query.error.message} />;
  if (!query.data)
    return (
      <ErrorMessage
        message={t("Page Not Found", {
          ns: "PageNotFound",
        })}
      />
    );

  return (
    <CardContainer
      title={t("Shop", {
        ns: "OrganisationPublicProfile",
      })}
    >
      <Box p={isMinTablet ? 2 : 1}>
        <ShopItemsList
          activeView="gallery"
          items={items}
          totalCount={totalCount}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
        />
      </Box>
    </CardContainer>
  );
};
