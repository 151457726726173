import { PopoverOpenButton, SelectMultiple, useSyncStates } from "@msys/ui";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { FilterProps } from "../../../commons/filters";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../../commons/filters/FilterChip";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes";
import { useProjectStates } from "../useProjectStates";

export function FilterProjectStateForm({
  value: externalValue,
  setValue: setExternalValue,
  allowedStates,
}: FilterProps<ProjectStateMachineStatus[]> & {
  allowedStates: ProjectStateMachineStatus[];
}) {
  const { t } = useTranslate(["Global"]);
  const { projectStateOptions } = useProjectStates();
  const options = projectStateOptions.filter(o =>
    allowedStates.includes(o.value)
  );
  const [value, setValue, error] = useSyncStates(
    externalValue,
    setExternalValue,
    value => {
      if (value.length === 0)
        return t("Please select at least one value", { ns: "Global" });
    }
  );
  if (options.length <= 1) return null;
  return (
    <Box
      p={1}
      pr={2}
      sx={theme => ({ width: `${theme.layout.filters.popoverWidth.md}px` })}
    >
      <FormControl
        component="fieldset"
        variant="standard"
        error={Boolean(error)}
      >
        <FormGroup>
          {options.map((option, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  checked={value.includes(option.value)}
                  onChange={(event, checked) => {
                    if (checked) {
                      setValue(Array.from(new Set([...value, option.value])));
                    } else {
                      setValue(value.filter(v => v !== option.value));
                    }
                  }}
                />
              }
              label={<Typography variant="body1">{option.label}</Typography>}
              sx={{ ml: 0, mr: 0 }}
              key={`${option.value}-${index}`}
            />
          ))}
        </FormGroup>
        {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}

export function FilterProjectStateButton({
  allowedStates,
  ...props
}: FilterProps<ProjectStateMachineStatus[]> & {
  allowedStates: ProjectStateMachineStatus[];
}) {
  const { t } = useTranslate("ProjectState");
  const { projectStateOptions } = useProjectStates();
  const [open, setOpen] = React.useState<boolean>(false);
  const options = projectStateOptions.filter(o =>
    allowedStates.includes(o.value)
  );
  if (options.length <= 1) return null;
  return (
    <PopoverOpenButton
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      content={
        <FilterProjectStateForm allowedStates={allowedStates} {...props} />
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Button
        size="extra-small"
        variant="text"
        color="primary"
        sx={{ textTransform: "none" }}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: "transform 0.2s ease-out",
              ...(open ? { transform: `rotate(-180deg)` } : undefined),
            }}
          />
        }
      >
        {t("Status")}
      </Button>
    </PopoverOpenButton>
  );
}

export function FilterProjectState({
  allowedStates,
  value,
  setValue,
  ...props
}: FilterProps<ProjectStateMachineStatus[]> & {
  allowedStates: ProjectStateMachineStatus[];
}) {
  const { t } = useTranslate("ProjectState");
  const { projectStateOptions } = useProjectStates();

  const options = projectStateOptions.filter(o =>
    allowedStates.includes(o.value)
  );
  if (options.length <= 1) return null;

  return (
    <SelectMultiple
      label={t("Project State")}
      options={options}
      value={Array.isArray(value) ? value : [value]}
      onChange={setValue}
      {...props}
    />
  );
}

export function FilterProjectStateChips(
  props: Omit<
    FilterChipGroupProps<ProjectStateMachineStatus>,
    "label" | "getValueLabel"
  > & { allowedStates: ProjectStateMachineStatus[] }
) {
  const { t } = useTranslate("ProjectState");
  const { projectStateLabels } = useProjectStates();
  if (props.values && props.values.length === props.allowedStates.length)
    return null;
  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value => projectStateLabels[value]}
      label={t("Status")}
    />
  );
}
