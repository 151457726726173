import { PurchaseOrderTextReplacementName } from "@msys/textutils";
import { TableCell, TableRow } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { AvailableReplacementsModal } from "../documents/AvailableReplacementsModal";

interface Props {
  handleClose(): void;
}

export function PurchaseOrderTextsAvailableReplacementsModal({
  handleClose,
}: Props) {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const replacements: Record<PurchaseOrderTextReplacementName, string> = {
    "<supplier name>": t("The name of supplier organisation", {
      ns: "OrganisationSettings",
    }),
    "<organisation name>": t("The name of your organisation", {
      ns: "OrganisationSettings",
    }),
    "<your name>": t('Your full name, e.g. "John Doe"', {
      ns: "OrganisationSettings",
    }),
  };

  return (
    <AvailableReplacementsModal handleClose={handleClose}>
      {Object.entries(replacements)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([name, description], index) => (
          <TableRow key={index}>
            <TableCell>{name}</TableCell>
            <TableCell>{description}</TableCell>
          </TableRow>
        ))}
    </AvailableReplacementsModal>
  );
}
