import { assertNever, valuesByKeys } from "@msys/common";
import { differenceWith, isNil } from "lodash";
import {
  Maybe,
  Quote,
  TemplateSearchFiltersInput,
} from "../../../clients/graphqlTypes";
import { ItemTreeItem } from "../../trees/types";
import {
  Props2AllFragment,
  Props2NonComputedAllFragment,
} from "../doc-items/properties.generated";

export function shouldRenderTemplateCreateInput<
  T extends ItemTreeItem & {
    templateQuote?: Maybe<{ __typename?: "Quote" } & Pick<Quote, "id">>;
  },
>(item: T): boolean {
  return !item.templateQuote;
}

export function allowHaveTemplateChildren<
  T extends ItemTreeItem & {
    templateQuote?: Maybe<{ __typename?: "Quote" } & Pick<Quote, "id">>;
  },
>(item: T): boolean {
  return !item.templateQuote;
}

/**
 * templateTypeProps ordering is providing the relevance of props
 * searchTemplatesFilters defines which properties are uninteresting because already specified
 */
export function computeTemplateRelevantProperties({
  rootItemProps,
  templateTypeProps,
  searchTemplatesPropertiesFilters,
}: {
  rootItemProps: Props2AllFragment[];
  templateTypeProps: Props2NonComputedAllFragment[];
  searchTemplatesPropertiesFilters: TemplateSearchFiltersInput["properties"];
}) {
  if (templateTypeProps.length === 0) {
    return [];
  }

  const explicitSearchPropertyFilters = (
    searchTemplatesPropertiesFilters ?? []
  ).filter(p => {
    return (
      (p.boolFilter && p.boolFilter.operatorBool === "eq") ||
      (p.boolInFilter && p.boolInFilter.valueBoolIn.length === 1) ||
      (p.numberFilter && p.numberFilter.operatorNumber === "eq") ||
      (p.numberInFilter && p.numberInFilter.valueNumberIn.length === 1) ||
      (p.textFilter && p.textFilter.operatorText === "eq") ||
      (p.textInFilter && p.textInFilter.valueTextIn.length === 1)
    );
  });

  const relevantRootItemProps = differenceWith(
    rootItemProps,
    explicitSearchPropertyFilters,
    (p, f) => p.key === f.key
  );

  return valuesByKeys(
    relevantRootItemProps,
    templateTypeProps.map(p => p.key),
    p => p.key
  ).flatMap(e => {
    if (!e) {
      return [];
    }

    switch (e.__typename) {
      case "Props2Bool": {
        return !isNil(e.valueBool) ? e : [];
      }
      case "Props2BoolComputed": {
        return !isNil(e.valueBoolComputed) ? e : [];
      }
      case "Props2Number": {
        return !isNil(e.valueNumber) ? e : [];
      }
      case "Props2NumberArray": {
        return !isNil(e.valueNumberArray) ? e : [];
      }
      case "Props2NumberArrayComputed": {
        return !isNil(e.valueNumberArrayComputed) ? e : [];
      }
      case "Props2NumberComputed": {
        return !isNil(e.valueNumberComputed) ? e : [];
      }
      case "Props2Text": {
        return !isNil(e.valueText) ? e : [];
      }
      case "Props2TextArray": {
        return !isNil(e.valueTextArray) ? e : [];
      }
      case "Props2TextArrayComputed": {
        return !isNil(e.valueTextArrayComputed) ? e : [];
      }
      case "Props2TextComputed": {
        return !isNil(e.valueTextComputed) ? e : [];
      }
      default: {
        assertNever(e);
      }
    }
  });
}
