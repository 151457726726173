/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectIncomingInvoiceQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  invoiceId: Types.Scalars['ID']['input'];
}>;


export type ProjectIncomingInvoiceQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string } | null }, incomingInvoice: { __typename: 'Invoice', id: string, title: string, file?: { __typename: 'AttachmentSnapshot', id: string, url: string } | null } | { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } };


export const ProjectIncomingInvoiceDocument = gql`
    query ProjectIncomingInvoice($projectId: ID!, $invoiceId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
      }
    }
  }
  incomingInvoice(projectId: $projectId, invoiceId: $invoiceId) {
    ... on Invoice {
      id
      title
      file {
        id
        url
      }
    }
  }
}
    `;

/**
 * __useProjectIncomingInvoiceQuery__
 *
 * To run a query within a React component, call `useProjectIncomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectIncomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectIncomingInvoiceQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useProjectIncomingInvoiceQuery(baseOptions: Apollo.QueryHookOptions<ProjectIncomingInvoiceQuery, ProjectIncomingInvoiceQueryVariables> & ({ variables: ProjectIncomingInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectIncomingInvoiceQuery, ProjectIncomingInvoiceQueryVariables>(ProjectIncomingInvoiceDocument, options);
      }
export function useProjectIncomingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectIncomingInvoiceQuery, ProjectIncomingInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectIncomingInvoiceQuery, ProjectIncomingInvoiceQueryVariables>(ProjectIncomingInvoiceDocument, options);
        }
export function useProjectIncomingInvoiceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectIncomingInvoiceQuery, ProjectIncomingInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectIncomingInvoiceQuery, ProjectIncomingInvoiceQueryVariables>(ProjectIncomingInvoiceDocument, options);
        }
export type ProjectIncomingInvoiceQueryHookResult = ReturnType<typeof useProjectIncomingInvoiceQuery>;
export type ProjectIncomingInvoiceLazyQueryHookResult = ReturnType<typeof useProjectIncomingInvoiceLazyQuery>;
export type ProjectIncomingInvoiceSuspenseQueryHookResult = ReturnType<typeof useProjectIncomingInvoiceSuspenseQuery>;
export type ProjectIncomingInvoiceQueryResult = Apollo.QueryResult<ProjectIncomingInvoiceQuery, ProjectIncomingInvoiceQueryVariables>;