import { Stack } from "@mui/material";
import {
  templateFiltersDefaultValue,
  useTemplateFilterFields,
} from "./TemplateFilterFields";
import { isArray, mergeWith } from "lodash";

export { templateFiltersDefaultValue };

type Props = Parameters<typeof useTemplateFilterFields>[0];

export const TemplateFilters = ({
  formikProps,
  showOnlyPropertyFiltersWithValues = false,
  ...props
}: Props) => {
  const { nonPropertyFields, propertyFields, isLoading } =
    useTemplateFilterFields({
      ...props,
      formikProps,
      showOnlyPropertyFiltersWithValues,
    });

  return props.viewType === "buttons" ? (
    nonPropertyFields.length > 0 || propertyFields.length > 0 ? (
      <Stack
        direction="row"
        spacing={1}
        flexWrap="wrap"
        alignItems="center"
        minHeight="32px"
        useFlexGap
      >
        {nonPropertyFields}
        {propertyFields}
      </Stack>
    ) : null
  ) : props.viewType === "inputs" ? (
    <>
      {nonPropertyFields}
      {propertyFields}
    </>
  ) : null;
};

function customizer(existing: any, incoming: any) {
  if (isArray(existing)) {
    return incoming;
  }
}

export const mergeObjects = (existing: any, ...incoming: any[]) => {
  return mergeWith({}, existing, ...incoming, customizer);
};
