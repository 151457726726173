import React from "react";
import { useLatest } from "react-use";

export const defaultIsEqual = <S>(a: S, b: S) => a === b;
export const defaultIsEqualArray = <S extends unknown[] | null>(a: S, b: S) => {
  if (a !== null && b !== null) {
    if (a.length !== b.length) return false;
    if (a.some((el, i) => el !== b[i])) return false;
    return true;
  } else if (a === null && b === null) {
    return true;
  }
  return false;
};

export const useSyncState = <S>(
  externalState: S,
  latestSavedState: React.MutableRefObject<S | undefined>,
  isEqual: (a: S, b: S) => boolean = defaultIsEqual
): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [internalState, setInternalState] = React.useState(externalState);

  const internalStateLatest = useLatest(internalState);

  // change from outside
  React.useEffect(() => {
    if (
      (latestSavedState.current === undefined ||
        !isEqual(latestSavedState.current, externalState)) &&
      !isEqual(internalStateLatest.current, externalState)
    ) {
      if (latestSavedState.current !== undefined) {
        latestSavedState.current = undefined;
      }
      setInternalState(externalState);
    }
  }, [externalState, isEqual, internalStateLatest, latestSavedState]);

  return [internalState, setInternalState];
};
