/* Auto-generated by libs/vatutils/scripts/load-vat-rates.ts, please don't edit manually! */

export type VatCountryCode =
  | "AT"
  | "BE"
  | "BG"
  | "HR"
  | "CY"
  | "CZ"
  | "DK"
  | "EE"
  | "FI"
  | "FR"
  | "DE"
  | "GR"
  | "HU"
  | "IE"
  | "IT"
  | "LV"
  | "LT"
  | "LU"
  | "MT"
  | "NL"
  | "PL"
  | "PT"
  | "RO"
  | "SK"
  | "SI"
  | "ES"
  | "SE"
  | "CH"
  | "GB";

export type VatCountryName =
  | "Austria"
  | "Belgium"
  | "Bulgaria"
  | "Croatia"
  | "Cyprus"
  | "Czech Republic"
  | "Denmark"
  | "Estonia"
  | "Finland"
  | "France"
  | "Germany"
  | "Greece"
  | "Hungary"
  | "Ireland"
  | "Italy"
  | "Latvia"
  | "Lithuania"
  | "Luxembourg"
  | "Malta"
  | "Netherlands"
  | "Poland"
  | "Portugal"
  | "Romania"
  | "Slovakia"
  | "Slovenia"
  | "Spain"
  | "Sweden"
  | "Switzerland"
  | "United Kingdom";

export type VatRateType =
  | "standard"
  | "reduced1"
  | "reduced2"
  | "reduced3"
  | "reduced"
  | "zero"
  | "custom";

export type VatRates = {
  standard: number;
  reduced1?: number;
  reduced2?: number;
  reduced3?: number;
  reduced?: number;
  zero?: number;
};

export type VatRatesList = {
  countryCode: VatCountryCode;
  countryName: VatCountryName;
  rates: VatRates;
}[];

export const vatRates: VatRatesList = [
  {
    countryCode: "AT",
    countryName: "Austria",
    rates: { standard: 0.2, reduced1: 0.13, reduced2: 0.1, zero: 0 },
  },
  {
    countryCode: "BE",
    countryName: "Belgium",
    rates: { standard: 0.21, reduced1: 0.12, reduced2: 0.06, zero: 0 },
  },
  {
    countryCode: "BG",
    countryName: "Bulgaria",
    rates: { standard: 0.2, reduced: 0.09, zero: 0 },
  },
  {
    countryCode: "HR",
    countryName: "Croatia",
    rates: { standard: 0.25, reduced1: 0.13, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "CY",
    countryName: "Cyprus",
    rates: { standard: 0.19, reduced1: 0.09, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "CZ",
    countryName: "Czech Republic",
    rates: { standard: 0.21, reduced1: 0.15, reduced2: 0.1, zero: 0 },
  },
  {
    countryCode: "DK",
    countryName: "Denmark",
    rates: { standard: 0.25, zero: 0 },
  },
  {
    countryCode: "EE",
    countryName: "Estonia",
    rates: { standard: 0.2, reduced: 0.09, zero: 0 },
  },
  {
    countryCode: "FI",
    countryName: "Finland",
    rates: { standard: 0.24, reduced1: 0.14, reduced2: 0.1, zero: 0 },
  },
  {
    countryCode: "FR",
    countryName: "France",
    rates: {
      standard: 0.2,
      reduced1: 0.1,
      reduced2: 0.055,
      reduced3: 0.021,
      zero: 0,
    },
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    rates: { standard: 0.19, reduced: 0.07, zero: 0 },
  },
  {
    countryCode: "GR",
    countryName: "Greece",
    rates: { standard: 0.24, reduced1: 0.13, reduced2: 0.06, zero: 0 },
  },
  {
    countryCode: "HU",
    countryName: "Hungary",
    rates: { standard: 0.27, reduced1: 0.18, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "IE",
    countryName: "Ireland",
    rates: {
      standard: 0.23,
      reduced1: 0.135,
      reduced2: 0.09,
      reduced3: 0.048,
      zero: 0,
    },
  },
  {
    countryCode: "IT",
    countryName: "Italy",
    rates: {
      standard: 0.22,
      reduced1: 0.1,
      reduced2: 0.05,
      reduced3: 0.04,
      zero: 0,
    },
  },
  {
    countryCode: "LV",
    countryName: "Latvia",
    rates: { standard: 0.21, reduced1: 0.12, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "LT",
    countryName: "Lithuania",
    rates: { standard: 0.21, reduced1: 0.09, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
    rates: {
      standard: 0.17,
      reduced1: 0.14,
      reduced2: 0.08,
      reduced3: 0.03,
      zero: 0,
    },
  },
  {
    countryCode: "MT",
    countryName: "Malta",
    rates: { standard: 0.18, reduced1: 0.07, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "NL",
    countryName: "Netherlands",
    rates: { standard: 0.21, reduced: 0.09, zero: 0 },
  },
  {
    countryCode: "PL",
    countryName: "Poland",
    rates: { standard: 0.23, reduced1: 0.08, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
    rates: { standard: 0.23, reduced1: 0.13, reduced2: 0.06, zero: 0 },
  },
  {
    countryCode: "RO",
    countryName: "Romania",
    rates: { standard: 0.19, reduced1: 0.09, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "SK",
    countryName: "Slovakia",
    rates: { standard: 0.2, reduced: 0.1, zero: 0 },
  },
  {
    countryCode: "SI",
    countryName: "Slovenia",
    rates: { standard: 0.22, reduced1: 0.095, reduced2: 0.05, zero: 0 },
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    rates: { standard: 0.21, reduced1: 0.1, reduced2: 0.04, zero: 0 },
  },
  {
    countryCode: "SE",
    countryName: "Sweden",
    rates: { standard: 0.25, reduced1: 0.12, reduced2: 0.06, zero: 0 },
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
    rates: {
      standard: 0.077,
      reduced1: 0.037000000000000005,
      reduced2: 0.025,
      zero: 0,
    },
  },
  {
    countryCode: "GB",
    countryName: "United Kingdom",
    rates: { standard: 0.2, reduced1: 0.05, reduced2: 0.05, zero: 0 },
  },
];
