import React from "react";
import { PermissionName } from "../../clients/graphqlTypes";
import { Restricted, RestrictedWithDebug } from "./Restricted";
import { useUserData } from "./useUserData";

interface Props
  extends Omit<React.ComponentProps<typeof Restricted>, "isRestricted"> {
  permission: PermissionName;
}

export const RestrictedByOrganisationPermission = ({
  permission,
  ...props
}: Props) => {
  const { hasOrganisationPermission } = useUserData();

  const isPermitted = hasOrganisationPermission(permission);

  return <Restricted isRestricted={!isPermitted} {...props} />;
};

export const RestrictedByOrganisationPermissionWithDebug = ({
  permission,
  children,
  ...props
}: Props) => {
  const { hasOrganisationPermission } = useUserData();

  const isPermitted = hasOrganisationPermission(permission);

  return (
    <RestrictedWithDebug
      {...props}
      isRestricted={!isPermitted}
      debugLabel={`Permission: ${permission}`}
      debugColor={"#DA70D6"}
    >
      {children}
    </RestrictedWithDebug>
  );
};
