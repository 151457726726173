import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Box, Container } from "@mui/material";
import { pick } from "lodash";
import React from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import {
  Agreement,
  ItemType,
  ModifyItemProductValuesInput,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { useFeature } from "../../../common/FeatureFlags";
import { useUserData } from "../../auth/useUserData";
import { usePageWidth } from "../../commons/hooks/usePageWidth";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { Stack } from "../../commons/layout/Stack";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { REQUIREMENT_ITEM_TYPES } from "../../constants";
import { isAllowedToPaste } from "../../features/doc-items/constraints";
import {
  getViewerDecisionRole,
  isTreePreviewItemVisible,
} from "../../features/doc-items/helpers";
import { useCreateItem } from "../../features/doc-items/hooks/useCreateItem";
import { useDecisionProcess } from "../../features/doc-items/hooks/useDecisionProcess";
import { useDocItemsClipboard } from "../../features/doc-items/hooks/useDocItemsClipboard";
import { DecisionWizardModalWithDefaultActions } from "../../features/doc-items/modals/DecisionWizardModal";
import { DecisionsInBulkProcessRef } from "../../features/doc-items/modals/DecisionsInBulkProcess";
import { ProductSearchItem__ProductSearchResultFragment } from "../../features/products/Product.generated";
import {
  createTreeItem,
  createTreeItemInput,
} from "../../features/projects/requirement-trees";
import { RequirementAction } from "../../features/requirements/RequirementAction";
import { RequirementDecisionsInBulkProcess } from "../../features/requirements/RequirementDecisionsInBulkProcess";
import { RequirementPreview } from "../../features/requirements/RequirementPreview";
import { RequirementSubHeader } from "../../features/requirements/RequirementSubHeader";
import { shouldRenderRequirementCreateInput } from "../../features/requirements/helpers";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../../features/templates/quote/TemplatesQuoteSelectMultipleModal.generated";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree";
import { VirtualBareTreeStandaloneItem } from "../../trees-virtual/components/VirtualBareTreeStandaloneItem";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton";
import { DocShareBox } from "../documents/DocShareBox";
import {
  RequirementEdit_ItemFragment,
  useProjectRequirementEditQuery,
  useProjectRequirementEdit_CreateItemsFromTemplatesMutation,
  useProjectRequirementEdit_CreateItemsMutation,
  useProjectRequirementEdit_PasteItemFromClipboardMutation,
} from "./ProjectRequirementEdit.generated";

interface Props {
  projectId: string;
  requirementId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectRequirementEdit = ({
  projectId,
  requirementId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const pathToProject = `/projects/${projectId}`;
  const pathToDocList = `/projects/${projectId}/requirements`;
  const pathToDoc = `/projects/${projectId}/requirements/${requirementId}`;
  const pathToDocPage = `${pathToDoc}/edit`;

  const match = useMatch(`${pathToDocPage}/items/:itemId`);
  const selectedItemId = match?.params?.itemId ?? null;

  const navigate = useNavigate();
  const viewer = useUserData().currentUser!;

  const { isMinOneColumnWithPreview, isMinTwoColumnsWithPreview } =
    usePageWidth();

  const { isMinTablet } = useScreenWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);
  const [previewContainer, setPreviewContainer] =
    React.useState<HTMLDivElement | null>(null);
  // const [treeItemsCount, setTreeItemsCount] = useState<number>(0);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "requirement",
    requirementId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useProjectRequirementEditQuery({
    client,
    variables: {
      quoteId: requirementId,
      projectId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.requirement)?.requirement;
  const docTitle = doc?.title ?? "";

  const canFinalize =
    viewer.organisation.id === doc?.owningSystemOrganisationId;

  const canEdit = viewer.organisation.id === doc?.owningSystemOrganisationId;

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [navigate, pathToDocPage]
  );

  const viewerIsContractor = true; // project?.contractor?.id === viewer.organisation.id; // TODO-MODEL2022 fix!

  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const viewerPermissions: PermissionName[] = React.useMemo(
    () => doc?.viewerPermissions ?? [],
    [doc?.viewerPermissions]
  );

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const decisionsInBulkProcessRef =
    React.useRef<DecisionsInBulkProcessRef>(null);
  const startDecisionsInBulkProcess = React.useCallback(() => {
    decisionsInBulkProcessRef.current?.startDecisionsInBulkProcess();
  }, []);

  const decisionProcess = useDecisionProcess({
    projectId,
    docId: requirementId,
    embeddedMode: false,
    itemUuidSeed: "",
    viewerDecisionRole,
    decisionContext: "onQuoteRefinement",
  });

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateRequirementItems({
    projectId,
    requirementId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  // TODO: STRIPDOWN - why do we need agreement on the requirement doc?
  const agreement: Agreement = "NONE";

  const TreeItem = React.useMemo(
    () =>
      agreement !== undefined
        ? createTreeItem({
            pathToDocPage,
            requirementId,
            projectId,
            document: { viewerPermissions },
            docAgreement: agreement,
            startConfirmProcess,
            navigateToItem,
            expandedItemIds: expandedStore.expandedItemIds,
            setItemExpanded: expandedStore.setItemExpanded,
            pasteItem,
            isAllowedToPasteItem,
            viewerDecisionRole,
          })
        : () => null,
    [
      pathToDocPage,
      requirementId,
      projectId,
      viewerPermissions,
      startConfirmProcess,
      navigateToItem,
      expandedStore.expandedItemIds,
      expandedStore.setItemExpanded,
      pasteItem,
      isAllowedToPasteItem,
      viewerDecisionRole,
    ]
  );

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        requirementId,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      isAllowedToPasteItem,
      pasteItem,
      requirementId,
    ]
  );

  const [filteredItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems
      .filter(e => e.authorOrganisationId === viewer.organisation.id)
      .filter(item => !item.isAnyParentItemEliminated);
    return [treeItems, rootItem, allDocItems];
  }, [doc, viewer.organisation.id]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  const filterItem = React.useCallback(
    (i: RequirementEdit_ItemFragment) =>
      isTreePreviewItemVisible(viewerIsContractor, i, allDocItems, false),
    [allDocItems, viewerIsContractor]
  );

  React.useEffect(() => {
    if (
      !query.loading &&
      selectedItemId &&
      !filteredItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredItems,
    pathToDocPage,
    rootItem,
    query.loading,
    navigate,
  ]);

  const docSharingEnabled = useFeature("DocSharing");
  const viewerPermissionDetailsEnabled = useFeature("ViewerPermissionDetails");

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc ? (
          <RequirementSubHeader
            project={project}
            requirement={doc}
            activeView="tree"
            canEdit={canEdit}
            pathToDoc={pathToDoc}
            pathToDocList={pathToDocList}
            pathToDocPage={pathToDocPage}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
          />
        ) : undefined
      }
      action={
        project && doc ? (
          <RequirementAction
            doc={doc}
            project={project}
            pathToProject={pathToProject}
            startDecisionWizard={decisionProcess.start}
            startDecisionsInBulkProcess={startDecisionsInBulkProcess}
          />
        ) : undefined
      }
    >
      <PageColumn
        $hasRightBorder
        $hasBackground
        $display="block"
        ref={isMinTablet ? setTreeContainer : undefined}
      >
        {project && doc && rootItem && (
          <Stack flexDirection={"column"} spacing={2}>
            <Box>
              <TreeToggleAllExpandedButton
                type="button"
                areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                setAllItemsExpanded={expandedStore.setAllItemsExpanded}
              />
            </Box>

            <Box>
              <VirtualBareTreeStandaloneItem
                item={rootItem}
                items={filteredItems}
                allItems={allDocItems}
                depth={0}
                itemComponent={TreeItem}
                selected={isRootSelected}
                sticky={isRootSelected}
                top={0}
                clickable
                onClick={() => navigateToItem(rootItem.id)}
                to={`${pathToDocPage}/items/${rootItem.id}`}
              />

              {/* <TreeConnector $isAtTheTopOfTree={treeItemsCount > 0} /> */}
              <VirtualItemTree<RequirementEdit_ItemFragment, true>
                docId={doc.id}
                projectId={projectId}
                items={filteredItems}
                allItems={allDocItems}
                filterItem={filterItem}
                selectedItemId={selectedItemId}
                enableCreating={true}
                enableDragging={true}
                shouldRenderCreateInput={shouldRenderRequirementCreateInput}
                documentItemTypes={REQUIREMENT_ITEM_TYPES}
                container={treeContainer}
                itemComponent={TreeItem}
                inputComponent={TreeItemInput}
                expandedStore={expandedStore}
                // onItemsCountChange={setTreeItemsCount}
              />
            </Box>

            <DecisionWizardModalWithDefaultActions
              processState={decisionProcess.state}
              projectId={projectId}
              docType="REQUIREMENT"
              docId={requirementId}
              viewerDecisionRole={viewerDecisionRole}
              decisionContext={decisionProcess.decisionContext}
              expandedItemIds={expandedStore.expandedItemIds}
              handleClose={decisionProcess.close}
              handleNextItem={decisionProcess.next}
              handlePreviousItem={decisionProcess.previous}
            />

            <RequirementDecisionsInBulkProcess
              ref={decisionsInBulkProcessRef}
              projectId={projectId}
              requirementId={doc.id}
              canFinalize={canFinalize}
              externalExpandedItemIds={expandedStore.expandedItemIds}
            />
          </Stack>
        )}
      </PageColumn>
      <ConfirmProcess ref={confirmProcessRef} />

      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
        }}
      />

      {project && doc && isMinOneColumnWithPreview && (
        <PageContainer
          $fullHeight
          onClick={() => {
            if (!isMinTwoColumnsWithPreview)
              navigate(pathToDocPage, { replace: true });
          }}
          ref={setPreviewContainer}
        >
          <Container maxWidth="md">
            {viewerPermissionDetailsEnabled ? (
              <div>
                <h2>viewer permission details</h2>
                <hr />
                <textarea
                  style={{ width: "100%", height: 50 }}
                  readOnly
                  defaultValue={JSON.stringify(doc.viewerPermissions, null, 2)}
                ></textarea>
              </div>
            ) : null}

            {docSharingEnabled ? (
              <DocShareBox
                docType="REQUIREMENT"
                docId={doc.id}
                projectId={project.id}
              />
            ) : null}

            <RequirementPreview
              requirement={doc}
              navigateToItem={({ id }) => navigateToItem(id)}
              selectedItemId={selectedItemId}
              canFinalize={canFinalize}
              expandedStore={expandedStore}
              expandedItemIds={expandedStore.expandedItemIds}
              container={previewContainer}
            />
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};

function useCreateRequirementItems({
  projectId,
  requirementId,
  expandedItemIds,
}: {
  projectId: string;
  requirementId: string;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  const [createItems] = useProjectRequirementEdit_CreateItemsMutation({
    client,
  });

  const { createItem, createItemByType } = useCreateItem({
    projectId,
    createDocumentItems: createItems,
    expandedItemIds,
  });

  const [createItemsFromTemplatesMutation] =
    useProjectRequirementEdit_CreateItemsFromTemplatesMutation({
      client,
    });

  const createItemFromTemplate = React.useCallback(
    async (title: string, parentItemId: string, templateQuoteId: string) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: requirementId,
            parentItemId,
            templates: [
              {
                templateQuoteId,
              },
            ],
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items[0];
    },
    [
      createItemsFromTemplatesMutation,
      expandedItemIds,
      projectId,
      requirementId,
    ]
  );

  const createItemsFromTemplates = React.useCallback(
    async (
      parentItemId: string,
      templates: {
        quantity: number;
        template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
      }[]
    ) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: requirementId,
            parentItemId,
            templates: templates.map(tpl => ({
              templateQuoteId: tpl.template.id,
              templateEstimatedQuantity: tpl.quantity,
            })),
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items;
    },
    [
      createItemsFromTemplatesMutation,
      expandedItemIds,
      projectId,
      requirementId,
    ]
  );

  const createItemsWithProducts = React.useCallback(
    async (
      parentItemId: string,
      products: {
        quantity: number;
        product:
          | ProductSearchItem__ProductSearchResultFragment
          | ModifyItemProductValuesInput;
      }[]
    ) => {
      const result = await createItems({
        variables: {
          input: {
            projectId,
            docId: requirementId,
            parentItemId,
            items: products.map(opts =>
              "__typename" in opts.product
                ? {
                    title: opts.product.texts?.title ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    product: pick(opts.product, "supplierId", "articleNumber"),
                  }
                : {
                    title: opts.product.productTitle ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    productValues: opts.product,
                  }
            ),
            expandedItemIds,
          },
        },
      });
      return result.data?.createItems.items;
    },
    [createItems, expandedItemIds, projectId, requirementId]
  );

  const [copiedItem] = useDocItemsClipboard();
  const [pasteItemFromClipboardMutation] =
    useProjectRequirementEdit_PasteItemFromClipboardMutation({ client });
  const pasteItem = React.useCallback(
    async (docId: string, parentItemId: string) => {
      if (!copiedItem) throw new Error("No item copied");

      const result = await pasteItemFromClipboardMutation({
        variables: {
          input: {
            sourceProjectId: copiedItem.projectId,
            sourceDocId: copiedItem.docId,
            sourceItemId: copiedItem.itemId,
            destProjectId: projectId,
            destDocId: docId,
            destItemId: parentItemId,
            expandedItemIds,
          },
        },
      });
    },
    [copiedItem, pasteItemFromClipboardMutation, projectId, expandedItemIds]
  );

  const isAllowedToPasteItem = React.useCallback(
    (parentItem: { type: ItemType }) => {
      if (!copiedItem?.itemType || !parentItem.type) return false;

      return isAllowedToPaste({
        documentItemTypes: REQUIREMENT_ITEM_TYPES,
        copiedItemType: copiedItem.itemType,
        parentItemType: parentItem.type,
      });
    },
    [copiedItem?.itemType]
  );

  return {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  };
}
