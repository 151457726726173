import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Alert, Box, Container } from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import React from "react";
import { namedOperations } from "../../../clients/graphqlTypes";
import { useFeature } from "../../../common/FeatureFlags";
import { useUserData } from "../../auth/useUserData";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { Stack } from "../../commons/layout/Stack";
import { getViewerDecisionRole } from "../../features/doc-items/helpers";
import { useDecisionProcess } from "../../features/doc-items/hooks/useDecisionProcess";
import { DecisionWizardModalWithDefaultActions } from "../../features/doc-items/modals/DecisionWizardModal";
import { DecisionsInBulkProcessRef } from "../../features/doc-items/modals/DecisionsInBulkProcess";
import { QuoteAction } from "../../features/quotes/QuoteAction";
import { QuoteDecisionsInBulkProcess } from "../../features/quotes/QuoteDecisionsInBulkProcess";
import { QuotePreview } from "../../features/quotes/QuotePreview";
import { QuoteSubHeader } from "../../features/quotes/QuoteSubHeader";
import { DocShareBox } from "../documents/DocShareBox";
import {
  ProjectQuotePreviewHeaderFragment,
  ProjectQuotePreviewHeader_ProjectFragment,
  useProjectQuotePreviewQuery,
} from "./ProjectQuotePreview.generated";
import { useHasSapS4HanaIntegration } from "../../features/sap-s4-hana/hooks";

const REFETCH_QUERIES = [namedOperations.Query.ProjectQuotePreview];

interface Props {
  projectId: string;
  quoteId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

const EMPTY_ARRAY: any[] = [];

export const ProjectQuotePreview = ({
  projectId,
  quoteId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const language = useTolgee(["language"]).getLanguage()!;
  const { isMinTablet } = useScreenWidth();

  const [previewContainer, setPreviewContainer] =
    React.useState<HTMLDivElement | null>(null);

  const client = useApolloClient();
  const query = useProjectQuotePreviewQuery({
    client,
    variables: {
      quoteId,
      projectId,
    },
    fetchPolicy: "no-cache",
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.quote)?.quote;
  const docTitle = doc?.title ?? "";

  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();

  const pathToDocList = `/projects/${projectId}/quotes`;
  const pathToDoc = `/projects/${projectId}/quotes/${quoteId}`;
  const pathToDocPage = `${pathToDoc}/preview`;

  const rootItem = doc?.rootItem;

  const isAgreed =
    (doc?.isPublished && doc?.isBinding && doc?.agreement === "YES") ?? false;
  const isViewersQuote =
    doc?.owningSystemOrganisationId === viewer.organisation.id;

  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const decisionsInBulkProcessRef =
    React.useRef<DecisionsInBulkProcessRef>(null);
  const startDecisionsInBulkProcess = React.useCallback(() => {
    decisionsInBulkProcessRef.current?.startDecisionsInBulkProcess();
  }, []);

  const decisionProcess = useDecisionProcess({
    projectId,
    docId: quoteId,
    embeddedMode: false,
    itemUuidSeed: "",
    viewerDecisionRole,
    decisionContext: "onQuoteRefinement",
    onClose: query.refetch,
  });

  const docSharingEnabled = useFeature("DocSharing");
  const viewerPermissionDetailsEnabled = useFeature("ViewerPermissionDetails");

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc ? (
          <QuoteSubHeader
            project={project}
            quote={doc}
            activeView="preview"
            canEdit={isViewersQuote}
            isReadOnly={isAgreed}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
            hasSapS4HanaIntegration={hasSapS4HanaIntegration}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
            expandedItemIds={undefined}
          />
        ) : undefined
      }
      action={
        project &&
        doc && (
          <QuoteAction
            doc={doc}
            project={project}
            onDiscardPendingChangesRefetchQueries={REFETCH_QUERIES}
            onDeclineChangesRefetchQueries={REFETCH_QUERIES}
            startDecisionWizard={decisionProcess.start}
            startDecisionsInBulkProcess={startDecisionsInBulkProcess}
            refetch={query.refetch}
          />
        )
      }
    >
      {doc && project && rootItem && (
        <PageContainer key={language} ref={setPreviewContainer}>
          <Container maxWidth="md">
            {viewerPermissionDetailsEnabled ? (
              <div>
                <h2>viewer permission details</h2>
                <hr />
                <textarea
                  style={{ width: "100%", height: 50 }}
                  readOnly
                  defaultValue={JSON.stringify(doc.viewerPermissions, null, 2)}
                ></textarea>
              </div>
            ) : null}

            {docSharingEnabled ? (
              <DocShareBox
                docType="QUOTE"
                docId={doc.id}
                projectId={project.id}
              />
            ) : null}

            <Stack flexDirection={"column"} spacing={1}>
              <ProjectQuotePreviewHeader quote={doc} project={project} />
              <QuotePreview
                quote={doc}
                canFinalize={true}
                container={isMinTablet ? previewContainer : undefined}
                expandedItemIds={EMPTY_ARRAY}
                refetch={query.refetch}
                hasSapS4HanaIntegration={hasSapS4HanaIntegration}
              />
              <DecisionWizardModalWithDefaultActions
                processState={decisionProcess.state}
                projectId={projectId}
                docType="QUOTE"
                docId={quoteId}
                viewerDecisionRole={viewerDecisionRole}
                decisionContext={decisionProcess.decisionContext}
                isOptionHidden={item => !isViewersQuote && item.isHidden}
                expandedItemIds={EMPTY_ARRAY}
                handleClose={decisionProcess.close}
                handleNextItem={decisionProcess.next}
                handlePreviousItem={decisionProcess.previous}
                refetch={query.refetch}
              />

              <QuoteDecisionsInBulkProcess
                ref={decisionsInBulkProcessRef}
                projectId={projectId}
                quoteId={doc.id}
                canFinalize={true}
                externalExpandedItemIds={EMPTY_ARRAY}
                handleComplete={query.refetch}
              />
            </Stack>
          </Container>
        </PageContainer>
      )}
    </Page>
  );
};

const ProjectQuotePreviewHeader = ({
  quote,
  project,
}: {
  quote: ProjectQuotePreviewHeaderFragment;
  project: ProjectQuotePreviewHeader_ProjectFragment;
}) => {
  const { t } = useTranslate(["Quote", "ProjectOverview"]);
  const viewer = useUserData().currentUser!;
  const isClientUser = viewer.organisation.isClientOrganisation;
  const showNonBindingTip = quote.isBinding === false && isClientUser;
  const nonBindingTip = showNonBindingTip
    ? t(
        "This document is for informational purposes only. To receive a binding quote document, please contact the craftsman via MeisterSystems chat.",
        { ns: "Quote" }
      )
    : undefined;

  const showClosedOpportunityAlert =
    quote.incomingRequest &&
    quote.incomingRequest.status !== "PUBLISHED" &&
    quote.incomingRequest.owningSystemOrganisationId !==
      viewer.organisation.id &&
    !quote.isPublished;

  return quote.decliningReason ||
    showNonBindingTip ||
    showClosedOpportunityAlert ? (
    <Stack flexDirection="column" spacing={1}>
      {quote.decliningReason && (
        <Box>
          <Alert variant="outlined" severity="warning">
            {`${t("Declined", { ns: "Quote" })}: ${quote.decliningReason}`}
          </Alert>
        </Box>
      )}
      {showNonBindingTip && (
        <Box>
          <Alert variant="outlined" severity="info">
            {nonBindingTip}
          </Alert>
        </Box>
      )}
      {showClosedOpportunityAlert && (
        <Box>
          <Alert variant="outlined" severity="warning">
            {t("This opportunity is no longer accepting new quotes", {
              ns: "ProjectOverview",
            })}
          </Alert>
        </Box>
      )}
    </Stack>
  ) : null;
};
