import { CardContainer } from "@msys/ui";
import { Box, Typography } from "@mui/material";
import { colord, extend } from "colord";
import a11yPlugin from "colord/plugins/a11y";
import { Form, Formik } from "formik";
import { FC } from "react";
import * as Yup from "yup";
import { ColorPickerField } from "../../../commons/form-fields/ColorPickerField";
import { ManualSave } from "../../../commons/form-fields/ManualSave";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";
import { SwitchField } from "../../../commons/form-fields/SwitchField";
import { Stack } from "../../../commons/layout/Stack";
import { color } from "../../../../common/MuiThemeProvider";
import { BrandingThemeType } from "../../../../clients/graphqlTypes";
import { BrandingThemeFragment } from "../../../main-routes/my-organisation/OrganisationBranding.generated";
import { useTranslate } from "@tolgee/react";

extend([a11yPlugin]);

const getDefaultValues = (type: BrandingThemeType): ThemeFormValues => {
  const { colorBackground, colorText } = getDefaultColors(type);
  return {
    enabled: false,
    type,
    colorBackground,
    colorText,
  };
};

const getDefaultColors = (type: BrandingThemeType) => {
  return {
    colorBackground: type === "light" ? color.white : color.blue0,
    colorText: type === "light" ? color.blue0 : color.white,
  };
};

export interface ThemeFormValues {
  enabled: boolean;
  type: BrandingThemeType;
  colorBackground: string;
  colorText: string;
}

interface Props {
  title: string;
  defaultType: BrandingThemeType;
  theme: BrandingThemeFragment | null;
  onSubmit: (values: ThemeFormValues) => void | Promise<void>;
}

export const OrganisationBrandingThemeBox: FC<Props> = ({
  title,
  defaultType,
  theme,
  onSubmit,
}) => {
  const { t } = useTranslate("OrganisationBranding");

  const validationSchema = Yup.object().shape({
    enabled: Yup.boolean().required(),
    type: Yup.string().required(),
    colorText: Yup.string()
      .matches(/^#[a-f\d]{6}$/i, t("Wrong color format, only HEX is allowed"))
      .when("enabled", {
        is: true,
        then: Yup.string().required(t("Text color is required")),
      }),
    colorBackground: Yup.string()
      .matches(/^#[a-f\d]{6}$/i, t("Wrong color format, only HEX is allowed"))
      .when("enabled", {
        is: true,
        then: Yup.string().required(t("Background color is required")),
      }),
  });

  const initialValues: ThemeFormValues = theme
    ? theme
    : getDefaultValues(defaultType);

  const typeOptions = [
    { value: "light", label: t("Light") },
    { value: "dark", label: t("Dark") },
  ];

  return (
    <Formik<ThemeFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, handleReset, values, setValues, isSubmitting }) => {
        const isNotReadable =
          values.colorText &&
          colord(values.colorText).isValid() &&
          values.colorBackground &&
          colord(values.colorBackground).isValid() &&
          !colord(values.colorText).isReadable(values.colorBackground);
        const showTypeSelect =
          (values.colorText &&
            colord(values.colorText).isValid() &&
            colord(values.colorText).brightness() <= 0.6 &&
            colord(values.colorText).brightness() >= 0.4) ||
          (values.colorBackground &&
            colord(values.colorBackground).isValid() &&
            colord(values.colorBackground).brightness() <= 0.6 &&
            colord(values.colorBackground).brightness() >= 0.4);
        return (
          <CardContainer isExpandable title={title}>
            <Box p={1}>
              <Form>
                <Stack flexDirection="column">
                  <SwitchField label={t("Enabled")} name="enabled" />
                  {values.enabled && (
                    <>
                      <ColorPickerField
                        label={t("Background color")}
                        name="colorBackground"
                        required
                      />
                      <ColorPickerField
                        label={t("Text color")}
                        name="colorText"
                        required
                        onChange={newColor => {
                          setValues({
                            ...values,
                            colorText: newColor,
                            type:
                              newColor && colord(newColor).isValid()
                                ? colord(newColor).isDark()
                                  ? "light"
                                  : "dark"
                                : values.type,
                          });
                        }}
                      />
                      {isNotReadable ? (
                        <Typography
                          variant="caption"
                          style={{ color: color.red }}
                        >
                          {t("Text may be not readable at a background")}
                        </Typography>
                      ) : null}
                      {showTypeSelect && (
                        <Stack spacing={2} alignItems="center">
                          <Typography variant="body2">{t("Theme")}:</Typography>
                          <RadioGroupField
                            inline
                            options={typeOptions}
                            name="type"
                          />
                        </Stack>
                      )}
                    </>
                  )}
                  <ManualSave
                    onCancel={handleReset}
                    disabled={isSubmitting}
                    disabledCancel={!dirty}
                  />
                </Stack>
              </Form>
            </Box>
          </CardContainer>
        );
      }}
    </Formik>
  );
};
