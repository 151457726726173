import { gql, useApolloClient } from "@apollo/client";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDataOrNull } from "@msys/common";
import {
  DataGrid,
  GridColDef,
  ModalOpenButton,
  TextWithBreaks,
  useScreenWidth,
} from "@msys/ui";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Icon, IconButton, Select, Tab, Tooltip } from "@mui/material";
import { isEqual, noop } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";
import { useUserData } from "../../auth/useUserData";
import { ButtonCreate } from "../../commons/button/Button";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { Stack } from "../../commons/layout/Stack";
import { ConfirmModal } from "../../commons/modals/ConfirmModal";
import { usePaginationParams } from "../../commons/pagination/usePaginationParams";
import { RESULTS_PER_PAGE_OPTIONS } from "../../constants";
import { AbsenceSchedule } from "../../features/absences/AbsenceSchedule";
import { CreateAbsenceModal } from "../../features/absences/CreateAbsenceModal";
import { EditAbsenceModal } from "../../features/absences/EditAbsenceModal";
import { useAbsenceReasons } from "../../features/absences/useAbsenceReasons";
import {
  getTotalDifferenceInDays,
  getTotalDurationInDays,
} from "../../features/planning/helpers";
import { ResourceFilterChips } from "../../features/planning/ResourceFilterChips";
import { ResourceFilterModal } from "../../features/planning/ResourceFilterModal";
import { useResourceFilter } from "../../features/planning/useResourceFilter";
import { CalendarViewLayout } from "../../features/schedule/CalendarViewLayout";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { namedOperations } from "../../../clients/graphqlTypes";
import {
  PlanningAbsenceFragment,
  usePlanningAbsencesMembersQuery,
  usePlanningAbsencesQuery,
  useRemoveAbsenceMutation,
} from "./PlanningAbsences.generated";
import { useTranslate } from "@tolgee/react";

const YEAR = new Date().getFullYear().toString();

const YEAR_OPTIONS = Array(21)
  .fill(null)
  .map((v, idx) => {
    const year = +YEAR + (10 - idx);
    return {
      value: year.toString(),
      label: year.toString(),
    };
  })
  .reverse();

enum EditorView {
  List = "list",
  Calendar = "calendar",
}

interface Props {
  submenuItems: PageTopbarItem[];
}

export const PlanningAbsences = ({ submenuItems }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Global", "SidebarItems", "Absences"]);
  const { isMinTablet } = useScreenWidth();
  const viewer = useUserData().currentUser!;

  const [view, setView] = useState<EditorView>(EditorView.List);

  const { absenceReasonLabels } = useAbsenceReasons();

  const {
    resourceList,
    setResourceList,
    resourceOrganisationRoleIds,
    setResourceOrganisationRoleIds,
  } = useResourceFilter(viewer.organisation.id, undefined, "absences-list");

  const { limit, offset, paginationModel, setPaginationModel, pageUrlParam } =
    usePaginationParams();

  const [year, setYear] = useStateWithUrlParam<string>("year", YEAR, [
    pageUrlParam,
  ]);

  const client = useApolloClient();
  const query = usePlanningAbsencesQuery({
    client,
    variables: {
      limit,
      offset,
      rangeStart: `${year}-01-01`,
      rangeEnd: `${year}-12-31`,
      ...(resourceList ? { filterByWhoIds: resourceList } : undefined),
      ...(resourceOrganisationRoleIds
        ? { filterByRoleIds: resourceOrganisationRoleIds }
        : undefined),
    },
  });
  const [removeAbsence] = useRemoveAbsenceMutation({ client });

  const membersQuery = usePlanningAbsencesMembersQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const absences =
    getDataOrNull((query.data ?? query.previousData)?.absences)?.edges.map(
      e => e.node
    ) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.absences)?.totalCount ??
    0;

  const organisationMemberships = membersQuery?.data?.organisationMemberships;

  const organisationDefaults = getDataOrNull(
    membersQuery?.data?.organisationDefaults
  );

  const craftsmen = useMemo(
    () =>
      (organisationMemberships ?? [])
        .slice()
        .sort((a, b) => a.familyname.localeCompare(b.familyname)),
    [organisationMemberships]
  );
  const roles = React.useMemo(
    () => membersQuery?.data?.roles ?? [],
    [membersQuery?.data?.roles]
  );

  const handleDeleteAbsence = async (id: string) => {
    try {
      await removeAbsence({
        variables: { input: { id } },
        refetchQueries: [namedOperations.Query.PlanningAbsences],
        awaitRefetchQueries: true,
      });
      enqueueSnackbar(
        t("Absence deleted", {
          ns: "Absences",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const stateStore = useDataGridStateStore("PlanningAbsences");

  const List = (
    <Stack
      flexDirection="column"
      flex={1}
      width="100%"
      height="100%"
      spacing={1}
      overflow="hidden"
    >
      <Stack
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={0}
        flexShrink={0}
      >
        <Stack alignItems="center">
          <Select
            fullWidth={false}
            native
            variant="outlined"
            size="extra-small"
            value={year}
            onChange={event => {
              setYear(event.target.value);
            }}
          >
            {YEAR_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>

          <ModalOpenButton
            Modal={ResourceFilterModal}
            modalProps={{
              usePinnedResources: false,
              useAvailability: false,
              resourceOnTop: undefined,
              resourceAvailability: undefined,
              resourceMaxDistance: undefined,
              resources: craftsmen,
              roles: roles,
              resourceList,
              resourceOrganisationRoleIds,
              handleComplete: ({
                resourceList: newResourceList,
                resourceOrganisationRoleIds: newResourceOrganisationRoleIds,
              }) => {
                if (!isEqual(resourceList, newResourceList))
                  setResourceList(newResourceList);
                if (
                  !isEqual(
                    resourceOrganisationRoleIds,
                    newResourceOrganisationRoleIds
                  )
                )
                  setResourceOrganisationRoleIds(
                    newResourceOrganisationRoleIds
                  );
              },
            }}
          >
            <IconButton color="primary" size="extra-small">
              <Icon fontSize="small">
                <FontAwesomeIcon icon={faFilter} />
              </Icon>
            </IconButton>
          </ModalOpenButton>

          {isMinTablet && (
            <ResourceFilterChips
              resources={craftsmen}
              roles={roles}
              resourceList={resourceList}
              resourceOrganisationRoleIds={resourceOrganisationRoleIds}
              resourceAvailability={undefined}
              resourceMaxDistance={undefined}
              setResourceList={setResourceList}
              setResourceOrganisationRoleIds={setResourceOrganisationRoleIds}
              setResourceAvailability={noop}
              setResourceMaxDistance={noop}
            />
          )}
        </Stack>
        <ModalOpenButton
          Modal={CreateAbsenceModal}
          modalProps={{
            craftsmen,
            refetchQueries: [
              namedOperations.Query.PlanningAbsences,
              namedOperations.Query.AbsenceSchedule,
            ],
          }}
        >
          <ButtonCreate
            variant="contained"
            color="primary"
            title={t("New absence", {
              ns: "Absences",
            })}
          />
        </ModalOpenButton>
      </Stack>

      {!isMinTablet && (
        <ResourceFilterChips
          resources={craftsmen}
          roles={roles}
          resourceList={resourceList}
          resourceOrganisationRoleIds={resourceOrganisationRoleIds}
          resourceAvailability={undefined}
          resourceMaxDistance={undefined}
          setResourceList={setResourceList}
          setResourceOrganisationRoleIds={setResourceOrganisationRoleIds}
          setResourceAvailability={noop}
          setResourceMaxDistance={noop}
        />
      )}

      <DataGrid
        stateStore={stateStore}
        loading={query.loading}
        hideFooter={totalCount === 0}
        columns={
          [
            {
              field: "who",
              headerName: t("Team member", {
                ns: "Absences",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: item }) =>
                `${item.who.firstname} ${item.who.familyname}`,
            },
            {
              field: "dates",
              headerName: t("Dates", {
                ns: "Absences",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: item }) =>
                `${moment(item.from).format("L")} – ${moment(item.till).format(
                  "L"
                )}`,
            },
            {
              field: "days",
              headerName: t("Days", {
                ns: "Absences",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: item }) =>
                t("{workingDays} working / {totalDays} total", {
                  ns: "Absences",
                  workingDays: getTotalDurationInDays(item.from, item.till),
                  totalDays: getTotalDifferenceInDays(item.from, item.till),
                }),
            },
            {
              field: "reason",
              headerName: t("Reason", {
                ns: "Absences",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: item }) => absenceReasonLabels[item.reason],
            },
            {
              field: "note",
              headerName: t("Note", {
                ns: "Absences",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: item }) => (
                <TextWithBreaks text={item.note} />
              ),
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: item }) => (
                <Stack>
                  <ModalOpenButton
                    Modal={EditAbsenceModal}
                    modalProps={{
                      absence: item,
                      craftsmen,
                    }}
                  >
                    <IconButton color="secondary" size="small">
                      <Tooltip
                        title={t("Edit absence", {
                          ns: "Absences",
                        })}
                      >
                        <EditIcon />
                      </Tooltip>
                    </IconButton>
                  </ModalOpenButton>
                  <ModalOpenButton
                    Modal={ConfirmModal}
                    modalProps={{
                      title: t(
                        "Are you sure you want to delete this absence?",
                        {
                          ns: "Absences",
                        }
                      ),
                      handleConfirm: async () => {
                        await handleDeleteAbsence(item.id);
                      },
                    }}
                  >
                    <IconButton color="secondary" size="small">
                      <Tooltip
                        title={t("Delete absence", {
                          ns: "Absences",
                        })}
                      >
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </ModalOpenButton>
                </Stack>
              ),
            },
          ] as GridColDef<PlanningAbsenceFragment>[]
        }
        rows={absences}
        paginationModel={paginationModel}
        onPaginationModelChange={newPaginationModel => {
          setPaginationModel(newPaginationModel);
        }}
        pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
        rowCount={totalCount}
      />
    </Stack>
  );

  const Calendar = (
    <AbsenceSchedule
      roles={roles}
      resources={craftsmen}
      startWorkDay={organisationDefaults?.defaultStartWorkDay ?? undefined}
      endWorkDay={organisationDefaults?.defaultEndWorkDay ?? undefined}
      view={"TimelineMonth"}
    />
  );

  return (
    <Page
      subtitle={t("Planning and Resources", {
        ns: "SidebarItems",
      })}
      title={t("Absences", {
        ns: "Absences",
      })}
      submenuItems={submenuItems}
    >
      <PageContainer style={{ minHeight: "100%", height: "unset" }}>
        {isMinTablet ? (
          <CalendarViewLayout name="absences" List={List} Calendar={Calendar} />
        ) : (
          <TabContext value={view}>
            <Stack
              flexGrow={1}
              flexDirection="column"
              justifyContent="stretch"
              alignItems="stretch"
            >
              <TabList
                onChange={(
                  event: React.ChangeEvent<{}>,
                  newValue: EditorView
                ) => {
                  setView(newValue);
                }}
                variant="fullWidth"
                scrollButtons={false}
                sx={{ mt: -1.5 }}
              >
                <Tab
                  icon={<EditIcon />}
                  value={EditorView.List}
                  aria-label="List"
                />
                <Tab
                  icon={<CalendarTodayIcon />}
                  value={EditorView.Calendar}
                  aria-label="Calendar"
                />
              </TabList>

              <TabPanel
                style={{
                  height: "100%",
                  flexShrink: 1,
                  flexGrow: 1,
                  overflow: "auto",
                  padding: 0,
                  display: view === EditorView.List ? "flex" : "none",
                  flexDirection: "column",
                }}
                value={EditorView.List}
              >
                {List}
              </TabPanel>
              <TabPanel
                style={{
                  height: "100%",
                  flexShrink: 1,
                  flexGrow: 1,
                  overflow: "hidden",
                  padding: 0,
                  display: view === EditorView.Calendar ? "flex" : "none",
                  flexDirection: "column",
                }}
                value={EditorView.Calendar}
              >
                {Calendar}
              </TabPanel>
            </Stack>
          </TabContext>
        )}
      </PageContainer>
    </Page>
  );
};
