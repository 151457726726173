import { gql, useApolloClient } from "@apollo/client";
import { uniq } from "lodash";
import React from "react";
import { TagGroup } from "../../../clients/graphqlTypes";
import { useOrganisationTagsQuery } from "./useOrganisationTags.generated";

export const useOrganisationTags = (group: TagGroup) => {
  const client = useApolloClient();
  const tagsQuery = useOrganisationTagsQuery({
    client,
    variables: { group },
  });
  const { tags, customTags, systemTags } = React.useMemo((): {
    tags: string[];
    customTags: string[];
    systemTags: string[];
  } => {
    const customTags = tagsQuery.data?.viewer?.organisation.customTags ?? [];
    const systemTags = tagsQuery.data?.viewer?.organisation.systemTags ?? [];
    const tags = uniq([...systemTags, ...customTags]).sort((t1, t2) =>
      t1.localeCompare(t2)
    );
    return { tags, customTags, systemTags };
  }, [
    tagsQuery.data?.viewer?.organisation.customTags,
    tagsQuery.data?.viewer?.organisation.systemTags,
  ]);
  return { tagsQuery, tags, customTags, systemTags };
};
