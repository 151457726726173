/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TodoListItemFragment = { __typename: 'TodoItemNode', id: string, title: string, isDone: boolean, time?: any | null, assignee?: { __typename: 'TodoItemUser', id: string, fullname?: string | null } | null };

export const TodoListItemFragmentDoc = gql`
    fragment TodoListItem on TodoItemNode {
  id
  title
  isDone
  time
  assignee {
    id
    fullname
  }
}
    `;