import { gql } from "@apollo/client";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ConfirmModalProps } from "../../../commons/modals/ConfirmProcess";
import { DocType, ItemType } from "../../../../clients/graphqlTypes";
import { CopyItemIntoClipboardMenuItem } from "./CopyItemIntoClipboardMenuItem";
import { DeleteItemMenuItem } from "./DeleteItemMenuItem";
import { DocItemContextMenuItems_ItemFragment } from "./DocItemContextMenuItems.generated";
import { PasteItemFromClipboardMenuItem } from "./PasteItemFromClipboardMenuItem";

interface Props {
  projectId: string | null;
  docId: string;
  docType: DocType;
  pathToDocPage: string;
  item: DocItemContextMenuItems_ItemFragment;
  disabled?: boolean;
  startConfirmProcess: (
    props: ConfirmModalProps
  ) => Promise<boolean | undefined>;
  pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
  expandedItemIds: string[] | undefined;
}

export const DocItemContextMenuItems = ({
  projectId,
  docId,
  docType,
  pathToDocPage,
  item,
  disabled,
  startConfirmProcess,
  expandedItemIds,
  pasteItem,
}: Props) => {
  const navigate = useNavigate();

  const canEdit = !item.deletedAt;
  const canDelete = !item.deletedAt && !item.isRootItem;

  const isLinkingItem = Boolean(item.linkedQuoteTemplate);

  return (
    <>
      <CopyItemIntoClipboardMenuItem
        itemId={item.id}
        itemType={item.type}
        docId={docId}
        docType={docType}
        projectId={projectId}
        disabled={disabled || !canEdit || isLinkingItem}
      />
      <PasteItemFromClipboardMenuItem
        itemId={item.id}
        docId={docId}
        disabled={disabled || !canEdit}
        pasteItem={pasteItem}
      />
      <DeleteItemMenuItem
        projectId={projectId}
        docId={docId}
        itemId={item.id}
        disabled={disabled || !canDelete}
        startConfirmProcess={startConfirmProcess}
        onItemDeleted={() => {
          navigate(pathToDocPage, { replace: true });
        }}
        expandedItemIds={expandedItemIds}
      />
    </>
  );
};
