/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { CrmEmailListItem_CrmMailMessageFragmentDoc } from './CrmEmailListItem.generated';
export type CrmEmailMessagesBox_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, title: string, email: any, members: Array<{ __typename: 'CrmPerson', id: string, fullname: string, email: any }>, mailMessages: Array<{ __typename: 'CrmMailMessage', id: string, createdAt: any, threadId: string, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, body: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> }> };

export type CrmEmailMessagesBox_CrmPersonFragment = { __typename: 'CrmPerson', id: string, fullname: string, email: any, mailMessages: Array<{ __typename: 'CrmMailMessage', id: string, createdAt: any, threadId: string, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, body: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> }>, crmCompany: { __typename: 'CrmCompany', id: string } };

export type CrmEmailMessagesBox_CrmMailMessageFragment = { __typename: 'CrmMailMessage', id: string, createdAt: any, threadId: string, direction: Types.EmailMessageDirection, from: string, to: string, subject: string, body: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> };

export type EmailMessagePreviewModal_CrmMailMessageFragment = { __typename: 'CrmMailMessage', id: string, createdAt: any, from: string, to: string, subject: string, body: string, attachments: Array<{ __typename: 'CrmMailMessageAttachment', filename: string, mimeType: string, url: string }> };

export const EmailMessagePreviewModal_CrmMailMessageFragmentDoc = gql`
    fragment EmailMessagePreviewModal_CrmMailMessage on CrmMailMessage {
  id
  createdAt
  from
  to
  subject
  body
  attachments {
    filename
    mimeType
    url
  }
}
    `;
export const CrmEmailMessagesBox_CrmMailMessageFragmentDoc = gql`
    fragment CrmEmailMessagesBox_CrmMailMessage on CrmMailMessage {
  id
  createdAt
  threadId
  ...CrmEmailListItem_CrmMailMessage
  ...EmailMessagePreviewModal_CrmMailMessage
}
    ${CrmEmailListItem_CrmMailMessageFragmentDoc}
${EmailMessagePreviewModal_CrmMailMessageFragmentDoc}`;
export const CrmEmailMessagesBox_CrmCompanyFragmentDoc = gql`
    fragment CrmEmailMessagesBox_CrmCompany on CrmCompany {
  id
  title
  email
  members {
    id
    fullname
    email
  }
  mailMessages {
    id
    ...CrmEmailMessagesBox_CrmMailMessage
  }
}
    ${CrmEmailMessagesBox_CrmMailMessageFragmentDoc}`;
export const CrmEmailMessagesBox_CrmPersonFragmentDoc = gql`
    fragment CrmEmailMessagesBox_CrmPerson on CrmPerson {
  id
  fullname
  email
  mailMessages {
    id
    ...CrmEmailMessagesBox_CrmMailMessage
  }
  crmCompany {
    id
  }
}
    ${CrmEmailMessagesBox_CrmMailMessageFragmentDoc}`;