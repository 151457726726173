import { MenuItemWithIcon } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslate } from "@tolgee/react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductOverview__ProductFragment } from "../Product.generated";

export const SupplierProductEditMenuButton = ({
  product,
}: {
  product: ProductOverview__ProductFragment;
}) => {
  const { t } = useTranslate(["Product", "Global"]);

  const navigate = useNavigate();
  const location = useLocation();

  if (product.__typename !== "PimSupplierProduct") {
    return null;
  }

  if (product.deletedAt) {
    return null;
  }

  if (!product.organisationCanEdit) {
    return null;
  }

  return (
    <MenuItemWithIcon
      icon={<EditIcon />}
      onClick={() => {
        navigate({
          pathname: `/products/catalogues/supplier/${
            product.supplierId
          }/${encodeURIComponent(product.articleNumber)}/edit`,
          search: new URLSearchParams({
            r: location.pathname + location.search,
          }).toString(),
        });
      }}
    >
      {t("Edit", { ns: "Product" })}
    </MenuItemWithIcon>
  );
};
