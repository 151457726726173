import { ellipsisStyle, FormattedPrice, useFormatting } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  ActionLine,
  ClientLine,
  CreatedDateLine,
  MasterLine,
} from "../../commons/DataItem";
import {
  QuoteListItem_IncomingQuoteFragment,
  QuoteListItem_OutgoingQuoteFragment,
} from "./QuoteListItem.generated";
import { QuoteStatusBadge } from "./QuoteStatusBadge";
import { assertNever } from "@msys/common";

interface Props {
  quote:
    | QuoteListItem_IncomingQuoteFragment
    | QuoteListItem_OutgoingQuoteFragment;
  Action?: React.ReactElement;
}

export const QuoteListItem = ({ quote, Action }: Props) => {
  const { getFormattedDateTime } = useFormatting();
  const { t } = useTranslate(["Quotes"]);

  const actorLine =
    quote.__typename === "IncomingQuote" ? (
      <Tooltip
        title={t("Contractor", { ns: "Global" })}
        arrow
        placement="bottom"
      >
        <div style={ellipsisStyle}>
          <MasterLine>{quote.docActorContractorName}</MasterLine>
        </div>
      </Tooltip>
    ) : quote.__typename === "OutgoingQuote" ? (
      <Tooltip
        title={t("Contractee (client)", { ns: "Global" })}
        arrow
        placement="bottom"
      >
        <div style={ellipsisStyle}>
          <ClientLine>{`${quote.docActorContracteeName}${quote.docActorClientName.trim() ? ` (${quote.docActorClientName})` : ""}`}</ClientLine>
        </div>
      </Tooltip>
    ) : (
      assertNever(quote)
    );

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{quote.title}</span>
          <span
            style={{
              display: "inline-flex",
              marginLeft: "8px",
              verticalAlign: "middle",
            }}
          >
            <QuoteStatusBadge quote={{ isPublished: true, ...quote }} small />
          </span>
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
        style={ellipsisStyle}
      >
        <Box
          gap={0.5}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <Tooltip title={t("Created", { ns: "Quotes" })} arrow placement="top">
            <div>
              <CreatedDateLine>
                {getFormattedDateTime(quote.createdAt)}
              </CreatedDateLine>
            </div>
          </Tooltip>
          {actorLine}
        </Box>

        <Typography
          variant={"body2"}
          style={{
            float: "right",
            alignItems: "flex-end",
            display: "flex",
            shapeOutside: "inset(calc(100% - 20px) 0 0)",
            marginBottom: "-4px",
          }}
          component="div"
        >
          <FormattedPrice value={quote.proposedTotalPrice ?? 0} />
        </Typography>
      </Stack>
    </Stack>
  );
};
