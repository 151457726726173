/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserPreferenceMutationVariables = Types.Exact<{
  input: Types.SetUserPreferenceInput;
}>;


export type SetUserPreferenceMutation = { setUserPreference: { __typename: 'SetUserPreferenceResult', ok: boolean } };

export type ViewerPreferenceQueryVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
}>;


export type ViewerPreferenceQuery = { viewerPreference?: { __typename: 'UserPreference', id: string, key: string, value: any } | null };


export const SetUserPreferenceDocument = gql`
    mutation SetUserPreference($input: SetUserPreferenceInput!) {
  setUserPreference(input: $input) {
    ok
  }
}
    `;
export type SetUserPreferenceMutationFn = Apollo.MutationFunction<SetUserPreferenceMutation, SetUserPreferenceMutationVariables>;

/**
 * __useSetUserPreferenceMutation__
 *
 * To run a mutation, you first call `useSetUserPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPreferenceMutation, { data, loading, error }] = useSetUserPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPreferenceMutation, SetUserPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPreferenceMutation, SetUserPreferenceMutationVariables>(SetUserPreferenceDocument, options);
      }
export type SetUserPreferenceMutationHookResult = ReturnType<typeof useSetUserPreferenceMutation>;
export type SetUserPreferenceMutationResult = Apollo.MutationResult<SetUserPreferenceMutation>;
export type SetUserPreferenceMutationOptions = Apollo.BaseMutationOptions<SetUserPreferenceMutation, SetUserPreferenceMutationVariables>;
export const ViewerPreferenceDocument = gql`
    query ViewerPreference($key: String!) {
  viewerPreference(key: $key) {
    id
    key
    value
  }
}
    `;

/**
 * __useViewerPreferenceQuery__
 *
 * To run a query within a React component, call `useViewerPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerPreferenceQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useViewerPreferenceQuery(baseOptions: Apollo.QueryHookOptions<ViewerPreferenceQuery, ViewerPreferenceQueryVariables> & ({ variables: ViewerPreferenceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerPreferenceQuery, ViewerPreferenceQueryVariables>(ViewerPreferenceDocument, options);
      }
export function useViewerPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerPreferenceQuery, ViewerPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerPreferenceQuery, ViewerPreferenceQueryVariables>(ViewerPreferenceDocument, options);
        }
export function useViewerPreferenceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerPreferenceQuery, ViewerPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewerPreferenceQuery, ViewerPreferenceQueryVariables>(ViewerPreferenceDocument, options);
        }
export type ViewerPreferenceQueryHookResult = ReturnType<typeof useViewerPreferenceQuery>;
export type ViewerPreferenceLazyQueryHookResult = ReturnType<typeof useViewerPreferenceLazyQuery>;
export type ViewerPreferenceSuspenseQueryHookResult = ReturnType<typeof useViewerPreferenceSuspenseQuery>;
export type ViewerPreferenceQueryResult = Apollo.QueryResult<ViewerPreferenceQuery, ViewerPreferenceQueryVariables>;