import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUserData } from "../../auth/useUserData";
import { PageNotFound } from "../../commons/PageNotFound";
import { PageTopbarItem } from "../../commons/layout/Page";
import { UserIcon } from "../../features/users/UserIcon";
import { MyOrganisationUserProfile } from "../my-organisation/OrganisationUserProfile";
import { UserSettings } from "./UserSettings";

type PageSubmenuItem = PageTopbarItem;

export const UserRoutes = () => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate("OrganisationPageTopbar");

  const organisation = viewer.organisation;

  const submenuItems: PageSubmenuItem[] = useMemo(() => {
    if (!organisation) return [];

    return [
      {
        name: "user-profile",
        label: t("My Profile"),
        type: "link",
        path: `/user/profile`,
        icon: <UserIcon />,
        organisation,
      },
      {
        name: "user-settings",
        label: t("My Settings"),
        type: "link",
        path: `/user/settings`,
        icon: <SettingsIcon />,
        organisation,
      },
    ];
  }, [t, organisation]);

  return (
    <Routes>
      <Route index element={<Navigate to="profile" replace />} />
      <Route
        path="profile"
        element={
          <MyOrganisationUserProfile
            submenuItems={submenuItems}
            userId={viewer.id}
          />
        }
      />
      <Route
        path="settings"
        element={<UserSettings submenuItems={submenuItems} />}
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
