import { noop } from "lodash";
import React from "react";
import { InvitationTokenData } from "./InvitationTokenData";

export interface InvitationTokenContext {
  token: null | string;
  tokenData: null | InvitationTokenData;
  isLoading: boolean;
  remove: () => void;
}
export const InvitationTokenContext =
  React.createContext<InvitationTokenContext>({
    token: null,
    tokenData: null,
    isLoading: false,
    remove: noop,
  });
