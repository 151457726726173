import { useApolloClient } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useDeclineQuoteMutation } from "../Quotes.generated";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../../../commons/layout/Stack";
import { Modal } from "@msys/ui";

interface FormValues {
  reason: string;
}

interface Props {
  projectId: string;
  quoteId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: () => Promise<unknown> | unknown;
}

export const DeclineQuoteModal: React.FC<Props> = ({
  projectId,
  quoteId,
  title,
  handleClose,
  handleComplete,
}) => {
  const { t } = useTranslate(["QuoteDecline", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [declineQuote] = useDeclineQuoteMutation({ client });

  const onSubmit = async (values: FormValues) => {
    await declineQuote({
      variables: {
        input: {
          projectId,
          docId: quoteId,
          reason: values.reason,
        },
      },
    });

    enqueueSnackbar(
      t("Quote declined.", {
        ns: "QuoteDecline",
      })
    );
    handleComplete?.();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    reason: Yup.string().label(
      t("Reason", {
        ns: "QuoteDecline",
      })
    ),
  });

  return (
    <Modal
      title={
        title ??
        t("Decline Quote", {
          ns: "QuoteDecline",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Decline Quote", {
            ns: "QuoteDecline",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
          },
        },
      ]}
    >
      <Formik<FormValues>
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{
          reason: "",
        }}
      >
        <Form id={formId}>
          <Stack flexDirection="column">
            <Field
              autoFocus
              label={t("Reason", {
                ns: "QuoteDecline",
              })}
              name="reason"
              multiline
              component={TextField}
              rows={3}
            />
          </Stack>
        </Form>
      </Formik>
    </Modal>
  );
};
