import React from "react";
import { useUserData } from "../../auth/useUserData";
import { VanMarckeProfitabilityButton } from "./VanMarckeProfitabilityButton";

const VAN_MARCKE_ORGANISATION_IDS = [
  "63def7f7-1478-4954-85ab-ff7d92eee4e8",
  "e3e427c5-1179-4bb5-92a0-cfcd861027c9",
  "fd207cf7-3f83-4b47-aba4-07eea8fb8d28",
  "8fc195ca-4119-4d80-80c3-d3889489deb7",
  "591896f4-bfe9-4646-ba5b-a1d9c0acf3e5",
  "430c1a99-e214-4343-97d3-e2d02e5acdf9",
  "48199f6a-5da9-49e0-a133-ab4ff5d279b4",
  "fdf1b1bf-162f-48bd-be1a-97f43bcd6b25",
];

export const VanMarckeProfitability = (
  props: React.ComponentProps<typeof VanMarckeProfitabilityButton>
) => {
  const viewer = useUserData().currentUser!;

  if (!VAN_MARCKE_ORGANISATION_IDS.includes(viewer.organisation.id)) {
    return null;
  }

  return <VanMarckeProfitabilityButton {...props} />;
};
