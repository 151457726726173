import { gql } from "@apollo/client";
import { Button } from "@mui/material";
import { Alert } from "@mui/material";
import React from "react";
import { BillOfMaterialsItemAlertFragment } from "./BillOfMaterialsItemAlert.generated";
import { useTranslate } from "@tolgee/react";

export function BillOfMaterialsItemAlert({
  item,
  handleActionClick,
}: {
  item: BillOfMaterialsItemAlertFragment;
  handleActionClick: () => void;
}) {
  const { t } = useTranslate("Materials");

  const button = (
    <Button color="inherit" onClick={handleActionClick}>
      {t("View items")}
    </Button>
  );

  if (
    item.quantityRequiredDraft > 0 &&
    item.quantityRequiredDraft === item.quantityRequiredTotal
  ) {
    return (
      <Alert severity="info" action={button}>
        {t("This product is included only in draft quote items.")}
      </Alert>
    );
  }

  if (
    item.quantityRequiredDraft !== 0 ||
    item.quantityRequiredPending !== 0 ||
    item.quantityRequiredChange !== 0
  ) {
    return (
      <Alert severity={"warning"} action={button}>
        <p>
          {t(
            "Unsettled quote items affect the total required quantity of this material:"
          )}
        </p>
        <ul>
          {item.quantityRequiredDraft !== 0 && <li>{t("draft item")}</li>}
          {item.quantityRequiredPending !== 0 && <li>{t("pending item")}</li>}
          {item.quantityRequiredChange !== 0 && (
            <li>{t("item with pending change request")}</li>
          )}
        </ul>
        <p>{t("View quote item for details.")}</p>
      </Alert>
    );
  }

  return null;
}
