import { useApolloClient } from "@apollo/client";
import { MenuButton, MenuItemWithIcon } from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { useDeleteBuildingMutation } from "./Buildings.generated";

export const BuildingMenu = ({
  docId,
  pathToDocList,
  pathToDocPage,
}: {
  docId: string;
  pathToDocList: string;
  pathToDocPage: string;
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const { t } = useTranslate(["Buildings", "Global"]);

  const { enqueueSnackbar } = useSnackbar();

  const [deleteBuildingMutation, { loading: deleteBuildingLoading }] =
    useDeleteBuildingMutation({
      client,
    });

  const deleteBuilding = async () => {
    try {
      await deleteBuildingMutation({
        variables: {
          input: {
            buildingId: docId,
          },
        },
      });

      navigate(pathToDocList, { replace: true });
      enqueueSnackbar(
        t("Building deleted", {
          ns: "Buildings",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <MenuButton>
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_QUOTES">
        <MenuItemWithIcon
          icon={<DeleteIcon />}
          onClick={deleteBuilding}
          disabled={deleteBuildingLoading}
        >
          {t("Delete building", {
            ns: "Buildings",
          })}
        </MenuItemWithIcon>
      </RestrictedByOrganisationPermissionWithDebug>
    </MenuButton>
  );
};
