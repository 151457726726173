import { useTranslate } from "@tolgee/react";
import {
  PurchaseOrderTitle_OrderFragment,
  PurchaseOrderTitle_OrderNodeFragment,
} from "./PurchaseOrderTitle.generated";

interface Props {
  order:
    | PurchaseOrderTitle_OrderFragment
    | PurchaseOrderTitle_OrderNodeFragment;
}

export function PurchaseOrderTitle({ order }: Props) {
  const { t } = useTranslate("PurchaseOrders");
  return <>{`${t("PurchaseOrder")}_${order.number}`}</>;
}
