import { useState } from "react";
import { isUndefined } from "lodash";
import { useUpdateEffect } from "react-use";

export const useLatestDefinedValue = <T,>(value: T): T => {
  const [latestValue, setLatestValue] = useState<T>(value);

  useUpdateEffect(() => {
    if (!isUndefined(value)) setLatestValue(value);
  }, [value]);

  return latestValue;
};
