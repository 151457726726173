import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import * as Yup from "yup";
import { SwitchField } from "../../../commons/form-fields/SwitchField";

interface Props {
  name: string;
  disabled?: boolean;
}

export type QuoteHeaderVisibility = {
  isContractorNameVisible: boolean;
  isContractorAddressVisible: boolean;
  isContractorPhoneVisible: boolean;
  isContractorEmailVisible: boolean;
  isContractorContactPersonVisible: boolean;
  isEndClientVisible: boolean;
  isProjectNumberVisible: boolean;
  isClientNumberVisible: boolean;
  isDocumentNumberVisible: boolean;
  isExternalIdentifierVisible: boolean;
  isPublishDateVisible: boolean;
};

export const defaultQuoteHeaderVisibility: QuoteHeaderVisibility = {
  isProjectNumberVisible: true,
  isClientNumberVisible: true,
  isDocumentNumberVisible: true,
  isExternalIdentifierVisible: true,
  isPublishDateVisible: true,
  isContractorNameVisible: true,
  isContractorAddressVisible: true,
  isContractorPhoneVisible: true,
  isContractorEmailVisible: true,
  isContractorContactPersonVisible: true,
  isEndClientVisible: true,
};

export const QuoteHeaderVisibilityField = ({ name, disabled }: Props) => {
  const { t } = useTranslate(["QuoteEditFooter", "Global"]);

  return (
    <Box>
      <Typography>
        {t("Header rows visibility", { ns: "QuoteEditFooter" })}
      </Typography>
      <Stack direction="column" spacing={0}>
        <SwitchField
          label={t("Contractor name is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isContractorNameVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Contractor address is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isContractorAddressVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Contractor's contact person is visible", {
            ns: "QuoteEditFooter",
          })}
          name={`${name}.isContractorContactPersonVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Contractor phone is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isContractorPhoneVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Contractor email is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isContractorEmailVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Project No. is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isProjectNumberVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Client No. is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isClientNumberVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Quote No. is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isDocumentNumberVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("External identifier is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isExternalIdentifierVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("Issue date is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isPublishDateVisible`}
          disabled={disabled}
        />
        <SwitchField
          label={t("End client is visible", { ns: "QuoteEditFooter" })}
          name={`${name}.isEndClientVisible`}
          disabled={disabled}
        />
      </Stack>
    </Box>
  );
};

export const useQuoteHeaderVisibilityValidationSchema = () => {
  const { t } = useTranslate(["QuoteEditFooter", "Global"]);

  const validationSchema = Yup.object().shape({
    isContractorNameVisible: Yup.boolean()
      .label(t("Contractor name is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isContractorAddressVisible: Yup.boolean()
      .label(t("Contractor address is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isContractorContactPersonVisible: Yup.boolean()
      .label(
        t("Contractor's contact person is visible", { ns: "QuoteEditFooter" })
      )
      .required(),
    isContractorPhoneVisible: Yup.boolean()
      .label(t("Contractor phone is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isContractorEmailVisible: Yup.boolean()
      .label(t("Contractor email is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isClientNumberVisible: Yup.boolean()
      .label(t("Client No. is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isDocumentNumberVisible: Yup.boolean()
      .label(t("Quote No. is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isProjectNumberVisible: Yup.boolean()
      .label(t("Project No. is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isExternalIdentifierVisible: Yup.boolean()
      .label(t("External identifier is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isPublishDateVisible: Yup.boolean()
      .label(t("Issue date is visible", { ns: "QuoteEditFooter" }))
      .required(),
    isEndClientVisible: Yup.boolean()
      .label(t("End client is visible", { ns: "QuoteEditFooter" }))
      .required(),
  });

  return validationSchema;
};
