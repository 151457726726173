import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner as LoadingContainer, Modal, Select } from "@msys/ui";
import { noop } from "lodash";
import React from "react";
import {
  RequestGetQuoteModal_QuoteFragment,
  useRequestGetQuoteModalQuery,
} from "./RequestGetQuoteModal.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  projectId: string;
  handleClose: () => void;
  handleComplete: (
    quote: RequestGetQuoteModal_QuoteFragment,
    handleClose: () => void
  ) => Promise<void> | void;
}

export function RequestGetQuoteModal({
  projectId,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Requests", "Global"]);

  const client = useApolloClient();
  const query = useRequestGetQuoteModalQuery({
    client,
    variables: { projectId },
    fetchPolicy: "no-cache",
  });

  const quotes = getDataOrNull(query.data?.projectOutgoingQuotes)?.edges?.map(
    e => e.node
  );

  const [selectedQuote, selectQuote] =
    React.useState<RequestGetQuoteModal_QuoteFragment | null>(null);
  React.useEffect(() => {
    if (quotes?.length === 1) {
      selectQuote(quotes[0]);
    }
  }, [quotes]);

  if (query.loading)
    return (
      <Modal handleClose={noop}>
        <LoadingContainer />
      </Modal>
    );

  return (
    <Modal
      title={t("Select quote", {
        ns: "Requests",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", disabled: query.loading },
        },
        {
          label: t("Select", {
            ns: "Global",
          }),
          handleClick: async () => {
            if (selectedQuote) {
              handleComplete(selectedQuote, handleClose);
            }
          },
          buttonProps: { loading: query.loading },
        },
      ]}
      alwaysVisible
    >
      <Select
        label={t("Quote document", {
          ns: "Requests",
        })}
        options={(quotes || []).map(quote => ({
          label: quote.title,
          value: quote,
        }))}
        value={selectedQuote}
        onChange={value => selectQuote(value)}
      />
    </Modal>
  );
}
