import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import BusinessIcon from "@mui/icons-material/Business";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import { AddressBox } from "../../addresses/AddressBox";
import { ProjectAssignBuildingModal } from "../modals/ProjectAssignBuildingModal";
import { ProjectOverviewBuildingBox_ProjectFragment } from "./ProjectOverviewBuildingBox.generated";

interface Props {
  project: ProjectOverviewBuildingBox_ProjectFragment;
  canEdit: boolean;
}

export const ProjectOverviewBuildingBox = ({ project, canEdit }: Props) => {
  const { t } = useTranslate("AddressBox");

  // TODO-MODEL2022
  const contractee = project.crmOrganisation;

  return (
    <AddressBox
      buildingTitle={project.buildingItem?.title}
      address={project.building?.buildingAddress ?? null}
      title={contractee?.title}
      AddButton={
        canEdit && !project.building?.buildingAddress ? (
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_PROJECT"
            project={project}
            // action={{ name: "assign building for project", project }}
          >
            <ModalOpenButton
              Modal={ProjectAssignBuildingModal}
              modalProps={{
                projectId: project.id,
                // TODO-MODEL2022
                buildingOwningOrganisationId:
                  project?.crmOrganisation?.id ??
                  project.owningSystemOrganisationId,
                refetchQueries: [namedOperations.Query.ProjectOverview],
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                startIcon={<AddIcon />}
              >
                {t("Add address")}
              </Button>
            </ModalOpenButton>
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined
      }
      EditButton={
        canEdit && project.building?.buildingAddress ? (
          <RestrictedByProjectPermissionWithDebug
            permission="MANAGE_PROJECT"
            project={project}
            // action={{ name: "assign building for project", project }}
          >
            <ModalOpenButton
              Modal={ProjectAssignBuildingModal}
              modalProps={{
                projectId: project.id,
                // TODO-MODEL2022
                buildingOwningOrganisationId:
                  project?.crmOrganisation?.id ??
                  project.owningSystemOrganisationId,
                refetchQueries: [namedOperations.Query.ProjectOverview],
              }}
            >
              <IconButton
                size="small"
                color="secondary"
                style={{ flexShrink: 0, flexGrow: 0 }}
              >
                <Tooltip title={t("Change address")}>
                  <EditIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </ModalOpenButton>
          </RestrictedByProjectPermissionWithDebug>
        ) : undefined
      }
      BuildingButton={
        project.buildingItem?.docId ? (
          <Button
            component={Link}
            to={`/buildings/${project.buildingItem?.docId}${
              project.buildingItem?.id
                ? `/edit/items/${project.buildingItem?.id}`
                : ""
            }`}
            variant="outlined"
            color="secondary"
            size="extra-small"
            startIcon={<BusinessIcon />}
          >
            {t("Building")}
          </Button>
        ) : undefined
      }
    />
  );
};
