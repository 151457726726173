import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import SyncIcon from "@mui/icons-material/Sync";
import { useTranslate } from "@tolgee/react";
import { QuoteBulkUpdateProductPricingsModal } from "../modals/QuoteBulkUpdateProductPricingsModal";

interface Props {
  docId: string;
  projectId: string;
  refetchQueries?: string[];
}

export function QuoteBulkUpdateProductPricingsButton({
  docId,
  projectId,
  refetchQueries,
}: Props) {
  const { t } = useTranslate("Quote");

  return (
    <ModalOpenButton
      Modal={QuoteBulkUpdateProductPricingsModal}
      modalProps={{
        docId,
        projectId,
        refetchQueries,
      }}
    >
      <MenuItemWithIcon icon={<SyncIcon />}>
        {t("Update product pricings")}
      </MenuItemWithIcon>
    </ModalOpenButton>
  );
}
