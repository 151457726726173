import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { FilledInput, FilledInputProps, IconButton } from "@mui/material";
import debounce from "lodash.debounce";
import React from "react";
import { useUpdateEffect } from "react-use";
import { mergeRefs } from "../utils";

interface WithSearchTermChangeHandler {
  onChangeSearchTerm: (newSearchTerm: string) => void;
}
interface WithImmediateSearchTermChangeHandler {
  onImmediateChangeSearchTerm?: (newSearchTerm: string) => void;
}

interface SearchInputProps
  extends WithSearchTermChangeHandler,
    FilledInputProps {
  searchTerm?: string;
}

export function SearchInput({
  searchTerm,
  onChangeSearchTerm,
  ...props
}: SearchInputProps) {
  return (
    <BaseSearchInput
      value={searchTerm}
      onChange={e => onChangeSearchTerm(e.target.value)}
      {...props}
    />
  );
}

interface DebouncedSearchInputProps
  extends WithSearchTermChangeHandler,
    WithImmediateSearchTermChangeHandler,
    FilledInputProps {
  debounceMs?: number;
  placeholder: string;
}

export function DebouncedSearchInput({
  inputRef: passedInputRef,
  onChangeSearchTerm,
  onImmediateChangeSearchTerm,
  debounceMs = 500,
  ...props
}: DebouncedSearchInputProps) {
  // we're saving on change callback to reference to exclude from useMemo dependencies
  const onChangeRef = React.useRef(onChangeSearchTerm);
  onChangeRef.current = onChangeSearchTerm;

  const latestValue = React.useRef<string | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleDebouncedChange = React.useMemo(
    () =>
      debounce((newValue: string) => {
        latestValue.current = newValue;
        onChangeRef.current(newValue);
      }, debounceMs),
    [debounceMs]
  );

  // if default value is changed from the outside - we also need to change input value
  React.useEffect(() => {
    if (!inputRef.current) return;
    if (
      latestValue.current === null ||
      latestValue.current !== (props.defaultValue ?? "")
    ) {
      // was changed from the outside - set it in the input
      latestValue.current = null;
      // @ts-ignore
      inputRef.current.value = props.defaultValue ?? "";
    }
  }, [props.defaultValue]);

  return (
    <BaseSearchInput
      inputRef={mergeRefs(inputRef, passedInputRef)}
      onChange={e => {
        onImmediateChangeSearchTerm?.(e.target.value);
        handleDebouncedChange(e.target.value);
      }}
      {...props}
    />
  );
}

const defaultAdornment = <SearchIcon fontSize="small" color="secondary" />;

const triggerChangeEvent = (input: HTMLInputElement, value: string = "") => {
  // @ts-ignore
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set;
  nativeInputValueSetter?.call(input, value);
  input.dispatchEvent(new Event("change", { bubbles: true }));
};

function BaseSearchInput({
  inputRef: passedInputRef,
  onChange,
  endAdornment = defaultAdornment,
  placeholder,
  inputProps,
  ...props
}: FilledInputProps) {
  const [hasValue, setHasValue] = React.useState<boolean>(
    Boolean(props.value ?? props.defaultValue)
  );
  useUpdateEffect(() => {
    setHasValue(Boolean(props.value ?? props.defaultValue));
  }, [props.value, props.defaultValue]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <FilledInput
      inputRef={mergeRefs(inputRef, passedInputRef)}
      size="extra-small"
      type="search"
      placeholder={placeholder && `${placeholder}…`}
      // handled by type="search" !
      /*endAdornment={
        hasValue ? (
          <IconButton
            size="small"
            color="secondary"
            sx={{ width: "20px", height: "20px" }}
            onClick={() => {
              if (inputRef.current) triggerChangeEvent(inputRef.current, "");
            }}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        ) : (
          endAdornment
        )
      }*/
      endAdornment={hasValue ? undefined : endAdornment}
      onChange={(...args) => {
        setHasValue(Boolean(args[0].target.value));
        onChange?.(...args);
      }}
      inputProps={{ enterKeyHint: "search", ...inputProps }}
      {...props}
    />
  );
}
