import React, { useMemo } from "react";
import {
  Agreement,
  AskWhom,
  PermissionName,
} from "../../../clients/graphqlTypes";
import { ConfirmModalProps } from "../../commons/modals/ConfirmProcess";
import { REQUIREMENT_ITEM_TYPES } from "../../constants";
import { isItemVisibleToOtherSide } from "../../trees-virtual/helpers";
import { VirtualItem } from "../../trees-virtual/types";
import { BareTreeItem } from "../../trees/BareTreeItem";
import { TreeDataContainer } from "../../trees/components/TreeDataContainer";
import { TreeRightIcons } from "../../trees/components/TreeRightIcons";
import { InputComponentProps, ItemComponentProps } from "../../trees/types";
import { assertNever } from "../../utils";
import { CreateItemInput } from "../doc-items/CreateItemInput";
import { DecisionModalButton } from "../doc-items/buttons/DecisionModalButton";
import { DecisionContingencyCheckbox } from "../doc-items/fields/DecisionContingencyCheckbox";
import { DecisionOptionRadioOrCheckbox } from "../doc-items/fields/DecisionOptionRadioOrCheckbox";
import { hasAnyQuestions } from "../doc-items/helpers";
import { DocItemContextMenuItems } from "../doc-items/menus/DocItemContextMenuItems";
import {
  RequirementTreeItem_Data123Fragment,
  RequirementTreeItem_TreeItemFragment,
} from "./requirement-trees.generated";

export function createTreeItemInput<
  Item extends VirtualItem,
  ChildItem extends VirtualItem,
>({
  requirementId,
  createItem,
  createItemByType,
  createItemFromTemplate,
  createItemsFromTemplates,
  createItemsWithProducts,
  pasteItem,
  isAllowedToPasteItem,
}: {
  requirementId: string;
} & Pick<
  React.ComponentProps<typeof CreateItemInput>,
  | "createItem"
  | "createItemByType"
  | "createItemFromTemplate"
  | "createItemsFromTemplates"
  | "createItemsWithProducts"
  | "pasteItem"
  | "isAllowedToPasteItem"
>) {
  return (itemProps: InputComponentProps<Item, ChildItem>) => (
    <CreateItemInput
      doc={{
        id: requirementId,
        docType: "REQUIREMENT",
        templateIsDraft: false,
      }}
      documentItemTypes={REQUIREMENT_ITEM_TYPES}
      createItem={createItem}
      createItemByType={createItemByType}
      createItemFromTemplate={createItemFromTemplate}
      createItemsFromTemplates={createItemsFromTemplates}
      createItemsWithProducts={createItemsWithProducts}
      pasteItem={pasteItem}
      isAllowedToPasteItem={isAllowedToPasteItem}
      {...itemProps}
    />
  );
}

export const createTreeItem =
  <T extends RequirementTreeItem_TreeItemFragment>({
    requirementId,
    pathToDocPage,
    projectId,
    document,
    docAgreement,
    startConfirmProcess,
    navigateToItem,
    expandedItemIds,
    setItemExpanded,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
  }: {
    pathToDocPage: string;
    requirementId: string;
    projectId: string;
    document: {
      viewerPermissions: PermissionName[];
    };
    docAgreement: Agreement;
    startConfirmProcess: (
      props: ConfirmModalProps
    ) => Promise<boolean | undefined>;
    navigateToItem: (id: string) => void;
    expandedItemIds: string[] | undefined;
    setItemExpanded: (id: string, expanded: boolean) => void;
    pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
    isAllowedToPasteItem: (parentItem: T) => boolean;
    viewerDecisionRole: AskWhom | undefined;
  }) =>
  (itemProps: ItemComponentProps<T>) => {
    const [Data123Left, Data123Right] = useMemo(
      () => getData123(itemProps.item, itemProps.isPriceHidden),
      [itemProps.item, itemProps.isPriceHidden]
    );

    const IconRightButtons = useMemo(
      () => (
        <>
          {hasAnyQuestions(
            itemProps.item,
            "REQUIREMENT",
            viewerDecisionRole
          ) && (
            <DecisionModalButton
              projectId={projectId}
              docType="REQUIREMENT"
              docId={requirementId}
              itemId={itemProps.item.id}
              canFinalize={true}
              viewerDecisionRole={viewerDecisionRole}
              expandedItemIds={expandedItemIds}
              type="icon"
            />
          )}
          {itemProps.item.decisionIsContingentItem &&
            !itemProps.item.isItemEliminated && (
              <DecisionContingencyCheckbox
                projectId={projectId}
                docId={requirementId}
                itemId={itemProps.item.id}
                item={itemProps.item}
              />
            )}
          {itemProps.parentItem?.type === "decision" &&
            !itemProps.item.decisionOptionElimination &&
            !itemProps.item.isItemEliminated && (
              <DecisionOptionRadioOrCheckbox
                projectId={projectId}
                docId={requirementId}
                item={itemProps.item}
                expandedItemIds={expandedItemIds}
              />
            )}
        </>
      ),
      [itemProps.item, itemProps.parentItem]
    );

    const ContextMenu = useMemo(
      () => (
        <DocItemContextMenuItems
          pathToDocPage={pathToDocPage}
          docId={requirementId}
          docType="REQUIREMENT"
          projectId={projectId}
          item={itemProps.item}
          startConfirmProcess={startConfirmProcess}
          expandedItemIds={expandedItemIds}
          pasteItem={isAllowedToPasteItem(itemProps.item) ? pasteItem : null}
        />
      ),
      [itemProps.item]
    );

    const RightIcons = useMemo(
      () => (
        <TreeRightIcons
          document={document}
          item={itemProps.item}
          isVisibleToOtherSide={isItemVisibleToOtherSide(
            itemProps.item,
            itemProps.allItems
          )}
        />
      ),
      [itemProps.item, itemProps.allItems]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        IconRightButtons={IconRightButtons}
        ContextMenu={ContextMenu}
        RightIcons={RightIcons}
        showPath={itemProps.parentItem?.type !== "decision"}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        collapseButton={itemProps.collapseButton}
        subcontractPath={null}
        onClick={() => {
          setItemExpanded(itemProps.item.id, true);
        }}
        to={`${pathToDocPage}/items/${itemProps.item.id}`}
      />
    );
  };

export const getData123 = (
  item: RequirementTreeItem_Data123Fragment,
  isPriceHidden: boolean
): [JSX.Element | null, JSX.Element | null] => {
  if (isPriceHidden) {
    return [null, null];
  }

  switch (item.type) {
    case "paid": {
      // TODO this is causing issues for deleted items; for now just show "null" as value instead of breaking
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      // if (calculateForQuote.pricePerUnit === 0) {
      //   return [null, null];
      // }

      return [
        <TreeDataContainer key="data123-left">
          <div>{calculateForQuote.quantity}x</div>
          {/*{calculateForQuote.timePerUnit > 0 && (*/}
          {/*  <ShowBoxElement*/}
          {/*    boxElement={{*/}
          {/*      name: "project-requirements-detail-items-timeBudget",*/}
          {/*      project,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div>*/}
          {/*      <FormattedTime value={calculateForQuote.timePerUnit} />*/}
          {/*    </div>*/}
          {/*  </ShowBoxElement>*/}
          {/*)}*/}

          {/*<ShowBoxElement*/}
          {/*  boxElement={{*/}
          {/*    name: "project-requirements-detail-items-sellPrice",*/}
          {/*    project,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div>*/}
          {/*    <FormattedPrice value={calculateForQuote.pricePerUnit} />*/}
          {/*  </div>*/}
          {/*</ShowBoxElement>*/}

          {/*<ShowBoxElement*/}
          {/*  boxElement={{*/}
          {/*    name: "project-requirements-detail-items-subtotal",*/}
          {/*    project,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div style={{ fontWeight: "bold" }}>*/}
          {/*    <FormattedPrice value={calculateForQuote.priceSubTotal} />*/}
          {/*  </div>*/}
          {/*</ShowBoxElement>*/}
        </TreeDataContainer>,
        null,
      ];
    }
    case "section": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        // calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }

      return [
        <TreeDataContainer key="data123-left">
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {/*{calculateForQuote.timePerUnit > 0 && (*/}
              {/*  <ShowBoxElement*/}
              {/*    boxElement={{*/}
              {/*      name: "project-requirements-detail-items-timeBudget",*/}
              {/*      project,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div>*/}
              {/*      <FormattedTime value={calculateForQuote.timePerUnit} />*/}
              {/*    </div>*/}
              {/*  </ShowBoxElement>*/}
              {/*)}*/}
              {/*<ShowBoxElement*/}
              {/*  boxElement={{*/}
              {/*    name: "project-requirements-detail-items-sellPrice",*/}
              {/*    project,*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div>*/}
              {/*    <FormattedPrice value={calculateForQuote.pricePerUnit} />*/}
              {/*  </div>*/}
              {/*</ShowBoxElement>*/}
            </>
          )}
          {/*<ShowBoxElement*/}
          {/*  boxElement={{*/}
          {/*    name: "project-requirements-detail-items-sectionTotal",*/}
          {/*    project,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div style={{ fontWeight: "bold" }}>*/}
          {/*    <FormattedTime value={calculateForQuote.timeTotal} />*/}
          {/*  </div>*/}
          {/*  <div style={{ fontWeight: "bold" }}>*/}
          {/*    <FormattedPrice value={calculateForQuote.priceSubTotal} />*/}
          {/*  </div>*/}
          {/*</ShowBoxElement>*/}
        </TreeDataContainer>,
        null,
      ];
    }
    case "decision": {
      const calculateForQuote = item.proposedCalculation;

      if (!calculateForQuote) return [null, null];

      if (
        // calculateForQuote.timePerUnit === 0 &&
        calculateForQuote.pricePerUnit === 0 &&
        calculateForQuote.quantity === 1
      ) {
        return [null, null];
      }

      return [
        <TreeDataContainer key="data123-left">
          {calculateForQuote.quantity !== 1 && (
            <>
              <div>{calculateForQuote.quantity}x</div>
              {/*{calculateForQuote.timePerUnit > 0 && (*/}
              {/*  <ShowBoxElement*/}
              {/*    boxElement={{*/}
              {/*      name: "project-requirements-detail-items-timeBudget",*/}
              {/*      project,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div>*/}
              {/*      <FormattedTime value={calculateForQuote.timePerUnit} />*/}
              {/*    </div>*/}
              {/*  </ShowBoxElement>*/}
              {/*)}*/}
              {/*<ShowBoxElement*/}
              {/*  boxElement={{*/}
              {/*    name: "project-requirements-detail-items-sellPrice",*/}
              {/*    project,*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div>*/}
              {/*    <FormattedPrice value={calculateForQuote.pricePerUnit} />*/}
              {/*  </div>*/}
              {/*</ShowBoxElement>*/}
            </>
          )}
          {/*<ShowBoxElement*/}
          {/*  boxElement={{*/}
          {/*    name: "project-requirements-detail-items-sectionTotal",*/}
          {/*    project,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div style={{ fontWeight: "bold" }}>*/}
          {/*    <FormattedTime value={calculateForQuote.timeTotal} />*/}
          {/*  </div>*/}
          {/*  <div style={{ fontWeight: "bold" }}>*/}
          {/*    <FormattedPrice value={calculateForQuote.priceSubTotal} />*/}
          {/*  </div>*/}
          {/*</ShowBoxElement>*/}
        </TreeDataContainer>,
        null,
      ];
    }
    case "unpaid":
    case "defect": {
      return [null, null];
    }
    default:
      throw assertNever(item.type);
  }
};
