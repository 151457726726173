import React from "react";
import { Link } from "react-router-dom";

import { gql } from "@apollo/client";
import { ListItem, Typography, useTheme } from "@mui/material";

import { CardContainer, StatusLabel } from "@msys/ui";
import { Stack } from "../../../commons/layout/Stack";
import { RelatedQuoteItemsFragment } from "./RelatedQuoteItemsBox.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  relatedQuoteItems: RelatedQuoteItemsFragment["relatedQuoteItems"];
  getItemPath: (docId: string, itemId: string) => string;
}

export const RelatedQuoteItemsBox = React.forwardRef<HTMLDivElement, Props>(
  ({ relatedQuoteItems, getItemPath }, ref) => {
    const { t } = useTranslate("Materials");

    return (
      <CardContainer
        ref={ref}
        title={t("Related quote items")}
        isExpandable
        itemCount={relatedQuoteItems.length}
      >
        {relatedQuoteItems.map((relatedQuoteItem, index) => {
          return (
            <ListItem
              key={relatedQuoteItem.quoteItem.id}
              button
              component={Link}
              to={getItemPath(
                relatedQuoteItem.quote.id,
                relatedQuoteItem.quoteItem.id
              )}
              divider={index > 0}
              style={{ display: "block" }}
            >
              <Stack justifyContent="space-between">
                <Stack>
                  <Typography>
                    {relatedQuoteItem.quoteItem.pathForPdf}
                  </Typography>
                  <Typography>{relatedQuoteItem.quoteItem.title}</Typography>
                </Stack>
                <QuoteItemStatus relatedQuoteItem={relatedQuoteItem} />
              </Stack>
            </ListItem>
          );
        })}
      </CardContainer>
    );
  }
);

function QuoteItemStatus({
  relatedQuoteItem,
}: {
  relatedQuoteItem: RelatedQuoteItemsFragment["relatedQuoteItems"][0];
}) {
  const { t } = useTranslate("Materials");
  const theme = useTheme();

  if (
    !relatedQuoteItem.quote.isPublished ||
    relatedQuoteItem.quoteItem.binding === "draft"
  ) {
    return (
      <StatusLabel color={theme.palette.grey[500]}>{t("Draft")}</StatusLabel>
    );
  }

  if (
    relatedQuoteItem.quote.agreement === "NONE" ||
    relatedQuoteItem.quoteItem.isOptional
  ) {
    return (
      <StatusLabel color={theme.palette.text.secondary}>
        {t("Pending item")}
      </StatusLabel>
    );
  }

  if (relatedQuoteItem.quoteItem.needsAgreement) {
    return (
      <StatusLabel color={theme.palette.warning.main}>
        {t("Pending change")}
      </StatusLabel>
    );
  }

  return null;
}
