/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationProjectSettingsBox_SettingsFragment = { __typename: 'OrganisationSetting', id: string, projectCouldBeUnassigned: boolean };

export type OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationVariables = Types.Exact<{
  input: Types.OrganisationSettingsUpdateInput;
}>;


export type OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation = { updateOrganisationSettings: { __typename: 'OrganisationSettingsUpdateResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, projectCouldBeUnassigned: boolean } } };

export const OrganisationProjectSettingsBox_SettingsFragmentDoc = gql`
    fragment OrganisationProjectSettingsBox_Settings on OrganisationSetting {
  id
  projectCouldBeUnassigned
}
    `;
export const OrganisationProjectSettingsForm_ModifyOrganisationSettingsDocument = gql`
    mutation OrganisationProjectSettingsForm_ModifyOrganisationSettings($input: OrganisationSettingsUpdateInput!) {
  updateOrganisationSettings(input: $input) {
    organisationSettings {
      id
      ...OrganisationProjectSettingsBox_Settings
    }
  }
}
    ${OrganisationProjectSettingsBox_SettingsFragmentDoc}`;
export type OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationFn = Apollo.MutationFunction<OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationVariables>;

/**
 * __useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation__
 *
 * To run a mutation, you first call `useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationProjectSettingsFormModifyOrganisationSettingsMutation, { data, loading, error }] = useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationVariables>(OrganisationProjectSettingsForm_ModifyOrganisationSettingsDocument, options);
      }
export type OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationHookResult = ReturnType<typeof useOrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation>;
export type OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationResult = Apollo.MutationResult<OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation>;
export type OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationOptions = Apollo.BaseMutationOptions<OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutation, OrganisationProjectSettingsForm_ModifyOrganisationSettingsMutationVariables>;