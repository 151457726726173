import { CardContainer, FormattedTime } from "@msys/ui";
import { Typography, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ItemCalculationFragment } from "../doc-items/Fragments.generated";

interface Props {
  calculated: ItemCalculationFragment;
}

export function QuoteTimeSummaryBox({ calculated }: Props) {
  const { t } = useTranslate("QuotePriceSummary");
  return (
    <CardContainer
      title={t("Working hours")}
      isExpandable
      CollapsedHint={
        <Typography variant="h3" component="div">
          <FormattedTime
            value={calculated?.timeTotal ?? 0}
            notSetText="0:00h"
          />
        </Typography>
      }
    >
      <Stack p={1} spacing={1} direction="column" width="100%">
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h3" component="div">
            {t("Total working hours")}
          </Typography>
          <Typography
            variant="h3"
            component="div"
            style={{ textDecoration: "underline" }}
          >
            <FormattedTime
              value={calculated?.timeTotal ?? 0}
              notSetText="0:00h"
            />
          </Typography>
        </Stack>
      </Stack>
    </CardContainer>
  );
}
