import { gql, useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { DialogContentText, Typography } from "@mui/material";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isUndefined, omit, omitBy, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React, { FC, useMemo } from "react";
import * as Yup from "yup";
import { DEFAULT_URL } from "../../../commons/Avatar";
import { Stack } from "../../../commons/layout/Stack";
import {
  useAddOrganisationProfileMutation,
  useAddOrganisationPublicProfileModalQuery,
} from "./AddOrganisationPublicProfileModal.generated";
import { useTranslate } from "@tolgee/react";

const convertToSlug = (title: string): string => {
  let str = title.replace(/^\s+|\s+$/g, "").toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaaaeeeeiiiioooouuuunc------";

  for (let i = 0; i < from.length; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  return str
    .replace(/[^a-z0-9 \-_]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes
};

const convertToRawState = (text: string): RawDraftContentState => {
  return convertToRaw(ContentState.createFromText(text));
};

interface FormValues {
  slug: string;
}

interface Props {
  title?: string;
  handleClose: () => void;
  handleComplete?: (slug?: string) => void;
}

export const AddOrganisationPublicProfileModal: FC<Props> = ({
  title,
  handleClose,
  handleComplete,
}) => {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);

  const { enqueueSnackbar } = useSnackbar();
  const formId = useMemo(() => uniqueId(), []);

  const client = useApolloClient();
  const query = useAddOrganisationPublicProfileModalQuery({
    client,
  });
  const [addProfile, { loading: addProfileLoading }] =
    useAddOrganisationProfileMutation({
      client,
    });

  const organisation = query.data?.viewer?.organisation;

  const initialValues: FormValues = {
    slug: convertToSlug(organisation?.title ?? ""),
  };

  const onSubmit = async (values: FormValues) => {
    const input = omitBy(
      {
        about:
          organisation?.description &&
          typeof organisation?.description === "string"
            ? convertToRawState(organisation?.description)
            : undefined,
        contactWebsite: organisation?.website ?? undefined,
        contactEmail: organisation?.email ?? undefined,
        contactAddress: organisation?.branchAddress
          ? omit(organisation.branchAddress, "__typename", "id")
          : undefined,
        contactPhones:
          organisation?.phones
            .filter(p => p.number.trim())
            .map(p => omit(p, "__typename", "id")) ?? undefined,
        skillset:
          organisation?.skillset && organisation.skillset.length > 0
            ? organisation.skillset
            : undefined,
        logo:
          organisation?.logo && organisation?.logo?.url !== DEFAULT_URL
            ? omit(organisation.logo, "__typename", "id")
            : undefined,
      },
      isUndefined
    );
    try {
      const { data } = await addProfile({
        variables: { input: { ...input, slug: values.slug } },
      });
      const slug = data?.addOrganisationProfile.profile.slug;
      enqueueSnackbar(
        t("Public profile created", {
          ns: "OrganisationProfile",
        })
      );
      handleComplete?.(slug);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        slug: Yup.string()
          .label(
            t("Profile name", {
              ns: "OrganisationProfile",
            })
          )
          .required()
          .min(3)
          .matches(
            /^[a-z0-9-_]+$/,
            t("Only alpha-numeric, dash and underscore are allowed", {
              ns: "OrganisationProfile",
            })
          )
          .matches(
            /[a-z0-9]/,
            t("Should have at least one letter or number", {
              ns: "OrganisationProfile",
            })
          ),
      }),
    [t]
  );

  return !organisation ? null : (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Modal
        title={
          title ??
          t("Create public profile", {
            ns: "OrganisationProfile",
          })
        }
        dialogProps={{ maxWidth: "xs" }}
        actionButtons={[
          {
            label: t("Cancel", {
              ns: "Global",
            }),
            handleClick: handleClose,
            buttonProps: { variant: "text" },
          },
          {
            label: t("Create", {
              ns: "Global",
            }),
            buttonProps: {
              type: "submit",
              form: formId,
              disabled: addProfileLoading,
            },
          },
        ]}
        handleClose={handleClose}
        isLoading={query.loading}
      >
        <DialogContentText>
          {t("Customize your profile URL.", {
            ns: "OrganisationProfile",
          })}
        </DialogContentText>

        <Form id={formId}>
          <Stack width="100%" alignItems="baseline">
            <Typography>{`${window?.location.hostname}/org/`}</Typography>
            <Field
              component={TextField}
              name="slug"
              label={t("Profile name", {
                ns: "OrganisationProfile",
              })}
              required
              disabled={false}
            />
          </Stack>
        </Form>
      </Modal>
    </Formik>
  );
};
