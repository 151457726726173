import { ellipsisStyle, ModalOpenButton } from "@msys/ui";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { getPersonLabel } from "../users/helpers";
import { UserAvatar } from "../users/UserAvatar";
import { UserAvatarFragment } from "../users/UserAvatar.generated";
import { ProjectAssigneeEditModal } from "./ProjectAssigneeEditModal";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Avatar, getAvatarSize } from "../../commons/Avatar";

interface Props {
  assignee: {
    id: string;
    user: {
      id: string;
      firstname: string;
      familyname: string;
    } & UserAvatarFragment;
  } | null;
  projectId: string;
  projectTitle?: string;
  refetchQueries?: string[];
  readOnly?: boolean;
  type: "button" | "icon";
}

export const ProjectAssigneeEditButton = ({
  assignee,
  projectId,
  projectTitle,
  refetchQueries,
  readOnly,
  type,
}: Props) => {
  const { t } = useTranslate(["Global", "Projects", "ProjectMembers"]);

  switch (type) {
    case "button":
      return (
        <ModalOpenButton
          Modal={ProjectAssigneeEditModal}
          modalProps={{
            projectId,
            projectTitle,
            refetchQueries,
          }}
          disabled={readOnly}
        >
          <Button
            variant="text"
            color="primary"
            size="extra-small"
            startIcon={
              assignee ? (
                <UserAvatar
                  size="xs"
                  userAvatar={assignee.user}
                  sx={{ pointerEvents: "none" }}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: "grey.300",
                    color: "grey.600",
                    pointerEvents: "none",
                  }}
                  size="xs"
                  type="circle"
                  icon={<PersonOutlineIcon />}
                />
              )
            }
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
            sx={{ textTransform: "unset", ...ellipsisStyle }}
          >
            <span style={ellipsisStyle}>
              {assignee
                ? getPersonLabel(assignee.user, false)
                : t("Unassigned", { ns: "Projects" })}
            </span>
          </Button>
        </ModalOpenButton>
      );
    case "icon":
      return (
        <Tooltip
          title={
            assignee
              ? getPersonLabel(assignee.user, false)
              : t("Unassigned", { ns: "Projects" })
          }
          arrow
        >
          <Box display="flex" flexGrow={0} flexShrink={0}>
            <ModalOpenButton
              Modal={ProjectAssigneeEditModal}
              modalProps={{
                projectId,
                projectTitle,
                refetchQueries,
              }}
              disabled={readOnly}
            >
              <IconButton
                size="small"
                sx={{
                  width: `${getAvatarSize("xs")}px`,
                  height: `${getAvatarSize("xs")}px`,
                }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {assignee ? (
                  <UserAvatar
                    size="xs"
                    userAvatar={assignee.user}
                    sx={{ pointerEvents: "none" }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: "grey.300",
                      color: "grey.600",
                      pointerEvents: "none",
                    }}
                    size="xs"
                    type="circle"
                    icon={<PersonOutlineIcon />}
                  />
                )}
              </IconButton>
            </ModalOpenButton>
          </Box>
        </Tooltip>
      );
    default:
      throw new Error(`Wrong type: ${type}`);
  }
};
