import { gql } from "@apollo/client";
import {
  getFormattedPercentage,
  getFormattedPrice,
  underlineDoubleStyle,
} from "@msys/ui";
import { Divider, Paper, Typography } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import React from "react";
import { Stack } from "../../../commons/layout/Stack";
import { InvoiceType } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { assertNever } from "../../../utils";
import { InvoiceCalculationFragment } from "../Fragments.generated";
import BigNumber from "bignumber.js";

interface Props {
  calculation: InvoiceCalculationFragment;
  doc: {
    discountPercentage: number;
    discountReason: string;
    isDiscountPercentageVisible: boolean;
  };
  invoice: {
    showLaborCostSeparately: boolean;
    type: InvoiceType;
  };
}

export const InvoiceSum = ({ calculation, doc, invoice }: Props) => {
  switch (invoice.type) {
    case "partial_invoice":
      return (
        <InvoicePartialSum
          doc={doc}
          invoice={invoice}
          calculation={calculation}
        />
      );
    case "down_payment_invoice":
    case "advance_payment_invoice":
      return (
        <InvoiceDownAndAdvancedSum
          doc={doc}
          invoice={invoice}
          calculation={calculation}
        />
      );
    case "final_invoice":
      return (
        <InvoiceFinalSum
          doc={doc}
          invoice={invoice}
          calculation={calculation}
        />
      );
    default:
      assertNever(invoice.type);
  }
};

export const InvoicePartialSum = ({ calculation, doc, invoice }: Props) => {
  return (
    <Paper>
      <Stack flexDirection="column" p={2}>
        <SubtotalWithNetPrice doc={doc} calculation={calculation} />
        <Divider />
        <Vat calculation={calculation} />
        <Divider />
        <Total calculation={calculation} invoice={invoice} />
      </Stack>
    </Paper>
  );
};

export const InvoiceDownAndAdvancedSum = ({
  calculation,
  doc,
  invoice,
}: Props) => {
  return (
    <Paper>
      <Stack flexDirection="column" p={2}>
        <NetPrice calculation={calculation} />
        <Divider />
        <Vat calculation={calculation} />
        <Divider />
        <Total calculation={calculation} invoice={invoice} />
      </Stack>
    </Paper>
  );
};

export const InvoiceFinalSum = ({ calculation, doc, invoice }: Props) => {
  return (
    <Paper>
      <Stack flexDirection="column" p={2}>
        <SubtotalWithNetPrice doc={doc} calculation={calculation} />
      </Stack>
    </Paper>
  );
};

// for down and advanced invoices we don't need to show subtotal, only discounted net price
function NetPrice({
  calculation,
}: {
  calculation: InvoiceCalculationFragment;
}) {
  const { t } = useTranslate(["Invoices"]);
  const language = useTolgee(["language"]).getLanguage()!;
  return (
    <Typography variant="h3" component="div">
      <Stack justifyContent="space-between">
        <span>
          {t("Subtotal", {
            ns: "Invoices",
          })}
        </span>
        <span style={{ textDecoration: "underline" }}>
          {getFormattedPrice(calculation.priceNetTotal, language)}
        </span>
      </Stack>
    </Typography>
  );
}

// default one for partial & final invoices
function SubtotalWithNetPrice({
  doc,
  calculation,
}: {
  doc: {
    discountPercentage: number;
    discountReason: string;
    isDiscountPercentageVisible: boolean;
  };
  calculation: InvoiceCalculationFragment;
}) {
  const { t } = useTranslate(["Invoices"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const showDiscount = doc.discountPercentage !== 0;

  return (
    <>
      <Typography variant="h3" component="div">
        <Stack justifyContent="space-between">
          <span>
            {t("Subtotal", {
              ns: "Invoices",
            })}
          </span>
          <span style={{ textDecoration: "underline" }}>
            {getFormattedPrice(calculation.priceSubTotal, language)}
          </span>
        </Stack>
      </Typography>
      {showDiscount && (
        <Stack justifyContent="space-between">
          <div>
            {doc.discountReason ||
              t("Discount", {
                ns: "Invoices",
              })}
            {doc.isDiscountPercentageVisible && (
              <> ({getFormattedPercentage(doc.discountPercentage, language)})</>
            )}
          </div>
          <div>
            −{" "}
            {getFormattedPrice(
              calculation.priceSubTotal - calculation.priceNetTotal,
              language
            )}
          </div>
        </Stack>
      )}
      {showDiscount && (
        <Typography variant="h3" component="div">
          <Stack justifyContent="space-between">
            <div>
              {t("Net price", {
                ns: "Invoices",
              })}
            </div>
            <div>{getFormattedPrice(calculation.priceNetTotal, language)}</div>
          </Stack>
        </Typography>
      )}
    </>
  );
}

function Vat({ calculation }: { calculation: InvoiceCalculationFragment }) {
  const { t } = useTranslate(["Invoices"]);
  const language = useTolgee(["language"]).getLanguage()!;
  return (
    <>
      <Stack justifyContent="space-between">
        <div>
          {t("VAT", {
            ns: "Invoices",
          })}{" "}
          ({getFormattedPercentage(calculation.vatRate, language)})
        </div>
        <div>+ {getFormattedPrice(calculation.priceVatTotal, language)}</div>
      </Stack>
    </>
  );
}

function Total({
  calculation,
  invoice,
}: {
  calculation: InvoiceCalculationFragment;
  invoice: {
    showLaborCostSeparately: boolean;
  };
}) {
  const { t } = useTranslate(["Invoices"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const workPriceTotal = React.useMemo(() => {
    return new BigNumber(calculation.workPriceDiscountedSubTotal)
      .multipliedBy(new BigNumber(1).plus(calculation.vatRate))
      .decimalPlaces(2)
      .toNumber();
  }, [calculation.workPriceDiscountedSubTotal, calculation.vatRate]);

  return (
    <Typography variant="h1" component="div">
      <Stack justifyContent="space-between">
        <div>
          <div>
            {t("Total due", {
              ns: "Invoices",
            })}
          </div>
          {invoice.showLaborCostSeparately && (
            <Typography variant="caption" component="div">
              {t(
                "The gross amount includes {grossPrice} labour costs ({netPrice} net plus {vatPercent} VAT)",
                {
                  ns: "Invoices",
                  grossPrice: getFormattedPrice(workPriceTotal, language) ?? "",
                  netPrice:
                    getFormattedPrice(
                      calculation.workPriceSubTotal,
                      language
                    ) ?? "",
                  vatPercent: getFormattedPercentage(
                    calculation.vatRate,
                    language
                  ),
                }
              )}
            </Typography>
          )}
        </div>
        <div style={underlineDoubleStyle}>
          {getFormattedPrice(calculation.priceTotal, language)}
        </div>
      </Stack>
    </Typography>
  );
}
