import { gql, MutationFunctionOptions, useApolloClient } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import React, { ChangeEvent } from "react";
import { color } from "../../../../common/MuiThemeProvider";
import {
  Exact,
  SetUnnestedTemplateItemDecisionContingentItemPreselectionInput,
} from "../../../../clients/graphqlTypes";
import {
  SetUnnestedTemplateItemDecisionContingentItemPreselectionMutation,
  SetUnnestedTemplateItemDecisionContingentItemPreselectionMutationFn,
  useSetUnnestedTemplateItemDecisionContingentItemPreselectionMutation,
} from "../../templates/quote/TemplateQuotes.generated";
import { DecisionContingencyCheckbox_ItemFragment } from "./DecisionContingencyCheckbox.generated";

interface Props {
  itemId: string;
  docId: string;
  item: DecisionContingencyCheckbox_ItemFragment;
  disabled?: boolean;
  modifyItemContingencyPreselection?:
    | SetUnnestedTemplateItemDecisionContingentItemPreselectionMutationFn
    | ((
        options?:
          | MutationFunctionOptions<
              SetUnnestedTemplateItemDecisionContingentItemPreselectionMutation,
              Exact<{
                input: SetUnnestedTemplateItemDecisionContingentItemPreselectionInput;
              }>
            >
          | undefined
      ) => Promise<void>);
}

export const UnnestedTemplateContingencyCheckbox = ({
  docId,
  itemId,
  item,
  disabled = false,
  modifyItemContingencyPreselection: modifyItemContingencyPreselectionProp,
}: Props) => {
  const client = useApolloClient();

  const [
    modifyItemContingencyPreselection,
    { loading: modifyItemContingencyPreselectionLoading },
  ] = useSetUnnestedTemplateItemDecisionContingentItemPreselectionMutation({
    client,
  });

  const { decisionContingentItemPreselection } = item;

  const checkboxDisabled = disabled || modifyItemContingencyPreselectionLoading;

  return (
    <Checkbox
      disabled={checkboxDisabled}
      checked={Boolean(decisionContingentItemPreselection)}
      color={checkboxDisabled ? undefined : "default"}
      style={checkboxDisabled ? undefined : { color: color.default }}
      indeterminate={decisionContingentItemPreselection === null}
      onClick={async (event: ChangeEvent<any>) => {
        event.stopPropagation();
        let newPreselection: boolean | null = false;
        if (decisionContingentItemPreselection === null) newPreselection = true;
        if (decisionContingentItemPreselection === false)
          newPreselection = true;
        await (
          modifyItemContingencyPreselectionProp ||
          modifyItemContingencyPreselection
        )({
          variables: {
            input: {
              templateDocId: docId,
              unnestedItemId: itemId,
              decisionContingentItemPreselection: newPreselection,
            },
          },
        });
      }}
      size="small"
    />
  );
};
