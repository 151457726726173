import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DebouncedSearchInput,
  LoadingSpinner as LoadingIndicator,
  Modal,
} from "@msys/ui";
import { Box, List, ListItemButton, Typography, useTheme } from "@mui/material";
import { uniqueId } from "lodash";
import React from "react";
import { Stack } from "../../../commons/layout/Stack";
// import { Stack } from "../../../commons/layout/Stack";
import {
  PickBrandLineModalSearchBrandLines_BrandLineFragment,
  usePickBrandLineModalSearchBrandLinesQuery,
} from "./PickBrandLineModal.generated";
import { useTranslate } from "@tolgee/react";

export interface BrandLine {
  id: string;
  title: string;
  brandId: string;
}

interface Props {
  title?: string;
  brandLine?: BrandLine | undefined | null;
  brandId?: string | undefined | null;
  handleClose: () => void;
  handleComplete?: (brandLine: BrandLine) => void;
}

export function PickBrandLineModal({
  title,
  brandLine,
  brandId,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["ProductOverview", "Global", "QuoteCreate"]);
  const client = useApolloClient();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedBrandLine, setSelectedBrandLine] = React.useState(
    brandLine ?? null
  );

  const query = usePickBrandLineModalSearchBrandLinesQuery({
    client,
    variables: {
      searchTerm,
      filter: brandLine
        ? { brandIds: [brandLine.brandId] }
        : brandId
          ? { brandIds: [brandId] }
          : undefined,
    },
  });

  const formId = React.useMemo(() => uniqueId(), []);

  const brandLines = getDataOrNull(query.data?.pimSearchBrandLines)?.brandLines;

  return (
    <Modal
      title={
        title ??
        t("Pick brandline", {
          ns: "ProductOverview",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          buttonProps: {
            disabled:
              brandLine?.id === selectedBrandLine?.id || !selectedBrandLine,
            form: formId,
            type: "submit",
          },
        },
      ]}
    >
      <form
        id={formId}
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();
          if (selectedBrandLine) {
            handleComplete?.(selectedBrandLine);
          }
          handleClose();
        }}
        style={{ maxHeight: "100%" }}
      >
        <Stack flexDirection="column">
          <DebouncedSearchInput
            placeholder={t("Search", {
              ns: "Global",
            })}
            defaultValue={searchTerm}
            onChangeSearchTerm={setSearchTerm}
            autoFocus
          />
          {brandLines?.length === 10 && (
            <Typography color="secondary" variant="caption">
              {t("Showing only top 10 results", {
                ns: "QuoteCreate",
              })}
            </Typography>
          )}
          <Box minHeight="20em">
            <SearchResults
              isLoading={query.loading}
              results={brandLines}
              selectedBrandLine={selectedBrandLine}
              selectBrandLine={setSelectedBrandLine}
            />
          </Box>
        </Stack>
      </form>
    </Modal>
  );
}

const SearchResults: React.FC<{
  isLoading: boolean;
  results: PickBrandLineModalSearchBrandLines_BrandLineFragment[] | undefined;
  selectedBrandLine: BrandLine | null;
  selectBrandLine: (brandLine: BrandLine) => void;
}> = ({ isLoading, results = [], selectedBrandLine, selectBrandLine }) => {
  const theme = useTheme();
  const { t } = useTranslate("QuoteCreate");

  if (isLoading) return <LoadingIndicator />;
  if (results.length === 0)
    return (
      <Box paddingX={2} lineHeight={2} color={theme.palette.secondary.main}>
        {t("No results")}
      </Box>
    );

  return (
    <List dense disablePadding>
      {results.map(result => (
        <ListItemButton
          key={result.id}
          onClick={() => selectBrandLine(result)}
          selected={selectedBrandLine?.id === result.id}
        >
          {result.title}
        </ListItemButton>
      ))}
    </List>
  );
};
