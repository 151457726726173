import React from "react";
import { Stack } from "../../commons/layout/Stack";
import { ellipsisStyle } from "@msys/ui";
import { Link as MuiLink, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";

interface Props {
  title: string;
  to: string;
  variant?: React.ComponentProps<typeof Typography>["variant"];
}

export const QuoteItemRow = ({ title, to, variant = "body1" }: Props) => (
  <Stack alignItems="center" style={ellipsisStyle}>
    <DescriptionIcon fontSize="small" />
    <Typography variant={variant} style={ellipsisStyle}>
      <MuiLink
        component={Link}
        to={to}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {title}
      </MuiLink>
    </Typography>
  </Stack>
);
