import { gql } from "@apollo/client";
import React, { useCallback, useImperativeHandle, useState } from "react";
import { useCounter } from "react-use";
import { AddTaskWorkSessionModal } from "./modals/AddTaskWorkSessionModal";

export interface AddTaskWorkSessionProcessRef {
  addWorkSession: (itemId: string, projectId: string, docId: string) => void;
}

interface Props {}

export const AddTaskWorkSessionProcess = React.forwardRef<
  AddTaskWorkSessionProcessRef,
  Props
>((_, forwardedRef) => {
  const [current, { inc }] = useCounter(0);
  const [{ isOpen, itemId, projectId, docId }, setState] = useState<{
    isOpen: boolean;
    itemId: string | null;
    projectId: string | null;
    docId: string | null;
  }>({
    itemId: null,
    projectId: null,
    docId: null,
    isOpen: false,
  });

  const handleClose = useCallback(
    () =>
      setState(s => ({
        isOpen: false,
        itemId: null,
        projectId: null,
        docId: null,
      })),
    []
  );

  const addWorkSession = useCallback(
    (itemId: string, projectId: string, docId: string) => {
      inc();
      setState({ isOpen: true, itemId, projectId, docId });
    },
    [inc]
  );

  useImperativeHandle(forwardedRef, () => ({ addWorkSession }));

  return isOpen && itemId && projectId && docId ? (
    <AddTaskWorkSessionModal
      key={`add-task-work-session-modal-${current}`}
      projectId={projectId}
      docId={docId}
      itemId={itemId}
      handleClose={handleClose}
    />
  ) : (
    <></>
  );
});
