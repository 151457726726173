import moment from "moment";
import React from "react";
import { useInterval } from "react-use";
import { DurationValue } from "./DurationValue";

interface Props {
  deltaMs?: number;
  active?: boolean;
  from: any;
  isSimplified?: boolean;
  noNegative?: boolean;
}

export const DurationCounter = ({
  from,
  deltaMs = 0,
  active = true,
  isSimplified = false,
  noNegative = true,
}: Props) => {
  const [valueMs, seValueMs] = React.useState<number>(
    Date.now() - moment(from).valueOf() + deltaMs
  );
  useInterval(
    () => {
      seValueMs(Date.now() - moment(from).valueOf() + deltaMs);
    },
    active ? 1000 : null
  );
  return (
    <DurationValue
      isSimplified={isSimplified}
      noNegative={noNegative}
      valueMs={valueMs}
    />
  );
};
