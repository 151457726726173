import { useTranslate } from "@tolgee/react";
import { namedOperations } from "../../../../clients/graphqlTypes";
import {
  Project,
  RestrictedByProjectPermissionWithDebug,
} from "../../../auth/RestrictedByProjectPermission";
import { FilesBoxTable } from "../../attachments/FilesBoxTable";
import { useRequestAttachments } from "../../attachments/useAttachments";
import { RequestAttachmentsBoxFragment } from "./RequestAttachmentsBox.generated";

export const RequestAttachmentsBox = ({
  request,
  project,
}: {
  request: RequestAttachmentsBoxFragment;
  project: Project;
}) => {
  const { t } = useTranslate("Requests");

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useRequestAttachments(request.id, [namedOperations.Query.ProjectRequest]);

  return (
    <FilesBoxTable
      title={t("Attachments")}
      attachments={request.attachments}
      canEdit={request.status === "OPEN"}
      canSign={false}
      noItemsText={t("No attachments added to the request.")}
      isInitiallyClosed={false}
      addAttachments={addAttachments}
      modifyAttachment={modifyAttachment}
      removeAttachment={removeAttachment}
      loading={loading}
      renderAddIconButton={button => (
        <RestrictedByProjectPermissionWithDebug
          permission="SHARE_REQUIREMENTS"
          project={project}
        >
          {button}
        </RestrictedByProjectPermissionWithDebug>
      )}
      renderEditIconButton={button => (
        <RestrictedByProjectPermissionWithDebug
          permission="SHARE_REQUIREMENTS"
          project={project}
        >
          {button}
        </RestrictedByProjectPermissionWithDebug>
      )}
    />
  );
};
