/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DatanormOnlineEntryFragment = { __typename: 'DatanormOnlineEntry', description: string, date?: string | null, dataDate?: string | null, url?: string | null, filename?: string | null };

export type DatanormOnlineEntriesQueryVariables = Types.Exact<{
  authentication: Types.ItekAuthenticateInput;
}>;


export type DatanormOnlineEntriesQuery = { datanormOnlineEntries: { __typename: 'DatanormOnlineEntriesResult', entries: Array<{ __typename: 'DatanormOnlineEntry', description: string, date?: string | null, dataDate?: string | null, url?: string | null, filename?: string | null }> } | { __typename: 'MissingPermissions' } };

export type ImportDatanormOnlineEntryMutationVariables = Types.Exact<{
  input: Types.ImportDatanormOnlineEntryInput;
}>;


export type ImportDatanormOnlineEntryMutation = { importDatanormOnlineEntry: { __typename: 'ImportDatanormOnlineEntryResult', importationId: string } };

export const DatanormOnlineEntryFragmentDoc = gql`
    fragment DatanormOnlineEntry on DatanormOnlineEntry {
  description
  date
  dataDate
  url
  filename
}
    `;
export const DatanormOnlineEntriesDocument = gql`
    query DatanormOnlineEntries($authentication: ItekAuthenticateInput!) {
  datanormOnlineEntries(authentication: $authentication) {
    ... on DatanormOnlineEntriesResult {
      entries {
        ...DatanormOnlineEntry
      }
    }
  }
}
    ${DatanormOnlineEntryFragmentDoc}`;

/**
 * __useDatanormOnlineEntriesQuery__
 *
 * To run a query within a React component, call `useDatanormOnlineEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatanormOnlineEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatanormOnlineEntriesQuery({
 *   variables: {
 *      authentication: // value for 'authentication'
 *   },
 * });
 */
export function useDatanormOnlineEntriesQuery(baseOptions: Apollo.QueryHookOptions<DatanormOnlineEntriesQuery, DatanormOnlineEntriesQueryVariables> & ({ variables: DatanormOnlineEntriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatanormOnlineEntriesQuery, DatanormOnlineEntriesQueryVariables>(DatanormOnlineEntriesDocument, options);
      }
export function useDatanormOnlineEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatanormOnlineEntriesQuery, DatanormOnlineEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatanormOnlineEntriesQuery, DatanormOnlineEntriesQueryVariables>(DatanormOnlineEntriesDocument, options);
        }
export function useDatanormOnlineEntriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DatanormOnlineEntriesQuery, DatanormOnlineEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DatanormOnlineEntriesQuery, DatanormOnlineEntriesQueryVariables>(DatanormOnlineEntriesDocument, options);
        }
export type DatanormOnlineEntriesQueryHookResult = ReturnType<typeof useDatanormOnlineEntriesQuery>;
export type DatanormOnlineEntriesLazyQueryHookResult = ReturnType<typeof useDatanormOnlineEntriesLazyQuery>;
export type DatanormOnlineEntriesSuspenseQueryHookResult = ReturnType<typeof useDatanormOnlineEntriesSuspenseQuery>;
export type DatanormOnlineEntriesQueryResult = Apollo.QueryResult<DatanormOnlineEntriesQuery, DatanormOnlineEntriesQueryVariables>;
export const ImportDatanormOnlineEntryDocument = gql`
    mutation ImportDatanormOnlineEntry($input: ImportDatanormOnlineEntryInput!) {
  importDatanormOnlineEntry(input: $input) {
    importationId
  }
}
    `;
export type ImportDatanormOnlineEntryMutationFn = Apollo.MutationFunction<ImportDatanormOnlineEntryMutation, ImportDatanormOnlineEntryMutationVariables>;

/**
 * __useImportDatanormOnlineEntryMutation__
 *
 * To run a mutation, you first call `useImportDatanormOnlineEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportDatanormOnlineEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importDatanormOnlineEntryMutation, { data, loading, error }] = useImportDatanormOnlineEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportDatanormOnlineEntryMutation(baseOptions?: Apollo.MutationHookOptions<ImportDatanormOnlineEntryMutation, ImportDatanormOnlineEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportDatanormOnlineEntryMutation, ImportDatanormOnlineEntryMutationVariables>(ImportDatanormOnlineEntryDocument, options);
      }
export type ImportDatanormOnlineEntryMutationHookResult = ReturnType<typeof useImportDatanormOnlineEntryMutation>;
export type ImportDatanormOnlineEntryMutationResult = Apollo.MutationResult<ImportDatanormOnlineEntryMutation>;
export type ImportDatanormOnlineEntryMutationOptions = Apollo.BaseMutationOptions<ImportDatanormOnlineEntryMutation, ImportDatanormOnlineEntryMutationVariables>;