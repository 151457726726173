/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../doc-items/Fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuotePriceBudgetSummaryQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type QuotePriceBudgetSummaryQuery = { outgoingQuote: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'OutgoingQuoteResult', quote?: { __typename: 'Quote', id: string, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null } | null }, project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, budget?: { __typename: 'Money', amount: number, currency: string } | null } | null }, projectOutgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'OutgoingQuoteEdge', node: { __typename: 'OutgoingQuote', id: string, isPublished: boolean, isBinding: boolean, agreement: Types.Agreement, needsAgreement: boolean, proposedTotalPrice: number } }> } };


export const QuotePriceBudgetSummaryDocument = gql`
    query QuotePriceBudgetSummary($projectId: ID!, $quoteId: ID!) {
  outgoingQuote(projectId: $projectId, docId: $quoteId) {
    ... on OutgoingQuoteResult {
      quote {
        id
        proposedCalculation {
          ...ItemCalculation
        }
      }
    }
  }
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        budget {
          amount
          currency
        }
      }
    }
  }
  projectOutgoingQuotes(limit: 0, projectId: $projectId) {
    ... on ProjectOutgoingQuoteConnection {
      edges {
        node {
          id
          isPublished
          isBinding
          agreement
          needsAgreement
          proposedTotalPrice
        }
      }
      totalCount
    }
  }
}
    ${ItemCalculationFragmentDoc}`;

/**
 * __useQuotePriceBudgetSummaryQuery__
 *
 * To run a query within a React component, call `useQuotePriceBudgetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotePriceBudgetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotePriceBudgetSummaryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuotePriceBudgetSummaryQuery(baseOptions: Apollo.QueryHookOptions<QuotePriceBudgetSummaryQuery, QuotePriceBudgetSummaryQueryVariables> & ({ variables: QuotePriceBudgetSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuotePriceBudgetSummaryQuery, QuotePriceBudgetSummaryQueryVariables>(QuotePriceBudgetSummaryDocument, options);
      }
export function useQuotePriceBudgetSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotePriceBudgetSummaryQuery, QuotePriceBudgetSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuotePriceBudgetSummaryQuery, QuotePriceBudgetSummaryQueryVariables>(QuotePriceBudgetSummaryDocument, options);
        }
export function useQuotePriceBudgetSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuotePriceBudgetSummaryQuery, QuotePriceBudgetSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuotePriceBudgetSummaryQuery, QuotePriceBudgetSummaryQueryVariables>(QuotePriceBudgetSummaryDocument, options);
        }
export type QuotePriceBudgetSummaryQueryHookResult = ReturnType<typeof useQuotePriceBudgetSummaryQuery>;
export type QuotePriceBudgetSummaryLazyQueryHookResult = ReturnType<typeof useQuotePriceBudgetSummaryLazyQuery>;
export type QuotePriceBudgetSummarySuspenseQueryHookResult = ReturnType<typeof useQuotePriceBudgetSummarySuspenseQuery>;
export type QuotePriceBudgetSummaryQueryResult = Apollo.QueryResult<QuotePriceBudgetSummaryQuery, QuotePriceBudgetSummaryQueryVariables>;