import { gql } from "@apollo/client";
import { CollapseSection, ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  ButtonProps,
  Checkbox,
  FormLabel,
  Link,
  LinkProps,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { useFeature } from "../../../../common/FeatureFlags";
import { DecisionOptionRadioOrCheckbox } from "../fields/DecisionOptionRadioOrCheckbox";
import { useDecisionItemMutations } from "../hooks/useDecisionItemMutations";
import { AttributeExpressionModal } from "../modals/AttributeExpressionModal";
import { RulesSection_CurrentDocumentItemFragment } from "./RulesSection.generated";

export function RulesSection({
  projectId,
  docId,
  item,
  readOnly = false,
}: {
  projectId: string | null;
  docId: string;
  item: RulesSection_CurrentDocumentItemFragment;
  readOnly?: boolean;
}) {
  const { t } = useTranslate(["QuoteItem"]);
  const { expandedItemIds } = useOutletContext<{
    expandedItemIds: string[] | undefined;
  }>();

  const {
    modifyItemContingency,
    handleEliminateOption,
    handleResetEliminateOption,
    eliminateItem,
    resetEliminateItem,
    loading,
  } = useDecisionItemMutations(projectId, docId, false, expandedItemIds);

  const onChangeDecisionIs = async (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (loading) return;

    const decisionIsContingentItem = checked;
    const decisionContingentItemPreselection =
      checked === false ? null : undefined;

    await modifyItemContingency({
      variables: {
        input: {
          projectId,
          docId,
          itemId: item.id,
          values: {
            decisionIsContingentItem,
            decisionContingentItemPreselection,
          },
        },
      },
    });
  };

  const handleItemEliminationToggle = async () => {
    if (loading) return;

    if (item.isItemEliminated) {
      await resetEliminateItem(item.id);
    } else {
      await eliminateItem(item.id);
    }
  };

  const onChangeDecisionPreselection = async () => {
    if (loading) return;

    await modifyItemContingency({
      variables: {
        input: {
          projectId,
          docId,
          itemId: item.id,
          values: {
            decisionContingentItemPreselection:
              !item.decisionContingentItemPreselection,
          },
        },
      },
    });
  };

  const handleDecisionOptionEliminationToggle = async () => {
    if (loading) return;

    if (item.decisionOptionElimination) {
      await handleResetEliminateOption(item.id);
    } else {
      await handleEliminateOption(item.id);
    }
  };

  if (!("attributeExpressions" in item)) return null;

  const eliminateItemIfExpression = item.attributeExpressions.find(
    expression => expression.attribute === "eliminateItemIf"
  );
  const decisionIsContingentItemExpression = item.attributeExpressions.find(
    expression => expression.attribute === "decisionIsContingentItem"
  );
  const decisionContingentItemPreselectionExpression =
    item.attributeExpressions.find(
      expression =>
        expression.attribute === "decisionContingentItemPreselection"
    );
  const preselectDecisionOptionIfExpression = item.attributeExpressions.find(
    expression => expression.attribute === "preselectDecisionOptionIf"
  );

  const eliminateDecisionOptionIfExpression = item.attributeExpressions.find(
    expression => expression.attribute === "eliminateDecisionOptionIf"
  );

  const itemIsDecisionOption = item.isDecisionOption;

  return (
    <CollapseSection
      title={t("Rules", {
        ns: "QuoteItem",
      })}
      isInitiallyExpanded={true}
    >
      <Stack spacing={0}>
        {itemIsDecisionOption ? (
          <>
            <Stack alignItems={"flex-start"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
              >
                <FormLabel sx={{ color: "text.primary" }}>
                  {t("Decision option eliminated", {
                    ns: "QuoteItem",
                  })}
                </FormLabel>
                <Checkbox
                  id={"decisionElimination"}
                  disabled={readOnly || loading}
                  checked={!!item.decisionOptionElimination}
                  onChange={handleDecisionOptionEliminationToggle}
                  size="small"
                />
              </Stack>
              {!readOnly && (
                <ModalOpenButton
                  Modal={AttributeExpressionModal}
                  modalProps={{
                    projectId,
                    docId: docId,
                    itemId: item.id,
                    fieldName: "eliminateDecisionOptionIf",
                    expression: eliminateDecisionOptionIfExpression?.expr,
                  }}
                >
                  {eliminateDecisionOptionIfExpression ? (
                    <ExpressionLink
                      itemId={item.id}
                      expression={eliminateDecisionOptionIfExpression.expr}
                    />
                  ) : (
                    <AddConditionButton />
                  )}
                </ModalOpenButton>
              )}
            </Stack>
            {(item.decisionOptionElimination === undefined ||
              item.decisionOptionElimination === null) && (
              <Stack alignItems={"flex-start"}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  alignSelf={"stretch"}
                >
                  <Typography>
                    {t("Decision option preselected", {
                      ns: "QuoteItem",
                    })}
                  </Typography>
                  <DecisionOptionRadioOrCheckbox
                    projectId={projectId}
                    docId={docId}
                    item={item}
                    disabled={readOnly}
                    expandedItemIds={expandedItemIds}
                  />
                </Stack>
                {!readOnly && (
                  <ModalOpenButton
                    Modal={AttributeExpressionModal}
                    modalProps={{
                      projectId,
                      docId: docId,
                      itemId: item.id,
                      fieldName: "preselectDecisionOptionIf",
                      expression: preselectDecisionOptionIfExpression?.expr,
                    }}
                  >
                    {preselectDecisionOptionIfExpression ? (
                      <ExpressionLink
                        itemId={item.id}
                        expression={preselectDecisionOptionIfExpression.expr}
                      />
                    ) : (
                      <AddConditionButton />
                    )}
                  </ModalOpenButton>
                )}
              </Stack>
            )}
          </>
        ) : (
          <>
            {!readOnly && (
              <Stack alignItems={"flex-start"}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  alignSelf={"stretch"}
                >
                  <FormLabel sx={{ color: "text.primary" }}>
                    {t("Item is eliminated", {
                      ns: "QuoteItem",
                    })}
                  </FormLabel>
                  <Checkbox
                    id={"decisionElimination"}
                    disabled={readOnly || loading}
                    checked={item.isItemEliminated}
                    onChange={handleItemEliminationToggle}
                    size="small"
                  />
                </Stack>
                <ModalOpenButton
                  Modal={AttributeExpressionModal}
                  modalProps={{
                    projectId,
                    docId,
                    itemId: item.id,
                    fieldName: "eliminateItemIf",
                    expression: eliminateItemIfExpression?.expr,
                  }}
                >
                  {eliminateItemIfExpression ? (
                    <ExpressionLink
                      itemId={item.id}
                      expression={eliminateItemIfExpression.expr}
                    />
                  ) : (
                    <AddConditionButton />
                  )}
                </ModalOpenButton>
              </Stack>
            )}
            {!item.isItemEliminated && (
              <>
                <Stack alignItems={"flex-start"}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <FormLabel sx={{ color: "text.primary" }}>
                      {t("Item is optional", {
                        ns: "QuoteItem",
                      })}
                    </FormLabel>
                    <Switch
                      id={"contingency"}
                      checked={item.decisionIsContingentItem}
                      onChange={onChangeDecisionIs}
                      disabled={readOnly || loading || item.isItemEliminated}
                    />
                  </Stack>
                  {!readOnly && (
                    <ModalOpenButton
                      Modal={AttributeExpressionModal}
                      modalProps={{
                        projectId,
                        docId,
                        itemId: item.id,
                        fieldName: "decisionIsContingentItem",
                        expression: decisionIsContingentItemExpression?.expr,
                      }}
                    >
                      {decisionIsContingentItemExpression ? (
                        <ExpressionLink
                          itemId={item.id}
                          expression={decisionIsContingentItemExpression.expr}
                        />
                      ) : (
                        <AddConditionButton />
                      )}
                    </ModalOpenButton>
                  )}
                </Stack>
                {(item.decisionIsContingentItem === true ||
                  decisionIsContingentItemExpression !== undefined) && (
                  <Stack alignItems={"flex-start"}>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      alignSelf={"stretch"}
                    >
                      <FormLabel sx={{ color: "text.primary" }}>
                        {t("Preselect option", {
                          ns: "QuoteItem",
                        })}
                      </FormLabel>
                      <Checkbox
                        id={"contingencyPreselection"}
                        disabled={
                          readOnly ||
                          loading ||
                          item.decisionIsContingentItem !== true
                        }
                        checked={
                          item.decisionContingentItemPreselection === true
                        }
                        indeterminate={
                          item.decisionContingentItemPreselection === null
                        }
                        onChange={onChangeDecisionPreselection}
                        size="small"
                      />
                    </Stack>
                    {!readOnly && (
                      <ModalOpenButton
                        Modal={AttributeExpressionModal}
                        modalProps={{
                          projectId,
                          docId,
                          itemId: item.id,
                          fieldName: "decisionContingentItemPreselection",
                          expression:
                            decisionContingentItemPreselectionExpression?.expr,
                        }}
                      >
                        {decisionContingentItemPreselectionExpression ? (
                          <ExpressionLink
                            itemId={item.id}
                            expression={
                              decisionContingentItemPreselectionExpression.expr
                            }
                          />
                        ) : (
                          <AddConditionButton />
                        )}
                      </ModalOpenButton>
                    )}
                  </Stack>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </CollapseSection>
  );
}

function AddConditionButton(props: ButtonProps) {
  const { t } = useTranslate(["QuoteItem"]);

  return (
    <Button
      startIcon={<AddIcon />}
      size={"small"}
      color={"secondary"}
      {...props}
    >
      {t("Add condition", {
        ns: "QuoteItem",
      })}
    </Button>
  );
}

function ExpressionLink({
  itemId,
  expression,
  ...props
}: {
  itemId: string;
  expression: string;
} & LinkProps) {
  return (
    <Link
      color={"secondary"}
      fontSize="small"
      sx={{ cursor: "pointer" }}
      {...props}
    >
      {expression}
    </Link>
  );
}
