import { useEffect, useRef, useState } from "react";
import { off, on } from "react-use/lib/util";
import { useUpdateEffect } from "react-use";
import { throttle } from "lodash";

const THROTTLE_MS = 100;

export interface State {
  x: number;
  y: number;
}

export type OnScroll = (state: State) => void;

export const useScroll = (
  ref: HTMLElement | Window,
  onScroll: OnScroll
): State => {
  const [state, setState] = useState<State>({ x: 0, y: 0 });

  const onScrollThrottledRef = useRef<OnScroll>(
    throttle(onScroll, THROTTLE_MS)
  );

  useUpdateEffect(() => {
    onScrollThrottledRef.current = throttle(onScroll, THROTTLE_MS);
  }, [onScroll]);

  useEffect(() => {
    if (!ref) return;

    const handler = () => {
      if (ref instanceof Window) {
        const value: State = {
          x: ref.scrollX,
          y: ref.scrollY,
        };
        setState(value);
        onScrollThrottledRef.current(value);
      } else if (ref instanceof HTMLElement) {
        const value: State = {
          x: ref.scrollLeft,
          y: ref.scrollTop,
        };
        setState(value);
        onScrollThrottledRef.current(value);
      }
    };

    handler();

    on(ref, "scroll", handler, {
      capture: false,
      passive: true,
    });

    return () => {
      off(ref, "scroll", handler);
    };
  }, [ref]);

  return state;
};
