import { useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  useQuoteSetExternalIdentifierModalQuery,
  useSetQuoteExternalIdentifierMutation,
} from "./QuoteSetExternalIdentifierModal.generated";

interface Props {
  projectId: string;
  quoteId: string;
  handleClose: () => void;
  handleComplete: () => Promise<void> | void;
}

interface FormValues {
  externalIdentifier: string | null | undefined;
}

export const QuoteSetExternalIdentifierModal = ({
  projectId,
  quoteId,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate([
    "QuoteEditImportRequirements",
    "QuoteEdit",
    "Global",
  ]);

  const client = useApolloClient();

  const [isDeleting, setIsDeleting] = useState(false);

  const [setQuoteExternalIdentifier] = useSetQuoteExternalIdentifierMutation({
    client,
  });

  const query = useQuoteSetExternalIdentifierModalQuery({
    client,
    variables: {
      projectId,
      docId: quoteId,
    },
    fetchPolicy: "network-only",
  });

  const quoteExternalIdentifier = query.data?.quoteExternalIdentifier;

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues: FormValues = {
    externalIdentifier: quoteExternalIdentifier?.externalIdentifier,
  };

  const handleSubmit = async (
    values: FormValues,
    formikActions: FormikHelpers<FormValues>
  ) => {
    try {
      await setQuoteExternalIdentifier({
        variables: {
          input: {
            projectId,
            docId: quoteId,
            externalIdentifier: values.externalIdentifier,
          },
        },
      });

      await handleComplete();
      handleClose();
    } catch (error) {
      formikActions.setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      await setQuoteExternalIdentifier({
        variables: {
          input: {
            projectId,
            docId: quoteId,
            externalIdentifier: null,
          },
        },
      });

      await handleComplete();
      handleClose();
    } catch (err) {
      setIsDeleting(false);
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={Yup.object({
        externalIdentifier: Yup.string().trim().required(),
      })}
    >
      {formikProps => {
        return (
          <Modal
            title={t("Set external identifier", {
              ns: "QuoteEdit",
            })}
            dialogProps={{ maxWidth: "sm" }}
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Delete", {
                  ns: "Global",
                }),
                handleClick: handleDelete,
                buttonProps: { color: "secondary", loading: isDeleting },
              },
              {
                label: t("Save", {
                  ns: "Global",
                }),
                buttonProps: {
                  loading: formikProps.isSubmitting,
                  form: formId,
                  type: "submit",
                  disabled: !formikProps.isValid,
                },
              },
            ]}
            handleClose={handleClose}
            isLoading={query.loading}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                <Field
                  component={TextField}
                  name="externalIdentifier"
                  label={t("External Identifier", {
                    ns: "Quote",
                  })}
                  disabled={false}
                />
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
