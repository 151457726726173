import { notNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Grid as MuiGrid, Stack } from "@mui/material";
import React from "react";

type Columns = {
  xs?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

interface Props {
  children: (React.ReactElement | null)[] | React.ReactElement | null;
  columns: Columns;
  strategy?: (
    children: React.ReactElement[],
    numberOfColumns: number
  ) => React.ReactElement[][];
}

export function PageGrid({
  children,
  columns: cols,
  strategy = roundRobinStrategy,
}: Props) {
  const { isMinLargeDesktop, isMinDesktop, isMinTablet } = useScreenWidth();

  const numberOfColumns = isMinLargeDesktop
    ? cols.xl ?? cols.lg ?? cols.md ?? cols.xs ?? 1
    : isMinDesktop
    ? cols.lg ?? cols.md ?? cols.xs ?? 1
    : isMinTablet
    ? cols.md ?? cols.xs ?? 1
    : cols.xs ?? 1;

  let columns = React.useMemo(
    () =>
      strategy(
        React.Children.toArray(children).filter(
          notNull
        ) as React.ReactElement[],
        numberOfColumns
      ),
    [children, numberOfColumns, strategy]
  );

  return (
    <MuiGrid container spacing={2}>
      {columns.map((column, index) => (
        <MuiGrid key={index} item xs={12 / numberOfColumns}>
          <Stack direction={"column"} spacing={2}>
            {column}
          </Stack>
        </MuiGrid>
      ))}
    </MuiGrid>
  );
}

export function roundRobinStrategy(
  children: React.ReactElement[],
  numberOfColumns: number
) {
  return children.reduce<React.ReactElement[][]>((acc, child, index) => {
    const columnNumber = index % numberOfColumns;
    acc.splice(columnNumber, 1, [...(acc[columnNumber] ?? []), child]);

    return acc;
  }, []);
}

export function splitStrategy(
  children: React.ReactElement[],
  numberOfColumns: number
) {
  const floor = Math.floor(children.length / numberOfColumns);
  const mod = children.length % numberOfColumns;
  const counts = new Array(numberOfColumns)
    .fill(floor)
    .map((count, index) => (index < mod ? count + 1 : count));
  const ch = [...children];
  const columns = counts.map(count => {
    return ch.splice(0, count);
  });

  return columns;
}
