import { Tooltip, Typography } from "@mui/material";
import React from "react";

export function EditableTitle({
  title,
  tooltip = "",
  handleChange,
}: {
  title: string;
  tooltip?: string;
  handleChange: (value: string) => void;
}) {
  const ref = React.useRef<HTMLElement | null>(null);
  return (
    <Tooltip title={tooltip} arrow>
      <Typography
        ref={ref}
        variant="h1"
        contentEditable
        suppressContentEditableWarning
        onPaste={event => {
          // when html is pasted, set the value to plain text content of it
          event.preventDefault();
          const pastedText = event.clipboardData.getData("text/plain");
          const element = event.currentTarget;
          element.textContent = pastedText;
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            // when Enter key is pressed, do not add line break, but blur immediately
            event.preventDefault();
            const element = event.currentTarget;
            element.blur();
          } else if (event.key === "Escape") {
            // when Escape key is pressed, ignore the changes
            event.preventDefault();
            event.currentTarget.textContent = title;
            const element = event.currentTarget;
            element.blur();
          }
        }}
        onBlur={event => {
          const newValue = event.currentTarget.textContent ?? "";
          if (!newValue.trim()) {
            // need to reset to the default value
            if (ref.current) ref.current.innerText = title;
            return;
          }
          if (newValue !== title) {
            handleChange(newValue);
          }
        }}
        sx={{
          paddingX: 0.5,
          marginX: -0.5,
          borderRadius: 0.5,
          "&:hover:not(:focus-within)": {
            outline: theme => `${theme.palette.grey[300]} solid 1px`,
          },
        }}
      >
        {title}
      </Typography>
    </Tooltip>
  );
}
