import { Stack } from "@mui/material";
import {
  productFiltersDefaultValue,
  useProductFilterFields,
} from "./ProductFilterFields";

export { productFiltersDefaultValue };

type Props = Parameters<typeof useProductFilterFields>[0];

export const ProductFilters = ({
  formikProps,
  hideBrandLines = true,
  hideNetPrice = true,
  showOnlyPropertyFiltersWithValues = true,
  ...props
}: Props) => {
  const {
    nonPropertyFields,
    popularPropertyFields,
    advancePropertyFields,
    isLoading,
  } = useProductFilterFields({
    ...props,
    formikProps,
    hideBrandLines,
    hideNetPrice,
    showOnlyPropertyFiltersWithValues,
  });

  return props.viewType === "buttons" ? (
    nonPropertyFields.length > 0 ||
    popularPropertyFields.length > 0 ||
    advancePropertyFields.length > 0 ? (
      <Stack
        direction="row"
        spacing={1}
        flexWrap="wrap"
        alignItems="center"
        minHeight="32px"
        useFlexGap
      >
        {nonPropertyFields}
        {popularPropertyFields}
        {advancePropertyFields}
      </Stack>
    ) : null
  ) : props.viewType === "inputs" ? (
    <>
      {nonPropertyFields}
      {popularPropertyFields}
      {advancePropertyFields}
    </>
  ) : null;
};
