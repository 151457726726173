/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectSelectQueryVariables = Types.Exact<{
  crmCompanyId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ProjectSelectQuery = { projects: { __typename: 'ProjectConnection', edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, title: string, number: string } }> } };


export const ProjectSelectDocument = gql`
    query ProjectSelect($crmCompanyId: ID) {
  projects(
    limit: 0
    filters: {crmOrganisationId: $crmCompanyId, includeState: [opportunity, contracted]}
    sorting: [{column: title, direction: asc}]
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          title
          number
        }
      }
    }
  }
}
    `;

/**
 * __useProjectSelectQuery__
 *
 * To run a query within a React component, call `useProjectSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSelectQuery({
 *   variables: {
 *      crmCompanyId: // value for 'crmCompanyId'
 *   },
 * });
 */
export function useProjectSelectQuery(baseOptions?: Apollo.QueryHookOptions<ProjectSelectQuery, ProjectSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectSelectQuery, ProjectSelectQueryVariables>(ProjectSelectDocument, options);
      }
export function useProjectSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectSelectQuery, ProjectSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectSelectQuery, ProjectSelectQueryVariables>(ProjectSelectDocument, options);
        }
export function useProjectSelectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectSelectQuery, ProjectSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectSelectQuery, ProjectSelectQueryVariables>(ProjectSelectDocument, options);
        }
export type ProjectSelectQueryHookResult = ReturnType<typeof useProjectSelectQuery>;
export type ProjectSelectLazyQueryHookResult = ReturnType<typeof useProjectSelectLazyQuery>;
export type ProjectSelectSuspenseQueryHookResult = ReturnType<typeof useProjectSelectSuspenseQuery>;
export type ProjectSelectQueryResult = Apollo.QueryResult<ProjectSelectQuery, ProjectSelectQueryVariables>;