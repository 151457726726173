/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationVariables = Types.Exact<{
  input: Types.CopySupplierOrganisationAsCrmInput;
}>;


export type CopySupplierOrganisationAsCrm__NewSupplierMutation = { copySupplierOrganisationAsCrm: { __typename: 'CopySupplierOrganisationAsCrmResult', crmOrganisation: { __typename: 'CrmCompany', id: string, title: string, email: any, abcCategory: Types.CrmOrganisationAbcCategory, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } };

export type SapCreateBusinessPartnerEndClientMutationVariables = Types.Exact<{
  input: Types.SapCreateBusinessPartnerEndClientInput;
}>;


export type SapCreateBusinessPartnerEndClientMutation = { sapCreateBusinessPartnerEndClient: { __typename: 'SapCreateBusinessPartnerEndClientResult', fioriLaunchPadUrl: string } };

export type SapCreateBusinessPartnerCraftsmanMutationVariables = Types.Exact<{
  input: Types.SapCreateBusinessPartnerCustomerInput;
}>;


export type SapCreateBusinessPartnerCraftsmanMutation = { sapCreateBusinessPartnerCustomer: { __typename: 'SapCreateBusinessPartnerCustomerResult', ok: boolean } };


export const CopySupplierOrganisationAsCrm__NewSupplierDocument = gql`
    mutation CopySupplierOrganisationAsCrm__NewSupplier($input: CopySupplierOrganisationAsCrmInput!) {
  copySupplierOrganisationAsCrm(input: $input) {
    crmOrganisation {
      id
      title
      email
      abcCategory
      contactPerson {
        id
        fullname
      }
      billingAddress {
        ...AddressDetails__Address
      }
      logo {
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationFn = Apollo.MutationFunction<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>;

/**
 * __useCopySupplierOrganisationAsCrm__NewSupplierMutation__
 *
 * To run a mutation, you first call `useCopySupplierOrganisationAsCrm__NewSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySupplierOrganisationAsCrm__NewSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySupplierOrganisationAsCrmNewSupplierMutation, { data, loading, error }] = useCopySupplierOrganisationAsCrm__NewSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopySupplierOrganisationAsCrm__NewSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>(CopySupplierOrganisationAsCrm__NewSupplierDocument, options);
      }
export type CopySupplierOrganisationAsCrm__NewSupplierMutationHookResult = ReturnType<typeof useCopySupplierOrganisationAsCrm__NewSupplierMutation>;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationResult = Apollo.MutationResult<CopySupplierOrganisationAsCrm__NewSupplierMutation>;
export type CopySupplierOrganisationAsCrm__NewSupplierMutationOptions = Apollo.BaseMutationOptions<CopySupplierOrganisationAsCrm__NewSupplierMutation, CopySupplierOrganisationAsCrm__NewSupplierMutationVariables>;
export const SapCreateBusinessPartnerEndClientDocument = gql`
    mutation SapCreateBusinessPartnerEndClient($input: SapCreateBusinessPartnerEndClientInput!) {
  sapCreateBusinessPartnerEndClient(input: $input) {
    fioriLaunchPadUrl
  }
}
    `;
export type SapCreateBusinessPartnerEndClientMutationFn = Apollo.MutationFunction<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>;

/**
 * __useSapCreateBusinessPartnerEndClientMutation__
 *
 * To run a mutation, you first call `useSapCreateBusinessPartnerEndClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSapCreateBusinessPartnerEndClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sapCreateBusinessPartnerEndClientMutation, { data, loading, error }] = useSapCreateBusinessPartnerEndClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSapCreateBusinessPartnerEndClientMutation(baseOptions?: Apollo.MutationHookOptions<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>(SapCreateBusinessPartnerEndClientDocument, options);
      }
export type SapCreateBusinessPartnerEndClientMutationHookResult = ReturnType<typeof useSapCreateBusinessPartnerEndClientMutation>;
export type SapCreateBusinessPartnerEndClientMutationResult = Apollo.MutationResult<SapCreateBusinessPartnerEndClientMutation>;
export type SapCreateBusinessPartnerEndClientMutationOptions = Apollo.BaseMutationOptions<SapCreateBusinessPartnerEndClientMutation, SapCreateBusinessPartnerEndClientMutationVariables>;
export const SapCreateBusinessPartnerCraftsmanDocument = gql`
    mutation SapCreateBusinessPartnerCraftsman($input: SapCreateBusinessPartnerCustomerInput!) {
  sapCreateBusinessPartnerCustomer(input: $input) {
    ok
  }
}
    `;
export type SapCreateBusinessPartnerCraftsmanMutationFn = Apollo.MutationFunction<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>;

/**
 * __useSapCreateBusinessPartnerCraftsmanMutation__
 *
 * To run a mutation, you first call `useSapCreateBusinessPartnerCraftsmanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSapCreateBusinessPartnerCraftsmanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sapCreateBusinessPartnerCraftsmanMutation, { data, loading, error }] = useSapCreateBusinessPartnerCraftsmanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSapCreateBusinessPartnerCraftsmanMutation(baseOptions?: Apollo.MutationHookOptions<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>(SapCreateBusinessPartnerCraftsmanDocument, options);
      }
export type SapCreateBusinessPartnerCraftsmanMutationHookResult = ReturnType<typeof useSapCreateBusinessPartnerCraftsmanMutation>;
export type SapCreateBusinessPartnerCraftsmanMutationResult = Apollo.MutationResult<SapCreateBusinessPartnerCraftsmanMutation>;
export type SapCreateBusinessPartnerCraftsmanMutationOptions = Apollo.BaseMutationOptions<SapCreateBusinessPartnerCraftsmanMutation, SapCreateBusinessPartnerCraftsmanMutationVariables>;