import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React from "react";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { useElementSize } from "../hooks/useElementSize";

interface Props {
  lines?: number;
  html?: string;
  textProps?: TypographyProps<"div">;
  buttonProps?: ButtonProps;
  showMoreLabel: string;
  showLessLabel: string;
  notSetLabel: string;
}

export const TruncatedHtmlValue = ({
  lines = 3,
  html,
  textProps,
  notSetLabel,
  showMoreLabel,
  showLessLabel,
  buttonProps,
}: Props) => {
  const [showMore, setShowMore] = React.useState<boolean>(false);
  const [hasTruncatedText, setHasTruncatedText] =
    React.useState<boolean>(false);
  const sizeMeasureRef = React.useRef<HTMLDivElement | null>(null);
  const size = useElementSize<HTMLDivElement>(sizeMeasureRef);
  return (
    <Typography
      sx={!html?.trim() ? { color: "grey.500" } : undefined}
      component="div"
      className="msys-markup-text-html"
      ref={sizeMeasureRef}
      {...textProps}
    >
      <HTMLEllipsis
        key={`html-ellipsis-${showMore ? 999 : lines}`}
        unsafeHTML={html?.trim() ? html : `<p>${notSetLabel}</p>`}
        maxLine={showMore ? 999 : lines}
        ellipsis="…"
        basedOn="words"
        onReflow={({ clamped, text }: { clamped: boolean; text: string }) => {
          if (!showMore && clamped && !hasTruncatedText)
            setHasTruncatedText(true);
          if (!showMore && !clamped && hasTruncatedText)
            setHasTruncatedText(false);
        }}
        winWidth={size.width}
      />
      {hasTruncatedText &&
        (!showMore ? (
          <Button
            onClick={() => setShowMore(true)}
            variant="text"
            color="secondary"
            size="extra-small"
            {...buttonProps}
          >
            {showMoreLabel}
          </Button>
        ) : (
          <Button
            onClick={() => setShowMore(false)}
            variant="text"
            color="secondary"
            size="extra-small"
            {...buttonProps}
          >
            {showLessLabel}
          </Button>
        ))}
    </Typography>
  );
};
