/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationRegistrationDetailsBox_OrganisationFragment = { __typename: 'Organisation', id: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string };

export type OrganisationRegistrationDetailsBox_ViewerOrganisationFragment = { __typename: 'ViewerOrganisation', id: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string };

export type ModifyOrganisationRegistrationDetailsMutationVariables = Types.Exact<{
  input: Types.OrganisationUpdateInput;
}>;


export type ModifyOrganisationRegistrationDetailsMutation = { updateOrganisation: { __typename: 'OrganisationUpdateResult', organisation: { __typename: 'ViewerOrganisation', id: string, fullLegalName: string, taxNumber: string, vatNumber: string, registrationNumber: string, registrationOffice: string, managingDirector: string } } };

export const OrganisationRegistrationDetailsBox_OrganisationFragmentDoc = gql`
    fragment OrganisationRegistrationDetailsBox_Organisation on Organisation {
  id
  fullLegalName
  taxNumber
  vatNumber
  registrationNumber
  registrationOffice
  managingDirector
}
    `;
export const OrganisationRegistrationDetailsBox_ViewerOrganisationFragmentDoc = gql`
    fragment OrganisationRegistrationDetailsBox_ViewerOrganisation on ViewerOrganisation {
  id
  fullLegalName
  taxNumber
  vatNumber
  registrationNumber
  registrationOffice
  managingDirector
}
    `;
export const ModifyOrganisationRegistrationDetailsDocument = gql`
    mutation ModifyOrganisationRegistrationDetails($input: OrganisationUpdateInput!) {
  updateOrganisation(input: $input) {
    organisation {
      id
      fullLegalName
      taxNumber
      vatNumber
      registrationNumber
      registrationOffice
      managingDirector
    }
  }
}
    `;
export type ModifyOrganisationRegistrationDetailsMutationFn = Apollo.MutationFunction<ModifyOrganisationRegistrationDetailsMutation, ModifyOrganisationRegistrationDetailsMutationVariables>;

/**
 * __useModifyOrganisationRegistrationDetailsMutation__
 *
 * To run a mutation, you first call `useModifyOrganisationRegistrationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationRegistrationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrganisationRegistrationDetailsMutation, { data, loading, error }] = useModifyOrganisationRegistrationDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyOrganisationRegistrationDetailsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyOrganisationRegistrationDetailsMutation, ModifyOrganisationRegistrationDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyOrganisationRegistrationDetailsMutation, ModifyOrganisationRegistrationDetailsMutationVariables>(ModifyOrganisationRegistrationDetailsDocument, options);
      }
export type ModifyOrganisationRegistrationDetailsMutationHookResult = ReturnType<typeof useModifyOrganisationRegistrationDetailsMutation>;
export type ModifyOrganisationRegistrationDetailsMutationResult = Apollo.MutationResult<ModifyOrganisationRegistrationDetailsMutation>;
export type ModifyOrganisationRegistrationDetailsMutationOptions = Apollo.BaseMutationOptions<ModifyOrganisationRegistrationDetailsMutation, ModifyOrganisationRegistrationDetailsMutationVariables>;