import {
  CardContainer,
  LabeledOpeningHoursValue,
  ModalOpenButton,
  useScreenWidth,
} from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import { Grid, IconButton, Link, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { color } from "../../../../common/MuiThemeProvider";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../../auth/useUserData";
import { prepareUrl } from "../../../commons/form-fields/helpers";
import { Stack } from "../../../commons/layout/Stack";
import { AddressWithBreaks } from "../../addresses/AddressWithBreaks";
import { getAddressDirectionUrl } from "../../addresses/helpers";
import { usePhoneTypes } from "../../phones/usePhoneTypes";
import { EditOrganisationProfileContactModal } from "../modals/EditOrganisationProfileContactModal";
import { OrganisationPublicContactBox_OrganisationProfileFragment } from "./OrganisationPublicContactBox.generated";

interface Props {
  organisationProfile: OrganisationPublicContactBox_OrganisationProfileFragment;
  canEdit: boolean;
}

export const OrganisationPublicContactBox = ({
  organisationProfile,
  canEdit,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate([
    "OrganisationPublicProfile",
    "PhonesField",
    "Global",
    "OpeningHoursField",
  ]);
  const { phoneTypeLabels } = usePhoneTypes();
  const { isMaxPhone } = useScreenWidth();

  return (
    <CardContainer
      title={t("Contact", {
        ns: "OrganisationPublicProfile",
      })}
      ActionButton={
        canEdit && (
          <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
            {viewer.organisation.id === organisationProfile.organisation.id && (
              <ModalOpenButton
                Modal={EditOrganisationProfileContactModal}
                modalProps={{
                  organisationProfile,
                }}
              >
                <IconButton color="primary" size="small">
                  <EditIcon />
                </IconButton>
              </ModalOpenButton>
            )}
          </RestrictedByOrganisationPermissionWithDebug>
        )
      }
    >
      <Stack
        flexDirection="column"
        width="100%"
        p={isMaxPhone ? 1 : 2}
        spacing={isMaxPhone ? 1 : 3 / 2}
      >
        <Grid container spacing={isMaxPhone ? 1 : 2}>
          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <RoomIcon />
              <div>
                {!organisationProfile.contactAddress ? (
                  <Typography style={{ color: color.grey }}>
                    {t("Not set", {
                      ns: "Global",
                    })}
                  </Typography>
                ) : (
                  <Link
                    href={getAddressDirectionUrl(
                      organisationProfile.contactAddress
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AddressWithBreaks
                      address={organisationProfile.contactAddress}
                      useCountry={false}
                    />
                  </Link>
                )}
              </div>
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <PhoneIcon />
              <div>
                {!organisationProfile.contactPhones ||
                organisationProfile.contactPhones.length === 0 ? (
                  <Typography style={{ color: color.grey }}>
                    {t("Not set", {
                      ns: "Global",
                    })}
                  </Typography>
                ) : (
                  organisationProfile.contactPhones.map((phone, index) => {
                    if (phone.type === "PRIVATE")
                      throw new Error("Invalid phone type");
                    return (
                      <Typography key={index}>
                        {phoneTypeLabels[phone.type]}{" "}
                        <Link href={`tel:${phone.number}`}>{phone.number}</Link>
                      </Typography>
                    );
                  })
                )}
              </div>
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <EmailIcon style={{ position: "relative", top: -1 }} />
              <div>
                {!organisationProfile.contactEmail ? (
                  <Typography style={{ color: color.grey }}>
                    {t("Not set", {
                      ns: "Global",
                    })}
                  </Typography>
                ) : (
                  <Link href={`mailto:${organisationProfile.contactEmail}`}>
                    {organisationProfile.contactEmail}
                  </Link>
                )}
              </div>
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack spacing={1}>
              <LanguageIcon style={{ position: "relative", top: -1 }} />
              <div>
                {!organisationProfile.contactWebsite ? (
                  <Typography style={{ color: color.grey }}>
                    {t("Not set", {
                      ns: "Global",
                    })}
                  </Typography>
                ) : (
                  <Link
                    href={prepareUrl(organisationProfile.contactWebsite)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {organisationProfile.contactWebsite}
                  </Link>
                )}
              </div>
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            <LabeledOpeningHoursValue
              value={organisationProfile.openingHours}
              label={t("Opening hours", {
                ns: "OpeningHoursField",
              })}
              notSetText={t("Not set", {
                ns: "Global",
              })}
              closedText={t("closed", {
                ns: "OpeningHoursField",
              })}
            />
          </Grid>
        </Grid>
      </Stack>
    </CardContainer>
  );
};
