import { gql, useApolloClient } from "@apollo/client";
import { replaceInText } from "@msys/textutils";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useUserData } from "../../auth/useUserData";
import { ALLOW_INVITE_EXISTING_CONTRACTEE } from "../../constants";
import { namedOperations } from "../../../clients/graphqlTypes";
import { useSalutation } from "../users/useSalutation";
import { useAddProjectContracteeInvitationMutation } from "./modals/AddProjectMemberCRMModal.generated";
import { InviteContractee_ProjectFragment } from "./useProjectContracteeInvite.generated";

export function useProjectContracteeInvite(
  project: InviteContractee_ProjectFragment
) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["QuoteSend"]);
  const viewer = useUserData().currentUser!;
  const { getNameWithSalutation } = useSalutation();

  const client = useApolloClient();
  const [addProjectContracteeInvitation] =
    useAddProjectContracteeInvitationMutation({ client });

  const handleInviteContractee = async (memberId: string) => {
    if (!project || !project.crmOrganisation) return;

    const member = project.crmOrganisation.members.find(m => m.id === memberId);
    if (!member) return;

    if (!member.email) {
      enqueueSnackbar(
        t("Cannot send invitation - no email set for CRM person", {
          ns: "QuoteSend",
        }),
        { variant: "error" }
      );
      return;
    }

    const existingInvitation = project.contracteeInvitations.find(
      invitation => invitation.contact.id === memberId
    );
    if (existingInvitation) {
      enqueueSnackbar(
        t("Invitation was already sent", {
          ns: "QuoteSend",
        }),
        { variant: "error" }
      );
      return;
    }

    await addProjectContracteeInvitation({
      variables: {
        input: {
          contactUserId: memberId,
          contactOrganisationId: project.crmOrganisation.id,
          projectId: project.id,
          messageBody: replaceInText(
            t(
              "Dear [NAME], Join our project {projectName} on MeisterSystems. Kind Regards, {authorName}",
              {
                ns: "ProjectMembers",
                projectName: project.title,
                authorName: viewer.firstname + " " + viewer.familyname,
              }
            ),
            {
              "[NAME]": getNameWithSalutation(
                member.title,
                member.firstname,
                member.familyname
              ),
            }
          ),
          messageSubject: t(
            "You are invited to join {projectName} on MeisterSystems!",
            {
              ns: "ProjectMembers",
              projectName: project.title,
            }
          ),
          attachments: [],
        },
      },
      refetchQueries: [namedOperations.Query.ProjectOverview],
      awaitRefetchQueries: true,
    });
    enqueueSnackbar(t("Invitation sent out", { ns: "CrmOrganisations" }));
  };

  const viewerOrganisation = viewer.organisation;
  const crmOrganisation = project?.crmOrganisation;

  const showContractee = Boolean(
    viewerOrganisation.capabilities.includes("QUOTING") && crmOrganisation
  );
  const canInviteContractee =
    ALLOW_INVITE_EXISTING_CONTRACTEE &&
    showContractee &&
    (project?.canInviteContractee ?? false);

  return { canInviteContractee, handleInviteContractee };
}
