/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { CrmCompanySelect_CrmCompaniesRecordFragmentDoc } from './CrmCompanySelect.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyOrPersonSelectQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  filterIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterExcludeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CrmCompanyOrPersonSelectQuery = { crmCompanies: { __typename: 'CrmCompanyConnection', totalCount: number, edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, title: string, systemTags: Array<string>, customTags: Array<string>, email: any, abcCategory: Types.CrmOrganisationAbcCategory, businessPartnerStatus?: Types.CrmCompanyBusinessPartnerStatus | null, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } | { __typename: 'MissingPermissions' }, crmPersons: { __typename: 'CrmPersonConnection', totalCount: number, edges: Array<{ __typename: 'CrmPersonEdge', node: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, crmCompany: { __typename: 'CrmCompany', id: string, title: string } } }> } | { __typename: 'MissingPermissions' } };

export type CrmCompanyOrPersonSelect_CrmPersonFragment = { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, crmCompany: { __typename: 'CrmCompany', id: string, title: string } };

export const CrmCompanyOrPersonSelect_CrmPersonFragmentDoc = gql`
    fragment CrmCompanyOrPersonSelect_CrmPerson on CrmPerson {
  id
  title
  description
  website
  isMeister
  familyname
  firstname
  fullname
  locale
  skillset
  avatar {
    ...AttachmentSnapshot
  }
  crmCompany {
    id
    title
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const CrmCompanyOrPersonSelectDocument = gql`
    query CrmCompanyOrPersonSelect($limit: Int!, $filterIds: [ID!], $filterExcludeIds: [ID!], $searchTerm: String) {
  crmCompanies(
    limit: $limit
    filters: {ids: $filterIds, excludeIds: $filterExcludeIds}
    search: $searchTerm
  ) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          ...CrmCompanySelect_CrmCompaniesRecord
        }
      }
      totalCount
    }
  }
  crmPersons(
    limit: $limit
    filters: {ids: $filterIds, excludeIds: $filterExcludeIds}
    search: $searchTerm
  ) {
    ... on CrmPersonConnection {
      edges {
        node {
          id
          ...CrmCompanyOrPersonSelect_CrmPerson
        }
      }
      totalCount
    }
  }
}
    ${CrmCompanySelect_CrmCompaniesRecordFragmentDoc}
${CrmCompanyOrPersonSelect_CrmPersonFragmentDoc}`;

/**
 * __useCrmCompanyOrPersonSelectQuery__
 *
 * To run a query within a React component, call `useCrmCompanyOrPersonSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyOrPersonSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanyOrPersonSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filterIds: // value for 'filterIds'
 *      filterExcludeIds: // value for 'filterExcludeIds'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCrmCompanyOrPersonSelectQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanyOrPersonSelectQuery, CrmCompanyOrPersonSelectQueryVariables> & ({ variables: CrmCompanyOrPersonSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanyOrPersonSelectQuery, CrmCompanyOrPersonSelectQueryVariables>(CrmCompanyOrPersonSelectDocument, options);
      }
export function useCrmCompanyOrPersonSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanyOrPersonSelectQuery, CrmCompanyOrPersonSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanyOrPersonSelectQuery, CrmCompanyOrPersonSelectQueryVariables>(CrmCompanyOrPersonSelectDocument, options);
        }
export function useCrmCompanyOrPersonSelectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmCompanyOrPersonSelectQuery, CrmCompanyOrPersonSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanyOrPersonSelectQuery, CrmCompanyOrPersonSelectQueryVariables>(CrmCompanyOrPersonSelectDocument, options);
        }
export type CrmCompanyOrPersonSelectQueryHookResult = ReturnType<typeof useCrmCompanyOrPersonSelectQuery>;
export type CrmCompanyOrPersonSelectLazyQueryHookResult = ReturnType<typeof useCrmCompanyOrPersonSelectLazyQuery>;
export type CrmCompanyOrPersonSelectSuspenseQueryHookResult = ReturnType<typeof useCrmCompanyOrPersonSelectSuspenseQuery>;
export type CrmCompanyOrPersonSelectQueryResult = Apollo.QueryResult<CrmCompanyOrPersonSelectQuery, CrmCompanyOrPersonSelectQueryVariables>;