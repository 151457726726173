import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { FormattedPrice, LoadingSpinner as LoadingContainer } from "@msys/ui";
import { Typography } from "@mui/material";
import { Stack } from "../../commons/layout/Stack";
import { useQuotePriceBudgetSummaryQuery } from "./QuotePriceBudgetSummary.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  quoteId: string;
  projectId: string;
}

export function QuotePriceBudgetSummary({ projectId, quoteId }: Props) {
  const { t } = useTranslate("QuotePriceSummary");
  const client = useApolloClient();
  const query = useQuotePriceBudgetSummaryQuery({
    client,
    variables: {
      projectId,
      quoteId,
    },
    fetchPolicy: "network-only",
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const quote = getDataOrNull(query.data?.outgoingQuote)?.quote;
  const quotes =
    getDataOrNull(query.data?.projectOutgoingQuotes)?.edges?.map(e => e.node) ??
    [];

  if (!query.data && query.loading) return <LoadingContainer padding={1} />;
  if (!quote) return <div>quote not found</div>;
  if (!project) return <div>project not found</div>;

  const budgetTotal = project?.budget?.amount || 0;
  const budgetSpent =
    quotes
      .filter(
        quote =>
          quote.id !== quoteId &&
          quote.isPublished &&
          quote.isBinding &&
          quote.agreement === "YES"
      )
      .reduce((acc, quote) => acc + (quote?.proposedTotalPrice || 0), 0) || 0;
  const budgetLeft = budgetTotal - budgetSpent;
  const priceTotal = quote.proposedCalculation?.priceTotal || 0;

  return (
    <Stack flexDirection="column" style={{ width: "100%" }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Typography>{t("Remaining budget")}</Typography>
        <Typography style={{ whiteSpace: "nowrap" }}>
          <FormattedPrice value={budgetLeft} />
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Typography style={{ fontWeight: "bold" }}>
          {t("Difference to budget")}
        </Typography>
        <Typography
          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          color={priceTotal > budgetLeft ? "error" : "initial"}
        >
          {priceTotal > budgetLeft ? "+" : ""}
          <FormattedPrice value={Math.abs(budgetLeft - priceTotal)} />
        </Typography>
      </Stack>
    </Stack>
  );
}
