/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type _3d_RequestAddProductDataUrlMutationVariables = Types.Exact<{
  input: Types._3d_RequestAddProductDataUrlInput;
}>;


export type _3d_RequestAddProductDataUrlMutation = { _3d_requestAddProductDataUrl: { __typename: 'RequestUploadUrlResult', uploadUrl: string } };


export const _3d_RequestAddProductDataUrlDocument = gql`
    mutation _3d_requestAddProductDataUrl($input: _3d_RequestAddProductDataUrlInput!) {
  _3d_requestAddProductDataUrl(input: $input) {
    uploadUrl
  }
}
    `;
export type _3d_RequestAddProductDataUrlMutationFn = Apollo.MutationFunction<_3d_RequestAddProductDataUrlMutation, _3d_RequestAddProductDataUrlMutationVariables>;

/**
 * __use_3d_RequestAddProductDataUrlMutation__
 *
 * To run a mutation, you first call `use_3d_RequestAddProductDataUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `use_3d_RequestAddProductDataUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [3dRequestAddProductDataUrlMutation, { data, loading, error }] = use_3d_RequestAddProductDataUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function use_3d_RequestAddProductDataUrlMutation(baseOptions?: Apollo.MutationHookOptions<_3d_RequestAddProductDataUrlMutation, _3d_RequestAddProductDataUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<_3d_RequestAddProductDataUrlMutation, _3d_RequestAddProductDataUrlMutationVariables>(_3d_RequestAddProductDataUrlDocument, options);
      }
export type _3d_RequestAddProductDataUrlMutationHookResult = ReturnType<typeof use_3d_RequestAddProductDataUrlMutation>;
export type _3d_RequestAddProductDataUrlMutationResult = Apollo.MutationResult<_3d_RequestAddProductDataUrlMutation>;
export type _3d_RequestAddProductDataUrlMutationOptions = Apollo.BaseMutationOptions<_3d_RequestAddProductDataUrlMutation, _3d_RequestAddProductDataUrlMutationVariables>;