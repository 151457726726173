/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type InvoiceCalculationFragment = { __typename: 'InvoiceCalculation', discountRate: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, vatRate: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workRate: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, extraItemsPriceSubTotal: number, extraItemsPriceNetTotal: number, extraItemsPriceVatTotal: number, extraItemsPriceTotal: number, paymentItemsPriceSubTotal: number, paymentItemsPriceNetTotal: number, paymentItemsPriceVatTotal: number, paymentItemsPriceTotal: number, openPriceNetTotal: number, openPriceVatTotal: number, openPriceTotal: number, finalPriceNetTotal: number, finalPriceVatTotal: number, finalPriceTotal: number };

export const InvoiceCalculationFragmentDoc = gql`
    fragment InvoiceCalculation on InvoiceCalculation {
  discountRate
  materialPriceDiscountedSubTotal
  materialPricePerUnit
  materialPriceSubTotal
  vatRate
  priceNetTotal
  pricePerUnit
  priceSubTotal
  priceTotal
  priceVatTotal
  quantity
  quantityUnit
  timePerUnit
  timeTotal
  workRate
  workPriceDiscountedSubTotal
  workPricePerUnit
  workPriceSubTotal
  extraItemsPriceSubTotal
  extraItemsPriceNetTotal
  extraItemsPriceVatTotal
  extraItemsPriceTotal
  paymentItemsPriceSubTotal
  paymentItemsPriceNetTotal
  paymentItemsPriceVatTotal
  paymentItemsPriceTotal
  openPriceNetTotal
  openPriceVatTotal
  openPriceTotal
  finalPriceNetTotal
  finalPriceVatTotal
  finalPriceTotal
}
    `;