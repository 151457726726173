import { useApolloClient } from "@apollo/client";
import { CardContainer, ImageWithPreview } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TimerIcon from "@mui/icons-material/Timer";
import {
  Box,
  Button,
  FormLabel,
  Step,
  stepClasses,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  StepLabelProps,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Checkbox } from "formik-mui";
import { isNull } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import {
  AttachmentInput,
  ModifyItemTaskInfoValuesInput,
  PermissionName,
} from "../../../../clients/graphqlTypes";
import { cssValue } from "../../../../common/MuiThemeProvider";
import { ReactComponent as QAIcon } from "../../../assets/icons/icon-qa.svg";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission";
import { useUserData } from "../../../auth/useUserData";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { Stack } from "../../../commons/layout/Stack";
import {
  PhotoApprovalProcess,
  PhotoApprovalProcessRef,
} from "../../tasks/PhotoApprovalProcess";
import {
  usePhotoApproveTaskItemMutation,
  usePhotoDisapproveTaskItemMutation,
} from "../../tasks/Tasks.generated";
import {
  RequiredOnCompletionBox_ItemFragment,
  RequiredOnCompletionBox_ProjectFragment,
} from "./RequiredOnCompletionBox.generated";
import { useQuoteModifyItemTaskInfo_TaskManagementBoxMutation } from "./TaskManagementBox.generated";

interface FormValues {
  timeTrackingRequired: boolean;
  approvalRequired: boolean;
  photoApprovalRequired: boolean;
  qaApprovalRequired: boolean;
}

interface Props {
  project: RequiredOnCompletionBox_ProjectFragment | null;
  docId: string;
  document: { viewerPermissions: PermissionName[] };
  item: RequiredOnCompletionBox_ItemFragment;
  canEdit?: boolean;
  isInitiallyClosed?: boolean;
  canAddPhotoApproval?: boolean;
  onUpdateDataRefetchQueries?: string[];
}

export const RequiredOnCompletionBox = ({
  project,
  docId,
  document,
  item,
  canEdit = false,
  isInitiallyClosed,
  canAddPhotoApproval,
  onUpdateDataRefetchQueries,
}: Props) => {
  const { t } = useTranslate("QuoteItemTaskManagementBox");
  const { enqueueSnackbar } = useSnackbar();

  const photoApprovalRef = React.useRef<PhotoApprovalProcessRef>(null);

  const client = useApolloClient();

  const [modifyItemTaskInfo] =
    useQuoteModifyItemTaskInfo_TaskManagementBoxMutation({
      client,
      refetchQueries: onUpdateDataRefetchQueries,
      awaitRefetchQueries: true,
    });
  const [photoDisapproveTaskItem, { loading: photoDisapproveTaskItemLoading }] =
    usePhotoDisapproveTaskItemMutation({
      client,
      refetchQueries: onUpdateDataRefetchQueries,
      awaitRefetchQueries: true,
    });
  const [
    photoApproveTaskItemMutation,
    { loading: photoApproveTaskItemLoading },
  ] = usePhotoApproveTaskItemMutation({
    client,
    refetchQueries: onUpdateDataRefetchQueries,
    awaitRefetchQueries: true,
  });

  const modifyTaskItem = async (values: ModifyItemTaskInfoValuesInput) => {
    await modifyItemTaskInfo({
      variables: {
        input: {
          projectId: project?.id ?? null,
          docId,
          itemId: item.id,
          values,
        },
      },
    });
  };

  const initialValues: FormValues = {
    timeTrackingRequired: item.timeTrackingRequired,
    approvalRequired: item.approvalRequired,
    photoApprovalRequired: item.photoApprovalRequired,
    qaApprovalRequired: item.qaApprovalRequired,
  };

  const showClientInspection = item.isVisibleToOtherSide;

  const onSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    try {
      await modifyTaskItem({
        timeTrackingRequired: values.timeTrackingRequired,
        photoApprovalRequired: values.photoApprovalRequired,
        qaApprovalRequired: values.qaApprovalRequired,
        ...(showClientInspection
          ? {
              approvalRequired: values.approvalRequired,
            }
          : {}),
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
      resetForm({ values: initialValues });
    }
  };

  const photoApproveTaskItem = async (
    itemId: string,
    docId: string,
    photo: AttachmentInput
  ) => {
    try {
      await photoApproveTaskItemMutation({
        variables: { input: { projectId: project!.id, docId, itemId, photo } },
      });
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  if (!document) return null;

  return (
    <CardContainer
      isExpandable
      isInitiallyClosed={isInitiallyClosed}
      disableAutoExpand
      title={t("Required on completion")}
    >
      <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <Stack flexDirection="column" m={1}>
            <Stepper
              sx={{
                padding: "8px 0 4px",
                border: "none",
                [`& > .${stepClasses.root}:first-of-type > .${stepConnectorClasses.root}`]:
                  {
                    display: "none",
                  },
              }}
              alternativeLabel
              nonLinear
              orientation="horizontal"
              activeStep={-1}
              connector={
                <StepConnector
                  sx={{
                    horizontal: {
                      position: "absolute",
                      top: "18px",
                      left: "calc(-50% + 18px)",
                      right: "calc(50% + 18px)",
                    },
                  }}
                />
              }
            >
              <RestrictedByCapabilityWithDebug capability="TIME_TRACKING">
                <RestrictedByDocumentPermissionWithDebug
                  permission="EXECUTE_TASK"
                  document={document}
                >
                  <StepperStep
                    StepIconComponent={() => <TimerIcon />}
                    isRequired={item.timeTrackingRequired}
                    isFulfilled={item.workSessions.length > 0}
                  >
                    <LabelRequired
                      name="timeTrackingRequired"
                      title={t("Time tracking")}
                    />
                    {canEdit && (
                      <RestrictedByDocumentPermissionWithDebug
                        permission="MANAGE_TASK"
                        document={document}
                      >
                        <CheckboxRequired
                          name="timeTrackingRequired"
                          disabled={false}
                        />
                      </RestrictedByDocumentPermissionWithDebug>
                    )}
                  </StepperStep>
                </RestrictedByDocumentPermissionWithDebug>
              </RestrictedByCapabilityWithDebug>
              <StepperStep
                StepIconComponent={() => <AddAPhotoIcon />}
                isRequired={item.photoApprovalRequired}
                isFulfilled={Boolean(item.photoApproved)}
              >
                <LabelRequired
                  name="photoApprovalRequired"
                  title={t("Photo")}
                />
                {canEdit && (
                  <RestrictedByDocumentPermissionWithDebug
                    permission="MANAGE_TASK"
                    document={document}
                  >
                    <CheckboxRequired
                      name="photoApprovalRequired"
                      disabled={false}
                    />
                  </RestrictedByDocumentPermissionWithDebug>
                )}
              </StepperStep>
              <RestrictedByDocumentPermissionWithDebug
                permission="EXECUTE_TASK"
                document={document}
              >
                <StepperStep
                  StepIconComponent={() => <QAIcon />}
                  isRequired={item.qaApprovalRequired}
                  isApproved={
                    !isNull(item.isQaApproved) ? item.isQaApproved : undefined
                  }
                >
                  <LabelRequired
                    name="qaApprovalRequired"
                    title={t("Quality Assurance")}
                  />
                  {canEdit && (
                    <RestrictedByDocumentPermissionWithDebug
                      permission="MANAGE_TASK"
                      document={document}
                    >
                      <CheckboxRequired
                        name="qaApprovalRequired"
                        disabled={false}
                      />
                    </RestrictedByDocumentPermissionWithDebug>
                  )}
                </StepperStep>
              </RestrictedByDocumentPermissionWithDebug>

              {showClientInspection && (
                <StepperStep
                  StepIconComponent={() =>
                    item.isApproved === false ? (
                      <ThumbDownIcon />
                    ) : (
                      <ThumbUpIcon />
                    )
                  }
                  isRequired={item.approvalRequired}
                  isApproved={
                    !isNull(item.isApproved) ? item.isApproved : undefined
                  }
                >
                  <LabelRequired
                    name="approvalRequired"
                    title={t("Client Inspection")}
                  />
                  {canEdit && (
                    <RestrictedByDocumentPermissionWithDebug
                      permission="MANAGE_TASK"
                      document={document}
                    >
                      <CheckboxRequired
                        name="approvalRequired"
                        disabled={false}
                      />
                    </RestrictedByDocumentPermissionWithDebug>
                  )}
                </StepperStep>
              )}
            </Stepper>
            {project &&
              (item.photoApproved ? (
                <ImageWithPreview
                  src={item.photoApproved.url}
                  title={item.photoApproved.title}
                  loading={photoDisapproveTaskItemLoading}
                  borderRadius={cssValue.boxBorderRadius}
                  handleDelete={async () =>
                    await photoDisapproveTaskItem({
                      variables: {
                        input: {
                          projectId: project.id,
                          docId,
                          itemId: item.id,
                        },
                      },
                    })
                  }
                  renderDeleteButton={button => (
                    <RestrictedByDocumentPermissionWithDebug
                      permission="MANAGE_TASK"
                      document={document}
                    >
                      {button}
                    </RestrictedByDocumentPermissionWithDebug>
                  )}
                />
              ) : (
                canAddPhotoApproval &&
                item.canBeWorkedOn &&
                !item.deletedAt && (
                  <RestrictedByDocumentPermissionWithDebug
                    permission="EXECUTE_TASK"
                    document={document}
                  >
                    <Box display="flex" justifyContent="center">
                      <Button
                        startIcon={<AddIcon />}
                        color="secondary"
                        variant="text"
                        onClick={() => {
                          photoApprovalRef.current?.checkPhotoApprove(
                            item.id,
                            item.docId,
                            false,
                            true
                          );
                        }}
                      >
                        {t("Add completion photo")}
                      </Button>
                    </Box>
                    <PhotoApprovalProcess
                      ref={photoApprovalRef}
                      loading={photoApproveTaskItemLoading}
                      photoApproveTaskItem={photoApproveTaskItem}
                      projectId={project.id}
                    />
                  </RestrictedByDocumentPermissionWithDebug>
                )
              ))}
            <AutoSave />
          </Stack>
        </Form>
      </Formik>
    </CardContainer>
  );
};

type CheckboxName =
  | "timeTrackingRequired"
  | "approvalRequired"
  | "photoApprovalRequired"
  | "qaApprovalRequired";

const CheckboxRequired = ({
  name,
  disabled,
}: {
  name: CheckboxName;
  disabled: boolean;
}) => (
  <Stack spacing={0} flex="1" alignItems="center" justifyContent="center">
    <Field
      id={`check-${name}`}
      component={Checkbox}
      type="checkbox"
      name={name}
      disabled={disabled}
    />
  </Stack>
);

const LabelRequired = ({
  name,
  title,
  basis,
}: {
  name?: CheckboxName;
  title: string;
  basis?: string;
}) => {
  const theme = useTheme();
  return (
    <Stack
      spacing={0}
      px={1 / 2}
      flex="1"
      flexShrink={0}
      flexBasis={basis}
      maxWidth={basis}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <FormLabel color="primary" htmlFor={name ? `check-${name}` : undefined}>
        <Typography
          variant="body2"
          style={{
            color: theme.palette.primary.main,
            hyphens: "auto",
            textAlign: "center",
          }}
          component="span"
        >
          {title}
        </Typography>
      </FormLabel>
    </Stack>
  );
};

const StepperStep = ({
  StepIconComponent,
  isRequired,
  isFulfilled,
  isApproved,
  children,
  ...props
}: React.PropsWithChildren<{
  StepIconComponent: Exclude<StepLabelProps["StepIconComponent"], undefined>;
  isRequired: boolean;
  isFulfilled?: boolean;
  isApproved?: boolean;
}>) => {
  const theme = useTheme();
  return (
    <Step
      {...props}
      sx={{
        horizontal: {
          padding: 0,
        },
        alternativeLabel: {
          alignSelf: "stretch",
        },
      }}
    >
      <StepLabel
        StepIconComponent={isRequired ? StepIconComponent : CircleIcon}
        sx={{
          root: {
            height: "100%",
          },
          labelContainer: {
            flexGrow: 1,
          },
          label: {
            height: "calc(100% - 16px)",
            "&&.MuiStepLabel-alternativeLabel": {
              marginTop: 12,
            },
          },
          iconContainer: {
            padding: "6px !important",
            borderRadius: "50%",
            backgroundColor: (() => {
              if (!isRequired) return "transparent";
              if (isApproved === true) return theme.palette.success.main;
              if (isApproved === false) return theme.palette.error.main;
              if (isFulfilled === true) return theme.palette.success.main;
              if (isRequired) return theme.palette.grey[400];
              return "transparent";
            })(),
          },
        }}
      >
        <Stack flexDirection="column" height="100%" spacing={0.5}>
          {children}
        </Stack>
      </StepLabel>
    </Step>
  );
};

const CircleIcon = () => (
  <Box
    sx={{
      backgroundColor: theme => theme.palette.grey[400],
      borderRadius: "50%",
      margin: 1,
      height: 8,
      width: 8,
    }}
  ></Box>
);
