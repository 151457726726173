import React from "react";
import { useFormatting } from "../formatting/useFormatting";
import { FormattedInput } from "./FormattedInput";

export const FormattedFloatInput = ({
  value,
  unit,
  min,
  max,
  multiplier = 1,
  onChange,
  ...props
}: {
  unit?: string;
  min?: number;
  max?: number;
  multiplier?: number;
} & Omit<
  React.ComponentProps<typeof FormattedInput<number>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue" | "getError"
>) => {
  const { getFormattedFloat, getEditableFloat, getFloat } = useFormatting();

  function getFormattedValue(value: number | null | undefined) {
    if (value === null || value === undefined) return "";

    return getFormattedUnit(getFormattedFloat((value ?? 0) * multiplier), unit);
  }

  function getEditableValue(value: number | null | undefined) {
    if (value === null || value === undefined) return "";

    return getEditableFloat(value * multiplier, false);
  }

  const getActualValue = React.useCallback(
    (value: string | null) => {
      if (value === null || value === "") return null;

      const float = getFloat(value, false);
      validate(float, min, max);

      return float / multiplier;
    },
    [getFloat, max, min, multiplier]
  );

  return (
    <FormattedInput
      {...props}
      value={value}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
      onChange={onChange}
    />
  );
};

function validate(
  value: number,
  min: number | undefined,
  max: number | undefined
) {
  if (Number.isNaN(value)) throw new Error("Invalid value"); // TODO: translate
  if (min !== undefined && value < min)
    throw new Error("Value is < min allowed"); // TODO: translate
  if (max !== undefined && value > max)
    throw new Error("Value is > max allowed"); // TODO: translate
}

function getFormattedUnit(value: string, unit?: string) {
  return `${value}${unit ?? ""}`.trim();
}
