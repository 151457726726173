import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { ManualSave } from "../../commons/form-fields/ManualSave";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageGrid } from "../../commons/layout/PageGrid";
import {
  FormValues as ProductEditHeaderBoxFormValues,
  ProductEditHeaderBox,
  useValidationSchema as useProductEditHeaderBoxFormValuesValidationSchema,
} from "../../features/products/boxes/ProductEditHeaderBox";
import {
  FormValues as ProductEditInstallationBoxFormValues,
  ProductEditInstallationBox,
  useValidationSchema as useProductEditInstallationBoxValidationSchema,
} from "../../features/products/boxes/ProductEditInstallationBox";
import {
  FormValues as ProductEditSupplierBoxFormValues,
  ProductEditSupplierBox,
  useValidationSchema as useProductEditSupplierBoxValidationSchema,
} from "../../features/products/boxes/ProductEditSupplierBox";
import {
  FormValues as FilesBoxFormValues,
  ProductOverviewFilesBoxForm,
  useValidationSchema as useFilesBoxValidationSchema,
} from "../../features/products/boxes/ProductOverviewFilesBox";
import {
  FormValues as SupplierProductPropertyBoxFormValues,
  ProductOverviewSupplierProductPropertyFormBox,
  useValidationSchema as useSupplierProductPropertyBoxValidationSchema,
} from "../../features/products/boxes/ProductOverviewSupplierProductPropertyBox";
import {
  FormValues as ThreeDBoxFormValues,
  ProductOverviewThreeDBoxForm,
  useValidationSchema as useThreeDBoxValidationSchema,
} from "../../features/products/boxes/ProductOverviewThreeDBox";
import {
  pimProductPropertyToInput,
  useProductBreadcrumbs,
} from "../../features/products/helper";
import { ProductOverviewSubHeader } from "./ProductOverviewSubHeader";
import {
  useSupplierCatalogueProductEdit__PimModifySupplierProductMutation,
  useSupplierProductEdit__GetSupplierProductByArticleNumberIdentifierQuery,
} from "./SupplierProductEdit.generated";

interface Props {
  submenuItems: PageTopbarItem[];
}

type FormValues = ProductEditHeaderBoxFormValues &
  ProductEditInstallationBoxFormValues &
  ProductEditSupplierBoxFormValues &
  SupplierProductPropertyBoxFormValues &
  FilesBoxFormValues &
  ThreeDBoxFormValues;

export const SupplierProductEdit = ({ submenuItems }: Props) => {
  const { supplierId, articleNumber } = useParams<{
    supplierId: string;
    articleNumber: string;
  }>();
  if (!(supplierId && articleNumber))
    throw new Error("Product identifier is missing");

  const { t } = useTranslate(["OrganisationPageTopbar", "Product"]);

  const navigate = useNavigate();

  const client = useApolloClient();
  const query =
    useSupplierProductEdit__GetSupplierProductByArticleNumberIdentifierQuery({
      client,
      variables: { supplierId, articleNumber },
    });
  const product = getDataOrNull(
    query.data?.pimGetSupplierProduct
  )?.supplierProduct;

  const [modifySupplierCatalogueProductMutation] =
    useSupplierCatalogueProductEdit__PimModifySupplierProductMutation({
      client,
    });

  const productEditHeaderBoxFormValuesValidationSchema =
    useProductEditHeaderBoxFormValuesValidationSchema();
  const productEditInstallationBoxValidationSchema =
    useProductEditInstallationBoxValidationSchema();
  const productEditSupplierBoxValidationSchema =
    useProductEditSupplierBoxValidationSchema();
  const supplierProductPropertyBoxValidationSchema =
    useSupplierProductPropertyBoxValidationSchema();
  const filesBoxValidationSchema = useFilesBoxValidationSchema();
  const threeDBoxValidationSchema = useThreeDBoxValidationSchema();

  const validationSchema = React.useMemo(
    () =>
      Yup.object()
        .shape({})
        .concat(productEditHeaderBoxFormValuesValidationSchema)
        .concat(productEditInstallationBoxValidationSchema)
        .concat(productEditSupplierBoxValidationSchema)
        .concat(supplierProductPropertyBoxValidationSchema)
        .concat(filesBoxValidationSchema)
        .concat(threeDBoxValidationSchema),
    [
      productEditHeaderBoxFormValuesValidationSchema,
      productEditInstallationBoxValidationSchema,
      productEditSupplierBoxValidationSchema,
      supplierProductPropertyBoxValidationSchema,
      filesBoxValidationSchema,
      threeDBoxValidationSchema,
    ]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");

  const categorySupplierId =
    product && product.__typename === "PimSupplierProduct"
      ? product.supplierProductCategory?.supplierId ?? null
      : null;
  const categoryKey =
    product && product.__typename === "PimSupplierProduct"
      ? product.supplierProductCategory?.key ?? null
      : null;

  const breadcrumbs = useProductBreadcrumbs(
    categorySupplierId,
    categoryKey,
    product?.texts?.title ?? product?.articleNumber ?? ""
  );

  if (!product) {
    return null;
  }

  const initialValues: FormValues = {
    articleNumber: product.articleNumber,
    supplierCatalogue: product.supplierCatalogue,
    texts: {
      titleText: product.texts?.title,
      descriptionText: product.texts?.description,
      extendedDescriptionText: product.texts?.extendedDescription,
      dimensionText: product.texts?.dimensionText,
      marketingText: product.texts?.marketingText,
    },
    productType: product.productType,
    branding: product.branding,
    gtin: product.gtin ?? null,
    manufacturerArticleNumber: product.manufacturerArticleNumber ?? null,
    priceUnit: product.pricing?.priceUnit ?? null,
    priceUnitAmount: product.pricing?.priceUnitAmount ?? 1,
    pricing: product.pricing,
    properties: product.supplierProductProperties,
    attachments: product.attachments,
    threedModel: product.threedModel ?? null,
  };

  const handleSubmit = async (values: FormValues) => {
    if (!(values.articleNumber && values.supplierCatalogue)) {
      throw new Error("Article number and supplier catalogue not provided");
    }

    const modifySupplierProductResult =
      await modifySupplierCatalogueProductMutation({
        variables: {
          input: {
            articleNumber: initialValues.articleNumber,
            supplierId: initialValues.supplierCatalogue!.supplier.id,
            product: {
              supplierCatalogueId: values.supplierCatalogue!.id,
              texts: {
                ...values.texts,
                titleText: values.texts?.titleText ?? "",
              },
              pricing: {
                listPrice: values.pricing?.listPrice ?? 0,
                netPrice: values.pricing?.netPrice ?? null,
                currency: "EUR",
                priceUnit: values.priceUnit || "",
                priceUnitAmount: values.priceUnitAmount,
              },
              branding: values.branding
                ? {
                    brandId: values.branding.brand.id,
                    brandLineId: values.branding?.brandLine?.id,
                  }
                : null,
              gtin: values.gtin || null,
              productTypeId: values.productType?.id ?? null,
              manufacturerArticleNumber:
                values.manufacturerArticleNumber || null,

              attachments: values.attachments.map(attachment => ({
                url: attachment.url,
                mimeType: attachment.mimeType,
                title: attachment.title,
              })),
              properties: values.properties.map(pimProductPropertyToInput),
              threedModelId: values.threedModel ? values.threedModel.id : null,
            },
          },
        },
      });

    if (!modifySupplierProductResult.data) {
      return;
    }

    const product = modifySupplierProductResult.data;

    navigate(
      returnPath ||
        `/products/catalogues/supplier/${
          product.pimModifySupplierProduct.supplierProduct.supplierId
        }/${encodeURIComponent(
          product.pimModifySupplierProduct.supplierProduct.articleNumber
        )}/overview`
    );
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Page
          breadcrumbs={breadcrumbs}
          subtitle={t("Products", {
            ns: "OrganisationPageTopbar",
          })}
          title={product?.texts?.title ?? product?.articleNumber}
          submenuItems={submenuItems}
          subHeader={
            product ? (
              <ProductOverviewSubHeader
                product={product}
                canEdit={false}
                isHeaderVisible={undefined as never}
                setHeaderVisible={undefined as never}
              />
            ) : undefined
          }
          action={
            <ManualSave
              form={formId}
              disabled={false}
              onCancel={() => {
                if (returnPath) {
                  navigate(returnPath);
                  return;
                }
                if (supplierId && articleNumber) {
                  navigate(
                    `/products/catalogues/supplier/${supplierId}/${encodeURIComponent(
                      articleNumber
                    )}/overview`
                  );
                  return;
                }
                navigate(-1);
              }}
              stackProps={{ maxWidth: "340px" }}
            />
          }
        >
          {product && (
            <PageContainer $fullHeight>
              <Form id={formId}>
                <Stack direction="column" spacing={1}>
                  <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
                    <>
                      <ProductEditHeaderBox />
                      <ProductOverviewSupplierProductPropertyFormBox
                        itemCount={product.supplierProductProperties.length}
                        productTypeId={
                          formikProps.values.productType?.id ?? null
                        }
                      />
                    </>
                    <>
                      <ProductEditInstallationBox />
                      <ProductEditSupplierBox
                        supplierDisabled
                        articleNumberDisabled
                      />
                    </>
                    <>
                      <ProductOverviewFilesBoxForm />
                      <ProductOverviewThreeDBoxForm />
                    </>
                  </PageGrid>
                </Stack>
              </Form>
            </PageContainer>
          )}
        </Page>
      )}
    </Formik>
  );
};
