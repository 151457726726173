/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ItemCalculationFragmentDoc, WithDocumentItemTaskInfoFragmentDoc, WithItemPropertiesFragmentDoc, WithProductInfoFragmentDoc, WithDocumentItemCalculationFragmentDoc, DocumentItem_CalculationsFragmentDoc, FieldsAffectedByPropertiesFragmentDoc, FieldsAffectedByAttributeExpressionsFragmentDoc, WithProductFiltersInfoFragmentDoc, TreeItem__DocumentItemFragmentDoc, TreeItemTemplatePlaceholderFragmentDoc, WithDocumentItemInfoFragmentDoc, WithDocumentItemTreeInfoFragmentDoc, WithDocumentItemVisibilityInfoFragmentDoc, WithDocumentItemChangeOrderInfoFragmentDoc, WithDocumentItemAgreementInfoFragmentDoc, WithDocumentItemDecisionInfoFragmentDoc, ItemProductFieldsFragmentDoc } from '../../doc-items/Fragments.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadQuoteAsPdfButton_QuoteFragment = { __typename: 'Quote', id: string, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type DownloadQuoteAsPdfButtonQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DownloadQuoteAsPdfButtonQuery = { organisationContractingPdfs: { __typename: 'OrganisationContractingPdfsResult', contractingPdfs: Array<{ __typename: 'OrganisationContractingPdf', id: string, title: string }> } };

export type GenerateQuotePdfQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
  calculationType: Types.QuotePdfCalculationType;
}>;


export type GenerateQuotePdfQuery = { quoteGeneratePdf: { __typename: 'MissingPermissions' } | { __typename: 'QuoteGeneratePdfResult', file?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } };

export type GenerateQuoteContractingPdfQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
  contractingPdfId: Types.Scalars['ID']['input'];
  calculationType: Types.QuotePdfCalculationType;
}>;


export type GenerateQuoteContractingPdfQuery = { quoteGenerateContractingPdf: { __typename: 'MissingPermissions' } | { __typename: 'QuoteGenerateContractingPdfResult', file?: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } | null } };

export const DownloadQuoteAsPdfButton_QuoteFragmentDoc = gql`
    fragment DownloadQuoteAsPdfButton_Quote on Quote {
  id
  agreedCalculation {
    ...ItemCalculation
  }
}
    ${ItemCalculationFragmentDoc}`;
export const DownloadQuoteAsPdfButtonDocument = gql`
    query DownloadQuoteAsPdfButton {
  organisationContractingPdfs(filters: {enabled: true}) {
    ... on OrganisationContractingPdfsResult {
      contractingPdfs {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useDownloadQuoteAsPdfButtonQuery__
 *
 * To run a query within a React component, call `useDownloadQuoteAsPdfButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadQuoteAsPdfButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadQuoteAsPdfButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useDownloadQuoteAsPdfButtonQuery(baseOptions?: Apollo.QueryHookOptions<DownloadQuoteAsPdfButtonQuery, DownloadQuoteAsPdfButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadQuoteAsPdfButtonQuery, DownloadQuoteAsPdfButtonQueryVariables>(DownloadQuoteAsPdfButtonDocument, options);
      }
export function useDownloadQuoteAsPdfButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadQuoteAsPdfButtonQuery, DownloadQuoteAsPdfButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadQuoteAsPdfButtonQuery, DownloadQuoteAsPdfButtonQueryVariables>(DownloadQuoteAsPdfButtonDocument, options);
        }
export function useDownloadQuoteAsPdfButtonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DownloadQuoteAsPdfButtonQuery, DownloadQuoteAsPdfButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DownloadQuoteAsPdfButtonQuery, DownloadQuoteAsPdfButtonQueryVariables>(DownloadQuoteAsPdfButtonDocument, options);
        }
export type DownloadQuoteAsPdfButtonQueryHookResult = ReturnType<typeof useDownloadQuoteAsPdfButtonQuery>;
export type DownloadQuoteAsPdfButtonLazyQueryHookResult = ReturnType<typeof useDownloadQuoteAsPdfButtonLazyQuery>;
export type DownloadQuoteAsPdfButtonSuspenseQueryHookResult = ReturnType<typeof useDownloadQuoteAsPdfButtonSuspenseQuery>;
export type DownloadQuoteAsPdfButtonQueryResult = Apollo.QueryResult<DownloadQuoteAsPdfButtonQuery, DownloadQuoteAsPdfButtonQueryVariables>;
export const GenerateQuotePdfDocument = gql`
    query GenerateQuotePdf($projectId: ID!, $quoteId: ID!, $calculationType: QuotePdfCalculationType!) {
  quoteGeneratePdf(
    projectId: $projectId
    quoteId: $quoteId
    calculationType: $calculationType
  ) {
    ... on QuoteGeneratePdfResult {
      file {
        id
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;

/**
 * __useGenerateQuotePdfQuery__
 *
 * To run a query within a React component, call `useGenerateQuotePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateQuotePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateQuotePdfQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *      calculationType: // value for 'calculationType'
 *   },
 * });
 */
export function useGenerateQuotePdfQuery(baseOptions: Apollo.QueryHookOptions<GenerateQuotePdfQuery, GenerateQuotePdfQueryVariables> & ({ variables: GenerateQuotePdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateQuotePdfQuery, GenerateQuotePdfQueryVariables>(GenerateQuotePdfDocument, options);
      }
export function useGenerateQuotePdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateQuotePdfQuery, GenerateQuotePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateQuotePdfQuery, GenerateQuotePdfQueryVariables>(GenerateQuotePdfDocument, options);
        }
export function useGenerateQuotePdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateQuotePdfQuery, GenerateQuotePdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateQuotePdfQuery, GenerateQuotePdfQueryVariables>(GenerateQuotePdfDocument, options);
        }
export type GenerateQuotePdfQueryHookResult = ReturnType<typeof useGenerateQuotePdfQuery>;
export type GenerateQuotePdfLazyQueryHookResult = ReturnType<typeof useGenerateQuotePdfLazyQuery>;
export type GenerateQuotePdfSuspenseQueryHookResult = ReturnType<typeof useGenerateQuotePdfSuspenseQuery>;
export type GenerateQuotePdfQueryResult = Apollo.QueryResult<GenerateQuotePdfQuery, GenerateQuotePdfQueryVariables>;
export const GenerateQuoteContractingPdfDocument = gql`
    query GenerateQuoteContractingPdf($projectId: ID!, $quoteId: ID!, $contractingPdfId: ID!, $calculationType: QuotePdfCalculationType!) {
  quoteGenerateContractingPdf(
    projectId: $projectId
    quoteId: $quoteId
    contractingPdfId: $contractingPdfId
    calculationType: $calculationType
  ) {
    ... on QuoteGenerateContractingPdfResult {
      file {
        id
        ...AttachmentSnapshot
      }
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;

/**
 * __useGenerateQuoteContractingPdfQuery__
 *
 * To run a query within a React component, call `useGenerateQuoteContractingPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateQuoteContractingPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateQuoteContractingPdfQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *      contractingPdfId: // value for 'contractingPdfId'
 *      calculationType: // value for 'calculationType'
 *   },
 * });
 */
export function useGenerateQuoteContractingPdfQuery(baseOptions: Apollo.QueryHookOptions<GenerateQuoteContractingPdfQuery, GenerateQuoteContractingPdfQueryVariables> & ({ variables: GenerateQuoteContractingPdfQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateQuoteContractingPdfQuery, GenerateQuoteContractingPdfQueryVariables>(GenerateQuoteContractingPdfDocument, options);
      }
export function useGenerateQuoteContractingPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateQuoteContractingPdfQuery, GenerateQuoteContractingPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateQuoteContractingPdfQuery, GenerateQuoteContractingPdfQueryVariables>(GenerateQuoteContractingPdfDocument, options);
        }
export function useGenerateQuoteContractingPdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateQuoteContractingPdfQuery, GenerateQuoteContractingPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateQuoteContractingPdfQuery, GenerateQuoteContractingPdfQueryVariables>(GenerateQuoteContractingPdfDocument, options);
        }
export type GenerateQuoteContractingPdfQueryHookResult = ReturnType<typeof useGenerateQuoteContractingPdfQuery>;
export type GenerateQuoteContractingPdfLazyQueryHookResult = ReturnType<typeof useGenerateQuoteContractingPdfLazyQuery>;
export type GenerateQuoteContractingPdfSuspenseQueryHookResult = ReturnType<typeof useGenerateQuoteContractingPdfSuspenseQuery>;
export type GenerateQuoteContractingPdfQueryResult = Apollo.QueryResult<GenerateQuoteContractingPdfQuery, GenerateQuoteContractingPdfQueryVariables>;