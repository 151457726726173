import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React from "react";
import { Meister1Flow } from "../../../clients/graphqlTypes";
import {
  useMeister1FlowsLazyQuery,
  useMeister1IntegrationQuery,
} from "./useM1Integration.generated";

export function useM1Integration() {
  const client = useApolloClient();
  const integrationQuery = useMeister1IntegrationQuery({ client });
  const hasMeister1Integration =
    getDataOrNull(integrationQuery.data?.organisationIntegrations)?.edges.some(
      edge => edge.node.integration.name === "meister1"
    ) ?? false;

  const [fetchFlows, flowsQuery] = useMeister1FlowsLazyQuery({
    client,
  });

  return {
    hasMeister1Integration,
    getMeister1Flows: fetchFlows,
    isLoading: integrationQuery.loading || flowsQuery.loading,
  };
}

const EMPTY_M1_FLOWS_ARRAY: Array<Meister1Flow> = [];

export function useM1Flows() {
  const [meister1Flows, setMeiste1Flows] = React.useState<Array<Meister1Flow>>(
    []
  );

  const { hasMeister1Integration, getMeister1Flows, isLoading } =
    useM1Integration();

  React.useEffect(() => {
    if (hasMeister1Integration) {
      getMeister1Flows().then(result => {
        setMeiste1Flows(result.data?.meister1Flows ?? EMPTY_M1_FLOWS_ARRAY);
      });
    } else {
      setMeiste1Flows(EMPTY_M1_FLOWS_ARRAY);
    }
  }, [getMeister1Flows, hasMeister1Integration]);

  return { hasMeister1Integration, meister1Flows, isLoading };
}
