import React, { useCallback, useMemo, useRef, useState } from "react";
import { Menu } from "@mui/material";

const initialState = { mouseX: null, mouseY: null };

export const useContextMenu = <T extends HTMLElement>(
  menu?: React.ReactNode
): [(e: React.MouseEvent<T>) => void, JSX.Element | null] => {
  const [state, setState] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>(initialState);

  const handleClick = useCallback(
    (e: React.MouseEvent<T>) => {
      if (!menu) return;
      e.preventDefault();
      e.stopPropagation();
      setState({ mouseX: e.clientX - 2, mouseY: e.clientY - 4 });
    },
    [menu]
  );

  const handleClose = useRef(() => {
    setState(initialState);
  });

  const menuElement = useMemo(
    () =>
      state.mouseY !== null && state.mouseX !== null ? (
        <Menu
          keepMounted
          open={true}
          onClose={handleClose.current}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleClose.current();
          }}
          anchorReference="anchorPosition"
          anchorPosition={{ top: state.mouseY, left: state.mouseX }}
        >
          {menu}
        </Menu>
      ) : null,
    [state, menu]
  );

  return [handleClick, menuElement];
};
