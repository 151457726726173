import { Divider, Typography } from "@mui/material";

interface Props {
  label?: string;
}

export const ButtonDivider = ({ label }: Props) => {
  return (
    <div>
      <Divider>
        {label && (
          <Typography
            color={theme => theme.palette.grey[500]}
            textTransform={"uppercase"}
          >
            {label}
          </Typography>
        )}
      </Divider>
    </div>
  );
};
