/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { QuoteTemplateShareButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateShareButton.generated';
import { QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateSubscribeButton.generated';
import { QuoteTemplateUnsubscribeButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateUnsubscribeButton.generated';
import { QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateAddToShopButton.generated';
import { QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplateFragmentDoc } from './buttons/QuoteTemplateUpdateSubscribedVersionButton.generated';
export type TemplateQuoteAction_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, latestPublishedVersionNumber?: number | null, owningSystemOrganisationId: string, hasAnyPublishedVersion: boolean, accessType: Types.TemplateAccessType, sharedPublicly: boolean, containsUnpublishedChanged: boolean, addedToShop: boolean, usedInOtherTemplates: boolean, subscribedToTemplate?: { __typename: 'TemplateSubscription', updateAvailable: boolean, subscribedVersionNumber: number } | null, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> };

export const TemplateQuoteAction_QuoteTemplateFragmentDoc = gql`
    fragment TemplateQuoteAction_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  latestPublishedVersionNumber
  owningSystemOrganisationId
  hasAnyPublishedVersion
  accessType
  sharedPublicly
  containsUnpublishedChanged
  subscribedToTemplate {
    updateAvailable
  }
  ...QuoteTemplateShareButton_QuoteTemplate
  ...QuoteTemplateSubscribeButton_QuoteTemplate
  ...QuoteTemplateUnsubscribeButton_QuoteTemplate
  ...QuoteTemplateAddToShopButton_QuoteTemplate
  ...QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplate
}
    ${QuoteTemplateShareButton_QuoteTemplateFragmentDoc}
${QuoteTemplateSubscribeButton_QuoteTemplateFragmentDoc}
${QuoteTemplateUnsubscribeButton_QuoteTemplateFragmentDoc}
${QuoteTemplateAddToShopButton_QuoteTemplateFragmentDoc}
${QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplateFragmentDoc}`;