import { gql } from "@apollo/client";
import { MenuButton, StatusChip } from "@msys/ui";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem } from "@mui/material";
import React from "react";
import { useProjectChangePhase } from "./useProjectChangePhase";
import {
  ProjectPhaseChip_OrganisationSettingsFragment,
  ProjectPhaseChip_ProjectFragment,
} from "./ProjectPhaseChip.generated";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes";

interface Props {
  projectId: string;
  currentState: ProjectStateMachineStatus;
  currentPhase: ProjectPhaseChip_ProjectFragment["phase"];
  availablePhases:
    | ProjectPhaseChip_OrganisationSettingsFragment["projectPhases"]
    | undefined;
}

export const ProjectPhaseChip = ({
  projectId,
  currentState,
  currentPhase,
  availablePhases = [],
}: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { handlePhaseChange } = useProjectChangePhase({
    projectId,
    projectPhaseId: currentPhase.id,
  });

  return (
    <MenuButton
      Button={
        <StatusChip
          disabled={availablePhases.length < 1}
          label={currentPhase.name}
          endIcon={
            <KeyboardArrowDown
              fontSize="small"
              sx={{
                transition: "transform 0.2s ease-out",
                ...(open ? { transform: `rotate(-180deg)` } : undefined),
              }}
            />
          }
        />
      }
      onToggle={setOpen}
    >
      {availablePhases
        .filter(
          phase =>
            phase.applicableForState === currentState &&
            phase.id !== currentPhase.id
        )
        .map(phase => (
          <MenuItem
            key={phase.id}
            onClick={async event => {
              await handlePhaseChange(phase.id);
            }}
          >
            {phase.name}
          </MenuItem>
        ))}
    </MenuButton>
  );
};
