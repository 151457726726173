import {
  Paper,
  Stack,
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableCellProps,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from "@mui/material";
import React from "react";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { LoadingSpinner as LoadingContainer } from "../loading/LoadingSpinner";

export interface TableColumn<TItem> {
  id: string;
  title?: React.ReactNode;
  render: (row: TItem, index: number) => React.ReactNode;
  cellStyle?: React.CSSProperties;
  isFullWidth?: boolean;
  width?: number | string;
  colSpan?: number;
  textAlign?: "right" | "left" | "center";
  padding?: TableCellProps["padding"];
}

export interface TableInnerProps<TItem> {
  columns: TableColumn<TItem>[];
  items?: TItem[];
  selectedId?: string | null;
  onRowClick?: (row: TItem, event: React.MouseEvent<HTMLElement>) => void;
}

export interface TableMobileInnerProps<TItem> {
  items?: TItem[];
  onRowClick?: (row: TItem, event: React.MouseEvent<HTMLElement>) => void;
  renderPhoneRow: (row: TItem, index: number) => JSX.Element | null;
  mobileStackSpacing?: number;
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface TableProps<TItem>
  extends TableInnerProps<TItem>,
    PartialBy<TableMobileInnerProps<TItem>, "renderPhoneRow"> {
  isLoading?: boolean;
  renderEmptyScreen?: () => JSX.Element | null;
}

export function Table<TRow extends { id: string }>({
  columns,
  items,
  selectedId,
  onRowClick,
  isLoading,
  renderPhoneRow,
  mobileStackSpacing = 1,
  renderEmptyScreen,
}: TableProps<TRow>) {
  const { isMinTablet } = useScreenWidth();

  if (isLoading) return <LoadingContainer />;

  if ((!items || items.length === 0) && renderEmptyScreen) {
    return renderEmptyScreen();
  }

  if (isMinTablet || !renderPhoneRow) {
    return (
      <Paper>
        <TableInner
          columns={columns}
          items={items}
          selectedId={selectedId}
          onRowClick={onRowClick}
        />
      </Paper>
    );
  }

  return (
    <Paper>
      <TableMobileInner
        items={items}
        onRowClick={onRowClick}
        renderPhoneRow={renderPhoneRow}
        mobileStackSpacing={mobileStackSpacing}
      />
    </Paper>
  );
}

export function TableInner<TRow extends { id: string }>({
  columns,
  items,
  selectedId,
  onRowClick,
  size = "small",
  ...props
}: TableInnerProps<TRow> & MuiTableProps) {
  return (
    <MuiTable size={size} {...props}>
      <colgroup>
        {columns.map(column => (
          <col key={column.id} width={column.width} />
        ))}
      </colgroup>
      <MuiTableHead>
        <MuiTableRow>
          {columns.map(column => (
            <MuiTableCell
              key={column.id}
              padding={column.padding}
              style={{
                textAlign: column.textAlign,
                width:
                  column.width ?? (column.isFullWidth ? "100%" : undefined),
                verticalAlign: "bottom",
              }}
            >
              {column.title}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody>
        {items?.map((item, index) => (
          <MuiTableRow
            data-testid={item.id}
            key={item.id}
            selected={selectedId === item.id}
            onClick={event => onRowClick?.(item, event)}
            style={{
              cursor:
                onRowClick && selectedId !== item.id ? "pointer" : "unset",
            }}
            hover={!!(onRowClick && selectedId !== item.id)}
          >
            {columns.map(column => (
              <MuiTableCell
                key={column.id}
                colSpan={column.colSpan ?? undefined}
                padding={column.padding}
                style={{
                  textAlign: column.textAlign,
                  width:
                    column.width ?? (column.isFullWidth ? "100%" : undefined),
                  ...column.cellStyle,
                }}
              >
                {column.render(item, index)}
              </MuiTableCell>
            ))}
          </MuiTableRow>
        ))}
      </MuiTableBody>
    </MuiTable>
  );
}

export function TableMobileInner<TRow extends { id: string }>({
  items,
  onRowClick,
  renderPhoneRow,
  mobileStackSpacing,
}: TableMobileInnerProps<TRow>) {
  return (
    <Stack width="100%" direction="column" spacing={mobileStackSpacing}>
      {items?.map((item, index) => (
        <div key={item.id} onClick={event => onRowClick?.(item, event)}>
          {renderPhoneRow?.(item, index)}
        </div>
      ))}
    </Stack>
  );
}
