import { useScreenWidth } from "@msys/ui";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

export const EXPANDED_COLUMN_MIN_WIDTH = 200;
export const COLLAPSED_COLUMN_WIDTH = 36;

interface Props {
  isCollapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  collapsedTitle: string;
  title: React.ReactNode;
  showCollapsedButton?: boolean;
  children: React.ReactNode;
}

export function KanbanColumn({
  isCollapsed,
  setCollapsed,
  collapsedTitle,
  title,
  children,
  showCollapsedButton = true,
}: Props) {
  const { isMinTablet } = useScreenWidth();
  return (
    <Box
      flexBasis={
        isCollapsed
          ? COLLAPSED_COLUMN_WIDTH
          : isMinTablet
            ? EXPANDED_COLUMN_MIN_WIDTH
            : "100%"
      }
      flexShrink={0}
      flexGrow={1}
      width={0}
      maxWidth={isCollapsed ? COLLAPSED_COLUMN_WIDTH : undefined}
      height={"max-content"}
    >
      <Paper
        sx={{
          height: "max-content",
          backgroundColor: "grey.100",
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
          maxWidth={"100%"}
          height="40px"
          width={"100%"}
          px={isCollapsed ? 0 : 1}
          py={0.5}
          sx={{
            backgroundColor: "background.paper",
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
          }}
          spacing={1}
        >
          {isCollapsed ? (
            showCollapsedButton && (
              <IconButton
                size={"small"}
                color={"primary"}
                onClick={() => {
                  setCollapsed(false);
                }}
              >
                <OpenInFullIcon />
              </IconButton>
            )
          ) : (
            <>
              {title}
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {showCollapsedButton && (
                  <IconButton
                    size={"small"}
                    color={"primary"}
                    onClick={() => {
                      setCollapsed(true);
                    }}
                  >
                    <CloseFullscreenIcon />
                  </IconButton>
                )}
              </Stack>
            </>
          )}
        </Stack>
        <Divider />
        {isCollapsed ? (
          <Typography
            padding={1}
            variant="h5"
            textTransform={"uppercase"}
            sx={{
              writingMode: "vertical-lr",
              transform: "rotate(180deg)",
              margin: "0 auto",
            }}
          >
            {collapsedTitle}
          </Typography>
        ) : (
          children
        )}
      </Paper>
    </Box>
  );
}
