import { ModalDialog, ModalTitle } from "@msys/ui";
import { CreateProjectModalContent } from "../../projects/modals/CreateProjectModalContent";
import { useTranslate } from "@tolgee/react";

interface Props
  extends Omit<
    React.ComponentProps<typeof CreateProjectModalContent>,
    "text" | "submitButtonLabel"
  > {
  handleClose: () => void;
}

export const OpportunityCreateModal = ({ handleClose, ...props }: Props) => {
  const { t } = useTranslate(["Opportunities"]);

  const title = t("Create opportunity", { ns: "Opportunities" });

  return (
    <ModalDialog handleClose={handleClose}>
      <ModalTitle title={title} handleClose={handleClose} />
      <CreateProjectModalContent
        {...props}
        text={t(
          "Choose a contact to whom you want to create this opportunity",
          { ns: "Opportunities" }
        )}
        submitButtonLabel={t("Create opportunity", { ns: "Opportunities" })}
        handleClose={handleClose}
      />
    </ModalDialog>
  );
};
