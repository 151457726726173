import { useApolloClient } from "@apollo/client";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import { Button, Icon } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@tolgee/react";
import { QuoteImportRequirementsModal } from "../QuoteImportRequirementsModal";

interface Props {
  projectId: string;
  quoteId: string;
  pathToDoc: string;
  type: "menu-item" | "button";
}

export const ImportRequirementButton = ({
  projectId,
  quoteId,
  pathToDoc,
  type = "menu-item",
}: Props) => {
  const { t } = useTranslate("QuoteEdit");
  const client = useApolloClient();
  const navigate = useNavigate();

  const button =
    type === "menu-item" ? (
      <MenuItemWithIcon
        icon={
          <Icon>
            <FontAwesomeIcon icon={faFileImport} />
          </Icon>
        }
      >
        {t("Import requirements")}
      </MenuItemWithIcon>
    ) : type === "button" ? (
      <Button
        fullWidth
        size="small"
        variant="outlined"
        color="primary"
        startIcon={
          <Icon>
            <FontAwesomeIcon icon={faFileImport} />
          </Icon>
        }
      >
        {t("Import requirements")}
      </Button>
    ) : null;

  if (button === null) return null;

  return (
    <ModalOpenButton
      Modal={QuoteImportRequirementsModal}
      modalProps={{
        projectId,
        quoteId,
        handleComplete: async () => {
          await client.reFetchObservableQueries();
          navigate(pathToDoc, { replace: true });
        },
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
