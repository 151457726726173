// import { ProductOverviewAlternativeSupplierBox } from "./boxes/ProductOverviewAlternativeSupplierBox";
import { Modal, VisibilityIndicatorIcon } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField as FormikTextField } from "formik-mui";
import { noop, uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { RichTextField } from "../../../commons/form-fields/RichTextField";
import { Stack } from "../../../commons/layout/Stack";
import { VisibilityButton } from "../../../commons/VisibilityButton";
import {
  Props2NonComputed,
  Visibility,
} from "../../../../clients/graphqlTypes";
import { ManufacturerSelect } from "../../manufacturers/ManufacturerSelect";
import { SupplierSelect } from "../../suppliers/SupplierSelect";

interface FormValues {
  productArticleNumber?: string | null;
  productSupplier?: { id: string; title: string } | null;
  productTitle?: string;
  productDescription?: string;
  productBrand?: string;
  productBrandLine?: string;
  productGtin?: string | null;
  productManufacturerArticleNumber?: string | null;
  productCategory?: string;
  productTitleClientVisibility: Visibility;
  productDescriptionClientVisibility: Visibility;
  productBrandClientVisibility: Visibility;
  productProps2: Props2NonComputed[];
}

export interface ProductCreateModalProps {
  handleSubmit: (product: FormValues) => Promise<void> | void;
  handleClose: () => void;
}

export const ProductCreateModal = ({
  handleSubmit,
  handleClose,
}: ProductCreateModalProps) => {
  const { t } = useTranslate([
    "Global",
    "ItemPropertyField",
    "QuoteItem",
    "Product",
  ]);

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        productArticleNumber: Yup.string()
          .nullable()
          .label(t("Article number", { ns: "Product" })),
        productSupplier: Yup.object()
          .shape({ id: Yup.string(), title: Yup.string() })
          .nullable()
          .label(t("Supplier", { ns: "Product" })),
        productTitle: Yup.string()
          .trim()
          .required()
          .label(t("Product title", { ns: "QuoteItem" })),
        productDescription: Yup.string(),
        productBrand: Yup.string(),
        productBrandLine: Yup.string(),
        productGtin: Yup.string().nullable(),
        productManufacturerArticleNumber: Yup.string().nullable(),
        productCategory: Yup.string(),
        productTitleClientVisibility: Yup.string().oneOf([
          "none",
          "show",
          "hide",
        ]),
        productDescriptionClientVisibility: Yup.string().oneOf([
          "none",
          "show",
          "hide",
        ]),
        productBrandClientVisibility: Yup.string().oneOf([
          "none",
          "show",
          "hide",
        ]),
        productProperties: Yup.mixed(),
      }),
    [t]
  );

  const initialValues = React.useMemo(
    (): FormValues => ({
      productArticleNumber: null,
      productSupplier: null,
      productTitle: "",
      productDescription: "",
      productBrand: "",
      productBrandLine: "",
      productGtin: null,
      productManufacturerArticleNumber: null,
      productCategory: "",
      productTitleClientVisibility: "none",
      productDescriptionClientVisibility: "none",
      productBrandClientVisibility: "none",
      productProps2: [],
    }),
    []
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        await handleSubmit(values);
        handleClose();
      }}
    >
      {formikProps => {
        return (
          <Modal
            title={t("Create product for one-time use", { ns: "Product" })}
            handleClose={handleClose}
            dialogProps={{
              maxWidth: "sm",
            }}
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Add", {
                  ns: "Global",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled: !formikProps.isValid || !formikProps.dirty,
                  loading: formikProps.isSubmitting,
                },
              },
            ]}
            alwaysVisible
          >
            <Form id={formId}>
              <Stack flexDirection="column" p={1}>
                <Stack
                  flex={1}
                  spacing={1 / 2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Field
                    component={FormikTextField}
                    label={t("Product title", { ns: "QuoteItem" })}
                    name="productTitle"
                    required
                    autoFocus
                  />
                  <VisibilityButton
                    visibility={formikProps.values.productTitleClientVisibility}
                    onChange={visibility =>
                      formikProps.setFieldValue(
                        "productTitleClientVisibility",
                        visibility
                      )
                    }
                  />
                </Stack>
                <Stack
                  flex={1}
                  spacing={1 / 2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box flex={1}>
                    <RichTextField
                      name="productDescription"
                      label={t("Product description", {
                        ns: "QuoteItem",
                      })}
                    />
                  </Box>
                  <VisibilityButton
                    visibility={
                      formikProps.values.productDescriptionClientVisibility
                    }
                    onChange={visibility =>
                      formikProps.setFieldValue(
                        "productDescriptionClientVisibility",
                        visibility
                      )
                    }
                  />
                </Stack>
                <Stack
                  flex={1}
                  spacing={1 / 2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Field
                    component={FormikTextField}
                    label={t("Category", { ns: "Product" })}
                    name="productCategory"
                  />
                  <VisibilityIndicatorIcon
                    isVisible={false}
                    color={"disabled"}
                  />
                  <Field
                    component={FormikTextField}
                    label={t("Article number", { ns: "Product" })}
                    name="productArticleNumber"
                  />
                  <VisibilityIndicatorIcon
                    isVisible={false}
                    color={"disabled"}
                  />
                </Stack>
                <Stack
                  flex={1}
                  spacing={1 / 2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Field
                    component={FormikTextField}
                    label={t("GTIN", {
                      ns: "Product",
                    })}
                    name="productGtin"
                  />
                  <VisibilityIndicatorIcon
                    isVisible={false}
                    color={"disabled"}
                  />
                  <Field
                    component={FormikTextField}
                    label={t("Manufacturer article number", {
                      ns: "Product",
                    })}
                    name="productManufacturerArticleNumber"
                  />
                  <VisibilityIndicatorIcon
                    isVisible={false}
                    color={"disabled"}
                  />
                </Stack>
                <Stack
                  flex={1}
                  spacing={1 / 2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Field
                    component={FormikTextField}
                    label={t("Brand", {
                      ns: "Product",
                    })}
                    name="productBrand"
                  />
                  <VisibilityButton
                    visibility={formikProps.values.productBrandClientVisibility}
                    onChange={visibility =>
                      formikProps.setFieldValue(
                        "productBrandClientVisibility",
                        visibility
                      )
                    }
                  />
                </Stack>
                <Stack
                  flex={1}
                  spacing={1 / 2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Field
                    component={FormikTextField}
                    label={t("Brand line", {
                      ns: "Product",
                    })}
                    name="productBrandLine"
                  />
                  <VisibilityButton
                    visibility={formikProps.values.productBrandClientVisibility}
                    onChange={noop}
                    disabled={true}
                  />
                </Stack>
                <Stack
                  flex={1}
                  spacing={1 / 2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <SupplierSelect
                    excludeIfAlreadyCrm={false}
                    required={false}
                    disabled={formikProps.isSubmitting}
                    productSupplierId={
                      formikProps.values.productSupplier?.id ?? null
                    }
                    onChange={productSupplier => {
                      formikProps.setFieldValue(
                        "productSupplier",
                        productSupplier
                      );
                    }}
                  />
                  <VisibilityIndicatorIcon
                    isVisible={false}
                    color={"disabled"}
                  />
                </Stack>
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
