/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TreeItemRightIconsFragment = { __typename: 'Item', id: string, timeTrackingRequired: boolean, photoApprovalRequired: boolean, qaApprovalRequired: boolean, approvalRequired: boolean };

export const TreeItemRightIconsFragmentDoc = gql`
    fragment TreeItemRightIcons on Item {
  id
  timeTrackingRequired
  photoApprovalRequired
  qaApprovalRequired
  approvalRequired
}
    `;