/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type InvoiceListItem_OutgoingInvoiceFragment = { __typename: 'OutgoingInvoice', id: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, createdAt: any, clientName: string, totalGrossPrice: number, dueDate?: any | null };

export type InvoiceListItem_IncomingInvoiceFragment = { __typename: 'IncomingInvoice', id: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, createdAt: any, contractorName: string, totalGrossPrice: number, dueDate: any };

export const InvoiceListItem_OutgoingInvoiceFragmentDoc = gql`
    fragment InvoiceListItem_OutgoingInvoice on OutgoingInvoice {
  id
  type
  status
  title
  createdAt
  clientName
  totalGrossPrice
  dueDate
}
    `;
export const InvoiceListItem_IncomingInvoiceFragmentDoc = gql`
    fragment InvoiceListItem_IncomingInvoice on IncomingInvoice {
  id
  type
  status
  title
  createdAt
  contractorName
  totalGrossPrice
  dueDate
}
    `;