/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { CrmCompanyListItemFragmentDoc } from '../../features/crm-companies/components/CrmCompanyListItem.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../features/attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmAllQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  sorting?: Types.InputMaybe<Array<Types.OrganisationCrmOrganisationsSorting>>;
  filterByTagsAny?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  filterByTagsAll?: Types.InputMaybe<Array<Types.Scalars['String']['input']>>;
  filterAbcCategory?: Types.InputMaybe<Array<Types.CrmOrganisationAbcCategory>>;
  filterByCustomFields?: Types.InputMaybe<Array<Types.CustomFieldFilter>>;
  filterProjectType?: Types.InputMaybe<Array<Types.ProjectType>>;
  filterProjectState?: Types.InputMaybe<Array<Types.ProjectStateMachineStatus>>;
  filterProjectCategories?: Types.InputMaybe<Array<Types.Skill>>;
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CrmAllQuery = { crmCompanies: { __typename: 'CrmCompanyConnection', totalCount: number, edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, number: string, title: string, linkedSystemOrganisationId?: string | null, abcCategory: Types.CrmOrganisationAbcCategory, systemTags: Array<string>, customTags: Array<string>, email: any, isInvited: boolean, crmDuplicateOfId?: string | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null } }> } | { __typename: 'MissingPermissions' } };

export type CrmCompanies__CrmCompanyFragment = { __typename: 'CrmCompaniesRecord', id: string, number: string, title: string, linkedSystemOrganisationId?: string | null, abcCategory: Types.CrmOrganisationAbcCategory, systemTags: Array<string>, customTags: Array<string>, email: any, isInvited: boolean, crmDuplicateOfId?: string | null, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null };

export const CrmCompanies__CrmCompanyFragmentDoc = gql`
    fragment CrmCompanies__CrmCompany on CrmCompaniesRecord {
  id
  number
  title
  logo {
    ...AttachmentSnapshot
  }
  contactPerson {
    id
    fullname
  }
  linkedSystemOrganisationId
  abcCategory
  systemTags
  customTags
  email
  isInvited
  crmDuplicateOfId
}
    ${AttachmentSnapshotFragmentDoc}`;
export const CrmAllDocument = gql`
    query CrmAll($offset: Int, $limit: Int!, $sorting: [OrganisationCrmOrganisationsSorting!], $filterByTagsAny: [String!], $filterByTagsAll: [String!], $filterAbcCategory: [CrmOrganisationAbcCategory!], $filterByCustomFields: [CustomFieldFilter!], $filterProjectType: [ProjectType!], $filterProjectState: [ProjectStateMachineStatus!], $filterProjectCategories: [Skill!], $filterSearchTerm: String) {
  crmCompanies(
    offset: $offset
    limit: $limit
    sorting: $sorting
    filters: {abcCategory: $filterAbcCategory, tagsAny: $filterByTagsAny, tagsAll: $filterByTagsAll, customFields: $filterByCustomFields, projectType: $filterProjectType, projectState: $filterProjectState, projectCategories: $filterProjectCategories}
    search: $filterSearchTerm
  ) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          ...CrmCompanies__CrmCompany
          ...CrmCompanyListItem
        }
      }
      totalCount
    }
  }
}
    ${CrmCompanies__CrmCompanyFragmentDoc}
${CrmCompanyListItemFragmentDoc}`;

/**
 * __useCrmAllQuery__
 *
 * To run a query within a React component, call `useCrmAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmAllQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sorting: // value for 'sorting'
 *      filterByTagsAny: // value for 'filterByTagsAny'
 *      filterByTagsAll: // value for 'filterByTagsAll'
 *      filterAbcCategory: // value for 'filterAbcCategory'
 *      filterByCustomFields: // value for 'filterByCustomFields'
 *      filterProjectType: // value for 'filterProjectType'
 *      filterProjectState: // value for 'filterProjectState'
 *      filterProjectCategories: // value for 'filterProjectCategories'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *   },
 * });
 */
export function useCrmAllQuery(baseOptions: Apollo.QueryHookOptions<CrmAllQuery, CrmAllQueryVariables> & ({ variables: CrmAllQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmAllQuery, CrmAllQueryVariables>(CrmAllDocument, options);
      }
export function useCrmAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmAllQuery, CrmAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmAllQuery, CrmAllQueryVariables>(CrmAllDocument, options);
        }
export function useCrmAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmAllQuery, CrmAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmAllQuery, CrmAllQueryVariables>(CrmAllDocument, options);
        }
export type CrmAllQueryHookResult = ReturnType<typeof useCrmAllQuery>;
export type CrmAllLazyQueryHookResult = ReturnType<typeof useCrmAllLazyQuery>;
export type CrmAllSuspenseQueryHookResult = ReturnType<typeof useCrmAllSuspenseQuery>;
export type CrmAllQueryResult = Apollo.QueryResult<CrmAllQuery, CrmAllQueryVariables>;