import { ellipsisStyle, FormattedPrice, useFormatting } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment/moment";
import React from "react";
import {
  ActionLine,
  ClientLine,
  DeadlineDateLine,
  MasterLine,
} from "../../commons/DataItem";
import {
  InvoiceListItem_IncomingInvoiceFragment,
  InvoiceListItem_OutgoingInvoiceFragment,
} from "./InvoiceListItem.generated";
import { InvoiceStatusBadge } from "./InvoiceStatusBadge";

interface Props {
  invoice:
    | InvoiceListItem_OutgoingInvoiceFragment
    | InvoiceListItem_IncomingInvoiceFragment;
  Action?: React.ReactElement;
}

export const InvoiceListItem = ({ invoice, Action }: Props) => {
  const { getFormattedDate } = useFormatting();
  const { t } = useTranslate("DataItem");

  const deadlineDate = invoice.dueDate
    ? getFormattedDate(invoice.dueDate)
    : undefined;

  const master =
    "contractorName" in invoice ? invoice.contractorName : undefined;
  const client = "clientName" in invoice ? invoice.clientName : undefined;

  const overdue = invoice.dueDate
    ? moment(invoice.dueDate).endOf("day").valueOf() < moment().valueOf()
    : undefined;

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>{invoice.title}</span>
          <span
            style={{
              display: "inline-flex",
              marginLeft: "8px",
              verticalAlign: "middle",
            }}
          >
            <InvoiceStatusBadge status={invoice.status} />
          </span>
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
        style={ellipsisStyle}
      >
        <Box
          gap={0.5}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          {client && (
            <Tooltip title={t("Client")} arrow placement="bottom">
              <div style={ellipsisStyle}>
                <ClientLine>{client}</ClientLine>
              </div>
            </Tooltip>
          )}
          {master && (
            <Tooltip title={t("Contractor")} arrow placement="bottom">
              <div style={ellipsisStyle}>
                <MasterLine>{master}</MasterLine>
              </div>
            </Tooltip>
          )}
          {deadlineDate && (
            <Tooltip title={t("Deadline")} arrow placement="bottom">
              <div>
                <DeadlineDateLine overdue={overdue}>
                  {deadlineDate}
                </DeadlineDateLine>
              </div>
            </Tooltip>
          )}
        </Box>

        <Typography
          variant={"body2"}
          style={{
            float: "right",
            alignItems: "flex-end",
            display: "flex",
            shapeOutside: "inset(calc(100% - 20px) 0 0)",
            marginBottom: "-4px",
          }}
          component="div"
        >
          <FormattedPrice value={invoice.totalGrossPrice ?? 0} />
        </Typography>
      </Stack>
    </Stack>
  );
};
