import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useSnackbar } from "notistack";
import omitDeep from "omit-deep-lodash";
import React from "react";
import {
  CompileDocIsolatedExpressionResultDiagnosticPropertyMapping,
  SetItemProps2Mapping,
} from "../../../../clients/graphqlTypes";
import { ExpressionModal } from "../../doc-items/modals/ExpressionModal";
import { useExpressionValidationError } from "../../doc-items/modals/useExpressionValidation";
import {
  usePropertyMappingExpressionModalQuery,
  useSetItemProps2MappingsMutation,
} from "./PropertyMappingExpressionModal.generated";

interface Props
  extends Omit<
    React.ComponentProps<typeof ExpressionModal>,
    "expressionError" | "handleSave" | "handleDelete"
  > {
  fieldName: string;
}

export function PropertyMappingExpressionModal({
  docId,
  itemId,
  fieldName,
  handleClose,
  ...props
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const query = usePropertyMappingExpressionModalQuery({
    client,
    variables: { docId, itemId },
  });
  const item = getDataOrNull(query.data?.item);
  const mappings = item?.propertyMappings;

  const relevantErrorPredicate = React.useCallback(
    (
      result: Parameters<Parameters<typeof useExpressionValidationError>[2]>[0]
    ) => "toKey" in result && result.toKey === fieldName,
    [fieldName]
  );

  const { expressionError, validateExpressions } = useExpressionValidationError(
    docId,
    itemId,
    relevantErrorPredicate
  );

  const [setItemProps2Mappings] = useSetItemProps2MappingsMutation({ client });
  const handleSetPropertyMapping = React.useCallback(
    async (toPropertyKey: string, fromExpression: string | null) => {
      const mappingsInput = (mappings ?? [])
        .filter(mapping => mapping.to.key !== toPropertyKey)
        .map(pm => omitDeep(pm, ["__typename"]) as SetItemProps2Mapping);

      await setItemProps2Mappings({
        variables: {
          input: {
            projectId: null,
            docId,
            itemId,
            mappings:
              fromExpression === null
                ? mappingsInput
                : [
                    ...mappingsInput,
                    {
                      from: { expr: fromExpression },
                      to: { key: toPropertyKey },
                    },
                  ],
          },
        },
      });
    },
    [docId, itemId, mappings, setItemProps2Mappings]
  );
  const handleDeletePropertyMapping = React.useCallback(
    async (propertyKey: string) => {
      await handleSetPropertyMapping(propertyKey, null);
    },
    [handleSetPropertyMapping]
  );

  const handleSave = React.useCallback(
    async (expression: string) => {
      const result = await validateExpressions({
        docId,
        itemId,
        overrides: [
          { itemId, propertyMapping: { key: fieldName, fromExpr: expression } },
        ],
      });

      const expressionCheckResult =
        result.data?.compileDocIsolatedExpression.results.find(
          relevantErrorPredicate
        );

      if (expressionCheckResult) {
        console.error("compile document error", expressionCheckResult);
        enqueueSnackbar(
          `Expression is invalid: ${expressionCheckResult.messageText}`,
          { variant: "error" }
        );
        return;
      }

      await handleSetPropertyMapping(fieldName, expression);
      handleClose();
    },
    [
      validateExpressions,
      docId,
      itemId,
      fieldName,
      relevantErrorPredicate,
      handleSetPropertyMapping,
      handleClose,
      enqueueSnackbar,
    ]
  );

  const handleDelete = React.useCallback(async () => {
    await handleDeletePropertyMapping(fieldName);
    handleClose();
  }, [fieldName, handleClose, handleDeletePropertyMapping]);

  return (
    <ExpressionModal
      {...props}
      docId={docId}
      itemId={itemId}
      handleClose={handleClose}
      handleSave={handleSave}
      handleDelete={handleDelete}
      expressionError={expressionError}
    />
  );
}
