/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TodosBox_LinkedCrmOrganisationFragment = { __typename: 'CrmCompany', id: string };

export type TodosBox_LinkedCrmUserFragment = { __typename: 'CrmPerson', id: string };

export type TodosBox_LinkedProjectFragment = { __typename: 'Project', id: string };

export const TodosBox_LinkedCrmOrganisationFragmentDoc = gql`
    fragment TodosBox_LinkedCrmOrganisation on CrmCompany {
  id
}
    `;
export const TodosBox_LinkedCrmUserFragmentDoc = gql`
    fragment TodosBox_LinkedCrmUser on CrmPerson {
  id
}
    `;
export const TodosBox_LinkedProjectFragmentDoc = gql`
    fragment TodosBox_LinkedProject on Project {
  id
}
    `;