import CloseIcon from "@mui/icons-material/Close";
import { DateTimePicker as MuiDateTimePicker } from "@mui/lab";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import moment, { Moment } from "moment";
import React, { ChangeEvent } from "react";
import { useLocale } from "../LocaleProvider";

export interface DateTimePickerProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  id?: string;
  disabled?: boolean;
  label: string;
  value: Moment | null;
  onChange: (
    e: ChangeEvent<HTMLInputElement> | null,
    newValue: Moment | null
  ) => void;
  minValue?: Moment;
  maxValue?: Moment;
  required?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  isClearable?: boolean;
}

export const DateTimePickerMui = ({
  disabled,
  label,
  value,
  minValue,
  maxValue,
  onChange,
  disablePast = false,
  disableFuture = false,
  ...otherProps
}: DateTimePickerProps) => {
  const locale = useLocale();

  const format = `${moment.localeData(locale).longDateFormat("L")} ${moment
    .localeData(locale)
    .longDateFormat("LT")}`;

  return (
    <MuiDateTimePicker<Moment>
      ampm={false}
      value={value}
      renderInput={props => <TextField {...props} error={otherProps.error} />}
      onChange={(date: Moment | null) => {
        onChange(null, date);
      }}
      disableFuture={disableFuture}
      disablePast={disablePast}
      label={label}
      disabled={disabled}
      minDate={minValue}
      maxDate={maxValue}
      inputFormat={format}
      showTodayButton
    />
  );
};

export const DateTimePickerNative = ({
  disabled,
  label,
  value,
  minValue,
  maxValue,
  onChange,
  isClearable = false,
  disablePast = false,
  disableFuture = false,
  inputProps,
  InputProps,
  InputLabelProps,
  ...otherProps
}: DateTimePickerProps) => {
  const min = React.useMemo(() => {
    if (minValue) return convertToNativeDateTimePickerFormat(minValue);
    if (disablePast) return convertToNativeDateTimePickerFormat(moment());
    return undefined;
  }, [minValue, disablePast]);
  const max = React.useMemo(() => {
    if (maxValue) return convertToNativeDateTimePickerFormat(maxValue);
    if (disableFuture) return convertToNativeDateTimePickerFormat(moment());
    return undefined;
  }, [maxValue, disableFuture]);

  return (
    <TextField
      type="datetime-local"
      label={label}
      disabled={disabled}
      value={convertToNativeDateTimePickerFormat(value)}
      inputProps={{
        min,
        max,
        ...inputProps,
      }}
      InputProps={{
        endAdornment: isClearable ? (
          value ? (
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                onChange(null, null);
              }}
              aria-label="Clear"
              style={{ position: "relative", top: 8 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : undefined
        ) : undefined,
        ...InputProps,
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value ? moment(e.target.value) : null;
        onChange(e, date && date.isValid() ? date : null);
      }}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      {...otherProps}
    />
  );
};

const convertToNativeDateTimePickerFormat = (date: Moment | null): string => {
  return date ? date?.format(moment.HTML5_FMT.DATETIME_LOCAL) : "";
};

export const DateTimePicker = ({
  browserHasInputDateTime,
  ...props
}: DateTimePickerProps & { browserHasInputDateTime: boolean }) => {
  return browserHasInputDateTime ? (
    <DateTimePickerNative {...props} />
  ) : (
    <DateTimePickerMui {...props} />
  );
};
