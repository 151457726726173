import { gql, useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { LoadingButton } from "@mui/lab";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../../../commons/modals/ConfirmModal";
import { namedOperations } from "../../../../../clients/graphqlTypes";
import { useDiscardUnpublishedChangesMutation } from "./QuoteTemplateDiscardUnpublishedChangesButton.generated";

interface Props {
  pathToDoc: string;
  docId: string;
}

export function QuoteTemplateDiscardUnpublishedChangesButton({
  docId,
  pathToDoc,
}: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Templates"]);

  const client = useApolloClient();
  const [discard, { loading }] = useDiscardUnpublishedChangesMutation({
    client,
    variables: { input: { docId } },
    refetchQueries: [
      namedOperations.Query.TemplateQuoteRoutes,
      namedOperations.Query.TemplateQuoteEdit,
      namedOperations.Query.TemplateQuoteEditPreview,
    ],
    awaitRefetchQueries: true,
  });

  async function discardUnpublishedChanges() {
    await discard();
    enqueueSnackbar(t("Unpublished changes discarded", { ns: "Templates" }), {
      variant: "success",
    });
    navigate(`${pathToDoc}/latest`);
  }

  return (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        text: t(
          "You will lose all unpublished changes made since this template was last published",
          { ns: "Templates" }
        ),
        confirmButtonLabel: t("Yes, revert changes", { ns: "Templates" }),
        handleConfirm: discardUnpublishedChanges,
      }}
    >
      <LoadingButton loading={loading} variant={"outlined"}>
        {t("Revert to last published version", { ns: "Templates" })}
      </LoadingButton>
    </ModalOpenButton>
  );
}
