/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyOwnInformation_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, title: string, crmOwningOrganisationClientId?: string | null, linkedSystemOrganisationId?: string | null };

export type ModifyCrmOrg_CrmCompanyOwnInformationMutationVariables = Types.Exact<{
  input: Array<Types.CrmOrganisationModifyInput>;
}>;


export type ModifyCrmOrg_CrmCompanyOwnInformationMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, title: string, crmOwningOrganisationClientId?: string | null, linkedSystemOrganisationId?: string | null }> } };

export type CrmCompanyOwnInformationDiscountGroupsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  supplierId: Types.Scalars['ID']['input'];
}>;


export type CrmCompanyOwnInformationDiscountGroupsQuery = { pimGetSupplierDiscountGroups: { __typename: 'MissingCapabilities' } | { __typename: 'PimGetSupplierDiscountGroupsResult', totalCount: number, discountGroups: Array<{ __typename: 'PimDiscountGroup', id: string, description: string, discountType: Types.PimDiscountGroupDiscountType, key: string, value: number }> } };

export type CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  supplierDiscountGroupVariantId: Types.Scalars['ID']['input'];
}>;


export type CrmCompanyOwnInformationDiscountGroupsVariantQuery = { pimGetSupplierDiscountGroups: { __typename: 'MissingCapabilities' } | { __typename: 'PimGetSupplierDiscountGroupsResult', totalCount: number, discountGroups: Array<{ __typename: 'PimDiscountGroup', id: string, description: string, discountType: Types.PimDiscountGroupDiscountType, key: string, value: number }> } };

export type CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  supplierId: Types.Scalars['ID']['input'];
}>;


export type CrmCompanyOwnInformationDiscountGroupVariantsQuery = { pimSearchSupplierDiscountGroupVariants: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchSupplierDiscountGroupVariantsResult', totalCount: number, supplierDiscountGroupVariants: Array<{ __typename: 'PimSupplierDiscountGroupVariant', id: string, title: string }> } };

export type DiscountGroupFragment = { __typename: 'PimDiscountGroup', id: string, description: string, discountType: Types.PimDiscountGroupDiscountType, key: string, value: number };

export type PimModifySupplierDiscountGroupMutationVariables = Types.Exact<{
  input: Types.PimModifySupplierDiscountGroupInput;
}>;


export type PimModifySupplierDiscountGroupMutation = { pimModifySupplierDiscountGroup: { __typename: 'PimModifySupplierDiscountGroupResult', discountGroup: { __typename: 'PimDiscountGroup', id: string, description: string, discountType: Types.PimDiscountGroupDiscountType, key: string, value: number } } };

export const CrmCompanyOwnInformation_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyOwnInformation_CrmCompany on CrmCompany {
  id
  title
  crmOwningOrganisationClientId
  linkedSystemOrganisationId
}
    `;
export const DiscountGroupFragmentDoc = gql`
    fragment DiscountGroup on PimDiscountGroup {
  id
  description
  discountType
  key
  value
}
    `;
export const ModifyCrmOrg_CrmCompanyOwnInformationDocument = gql`
    mutation ModifyCrmOrg_CrmCompanyOwnInformation($input: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $input) {
    crmOrganisations {
      ...CrmCompanyOwnInformation_CrmCompany
    }
  }
}
    ${CrmCompanyOwnInformation_CrmCompanyFragmentDoc}`;
export type ModifyCrmOrg_CrmCompanyOwnInformationMutationFn = Apollo.MutationFunction<ModifyCrmOrg_CrmCompanyOwnInformationMutation, ModifyCrmOrg_CrmCompanyOwnInformationMutationVariables>;

/**
 * __useModifyCrmOrg_CrmCompanyOwnInformationMutation__
 *
 * To run a mutation, you first call `useModifyCrmOrg_CrmCompanyOwnInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmOrg_CrmCompanyOwnInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmOrgCrmCompanyOwnInformationMutation, { data, loading, error }] = useModifyCrmOrg_CrmCompanyOwnInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyCrmOrg_CrmCompanyOwnInformationMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmOrg_CrmCompanyOwnInformationMutation, ModifyCrmOrg_CrmCompanyOwnInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmOrg_CrmCompanyOwnInformationMutation, ModifyCrmOrg_CrmCompanyOwnInformationMutationVariables>(ModifyCrmOrg_CrmCompanyOwnInformationDocument, options);
      }
export type ModifyCrmOrg_CrmCompanyOwnInformationMutationHookResult = ReturnType<typeof useModifyCrmOrg_CrmCompanyOwnInformationMutation>;
export type ModifyCrmOrg_CrmCompanyOwnInformationMutationResult = Apollo.MutationResult<ModifyCrmOrg_CrmCompanyOwnInformationMutation>;
export type ModifyCrmOrg_CrmCompanyOwnInformationMutationOptions = Apollo.BaseMutationOptions<ModifyCrmOrg_CrmCompanyOwnInformationMutation, ModifyCrmOrg_CrmCompanyOwnInformationMutationVariables>;
export const CrmCompanyOwnInformationDiscountGroupsDocument = gql`
    query CrmCompanyOwnInformationDiscountGroups($limit: Int!, $offset: Int!, $supplierId: ID!) {
  pimGetSupplierDiscountGroups(
    limit: $limit
    offset: $offset
    filters: {supplierIds: [$supplierId]}
  ) {
    ... on PimGetSupplierDiscountGroupsResult {
      discountGroups {
        id
        ...DiscountGroup
      }
      totalCount
    }
  }
}
    ${DiscountGroupFragmentDoc}`;

/**
 * __useCrmCompanyOwnInformationDiscountGroupsQuery__
 *
 * To run a query within a React component, call `useCrmCompanyOwnInformationDiscountGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyOwnInformationDiscountGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanyOwnInformationDiscountGroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useCrmCompanyOwnInformationDiscountGroupsQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanyOwnInformationDiscountGroupsQuery, CrmCompanyOwnInformationDiscountGroupsQueryVariables> & ({ variables: CrmCompanyOwnInformationDiscountGroupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanyOwnInformationDiscountGroupsQuery, CrmCompanyOwnInformationDiscountGroupsQueryVariables>(CrmCompanyOwnInformationDiscountGroupsDocument, options);
      }
export function useCrmCompanyOwnInformationDiscountGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanyOwnInformationDiscountGroupsQuery, CrmCompanyOwnInformationDiscountGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanyOwnInformationDiscountGroupsQuery, CrmCompanyOwnInformationDiscountGroupsQueryVariables>(CrmCompanyOwnInformationDiscountGroupsDocument, options);
        }
export function useCrmCompanyOwnInformationDiscountGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmCompanyOwnInformationDiscountGroupsQuery, CrmCompanyOwnInformationDiscountGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanyOwnInformationDiscountGroupsQuery, CrmCompanyOwnInformationDiscountGroupsQueryVariables>(CrmCompanyOwnInformationDiscountGroupsDocument, options);
        }
export type CrmCompanyOwnInformationDiscountGroupsQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupsQuery>;
export type CrmCompanyOwnInformationDiscountGroupsLazyQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupsLazyQuery>;
export type CrmCompanyOwnInformationDiscountGroupsSuspenseQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupsSuspenseQuery>;
export type CrmCompanyOwnInformationDiscountGroupsQueryResult = Apollo.QueryResult<CrmCompanyOwnInformationDiscountGroupsQuery, CrmCompanyOwnInformationDiscountGroupsQueryVariables>;
export const CrmCompanyOwnInformationDiscountGroupsVariantDocument = gql`
    query CrmCompanyOwnInformationDiscountGroupsVariant($limit: Int!, $offset: Int!, $supplierDiscountGroupVariantId: ID!) {
  pimGetSupplierDiscountGroups(
    limit: $limit
    offset: $offset
    filters: {supplierDiscountGroupVariantIds: [$supplierDiscountGroupVariantId]}
  ) {
    ... on PimGetSupplierDiscountGroupsResult {
      discountGroups {
        id
        ...DiscountGroup
      }
      totalCount
    }
  }
}
    ${DiscountGroupFragmentDoc}`;

/**
 * __useCrmCompanyOwnInformationDiscountGroupsVariantQuery__
 *
 * To run a query within a React component, call `useCrmCompanyOwnInformationDiscountGroupsVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyOwnInformationDiscountGroupsVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanyOwnInformationDiscountGroupsVariantQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      supplierDiscountGroupVariantId: // value for 'supplierDiscountGroupVariantId'
 *   },
 * });
 */
export function useCrmCompanyOwnInformationDiscountGroupsVariantQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanyOwnInformationDiscountGroupsVariantQuery, CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables> & ({ variables: CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanyOwnInformationDiscountGroupsVariantQuery, CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables>(CrmCompanyOwnInformationDiscountGroupsVariantDocument, options);
      }
export function useCrmCompanyOwnInformationDiscountGroupsVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanyOwnInformationDiscountGroupsVariantQuery, CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanyOwnInformationDiscountGroupsVariantQuery, CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables>(CrmCompanyOwnInformationDiscountGroupsVariantDocument, options);
        }
export function useCrmCompanyOwnInformationDiscountGroupsVariantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmCompanyOwnInformationDiscountGroupsVariantQuery, CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanyOwnInformationDiscountGroupsVariantQuery, CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables>(CrmCompanyOwnInformationDiscountGroupsVariantDocument, options);
        }
export type CrmCompanyOwnInformationDiscountGroupsVariantQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupsVariantQuery>;
export type CrmCompanyOwnInformationDiscountGroupsVariantLazyQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupsVariantLazyQuery>;
export type CrmCompanyOwnInformationDiscountGroupsVariantSuspenseQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupsVariantSuspenseQuery>;
export type CrmCompanyOwnInformationDiscountGroupsVariantQueryResult = Apollo.QueryResult<CrmCompanyOwnInformationDiscountGroupsVariantQuery, CrmCompanyOwnInformationDiscountGroupsVariantQueryVariables>;
export const CrmCompanyOwnInformationDiscountGroupVariantsDocument = gql`
    query CrmCompanyOwnInformationDiscountGroupVariants($limit: Int!, $offset: Int!, $supplierId: ID!) {
  pimSearchSupplierDiscountGroupVariants(
    limit: $limit
    offset: $offset
    filters: {supplierIds: [$supplierId]}
  ) {
    ... on PimSearchSupplierDiscountGroupVariantsResult {
      supplierDiscountGroupVariants {
        id
        title
      }
      totalCount
    }
  }
}
    `;

/**
 * __useCrmCompanyOwnInformationDiscountGroupVariantsQuery__
 *
 * To run a query within a React component, call `useCrmCompanyOwnInformationDiscountGroupVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmCompanyOwnInformationDiscountGroupVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmCompanyOwnInformationDiscountGroupVariantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useCrmCompanyOwnInformationDiscountGroupVariantsQuery(baseOptions: Apollo.QueryHookOptions<CrmCompanyOwnInformationDiscountGroupVariantsQuery, CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables> & ({ variables: CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCompanyOwnInformationDiscountGroupVariantsQuery, CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables>(CrmCompanyOwnInformationDiscountGroupVariantsDocument, options);
      }
export function useCrmCompanyOwnInformationDiscountGroupVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCompanyOwnInformationDiscountGroupVariantsQuery, CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCompanyOwnInformationDiscountGroupVariantsQuery, CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables>(CrmCompanyOwnInformationDiscountGroupVariantsDocument, options);
        }
export function useCrmCompanyOwnInformationDiscountGroupVariantsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CrmCompanyOwnInformationDiscountGroupVariantsQuery, CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CrmCompanyOwnInformationDiscountGroupVariantsQuery, CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables>(CrmCompanyOwnInformationDiscountGroupVariantsDocument, options);
        }
export type CrmCompanyOwnInformationDiscountGroupVariantsQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupVariantsQuery>;
export type CrmCompanyOwnInformationDiscountGroupVariantsLazyQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupVariantsLazyQuery>;
export type CrmCompanyOwnInformationDiscountGroupVariantsSuspenseQueryHookResult = ReturnType<typeof useCrmCompanyOwnInformationDiscountGroupVariantsSuspenseQuery>;
export type CrmCompanyOwnInformationDiscountGroupVariantsQueryResult = Apollo.QueryResult<CrmCompanyOwnInformationDiscountGroupVariantsQuery, CrmCompanyOwnInformationDiscountGroupVariantsQueryVariables>;
export const PimModifySupplierDiscountGroupDocument = gql`
    mutation PimModifySupplierDiscountGroup($input: PimModifySupplierDiscountGroupInput!) {
  pimModifySupplierDiscountGroup(input: $input) {
    discountGroup {
      id
      ...DiscountGroup
    }
  }
}
    ${DiscountGroupFragmentDoc}`;
export type PimModifySupplierDiscountGroupMutationFn = Apollo.MutationFunction<PimModifySupplierDiscountGroupMutation, PimModifySupplierDiscountGroupMutationVariables>;

/**
 * __usePimModifySupplierDiscountGroupMutation__
 *
 * To run a mutation, you first call `usePimModifySupplierDiscountGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePimModifySupplierDiscountGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pimModifySupplierDiscountGroupMutation, { data, loading, error }] = usePimModifySupplierDiscountGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePimModifySupplierDiscountGroupMutation(baseOptions?: Apollo.MutationHookOptions<PimModifySupplierDiscountGroupMutation, PimModifySupplierDiscountGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PimModifySupplierDiscountGroupMutation, PimModifySupplierDiscountGroupMutationVariables>(PimModifySupplierDiscountGroupDocument, options);
      }
export type PimModifySupplierDiscountGroupMutationHookResult = ReturnType<typeof usePimModifySupplierDiscountGroupMutation>;
export type PimModifySupplierDiscountGroupMutationResult = Apollo.MutationResult<PimModifySupplierDiscountGroupMutation>;
export type PimModifySupplierDiscountGroupMutationOptions = Apollo.BaseMutationOptions<PimModifySupplierDiscountGroupMutation, PimModifySupplierDiscountGroupMutationVariables>;