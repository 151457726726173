/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type UseSelectedDate_ProjectFragment = { __typename: 'Project', id: string, earliestPlanSessionDate?: any | null, latestPlanSessionDate?: any | null };

export const UseSelectedDate_ProjectFragmentDoc = gql`
    fragment useSelectedDate_Project on Project {
  id
  earliestPlanSessionDate
  latestPlanSessionDate
}
    `;