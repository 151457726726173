import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  FormattedPrice,
  getFormattedPercentage,
  LabeledDurationValue,
  LabeledSpecializedValue,
  LabeledValue,
  PopoverWithModalOnMobile,
  underlineDoubleStyle,
  useFormatting,
} from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { isUndefined } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { DurationField } from "../../../commons/form-fields/DurationField";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField";
import {
  ContractType,
  ItemType,
  QuantityUnit,
} from "../../../../clients/graphqlTypes";
import { Vertical } from "../../verticals/types";
import { useModifyItemEstimatedCalculationMutation } from "../CalculationMutations.generated";
import { WorkRatesField } from "../fields/WorkRatesField";
import { FormattedFloatWithExpressionField } from "../FormattedFloatWithExpressionField";
import { ItemCalculationFragment } from "../Fragments.generated";
import { LabeledQuantityValue } from "../LabeledQuantityValue";
import { Prop2RelatedItemFieldsFragment } from "../properties.generated";
import { QuantityUnitField } from "../QuantityUnitField";
import { EstimatedCalculation__ItemFragment } from "./EstimatedCalculationBox.generated";

interface FormRestrictedValues {
  estimatedQuantity: number;
  quantityUnit: QuantityUnit;
}
interface FormDetailedValues extends FormRestrictedValues {
  materialBuyingPrice: number;
  materialMargin: number;
  estimatedTime: number;
  workSellingRate: number;
  workBuyingRate: number;
}

export type FormValues = FormRestrictedValues | FormDetailedValues;

type Props = {
  docId: string;
  item: EstimatedCalculation__ItemFragment;
  isReadOnly?: boolean;
  contractType: ContractType;
  viewerIsContractor?: boolean;
  showDetailedCalculation?: boolean;
  onUpdateDataRefetchQueries?: string[];
} & (
  | ({
      projectId: string;
    } & (
      | {
          manualSave: React.ReactElement;
          setIsEditing: (value: boolean) => void;
        }
      | {
          manualSave: undefined;
          setIsEditing: undefined;
        }
    ))
  | { projectId: null; manualSave?: undefined; setIsEditing?: undefined }
);

export const EstimatedCalculationBox = ({
  item,
  docId,
  projectId,
  isReadOnly = false,
  manualSave,
  setIsEditing,
  contractType,
  viewerIsContractor = true,
  showDetailedCalculation: passedShowDetailedCalculation,
  onUpdateDataRefetchQueries,
}: Props) => {
  const showDetailedCalculation =
    passedShowDetailedCalculation ??
    (viewerIsContractor && item.type === "paid");

  return showDetailedCalculation ? (
    <EstimatedDetailedCalculationBox
      projectId={projectId}
      docId={docId}
      item={item}
      isReadOnly={isReadOnly}
      manualSave={manualSave}
      setIsEditing={setIsEditing}
      contractType={contractType}
      onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
    />
  ) : (
    <EstimatedRestrictedCalculationBox
      projectId={projectId}
      docId={docId}
      item={item}
      isReadOnly={isReadOnly}
      manualSave={manualSave}
      setIsEditing={setIsEditing}
      onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
    />
  );
};

const EstimatedDetailedCalculationBox = ({
  item,
  docId,
  projectId,
  isReadOnly = false,
  manualSave,
  setIsEditing,
  contractType,
  onUpdateDataRefetchQueries,
}: Omit<Props, "showDetailedCalculation" | "viewerIsContractor">) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate("QuoteItem");

  const client = useApolloClient();
  const [modifyItemCalculation, { loading: modifyItemCalculationLoading }] =
    useModifyItemEstimatedCalculationMutation({
      client,
      refetchQueries: onUpdateDataRefetchQueries,
      awaitRefetchQueries: true,
    });

  const handleSubmit = React.useCallback(
    async (values: FormDetailedValues) => {
      try {
        await modifyItemCalculation({
          variables: {
            input: {
              projectId,
              docId,
              itemId: item.id,
              values: {
                materialBuyingPrice:
                  item && item.type === "paid"
                    ? values.materialBuyingPrice
                    : null,
                materialMargin:
                  item && item.type === "paid"
                    ? values.materialMargin / 100
                    : null,
                estimatedTime:
                  item && item.type === "paid" ? values.estimatedTime : null,
                workSellingRate:
                  item && item.type === "paid" ? values.workSellingRate : null,
                workBuyingRate:
                  item && item.type === "paid" ? values.workBuyingRate : null,
                estimatedQuantity: values.estimatedQuantity,
                quantityUnit: values.quantityUnit,
              },
            },
          },
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [docId, enqueueSnackbar, item, modifyItemCalculation, projectId]
  );

  const vertical = item.vertical ?? item.inheritedVertical;
  const canEditWorkRate = !vertical;
  const attributeExpressions = item.attributeExpressions;
  const itemCalculations = item.proposedCalculation;
  if (!itemCalculations) throw new Error("Item calculations missing");

  const initialValues = React.useMemo(
    (): FormDetailedValues => ({
      materialBuyingPrice: itemCalculations.materialBuyingPricePerUnit,
      materialMargin: itemCalculations.materialMargin * 100,
      estimatedTime: itemCalculations.timePerUnit,
      workSellingRate: itemCalculations.workSellingRate,
      workBuyingRate: itemCalculations.workBuyingRate,
      estimatedQuantity: itemCalculations.quantity,
      quantityUnit: itemCalculations.quantityUnit,
    }),
    [
      itemCalculations.materialBuyingPricePerUnit,
      itemCalculations.materialMargin,
      itemCalculations.quantity,
      itemCalculations.quantityUnit,
      itemCalculations.timePerUnit,
      itemCalculations.workBuyingRate,
      itemCalculations.workSellingRate,
    ]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        materialBuyingPrice: Yup.number()
          .label(t("Price / Unit"))
          .min(0)
          .nullable(),
        materialMargin: Yup.number().label(t("Margin")).min(-100).nullable(),
        estimatedTime: Yup.number().label(t("Time")).min(0).nullable(),
        workSellingRate: Yup.number().label(t("Work rate")).min(0).nullable(),
        workBuyingRate: Yup.number().label(t("Work cost")).min(0).nullable(),
        estimatedQuantity: Yup.number().label(t("Quantity")).min(0),
      }),
    [t]
  );

  return !isReadOnly ? (
    <CardContainer isExpandable title={t("Estimated Calculation")}>
      <Formik<FormDetailedValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <Form>
            <Stack padding={1} spacing={0.5}>
              <MaterialRow
                itemCalculations={itemCalculations}
                contractType={contractType}
                isEditable={true}
              />
              <WorkRow
                itemCalculations={itemCalculations}
                vertical={vertical ?? null}
                isEditable={true}
                isWorkRateEditable={canEditWorkRate}
              />
              <Grid item container paddingY={1}>
                <Grid item xs={9} />
                <Grid item xs={3}>
                  <CustomDivider />
                </Grid>
              </Grid>
              <QuantityUnitRow
                itemCalculations={itemCalculations}
                itemType={item.type}
                isEditable={true}
                projectId={projectId}
                docId={docId}
                itemId={item.id}
                expressions={attributeExpressions}
              />
              <TotalRow itemCalculations={itemCalculations} />

              {manualSave}
              <AutoSave
                isAutoSaveDisabled={manualSave !== undefined}
                enableReinitialize
                initialValues={initialValues}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </CardContainer>
  ) : (
    <CardContainer
      isExpandable
      title={t("Estimated Calculation")}
      ActionButton={
        setIsEditing ? (
          <IconButton
            color="primary"
            onClick={() => setIsEditing(true)}
            size="small"
          >
            <EditIcon />
          </IconButton>
        ) : undefined
      }
    >
      <Stack padding={1} spacing={0.5}>
        <MaterialRow
          itemCalculations={itemCalculations}
          contractType={contractType}
          isEditable={false}
        />
        <WorkRow
          itemCalculations={itemCalculations}
          vertical={vertical ?? null}
          isEditable={false}
          isWorkRateEditable={false}
        />
        <Grid item container spacing={1}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <CustomDivider />
          </Grid>
        </Grid>
        <QuantityUnitRow
          itemCalculations={itemCalculations}
          itemType={item.type}
          isEditable={false}
          projectId={projectId}
          docId={docId}
          itemId={item.id}
          expressions={attributeExpressions}
        />
        <TotalRow itemCalculations={itemCalculations} />
      </Stack>
    </CardContainer>
  );
};

const EstimatedRestrictedCalculationBox = ({
  item,
  docId,
  projectId,
  isReadOnly = false,
  manualSave,
  setIsEditing,
  onUpdateDataRefetchQueries,
}: Omit<
  Props,
  | "showDetailedCalculation"
  | "viewerIsContractor"
  | "contractType"
  | "allDocItems"
>) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate("QuoteItem");

  const client = useApolloClient();
  const [modifyItemCalculation, { loading: modifyItemCalculationLoading }] =
    useModifyItemEstimatedCalculationMutation({
      client,
      refetchQueries: onUpdateDataRefetchQueries,
      awaitRefetchQueries: true,
    });

  const handleSubmit = React.useCallback(
    async (values: FormRestrictedValues) => {
      try {
        await modifyItemCalculation({
          variables: {
            input: {
              projectId,
              docId,
              itemId: item.id,
              values: {
                estimatedQuantity: values.estimatedQuantity,
                quantityUnit: values.quantityUnit,
              },
            },
          },
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [docId, enqueueSnackbar, item.id, modifyItemCalculation, projectId]
  );

  const attributeExpressions = item.attributeExpressions;
  const itemCalculations = item.proposedCalculation;
  if (!itemCalculations) throw new Error("Item calculations missing");

  const initialValues = React.useMemo(
    (): FormRestrictedValues => ({
      estimatedQuantity: itemCalculations.quantity,
      quantityUnit: itemCalculations.quantityUnit,
    }),
    [itemCalculations.quantity, itemCalculations.quantityUnit]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        estimatedQuantity: Yup.number().label(t("Quantity")).min(0),
      }),
    [t]
  );

  return !isReadOnly ? (
    <CardContainer isExpandable title={t("Estimated Calculation")}>
      <Formik<FormRestrictedValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <Form>
            <Stack padding={1} spacing={0.5}>
              <QuantityUnitRow
                itemCalculations={itemCalculations}
                itemType={item.type}
                isEditable={true}
                projectId={projectId}
                docId={docId}
                itemId={item.id}
                expressions={attributeExpressions}
              />
              <TotalRow itemCalculations={itemCalculations} />

              {manualSave}
              <AutoSave
                isAutoSaveDisabled={manualSave !== undefined}
                enableReinitialize
                initialValues={initialValues}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </CardContainer>
  ) : (
    <CardContainer
      isExpandable
      title={t("Estimated Calculation")}
      ActionButton={
        setIsEditing ? (
          <IconButton
            color="primary"
            onClick={() => setIsEditing(true)}
            size="small"
          >
            <EditIcon />
          </IconButton>
        ) : undefined
      }
    >
      <Stack padding={1} spacing={0.5}>
        <QuantityUnitRow
          itemType={item.type}
          itemCalculations={itemCalculations}
          isEditable={false}
          projectId={projectId}
          docId={docId}
          itemId={item.id}
          expressions={attributeExpressions}
        />
        <TotalRow itemCalculations={itemCalculations} />
      </Stack>
    </CardContainer>
  );
};

const CustomDivider = () => {
  const theme = useTheme();

  return (
    <Divider
      style={{
        backgroundColor: theme.palette.primary.main,
      }}
    />
  );
};

const MaterialRow = ({
  itemCalculations,
  contractType,
  isEditable,
}: {
  itemCalculations: ItemCalculationFragment;
  contractType: ContractType;
  isEditable: boolean;
}) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);

  const hasFactor = itemCalculations.materialFactor !== 1;

  return (
    <Stack>
      <Typography variant="h4">
        {t("Material", {
          ns: "QuoteItem",
        })}
      </Typography>
      <Grid container columns={hasFactor ? 20 : 16}>
        <Grid item xs={5} display="flex" alignItems="center">
          {isEditable ? (
            <FormattedPriceField
              disabled={false}
              name="materialBuyingPrice"
              label={t("Price / Unit", {
                ns: "QuoteItem",
              })}
              type="price"
            />
          ) : (
            <LabeledSpecializedValue
              label={t("Price / Unit", {
                ns: "QuoteItem",
              })}
              type="price"
              value={itemCalculations.materialBuyingPricePerUnit}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          )}
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          +
        </Grid>
        <Grid item xs={5} display="flex" alignItems="center">
          {isEditable ? (
            <FormattedFloatField
              disabled={contractType === "fmbp_fr"}
              name="materialMargin"
              label={t("Margin", {
                ns: "QuoteItem",
              })}
              unit="%"
              multiplier={1}
            />
          ) : (
            <LabeledSpecializedValue
              label={t("Margin", {
                ns: "QuoteItem",
              })}
              type="float"
              unit="%"
              multiplier={100}
              value={itemCalculations.materialMargin}
              notSetText={t("Not set", {
                ns: "Global",
              })}
            />
          )}
        </Grid>
        {hasFactor && (
          <Grid
            item
            xs={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            ×
          </Grid>
        )}
        {hasFactor && (
          <Grid item xs={3} display="flex" alignItems="center">
            <LabeledSpecializedValue
              label={t("Factor", {
                ns: "QuoteItem",
              })}
              type="float"
              value={itemCalculations.materialFactor}
              notSetText={"–"}
            />
          </Grid>
        )}
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          =
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="flex-end"
          alignItems={"center"}
        >
          <LabeledValue
            label={t("Material", {
              ns: "QuoteItem",
            })}
            labelProps={{ style: { textAlign: "right" } }}
            style={{ textAlign: "right" }}
          >
            <FormattedPrice value={itemCalculations.materialPricePerUnit} />
          </LabeledValue>
        </Grid>
      </Grid>
    </Stack>
  );
};

const WorkRow = ({
  itemCalculations,
  vertical,
  isEditable,
  isWorkRateEditable,
}: {
  itemCalculations: ItemCalculationFragment;
  vertical: Vertical | null;
  isEditable: boolean;
  isWorkRateEditable: boolean;
}) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const hasFactor = itemCalculations.workFactor !== 1;

  return (
    <Stack>
      <Typography variant="h4">
        {t("Work", {
          ns: "QuoteItem",
        })}
      </Typography>
      <Grid container columns={hasFactor ? 20 : 16}>
        <Grid item xs={5} display="flex" alignItems="center">
          {isEditable ? (
            <DurationField
              disabled={false}
              name="estimatedTime"
              label={t("Time", {
                ns: "QuoteItem",
              })}
            />
          ) : (
            <LabeledDurationValue
              label={t("Time", {
                ns: "QuoteItem",
              })}
              value={itemCalculations.timePerUnit}
            />
          )}
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          ×
        </Grid>
        <Grid item xs={5} display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={0.5} flex={1}>
            {isEditable && isWorkRateEditable ? (
              <FormattedPriceField
                disabled={false}
                name="workSellingRate"
                label={t("Work rate", {
                  ns: "QuoteItem",
                })}
                type="price"
              />
            ) : (
              <LabeledSpecializedValue
                label={t("Work rate", {
                  ns: "QuoteItem",
                })}
                type="price"
                value={itemCalculations.workSellingRate}
                notSetText={t("Not set", {
                  ns: "Global",
                })}
              />
            )}
            <PopoverWithModalOnMobile
              title={t("Work rate", {
                ns: "QuoteItem",
              })}
              content={
                <WorkRatesField
                  vertical={vertical}
                  isEditable={isEditable}
                  isWorkRateEditable={isWorkRateEditable}
                  workBuyingRate={itemCalculations.workBuyingRate}
                  workSellingRate={itemCalculations.workSellingRate}
                />
              }
            >
              <IconButton
                color="secondary"
                size="extra-small"
                sx={{ flexGrow: 0, flexShrink: 0 }}
              >
                <Tooltip
                  title={t("Work margin: {percentage}", {
                    ns: "QuoteItem",
                    percentage: getFormattedPercentage(
                      itemCalculations.workBuyingRate !== 0
                        ? itemCalculations.workSellingRate /
                            itemCalculations.workBuyingRate -
                            1
                        : 0,
                      language
                    ),
                  })}
                >
                  <HelpIcon />
                </Tooltip>
              </IconButton>
            </PopoverWithModalOnMobile>
          </Stack>
        </Grid>
        {hasFactor && (
          <Grid
            item
            xs={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            x
          </Grid>
        )}
        {hasFactor && (
          <Grid item xs={3} display="flex" alignItems="center">
            <LabeledSpecializedValue
              label={t("Factor", {
                ns: "QuoteItem",
              })}
              type="float"
              value={itemCalculations.workFactor}
              notSetText={"-"}
            />
          </Grid>
        )}
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          =
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <LabeledValue
            label={t("Work Price", {
              ns: "QuoteItem",
            })}
            labelProps={{ style: { textAlign: "right" } }}
            style={{ textAlign: "right" }}
          >
            <FormattedPrice value={itemCalculations.workPricePerUnit} />
          </LabeledValue>
        </Grid>
      </Grid>
    </Stack>
  );
};

const QuantityUnitRow = ({
  itemCalculations,
  itemType,
  isEditable,
  projectId,
  docId,
  itemId,
  expressions,
}: {
  itemCalculations: ItemCalculationFragment;
  itemType: ItemType;
  isEditable: boolean;
  projectId: string | null;
  docId: string;
  itemId: string;
  expressions:
    | Extract<
        Prop2RelatedItemFieldsFragment,
        { attributeExpressions: any }
      >["attributeExpressions"];
}) => {
  const { t } = useTranslate("QuoteItem");

  const showSelectUnit = itemType === "paid";
  const disableSelectUnit = itemType !== "paid";
  const expression = expressions.find(
    expr => expr.attribute === "estimatedQuantity"
  );

  return (
    <Grid item container columns={16}>
      <Grid item xs={11}>
        {isEditable ? (
          <Grid container columns={11}>
            <Grid item xs={showSelectUnit || !disableSelectUnit ? 5 : 11}>
              <FormattedFloatWithExpressionField
                name="estimatedQuantity"
                label={t("Quantity")}
                disabled={false}
                projectId={projectId}
                docId={docId}
                itemId={itemId}
                expression={expression}
              />
            </Grid>
            {(showSelectUnit || !disableSelectUnit) && (
              <>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  {showSelectUnit && (
                    <QuantityUnitField name={"quantityUnit"} disabled={false} />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <LabeledQuantityValue
            value={itemCalculations.quantity}
            unit={itemCalculations.quantityUnit}
            label={t("Quantity")}
          />
        )}
      </Grid>
      <Grid
        item
        xs={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        x
      </Grid>
      <Grid
        item
        xs={4}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <LabeledValue
          label={t("Unit Price")}
          labelProps={{ style: { textAlign: "right" } }}
          style={{ textAlign: "right" }}
        >
          <FormattedPrice value={itemCalculations.pricePerUnit} />
        </LabeledValue>
      </Grid>
    </Grid>
  );
};

const TotalRow = ({
  itemCalculations,
}: {
  itemCalculations: ItemCalculationFragment;
}) => {
  const { t } = useTranslate("QuoteItem");
  const { getFormattedPrice, getFormattedDuration } = useFormatting();

  return (
    <Grid container>
      <Grid item xs={2} />
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12} paddingY={1}>
            <CustomDivider />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4">{`${t("Total")}: ${getFormattedDuration(
              itemCalculations.timeTotal
            )} h`}</Typography>
          </Grid>
          <Grid item xs={7} container justifyContent="flex-end">
            <Typography variant="h4" style={underlineDoubleStyle}>{`${t(
              "Total"
            )}: ${getFormattedPrice(
              itemCalculations.priceSubTotal
            )}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
