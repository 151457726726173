import { useApolloClient } from "@apollo/client";
import { MenuButton, MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { Divider, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as FileMoveIcon } from "../../assets/icons/icon-file-move.svg";
import { RestrictedByDocumentPermissionWithDebug } from "../../auth/RestrictedByDocumentPermission";
import { useUserData } from "../../auth/useUserData";
import { CrmSendEmailModal } from "../crm/CrmSendEmailModal";
import { ConfirmDeletionModal } from "./ConfirmationDeletionModal";
import { RequirementDocumentActorsModal } from "./RequirementDocumentActorsModal";
import {
  RequirementMenu_ProjectFragment,
  RequirementMenu_RequirementFragment,
  useDeleteRequirementMutation,
} from "./RequirementMenu.generated";

interface Props {
  project: RequirementMenu_ProjectFragment;
  requirement: RequirementMenu_RequirementFragment;
  pathToDocList: string;
}

export const RequirementMenu = ({
  project,
  requirement,
  pathToDocList,
}: Props) => {
  const navigate = useNavigate();
  const viewer = useUserData().currentUser!;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Quote", "QuoteEdit", "Requirements"]);

  const client = useApolloClient();
  const [deleteDoc, { loading: deleteDocLoading }] =
    useDeleteRequirementMutation({
      client,
    });
  const deleteRequirement = async () => {
    await deleteDoc({
      variables: {
        input: {
          projectId: project.id,
          requirementId: requirement.id,
        },
      },
    });
    enqueueSnackbar(
      t("Requirement was deleted", {
        ns: "QuoteEdit",
      })
    );
    navigate(pathToDocList, { replace: true });
  };

  const context = React.useMemo(
    () => ({ type: "REQUIREMENT", id: requirement.id }) as const,
    [requirement.id]
  );

  const isViewersDoc =
    requirement.owningSystemOrganisationId === viewer.organisation.id;
  const mainContract = requirement?.mainContract;
  const crmCompany = project.crmOrganisation;

  const recipientOptions = React.useMemo(
    () =>
      crmCompany
        ? [
            {
              id: crmCompany.id,
              label: `${crmCompany.title} <${crmCompany.email}>`,
              value: crmCompany.id,
              email: crmCompany.email,
              crmCompanyId: crmCompany.id,
              crmPersonId: null,
            },
            ...crmCompany.members.map(member => ({
              id: member.id,
              label: `${member.fullname} <${member.email}>`,
              value: member.id,
              email: member.email,
              crmCompanyId: crmCompany.id,
              crmPersonId: member.id,
            })),
          ].filter(option => option.email)
        : [],
    [crmCompany]
  );
  const recipient = recipientOptions[0];

  return isViewersDoc ? (
    <MenuButton>
      {mainContract && (
        <MenuItemWithIcon
          icon={<FileMoveIcon style={{ width: "22px", flexShrink: 0 }} />}
          component={Link}
          to={
            (mainContract.originProject.state === "opportunity"
              ? `/opportunities`
              : `/projects`) +
            `/${mainContract.originProject.id}/quotes/${mainContract.originQuote.id}/edit/items/${mainContract.originItem.id}`
          }
        >
          {t("Go to main project quote", {
            ns: "Quote",
          })}
        </MenuItemWithIcon>
      )}

      {mainContract && <Divider />}

      <RestrictedByDocumentPermissionWithDebug
        permission="MANAGE_REQUIREMENTS_ACTORS"
        document={requirement}
      >
        <ModalOpenButton
          Modal={RequirementDocumentActorsModal}
          modalProps={{ projectId: project.id, requirementId: requirement.id }}
        >
          <MenuItemWithIcon icon={<RecentActorsIcon />}>
            {t("Manage relevant parties", {
              ns: "Global",
            })}
          </MenuItemWithIcon>
        </ModalOpenButton>
      </RestrictedByDocumentPermissionWithDebug>

      <ModalOpenButton
        Modal={CrmSendEmailModal}
        modalProps={{
          emailTemplateContext: "REQUIREMENT",
          context,
          recipientOptions,
          recipient,
        }}
      >
        <MenuItemWithIcon icon={<EmailIcon />}>
          {t("Send email", { ns: "Requirements" })}
        </MenuItemWithIcon>
      </ModalOpenButton>

      <RestrictedByDocumentPermissionWithDebug
        permission="MANAGE_REQUIREMENTS"
        document={requirement}
        otherwise={
          <Tooltip
            title={t(
              "Requirement has outgoing requests and cannot be deleted",
              {
                ns: "Quote",
              }
            )}
          >
            <div>
              <MenuItemWithIcon icon={<DeleteIcon />} disabled>
                {t("Delete Requirement", {
                  ns: "Quote",
                })}
              </MenuItemWithIcon>
            </div>
          </Tooltip>
        }
      >
        {!requirement.isPublished ? (
          requirement.outgoingRequests.length === 0 ? (
            <MenuItemWithIcon
              icon={<DeleteIcon />}
              onClick={async () => {
                await deleteRequirement();
              }}
              disabled={deleteDocLoading}
            >
              {t("Delete Requirement", {
                ns: "Quote",
              })}
            </MenuItemWithIcon>
          ) : (
            <ModalOpenButton
              Modal={ConfirmDeletionModal}
              modalProps={{
                projectId: project.id,
                requirementId: requirement.id,
                handleComplete: async () => {
                  await deleteRequirement();
                },
              }}
            >
              <MenuItemWithIcon
                icon={<DeleteIcon />}
                disabled={deleteDocLoading}
              >
                {t("Delete Requirement", {
                  ns: "Quote",
                })}
              </MenuItemWithIcon>
            </ModalOpenButton>
          )
        ) : (
          <Tooltip
            title={t(
              "Requirement has outgoing requests and cannot be deleted",
              {
                ns: "Quote",
              }
            )}
          >
            <div>
              <MenuItemWithIcon icon={<DeleteIcon />} disabled>
                {t("Delete Requirement", {
                  ns: "Quote",
                })}
              </MenuItemWithIcon>
            </div>
          </Tooltip>
        )}
      </RestrictedByDocumentPermissionWithDebug>
    </MenuButton>
  ) : null;
};
