import { ModalOpenButton } from "@msys/ui";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslate } from "@tolgee/react";
import { ProductOverviewModal } from "../modals/ProductOverviewModal";

interface ProductCataloguePageButtonProps {
  productArticleNumber: string;
  productSupplierId: string;
}

export const ProductCataloguePageButton: FC<
  ProductCataloguePageButtonProps
> = ({ productArticleNumber, productSupplierId }) => {
  const { t } = useTranslate(["Product"]);
  return (
    <ModalOpenButton
      Modal={ProductOverviewModal}
      modalProps={{ productArticleNumber, productSupplierId }}
    >
      <Button
        color="secondary"
        size="small"
        aria-haspopup="true"
        endIcon={<LibraryBooksIcon />}
      >
        {t("Catalogue page", {
          ns: "Product",
        })}
      </Button>
    </ModalOpenButton>
  );
};
