/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProductSearchItem__ProductSearchResultFragmentDoc } from '../Product.generated';
import { GetProductFullDescription_ProductSearchResultFragmentDoc } from '../helper.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductSearchModalQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filters?: Types.InputMaybe<Types.PimSearchProductsFilters>;
  specificFieldQueryFields?: Types.InputMaybe<Array<Types.PimSpecificFieldQueryField>>;
}>;


export type ProductSearchModalQuery = { pimSearchProducts: { __typename: 'MissingPermissions' } | { __typename: 'PimSearchProductConnection', totalCount: number, edges: Array<{ __typename: 'PimSearchProductsEdge', node: { __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null, title?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null, title?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } }> } };

export type ProductSearchModalRecentViewsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
}>;


export type ProductSearchModalRecentViewsQuery = { pimSupplierProductRecentViews: { __typename: 'MissingCapabilities' } | { __typename: 'PimSupplierProductRecentViewsResult', products: Array<{ __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> }> } };


export const ProductSearchModalDocument = gql`
    query ProductSearchModal($offset: Int, $limit: Int!, $searchTerm: String, $filters: PimSearchProductsFilters, $specificFieldQueryFields: [PimSpecificFieldQueryField!]) {
  pimSearchProducts(
    offset: $offset
    limit: $limit
    filters: $filters
    specificFieldQueryFields: $specificFieldQueryFields
    search: $searchTerm
  ) {
    ... on PimSearchProductConnection {
      totalCount
      edges {
        node {
          id
          ...ProductSearchItem__ProductSearchResult
          ...GetProductFullDescription_ProductSearchResult
        }
      }
    }
  }
}
    ${ProductSearchItem__ProductSearchResultFragmentDoc}
${GetProductFullDescription_ProductSearchResultFragmentDoc}`;

/**
 * __useProductSearchModalQuery__
 *
 * To run a query within a React component, call `useProductSearchModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchModalQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      filters: // value for 'filters'
 *      specificFieldQueryFields: // value for 'specificFieldQueryFields'
 *   },
 * });
 */
export function useProductSearchModalQuery(baseOptions: Apollo.QueryHookOptions<ProductSearchModalQuery, ProductSearchModalQueryVariables> & ({ variables: ProductSearchModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchModalQuery, ProductSearchModalQueryVariables>(ProductSearchModalDocument, options);
      }
export function useProductSearchModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchModalQuery, ProductSearchModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchModalQuery, ProductSearchModalQueryVariables>(ProductSearchModalDocument, options);
        }
export function useProductSearchModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductSearchModalQuery, ProductSearchModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchModalQuery, ProductSearchModalQueryVariables>(ProductSearchModalDocument, options);
        }
export type ProductSearchModalQueryHookResult = ReturnType<typeof useProductSearchModalQuery>;
export type ProductSearchModalLazyQueryHookResult = ReturnType<typeof useProductSearchModalLazyQuery>;
export type ProductSearchModalSuspenseQueryHookResult = ReturnType<typeof useProductSearchModalSuspenseQuery>;
export type ProductSearchModalQueryResult = Apollo.QueryResult<ProductSearchModalQuery, ProductSearchModalQueryVariables>;
export const ProductSearchModalRecentViewsDocument = gql`
    query ProductSearchModalRecentViews($limit: Int!) {
  pimSupplierProductRecentViews(limit: $limit) {
    ... on PimSupplierProductRecentViewsResult {
      products {
        id
        ...ProductSearchItem__ProductSearchResult
      }
    }
  }
}
    ${ProductSearchItem__ProductSearchResultFragmentDoc}`;

/**
 * __useProductSearchModalRecentViewsQuery__
 *
 * To run a query within a React component, call `useProductSearchModalRecentViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchModalRecentViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchModalRecentViewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductSearchModalRecentViewsQuery(baseOptions: Apollo.QueryHookOptions<ProductSearchModalRecentViewsQuery, ProductSearchModalRecentViewsQueryVariables> & ({ variables: ProductSearchModalRecentViewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchModalRecentViewsQuery, ProductSearchModalRecentViewsQueryVariables>(ProductSearchModalRecentViewsDocument, options);
      }
export function useProductSearchModalRecentViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchModalRecentViewsQuery, ProductSearchModalRecentViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchModalRecentViewsQuery, ProductSearchModalRecentViewsQueryVariables>(ProductSearchModalRecentViewsDocument, options);
        }
export function useProductSearchModalRecentViewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductSearchModalRecentViewsQuery, ProductSearchModalRecentViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchModalRecentViewsQuery, ProductSearchModalRecentViewsQueryVariables>(ProductSearchModalRecentViewsDocument, options);
        }
export type ProductSearchModalRecentViewsQueryHookResult = ReturnType<typeof useProductSearchModalRecentViewsQuery>;
export type ProductSearchModalRecentViewsLazyQueryHookResult = ReturnType<typeof useProductSearchModalRecentViewsLazyQuery>;
export type ProductSearchModalRecentViewsSuspenseQueryHookResult = ReturnType<typeof useProductSearchModalRecentViewsSuspenseQuery>;
export type ProductSearchModalRecentViewsQueryResult = Apollo.QueryResult<ProductSearchModalRecentViewsQuery, ProductSearchModalRecentViewsQueryVariables>;