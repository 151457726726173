import { gql } from "@apollo/client";
import { SelectOption } from "@msys/ui";
import { ROOT_ITEM_ID } from "../../trees-virtual/helpers";
import { isAnyParentMatching } from "../../trees/helpers";
import { ItemTreeItem } from "../../trees/types";
import { getPersonLabel } from "../users/helpers";
import { TaskShouldRender_ItemFragment } from "./helpers.generated";
import { AssigneeOptions_ProjectFragment } from "./Tasks.generated";

export function shouldRenderTaskCreateInput<T extends ItemTreeItem>(
  item: T
): boolean {
  return item.id !== ROOT_ITEM_ID;
}

export function shouldRenderItem<T extends TaskShouldRender_ItemFragment>(
  item: T,
  allItems: T[]
): boolean {
  return (
    item.isTreePreviewItemVisible &&
    !item.isItemEliminated &&
    !item.decisionOptionElimination &&
    !isAnyParentMatching(
      item,
      allItems,
      parentItem =>
        Boolean(parentItem.decisionOptionElimination) ||
        parentItem.isItemEliminated ||
        !item.isTreePreviewItemVisible
    )
  );
}

export function shouldRenderChildItem<T extends TaskShouldRender_ItemFragment>(
  childItem: T
): boolean {
  return (
    childItem.isTreePreviewItemVisible &&
    !childItem.isItemEliminated &&
    !childItem.decisionOptionElimination
  );
}

export const getAssigneeOptions = (
  project: AssigneeOptions_ProjectFragment,
  compact: boolean = false
): SelectOption[] => {
  return project.internalStakeholders
    .map(s => ({
      label: getPersonLabel(s.user, compact),
      value: s.user.id,
    }))
    .sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
    );
};
