/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountingBox__OrganisationSettingsFragment = { __typename: 'OrganisationSetting', id: string, invoiceNumberMinLength: number, invoiceNumberPrefix: string, timeReportNumberMinLength: number, timeReportNumberPrefix: string, purchaseOrderNumberMinLength: number, purchaseOrderNumberPrefix: string, crmOrganisationNumberMinLength: number, crmOrganisationNumberPrefix: string, crmUserNumberMinLength: number, crmUserNumberPrefix: string, quoteNumberMinLength: number, quoteNumberPrefix: string, requirementNumberMinLength: number, requirementNumberPrefix: string };

export type AccountingBox__OrganisationCountersFragment = { __typename: 'OrganisationCounter', id: string, invoiceCounter: number, timeReportCounter: number, purchaseOrderCounter: number, crmOrganisationCounter: number, crmUserCounter: number, quoteCounter: number, requirementCounter: number };

export type OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationVariables = Types.Exact<{
  input: Types.OrganisationSettingsUpdateInput;
}>;


export type OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation = { updateOrganisationSettings: { __typename: 'OrganisationSettingsUpdateResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, invoiceNumberMinLength: number, invoiceNumberPrefix: string, timeReportNumberMinLength: number, timeReportNumberPrefix: string, purchaseOrderNumberMinLength: number, purchaseOrderNumberPrefix: string, crmOrganisationNumberMinLength: number, crmOrganisationNumberPrefix: string, crmUserNumberMinLength: number, crmUserNumberPrefix: string, quoteNumberMinLength: number, quoteNumberPrefix: string, requirementNumberMinLength: number, requirementNumberPrefix: string } } };

export type OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationVariables = Types.Exact<{
  input: Types.OrganisationCountersUpdateInput;
}>;


export type OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation = { updateOrganisationCounters: { __typename: 'OrganisationCountersUpdateResult', organisationCounters: { __typename: 'OrganisationCounter', id: string, invoiceCounter: number, timeReportCounter: number, purchaseOrderCounter: number, crmOrganisationCounter: number, crmUserCounter: number, quoteCounter: number, requirementCounter: number } } };

export const AccountingBox__OrganisationSettingsFragmentDoc = gql`
    fragment AccountingBox__OrganisationSettings on OrganisationSetting {
  id
  invoiceNumberMinLength
  invoiceNumberPrefix
  timeReportNumberMinLength
  timeReportNumberPrefix
  purchaseOrderNumberMinLength
  purchaseOrderNumberPrefix
  crmOrganisationNumberMinLength
  crmOrganisationNumberPrefix
  crmUserNumberMinLength
  crmUserNumberPrefix
  quoteNumberMinLength
  quoteNumberPrefix
  requirementNumberMinLength
  requirementNumberPrefix
}
    `;
export const AccountingBox__OrganisationCountersFragmentDoc = gql`
    fragment AccountingBox__OrganisationCounters on OrganisationCounter {
  id
  invoiceCounter
  timeReportCounter
  purchaseOrderCounter
  crmOrganisationCounter
  crmUserCounter
  quoteCounter
  requirementCounter
}
    `;
export const OrganisationAccountingSettingsBox_ModifyOrganisationSettingsDocument = gql`
    mutation OrganisationAccountingSettingsBox_ModifyOrganisationSettings($input: OrganisationSettingsUpdateInput!) {
  updateOrganisationSettings(input: $input) {
    organisationSettings {
      id
      ...AccountingBox__OrganisationSettings
    }
  }
}
    ${AccountingBox__OrganisationSettingsFragmentDoc}`;
export type OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationFn = Apollo.MutationFunction<OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation, OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationVariables>;

/**
 * __useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation__
 *
 * To run a mutation, you first call `useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationAccountingSettingsBoxModifyOrganisationSettingsMutation, { data, loading, error }] = useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation, OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation, OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationVariables>(OrganisationAccountingSettingsBox_ModifyOrganisationSettingsDocument, options);
      }
export type OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationHookResult = ReturnType<typeof useOrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation>;
export type OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationResult = Apollo.MutationResult<OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation>;
export type OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationOptions = Apollo.BaseMutationOptions<OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutation, OrganisationAccountingSettingsBox_ModifyOrganisationSettingsMutationVariables>;
export const OrganisationAccountingSettingsBox_ModifyOrganisationCountersDocument = gql`
    mutation OrganisationAccountingSettingsBox_ModifyOrganisationCounters($input: OrganisationCountersUpdateInput!) {
  updateOrganisationCounters(input: $input) {
    organisationCounters {
      id
      ...AccountingBox__OrganisationCounters
    }
  }
}
    ${AccountingBox__OrganisationCountersFragmentDoc}`;
export type OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationFn = Apollo.MutationFunction<OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation, OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationVariables>;

/**
 * __useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation__
 *
 * To run a mutation, you first call `useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationAccountingSettingsBoxModifyOrganisationCountersMutation, { data, loading, error }] = useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation, OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation, OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationVariables>(OrganisationAccountingSettingsBox_ModifyOrganisationCountersDocument, options);
      }
export type OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationHookResult = ReturnType<typeof useOrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation>;
export type OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationResult = Apollo.MutationResult<OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation>;
export type OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationOptions = Apollo.BaseMutationOptions<OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutation, OrganisationAccountingSettingsBox_ModifyOrganisationCountersMutationVariables>;