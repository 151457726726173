import { useFormatting } from "@msys/ui";
import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Stack } from "../../../commons/layout/Stack";
import { px } from "../../../../common/MuiThemeProvider";
import { TimeReportItem__EntityFragment } from "../../../main-routes/projects/ProjectTimeReportPreview.generated";

interface Props {
  item: TimeReportItem__EntityFragment;
  index: number;
}

export const TimeReportItemMobileRow: FC<Props> = ({ item, index }) => {
  const { getFormattedDate, getFormattedDuration } = useFormatting();
  return (
    <Box p={1}>
      <Stack>
        <Typography style={{ minWidth: "32px" }} variant="body2">
          {index + 1}.
        </Typography>
        <Stack flexDirection="column" flexGrow={1}>
          <Typography component="div">
            {item.assignee.firstname} {item.assignee.familyname}
          </Typography>
          <Stack justifyContent="flex-end" width="100%">
            <Typography variant="body2" component="div">
              {getFormattedDate(item.date)}
            </Typography>
            <Typography
              variant="body2"
              style={{ minWidth: "64px" }}
              component="div"
              align="right"
            >
              {getFormattedDuration(item.duration)} h
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
