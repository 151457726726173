/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { VirtualItem__DocumentItemFragmentDoc } from '../../../trees-virtual/types.generated';
export type RequiredOnCompletionBox_ItemFragment = { __typename: 'Item', id: string, docId: string, deletedAt?: any | null, canBeWorkedOn: boolean, timeTrackingRequired: boolean, approvalRequired: boolean, isApproved?: boolean | null, isVisibleToOtherSide: boolean, photoApprovalRequired: boolean, qaApprovalRequired: boolean, isQaApproved?: boolean | null, title: string, rank: number, level: number, parentId?: string | null, isRootItem: boolean, pathForPdf: string, pathSortable: string, isHidden: boolean, isPriceHidden: boolean, isParentDecisionPreselected: boolean, isParentDecisionNotPreselected: boolean, isParentContingencyNotPreselected: boolean, type: Types.ItemType, binding: Types.ItemBinding, decisionIsContingentItem: boolean, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, childrenVisibility: Types.ItemChildrenVisibility, isItemEliminated: boolean, hasChildren: boolean, photoApproved?: { __typename: 'AttachmentSnapshot', id: string, title: string, url: string } | null, workSessions: Array<{ __typename: 'WorkSession', id: string }>, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export type RequiredOnCompletionBox_ProjectFragment = { __typename: 'Project', id: string };

export const RequiredOnCompletionBox_ItemFragmentDoc = gql`
    fragment RequiredOnCompletionBox_Item on Item {
  id
  docId
  deletedAt
  canBeWorkedOn
  timeTrackingRequired
  approvalRequired
  isApproved
  isVisibleToOtherSide
  photoApprovalRequired
  photoApproved {
    id
    title
    url
  }
  qaApprovalRequired
  isQaApproved
  workSessions {
    id
  }
  ...VirtualItem__DocumentItem
}
    ${VirtualItem__DocumentItemFragmentDoc}`;
export const RequiredOnCompletionBox_ProjectFragmentDoc = gql`
    fragment RequiredOnCompletionBox_Project on Project {
  id
}
    `;