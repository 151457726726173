import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  CollapseSection,
  LabeledValue,
  TruncatedHtmlValue,
  useFormatting,
} from "@msys/ui";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { LabeledPropertiesValue } from "../../item-properties/LabeledPropertiesValue";
import { useItemManualProductBoxQuery } from "./ItemManualProductBox.generated";

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  overriddenNetPrice?: number;
}

export const ItemManualProductBox = ({
  projectId,
  docId,
  itemId,
  overriddenNetPrice,
}: Props) => {
  const { t } = useTranslate([
    "QuoteItem",
    "Global",
    "Product",
    "ItemPropertyField",
  ]);

  const client = useApolloClient();
  const query = useItemManualProductBoxQuery({
    client,
    variables: {
      projectId: projectId,
      docId: docId,
      itemId: itemId,
    },
  });

  const { getFormattedPrice } = useFormatting();

  const item = getDataOrNull(query.data?.item);

  if (!item) return null;
  if (!item.product) return null;

  const netPrice = overriddenNetPrice ?? item.materialBuyingPrice;

  return (
    <CardContainer
      isExpandable
      title={t("Product", {
        ns: "QuoteItem",
      })}
    >
      <Stack direction="column" m={1} spacing={1}>
        <Typography variant={"h3"}>
          {item.product.title ?? item.product.articleNumber}
        </Typography>
        <Stack marginBottom={2} direction="column" spacing={1}>
          <TruncatedHtmlValue
            textProps={{ variant: "body1" }}
            lines={5}
            html={item.product.description.trim()}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
            notSetLabel={t("Not set", { ns: "Global" })}
          />
        </Stack>
        <Box>
          <Grid container spacing={2} style={{ clear: "left" }}>
            {item.product.productCategory && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Category", {
                    ns: "Product",
                  })}
                >
                  {item.product.productCategory}
                </LabeledValue>
              </Grid>
            )}
            <Grid item xs={6}>
              <LabeledValue
                label={t("Article Number", {
                  ns: "Product",
                })}
              >
                {item.product.articleNumber}
              </LabeledValue>
            </Grid>
            {item.product.supplier && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Supplier", {
                    ns: "Product",
                  })}
                >
                  {item.product.supplier.title}
                </LabeledValue>
              </Grid>
            )}
            {item.product.gtin && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("GTIN", {
                    ns: "Product",
                  })}
                >
                  {item.product.gtin}
                </LabeledValue>
              </Grid>
            )}
            {item.product.manufacturerArticleNumber && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Manufacturer article number", {
                    ns: "Product",
                  })}
                >
                  {item.product.manufacturerArticleNumber}
                </LabeledValue>
              </Grid>
            )}
            {item.product.brand && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Brand", {
                    ns: "Product",
                  })}
                >
                  {item.product.brand}
                </LabeledValue>
              </Grid>
            )}
            {item.product.brandLine && (
              <Grid item xs={6}>
                <LabeledValue
                  label={t("Brand line", {
                    ns: "Product",
                  })}
                >
                  {item.product.brandLine}
                </LabeledValue>
              </Grid>
            )}
          </Grid>
        </Box>
        <CollapseSection
          title={t("Product Properties", {
            ns: "QuoteItem",
          })}
          isInitiallyExpanded={item.product.props2.length > 0}
          titleVariant="h4"
          itemCount={item.product.props2.length}
          hideItemCountOnExpand={true}
        >
          <LabeledPropertiesValue
            properties={item.product.props2}
            viewerIsContractor={true}
            hideVisibility={true}
          />
        </CollapseSection>

        <LabeledValue
          label={t("Net Price", {
            ns: "Product",
          })}
        >
          {netPrice ? getFormattedPrice(netPrice) : "-"}
        </LabeledValue>
      </Stack>
    </CardContainer>
  );
};
