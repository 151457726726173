import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useFormatting } from "@msys/ui";
import { Link, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isBoolean } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { OrganisationQuoteTemplatesFilters } from "../../../../clients/graphqlTypes";
import {
  FilterChip,
  FilterChipBoolean,
  FilterChipGroup,
} from "../../../commons/filters/FilterChip";
import { PropertiesFilterChips } from "../../properties/PropertiesFilterChips";
import { TemplateFiltersFormValues } from "../filters/TemplateFilterFields";
import { useTemplatesQuoteFilterChips_TemplateTypesQuery } from "./TemplatesQuoteFilterChips.generated";

type Filters = Pick<
  OrganisationQuoteTemplatesFilters,
  | "createdAfter"
  | "createdBefore"
  | "priceMin"
  | "priceMax"
  | "tagsAny"
  | "addedToShop"
>;

interface Props<F extends Filters> {
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
  templateFilters?: TemplateFiltersFormValues;
  setTemplateFilters?: Dispatch<SetStateAction<TemplateFiltersFormValues>>;
  onReset?(): void | Promise<void>;
  canChangeTemplateType?: boolean;
}

export const TemplatesQuoteFilterChips = <F extends Filters>({
  filters,
  setFilters,
  templateFilters,
  setTemplateFilters,
  canChangeTemplateType = true,
  onReset,
}: Props<F>) => {
  const { t } = useTranslate("Templates");
  const { getFormattedDate } = useFormatting();

  const client = useApolloClient();
  const templateTypesQuery = useTemplatesQuoteFilterChips_TemplateTypesQuery({
    client,
    variables: {
      ids: templateFilters?.templateTypeIds ?? [],
    },
    fetchPolicy: "cache-and-network",
    skip:
      !templateFilters?.templateTypeIds ||
      templateFilters.templateTypeIds.length === 0,
  });

  const getTemplateTypeTitle = (id: string) =>
    getDataOrNull(
      (templateTypesQuery.data ?? templateTypesQuery.previousData)
        ?.templateTypes
    )?.templateTypes?.find(templateType => templateType.id === id)?.title;

  return canShowChips(filters, templateFilters) ? (
    <Stack
      direction={"row"}
      spacing={1}
      flexWrap="wrap"
      alignItems="center"
      useFlexGap
    >
      {templateFilters?.templateTypeIds &&
        templateFilters.templateTypeIds.length > 0 && (
          <FilterChipGroup
            values={templateFilters.templateTypeIds}
            setValues={newValues => {
              setTemplateFilters?.(filters => ({
                ...filters,
                templateTypeIds:
                  newValues && newValues.length > 0 ? newValues : null,
              }));
            }}
            getValueLabel={getTemplateTypeTitle}
            canEdit={canChangeTemplateType}
          />
        )}
      <FilterChip
        label={t("Created after")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            createdAfter: newValue,
          }))
        }
        value={filters.createdAfter && getFormattedDate(filters.createdAfter)}
      />
      <FilterChip
        label={t("Created before")}
        resetValue={null}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            createdBefore: newValue,
          }))
        }
        value={filters.createdBefore && getFormattedDate(filters.createdBefore)}
      />
      <FilterChip
        label={t("Min price")}
        resetValue={0}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            priceMin: newValue,
          }))
        }
        value={filters.priceMin}
      />
      <FilterChip
        label={t("Max price")}
        resetValue={0}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            priceMax: newValue,
          }))
        }
        value={filters.priceMax}
      />
      <FilterChipBoolean
        label={t("Added to Shop")}
        setValue={newValue =>
          setFilters(filters => ({
            ...filters,
            addedToShop: newValue ?? null,
          }))
        }
        value={filters.addedToShop}
      />
      <FilterChipGroup
        label={t("Tag")}
        values={filters.tagsAny}
        getValueLabel={value => value}
        setValues={value =>
          setFilters(state => ({
            ...state,
            tagsAny: value,
          }))
        }
      />
      {templateFilters?.properties && (
        <PropertiesFilterChips
          values={templateFilters?.properties}
          setValues={newValue =>
            setTemplateFilters?.(filters => ({
              ...filters,
              properties: newValue ?? null,
            }))
          }
        />
      )}
      {onReset && (
        <Typography variant="body2">
          <Link onClick={onReset} color="secondary" sx={{ cursor: "pointer" }}>
            {t("Clear all", { ns: "Global" })}
          </Link>
        </Typography>
      )}
    </Stack>
  ) : null;
};

export const canShowChips = (
  filters: Filters,
  templateFilters?: TemplateFiltersFormValues
): boolean =>
  Boolean(
    filters.priceMin ||
      filters.priceMax ||
      filters.createdAfter ||
      filters.createdBefore ||
      isBoolean(filters.addedToShop) ||
      filters.tagsAny?.length ||
      templateFilters?.templateTypeIds?.length ||
      templateFilters?.properties?.length
  );
