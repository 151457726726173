import { DataGrid, GridColDef } from "@msys/ui";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, MenuItem, Select } from "@mui/material";
import * as Sentry from "@sentry/react";
import { take } from "lodash";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { CLIENT_PER_PAGE_OPTIONS } from "../../constants";
import { DragAndDropContainer } from "../../features/attachments/DragAndDropContainer";
import { useTranslate } from "@tolgee/react";

const parseCsv = async (file: File) => {
  const Papa = await import("papaparse");
  return await new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: false,
      complete: data => {
        resolve(data);
      },
      error(error: Error) {
        reject(error);
      },
    });
  });
};

export type MappingRow = { header: string; values: string[]; index: number };

export type MappingValue<MappingField extends string> = {
  field: "" | MappingField;
  index: number;
};

export type MappingSet<MappingField extends string> = Record<
  string,
  MappingValue<MappingField>
>;

interface Props<MappingField extends string> {
  mappingsData: MappingRow[] | null;
  setMappingsData: React.Dispatch<React.SetStateAction<MappingRow[] | null>>;
  mappingsValue: MappingSet<MappingField>;
  setMappingsValue: React.Dispatch<
    React.SetStateAction<MappingSet<MappingField>>
  >;
  mappingsLabels: Record<MappingField, string>;
  mappingsList: MappingField[];
}

export function ImportMappingsStage<MappingField extends string>({
  mappingsData,
  setMappingsData,
  mappingsValue,
  setMappingsValue,
  mappingsLabels,
  mappingsList,
}: Props<MappingField>) {
  const { t } = useTranslate(["Global"]);
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = React.useState<"idle" | "loading" | "error">(
    "idle"
  );

  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 100 });

  const handleFile = async (file: File) => {
    setStatus("loading");
    try {
      const data = await parseCsv(file);
      const headerRow = (data as any).data[0];
      const dataRows = (data as any).data.slice(1);

      const mappingsData = headerRow.map((header: any, index: number) => {
        return {
          header,
          index,
          values: dataRows.map((row: any) => row?.[index] ?? ""),
        };
      });

      setMappingsData(mappingsData);

      setStatus("idle");
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
      setStatus("error");
      console.error(e);
      Sentry.captureException(e);
    }
  };

  return (
    <Box maxHeight="100%" overflow="hidden">
      {!mappingsData ? (
        <DragAndDropContainer
          onFiles={files => handleFile(files[0])}
          title={t("Upload .csv file", {
            ns: "Global",
          })}
          disabled={status === "loading"}
          accept=".csv"
          iconComponent={AttachFileIcon}
          multiple={false}
        />
      ) : (
        <DataGrid<MappingRow>
          density="compact"
          loading={false}
          hideFooter={mappingsData.length === 0}
          columns={
            [
              {
                field: "column",
                headerName: t("Column", {
                  ns: "Global",
                }),
                flex: 1.5,
                minWidth: 50,
                sortable: false,
                valueGetter: ({ row }) => row.header,
              },
              {
                field: "description",
                headerName: t("Value", {
                  ns: "Global",
                }),
                flex: 2,
                minWidth: 50,
                sortable: false,
                renderCell: ({ row }) =>
                  take(row.values.filter(Boolean), 5).join(", "),
              },
              {
                field: "value",
                headerName: t("Label", {
                  ns: "Global",
                }),
                flex: 1.5,
                minWidth: 50,
                sortable: false,
                renderCell: ({ row }) => (
                  <Select
                    variant="filled"
                    size="extra-small"
                    placeholder={t("Label", {
                      ns: "Global",
                    })}
                    fullWidth
                    value={mappingsValue[row.index]?.field || ""}
                    onChange={e => {
                      setMappingsValue(v => ({
                        ...v,
                        [row.index]: {
                          index: row.index,
                          field: e.target.value as "" | MappingField,
                        },
                      }));
                    }}
                  >
                    <MenuItem value="">
                      {t("None", {
                        ns: "Global",
                      })}
                    </MenuItem>
                    {mappingsList.map(value => (
                      <MenuItem value={value} key={value}>
                        {mappingsLabels[value]}
                      </MenuItem>
                    ))}
                  </Select>
                ),
              },
            ] as GridColDef<MappingRow>[]
          }
          getRowId={row => row.index}
          rows={mappingsData}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={CLIENT_PER_PAGE_OPTIONS}
          rowCount={mappingsData.length}
          filterMode={"client"}
          paginationMode={"client"}
          sortingMode={"client"}
        />
      )}
    </Box>
  );
}
