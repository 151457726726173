import { ModalOpenButton } from "@msys/ui";
import { Chip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { PimProductCategoryFragment } from "./Product.generated";
import { ProductCategoryTreeSelectModal } from "./ProductCategoryTreeSelectModal";

export const ProductCategoryTreeChip = ({
  productCategory,
  supplier,
  handleSelect,
}: {
  productCategory: PimProductCategoryFragment | null;
  supplier: { id: string; title: string };
  handleSelect(filter: {
    supplierProductCategoryKey?: string | null;
    supplierId?: string | null;
  }): void;
}) => {
  const { t } = useTranslate(["Product", "ProductSearch", "Global"]);

  return (
    <ModalOpenButton
      Modal={ProductCategoryTreeSelectModal}
      modalProps={{
        supplierId: supplier.id,
        categoryKey: productCategory?.key ?? null,
        handleSelect,
      }}
    >
      <Chip
        size={"small"}
        variant="outlined"
        color="primary"
        label={
          productCategory
            ? `${productCategory.title} ❯`
            : `${t("Category", { ns: "Product" })} ❯`
        }
      />
    </ModalOpenButton>
  );
};
