import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  DurationType,
  getDurationInSeconds,
  getNormalizedDuration,
  Modal,
} from "@msys/ui";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { DatePickerField } from "../../../commons/form-fields/DatePickerField";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { SwitchField } from "../../../commons/form-fields/SwitchField";
import { Stack } from "../../../commons/layout/Stack";
import {
  useModifyQuoteOfferConditionsMutation,
  useQuoteOfferConditionsModalQuery,
} from "./QuoteOfferConditionsModal.generated";
import { useTranslate } from "@tolgee/react";
import { DURATION_MAX_VALUES } from "../../../utils";

interface FormValues {
  showOfferConditions: boolean;
  earliestDate?: Moment | null;
  durationAmount: number;
  durationType: DurationType;
  expirationDate?: Moment | null;
}

interface Props {
  id?: string;
  projectId: string;
  quoteId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (handleClose: () => void) => void | Promise<void>;
}

export const QuoteOfferConditionsModal = ({
  id,
  projectId,
  quoteId,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["QuoteEditFooter", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const query = useQuoteOfferConditionsModalQuery({
    client,
    variables: {
      quoteId,
      projectId,
    },
  });

  const quote = getDataOrNull(query?.data?.quote)?.quote;

  const [modifyQuoteOfferConditions] = useModifyQuoteOfferConditionsMutation({
    client,
  });

  const onSubmit = async (values: FormValues) => {
    try {
      await modifyQuoteOfferConditions({
        variables: {
          input: {
            docId: quoteId,
            projectId,
            values: {
              showOfferConditions: values.showOfferConditions,
              earliestDate: values.earliestDate?.format("YYYY-MM-DD"),
              duration: getDurationInSeconds({
                durationAmount: values.durationAmount,
                durationType: values.durationType,
              }),
              expirationDate: values.expirationDate?.format("YYYY-MM-DD"),
            },
          },
        },
      });
      if (handleComplete) {
        await handleComplete(handleClose);
      } else {
        handleClose();
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    showOfferConditions: Yup.boolean()
      .label(
        t("Offer conditions are visible", {
          ns: "QuoteEditFooter",
        })
      )
      .required(),
    earliestDate: Yup.date()
      .label(
        t("Earliest work start", {
          ns: "QuoteEditFooter",
        })
      )
      .defined()
      .nullable(),
    durationAmount: Yup.number()
      .label(
        t("Work duration", {
          ns: "QuoteEditFooter",
        })
      )
      .required(),
    durationType: Yup.string()
      .label(
        t("Units", {
          ns: "QuoteEditFooter",
        })
      )
      .oneOf(["days", "weeks", "months", "none"])
      .required(),
    expirationDate: Yup.date()
      .label(
        t("Valid Until", {
          ns: "QuoteEditFooter",
        })
      )
      .nullable()
      .defined(),
  });

  const initialValues: FormValues = React.useMemo(() => {
    const [durationAmount, durationType] = getNormalizedDuration(
      quote?.duration || 0
    );

    return {
      showOfferConditions: quote?.showOfferConditions ?? true,
      earliestDate: quote?.earliestDate ? moment(quote.earliestDate) : null,
      durationAmount,
      durationType,
      expirationDate: quote?.expirationDate
        ? moment(quote.expirationDate)
        : null,
    };
  }, [quote]);

  return (
    <Formik<FormValues>
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      // validateOnMount
    >
      {formikProps => (
        <Modal
          id={id}
          title={
            title ??
            t("Offer conditions", {
              ns: "QuoteEditFooter",
            })
          }
          maxWidth="xs"
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled:
                  formikProps.isSubmitting ||
                  !formikProps.dirty ||
                  !formikProps.isValid,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={query.loading}
        >
          <Form id={formId}>
            <Stack flexDirection="column" spacing={1}>
              <SwitchField
                name="showOfferConditions"
                label={t("Offer conditions are visible", {
                  ns: "QuoteEditFooter",
                })}
                disabled={false}
              />
              <DatePickerField
                name="earliestDate"
                label={t("Earliest work start", {
                  ns: "QuoteEditFooter",
                })}
                disabled={
                  formikProps.isSubmitting ||
                  formikProps.values.showOfferConditions === false
                }
              />

              <Stack>
                <Field
                  component={TextField}
                  name="durationAmount"
                  label={t("Work duration", {
                    ns: "QuoteEditFooter",
                  })}
                  type="number"
                  min={0}
                  max={DURATION_MAX_VALUES[formikProps.values.durationType]}
                  disabled={
                    formikProps.isSubmitting ||
                    formikProps.values.showOfferConditions === false
                  }
                />
                <SelectField
                  name="durationType"
                  label={t("Units", {
                    ns: "QuoteEditFooter",
                  })}
                  disabled={
                    formikProps.isSubmitting ||
                    formikProps.values.showOfferConditions === false
                  }
                  options={[
                    {
                      label: "-",
                      value: "none",
                    },
                    {
                      label: t("Days", {
                        ns: "QuoteEditFooter",
                      }),
                      value: "days",
                    },
                    {
                      label: t("Weeks", {
                        ns: "QuoteEditFooter",
                      }),
                      value: "weeks",
                    },
                    {
                      label: t("Months", {
                        ns: "QuoteEditFooter",
                      }),
                      value: "months",
                    },
                  ]}
                />
              </Stack>

              <DatePickerField
                name="expirationDate"
                label={t("Valid Until", {
                  ns: "QuoteEditFooter",
                })}
                disabled={
                  formikProps.isSubmitting ||
                  formikProps.values.showOfferConditions === false
                }
                disablePast
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
