import { gql, useApolloClient } from "@apollo/client";
import { MenuItemWithIcon } from "@msys/ui";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { FormControlLabel, MenuItem, Switch } from "@mui/material";
import React from "react";
import { useMakeQuoteBindingMutation } from "../Quotes.generated";
import { ToggleQuoteBindingMenuItem_QuoteFragment } from "./ToggleQuoteBindingMenuItem.generated";
import { useTranslate } from "@tolgee/react";

export const ToggleQuoteBindingMenuItem = ({
  doc,
  docId,
  projectId,
  disabled = false,
}: {
  doc: ToggleQuoteBindingMenuItem_QuoteFragment;
  docId: string;
  projectId: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslate("Quote");
  const client = useApolloClient();

  const [makeQuoteBinding, { loading: modifyLoading }] =
    useMakeQuoteBindingMutation({
      client,
    });

  const onChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    await makeQuoteBinding({
      variables: {
        projectId,
        docId,
        isBinding: checked,
      },
    });
  };

  return disabled ? (
    <MenuItemWithIcon
      disabled
      icon={doc.isBinding ? <CheckIcon /> : <CancelIcon />}
      sx={theme => ({
        color: doc.isBinding
          ? theme.palette.primary.main
          : theme.palette.grey[600],
      })}
    >
      {doc.isBinding ? t("Quote is binding") : t("Quote is not binding")}
    </MenuItemWithIcon>
  ) : (
    <MenuItem
      disableRipple
      onClick={(event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
      }}
    >
      <FormControlLabel
        style={{ marginRight: 0 }}
        disabled={modifyLoading}
        control={
          <Switch
            size="small"
            checked={doc.isBinding}
            onChange={onChange}
            disabled={modifyLoading}
            sx={{ marginLeft: "2px", marginRight: "4px" }}
          />
        }
        label={t("Quote is binding")}
        onClick={event => event.stopPropagation()}
      />
    </MenuItem>
  );
};
