import { StatusLabel } from "@msys/ui";
import { useTheme } from "@mui/material";
import { CSSProperties, FC } from "react";
import { InvitationState } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

interface Props {
  status: InvitationState;
  style?: CSSProperties;
}

export const InvitationStatusBadge: FC<Props> = ({ style, status }) => {
  const theme = useTheme();
  const { t } = useTranslate(["ProjectOverview", "Global"]);

  const statusLabels: Record<InvitationState, string> = {
    PENDING: t("Pending", { ns: "ProjectOverview" }),
    ACCEPTED: t("Accepted", { ns: "ProjectOverview" }),
    DECLINED: t("Declined", { ns: "ProjectOverview" }),
    DELETED: t("Deleted", { ns: "Global" }),
  };

  switch (status) {
    case "ACCEPTED":
      return (
        <StatusLabel color={theme.palette.success.main} style={style}>
          {statusLabels[status]}
        </StatusLabel>
      );
    case "DECLINED":
      return (
        <StatusLabel color={theme.palette.error.main} style={style}>
          {statusLabels[status]}
        </StatusLabel>
      );
    case "PENDING":
    default:
      return (
        <StatusLabel color={theme.palette.grey[600]} style={style}>
          {statusLabels[status]}
        </StatusLabel>
      );
  }
};
