import { gql, useApolloClient } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useUserData } from "../auth/useUserData";
import { useAddPushNotificationTokenMutation } from "./PushNotificationsProvider.generated";

const PushNotificationsContext = React.createContext<{
  requestPushPermission(): void;
}>(null as any);

export const PushNotificationsProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  // const [
  //   pushPermissions,
  //   setPushPermissions,
  // ] = useState<PermissionStatus | null>(null);

  // useEffectOnce(() => {
  //   if (!Capacitor.isNativePlatform()) return;

  //   (async () => {
  //     const status = await PushNotifications.checkPermissions();
  //     setPushPermissions(status);
  //     // TODO: register token if viewer doesn't have it in
  //   })();
  // });

  const client = useApolloClient();

  const viewer = useUserData().currentUser!;

  const [addPushNotificationToken] = useAddPushNotificationTokenMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: ["Me"],
  });

  const requestPushPermission = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) return;

    // const { receive } = await PushNotifications.checkPermissions();
    // if (receive === "granted") return;

    // TODO: show alert if permission are denied

    const result = await PushNotifications.requestPermissions();
    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      await PushNotifications.register();
    } else {
      // Show some error
      console.error("error requestPermissions: " + result.receive);
    }
  }, []);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    if (viewer) {
      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", async (token: Token) => {
        console.log("Push registration success, token: " + token.value);
        // console.log("client", client);

        try {
          const res = await addPushNotificationToken({
            variables: {
              token: token.value,
            },
          });

          // const status = await PushNotifications.checkPermissions();
          // setPushPermissions(status);

          console.log("apollo.SetPushNotificationToken", res);
        } catch (err) {
          console.error("apollo.SetPushNotificationToken.error", err);
        }
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        console.error("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          console.log("Push received: " + JSON.stringify(notification));

          // if (notification.data.link) window.location.href = notification.data.link;
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (action: ActionPerformed) => {
          console.log("Push action performed: " + JSON.stringify(action));

          const { link, organisationId, organisationName } =
            action.notification.data;

          if (link) {
            if (organisationId && organisationName) {
              if (organisationId !== viewer.organisation.id) {
                alert("Please switch to organisation: " + organisationName);

                // TODO alert to switch organisation
                return;
              }
            }

            window.location.href = link;
          }
        }
      );
    }

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [addPushNotificationToken, viewer]);

  return (
    <PushNotificationsContext.Provider
      value={{
        requestPushPermission,
      }}
    >
      {children}
    </PushNotificationsContext.Provider>
  );
};

export const usePushNotifications = () => useContext(PushNotificationsContext);
