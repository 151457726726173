import { Autocomplete, Modal, ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { DialogContentText, IconButton, Stack } from "@mui/material";
import React from "react";
import { AddressInput } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { GetAddressModal } from "./GetAddressModal";
import { getAddressLabel } from "./helpers";

type Option = {
  address: AddressInput;
  title: string;
};

interface Props {
  id?: string;
  inputLabel: string;
  options: Option[];
  preselectedOption: Option | null;
  title?: string;
  description?: string;
  handleClose: () => void;
  handleComplete: (
    address: AddressInput,
    handleClose: () => void
  ) => Promise<void>;
  allowCustom?: boolean;
}

export const SelectExistingAddressModal = ({
  id,
  inputLabel,
  options,
  preselectedOption,
  title,
  description,
  handleClose,
  handleComplete,
  allowCustom = false,
}: Props) => {
  const { t } = useTranslate(["Address", "Global"]);
  const [selectedAddress, setSelectedAddress] = React.useState(
    preselectedOption ?? null
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <Modal
      id={id}
      title={
        title ??
        t("Address", {
          ns: "Address",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Confirm", {
            ns: "Global",
          }),
          buttonProps: {
            disabled: !selectedAddress,
            onClick: async () => {
              if (!selectedAddress) return;
              setIsSubmitting(true);
              await handleComplete(selectedAddress.address, handleClose);
              setIsSubmitting(false);
            },
            loading: isSubmitting,
          },
        },
      ]}
    >
      {description && <DialogContentText>{description}</DialogContentText>}
      <Stack direction={"row"} spacing={1}>
        <Autocomplete
          inputLabel={inputLabel}
          options={options}
          getOptionLabel={option =>
            `${getAddressLabel(option.address)}${
              option.title ? ` (${option.title})` : ""
            }`
          }
          value={selectedAddress}
          onChange={value => {
            setSelectedAddress(value);
          }}
        />
        <ModalOpenButton
          Modal={GetAddressModal}
          modalProps={{
            title,
            description,
            inputLabel,
            handleComplete: async (address, handleClose) => {
              await handleComplete(address, handleClose);
            },
            allowCustom,
          }}
        >
          <IconButton>
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      </Stack>
    </Modal>
  );
};
