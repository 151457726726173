import { Modal } from "@msys/ui";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";

interface Props {
  handleClose(): void;
}

export function AvailableReplacementsModal({
  handleClose,
  children,
}: React.PropsWithChildren<Props>) {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  return (
    <Modal
      title={t("Using placeholders in texts", {
        ns: "OrganisationSettings",
      })}
      handleClose={handleClose}
      notInStack
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
        },
      ]}
      maxWidth="md"
    >
      <Typography variant="body1" gutterBottom>
        {t(
          "You can use placeholders to fill in information automatically in different parts of your documents.",
          {
            ns: "OrganisationSettings",
          }
        )}
      </Typography>
      <Typography variant="body1">
        {t(
          "The following placeholders are available for use in the opening and closing texts of the documents listed below.",
          {
            ns: "OrganisationSettings",
          }
        )}
      </Typography>

      <Box pt={2}>
        <Table size="small">
          <colgroup>
            <col width="35%" />
            <col width="65%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                {t("Placeholder", {
                  ns: "OrganisationSettings",
                })}
              </TableCell>
              <TableCell>
                {t("Description", {
                  ns: "OrganisationSettings",
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </Box>
    </Modal>
  );
}
