import { useApolloClient } from "@apollo/client";
import { Attachment, CardContainer } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { omit } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { ManualSave } from "../../../commons/form-fields/ManualSave";
import { getAttachmentValidationSchema } from "../../attachments/AttachmentField";
import { AttachmentFileRow } from "../../attachments/AttachmentFileRow";
import { AttachmentUploadRow } from "../../attachments/AttachmentUploadRow";
import {
  OrganisationProjectDefaultSettingsBox_SettingsFragment,
  useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation,
} from "./OrganisationProjectDefaultSettingsBox.generated";

interface Props {
  organisationSettings: OrganisationProjectDefaultSettingsBox_SettingsFragment;
  organisationId: string;
}

export function OrganisationProjectDefaultSettingsBox(props: Props) {
  const { t } = useTranslate("OrganisationSettings");
  return (
    <CardContainer isExpandable title={t("Project settings")}>
      <OrganisationProjectDefaultSettingsForm {...props} />
    </CardContainer>
  );
}

interface FormValues {
  projectDefaultAttachments: (Attachment & { group: string })[];
}

function OrganisationProjectDefaultSettingsForm({
  organisationId,
  organisationSettings,
}: {
  organisationSettings: OrganisationProjectDefaultSettingsBox_SettingsFragment;
  organisationId: string;
}) {
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const { t } = useTranslate(["OrganisationSettings"]);
  const { enqueueSnackbar } = useSnackbar();
  const [modifyOrganisationSettings] =
    useOrganisationProjectDefaultSettingsForm_ModifyOrganisationSettingsMutation(
      {
        client,
      }
    );

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const initialValues = {
    projectDefaultAttachments: organisationSettings.projectDefaultAttachments,
  };

  const validationSchema = Yup.object().shape({
    projectDefaultAttachments: Yup.array(getAttachmentValidationSchema()),
  });

  const handleSubmit = async (values: FormValues) => {
    await modifyOrganisationSettings({
      variables: {
        input: {
          projectDefaultAttachments: values.projectDefaultAttachments.map(a =>
            omit(a, "__typename")
          ),
        },
      },
    });
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1} p={1}>
            <FieldArray
              name="projectDefaultAttachments"
              render={arrayHelpers => (
                <Stack>
                  <AttachmentUploadRow
                    title={t("Default attachments", {
                      ns: "OrganisationSettings",
                    })}
                    subTitle={t(
                      "Upload documents that will be added to each newly created project.",
                      {
                        ns: "OrganisationSettings",
                      }
                    )}
                    accept="*"
                    multiple={true}
                    onComplete={attachments => {
                      attachments.forEach(attachment =>
                        arrayHelpers.push({
                          ...attachment,
                          group: "",
                          id: v4(),
                        })
                      );
                    }}
                  />
                  {formikProps.values.projectDefaultAttachments.length > 0 &&
                    formikProps.values.projectDefaultAttachments.map(
                      (attachment: Attachment, index: number) => (
                        <AttachmentFileRow
                          key={attachment.id}
                          attachment={attachment}
                          onRemove={async () => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      )
                    )}
                </Stack>
              )}
            />
            <ManualSave
              onCancel={formikProps.handleReset}
              disabled={formikProps.isSubmitting}
              disabledCancel={!formikProps.dirty}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
