/* eslint-disable */
import * as Types from '../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptCrmLinkingInvitationMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type AcceptCrmLinkingInvitationMutation = { acceptCrmLinkingInvitation: { __typename: 'AcceptCrmLinkingInvitationResult', ok: boolean } };


export const AcceptCrmLinkingInvitationDocument = gql`
    mutation AcceptCrmLinkingInvitation($token: String!) {
  acceptCrmLinkingInvitation(invitationToken: $token) {
    ok
  }
}
    `;
export type AcceptCrmLinkingInvitationMutationFn = Apollo.MutationFunction<AcceptCrmLinkingInvitationMutation, AcceptCrmLinkingInvitationMutationVariables>;

/**
 * __useAcceptCrmLinkingInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptCrmLinkingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCrmLinkingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCrmLinkingInvitationMutation, { data, loading, error }] = useAcceptCrmLinkingInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptCrmLinkingInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCrmLinkingInvitationMutation, AcceptCrmLinkingInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCrmLinkingInvitationMutation, AcceptCrmLinkingInvitationMutationVariables>(AcceptCrmLinkingInvitationDocument, options);
      }
export type AcceptCrmLinkingInvitationMutationHookResult = ReturnType<typeof useAcceptCrmLinkingInvitationMutation>;
export type AcceptCrmLinkingInvitationMutationResult = Apollo.MutationResult<AcceptCrmLinkingInvitationMutation>;
export type AcceptCrmLinkingInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptCrmLinkingInvitationMutation, AcceptCrmLinkingInvitationMutationVariables>;