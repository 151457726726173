import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete, Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { debounce, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { FormattedFloatOptionalField } from "../../../commons/form-fields/FormattedFloatOptionalField";
import { Stack } from "../../../commons/layout/Stack";
import { useFeature } from "../../../../common/FeatureFlags";
import {
  QuoteBulkUpdateProductPricingsModal__CrmCompanyFragment,
  QuoteBulkUpdateProductPricingsModal__SupplierDiscountGroupVariantFragment,
  useQuoteBulkUpdateProductPricingsModal__SearchSupplierDiscountGroupVariantsQuery,
  useQuoteBulkUpdateProductPricingsModal__SearchSuppliersQuery,
  useQuoteBulkUpdateProductPricingsModal__UpdateProductPricingsInQuoteMutation,
} from "./QuoteBulkUpdateProductPricingsModal.generated";

interface FormValues {
  supplier: {
    id: string;
    title: string;
    linkedSystemOrganisationId?: string | null;
  } | null;
  supplierDiscountGroupVariant: { id: string; title: string } | null;
  materialMarginOverwrite: number | null;
}

interface Props {
  projectId: string;
  docId: string;
  title?: string;
  refetchQueries?: string[];
  handleClose: () => void;
  handleComplete?: () => Promise<unknown> | unknown;
}

export const QuoteBulkUpdateProductPricingsModal: React.FC<Props> = ({
  projectId,
  docId,
  title,
  refetchQueries,
  handleClose,
  handleComplete,
}) => {
  const { t } = useTranslate(["Quote", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const supplierDiscountGroupVariantsEnabled = useFeature(
    "SupplierDiscountGroupVariants"
  );

  const client = useApolloClient();

  const { suppliers, debouncedRefetch: debouncedRefetchSuppliers } =
    useSuppliers();

  const {
    supplierDiscountGroupVariants,
    debouncedRefetch: debouncedRefetchSupplierDiscountGroupVariants,
  } = useSupplierDiscountGroupVariants();

  const [bulkUpdateProductPricings] =
    useQuoteBulkUpdateProductPricingsModal__UpdateProductPricingsInQuoteMutation(
      {
        client,
        refetchQueries,
      }
    );

  const onSubmit = async (values: FormValues) => {
    if (!(values.supplier && values.supplier.linkedSystemOrganisationId)) {
      return;
    }

    try {
      await bulkUpdateProductPricings({
        variables: {
          input: {
            docId: docId,
            projectId: projectId,
            supplierId: values.supplier.linkedSystemOrganisationId,
            supplierDiscountGroupVariantId: values.supplierDiscountGroupVariant
              ? values.supplierDiscountGroupVariant.id
              : null,
            opts: { materialMargin: values.materialMarginOverwrite },
          },
        },
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, {
          variant: "error",
        });
    }
    handleComplete?.();
    handleClose();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    supplier: Yup.object({
      linkedSystemOrganisationId: Yup.string().required(),
    }).required(),
    supplierDiscountGroupVariant: Yup.object({
      id: Yup.string().required(),
    }).nullable(),
    materialMarginOverwrite: Yup.number().nullable(),
  });

  return (
    <Modal
      title={
        title ??
        t("Pick supplier discount group variant", {
          ns: "Quote",
        })
      }
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Update product pricing", {
            ns: "Quote",
          }),
          buttonProps: {
            type: "submit",
            form: formId,
          },
        },
      ]}
    >
      <Formik<FormValues>
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={{
          supplier: null,
          supplierDiscountGroupVariant: null,
          materialMarginOverwrite: null,
        }}
      >
        {formikProps => (
          <Form id={formId}>
            <Stack flexDirection="column">
              <Autocomplete
                placeholder={t("Type to search", {
                  ns: "Global",
                })}
                inputLabel={t("Supplier", {
                  ns: "Product",
                })}
                options={suppliers}
                getOptionLabel={option => option.title}
                value={formikProps.values.supplier}
                required
                onChange={value => {
                  formikProps.setFieldValue("supplier", value);

                  formikProps.setFieldValue("supplierCatalogue", null);

                  debouncedRefetchSupplierDiscountGroupVariants(
                    value?.linkedSystemOrganisationId ?? null
                  );
                }}
                onInputChange={(_, value, reason) => {
                  if (reason === "input") {
                    debouncedRefetchSuppliers(value);
                  }
                }}
                isOptionEqualToValue={(o, v) => o.id === v.id}
              />
              {supplierDiscountGroupVariantsEnabled && (
                <Autocomplete
                  placeholder={t("Type to search", {
                    ns: "Global",
                  })}
                  inputLabel={t("Supplier discount group variant", {
                    ns: "Product",
                  })}
                  options={supplierDiscountGroupVariants}
                  getOptionLabel={option => option.title}
                  value={formikProps.values.supplierDiscountGroupVariant}
                  onChange={value => {
                    formikProps.setFieldValue(
                      "supplierDiscountGroupVariant",
                      value
                    );
                  }}
                  // onInputChange={(_, value, reason) => {}}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  disabled={!formikProps.values.supplier}
                />
              )}
              <FormattedFloatOptionalField
                name={`materialMarginOverwrite`}
                label={t("Overwrite material margin", {
                  ns: "Quote",
                })}
                unit={"%"}
                multiplier={100}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

function useSuppliers() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [suppliers, setSuppliers] = React.useState<
    QuoteBulkUpdateProductPricingsModal__CrmCompanyFragment[]
  >([]);

  const client = useApolloClient();
  const query = useQuoteBulkUpdateProductPricingsModal__SearchSuppliersQuery({
    client,
    variables: { limit: 50 },
    fetchPolicy: "cache-and-network",
    skip: true,
  });

  const refetch = React.useCallback(
    async (value: string) => {
      setIsFetching(true);
      const result = await query.refetch({
        searchTerm: value,
      });
      setSuppliers(
        getDataOrNull(result.data?.crmCompanies)?.edges?.map(e => e.node) ?? []
      );
      setIsFetching(false);
    },
    [query]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    refetch("");
  }, []);

  return { suppliers, refetch, debouncedRefetch, isFetching };
}

function useSupplierDiscountGroupVariants() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [supplierDiscountGroupVariants, setSupplierDiscountGroupVariants] =
    React.useState<
      QuoteBulkUpdateProductPricingsModal__SupplierDiscountGroupVariantFragment[]
    >([]);

  const client = useApolloClient();
  const query =
    useQuoteBulkUpdateProductPricingsModal__SearchSupplierDiscountGroupVariantsQuery(
      {
        client,
        variables: { limit: 50, supplierId: "" },
        fetchPolicy: "cache-and-network",
        skip: true,
      }
    );

  const refetch = React.useCallback(
    async (supplierId: string | null) => {
      if (supplierId) {
        setIsFetching(true);
        const result = await query.refetch({
          supplierId: supplierId,
        });
        setSupplierDiscountGroupVariants(
          getDataOrNull(result.data?.pimSearchSupplierDiscountGroupVariants)
            ?.supplierDiscountGroupVariants ?? []
        );
        setIsFetching(false);
      } else {
        setSupplierDiscountGroupVariants([]);
      }
    },
    [query]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  return {
    supplierDiscountGroupVariants,
    refetch,
    debouncedRefetch,
    isFetching,
  };
}
