import { gql, useApolloClient } from "@apollo/client";
import { ProjectState } from "@msys/statemachines";
import { CardItem, ModalOpenButton, useScreenWidth } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { CreateNewPhaseModal } from "../opportunities/modals/CreateNewPhaseModal";
import {
  useAddOrganisationProjectPhaseMutation,
  useRearrangeOrganisationProjectPhaseMutation,
  useRemoveOrganisationProjectPhaseMutation,
  useRenameOrganisationProjectPhaseMutation,
  ProjectPhasesConfigurator_OrganisationProjectPhaseFragment,
} from "./ProjectPhasesConfigurator.generated";

interface Props {
  state: ProjectState;
  opportunitiesCount: Record<string, number>;
  phases: ProjectPhasesConfigurator_OrganisationProjectPhaseFragment[];
  isConfiguring: boolean;
  setIsConfiguring: React.Dispatch<React.SetStateAction<boolean>>;
  extraItems?: React.ReactNode[];
}

export const ProjectPhasesConfigurator = ({
  state,
  phases,
  opportunitiesCount,
  isConfiguring,
  setIsConfiguring,
  extraItems,
}: Props) => {
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["Projects", "Opportunities"]);

  const client = useApolloClient();

  const [renameOrganisationProjectPhase] =
    useRenameOrganisationProjectPhaseMutation({ client });
  const [rearrangeOrganisationProjectPhase] =
    useRearrangeOrganisationProjectPhaseMutation({ client });
  const [removeOrganisationProjectPhase] =
    useRemoveOrganisationProjectPhaseMutation({ client });
  const [addOrganisationProjectPhase] = useAddOrganisationProjectPhaseMutation({
    client,
  });

  if (!isMinTablet) return null;

  return (
    <Stack alignItems={"flex-end"}>
      {isConfiguring ? (
        <Stack
          spacing={1}
          padding={2}
          sx={theme => ({
            backgroundColor: theme.palette.blue.paper,
            borderRadius: `${theme.shape.borderRadius}px`,
          })}
          alignSelf={"stretch"}
        >
          <Stack direction="row" spacing={1} justifyContent={"space-between"}>
            <Typography variant="h3">
              {t(
                "Pipeline configuration (rename, reorder, add/delete phases)",
                { ns: "Projects" }
              )}
            </Typography>
            <IconButton
              onClick={() => {
                setIsConfiguring(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Stack
            direction="row"
            alignItems={"center"}
            spacing={1}
            sx={{ overflowX: "auto" }}
            paddingBottom={2}
          >
            {phases.map((phase, index) => (
              <React.Fragment key={phase.id}>
                <Box flexBasis={200} flexShrink={0} flexGrow={1}>
                  <PhaseConfigCard
                    phase={phase}
                    handleChange={async (phaseId, name) => {
                      await renameOrganisationProjectPhase({
                        variables: { input: { phaseId, name } },
                      });
                    }}
                    isUsed={Boolean(
                      opportunitiesCount[phase.id] &&
                        opportunitiesCount[phase.id] > 0
                    )}
                    isOnlyPhase={phases?.length === 1}
                    handleDelete={async () => {
                      await removeOrganisationProjectPhase({
                        variables: {
                          input: { phaseId: phase.id },
                          filterIncludeStates: [state],
                        },
                      });
                    }}
                  />
                </Box>
                {index < phases?.length - 1 ? (
                  <Box>
                    <IconButton
                      color={"primary"}
                      onClick={async () => {
                        await rearrangeOrganisationProjectPhase({
                          variables: {
                            input: {
                              phaseId: phase.id,
                              change: "SWAP_WITH_NEXT",
                            },
                            filterIncludeStates: [state],
                          },
                        });
                      }}
                    >
                      <SwapHorizIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box>
                    <ModalOpenButton
                      Modal={CreateNewPhaseModal}
                      modalProps={{
                        handleComplete: async (phaseName, handleClose) => {
                          await addOrganisationProjectPhase({
                            variables: {
                              state,
                              phaseName,
                            },
                          });
                          handleClose();
                        },
                        isNameAvailable: phaseName =>
                          phases.every(phase => phase.name !== phaseName),
                      }}
                    >
                      <IconButton color={"primary"}>
                        <AddIcon />
                      </IconButton>
                    </ModalOpenButton>
                  </Box>
                )}
              </React.Fragment>
            ))}
            {extraItems?.map(item => (
              <Box
                flexBasis={200}
                flexShrink={0}
                flexGrow={1}
                display="flex"
                alignItems="center"
              >
                {item}
              </Box>
            ))}
          </Stack>
        </Stack>
      ) : (
        <Box paddingX={2}>
          <Button
            size="small"
            color="primary"
            variant="text"
            startIcon={<ViewColumnIcon />}
            onClick={() => {
              setIsConfiguring(true);
            }}
          >
            {t("Configure board", { ns: "Opportunities" })}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

function PhaseConfigCard({
  phase,
  handleChange,
  isUsed,
  isOnlyPhase,
  handleDelete,
}: {
  phase: { id: string; name: string };
  handleChange: (phaseId: string, newName: string) => Promise<void>;
  isUsed: boolean;
  isOnlyPhase: boolean;
  handleDelete: () => Promise<void>;
}) {
  const { t } = useTranslate(["Projects"]);

  const [inputValue, setInputValue] = React.useState(phase.name);

  return (
    <CardItem padding={1}>
      <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
        <TextField
          label={t("Phase name", { ns: "Projects" })}
          value={inputValue}
          onChange={event => {
            setInputValue(event.target.value);
          }}
          error={inputValue.length < 1}
        />
        {inputValue.trim() !== phase.name ? (
          <>
            <IconButton
              color="success"
              size="small"
              onClick={() => handleChange(phase.id, inputValue.trim())}
            >
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              color="error"
              size="small"
              onClick={() => setInputValue(phase.name)}
            >
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <Tooltip
            title={
              isOnlyPhase
                ? t("At least one phase is needed", { ns: "Projects" })
                : isUsed
                  ? t(
                      "Move all opportunities out of the column in order to delete it", // FIXME: this use used also for projects
                      { ns: "Projects" }
                    )
                  : ""
            }
          >
            <span>
              <IconButton
                color="secondary"
                disabled={isUsed || isOnlyPhase}
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>
    </CardItem>
  );
}
