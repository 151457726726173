import { gql } from "@apollo/client";
import {
  CardContainer,
  isImageOr3dModel,
  processAttachment,
  RichTextValue,
  TitleWithPathForPdf,
} from "@msys/ui";
import { Stack } from "@mui/material";
import { isUndefined } from "lodash";
import React from "react";
import { PictureGallery } from "../../../commons/images/PictureGallery";
import { PropertiesValue } from "../../../commons/PropertiesValue";
import { useQuoteItemProperties } from "../../item-properties/itemProperties";
import { PropertyDisplayBox_ItemFragment } from "./PropertyDisplayBox.generated";
import { PropertyGalleryBox } from "./PropertyGalleryBox";

interface Props {
  item: PropertyDisplayBox_ItemFragment;
  layout?: "vertical" | "horizontal";
  columns?: number;
  showProperties?: boolean;
  ActionButton?: React.ReactNode;
}

export const PropertyDisplayBox = ({
  item,
  showProperties = true,
  columns = 2,
  layout = "vertical",
  ActionButton,
}: Props) => {
  const [selfProperties, productProperties] = useQuoteItemProperties(item);

  const properties = [...selfProperties, ...productProperties];

  const title = !isUndefined(item.pendingChangeAttributes["title"])
    ? item.pendingChangeAttributes["title"]
    : item.title;

  const pathForPdf = !isUndefined(item.pendingChangeAttributes["pathForPdf"])
    ? item.pendingChangeAttributes["pathForPdf"]
    : item.pathForPdf;

  const description = !isUndefined(item.pendingChangeAttributes["description"])
    ? item.pendingChangeAttributes["description"]
    : item.description;

  const pictures = item.attachments
    .map(processAttachment)
    .filter(isImageOr3dModel);

  const hasContent = !!(
    pictures.length > 0 ||
    description ||
    (showProperties && properties.length > 0)
  );

  return (
    <CardContainer
      isExpandable={hasContent}
      isInitiallyClosed={!hasContent}
      title={<TitleWithPathForPdf title={title} pathForPdf={pathForPdf} />}
      ActionButton={ActionButton}
    >
      {hasContent &&
        (layout === "vertical" ? (
          <Stack direction="column" p={1} spacing={1}>
            <PictureGallery
              pictures={pictures}
              showDelete={false}
              showAdd={false}
              showRotate={false}
              showUpload={false}
            />
            {description && <RichTextValue htmlContent={description} />}
            {showProperties && properties.length > 0 && (
              <PropertiesValue properties={properties} columns={columns} />
            )}
          </Stack>
        ) : layout === "horizontal" ? (
          <Stack
            direction={"row"}
            alignItems="flex-start"
            width="100%"
            p={1}
            spacing={1}
            minWidth={0}
          >
            <PropertyGalleryBox
              pictures={pictures}
              flexGrow={0}
              flexShrink={0}
              marginTop="1px"
              width="80px"
              height="60px"
              imageHeight={60}
              aspectRatio="80 / 60"
            />
            <Stack direction="column" spacing={1}>
              {description && <RichTextValue htmlContent={description} />}
              {showProperties && properties.length > 0 && (
                <PropertiesValue properties={properties} columns={columns} />
              )}
            </Stack>
          </Stack>
        ) : null)}
    </CardContainer>
  );
};
