import { Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import {
  FormValues,
  ProjectAssigneeField,
  useProjectAssigneeSelect,
} from "./ProjectAssigneeSelect";

interface Props {
  projectId: string;
  projectTitle?: string;
  refetchQueries?: string[];
  handleClose: () => void;
}

export const ProjectAssigneeEditModal = ({
  handleClose,
  projectId,
  projectTitle,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["Global", "Projects", "ProjectMembers"]);
  const formId = React.useMemo(() => uniqueId(), []);
  const { initialValues, validationSchema, onSubmit, required } =
    useProjectAssigneeSelect({ projectId, refetchQueries });
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={async values => {
        await onSubmit(values);
        handleClose();
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {formikProps => (
        <Modal
          title={
            projectTitle
              ? t("Change assignee for “{projectTitle}”", {
                  ns: "Projects",
                  projectTitle,
                })
              : t("Change assignee", { ns: "Projects" })
          }
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
                disabled: !formikProps.isValid || !formikProps.dirty,
              },
            },
          ]}
          handleClose={handleClose}
          maxWidth="sm"
        >
          <Form id={formId}>
            <ProjectAssigneeField
              disabled={formikProps.isSubmitting}
              required={required}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
