import {
  Duration,
  FormattedDate,
  ModalOpenButton,
  useScreenWidth,
} from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { PermissionName } from "../../../../clients/graphqlTypes";
import { QuoteOfferConditionsModal } from "../modals/QuoteOfferConditionsModal";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission";

interface QuotePreviewDatesProps {
  quote: { viewerPermissions: PermissionName[] };
  projectId: string;
  quoteId: string;
  earliestDate: Date | undefined;
  acceptedAt: Date | undefined;
  duration: number | undefined | null;
  expirationDate: Date | undefined;
  isPublished: boolean;
  canEdit?: boolean;
}

export const QuotePreviewDates = ({
  quote,
  projectId,
  quoteId,
  earliestDate,
  acceptedAt,
  duration,
  expirationDate,
  isPublished,
  canEdit = false,
}: QuotePreviewDatesProps) => {
  const theme = useTheme();
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["Quote", "Global"]);
  const checkMoment = React.useMemo(
    () => (acceptedAt ? moment(acceptedAt) : moment()),
    [acceptedAt]
  );
  return (
    <Paper>
      <Stack
        justifyContent="space-between"
        flexWrap="wrap"
        direction={!isMinTablet ? "column" : "row"}
        spacing={0}
      >
        <Stack
          direction="row"
          flex={1}
          justifyContent="space-between"
          padding={2}
          spacing={1}
        >
          {!isPublished || earliestDate ? (
            <>
              <div>
                {t("Earliest work start", {
                  ns: "Quote",
                })}
              </div>
              <div>
                <FormattedDate
                  date={earliestDate}
                  notSetText={t("Not set", {
                    ns: "Global",
                  })}
                />
              </div>
            </>
          ) : null}
        </Stack>
        <Divider
          orientation={!isMinTablet ? "horizontal" : "vertical"}
          flexItem={isMinTablet}
        />
        <Stack
          direction="row"
          flex={1}
          justifyContent="space-between"
          padding={2}
          spacing={1}
        >
          {!isPublished || duration ? (
            <>
              <div>
                {t("Work duration", {
                  ns: "Quote",
                })}
              </div>
              <div>
                <Duration
                  duration={duration}
                  notSetText={t("Not set", {
                    ns: "Global",
                  })}
                />
              </div>
            </>
          ) : null}
        </Stack>
        <Divider
          orientation={!isMinTablet ? "horizontal" : "vertical"}
          flexItem={isMinTablet}
        />
        <Stack direction="row" flex={1} justifyContent="space-between">
          <Stack
            direction="row"
            flex={1}
            justifyContent="space-between"
            spacing={1}
            padding={2}
            style={
              expirationDate &&
              moment(expirationDate).endOf("day").isBefore(checkMoment)
                ? { color: theme.palette.error.main }
                : undefined
            }
          >
            <div>
              {t("Valid until", {
                ns: "Quote",
              })}
            </div>
            <div>
              <FormattedDate
                date={expirationDate}
                notSetText={t("Not set", {
                  ns: "Global",
                })}
              />
            </div>
          </Stack>
          {canEdit && (
            <RestrictedByDocumentPermissionWithDebug
              permission="MANAGE_QUOTES_SETTINGS"
              document={quote}
            >
              <Box flex={0} alignSelf="center" paddingRight={2}>
                <ModalOpenButton
                  Modal={QuoteOfferConditionsModal}
                  modalProps={{
                    projectId,
                    quoteId,
                  }}
                >
                  <IconButton color="secondary" size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </ModalOpenButton>
              </Box>
            </RestrictedByDocumentPermissionWithDebug>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
