import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import React from "react";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { useCloseForBiddingRequestMutation } from "../Requests.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  requestId: string;
  projectId: string;
  needsConfirm?: boolean;
  refetchQueries?: string[];
}

export const RequestCloseForBiddingButton = ({
  requestId,
  projectId,
  needsConfirm,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["Requests", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [closeRequestForBidding, { loading }] =
    useCloseForBiddingRequestMutation({
      client,
      refetchQueries,
      awaitRefetchQueries: true,
    });

  const handleCloseRequest = async () => {
    try {
      await closeRequestForBidding({
        variables: {
          input: { projectId, requestId },
        },
      });
      enqueueSnackbar(
        t("Request closed for bidding", {
          ns: "Requests",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  if (needsConfirm) {
    return (
      <ModalOpenButton
        Modal={ConfirmModal}
        modalProps={{
          title: t("Close request for bidding", {
            ns: "Requests",
          }),
          text: t(
            "Bidders will no longer be able to submit quotes for this request. Are you sure you want to close this request?",
            {
              ns: "Requests",
            }
          ),
          confirmButtonLabel: t("Yes, close", {
            ns: "Requests",
          }),
          handleConfirm: handleCloseRequest,
          cancelButtonLabel: t("No", {
            ns: "Global",
          }),
        }}
      >
        <LoadingButton
          startIcon={<LockIcon />}
          variant="outlined"
          color="primary"
          loading={loading}
        >
          {t("Close for bidding", {
            ns: "Requests",
          })}
        </LoadingButton>
      </ModalOpenButton>
    );
  }

  return (
    <LoadingButton
      startIcon={<LockIcon />}
      variant="outlined"
      color="primary"
      loading={loading}
      onClick={handleCloseRequest}
    >
      {t("Close for bidding", {
        ns: "Requests",
      })}
    </LoadingButton>
  );
};
