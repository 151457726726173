/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportAsXiopdMutationVariables = Types.Exact<{
  input: Types.ExportAsXiopdInput;
}>;


export type ExportAsXiopdMutation = { exportAsXiopd: { __typename: 'ExportAsXiopdResult', downloadUrl: string, filename: string } };


export const ExportAsXiopdDocument = gql`
    mutation ExportAsXiopd($input: ExportAsXiopdInput!) {
  exportAsXiopd(input: $input) {
    downloadUrl
    filename
  }
}
    `;
export type ExportAsXiopdMutationFn = Apollo.MutationFunction<ExportAsXiopdMutation, ExportAsXiopdMutationVariables>;

/**
 * __useExportAsXiopdMutation__
 *
 * To run a mutation, you first call `useExportAsXiopdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAsXiopdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAsXiopdMutation, { data, loading, error }] = useExportAsXiopdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportAsXiopdMutation(baseOptions?: Apollo.MutationHookOptions<ExportAsXiopdMutation, ExportAsXiopdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportAsXiopdMutation, ExportAsXiopdMutationVariables>(ExportAsXiopdDocument, options);
      }
export type ExportAsXiopdMutationHookResult = ReturnType<typeof useExportAsXiopdMutation>;
export type ExportAsXiopdMutationResult = Apollo.MutationResult<ExportAsXiopdMutation>;
export type ExportAsXiopdMutationOptions = Apollo.BaseMutationOptions<ExportAsXiopdMutation, ExportAsXiopdMutationVariables>;