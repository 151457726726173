import { ellipsisStyle, FormattedPrice, useFormatting } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { OrganisationProjectPhase } from "../../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import {
  ActionLine,
  ClientLine,
  DeadlineDateLine,
  EarliestPlanSessionsDateLine,
  EarliestStartDateLine,
  LocationLine,
  MasterLine,
} from "../../../commons/DataItem";
import { getAddressLabel } from "../../addresses/helpers";
import { getPersonLabel } from "../../users/helpers";
import { ProjectStatusBadge } from "../badges/ProjectStatusBadge";
import { ProjectAssigneeEditButton } from "../ProjectAssigneeEditButton";
import { ProjectPhaseChip } from "../ProjectPhaseChip";
import { TicketUrgentBadge } from "../TicketStatusBadge";
import { ProjectListItemFragment } from "./ProjectListItem.generated";

export interface ProjectListItemDisplayConfig {
  location: boolean;
  client: boolean;
  assignees: boolean;
  earliestPlanSessionDate: boolean;
  earliestStartDate: boolean;
  deadlineDate: boolean;
  budget: boolean;
  leadId?: boolean;
}

interface Props {
  project: ProjectListItemFragment;
  Action?: React.ReactElement;
  showAssignee?: boolean;
  showStatus?: boolean;
  showPhase?: boolean;
  showContractee?: boolean;
  availablePhases?: OrganisationProjectPhase[];
  displayConfig?: ProjectListItemDisplayConfig;
}

export const ProjectListItem = ({
  project,
  Action,
  showAssignee = false,
  showStatus = true,
  showPhase = false,
  showContractee = true,
  availablePhases = [],
  displayConfig,
}: Props) => {
  const { t } = useTranslate(["DataItem", "Projects"]);

  const { getFormattedDate } = useFormatting();

  const assignees =
    project.assignees && project.assignees?.length
      ? getPersonLabel(project.assignees[0]!, true) +
        (project.assignees.length > 1
          ? ` +${project.assignees.length - 1}`
          : "")
      : undefined;
  const location = getAddressLabel(
    project?.buildingInfo?.buildingAddress,
    ", "
  );

  const leadId = project?.leadId;
  const client = showContractee
    ? [
        project.crmOrganisation?.title,
        project.state === "opportunity" &&
        project.crmOrganisation?.contactPerson
          ? getPersonLabel(project.crmOrganisation?.contactPerson, true) || null
          : null,
      ]
        .filter(Boolean)
        .join(", ")
    : null;

  const deadlineDate = project.deadline
    ? getFormattedDate(project.deadline)
    : undefined;
  const earliestPlanSessionDate = project.assigned
    ? getFormattedDate(project.earliestPlanSessionDate!)
    : undefined;
  const earliestStartDate = project.earliestStart
    ? getFormattedDate(project.earliestStart)
    : undefined;

  const AssigneeButton = showAssignee ? (
    <RestrictedByProjectPermissionWithDebug
      permission="MANAGE_PROJECT"
      project={project}
      otherwise={
        <ProjectAssigneeEditButton
          assignee={project.assignee ?? null}
          projectId={project.id}
          projectTitle={project.title}
          readOnly={true}
          type="icon"
        />
      }
    >
      <ProjectAssigneeEditButton
        assignee={project.assignee ?? null}
        projectId={project.id}
        projectTitle={project.title}
        type="icon"
      />
    </RestrictedByProjectPermissionWithDebug>
  ) : null;

  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography
          variant={"h4"}
          sx={{ overflowWrap: "break-word", minWidth: "40px" }}
        >
          <span>{project.title}</span>
          {project.urgent && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <TicketUrgentBadge small />
            </span>
          )}
          {showStatus && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <ProjectStatusBadge projectState={project.state} small />
            </span>
          )}
          {showPhase && (
            <span
              style={{
                display: "inline-flex",
                marginLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              <ProjectPhaseChip
                projectId={project.id}
                currentState={project.state}
                currentPhase={project.phase}
                availablePhases={availablePhases}
              />
            </span>
          )}
        </Typography>
        {Action || AssigneeButton ? (
          <ActionLine>
            {AssigneeButton}
            {Action}
          </ActionLine>
        ) : null}
      </Stack>

      {location ||
      leadId ||
      client ||
      assignees ||
      earliestPlanSessionDate ||
      earliestStartDate ||
      deadlineDate ||
      project.budget ? (
        <Stack
          direction="row"
          spacing={1}
          minWidth={0}
          justifyContent="space-between"
          style={ellipsisStyle}
        >
          {location ||
          leadId ||
          client ||
          assignees ||
          earliestPlanSessionDate ||
          earliestStartDate ||
          deadlineDate ? (
            <Box
              gap={0.5}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              style={ellipsisStyle}
            >
              {(displayConfig?.location ?? true) && location && (
                <Tooltip
                  title={t("Location", { ns: "DataItem" })}
                  arrow
                  placement="bottom"
                >
                  <div style={ellipsisStyle}>
                    <LocationLine>{location}</LocationLine>
                  </div>
                </Tooltip>
              )}
              {(displayConfig?.leadId ?? true) && leadId && (
                <Tooltip
                  title={t("Lead ID", { ns: "Projects" })}
                  arrow
                  placement="bottom"
                >
                  <div style={ellipsisStyle}>
                    <Typography variant="caption" color="secondary">
                      {leadId}
                    </Typography>
                  </div>
                </Tooltip>
              )}
              {(displayConfig?.client ?? true) && client && (
                <Tooltip
                  title={t("Client", { ns: "DataItem" })}
                  arrow
                  placement="bottom"
                >
                  <div style={ellipsisStyle}>
                    <ClientLine>{client}</ClientLine>
                  </div>
                </Tooltip>
              )}
              {(displayConfig?.assignees ?? true) && assignees && (
                <Tooltip
                  title={t("Assigned workers", { ns: "DataItem" })}
                  arrow
                  placement="bottom"
                >
                  <div style={ellipsisStyle}>
                    <MasterLine>{assignees}</MasterLine>
                  </div>
                </Tooltip>
              )}
              {(displayConfig?.earliestPlanSessionDate ?? true) &&
                earliestPlanSessionDate && (
                  <Tooltip
                    title={t("Earliest planned work session", {
                      ns: "DataItem",
                    })}
                    arrow
                    placement="bottom"
                  >
                    <div>
                      <EarliestPlanSessionsDateLine>
                        {earliestPlanSessionDate}
                      </EarliestPlanSessionsDateLine>
                    </div>
                  </Tooltip>
                )}
              {(displayConfig?.earliestStartDate ?? true) &&
                earliestStartDate && (
                  <Tooltip
                    title={t("Earliest start", { ns: "DataItem" })}
                    arrow
                    placement="bottom"
                  >
                    <div>
                      <EarliestStartDateLine>
                        {earliestStartDate}
                      </EarliestStartDateLine>
                    </div>
                  </Tooltip>
                )}
              {(displayConfig?.deadlineDate ?? true) && deadlineDate && (
                <Tooltip
                  title={t("Deadline", { ns: "DataItem" })}
                  arrow
                  placement="bottom"
                >
                  <div>
                    <DeadlineDateLine overdue={project.overdue}>
                      {deadlineDate}
                    </DeadlineDateLine>
                  </div>
                </Tooltip>
              )}
            </Box>
          ) : null}

          {(displayConfig?.location ?? true) && project.budget ? (
            <RestrictedByProjectPermissionWithDebug
              permission="READ_QUOTES"
              project={project}
            >
              <Typography
                variant={"body2"}
                style={{
                  float: "right",
                  alignItems: "flex-end",
                  display: "flex",
                  shapeOutside: "inset(calc(100% - 20px) 0 0)",
                  marginBottom: "-4px",
                }}
                component="div"
              >
                <FormattedPrice value={project.budget} />
              </Typography>
            </RestrictedByProjectPermissionWithDebug>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
};
