import { Capacitor } from "@capacitor/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import * as React from "react";
import { DEPLOY_ENV } from "../../environment";

export const OutdatedVersionDialog = () => {
  const { t } = useTranslate("OutdatedDialog");
  const { outdatedVersionModalOpen, setOutdatedVersionModalOpen } =
    useOutdatedVersionCheck();

  return (
    <Dialog
      open={outdatedVersionModalOpen}
      onClose={() => setOutdatedVersionModalOpen(false)}
    >
      <DialogTitle>{t("We deployed a new application version")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Please reload (CMD + R) your browser window if you experience any problems"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOutdatedVersionModalOpen(false)}
          variant="outlined"
        >
          {t("Not now")}
        </Button>
        <Button
          onClick={() => {
            setOutdatedVersionModalOpen(false);
            window.location.reload();
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          {t("Reload now")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function useOutdatedVersionCheck() {
  const [outdatedVersionModalOpen, setOutdatedVersionModalOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (DEPLOY_ENV === "local" || Capacitor.isNativePlatform()) return;

    let lastVersion = "";

    const handler = async () => {
      try {
        const data = await (await fetch("/VERSION.json")).json();

        const { version: latestVersion } = data;

        if (!lastVersion) {
          lastVersion = latestVersion;
          return;
        }

        if (lastVersion && lastVersion !== latestVersion) {
          lastVersion = latestVersion;
          setOutdatedVersionModalOpen(true);
        }
      } catch (err) {
        console.info(err);
      }
    };

    window.addEventListener("focus", handler);

    return () => {
      window.removeEventListener("focus", handler);
    };
  }, []);

  return { outdatedVersionModalOpen, setOutdatedVersionModalOpen };
}
