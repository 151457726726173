/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectOpportunityAlertFragment = { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, owningSystemOrganisationId: string, ticket: boolean, closedAt?: any | null, state: Types.ProjectStateMachineStatus, incomingQuoteRequests: Array<{ __typename: 'Request', id: string, status: Types.RequestStatus, viewerRecipientStatus: Types.RequestRecipientStatus, viewerRecipientNumPublishedQuotes: number }> };

export const ProjectOpportunityAlertFragmentDoc = gql`
    fragment ProjectOpportunityAlert on Project {
  id
  incomingQuoteRequests {
    id
    status
    viewerRecipientStatus
    viewerRecipientNumPublishedQuotes
  }
  viewerPermissions
  owningSystemOrganisationId
  ticket
  closedAt
  state
}
    `;