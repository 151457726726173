/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type CrmCompanyCommentsBox_ChannelFragment = { __typename: 'Channel', id: string, type: string };

export const CrmCompanyCommentsBox_ChannelFragmentDoc = gql`
    fragment CrmCompanyCommentsBox_Channel on Channel {
  id
  type
}
    `;