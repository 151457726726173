import { useLocalStorageAsState } from "@msys/ui";
import { useMediaQuery, useTheme } from "@mui/material";

export type CollectionView = "table" | "list" | "gallery" | "swipe" | "kanban";

export const useCollectionView = (
  key: string,
  defaultView: CollectionView
): [CollectionView, (newValue: CollectionView | undefined) => void] => {
  const [activeView, setActiveView] = useLocalStorageAsState<CollectionView>(
    key,
    defaultView
  );
  return [activeView, setActiveView];
};

export const useCollectionViewWithMobile = (
  key: string,
  defaultViewDesktop: CollectionView,
  defaultViewMobile: CollectionView
): [CollectionView, (newValue: CollectionView | undefined) => void] => {
  const theme = useTheme();
  const isMinSm = useMediaQuery(
    theme.breakpoints.up(theme.breakpoints.values.sm),
    { noSsr: true }
  );
  const [activeView, setActiveView] = useLocalStorageAsState<CollectionView>(
    `msys-${key}-${isMinSm ? "desktop" : "mobile"}-listViewType`,
    isMinSm ? defaultViewDesktop : defaultViewMobile
  );
  return [activeView, setActiveView];
};
