import { QuantityUnit } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

const units = new Set<QuantityUnit>([
  "mm",
  "cm",
  "m",
  "m2",
  "m3",
  "l",
  "gr",
  "kg",
  "t",
  "piece",
  "lump_sum",
  "hr",
  "pair",
  "cm3",
  "cm2",
  "doz",
  "hl",
  "km",
  "set",
]);

export function useQuantityUnits() {
  const { t } = useTranslate("Units");

  const quantityUnitLabels: Record<QuantityUnit, string> = {
    cm: t("cm"),
    mm: t("mm"),
    m: t("m"),
    m2: t("m2"),
    m3: t("m3"),
    gr: t("gr"),
    kg: t("kg"),
    l: t("l"),
    lump_sum: t("lumpsum"),
    piece: t("piece"),
    t: t("t"),
    hr: t("hr"),
    pair: t("pair"),
    cm3: t("cm3"),
    cm2: t("cm2"),
    doz: t("doz"),
    hl: t("hl"),
    km: t("km"),
    set: t("set"),
  };

  const quantityUnitOptions = [...units].map(unit => ({
    label: quantityUnitLabels[unit],
    value: unit,
  }));

  return { quantityUnitLabels, quantityUnitOptions };
}
