import { gql, useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { ButtonCreate } from "../../../../commons/button/Button";
import { QUOTE_TEMPLATE_ITEM_TYPES } from "../../../../constants";
import { buildDocPath } from "../../../../utils";
import {
  CreateQuoteTemplateModal,
  FormValues,
} from "../../CreateQuoteTemplateModal";
import {
  useCreateQuoteTemplateButton_SetItemProductMutation,
  useCreateQuoteTemplateMutation,
} from "./CreateTemplateQuoteButton.generated";

interface Props {
  pathToDocList: string;
}

export const CreateQuoteTemplateButton = ({ pathToDocList }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate("Templates");

  const client = useApolloClient();

  const [createQuoteTemplate] = useCreateQuoteTemplateMutation({ client });
  const [setItemProduct] = useCreateQuoteTemplateButton_SetItemProductMutation({
    client,
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await createQuoteTemplate({
        variables: {
          input: {
            title: values.title,
            description: values.description,
            discountPercentage: 0,
            applyTemplate: true,
            rootItemType: values.rootItemType,
          },
        },
      });
      const template = response.data?.createQuoteTemplate.quoteTemplate;
      const docId = template?.id;

      if (!docId) throw new Error("Document id missing");

      const rootItemId = template?.rootItem.id;
      const rootItemType = template?.rootItem.type;

      if (!rootItemId || !rootItemType) throw new Error("Root item id missing");

      if (rootItemType === "paid" && values.product) {
        await setItemProduct({
          variables: {
            input: {
              projectId: null,
              docId,
              itemId: rootItemId,
              productArticleNumber: values.product.articleNumber,
              productSupplierId: values.product.supplierId,
              expandedItemIds: [],
            },
          },
        });
      }

      navigate(
        buildDocPath(
          `${pathToDocList}/${docId}`,
          template.resolvedAsReadModelVersionNumber ?? null
        )
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <ModalOpenButton
      Modal={CreateQuoteTemplateModal}
      modalProps={{
        documentItemTypes: QUOTE_TEMPLATE_ITEM_TYPES,
        handleComplete: handleSubmit,
        showProductSelect: true,
      }}
    >
      <ButtonCreate
        type="button"
        color="primary"
        variant="contained"
        title={t("New Template")}
      />
    </ModalOpenButton>
  );
};
