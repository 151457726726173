/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../features/attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ArticleListQuery = { quoteProductsDistribution: { __typename: 'QuoteProductsDistributionResult', quoteProductsDistribution: { __typename: 'QuoteProductsDistribution', id: string, clientEmail?: any | null } } };

export type ArticleListCrmCompanyQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type ArticleListCrmCompanyQuery = { crmCompanies: { __typename: 'CrmCompanyConnection', totalCount: number, edges: Array<{ __typename: 'CrmCompanyEdge', node: { __typename: 'CrmCompaniesRecord', id: string, title: string, email: any, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } }> } | { __typename: 'MissingPermissions' } };

export type ApplyArticleListMutationVariables = Types.Exact<{
  input: Types.CreateProjectForQuoteProductsDistributionInput;
}>;


export type ApplyArticleListMutation = { createProjectForQuoteProductsDistribution: { __typename: 'CreateProjectForQuoteProductsDistributionResult', project: { __typename: 'Project', id: string }, quote: { __typename: 'Quote', id: string } } };

export type ArticleList_ModifyCrmCompanyEmailMutationVariables = Types.Exact<{
  crmCompanyId: Types.Scalars['ID']['input'];
  email: Types.Scalars['EmailAddress']['input'];
}>;


export type ArticleList_ModifyCrmCompanyEmailMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, email: any }> } };


export const ArticleListDocument = gql`
    query ArticleList($id: ID!) {
  quoteProductsDistribution(quoteProductsDistributionId: $id) {
    quoteProductsDistribution {
      id
      clientEmail
    }
  }
}
    `;

/**
 * __useArticleListQuery__
 *
 * To run a query within a React component, call `useArticleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleListQuery(baseOptions: Apollo.QueryHookOptions<ArticleListQuery, ArticleListQueryVariables> & ({ variables: ArticleListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleListQuery, ArticleListQueryVariables>(ArticleListDocument, options);
      }
export function useArticleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleListQuery, ArticleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleListQuery, ArticleListQueryVariables>(ArticleListDocument, options);
        }
export function useArticleListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArticleListQuery, ArticleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleListQuery, ArticleListQueryVariables>(ArticleListDocument, options);
        }
export type ArticleListQueryHookResult = ReturnType<typeof useArticleListQuery>;
export type ArticleListLazyQueryHookResult = ReturnType<typeof useArticleListLazyQuery>;
export type ArticleListSuspenseQueryHookResult = ReturnType<typeof useArticleListSuspenseQuery>;
export type ArticleListQueryResult = Apollo.QueryResult<ArticleListQuery, ArticleListQueryVariables>;
export const ArticleListCrmCompanyDocument = gql`
    query ArticleListCrmCompany($email: String!) {
  crmCompanies(
    limit: 1
    sorting: [{column: createdAt, direction: desc}]
    search: $email
  ) {
    ... on CrmCompanyConnection {
      edges {
        node {
          id
          title
          email
          logo {
            ...AttachmentSnapshot
          }
        }
      }
      totalCount
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;

/**
 * __useArticleListCrmCompanyQuery__
 *
 * To run a query within a React component, call `useArticleListCrmCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleListCrmCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleListCrmCompanyQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useArticleListCrmCompanyQuery(baseOptions: Apollo.QueryHookOptions<ArticleListCrmCompanyQuery, ArticleListCrmCompanyQueryVariables> & ({ variables: ArticleListCrmCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleListCrmCompanyQuery, ArticleListCrmCompanyQueryVariables>(ArticleListCrmCompanyDocument, options);
      }
export function useArticleListCrmCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleListCrmCompanyQuery, ArticleListCrmCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleListCrmCompanyQuery, ArticleListCrmCompanyQueryVariables>(ArticleListCrmCompanyDocument, options);
        }
export function useArticleListCrmCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArticleListCrmCompanyQuery, ArticleListCrmCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleListCrmCompanyQuery, ArticleListCrmCompanyQueryVariables>(ArticleListCrmCompanyDocument, options);
        }
export type ArticleListCrmCompanyQueryHookResult = ReturnType<typeof useArticleListCrmCompanyQuery>;
export type ArticleListCrmCompanyLazyQueryHookResult = ReturnType<typeof useArticleListCrmCompanyLazyQuery>;
export type ArticleListCrmCompanySuspenseQueryHookResult = ReturnType<typeof useArticleListCrmCompanySuspenseQuery>;
export type ArticleListCrmCompanyQueryResult = Apollo.QueryResult<ArticleListCrmCompanyQuery, ArticleListCrmCompanyQueryVariables>;
export const ApplyArticleListDocument = gql`
    mutation ApplyArticleList($input: CreateProjectForQuoteProductsDistributionInput!) {
  createProjectForQuoteProductsDistribution(input: $input) {
    project {
      id
    }
    quote {
      id
    }
  }
}
    `;
export type ApplyArticleListMutationFn = Apollo.MutationFunction<ApplyArticleListMutation, ApplyArticleListMutationVariables>;

/**
 * __useApplyArticleListMutation__
 *
 * To run a mutation, you first call `useApplyArticleListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyArticleListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyArticleListMutation, { data, loading, error }] = useApplyArticleListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyArticleListMutation(baseOptions?: Apollo.MutationHookOptions<ApplyArticleListMutation, ApplyArticleListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyArticleListMutation, ApplyArticleListMutationVariables>(ApplyArticleListDocument, options);
      }
export type ApplyArticleListMutationHookResult = ReturnType<typeof useApplyArticleListMutation>;
export type ApplyArticleListMutationResult = Apollo.MutationResult<ApplyArticleListMutation>;
export type ApplyArticleListMutationOptions = Apollo.BaseMutationOptions<ApplyArticleListMutation, ApplyArticleListMutationVariables>;
export const ArticleList_ModifyCrmCompanyEmailDocument = gql`
    mutation ArticleList_ModifyCrmCompanyEmail($crmCompanyId: ID!, $email: EmailAddress!) {
  modifyCrmOrganisation(input: [{id: $crmCompanyId, email: $email}]) {
    crmOrganisations {
      id
      email
    }
  }
}
    `;
export type ArticleList_ModifyCrmCompanyEmailMutationFn = Apollo.MutationFunction<ArticleList_ModifyCrmCompanyEmailMutation, ArticleList_ModifyCrmCompanyEmailMutationVariables>;

/**
 * __useArticleList_ModifyCrmCompanyEmailMutation__
 *
 * To run a mutation, you first call `useArticleList_ModifyCrmCompanyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleList_ModifyCrmCompanyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleListModifyCrmCompanyEmailMutation, { data, loading, error }] = useArticleList_ModifyCrmCompanyEmailMutation({
 *   variables: {
 *      crmCompanyId: // value for 'crmCompanyId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useArticleList_ModifyCrmCompanyEmailMutation(baseOptions?: Apollo.MutationHookOptions<ArticleList_ModifyCrmCompanyEmailMutation, ArticleList_ModifyCrmCompanyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleList_ModifyCrmCompanyEmailMutation, ArticleList_ModifyCrmCompanyEmailMutationVariables>(ArticleList_ModifyCrmCompanyEmailDocument, options);
      }
export type ArticleList_ModifyCrmCompanyEmailMutationHookResult = ReturnType<typeof useArticleList_ModifyCrmCompanyEmailMutation>;
export type ArticleList_ModifyCrmCompanyEmailMutationResult = Apollo.MutationResult<ArticleList_ModifyCrmCompanyEmailMutation>;
export type ArticleList_ModifyCrmCompanyEmailMutationOptions = Apollo.BaseMutationOptions<ArticleList_ModifyCrmCompanyEmailMutation, ArticleList_ModifyCrmCompanyEmailMutationVariables>;