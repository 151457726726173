import { useApolloClient } from "@apollo/client";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { Icon } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useImportFromJsonMutation } from "../../jsonImportExport/JsonImportExport.generated";
import { useTranslate } from "@tolgee/react";
import { DocUploader } from "../../jsonImportExport/ImportFromJsonUploader";

export const ImportTemplatesMenuItem = () => {
  const client = useApolloClient();
  const { t } = useTranslate("Templates");
  const [importFromJsonMutation] = useImportFromJsonMutation({
    client,
  });
  const { enqueueSnackbar } = useSnackbar();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

  return (
    <MenuItemWithIcon
      icon={
        <Icon>
          <FontAwesomeIcon icon={faFileImport} />
        </Icon>
      }
      onClick={async () => {
        fileInputRef.current!.click();
      }}
      disabled={status === "loading"}
    >
      <DocUploader
        innerRef={fileInputRef}
        onStart={() => setStatus("loading")}
        onError={e => {
          if (e instanceof Error)
            enqueueSnackbar(e.message, { variant: "error" });
          Sentry.captureException(e);
          setStatus("error");
        }}
        onUploadFile={async uploadFile => {
          try {
            await importFromJsonMutation({
              variables: {
                input: {
                  filename: uploadFile.filename,
                },
              },
            });
            await client.reFetchObservableQueries();
            enqueueSnackbar(t("Templates successfully imported"));
            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            Sentry.captureException(e);
            setStatus("error");
          }
        }}
      />
      {t("Import templates")}
    </MenuItemWithIcon>
  );
};
