/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProposedCalculations_ItemFragmentDoc } from '../hooks/useDecisionItemMutations.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteItemCreateItemsMutationVariables = Types.Exact<{
  input: Types.CreateItemsInput;
}>;


export type QuoteItemCreateItemsMutation = { createItems: { __typename: 'CreateItemsResult', doc: { __typename: 'Building', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } | { __typename: 'Quote', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } | { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } | { __typename: 'Requirement', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> }, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, parentId?: string | null }>, parentItem: { __typename: 'Item', id: string, originVersionNumber?: number | null, hasChildren: boolean, children: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> }, parentPathItems: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null }> } };

export type CreateItemsFromTemplatesMutationVariables = Types.Exact<{
  input: Types.CreateItemsFromTemplatesInput;
}>;


export type CreateItemsFromTemplatesMutation = { createItemsFromTemplates: { __typename: 'CreateItemsFromTemplatesResult', doc: { __typename: 'Building', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } | { __typename: 'Quote', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } | { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> } | { __typename: 'Requirement', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> }, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, parentId?: string | null }>, parentItem: { __typename: 'Item', id: string, originVersionNumber?: number | null, hasChildren: boolean, children: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null }> }, parentPathItems: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null }> } };


export const QuoteItemCreateItemsDocument = gql`
    mutation QuoteItemCreateItems($input: CreateItemsInput!) {
  createItems(input: $input) {
    doc {
      ... on WithDocumentInfo {
        id
      }
      ... on QuoteTemplate {
        resolvedAsReadModelVersionNumber
        items {
          id
          originVersionNumber
        }
      }
      ... on Quote {
        items {
          id
          originVersionNumber
        }
      }
      ... on Requirement {
        items {
          id
          originVersionNumber
        }
      }
      ... on Building {
        items {
          id
          originVersionNumber
        }
      }
    }
    items {
      id
      originVersionNumber
      parentId
    }
    parentItem {
      id
      originVersionNumber
      hasChildren
      children {
        id
        originVersionNumber
      }
    }
    parentPathItems {
      id
      originVersionNumber
      ...ProposedCalculations_Item
    }
  }
}
    ${ProposedCalculations_ItemFragmentDoc}`;
export type QuoteItemCreateItemsMutationFn = Apollo.MutationFunction<QuoteItemCreateItemsMutation, QuoteItemCreateItemsMutationVariables>;

/**
 * __useQuoteItemCreateItemsMutation__
 *
 * To run a mutation, you first call `useQuoteItemCreateItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteItemCreateItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteItemCreateItemsMutation, { data, loading, error }] = useQuoteItemCreateItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteItemCreateItemsMutation(baseOptions?: Apollo.MutationHookOptions<QuoteItemCreateItemsMutation, QuoteItemCreateItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteItemCreateItemsMutation, QuoteItemCreateItemsMutationVariables>(QuoteItemCreateItemsDocument, options);
      }
export type QuoteItemCreateItemsMutationHookResult = ReturnType<typeof useQuoteItemCreateItemsMutation>;
export type QuoteItemCreateItemsMutationResult = Apollo.MutationResult<QuoteItemCreateItemsMutation>;
export type QuoteItemCreateItemsMutationOptions = Apollo.BaseMutationOptions<QuoteItemCreateItemsMutation, QuoteItemCreateItemsMutationVariables>;
export const CreateItemsFromTemplatesDocument = gql`
    mutation CreateItemsFromTemplates($input: CreateItemsFromTemplatesInput!) {
  createItemsFromTemplates(input: $input) {
    doc {
      ... on WithDocumentInfo {
        id
      }
      ... on QuoteTemplate {
        resolvedAsReadModelVersionNumber
        items {
          id
          originVersionNumber
        }
      }
      ... on Quote {
        items {
          id
          originVersionNumber
        }
      }
      ... on Requirement {
        items {
          id
          originVersionNumber
        }
      }
      ... on Building {
        items {
          id
          originVersionNumber
        }
      }
    }
    items {
      id
      originVersionNumber
      parentId
    }
    parentItem {
      id
      originVersionNumber
      hasChildren
      children {
        id
        originVersionNumber
      }
    }
    parentPathItems {
      id
      originVersionNumber
      ...ProposedCalculations_Item
    }
  }
}
    ${ProposedCalculations_ItemFragmentDoc}`;
export type CreateItemsFromTemplatesMutationFn = Apollo.MutationFunction<CreateItemsFromTemplatesMutation, CreateItemsFromTemplatesMutationVariables>;

/**
 * __useCreateItemsFromTemplatesMutation__
 *
 * To run a mutation, you first call `useCreateItemsFromTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemsFromTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemsFromTemplatesMutation, { data, loading, error }] = useCreateItemsFromTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemsFromTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemsFromTemplatesMutation, CreateItemsFromTemplatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemsFromTemplatesMutation, CreateItemsFromTemplatesMutationVariables>(CreateItemsFromTemplatesDocument, options);
      }
export type CreateItemsFromTemplatesMutationHookResult = ReturnType<typeof useCreateItemsFromTemplatesMutation>;
export type CreateItemsFromTemplatesMutationResult = Apollo.MutationResult<CreateItemsFromTemplatesMutation>;
export type CreateItemsFromTemplatesMutationOptions = Apollo.BaseMutationOptions<CreateItemsFromTemplatesMutation, CreateItemsFromTemplatesMutationVariables>;