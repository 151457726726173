import { gql } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { CrmCompanyCustomFieldsBox_CrmCompanyFragment } from "./CrmCompanyCustomFieldsBox.generated";
import { useTranslate } from "@tolgee/react";
import { CustomFieldsBox } from "../../custom-fields/CustomFieldsBox";
import { EditCustomFieldModal } from "../../custom-fields/EditCustomFieldModal";

interface Props {
  crmCompany: CrmCompanyCustomFieldsBox_CrmCompanyFragment;
}

export const CrmCompanyCustomFieldsBox = ({ crmCompany }: Props) => {
  const { t } = useTranslate("OrganisationProfile");

  return (
    <CustomFieldsBox
      source={crmCompany}
      objectType={"Organisation"}
      actionButton={
        <ModalOpenButton
          Modal={EditCustomFieldModal}
          modalProps={{
            title: t("Edit organisation custom fields"),
            objectId: crmCompany.id,
            objectType: "Organisation",
            customFields: crmCompany.customFields,
            refetchQueries: [namedOperations.Query.CrmCompanyProfile],
          }}
        >
          <IconButton color="secondary" size="small">
            <EditIcon />
          </IconButton>
        </ModalOpenButton>
      }
    />
  );
};
