import { useScreenWidth } from "../hooks/useScreenWidth";
import { useFormatting } from "../formatting/useFormatting";
import { LabeledValue } from "../labeled-values/LabeledValue";
import { Paper, Stack, TypographyProps } from "@mui/material";
import React from "react";
import { LabeledValueHorizontal } from "../labeled-values/LabeledValueHorizontal";

interface Props {
  aggregates: {
    label: string;
    value: number | undefined;
    color: Exclude<TypographyProps["color"], "inherit">;
  }[];
}

export const TableAggregates = ({ aggregates }: Props) => {
  const { isMaxPhone } = useScreenWidth();
  const { getFormattedPrice } = useFormatting();

  const aggregatesToShow = aggregates.filter(a => a.value !== undefined);

  if (!aggregatesToShow.length) return null;

  return (
    <Paper>
      <Stack direction={"row"} paddingY={0.5} paddingX={1} spacing={2}>
        {aggregatesToShow.map(({ label, color, value }, index) =>
          !isMaxPhone ? (
            <LabeledValueHorizontal
              key={index}
              label={label}
              labelProps={{ color }}
            >
              {getFormattedPrice(value)}
            </LabeledValueHorizontal>
          ) : (
            <LabeledValue key={index} label={label} labelProps={{ color }}>
              {getFormattedPrice(value)}
            </LabeledValue>
          )
        )}
      </Stack>
    </Paper>
  );
};
