import { intersection } from "lodash";
import { DocType, ItemType } from "../../../clients/graphqlTypes";
import { ItemClipboard } from "./helpers";

// constrains where items inside doc types can be copied between each others
// const structuralDocTypesGroups: DocType[][] = [
//   ["QUOTE", "REQUIREMENT", "TEMPLATE"],
//   ["BUILDING", "BUILDING_TEMPLATE"],
// ];

// export const getDefaultDocTypeGroup = (
//   docItemTypename: DocItemTypename
// ): DocType[] => {
//   return (
//     structuralDocTypesGroups.find(group =>
//       group.includes(DocItemTypenameToDocTypeMapping[docItemTypename])
//     ) ?? []
//   );
// };

// type DocItemTypename =
//   | "RequirementItem"
//   | "QuoteItem"
//   | "QuoteTemplateItem"
//   | "BuildingItem"
//   | "UnnestedQuoteTemplateItem";

// TODO: temporary solution to migrate away from DocType
// const DocItemTypenameToDocTypeMapping: Record<DocItemTypename, DocType> = {
//   RequirementItem: "REQUIREMENT",
//   QuoteItem: "QUOTE",
//   QuoteTemplateItem: "TEMPLATE",
//   BuildingItem: "BUILDING",
//   UnnestedQuoteTemplateItem: "TEMPLATE",
// };

// constrains where items can be placed as children of parent items
export const structuralChildTypesConstraints: Record<ItemType, ItemType[]> = {
  section: ["section", "paid", "unpaid", "decision", "defect"],
  decision: ["section", "paid", "unpaid", "decision"],
  paid: ["unpaid", "defect"],
  unpaid: ["unpaid"],
  defect: ["unpaid"],
};

export const rootItemsAllowedConstraints: Record<DocType, ItemType[]> = {
  TEMPLATE: ["section", "paid", "unpaid", "decision"],
  REQUIREMENT: ["section", "decision"],
  BUILDING: ["section", "decision"],
  QUOTE: ["section", "decision"],
};

export const getDefaultCreatableSubItemTypes = (
  itemType: ItemType,
  documentItemTypes: ItemType[]
): ItemType[] => {
  return intersection(
    structuralChildTypesConstraints[itemType],
    documentItemTypes
  );
};

export const rootItemHasCalculationConstraints = [
  "section",
  "decision",
  "paid",
];
export const rootItemHasItemTableConstraints = ["section", "decision"];

// const DOC_ITEM_TYPES_ALLOWED_FOR_CONTINGENCY: DocItemTypename[] = [
//   "RequirementItem",
//   "QuoteItem",
//   "QuoteTemplateItem",
// ];

export const ITEM_TYPES_ALLOWED_FOR_CONTINGENCY: ItemType[] = [
  "section",
  "paid",
  "decision",
];

export function isAllowedToPaste({
  documentItemTypes,
  copiedItemType,
  parentItemType,
}: {
  documentItemTypes: ItemType[];
  copiedItemType: ItemType;
  parentItemType: ItemType;
}): boolean {
  return getDefaultCreatableSubItemTypes(
    parentItemType,
    documentItemTypes
  ).includes(copiedItemType);
}
