import { CardContainer } from "@msys/ui";
import { Stack } from "@mui/material";
import { RulesSection } from "../../doc-items/boxes/RulesSection";
import { isComputedProp } from "../../doc-items/properties";
import { PropertyMappingSection } from "./PropertyMappingSection";
import { TemplateQuoteLinkedTemplateItemHeaderBox_ItemFragment } from "./TemplateQuoteLinkedTemplateItemHeaderBox.generated";

interface Props {
  docId: string;
  item: TemplateQuoteLinkedTemplateItemHeaderBox_ItemFragment;
  isEditable: boolean;
}

export function TemplateQuoteLinkedTemplateItemHeaderBox({
  docId,
  item,
  isEditable,
}: Props) {
  const { linkedQuoteTemplate } = item;
  if (!linkedQuoteTemplate) return null;

  const mappableProperties = linkedQuoteTemplate.rootItem.props2.filter(
    prop => !isComputedProp(prop)
  );
  const mappings = item.propertyMappings;

  return (
    <CardContainer isExpandable>
      <Stack direction={"column"} spacing={1} padding={1}>
        <RulesSection
          projectId={null}
          docId={docId}
          item={item}
          readOnly={!isEditable}
        />
        <PropertyMappingSection
          docId={docId}
          itemId={item.id}
          properties={mappableProperties}
          mappings={mappings}
          isEditable={isEditable}
        />

        <button
          onClick={() => {
            const obj = {
              templateId: linkedQuoteTemplate.id,
              templateVersionNumber:
                linkedQuoteTemplate.resolvedAsReadModelVersionNumber ?? -1,
              bindProps: {},
              includeIfExpr: "false",
              eligibleIfExpr: "true",
            };

            prompt("copy and paste", JSON.stringify(obj));
          }}
        >
          show as recommend-template config
        </button>
      </Stack>
    </CardContainer>
  );
}
