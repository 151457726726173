import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash";
import React from "react";
import {
  ProductTypeSelect_ProductTypeFragment,
  useProductTypeSelectSearchProductTypesLazyQuery,
} from "./ProductTypeSelect.generated";

const LIMIT = 25;

interface Props {
  productTypeId: string | null;
  inputLabel?: string;
  onChange: (productType: ProductTypeSelect_ProductTypeFragment | null) => void;
  required?: boolean;
  disabled?: boolean;
}

export const ProductTypeSelect = ({
  productTypeId,
  inputLabel,
  required = false,
  disabled = false,
  onChange,
}: Props) => {
  const { t } = useTranslate(["Product", "Global"]);
  const [inputValue, setInputValue] = React.useState("");

  const { productTypes, refetch, totalCount, debouncedRefetch, loading } =
    useProductTypes(productTypeId, inputValue, setInputValue);

  return (
    <Autocomplete
      placeholder={t("Type to search", {
        ns: "Global",
      })}
      inputLabel={
        inputLabel ??
        t("Product type", {
          ns: "Product",
        })
      }
      inputValue={inputValue}
      options={[
        ...productTypes,
        ...(totalCount > productTypes.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      getOptionLabel={option =>
        "__typename" in option
          ? `${option.label} (${option.key})`
          : option.title
      }
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
          if (productTypeId) onChange(null);
        }
      }}
      value={
        productTypes.find(productType => productType.id === productTypeId) ??
        null
      }
      onChange={value => {
        if (value && "__typename" in value) {
          setInputValue(value.label);
          onChange(value);
        } else if (!value) {
          setInputValue("");
          onChange(null);
        }
      }}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      loading={loading}
      required={required}
      disabled={disabled}
    />
  );
};

function useProductTypes(
  productTypeId: string | null,
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();
  const [fetchQuery, query] = useProductTypeSelectSearchProductTypesLazyQuery({
    client,
    variables: {
      limit: LIMIT,
    },
    fetchPolicy: "network-only",
  });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          searchTerm: value,
        },
      });
    },
    [fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (productTypeId) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            productTypeIds: [productTypeId],
          },
        });
        const option = getDataOrNull(result.data?.pimSearchProductTypes)
          ?.productTypes[0];
        if (option && option.id === productTypeId)
          setInputValue(`${option.label} (${option.key})`);
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            searchTerm: inputValueRef.current,
          },
        });
      }
    })();
  }, [productTypeId, fetchQuery, setInputValue]);

  const productTypes =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProductTypes)
      ?.productTypes ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProductTypes)
      ?.totalCount ?? 0;

  return {
    productTypes,
    refetch,
    totalCount,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
  };
}
