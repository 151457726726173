/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiRephraseModal_ItemQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
}>;


export type AiRephraseModal_ItemQuery = { item: { __typename: 'Item', id: string, originVersionNumber?: number | null, product?: { __typename: 'ItemProduct', title: string, description: string } | null } | { __typename: 'MissingPermissions' } };

export type AiRephraseModal_AiQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  docId: Types.Scalars['ID']['input'];
  itemId: Types.Scalars['ID']['input'];
  locale: Types.Locale;
}>;


export type AiRephraseModal_AiQuery = { aiRephrase: { __typename: 'AiRephraseResult', title: string, description: string } };


export const AiRephraseModal_ItemDocument = gql`
    query AiRephraseModal_Item($projectId: ID, $docId: ID!, $itemId: ID!) {
  item(projectId: $projectId, docId: $docId, itemId: $itemId) {
    ... on Item {
      id
      originVersionNumber
      product {
        title
        description
      }
    }
  }
}
    `;

/**
 * __useAiRephraseModal_ItemQuery__
 *
 * To run a query within a React component, call `useAiRephraseModal_ItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiRephraseModal_ItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiRephraseModal_ItemQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useAiRephraseModal_ItemQuery(baseOptions: Apollo.QueryHookOptions<AiRephraseModal_ItemQuery, AiRephraseModal_ItemQueryVariables> & ({ variables: AiRephraseModal_ItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiRephraseModal_ItemQuery, AiRephraseModal_ItemQueryVariables>(AiRephraseModal_ItemDocument, options);
      }
export function useAiRephraseModal_ItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiRephraseModal_ItemQuery, AiRephraseModal_ItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiRephraseModal_ItemQuery, AiRephraseModal_ItemQueryVariables>(AiRephraseModal_ItemDocument, options);
        }
export function useAiRephraseModal_ItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AiRephraseModal_ItemQuery, AiRephraseModal_ItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AiRephraseModal_ItemQuery, AiRephraseModal_ItemQueryVariables>(AiRephraseModal_ItemDocument, options);
        }
export type AiRephraseModal_ItemQueryHookResult = ReturnType<typeof useAiRephraseModal_ItemQuery>;
export type AiRephraseModal_ItemLazyQueryHookResult = ReturnType<typeof useAiRephraseModal_ItemLazyQuery>;
export type AiRephraseModal_ItemSuspenseQueryHookResult = ReturnType<typeof useAiRephraseModal_ItemSuspenseQuery>;
export type AiRephraseModal_ItemQueryResult = Apollo.QueryResult<AiRephraseModal_ItemQuery, AiRephraseModal_ItemQueryVariables>;
export const AiRephraseModal_AiDocument = gql`
    query AiRephraseModal_AI($projectId: ID, $docId: ID!, $itemId: ID!, $locale: Locale!) {
  aiRephrase(
    projectId: $projectId
    docId: $docId
    itemId: $itemId
    locale: $locale
  ) {
    title
    description
  }
}
    `;

/**
 * __useAiRephraseModal_AiQuery__
 *
 * To run a query within a React component, call `useAiRephraseModal_AiQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiRephraseModal_AiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiRephraseModal_AiQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      docId: // value for 'docId'
 *      itemId: // value for 'itemId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAiRephraseModal_AiQuery(baseOptions: Apollo.QueryHookOptions<AiRephraseModal_AiQuery, AiRephraseModal_AiQueryVariables> & ({ variables: AiRephraseModal_AiQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiRephraseModal_AiQuery, AiRephraseModal_AiQueryVariables>(AiRephraseModal_AiDocument, options);
      }
export function useAiRephraseModal_AiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiRephraseModal_AiQuery, AiRephraseModal_AiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiRephraseModal_AiQuery, AiRephraseModal_AiQueryVariables>(AiRephraseModal_AiDocument, options);
        }
export function useAiRephraseModal_AiSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AiRephraseModal_AiQuery, AiRephraseModal_AiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AiRephraseModal_AiQuery, AiRephraseModal_AiQueryVariables>(AiRephraseModal_AiDocument, options);
        }
export type AiRephraseModal_AiQueryHookResult = ReturnType<typeof useAiRephraseModal_AiQuery>;
export type AiRephraseModal_AiLazyQueryHookResult = ReturnType<typeof useAiRephraseModal_AiLazyQuery>;
export type AiRephraseModal_AiSuspenseQueryHookResult = ReturnType<typeof useAiRephraseModal_AiSuspenseQuery>;
export type AiRephraseModal_AiQueryResult = Apollo.QueryResult<AiRephraseModal_AiQuery, AiRephraseModal_AiQueryVariables>;