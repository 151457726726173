import { useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { useUserData } from "../../auth/useUserData";
import { AutoSave } from "../../commons/form-fields/AutoSave";
import { SelectField } from "../../commons/form-fields/SelectField";
import { SelectMultipleField } from "../../commons/form-fields/SelectMultipleField";
import { Stack } from "../../commons/layout/Stack";
import {
  useSetOrganisationMemberDefaultProjectRolesMutation,
  useSetOrganisationMemberOrganisationRolesMutation,
} from "./UserRolesForm.generated";
import { useOrganisationRoles, useProjectRoles } from "./useRoles";

interface FormValues {
  roleIds: string[];
  defaultProjectRoleId?: string;
}

interface Props {
  user: {
    id: string;
    roles: { id: string }[];
    defaultProjectRoles?: { id: string }[];
  };
}

export const UserRolesForm = ({ user }: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate("UserProfile");
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;

  const [setOrganisationRoles] =
    useSetOrganisationMemberOrganisationRolesMutation({
      client,
    });

  const [setDefaultProjectRoles] =
    useSetOrganisationMemberDefaultProjectRolesMutation({
      client,
    });

  const initialValues: FormValues = useMemo(
    () => ({
      roleIds: user.roles.map(r => r.id),
      defaultProjectRoleId: user.defaultProjectRoles?.[0]?.id || "",
    }),
    [user]
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await setOrganisationRoles({
          variables: { input: { memberId: user.id, roleIds: values.roleIds } },
        });
        await setDefaultProjectRoles({
          variables: {
            input: {
              memberId: user.id,
              defaultProjectRoleIds: values.defaultProjectRoleId
                ? [values.defaultProjectRoleId]
                : [],
            },
          },
        });
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [enqueueSnackbar, setOrganisationRoles, setDefaultProjectRoles, user.id]
  );

  const { getOrganisationRoleOptions } = useOrganisationRoles();
  const { getProjectRoleOptions } = useProjectRoles();

  const roles = getOrganisationRoleOptions(viewer.organisation.roles);
  const projectRoles = getProjectRoleOptions(viewer.organisation.projectRoles);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        roleIds: Yup.array()
          .of(Yup.string())
          .label(t("Organisation role"))
          .required()
          .min(1, t("At least one organisation role is required")),
        defaultProjectRoleId: Yup.string()
          .label(t("Default project role"))
          .required(),
      }),
    [t]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      <Form>
        <Stack flexDirection="column">
          <SelectField
            label={t("Default project role")}
            name="defaultProjectRoleId"
            options={projectRoles}
            required
          />
          <SelectMultipleField
            label={t("Organisation role")}
            name="roleIds"
            options={roles}
            required
          />
          <AutoSave />
        </Stack>
      </Form>
    </Formik>
  );
};
