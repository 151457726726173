import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardContainer,
  DataGrid,
  FormattedFloatInput,
  getFormattedPercentage,
  GridColDef,
  MenuButton,
  ModalOpenButton,
  Select,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import {
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { debounce, head } from "lodash";
import React from "react";
import { usePaginationParams } from "../../../commons/pagination/usePaginationParams";
import { ViewMode, ViewModeMenuItem } from "../../../commons/ViewModeMenuItem";
import {
  MEDIUM_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../../constants";
import { useFeature } from "../../../../common/FeatureFlags";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { AddPimSupplierDiscountGroupsImportationButton } from "../../pimImportations/buttons/AddPimSupplierDiscountGroupsImportationButton";
import { SupplierDiscountGroupVariantCreateMenuButton } from "../../products/buttons/SupplierDiscountGroupVariantCreateMenuButton";
import { SupplierDiscountGroupVariantDeleteMenuButton } from "../../products/buttons/SupplierDiscountGroupVariantDeleteMenuButton";
import { SupplierDiscountGroupVariantCreateModal } from "../../products/modals/SupplierDiscountGroupVariantCreateModal";
import { useDiscountGroupTypes } from "../hooks/useDiscountGroupTypes";
import {
  CrmCompanyOwnInformation_CrmCompanyFragment,
  DiscountGroupFragment,
  useCrmCompanyOwnInformationDiscountGroupsQuery,
  useCrmCompanyOwnInformationDiscountGroupsVariantQuery,
  useCrmCompanyOwnInformationDiscountGroupVariantsQuery,
  useModifyCrmOrg_CrmCompanyOwnInformationMutation,
  usePimModifySupplierDiscountGroupMutation,
} from "./CrmCompanyOwnInformationAtCrmBox.generated";

export function CrmCompanyOwnInformation({
  crmCompany,
}: {
  crmCompany: CrmCompanyOwnInformation_CrmCompanyFragment;
}) {
  const { t } = useTranslate("CrmOrganisations");

  const isSupplierDiscountGroupVariantsEnabled = useFeature(
    "SupplierDiscountGroupVariants"
  );

  const client = useApolloClient();
  const [modifyCrmOrganisation] =
    useModifyCrmOrg_CrmCompanyOwnInformationMutation({
      client,
    });

  const handleOwnClientIdChange = React.useMemo(
    () =>
      debounce(value => {
        modifyCrmOrganisation({
          variables: {
            input: [
              {
                id: crmCompany.id,
                crmOwningOrganisationClientId: value,
              },
            ],
          },
        });
      }, 500),
    [modifyCrmOrganisation, crmCompany.id]
  );

  return (
    <CardContainer
      title={t("Own information at {organisationName}", {
        organisationName: crmCompany.title,
      })}
      isExpandable
    >
      <Stack direction="column" p={1} spacing={1}>
        <TextField
          label={t("Own client id")}
          defaultValue={crmCompany.crmOwningOrganisationClientId}
          onChange={event => {
            handleOwnClientIdChange(event.target.value);
          }}
        />
        {crmCompany.linkedSystemOrganisationId && (
          <DiscountGroupsSection
            supplierId={crmCompany.linkedSystemOrganisationId}
          />
        )}
        {crmCompany.linkedSystemOrganisationId &&
          isSupplierDiscountGroupVariantsEnabled && (
            <DiscountGroupsVariantSection
              supplierId={crmCompany.linkedSystemOrganisationId}
            />
          )}
      </Stack>
    </CardContainer>
  );
}

function DiscountGroupsSection({ supplierId }: { supplierId: string }) {
  const { t } = useTranslate(["CrmOrganisations", "Global"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const { offset, limit, paginationModel, setPaginationModel } =
    usePaginationParams({
      pageUrlParam: "pageDiscountGroups",
      perPageUrlParam: "pageSizeDiscountGroups",
      perPageDefaultAmount: MEDIUM_LIST_RESULTS_PER_PAGE,
      base: 0,
    });

  const { discountGroupTypeLabels } = useDiscountGroupTypes();

  const client = useApolloClient();

  const query = useCrmCompanyOwnInformationDiscountGroupsQuery({
    client,
    fetchPolicy: "network-only",
    variables: {
      supplierId,
      offset,
      limit,
    },
  });

  const items =
    getDataOrNull(query.data?.pimGetSupplierDiscountGroups)?.discountGroups ??
    [];
  const total =
    getDataOrNull(query.data?.pimGetSupplierDiscountGroups)?.totalCount ?? 0;

  return (
    <Stack direction="column" spacing={1}>
      <Divider />
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">
          {t("Discount groups", {
            ns: "CrmOrganisations",
          })}
        </Typography>
        <MenuButton>
          <ViewModeMenuItem
            viewMode={viewMode}
            onViewModeChange={setViewMode}
            allowedModes={[null, "edit"]}
          />
        </MenuButton>
      </Stack>
      <DataGrid
        borderless
        density="compact"
        loading={query.loading}
        hideFooter={total === 0}
        columns={
          [
            {
              field: "key",
              headerName: t("Shortcut", {
                ns: "Global",
              }),
              flex: 1,
              minWidth: 30,
              sortable: false,
              valueGetter: ({ row }) => row.key,
            },
            {
              field: "description",
              headerName: t("Name", {
                ns: "Global",
              }),
              flex: 2,
              minWidth: 50,
              sortable: false,
              valueGetter: ({ row }) => row.description,
            },
            {
              field: "value",
              headerName: t("Value", {
                ns: "Global",
              }),
              flex: 1,
              minWidth: 30,
              sortable: false,
              headerAlign: "right",
              align: "right",
              valueGetter: ({ row }) => row.value,
              renderCell: ({ row }) =>
                viewMode === "edit" ? (
                  <DiscountGroupValueInput
                    discountGroup={row}
                    supplierId={supplierId}
                  />
                ) : (
                  getFormattedPercentage(row.value, language)
                ),
            },
            {
              field: "discountType",
              headerName: t("Type", {
                ns: "Global",
              }),
              flex: 1.25,
              minWidth: 50,
              sortable: false,
              headerAlign: "right",
              align: "right",
              valueGetter: ({ row }) => row.discountType,
              renderCell: ({ row }) =>
                discountGroupTypeLabels[row.discountType],
            },
          ] as GridColDef<DiscountGroupFragment>[]
        }
        rows={items}
        paginationModel={paginationModel}
        onPaginationModelChange={newPaginationModel => {
          setPaginationModel(newPaginationModel);
        }}
        disableColumnFilter
        pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
        rowCount={total}
      />
    </Stack>
  );
}

function DiscountGroupsVariantSection({ supplierId }: { supplierId: string }) {
  const { t } = useTranslate(["CrmOrganisations", "Global"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const [supplierDiscountGroupVariantId, setSupplierDiscountGroupVariantId] =
    React.useState<string | null>(null);

  const { offset, limit, paginationModel, setPaginationModel } =
    usePaginationParams({
      pageUrlParam: "pageDiscountGroupsVariant",
      perPageUrlParam: "pageSizeDiscountGroupsVariant",
      perPageDefaultAmount: MEDIUM_LIST_RESULTS_PER_PAGE,
      base: 0,
    });

  const { discountGroupTypeLabels } = useDiscountGroupTypes();

  const client = useApolloClient();

  const supplierDiscountGroupVariantsQuery =
    useCrmCompanyOwnInformationDiscountGroupVariantsQuery({
      client,
      fetchPolicy: "network-only",
      variables: {
        supplierId,
        offset: 0,
        limit: 100,
      },
    });

  const supplierDiscountGroupsVariantQuery =
    useCrmCompanyOwnInformationDiscountGroupsVariantQuery({
      client,
      fetchPolicy: "network-only",
      variables: {
        supplierDiscountGroupVariantId: supplierDiscountGroupVariantId!,
        offset,
        limit,
      },
      skip: !supplierDiscountGroupVariantId,
    });

  const supplierDiscountGroupVariants =
    getDataOrNull(
      supplierDiscountGroupVariantsQuery.data
        ?.pimSearchSupplierDiscountGroupVariants
    )?.supplierDiscountGroupVariants ?? [];

  const supplierDiscountGroupsVariantItems =
    getDataOrNull(
      supplierDiscountGroupsVariantQuery.data?.pimGetSupplierDiscountGroups
    )?.discountGroups ?? [];
  const supplierDiscountGroupsVariantTotal =
    getDataOrNull(
      supplierDiscountGroupsVariantQuery.data?.pimGetSupplierDiscountGroups
    )?.totalCount ?? 0;

  React.useEffect(() => {
    const supplierDiscountGroupVariant = head(supplierDiscountGroupVariants);
    if (!supplierDiscountGroupVariantId && supplierDiscountGroupVariant) {
      setSupplierDiscountGroupVariantId(supplierDiscountGroupVariant.id);
    }
  }, [supplierDiscountGroupVariantId, supplierDiscountGroupVariants]);

  return (
    <Stack direction="column" spacing={1}>
      <Divider />
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">
          {t("Discount group variants", {
            ns: "CrmOrganisations",
          })}
        </Typography>
        <MenuButton>
          <ViewModeMenuItem
            viewMode={viewMode}
            onViewModeChange={setViewMode}
            allowedModes={[null, "edit"]}
          />
          {!!supplierDiscountGroupVariantId && (
            <AddPimSupplierDiscountGroupsImportationButton
              supplierId={supplierId}
              supplierDiscountGroupVariantId={supplierDiscountGroupVariantId}
            />
          )}
          <SupplierDiscountGroupVariantCreateMenuButton
            supplierId={supplierId}
            refetchQueries={[
              namedOperations.Query
                .CrmCompanyOwnInformationDiscountGroupVariants,
            ]}
          />
          {!!supplierDiscountGroupVariantId && (
            <SupplierDiscountGroupVariantDeleteMenuButton
              supplierDiscountGroupVariantId={supplierDiscountGroupVariantId}
              refetchQueries={[
                namedOperations.Query
                  .CrmCompanyOwnInformationDiscountGroupVariants,
              ]}
            />
          )}
        </MenuButton>
      </Stack>
      <Stack flexDirection="row">
        <Select
          label={t("Variant", {
            ns: "CrmOrganisations",
          })}
          placeholder={t("Variant", {
            ns: "CrmOrganisations",
          })}
          size="small"
          options={supplierDiscountGroupVariants.map(v => ({
            value: v.id,
            label: v.title,
          }))}
          value={supplierDiscountGroupVariantId}
          onChange={value => {
            setSupplierDiscountGroupVariantId(value);
          }}
        />
        <ModalOpenButton
          Modal={SupplierDiscountGroupVariantCreateModal}
          modalProps={{ supplierId }}
        >
          <IconButton color="primary">
            <AddIcon />
          </IconButton>
        </ModalOpenButton>
      </Stack>

      <DataGrid
        density="compact"
        loading={supplierDiscountGroupsVariantQuery.loading}
        hideFooter={supplierDiscountGroupsVariantTotal === 0}
        columns={
          [
            {
              field: "key",
              headerName: t("Shortcut", {
                ns: "Global",
              }),
              flex: 1,
              minWidth: 30,
              sortable: false,
              valueGetter: ({ row }) => row.key,
            },
            {
              field: "description",
              headerName: t("Name", {
                ns: "Global",
              }),
              flex: 2,
              minWidth: 50,
              sortable: false,
              valueGetter: ({ row }) => row.description,
            },
            {
              field: "value",
              headerName: t("Value", {
                ns: "Global",
              }),
              flex: 1,
              minWidth: 30,
              sortable: false,
              headerAlign: "right",
              align: "right",
              valueGetter: ({ row }) => row.value,
              renderCell: ({ row }) =>
                viewMode === "edit" ? (
                  <DiscountGroupValueInput
                    discountGroup={row}
                    supplierId={supplierId}
                  />
                ) : (
                  getFormattedPercentage(row.value, language)
                ),
            },
            {
              field: "discountType",
              headerName: t("Type", {
                ns: "Global",
              }),
              flex: 1.25,
              minWidth: 50,
              sortable: false,
              headerAlign: "right",
              align: "right",
              valueGetter: ({ row }) => row.discountType,
              renderCell: ({ row }) =>
                discountGroupTypeLabels[row.discountType],
            },
          ] as GridColDef<DiscountGroupFragment>[]
        }
        rows={supplierDiscountGroupsVariantItems}
        paginationModel={paginationModel}
        onPaginationModelChange={newPaginationModel => {
          setPaginationModel(newPaginationModel);
        }}
        disableColumnFilter
        pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
        rowCount={supplierDiscountGroupsVariantTotal}
      />
    </Stack>
  );
}

function DiscountGroupValueInput({
  discountGroup,
  supplierId,
}: {
  discountGroup: DiscountGroupFragment;
  supplierId: string;
}) {
  const client = useApolloClient();
  const [value, setValue] = React.useState<number>(discountGroup.value);
  const [modifyDiscountGroup] = usePimModifySupplierDiscountGroupMutation({
    client,
  });
  const handleChange = async () => {
    if (value !== discountGroup.value) {
      await modifyDiscountGroup({
        variables: {
          input: {
            discountGroupId: discountGroup.id,
            supplierId,
            args: {
              value,
            },
          },
        },
      });
    }
  };
  return (
    <FormattedFloatInput
      value={value}
      onChange={v => {
        if (v !== null) setValue(v);
      }}
      min={0}
      max={discountGroup.discountType === "DISCOUNT" ? 100 : undefined}
      unit={"%"}
      multiplier={100}
      size="extra-small"
      onBlur={handleChange}
      onKeyPress={async e => {
        e.stopPropagation();
        if (e.key === "Enter") {
          await handleChange();
        }
      }}
      onKeyDown={e => {
        e.stopPropagation();
      }}
    />
  );
}
