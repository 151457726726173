/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import { InvitationContactFragmentDoc } from '../../invitation/InvitationTokenProvider.generated';
export type OrganisationMemberListItem__OrganisationMembershipFragment = { __typename: 'User', id: string, email: any, active: boolean, organisationId: string, organisationTitle: string, jobTitle: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, roles: Array<{ __typename: 'OrganisationRole', id: string, internalName: string, label: string }>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, internalName: string, label: string }>, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } };

export type OrganisationMemberListItem__OrganisationInvitationFragment = { __typename: 'OrganisationMembershipInvitation', id: string, status: Types.InvitationState, contact: { __typename: 'InvitationContact', id: string, title: Types.Salutation, company: string, firstname: string, familyname: string, email: any, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, organisationRoles?: Array<{ __typename: 'OrganisationRole', id: string, internalName: string }> | null };

export const OrganisationMemberListItem__OrganisationMembershipFragmentDoc = gql`
    fragment OrganisationMemberListItem__OrganisationMembership on User {
  id
  email
  active
  organisationId
  organisationTitle
  active
  jobTitle
  email
  active
  roles {
    id
    internalName
    label
  }
  defaultProjectRoles {
    id
    internalName
    label
  }
  homeAddress {
    ...AddressDetails__Address
  }
  roles {
    id
    label
    internalName
  }
  defaultProjectRoles {
    id
    label
    internalName
  }
  phones {
    id
    number
    type
    main
  }
  id
  title
  description
  website
  isMeister
  familyname
  firstname
  fullname
  locale
  skillset
  email
  phones {
    id
    main
    type
    number
  }
  avatar {
    ...AttachmentSnapshot
  }
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}`;
export const OrganisationMemberListItem__OrganisationInvitationFragmentDoc = gql`
    fragment OrganisationMemberListItem__OrganisationInvitation on OrganisationMembershipInvitation {
  id
  status
  contact {
    ...InvitationContact
  }
  organisationRoles {
    id
    internalName
  }
}
    ${InvitationContactFragmentDoc}`;