import { assert } from "@msys/common";
import {
  CollapseHeader,
  Size,
  TypographyWithIcon,
  useElementObserveSize,
  useElementSize,
  useScreenWidth,
} from "@msys/ui";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTolgee, useTranslate } from "@tolgee/react";
import { partition } from "lodash";
import React from "react";
import { List, WindowScroller } from "react-virtualized";
import { ListRowRenderer } from "react-virtualized/dist/es/List";
import {
  Agreement,
  ContractType,
  ItemType,
} from "../../../clients/graphqlTypes";
import { useHeightsCache } from "../../commons/hooks/useHeightsCache";
import { ExpandedStore } from "../../trees-virtual/hooks/useExpandedStore";
import { getSortedPreviewItems, PriceDataExtended } from "../doc-items/helpers";
import { useQuantityUnits } from "../doc-items/useQuantityUnits";
import { PreviewItem__DocumentItemFragment } from "./DocumentPreviewItems.generated";
import { DocumentPreviewItemsWithVirtualizationMobile } from "./DocumentPreviewItemsMobile";
import {
  FieldName,
  Fields,
  PreviewItem,
  AdditionalColumn,
} from "./DocumentPreviewItemsTypes";

interface Props<T extends PreviewItem__DocumentItemFragment> {
  doc: {
    id: string;
    agreement: Agreement;
    isBinding: boolean;
    contractType: ContractType;
  };
  fields: Fields<T>;
  additionalColumns?: AdditionalColumn<T>[];
  shouldRenderItem?: (item: T, allItems: T[]) => boolean;
  allDocItems: T[];
  navigateToItem?: (item: T) => void;
  selectedItemId?: string | null;
  isTimeAndMaterialContract: boolean;
  getItemStyle?: (theme: Theme, item: T) => React.CSSProperties | undefined;
  container?: HTMLElement | null;
  showCollapseButton?: boolean;
  expandedStore: ExpandedStore | undefined;
}

export const DocumentPreviewItems = <
  T extends PreviewItem__DocumentItemFragment,
>({
  doc,
  fields,
  additionalColumns,
  shouldRenderItem,
  allDocItems,
  navigateToItem,
  selectedItemId,
  isTimeAndMaterialContract,
  getItemStyle,
  container,
  showCollapseButton = true,
  expandedStore,
}: Props<T>) => {
  const { isMaxPhone } = useScreenWidth();
  const { t } = useTranslate("Quote");
  const language = useTolgee(["language"]).getLanguage()!;
  const { quantityUnitLabels } = useQuantityUnits();

  const fieldTitleLabels: Record<
    Extract<
      FieldName,
      "Pos" | "Title" | "Quantity" | "UnitPrice" | "Subtotal" | "TotalPrice"
    >,
    string
  > = {
    Pos: t("Pos. "),
    Title: t("Title"),
    Quantity: t("Quantity"),
    UnitPrice: t("Unit Price"),
    Subtotal: t("Subtotal"),
    TotalPrice: t("Total Price"),
  };

  const previewItems: PreviewItem<T>[] = getSortedPreviewItems<T>({
    allDocItems,
    shouldRenderItem,
    docIsBinding: doc.isBinding,
    isTimeAndMaterialContract,
    quantityUnitLabels,
    language,
    expandedStore,
  });

  if (isMaxPhone) {
    return previewItems.length > 0 ? (
      <Paper
        sx={{
          ".ReactVirtualized__List": { outline: "none !important" }, // no outline on virtualized list
        }}
      >
        <DocumentPreviewItemsWithVirtualizationMobile<T>
          container={container}
          previewItems={previewItems}
          fields={fields}
          getItemStyle={getItemStyle}
          defaultHeight={120} // generic height for row on mobile
          overscanRowCount={2}
          showCollapseButton={showCollapseButton}
        />
      </Paper>
    ) : null;
  }

  const additionalColumnsCount =
    additionalColumns?.reduce((acc, c) => acc + c.columns, 0) ?? 0;

  return previewItems.length > 0 ? (
    <Paper
      sx={{
        ".ReactVirtualized__List": { outline: "none !important" }, // no outline on virtualized list
      }}
    >
      <Box position={"sticky"} top={"-16px"} zIndex={1}>
        <Typography
          component={"div"}
          variant="body2"
          fontWeight="500"
          color="secondary"
          padding={1}
          sx={{
            backgroundColor: theme => theme.palette.background.paper,
          }}
        >
          <Grid container columns={columnCount + additionalColumnsCount}>
            <Grid item xs={positionColumnCount}>
              {fieldTitleLabels.Pos}
            </Grid>
            {additionalColumns?.map((c, index) => (
              <Grid item xs={c.columns} key={`additional-column-${index}`}>
                {c.label}
              </Grid>
            ))}
            <Grid item xs={titleColumnCount}>
              {fieldTitleLabels.Title}
            </Grid>
            <Grid item xs={quantityColumnCount}>
              {fieldTitleLabels.Quantity}
            </Grid>
            <Grid item xs={unitPriceColumnCount} textAlign={"right"}>
              {fieldTitleLabels.UnitPrice}
            </Grid>
            <Grid item xs={subtotalColumnCount} textAlign={"right"}>
              {fieldTitleLabels.Subtotal}
            </Grid>
            <Grid item xs={totalPriceColumnCount} textAlign={"right"}>
              {fieldTitleLabels.TotalPrice}
            </Grid>
          </Grid>
        </Typography>
        <Divider />
      </Box>
      <DocumentPreviewItemsWithVirtualizationDesktop
        container={container}
        previewItems={previewItems}
        fields={fields}
        additionalColumns={additionalColumns}
        getItemStyle={getItemStyle}
        defaultHeight={48} // generic height for row on desktop
        docId={doc.id}
        overscanRowCount={2}
        navigateToItem={navigateToItem}
        selectedItemId={selectedItemId}
        showCollapseButton={showCollapseButton}
      />
    </Paper>
  ) : null;
};

const DocumentPreviewItemsWithVirtualizationDesktop = React.memo(
  <T extends PreviewItem__DocumentItemFragment>({
    previewItems,
    container,
    fields,
    additionalColumns,
    getItemStyle,
    docId,
    navigateToItem,
    selectedItemId,
    defaultHeight,
    overscanRowCount,
    showCollapseButton,
  }: {
    previewItems: PreviewItem<T>[];
    container?: HTMLElement | null;
    fields: Fields<T>;
    additionalColumns?: AdditionalColumn<T>[];
    getItemStyle?: (theme: Theme, item: T) => React.CSSProperties | undefined;
    docId: string;
    navigateToItem?: (item: T) => void;
    selectedItemId?: string | null;
    defaultHeight: number;
    overscanRowCount: number;
    showCollapseButton: boolean;
  }) => {
    const sizeMeasureRef = React.useRef<HTMLDivElement | null>(null);
    const size = useElementSize(sizeMeasureRef);
    const { setHeightForId, totalHeight, getHeightForIndex, cache } =
      useHeightsCache(previewItems, defaultHeight, i => i.item.id);
    const rowRenderer = React.useMemo(
      () =>
        getRowRendererDesktop({
          previewItems,
          fields,
          additionalColumns,
          getItemStyle,
          setHeightForId,
          docId,
          navigateToItem,
          selectedItemId,
          showCollapseButton,
        }),
      [
        previewItems,
        fields,
        additionalColumns,
        getItemStyle,
        setHeightForId,
        docId,
        navigateToItem,
        selectedItemId,
        showCollapseButton,
      ]
    );

    const listRef = React.useRef<List | null>(null);
    React.useEffect(() => {
      listRef.current?.recomputeRowHeights();
    }, [previewItems]);

    return (
      // @ts-ignore render return type is incompatible
      <WindowScroller scrollElement={container ?? window}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <div
            ref={ref => {
              sizeMeasureRef.current = ref;
              // @ts-ignore wrong types
              registerChild(ref);
            }}
            style={{ height: totalHeight }} // this is important to set, otherwise height will be shrinking when going up
          >
            {/*@ts-ignore render return type is incompatible*/}
            <List
              ref={listRef}
              autoHeight
              deferredMeasurementCache={cache}
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              overscanRowCount={overscanRowCount}
              rowCount={previewItems.length}
              rowHeight={getHeightForIndex}
              rowRenderer={rowRenderer}
              scrollTop={scrollTop}
              width={size.width}
            />
          </div>
        )}
      </WindowScroller>
    );
  }
);

const getRowRendererDesktop =
  <T extends PreviewItem__DocumentItemFragment>({
    previewItems,
    fields,
    additionalColumns,
    getItemStyle,
    setHeightForId,
    docId,
    navigateToItem,
    selectedItemId,
    showCollapseButton,
  }: {
    previewItems: PreviewItem<T>[];
    fields: Fields<T>;
    additionalColumns?: AdditionalColumn<T>[];
    getItemStyle?: (theme: Theme, item: T) => React.CSSProperties | undefined;
    setHeightForId: (id: string, height: number) => boolean;
    docId: string;
    navigateToItem?: (item: T) => void;
    selectedItemId?: string | null;
    showCollapseButton: boolean;
  }): ListRowRenderer =>
  ({ index, isScrolling, key, style, parent }) => {
    const {
      item,
      parent: parentItem,
      childItems,
      priceData,
    } = previewItems[index];
    const isItemFromOriginDoc = Boolean(item.originDocId === docId);
    const isParentFromOriginDoc = Boolean(
      parentItem && parentItem.originDocId === docId
    );
    return (
      <div style={{ ...style }} key={key}>
        <DocumentPreviewItemRowDesktop
          key={item.id}
          fields={fields}
          additionalColumns={additionalColumns}
          childItems={
            childItems
              ? childItems.map(({ priceData, parent, item }) => ({
                  item,
                  parent,
                  priceData,
                  isSelected: isItemFromOriginDoc && selectedItemId === item.id,
                  handleClick:
                    navigateToItem && isItemFromOriginDoc
                      ? (e: React.MouseEvent<HTMLElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigateToItem(item);
                        }
                      : undefined,
                }))
              : null
          }
          item={item}
          parent={parentItem}
          priceData={priceData}
          isSelected={
            (isItemFromOriginDoc || isParentFromOriginDoc) &&
            selectedItemId === item.id
          }
          handleClick={
            navigateToItem && (isItemFromOriginDoc || isParentFromOriginDoc)
              ? (e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigateToItem(item);
                }
              : undefined
          }
          getItemStyle={getItemStyle}
          onSizeChange={size => {
            const changed = setHeightForId(item.id, Math.ceil(size.height));
            if (changed) {
              parent?.recomputeGridSize?.({
                columnIndex: 0,
                rowIndex: index,
              });
            }
          }}
          divider={index < previewItems.length - 1}
          showCollapseButton={showCollapseButton}
        />
      </div>
    );
  };

const DocumentPreviewItemRowDesktop = <
  T extends PreviewItem__DocumentItemFragment,
>({
  divider,
  item,
  parent,
  priceData,
  childItems,
  fields,
  additionalColumns,
  isSelected,
  handleClick,
  getItemStyle,
  onSizeChange,
  showCollapseButton,
}: {
  divider: boolean;
  fields: Fields<T>;
  additionalColumns?: AdditionalColumn<T>[];
  item: T;
  parent: T | undefined;
  priceData: PriceDataExtended;
  childItems:
    | {
        item: T;
        parent: T | undefined;
        priceData: PriceDataExtended;
        isSelected: boolean;
        handleClick:
          | ((event: React.MouseEvent<HTMLElement>) => void)
          | undefined;
      }[]
    | null;
  isSelected: boolean;
  handleClick: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
  getItemStyle?: (theme: Theme, item: T) => React.CSSProperties | undefined;
  onSizeChange?(size: Size): void;
  showCollapseButton: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslate(["Decisions", "Quote"]);

  const sizeMeasureRef = React.useRef<HTMLDivElement | null>(null);
  useElementObserveSize(sizeMeasureRef, onSizeChange);

  const [itemChildren, eliminatedItemChildren] = partition(
    childItems ?? [],
    ({ item }) => !item.decisionOptionElimination
  );

  const [mainSubItems, alternativeSubItems] =
    item.decisionSubitemsPreselection.length > 0
      ? partition(itemChildren, ({ item: i }) =>
          item.decisionSubitemsPreselection.includes(i.id)
        )
      : [itemChildren, []];

  const [isEliminatedExpanded, setIsEliminatedExpanded] = React.useState(false);
  const [isAlternativeExpanded, setIsAlternativeExpanded] =
    React.useState(false);

  const ContingencyOptionalCaption = fields.ContingencyOptionalCaption.render(
    item,
    parent,
    priceData
  );
  const NonBindingCaption = fields.NonBindingCaption.render(
    item,
    parent,
    priceData
  );
  const ContingencyOptionsButtons = fields.ContingencyOptionsButtons.render(
    item,
    parent,
    priceData
  );
  const ContingencyFinalizeButton = fields.ContingencyFinalizeButton.render(
    item,
    parent,
    priceData
  );
  const CollapseButton = fields.CollapseButton.render(item, parent, priceData);
  const Caption =
    ContingencyOptionsButtons ||
    ContingencyOptionalCaption ||
    NonBindingCaption ||
    ContingencyFinalizeButton ? (
      <Stack direction={"row"} alignItems="center" spacing={2}>
        <Stack direction={"row"} alignItems="center">
          {ContingencyOptionsButtons ? (
            <FormControlLabel
              control={ContingencyOptionsButtons}
              label={null}
              sx={{ marginRight: 0 }}
            />
          ) : null}
          <Typography component="div" variant="caption" color="textSecondary">
            {ContingencyOptionalCaption}
            {NonBindingCaption ? <> • {NonBindingCaption}</> : null}
          </Typography>
        </Stack>
        {ContingencyFinalizeButton}
      </Stack>
    ) : null;
  const Pos = fields.Pos.render(item, parent, priceData);
  const Title = fields.Title.render(item, parent, priceData);
  const Description = fields.Description.render(item, parent, priceData);
  const Images = fields.Images.render(item, parent, priceData);
  const Attachments = fields.Attachments.render(item, parent, priceData);
  const Quantity = fields.Quantity.render(item, parent, priceData);
  const UnitPrice = fields.UnitPrice.render(item, parent, priceData);
  const Subtotal = fields.Subtotal.render(item, parent, priceData);
  const TotalPrice = fields.TotalPrice.render(item, parent, priceData);
  const DecisionButton = fields.DecisionButton.render(item, parent, priceData);
  const DecisionFinalizeButton = fields.DecisionFinalizeButton.render(
    item,
    parent,
    priceData
  );
  const DecisionNoneOptionButton = fields.DecisionNoneOptionButton.render(
    item,
    parent,
    priceData
  );
  const AdditionalColumns = additionalColumns?.map((c, index) => (
    <Grid
      item
      xs={c.columns}
      textAlign={c.textAlign}
      key={`additional-column-${item.id}-${index}`}
      sx={c.textAlign === "right" ? { direction: "rtl" } : undefined}
    >
      {c.render(item, parent, priceData)}
    </Grid>
  ));

  const itemStyle = getItemStyle?.(theme, item);
  const titleLineStyle = getTitleLineStyle(item.level, item.type);
  const isClickable = Boolean(handleClick && !isSelected);

  const additionalColumnsCount =
    additionalColumns?.reduce((acc, c) => acc + c.columns, 0) ?? 0;

  return (
    <Stack ref={sizeMeasureRef}>
      <Paper
        variant={"elevation"}
        elevation={0}
        sx={{
          boxShadow: isSelected
            ? theme => `0px 0px 0px 2px ${theme.palette.secondary.main} inset`
            : undefined,
          ...(!isSelected && isClickable ? clickableStyle : undefined),
        }}
        style={itemStyle}
        onClick={handleClick}
      >
        {!item.isRootItem && (
          <ItemRow
            key={item.id}
            Pos={Pos}
            DecisionOptionButton={null}
            Caption={Caption}
            Title={Title}
            Quantity={Quantity}
            UnitPrice={UnitPrice}
            Subtotal={Subtotal}
            TotalPrice={TotalPrice}
            Description={Description}
            Images={Images}
            Attachments={Attachments}
            CollapseButton={CollapseButton}
            AdditionalColumns={AdditionalColumns}
            titleLineStyle={titleLineStyle}
            showCollapseButton={showCollapseButton}
            additionalColumnsCount={additionalColumnsCount}
          />
        )}

        {mainSubItems.map(
          ({ item, parent, priceData, isSelected, handleClick }) => {
            const Title = fields.Title.render(item, parent, priceData);
            const DecisionOptionButton = fields.DecisionOptionButton.render(
              item,
              parent
            );
            const Description = fields.Description.render(
              item,
              parent,
              priceData
            );
            const Images = fields.Images.render(item, parent, priceData);
            const Attachments = fields.Attachments.render(
              item,
              parent,
              priceData
            );
            const Quantity = fields.Quantity.render(item, parent, priceData);
            const UnitPrice = fields.UnitPrice.render(item, parent, priceData);
            const Subtotal = fields.Subtotal.render(item, parent, priceData);

            const AdditionalColumns = additionalColumns?.map((c, index) => (
              <Grid
                item
                xs={c.columns}
                textAlign={c.textAlign}
                key={`additional-column-${item.id}-${index}`}
                sx={c.textAlign === "right" ? { direction: "rtl" } : undefined}
              >
                {c.render(item, parent, priceData)}
              </Grid>
            ));

            const isClickable = Boolean(handleClick && !isSelected);
            const itemStyle = getItemStyle?.(theme, item);

            return (
              <OptionRow
                key={item.id}
                DecisionOptionButton={DecisionOptionButton}
                Title={Title}
                Quantity={Quantity}
                UnitPrice={UnitPrice}
                Subtotal={Subtotal}
                Description={Description}
                Images={Images}
                Attachments={Attachments}
                AdditionalColumns={AdditionalColumns}
                isClickable={isClickable}
                isSelected={isSelected}
                handleClick={handleClick}
                itemStyle={itemStyle}
                additionalColumnsCount={additionalColumnsCount}
              />
            );
          }
        )}
        {alternativeSubItems.length > 0 && (
          <Grid
            key={`setIsAlternativeExpanded-${item.id}`}
            container
            columns={columnCount + additionalColumnsCount}
            padding={1}
            rowSpacing={1}
          >
            <Grid item xs={positionColumnCount + additionalColumnsCount}></Grid>
            <Grid item xs={descriptionColumnCount}>
              <CollapseHeader
                isExpandable={true}
                isExpanded={isAlternativeExpanded}
                setIsExpanded={setIsAlternativeExpanded}
                minHeight="auto"
                py={0}
                title={
                  <TypographyWithIcon
                    count={alternativeSubItems.length}
                    variant="h4"
                  >
                    {item.decisionBehaviorOfSubitems === "SELECT_MANY"
                      ? t("Additional options", {
                          ns: "Quote",
                        })
                      : t("Alternative options", {
                          ns: "Quote",
                        })}
                  </TypographyWithIcon>
                }
              />
              <Grid item xs={totalPriceColumnCount}></Grid>
            </Grid>
          </Grid>
        )}
        {isAlternativeExpanded &&
          alternativeSubItems.map(
            ({ item, parent, priceData, isSelected, handleClick }) => {
              const Title = fields.Title.render(item, parent, priceData);
              const DecisionOptionButton = fields.DecisionOptionButton.render(
                item,
                parent
              );
              const Description = fields.Description.render(
                item,
                parent,
                priceData
              );
              const Images = fields.Images.render(item, parent, priceData);
              const Attachments = fields.Attachments.render(
                item,
                parent,
                priceData
              );
              const Quantity = fields.Quantity.render(item, parent, priceData);
              const UnitPrice = fields.UnitPrice.render(
                item,
                parent,
                priceData
              );
              const Subtotal = fields.Subtotal.render(item, parent, priceData);

              const AdditionalColumns = additionalColumns?.map((c, index) => (
                <Grid
                  item
                  xs={c.columns}
                  textAlign={c.textAlign}
                  key={`additional-column-${item.id}-${index}`}
                  sx={
                    c.textAlign === "right" ? { direction: "rtl" } : undefined
                  }
                >
                  {c.render(item, parent, priceData)}
                </Grid>
              ));

              const isClickable = Boolean(handleClick && !isSelected);
              const itemStyle = getItemStyle?.(theme, item);

              return (
                <OptionRow
                  key={item.id}
                  DecisionOptionButton={DecisionOptionButton}
                  Title={Title}
                  Quantity={Quantity}
                  UnitPrice={UnitPrice}
                  Subtotal={Subtotal}
                  Description={Description}
                  Images={Images}
                  Attachments={Attachments}
                  AdditionalColumns={AdditionalColumns}
                  isClickable={isClickable}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  itemStyle={itemStyle}
                  additionalColumnsCount={additionalColumnsCount}
                />
              );
            }
          )}
        {(isAlternativeExpanded ||
          item.decisionSubitemsPreselection.length === 0 ||
          alternativeSubItems.length === 0) &&
          DecisionNoneOptionButton && (
            <Grid
              key={`DecisionNoneOptionButton-${item.id}`}
              container
              columns={columnCount + additionalColumnsCount}
              padding={1}
              paddingBottom={1}
              rowSpacing={1}
            >
              <Grid
                item
                xs={positionColumnCount + additionalColumnsCount}
              ></Grid>
              <Grid item xs={descriptionColumnCount}>
                {DecisionNoneOptionButton}
              </Grid>
              <Grid item xs={totalPriceColumnCount}></Grid>
            </Grid>
          )}
        {eliminatedItemChildren.length > 0 && (
          <Grid
            key={`setIsEliminatedExpanded-${item.id}`}
            container
            columns={columnCount + additionalColumnsCount}
            padding={1}
            spacing={1}
          >
            <Grid item xs={positionColumnCount + additionalColumnsCount}></Grid>
            <Grid item xs={descriptionColumnCount}>
              <CollapseHeader
                isExpandable={true}
                isExpanded={isEliminatedExpanded}
                setIsExpanded={setIsEliminatedExpanded}
                minHeight="auto"
                py={0}
                title={
                  <TypographyWithIcon
                    count={eliminatedItemChildren.length}
                    variant="h4"
                  >
                    {t("Discarded options", {
                      ns: "Decisions",
                    })}
                  </TypographyWithIcon>
                }
              />
            </Grid>
            <Grid item xs={totalPriceColumnCount}></Grid>
          </Grid>
        )}
        {isEliminatedExpanded &&
          eliminatedItemChildren.map(
            ({ item, parent, priceData, isSelected, handleClick }) => {
              const Title = fields.Title.render(item, parent, priceData);
              const Description = fields.Description.render(
                item,
                parent,
                priceData
              );
              const Images = fields.Images.render(item, parent, priceData);
              const Attachments = fields.Attachments.render(
                item,
                parent,
                priceData
              );
              const Quantity = fields.Quantity.render(item, parent, priceData);
              const UnitPrice = fields.UnitPrice.render(
                item,
                parent,
                priceData
              );
              const Subtotal = fields.Subtotal.render(item, parent, priceData);

              const AdditionalColumns = additionalColumns?.map((c, index) => (
                <Grid
                  item
                  xs={c.columns}
                  textAlign={c.textAlign}
                  key={`additional-column-${item.id}-${index}`}
                  sx={
                    c.textAlign === "right" ? { direction: "rtl" } : undefined
                  }
                >
                  {c.render(item, parent, priceData)}
                </Grid>
              ));

              const isClickable = Boolean(handleClick && !isSelected);
              const itemStyle = getItemStyle?.(theme, item);

              return (
                <OptionRow
                  key={item.id}
                  DecisionOptionButton={null}
                  Title={Title}
                  Quantity={Quantity}
                  UnitPrice={UnitPrice}
                  Subtotal={Subtotal}
                  Description={Description}
                  Images={Images}
                  Attachments={Attachments}
                  AdditionalColumns={AdditionalColumns}
                  isClickable={isClickable}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  itemStyle={itemStyle}
                  additionalColumnsCount={additionalColumnsCount}
                />
              );
            }
          )}
        {(DecisionButton || DecisionFinalizeButton) && (
          <Grid
            key={`decisionButtons-${item.id}`}
            container
            columns={columnCount + additionalColumnsCount}
            padding={1}
            paddingTop={1}
            rowSpacing={1}
          >
            <Grid item xs={positionColumnCount + additionalColumnsCount}></Grid>
            <Grid item xs={descriptionColumnCount}>
              <Stack direction={"row"} spacing={1}>
                {DecisionButton}
                {DecisionFinalizeButton}
              </Stack>
            </Grid>
            <Grid item xs={totalPriceColumnCount}></Grid>
          </Grid>
        )}
      </Paper>
      {divider && (
        <Grid
          key={`divider-${item.id}`}
          container
          columns={columnCount + additionalColumnsCount}
        >
          <Grid item xs={positionColumnCount + additionalColumnsCount}></Grid>
          <Grid item xs={descriptionColumnCount}>
            <Divider />
          </Grid>
          <Grid item xs={totalPriceColumnCount}></Grid>
        </Grid>
      )}
    </Stack>
  );
};

////////////
///  UI  ///
////////////

const columnCount = 22 as const;
const positionColumnCount = 3 as const;
const titleColumnCount = 10 as const;
const quantityColumnCount = 2 as const;
const unitPriceColumnCount = 2 as const;
const subtotalColumnCount = 2 as const;
const totalPriceColumnCount = 3 as const;
const descriptionColumnCount = 16 as const;

assert(
  positionColumnCount +
    titleColumnCount +
    quantityColumnCount +
    unitPriceColumnCount +
    subtotalColumnCount +
    totalPriceColumnCount ===
    columnCount &&
    positionColumnCount + descriptionColumnCount + totalPriceColumnCount ===
      columnCount,
  "Column count does not add up"
);

function CollapseButtonWrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <span style={{ position: "relative", width: "44px" }}>
      <span style={{ position: "absolute", left: "-3px", top: "-3px" }}>
        {children}
      </span>
    </span>
  );
}

function ItemRow({
  Pos,
  Caption,
  DecisionOptionButton,
  Title,
  Quantity,
  UnitPrice,
  Subtotal,
  TotalPrice,
  Description,
  Images,
  Attachments,
  CollapseButton,
  AdditionalColumns,
  titleLineStyle,
  showCollapseButton,
  additionalColumnsCount,
}: {
  Pos: React.ReactElement | null;
  Caption: React.ReactElement | null;
  DecisionOptionButton: React.ReactElement | null;
  Title: React.ReactElement | null;
  Quantity: React.ReactElement | null;
  UnitPrice: React.ReactElement | null;
  Subtotal: React.ReactElement | null;
  TotalPrice: React.ReactElement | null;
  Description: React.ReactElement | null;
  Images: React.ReactElement | null;
  Attachments: React.ReactElement | null;
  CollapseButton: React.ReactElement | null;
  AdditionalColumns: React.ReactElement[] | undefined;
  titleLineStyle: React.CSSProperties | undefined;
  showCollapseButton: boolean;
  additionalColumnsCount: number;
}) {
  return (
    <Grid
      container
      columns={columnCount + additionalColumnsCount}
      padding={1}
      rowSpacing={1}
    >
      {Caption && (
        <>
          <Grid item xs={positionColumnCount + additionalColumnsCount} />
          <Grid item xs={descriptionColumnCount}>
            {Caption}
          </Grid>
          <Grid item xs={totalPriceColumnCount}></Grid>
        </>
      )}

      <Grid
        item
        xs={positionColumnCount}
        // sx={titleLineStyle}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        {showCollapseButton && (
          <CollapseButtonWrapper>{CollapseButton}</CollapseButtonWrapper>
        )}
        {Pos}
      </Grid>

      {AdditionalColumns}

      <Grid item xs={titleColumnCount} style={titleLineStyle}>
        <Stack direction={"row"} alignItems={"flex-start"}>
          {DecisionOptionButton ? (
            <FormControlLabel
              label={null}
              control={DecisionOptionButton}
              sx={{ marginY: "-7px", marginRight: "4px" }}
            />
          ) : null}
          {Title}
        </Stack>
      </Grid>
      <Grid
        item
        xs={quantityColumnCount}
        // sx={titleLineStyle}
      >
        {Quantity}
      </Grid>
      <Grid
        item
        xs={unitPriceColumnCount}
        textAlign={"right"}
        sx={{
          // ...titleLineStyle,
          direction: "rtl",
        }}
      >
        {UnitPrice}
      </Grid>
      <Grid
        item
        xs={subtotalColumnCount}
        textAlign={"right"}
        sx={{
          // ...titleLineStyle,
          direction: "rtl",
        }}
      >
        {Subtotal}
      </Grid>
      <Grid
        item
        xs={totalPriceColumnCount}
        textAlign={"right"}
        sx={{
          ...titleLineStyle,
          direction: "rtl",
        }}
      >
        {TotalPrice}
      </Grid>

      {(Description || Images || Attachments) && (
        <>
          <Grid item xs={positionColumnCount + additionalColumnsCount}></Grid>
          <Grid
            item
            xs={descriptionColumnCount}
            sx={DecisionOptionButton ? { paddingLeft: "27px" } : undefined}
          >
            <Stack spacing={1}>
              {Description}
              {Images}
              {Attachments}
            </Stack>
          </Grid>
          <Grid item xs={totalPriceColumnCount}></Grid>
        </>
      )}
    </Grid>
  );
}

function OptionRow({
  DecisionOptionButton,
  Title,
  isClickable,
  isSelected,
  handleClick,
  itemStyle,
  ...props
}: {
  DecisionOptionButton: React.ReactElement | null;
  Title: React.ReactElement | null;
  Quantity: React.ReactElement | null;
  UnitPrice: React.ReactElement | null;
  Subtotal: React.ReactElement | null;
  Description: React.ReactElement | null;
  Images: React.ReactElement | null;
  Attachments: React.ReactElement | null;
  AdditionalColumns: React.ReactElement[] | undefined;
  isClickable: boolean;
  isSelected: boolean;
  handleClick: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
  itemStyle: React.CSSProperties | undefined;
  additionalColumnsCount: number;
}) {
  return (
    <Paper
      variant={"elevation"}
      elevation={0}
      sx={{
        display: "flex",
        backgroundColor: isSelected ? undefined : `rgb(0,0,0,0)`,
        boxShadow: isSelected
          ? theme => `0px 0px 0px 2px ${theme.palette.secondary.main} inset`
          : undefined,
        ...(!isSelected && isClickable ? clickableStyle : undefined),
      }}
      style={itemStyle}
      onClick={handleClick}
    >
      <ItemRow
        Pos={null}
        Caption={null}
        DecisionOptionButton={DecisionOptionButton}
        Title={Title}
        TotalPrice={null}
        titleLineStyle={undefined}
        CollapseButton={null}
        showCollapseButton={false}
        {...props}
      />
    </Paper>
  );
}

function getTitleLineStyle(
  itemLevel: number,
  itemType: ItemType
): Pick<React.CSSProperties, "fontSize" | "fontWeight" | "lineHeight"> {
  switch (itemLevel) {
    case 1: {
      return {
        fontSize: "20px",
        fontWeight: "medium", // itemType === "paid" ? "regular" : "medium",
        lineHeight: "24px",
      };
    }
    case 2: {
      return {
        fontSize: "18px",
        fontWeight: "medium", // itemType === "paid" ? "regular" : "medium",
        lineHeight: "24px",
      };
    }
    case 3: {
      return {
        fontSize: "17px",
        fontWeight: "medium", // itemType === "paid" ? "regular" : "medium",
        lineHeight: "24px",
      };
    }
    default: {
      return {
        fontSize: "16px",
        fontWeight: "medium", // itemType === "paid" ? "regular" : "medium",
        lineHeight: "20px",
      };
    }
  }
}

const hoverStyle = {
  backgroundColor: (theme: Theme) => theme.palette.action.hover,
};
const clickableStyle = {
  cursor: "pointer",
  "&:hover:not(:has(button:hover)):not(:has(a:hover)):not(:has(input:hover))":
    hoverStyle,
};
