import { useApolloClient } from "@apollo/client";
import { LoadingSpinner } from "@msys/ui";
import { useParams } from "react-router-dom";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageGrid } from "../../commons/layout/PageGrid";
import { OrganisationDetailsBox } from "../../features/organisations/boxes/OrganisationDetailsBox";
import { OrganisationPaymentInformationBox } from "../../features/organisations/boxes/OrganisationPaymentInformationBox";
import { OrganisationRegistrationDetailsBox } from "../../features/organisations/boxes/OrganisationRegistrationDetailsBox";
import { useGlobalOrganisationProfileQuery } from "./GlobalOrganisationProfile.generated";

export function GlobalOrganisationProfile() {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error("Organisation id is missing");

  const client = useApolloClient();
  const query = useGlobalOrganisationProfileQuery({
    client,
    variables: { organisationId },
  });

  const organisation = query.data?.globalOrganisation;
  const isManufacturer = organisation?.organisationType === "MANUFACTURER";
  const isSupplier = organisation?.organisationType === "SUPPLIER";

  if (!organisation) return <div>organisation not found</div>;
  if (!isManufacturer && !isSupplier)
    return <div>invalid organisation type</div>;

  return (
    <Page title={organisation?.title}>
      <PageContainer>
        {query.loading && <LoadingSpinner />}
        {organisation && (
          <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
            <>
              <OrganisationDetailsBox
                organisation={organisation}
                isViewerOrganisation={false}
              />
            </>
            <>
              <OrganisationPaymentInformationBox organisation={organisation} />
              <OrganisationRegistrationDetailsBox organisation={organisation} />
            </>
          </PageGrid>
        )}
      </PageContainer>
    </Page>
  );
}
