/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuotePublishingInfoModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type QuotePublishingInfoModalQuery = { quote: { __typename: 'MissingPermissions' } | { __typename: 'QuoteResult', quote?: { __typename: 'Quote', id: string, title: string, isBinding: boolean, expirationDate?: any | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null, title: string } } | null } };

export type ModifyPublishingInfoMutationVariables = Types.Exact<{
  input: Types.QuoteModifyInput;
}>;


export type ModifyPublishingInfoMutation = { modifyQuote: { __typename: 'ModifyQuoteResult', quote: { __typename: 'Quote', id: string, title: string, isBinding: boolean, expirationDate?: any | null } } };


export const QuotePublishingInfoModalDocument = gql`
    query QuotePublishingInfoModal($projectId: ID!, $quoteId: ID!) {
  quote(projectId: $projectId, docId: $quoteId) {
    ... on QuoteResult {
      quote {
        id
        title
        isBinding
        expirationDate
        rootItem {
          id
          originVersionNumber
          title
        }
      }
    }
  }
}
    `;

/**
 * __useQuotePublishingInfoModalQuery__
 *
 * To run a query within a React component, call `useQuotePublishingInfoModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotePublishingInfoModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotePublishingInfoModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuotePublishingInfoModalQuery(baseOptions: Apollo.QueryHookOptions<QuotePublishingInfoModalQuery, QuotePublishingInfoModalQueryVariables> & ({ variables: QuotePublishingInfoModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuotePublishingInfoModalQuery, QuotePublishingInfoModalQueryVariables>(QuotePublishingInfoModalDocument, options);
      }
export function useQuotePublishingInfoModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotePublishingInfoModalQuery, QuotePublishingInfoModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuotePublishingInfoModalQuery, QuotePublishingInfoModalQueryVariables>(QuotePublishingInfoModalDocument, options);
        }
export function useQuotePublishingInfoModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuotePublishingInfoModalQuery, QuotePublishingInfoModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuotePublishingInfoModalQuery, QuotePublishingInfoModalQueryVariables>(QuotePublishingInfoModalDocument, options);
        }
export type QuotePublishingInfoModalQueryHookResult = ReturnType<typeof useQuotePublishingInfoModalQuery>;
export type QuotePublishingInfoModalLazyQueryHookResult = ReturnType<typeof useQuotePublishingInfoModalLazyQuery>;
export type QuotePublishingInfoModalSuspenseQueryHookResult = ReturnType<typeof useQuotePublishingInfoModalSuspenseQuery>;
export type QuotePublishingInfoModalQueryResult = Apollo.QueryResult<QuotePublishingInfoModalQuery, QuotePublishingInfoModalQueryVariables>;
export const ModifyPublishingInfoDocument = gql`
    mutation ModifyPublishingInfo($input: QuoteModifyInput!) {
  modifyQuote(input: $input) {
    quote {
      id
      title
      isBinding
      expirationDate
    }
  }
}
    `;
export type ModifyPublishingInfoMutationFn = Apollo.MutationFunction<ModifyPublishingInfoMutation, ModifyPublishingInfoMutationVariables>;

/**
 * __useModifyPublishingInfoMutation__
 *
 * To run a mutation, you first call `useModifyPublishingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPublishingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPublishingInfoMutation, { data, loading, error }] = useModifyPublishingInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyPublishingInfoMutation(baseOptions?: Apollo.MutationHookOptions<ModifyPublishingInfoMutation, ModifyPublishingInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyPublishingInfoMutation, ModifyPublishingInfoMutationVariables>(ModifyPublishingInfoDocument, options);
      }
export type ModifyPublishingInfoMutationHookResult = ReturnType<typeof useModifyPublishingInfoMutation>;
export type ModifyPublishingInfoMutationResult = Apollo.MutationResult<ModifyPublishingInfoMutation>;
export type ModifyPublishingInfoMutationOptions = Apollo.BaseMutationOptions<ModifyPublishingInfoMutation, ModifyPublishingInfoMutationVariables>;