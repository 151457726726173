import { gql, useApolloClient } from "@apollo/client";
import { Modal, useLocalStorageAsState } from "@msys/ui";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import React from "react";
import * as uuid from "uuid";
import * as Yup from "yup";
import { PasswordField } from "../../../commons/form-fields/PasswordField";
import { useOpenMasterdataAuthenticateMutation } from "./SyncViaOpenMasterdataAuthenticationModal.generated";

interface FormValues {
  clientId: string;
  username: string;
  password: string;
}

interface Props {
  systemSupplierOrganisationId: string;
  openMasterdataInfo: {
    clientIdRequired: boolean;
    usernameRequired: boolean;
    passwordRequired: boolean;
  };
  handleComplete: (handleClose: () => void) => Promise<void>;
  handleClose: () => void;
}

export function SyncViaOpenMasterdataModal({
  systemSupplierOrganisationId,
  openMasterdataInfo,
  handleComplete,
  handleClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["PurchaseOrders", "Product", "Global"]);

  const client = useApolloClient();
  const [openMasterAuthenticate] = useOpenMasterdataAuthenticateMutation({
    client,
  });

  const [openMasterdataLogin, setOpenMasterdataLogin] = useLocalStorageAsState<{
    clientId: string;
    username: string;
  }>(`msys-open-masterdata-login-${systemSupplierOrganisationId}`, {
    clientId: "",
    username: "",
  });

  const initialValues: FormValues = {
    clientId: openMasterdataLogin?.clientId ?? "",
    username: openMasterdataLogin?.username ?? "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    ...(openMasterdataInfo.clientIdRequired
      ? {
          clientId: Yup.string()
            .label(
              t("Client id", {
                ns: "PurchaseOrders",
              })
            )
            .required(),
        }
      : undefined),
    ...(openMasterdataInfo.usernameRequired
      ? {
          username: Yup.string()
            .label(
              t("Username", {
                ns: "PurchaseOrders",
              })
            )
            .required(),
        }
      : undefined),
    ...(openMasterdataInfo.passwordRequired
      ? {
          password: Yup.string()
            .label(
              t("Password", {
                ns: "PurchaseOrders",
              })
            )
            .required(),
        }
      : undefined),
  });

  const formId = React.useMemo(() => uuid.v4(), []);

  const handleSubmit = async ({ username, clientId, password }: FormValues) => {
    try {
      const result = await openMasterAuthenticate({
        variables: {
          input: {
            msOrganisationId: systemSupplierOrganisationId,
            clientId,
            username,
            password,
          },
        },
      });
      if (result.data?.openMasterdataAuthenticate.ok) {
        setOpenMasterdataLogin({ username, clientId });
        await handleComplete(handleClose);
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {formikProps => (
        <Modal
          dialogProps={{ maxWidth: "sm" }}
          title={t("Sync via open masterdata", {
            ns: "Product",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Submit", {
                ns: "Global",
              }),
              buttonProps: {
                disabled: !formikProps.dirty || !formikProps.isValid,
                loading: formikProps.isSubmitting,
                endIcon: <ArrowForwardIcon />,
                type: "submit",
                form: formId,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              {openMasterdataInfo.clientIdRequired && (
                <Field
                  component={TextField}
                  label={t("Client id", {
                    ns: "PurchaseOrders",
                  })}
                  name="clientId"
                  required
                />
              )}
              {openMasterdataInfo.usernameRequired && (
                <Field
                  component={TextField}
                  label={t("Username", {
                    ns: "PurchaseOrders",
                  })}
                  name="username"
                  required
                />
              )}
              {openMasterdataInfo.passwordRequired && (
                <PasswordField
                  name="password"
                  label={t("Password", {
                    ns: "PurchaseOrders",
                  })}
                />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
