import { Navigate, Route, Routes } from "react-router-dom";
import { PageNotFound } from "../../commons/PageNotFound";
import { assertNever } from "../../utils";
import { GlobalManufacturerOrganisations } from "./GlobalManufacturerOrganisations";
import { GlobalOrganisationProfile } from "./GlobalOrganisationProfile";
import { GlobalSupplierOrganisations } from "./GlobalSupplierOrganisations";

export const GlobalOrganisationsRoutes = ({
  path,
}: {
  path: "globalsuppliers" | "globalmanufacturers";
}) => {
  return (
    <Routes>
      <Route
        index
        element={
          path === "globalsuppliers" ? (
            <GlobalSupplierOrganisations />
          ) : path === "globalmanufacturers" ? (
            <GlobalManufacturerOrganisations />
          ) : (
            assertNever(path)
          )
        }
      />

      <Route
        path={":organisationId"}
        element={<Navigate to="profile" replace />}
      />
      <Route
        path=":organisationId/profile"
        element={<GlobalOrganisationProfile />}
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
