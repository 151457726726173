/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type RequestRequestorMenuFragment = { __typename: 'Request', id: string, status: Types.RequestStatus, viewerNumActiveQuotes: number };

export const RequestRequestorMenuFragmentDoc = gql`
    fragment RequestRequestorMenu on Request {
  id
  status
  viewerNumActiveQuotes
}
    `;