import { useApolloClient } from "@apollo/client";
import {
  DataGrid,
  getFormattedDate,
  GridColDef,
  Modal,
  useDataGridExpandedStore,
} from "@msys/ui";
import { Box, Stack, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useTolgee, useTranslate } from "@tolgee/react";
import React from "react";
import { useDataGridStateStore } from "../users/useDataGridStateStore";
import {
  SapDocumentInfoFragment,
  useSapSalesQuotationDocumentFlowQuery,
} from "./S4HanaDocumentFlowModal.generated";

interface Props {
  projectId: string;
  docId: string;
  salesQuotationId: string;
  handleClose: () => void;
}

type DocumentFlowType = {
  creationDate: string;
  documentId: string;
  documentName: string;
  status: string;
};

type DocumentFlowTypeWithPath = DocumentFlowType & { documentPath: [string] };

const toDocumentFlow = (doc: SapDocumentInfoFragment): DocumentFlowType => ({
  creationDate: doc.creationDate,
  documentId: doc.documentId,
  documentName: `${doc.orderType} ${doc.documentId}`,
  status: doc.status,
});

export const S4HanaDocumentFlowModal = ({
  salesQuotationId,
  handleClose,
}: Props) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const client = useApolloClient();
  const query = useSapSalesQuotationDocumentFlowQuery({
    client,
    variables: {
      salesQuotationId,
    },
  });

  const items = React.useMemo(() => {
    const flow =
      query.data?.sapSalesQuotationDocumentFlow?.salesQuotationDocumentFlow
        ?.documentRelationships ?? [];

    const items: DocumentFlowTypeWithPath[] = [];
    const itemsById: Record<string, DocumentFlowTypeWithPath> = {};

    flow.forEach(item => {
      if (item.fromDocument) {
        let parentFromDocumentPath: string[] = [];

        if (itemsById[item.fromDocument.documentId]) {
          // parent exists
          parentFromDocumentPath =
            itemsById[item.fromDocument.documentId].documentPath;
        } else {
          // no parent exists yet = need to create
          const _document = toDocumentFlow(item.fromDocument);
          const document: DocumentFlowTypeWithPath = {
            ..._document,
            documentPath: [_document.documentName],
          };
          items.push(document);
          itemsById[document.documentId] = document;
          parentFromDocumentPath = document.documentPath;
        }

        if (!itemsById[item.toDocument.documentId]) {
          // to document doesn't exist yet - need to create
          const _document = toDocumentFlow(item.toDocument);
          const document: DocumentFlowTypeWithPath = {
            ..._document,
            // @ts-ignore
            documentPath: [...parentFromDocumentPath, _document.documentName],
          };
          items.push(document);
          itemsById[document.documentId] = document;
        }
      }
    });

    return items;
  }, [
    query.data?.sapSalesQuotationDocumentFlow?.salesQuotationDocumentFlow
      ?.documentRelationships,
  ]);

  return (
    <Modal
      title={t("Document flow", { ns: "SapS4Hana" })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Close", { ns: "Global" }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
          },
        },
      ]}
      maxWidth="lg"
      isLoading={query.loading}
      contentProps={{ sx: { display: "flex" } }}
    >
      <Stack direction="column" spacing={1} flex={1} overflow="auto">
        {items.length > 0 ? (
          <S4HanaDocumentFlowDataTree
            salesQuotationId={salesQuotationId}
            items={items}
          />
        ) : (
          <Box p={1} py={3}>
            <Typography align="center" color="grey.500" variant="body2">
              {t("There are no items to display", { ns: "Global" })}
            </Typography>
          </Box>
        )}
      </Stack>
    </Modal>
  );
};

const getTreeDataPath = (row: DocumentFlowTypeWithPath) => row.documentPath;
const getRowId = (row: DocumentFlowTypeWithPath) => row.documentId;

function S4HanaDocumentFlowDataTree({
  salesQuotationId,
  items,
}: {
  salesQuotationId: string;
  items: DocumentFlowTypeWithPath[];
}) {
  const language = useTolgee(["language"]).getLanguage()!;
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const columns = React.useMemo(
    (): GridColDef<DocumentFlowTypeWithPath>[] => [
      {
        field: "creationDate",
        headerName: t("Date", { ns: "Global" }),
        sortable: false,
        groupable: false,
        minWidth: 100,
        flex: 1,
        valueFormatter({ value }) {
          return value ? getFormattedDate(value, language) : "-";
        },
      },
      {
        field: "status",
        headerName: t("Status", { ns: "Global" }),
        sortable: false,
        groupable: false,
        minWidth: 100,
        flex: 1,
      },
    ],
    [language, t]
  );

  const apiRef = useGridApiRef();
  const { isGroupExpanded } = useDataGridExpandedStore(
    apiRef,
    `S4HanaDocumentFlowModal-${salesQuotationId}`
  );

  const stateStore = useDataGridStateStore("S4HanaDocumentFlowModal");

  return (
    <DataGrid
      apiRef={apiRef}
      density="compact"
      rows={items}
      columns={columns}
      treeData
      getTreeDataPath={getTreeDataPath}
      groupingColDef={
        {
          headerName: t("Title", { ns: "Global" }),
          flex: 3,
          minWidth: 200,
          hideable: false,
        } as GridColDef<DocumentFlowTypeWithPath>
      }
      getRowId={getRowId}
      stateStore={stateStore}
      pagination={false}
      hideFooter={true}
      isGroupExpandedByDefault={isGroupExpanded}
    />
  );
}
