import { Capabilities } from "@msys/common";
import { Badge, ButtonBase, Tab, Tabs } from "@mui/material";
import React from "react";
import { Link, To } from "react-router-dom";
import { PermissionName } from "../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import {
  Project,
  RestrictedByProjectPermissionWithDebug,
} from "../../auth/RestrictedByProjectPermission";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";

export interface PageTopbarItem {
  name: string;
  icon: React.ReactNode;
  label: string;
  path: string;
  activePaths?: string[];
  isInMoreMenu?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  float?: "left" | "right";
  bubbleAmount?: number;
  capability?: Capabilities;
  permission?: PermissionName;
  projectPermission?: { name: PermissionName; project: Project };
}

export const PageSubmenu = ({
  items,
  activeItem,
}: {
  items: PageTopbarItem[];
  activeItem?: PageTopbarItem | false;
}) => {
  if (items.length === 0) return <div />;
  return (
    <Tabs
      value={!!activeItem && (activeItem?.path ?? false)}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      sx={theme => ({
        minHeight: "unset",
        [".MuiTab-root"]: {
          minHeight: "unset",
          minWidth: "unset",
          padding: "6px 12px",
          borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
          ["&:hover:not(.Mui-selected)"]: {
            backgroundColor: theme.palette.action.hover,
          },
          ["&:active:not(.Mui-selected)"]: {
            backgroundColor: theme.palette.action.selected,
          },
          ["&.Mui-selected"]: {
            color: theme.palette.primary.main,
          },
          [".MuiBadge-root"]: {
            mr: 0.5,
          },
          [".MuiSvgIcon-root"]: {
            fontSize: "1.25rem",
          },
        },
      })}
    >
      {items.map((item, index) => (
        <Tab
          key={`${item.name}-${item.label}`}
          component={TabButtonLinkWithRestriction}
          to={item.path}
          capability={item.capability}
          permission={item.permission}
          projectPermission={item.projectPermission}
          index={index}
          label={item.label}
          icon={
            <Badge badgeContent={item.bubbleAmount} color="secondary">
              {item.icon}
            </Badge>
          }
          iconPosition="start"
          value={item.path}
          disabled={item.disabled ?? false}
        />
      ))}
    </Tabs>
  );
};

const TabButtonLinkWithRestriction = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<{
    capability?: Capabilities;
    permission?: PermissionName;
    projectPermission?: { name: PermissionName; project: Project };
    index?: number;
    to: To;
  }>
>(
  (
    {
      capability,
      permission,
      projectPermission,
      index,
      children,
      to,
      ...props
    },
    forwardedRef
  ) => {
    let button = (
      <ButtonBase ref={forwardedRef} {...props} component={Link} to={to}>
        {children}
      </ButtonBase>
    );

    if (projectPermission) {
      button = (
        <RestrictedByProjectPermissionWithDebug
          permission={projectPermission.name}
          project={projectPermission.project}
          key={`restricted-by-project-permission-${index}`}
        >
          {button}
        </RestrictedByProjectPermissionWithDebug>
      );
    }
    if (permission) {
      button = (
        <RestrictedByOrganisationPermissionWithDebug
          permission={permission}
          key={`restricted-by-permission-${index}`}
        >
          {button}
        </RestrictedByOrganisationPermissionWithDebug>
      );
    }
    if (capability) {
      button = (
        <RestrictedByCapabilityWithDebug
          capability={capability}
          key={`restricted-by-capability-${index}`}
        >
          {button}
        </RestrictedByCapabilityWithDebug>
      );
    }
    return button;
  }
);
