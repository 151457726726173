import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useOutdatedAppCheck } from "./OutdatedAppVersionDialog";
import { SplashScreen } from "../../common/SplashScreen";

interface Props {}

// depends on SnackbarProvider & TranslationProvider, and must be before AuthProvider!
export const OutdatedAppVersionProvider = ({
  children,
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslate(["OutdatedDialog"]);
  const { isChecked, isChecking, repeatInitialUpdate } = useOutdatedAppCheck();

  // app new version initial check is in progress (should be blocking)
  if (!isChecked)
    return (
      <SplashScreen
        showLogo
        message={
          isChecking
            ? t("Checking for the new version, please wait...", {
                ns: "OutdatedDialog",
              })
            : t("Check for the new version finished", {
                ns: "OutdatedDialog",
              })
        }
        isLoading={isChecking}
        button={
          !isChecking ? (
            <Button
              variant="contained"
              color="primary"
              onClick={repeatInitialUpdate}
            >
              {t("Try again", {
                ns: "OutdatedDialog",
              })}
            </Button>
          ) : undefined
        }
      />
    );

  return <>{children}</>;
};
