/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleListsListQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  filterApplied?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterSearchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ArticleListsListQuery = { searchQuoteProductsDistributions: { __typename: 'SearchQuoteProductsDistributionsResult', totalCount: number, quoteProductsDistributions: Array<{ __typename: 'QuoteProductsDistribution', id: string, createdAt: any, clientEmail?: any | null, quoteProductsDistributionProducts: Array<{ __typename: 'QuoteProductsDistributionProductRef', id: string, productArticleNumber: string }>, applications: Array<{ __typename: 'QuoteProductsDistributionApplication', id: string, docId: string, project?: { __typename: 'Project', id: string, title: string } | null }> }> } };

export type ArticleListsList_QuoteProductsDistributionFragment = { __typename: 'QuoteProductsDistribution', id: string, createdAt: any, clientEmail?: any | null, quoteProductsDistributionProducts: Array<{ __typename: 'QuoteProductsDistributionProductRef', id: string, productArticleNumber: string }>, applications: Array<{ __typename: 'QuoteProductsDistributionApplication', id: string, docId: string, project?: { __typename: 'Project', id: string, title: string } | null }> };

export type ArticleLists_CreateQuoteProductsDistributionMutationVariables = Types.Exact<{
  input: Types.CreateQuoteProductsDistributionInput;
}>;


export type ArticleLists_CreateQuoteProductsDistributionMutation = { createQuoteProductsDistribution: { __typename: 'CreateQuoteProductsDistributionResult', quoteProductsDistribution: { __typename: 'QuoteProductsDistribution', id: string } } };

export const ArticleListsList_QuoteProductsDistributionFragmentDoc = gql`
    fragment ArticleListsList_QuoteProductsDistribution on QuoteProductsDistribution {
  id
  createdAt
  clientEmail
  quoteProductsDistributionProducts {
    id
    productArticleNumber
  }
  applications {
    id
    docId
    project {
      id
      title
    }
  }
}
    `;
export const ArticleListsListDocument = gql`
    query ArticleListsList($offset: Int, $limit: Int!, $filterApplied: Boolean, $filterSearchTerm: String) {
  searchQuoteProductsDistributions(
    offset: $offset
    limit: $limit
    filters: {applied: $filterApplied}
    search: $filterSearchTerm
  ) {
    quoteProductsDistributions {
      id
      ...ArticleListsList_QuoteProductsDistribution
    }
    totalCount
  }
}
    ${ArticleListsList_QuoteProductsDistributionFragmentDoc}`;

/**
 * __useArticleListsListQuery__
 *
 * To run a query within a React component, call `useArticleListsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleListsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleListsListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filterApplied: // value for 'filterApplied'
 *      filterSearchTerm: // value for 'filterSearchTerm'
 *   },
 * });
 */
export function useArticleListsListQuery(baseOptions: Apollo.QueryHookOptions<ArticleListsListQuery, ArticleListsListQueryVariables> & ({ variables: ArticleListsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleListsListQuery, ArticleListsListQueryVariables>(ArticleListsListDocument, options);
      }
export function useArticleListsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleListsListQuery, ArticleListsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleListsListQuery, ArticleListsListQueryVariables>(ArticleListsListDocument, options);
        }
export function useArticleListsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArticleListsListQuery, ArticleListsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleListsListQuery, ArticleListsListQueryVariables>(ArticleListsListDocument, options);
        }
export type ArticleListsListQueryHookResult = ReturnType<typeof useArticleListsListQuery>;
export type ArticleListsListLazyQueryHookResult = ReturnType<typeof useArticleListsListLazyQuery>;
export type ArticleListsListSuspenseQueryHookResult = ReturnType<typeof useArticleListsListSuspenseQuery>;
export type ArticleListsListQueryResult = Apollo.QueryResult<ArticleListsListQuery, ArticleListsListQueryVariables>;
export const ArticleLists_CreateQuoteProductsDistributionDocument = gql`
    mutation ArticleLists_CreateQuoteProductsDistribution($input: CreateQuoteProductsDistributionInput!) {
  createQuoteProductsDistribution(input: $input) {
    quoteProductsDistribution {
      id
    }
  }
}
    `;
export type ArticleLists_CreateQuoteProductsDistributionMutationFn = Apollo.MutationFunction<ArticleLists_CreateQuoteProductsDistributionMutation, ArticleLists_CreateQuoteProductsDistributionMutationVariables>;

/**
 * __useArticleLists_CreateQuoteProductsDistributionMutation__
 *
 * To run a mutation, you first call `useArticleLists_CreateQuoteProductsDistributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleLists_CreateQuoteProductsDistributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleListsCreateQuoteProductsDistributionMutation, { data, loading, error }] = useArticleLists_CreateQuoteProductsDistributionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleLists_CreateQuoteProductsDistributionMutation(baseOptions?: Apollo.MutationHookOptions<ArticleLists_CreateQuoteProductsDistributionMutation, ArticleLists_CreateQuoteProductsDistributionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleLists_CreateQuoteProductsDistributionMutation, ArticleLists_CreateQuoteProductsDistributionMutationVariables>(ArticleLists_CreateQuoteProductsDistributionDocument, options);
      }
export type ArticleLists_CreateQuoteProductsDistributionMutationHookResult = ReturnType<typeof useArticleLists_CreateQuoteProductsDistributionMutation>;
export type ArticleLists_CreateQuoteProductsDistributionMutationResult = Apollo.MutationResult<ArticleLists_CreateQuoteProductsDistributionMutation>;
export type ArticleLists_CreateQuoteProductsDistributionMutationOptions = Apollo.BaseMutationOptions<ArticleLists_CreateQuoteProductsDistributionMutation, ArticleLists_CreateQuoteProductsDistributionMutationVariables>;