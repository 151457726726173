import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Content } from "../../../../clients/graphqlTypes";
import { ContentEditModal } from "../../contents/ContentEditModal";

export const AddContentMenuItem = ({
  addArticle,
}: {
  addArticle: (newArticle: Content) => Promise<void>;
}) => {
  const { t } = useTranslate("QuoteItem");

  return (
    <ModalOpenButton
      Modal={ContentEditModal}
      modalProps={{
        handleComplete: async newArticle => {
          await addArticle(newArticle);
        },
      }}
    >
      <MenuItemWithIcon icon={<PostAddOutlinedIcon />}>
        {t("Add content")}
      </MenuItemWithIcon>
    </ModalOpenButton>
  );
};
