import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { MenuButton, MenuItemWithIcon } from "@msys/ui";
import DownloadIcon from "@mui/icons-material/GetApp";
import { CircularProgress, Divider } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useOpenFile } from "../../../commons/hooks/useOpenFile";
import {
  DownloadQuoteAsPdfButton_QuoteFragment,
  GenerateQuoteContractingPdfDocument,
  GenerateQuoteContractingPdfQuery,
  GenerateQuoteContractingPdfQueryVariables,
  GenerateQuotePdfDocument,
  GenerateQuotePdfQuery,
  GenerateQuotePdfQueryVariables,
  useDownloadQuoteAsPdfButtonQuery,
} from "./DownloadQuoteAsPdfIconButton.generated";

export const DownloadQuoteAsPdfIconButton = ({
  docId,
  projectId,
  doc,
}: {
  docId: string;
  projectId: string;
  doc: DownloadQuoteAsPdfButton_QuoteFragment;
}) => {
  const { t } = useTranslate("Quote");
  const { enqueueSnackbar } = useSnackbar();
  const [loadingPdf, setLoadingPdf] = React.useState<null | string>(null);
  const { openPdf } = useOpenFile();

  const client = useApolloClient();
  const query = useDownloadQuoteAsPdfButtonQuery({
    client,
  });

  const handleClick =
    (
      templateType: "none" | "contracting",
      calculationType: "agreed" | "proposed",
      contractingPdfId: string | null
    ) =>
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (loadingPdf) return;

      const key = `${templateType}-${calculationType}${
        contractingPdfId ? `-${contractingPdfId}` : ""
      }`;

      setLoadingPdf(key);

      try {
        if (templateType === "none") {
          const { data } = await client.query<
            GenerateQuotePdfQuery,
            GenerateQuotePdfQueryVariables
          >({
            query: GenerateQuotePdfDocument,
            variables: {
              projectId,
              quoteId: docId,
              calculationType,
            },
          });
          const file = getDataOrNull(data?.quoteGeneratePdf)?.file;
          if (!file) throw new Error("Failed to generate PDF");
          openPdf(file.url, `Quote_${docId}`);
        } else if (templateType === "contracting" && contractingPdfId) {
          const { data } = await client.query<
            GenerateQuoteContractingPdfQuery,
            GenerateQuoteContractingPdfQueryVariables
          >({
            query: GenerateQuoteContractingPdfDocument,
            variables: {
              projectId,
              quoteId: docId,
              contractingPdfId,
              calculationType,
            },
          });
          const file = getDataOrNull(data?.quoteGenerateContractingPdf)?.file;
          if (!file) throw new Error("Failed to generate PDF");
          openPdf(file.url, `Quote_${docId}`);
        }
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingPdf(null);
      }
    };

  const contractingPdfs =
    getDataOrNull(query.data?.organisationContractingPdfs)?.contractingPdfs ??
    [];
  const hasSelectedCalculations = doc.agreedCalculation;
  const hasContractingTemplates = contractingPdfs.length > 0;

  return (
    <MenuButton
      Icon={
        loadingPdf ? (
          <CircularProgress size="1.5rem" color={"primary"} />
        ) : (
          <DownloadIcon />
        )
      }
      tooltip={t("Download PDF")}
    >
      {hasSelectedCalculations && (
        <MenuItemWithIcon
          icon={
            loadingPdf === "none-agreed" ? (
              <CircularProgress size="1.5rem" color={"primary"} />
            ) : (
              <DownloadIcon />
            )
          }
          onClick={handleClick("none", "agreed", null)}
          disabled={loadingPdf === "none-agreed"}
        >
          {t("Download PDF (agreed)")}
        </MenuItemWithIcon>
      )}
      <MenuItemWithIcon
        icon={
          loadingPdf === "none-proposed" ? (
            <CircularProgress size={24} color={"primary"} />
          ) : (
            <DownloadIcon />
          )
        }
        onClick={handleClick("none", "proposed", null)}
        disabled={loadingPdf === "none-proposed"}
      >
        {hasSelectedCalculations
          ? t("Download PDF (proposed)")
          : t("Download PDF")}
      </MenuItemWithIcon>
      {hasContractingTemplates && [
        <Divider key="divider" />,
        ...contractingPdfs.flatMap(contractingPdf =>
          [
            hasSelectedCalculations && (
              <MenuItemWithIcon
                key={`${contractingPdf.id}-agreed`}
                icon={
                  loadingPdf === `contracting-agreed-${contractingPdf.id}` ? (
                    <CircularProgress size="1.5rem" color={"primary"} />
                  ) : (
                    <DownloadIcon />
                  )
                }
                onClick={handleClick(
                  "contracting",
                  "agreed",
                  contractingPdf.id
                )}
                disabled={
                  loadingPdf === `contracting-agreed-${contractingPdf.id}`
                }
              >
                {t("Download PDF: {title} (agreed)", {
                  title: contractingPdf.title,
                })}
              </MenuItemWithIcon>
            ),
            <MenuItemWithIcon
              key={`${contractingPdf.id}-propsed`}
              icon={
                loadingPdf === `contracting-proposed-${contractingPdf.id}` ? (
                  <CircularProgress size={24} color={"primary"} />
                ) : (
                  <DownloadIcon />
                )
              }
              onClick={handleClick(
                "contracting",
                "proposed",
                contractingPdf.id
              )}
              disabled={
                loadingPdf === `contracting-proposed-${contractingPdf.id}`
              }
            >
              {hasSelectedCalculations
                ? t("Download PDF: {title} (proposed)", {
                    title: contractingPdf.title,
                  })
                : t("Download PDF: {title}", {
                    title: contractingPdf.title,
                  })}
            </MenuItemWithIcon>,
          ].filter(value => value)
        ),
      ]}
    </MenuButton>
  );
};
