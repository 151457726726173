/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectFromGaebImportMutationVariables = Types.Exact<{
  input: Types.CreateProjectFromGaebImportInput;
}>;


export type CreateProjectFromGaebImportMutation = { createProjectFromGaebImport: { __typename: 'CreateProjectFromGaebImportResult', project: { __typename: 'Project', id: string } } };

export type CreateQuoteFromGaebImportMutationVariables = Types.Exact<{
  input: Types.CreateQuoteFromGaebImportInput;
}>;


export type CreateQuoteFromGaebImportMutation = { createQuoteFromGaebImport: { __typename: 'CreateQuoteFromGaebImportResult', doc: { __typename: 'Quote', id: string } } };


export const CreateProjectFromGaebImportDocument = gql`
    mutation CreateProjectFromGaebImport($input: CreateProjectFromGaebImportInput!) {
  createProjectFromGaebImport(input: $input) {
    project {
      id
    }
  }
}
    `;
export type CreateProjectFromGaebImportMutationFn = Apollo.MutationFunction<CreateProjectFromGaebImportMutation, CreateProjectFromGaebImportMutationVariables>;

/**
 * __useCreateProjectFromGaebImportMutation__
 *
 * To run a mutation, you first call `useCreateProjectFromGaebImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFromGaebImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFromGaebImportMutation, { data, loading, error }] = useCreateProjectFromGaebImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectFromGaebImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectFromGaebImportMutation, CreateProjectFromGaebImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectFromGaebImportMutation, CreateProjectFromGaebImportMutationVariables>(CreateProjectFromGaebImportDocument, options);
      }
export type CreateProjectFromGaebImportMutationHookResult = ReturnType<typeof useCreateProjectFromGaebImportMutation>;
export type CreateProjectFromGaebImportMutationResult = Apollo.MutationResult<CreateProjectFromGaebImportMutation>;
export type CreateProjectFromGaebImportMutationOptions = Apollo.BaseMutationOptions<CreateProjectFromGaebImportMutation, CreateProjectFromGaebImportMutationVariables>;
export const CreateQuoteFromGaebImportDocument = gql`
    mutation CreateQuoteFromGaebImport($input: CreateQuoteFromGaebImportInput!) {
  createQuoteFromGaebImport(input: $input) {
    doc {
      id
    }
  }
}
    `;
export type CreateQuoteFromGaebImportMutationFn = Apollo.MutationFunction<CreateQuoteFromGaebImportMutation, CreateQuoteFromGaebImportMutationVariables>;

/**
 * __useCreateQuoteFromGaebImportMutation__
 *
 * To run a mutation, you first call `useCreateQuoteFromGaebImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteFromGaebImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteFromGaebImportMutation, { data, loading, error }] = useCreateQuoteFromGaebImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteFromGaebImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteFromGaebImportMutation, CreateQuoteFromGaebImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteFromGaebImportMutation, CreateQuoteFromGaebImportMutationVariables>(CreateQuoteFromGaebImportDocument, options);
      }
export type CreateQuoteFromGaebImportMutationHookResult = ReturnType<typeof useCreateQuoteFromGaebImportMutation>;
export type CreateQuoteFromGaebImportMutationResult = Apollo.MutationResult<CreateQuoteFromGaebImportMutation>;
export type CreateQuoteFromGaebImportMutationOptions = Apollo.BaseMutationOptions<CreateQuoteFromGaebImportMutation, CreateQuoteFromGaebImportMutationVariables>;