import { VisibilityButton, ThreeDViewModal } from "@msys/ui";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { IconButton, ListItemSecondaryAction, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { Suspense, useState } from "react";
import { Attachment } from "./helpers";
import { ViewMode } from "../../commons/ViewModeMenuItem";
import { ItemAttachment } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { FileRow } from "./FileRow";

const ViewPdfModal = React.lazy(
  () => import("../../commons/modals/ViewPdfModal")
);

interface Props {
  file: Attachment | ItemAttachment;
  onDeleteFile?: (attachmentId: string) => Promise<void> | void;
  defaultPlace?: string;
  defaultDate?: any;
  canSign?: boolean;
  onStart?: () => void;
  onFinish?: () => void;
  onSignedPdfAttachment?: (attachment: Attachment) => Promise<void> | void;
  viewMode: ViewMode;
  setClientVisibility?: (
    attachmentId: string,
    isVisible: boolean
  ) => Promise<void> | void;
  renderSignIconButton?: (button: React.ReactNode) => React.ReactNode;
  renderDeleteIconButton?: (button: React.ReactNode) => React.ReactNode;
  renderChangeVisibilityIconButton?: (
    button: React.ReactNode
  ) => React.ReactNode;
}

const defaultRenderButton = (button: React.ReactNode) => button;

export const FileBoxRow = ({
  file,
  onDeleteFile,
  defaultPlace,
  defaultDate,
  canSign = false,
  onStart,
  onFinish,
  onSignedPdfAttachment,
  viewMode,
  setClientVisibility,
  renderSignIconButton = defaultRenderButton,
  renderDeleteIconButton = defaultRenderButton,
  renderChangeVisibilityIconButton = defaultRenderButton,
}: Props) => {
  const { t } = useTranslate(["FilesBoxTable", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSignOpen, setSignOpen] = useState(false);
  const [isThreeDOpen, setThreeDOpen] = useState(false);

  const handleDeleteFile = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    try {
      setIsDeleting(true);
      await onDeleteFile?.(file.id);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSetClientVisibility = async (isVisible: boolean) => {
    try {
      await setClientVisibility?.(file.id, isVisible);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const isPdf = file.mimeType === "application/pdf";
  const isThreeDModel = [".gltf", ".glb"].some(ext => file.title.endsWith(ext));

  return (
    <>
      <FileRow
        file={file}
        button
        // divider={divider}
        Action={
          viewMode === "delete" ? (
            renderDeleteIconButton(
              <Tooltip title={t("Delete File", { ns: "FilesBoxTable" })}>
                <IconButton
                  aria-label={t("Delete File", { ns: "FilesBoxTable" })}
                  color="primary"
                  onClick={handleDeleteFile}
                  size="small"
                  disabled={isDeleting}
                >
                  <DeleteIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            )
          ) : viewMode === "visibility" && "clientVisibility" in file ? (
            renderChangeVisibilityIconButton(
              <Tooltip
                title={t("Set client visibility", { ns: "FilesBoxTable" })}
              >
                <VisibilityButton
                  aria-label={t("Set client visibility", {
                    ns: "FilesBoxTable",
                  })}
                  fontSize={"small"}
                  isVisible={file.clientVisibility}
                  onChange={handleSetClientVisibility}
                  disabled={!setClientVisibility}
                />
              </Tooltip>
            )
          ) : isPdf && canSign ? (
            renderSignIconButton(
              <Tooltip title={t("Sign PDF", { ns: "FilesBoxTable" })}>
                <IconButton
                  aria-label={t("Sign PDF", { ns: "FilesBoxTable" })}
                  color="primary"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.stopPropagation();
                    setSignOpen(true);
                  }}
                  size="small"
                >
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>
            )
          ) : isThreeDModel ? (
            <Tooltip title={t("View in 3D", { ns: "FilesBoxTable" })}>
              <IconButton
                aria-label={t("View in 3D", { ns: "FilesBoxTable" })}
                color="primary"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  setThreeDOpen(true);
                }}
                size="small"
              >
                <ViewInArIcon />
              </IconButton>
            </Tooltip>
          ) : undefined
        }
      />
      {isSignOpen && (
        <Suspense fallback={null}>
          <ViewPdfModal
            handleClose={() => setSignOpen(false)}
            handleComplete={() => setSignOpen(false)}
            file={file}
            defaultPlace={defaultPlace}
            defaultDate={defaultDate}
            onStart={onStart}
            onFinish={onFinish}
            onAttachment={onSignedPdfAttachment}
          />
        </Suspense>
      )}
      {isThreeDOpen && (
        <ThreeDViewModal
          handleClose={() => setThreeDOpen(false)}
          modelUrl={file.url}
          arButtonTitle={t("View in your space", { ns: "Global" })}
        />
      )}
    </>
  );
};
