import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { DataGrid, ListHeader } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { namedOperations } from "../../../clients/graphqlTypes";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { QuoteItemRow } from "../../features/quotes/QuoteItemRow";
import { RequestStatusBadge } from "../../features/requests/RequestStatusBadge";
import { RequestCreateButton } from "../../features/requests/buttons/RequestCreateButton";
import { RequestQuoteForOutsourcingButton } from "../../features/requests/buttons/RequestQuoteForOutsourcingButton";
import { RequestQuoteForRequirementButton } from "../../features/requests/buttons/RequestQuoteForRequirementButton";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  ProjectRequest__RequestFragment,
  useProjectRequestsQuery,
} from "./ProjectRequests.generated";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectRequests = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const navigate = useNavigate();

  const { t } = useTranslate(["Global", "Requests", "ProjectOverview"]);

  const client = useApolloClient();
  const query = useProjectRequestsQuery({
    client,
    variables: { projectId },
  });

  const project = getDataOrNull(query.data?.project)?.project;

  const requests = project?.outgoingQuoteRequests ?? [];
  const ownRequirementsCount =
    getDataOrNull(query.data?.projectRequirements)?.totalCount ?? 0;
  const quotes =
    getDataOrNull(query.data?.projectOutgoingQuotes)?.edges?.map(e => e.node) ??
    [];

  const hasRequirementsForRequest = ownRequirementsCount > 0;
  const hasQuotesForRequest = quotes.length > 0;

  const breadcrumbs = useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Requests", {
          ns: "Requests",
        }),
        to: `/projects/${projectId}/requests`,
      },
    ],
    [prefixBreadcrumbs, projectId, t]
  );

  const stateStore = useDataGridStateStore("ProjectRequests");

  return (
    <Page
      subtitle={project?.title}
      title={t("Requests", {
        ns: "Requests",
      })}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      <PageContainer>
        <ListHeader
          // title={t("Requests", {
          //   ns: "Requests",
          // })}
          CreateButton={
            project ? (
              <RestrictedByCapabilityWithDebug
                capability="QUOTING"
                otherwise={
                  <RestrictedByProjectPermissionWithDebug
                    permission="SHARE_REQUIREMENTS"
                    project={project}
                  >
                    <RequestQuoteForRequirementButton
                      projectId={projectId}
                      refetchQueries={[namedOperations.Query.ProjectRequests]}
                      Button={
                        <RequestCreateButton
                          type="button-create"
                          disabled={!hasRequirementsForRequest}
                        />
                      }
                    />
                  </RestrictedByProjectPermissionWithDebug>
                }
              >
                <RestrictedByProjectPermissionWithDebug
                  permission="DECIDE_QUOTES"
                  project={project}
                >
                  <RequestQuoteForOutsourcingButton
                    projectId={projectId}
                    refetchQueries={[namedOperations.Query.ProjectRequests]}
                    Button={
                      <RequestCreateButton
                        type="button-create"
                        disabled={!hasQuotesForRequest}
                      />
                    }
                  />
                </RestrictedByProjectPermissionWithDebug>
              </RestrictedByCapabilityWithDebug>
            ) : undefined
          }
          mb={2}
        />
        <DataGrid
          stateStore={stateStore}
          loading={query.loading}
          hideFooter={true}
          disableColumnFilter
          columns={[
            {
              field: "title",
              headerName: t("Quote request title", {
                ns: "Requests",
              }),
              flex: 2,
              minWidth: 100,
              sortable: false,
              renderCell: ({
                row: request,
              }: {
                row: ProjectRequest__RequestFragment;
              }) => request.title,
            },
            {
              field: "status",
              headerName: t("Status", {
                ns: "Requests",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({
                row: request,
              }: {
                row: ProjectRequest__RequestFragment;
              }) => <RequestStatusBadge request={request} />,
            },
            {
              field: "requrementDoc",
              headerName: t("Requirements list", {
                ns: "Requests",
              }),
              flex: 2,
              minWidth: 100,
              sortable: false,
              renderCell: ({
                row: request,
              }: {
                row: ProjectRequest__RequestFragment;
              }) => (
                <QuoteItemRow
                  title={request.requestorDoc.title}
                  to={`/projects/${projectId}/requirements/${request.requestorDoc.id}`}
                  variant="body2"
                />
              ),
            },
            {
              field: "quotes",
              headerName: "",
              flex: 2,
              minWidth: 100,
              sortable: false,
              renderCell: ({
                row: request,
              }: {
                row: ProjectRequest__RequestFragment;
              }) =>
                t(
                  "{count, plural, =0 {No submissions} one {One submission} other {# submissions}}",
                  {
                    ns: "ProjectOverview",
                    count: request.quotes.length,
                  }
                ),
            },
          ]}
          rows={requests}
          onRowClick={row =>
            navigate(`/projects/${projectId}/requests/${row.id}`)
          }
        />
      </PageContainer>
    </Page>
  );
};
