/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmSendEmailForm_AvailableTemplateFragment = { __typename: 'OrganisationEmailTemplate', id: string, description: string };

export type EmailTemplateValuesQueryVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
  crmCompanyId: Types.Scalars['ID']['input'];
  crmPersonId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  context?: Types.InputMaybe<Types.SendCrmEmailContextInput>;
}>;


export type EmailTemplateValuesQuery = { organisationEmailTemplateValuesWithReplacements: { __typename: 'OrganisationEmailTemplateValuesWithReplacementsResult', subject: string, content: string, attachments: Array<{ __typename: 'Attachment', id: string, url: string, title: string, mimeType: string, group: string }> } };

export type RequestCrmEmailAttachmentUploadUrlMutationVariables = Types.Exact<{
  input: Types.RequestCrmEmailAttachmentUploadUrlInput;
}>;


export type RequestCrmEmailAttachmentUploadUrlMutation = { requestCrmEmailAttachmentUploadUrl: { __typename: 'RequestUploadUrlResult', uploadUrl: string } };

export type CrmSendEmailMutationVariables = Types.Exact<{
  input: Types.SendCrmEmailInput;
}>;


export type CrmSendEmailMutation = { sendCrmEmail: boolean };

export type UseCrmSendEmailQueryVariables = Types.Exact<{
  emailTemplateContext?: Types.InputMaybe<Types.EmailTemplateContext>;
}>;


export type UseCrmSendEmailQuery = { organisationEmailTemplates: Array<{ __typename: 'OrganisationEmailTemplate', id: string, description: string }> };

export const CrmSendEmailForm_AvailableTemplateFragmentDoc = gql`
    fragment CrmSendEmailForm_AvailableTemplate on OrganisationEmailTemplate {
  id
  description
}
    `;
export const EmailTemplateValuesDocument = gql`
    query EmailTemplateValues($templateId: ID!, $crmCompanyId: ID!, $crmPersonId: ID, $context: SendCrmEmailContextInput) {
  organisationEmailTemplateValuesWithReplacements(
    templateId: $templateId
    crmCompanyId: $crmCompanyId
    crmPersonId: $crmPersonId
    context: $context
  ) {
    subject
    content
    attachments {
      id
      url
      title
      mimeType
      group
    }
  }
}
    `;

/**
 * __useEmailTemplateValuesQuery__
 *
 * To run a query within a React component, call `useEmailTemplateValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplateValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplateValuesQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      crmCompanyId: // value for 'crmCompanyId'
 *      crmPersonId: // value for 'crmPersonId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useEmailTemplateValuesQuery(baseOptions: Apollo.QueryHookOptions<EmailTemplateValuesQuery, EmailTemplateValuesQueryVariables> & ({ variables: EmailTemplateValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailTemplateValuesQuery, EmailTemplateValuesQueryVariables>(EmailTemplateValuesDocument, options);
      }
export function useEmailTemplateValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailTemplateValuesQuery, EmailTemplateValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailTemplateValuesQuery, EmailTemplateValuesQueryVariables>(EmailTemplateValuesDocument, options);
        }
export function useEmailTemplateValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmailTemplateValuesQuery, EmailTemplateValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailTemplateValuesQuery, EmailTemplateValuesQueryVariables>(EmailTemplateValuesDocument, options);
        }
export type EmailTemplateValuesQueryHookResult = ReturnType<typeof useEmailTemplateValuesQuery>;
export type EmailTemplateValuesLazyQueryHookResult = ReturnType<typeof useEmailTemplateValuesLazyQuery>;
export type EmailTemplateValuesSuspenseQueryHookResult = ReturnType<typeof useEmailTemplateValuesSuspenseQuery>;
export type EmailTemplateValuesQueryResult = Apollo.QueryResult<EmailTemplateValuesQuery, EmailTemplateValuesQueryVariables>;
export const RequestCrmEmailAttachmentUploadUrlDocument = gql`
    mutation RequestCrmEmailAttachmentUploadUrl($input: RequestCrmEmailAttachmentUploadUrlInput!) {
  requestCrmEmailAttachmentUploadUrl(input: $input) {
    uploadUrl
  }
}
    `;
export type RequestCrmEmailAttachmentUploadUrlMutationFn = Apollo.MutationFunction<RequestCrmEmailAttachmentUploadUrlMutation, RequestCrmEmailAttachmentUploadUrlMutationVariables>;

/**
 * __useRequestCrmEmailAttachmentUploadUrlMutation__
 *
 * To run a mutation, you first call `useRequestCrmEmailAttachmentUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCrmEmailAttachmentUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCrmEmailAttachmentUploadUrlMutation, { data, loading, error }] = useRequestCrmEmailAttachmentUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCrmEmailAttachmentUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<RequestCrmEmailAttachmentUploadUrlMutation, RequestCrmEmailAttachmentUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestCrmEmailAttachmentUploadUrlMutation, RequestCrmEmailAttachmentUploadUrlMutationVariables>(RequestCrmEmailAttachmentUploadUrlDocument, options);
      }
export type RequestCrmEmailAttachmentUploadUrlMutationHookResult = ReturnType<typeof useRequestCrmEmailAttachmentUploadUrlMutation>;
export type RequestCrmEmailAttachmentUploadUrlMutationResult = Apollo.MutationResult<RequestCrmEmailAttachmentUploadUrlMutation>;
export type RequestCrmEmailAttachmentUploadUrlMutationOptions = Apollo.BaseMutationOptions<RequestCrmEmailAttachmentUploadUrlMutation, RequestCrmEmailAttachmentUploadUrlMutationVariables>;
export const CrmSendEmailDocument = gql`
    mutation CrmSendEmail($input: SendCrmEmailInput!) {
  sendCrmEmail(input: $input)
}
    `;
export type CrmSendEmailMutationFn = Apollo.MutationFunction<CrmSendEmailMutation, CrmSendEmailMutationVariables>;

/**
 * __useCrmSendEmailMutation__
 *
 * To run a mutation, you first call `useCrmSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmSendEmailMutation, { data, loading, error }] = useCrmSendEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCrmSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<CrmSendEmailMutation, CrmSendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrmSendEmailMutation, CrmSendEmailMutationVariables>(CrmSendEmailDocument, options);
      }
export type CrmSendEmailMutationHookResult = ReturnType<typeof useCrmSendEmailMutation>;
export type CrmSendEmailMutationResult = Apollo.MutationResult<CrmSendEmailMutation>;
export type CrmSendEmailMutationOptions = Apollo.BaseMutationOptions<CrmSendEmailMutation, CrmSendEmailMutationVariables>;
export const UseCrmSendEmailDocument = gql`
    query UseCrmSendEmail($emailTemplateContext: EmailTemplateContext) {
  organisationEmailTemplates(forContext: $emailTemplateContext) {
    id
    description
  }
}
    `;

/**
 * __useUseCrmSendEmailQuery__
 *
 * To run a query within a React component, call `useUseCrmSendEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCrmSendEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCrmSendEmailQuery({
 *   variables: {
 *      emailTemplateContext: // value for 'emailTemplateContext'
 *   },
 * });
 */
export function useUseCrmSendEmailQuery(baseOptions?: Apollo.QueryHookOptions<UseCrmSendEmailQuery, UseCrmSendEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseCrmSendEmailQuery, UseCrmSendEmailQueryVariables>(UseCrmSendEmailDocument, options);
      }
export function useUseCrmSendEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseCrmSendEmailQuery, UseCrmSendEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseCrmSendEmailQuery, UseCrmSendEmailQueryVariables>(UseCrmSendEmailDocument, options);
        }
export function useUseCrmSendEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseCrmSendEmailQuery, UseCrmSendEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseCrmSendEmailQuery, UseCrmSendEmailQueryVariables>(UseCrmSendEmailDocument, options);
        }
export type UseCrmSendEmailQueryHookResult = ReturnType<typeof useUseCrmSendEmailQuery>;
export type UseCrmSendEmailLazyQueryHookResult = ReturnType<typeof useUseCrmSendEmailLazyQuery>;
export type UseCrmSendEmailSuspenseQueryHookResult = ReturnType<typeof useUseCrmSendEmailSuspenseQuery>;
export type UseCrmSendEmailQueryResult = Apollo.QueryResult<UseCrmSendEmailQuery, UseCrmSendEmailQueryVariables>;