import { notNull } from "@msys/common";
import {
  DebouncedSearchInput,
  ListHeader,
  MenuButton,
  ModalOpenButton,
  useScreenWidth,
} from "@msys/ui";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  OrganisationProjectsSorting,
  ProjectStateMachineStatus,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../auth/useUserData";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants";
import { ImportCustomGaebP84Button } from "../../features/importExport/gaebImport/ImportCustomGaebP84Button";
import { ImportXiopdButton } from "../../features/importExport/xiopdImport/ImportXiopdButton";
import { CreateOpportunityButton } from "../../features/opportunities/CreateOpportunityButton";
import {
  FilterRelevantToMe,
  OpportunitiesFilterModal,
} from "../../features/opportunities/OpportunitiesFilterModal";
import { OpportunitiesKanban } from "../../features/opportunities/OpportunitiesKanban";
import {
  DEFAULT_SORTING,
  Filters,
  OpportunitiesList,
} from "../../features/opportunities/OpportunitiesList";
import {
  OpportunitiesSettingsModal,
  defaultListItemDisplayConfig,
} from "../../features/opportunities/OpportunitiesSettingsModal";
import { OpportunityListItemDisplayConfig } from "../../features/opportunities/components/OpportunityListItem";
import { renderOpportunitiesFilterChips } from "../../features/opportunities/renderOpportunitiesFilterChips";
import { PROJECT_FILTER_MAP } from "../../features/projects/useProjectSources";
import { ImportS4HanaSalesQuotationButton } from "../../features/sap-s4-hana/ImportS4HanaSalesQuotationButton";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { useUserPreference } from "../../features/users/useUserPreference";
import { useVisibilityStore } from "../../features/users/useVisibilityStore";

type ImportVisibilityKeys = "gaeb" | "xiopd";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list", "kanban"];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function OpportunitiesCurrent({ submenuItems }: Props) {
  const { t } = useTranslate([
    "Opportunities",
    "Categories",
    "Global",
    "ProjectMembers",
  ]);
  const { isMinTablet } = useScreenWidth();

  const viewer = useUserData().currentUser!;

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "opportunities-current",
    "kanban",
    "list"
  );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const baseVariables = React.useMemo(
    () => ({
      offset,
      limit,
      sorting: DEFAULT_SORTING,
    }),
    [offset, limit]
  );

  const allowedStates: ProjectStateMachineStatus[] = React.useMemo(
    () => ["opportunity"],
    []
  );

  const variables = React.useMemo(
    () => ({
      ...baseVariables,
      ...filters,
      filterBySources: filters.sources
        ? filters.sources.map(s => PROJECT_FILTER_MAP[s]).flat(1)
        : undefined,
      filterIncludeState: filters.filterIncludeState?.length
        ? filters.filterIncludeState
        : allowedStates,
      sorting,
    }),
    [baseVariables, filters, sorting, allowedStates]
  );

  const stateStore = useDataGridStateStore("OpportunitiesCurrent");

  const importVisibilityStore = useVisibilityStore<ImportVisibilityKeys>(
    "OpportunitiesCurrent-Import"
  );
  const listItemDisplayConfigStore =
    useUserPreference<OpportunityListItemDisplayConfig>(
      `DisplayConfig-OpportunitiesCurrent`,
      defaultListItemDisplayConfig
    );

  const menuItems = !importVisibilityStore.loading
    ? [
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-gaeb"
        >
          {importVisibilityStore.value.gaeb !== false ? (
            <ImportCustomGaebP84Button
              projectParams={{ isTentative: true }}
              refetchQueries={[namedOperations.Query.OpportunitiesList]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-xiopd"
        >
          {importVisibilityStore.value.xiopd !== false ? (
            <ImportXiopdButton
              projectParams={{ isTentative: true }}
              refetchQueries={[namedOperations.Query.OpportunitiesList]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        viewer.organisation.enabledIntegrationIds.includes("sap_s4hana") ? (
          <RestrictedByOrganisationPermissionWithDebug
            permission="CREATE_PROJECT"
            key="import-s4-hana-salesquoation"
          >
            <ImportS4HanaSalesQuotationButton
              projectId={null}
              refetchQueries={[namedOperations.Query.OpportunitiesList]}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        ) : null,
      ].filter(notNull)
    : [];

  return (
    <Page
      subtitle={t("Opportunities", { ns: "Opportunities" })}
      title={t("Current", { ns: "Opportunities" })}
      submenuItems={submenuItems}
    >
      <PageContainer $fullHeight>
        <ListHeader
          // hideTitleOnMobile
          // title={title}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS.filter(view => {
                if (
                  viewer.organisation.isClientOrganisation &&
                  view === "kanban"
                )
                  return false;
                return true;
              })}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={OpportunitiesFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                allowedStates,
                showPhasesFilter: activeView !== "kanban",
                showSorting: activeView !== "kanban",
                showRelevantToMe: !isMinTablet,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterFields={
            isMinTablet ? (
              <FilterRelevantToMe filters={filters} setFilters={setFilters} />
            ) : undefined
          }
          ActionButtons={
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
              <ModalOpenButton
                Modal={OpportunitiesSettingsModal}
                modalProps={{
                  importVisibilityStore,
                  listItemDisplayConfigStore,
                  labels: {
                    xiopd: t("{fileFormat} files", {
                      ns: "Global",
                      fileFormat: "xi:opd",
                    }),
                    gaeb: t("{fileFormat} files", {
                      ns: "Global",
                      fileFormat: "GAEB",
                    }),
                  },
                }}
              >
                <IconButton size="small" color="primary">
                  <SettingsIcon />
                </IconButton>
              </ModalOpenButton>
            </RestrictedByOrganisationPermissionWithDebug>
          }
          MenuButton={
            menuItems.length > 0 ? (
              <MenuButton>{menuItems}</MenuButton>
            ) : undefined
          }
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
              <CreateOpportunityButton pageName="Opportunities" type="button" />
            </RestrictedByOrganisationPermissionWithDebug>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }))
              }
            />
          }
          FilterChips={renderOpportunitiesFilterChips({
            viewer,
            t,
            filters,
            setFilters,
            allowedStates,
            showRelevantToMe: !isMinTablet,
          })}
          mb={2}
        />
        {!listItemDisplayConfigStore.loading &&
          (activeView === "kanban" ? (
            <OpportunitiesKanban
              variables={variables}
              displayConfig={listItemDisplayConfigStore.value}
            />
          ) : (
            <OpportunitiesList
              sorting={sorting}
              setSorting={setSorting}
              activeView={activeView}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              showStatus={allowedStates.length > 1}
              showPhase={true}
              stateStore={stateStore}
              displayConfig={listItemDisplayConfigStore.value}
              variables={variables}
            />
          ))}
      </PageContainer>
    </Page>
  );
}
