import { rawTimeZones } from "@vvo/tzdb";
import { useFormikContext } from "formik";
import { AutocompleteField } from "./AutocompleteField";

const OPTIONS = rawTimeZones.map(zone => ({
  label: zone.rawFormat,
  value: zone.name,
}));

interface Props {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

export const TimeZoneField = ({ name, label, required, disabled }: Props) => {
  const { errors, touched, setFieldValue, values } =
    useFormikContext<Record<string, any>>();

  return (
    <AutocompleteField
      name={name}
      options={OPTIONS.map(option => option.value)}
      getOptionLabel={option => {
        return OPTIONS.find(o => o.value === option)?.label ?? "";
      }}
      isRequired={required}
      disabled={disabled}
      inputLabel={label}
    />
  );
};
