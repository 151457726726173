/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { VirtualItem__DocumentItemFragmentDoc } from '../../trees-virtual/types.generated';
export type BuildingTreeItemFragment = { __typename: 'Item', id: string, title: string, rank: number, level: number, parentId?: string | null, isRootItem: boolean, pathForPdf: string, pathSortable: string, isHidden: boolean, isPriceHidden: boolean, isParentDecisionPreselected: boolean, isParentDecisionNotPreselected: boolean, isParentContingencyNotPreselected: boolean, deletedAt?: any | null, type: Types.ItemType, binding: Types.ItemBinding, decisionIsContingentItem: boolean, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, childrenVisibility: Types.ItemChildrenVisibility, isItemEliminated: boolean, hasChildren: boolean, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export const BuildingTreeItemFragmentDoc = gql`
    fragment BuildingTreeItem on Item {
  id
  title
  ...VirtualItem__DocumentItem
}
    ${VirtualItem__DocumentItemFragmentDoc}`;