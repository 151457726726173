import { LinesWithBreaks } from "@msys/ui";

interface Props {
  address: {
    countryCode: string;
    postalCode: string;
    city: string;
    streetLines1: string;
  };
  title?: string;
  useCountry?: boolean;
  useStreet?: boolean;
}

export const AddressWithBreaks = ({
  address,
  useCountry = true,
  useStreet = true,
  title,
}: Props) => {
  const lines: string[] = [
    useStreet
      ? (title && title !== address.streetLines1.trim()
          ? `${title.trim()}: `
          : "") + address.streetLines1.trim()
      : null,
    `${address.postalCode} ${address.city}`.trim(),
    useCountry ? address.countryCode : null,
  ].filter(Boolean) as string[];

  return <LinesWithBreaks lines={lines} />;
};
