import { useApolloClient } from "@apollo/client";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { ButtonCreate } from "../../../commons/button/Button";
import { VAT } from "../../../constants";
import { useCreatePurchaseOrderMutation } from "./PurchaseOrderCreateButton.generated";

interface Props {
  projectId: string;
  handleOrderCreate: (orderId: string) => void;
  Button?: React.ReactElement;
}

export function PurchaseOrderCreateButton({
  projectId,
  handleOrderCreate,
  Button,
}: Props) {
  const { t } = useTranslate("PurchaseOrders");

  const client = useApolloClient();
  const [createPurchaseOrder, { loading }] = useCreatePurchaseOrderMutation({
    client,
    variables: {
      input: {
        projectId,
        vatRate: VAT,
      },
    },
    refetchQueries: [namedOperations.Query.PurchaseOrders],
    awaitRefetchQueries: true,
  });

  const handleCreatePurchaseOrder = async () => {
    const { data } = await createPurchaseOrder();
    if (data?.createOrder.order.id)
      handleOrderCreate(data?.createOrder.order.id);
  };

  if (Button) {
    return React.cloneElement(Button, {
      onClick: handleCreatePurchaseOrder,
      disabled: loading,
    });
  }

  return (
    <ButtonCreate
      title={t("New purchase order")}
      onClick={handleCreatePurchaseOrder}
      disabled={loading}
    />
  );
}
