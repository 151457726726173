import { gql, useApolloClient } from "@apollo/client";
import { DataGrid, ListHeader, ModalOpenButton, useFormatting } from "@msys/ui";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../auth/useUserData";
import { ButtonCreate } from "../../commons/button/Button";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { getAddressLabel } from "../../features/addresses/helpers";
import { BuildingCreateModal } from "../../features/buildings/modals/BuildingCreateModal";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { BuildingFragment, useBuildingsQuery } from "./Buildings.generated";
import { useTranslate } from "@tolgee/react";

export const Buildings = () => {
  const navigate = useNavigate();
  const { getFormattedDate } = useFormatting();
  const { t } = useTranslate(["Buildings", "Global"]);
  const viewer = useUserData().currentUser!;

  const { offset, limit, paginationModel, setPaginationModel } =
    useFiltersAndPagination({}, [], PAGE_LIST_RESULTS_PER_PAGE);

  const client = useApolloClient();
  const query = useBuildingsQuery({
    client,
    variables: {
      limit,
      offset,
    },
  });

  const buildings =
    (query.data ?? query.previousData)?.buildings.edges.map(e => e.node) ?? [];
  const total = (query.data ?? query.previousData)?.buildings.totalCount;

  const stateStore = useDataGridStateStore("Buildings");

  return (
    <Page
      title={t("Buildings", {
        ns: "Buildings",
      })}
    >
      <PageContainer>
        <ListHeader
          hideTitleOnMobile
          title={t("Buildings", {
            ns: "Buildings",
          })}
          CreateButton={
            <ModalOpenButton
              Modal={BuildingCreateModal}
              modalProps={{
                buildingOwningOrganisationId: viewer.organisation.id,
                handleComplete: buildingId => {
                  navigate(`/buildings/${buildingId}`);
                },
                refetchQueries: ["Buildings"],
              }}
            >
              <ButtonCreate
                title={t("Create new building", {
                  ns: "Buildings",
                })}
              />
            </ModalOpenButton>
          }
          marginBottom={2}
        />
        <DataGrid
          stateStore={stateStore}
          loading={query.loading}
          hideFooter={total === 0}
          columns={[
            {
              field: "title",
              headerName: t("Title", {
                ns: "Buildings",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: building }: { row: BuildingFragment }) =>
                building.title,
            },
            {
              field: "address",
              headerName: t("Address", {
                ns: "Buildings",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: building }: { row: BuildingFragment }) =>
                getAddressLabel(building.buildingAddress),
            },
            {
              field: "createdAt",
              headerName: t("Created", {
                ns: "Buildings",
              }),
              flex: 1,
              minWidth: 100,
              sortable: false,
              renderCell: ({ row: building }: { row: BuildingFragment }) =>
                getFormattedDate(building.createdAt),
            },
          ]}
          rows={buildings}
          onRowClick={building => navigate(`/buildings/${building.id}/edit`)}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={total}
        />
      </PageContainer>
    </Page>
  );
};
