/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
export type RequestAttachmentsBoxFragment = { __typename: 'Request', id: string, status: Types.RequestStatus, requestorProjectId: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export const RequestAttachmentsBoxFragmentDoc = gql`
    fragment RequestAttachmentsBox on Request {
  id
  status
  requestorProjectId
  attachments {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}`;