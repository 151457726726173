/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationEmbeddableWizardSnippetBox_OrganisationFragment = { __typename: 'ViewerOrganisation', id: string };

export type OrganisationEmbeddableWizardSnippetBox_SettingsFragment = { __typename: 'OrganisationSetting', id: string, embeddableWizardIsLeadAddressRequired: boolean };

export type OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationVariables = Types.Exact<{
  input: Types.OrganisationSettingsUpdateInput;
}>;


export type OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation = { updateOrganisationSettings: { __typename: 'OrganisationSettingsUpdateResult', organisationSettings: { __typename: 'OrganisationSetting', id: string, embeddableWizardIsLeadAddressRequired: boolean } } };

export const OrganisationEmbeddableWizardSnippetBox_OrganisationFragmentDoc = gql`
    fragment OrganisationEmbeddableWizardSnippetBox_Organisation on ViewerOrganisation {
  id
}
    `;
export const OrganisationEmbeddableWizardSnippetBox_SettingsFragmentDoc = gql`
    fragment OrganisationEmbeddableWizardSnippetBox_Settings on OrganisationSetting {
  id
  embeddableWizardIsLeadAddressRequired
}
    `;
export const OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsDocument = gql`
    mutation OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettings($input: OrganisationSettingsUpdateInput!) {
  updateOrganisationSettings(input: $input) {
    organisationSettings {
      id
      ...OrganisationEmbeddableWizardSnippetBox_Settings
    }
  }
}
    ${OrganisationEmbeddableWizardSnippetBox_SettingsFragmentDoc}`;
export type OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationFn = Apollo.MutationFunction<OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation, OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationVariables>;

/**
 * __useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation__
 *
 * To run a mutation, you first call `useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationEmbeddableWizardSnippetBoxModifyOrganisationSettingsMutation, { data, loading, error }] = useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation, OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation, OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationVariables>(OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsDocument, options);
      }
export type OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationHookResult = ReturnType<typeof useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation>;
export type OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationResult = Apollo.MutationResult<OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation>;
export type OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationOptions = Apollo.BaseMutationOptions<OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation, OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutationVariables>;