/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectOverdueAlertFragment = { __typename: 'Project', id: string, overdue: boolean, ticket: boolean, state: Types.ProjectStateMachineStatus };

export const ProjectOverdueAlertFragmentDoc = gql`
    fragment ProjectOverdueAlert on Project {
  id
  overdue
  ticket
  state
}
    `;