import { useTranslate } from "@tolgee/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "../../commons/PageNotFound";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu";
import { TaskIcon } from "../../features/tasks/TaskIcon";
import { TaskItem } from "./TaskItem";
import { TasksList } from "./TasksList";

type PageSubmenuItem = PageTopbarItem;

export function TasksRoutes() {
  const { t } = useTranslate(["Global", "Tasks"]);

  const submenuItems: PageSubmenuItem[] = React.useMemo(() => {
    return [
      {
        name: "task-list",
        label: t("Tasks", { ns: "Tasks" }),
        icon: <TaskIcon />,
        path: "/tasks",
      },
    ];
  }, [t]);

  return (
    <Routes>
      <Route path="/" element={<TasksList submenuItems={submenuItems} />}>
        <Route path=":taskId" element={<TaskItem />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
