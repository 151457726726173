import { default as DashboardIcon } from "@mui/icons-material/Apps";
import ContactsIcon from "@mui/icons-material/AssignmentInd";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
} from "react-router-dom";
import { useFeature } from "../../common/FeatureFlags";
import { RestrictedByCapability } from "../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermission } from "../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../auth/useUserData";
import { MainMenu, type IMainMenuItem } from "../commons/MainMenu";
import { PageNotFound } from "../commons/PageNotFound";
import { useBubbleContext } from "../commons/hooks/useBubbleContext";
import { BuildingIcon } from "../features/buildings/BuildingIcon";
import { DocumentIcon } from "../features/documents/DocumentIcon";
import { OpportunityIcon } from "../features/opportunities/OpportunityIcon";
import { OrganisationAvatar } from "../features/organisations/OrganisationAvatar";
import { PlanningIcon } from "../features/planning/PlanningIcon";
import { ProjectIcon } from "../features/projects/ProjectIcon";
import { useProjectIsOpportunity } from "../features/projects/useProjectIsOpportunity";
import { TaskIcon } from "../features/tasks/TaskIcon";
import { TemplateIcon } from "../features/templates/TemplateIcon";
import { UserAvatar } from "../features/users/UserAvatar";
import { BuildingsRoutes } from "./buildings/BuildingsRoutes";
import { Calendar } from "./calendar/Calendar";
import { CrmRoutes } from "./crm/CrmRoutes";
import { Dashboard } from "./dashboard/Dashboard";
import { DocumentsRoutes } from "./documents/DocumentsRoutes";
import { ExamplesRoutes } from "./examples/ExamplesRoutes";
import { GlobalOrganisationsRoutes } from "./global-organisations/GlobalOrganisationsRoutes";
import { OrganisationRoutes } from "./my-organisation/OrganisationRoutes";
import { UserRoutes } from "./my-user/UserRoutes";
import { OpportunitiesRoutes } from "./opportunities/OpportunitiesRoutes";
import { PlanningRoutes } from "./planning/PlanningRoutes";
import { ProductsRoutes } from "./products/ProductsRoutes";
import { ProjectsRoutes } from "./projects/ProjectsRoutes";
import { Schedule } from "./schedule/Schedule";
import { ShopRoutes } from "./shop/ShopRoutes";
import { TasksRoutes } from "./tasks/TaskRoutes";
import { TemplatesRoutes } from "./templates/TemplatesRoutes";

const DASHBOARD_PATH = "/dashboard";

export type MainMenuItem =
  | { name: "dashboard" }
  | { name: "buildings" }
  | { name: "opportunity" }
  | { name: "project" }
  | { name: "project2" }
  | { name: "documents" }
  | { name: "planning" }
  | { name: "tasks" }
  | { name: "CRM" }
  | { name: "templates" }
  | { name: "products" }
  | { name: "myprofile" }
  | { name: "myprofile-user" }
  | { name: "myprofile-organisation" }
  | { name: "shop" }
  | { name: "agenda" }
  | { name: "meistersystemsadmin" }
  | { name: "organizations" }
  | { name: "login" }
  | { name: "signup" }
  | { name: "__otherprofile" };

type AppSidebarItem = MainMenuItem & Omit<IMainMenuItem, "name">;

export const MainRoutes = () => {
  const location = useLocation();
  const { primaryItems, secondaryItems } = useMainMenuItems();

  return (
    <>
      <MainMenu primaryItems={primaryItems} secondaryItems={secondaryItems} />
      <Routes>
        <Route
          path={"/"}
          element={
            <Navigate
              to={{
                pathname: DASHBOARD_PATH,
                search: location.search,
              }}
              replace
            />
          }
        />
        <Route
          path={"/next"}
          element={
            <Navigate
              to={{
                pathname: DASHBOARD_PATH,
                search: location.search,
              }}
              replace
            />
          }
        />

        <Route path={DASHBOARD_PATH} element={<Dashboard />} />
        <Route path={"/buildings/*"} element={<BuildingsRoutes />} />
        <Route
          path={`/opportunities/*`}
          element={
            <RestrictedByOrganisationPermission permission="CREATE_PROJECT">
              <OpportunitiesRoutes />
            </RestrictedByOrganisationPermission>
          }
        />
        <Route path={`/projects/*`} element={<ProjectsRoutes />} />
        <Route path={`/documents/*`} element={<DocumentsRoutes />} />
        <Route path={`/tasks/*`} element={<TasksRoutes />} />
        <Route
          path={`/planning/*`}
          element={
            <RestrictedByCapability capability="PLANNING">
              <PlanningRoutes />
            </RestrictedByCapability>
          }
        />
        <Route
          path={"/crm/*"}
          element={
            <RestrictedByOrganisationPermission permission="READ_CRM">
              <CrmRoutes />
            </RestrictedByOrganisationPermission>
          }
        />
        <Route
          path={`/templates/*`}
          element={
            <RestrictedByCapability capability="TEMPLATING">
              <RestrictedByOrganisationPermission permission="READ_TEMPLATE">
                <TemplatesRoutes />
              </RestrictedByOrganisationPermission>
            </RestrictedByCapability>
          }
        />
        <Route
          path={"/products/*"}
          element={
            <RestrictedByCapability capability="PIM">
              <RestrictedByOrganisationPermission permission="MANAGE_ORG">
                <ProductsRoutes />
              </RestrictedByOrganisationPermission>
            </RestrictedByCapability>
          }
        />
        <Route
          path={`/shop/*`}
          element={
            <RestrictedByCapability capability="SHOP">
              <ShopRoutes />
            </RestrictedByCapability>
          }
        />
        <Route path={"/organisation/*"} element={<OrganisationRoutes />} />
        <Route path={"/user/*"} element={<UserRoutes />} />

        <Route path={`/schedule`} element={<Schedule />} />
        <Route path={`/calendar`} element={<Calendar />} />

        <Route
          path={"/globalsuppliers/*"}
          element={<GlobalOrganisationsRoutes path="globalsuppliers" />}
        />
        <Route
          path={"/globalmanufacturers/*"}
          element={<GlobalOrganisationsRoutes path="globalmanufacturers" />}
        />

        <Route path={`/examples/*`} element={<ExamplesRoutes />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

function useMainMenuItems() {
  const viewer = useUserData().currentUser;
  const isShopEnabled = useFeature("Shop");
  const { bubble } = useBubbleContext();
  const { t } = useTranslate("SidebarItems");

  const projectMatch = useMatch(`/projects/:projectId/*`);
  const projectId = projectMatch?.params?.projectId;

  const { projectLoading, projectIsOpportunity } =
    useProjectIsOpportunity(projectId);

  const menuItems = React.useMemo(() => {
    if (!viewer) return { primaryItems: [], secondaryItems: [] };

    const items: (AppSidebarItem & { isHidden?: boolean })[] = [
      {
        name: "dashboard",
        label: t("Dashboard"),
        icon: <DashboardIcon />,
        path: "/dashboard",
      },
      {
        name: "buildings",
        label: t("Buildings"),
        icon: <BuildingIcon />,
        path: "/buildings",
        capability: "BUILDINGS",
      },
      {
        name: "opportunity",
        label: t("Opportunities"),
        icon: <OpportunityIcon />,
        path: "/opportunities",
        permission: "CREATE_PROJECT",
        bubbleAmount: 0, //bubble.opportunitiesTotal,
        isActive: match => {
          return Boolean(match) || (!projectLoading && projectIsOpportunity);
        },
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      {
        name: "project",
        label: viewer.organisation.isCraftsmanOrganisation
          ? t("Projects and Tickets")
          : t("Projects"),
        icon: <ProjectIcon />,
        path: "/projects",
        bubbleAmount: bubble.projectsTotal,
        isActive: (match, location) =>
          Boolean(match) && !projectLoading && !projectIsOpportunity,
      },
      {
        name: "documents",
        label: t("Documents"),
        icon: <DocumentIcon />,
        path: "/documents",
      },
      {
        name: "planning",
        label: t("Planning and Resources"),
        icon: <PlanningIcon />,
        path: "/planning",
        capability: "PLANNING",
      },
      {
        name: "tasks",
        label: t("Tasks"),
        icon: <TaskIcon />,
        path: "/tasks",
      },
      {
        name: "CRM",
        label: "CRM",
        icon: <ContactsIcon />,
        path: "/crm",
        permission: "READ_CRM",
      },
      {
        name: "templates",
        label: t("Templates"),
        icon: <TemplateIcon />,
        path: "/templates",
        capability: "TEMPLATING",
        permission: "READ_TEMPLATE",
      },
      {
        name: "shop",
        label: t("Shop"),
        icon: <ShoppingBasketIcon />,
        path: "/shop",
        isHidden: !isShopEnabled,
        capability: "SHOP",
      },
      {
        name: "products",
        label: t("Product catalogues"),
        icon: <CategoryIcon />,
        path: "/products",
        capability: "PIM",
        permission: "MANAGE_ORG",
      },
    ] as const;

    const secondaryItems: (AppSidebarItem & { isHidden?: boolean })[] = [
      {
        name: "myprofile-organisation",
        path: "/organisation",
        label: viewer.organisation.title,
        icon: (
          <OrganisationAvatar
            organisationAvatar={viewer.organisation}
            size="s"
            style={{ left: -4, pointerEvents: "none" }}
          />
        ),
      },
      {
        name: "myprofile-user",
        path: "/user",
        label: `${viewer.firstname} ${viewer.familyname}`,
        icon: (
          <UserAvatar
            userAvatar={viewer}
            size="s"
            style={{ left: -4, pointerEvents: "none" }}
          />
        ),
      },
    ];

    return {
      primaryItems: items.filter(item => item.isHidden !== true),
      secondaryItems,
    };
  }, [
    bubble.projectsTotal,
    isShopEnabled,
    t,
    viewer,
    projectLoading,
    projectIsOpportunity,
  ]);

  return menuItems;
}
