import { CrmOrganisationContactType } from "../../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

export const useCrmOrganisationContactTypes = () => {
  const { t } = useTranslate(["OrganisationProfile"]);

  const contactTypeOptions: {
    label: string;
    value: CrmOrganisationContactType;
  }[] = [
    {
      label: t("Company", {
        ns: "OrganisationProfile",
      }),
      value: "COMPANY",
    },
    {
      label: t("Individual", {
        ns: "OrganisationProfile",
      }),
      value: "INDIVIDUAL",
    },
  ];

  const contactTypeLabels: Record<CrmOrganisationContactType, string> = {
    COMPANY: t("Company", {
      ns: "OrganisationProfile",
    }),
    INDIVIDUAL: t("Individual", {
      ns: "OrganisationProfile",
    }),
  };

  return { contactTypeOptions, contactTypeLabels };
};
