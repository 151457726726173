import { CardContainer, StatusLabel } from "@msys/ui";
import { Masonry } from "@mui/lab";
import { Box, Grid, useTheme } from "@mui/material";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageSectionHeader } from "../../commons/layout/PageSectionHeader";

export const ExampleLayoutXl4Columns = () => {
  const theme = useTheme();
  return (
    <>
      <PageContainer>
        <PageSectionHeader
          status={
            <StatusLabel color={theme.palette.grey[600]}>Example</StatusLabel>
          }
          title="Page title"
        />
      </PageContainer>
      <Page>
        <PageContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <CardContainer title="Header">
                <Box height={400} />
              </CardContainer>
              <CardContainer title="Address">
                <Box height={100} />
              </CardContainer>
              <CardContainer title="Next tasks">
                <Box height={200} />
              </CardContainer>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <CardContainer title="Requirements">
                <Box height={300} />
              </CardContainer>
              <CardContainer title="Quotes">
                <Box height={500} />
              </CardContainer>
              <CardContainer title="Tasks">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Purchase Orders">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Invoices">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Files">
                <Box height={400} />
              </CardContainer>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <CardContainer title="Project participants">
                <Box height={600} />
              </CardContainer>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <CardContainer title="Planning">
                <Box height={1600} />
              </CardContainer>
            </Grid>
          </Grid>
        </PageContainer>
      </Page>
    </>
  );
};

export const ExampleLayoutXl4ColumnsMasonry = () => {
  const theme = useTheme();
  return (
    <>
      <PageContainer>
        <PageSectionHeader
          status={
            <StatusLabel color={theme.palette.grey[600]}>Example</StatusLabel>
          }
          title="Page title"
        />
      </PageContainer>
      <Page>
        <PageContainer>
          <Masonry columns={{ xs: 1, md: 2, lg: 3, xl: 4 }} spacing={2}>
            <div>
              <CardContainer title="Header">
                <Box height={400} />
              </CardContainer>
              <CardContainer title="Address">
                <Box height={100} />
              </CardContainer>
              <CardContainer title="Next tasks">
                <Box height={200} />
              </CardContainer>
            </div>
            <div>
              <CardContainer title="Requirements">
                <Box height={300} />
              </CardContainer>
              <CardContainer title="Quotes">
                <Box height={500} />
              </CardContainer>
              <CardContainer title="Tasks">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Purchase Orders">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Invoices">
                <Box height={150} />
              </CardContainer>
              <CardContainer title="Files">
                <Box height={400} />
              </CardContainer>
            </div>
            <div>
              <CardContainer title="Project participants">
                <Box height={600} />
              </CardContainer>
            </div>
            <div>
              <CardContainer title="Planning">
                <Box height={1600} />
              </CardContainer>
            </div>
          </Masonry>
        </PageContainer>
      </Page>
    </>
  );
};

export const ExampleLayoutXl1Column = () => {
  const theme = useTheme();
  return (
    <Page>
      <PageContainer>
        <PageSectionHeader
          status={
            <StatusLabel color={theme.palette.grey[600]}>Example</StatusLabel>
          }
          title="Page title"
          mb={2}
        />
        <CardContainer title="Table">
          <Box height={400} />
        </CardContainer>
      </PageContainer>
    </Page>
  );
};
