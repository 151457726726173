import moment from "moment-timezone";

export type WeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type BizHours = Record<WeekDay, null | { start: string; end: string }>;
export type WorkDays = Record<WeekDay, boolean>;

export const getWorkSessions = (
  start: moment.Moment,
  end: moment.Moment,
  startWorkDay: string, // should be in HH:mm format!
  endWorkDay: string, // should be in HH:mm format!
  timezone: string = "UTC"
): [moment.Moment, moment.Moment][] => {
  // TODO: holidays?
  const bizHours: BizHours = {
    1: { start: startWorkDay, end: endWorkDay },
    2: { start: startWorkDay, end: endWorkDay },
    3: { start: startWorkDay, end: endWorkDay },
    4: { start: startWorkDay, end: endWorkDay },
    5: { start: startWorkDay, end: endWorkDay },
    6: null,
    7: null,
  };

  if (end.isBefore(start, "second")) {
    return [];
  }

  let startDay = start.format("YYYY-MM-DD");
  let endDay = end.format("YYYY-MM-DD");
  let current = moment(start);
  let currentDay = current.format("YYYY-MM-DD");

  let workSessions: [moment.Moment, moment.Moment][] = [];
  let endTime, startTime;
  let endMoment, startMoment;
  let weekday, bizStartTime, bizEndTime, duration;

  do {
    weekday = current.isoWeekday() as WeekDay; // returns 1-7 where 1 is Monday and 7 is Sunday
    bizStartTime = bizHours[weekday]?.start ?? null;
    bizEndTime = bizHours[weekday]?.end ?? null;

    if (bizStartTime && bizEndTime) {
      if (currentDay === startDay) {
        startTime = start.format("HH:mm");
        startTime = startTime > bizStartTime ? startTime : bizStartTime;
        startTime = startTime < bizEndTime ? startTime : bizEndTime;
      } else {
        startTime = bizStartTime;
      }

      if (currentDay === endDay) {
        endTime = end.format("HH:mm");
        endTime = endTime < bizEndTime ? endTime : bizEndTime;
        endTime = endTime > bizStartTime ? endTime : bizStartTime;
      } else {
        endTime = bizEndTime;
      }

      startMoment = moment.tz(
        currentDay + " " + startTime,
        "YYYY-MM-DD HH:mm",
        timezone
      );
      endMoment = moment.tz(
        currentDay + " " + endTime,
        "YYYY-MM-DD HH:mm",
        timezone
      );

      workSessions.push([startMoment, endMoment]);
    }

    current.add(1, "days");
    currentDay = current.format("YYYY-MM-DD");
  } while (currentDay <= endDay);

  return workSessions;
};
