import { gql, useApolloClient } from "@apollo/client";
import { CardContainer, MenuButton, ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { ViewMode, ViewModeMenuItem } from "../../../commons/ViewModeMenuItem";
import { PropertyAddModal } from "../../doc-items/modals/PropertyAddModal";
import { PropertyField } from "../../doc-items/PropertyField";
import { LabeledPropertiesValue } from "../../item-properties/LabeledPropertiesValue";
import { pimProductPropertyToInput } from "../helper";
import {
  PimProductPropertyFragment,
  ProductOverview__ProductFragment,
} from "../Product.generated";
import { propertyEntryToPimProperty } from "./ProductOverviewPropertyBox";
import {
  usePimSetSupplierProductPropertySuggestionsMutation,
  useProductOverviewSupplierProductPropertyFormBoxQuery,
} from "./ProductOverviewSupplierProductPropertyBox.generated";
import { assertNever, getDataOrNull } from "@msys/common";

export interface FormValues {
  properties: PimProductPropertyFragment[];
}

export function useValidationSchema() {
  return Yup.object().shape({
    properties: Yup.mixed(),
  });
}

interface Props {
  product: ProductOverview__ProductFragment;
  isEditable?: boolean;
  refetchQueries?: string[];
}

export const ProductOverviewSupplierProductPropertyBox = ({
  product,
  isEditable,
  refetchQueries,
}: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate([
    "Global",
    "ProductOverview",
    "ItemPropertyField",
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const [setPropertyValues] =
    usePimSetSupplierProductPropertySuggestionsMutation({
      client,
    });

  const initialValues: FormValues = {
    properties: product.supplierProductProperties,
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      await setPropertyValues({
        variables: {
          input: {
            productArticleNumber: product.articleNumber,
            productSupplierId: product.supplierId,
            properties: values.properties.map(pimProductPropertyToInput),
          },
        },
        refetchQueries,
      });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const actionButtons = (
    <Stack direction="row" spacing={1}>
      <MenuButton>
        <ViewModeMenuItem
          viewMode={viewMode}
          onViewModeChange={setViewMode}
          allowedModes={[null, "delete"]}
        />
      </MenuButton>
    </Stack>
  );

  const validationSchema = useValidationSchema();

  const fieldName = "properties" as const;

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <CardContainer
            title={t("Properties", {
              ns: "Global",
            })}
            isExpandable
            isInitiallyClosed={false}
            itemCount={product.supplierProductProperties.length}
            // ActionButton={isEditable ? actionButtons : undefined}
          >
            {isEditable ? (
              <Form>
                <Stack direction="column" p={1} spacing={1}>
                  <FieldArray
                    name={fieldName}
                    render={arrayHelpers => (
                      <Grid container spacing={1} columns={2}>
                        {values[fieldName].map((property, i) => (
                          <Grid key={property.prop2.key} item xs={1}>
                            <PropertyField
                              disableExpressions
                              docId=""
                              itemId=""
                              projectId=""
                              property={property.prop2}
                              setPropertyValue={property => {
                                if (property.bool) {
                                  setFieldValue(
                                    `${fieldName}.[${i}].prop2.valueBool`,
                                    property.bool.valueBool
                                  );
                                } else if (property.number) {
                                  setFieldValue(
                                    `${fieldName}.[${i}].prop2.valueNumber`,
                                    property.number.valueNumber
                                  );
                                } else if (property.text) {
                                  setFieldValue(
                                    `${fieldName}.[${i}].prop2.valueText`,
                                    property.text.valueText
                                  );
                                } else {
                                  throw new Error(
                                    "Property kind not supported"
                                  );
                                }
                              }}
                              handleDelete={
                                viewMode === "delete"
                                  ? () => {
                                      arrayHelpers.remove(i);
                                    }
                                  : undefined
                              }
                              readOnly={false}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  />
                  <AutoSave enableReinitialize initialValues={initialValues} />
                </Stack>
              </Form>
            ) : (
              <Stack direction="column" p={1} spacing={1}>
                <LabeledPropertiesValue
                  properties={product.supplierProductProperties.map(
                    p => p.prop2
                  )}
                  viewerIsContractor={true} // TODO
                  hideVisibility={true}
                />
              </Stack>
            )}
          </CardContainer>
        );
      }}
    </Formik>
  );
};

export const ProductOverviewSupplierProductPropertyFormBox = ({
  itemCount,
  productTypeId,
}: {
  itemCount: number;
  productTypeId: string | null;
}) => {
  const { t } = useTranslate([
    "Global",
    "ProductOverview",
    "ItemPropertyField",
  ]);

  const client = useApolloClient();
  const query = useProductOverviewSupplierProductPropertyFormBoxQuery({
    client,
    variables: { productTypeIds: productTypeId ? [productTypeId] : [] },
    skip: !productTypeId,
  });

  const productType = getDataOrNull(query.data?.pimSearchProductTypes)
    ?.productTypes[0];
  const productTypePropertyTypes = productType?.propertyTypes;

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const actionButtons = (
    <Stack direction="row" spacing={1}>
      <ModalOpenButton
        Modal={PropertyAddModal}
        modalProps={{
          docType: null,
          showQuestionControl: false,
          handleComplete: async property => {
            const newProperty = propertyEntryToPimProperty(property);
            if (newProperty) {
              if (
                values.properties.find(
                  p => p.prop2.key === newProperty.prop2.key
                )
              ) {
                throw new Error(
                  t(
                    "A property already exists with this name. Please enter another name",
                    {
                      ns: "QuoteItem",
                    }
                  )
                );
              }
              setFieldValue(fieldName, [...values.properties, newProperty]);
            }
          },
        }}
      >
        <IconButton size="small" color="primary">
          <AddIcon />
        </IconButton>
      </ModalOpenButton>
      <MenuButton>
        <ViewModeMenuItem
          viewMode={viewMode}
          onViewModeChange={setViewMode}
          allowedModes={[null, "visibility", "delete"]}
        />
      </MenuButton>
    </Stack>
  );

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const fieldName = "properties" as const;

  const valuesRef = React.useRef(values);
  valuesRef.current = values;

  React.useEffect(() => {
    if (
      productTypeId &&
      !query.loading &&
      query.data &&
      productType &&
      productTypePropertyTypes
    ) {
      const newProperties = [
        // at first, we select properties came from property type, not editable
        ...productTypePropertyTypes.flatMap<PimProductPropertyFragment>(
          propertyType => {
            const property = valuesRef.current[fieldName].find(p => {
              switch (p.pimProperty.__typename) {
                case "PimTypedBoolProperty":
                case "PimTypedTextProperty":
                case "PimTypedNumberProperty": {
                  return (
                    p.pimProperty.typeSystem ===
                      propertyType.propertyType.typeSystem &&
                    p.pimProperty.productTypeKey === productType.key &&
                    p.pimProperty.propertyTypeKey ===
                      propertyType.propertyType.key
                  );
                }
                case "PimBoolProperty":
                case "PimTextProperty":
                case "PimNumberProperty":
                case "PimRangeProperty":
                case "PimTypedRangeProperty": {
                  return false;
                }
                default:
                  assertNever(p.pimProperty);
              }
            });

            switch (propertyType.propertyType.kind) {
              case "number": {
                return {
                  __typename: "PimProductPropertyNumberTyped",
                  prop2: {
                    __typename: "Props2Number",
                    allowedValuesNumber: [],
                    askWhen: [],
                    askWhom: [],
                    clientVisibility: false,
                    essential: false,
                    group: "",
                    key: propertyType.propertyType.key,
                    label: propertyType.propertyType.label,
                    prompt: "",
                    unit: propertyType.propertyType.unit,
                    ...(property &&
                    property.__typename === "PimProductPropertyNumberTyped"
                      ? property.prop2
                      : {}),
                  },
                  pimProperty: {
                    __typename: "PimTypedNumberProperty",
                    productTypeKey: productType.key,
                    propertyTypeKey: propertyType.propertyType.key,
                    typeSystem: propertyType.propertyType.typeSystem,
                    unit: propertyType.propertyType.unit,
                    ...(property &&
                    property.__typename === "PimProductPropertyNumberTyped"
                      ? property.pimProperty
                      : {}),
                  },
                };
              }
              case "bool": {
                return {
                  __typename: "PimProductPropertyBoolTyped",
                  prop2: {
                    __typename: "Props2Bool",
                    allowedValuesNumber: [],
                    askWhen: [],
                    askWhom: [],
                    clientVisibility: false,
                    essential: false,
                    group: "",
                    key: propertyType.propertyType.key,
                    label: propertyType.propertyType.label,
                    prompt: "",
                    ...(property &&
                    property.__typename === "PimProductPropertyBoolTyped"
                      ? property.prop2
                      : {}),
                  },
                  pimProperty: {
                    __typename: "PimTypedBoolProperty",
                    productTypeKey: productType.key,
                    propertyTypeKey: propertyType.propertyType.key,
                    typeSystem: propertyType.propertyType.typeSystem,
                    ...(property &&
                    property.__typename === "PimProductPropertyBoolTyped"
                      ? property.pimProperty
                      : {}),
                  },
                };
              }
              case "text": {
                return {
                  __typename: "PimProductPropertyTextTyped",
                  prop2: {
                    __typename: "Props2Text",
                    allowedValuesText: propertyType.propertyTypeValues.map(
                      v => ({
                        allowedText: v.label,
                        __typename: "Props2AllowedValuesText",
                      })
                    ),
                    askWhen: [],
                    askWhom: [],
                    clientVisibility: false,
                    essential: false,
                    group: "",
                    key: propertyType.propertyType.key,
                    label: propertyType.propertyType.label,
                    prompt: "",
                    ...(property &&
                    property.__typename === "PimProductPropertyTextTyped"
                      ? property.prop2
                      : {}),
                  },
                  pimProperty: {
                    __typename: "PimTypedTextProperty",
                    productTypeKey: productType.key,
                    propertyTypeKey: propertyType.propertyType.key,
                    propertyTypeValueKey: null,
                    propertyTypeValue: null,
                    allowedPropertyTypeValues: propertyType.propertyTypeValues,
                    typeSystem: propertyType.propertyType.typeSystem,
                    ...(property &&
                    property.__typename === "PimProductPropertyTextTyped"
                      ? property.pimProperty
                      : {}),
                  },
                };
              }
              case "range": {
                return [];
              }
              default:
                assertNever(propertyType.propertyType.kind);
            }
          }
        ),
        // at second, custom manually added properties
        ...valuesRef.current[fieldName].filter(
          p =>
            p.pimProperty.__typename !== "PimTypedBoolProperty" &&
            p.pimProperty.__typename !== "PimTypedNumberProperty" &&
            p.pimProperty.__typename !== "PimTypedTextProperty" &&
            p.pimProperty.__typename !== "PimTypedRangeProperty"
        ),
      ];
      setFieldValue(fieldName, newProperties);
    }
    if (!productTypeId) {
      // only keep custom manually added properties
      const newProperties = valuesRef.current[fieldName].filter(
        p =>
          p.pimProperty.__typename !== "PimTypedBoolProperty" &&
          p.pimProperty.__typename !== "PimTypedNumberProperty" &&
          p.pimProperty.__typename !== "PimTypedTextProperty" &&
          p.pimProperty.__typename !== "PimTypedRangeProperty"
      );
      if (newProperties.length !== valuesRef.current[fieldName].length) {
        setFieldValue(fieldName, newProperties);
      }
    }
  }, [
    fieldName,
    productTypeId,
    productTypePropertyTypes,
    setFieldValue,
    query.loading,
    query.data,
  ]);

  return (
    <CardContainer
      title={t("Properties", { ns: "Global" })}
      isExpandable
      isInitiallyClosed={false}
      itemCount={itemCount}
      ActionButton={actionButtons}
    >
      <Stack direction="column" p={1} spacing={1}>
        <FieldArray
          name={fieldName}
          render={arrayHelpers => (
            <Grid container spacing={1} columns={2}>
              {values[fieldName].map((property, i) => (
                <Grid key={property.prop2.key} item xs={1}>
                  <PropertyField
                    disableExpressions
                    docId=""
                    itemId=""
                    projectId=""
                    property={property.prop2}
                    setPropertyValue={property => {
                      if (property.bool) {
                        setFieldValue(
                          `${fieldName}.[${i}].prop2.valueBool`,
                          property.bool.valueBool
                        );
                      } else if (property.number) {
                        setFieldValue(
                          `${fieldName}.[${i}].prop2.valueNumber`,
                          property.number.valueNumber
                        );
                      } else if (property.text) {
                        setFieldValue(
                          `${fieldName}.[${i}].prop2.valueText`,
                          property.text.valueText
                        );
                      } else {
                        throw new Error("Property kind not supported");
                      }
                    }}
                    setVisibility={
                      viewMode === "visibility" &&
                      property.pimProperty.__typename !==
                        "PimTypedBoolProperty" &&
                      property.pimProperty.__typename !==
                        "PimTypedNumberProperty" &&
                      property.pimProperty.__typename !==
                        "PimTypedTextProperty" &&
                      property.pimProperty.__typename !==
                        "PimTypedRangeProperty"
                        ? (key, isVisible) => {
                            setFieldValue(
                              `${fieldName}.[${i}].prop2.clientVisibility`,
                              isVisible
                            );
                          }
                        : undefined
                    }
                    handleDelete={
                      viewMode === "delete" &&
                      property.pimProperty.__typename !==
                        "PimTypedBoolProperty" &&
                      property.pimProperty.__typename !==
                        "PimTypedNumberProperty" &&
                      property.pimProperty.__typename !==
                        "PimTypedTextProperty" &&
                      property.pimProperty.__typename !==
                        "PimTypedRangeProperty"
                        ? () => {
                            arrayHelpers.remove(i);
                          }
                        : undefined
                    }
                    readOnly={false}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        />
      </Stack>
    </CardContainer>
  );
};
