import { gql } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Alert } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../../commons/modals/ConfirmProcess";
import { useProjectChangeState } from "../useProjectChangeState";
import { ProjectOpportunityAlertFragment } from "./ProjectOpportunityAlert.generated";

interface Props {
  project: ProjectOpportunityAlertFragment;
}

export const ProjectOpportunityAlert = ({ project }: Props) => {
  const { t } = useTranslate(["ProjectOverview", "RequestOverview", "Global"]);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const { handleStateChange } = useProjectChangeState({
    projectId: project.id,
    projectState: project.state,
    confirmProcessRef,
  });

  const hasExactlyOneIncomingRequest =
    project.incomingQuoteRequests.length === 1;

  if (!hasExactlyOneIncomingRequest) return null;

  const request = project.incomingQuoteRequests[0];
  const havePublishedQuotes = request.viewerRecipientNumPublishedQuotes > 0;

  if (request.status === "WITHDRAWN") {
    return (
      <>
        <Alert
          variant="outlined"
          severity="warning"
          action={
            <LoadingButton
              size="small"
              color="secondary"
              variant="outlined"
              onClick={async () => {
                await handleStateChange("CONTRACTEE_DECLINE");
              }}
            >
              {t("Close opportunity", { ns: "ProjectOverview" })}
            </LoadingButton>
          }
        >
          {t("The customer has withdrawn their request for quote.", {
            ns: "ProjectOverview",
          })}
        </Alert>
        <ConfirmProcess ref={confirmProcessRef} />
      </>
    );
  }

  if (request.status === "CLOSED") {
    return (
      <>
        <Alert
          variant="outlined"
          severity="warning"
          action={
            <LoadingButton
              size="small"
              color="secondary"
              variant="outlined"
              onClick={async () => {
                await handleStateChange("CONTRACTEE_DECLINE");
              }}
            >
              {t("Close opportunity", { ns: "ProjectOverview" })}
            </LoadingButton>
          }
        >
          {t("The customer's request for quote has been closed.", {
            ns: "ProjectOverview",
          })}
        </Alert>
        <ConfirmProcess ref={confirmProcessRef} />
      </>
    );
  }

  if (request.status === "CLOSED_FOR_BIDDING") {
    return (
      <>
        <Alert
          variant="outlined"
          severity="warning"
          action={
            <LoadingButton
              size="small"
              color="secondary"
              variant="outlined"
              onClick={async () => {
                await handleStateChange(
                  havePublishedQuotes
                    ? "CONTRACTOR_DECLINE"
                    : "CONTRACTEE_DECLINE"
                );
              }}
            >
              {t("Close opportunity", { ns: "ProjectOverview" })}
            </LoadingButton>
          }
        >
          {t("The customer is no longer accepting new quotes.", {
            ns: "ProjectOverview",
          })}
        </Alert>
        <ConfirmProcess ref={confirmProcessRef} />
      </>
    );
  }

  return null;
};
