/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModifyOrganisationTextsModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ModifyOrganisationTextsModalQuery = { organisationDocumentSettings: { __typename: 'DocumentSettings', id: string, quoteTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, invoiceTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, purchaseOrderTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, timeReportTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, materialListTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null } } | { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } };

export type OrganisationTextsModal_ModifyOrganisationMutationVariables = Types.Exact<{
  input: Types.OrganisationDocumentSettingsUpdateInput;
}>;


export type OrganisationTextsModal_ModifyOrganisationMutation = { updateOrganisationDocumentSettings: { __typename: 'OrganisationDocumentSettingsUpdateResult', documentSettings: { __typename: 'DocumentSettings', id: string, quoteTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, invoiceTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, purchaseOrderTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, timeReportTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null }, materialListTexts: { __typename: 'OrganisationPdfTexts', id: string, opening?: string | null, closing?: string | null } } } };


export const ModifyOrganisationTextsModalDocument = gql`
    query ModifyOrganisationTextsModal {
  organisationDocumentSettings {
    ... on DocumentSettings {
      id
      quoteTexts {
        id
        opening
        closing
      }
      invoiceTexts {
        id
        opening
        closing
      }
      purchaseOrderTexts {
        id
        opening
        closing
      }
      timeReportTexts {
        id
        opening
        closing
      }
      materialListTexts {
        id
        opening
        closing
      }
    }
  }
}
    `;

/**
 * __useModifyOrganisationTextsModalQuery__
 *
 * To run a query within a React component, call `useModifyOrganisationTextsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useModifyOrganisationTextsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModifyOrganisationTextsModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useModifyOrganisationTextsModalQuery(baseOptions?: Apollo.QueryHookOptions<ModifyOrganisationTextsModalQuery, ModifyOrganisationTextsModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModifyOrganisationTextsModalQuery, ModifyOrganisationTextsModalQueryVariables>(ModifyOrganisationTextsModalDocument, options);
      }
export function useModifyOrganisationTextsModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModifyOrganisationTextsModalQuery, ModifyOrganisationTextsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModifyOrganisationTextsModalQuery, ModifyOrganisationTextsModalQueryVariables>(ModifyOrganisationTextsModalDocument, options);
        }
export function useModifyOrganisationTextsModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ModifyOrganisationTextsModalQuery, ModifyOrganisationTextsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ModifyOrganisationTextsModalQuery, ModifyOrganisationTextsModalQueryVariables>(ModifyOrganisationTextsModalDocument, options);
        }
export type ModifyOrganisationTextsModalQueryHookResult = ReturnType<typeof useModifyOrganisationTextsModalQuery>;
export type ModifyOrganisationTextsModalLazyQueryHookResult = ReturnType<typeof useModifyOrganisationTextsModalLazyQuery>;
export type ModifyOrganisationTextsModalSuspenseQueryHookResult = ReturnType<typeof useModifyOrganisationTextsModalSuspenseQuery>;
export type ModifyOrganisationTextsModalQueryResult = Apollo.QueryResult<ModifyOrganisationTextsModalQuery, ModifyOrganisationTextsModalQueryVariables>;
export const OrganisationTextsModal_ModifyOrganisationDocument = gql`
    mutation OrganisationTextsModal_ModifyOrganisation($input: OrganisationDocumentSettingsUpdateInput!) {
  updateOrganisationDocumentSettings(input: $input) {
    documentSettings {
      id
      quoteTexts {
        id
        opening
        closing
      }
      invoiceTexts {
        id
        opening
        closing
      }
      purchaseOrderTexts {
        id
        opening
        closing
      }
      timeReportTexts {
        id
        opening
        closing
      }
      materialListTexts {
        id
        opening
        closing
      }
    }
  }
}
    `;
export type OrganisationTextsModal_ModifyOrganisationMutationFn = Apollo.MutationFunction<OrganisationTextsModal_ModifyOrganisationMutation, OrganisationTextsModal_ModifyOrganisationMutationVariables>;

/**
 * __useOrganisationTextsModal_ModifyOrganisationMutation__
 *
 * To run a mutation, you first call `useOrganisationTextsModal_ModifyOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationTextsModal_ModifyOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationTextsModalModifyOrganisationMutation, { data, loading, error }] = useOrganisationTextsModal_ModifyOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationTextsModal_ModifyOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationTextsModal_ModifyOrganisationMutation, OrganisationTextsModal_ModifyOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationTextsModal_ModifyOrganisationMutation, OrganisationTextsModal_ModifyOrganisationMutationVariables>(OrganisationTextsModal_ModifyOrganisationDocument, options);
      }
export type OrganisationTextsModal_ModifyOrganisationMutationHookResult = ReturnType<typeof useOrganisationTextsModal_ModifyOrganisationMutation>;
export type OrganisationTextsModal_ModifyOrganisationMutationResult = Apollo.MutationResult<OrganisationTextsModal_ModifyOrganisationMutation>;
export type OrganisationTextsModal_ModifyOrganisationMutationOptions = Apollo.BaseMutationOptions<OrganisationTextsModal_ModifyOrganisationMutation, OrganisationTextsModal_ModifyOrganisationMutationVariables>;