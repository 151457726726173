/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type AddressDetails__AddressFragment = { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null };

export type WithBillingAddress_CrmCompany_Fragment = { __typename: 'CrmCompany', id: string, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type WithBillingAddress_Organisation_Fragment = { __typename: 'Organisation', id: string, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type WithBillingAddressFragment = WithBillingAddress_CrmCompany_Fragment | WithBillingAddress_Organisation_Fragment;

export type WithBranchAddress_Company_Fragment = { __typename: 'Company', id: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type WithBranchAddress_CrmCompany_Fragment = { __typename: 'CrmCompany', id: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type WithBranchAddress_Organisation_Fragment = { __typename: 'Organisation', id: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type WithBranchAddress_ViewerOrganisation_Fragment = { __typename: 'ViewerOrganisation', id: string, branchAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null };

export type WithBranchAddressFragment = WithBranchAddress_Company_Fragment | WithBranchAddress_CrmCompany_Fragment | WithBranchAddress_Organisation_Fragment | WithBranchAddress_ViewerOrganisation_Fragment;

export const AddressDetails__AddressFragmentDoc = gql`
    fragment AddressDetails__Address on Address {
  id
  streetLines1
  city
  postalCode
  countryCode
  location {
    lat
    lng
  }
}
    `;
export const WithBillingAddressFragmentDoc = gql`
    fragment WithBillingAddress on WithBillingAddress {
  id
  billingAddress {
    ...AddressDetails__Address
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const WithBranchAddressFragmentDoc = gql`
    fragment WithBranchAddress on WithBranchAddress {
  id
  branchAddress {
    ...AddressDetails__Address
  }
}
    ${AddressDetails__AddressFragmentDoc}`;