import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React, { useCallback } from "react";
import { ArrayField } from "../../commons/form-fields/ArrayField";
import { PhoneField } from "../../commons/form-fields/PhoneField";
import { SelectField } from "../../commons/form-fields/SelectField";
import { Stack } from "../../commons/layout/Stack";
import { defaultPhoneType, usePhoneTypes } from "./usePhoneTypes";

export interface Props {
  name: string;
  buttonLabel: string;
  value: { main: boolean; number: string; type: string }[];
  disabled?: boolean;
  hideCreateButton?: boolean;
  hideDeleteButton?: boolean;
  autoSubmit?: boolean;
}

export const PhonesField = ({
  buttonLabel,
  disabled,
  name,
  value,
  hideCreateButton,
  hideDeleteButton,
  autoSubmit,
}: Props) => {
  // const renderItem = React.useCallback(
  //   (_: unknown, index: number) => (

  //   ),
  //   [disabled, name, phoneTypeOptions, t]
  // );

  return (
    <ArrayField
      name={name}
      value={value}
      disabled={disabled}
      renderItem={PhonesFieldItem}
      autoSubmit={autoSubmit}
      addButtonLabel={buttonLabel}
      createNewItem={() => ({
        ...defaultPhoneType,
      })}
      hideCreateButton={hideCreateButton}
      hideDeleteButton={hideDeleteButton}
    />
  );
};

const PhonesFieldItem = ({
  name,
  index,
  disabled,
}: {
  name: string;
  index: number;
  disabled: boolean | undefined;
}) => {
  const { t } = useTranslate("PhonesField");

  const { phoneTypeOptions } = usePhoneTypes();

  return (
    <Stack justifyContent="stretch" flex={1}>
      <Box style={{ minWidth: "100px" }}>
        <SelectField
          name={`${name}.${index}.type`}
          label={t("Type")}
          options={phoneTypeOptions}
          disabled={disabled}
        />
      </Box>
      <Box flex={1}>
        <PhoneField
          name={`${name}.${index}.number`}
          label={t("Number")}
          disabled={disabled}
          fullWidth
        />
      </Box>
    </Stack>
  );
};
