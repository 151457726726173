import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonCreate } from "../../../commons/button/Button";
import { RequirementCreateModal } from "../CreateRequirementModal";

export const CreateRequirementButton = ({
  projectId,
  Button,
  refetchQueries,
}: {
  projectId: string;
  Button?: React.ReactElement;
  refetchQueries?: string[];
}) => {
  const navigate = useNavigate();
  const { t } = useTranslate("Requirements");
  const title = t("New requirement");

  const button = Button ?? <ButtonCreate title={title} />;

  return (
    <ModalOpenButton
      Modal={RequirementCreateModal}
      modalProps={{
        refetchQueries,
        projectId,
        handleComplete: async requirementId => {
          if (requirementId) {
            navigate({
              pathname: `/projects/${projectId}/requirements/${requirementId}/edit`,
              search: new URLSearchParams({ create: "true" }).toString(),
            });
          } else {
            navigate(`/projects/${projectId}/requirements`);
          }
        },
      }}
    >
      {button}
    </ModalOpenButton>
  );
};
