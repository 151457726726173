import { ellipsisStyle, FormattedPrice, useFormatting } from "@msys/ui";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  ActionLine,
  ClientLine,
  CreatedDateLine,
  SupplierLine,
} from "../../commons/DataItem";
import {
  PurchaseOrderListItem_OrderNodeFragment,
  PurchaseOrderListItemFragment,
} from "./PurchaseOrderListItem.generated";
import { PurchaseOrderStatusBadge } from "./PurchaseOrderStatusBadge";
import { PurchaseOrderTitle } from "./PurchaseOrderTitle";

interface NodeProps {
  order: PurchaseOrderListItem_OrderNodeFragment;
  Action?: React.ReactElement;
}

export const NodePurchaseOrderListItem = ({ order, Action }: NodeProps) => {
  const { getFormattedDateTime } = useFormatting();
  const { t } = useTranslate("DataItem");
  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>
            <PurchaseOrderTitle order={order} />
          </span>
          <span
            style={{
              display: "inline-flex",
              marginLeft: "8px",
              verticalAlign: "middle",
            }}
          >
            <PurchaseOrderStatusBadge order={order} small />
          </span>
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      <Box
        gap={0.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        style={ellipsisStyle}
      >
        <Tooltip title={t("Created date")} arrow placement="top">
          <div>
            <CreatedDateLine>
              {getFormattedDateTime(order.createdAt)}
            </CreatedDateLine>
          </div>
        </Tooltip>
        {order.clientName && (
          <Tooltip title={t("Client")} arrow placement="bottom">
            <div style={ellipsisStyle}>
              <ClientLine>{order.clientName}</ClientLine>
            </div>
          </Tooltip>
        )}
        {order.supplierName && (
          <Tooltip title={t("Supplier")} arrow placement="bottom">
            <div style={ellipsisStyle}>
              <SupplierLine>{order.supplierName}</SupplierLine>
            </div>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
};
interface Props {
  order: PurchaseOrderListItemFragment;
  Action?: React.ReactElement;
}

export const PurchaseOrderListItem = ({ order, Action }: Props) => {
  const { getFormattedDate } = useFormatting();
  const { t } = useTranslate("DataItem");
  return (
    <Stack direction="column" spacing={0.5} minWidth={0}>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
      >
        <Typography variant={"h4"}>
          <span>
            <PurchaseOrderTitle order={order} />
          </span>
          <span
            style={{
              display: "inline-flex",
              marginLeft: "8px",
              verticalAlign: "middle",
            }}
          >
            <PurchaseOrderStatusBadge order={order} small />
          </span>
        </Typography>
        {Action ? <ActionLine>{Action}</ActionLine> : null}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        minWidth={0}
        justifyContent="space-between"
        style={ellipsisStyle}
      >
        <Box
          gap={0.5}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          style={ellipsisStyle}
        >
          <Tooltip title={t("Created date")} arrow placement="top">
            <div>
              <CreatedDateLine>
                {getFormattedDate(order.createdAt)}
              </CreatedDateLine>
            </div>
          </Tooltip>
          {order.supplier && (
            <Tooltip title={t("Supplier")} arrow placement="bottom">
              <div style={ellipsisStyle}>
                <SupplierLine>{order.supplier.title}</SupplierLine>
              </div>
            </Tooltip>
          )}
        </Box>
        <Typography
          variant={"body2"}
          style={{
            float: "right",
            alignItems: "flex-end",
            display: "flex",
            shapeOutside: "inset(calc(100% - 20px) 0 0)",
            marginBottom: "-4px",
          }}
          component="div"
        >
          <FormattedPrice
            value={order.calculationPrepared?.priceTotalOrdered ?? 0}
          />
        </Typography>
      </Stack>
    </Stack>
  );
};
