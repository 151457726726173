import { gql, useApolloClient } from "@apollo/client";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import { LoadingButton } from "@mui/lab";
import { ButtonProps, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import {
  QuoteTemplateUnsubscribeButton_QuoteTemplateFragment,
  useUnsubscribeFromQuoteTemplateMutation,
} from "./QuoteTemplateUnsubscribeButton.generated";

interface Props extends ButtonProps {
  doc: QuoteTemplateUnsubscribeButton_QuoteTemplateFragment;
}

export const QuoteTemplateUnsubscribeButton = ({ doc, ...rest }: Props) => {
  const { t } = useTranslate(["Templates", "TemplatesPublishing"]);

  const client = useApolloClient();
  const [unsubscribe, { loading }] = useUnsubscribeFromQuoteTemplateMutation({
    client,
  });

  const tooltip = doc.usedInOtherTemplates
    ? t("Template is used in other templates, cannot unsubscribe", {
        ns: "TemplatesPublishing",
      })
    : doc.addedToShop
    ? t("Template is added to the shop, cannot unsubscribe", {
        ns: "TemplatesPublishing",
      })
    : "";

  return (
    <Tooltip title={tooltip}>
      <div>
        <LoadingButton
          loading={loading}
          startIcon={
            rest.size !== "extra-small" ? <BookmarkRemoveIcon /> : undefined
          }
          variant={"outlined"}
          color="primary"
          sx={{ whiteSpace: "nowrap" }}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();
            if (doc.addedToShop || doc.usedInOtherTemplates) return;
            if (!doc.subscribedToTemplate) {
              throw new Error("Template not subscribed");
            }
            await unsubscribe({
              variables: { input: { quoteTemplateId: doc.id } },
            });
          }}
          {...rest}
          disabled={doc.addedToShop || doc.usedInOtherTemplates}
        >
          {t("Unsubscribe", { ns: "TemplatesPublishing" })}
        </LoadingButton>
      </div>
    </Tooltip>
  );
};
