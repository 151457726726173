/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { PlanningResourcesFragmentDoc } from '../../features/planning/PlanningSchedule.generated';
import { ScheduleWhoFragmentDoc, ScheduleProjectFragmentDoc, SchedulePlanSessionFragmentDoc, MapProjectFragmentDoc } from '../../features/schedule/Fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlanningAbsencesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  rangeStart?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  rangeEnd?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  filterByWhoIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterByRoleIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type PlanningAbsencesQuery = { absences: { __typename: 'AbsenceConnection', totalCount: number, edges: Array<{ __typename: 'AbsenceEdge', node: { __typename: 'Absence', id: string, from: any, till: any, note: string, reason: Types.AbsenceReason, who: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } }> } | { __typename: 'MissingCapabilities' } };

export type PlanningAbsencesMembersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PlanningAbsencesMembersQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, email: any, firstname: string, familyname: string, fullname: string, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }>, roles: Array<{ __typename: 'OrganisationRole', id: string, label: string, internalName: string }>, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, roles: Array<{ __typename: 'OrganisationRole', id: string, label: string, internalName: string }>, organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultStartWorkDay?: string | null, defaultEndWorkDay?: string | null } };

export type PlanningAbsenceFragment = { __typename: 'Absence', id: string, from: any, till: any, note: string, reason: Types.AbsenceReason, who: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } };

export type RemoveAbsenceMutationVariables = Types.Exact<{
  input: Types.RemoveAbsenceInput;
}>;


export type RemoveAbsenceMutation = { removeAbsence: { __typename: 'RemoveAbsenceResult', ok: boolean } };

export const PlanningAbsenceFragmentDoc = gql`
    fragment PlanningAbsence on Absence {
  id
  from
  till
  note
  reason
  who {
    id
    ...ScheduleWho
  }
}
    ${ScheduleWhoFragmentDoc}`;
export const PlanningAbsencesDocument = gql`
    query PlanningAbsences($limit: Int!, $offset: Int!, $rangeStart: Date, $rangeEnd: Date, $filterByWhoIds: [ID!], $filterByRoleIds: [ID!]) {
  absences(
    limit: $limit
    offset: $offset
    filters: {rangeStart: $rangeStart, rangeEnd: $rangeEnd, whoIds: $filterByWhoIds, roleIds: $filterByRoleIds}
  ) {
    ... on AbsenceConnection {
      totalCount
      edges {
        node {
          id
          ...PlanningAbsence
        }
      }
    }
  }
}
    ${PlanningAbsenceFragmentDoc}`;

/**
 * __usePlanningAbsencesQuery__
 *
 * To run a query within a React component, call `usePlanningAbsencesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningAbsencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningAbsencesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      rangeStart: // value for 'rangeStart'
 *      rangeEnd: // value for 'rangeEnd'
 *      filterByWhoIds: // value for 'filterByWhoIds'
 *      filterByRoleIds: // value for 'filterByRoleIds'
 *   },
 * });
 */
export function usePlanningAbsencesQuery(baseOptions: Apollo.QueryHookOptions<PlanningAbsencesQuery, PlanningAbsencesQueryVariables> & ({ variables: PlanningAbsencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningAbsencesQuery, PlanningAbsencesQueryVariables>(PlanningAbsencesDocument, options);
      }
export function usePlanningAbsencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningAbsencesQuery, PlanningAbsencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningAbsencesQuery, PlanningAbsencesQueryVariables>(PlanningAbsencesDocument, options);
        }
export function usePlanningAbsencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlanningAbsencesQuery, PlanningAbsencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlanningAbsencesQuery, PlanningAbsencesQueryVariables>(PlanningAbsencesDocument, options);
        }
export type PlanningAbsencesQueryHookResult = ReturnType<typeof usePlanningAbsencesQuery>;
export type PlanningAbsencesLazyQueryHookResult = ReturnType<typeof usePlanningAbsencesLazyQuery>;
export type PlanningAbsencesSuspenseQueryHookResult = ReturnType<typeof usePlanningAbsencesSuspenseQuery>;
export type PlanningAbsencesQueryResult = Apollo.QueryResult<PlanningAbsencesQuery, PlanningAbsencesQueryVariables>;
export const PlanningAbsencesMembersDocument = gql`
    query PlanningAbsencesMembers {
  organisationMemberships(filters: {active: true}) {
    id
    ...PlanningResources
  }
  roles {
    id
    label
    internalName
  }
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      defaultStartWorkDay
      defaultEndWorkDay
    }
  }
}
    ${PlanningResourcesFragmentDoc}`;

/**
 * __usePlanningAbsencesMembersQuery__
 *
 * To run a query within a React component, call `usePlanningAbsencesMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningAbsencesMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningAbsencesMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanningAbsencesMembersQuery(baseOptions?: Apollo.QueryHookOptions<PlanningAbsencesMembersQuery, PlanningAbsencesMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningAbsencesMembersQuery, PlanningAbsencesMembersQueryVariables>(PlanningAbsencesMembersDocument, options);
      }
export function usePlanningAbsencesMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningAbsencesMembersQuery, PlanningAbsencesMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningAbsencesMembersQuery, PlanningAbsencesMembersQueryVariables>(PlanningAbsencesMembersDocument, options);
        }
export function usePlanningAbsencesMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlanningAbsencesMembersQuery, PlanningAbsencesMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlanningAbsencesMembersQuery, PlanningAbsencesMembersQueryVariables>(PlanningAbsencesMembersDocument, options);
        }
export type PlanningAbsencesMembersQueryHookResult = ReturnType<typeof usePlanningAbsencesMembersQuery>;
export type PlanningAbsencesMembersLazyQueryHookResult = ReturnType<typeof usePlanningAbsencesMembersLazyQuery>;
export type PlanningAbsencesMembersSuspenseQueryHookResult = ReturnType<typeof usePlanningAbsencesMembersSuspenseQuery>;
export type PlanningAbsencesMembersQueryResult = Apollo.QueryResult<PlanningAbsencesMembersQuery, PlanningAbsencesMembersQueryVariables>;
export const RemoveAbsenceDocument = gql`
    mutation RemoveAbsence($input: RemoveAbsenceInput!) {
  removeAbsence(input: $input) {
    ok
  }
}
    `;
export type RemoveAbsenceMutationFn = Apollo.MutationFunction<RemoveAbsenceMutation, RemoveAbsenceMutationVariables>;

/**
 * __useRemoveAbsenceMutation__
 *
 * To run a mutation, you first call `useRemoveAbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAbsenceMutation, { data, loading, error }] = useRemoveAbsenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAbsenceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAbsenceMutation, RemoveAbsenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAbsenceMutation, RemoveAbsenceMutationVariables>(RemoveAbsenceDocument, options);
      }
export type RemoveAbsenceMutationHookResult = ReturnType<typeof useRemoveAbsenceMutation>;
export type RemoveAbsenceMutationResult = Apollo.MutationResult<RemoveAbsenceMutation>;
export type RemoveAbsenceMutationOptions = Apollo.BaseMutationOptions<RemoveAbsenceMutation, RemoveAbsenceMutationVariables>;