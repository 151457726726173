/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocExportAsM1ImpexJsonMutationVariables = Types.Exact<{
  input: Types.DocExportAsM1ImpexJsonInput;
}>;


export type DocExportAsM1ImpexJsonMutation = { docExportAsM1ImpexJson: { __typename: 'DocExportAsM1ImpexJsonResult', downloadUrl: string, filename: string } };


export const DocExportAsM1ImpexJsonDocument = gql`
    mutation DocExportAsM1ImpexJson($input: DocExportAsM1ImpexJsonInput!) {
  docExportAsM1ImpexJson(input: $input) {
    downloadUrl
    filename
  }
}
    `;
export type DocExportAsM1ImpexJsonMutationFn = Apollo.MutationFunction<DocExportAsM1ImpexJsonMutation, DocExportAsM1ImpexJsonMutationVariables>;

/**
 * __useDocExportAsM1ImpexJsonMutation__
 *
 * To run a mutation, you first call `useDocExportAsM1ImpexJsonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocExportAsM1ImpexJsonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [docExportAsM1ImpexJsonMutation, { data, loading, error }] = useDocExportAsM1ImpexJsonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocExportAsM1ImpexJsonMutation(baseOptions?: Apollo.MutationHookOptions<DocExportAsM1ImpexJsonMutation, DocExportAsM1ImpexJsonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocExportAsM1ImpexJsonMutation, DocExportAsM1ImpexJsonMutationVariables>(DocExportAsM1ImpexJsonDocument, options);
      }
export type DocExportAsM1ImpexJsonMutationHookResult = ReturnType<typeof useDocExportAsM1ImpexJsonMutation>;
export type DocExportAsM1ImpexJsonMutationResult = Apollo.MutationResult<DocExportAsM1ImpexJsonMutation>;
export type DocExportAsM1ImpexJsonMutationOptions = Apollo.BaseMutationOptions<DocExportAsM1ImpexJsonMutation, DocExportAsM1ImpexJsonMutationVariables>;