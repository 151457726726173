import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { Stack } from "../../../commons/layout/Stack";
import {
  CrmCompanyPaymentInformationBox_CrmCompanyFragment,
  CrmCompanyPaymentInformationData_CrmCompanyFragment,
  CrmCompanyPaymentInformationForm_CrmComapnyFragment,
  useModifyCrmCompanyPaymentInformationMutation,
} from "./CrmCompanyPaymentInformationBox.generated";

interface Props {
  crmCompany: CrmCompanyPaymentInformationBox_CrmCompanyFragment;
}

export function CrmCompanyPaymentInformationBox({ crmCompany }: Props) {
  const { t } = useTranslate("OrganisationProfile");

  return (
    <CardContainer isExpandable title={t("Payment information")}>
      <Box p={1}>
        <RestrictedByOrganisationPermissionWithDebug
          permission="MANAGE_CRM"
          otherwise={
            <CrmCompanyPaymentInformationData crmCompany={crmCompany} />
          }
        >
          <CrmCompanyPaymentInformationForm crmCompany={crmCompany} />
        </RestrictedByOrganisationPermissionWithDebug>
      </Box>
    </CardContainer>
  );
}

function CrmCompanyPaymentInformationData({
  crmCompany,
}: {
  crmCompany: CrmCompanyPaymentInformationData_CrmCompanyFragment;
}) {
  const { t } = useTranslate(["OrganisationProfile", "Global"]);

  return (
    <Stack flexDirection="column">
      <LabeledValue
        label={t("Bank name", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.bankName ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("IBAN", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.bankAccount ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
      <LabeledValue
        label={t("BIC code", {
          ns: "OrganisationProfile",
        })}
      >
        {crmCompany.bankCode ||
          t("Not set", {
            ns: "Global",
          })}
      </LabeledValue>
    </Stack>
  );
}

interface FormValues {
  bankName: string;
  bankAccount: string;
  bankCode: string;
}

function CrmCompanyPaymentInformationForm({
  crmCompany,
}: {
  crmCompany: CrmCompanyPaymentInformationForm_CrmComapnyFragment;
}) {
  const { t } = useTranslate("OrganisationProfile");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyCrmCompany] = useModifyCrmCompanyPaymentInformationMutation({
    client,
  });

  const validationSchema = Yup.object().shape({
    bankName: Yup.string().label(t("Bank name")),
    bankAccount: Yup.string().label(t("IBAN")),
    bankCode: Yup.string().label(t("BIC code")),
  });

  const initialValues = {
    bankName: crmCompany.bankName,
    bankAccount: crmCompany.bankAccount,
    bankCode: crmCompany.bankCode,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          await modifyCrmCompany({
            variables: {
              input: [
                {
                  id: crmCompany.id,
                  bankName: values.bankName,
                  bankAccount: values.bankAccount,
                  bankCode: values.bankCode,
                },
              ],
            },
          });
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, { variant: "error" });
        }
      }}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack flexDirection="column">
            <Field
              component={TextField}
              name="bankName"
              label={t("Bank name")}
              disabled={false}
            />
            <Field
              component={TextField}
              name="bankAccount"
              label={t("IBAN")}
              disabled={false}
            />
            <Field
              component={TextField}
              name="bankCode"
              label={t("BIC code")}
              disabled={false}
            />
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
