import { Stack, Typography } from "@mui/material";
import React from "react";

type Props = {
  value?: string | null;
  diffValue?: string | null;
  ValueComponent?: React.ComponentType<{ value: string }>;
  DiffValueComponent?: React.ComponentType<{ value: string }>;
  spacing?: number;
  showDiff?: boolean;
  flex?: number;
  placeholder?: string;
};

export const ValueWithDiff = ({
  value,
  ValueComponent,
  diffValue,
  DiffValueComponent,
  spacing,
  showDiff = true,
  flex,
  placeholder,
}: Props) => {
  const showValue = typeof value === "string" || typeof diffValue === "string";
  const valueToShow = value ? value : placeholder ? placeholder : " ";

  const showDiffValue = (diffValue || showDiff) && diffValue !== value;
  const diffValueToShow = showDiffValue
    ? diffValue
      ? diffValue
      : placeholder
      ? placeholder
      : ""
    : "";

  return (
    <Stack direction="column" spacing={spacing} flex={flex}>
      {showValue &&
        (ValueComponent ? (
          <ValueComponent value={valueToShow} />
        ) : (
          <div>{valueToShow}</div>
        ))}
      {showDiffValue && (
        <DiffedValue>
          {DiffValueComponent ? (
            <DiffValueComponent value={diffValueToShow} />
          ) : (
            <div>{diffValueToShow}</div>
          )}
        </DiffedValue>
      )}
    </Stack>
  );
};

const DiffedValue = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Typography
      component="div"
      style={{
        marginTop: `-2px`,
        color: "rgba(0, 0, 0, 0.58)",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: "rgba(0, 0, 0, 0.58)",
        paddingLeft: "4px",
        fontSize: "12px",
        fontStyle: "italic",
      }}
      variant="body2"
    >
      {children}
    </Typography>
  );
};
