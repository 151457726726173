/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { RequestStatusBadgeFragmentDoc } from './RequestStatusBadge.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishRequestToMarketplaceMutationVariables = Types.Exact<{
  input: Types.PublishRequestToMarketplaceInput;
}>;


export type PublishRequestToMarketplaceMutation = { publishRequestToMarketplace: { __typename: 'PublishRequestToMarketplaceResult', request: { __typename: 'Request', id: string, marketplace: boolean } } };

export type UnpublishRequestToMarketplaceMutationVariables = Types.Exact<{
  input: Types.UnpublishRequestToMarketplaceInput;
}>;


export type UnpublishRequestToMarketplaceMutation = { unpublishRequestToMarketplace: { __typename: 'UnpublishRequestToMarketplaceResult', request: { __typename: 'Request', id: string, marketplace: boolean } } };

export type PublishRequestMutationVariables = Types.Exact<{
  input: Types.PublishRequestInput;
}>;


export type PublishRequestMutation = { publishRequest: { __typename: 'PublishRequestResult', request: { __typename: 'Request', id: string, status: Types.RequestStatus, viewerNumActiveQuotes: number, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } } };

export type WithdrawRequestMutationVariables = Types.Exact<{
  input: Types.WithdrawRequestInput;
}>;


export type WithdrawRequestMutation = { withdrawRequest: { __typename: 'WithdrawRequestResult', request: { __typename: 'Request', id: string, status: Types.RequestStatus, viewerNumActiveQuotes: number, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } } };

export type CloseRequestMutationVariables = Types.Exact<{
  input: Types.CloseRequestInput;
}>;


export type CloseRequestMutation = { closeRequest: { __typename: 'CloseRequestResult', request: { __typename: 'Request', id: string, status: Types.RequestStatus, viewerNumActiveQuotes: number, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } } };

export type CloseForBiddingRequestMutationVariables = Types.Exact<{
  input: Types.CloseRequestForBiddingInput;
}>;


export type CloseForBiddingRequestMutation = { closeRequestForBidding: { __typename: 'CloseRequestForBiddingResult', request: { __typename: 'Request', id: string, status: Types.RequestStatus, viewerNumActiveQuotes: number, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } } };

export type AddRequestBiddersMutationVariables = Types.Exact<{
  input: Types.AddRequestRecipientsInput;
}>;


export type AddRequestBiddersMutation = { addRequestRecipients: { __typename: 'AddRequestRecipientsResult', request: { __typename: 'Request', id: string, viewerNumActiveRecipients: number }, requestRecipients: Array<{ __typename: 'RequestRecipient', id: string }> } };

export type RejectAllQuotesInRequestMutationVariables = Types.Exact<{
  input: Types.RejectAllQuotesInRequestInput;
}>;


export type RejectAllQuotesInRequestMutation = { rejectAllQuotesInRequest: { __typename: 'RejectAllQuotesInRequestResult', request: { __typename: 'Request', id: string, status: Types.RequestStatus, viewerNumActiveQuotes: number, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } } };

export type ProvideRequestFeedbackMutationVariables = Types.Exact<{
  input: Types.ProvideRequestFeedbackInput;
}>;


export type ProvideRequestFeedbackMutation = { provideRequestFeedback: { __typename: 'ProvideRequestFeedbackResult', requestRecipient?: { __typename: 'RequestRecipient', id: string, recipientStatus: Types.RequestRecipientStatus } | null, request: { __typename: 'Request', id: string, status: Types.RequestStatus, viewerRecipientStatus: Types.RequestRecipientStatus, viewerNumActiveQuotes: number, wonBySystemOrganisationId?: string | null, owningSystemOrganisationId: string } } };

export type CreateProjectForRequestMutationVariables = Types.Exact<{
  input: Types.CreateProjectForRequestInput;
}>;


export type CreateProjectForRequestMutation = { createProjectForRequest: { __typename: 'CreateProjectForRequestResult', request: { __typename: 'Request', id: string, viewerRecipientProject?: { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName> } | null }, project: { __typename: 'Project', id: string, state: Types.ProjectStateMachineStatus } } };

export type RemoveRequestRecipientMutationVariables = Types.Exact<{
  input: Types.RemoveRequestRecipientInput;
}>;


export type RemoveRequestRecipientMutation = { removeRequestRecipient: { __typename: 'RemoveRequestRecipientResult', removedRecipientId: string, request: { __typename: 'Request', id: string, viewerNumActiveRecipients: number } } };

export type CreateQuoteRequestMutationVariables = Types.Exact<{
  input: Types.CreateQuoteRequestInput;
}>;


export type CreateQuoteRequestMutation = { createQuoteRequest: { __typename: 'CreateQuoteRequestResult', request: { __typename: 'Request', id: string } } };


export const PublishRequestToMarketplaceDocument = gql`
    mutation PublishRequestToMarketplace($input: PublishRequestToMarketplaceInput!) {
  publishRequestToMarketplace(input: $input) {
    request {
      id
      marketplace
    }
  }
}
    `;
export type PublishRequestToMarketplaceMutationFn = Apollo.MutationFunction<PublishRequestToMarketplaceMutation, PublishRequestToMarketplaceMutationVariables>;

/**
 * __usePublishRequestToMarketplaceMutation__
 *
 * To run a mutation, you first call `usePublishRequestToMarketplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishRequestToMarketplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishRequestToMarketplaceMutation, { data, loading, error }] = usePublishRequestToMarketplaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishRequestToMarketplaceMutation(baseOptions?: Apollo.MutationHookOptions<PublishRequestToMarketplaceMutation, PublishRequestToMarketplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishRequestToMarketplaceMutation, PublishRequestToMarketplaceMutationVariables>(PublishRequestToMarketplaceDocument, options);
      }
export type PublishRequestToMarketplaceMutationHookResult = ReturnType<typeof usePublishRequestToMarketplaceMutation>;
export type PublishRequestToMarketplaceMutationResult = Apollo.MutationResult<PublishRequestToMarketplaceMutation>;
export type PublishRequestToMarketplaceMutationOptions = Apollo.BaseMutationOptions<PublishRequestToMarketplaceMutation, PublishRequestToMarketplaceMutationVariables>;
export const UnpublishRequestToMarketplaceDocument = gql`
    mutation UnpublishRequestToMarketplace($input: UnpublishRequestToMarketplaceInput!) {
  unpublishRequestToMarketplace(input: $input) {
    request {
      id
      marketplace
    }
  }
}
    `;
export type UnpublishRequestToMarketplaceMutationFn = Apollo.MutationFunction<UnpublishRequestToMarketplaceMutation, UnpublishRequestToMarketplaceMutationVariables>;

/**
 * __useUnpublishRequestToMarketplaceMutation__
 *
 * To run a mutation, you first call `useUnpublishRequestToMarketplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishRequestToMarketplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishRequestToMarketplaceMutation, { data, loading, error }] = useUnpublishRequestToMarketplaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishRequestToMarketplaceMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishRequestToMarketplaceMutation, UnpublishRequestToMarketplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishRequestToMarketplaceMutation, UnpublishRequestToMarketplaceMutationVariables>(UnpublishRequestToMarketplaceDocument, options);
      }
export type UnpublishRequestToMarketplaceMutationHookResult = ReturnType<typeof useUnpublishRequestToMarketplaceMutation>;
export type UnpublishRequestToMarketplaceMutationResult = Apollo.MutationResult<UnpublishRequestToMarketplaceMutation>;
export type UnpublishRequestToMarketplaceMutationOptions = Apollo.BaseMutationOptions<UnpublishRequestToMarketplaceMutation, UnpublishRequestToMarketplaceMutationVariables>;
export const PublishRequestDocument = gql`
    mutation PublishRequest($input: PublishRequestInput!) {
  publishRequest(input: $input) {
    request {
      id
      status
      viewerNumActiveQuotes
      ...RequestStatusBadge
    }
  }
}
    ${RequestStatusBadgeFragmentDoc}`;
export type PublishRequestMutationFn = Apollo.MutationFunction<PublishRequestMutation, PublishRequestMutationVariables>;

/**
 * __usePublishRequestMutation__
 *
 * To run a mutation, you first call `usePublishRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishRequestMutation, { data, loading, error }] = usePublishRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishRequestMutation(baseOptions?: Apollo.MutationHookOptions<PublishRequestMutation, PublishRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishRequestMutation, PublishRequestMutationVariables>(PublishRequestDocument, options);
      }
export type PublishRequestMutationHookResult = ReturnType<typeof usePublishRequestMutation>;
export type PublishRequestMutationResult = Apollo.MutationResult<PublishRequestMutation>;
export type PublishRequestMutationOptions = Apollo.BaseMutationOptions<PublishRequestMutation, PublishRequestMutationVariables>;
export const WithdrawRequestDocument = gql`
    mutation WithdrawRequest($input: WithdrawRequestInput!) {
  withdrawRequest(input: $input) {
    request {
      id
      status
      viewerNumActiveQuotes
      ...RequestStatusBadge
    }
  }
}
    ${RequestStatusBadgeFragmentDoc}`;
export type WithdrawRequestMutationFn = Apollo.MutationFunction<WithdrawRequestMutation, WithdrawRequestMutationVariables>;

/**
 * __useWithdrawRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawRequestMutation, { data, loading, error }] = useWithdrawRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawRequestMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawRequestMutation, WithdrawRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawRequestMutation, WithdrawRequestMutationVariables>(WithdrawRequestDocument, options);
      }
export type WithdrawRequestMutationHookResult = ReturnType<typeof useWithdrawRequestMutation>;
export type WithdrawRequestMutationResult = Apollo.MutationResult<WithdrawRequestMutation>;
export type WithdrawRequestMutationOptions = Apollo.BaseMutationOptions<WithdrawRequestMutation, WithdrawRequestMutationVariables>;
export const CloseRequestDocument = gql`
    mutation CloseRequest($input: CloseRequestInput!) {
  closeRequest(input: $input) {
    request {
      id
      status
      viewerNumActiveQuotes
      ...RequestStatusBadge
    }
  }
}
    ${RequestStatusBadgeFragmentDoc}`;
export type CloseRequestMutationFn = Apollo.MutationFunction<CloseRequestMutation, CloseRequestMutationVariables>;

/**
 * __useCloseRequestMutation__
 *
 * To run a mutation, you first call `useCloseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeRequestMutation, { data, loading, error }] = useCloseRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseRequestMutation(baseOptions?: Apollo.MutationHookOptions<CloseRequestMutation, CloseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseRequestMutation, CloseRequestMutationVariables>(CloseRequestDocument, options);
      }
export type CloseRequestMutationHookResult = ReturnType<typeof useCloseRequestMutation>;
export type CloseRequestMutationResult = Apollo.MutationResult<CloseRequestMutation>;
export type CloseRequestMutationOptions = Apollo.BaseMutationOptions<CloseRequestMutation, CloseRequestMutationVariables>;
export const CloseForBiddingRequestDocument = gql`
    mutation CloseForBiddingRequest($input: CloseRequestForBiddingInput!) {
  closeRequestForBidding(input: $input) {
    request {
      id
      status
      viewerNumActiveQuotes
      ...RequestStatusBadge
    }
  }
}
    ${RequestStatusBadgeFragmentDoc}`;
export type CloseForBiddingRequestMutationFn = Apollo.MutationFunction<CloseForBiddingRequestMutation, CloseForBiddingRequestMutationVariables>;

/**
 * __useCloseForBiddingRequestMutation__
 *
 * To run a mutation, you first call `useCloseForBiddingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseForBiddingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeForBiddingRequestMutation, { data, loading, error }] = useCloseForBiddingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseForBiddingRequestMutation(baseOptions?: Apollo.MutationHookOptions<CloseForBiddingRequestMutation, CloseForBiddingRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseForBiddingRequestMutation, CloseForBiddingRequestMutationVariables>(CloseForBiddingRequestDocument, options);
      }
export type CloseForBiddingRequestMutationHookResult = ReturnType<typeof useCloseForBiddingRequestMutation>;
export type CloseForBiddingRequestMutationResult = Apollo.MutationResult<CloseForBiddingRequestMutation>;
export type CloseForBiddingRequestMutationOptions = Apollo.BaseMutationOptions<CloseForBiddingRequestMutation, CloseForBiddingRequestMutationVariables>;
export const AddRequestBiddersDocument = gql`
    mutation AddRequestBidders($input: AddRequestRecipientsInput!) {
  addRequestRecipients(input: $input) {
    request {
      id
      viewerNumActiveRecipients
    }
    requestRecipients {
      id
    }
  }
}
    `;
export type AddRequestBiddersMutationFn = Apollo.MutationFunction<AddRequestBiddersMutation, AddRequestBiddersMutationVariables>;

/**
 * __useAddRequestBiddersMutation__
 *
 * To run a mutation, you first call `useAddRequestBiddersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRequestBiddersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRequestBiddersMutation, { data, loading, error }] = useAddRequestBiddersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRequestBiddersMutation(baseOptions?: Apollo.MutationHookOptions<AddRequestBiddersMutation, AddRequestBiddersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRequestBiddersMutation, AddRequestBiddersMutationVariables>(AddRequestBiddersDocument, options);
      }
export type AddRequestBiddersMutationHookResult = ReturnType<typeof useAddRequestBiddersMutation>;
export type AddRequestBiddersMutationResult = Apollo.MutationResult<AddRequestBiddersMutation>;
export type AddRequestBiddersMutationOptions = Apollo.BaseMutationOptions<AddRequestBiddersMutation, AddRequestBiddersMutationVariables>;
export const RejectAllQuotesInRequestDocument = gql`
    mutation rejectAllQuotesInRequest($input: RejectAllQuotesInRequestInput!) {
  rejectAllQuotesInRequest(input: $input) {
    request {
      id
      status
      viewerNumActiveQuotes
      ...RequestStatusBadge
    }
  }
}
    ${RequestStatusBadgeFragmentDoc}`;
export type RejectAllQuotesInRequestMutationFn = Apollo.MutationFunction<RejectAllQuotesInRequestMutation, RejectAllQuotesInRequestMutationVariables>;

/**
 * __useRejectAllQuotesInRequestMutation__
 *
 * To run a mutation, you first call `useRejectAllQuotesInRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAllQuotesInRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAllQuotesInRequestMutation, { data, loading, error }] = useRejectAllQuotesInRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectAllQuotesInRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectAllQuotesInRequestMutation, RejectAllQuotesInRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectAllQuotesInRequestMutation, RejectAllQuotesInRequestMutationVariables>(RejectAllQuotesInRequestDocument, options);
      }
export type RejectAllQuotesInRequestMutationHookResult = ReturnType<typeof useRejectAllQuotesInRequestMutation>;
export type RejectAllQuotesInRequestMutationResult = Apollo.MutationResult<RejectAllQuotesInRequestMutation>;
export type RejectAllQuotesInRequestMutationOptions = Apollo.BaseMutationOptions<RejectAllQuotesInRequestMutation, RejectAllQuotesInRequestMutationVariables>;
export const ProvideRequestFeedbackDocument = gql`
    mutation ProvideRequestFeedback($input: ProvideRequestFeedbackInput!) {
  provideRequestFeedback(input: $input) {
    requestRecipient {
      id
      recipientStatus
    }
    request {
      id
      status
      viewerRecipientStatus
      viewerNumActiveQuotes
      ...RequestStatusBadge
    }
  }
}
    ${RequestStatusBadgeFragmentDoc}`;
export type ProvideRequestFeedbackMutationFn = Apollo.MutationFunction<ProvideRequestFeedbackMutation, ProvideRequestFeedbackMutationVariables>;

/**
 * __useProvideRequestFeedbackMutation__
 *
 * To run a mutation, you first call `useProvideRequestFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvideRequestFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provideRequestFeedbackMutation, { data, loading, error }] = useProvideRequestFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProvideRequestFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<ProvideRequestFeedbackMutation, ProvideRequestFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvideRequestFeedbackMutation, ProvideRequestFeedbackMutationVariables>(ProvideRequestFeedbackDocument, options);
      }
export type ProvideRequestFeedbackMutationHookResult = ReturnType<typeof useProvideRequestFeedbackMutation>;
export type ProvideRequestFeedbackMutationResult = Apollo.MutationResult<ProvideRequestFeedbackMutation>;
export type ProvideRequestFeedbackMutationOptions = Apollo.BaseMutationOptions<ProvideRequestFeedbackMutation, ProvideRequestFeedbackMutationVariables>;
export const CreateProjectForRequestDocument = gql`
    mutation CreateProjectForRequest($input: CreateProjectForRequestInput!) {
  createProjectForRequest(input: $input) {
    request {
      id
      viewerRecipientProject {
        id
        viewerPermissions
      }
    }
    project {
      id
      state
    }
  }
}
    `;
export type CreateProjectForRequestMutationFn = Apollo.MutationFunction<CreateProjectForRequestMutation, CreateProjectForRequestMutationVariables>;

/**
 * __useCreateProjectForRequestMutation__
 *
 * To run a mutation, you first call `useCreateProjectForRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectForRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectForRequestMutation, { data, loading, error }] = useCreateProjectForRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectForRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectForRequestMutation, CreateProjectForRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectForRequestMutation, CreateProjectForRequestMutationVariables>(CreateProjectForRequestDocument, options);
      }
export type CreateProjectForRequestMutationHookResult = ReturnType<typeof useCreateProjectForRequestMutation>;
export type CreateProjectForRequestMutationResult = Apollo.MutationResult<CreateProjectForRequestMutation>;
export type CreateProjectForRequestMutationOptions = Apollo.BaseMutationOptions<CreateProjectForRequestMutation, CreateProjectForRequestMutationVariables>;
export const RemoveRequestRecipientDocument = gql`
    mutation RemoveRequestRecipient($input: RemoveRequestRecipientInput!) {
  removeRequestRecipient(input: $input) {
    removedRecipientId
    request {
      id
      viewerNumActiveRecipients
    }
  }
}
    `;
export type RemoveRequestRecipientMutationFn = Apollo.MutationFunction<RemoveRequestRecipientMutation, RemoveRequestRecipientMutationVariables>;

/**
 * __useRemoveRequestRecipientMutation__
 *
 * To run a mutation, you first call `useRemoveRequestRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRequestRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRequestRecipientMutation, { data, loading, error }] = useRemoveRequestRecipientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRequestRecipientMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRequestRecipientMutation, RemoveRequestRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRequestRecipientMutation, RemoveRequestRecipientMutationVariables>(RemoveRequestRecipientDocument, options);
      }
export type RemoveRequestRecipientMutationHookResult = ReturnType<typeof useRemoveRequestRecipientMutation>;
export type RemoveRequestRecipientMutationResult = Apollo.MutationResult<RemoveRequestRecipientMutation>;
export type RemoveRequestRecipientMutationOptions = Apollo.BaseMutationOptions<RemoveRequestRecipientMutation, RemoveRequestRecipientMutationVariables>;
export const CreateQuoteRequestDocument = gql`
    mutation CreateQuoteRequest($input: CreateQuoteRequestInput!) {
  createQuoteRequest(input: $input) {
    request {
      id
    }
  }
}
    `;
export type CreateQuoteRequestMutationFn = Apollo.MutationFunction<CreateQuoteRequestMutation, CreateQuoteRequestMutationVariables>;

/**
 * __useCreateQuoteRequestMutation__
 *
 * To run a mutation, you first call `useCreateQuoteRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteRequestMutation, { data, loading, error }] = useCreateQuoteRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteRequestMutation, CreateQuoteRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteRequestMutation, CreateQuoteRequestMutationVariables>(CreateQuoteRequestDocument, options);
      }
export type CreateQuoteRequestMutationHookResult = ReturnType<typeof useCreateQuoteRequestMutation>;
export type CreateQuoteRequestMutationResult = Apollo.MutationResult<CreateQuoteRequestMutation>;
export type CreateQuoteRequestMutationOptions = Apollo.BaseMutationOptions<CreateQuoteRequestMutation, CreateQuoteRequestMutationVariables>;