/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ContentsContentFragmentDoc } from '../contents/Content.generated';
export type QuoteItemMenuItems_ItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, binding: Types.ItemBinding, agreement: Types.Agreement, contents: Array<{ __typename: 'Content', id: string, title: string, description: string, group: string, previewImageUrl: string, keywords: string, youtubeVideos: Array<{ __typename: 'ContentYoutube', id: string, title: string, description: string, previewImageUrl: string, group: string, videoId: string }>, links: Array<{ __typename: 'ContentLink', id: string, title: string, description: string, previewImageUrl: string, group: string, url: string }>, richTexts: Array<{ __typename: 'ContentRichText', id: string, title: string, description: string, previewImageUrl: string, group: string, richText: any }>, attachments: Array<{ __typename: 'ContentAttachment', id: string, title: string, description: string, group: string, mimeType: string, previewImageUrl: string, url: string }> }>, linkedQuoteTemplate?: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null } | null };

export const QuoteItemMenuItems_ItemFragmentDoc = gql`
    fragment QuoteItemMenuItems_Item on Item {
  id
  type
  binding
  agreement
  contents {
    id
    ...ContentsContent
  }
  linkedQuoteTemplate {
    id
    resolvedAsReadModelVersionNumber
  }
}
    ${ContentsContentFragmentDoc}`;