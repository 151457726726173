import { useApolloClient } from "@apollo/client";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import uploadcare from "uploadcare-widget";
import { dataURLtoFile } from "../../attachments/helpers";
import { useAcceptQuoteMutation } from "../Quotes.generated";
import { MarkQuoteAsAcceptedModal } from "../modals/MarkQuoteAsAcceptedModal";

interface Props {
  docId: string;
  projectId: string;
}

export const MarkQuoteAsAcceptedButton = ({ docId, projectId }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslate(["QuoteEdit", "Global"]);
  const client = useApolloClient();
  const { isMaxPhone } = useScreenWidth();

  const [acceptQuote] = useAcceptQuoteMutation({ client });

  return (
    <ModalOpenButton
      Modal={MarkQuoteAsAcceptedModal}
      modalProps={{
        projectId: projectId,
        docId: docId,
        handleComplete: async ({ place, date, signature }) => {
          let fileToUpload = dataURLtoFile(signature, "signature");
          let upload = uploadcare.fileFrom("object", fileToUpload);

          await new Promise<void>(resolve => {
            upload.done(async (fileInfo: any) => {
              await acceptQuote({
                variables: {
                  input: {
                    projectId,
                    docId,
                    signature: {
                      place: place,
                      date: date.format("YYYY-MM-DD"),
                      signature: {
                        url: fileInfo.cdnUrl,
                        title: fileInfo.name,
                        mimeType: fileInfo.mimeType,
                      },
                    },
                  },
                },
              });

              resolve();
            });
          });

          navigate(`/projects/${projectId}/tasks`);
        },
      }}
    >
      <Button color="primary" variant="contained">
        {isMaxPhone
          ? t("Accept", { ns: "Global" })
          : t("Mark quote as accepted", { ns: "QuoteEdit" })}
      </Button>
    </ModalOpenButton>
  );
};
