import React, { useImperativeHandle, useState } from "react";
import { Attachment } from "../attachments/helpers";
import { PimCsvColumnMappingModal } from "./PimCsvColumnMappingModal";

export interface PimCsvColumnMappingProcessRef {
  start: (attachment: {
    url?: string;
    filename: string;
  }) => Promise<{ [key: string]: string } | null>;
}

interface Props {
  separator?: string;
  title?: string;
  supplierId: string;
}

export const PimCsvColumnMappingProcess = React.forwardRef(
  ({ separator, title, supplierId }: Props, forwardedRef) => {
    const [csvAttachment, setCsvAttachment] = useState<{
      url?: string;
      filename: string;
    } | null>(null);

    const resolveRef = React.useRef<(value: unknown) => void>();

    useImperativeHandle(forwardedRef, () => ({
      start: (attachment: { url?: string; filename: string }) =>
        new Promise(resolve => {
          resolveRef.current = resolve;
          setCsvAttachment(attachment);
        }),
    }));

    return csvAttachment ? (
      <PimCsvColumnMappingModal
        csvUrl={csvAttachment.url}
        csvFilename={csvAttachment.filename}
        supplierId={supplierId}
        handleClose={() => setCsvAttachment(null)}
        handleComplete={async jsonMapping => {
          resolveRef.current?.(jsonMapping);
          resolveRef.current = undefined;
        }}
      />
    ) : null;
  }
);
