import { gql } from "@apollo/client";
import { RichTextValue } from "@msys/ui";
import { Stack, Typography } from "@mui/material";
import { PropertiesGridLabeledValue } from "../../../commons/PropertiesGridLabeledValue";
import { color } from "../../../../common/MuiThemeProvider";
import {
  itemHasProperties,
  useQuoteItemProperties,
} from "../../item-properties/itemProperties";
import { QuoteProductsVisibilityFragment } from "../Quotes.generated";
import { QuotePreviewItemDescription_ItemFragment } from "./QuotePreviewItemDescription.generated";
import { PreviewValue } from "./QuotePreviewValues";

interface Props {
  item: QuotePreviewItemDescription_ItemFragment;
  productsVisibility: QuoteProductsVisibilityFragment;
  columns?: number;
}

export const QuotePreviewItemDescription = ({
  item,
  productsVisibility,
  columns = 2,
}: Props) => {
  const [properties, productProperties] = useQuoteItemProperties(item);

  const ItemDescription =
    item.description ||
    item.pendingChangeAttributes.description ||
    properties.length > 0 ? (
      <Typography variant="body2" component="div">
        {item.description || item.pendingChangeAttributes.description ? (
          <div>
            {item.pendingChangeAttributes.description && (
              <RichTextValue
                key={item.pendingChangeAttributes.description}
                htmlContent={item.pendingChangeAttributes.description}
              />
            )}
            <RichTextValue
              key={item.description}
              htmlContent={item.description}
              style={{
                background: item.pendingChangeAttributes.description
                  ? color.yellowLight
                  : "initial",
                textDecoration: item.pendingChangeAttributes.description
                  ? "line-through"
                  : "none",
                marginBottom: properties.length > 0 ? 8 : 0,
              }}
            />
          </div>
        ) : null}
        {properties.length > 0 ? (
          <PropertiesGridLabeledValue
            properties={properties}
            columns={columns}
          />
        ) : null}
      </Typography>
    ) : null;

  const ProductTitle =
    (item.product &&
      item.product.title &&
      item.product.titleClientVisibility !== "hide" &&
      productsVisibility.showTitle) ||
    (item.pendingChangeAttributes.productTitle &&
      item.pendingChangeAttributes.productTitleClientVisibility !== "hide" &&
      productsVisibility.showTitle) ? (
      <Typography variant="h4" style={{ fontWeight: "normal" }}>
        <PreviewValue
          value={
            item.product &&
            item.product.title &&
            item.product.titleClientVisibility !== "hide"
              ? item.product.title
              : ""
          }
          changedValue={
            item.pendingChangeAttributes?.productTitle &&
            item.pendingChangeAttributes?.productTitleClientVisibility !==
              "hide"
              ? item.pendingChangeAttributes.productTitle
              : ""
          }
        />
      </Typography>
    ) : null;

  const ProductDescription =
    (item.product &&
      item.product.description &&
      item.product.descriptionClientVisibility !== "hide" &&
      productsVisibility.showDescription) ||
    (item.pendingChangeAttributes.productDescription &&
      item.pendingChangeAttributes.productDescriptionClientVisibility !==
        "hide" &&
      productsVisibility.showDescription) ||
    productProperties.length > 0 ? (
      <Typography variant="body2" component="div">
        {(item.product &&
          item.product.description &&
          item.product.descriptionClientVisibility !== "hide") ||
        (item.pendingChangeAttributes.productDescription &&
          item.pendingChangeAttributes.productDescriptionClientVisibility !==
            "hide") ? (
          <div>
            {item.pendingChangeAttributes.productDescription &&
              item.pendingChangeAttributes
                .productDescriptionClientVisibility !== "hide" && (
                <RichTextValue
                  key={item.pendingChangeAttributes.productDescription}
                  htmlContent={item.pendingChangeAttributes.productDescription}
                />
              )}
            <RichTextValue
              key={item.product?.description ?? ""}
              htmlContent={
                item.product &&
                item.product.description &&
                item.product?.descriptionClientVisibility !== "hide"
                  ? item.product?.description
                  : ""
              }
              style={{
                background: item.pendingChangeAttributes.productDescription
                  ? color.yellowLight
                  : "initial",
                textDecoration: item.pendingChangeAttributes.productDescription
                  ? "line-through"
                  : "none",
                marginBottom: productProperties.length > 0 ? 8 : 0,
              }}
            />
          </div>
        ) : null}
        {productProperties.length > 0 ? (
          <PropertiesGridLabeledValue
            properties={productProperties}
            columns={columns}
          />
        ) : null}
      </Typography>
    ) : null;

  return ItemDescription || ProductTitle || ProductDescription ? (
    <Stack direction="column" spacing={1}>
      {ItemDescription}
      {ProductTitle}
      {ProductDescription}
    </Stack>
  ) : null;
};

export const quoteItemHasDescription = (
  item: QuotePreviewItemDescription_ItemFragment,
  productsVisibility: QuoteProductsVisibilityFragment
): boolean => {
  return Boolean(
    itemHasProperties(item) ||
      item.description ||
      item.pendingChangeAttributes.description ||
      (item.product &&
        item.product.title &&
        item.product.titleClientVisibility !== "hide" &&
        productsVisibility.showTitle) ||
      (item.pendingChangeAttributes.productTitle &&
        item.pendingChangeAttributes.productTitleClientVisibility !== "hide" &&
        productsVisibility.showTitle) ||
      (item.product &&
        item.product.description &&
        item.product.descriptionClientVisibility !== "hide" &&
        productsVisibility.showDescription) ||
      (item.pendingChangeAttributes.productDescription &&
        item.pendingChangeAttributes.productDescriptionClientVisibility !==
          "hide" &&
        productsVisibility.showDescription)
  );
};
