import { gql } from "@apollo/client";
import { Modal, RadioGroup } from "@msys/ui";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { PdfTemplateSelectModal_OrganisationContractingPdfFragment } from "./PdfTemplateSelectModal.generated";
import { useTranslate } from "@tolgee/react";

export function PdfTemplateSelectModal({
  handleClose,
  handleComplete,
  organisationQuoteTemplates,
}: {
  handleClose: () => void;
  handleComplete: (pdfTemplateId: string | null) => void;
  organisationQuoteTemplates: PdfTemplateSelectModal_OrganisationContractingPdfFragment[];
}) {
  const { t } = useTranslate(["Global", "QuoteSend"]);

  const formId = React.useMemo(() => uniqueId(), []);

  const options = [
    {
      label: t("Standard", {
        ns: "QuoteSend",
      }),
      value: "standard",
    },
    ...organisationQuoteTemplates.map(template => ({
      label: template.title,
      value: template.id,
    })),
  ];

  return (
    <Formik
      initialValues={{ selectedPdfTemplateId: "" }}
      onSubmit={async values => {
        handleComplete(
          values.selectedPdfTemplateId === "standard"
            ? null
            : values.selectedPdfTemplateId
        );
      }}
      validationSchema={Yup.object().shape({
        selectedPdfTemplateId: Yup.string().required(),
      })}
    >
      {formikProps => (
        <Modal
          title={t("Select PDF", {
            ns: "QuoteSend",
          })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Confirm", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <RadioGroup
              name={"selectedPdfTemplateId"}
              options={options}
              value={formikProps.values.selectedPdfTemplateId}
              onChange={value => {
                formikProps.setFieldValue("selectedPdfTemplateId", value);
              }}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export function PdfTemplateRadioGroup({
  label,
  value,
  onChange,
  organisationQuoteTemplates,
}: {
  label?: string;
  value: string | null;
  onChange: (newValue: string | null) => void;
  organisationQuoteTemplates: PdfTemplateSelectModal_OrganisationContractingPdfFragment[];
}) {
  const { t } = useTranslate(["Global", "QuoteSend"]);
  const options = [
    {
      label: t("Standard", { ns: "QuoteSend" }),
      value: "standard",
    },
    ...organisationQuoteTemplates.map(template => ({
      label: template.title,
      value: template.id,
    })),
  ];
  return (
    <RadioGroup
      label={label}
      options={options}
      value={value ?? "standard"}
      onChange={value => {
        onChange(value === "standard" ? null : value);
      }}
    />
  );
}
