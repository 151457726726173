import { useApolloClient } from "@apollo/client";
import { LoadingSpinner } from "@msys/ui";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { InvitationState, namedOperations } from "../../clients/graphqlTypes";
import { useLanguages } from "../../common/translations/useLanguages";
import { OrganisationAvatar } from "../features/organisations/OrganisationAvatar";
import { OrganisationAvatarFragment } from "../features/organisations/OrganisationAvatar.generated";
import { ButtonDivider } from "./ButtonDivider";
import { LoginButton } from "../auth/LoginButton";
import { useAcceptOrganisationMembershipInvitationMutation } from "./OrganisationMembershipInvitation.generated";
import { RegisterButton } from "../auth/RegisterButton";
import { useAuth } from "../auth/useAuth";
import { useInvitationToken } from "./useInvitationToken";
import { useSelectedUser } from "../auth/useSelectedUser";

interface Prefill {
  firstname: string;
  familyname: string;
  title: string;
  email: string;
}
interface Invitation {
  invitationToken: string;
  status: InvitationState;
  organisation: OrganisationAvatarFragment;
  contact: Prefill;
}

interface Props {
  invitation: Invitation;
  isAuthenticated: boolean;
}

export const OrganisationMembershipInvitation = ({
  invitation,
  isAuthenticated,
}: Props) => {
  const { t } = useTranslate(["Global"]);
  const { currentLanguage } = useLanguages();
  const { setSelectedUserId } = useSelectedUser();
  const { remove: removeInvitationToken } = useInvitationToken();
  const { auth } = useAuth();

  const client = useApolloClient();
  const [acceptOrgmembershipInvitation] =
    useAcceptOrganisationMembershipInvitationMutation({
      client,
      refetchQueries: [namedOperations.Query.Me],
      awaitRefetchQueries: true,
    });

  const authenticatedUserEmail = auth.idTokenParsed?.email;

  const authenticatedUserIsInvitee =
    authenticatedUserEmail === invitation.contact.email;

  const accept = React.useCallback(async () => {
    const { data } = await acceptOrgmembershipInvitation({
      variables: {
        input: {
          token: invitation.invitationToken,
          email: invitation.contact.email,
          locale: currentLanguage.locale,
        },
      },
    });
    if (!data) throw new Error("Failed to accept invitation!");
    removeInvitationToken();
    setSelectedUserId(data.acceptOrganisationMembershipInvitation.user.id);
  }, [
    acceptOrgmembershipInvitation,
    currentLanguage.locale,
    invitation.contact.email,
    invitation.invitationToken,
    removeInvitationToken,
    setSelectedUserId,
  ]);

  React.useEffect(() => {
    if (authenticatedUserIsInvitee) {
      accept();
    }
  }, [
    accept,
    acceptOrgmembershipInvitation,
    authenticatedUserIsInvitee,
    currentLanguage.locale,
    invitation.contact.email,
    invitation.invitationToken,
  ]);

  if (invitation.status !== "PENDING") {
    return (
      <Stack alignItems="center" spacing={6}>
        <Typography variant="h3">
          {t("Sorry, this invitation is no longer valid!", {
            ns: "Global",
          })}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          onClick={() => {
            removeInvitationToken();
          }}
        >
          {t("Return to MeisterSystems", { ns: "Global" })}
        </Button>
      </Stack>
    );
  }

  if (!isAuthenticated) {
    return (
      <OrganisationMembershipInvitationUnAuhenticated invitation={invitation} />
    );
  }

  if (!authenticatedUserIsInvitee) {
    return (
      <OrganisationMembershipInvitationInvalidAccount invitation={invitation} />
    );
  }

  return <LoadingSpinner />;
};

const OrganisationMembershipInvitationUnAuhenticated = ({
  invitation,
}: Pick<Props, "invitation">) => {
  const { t } = useTranslate(["Global"]);

  const prefill = React.useMemo(() => {
    return getPrefillData(invitation.contact);
  }, [invitation.contact]);

  return (
    <Stack direction="column" alignItems={"center"} spacing={6}>
      <OrganisationAvatar
        organisationAvatar={invitation.organisation}
        size="xl"
      />
      <Typography variant="h3" align="center">
        {t(
          "Please sign in to join the {inviterOrgName} account on MeisterSystems",
          {
            ns: "Global",
            inviterOrgName: invitation.organisation.title,
          }
        )}
      </Typography>
      <Stack direction={"column"} spacing={2} minWidth={300}>
        <RegisterButton prefill={prefill} color="primary" />
        <ButtonDivider label={t("Or", { ns: "Global" })} />
        <LoginButton variant="text" color="secondary" prefill={prefill} />
      </Stack>
    </Stack>
  );
};

const OrganisationMembershipInvitationInvalidAccount = ({
  invitation,
}: Pick<Props, "invitation">) => {
  const { auth } = useAuth();
  const { currentLanguage } = useLanguages();
  const { t } = useTranslate(["Global"]);
  const { remove: removeInvitationToken } = useInvitationToken();

  const prefill = React.useMemo(() => {
    return getPrefillData(invitation.contact);
  }, [invitation.contact]);

  return (
    <Stack spacing={6} alignItems={"center"}>
      <OrganisationAvatar
        organisationAvatar={invitation.organisation}
        size="xl"
      />
      <Typography variant="h3" align="center">
        {t(
          "You must be logged in with {inviteeEmail} to join {inviterOrgName}'s team on MeisterSystems",
          {
            ns: "Global",
            inviterOrgName: invitation.organisation.title,
            inviteeEmail: invitation.contact.email,
          }
        )}
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        alignItems={"stretch"}
        minWidth={300}
      >
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={async () => {
            const url = new URL(
              auth.createRegisterUrl({ locale: currentLanguage.locale })
            );
            if (prefill) {
              url.searchParams.set("email", prefill.email);
              url.searchParams.set("firstName", prefill.firstName);
              url.searchParams.set("lastName", prefill.lastName);
              url.searchParams.set(
                "user.attributes.salutation",
                prefill.salutation
              );
            }

            await auth.logout({
              redirectUri: url.toString(),
            });
          }}
        >
          {t("Log out", { ns: "Global" })}
        </Button>
        <ButtonDivider label={t("Or", { ns: "Global" })} />
        <Button
          color="secondary"
          onClick={() => {
            removeInvitationToken();
          }}
        >
          {t("Return to MeisterSystems", { ns: "Global" })}
        </Button>
      </Stack>
    </Stack>
  );
};

function getPrefillData(contact: Prefill) {
  const firstName = contact.firstname;
  const lastName = contact.familyname;
  const email = contact.email;
  const salutation = contact.title;

  return {
    firstName,
    lastName,
    email,
    salutation,
  };
}
