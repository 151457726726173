import {
  DecisionBehaviorOfSubitems,
  ItemType,
} from "../../../../clients/graphqlTypes";

export function itemHasEstimatedCalculationBox<
  T extends {
    type: ItemType;
    decisionBehaviorOfSubitems: DecisionBehaviorOfSubitems;
    isRootItem: boolean;
  }
>(item: T) {
  return (
    item.type === "paid" ||
    (item.type === "section" && !item.isRootItem) ||
    (item.type === "decision" &&
      item.decisionBehaviorOfSubitems === "SELECT_MANY")
  );
}
