import { StyledComponent } from "@emotion/styled";
import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import React from "react";
import { transientOptions } from "../../styles";

export const TreeTitle: StyledComponent<
  MUIStyledCommonProps<Theme> & {
    $depth: number;
    $bold: boolean;
  },
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled(
  "div",
  transientOptions
)<{
  $depth: number;
  $bold: boolean;
}>(
  ({ $depth, $bold }) => `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
  font-size: ${getTitleFontSize($depth)};
  font-weight: ${$bold ? "bold" : "normal"};
`
);

TreeTitle.defaultProps = {
  className: "msys-tree-title",
};

function getTitleFontSize(depth: number) {
  if (depth === 0) return "20px";
  if (depth === 1) return "20px";
  if (depth === 2) return "18px";
  return "16px";
}
