/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestSupplierOrganisationMutationVariables = Types.Exact<{
  input: Types.RequestSupplierOrganisationInput;
}>;


export type RequestSupplierOrganisationMutation = { requestSupplierOrganisation: { __typename: 'RequestSupplierOrganisationResult', ok: boolean } };


export const RequestSupplierOrganisationDocument = gql`
    mutation RequestSupplierOrganisation($input: RequestSupplierOrganisationInput!) {
  requestSupplierOrganisation(input: $input) {
    ok
  }
}
    `;
export type RequestSupplierOrganisationMutationFn = Apollo.MutationFunction<RequestSupplierOrganisationMutation, RequestSupplierOrganisationMutationVariables>;

/**
 * __useRequestSupplierOrganisationMutation__
 *
 * To run a mutation, you first call `useRequestSupplierOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSupplierOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSupplierOrganisationMutation, { data, loading, error }] = useRequestSupplierOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestSupplierOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<RequestSupplierOrganisationMutation, RequestSupplierOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestSupplierOrganisationMutation, RequestSupplierOrganisationMutationVariables>(RequestSupplierOrganisationDocument, options);
      }
export type RequestSupplierOrganisationMutationHookResult = ReturnType<typeof useRequestSupplierOrganisationMutation>;
export type RequestSupplierOrganisationMutationResult = Apollo.MutationResult<RequestSupplierOrganisationMutation>;
export type RequestSupplierOrganisationMutationOptions = Apollo.BaseMutationOptions<RequestSupplierOrganisationMutation, RequestSupplierOrganisationMutationVariables>;