import { gql, useApolloClient } from "@apollo/client";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DEFAULT_END_WORK_DAY,
  DEFAULT_START_WORK_DAY,
  getDataOrNull,
} from "@msys/common";
import {
  ellipsisStyle,
  ModalOpenButton,
  SearchInput,
  TextWithBreaks,
} from "@msys/ui";
import { Box, Icon, IconButton, Typography } from "@mui/material";
import {
  DragAndDrop,
  Inject,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  TimelineMonth,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import { ActionEventArgs } from "@syncfusion/ej2-schedule";
import { EventRenderedArgs } from "@syncfusion/ej2-schedule/src/schedule/base/interface";
import { isEqual, noop } from "lodash";
import moment from "moment";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { useUserData } from "../../auth/useUserData";
import { Stack } from "../../commons/layout/Stack";
import { color } from "../../../common/MuiThemeProvider";
import { OrganisationRole as GQLOrganisationRole } from "../../../clients/graphqlTypes";
import { PlanningAbsenceFragment } from "../../main-routes/planning/PlanningAbsences.generated";
import { PlanningResourcesFragment } from "../planning/PlanningSchedule.generated";
import { useAbsenceScheduleQuery } from "./AbsenceSchedule.generated";
import { useTranslate } from "@tolgee/react";
import { MomentRange } from "../planning/helpers";
import { ResourceFilterChips } from "../planning/ResourceFilterChips";
import { ResourceFilterModal } from "../planning/ResourceFilterModal";
import { useResourceFilter } from "../planning/useResourceFilter";
import { UserAvatar } from "../users/UserAvatar";
import { useOrganisationRoles } from "../users/useRoles";
import { absenceColors } from "./helpers";
import { useAbsenceReasons } from "./useAbsenceReasons";

const SCHEDULE_GROUP = { resources: ["Workers"] };
const SCHEDULE_STYLE = { flexGrow: 1, flexShrink: 1 };

interface Props {
  roles: GQLOrganisationRole[] | undefined;
  resources: PlanningResourcesFragment[] | undefined;
  startWorkDay?: string;
  endWorkDay?: string;
  view: "TimelineMonth" | "TimelineWeek";
}

const _AbsenceSchedule: FC<Props> = ({
  roles,
  resources,
  startWorkDay = DEFAULT_START_WORK_DAY,
  endWorkDay = DEFAULT_END_WORK_DAY,
  view,
}) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Absences", "Global"]);

  const [range, setRange] = React.useState<MomentRange>([undefined, undefined]);

  const schedulerRef = useRef<ScheduleComponent>(null);

  const updateRange = useRef<() => void>();
  updateRange.current = () => {
    if (!schedulerRef.current) return;
    const currentViewDates = schedulerRef.current.getCurrentViewDates();
    const startDate = currentViewDates[0];
    const endDate = currentViewDates[currentViewDates.length - 1];
    setRange([moment(startDate).startOf("day"), moment(endDate).endOf("day")]);
  };

  const onCreated = useCallback(() => {
    setTimeout(() => {
      updateRange.current?.();
    });
  }, []);

  const [resourceSearch, setResourceSearch] = useState<string>("");

  const {
    resourceList,
    setResourceList,
    resourceOrganisationRoleIds,
    setResourceOrganisationRoleIds,
  } = useResourceFilter(viewer.organisation.id, undefined, "absences-calendar");

  const client = useApolloClient();

  const query = useAbsenceScheduleQuery({
    client,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      rangeStart: range[0] ? moment(range[0]).format("YYYY-MM-DD") : undefined,
      rangeEnd: range[1] ? moment(range[1]).format("YYYY-MM-DD") : undefined,
      ...(resourceList ? { filterByWhoIds: resourceList } : undefined),
      ...(resourceOrganisationRoleIds
        ? { filterByRoleIds: resourceOrganisationRoleIds }
        : undefined),
    },
    skip: !range[0] || !range[1],
  });

  const absences: PlanningAbsenceFragment[] = useMemo(
    () => getDataOrNull(query?.data?.absences)?.edges.map(e => e.node) ?? [],
    [query?.data?.absences]
  );

  const { getOrganisationRoleTitle } = useOrganisationRoles();
  const { absenceReasonLabels } = useAbsenceReasons();

  const workHours = useMemo(
    () => ({ start: startWorkDay, end: endWorkDay }),
    [startWorkDay, endWorkDay]
  );

  const scheduleData = useMemo(() => {
    const absenceToAppointment = (a: PlanningAbsenceFragment) => ({
      id: a.id,
      absence: a,
      title: absenceReasonLabels[a.reason],
      description: a.note,
      backgroundColor: absenceColors[a.reason],
      WhoId: a.who.id,
      WhoName: a.who.fullname,
      location: undefined,
      startTime: moment(a.from).startOf("day").local().toDate(),
      endTime: moment(a.till).endOf("day").local().toDate(),
      isAllDay: true,
      isReadonly: true,
    });

    const sessions = [...(absences ?? []).map(a => absenceToAppointment(a))];

    if (sessions.length === 0) return;

    return sessions;
  }, [absences, absenceReasonLabels]);

  const eventRendered = useCallback((args: EventRenderedArgs) => {
    if (!args) return;
    const data = args.data as Exclude<typeof scheduleData, undefined>[0];
    if (data.backgroundColor)
      args.element.style.backgroundColor = data.backgroundColor;
  }, []);

  const workersData = useMemo(
    () =>
      (resources ?? [])
        .filter(r => {
          return (
            (!resourceList || resourceList.includes(r.id)) &&
            (!resourceSearch ||
              r.fullname
                .toLowerCase()
                .includes(resourceSearch.toLowerCase())) &&
            (!resourceOrganisationRoleIds ||
              r.roles.some(r => resourceOrganisationRoleIds.includes(r.id)))
          );
        })
        .map(r => ({
          ...r,
          id: r.id,
          color: color.red,
          roles: getOrganisationRoleTitle(r.roles),
        }))
        .sort((a, b) => a.familyname.localeCompare(b.familyname)),
    [
      resources,
      resourceList,
      resourceSearch,
      resourceOrganisationRoleIds,
      getOrganisationRoleTitle,
    ]
  );

  const eventSettings = useMemo(() => {
    return {
      allowAdding: false,
      allowDeleting: false,
      allowEditing: false,
      enableTooltip: true,
      enableMaxHeight: false,
      rowAutoHeight: true,
      dataSource: scheduleData,
      fields: {
        id: "id",
        subject: { name: "title" },
        description: { name: "description" },
        isAllDay: { name: "isAllDay" },
        isReadonly: "isReadonly",
        startTime: { name: "startTime" },
        endTime: { name: "endTime" },
      },
      template: EventTemplate,
    };
  }, [scheduleData]);

  return (
    <Stack flexDirection="column" height="100%" flexGrow={1} flexShrink={1}>
      <Stack alignItems="center" flexGrow={0} flexShrink={0}>
        <SearchInput
          placeholder={t("Search", {
            ns: "Global",
          })}
          searchTerm={resourceSearch}
          onChangeSearchTerm={setResourceSearch}
        />

        <ModalOpenButton
          Modal={ResourceFilterModal}
          modalProps={{
            useAvailability: false,
            usePinnedResources: false,
            resourceOnTop: undefined,
            resourceAvailability: undefined,
            resourceMaxDistance: undefined,
            resources: resources ?? [],
            roles: roles ?? [],
            resourceList,
            resourceOrganisationRoleIds,
            handleComplete: ({
              resourceList: newResourceList,
              resourceOrganisationRoleIds: newResourceOrganisationRoleIds,
            }) => {
              if (!isEqual(resourceList, newResourceList))
                setResourceList(newResourceList);
              if (
                !isEqual(
                  resourceOrganisationRoleIds,
                  newResourceOrganisationRoleIds
                )
              )
                setResourceOrganisationRoleIds(newResourceOrganisationRoleIds);
            },
          }}
        >
          <IconButton color="primary" size="extra-small">
            <Icon fontSize="small">
              <FontAwesomeIcon icon={faFilter} />
            </Icon>
          </IconButton>
        </ModalOpenButton>

        <ResourceFilterChips
          resources={resources ?? []}
          roles={roles ?? []}
          resourceList={resourceList}
          resourceOrganisationRoleIds={resourceOrganisationRoleIds}
          resourceAvailability={undefined}
          resourceMaxDistance={undefined}
          setResourceList={setResourceList}
          setResourceOrganisationRoleIds={setResourceOrganisationRoleIds}
          setResourceAvailability={noop}
          setResourceMaxDistance={noop}
        />
      </Stack>
      <Box
        flexGrow={1}
        flexShrink={1}
        position="relative"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
      >
        <ScheduleComponent
          ref={schedulerRef}
          showTimeIndicator={false}
          enablePersistence
          rowAutoHeight
          height="100%"
          width="100%"
          style={SCHEDULE_STYLE}
          startHour={startWorkDay}
          endHour={endWorkDay}
          workHours={workHours}
          group={SCHEDULE_GROUP}
          currentView={view}
          // popupOpen={onPopupOpen}
          // popupClose={onPopupClose}
          // actionBegin={actionBegin}
          actionComplete={(args: ActionEventArgs | undefined) => {
            if (args?.requestType === "dateNavigate") {
              // This block is executed before previous and next navigation
              updateRange.current?.();
            }
          }}
          resourceHeaderTemplate={ResourceHeaderTemplate}
          created={onCreated}
          // actionComplete={onActionComplete}
          eventRendered={eventRendered}
          //@ts-ignore Library mistake in typing
          quickInfoTemplates={QuickInfoTemplate}
          // editorTemplate={EditorTemplate}
          // @ts-ignore Library mistake in typing
          eventSettings={eventSettings}
        >
          <Inject
            services={[TimelineViews, TimelineMonth, DragAndDrop, Resize]}
          />
          <ResourcesDirective>
            <ResourceDirective
              field="WhoId"
              title="Workers"
              name="Workers"
              allowMultiple={true}
              dataSource={workersData}
              textField="fullname"
              idField="id"
              colorField="color"
            />
          </ResourcesDirective>
          <ViewsDirective>
            <ViewDirective
              firstDayOfWeek={1}
              option="TimelineWeek"
              timeScale={{
                interval: 60 * 24,
                enable: true,
              }}
            />
            <ViewDirective option="TimelineMonth" />
          </ViewsDirective>
        </ScheduleComponent>
      </Box>
    </Stack>
  );
};

const EventTemplate = (props: any) => {
  return (
    <div className="e-inner-wrap">
      <div className="e-subject">{props.title}</div>
      <div className="e-description">
        <TextWithBreaks text={props.description} />
      </div>
    </div>
  );
};

const ResourceHeaderTemplate = (props: any) => {
  return (
    <Box pr={2} style={ellipsisStyle}>
      <Stack alignItems="center" style={ellipsisStyle}>
        <UserAvatar userAvatar={props.resourceData} size="s" />
        <Box style={ellipsisStyle}>
          <Typography style={ellipsisStyle}>
            {props.resourceData.fullname}
          </Typography>
          <Typography
            style={ellipsisStyle}
            variant="caption"
            color="textSecondary"
          >
            {props.resourceData.roles}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

const QuickInfoTemplate = {
  content(props: any) {
    return (
      <div className="e-event-content e-template">
        <div className="e-subject-wrap">
          <div className="e-date-time">
            <div className="e-date-time-icon e-icons"></div>
            <div className="e-date-time-wrapper e-text-ellipsis">
              <div className="e-date-time-details e-text-ellipsis">
                {moment(props.startTime).format("L")} –{" "}
                {moment(props.endTime).format("L")}
              </div>
            </div>
          </div>
          {props.WhoName ? (
            <div className="e-resource">
              <div className="e-resource-icon e-icons"></div>
              <div className="e-resource-details e-text-ellipsis">
                {props.WhoName}
              </div>
            </div>
          ) : (
            ""
          )}
          {props.description ? (
            <div className="e-description">
              <TextWithBreaks text={props.description} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  },
};

export const AbsenceSchedule = React.memo(_AbsenceSchedule);
