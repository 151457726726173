import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";

interface Props {
  type?: "icon" | "button";
  areAllItemsExpanded: boolean;
  setAllItemsExpanded: (expanded: boolean) => void;
}

export const TreeToggleAllExpandedButton = ({
  areAllItemsExpanded,
  setAllItemsExpanded,
  type = "icon",
}: Props) => {
  const { t } = useTranslate("ItemTree");

  const getText = (state: boolean) => {
    switch (state) {
      case true:
        return t("Collapse all");
      case false:
        return t("Expand all");
    }
  };

  const getIcon = (state: boolean) => {
    switch (state) {
      case true:
        return <UnfoldLessIcon />;
      case false:
        return <UnfoldMoreIcon />;
    }
  };

  const toggleExpandedAll = () => {
    setAllItemsExpanded(!areAllItemsExpanded);
  };

  return type === "icon" ? (
    <Tooltip title={getText(areAllItemsExpanded)}>
      <IconButton
        style={{ width: 30, height: 30, flexShrink: 0, marginLeft: -3 }}
        onClick={toggleExpandedAll}
        size="small"
        color="primary"
      >
        {getIcon(areAllItemsExpanded)}
      </IconButton>
    </Tooltip>
  ) : type === "button" ? (
    <Button
      onClick={toggleExpandedAll}
      size="small"
      color="primary"
      variant="text"
      startIcon={getIcon(areAllItemsExpanded)}
    >
      {getText(areAllItemsExpanded)}
    </Button>
  ) : (
    <></>
  );
};
