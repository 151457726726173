import { styled } from "@mui/material/styles";
import React from "react";
import { transientOptions } from "../../styles";
import { Theme } from "@mui/material";
import { StyledComponent } from "@emotion/styled";
import { MUIStyledCommonProps } from "@mui/system";

export const TreeDataContainer: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled("div")(`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 11px;
  line-height: 15px;
  flex-grow: 1;
  & > * + * {
    margin-left: 8px;
  }
`);

export const TreeDataIcon: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >,
  {}
> = styled("span")(`
  display: inline-flex;
  height: 12px;
  width: 12px;
  line-height: 12px;
  position: relative;
  top: 2px;
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`);

export const TreeDataWrapper: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled("div")(`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > *:not(:empty) + *:not(:empty) {
    margin-left: 8px;
  }
`);

export const TreeDataIconsWrapper: StyledComponent<
  MUIStyledCommonProps<Theme>,
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >,
  {}
> = styled("span")(`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 12px;
  line-height: 12px;
  & > *:not(:empty) + *:not(:empty) {
    margin-left: 4px;
  }
`);

export const TreeDataPath: StyledComponent<
  MUIStyledCommonProps<Theme> & { $depth: number },
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >,
  {}
> = styled(
  "span",
  transientOptions
)<{ $depth: number }>(`
  line-height: 1em;
  font-size: 0.75em;
  margin-right: 3px;
`);
