import { DebouncedSearchInput, SearchInput, Select } from "@msys/ui";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Stack, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormik } from "formik";
import React from "react";
import { useKey, useUpdateEffect } from "react-use";
import * as Yup from "yup";
import {
  ProductSearchType,
  ProductSearchTypeOption,
} from "../filters/useProductSearchTypes";

export function ProductSearchInputWithAutoSubmit({
  productSearchType,
  productSearchTypeOptions,
  searchTerm,
  setSearchTerm,
  setProductSearchType,
}: {
  productSearchTypeOptions: ProductSearchTypeOption[];
  productSearchType: ProductSearchType;
  setProductSearchType: (value: ProductSearchType) => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { t } = useTranslate(["Global", "ProductSearch"]);
  return (
    <Stack direction={"row"} flex={1} spacing={1}>
      <Select
        label={t("Search In", { ns: "ProductSearch" })}
        options={productSearchTypeOptions}
        value={productSearchType}
        onChange={setProductSearchType}
        style={{ maxWidth: 150 }}
        size="extra-small"
      />
      <DebouncedSearchInput
        defaultValue={searchTerm}
        placeholder={t("Search", { ns: "Global" })}
        onChangeSearchTerm={setSearchTerm}
        autoFocus
        style={{ flexGrow: 1, maxWidth: "600px" }}
      />
    </Stack>
  );
}

export interface SearchInputFormValues {
  productSearchType: ProductSearchType;
  searchTerm: string;
}

export function ProductSearchInputWithManualForm({
  productSearchType,
  productSearchTypeOptions,
  searchTerm,
  setSearchTerm,
  setProductSearchType,
  searchEnabled,
  setSearchEnabled,
  handleSubmit,
}: {
  productSearchTypeOptions: ProductSearchTypeOption[];
  productSearchType: ProductSearchType;
  setProductSearchType: (value: ProductSearchType) => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchEnabled: boolean;
  setSearchEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?(values: SearchInputFormValues): Promise<void> | void;
}) {
  const { t } = useTranslate(["Global", "ProductSearch"]);
  const theme = useTheme();

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const formik = useFormik<SearchInputFormValues>({
    enableReinitialize: true,
    initialValues: { searchTerm, productSearchType },
    validationSchema: Yup.object().shape({
      searchTerm: Yup.string(),
      productSearchType: Yup.string(),
    }),
    onSubmit: async values => {
      if (productSearchType !== values.productSearchType)
        setProductSearchType(values.productSearchType);
      setSearchTerm(values.searchTerm);
      formRef.current?.blur();
      if (document.activeElement && document.activeElement !== document.body)
        (document.activeElement as HTMLElement)?.blur();
      await handleSubmit?.(values);
    },
  });
  const [focused, setFocused] = React.useState<boolean>(false);
  const blurTimeoutId = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  const onFocus = () => {
    if (blurTimeoutId.current) clearTimeout(blurTimeoutId.current);
    if (!focused) setFocused(true);
  };
  const onBlur = () => {
    if (blurTimeoutId.current) clearTimeout(blurTimeoutId.current);
    blurTimeoutId.current = setTimeout(() => {
      setFocused(false);
    }, 250);
  };

  useUpdateEffect(() => {
    setSearchEnabled(focused);
  }, [focused]);

  useKey(
    "Escape",
    () => {
      if (focused) {
        formRef.current?.blur();
        if (document.activeElement && document.activeElement !== document.body)
          (document.activeElement as HTMLElement)?.blur();
      }
    },
    { event: "keydown" },
    [focused]
  );

  return (
    <form
      ref={formRef}
      onSubmit={async e => {
        e.preventDefault();
        await formik.submitForm();
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        overflow: "hidden",
        transition: "width 0.4s ease-out",
        alignItems: "center",
        ...(focused
          ? {
              position: "absolute",
              width: "100%",
              left: 0,
              right: 0,
              backgroundColor: theme.palette.common.white,
              zIndex: 2,
            }
          : undefined),
      }}
    >
      <Box
        display="flex"
        flexShrink={1}
        flexGrow={1}
        maxWidth={focused ? "120px" : 0}
        width={focused ? "120px" : 0}
        mr={focused ? 0.5 : 0}
        sx={{
          transition: "width 0.2s ease-out, ease-out, margin 0.2s ease-out",
        }}
      >
        <Select
          label={t("Search In", { ns: "ProductSearch" })}
          options={productSearchTypeOptions}
          value={formik.values.productSearchType}
          onChange={type => formik.setFieldValue("productSearchType", type)}
          size="extra-small"
          style={{ width: 120 }}
        />
      </Box>
      <SearchInput
        searchTerm={formik.values.searchTerm}
        onChangeSearchTerm={term => formik.setFieldValue("searchTerm", term)}
        placeholder={t("Search", { ns: "Global" })}
        autoFocus={false}
        sx={theme => ({
          flexGrow: 1,
          ...(!focused
            ? { maxWidth: theme.layout.searchFieldWidth.lg }
            : undefined),
        })}
      />
      <Box
        flexGrow={0}
        flexShrink={0}
        width={focused ? "auto" : 0}
        ml={focused ? 0.5 : 0}
        display={focused ? "flex" : "none"}
        sx={{
          transition:
            "width 0.2s ease-out, max-width 0.2s ease-out, margin 0.2s ease-out",
        }}
      >
        <Button
          size="extra-small"
          variant="contained"
          color="primary"
          type="submit"
          sx={{ minHeight: "30px" }}
        >
          <SearchIcon />
        </Button>
      </Box>
    </form>
  );
}
