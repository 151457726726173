import { ReactComponent as AcUnitFilled } from "@material-design-icons/svg/filled/ac_unit.svg";
import { ReactComponent as BathroomFilled } from "@material-design-icons/svg/filled/bathroom.svg";
import { ReactComponent as CountertopsFilled } from "@material-design-icons/svg/filled/countertops.svg";
import { ReactComponent as ElectricBoltFilled } from "@material-design-icons/svg/filled/electric_bolt.svg";
import { ReactComponent as EngineeringFilled } from "@material-design-icons/svg/filled/engineering.svg";
import { ReactComponent as HandymanFilled } from "@material-design-icons/svg/filled/handyman.svg";
import { ReactComponent as HardwareFilled } from "@material-design-icons/svg/filled/hardware.svg";
import { ReactComponent as HeatPumpFilled } from "@material-design-icons/svg/filled/heat_pump.svg";
import { ReactComponent as HvacFilled } from "@material-design-icons/svg/filled/hvac.svg";
import { ReactComponent as LocalOfferFilled } from "@material-design-icons/svg/filled/local_offer.svg";
import { ReactComponent as MoreFilled } from "@material-design-icons/svg/filled/more.svg";
import { ReactComponent as RoofingFilled } from "@material-design-icons/svg/filled/roofing.svg";
import { ReactComponent as SolarPowerFilled } from "@material-design-icons/svg/filled/solar_power.svg";
import { ReactComponent as WaterFilled } from "@material-design-icons/svg/filled/water.svg";
import { ReactComponent as AcUnitOutlined } from "@material-design-icons/svg/outlined/ac_unit.svg";
import { ReactComponent as BathroomOutlined } from "@material-design-icons/svg/outlined/bathroom.svg";
import { ReactComponent as CountertopsOutlined } from "@material-design-icons/svg/outlined/countertops.svg";
import { ReactComponent as ElectricBoltOutlined } from "@material-design-icons/svg/outlined/electric_bolt.svg";
import { ReactComponent as EngineeringOutlined } from "@material-design-icons/svg/outlined/engineering.svg";
import { ReactComponent as HandymanOutlined } from "@material-design-icons/svg/outlined/handyman.svg";
import { ReactComponent as HardwareOutlined } from "@material-design-icons/svg/outlined/hardware.svg";
import { ReactComponent as HeatPumpOutlined } from "@material-design-icons/svg/outlined/heat_pump.svg";
import { ReactComponent as HvacOutlined } from "@material-design-icons/svg/outlined/hvac.svg";
import { ReactComponent as LocalOfferOutlined } from "@material-design-icons/svg/outlined/local_offer.svg";
import { ReactComponent as MoreOutlined } from "@material-design-icons/svg/outlined/more.svg";
import { ReactComponent as RoofingOutlined } from "@material-design-icons/svg/outlined/roofing.svg";
import { ReactComponent as SolarPowerOutlined } from "@material-design-icons/svg/outlined/solar_power.svg";
import { ReactComponent as WaterOutlined } from "@material-design-icons/svg/outlined/water.svg";
import React from "react";
import { ReactComponent as Pipes } from "../../assets/icons/icon-pipes.svg";
import { ReactComponent as Pumps } from "../../assets/icons/icon-pumps.svg";

export const productCategoryDefaultIcon: React.ReactElement = <MoreFilled />;

export const productCategoryIcons: Record<
  string,
  { filled: React.ReactElement; outlined: React.ReactElement }
> = {
  "00010": {
    filled: (
      <BathroomFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <BathroomOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "00020": {
    filled: (
      <HeatPumpFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <HeatPumpOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "00030": {
    filled: (
      <HardwareFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <HardwareOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "00040": {
    filled: <HvacFilled height="inherit" width="inherit" fill="currentColor" />,
    outlined: (
      <HvacOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "00050": {
    filled: (
      <RoofingFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <RoofingOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "00060": {
    filled: (
      <EngineeringFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <EngineeringOutlined
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
  },
  "00070": {
    filled: (
      <HandymanFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <HandymanOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "00075": {
    filled: (
      <HandymanFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <HandymanOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "00080": {
    filled: (
      <LocalOfferFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <LocalOfferOutlined
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
  },
  "00081": {
    filled: (
      <LocalOfferFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <LocalOfferOutlined
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
  },
  "00090": {
    filled: (
      <ElectricBoltFilled
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
    outlined: (
      <ElectricBoltOutlined
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
  },
  "2-001": {
    filled: (
      <BathroomFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <BathroomOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "2-002": {
    filled: (
      <CountertopsFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <CountertopsOutlined
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
  },
  "2-003": {
    filled: (
      <HeatPumpFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <HeatPumpOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "2-004": {
    filled: (
      <SolarPowerFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <SolarPowerOutlined
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
  },
  "2-005": {
    filled: (
      <RoofingFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <RoofingOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "2-006": {
    filled: <HvacFilled height="inherit" width="inherit" fill="currentColor" />,
    outlined: (
      <HvacOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "2-007": {
    filled: <Pipes />,
    outlined: <Pipes />,
  },
  "2-008": {
    filled: <Pumps />,
    outlined: <Pumps />,
  },
  "2-009": {
    filled: (
      <WaterFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <WaterOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "2-010": {
    filled: (
      <HandymanFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <HandymanOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "2-011": {
    filled: (
      <AcUnitFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <AcUnitOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
  "2-012": {
    filled: (
      <EngineeringFilled height="inherit" width="inherit" fill="currentColor" />
    ),
    outlined: (
      <EngineeringOutlined
        height="inherit"
        width="inherit"
        fill="currentColor"
      />
    ),
  },
  "2-099": {
    filled: <MoreFilled height="inherit" width="inherit" fill="currentColor" />,
    outlined: (
      <MoreOutlined height="inherit" width="inherit" fill="currentColor" />
    ),
  },
};

/*
The L1 categories for R+F are:
00010 Sanitary
00020 Heating
00030 Installation
00040 Ventilation / air conditioning
00050 Roofing
00060 Civil engineering
00070 Tools ALT
00075 Tools
00080 Special articles R+F
00081 Special articles central
00090 Electrical

The L1 categories for VM should be
2-001 Sanitary
2-002 Kitchens
2-003 Heating and hot water
2-004 Solar
2-005 Flue tube
2-006 Ventilation
2-007 Pipes and laying equipment
2-008 Pumps
2-009 Water treatment
2-010 Tools
2-011 Electronic climate control and air-conditioning
2-012 Civil engineering
2-099 Other
*/
