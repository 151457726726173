/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProjectListItemFragmentDoc } from '../../../features/projects/components/ProjectListItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardOpportunitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DashboardOpportunitiesQuery = { opportunities: { __typename: 'ProjectConnection', totalCount: number, edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, title: string, deadline?: any | null, overdue: boolean, state: Types.ProjectStateMachineStatus, leadId?: number | null, urgent: boolean, earliestStart?: any | null, earliestPlanSessionDate?: any | null, assigned: boolean, phase: { __typename: 'ProjectPhase', id: string, name: string }, budget?: { __typename: 'Money', currency: string, amount: number } | null, assignees: Array<{ __typename: 'User', id: string, firstname: string, familyname: string } | null>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | null, buildingInfo?: { __typename: 'BuildingInfo', id: string, buildingAddress?: { __typename: 'Address', streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null } | null } }> } };


export const DashboardOpportunitiesDocument = gql`
    query DashboardOpportunities {
  opportunities: projects(
    limit: 5
    sorting: [{column: createdAt, direction: desc}]
    filters: {includeState: [opportunity]}
  ) {
    ... on ProjectConnection {
      totalCount
      edges {
        node {
          ...ProjectListItem
        }
      }
    }
  }
}
    ${ProjectListItemFragmentDoc}`;

/**
 * __useDashboardOpportunitiesQuery__
 *
 * To run a query within a React component, call `useDashboardOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOpportunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardOpportunitiesQuery(baseOptions?: Apollo.QueryHookOptions<DashboardOpportunitiesQuery, DashboardOpportunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardOpportunitiesQuery, DashboardOpportunitiesQueryVariables>(DashboardOpportunitiesDocument, options);
      }
export function useDashboardOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardOpportunitiesQuery, DashboardOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardOpportunitiesQuery, DashboardOpportunitiesQueryVariables>(DashboardOpportunitiesDocument, options);
        }
export function useDashboardOpportunitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardOpportunitiesQuery, DashboardOpportunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardOpportunitiesQuery, DashboardOpportunitiesQueryVariables>(DashboardOpportunitiesDocument, options);
        }
export type DashboardOpportunitiesQueryHookResult = ReturnType<typeof useDashboardOpportunitiesQuery>;
export type DashboardOpportunitiesLazyQueryHookResult = ReturnType<typeof useDashboardOpportunitiesLazyQuery>;
export type DashboardOpportunitiesSuspenseQueryHookResult = ReturnType<typeof useDashboardOpportunitiesSuspenseQuery>;
export type DashboardOpportunitiesQueryResult = Apollo.QueryResult<DashboardOpportunitiesQuery, DashboardOpportunitiesQueryVariables>;