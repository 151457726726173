import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Badge, IconButton, Avatar as MuiAvatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { useTranslate } from "@tolgee/react";
import React, { CSSProperties } from "react";
import { color } from "../../common/MuiThemeProvider";
import { Attachment } from "../features/attachments/helpers";
import { transientOptions } from "../styles";
import { UploadPhotoModal } from "./modals/UploadPhotoModal";

export type AvatarSize = "xs" | "s" | "m" | "l" | "xl";
export type AvatarType = "circle" | "square";

// ignore comment for app //
export interface AvatarProps {
  /**
   * Initials line on avatar
   */
  initials?: string;
  /**
   * Icon on avatar
   */
  icon?: React.ReactNode;
  /**
   * Type: user, organisation, project or building
   */
  type?: AvatarType;
  /**
   * Size: s, m, l
   */
  size: AvatarSize;
  /**
   * Image url for avatar
   */
  url?: string;
  /**
   * On attachment callback
   */
  onAttachment?: (attachment: Attachment | null) => Promise<void>;
  /**
   * Whether uploading button is disabled
   */
  disabled?: boolean;
  /**
   * Optional style
   */
  style?: CSSProperties;
  sx?: SxProps;
}

export const Avatar = ({
  initials,
  icon,
  url,
  size,
  type = "square",
  onAttachment,
  disabled = false,
  style,
  sx,
}: React.PropsWithChildren<AvatarProps>) => {
  const { t } = useTranslate("AttachmentUploader");

  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    if (onAttachment && !disabled) {
      setIsOpen(true);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Badge
      style={style}
      badgeContent={
        onAttachment ? (
          <>
            <IconButton
              disabled={disabled}
              color="primary"
              onClick={handleClick}
              size="small"
            >
              <AddAPhotoIcon />
            </IconButton>
            {isOpen && (
              <UploadPhotoModal
                initialUrl={hasAvatar(url) ? url : undefined}
                initialTitle={t("Avatar")}
                title={t("Edit avatar")}
                handleComplete={async (attachment: Attachment) => {
                  await onAttachment(attachment);
                }}
                handleRemove={
                  url
                    ? async () => {
                        await onAttachment(null);
                      }
                    : undefined
                }
                handleClose={handleClose}
                skipEdit={true}
              />
            )}
          </>
        ) : undefined
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <StyledMuiAvatar
        $clickable={!!onAttachment && !disabled}
        $size={size}
        $type={type}
        onClick={handleClick}
        src={hasAvatar(url) ? url : undefined}
        sx={sx}
      >
        {initials ? initials.trim().toUpperCase() : icon ? icon : null}
      </StyledMuiAvatar>
    </Badge>
  );
};

const StyledMuiAvatar = styled(MuiAvatar, transientOptions)<{
  $clickable: boolean;
  $size: AvatarSize;
  $type: AvatarType;
}>`
  cursor: ${({ $clickable }) =>
    $clickable ? "pointer" : "default"} !important;
  color: ${color.white};
  ${({ $size }) => `
    font-size: ${getFontSize($size)}px !important;
    height: ${getAvatarSize($size)}px !important;
    width: ${getAvatarSize($size)}px !important;
    svg {
      height: ${getSvgSize($size)}px !important;
      width: ${getSvgSize($size)}px !important;
    }
  `}
  ${({ $size, $type }) =>
    $type === "square"
      ? `
        border-radius: ${getBorderRadius($size)}px !important;
      `
      : ""}
`;

// TODO: remove as soon as default URL is removed from backend
export const DEFAULT_URL =
  "https://storage.googleapis.com/proportal/e08d740e-a3dc-4395-8d0b-089abf832350_cbb34a1f-db3b-42df-9da9-5a267c3ad8e8";

const hasAvatar = (url?: string) => url && url !== DEFAULT_URL;

export const getInitials = (firstName?: string, familyName?: string) => {
  const i1 = firstName?.substring(0, 1) ?? "";
  const i2 = familyName?.substring(0, 1) ?? "";
  return `${i1}${i2}`.toLocaleUpperCase();
};

export const getAvatarSize = (size?: AvatarSize) => {
  switch (size) {
    case "xl":
      return 96;
    case "l":
      return 72;
    case "s":
      return 32;
    case "xs":
      return 22;
    case "m":
    default:
      return 48;
  }
};

export const getBorderRadius = (size?: AvatarSize) => {
  switch (size) {
    case "xl":
      return 18;
    case "l":
      return 12;
    case "xs":
    case "s":
      return 4;
    case "m":
    default:
      return 8;
  }
};

export const getFontSize = (size?: AvatarSize) => {
  switch (size) {
    case "xl":
      return 28;
    case "l":
      return 20;
    case "xs":
    case "s":
      return 12;
    case "m":
    default:
      return 16;
  }
};

export const getSvgSize = (size?: AvatarSize) => {
  switch (size) {
    case "xl":
      return 40;
    case "l":
      return 32;
    case "xs":
    case "s":
      return 18;
    case "m":
    default:
      return 24;
  }
};
