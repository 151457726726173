import { gql } from "@apollo/client";
import { MenuButton } from "@msys/ui";
import { MenuItem, Typography } from "@mui/material";
import React from "react";
import { useUserData } from "../../auth/useUserData";
import {
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { ProjectStateMachineStatus } from "../../../clients/graphqlTypes";
import { ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhaseFragment } from "./ProjectPhaseAndStateChangeMenuButton.generated";
import { useProjectChangePhase } from "./useProjectChangePhase";
import { useProjectChangeState } from "./useProjectChangeState";
import {
  ALL_PROJECT_EVENTS,
  getPossibleProjectStateEvents,
  useProjectStates,
} from "./useProjectStates";

interface Props
  extends Omit<React.ComponentProps<typeof MenuButton>, "children"> {
  projectId: string;
  projectPhaseId: string;
  projectState: ProjectStateMachineStatus;
  availablePhases: ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhaseFragment[];
  stateGroupLabel: string;
  onStateChangeRefetchQueries?: string[];
  changeProjectStateUpdate?: Parameters<
    typeof useProjectChangeState
  >[0]["changeProjectStateUpdate"];
  phaseGroupLabel: string;
  onPhaseChangeRefetchQueries?: string[];
  changeProjectPhaseUpdate?: Parameters<
    typeof useProjectChangePhase
  >[0]["changeProjectPhaseUpdate"];
}

export const ProjectPhaseAndStateChangeMenuButton = ({
  projectId,
  projectPhaseId,
  projectState,
  availablePhases,
  stateGroupLabel,
  changeProjectStateUpdate,
  onStateChangeRefetchQueries,
  phaseGroupLabel,
  changeProjectPhaseUpdate,
  onPhaseChangeRefetchQueries,
  ...props
}: Props) => {
  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);

  const viewer = useUserData().currentUser!;
  const events = getPossibleProjectStateEvents(projectState, {
    guards: { isContractor: viewer.organisation.isCraftsmanOrganisation },
  });
  const availableStateEvents = ALL_PROJECT_EVENTS.filter(e =>
    events.includes(e)
  );
  const { projectStateEventLabels } = useProjectStates();

  const { handlePhaseChange, loading: loadingPhaseChange } =
    useProjectChangePhase({
      projectId,
      projectPhaseId,
      refetchQueries: onPhaseChangeRefetchQueries,
      changeProjectPhaseUpdate,
    });
  const { handleStateChange, loading: loadingStateChange } =
    useProjectChangeState({
      projectId,
      projectState,
      confirmProcessRef,
      refetchQueries: onStateChangeRefetchQueries,
      changeProjectStateUpdate,
    });

  return (
    <>
      <MenuButton
        {...props}
        buttonProps={{
          ...props.buttonProps,
          // indicate that mutation is in progress
          ...(loadingPhaseChange || loadingStateChange
            ? { disabled: true }
            : undefined),
        }}
      >
        <MenuItem disabled>
          <Typography variant="body2" color="grey.900">
            {phaseGroupLabel}
          </Typography>
        </MenuItem>
        {availablePhases.map(phase => (
          <MenuItem
            key={phase.id}
            onClick={async event => {
              await handlePhaseChange(phase.id);
            }}
            // selected={phase.id === currentPhaseId}
          >
            {phase.name}
          </MenuItem>
        ))}

        <MenuItem disabled sx={{ mt: 1 }}>
          <Typography variant="body2" color="grey.900">
            {stateGroupLabel}
          </Typography>
        </MenuItem>
        {availableStateEvents.map(stateEvent => (
          <MenuItem
            key={stateEvent}
            onClick={async event => {
              await handleStateChange(stateEvent);
            }}
          >
            {projectStateEventLabels[stateEvent]}
          </MenuItem>
        ))}
      </MenuButton>
      <ConfirmProcess ref={confirmProcessRef} />
    </>
  );
};
