import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, List, ListItem, Typography } from "@mui/material";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { map, mapValues, omit, uniqueId, without } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { SelectMultipleField } from "../../../commons/form-fields/SelectMultipleField";
import { Stack } from "../../../commons/layout/Stack";
import { ProfileListItem } from "../../../commons/ProfileListItem";
import {
  useModifyProjectMemberModalQuery,
  useModifyProjectMembershipMutation,
  useRemoveProjectMembershipMutation,
} from "./ModifyProjectMemberModal.generated";
import { useTranslate } from "@tolgee/react";
import { UserAvatar } from "../../users/UserAvatar";
import { useProjectRoles } from "../../users/useRoles";

function useData(projectId: string) {
  const client = useApolloClient();
  const query = useModifyProjectMemberModalQuery({
    client,
    variables: { projectId },
  });
  return {
    data: query.data,
    refetch: query.refetch,
    isLoading: query.loading,
    error: query.error,
  };
}

interface FormValues {
  removeIds: string[];
  updates: { [membershipId: string]: string[] };
}

interface Props {
  projectId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (projectId: string) => Promise<void>;
  refetchQueries?: string[];
}

export const ModifyProjectMemberModal = ({
  projectId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["ProjectMembers", "Global"]);
  const { getProjectRoleOptions, getProjectRoleTitle } = useProjectRoles();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useData(projectId);

  const project = getDataOrNull(data?.project)?.project;

  const allRoles = project?.roles ?? [];
  const internalStakeholders = project?.internalStakeholders ?? [];

  const roles = getProjectRoleOptions(allRoles);

  const myOrganisationMembers = internalStakeholders;

  const client = useApolloClient();
  const [modifyProjectMembership] = useModifyProjectMembershipMutation({
    client,
  });
  const [removeProjectMembership] = useRemoveProjectMembershipMutation({
    client,
  });

  const handleSubmit = async (formValues: FormValues) => {
    await Promise.all(
      map(formValues.updates, async (roleIds, membershipId) => {
        await modifyProjectMembership({
          variables: {
            membershipId,
            roleIds,
            projectId: projectId,
          },
        });
      })
    );

    await Promise.all(
      formValues.removeIds.map(async membershipId => {
        await removeProjectMembership({
          variables: {
            projectId: projectId,
            membershipId,
          },
        });
      })
    );

    await client.refetchQueries({ include: refetchQueries });

    enqueueSnackbar(
      t("Changes saved.", {
        ns: "ProjectMembers",
      })
    );
    handleClose();
    handleComplete?.(projectId);
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues = {
    removeIds: [],
    updates: {},
  };
  const validationSchema = Yup.object().shape({
    removeIds: Yup.array().max(
      myOrganisationMembers.length - 1,
      t("You cannot remove all members of the team on your side.", {
        ns: "ProjectMembers",
      })
    ),
    updates: Yup.lazy((obj: any) =>
      Yup.object(
        mapValues(obj, () =>
          Yup.array().required(
            t(
              "You cannot remove all roles of a user. If you want to remove the user from the project, please use the remove button.",
              {
                ns: "ProjectMembers",
              }
            )
          )
        )
      )
    ),
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        setTouched,
      }: FormikProps<FormValues>) => (
        <Modal
          title={
            title ??
            t("Manage project participants", {
              ns: "ProjectMembers",
            })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: !isValid || isLoading,
                loading: isSubmitting,
              },
            },
          ]}
          isLoading={isLoading}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <List>
                {myOrganisationMembers.map(member => (
                  <React.Fragment key={member.id}>
                    <ProfileListItem
                      button={false}
                      isNotActive={values.removeIds.includes(member.id)}
                      avatar={<UserAvatar userAvatar={member.user} size="m" />}
                      primary={member.user.fullname}
                      secondary={getProjectRoleTitle(member.roles)}
                      RightButton={
                        values.removeIds.includes(member.id) ? (
                          <Button
                            size="extra-small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              setFieldValue(
                                "removeIds",
                                without(values.removeIds, member.id)
                              )
                            }
                          >
                            {t("Undo", {
                              ns: "Global",
                            })}
                          </Button>
                        ) : undefined
                      }
                      ActionButtons={
                        !values.removeIds.includes(member.id)
                          ? [
                              <IconButton
                                key="update"
                                size="small"
                                color="secondary"
                                onClick={() => {
                                  if (values.updates[member.id]) {
                                    setFieldValue(
                                      "updates",
                                      omit(values.updates, [member.id])
                                    );
                                  } else {
                                    setFieldValue(
                                      `updates.${member.id}`,
                                      member.roles.map(r => r.id)
                                    );
                                  }
                                }}
                              >
                                <EditIcon />
                              </IconButton>,
                              <IconButton
                                key="remove"
                                size="small"
                                color="secondary"
                                onClick={() => {
                                  setFieldValue("removeIds", [
                                    ...values.removeIds,
                                    member.id,
                                  ]);
                                  requestAnimationFrame(() =>
                                    setTouched({ removeIds: true })
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>,
                            ]
                          : undefined
                      }
                    />
                    {values.updates[member.id] &&
                      !values.removeIds.includes(member.id) && (
                        <ListItem
                          style={{ paddingLeft: "70px", marginTop: "-15px" }}
                        >
                          <Stack
                            spacing={0}
                            flexDirection="column"
                            alignItems="stretch"
                            style={{ minWidth: "100%" }}
                          >
                            <SelectMultipleField
                              label={t("Project roles", {
                                ns: "ProjectMembers",
                              })}
                              name={`updates.${member.id}`}
                              options={roles}
                              required
                              validate={value => {
                                let message;
                                if (value.length === 0) {
                                  message = t(
                                    "At least one organisation role is required",
                                    {
                                      ns: "ProjectMembers",
                                    }
                                  );
                                }
                                return message;
                              }}
                              disabled={false}
                            />
                          </Stack>
                        </ListItem>
                      )}
                  </React.Fragment>
                ))}
              </List>

              <ErrorMessage
                name="removeIds"
                render={msg => (
                  <Typography color="error" variant="body2">
                    {msg}
                  </Typography>
                )}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
