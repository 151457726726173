import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { BuildingIcon } from "../../buildings/BuildingIcon";
import { AddPlacementInProjectBuildingModal } from "../modals/AddPlacementInProjectBuildingModal";

export const ProjectBuildingPlacementMenuItem = ({
  projectId,
  docId,
  itemId,
}: {
  projectId: string;
  docId: string;
  itemId: string;
}) => {
  const { t } = useTranslate("QuoteItem");

  const title = t("Add location in the building");

  return (
    <ModalOpenButton
      Modal={AddPlacementInProjectBuildingModal}
      modalProps={{ projectId, docId, itemId, title }}
    >
      <MenuItemWithIcon icon={<BuildingIcon />}>{title}</MenuItemWithIcon>
    </ModalOpenButton>
  );
};
