import { useApolloClient } from "@apollo/client";
import { CardContainer, CollapseSection, ModalOpenButton } from "@msys/ui";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Link as MuiLink,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  stepLabelClasses,
} from "@mui/material";
import { T, useTranslate } from "@tolgee/react";
import React from "react";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import { useUserData } from "../../../auth/useUserData";
import { CreateInvoiceButton } from "../../invoices/buttons/CreateInvoiceButton";
import { CreateQuoteButton } from "../../quotes/buttons/CreateQuoteButton";
import { RequestQuoteForOutsourcingButton } from "../../requests/buttons/RequestQuoteForOutsourcingButton";
import { CreateRequirementButton } from "../../requirements/buttons/CreateRequirementButton";
import { AddProjectContentButton } from "../buttons/AddProjectContentButton";
import { AddProjectFilesButton } from "../buttons/AddProjectFilesButton";
import { ProjectAssignBuildingModal } from "../modals/ProjectAssignBuildingModal";
import { useProjectAddEntity } from "../useProjectAddEntity";
import {
  ProjectOverviewActionListBox_ProjectFragment,
  useProjectOverviewActionListBoxQuery,
} from "./ProjectOverviewActionListBox.generated";

const iconStyle = { width: "32px", height: "32px", marginRight: "8px" };

interface Props {
  project: ProjectOverviewActionListBox_ProjectFragment;
  hasAttachments: boolean;
  hasContents: boolean;
  hasAddress: boolean;
  hasOutgoingRequests: boolean;
  hasIncomingAgreedQuotes: boolean;
  ordersCount: number;
  incomingRequirementsCount: number;
  outgoingRequirementsCount: number;
  incomingInvoicesCount: number;
  outgoingInvoicesCount: number;
  incomingQuotesCount: number;
  outgoingQuotesCount: number;
  refetchQueries: string[];
}

export const ProjectOverviewActionListBox = ({
  project,
  hasAttachments,
  hasContents,
  hasAddress,
  hasOutgoingRequests,
  hasIncomingAgreedQuotes,
  incomingRequirementsCount,
  outgoingRequirementsCount,
  ordersCount,
  incomingInvoicesCount,
  outgoingInvoicesCount,
  incomingQuotesCount,
  outgoingQuotesCount,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["ProjectOverview", "Global"]);
  const viewer = useUserData().currentUser!;

  const {
    handleNavigateToBillOfMaterials,
    handleNavigateToPlanning,
    handleNavigateToTasks,
  } = useProjectAddEntity({
    projectId: project.id,
  });

  const client = useApolloClient();
  const query = useProjectOverviewActionListBoxQuery({
    client,
    variables: { projectId: project.id },
    fetchPolicy: "network-only",
  });

  const isAnyOutgoingQuoteItemDone =
    query?.data?.projectDocumentsStatus?.isAnyOutgoingQuoteItemDone ?? false;
  const areAllOutgoingQuoteItemsDone =
    query?.data?.projectDocumentsStatus?.areAllOutgoingQuoteItemsDone ?? false;

  const hasQuotesForRequest = outgoingQuotesCount > 0;

  const buildingLink = React.useCallback(
    (content: string) => (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_PROJECT"
        project={project}
        otherwise={content}
      >
        <ModalOpenButton
          Modal={ProjectAssignBuildingModal}
          modalProps={{
            projectId: project.id,
            buildingOwningOrganisationId:
              project.crmOrganisation?.id ?? project.owningSystemOrganisationId,
            refetchQueries,
          }}
        >
          <MuiLink
            color="textSecondary"
            sx={{ textTransform: "uppercase", cursor: "pointer" }}
          >
            {content}
          </MuiLink>
        </ModalOpenButton>
      </RestrictedByProjectPermissionWithDebug>
    ),
    [project, refetchQueries]
  );
  const requirementLink = React.useCallback(
    (content: string) => (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_REQUIREMENTS"
        project={project}
        otherwise={content}
      >
        <CreateRequirementButton
          projectId={project.id}
          Button={
            <MuiLink
              color="textSecondary"
              sx={{ textTransform: "uppercase", cursor: "pointer" }}
            >
              {content}
            </MuiLink>
          }
        />
      </RestrictedByProjectPermissionWithDebug>
    ),
    [project]
  );
  const quotesLink = React.useCallback(
    (content: string) => (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_QUOTES"
        project={project}
        otherwise={content}
      >
        {!(project.state === "opportunity") ||
        project.incomingQuoteRequests.length === 0 ||
        project.incomingQuoteRequests.some(
          request =>
            request.status === "PUBLISHED" ||
            request.wonBySystemOrganisationId === viewer.organisation.id
        ) ? (
          <CreateQuoteButton
            projectId={project.id}
            pathToProject={`/projects/${project.id}`}
            Button={
              <MuiLink
                color="textSecondary"
                sx={{ textTransform: "uppercase", cursor: "pointer" }}
              >
                {content}
              </MuiLink>
            }
          />
        ) : (
          content
        )}
      </RestrictedByProjectPermissionWithDebug>
    ),
    [project, viewer.organisation.id]
  );
  const filesLink = React.useCallback(
    (content: string) => (
      <AddProjectFilesButton
        projectId={project.id}
        Button={
          <MuiLink
            color="textSecondary"
            sx={{ textTransform: "uppercase", cursor: "pointer" }}
          >
            {content}
          </MuiLink>
        }
      />
    ),
    [project.id]
  );
  const infoLink = React.useCallback(
    (content: string) => (
      <AddProjectContentButton
        project={project}
        Button={
          <MuiLink
            color="textSecondary"
            sx={{
              textTransform: "uppercase",
              cursor: "pointer",
            }}
          >
            {content}
          </MuiLink>
        }
      />
    ),
    [project]
  );
  const planningLink = React.useCallback(
    (content: string) => (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_PROJECT"
        project={project}
        otherwise={content}
      >
        {viewer.organisation.id === project.owningSystemOrganisationId ? (
          <MuiLink
            color="textSecondary"
            sx={{ textTransform: "uppercase", cursor: "pointer" }}
            onClick={handleNavigateToPlanning}
          >
            {content}
          </MuiLink>
        ) : (
          content
        )}
      </RestrictedByProjectPermissionWithDebug>
    ),
    [project, viewer.organisation.id, handleNavigateToPlanning]
  );
  const orderLink = React.useCallback(
    (content: string) => (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_QUOTES"
        project={project}
        otherwise={content}
      >
        <MuiLink
          color="textSecondary"
          sx={{
            textTransform: "uppercase",
            cursor: "pointer",
          }}
          onClick={handleNavigateToBillOfMaterials}
        >
          {content}
        </MuiLink>
      </RestrictedByProjectPermissionWithDebug>
    ),
    [project, handleNavigateToBillOfMaterials]
  );
  const requestLink = React.useCallback(
    (content: string) =>
      hasQuotesForRequest ? (
        <RestrictedByProjectPermissionWithDebug
          permission="DECIDE_QUOTES"
          project={project}
          otherwise={content}
        >
          <RequestQuoteForOutsourcingButton
            projectId={project.id}
            Button={
              <MuiLink
                color="textSecondary"
                sx={{
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
              >
                {content}
              </MuiLink>
            }
          />
        </RestrictedByProjectPermissionWithDebug>
      ) : (
        <>{content}</>
      ),
    [project, hasQuotesForRequest]
  );
  const invoiceLink = React.useCallback(
    (content: string) => (
      <RestrictedByProjectPermissionWithDebug
        permission="MANAGE_INVOICES"
        project={project}
        otherwise={content}
      >
        <CreateInvoiceButton
          projectId={project.id}
          Button={
            <MuiLink
              color="textSecondary"
              sx={{ textTransform: "uppercase", cursor: "pointer" }}
            >
              {content}
            </MuiLink>
          }
        />
      </RestrictedByProjectPermissionWithDebug>
    ),
    [project]
  );
  const tasksLink = React.useCallback(
    (content: string) => (
      <MuiLink
        color="textSecondary"
        onClick={handleNavigateToTasks}
        sx={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {content}
      </MuiLink>
    ),
    [handleNavigateToTasks]
  );

  const isAcquisitionExpanded = project.state === "opportunity";
  const isAcquisitionDone =
    project.state === "contracted" || project.state === "completed";

  const isConstructionExpanded = project.state === "contracted";
  const isConstructionDone = project.state === "completed";
  const isProjectCompleted =
    (project.state === "contracted" && areAllOutgoingQuoteItemsDone) ||
    project.state === "completed";

  const acquisitionSteps = {
    projectCreated: true,
    buildingCreated: hasAddress,
    requirementCreated: outgoingRequirementsCount > 0,
    ...(viewer.organisation.capabilities.includes("QUOTING")
      ? {
          quoteCreated: outgoingQuotesCount > 0,
        }
      : undefined),
    contentAdded: hasAttachments || hasContents,
    projectWon: isAcquisitionDone,
  };
  const constructionSteps = {
    ...(viewer.organisation.capabilities.includes("PLANNING")
      ? {
          projectPlanned: Boolean(
            project.earliestPlanSessionDate && project.latestPlanSessionDate
          ),
        }
      : undefined),
    ...(viewer.organisation.capabilities.includes("ORDERING")
      ? { materialOrdered: ordersCount > 0 }
      : undefined),
    subcontractorsAdded: hasIncomingAgreedQuotes,
    installationStarted: isAnyOutgoingQuoteItemDone,
    ...(viewer.organisation.capabilities.includes("INVOICING")
      ? {
          invoiceCreated: outgoingInvoicesCount > 0,
        }
      : undefined),
    projectCompleted: isProjectCompleted,
  };

  const acquisitionProgress = calculateProgress(
    acquisitionSteps,
    isAcquisitionDone
  );
  const constructionProgress = calculateProgress(
    constructionSteps,
    isConstructionDone
  );
  return (
    <CardContainer
      Icon={<OfflinePinIcon />}
      title={"Action list"}
      isExpandable
      isInitiallyClosed={false}
    >
      {/* {Modals} */}
      <Stack direction="column" px={1} spacing={1}>
        <CollapseSection
          title={
            <Stack
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              direction="row"
              flex={1}
              width="100%"
            >
              <span>{t("Acquisition phase", { ns: "ProjectOverview" })}</span>
              <Box flex={1} maxWidth="45%">
                <LinearProgressWithLabel value={acquisitionProgress} />
              </Box>
            </Stack>
          }
          fullWidth
          titleVariant="h4"
          isInitiallyExpanded={isAcquisitionExpanded}
        >
          <ProjectStepper>
            <ProjectStep
              completed={acquisitionSteps.projectCreated}
              icon={<img src="/entities/project.svg" style={iconStyle} />}
            >
              {t("Project created", { ns: "ProjectOverview" })}
            </ProjectStep>
            <ProjectStep
              completed={acquisitionSteps.buildingCreated}
              icon={<img src="/entities/building.svg" style={iconStyle} />}
            >
              <T
                ns="ProjectOverview"
                keyName={"Create/add {building}"}
                params={{ link: buildingLink }}
              />
            </ProjectStep>
            <ProjectStep
              completed={acquisitionSteps.requirementCreated}
              icon={<img src="/entities/requirement.svg" style={iconStyle} />}
            >
              <T
                ns="ProjectOverview"
                keyName={"Create {new requirement}"}
                params={{ link: requirementLink }}
              />
            </ProjectStep>
            <RestrictedByCapabilityWithDebug capability="QUOTING">
              <ProjectStep
                completed={Boolean(acquisitionSteps.quoteCreated)}
                icon={<img src="/entities/quote.svg" style={iconStyle} />}
              >
                <T
                  ns="ProjectOverview"
                  keyName={"Create {new quote}"}
                  params={{ link: quotesLink }}
                />
              </ProjectStep>
            </RestrictedByCapabilityWithDebug>
            <ProjectStep
              completed={acquisitionSteps.contentAdded}
              icon={<img src="/entities/attachment.svg" style={iconStyle} />}
            >
              <T
                ns="ProjectOverview"
                keyName={"Add {files} & {info}"}
                params={{ files: filesLink, info: infoLink }}
              />
            </ProjectStep>
            <ProjectStep
              completed={acquisitionSteps.projectWon}
              icon={<img src="/entities/win.svg" style={iconStyle} />}
            >
              {t("Win project", { ns: "ProjectOverview" })}
            </ProjectStep>
          </ProjectStepper>
        </CollapseSection>

        <CollapseSection
          title={
            <Stack
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              direction="row"
              width="100%"
              flex={1}
            >
              <span>{t("Construction phase", { ns: "ProjectOverview" })}</span>
              <Box flex={1} maxWidth="45%">
                <LinearProgressWithLabel value={constructionProgress} />
              </Box>
            </Stack>
          }
          fullWidth
          titleVariant="h4"
          isInitiallyExpanded={isConstructionExpanded}
        >
          <ProjectStepper>
            <RestrictedByCapabilityWithDebug capability="PLANNING">
              <ProjectStep
                completed={Boolean(constructionSteps.projectPlanned)}
                icon={<img src="/entities/planning.svg" style={iconStyle} />}
              >
                <T
                  ns="ProjectOverview"
                  keyName={"Plan {resources}"}
                  params={{ link: planningLink }}
                />
              </ProjectStep>
            </RestrictedByCapabilityWithDebug>
            <RestrictedByCapabilityWithDebug capability="ORDERING">
              <ProjectStep
                completed={Boolean(constructionSteps.materialOrdered)}
                icon={<img src="/entities/order.svg" style={iconStyle} />}
              >
                <T
                  ns="ProjectOverview"
                  keyName={"Order {material}"}
                  params={{ link: orderLink }}
                />
              </ProjectStep>
            </RestrictedByCapabilityWithDebug>
            <ProjectStep
              completed={constructionSteps.subcontractorsAdded}
              icon={<img src="/entities/subcontract.svg" style={iconStyle} />}
            >
              <T
                ns="ProjectOverview"
                keyName={"Add {subcontractors}"}
                params={{ link: requestLink }}
              />
            </ProjectStep>
            <ProjectStep
              completed={constructionSteps.installationStarted}
              icon={<img src="/entities/installation.svg" style={iconStyle} />}
            >
              <T
                ns="ProjectOverview"
                keyName={"Start {installation}"}
                params={{ link: tasksLink }}
              />
            </ProjectStep>
            <RestrictedByCapabilityWithDebug capability="INVOICING">
              <ProjectStep
                completed={Boolean(constructionSteps.invoiceCreated)}
                icon={<img src="/entities/invoice.svg" style={iconStyle} />}
              >
                <T
                  ns="ProjectOverview"
                  keyName={"Create {new invoice}"}
                  params={{ link: invoiceLink }}
                />
              </ProjectStep>
            </RestrictedByCapabilityWithDebug>
            <ProjectStep
              completed={constructionSteps.projectCompleted}
              icon={
                <img src="/entities/project-completed.svg" style={iconStyle} />
              }
            >
              {t("Project completed", { ns: "ProjectOverview" })}
            </ProjectStep>
          </ProjectStepper>
        </CollapseSection>
      </Stack>
    </CardContainer>
  );
};

const ProjectStepper = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Stepper
      activeStep={-1}
      orientation="vertical"
      nonLinear
      connector={
        <StepConnector
          sx={{
            pointerEvents: "none",
            marginTop: "-24px",
            marginBottom: "-24px",
            [`& .${stepConnectorClasses.line}`]: {
              minHeight: "40px",
            },
          }}
        />
      }
      sx={{
        [`& .${stepConnectorClasses.root}:first-child`]: {
          display: "none",
        },
      }}
    >
      {children}
    </Stepper>
  );
};
const ProjectStep = ({
  completed,
  icon,
  children,
}: React.PropsWithChildren<{
  completed: boolean;
  icon: React.ReactElement;
}>) => {
  return (
    <Step completed={completed} active={false}>
      <StepLabel
        icon={
          completed ? (
            <CheckCircleIcon
              sx={{
                height: 24,
                width: 24,
                zIndex: 3,
                color: theme => theme.palette.success.main,
                backgroundColor: theme => theme.palette.common.white,
              }}
            />
          ) : (
            <Box
              sx={{
                height: "8px",
                width: "8px",
                margin: "8px",
                zIndex: 3,
                color: theme => theme.palette.grey[400],
                backgroundColor: "currentColor",
                borderRadius: "50%",
              }}
            />
          )
        }
        sx={{
          [`& .${stepLabelClasses.label}`]: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          },
        }}
      >
        <div style={{ display: "flex" }}>{icon}</div>
        <Typography variant="body2" fontWeight={500} color="textPrimary">
          {children}
        </Typography>
      </StepLabel>
    </Step>
  );
};

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Stack direction="row" alignItems="center" spacing={1} width="100%">
      <LinearProgress
        color="success"
        variant="determinate"
        {...props}
        sx={{ flex: 1 }}
      />
      <Typography variant="body2" color="textPrimary">{`${Math.round(
        props.value
      )}%`}</Typography>
    </Stack>
  );
}

function calculateProgress(flags: Record<string, boolean>, isDone: boolean) {
  return isDone
    ? 100
    : Math.ceil(
        (Object.entries(flags).reduce(
          (acc, [key, value]) => acc + (value ? 1 : 0),
          0
        ) /
          Object.entries(flags).length) *
          100
      );
}
