import { Breadcrumbs, Link as MuiLink, Typography } from "@mui/material";
import { Link, To } from "react-router-dom";

export interface BreadcrumbItem {
  title: string | React.ReactElement;
  to: To;
  icon?: React.ReactElement;
}

interface Props {
  breadcrumbs: BreadcrumbItem[];
}

export function PageBreadcrumbLink({ link }: { link: BreadcrumbItem }) {
  return (
    <Breadcrumbs separator="›">
      <MuiLink component={Link} to={link.to}>
        <Typography variant="caption">
          {link.icon && (
            <span style={{ verticalAlign: "middle", marginRight: "4px" }}>
              {link.icon}
            </span>
          )}
          <span>{link.title}</span>
        </Typography>
      </MuiLink>
    </Breadcrumbs>
  );
}

export function PageBreadcrumbs({ breadcrumbs }: Props) {
  if (breadcrumbs.length < 1) return null;

  return (
    <Breadcrumbs separator="›">
      {breadcrumbs.map((breadcrumb, index, array) =>
        breadcrumb.to === array[array.length - 1].to ? (
          <Typography
            key={index}
            variant="caption"
            color="primary"
            style={{ fontWeight: 500 }}
          >
            {breadcrumb.title}
          </Typography>
        ) : (
          <MuiLink key={index} component={Link} to={breadcrumb.to}>
            <Typography variant="caption">
              {breadcrumb.icon && (
                <span style={{ verticalAlign: "middle", marginRight: "4px" }}>
                  {breadcrumb.icon}
                </span>
              )}
              <span>{breadcrumb.title}</span>
            </Typography>
          </MuiLink>
        )
      )}
    </Breadcrumbs>
  );
}
