/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyPaymentInformationBox_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, bankName: string, bankAccount: string, bankCode: string };

export type CrmCompanyPaymentInformationData_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, bankName: string, bankAccount: string, bankCode: string };

export type CrmCompanyPaymentInformationForm_CrmComapnyFragment = { __typename: 'CrmCompany', id: string, bankName: string, bankAccount: string, bankCode: string };

export type ModifyCrmCompanyPaymentInformationMutationVariables = Types.Exact<{
  input: Array<Types.CrmOrganisationModifyInput>;
}>;


export type ModifyCrmCompanyPaymentInformationMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, bankName: string, bankAccount: string, bankCode: string }> } };

export const CrmCompanyPaymentInformationData_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyPaymentInformationData_CrmCompany on CrmCompany {
  id
  bankName
  bankAccount
  bankCode
}
    `;
export const CrmCompanyPaymentInformationForm_CrmComapnyFragmentDoc = gql`
    fragment CrmCompanyPaymentInformationForm_CrmComapny on CrmCompany {
  id
  bankName
  bankAccount
  bankCode
}
    `;
export const CrmCompanyPaymentInformationBox_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyPaymentInformationBox_CrmCompany on CrmCompany {
  id
  ...CrmCompanyPaymentInformationData_CrmCompany
  ...CrmCompanyPaymentInformationForm_CrmComapny
}
    ${CrmCompanyPaymentInformationData_CrmCompanyFragmentDoc}
${CrmCompanyPaymentInformationForm_CrmComapnyFragmentDoc}`;
export const ModifyCrmCompanyPaymentInformationDocument = gql`
    mutation ModifyCrmCompanyPaymentInformation($input: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $input) {
    crmOrganisations {
      id
      ...CrmCompanyPaymentInformationForm_CrmComapny
    }
  }
}
    ${CrmCompanyPaymentInformationForm_CrmComapnyFragmentDoc}`;
export type ModifyCrmCompanyPaymentInformationMutationFn = Apollo.MutationFunction<ModifyCrmCompanyPaymentInformationMutation, ModifyCrmCompanyPaymentInformationMutationVariables>;

/**
 * __useModifyCrmCompanyPaymentInformationMutation__
 *
 * To run a mutation, you first call `useModifyCrmCompanyPaymentInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmCompanyPaymentInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmCompanyPaymentInformationMutation, { data, loading, error }] = useModifyCrmCompanyPaymentInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyCrmCompanyPaymentInformationMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmCompanyPaymentInformationMutation, ModifyCrmCompanyPaymentInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmCompanyPaymentInformationMutation, ModifyCrmCompanyPaymentInformationMutationVariables>(ModifyCrmCompanyPaymentInformationDocument, options);
      }
export type ModifyCrmCompanyPaymentInformationMutationHookResult = ReturnType<typeof useModifyCrmCompanyPaymentInformationMutation>;
export type ModifyCrmCompanyPaymentInformationMutationResult = Apollo.MutationResult<ModifyCrmCompanyPaymentInformationMutation>;
export type ModifyCrmCompanyPaymentInformationMutationOptions = Apollo.BaseMutationOptions<ModifyCrmCompanyPaymentInformationMutation, ModifyCrmCompanyPaymentInformationMutationVariables>;