import { CollapseSection, Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { SwitchField } from "../../../commons/form-fields/SwitchField";
import { PreferenceStore } from "../../users/useUserPreference";
import { VisibilityStore } from "../../users/useVisibilityStore";
import { ProjectListItemDisplayConfig } from "./ProjectListItem";

interface Props<Keys extends string> {
  importVisibilityStore: VisibilityStore<Keys>;
  listItemDisplayConfigStore: PreferenceStore<ProjectListItemDisplayConfig>;
  labels: Record<Keys, string>;
  title?: string;
  handleClose(): void;
}

interface FormValues<Keys extends string> {
  listItemDisplayConfig: ProjectListItemDisplayConfig;
  importVisibility: Record<Keys, boolean>;
}

export const defaultListItemDisplayConfig: ProjectListItemDisplayConfig = {
  location: true,
  client: true,
  assignees: true,
  earliestPlanSessionDate: true,
  earliestStartDate: true,
  deadlineDate: true,
  budget: true,
  leadId: false,
};

export const ProjectListItemVisibilitySettingsModal = <Keys extends string>({
  title,
  importVisibilityStore,
  listItemDisplayConfigStore,
  labels,
  handleClose,
}: Props<Keys>) => {
  const { t } = useTranslate(["Projects", "Global", "DataItem"]);
  const initialValues = {
    listItemDisplayConfig: listItemDisplayConfigStore.value,
    importVisibility: Object.fromEntries(
      (Object.keys(labels) as Keys[]).map(field => [
        field,
        importVisibilityStore.value[field] ?? true,
      ])
    ) as Record<Keys, boolean>,
  };
  const validationSchema = Yup.object().shape({
    importVisibility: Yup.object()
      .shape(
        Object.fromEntries(
          (Object.keys(labels) as Keys[]).map(field => [
            field,
            Yup.boolean().label(labels[field]).required(),
          ])
        )
      )
      .required(),
  });
  const handleSubmit = (values: FormValues<Keys>) => {
    importVisibilityStore.saveValue(values.importVisibility);
    listItemDisplayConfigStore.saveValue(values.listItemDisplayConfig);
    handleClose();
  };
  const formId = React.useMemo(() => uniqueId(), []);

  const displayOptions = [
    {
      value: "location",
      label: t("Location", { ns: "DataItem" }),
    },
    {
      value: "client",
      label: t("Client", { ns: "DataItem" }),
    },
    { value: "assignees", label: t("Assigned workers", { ns: "DataItem" }) },
    {
      value: "earliestPlanSessionDate",
      label: t("Earliest planned work session", { ns: "DataItem" }),
    },
    {
      value: "earliestStartDate",
      label: t("Earliest start", { ns: "DataItem" }),
    },
    { value: "deadlineDate", label: t("Deadline", { ns: "DataItem" }) },
    { value: "budget", label: t("Budget", { ns: "DataItem" }) },
    { value: "leadId", label: t("Lead ID", { ns: "Projects" }) },
  ];

  return (
    <Formik<FormValues<Keys>>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={false}
    >
      {formikProps => (
        <Modal
          title={title ?? t("Projects settings", { ns: "Projects" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: formikProps.isSubmitting,
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
          maxWidth="xs"
        >
          <Form id={formId}>
            <Stack direction="column" spacing={2}>
              <CollapseSection title={t("Data on cards", { ns: "Projects" })}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="column">
                    {displayOptions.map(({ value, label }) => (
                      <SwitchField
                        key={value}
                        label={label}
                        name={`listItemDisplayConfig.${value}`}
                        disabled={formikProps.isSubmitting}
                      />
                    ))}
                  </Stack>
                </Stack>
              </CollapseSection>
              <CollapseSection
                title={t("Import projects via", { ns: "Projects" })}
              >
                <Stack direction="column" spacing={0}>
                  {(Object.entries(labels) as [Keys, string][]).map(
                    ([field, label]) => (
                      <SwitchField
                        key={field}
                        label={label}
                        name={`importVisibility.${field}`}
                        disabled={formikProps.isSubmitting}
                      />
                    )
                  )}
                </Stack>
              </CollapseSection>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
