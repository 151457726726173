import {
  Currency,
  getFormattedDate,
  getFormattedFloat,
  getFormattedPrice,
  getFormattedTime,
  getHumanReadableDuration,
} from "@msys/formatting";
import { Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import React from "react";
import { useLocale } from "../LocaleProvider";

interface Money {
  amount: number;
  currency: string;
}

export const FormattedPercentage = ({
  value,
  notSetText = "–",
}: {
  value?: number | BigNumber | Money | null;
  notSetText?: string;
}) => {
  const locale = useLocale();

  function renderPercentage() {
    if (typeof value === "number") {
      return getFormattedFloat(value * 100, locale) + "%";
    } else if (BigNumber.isBigNumber(value)) {
      return getFormattedFloat(value.toNumber() * 100, locale) + "%";
    } else if (value) {
      return getFormattedFloat(value.amount * 100, locale) + "%";
    } else {
      return notSetText;
    }
  }

  return <React.Fragment>{renderPercentage()}</React.Fragment>;
};

export const FormattedPrice = ({
  value,
  isPlaceholder,
  showFallback = true,
  notSetText = "–",
}: {
  value?: number | BigNumber | Money | null;
  isPlaceholder?: boolean;
  showFallback?: boolean;
  notSetText?: string;
}) => {
  const locale = useLocale();

  function renderPrice() {
    if (isPlaceholder) {
      return renderPlaceholder(Currency.Eur);
    } else if (typeof value === "number") {
      return getFormattedPrice(value, locale);
    } else if (BigNumber.isBigNumber(value)) {
      return getFormattedPrice((value as BigNumber).toNumber(), locale);
    } else if (value) {
      return getFormattedPrice((value as Money).amount, locale);
    } else {
      return showFallback ? notSetText : null;
    }
  }

  return <React.Fragment>{renderPrice()}</React.Fragment>;
};

function renderPlaceholder(currency: Currency) {
  return (
    <Typography component="p" style={{ textDecoration: "underline" }}>
      {Array.from({ length: 14 }).map((_v, idx) => (
        <React.Fragment key={idx}>&nbsp;</React.Fragment>
      ))}
      {currency}
    </Typography>
  );
}

export const FormattedTime = ({
  value,
  notSetText = "–",
}: {
  value: number | BigNumber | null | undefined;
  notSetText?: string;
}) => {
  if (!value) return <React.Fragment>{notSetText}</React.Fragment>;

  const numberValue: number = BigNumber.isBigNumber(value)
    ? value.toNumber()
    : value;
  const { hours, min } = getFormattedTime(numberValue);
  const timeString = hours + ":" + String(min).padStart(2, "0") + "h";

  return <React.Fragment>{timeString}</React.Fragment>;
};

export const FormattedDate = ({
  date,
  notSetText = "–",
}: {
  date?: Date;
  notSetText?: string;
}) => {
  const locale = useLocale();

  if (!date) return <>{notSetText}</>;

  return <>{getFormattedDate(date, locale)}</>;
};

export const Duration = ({
  duration,
  notSetText = "–",
}: {
  duration?: number | null;
  notSetText?: string;
}) => {
  const locale = useLocale();

  if (!duration) return <>{notSetText}</>;

  return <>{getHumanReadableDuration(duration, locale)}</>;
};
