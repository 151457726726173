/* eslint-disable */
import * as Types from '../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type VirtualItem__DocumentItemFragment = { __typename: 'Item', id: string, title: string, rank: number, level: number, parentId?: string | null, isRootItem: boolean, pathForPdf: string, pathSortable: string, isHidden: boolean, isPriceHidden: boolean, isParentDecisionPreselected: boolean, isParentDecisionNotPreselected: boolean, isParentContingencyNotPreselected: boolean, deletedAt?: any | null, type: Types.ItemType, binding: Types.ItemBinding, decisionIsContingentItem: boolean, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, childrenVisibility: Types.ItemChildrenVisibility, isItemEliminated: boolean, hasChildren: boolean, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export const VirtualItem__DocumentItemFragmentDoc = gql`
    fragment VirtualItem__DocumentItem on Item {
  id
  title
  rank
  level
  parentId
  isRootItem
  pathForPdf
  pathSortable
  isHidden
  isPriceHidden
  isParentDecisionPreselected
  isParentDecisionNotPreselected
  isParentContingencyNotPreselected
  deletedAt
  type
  binding
  decisionIsContingentItem
  decisionSubitemsPreselection
  decisionBehaviorOfSubitems
  decisionContingentItemPreselection
  decisionOptionElimination {
    reason
  }
  isExplicitHidden
  childrenVisibility
  isItemEliminated
  hasChildren
}
    `;