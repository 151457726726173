import { gql } from "@apollo/client";
import { CardContainer, CardItem } from "@msys/ui";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DirectionsIcon from "@mui/icons-material/Directions";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { groupBy } from "lodash";
import moment, { Moment } from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "../../commons/layout/Stack";
import { Address } from "../../../clients/graphqlTypes";
import { AgendaPlanSessionFragment } from "./AgendaBox.generated";
import { useTranslate } from "@tolgee/react";
import { getAddressDirectionUrl, getAddressLabel } from "../addresses/helpers";

export type MomentRange = [Moment, Moment];
enum View {
  Today,
  Week,
}

export const AgendaBox: React.FC<{
  userSessions: AgendaPlanSessionFragment[];
  startOfWorkHour: number;
  endOfWorkHour: number;
  dateRange: MomentRange;
  setDateRange: React.Dispatch<React.SetStateAction<MomentRange>>;
}> = ({
  userSessions,
  startOfWorkHour,
  endOfWorkHour,
  dateRange,
  setDateRange,
}) => {
  const { t } = useTranslate("Global");

  const [activeView, setActiveView] = React.useState(View.Today);

  const nextWeek = React.useCallback(() => {
    setDateRange(range => [range[0].add(7, "days"), range[1].add(7, "days")]);
  }, [setDateRange]);

  const previousWeek = React.useCallback(() => {
    setDateRange(range => [
      range[0].subtract(7, "days"),
      range[1].subtract(7, "days"),
    ]);
  }, [setDateRange]);

  const agendaItems = userSessions
    .map(session => ({
      id: session.id,
      title: session.project.title,
      address: session.project.building?.buildingAddress ?? null,
      from: moment(session.from),
      until: moment(session.till),
      projectId: session.project.id,
      isTentative: session.isTentative,
    }))
    .flatMap(session => {
      const differenceInDays = session.until.diff(session.from, "days");

      if (differenceInDays === 0) return [session];

      const sessions = Array.from(Array(differenceInDays + 1).keys())
        .map(index => ({
          ...session,
          from:
            index === 0
              ? session.from
              : moment(session.from).add(index, "day").hours(startOfWorkHour),
          until:
            index === differenceInDays
              ? session.until
              : moment(session.from).add(index, "day").hours(endOfWorkHour),
        }))
        .filter(
          session =>
            session.from.isoWeekday() < 6 &&
            session.from.isBefore(dateRange[1]) &&
            session.until.isAfter(dateRange[0]) &&
            !session.isTentative
        );

      return sessions;
    })
    .sort((session1, session2) =>
      session1.from.isBefore(session2.from) ? -1 : 1
    );

  const days = Array.from(Array(7).keys())
    .map(index => moment(dateRange[0]).add(index, "days").startOf("date"))
    .filter(day => activeView !== View.Today || moment().isSame(day, "day"));

  const agendaItemsGroupedByDate =
    groupBy(agendaItems, sessions => moment(sessions.from).startOf("date")) ??
    {};

  return (
    <CardContainer isExpandable title={t("Agenda")}>
      <Stack flexDirection="column" spacing={0}>
        {activeView === View.Week && (
          <>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              padding={1}
            >
              <Typography variant="h3">
                {`${t("Week")} ${dateRange[0].format(
                  "DD/MM"
                )} - ${dateRange[1].format("DD/MM")}`}
              </Typography>
              <Stack>
                <IconButton color="primary" size="small" onClick={previousWeek}>
                  <ChevronLeft fontSize="large" />
                </IconButton>
                <IconButton color="primary" size="small" onClick={nextWeek}>
                  <ChevronRight fontSize="large" />
                </IconButton>
              </Stack>
            </Stack>
            <Divider />
          </>
        )}
        <Stack flexDirection="column" marginY={1} spacing={2} padding={1}>
          {days.map(day => (
            <AgendaDay
              key={day.format("ll")}
              day={day}
              sessions={agendaItemsGroupedByDate[day.toString()]}
            />
          ))}
        </Stack>
        <Stack padding={1}>
          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={() =>
              setActiveView(activeView === View.Today ? View.Week : View.Today)
            }
          >
            {activeView === View.Today
              ? t("Show whole week")
              : t("Show today only")}
          </Button>
        </Stack>
      </Stack>
    </CardContainer>
  );
};

interface Session {
  id: string;
  title: string;
  from: Moment;
  until: Moment;
  address: Address | null;
  projectId: string;
}

const AgendaDay: React.FC<{
  day: Moment;
  sessions: Session[] | undefined;
}> = ({ day, sessions = [] }) => {
  const { t } = useTranslate("Global");
  const theme = useTheme();

  return (
    <Stack flexDirection="column">
      <Stack>
        <Typography variant="h4">
          {day.isSame(moment.now(), "day")
            ? `${t("Today")}, ${moment(day).format("ll")}`
            : moment(day).format("ddd, ll")}
        </Typography>
      </Stack>

      {sessions.length === 0 ? (
        <Typography
          variant="caption"
          style={{ color: theme.palette.grey[500] }}
        >
          {t("No assignments")}
        </Typography>
      ) : (
        <List disablePadding>
          {sessions.map(item => (
            <ListItem key={item.id} disablePadding>
              <ListItemText>
                <CardItem
                  //@ts-ignore
                  component={Link}
                  to={`/projects/${item.projectId}`}
                >
                  <AgendaItem
                    {...item}
                    showDirectionsButton={item.from.isSame(moment.now(), "day")}
                  />
                </CardItem>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};

const AgendaItem: React.FC<{
  showDirectionsButton?: boolean;
  title: string;
  from: Moment;
  until: Moment;
  address: Address | null;
}> = ({ showDirectionsButton = false, title, from, until, address }) => {
  const theme = useTheme();
  const now = moment();
  const in30Minutes = moment(now).add(120, "minutes");
  const isSoon = from.isBetween(moment(), in30Minutes);

  return (
    <Stack maxWidth="100%" justifyContent="space-between" alignItems="center">
      <Stack flexDirection="column" spacing={0} flex={1} overflow="hidden">
        {isSoon && (
          <Typography
            variant="body2"
            style={{ color: theme.palette.warning.main }}
          >
            {now.to(from)}
          </Typography>
        )}
        <Typography>{title}</Typography>
        <Stack justifyContent="space-between" spacing={2}>
          <Stack flex={1} alignItems="center" spacing={0.5}>
            <AccessTimeIcon fontSize="inherit" color="secondary" />
            <Typography noWrap variant="body2" color="secondary">
              {`${from.format("HH:mm")} – ${until.format("HH:mm")}`}
            </Typography>
          </Stack>
          {address && (
            <Stack alignItems="center" spacing={0.5} overflow="hidden">
              <LocationOnIcon fontSize="inherit" color="secondary" />
              <Typography noWrap variant="body2" color="secondary">
                {getAddressLabel(address)}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
      {showDirectionsButton && address && (
        <Box flex={0}>
          <IconButton
            color="primary"
            size="small"
            component="a"
            target="_blank"
            rel="noreferrer nofollow"
            href={getAddressDirectionUrl(address)}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              event.stopPropagation()
            }
          >
            <DirectionsIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};
