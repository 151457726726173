/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentFragmentDoc, AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../attachments/Attachments.generated';
export type RequestOverviewAttachmentsBoxFragment = { __typename: 'Request', id: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, mimeType: string, url: string, group: string }> };

export const RequestOverviewAttachmentsBoxFragmentDoc = gql`
    fragment RequestOverviewAttachmentsBox on Request {
  id
  attachments {
    ...Attachment
  }
}
    ${AttachmentFragmentDoc}`;