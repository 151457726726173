import {
  BizHours,
  DEFAULT_END_WORK_DAY,
  DEFAULT_START_WORK_DAY,
  getWorkSessions,
  WeekDay,
  WorkDays,
} from "@msys/common";
import { getNormalizedDuration } from "@msys/formatting";
import moment, { Moment } from "moment";
import { DEFAULT_PROJECT_DURATION, DEFAULT_TICKET_DURATION } from "../../utils";

const getStartOfNextWorkDay = (
  date: moment.Moment,
  startWorkDay: string,
  bizHours: BizHours
) => {
  const startWorkDayParts = startWorkDay.split(":");
  let newDate = moment(date),
    newBizHours;

  do {
    newDate = moment(newDate)
      .add(1, "days")
      .set({
        hour: +startWorkDayParts[0],
        minute: +startWorkDayParts[1],
        second: 0,
        millisecond: 0,
      });
    const weekday = newDate.isoWeekday() as WeekDay;
    newBizHours = bizHours[weekday];
  } while (!newBizHours);

  return newDate;
};

const getEndOfPreviousWorkDay = (
  date: moment.Moment,
  endWorkDay: string,
  bizHours: BizHours
) => {
  const endWorkDayParts = endWorkDay.split(":");
  let newDate = moment(date),
    newBizHours;

  do {
    newDate = moment(newDate)
      .add(-1, "days")
      .set({
        hour: +endWorkDayParts[0],
        minute: +endWorkDayParts[1],
        second: 0,
        millisecond: 0,
      });
    const weekday = newDate.isoWeekday() as WeekDay;
    newBizHours = bizHours[weekday];
  } while (!newBizHours);

  return newDate;
};

// looking for free ?-hours slot today or next working day
const getNextHoursSlot = (
  now: moment.Moment,
  duration: number,
  bizHours: BizHours,
  startWorkDay: string,
  endWorkDay: string
): [moment.Moment, moment.Moment] => {
  let from = moment(now);
  let till = moment(now).add(duration, "seconds");

  const startWorkDayParts = startWorkDay.split(":");

  const tillWeekday = till.isoWeekday() as WeekDay;
  const tillTime = till.format("HH:mm");

  const tillBizEndTime = bizHours[tillWeekday]?.end ?? null;
  const tillBizStartTime = bizHours[tillWeekday]?.start ?? null;

  if (tillBizStartTime && tillBizEndTime) {
    if (tillTime <= tillBizStartTime) {
      from = moment(till).set({
        hour: +startWorkDayParts[0],
        minute: +startWorkDayParts[1],
        second: 0,
        millisecond: 0,
      });
      till = moment(from).add(duration, "seconds");
      return [from, till];
    } else if (tillTime <= tillBizEndTime) {
      return [from, till];
    }
  }

  from = getStartOfNextWorkDay(now, startWorkDay, bizHours);
  till = moment(from).add(duration, "seconds");

  return [from, till];
};

// from start of next working day and till + ? days/weeks/months
const getNextDaysSlot = (
  now: moment.Moment,
  duration: number,
  bizHours: BizHours,
  startWorkDay: string,
  endWorkDay: string
): [moment.Moment, moment.Moment] => {
  const from = getStartOfNextWorkDay(now, startWorkDay, bizHours);
  const till = getEndOfPreviousWorkDay(
    moment(from).add(duration, "seconds"),
    endWorkDay,
    bizHours
  );
  return [from, till];
};

export const getNextWorkingSessionDate = (
  now: moment.Moment,
  projectIsTicket: boolean,
  defaultProjectDuration: number,
  defaultTicketDuration: number,
  lastFrom: moment.Moment | null,
  lastTill: moment.Moment | null,
  startWorkDay: string = DEFAULT_START_WORK_DAY, // should be in HH:mm format
  endWorkDay: string = DEFAULT_END_WORK_DAY // should be in HH:mm format
): [moment.Moment, moment.Moment] => {
  if (lastFrom && lastTill) return [lastFrom, lastTill];

  // TODO: holidays?
  const bizHours: BizHours = {
    1: { start: startWorkDay, end: endWorkDay },
    2: { start: startWorkDay, end: endWorkDay },
    3: { start: startWorkDay, end: endWorkDay },
    4: { start: startWorkDay, end: endWorkDay },
    5: { start: startWorkDay, end: endWorkDay },
    6: null,
    7: null,
  };

  const duration = projectIsTicket
    ? defaultTicketDuration || DEFAULT_TICKET_DURATION
    : defaultProjectDuration || DEFAULT_PROJECT_DURATION;

  const [, durationType] = getNormalizedDuration(duration);

  return durationType === "none" || durationType === "hours"
    ? getNextHoursSlot(now, duration, bizHours, startWorkDay, endWorkDay)
    : getNextDaysSlot(now, duration, bizHours, startWorkDay, endWorkDay);
};

export const getTotalDurationInMinutes = (
  start: moment.Moment,
  end: moment.Moment,
  startWorkDay: string = DEFAULT_START_WORK_DAY, // should be in HH:mm format
  endWorkDay: string = DEFAULT_END_WORK_DAY // should be in HH:mm format
) => {
  const workSessions = getWorkSessions(start, end, startWorkDay, endWorkDay);
  return workSessions.reduce((acc, [startMoment, endMoment]) => {
    const duration = endMoment.diff(startMoment, "minutes");
    return acc + Math.max(duration, 0);
  }, 0);
};

// TODO: holidays?
const workDays: WorkDays = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: false,
  7: false,
};

export const getTotalDurationInDays = (
  from: string | moment.Moment,
  till: string | moment.Moment
): number => {
  let days = 0;
  let weekday;
  const current = moment(from).startOf("day");
  const end = moment(till).startOf("day");

  if (current.isAfter(end)) return 0;

  do {
    weekday = current.isoWeekday() as WeekDay; // returns 1-7 where 1 is Monday and 7 is Sunday

    if (workDays[weekday]) days++;

    current.add(1, "days");
  } while (current.isSameOrBefore(end));

  return days;
};

export const getTotalDifferenceInDays = (
  from: string | moment.Moment,
  till: string | moment.Moment
): number => {
  const diff = moment(till)
    .startOf("day")
    .diff(moment(from).startOf("day"), "days");
  return diff < 0 ? 0 : diff + 1;
};

export const formatDateRange = (from: string, till: string) => {
  const mFrom = moment(from);
  const mTill = moment(till);
  const isSameDay = mFrom.isSame(mTill, "day");
  return `${mFrom.format("DD/MM, HH:mm")} – ${
    isSameDay ? mTill.format("HH:mm") : mTill.format("HH:mm, DD/MM")
  }`;
};

export type MomentRange = [Moment | undefined, Moment | undefined];

export const isEqualRange = (
  range1: [moment.Moment | null, moment.Moment | null] | null | undefined,
  range2: [moment.Moment | null, moment.Moment | null] | null | undefined
) => {
  const range1Defined = Boolean(range1 && (range1[0] || range1[1]));
  const range2Defined = Boolean(range2 && (range2[0] || range2[1]));

  if (!range1Defined && !range2Defined) return true;
  if ((range1Defined && !range2Defined) || (!range1Defined && range2Defined))
    return false;

  const isValue1Same =
    (!range1![0] && !range2![0]) ||
    (range1![0] && range2![0] && range1![0]!.isSame(range2![0]!, "minutes"));
  const isValue2Same =
    (!range1![1] && !range2![1]) ||
    (range1![1] && range2![1] && range1![1]!.isSame(range2![1]!, "minutes"));
  return Boolean(isValue1Same && isValue2Same);
};
