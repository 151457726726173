import { gql } from "@apollo/client";
import { RecipientOption } from "../crm/CrmSendEmailForm";
import { CrmCompanyToEmailRecipientOption_CrmCompanyFragment } from "./utils.generated";

export function crmCompanyToEmailRecipientOption(
  crmCompany: CrmCompanyToEmailRecipientOption_CrmCompanyFragment
): RecipientOption {
  return {
    key: crmCompany.id,
    label: `${crmCompany.title} <${crmCompany.email}>`,
    value: crmCompany.id,
    crmCompanyId: crmCompany.id,
    crmPersonId: null,
  };
}
