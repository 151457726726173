import { Box, Stack, Typography, TypographyProps } from "@mui/material";
import React from "react";

interface Props extends TypographyProps<"div"> {
  label: string;
  labelWidth?: number | string;
  labelLineHeight?: number;
  labelProps?: Omit<
    TypographyProps<"label">,
    "children" | "variant" | "component"
  >;
}
export const LabeledValueHorizontal = ({
  label,
  labelWidth,
  labelLineHeight = 1.7,
  labelProps,
  children,
  ...otherProps
}: Props) => {
  return (
    <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
      <Box width={labelWidth} display="flex" alignItems="center" flexShrink={0}>
        <Typography
          component="label"
          color="textSecondary"
          variant="caption"
          lineHeight={labelLineHeight}
          style={{ hyphens: "auto" }}
          {...labelProps}
        >
          {label}:
        </Typography>
      </Box>
      <Box>
        <Typography component="div" variant="body2" {...otherProps}>
          {children}
        </Typography>
      </Box>
    </Stack>
  );
};
