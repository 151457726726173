import { gql, useApolloClient } from "@apollo/client";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import React from "react";
import { ConfirmModal } from "../../../commons/modals/ConfirmModal";
import { ModalOpenButton, useScreenWidth } from "@msys/ui";
import { useDiscardPendingChangesMutation } from "./DiscardPendingChangesButton.generated";
import { useTranslate } from "@tolgee/react";

interface Props extends LoadingButtonProps {
  projectId: string;
  quoteId: string;
  itemId: string;
  refetchQueries?: string[];
  handleComplete?: () => Promise<unknown> | unknown;
}

export const DiscardPendingChangesButton = ({
  projectId,
  quoteId,
  itemId,
  refetchQueries,
  handleComplete,
  ...props
}: Props) => {
  const { t } = useTranslate(["QuoteEdit", "Global"]);
  const { isMaxPhone } = useScreenWidth();
  const client = useApolloClient();
  const [discardPendingChanges, { loading }] = useDiscardPendingChangesMutation(
    { client, refetchQueries }
  );

  return (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        handleConfirm: async () => {
          await discardPendingChanges({
            variables: {
              input: {
                projectId,
                docId: quoteId,
                itemId,
                deep: true,
              },
            },
          });
          await handleComplete?.();
        },
      }}
    >
      <LoadingButton
        color="warning"
        variant="contained"
        loading={loading}
        {...props}
      >
        {isMaxPhone
          ? t("Revert", { ns: "Global" })
          : t("Revert to accepted", { ns: "QuoteEdit" })}
      </LoadingButton>
    </ModalOpenButton>
  );
};
