/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { DecisionOptionButton_DocumentItemFragmentDoc } from '../fields/DecisionOptionRadioOrCheckbox.generated';
import { ItemAttributeExpressionsFragmentDoc } from '../properties.generated';
export type RulesSection_CurrentDocumentItemFragment = { __typename: 'Item', id: string, parentId?: string | null, decisionIsContingentItem: boolean, decisionContingentItemPreselection?: boolean | null, isDecisionOption: boolean, isItemEliminated: boolean, decisionOptionBehaviour: Types.DecisionBehaviorOfSubitems, decisionOptionIsPreselected?: boolean | null, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null, attributeExpressions: Array<{ __typename: 'ItemAttributeExpression', attribute: string, expr: string, result: any, missingValue: boolean }> };

export const RulesSection_CurrentDocumentItemFragmentDoc = gql`
    fragment RulesSection_CurrentDocumentItem on Item {
  id
  parentId
  decisionIsContingentItem
  decisionContingentItemPreselection
  isDecisionOption
  isItemEliminated
  ...DecisionOptionButton_DocumentItem
  ...ItemAttributeExpressions
}
    ${DecisionOptionButton_DocumentItemFragmentDoc}
${ItemAttributeExpressionsFragmentDoc}`;