import { Box, BoxProps, Chip } from "@mui/material";
import React from "react";

interface Props {
  tags: string[];
  wrap?: boolean;
  boxProps?: BoxProps;
}

export const TagChips = ({ tags, wrap = true, boxProps }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="baseline"
      flexWrap={wrap ? "wrap" : "nowrap"}
      sx={{ ml: -0.5, mt: -0.25 }}
      {...boxProps}
    >
      {tags.map((tag, index) => (
        <Chip
          variant="outlined"
          clickable={false}
          key={`${tag}-${index}`}
          label={tag}
          size="small"
          sx={{ ml: 0.5, mt: 0.25 }}
        />
      ))}
    </Box>
  );
};
