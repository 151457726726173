/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { InvoiceListItem_OutgoingInvoiceFragmentDoc, InvoiceListItem_IncomingInvoiceFragmentDoc } from '../../features/invoices/InvoiceListItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentsOutgoingInvoicesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sorting: Array<Types.OutgoingInvoicesSorting>;
  filterStatus?: Types.InputMaybe<Array<Types.InvoiceStatus>>;
  filterOverdue?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterByCrmOrganisationId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DocumentsOutgoingInvoicesQuery = { outgoingInvoices: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'OutgoingInvoiceConnection', totalCount: number, totalAmountOpen: number, totalAmountOverdue: number, edges: Array<{ __typename: 'OutgoingInvoiceEdge', node: { __typename: 'OutgoingInvoice', id: string, number?: string | null, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, createdAt: any, projectId: string, projectNumber: string, projectTitle: string, clientName: string, totalNetPrice: number, totalGrossPrice: number, dueDate?: any | null, issueDate?: any | null } }> } };

export type DocumentsOutgoingInvoices_InvoiceFragment = { __typename: 'OutgoingInvoice', id: string, number?: string | null, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, createdAt: any, projectId: string, projectNumber: string, projectTitle: string, clientName: string, totalNetPrice: number, totalGrossPrice: number, dueDate?: any | null, issueDate?: any | null };

export type DocumentsIncomingInvoicesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sorting: Array<Types.IncomingInvoicesSorting>;
  filterOverdue?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterStatus?: Types.InputMaybe<Array<Types.InvoiceStatus>>;
  filterByCrmOrganisationId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DocumentsIncomingInvoicesQuery = { incomingInvoices: { __typename: 'IncomingInvoiceConnection', totalCount: number, totalAmountOpen: number, totalAmountOverdue: number, edges: Array<{ __typename: 'IncomingInvoiceEdge', node: { __typename: 'IncomingInvoice', id: string, number: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, createdAt: any, projectId: string, projectNumber: string, projectTitle: string, contractorName: string, totalNetPrice: number, totalGrossPrice: number, dueDate: any, issueDate?: any | null } }> } | { __typename: 'MissingPermissions' } };

export type DocumentsIncomingInvoices_InvoiceFragment = { __typename: 'IncomingInvoice', id: string, number: string, type: Types.InvoiceType, status: Types.InvoiceStatus, title: string, createdAt: any, projectId: string, projectNumber: string, projectTitle: string, contractorName: string, totalNetPrice: number, totalGrossPrice: number, dueDate: any, issueDate?: any | null };

export const DocumentsOutgoingInvoices_InvoiceFragmentDoc = gql`
    fragment DocumentsOutgoingInvoices_Invoice on OutgoingInvoice {
  id
  number
  type
  status
  title
  createdAt
  projectId
  projectNumber
  projectTitle
  clientName
  totalNetPrice
  totalGrossPrice
  dueDate
  issueDate
}
    `;
export const DocumentsIncomingInvoices_InvoiceFragmentDoc = gql`
    fragment DocumentsIncomingInvoices_Invoice on IncomingInvoice {
  id
  number
  type
  status
  title
  createdAt
  projectId
  projectNumber
  projectTitle
  contractorName
  totalNetPrice
  totalGrossPrice
  dueDate
  issueDate
}
    `;
export const DocumentsOutgoingInvoicesDocument = gql`
    query DocumentsOutgoingInvoices($limit: Int!, $offset: Int!, $sorting: [OutgoingInvoicesSorting!]!, $filterStatus: [InvoiceStatus!], $filterOverdue: Boolean, $filterByCrmOrganisationId: ID, $searchTerm: String) {
  outgoingInvoices(
    limit: $limit
    offset: $offset
    sorting: $sorting
    filters: {overdue: $filterOverdue, status: $filterStatus, crmOrganisationId: $filterByCrmOrganisationId}
    search: $searchTerm
  ) {
    ... on OutgoingInvoiceConnection {
      edges {
        node {
          id
          ...DocumentsOutgoingInvoices_Invoice
          ...InvoiceListItem_OutgoingInvoice
        }
      }
      totalCount
      totalAmountOpen
      totalAmountOverdue
    }
  }
}
    ${DocumentsOutgoingInvoices_InvoiceFragmentDoc}
${InvoiceListItem_OutgoingInvoiceFragmentDoc}`;

/**
 * __useDocumentsOutgoingInvoicesQuery__
 *
 * To run a query within a React component, call `useDocumentsOutgoingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsOutgoingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsOutgoingInvoicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorting: // value for 'sorting'
 *      filterStatus: // value for 'filterStatus'
 *      filterOverdue: // value for 'filterOverdue'
 *      filterByCrmOrganisationId: // value for 'filterByCrmOrganisationId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDocumentsOutgoingInvoicesQuery(baseOptions: Apollo.QueryHookOptions<DocumentsOutgoingInvoicesQuery, DocumentsOutgoingInvoicesQueryVariables> & ({ variables: DocumentsOutgoingInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsOutgoingInvoicesQuery, DocumentsOutgoingInvoicesQueryVariables>(DocumentsOutgoingInvoicesDocument, options);
      }
export function useDocumentsOutgoingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsOutgoingInvoicesQuery, DocumentsOutgoingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsOutgoingInvoicesQuery, DocumentsOutgoingInvoicesQueryVariables>(DocumentsOutgoingInvoicesDocument, options);
        }
export function useDocumentsOutgoingInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsOutgoingInvoicesQuery, DocumentsOutgoingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentsOutgoingInvoicesQuery, DocumentsOutgoingInvoicesQueryVariables>(DocumentsOutgoingInvoicesDocument, options);
        }
export type DocumentsOutgoingInvoicesQueryHookResult = ReturnType<typeof useDocumentsOutgoingInvoicesQuery>;
export type DocumentsOutgoingInvoicesLazyQueryHookResult = ReturnType<typeof useDocumentsOutgoingInvoicesLazyQuery>;
export type DocumentsOutgoingInvoicesSuspenseQueryHookResult = ReturnType<typeof useDocumentsOutgoingInvoicesSuspenseQuery>;
export type DocumentsOutgoingInvoicesQueryResult = Apollo.QueryResult<DocumentsOutgoingInvoicesQuery, DocumentsOutgoingInvoicesQueryVariables>;
export const DocumentsIncomingInvoicesDocument = gql`
    query DocumentsIncomingInvoices($limit: Int!, $offset: Int!, $sorting: [IncomingInvoicesSorting!]!, $filterOverdue: Boolean, $filterStatus: [InvoiceStatus!], $filterByCrmOrganisationId: ID, $searchTerm: String) {
  incomingInvoices(
    limit: $limit
    offset: $offset
    sorting: $sorting
    filters: {overdue: $filterOverdue, status: $filterStatus, crmOrganisationId: $filterByCrmOrganisationId}
    search: $searchTerm
  ) {
    ... on IncomingInvoiceConnection {
      edges {
        node {
          id
          ...DocumentsIncomingInvoices_Invoice
          ...InvoiceListItem_IncomingInvoice
        }
      }
      totalCount
      totalAmountOpen
      totalAmountOverdue
    }
  }
}
    ${DocumentsIncomingInvoices_InvoiceFragmentDoc}
${InvoiceListItem_IncomingInvoiceFragmentDoc}`;

/**
 * __useDocumentsIncomingInvoicesQuery__
 *
 * To run a query within a React component, call `useDocumentsIncomingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsIncomingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsIncomingInvoicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorting: // value for 'sorting'
 *      filterOverdue: // value for 'filterOverdue'
 *      filterStatus: // value for 'filterStatus'
 *      filterByCrmOrganisationId: // value for 'filterByCrmOrganisationId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDocumentsIncomingInvoicesQuery(baseOptions: Apollo.QueryHookOptions<DocumentsIncomingInvoicesQuery, DocumentsIncomingInvoicesQueryVariables> & ({ variables: DocumentsIncomingInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsIncomingInvoicesQuery, DocumentsIncomingInvoicesQueryVariables>(DocumentsIncomingInvoicesDocument, options);
      }
export function useDocumentsIncomingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsIncomingInvoicesQuery, DocumentsIncomingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsIncomingInvoicesQuery, DocumentsIncomingInvoicesQueryVariables>(DocumentsIncomingInvoicesDocument, options);
        }
export function useDocumentsIncomingInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentsIncomingInvoicesQuery, DocumentsIncomingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentsIncomingInvoicesQuery, DocumentsIncomingInvoicesQueryVariables>(DocumentsIncomingInvoicesDocument, options);
        }
export type DocumentsIncomingInvoicesQueryHookResult = ReturnType<typeof useDocumentsIncomingInvoicesQuery>;
export type DocumentsIncomingInvoicesLazyQueryHookResult = ReturnType<typeof useDocumentsIncomingInvoicesLazyQuery>;
export type DocumentsIncomingInvoicesSuspenseQueryHookResult = ReturnType<typeof useDocumentsIncomingInvoicesSuspenseQuery>;
export type DocumentsIncomingInvoicesQueryResult = Apollo.QueryResult<DocumentsIncomingInvoicesQuery, DocumentsIncomingInvoicesQueryVariables>;