import { ModalOpenButton } from "@msys/ui";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ModifyProjectMemberModal } from "../modals/ModifyProjectMemberModal";

export const ModifyProjectMembersButton: React.FC<{
  projectId: string;
  type?: "icon" | "button";
  refetchQueries?: string[];
}> = ({ projectId, type, refetchQueries }) => {
  const { t } = useTranslate("ProjectMembers");
  const title = t("Manage project participants");

  return (
    <ModalOpenButton
      Modal={ModifyProjectMemberModal}
      modalProps={{
        projectId,
        refetchQueries,
      }}
    >
      {type !== "icon" ? (
        <Button color="primary" variant="outlined">
          {title}
        </Button>
      ) : (
        <Tooltip title={title}>
          <IconButton color="secondary" aria-label={title} size="small">
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      )}
    </ModalOpenButton>
  );
};
