import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  FilterByBoolean,
  FilterByNumber,
  FilterByText,
  FilterProps,
} from "../../../commons/filters";

export {
  FilterProjectCategory,
  FilterProjectCategoryChips,
} from "./ProjectCategory";
export { ProjectsSorting } from "./ProjectSorting";
export { FilterProjectState, FilterProjectStateChips } from "./ProjectState";
export { FilterProjectType, FilterProjectTypeChips } from "./ProjectType";

export const FilterBuildingZip = (props: FilterProps<string>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByText
      label={t("Project building zip")}
      placeholder={t("Enter zipcode")}
      {...props}
    />
  );
};

export const FilterWithinRadiusKm = (
  props: FilterProps<number> & { label?: string }
) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByNumber
      label={props.label || t("Buildings within radius")}
      placeholder={t("e.g. 40")}
      helperText={t("in km; 0 = any")}
      {...props}
    />
  );
};

export const FilterMinBudget = (props: FilterProps<number>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByNumber
      label={t("Minimum Budget")}
      placeholder={t("e.g. 10000")}
      helperText={t("in EUR; 0 = any")}
      {...props}
    />
  );
};

export const FilterPlanned = (props: FilterProps<boolean | undefined>) => {
  const { t } = useTranslate("Projects");
  return (
    <FilterByBoolean
      label={t("Planned")}
      yesLabel={t("Only planned")}
      noLabel={t("Only not planned")}
      {...props}
    />
  );
};
