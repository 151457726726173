import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { ErrorMessage, LoadingSpinner } from "@msys/ui";
import {
  Agenda,
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
} from "@syncfusion/ej2-react-schedule";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../auth/useUserData";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { useCalendarQuery } from "./Calendar.generated";

export const Calendar = () => {
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const query = useCalendarQuery({
    client,
    variables: { userId: viewer.id },
    fetchPolicy: "cache-and-network",
  });
  const planSessions =
    getDataOrNull(query.data?.planSessions)?.edges.map(e => e.node) ?? [];

  if (query.loading) {
    return <LoadingSpinner />;
  } else if (query.error) {
    return <ErrorMessage message={query.error.message} />;
  }

  const data = planSessions.map(ps => {
    return {
      Id: ps.id,
      Subject: ps.project.title,
      IsAllDay: false,
      ProjectId: ps.project.id,

      StartTime: moment(ps.from).local().toDate(),
      EndTime: moment(ps.till).local().toDate(),
    };
  });

  return (
    <Page>
      <PageContainer>
        <ScheduleComponent
          popupOpen={args => {
            if (!args) return;

            args.cancel = true;

            if (args.type === "QuickInfo") {
              // noop
            } else if (args.type === "Editor") {
              const data: any = args.data;

              if (data && data.ProjectId) {
                setTimeout(() => {
                  // let ScheduleComponent do its stuff and new push route on next tick
                  navigate(`/projects/${data.ProjectId}/overview`);
                }, 0);
              }
            }
          }}
          actionBegin={async args => {
            if (!args) return;

            if (args.requestType === "eventChange") {
              args.cancel = true;
            }
          }}
          rowAutoHeight
          height="auto"
          currentView="Week"
          width="100%"
          eventSettings={{
            allowDeleting: false,
            dataSource: data,
            fields: {
              id: "Id",
              subject: { name: "Subject" },
              isAllDay: { name: "IsAllDay" },
            },
          }}
        >
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" firstDayOfWeek={1} />
            <ViewDirective option="Month" />
            <ViewDirective option="Agenda" />
          </ViewsDirective>

          <Inject services={[Day, Week, Month, Agenda]} />
        </ScheduleComponent>
      </PageContainer>
    </Page>
  );
};
