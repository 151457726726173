import { CardContainer, ModalOpenButton } from "@msys/ui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Stack } from "../../../commons/layout/Stack";
import { ModifyOrganisationTimeReportsTextsModal } from "../modals/ModifyOrganisationTimeReportsTextsModal";

interface Props {
  organisationId: string;
}

export const OrganisationTimeReportSettingsBox = ({
  organisationId,
}: Props) => {
  const { t } = useTranslate("OrganisationSettings");

  return (
    <CardContainer isExpandable title={t("Time report PDF settings")}>
      <Stack flexDirection="column" p={1} width="100%">
        <div>
          <ModalOpenButton
            Modal={ModifyOrganisationTimeReportsTextsModal}
            modalProps={{
              organisationId,
              title: t("Customize time report text"),
            }}
          >
            <Button
              type="button"
              color="secondary"
              variant="text"
              size="small"
              endIcon={<ChevronRightIcon />}
            >
              {t("Customize time report text")}
            </Button>
          </ModalOpenButton>
        </div>
      </Stack>
    </CardContainer>
  );
};
