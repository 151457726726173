export type Vertical =
  | "general"
  | "concrete_and_reinforced_concrete"
  | "road"
  | "excavation_and_earthworks"
  | "well_construction"
  | "scaffolding"
  | "masonry"
  | "roof"
  | "heat_and_cold_insulation"
  | "sound_insulation"
  | "screed"
  | "stone_masonry"
  | "cast_stone"
  | "metal"
  | "drywall"
  | "sanitary"
  | "plumbing"
  | "heating"
  | "heat_pump"
  | "furnace_and_air_heating"
  | "electrical_installation"
  | "solar"
  | "chimney_sweeping"
  | "carpentry_and_woodwork"
  | "painting"
  | "stucco"
  | "glass"
  | "tile"
  | "parquet"
  | "interior_doors"
  | "interior_decoration"
  | "alarm_systems"
  | "windows_and_doors"
  | "shutters_and_sun_protection"
  | "gardening"
  | "swimming_pools";

export const verticals: Vertical[] = [
  "general",
  "concrete_and_reinforced_concrete",
  "road",
  "excavation_and_earthworks",
  "well_construction",
  "scaffolding",
  "masonry",
  "roof",
  "heat_and_cold_insulation",
  "sound_insulation",
  "screed",
  "stone_masonry",
  "cast_stone",
  "metal",
  "drywall",
  "sanitary",
  "plumbing",
  "heating",
  "heat_pump",
  "furnace_and_air_heating",
  "electrical_installation",
  "solar",
  "chimney_sweeping",
  "carpentry_and_woodwork",
  "painting",
  "stucco",
  "glass",
  "tile",
  "parquet",
  "interior_doors",
  "interior_decoration",
  "alarm_systems",
  "windows_and_doors",
  "shutters_and_sun_protection",
  "gardening",
  "swimming_pools",
];
