/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { UserAvatarFragmentDoc } from '../../users/UserAvatar.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrmCompanyMembersBox_CrmCompanyFragment = { __typename: 'CrmCompany', id: string, contactPerson?: { __typename: 'CrmPerson', id: string } | null, members: Array<{ __typename: 'CrmPerson', id: string, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } }> };

export type ModifyCrmOrganisationContactPersonMutationVariables = Types.Exact<{
  organisations: Array<Types.CrmOrganisationModifyInput>;
}>;


export type ModifyCrmOrganisationContactPersonMutation = { modifyCrmOrganisation: { __typename: 'CrmOrganisationModifyResult', crmOrganisations: Array<{ __typename: 'CrmCompany', id: string, contactPerson?: { __typename: 'CrmPerson', id: string } | null, members: Array<{ __typename: 'CrmPerson', id: string, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } }> }> } };

export type CrmCompanyMemberRow__CrmPersonFragment = { __typename: 'CrmPerson', id: string, firstname: string, familyname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, url: string } };

export const CrmCompanyMemberRow__CrmPersonFragmentDoc = gql`
    fragment CrmCompanyMemberRow__CrmPerson on CrmPerson {
  id
  firstname
  familyname
  email
  phones {
    id
    number
  }
  ...UserAvatar
}
    ${UserAvatarFragmentDoc}`;
export const CrmCompanyMembersBox_CrmCompanyFragmentDoc = gql`
    fragment CrmCompanyMembersBox_CrmCompany on CrmCompany {
  id
  contactPerson {
    id
  }
  members {
    id
    firstname
    ...CrmCompanyMemberRow__CrmPerson
  }
}
    ${CrmCompanyMemberRow__CrmPersonFragmentDoc}`;
export const ModifyCrmOrganisationContactPersonDocument = gql`
    mutation ModifyCrmOrganisationContactPerson($organisations: [CrmOrganisationModifyInput!]!) {
  modifyCrmOrganisation(input: $organisations) {
    crmOrganisations {
      ...CrmCompanyMembersBox_CrmCompany
    }
  }
}
    ${CrmCompanyMembersBox_CrmCompanyFragmentDoc}`;
export type ModifyCrmOrganisationContactPersonMutationFn = Apollo.MutationFunction<ModifyCrmOrganisationContactPersonMutation, ModifyCrmOrganisationContactPersonMutationVariables>;

/**
 * __useModifyCrmOrganisationContactPersonMutation__
 *
 * To run a mutation, you first call `useModifyCrmOrganisationContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmOrganisationContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmOrganisationContactPersonMutation, { data, loading, error }] = useModifyCrmOrganisationContactPersonMutation({
 *   variables: {
 *      organisations: // value for 'organisations'
 *   },
 * });
 */
export function useModifyCrmOrganisationContactPersonMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmOrganisationContactPersonMutation, ModifyCrmOrganisationContactPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmOrganisationContactPersonMutation, ModifyCrmOrganisationContactPersonMutationVariables>(ModifyCrmOrganisationContactPersonDocument, options);
      }
export type ModifyCrmOrganisationContactPersonMutationHookResult = ReturnType<typeof useModifyCrmOrganisationContactPersonMutation>;
export type ModifyCrmOrganisationContactPersonMutationResult = Apollo.MutationResult<ModifyCrmOrganisationContactPersonMutation>;
export type ModifyCrmOrganisationContactPersonMutationOptions = Apollo.BaseMutationOptions<ModifyCrmOrganisationContactPersonMutation, ModifyCrmOrganisationContactPersonMutationVariables>;