import { TimePicker as MuiTimePicker } from "@mui/lab";
import { TextField, TextFieldProps } from "@mui/material";
import moment, { Moment } from "moment";
import { ChangeEvent } from "react";
import { useLocale } from "../LocaleProvider";

export interface TimePickerProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  id?: string;
  disabled?: boolean;
  label: string;
  value: Moment | null;
  onChange: (
    e: ChangeEvent<HTMLInputElement> | null,
    newValue: string | null
  ) => void;
  minValue?: Moment;
  maxValue?: Moment;
  required?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
}

export const TimePickerMui = ({
  disabled,
  label,
  value,
  onChange,
  ...otherProps
}: TimePickerProps) => {
  const locale = useLocale();

  const format = moment.localeData(locale).longDateFormat("LT");

  return (
    <MuiTimePicker<Moment>
      value={value}
      renderInput={props => <TextField {...props} error={otherProps.error} />}
      onChange={(date: Moment | null, value) => {
        onChange(null, value ?? null);
      }}
      label={label}
      disabled={disabled}
      inputFormat={format}
      showTodayButton
    />
  );
};

export const TimePickerNative = ({
  disabled,
  label,
  value,
  onChange,
  ...otherProps
}: TimePickerProps) => {
  return (
    <TextField
      type="time"
      label={label}
      disabled={disabled}
      value={value}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        step: 60, // 1 min
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChange(e, e.target.value);
      }}
      {...otherProps}
    />
  );
};

export const TimePicker = ({
  browserHasInputTime,
  ...props
}: TimePickerProps & { browserHasInputTime: boolean }) => {
  return browserHasInputTime ? (
    <TimePickerNative {...props} />
  ) : (
    <TimePickerMui {...props} />
  );
};
