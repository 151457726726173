import { useApolloClient } from "@apollo/client";
import { AutocompleteMultiple } from "@msys/ui";
import { Chip } from "@mui/material";
import {
  FilterChipGroup,
  FilterChipGroupProps,
} from "../../commons/filters/FilterChip";
import {
  getMemberOptionsWithUserAvatar,
  getPersonLabel,
} from "../users/helpers";
import { UserAvatar } from "../users/UserAvatar";
import { useOrganisationUserSelectQuery } from "./OrganisationUserSelect.generated";

interface Props {
  userIds: string[];
  onChange: (userIds: string[]) => void;
  inputLabel: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: string;
}

export const OrganisationUsersSelect = ({
  userIds,
  onChange,
  inputLabel,
  placeholder,
  required,
  disabled,
  readOnly,
  error,
}: Props) => {
  const client = useApolloClient();
  const query = useOrganisationUserSelectQuery({
    client,
    fetchPolicy: "network-only",
  });
  const activeMembers = query?.data?.organisationMemberships ?? [];
  const memberOptions = getMemberOptionsWithUserAvatar(activeMembers);
  const selectedMemberOptions = memberOptions.filter(m =>
    userIds.includes(m.value)
  );

  return (
    <AutocompleteMultiple
      inputLabel={inputLabel}
      placeholder={placeholder}
      options={memberOptions}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => (
        <li key={option.key} {...props}>
          <UserAvatar
            size="xs"
            userAvatar={option.userAvatar}
            style={{ marginRight: "8px" }}
          />
          {option.label}
        </li>
      )}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      renderTags={(value, getTagProps) =>
        value.map((value, index) => (
          <Chip
            avatar={<UserAvatar size="xs" userAvatar={value.userAvatar} />}
            variant="outlined"
            size="small"
            label={value.label}
            {...getTagProps({ index })}
          />
        ))
      }
      value={selectedMemberOptions}
      onChange={value => {
        onChange(value.map(v => v.value));
      }}
      error={error}
    />
  );
};

export function FilterOrganisationUsersChips(
  props: Omit<FilterChipGroupProps<string>, "getValueLabel">
) {
  const client = useApolloClient();
  const query = useOrganisationUserSelectQuery({
    client,
    fetchPolicy: "network-only",
  });
  const activeMembers = query?.data?.organisationMemberships ?? [];
  return (
    <FilterChipGroup
      {...props}
      getValueLabel={value => {
        const member = activeMembers.find(u => u.id === value);
        if (member) return getPersonLabel(member, false);
        return "–";
      }}
      getAvatar={value => {
        const member = activeMembers.find(u => u.id === value);
        if (member)
          return (
            <UserAvatar
              size="xs"
              userAvatar={member}
              sx={{ ml: 0.5, mr: -0.5 }}
            />
          );
      }}
    />
  );
}
