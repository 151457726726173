import { CardContainer, StatusLabel } from "@msys/ui";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Page } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageSectionHeader } from "../../commons/layout/PageSectionHeader";

export const ExampleLayoutLg3ColumnsProfile = () => {
  return (
    <>
      <PageContainer>
        <CardContainer title="Background">
          <Box height={400} />
        </CardContainer>
        <Typography variant="h1" style={{ marginTop: 16 }}>
          Name
        </Typography>
      </PageContainer>
      <Page>
        <PageContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CardContainer title="Services">
                <Box height={400} />
              </CardContainer>
              <CardContainer title="Area">
                <Box height={200} />
              </CardContainer>
              <CardContainer title="Certificates">
                <Box height={200} />
              </CardContainer>
            </Grid>
            <Grid item xs={12} lg={8}>
              <CardContainer title="About">
                <Box height={500} />
              </CardContainer>
              <CardContainer title="Contacts">
                <Box height={300} />
              </CardContainer>
              <CardContainer title="Orders">
                <Box height={250} />
              </CardContainer>
            </Grid>
          </Grid>
        </PageContainer>
      </Page>
    </>
  );
};

export const ExampleLayoutLg1Column = () => {
  const theme = useTheme();
  return (
    <Page>
      <PageContainer>
        <PageSectionHeader
          status={
            <StatusLabel color={theme.palette.grey[600]}>Example</StatusLabel>
          }
          title="Page title"
          mb={2}
        />
        <CardContainer title="Table">
          <Box height={400} />
        </CardContainer>
      </PageContainer>
    </Page>
  );
};
