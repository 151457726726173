import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  CardItem,
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  ModalOpenButton,
  Select,
  Tabs,
  useFormatting,
} from "@msys/ui";
import DoneIcon from "@mui/icons-material/Done";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import {
  Button,
  Divider,
  Link as MuiLink,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isEqual } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserData } from "../../auth/useUserData";
import { ButtonCreate } from "../../commons/button/Button";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { FilterChip } from "../../commons/filters/FilterChip";
import { FilterModal } from "../../commons/filters/FilterModal";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import {
  PAGE_LIST_RESULTS_PER_PAGE,
  RESULTS_PER_PAGE_OPTIONS,
} from "../../constants";
import { CrmCompanySelect } from "../../features/crm-companies/CrmCompanySelect";
import { CrmPersonSelect } from "../../features/crm-persons/CrmPersonSelect";
import { CreateTodoItemModal } from "../../features/todos/CreateTodoItemModal";
import { TodoListItem } from "../../features/todos/TodoListItem";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  TodoItemsSortBy,
  TodoItemsSorting,
  namedOperations,
  SortDirection,
} from "../../../clients/graphqlTypes";
import {
  TasksListQueryVariables,
  TodoItemFragment,
  useAssigneeFilterChipsQuery,
  useClientFilterChipsQuery,
  useContactFilterChipsQuery,
  useProjectFilterChipsQuery,
  useTasksListFilterOptionsQuery,
  useTasksListQuery,
  useToggleTodoItemDoneMutation,
} from "./TasksList.generated";
import { CreateTodoItemButton } from "../../features/todos/CreateTodoItemButton";

const DEFAULT_SORTING: TodoItemsSorting[] = [
  {
    column: "time",
    direction: "asc",
  },
];

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];

type QuickFilterValue = "ALL_OPEN" | "MINE" | "OVERDUE" | "COMPLETED";
type Filters = Omit<TasksListQueryVariables, "limit" | "offset" | "sorting">;

export function TasksList({
  submenuItems,
}: {
  submenuItems: PageTopbarItem[];
}) {
  const { t } = useTranslate(["Global", "Tasks"]);
  const navigate = useNavigate();
  const location = useLocation();
  const viewer = useUserData().currentUser!;
  const { getFormattedDateTime } = useFormatting();
  const { enqueueSnackbar } = useSnackbar();

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "todos",
    "table",
    "list"
  );

  const quickFilterSettings: Record<
    QuickFilterValue,
    {
      label: string;
      disabled?: boolean;
      values: Filters;
    }
  > = React.useMemo(
    () => ({
      ALL_OPEN: {
        label: t("All open", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
        },
      },
      MINE: {
        label: t("Mine", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
          filterAssigneeId: viewer.id,
        },
      },
      OVERDUE: {
        label: t("Overdue", {
          ns: "Global",
        }),
        values: {
          filterIsDone: false,
          filterTimeBefore: moment(),
        },
      },
      COMPLETED: {
        label: t("Completed", {
          ns: "Global",
        }),
        values: {
          filterIsDone: true,
        },
      },
    }),
    [t, viewer.id]
  );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<TodoItemsSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "quickfilter",
    "ALL_OPEN",
    toRemoveParams
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const client = useApolloClient();
  const query = useTasksListQuery({
    client,
    variables: {
      ...QUERY_BASE_VARIABLES,
      ...quickFilterSettings[quickFilter].values,
      ...filters,
      sorting,
    },
    // needed to always update the query after every mount and always have the latest data;
    // to mitigate the weird issue with ApolloClient that `nextFetchPolicy` is set to cache-first
    fetchPolicy: "network-only",
  });

  const [toggleTodoDone] = useToggleTodoItemDoneMutation({
    client,
    refetchQueries: [namedOperations.Query.TasksList],
  });

  const tasks =
    (query.data ?? query.previousData)?.todoItems?.edges.map(e => e.node) ?? [];
  const total = (query.data ?? query.previousData)?.todoItems?.totalCount ?? 0;

  const [selectedItems, setSelectedItems] = React.useState<TodoItemFragment[]>(
    []
  );

  const resetSelectedItems = React.useCallback(() => setSelectedItems([]), []);
  React.useEffect(() => {
    resetSelectedItems();
  }, [quickFilter, resetSelectedItems]);
  const { actions } = useBulkActions(selectedItems, resetSelectedItems);

  const handleToggle = async (task: { id: string; isDone: boolean }) => {
    await toggleTodoDone({
      variables: {
        input: { id: task.id, isDone: !task.isDone },
      },
    });
    enqueueSnackbar(
      task.isDone
        ? t("To-do marked as undone", { ns: "Tasks" })
        : t("To-do marked as done", { ns: "Tasks" })
    );
  };

  const stateStore = useDataGridStateStore("TasksList");

  return (
    <Page
      title={t("Tasks", { ns: "Tasks" })}
      action={actions}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          // title={t("Tasks", {
          //   ns: "Tasks",
          // })}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          QuickFilter={
            <Tabs
              condensed
              useSelectOnMobile
              options={Object.entries(quickFilterSettings).map(
                ([key, value]) => {
                  return {
                    value: key as QuickFilterValue,
                    label: value.label,
                    disabled: value.disabled,
                  };
                }
              )}
              value={quickFilter}
              onChange={(newQuickFilter: QuickFilterValue) => {
                setQuickFilter(newQuickFilter);
              }}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={TasksFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.searchTerm}
              onChangeSearchTerm={(newValue: string) => {
                setFilters(filters => ({
                  ...filters,
                  searchTerm: newValue,
                }));
              }}
              debounceMs={1000}
            />
          }
          FilterChips={
            filters.filterAssigneeId ||
            filters.filterLinkedCrmUserId ||
            filters.filterLinkedCrmOrganisationId ||
            filters.filterLinkedProjectId ? (
              <Stack direction={"row"} flexWrap="wrap" spacing={0.5}>
                {filters.filterAssigneeId && (
                  <AssigneeFilterChips
                    filter={filters.filterAssigneeId}
                    setFilters={setFilters}
                  />
                )}
                {filters.filterLinkedCrmUserId && (
                  <ContactFilterChips
                    filter={filters.filterLinkedCrmUserId}
                    setFilters={setFilters}
                  />
                )}
                {filters.filterLinkedCrmOrganisationId && (
                  <ClientFilterChips
                    filter={filters.filterLinkedCrmOrganisationId}
                    setFilters={setFilters}
                  />
                )}
                {filters.filterLinkedProjectId && (
                  <ProjectFilterChips
                    filter={filters.filterLinkedProjectId}
                    setFilters={setFilters}
                  />
                )}
              </Stack>
            ) : undefined
          }
          CreateButton={
            <CreateTodoItemButton
              Button={
                <ButtonCreate
                  title={t("New task", {
                    ns: "Tasks",
                  })}
                />
              }
              refetchQueries={[namedOperations.Query.TasksList]}
            />
          }
          marginBottom={2}
        />

        {(() => {
          switch (activeView) {
            case "table":
              return (
                <DataGrid
                  stateStore={stateStore}
                  loading={query.loading}
                  hideFooter={total === 0}
                  columns={
                    [
                      {
                        field: "title",
                        headerName: t("Title", {
                          ns: "Tasks",
                        }),
                        flex: 3,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: todo }) => todo.title,
                      },
                      {
                        field: "time",
                        headerName: t("Time", {
                          ns: "Tasks",
                        }),
                        flex: 2,
                        minWidth: 100,
                        renderCell: ({ row: todo }) => (
                          <Typography
                            color={
                              moment().isAfter(todo.time) ? "error" : "inherit"
                            }
                          >
                            {todo.time ? getFormattedDateTime(todo.time) : null}
                          </Typography>
                        ),
                      },
                      {
                        field: "assignee",
                        headerName: t("Assignee", {
                          ns: "Tasks",
                        }),
                        flex: 2,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: todo }) => todo.assignee?.fullname,
                      },
                      {
                        field: "related-to",
                        headerName: t("Related to", {
                          ns: "Tasks",
                        }),
                        flex: 2,
                        minWidth: 100,
                        sortable: false,
                        renderCell: ({ row: todo }) => {
                          const links: { [key: string]: React.ReactNode } = {};
                          if (todo.linkedProject) {
                            links.project = (
                              <MuiLink
                                component={Link}
                                to={`/projects/${todo.linkedProject.id}`}
                                onClick={event => event.stopPropagation()}
                              >
                                {todo.linkedProject.title}
                              </MuiLink>
                            );
                          }
                          if (todo.linkedCrmOrganisation) {
                            links.crmOrganisation = (
                              <MuiLink
                                component={Link}
                                to={`/crm/organisations/${todo.linkedCrmOrganisation.id}`}
                                onClick={event => event.stopPropagation()}
                              >
                                {todo.linkedCrmOrganisation.title}
                              </MuiLink>
                            );
                          }
                          if (todo.linkedCrmUser) {
                            links.crmUser = (
                              <MuiLink
                                component={Link}
                                to={`/crm/users/${todo.linkedCrmUser.id}`}
                                onClick={event => event.stopPropagation()}
                              >
                                {todo.linkedCrmUser.fullname}
                              </MuiLink>
                            );
                          }

                          return (
                            <Stack direction="row" spacing={1}>
                              {Object.entries(links).map(
                                ([key, link], index) => (
                                  <React.Fragment key={key}>
                                    {index > 0 && (
                                      <Divider
                                        flexItem
                                        orientation="vertical"
                                      />
                                    )}
                                    {link}
                                  </React.Fragment>
                                )
                              )}
                            </Stack>
                          );
                        },
                      },
                    ] as GridColDef<TodoItemFragment>[]
                  }
                  rows={tasks}
                  sortModel={sorting.map(s => ({
                    field: s.column,
                    sort: s.direction,
                  }))}
                  onSortModelChange={newModel => {
                    setSorting(
                      newModel.map(({ field, sort }) => ({
                        column: field as TodoItemsSortBy,
                        direction: sort ?? "asc",
                      }))
                    );
                  }}
                  onRowClick={item =>
                    navigate({
                      pathname: `/tasks/${item.id}`,
                      search: location.search,
                    })
                  }
                  paginationModel={paginationModel}
                  onPaginationModelChange={newPaginationModel => {
                    setPaginationModel(newPaginationModel);
                  }}
                  disableColumnFilter
                  pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                  rowCount={total}
                  checkboxSelection
                  onRowSelectionModelChange={model => {
                    const toAdd = model
                      .filter(id => !selectedItems.find(item => item.id === id))
                      .map(id => tasks.find(task => task.id === id))
                      .filter(item => item) as TodoItemFragment[]; // FIXME
                    setSelectedItems([
                      ...selectedItems.filter(item => model.includes(item.id)),
                      ...toAdd,
                    ]);
                  }}
                  rowSelectionModel={selectedItems.map(item => item.id)}
                />
              );
            case "list":
              if (query.loading) return <LoadingSpinner />;
              if (total === 0)
                return (
                  <ErrorMessage
                    message={t("There are no items to display", {
                      ns: "Global",
                    })}
                  />
                );
              return (
                <Stack direction="column" spacing={1}>
                  {tasks.map(task => (
                    <CardItem
                      key={task.id}
                      //@ts-ignore
                      component={Link}
                      to={`/tasks/${task.id}${location.search}`}
                    >
                      <TodoListItem task={task} onToggle={handleToggle} />
                    </CardItem>
                  ))}
                  <TablePagination
                    component="div"
                    count={total}
                    page={paginationModel.page}
                    onPageChange={(event, newPage) => {
                      setPaginationModel({
                        pageSize: paginationModel.pageSize,
                        page: newPage,
                      });
                    }}
                    rowsPerPage={paginationModel.pageSize}
                    onRowsPerPageChange={event => {
                      setPaginationModel({
                        pageSize: parseInt(event.target.value, 10),
                        page: 0,
                      });
                    }}
                    labelRowsPerPage={t("Per page:", { ns: "Global" })}
                  />
                </Stack>
              );
          }
        })()}

        <Outlet />
      </PageContainer>
    </Page>
  );
}

/////// FILTER CHIPS ///////

function AssigneeFilterChips({
  filter,
  setFilters,
}: {
  filter: string;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}) {
  const { t } = useTranslate(["Global", "Tasks"]);

  const client = useApolloClient();
  const query = useAssigneeFilterChipsQuery({
    client,
    variables: {
      filterAssigneeIds: [filter],
    },
  });
  const filterAssignees = query.data?.organisationMemberships ?? [];

  return (
    <>
      {filterAssignees.map(assignee => (
        <FilterChip
          key={assignee.id}
          label={t("Assignee", {
            ns: "Tasks",
          })}
          resetValue={undefined}
          setValue={newValue =>
            setFilters(filters => ({
              ...filters,
              filterAssigneeId: newValue,
            }))
          }
          value={assignee?.fullname}
        />
      ))}
    </>
  );
}

function ContactFilterChips({
  filter,
  setFilters,
}: {
  filter: string;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}) {
  const { t } = useTranslate(["Global", "Tasks"]);

  const client = useApolloClient();
  const query = useContactFilterChipsQuery({
    client,
    variables: {
      filterLinkedCrmUserIds: [filter],
    },
  });
  const filterCrmPersons =
    getDataOrNull(query.data?.crmPersons)?.edges.map(edge => edge.node) ?? [];

  return (
    <>
      {filterCrmPersons.map(person => (
        <FilterChip
          key={person.id}
          label={t("Contact", {
            ns: "Tasks",
          })}
          resetValue={undefined}
          setValue={newValue =>
            setFilters(filters => ({
              ...filters,
              filterLinkedCrmUserId: newValue,
            }))
          }
          value={person?.fullname}
        />
      ))}
    </>
  );
}

function ClientFilterChips({
  filter,
  setFilters,
}: {
  filter: string;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}) {
  const { t } = useTranslate(["Global", "Tasks"]);

  const client = useApolloClient();
  const query = useClientFilterChipsQuery({
    client,
    variables: {
      filterLinkedCrmOrganisationIds: [filter],
    },
  });
  const filterCrmCompanies =
    getDataOrNull(query.data?.crmCompanies)?.edges.map(edge => edge.node) ?? [];

  return (
    <>
      {filterCrmCompanies.map(company => (
        <FilterChip
          key={company.id}
          label={t("Client", {
            ns: "Tasks",
          })}
          resetValue={undefined}
          setValue={newValue =>
            setFilters(filters => ({
              ...filters,
              filterLinkedCrmOrganisationId: newValue,
            }))
          }
          value={company?.title}
        />
      ))}
    </>
  );
}

function ProjectFilterChips({
  filter,
  setFilters,
}: {
  filter: string;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}) {
  const { t } = useTranslate(["Global", "Tasks"]);

  const client = useApolloClient();
  const query = useProjectFilterChipsQuery({
    client,
    variables: {
      filterLinkedProjectIds: [filter],
    },
  });
  const filterProjects =
    getDataOrNull(query.data?.projects)?.edges.map(edge => edge.node) ?? [];

  return (
    <>
      {filterProjects.map(project => (
        <FilterChip
          key={project.id}
          label={t("Project", {
            ns: "Tasks",
          })}
          resetValue={undefined}
          setValue={newValue =>
            setFilters(filters => ({
              ...filters,
              filterLinkedProjectId: newValue,
            }))
          }
          value={project?.title}
        />
      ))}
    </>
  );
}

/////// FILTER MODAL ///////

function TasksFilterModal({
  sorting,
  setSorting,
  filters,
  setFilters,
  resetFilters,
  handleClose,
}: {
  sorting: TodoItemsSorting[];
  setSorting: (value: TodoItemsSorting[]) => void;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  resetFilters: () => void;
  handleClose: () => void;
}) {
  const { t } = useTranslate(["Global", "Tasks"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  const sortByOptions = React.useMemo(() => {
    const tasksListSortingLabels: Record<
      TodoItemsSortBy,
      Record<SortDirection, string>
    > = {
      time: {
        asc: t("Time (earliest first)", {
          ns: "Tasks",
        }),
        desc: t("Time (latest first)", {
          ns: "Tasks",
        }),
      },
      createdAt: {
        asc: t("Created at (earliest first)", {
          ns: "Tasks",
        }),
        desc: t("Created at (latest first)", {
          ns: "Tasks",
        }),
      },
      completedAt: {
        asc: t("Completed at (earliest first)", {
          ns: "Tasks",
        }),
        desc: t("Completed at (latest first)", {
          ns: "Tasks",
        }),
      },
    };

    const ALL_SORTING_FIELDS: TodoItemsSortBy[] = [
      "createdAt",
      "time",
      "completedAt",
    ];

    return ALL_SORTING_FIELDS.flatMap(value => [
      {
        label: tasksListSortingLabels[value]["asc"],
        value: { column: value, direction: "asc" as SortDirection },
      },
      {
        label: tasksListSortingLabels[value]["desc"],
        value: { column: value, direction: "desc" as SortDirection },
      },
    ]);
  }, [t]);

  const client = useApolloClient();
  const filterOptionsQuery = useTasksListFilterOptionsQuery({
    client,
  });
  const filterOptions = filterOptionsQuery.data;
  const filterAssigneeOptions = filterOptions?.organisationMemberships;
  const filterCrmUserUptions = getDataOrNull(
    filterOptions?.crmPersons
  )?.edges.map(edge => edge.node);
  const filterProjectOptions = getDataOrNull(
    filterOptions?.projects
  )?.edges.map(edge => edge.node);

  return (
    <FilterModal
      title={t("Tasks sorting and filters", {
        ns: "Tasks",
      })}
      handleApply={() => {
        setFilters(filtersState);
        setSorting(sortingState);
      }}
      handleReset={resetFilters}
      handleClose={handleClose}
    >
      <Typography variant="h3">
        {t("Sort by", {
          ns: "Global",
        })}
      </Typography>
      <Select
        label={t("Sort by", {
          ns: "Global",
        })}
        options={sortByOptions}
        value={
          sortByOptions.find(option => isEqual(option.value, sortingState[0]))
            ?.value
        }
        onChange={value => {
          setSortingState(value ? [value] : DEFAULT_SORTING);
        }}
      />
      <Typography variant="h3">
        {t("Filter by", {
          ns: "Global",
        })}
      </Typography>
      <Select
        label={t("Assignee", {
          ns: "Tasks",
        })}
        options={
          filterAssigneeOptions?.map(user => ({
            value: user.id,
            label: user.fullname,
          })) ?? []
        }
        value={filtersState.filterAssigneeId ?? ""}
        onChange={value => {
          setFiltersState(state => ({ ...state, filterAssigneeId: value }));
        }}
      />
      <CrmPersonSelect
        inputLabel={t("Contact", {
          ns: "Tasks",
        })}
        crmPersonId={filtersState.filterLinkedCrmUserId}
        onChange={value => {
          setFiltersState(state => ({
            ...state,
            filterLinkedCrmUserId: value,
          }));
        }}
      />
      <CrmCompanySelect
        inputLabel={t("Client", {
          ns: "Tasks",
        })}
        crmCompanyId={filtersState.filterLinkedCrmOrganisationId}
        onChange={crmCompany => {
          setFiltersState(state => ({
            ...state,
            filterLinkedCrmOrganisationId: crmCompany?.id,
          }));
        }}
        disableBlockedBusinessPartners={false}
      />
      <Select
        label={t("Project", {
          ns: "Tasks",
        })}
        options={
          filterProjectOptions?.map(project => ({
            value: project.id,
            label: project.title,
          })) ?? []
        }
        value={filtersState.filterLinkedProjectId ?? ""}
        onChange={value => {
          setFiltersState(state => ({
            ...state,
            filterLinkedProjectId: value,
          }));
        }}
      />
    </FilterModal>
  );
}

function useBulkActions(
  selectedItems: TodoItemFragment[],
  resetSelectedItems: () => void
) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Global", "Tasks"]);

  const client = useApolloClient();
  const [toggleTodoDone] = useToggleTodoItemDoneMutation({
    client,
    refetchQueries: [namedOperations.Query.TasksList],
    awaitRefetchQueries: true,
  });

  const canMarkAsDone = selectedItems.some(task => !task.isDone);
  const canMarkAsUndone = selectedItems.some(task => task.isDone);

  const buttons = [];

  if (selectedItems.length > 0) {
    if (canMarkAsDone) {
      buttons.push(
        <Button
          key="mark-as-done"
          variant={"contained"}
          color={"secondary"}
          startIcon={<DoneIcon />}
          onClick={async () => {
            const validTasks = selectedItems.filter(task => !task.isDone);
            await Promise.all(
              validTasks.map(async task => {
                await toggleTodoDone({
                  variables: {
                    input: { id: task.id, isDone: true },
                  },
                });
              })
            );
            resetSelectedItems();
            enqueueSnackbar(
              t(
                "{count, plural, =0 {No to-dos} one {One to-do} other {# to-dos}} marked as done",
                {
                  ns: "Tasks",
                  count: validTasks.length,
                }
              )
            );
          }}
        >
          {t("Mark as done", {
            ns: "Tasks",
          })}
        </Button>
      );
    }

    if (canMarkAsUndone) {
      buttons.push(
        <Button
          key="mark-as-undone"
          variant={"contained"}
          color={"warning"}
          startIcon={<RemoveDoneIcon />}
          onClick={async () => {
            const validTasks = selectedItems.filter(task => task.isDone);
            await Promise.all(
              validTasks.map(async task => {
                await toggleTodoDone({
                  variables: {
                    input: { id: task.id, isDone: false },
                  },
                });
              })
            );
            resetSelectedItems();
            enqueueSnackbar(
              t(
                "{count, plural, =0 {No to-dos} one {One to-do} other {# to-dos}} reopened",
                {
                  ns: "Tasks",
                  count: validTasks.length,
                }
              )
            );
          }}
        >
          {t("Reopen task", {
            ns: "Tasks",
          })}
        </Button>
      );
    }
  }

  return { actions: buttons };
}
