import { TypographyProps } from "@mui/material";
import React from "react";
import { useFormatting } from "../formatting/useFormatting";
import { LabeledValue } from "./LabeledValue";

interface Props extends TypographyProps<"div"> {
  label: string;
  value: number;
  variant?: React.ComponentProps<typeof LabeledValue>["variant"];
}

export const LabeledDurationValue = ({
  label,
  value = 0,
  ...otherProps
}: Props) => {
  const { getFormattedDuration } = useFormatting();

  return (
    <LabeledValue label={label} color="primary" {...otherProps}>
      {getFormattedDuration(value)} h
    </LabeledValue>
  );
};
