import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Modal } from "@msys/ui";
import { Alert, AlertTitle, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { SelectField } from "../../commons/form-fields/SelectField";
import { InvoiceType } from "../../../clients/graphqlTypes";
import {
  useCreateInvoiceModalQuery,
  useInvoiceDetailsCreateInvoiceMutation,
} from "./CreateInvoiceModal.generated";
import { useTranslate } from "@tolgee/react";
import { useInvoiceTypes } from "./hooks/useInvoiceTypes";

interface FormValues {
  docId: string;
  invoiceType: InvoiceType | null;
}

interface Props {
  id?: string;
  projectId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (invoiceId: string) => Promise<void>;
  refetchQueries?: string[];
}

export const CreateInvoiceModal = ({
  id,
  projectId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate(["InvoiceDetailsForm"]);

  const client = useApolloClient();
  const query = useCreateInvoiceModalQuery({
    client,
    variables: { projectId },
  });

  const [createInvoice] = useInvoiceDetailsCreateInvoiceMutation({
    client,
  });

  const { invoiceTypeOptions } = useInvoiceTypes();

  const outgoingQuotes =
    getDataOrNull(query.data?.projectOutgoingQuotes)?.edges?.map(e => e.node) ??
    [];
  const quotesAllowedInvoiceTypes =
    getDataOrNull(query.data?.projectOutgoingQuotesAllowedInvoiceTypes)
      ?.types ?? [];

  const outgoingQuoteOptions = outgoingQuotes.map(q => ({
    label: q.title,
    value: q.id,
  }));

  const onSubmit = async ({ docId, invoiceType }: FormValues) => {
    if (!docId || !invoiceType) return;

    const res = await createInvoice({
      variables: {
        input: {
          projectId,
          docId,
          invoiceType,
        },
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });

    if (!res) return;

    const createdInvoice = res.data!.createInvoice.invoice;

    await handleComplete?.(createdInvoice.id);
    handleClose();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = Yup.object().shape({
    docId: Yup.string()
      .label(
        t("Quote", {
          ns: "InvoiceDetailsForm",
        })
      )
      .required(),
    invoiceType: Yup.string()
      .label(
        t("Invoice type", {
          ns: "InvoiceDetailsForm",
        })
      )
      .nullable()
      .required(),
  });

  const initialValues = {
    docId:
      outgoingQuoteOptions.length === 1 ? outgoingQuoteOptions[0].value : "",
    invoiceType: null,
  };

  return (
    <Formik<FormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, isSubmitting, values, setValues }) => {
        const selectedQuote = values.docId
          ? outgoingQuotes.find(quote => quote.id === values.docId)
          : null;
        const allowedInvoiceTypes: InvoiceType[] = values.docId
          ? quotesAllowedInvoiceTypes.find(a => a.quoteId === values.docId)
              ?.invoiceAllowedTypes ?? []
          : [];
        const availableInvoiceTypeOptions = selectedQuote
          ? invoiceTypeOptions.filter(o =>
              allowedInvoiceTypes.includes(o.value)
            )
          : [];
        return (
          <Modal
            id={id}
            title={
              title ??
              t("Create Invoice", {
                ns: "InvoiceDetailsForm",
              })
            }
            handleClose={handleClose}
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "InvoiceDetailsForm",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text", disabled: isSubmitting },
              },
              {
                label: t("Create Invoice", {
                  ns: "InvoiceDetailsForm",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled: query.loading || !isValid,
                  loading: isSubmitting,
                },
              },
            ]}
            isLoading={query.loading}
          >
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                <SelectField
                  label={t("Quote", {
                    ns: "InvoiceDetailsForm",
                  })}
                  name="docId"
                  options={outgoingQuoteOptions}
                  onChange={e => {
                    const newDocId = e.target.value;
                    const newAllowedInvoiceTypes: InvoiceType[] = newDocId
                      ? quotesAllowedInvoiceTypes.find(
                          a => a.quoteId === newDocId
                        )?.invoiceAllowedTypes ?? []
                      : [];
                    setValues({
                      ...values,
                      docId: newDocId,
                      invoiceType:
                        newAllowedInvoiceTypes.length === 1
                          ? newAllowedInvoiceTypes[0]!
                          : null,
                    });
                  }}
                  required
                />
                {values.docId &&
                  (!availableInvoiceTypeOptions.length ? (
                    <Alert severity="warning" variant="outlined">
                      <AlertTitle>
                        {t("No invoice could be created for selected quote", {
                          ns: "InvoiceDetailsForm",
                        })}
                      </AlertTitle>
                      {t(
                        "Either a final invoice already created or one of invoice is still in draft status",
                        {
                          ns: "InvoiceDetailsForm",
                        }
                      )}
                    </Alert>
                  ) : (
                    <SelectField
                      label={t("Invoice type", {
                        ns: "InvoiceDetailsForm",
                      })}
                      name="invoiceType"
                      options={availableInvoiceTypeOptions}
                      required
                    />
                  ))}
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
