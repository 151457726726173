/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ToggleTemplatesQuoteBindingButton_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, isBinding: boolean };

export const ToggleTemplatesQuoteBindingButton_QuoteTemplateFragmentDoc = gql`
    fragment ToggleTemplatesQuoteBindingButton_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  isBinding
}
    `;