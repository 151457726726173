import { Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  OrganisationProjectsSorting,
  ProjectFilterKind,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import {
  FilterByOrganisationUsers,
  FilterCheckbox,
} from "../../commons/filters";
import { FilterModal } from "../../commons/filters/FilterModal";
import { CrmCompaniesOrPersonsSelect } from "../crm-companies/CrmCompaniesOrPersonsSelect";
import { CrmCompanySelect } from "../crm-companies/CrmCompanySelect";
import { CustomFieldConfigFragment } from "../custom-fields/customFieldConfigs.generated";
import { FilterCustomFields } from "../custom-fields/filters";
import { DEFAULT_SORTING, Filters } from "./ProjectsList";
import {
  FilterBuildingZip,
  FilterMinBudget,
  FilterPlanned,
  FilterProjectCategory,
  FilterProjectState,
  FilterProjectType,
  FilterWithinRadiusKm,
  ProjectsSorting,
} from "./filters";
import { FilterProjectPhases } from "./filters/ProjectPhases";

export function ProjectsFilterModal<F extends Filters>({
  handleClose,
  sorting,
  setSorting,
  filters,
  setFilters,
  resetFilters,
  customFieldConfigs,
  allowedStates,
  onKindChange,
  onPlanningChange,
  onStateChange,
  showPhasesFilter = true,
}: {
  handleClose: () => void;
  sorting: OrganisationProjectsSorting[];
  setSorting: (sorting: OrganisationProjectsSorting[]) => void;
  filters: F;
  setFilters: React.Dispatch<React.SetStateAction<F>>;
  resetFilters: () => void;
  customFieldConfigs: CustomFieldConfigFragment[];
  allowedStates: ProjectStateMachineStatus[];
  onKindChange?: (value: ProjectFilterKind | null | undefined) => Partial<F>;
  onPlanningChange?: (value: boolean | null | undefined) => Partial<F>;
  onStateChange?: (
    value: ProjectStateMachineStatus[] | null | undefined
  ) => Partial<F>;
  showPhasesFilter?: boolean;
}) {
  const { t } = useTranslate(["Projects", "Tickets", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  return (
    <FilterModal
      title={t("Project Filters", {
        ns: "Projects",
      })}
      handleApply={() => {
        setSorting(sortingState);
        setFilters(filtersState);
      }}
      handleClose={handleClose}
      handleReset={() => resetFilters()}
    >
      <Typography variant="h3">
        {t("Sort by", {
          ns: "Global",
        })}
      </Typography>
      <ProjectsSorting
        sorting={sortingState[0]}
        setSorting={setSortingState}
        defaultSorting={DEFAULT_SORTING}
      />
      <Typography variant="h3">
        {t("Filter by", {
          ns: "Global",
        })}
      </Typography>
      <FilterCheckbox
        label={t("Urgent", { ns: "Tickets" })}
        value={filtersState.filterUrgent ?? undefined}
        setValue={newValue =>
          setFiltersState(state => ({
            ...state,
            filterUrgent: newValue,
          }))
        }
      />
      <FilterCheckbox
        label={t("Overdue", { ns: "Tickets" })}
        value={filtersState.filterOverdue ?? undefined}
        setValue={newValue =>
          setFiltersState(state => ({
            ...state,
            filterOverdue: newValue,
          }))
        }
      />
      <FilterProjectType
        value={filtersState.projectType ?? []}
        setValue={newValue =>
          setFiltersState(filters => ({
            ...filters,
            projectType: newValue,
          }))
        }
      />
      <FilterProjectCategory
        value={filtersState.projectCategories ?? []}
        setValue={newValue =>
          setFiltersState(state => ({
            ...state,
            projectCategories: newValue,
          }))
        }
      />
      <FilterBuildingZip
        value={filtersState.buildingZip ?? ""}
        setValue={newValue =>
          setFiltersState(state => ({
            ...state,
            buildingZip: newValue,
          }))
        }
      />
      <FilterWithinRadiusKm
        value={filtersState.withinRadiusKm ?? 0}
        setValue={newValue =>
          setFiltersState(state => ({
            ...state,
            withinRadiusKm: newValue,
          }))
        }
      />
      <FilterMinBudget
        value={filtersState.minBudget ?? 0}
        setValue={newValue =>
          setFiltersState(state => ({
            ...state,
            minBudget: newValue,
          }))
        }
      />
      <FilterProjectState
        allowedStates={allowedStates}
        value={filtersState.filterIncludeState ?? []}
        setValue={newValue =>
          setFiltersState(filters => ({
            ...filters,
            ...(onStateChange?.(newValue) ?? { filterIncludeState: newValue }),
          }))
        }
      />
      {showPhasesFilter && (
        <FilterProjectPhases
          allowedStates={allowedStates}
          value={filtersState.filterPhaseIds ?? []}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              filterPhaseIds: newValue,
            }))
          }
        />
      )}
      <RestrictedByCapabilityWithDebug capability="QUOTING">
        <CrmCompanySelect
          crmCompanyId={filtersState.filterByCrmOrganisationId ?? undefined}
          onChange={crmCompany =>
            setFiltersState(state => ({
              ...state,
              filterByCrmOrganisationId: crmCompany?.id,
            }))
          }
          inputLabel={t("Client", { ns: "Projects" })}
          required={false}
          canCreateNew={false}
          disableBlockedBusinessPartners={false}
        />
      </RestrictedByCapabilityWithDebug>
      <CrmCompaniesOrPersonsSelect
        crmCompaniesOrPersonsIds={filtersState.filterByCrmLinkIds ?? []}
        onChange={crmCompaniesOrPersonsIds =>
          setFiltersState(state => ({
            ...state,
            filterByCrmLinkIds: crmCompaniesOrPersonsIds,
          }))
        }
        inputLabel={t("Other contacts", { ns: "Projects" })}
        required={false}
      />
      <FilterByOrganisationUsers
        label={t("Created by", { ns: "Projects" })}
        placeholder=""
        value={filtersState.filterBySourceUserIds ?? []}
        setValue={newValue =>
          setFiltersState(filters => ({
            ...filters,
            filterBySourceUserIds: newValue,
          }))
        }
      />
      <RestrictedByCapabilityWithDebug capability="PLANNING">
        <FilterPlanned
          value={filtersState.filterAssigned ?? undefined}
          setValue={newValue =>
            setFiltersState(state => ({
              ...state,
              ...(onPlanningChange?.(newValue) ?? { filterAssigned: newValue }),
            }))
          }
        />
      </RestrictedByCapabilityWithDebug>
      {customFieldConfigs.length > 0 && (
        <FilterCustomFields
          fieldConfigs={customFieldConfigs}
          values={filtersState.filterByCustomFields ?? []}
          setValues={newValues =>
            setFiltersState(state => ({
              ...state,
              filterByCustomFields: newValues,
            }))
          }
        />
      )}
    </FilterModal>
  );
}
