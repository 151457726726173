import { Modal, RichTextEditor } from "@msys/ui";
import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ContentState, RawDraftContentState, convertToRaw } from "draft-js";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isEqual, uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";
import { EditOrganisationProfileAboutModal_OrganisationProfileFragment } from "./EditOrganisationProfileAboutModal.generated";

interface FormValues {
  about: RawDraftContentState;
  yearFounded: number | undefined;
  employeesNumber: number | undefined;
  isMeister: "yes" | "no";
}

interface Props {
  organisationProfile: EditOrganisationProfileAboutModal_OrganisationProfileFragment;
  title?: string;
  handleClose: () => void;
  handleComplete: (values: FormValues) => Promise<void> | void;
}

export const EditOrganisationProfileAboutModal = ({
  organisationProfile,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["OrganisationPublicProfile", "Global"]);

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues: FormValues = {
    about:
      organisationProfile.about ??
      convertToRaw(ContentState.createFromText("")),
    yearFounded: organisationProfile.yearFounded ?? undefined,
    employeesNumber: organisationProfile.employeesNumber ?? undefined,
    isMeister: organisationProfile.isMeister ? "yes" : "no",
  };

  const handleSubmit = async (values: FormValues) => {
    await handleComplete(values);
    handleClose();
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        about: Yup.object().label(
          t("About", { ns: "OrganisationPublicProfile" })
        ),
        yearFounded: Yup.number()
          .label(
            t("Year founded", {
              ns: "OrganisationPublicProfile",
            })
          )
          .max(new Date().getFullYear()),
        employeesNumber: Yup.number()
          .label(
            t("Number of employees", {
              ns: "OrganisationPublicProfile",
            })
          )
          .min(0),
        isMeister: Yup.string(),
      }),
    [t]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, values, setFieldValue, errors }) => (
        <Modal
          title={
            title ??
            t("Edit information", {
              ns: "OrganisationPublicProfile",
            })
          }
          dialogProps={{ maxWidth: "sm" }}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: isSubmitting,
              },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                loading: isSubmitting,
                disabled: !isValid,
              },
            },
          ]}
          handleClose={handleClose}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1}>
                <Field
                  component={TextField}
                  name="yearFounded"
                  label={t("Year founded", {
                    ns: "OrganisationPublicProfile",
                  })}
                  disabled={false}
                  type="number"
                  min={1000}
                  max={new Date().getFullYear()}
                />
                <Field
                  component={TextField}
                  name="employeesNumber"
                  label={t("Number of employees", {
                    ns: "OrganisationPublicProfile",
                  })}
                  disabled={false}
                  type="number"
                  min={0}
                  max={9999999}
                />
              </Stack>
              <RichTextEditor
                label={t("About", {
                  ns: "OrganisationPublicProfile",
                })}
                content={values.about}
                canEdit={true}
                onChange={(content: RawDraftContentState) => {
                  if (!isEqual(values.about, content))
                    setFieldValue("about", content);
                }}
                saveButtonLabel={t("Save", { ns: "Global" })}
                cancelButtonLabel={t("Cancel", { ns: "Global" })}
              />
              <RadioGroupField
                label={t("Does company have Meister qualification?", {
                  ns: "OrganisationPublicProfile",
                })}
                name="isMeister"
                inline
                options={[
                  {
                    value: "yes",
                    label: t("Yes", { ns: "Global" }),
                  },
                  {
                    value: "no",
                    label: t("No", { ns: "Global" }),
                  },
                ]}
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
