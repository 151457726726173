import BallotIcon from "@mui/icons-material/Ballot";
import { Button, ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";

interface Props extends ButtonProps {
  start: () => unknown;
  label?: string;
}

export const DecisionWizardButton = ({ start, label, ...props }: Props) => {
  const { t } = useTranslate(["Quote"]);

  const [isLoading, setIsLoading] = React.useState(false);
  const handleClick = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await start();
    } finally {
      setIsLoading(false);
    }
  }, [start]);

  return (
    <Button
      startIcon={<BallotIcon />}
      variant={"contained"}
      color={"secondary"}
      disabled={isLoading}
      onClick={handleClick}
      {...props}
    >
      {label ?? t("Open wizard", { ns: "Quote" })}
    </Button>
  );
};
