import { useApolloClient } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import CancelIcon from "@mui/icons-material/Cancel";
// import DeleteIcon from "@mui/icons-material/Delete";
import { MenuButton, MenuItemWithIcon } from "@msys/ui";
import { Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import { PermissionName, namedOperations } from "../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { ConfirmModal } from "../../commons/modals/ConfirmModal";
import { RequestRequestorMenuFragment } from "./RequestRequestorMenu.generated";
import {
  useRejectAllQuotesInRequestMutation,
  useWithdrawRequestMutation,
} from "./Requests.generated";

export const RequestRequestorMenu = ({
  request,
  project,
}: {
  request: RequestRequestorMenuFragment;
  project: { id: string; viewerPermissions: PermissionName[] };
}) => {
  const { t } = useTranslate(["Requests", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  // const [deleteRequest] = useDeleteRequestMutation({ client });
  // const handleDeleteRequest = async () => {
  //   try {
  //     await deleteRequest({
  //       variables: {
  //         input: { requestId: request.id, projectId: project.id },
  //       },
  //       refetchQueries: [namedOperations.Query.ProjectRequest],
  //       awaitRefetchQueries: true,
  //     });
  //     enqueueSnackbar(t("Requests::Request closed"));
  //   } catch (e) {
  //     if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
  //   }
  // };

  const [rejectAllQuotes] = useRejectAllQuotesInRequestMutation({ client });
  const handleRejectAllQuotes = async () => {
    try {
      await rejectAllQuotes({
        variables: {
          input: { requestId: request.id, projectId: project.id },
        },
        refetchQueries: [namedOperations.Query.ProjectRequest],
        awaitRefetchQueries: true,
      });
      enqueueSnackbar(
        t("Quotes rejected", {
          ns: "Requests",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const [withdrawRequest] = useWithdrawRequestMutation({ client });
  const handleWithdrawRequest = async () => {
    try {
      await withdrawRequest({
        variables: {
          input: { requestId: request.id, projectId: project.id },
        },
        refetchQueries: [namedOperations.Query.ProjectRequest],
        awaitRefetchQueries: true,
      });
      enqueueSnackbar(
        t("Request withdrawn", {
          ns: "Requests",
        })
      );
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  // TODO: implement delete on backend
  // if (request.status === RequestStatus.Open)
  //   return (
  //     <ShowAction action={{ name: "delete request", project }}>
  //       <MenuButton>
  //         <ModalOpenButton
  //           Modal={ConfirmModal}
  //           modalProps={{
  //             title: t("Requests::Confirm request closure"),
  //             confirmButtonLabel: t("Requests::Yes, delete"),
  //             Text: (
  //               <Typography variant="body1">
  //                 {t("Requests::Are you sure you want to delete the request?")}
  //               </Typography>
  //             ),
  //             handleConfirm: handleDeleteRequest,
  //           }}
  //         >
  //           <MenuItemWithIcon icon={<DeleteIcon />} color="primary">
  //             {t("Requests::Delete request")}
  //           </MenuItemWithIcon>
  //         </ModalOpenButton>
  //       </MenuButton>
  //     </ShowAction>
  //   );

  if (request.status === "PUBLISHED")
    return (
      <RestrictedByProjectPermissionWithDebug
        permission="SHARE_REQUIREMENTS"
        project={project}
      >
        <MenuButton>
          <ModalOpenButton
            Modal={ConfirmModal}
            modalProps={{
              title: t("Confirm cancellation", {
                ns: "Requests",
              }),
              text: t(
                "Any received quotes will be declined. You will not be able to re-publish this request. Are you sure you want to cancel this request?",
                {
                  ns: "Requests",
                }
              ),
              confirmButtonLabel: t("Yes, cancel", {
                ns: "Requests",
              }),
              handleConfirm: handleWithdrawRequest,
              cancelButtonLabel: t("No", {
                ns: "Global",
              }),
            }}
          >
            <MenuItemWithIcon icon={<CancelIcon />} color="primary">
              {t("Cancel request", {
                ns: "Requests",
              })}
            </MenuItemWithIcon>
          </ModalOpenButton>
        </MenuButton>
      </RestrictedByProjectPermissionWithDebug>
    );

  if (request.status === "CLOSED" && request.viewerNumActiveQuotes > 0)
    return (
      <RestrictedByProjectPermissionWithDebug
        permission="SHARE_REQUIREMENTS"
        project={project}
      >
        <MenuButton>
          <ModalOpenButton
            Modal={ConfirmModal}
            modalProps={{
              title: t("Reject all quotes", {
                ns: "Requests",
              }),
              confirmButtonLabel: t("Yes, reject all", {
                ns: "Requests",
              }),
              content: () => (
                <Typography variant="body1">
                  {t(
                    "Are you sure you want to reject all received quotes? This action is not reversible.",
                    {
                      ns: "Requests",
                    }
                  )}
                </Typography>
              ),
              handleConfirm: handleRejectAllQuotes,
            }}
          >
            <MenuItemWithIcon icon={<CancelIcon />} color="primary">
              {t("Reject all quotes", {
                ns: "Requests",
              })}
            </MenuItemWithIcon>
          </ModalOpenButton>
        </MenuButton>
      </RestrictedByProjectPermissionWithDebug>
    );

  return null;
};
