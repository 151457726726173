import { gql, useApolloClient } from "@apollo/client";
import {
  CardContainer,
  getFormattedPercentage,
  getFormattedPrice,
  LabeledValue,
} from "@msys/ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Divider, Grid, Tooltip, Typography } from "@mui/material";
import { useTolgee } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField";
import { Stack } from "../../../commons/layout/Stack";
import { px } from "../../../../common/MuiThemeProvider";
import {
  MaterialCalculationActualRow_ItemFragment,
  MaterialCalculationBox_ItemFragment,
  MaterialCalculationBox_ProjectFragment,
  MaterialCalculationEstimatedRow_ItemFragment,
  useQuoteModifyItem_MaterialCalculationBoxMutation,
} from "./MaterialCalculationBox.generated";
import { useTranslate } from "@tolgee/react";
import { LabeledQuantityValue } from "../LabeledQuantityValue";
import { useQuantityUnits } from "../useQuantityUnits";

interface FormValues {
  actualQuantity: number;
}

interface Props {
  project: MaterialCalculationBox_ProjectFragment;
  docId: string;
  item: MaterialCalculationBox_ItemFragment;
  refetchQueriesOnAction?: string[];
  isInitiallyClosed?: boolean;
}

export const MaterialCalculationBox = ({
  project,
  docId,
  item,
  isInitiallyClosed,
  refetchQueriesOnAction,
}: Props) => {
  const { t } = useTranslate("QuoteItem");

  return (
    <CardContainer
      isExpandable={true}
      title={t("Material Calculation")}
      isInitiallyClosed={isInitiallyClosed}
    >
      <Stack p={1} flexDirection="column">
        {item.type === "paid" && (
          <>
            <MaterialCalculationEstimatedRow item={item} />

            <Divider />

            <MaterialCalculationActualRow
              projectId={project.id}
              docId={docId}
              item={item}
              refetchQueriesOnAction={refetchQueriesOnAction}
              canEdit={item.canBeWorkedOn}
            />
          </>
        )}
      </Stack>
    </CardContainer>
  );
};

const MaterialCalculationEstimatedRow = ({
  item,
}: {
  item: MaterialCalculationEstimatedRow_ItemFragment;
}) => {
  const { t } = useTranslate("QuoteItem");
  const language = useTolgee(["language"]).getLanguage()!;

  const calculation =
    item?.invoiceAgreedOrProposed === "agreed"
      ? item?.agreedCalculation
      : item?.proposedCalculation;

  const materialPricePerUnit = calculation?.materialPricePerUnit ?? 0;
  const materialBuyingPricePerUnit =
    calculation?.materialBuyingPricePerUnit ?? 0;
  const materialMargin = calculation?.materialMargin ?? 0;
  const materialPriceSubTotal = calculation?.materialPriceSubTotal ?? 0;

  return (
    <Stack flexDirection="column" spacing={1 / 2}>
      <Typography color="primary" variant="h3">
        {t("Estimated")}
      </Typography>
      <Grid container direction="row">
        <Grid
          item
          xs={4}
          style={{
            flexBasis: `calc(33.333333% - ${px.l})`,
            maxWidth: `calc(33.333333% - ${px.l})`,
          }}
        >
          <LabeledQuantityValue
            value={item.estimatedQuantity}
            unit={item.quantityUnit}
            label={t("Quantity")}
            color="primary"
          />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          style={{ width: px.l }}
        >
          x
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          xs={4}
          style={{
            flexBasis: `calc(33.333333% - ${px.l})`,
            maxWidth: `calc(33.333333% - ${px.l})`,
          }}
        >
          <Stack alignItems="center">
            <LabeledValue label={t("Price / Unit")}>
              {getFormattedPrice(materialPricePerUnit, language)}
            </LabeledValue>
            {materialMargin > 0 && (
              <Tooltip
                title={`${t("Net price")}: ${getFormattedPrice(
                  materialBuyingPricePerUnit,
                  language
                )} + ${getFormattedPercentage(materialMargin, language)}`}
              >
                <InfoOutlinedIcon fontSize="small" color="secondary" />
              </Tooltip>
            )}
          </Stack>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          style={{ width: px.l }}
        >
          =
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          xs={4}
        >
          <LabeledValue label={t("Total price")}>
            {getFormattedPrice(materialPriceSubTotal, language)}
          </LabeledValue>
        </Grid>
      </Grid>
    </Stack>
  );
};

const MaterialCalculationActualRow = ({
  docId,
  projectId,
  item,
  refetchQueriesOnAction,
  canEdit,
}: {
  projectId: string;
  docId: string;
  item: MaterialCalculationActualRow_ItemFragment;
  refetchQueriesOnAction?: string[];
  canEdit: boolean;
}) => {
  const { t } = useTranslate("QuoteItem");
  const language = useTolgee(["language"]).getLanguage()!;

  const client = useApolloClient();
  const [modifyItemActualCalculation] =
    useQuoteModifyItem_MaterialCalculationBoxMutation({
      client,
    });

  const { quantityUnitLabels } = useQuantityUnits();

  const initialValues: FormValues = {
    actualQuantity: item.actualQuantity || item.estimatedQuantity,
  };

  const onSubmit = async (values: FormValues) => {
    await modifyItemActualCalculation({
      variables: {
        input: {
          projectId,
          docId,
          itemId: item.id,
          values: {
            actualQuantity: values.actualQuantity,
          },
        },
      },
      refetchQueries: refetchQueriesOnAction,
    });
  };

  return (
    <Stack flexDirection="column" spacing={1 / 2}>
      <Typography color="primary" variant="h3">
        {t("Actual")}
      </Typography>
      <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }: FormikProps<FormValues>) => (
          <Form>
            <Grid container direction="row">
              <Grid
                item
                xs={4}
                style={{
                  flexBasis: `calc(33.333333% - ${px.l})`,
                  maxWidth: `calc(33.333333% - ${px.l})`,
                }}
              >
                {canEdit ? (
                  <FormattedFloatField
                    disabled={false}
                    label={t("Quantity")}
                    name="actualQuantity"
                    type="float"
                    min={0}
                    unit={"\u00A0" + quantityUnitLabels[item.quantityUnit]}
                  />
                ) : (
                  <LabeledQuantityValue
                    value={item.actualQuantity || item.estimatedQuantity}
                    unit={item.quantityUnit}
                    label={t("Quantity")}
                    color="primary"
                  />
                )}
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                style={{
                  width: px.l,
                  paddingBottom: px.xs,
                }}
              >
                x
              </Grid>
              <Grid
                item
                container
                xs={4}
                style={{
                  flexBasis: `calc(33.333333% - ${px.l})`,
                  maxWidth: `calc(33.333333% - ${px.l})`,
                }}
                alignItems="center"
              >
                <Stack alignItems="center">
                  <LabeledValue label={t("Price / Unit")}>
                    {getFormattedPrice(
                      item.averageMaterialBuyingPrice,
                      language
                    )}
                  </LabeledValue>
                  <Tooltip
                    title={t("An average buying price from Purchase Orders")}
                  >
                    <InfoOutlinedIcon fontSize="small" color="secondary" />
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                style={{
                  width: px.l,
                  paddingBottom: px.xs,
                }}
              >
                =
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                xs={4}
              >
                <Stack spacing={1 / 2} alignItems="center">
                  <LabeledValue label={t("Total price")}>
                    {getFormattedPrice(
                      values.actualQuantity * item.averageMaterialBuyingPrice,
                      language
                    )}
                  </LabeledValue>
                </Stack>
              </Grid>
            </Grid>
            <AutoSave enableReinitialize initialValues={initialValues} />
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
