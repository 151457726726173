import { gql, useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { noop } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import {
  RequestAdditionalInfoModalFragment,
  useModifyRequestAdditionalInfoMutation,
} from "./RequestAdditionalInfoModal.generated";
import { useTranslate } from "@tolgee/react";

interface FormValues {
  instructions: string;
}

interface Props {
  projectId: string;
  request: RequestAdditionalInfoModalFragment;
  handleClose: () => void;
  refetchQueries?: string[];
}

export function RequestAdditionalInfoModal({
  projectId,
  request,
  handleClose,
  refetchQueries,
}: Props) {
  const { t } = useTranslate(["Requests", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const [modifyRequest, { loading }] = useModifyRequestAdditionalInfoMutation({
    client,
    refetchQueries,
  });

  const initialValues = React.useMemo<FormValues>(
    () => ({
      instructions: request.instructions,
    }),
    [request]
  );

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: noop,
    enableReinitialize: true,
  });

  return (
    <Modal
      title={t("Edit bidding details", {
        ns: "Requests",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text", disabled: loading },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          handleClick: async () => {
            try {
              await modifyRequest({
                variables: {
                  input: {
                    projectId,
                    requestId: request.id,
                    ...formik.values,
                  },
                },
              });
              handleClose();
              enqueueSnackbar(
                t("Request updated", {
                  ns: "Requests",
                })
              );
            } catch (e) {
              if (e instanceof Error)
                enqueueSnackbar(e.message, { variant: "error" });
            }
          },
          buttonProps: { loading },
        },
      ]}
    >
      <TextField
        multiline
        name="instructions"
        label={t("Addition bidding instructions", {
          ns: "Requests",
        })}
        value={formik.values.instructions}
        onChange={formik.handleChange}
      />
    </Modal>
  );
}
