import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/material";
import { TextWithBreaks } from "../text/TextWithBreaks";

interface Props {
  message: string;
}

export const InfoMessage = ({ message }: Props) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <FontAwesomeIcon
        color={theme.palette.secondary.main}
        icon={faInfoCircle}
        size="2x"
      />
      <Box mt={2}>
        <Typography align="center">
          <TextWithBreaks text={message} />
        </Typography>
      </Box>
    </Box>
  );
};
