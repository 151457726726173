import { Paper } from "@mui/material";
import React, { CSSProperties, FC } from "react";
import { Link } from "react-router-dom";
import { cssValue } from "../../../common/MuiThemeProvider";
import { ViewerBrandingSlot } from "../../../clients/graphqlTypes";

interface Props {
  ad: ViewerBrandingSlot;
  style?: CSSProperties;
  noLink?: boolean;
}

export const BrandingAd: FC<Props> = ({ ad, style, noLink = false }) => {
  const Image = ad.attachment ? (
    <div style={{ display: "flex" }}>
      <img
        src={ad.attachment.url}
        alt={ad.attachment.title}
        style={{
          width: "100%",
          height: "auto",
          borderRadius: cssValue.boxBorderRadius,
        }}
      />
    </div>
  ) : null;

  return ad.attachment ? (
    ad.url && !noLink ? (
      <Paper style={style}>
        <a href={ad.url} target="_blank" rel="noreferrer nofollow">
          {Image}
        </a>
      </Paper>
    ) : (
      <Paper style={style}>{Image}</Paper>
    )
  ) : (
    <></>
  );
};
