import { QuoteTextReplacementName } from "@msys/textutils";
import { TableCell, TableRow } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { AvailableReplacementsModal } from "../../documents/AvailableReplacementsModal";

interface Props {
  handleClose(): void;
}

export function QuoteTextsAvailableReplacementsModal({ handleClose }: Props) {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const replacements: Record<QuoteTextReplacementName, string> = {
    "{{contractee name}}": t(
      `Full name of the contractee, e.g. "Company ABC" or "Mr John Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee salutation}}": t(
      'Salutation of the contractee, e.g. "Mr" or "Mrs"',
      { ns: "OrganisationSettings" }
    ),
    "{{contractee firstname}}": t('First name of the contractee, e.g. "John"', {
      ns: "OrganisationSettings",
    }),
    "{{contractee lastname}}": t('Last name of the contractee, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "{{contractee contact person salutation}}": t(
      `Salutation of the contractee's contact person, e.g. "Mr"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person firstname}}": t(
      `First name of the contractee's contact person, e.g. "John"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person lastname}}": t(
      `Last name of the contractee's contact person, e.g. "Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client name}}": t(
      `Full name of the end client, e.g. "Agency X" or "Mrs Jane Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client salutation}}": t(
      'Salutation of the individual end client, e.g. "Mr" or "Mrs"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client firstname}}": t('First name of the end client, e.g. "Jane"', {
      ns: "OrganisationSettings",
    }),
    "{{end client lastname}}": t('Last name of the end client, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "{{end client contact person salutation}}": t(
      `Salutation of the end client's contact person, e.g. "Mr"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person firstname}}": t(
      `First name of the end client's contact person, e.g. "John"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person lastname}}": t(
      `Last name of the end client's contact person, e.g. "Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{organisation name}}": t(`The name of your organisation`, {
      ns: "OrganisationSettings",
    }),
    "{{your name}}": t(`Your full name, e.g. "John Doe"`, {
      ns: "OrganisationSettings",
    }),
  };

  return (
    <AvailableReplacementsModal handleClose={handleClose}>
      {Object.entries(replacements)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([name, description], index) => (
          <TableRow key={index}>
            <TableCell>{name}</TableCell>
            <TableCell>{description}</TableCell>
          </TableRow>
        ))}
    </AvailableReplacementsModal>
  );
}
