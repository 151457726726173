import {
  Badge,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, Location, useLocation, useMatch } from "react-router-dom";

interface Props {
  icon: React.ReactNode;
  badgeContent?: React.ReactNode;
  label: string;
  linkTo?: string;
  isActive?: (match: any, location: Location) => boolean;
  handleClick: () => void;
}

export function SidebarItem({
  icon,
  badgeContent,
  label,
  linkTo,
  isActive,
  handleClick,
  ...props
}: Props) {
  const location = useLocation();
  const match = useMatch(`${linkTo}/*`);
  const isItemActive = isActive ? isActive(match, location) : match;

  return (
    <ListItemButton
      onClick={handleClick}
      className={isItemActive ? "active" : ""}
      sx={theme => ({
        borderRadius: 1,
        display: "flex",
        flexDirection: "row",
        margin: "4px",
        color: theme.palette.primary.main,
        height: `${theme.navBar.itemHeight}px`,
        minWidth: `${theme.navBar.itemHeight}px`,
        overflow: "hidden",
        "&.active": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      })}
      component={linkTo ? Link : "div"}
      to={linkTo}
      {...props}
    >
      <ListItemIcon
        style={{
          position: "relative",
          pointerEvents: "none",
        }}
      >
        <Badge color="secondary" badgeContent={badgeContent}>
          {icon}
        </Badge>
      </ListItemIcon>
      <ListItemText>
        <Typography noWrap>{label}</Typography>
      </ListItemText>
    </ListItemButton>
  );
}
