import { LabeledValue } from "@msys/ui";
import { Link, Typography } from "@mui/material";
import React from "react";
import { AddressDetails__AddressFragment } from "./Addresses.generated";
import { useTranslate } from "@tolgee/react";
import { getAddressSearchUrl } from "./helpers";

interface Props {
  label: string;
  address?: AddressDetails__AddressFragment | null;
  hideStreetLines?: boolean;
  icon?: React.ComponentProps<typeof LabeledValue>["icon"];
  iconFontSize?: React.ComponentProps<typeof LabeledValue>["iconFontSize"];
  variant?: React.ComponentProps<typeof Typography>["variant"];
}

export function LabeledAddressValue({
  label,
  address,
  hideStreetLines = false,
  icon,
  iconFontSize,
  variant = "body2",
}: Props) {
  const { t } = useTranslate("Global");

  const value = !address ? (
    <div>{t("Not set")}</div>
  ) : hideStreetLines ? (
    <div>
      {address.postalCode} {address.city}
    </div>
  ) : (
    <Link
      href={getAddressSearchUrl(address)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>{address.streetLines1}</div>
      <div>
        {address.postalCode} {address.city}
      </div>
    </Link>
  );

  return (
    <LabeledValue
      label={label}
      icon={icon}
      iconFontSize={iconFontSize}
      variant={variant}
    >
      {value}
    </LabeledValue>
  );
}
