import { useFormatting } from "@msys/ui";
import { clamp } from "lodash";
import React from "react";
import { FormattedField } from "./FormattedField";

export const FormattedFloatField = ({
  name,
  unit,
  min,
  max,
  multiplier = 1,
  defaultValue,
  ...props
}: {
  name: string;
  unit?: string;
  min?: number;
  max?: number;
  multiplier?: number;
  defaultValue?: number;
} & Omit<
  React.ComponentProps<typeof FormattedField<number>>,
  "getFormattedValue" | "getActualValue" | "getEditableValue"
>) => {
  const { getFormattedFloat, getEditableFloat, getFloat } = useFormatting();

  function getFormattedValue(value: number | null | undefined) {
    return getFormattedUnit(getFormattedFloat((value ?? 0) * multiplier), unit);
  }

  function getActualValue(value: string) {
    const floatValue =
      (value === "" && defaultValue !== undefined
        ? defaultValue
        : getFloat(value, false)) / multiplier;
    const newValue = clamp(
      Number.isNaN(floatValue) ? defaultValue ?? 0 : floatValue,
      min ?? Number.MIN_SAFE_INTEGER,
      max ?? Number.MAX_SAFE_INTEGER
    );
    return newValue;
  }

  function getEditableValue(value: number | null | undefined) {
    return getEditableFloat((value ?? 0) * multiplier);
  }

  return (
    <FormattedField
      {...props}
      name={name}
      getFormattedValue={getFormattedValue}
      getActualValue={getActualValue}
      getEditableValue={getEditableValue}
    />
  );
};

function getFormattedUnit(value: string, unit?: string) {
  return `${value}${unit ?? ""}`.trim();
}
