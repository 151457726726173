import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import React from "react";
import * as Yup from "yup";
import {
  ValidateEmailAddressDocument,
  ValidateEmailAddressQuery,
  ValidateEmailAddressQueryVariables,
  useValidateEmailAddressLazyQuery,
} from "./validateEmailAddress.generated";

export function useEmailAddressValidation() {
  const client = useApolloClient();
  const [validateEmail] = useValidateEmailAddressLazyQuery({ client });

  async function isEmailAddressValid(emailAddress: string) {
    const response = await validateEmail({
      variables: { emailAddress },
    });

    return response.data?.validateEmailAddress === true;
  }

  return isEmailAddressValid;
}

export function useEmailAddressValidationSchema() {
  const isEmailAddressValid = useEmailAddressValidation();

  const createEmailAddressValidationSchema = React.useCallback(
    (label: string, isEmailRequired: boolean = false) => {
      let emailSchema = Yup.string().label(label).trim().email();

      if (isEmailRequired) {
        emailSchema = emailSchema.required();
      }

      return emailSchema.test("checkEmailValidity", async (value, context) => {
        emailSchema.validateSync(value);
        if (!value) return !isEmailRequired;

        return await isEmailAddressValid(value);
      });
    },
    [isEmailAddressValid]
  );

  return { createEmailAddressValidationSchema };
}

export async function validateEmailAddress(
  emailAddress: string,
  client: ApolloClient<object>
) {
  const { data } = await client.query<
    ValidateEmailAddressQuery,
    ValidateEmailAddressQueryVariables
  >({
    query: ValidateEmailAddressDocument,
    variables: {
      emailAddress,
    },
  });

  return data.validateEmailAddress === true;
}
