import { gql, useApolloClient } from "@apollo/client";
import { IconButton, useTheme } from "@mui/material";
import { MouseEvent } from "react";
import { ReactComponent as QAOutlinedIcon } from "../../assets/icons/icon-qa-outline.svg";
import { ReactComponent as QAIcon } from "../../assets/icons/icon-qa.svg";
import {
  useQaApproveTaskItemMutation,
  useQaDisapproveTaskItemMutation,
} from "./Tasks.generated";
import { ToggleQaApprovalStatusButton__TaskDocumentItemFragment } from "./ToggleQaApprovalStatusButton.generated";

interface Props {
  projectId: string;
  docId: string;
  itemId: string;
  item: ToggleQaApprovalStatusButton__TaskDocumentItemFragment;
}

export const ToggleQaApprovalStatusButton = ({
  projectId,
  docId,
  itemId,
  item,
}: Props) => {
  const theme = useTheme();
  const client = useApolloClient();

  const [qaApproveTaskItemMutation, { loading: qaApproveTaskItemLoading }] =
    useQaApproveTaskItemMutation({ client });

  const [
    qaDisapproveTaskItemMutation,
    { loading: qaDisapproveTaskItemLoading },
  ] = useQaDisapproveTaskItemMutation({ client });

  const onClick = async (
    e: MouseEvent<HTMLButtonElement>,
    isQaApproved: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isQaApproved) {
      await qaApproveTaskItemMutation({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
          },
        },
      });
    } else {
      await qaDisapproveTaskItemMutation({
        variables: {
          input: {
            projectId,
            docId,
            itemId,
          },
        },
      });
    }
  };

  if (!item.isDone || (item.photoApprovalRequired && !item.photoApproved)) {
    return null;
  }

  const { isQaApproved } = item;

  return (
    <IconButton
      disabled={qaApproveTaskItemLoading || qaDisapproveTaskItemLoading}
      onClick={e => onClick(e, !!isQaApproved)}
      size="small"
      style={{ width: 40, height: 40 }}
    >
      {isQaApproved && (
        <QAIcon
          color={theme.palette.success.main}
          style={{ width: 30, height: 30 }}
        />
      )}
      {isQaApproved === false && (
        <QAIcon
          color={theme.palette.error.main}
          style={{ width: 30, height: 30 }}
        />
      )}
      {isQaApproved === null && (
        <QAOutlinedIcon
          color={theme.palette.grey[300]}
          style={{ width: 30, height: 30 }}
        />
      )}
    </IconButton>
  );
};
