/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariantFragment = { __typename: 'PimSupplierDiscountGroupVariant', id: string, title: string };

export type SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationVariables = Types.Exact<{
  input: Types.PimCreateSupplierDiscountGroupVariantInput;
}>;


export type SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation = { pimCreateSupplierDiscountGroupVariant: { __typename: 'PimCreateSupplierDiscountGroupVariantResult', supplierDiscountGroupVariant: { __typename: 'PimSupplierDiscountGroupVariant', id: string, title: string } } };

export const SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariantFragmentDoc = gql`
    fragment SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariant on PimSupplierDiscountGroupVariant {
  id
  title
}
    `;
export const SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantDocument = gql`
    mutation SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariant($input: PimCreateSupplierDiscountGroupVariantInput!) {
  pimCreateSupplierDiscountGroupVariant(input: $input) {
    supplierDiscountGroupVariant {
      ...SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariant
    }
  }
}
    ${SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariantFragmentDoc}`;
export type SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationFn = Apollo.MutationFunction<SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationVariables>;

/**
 * __useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation__
 *
 * To run a mutation, you first call `useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierDiscountGroupVariantCreateModalCreateSupplierDiscountGroupVariantMutation, { data, loading, error }] = useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation(baseOptions?: Apollo.MutationHookOptions<SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationVariables>(SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantDocument, options);
      }
export type SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationHookResult = ReturnType<typeof useSupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation>;
export type SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationResult = Apollo.MutationResult<SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation>;
export type SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationOptions = Apollo.BaseMutationOptions<SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutation, SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariantMutationVariables>;