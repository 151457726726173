import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete, DataGrid, GridColDef, Modal } from "@msys/ui";
import { FormHelperText, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { debounce, isString, uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import {
  S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntryFragment,
  useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation,
  useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery,
  useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsLazyQuery,
} from "./S4HanaSalesQuotationImportModal.generated";

type FormValues = {
  salesQuoteId: string;
  selectedItemIds: string[];
};

interface Props {
  projectId: string | null;
  refetchQueries?: string[];
  title?: string;
  handleClose: () => void;
  handleComplete?: (params: {
    projectId: string;
    docId: string;
  }) => Promise<void> | void;
}

export const S4HanaSalesQuotationImportModal = ({
  refetchQueries,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const [selectedQuotationId, setSelectedQuotationId] = React.useState<
    string | null
  >(null);

  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const client = useApolloClient();

  const [createQuoteFromSapS4HanaSalesQuote] =
    useS4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuoteMutation(
      { client, refetchQueries }
    );

  const quotationQuery =
    useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationQuery({
      client,
      variables: { salesQuotationId: selectedQuotationId! },
      skip: !selectedQuotationId,
    });

  const quotationItems = React.useMemo(
    () =>
      getDataOrNull(quotationQuery?.data?.sapS4HanaSalesQuotation)
        ?.salesQuotation?.items ?? [],
    [quotationQuery.data?.sapS4HanaSalesQuotation]
  );

  React.useEffect(() => {
    if (quotationItems.length > 0) {
      formikRef.current?.setValues(values => ({
        ...values,
        selectedItemIds: quotationItems.map(i => i.salesQuotationItemId),
      }));
    }
  }, [quotationItems]);

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        salesQuoteId: Yup.string()
          .label(t("Sales quotation", { ns: "SapS4Hana" }))
          .required(),
        selectedItemIds: Yup.array()
          .of(Yup.string())
          .label(t("Positions to import", { ns: "SapS4Hana" }))
          .required()
          .min(1),
      }),
    [t]
  );
  const initialValues = React.useMemo(
    (): FormValues => ({
      salesQuoteId: "",
      selectedItemIds: [],
    }),
    []
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const result = await createQuoteFromSapS4HanaSalesQuote({
        variables: {
          input: {
            sapS4HanaSalesQuoteId: values.salesQuoteId,
            onlySalesQuotationItems: values.selectedItemIds,
          },
        },
      });

      const createQuoteFromSapS4HanaSalesQuoteResult =
        result.data?.createQuoteFromSapS4HanaSalesQuote;

      if (!createQuoteFromSapS4HanaSalesQuoteResult) {
        throw new Error("Quote not created");
      }

      await handleComplete?.({
        docId: createQuoteFromSapS4HanaSalesQuoteResult.quote.id,
        projectId: createQuoteFromSapS4HanaSalesQuoteResult.project.id,
      });

      handleClose();
    },
    [createQuoteFromSapS4HanaSalesQuote, handleComplete, handleClose]
  );

  const columns = React.useMemo(
    (): GridColDef<{
      id: string;
      position: string;
      title: string;
      salesQuotationItemId: string;
    }>[] => [
      {
        field: "position",
        headerName: t("Position", { ns: "Global" }),
        sortable: false,
        groupable: false,
        flex: 1,
        minWidth: 80,
        maxWidth: 150,
      },
      {
        field: "title",
        headerName: t("Title", { ns: "Global" }),
        sortable: false,
        groupable: false,
        flex: 4,
      },
    ],
    [t]
  );

  return (
    <Formik<FormValues>
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {({
        isSubmitting,
        isValid,
        setValues,
        values,
        errors,
      }: FormikProps<FormValues>) => (
        <Modal
          id="import-s4-hana-sales-quotation-modal"
          title={title ?? t("Import sales quotation", { ns: "SapS4Hana" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text", disabled: isSubmitting },
            },
            {
              label: t("Import", { ns: "Global" }),
              buttonProps: {
                type: "submit",
                form: formId,
                color: "primary",
                loading: isSubmitting,
                disabled: !isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <Typography>
                {t("Select sales quotation to import", { ns: "SapS4Hana" })}
              </Typography>
              <SalesQuotationSelect
                inputLabel={t("Sales quotation", { ns: "SapS4Hana" })}
                salesQuotationId={values.salesQuoteId}
                onChange={salesQuotation => {
                  setSelectedQuotationId(
                    salesQuotation?.salesQuotationId ?? null
                  );
                  setValues(values => ({
                    ...values,
                    salesQuoteId: salesQuotation?.salesQuotationId ?? "",
                    selectedItemIds: [],
                  }));
                }}
                required
              />

              {selectedQuotationId && quotationItems.length > 0 && (
                <>
                  <Typography>
                    {t("Select positions to import", { ns: "SapS4Hana" })}
                  </Typography>
                  {errors.selectedItemIds &&
                    isString(errors.selectedItemIds) && (
                      <FormHelperText error>
                        {errors.selectedItemIds}
                      </FormHelperText>
                    )}
                  <DataGrid
                    loading={false}
                    columns={columns}
                    disableColumnMenu
                    disableColumnFilter
                    disableRowGrouping
                    rows={quotationItems}
                    getRowId={row => row.salesQuotationItemId}
                    paginationMode="client"
                    sortingMode="client"
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={values.selectedItemIds}
                    onRowSelectionModelChange={itemIds => {
                      setValues(values => ({
                        ...values,
                        selectedItemIds: itemIds as string[],
                      }));
                    }}
                    pagination={false}
                  />
                </>
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export type SalesQuotationSelectEntry = Omit<
  S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntryFragment,
  "__typename"
>;

interface SalesQuotationSelectProps {
  salesQuotationId?: string | undefined | null;
  onChange: (salesQuotation: SalesQuotationSelectEntry | null) => void;
  inputLabel: string;
  required?: boolean;
  error?: string;
  disabled?: boolean;
}

export const SalesQuotationSelect = ({
  salesQuotationId,
  onChange,
  inputLabel,
  required = false,
  error,
  disabled,
}: SalesQuotationSelectProps) => {
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const [inputValue, setInputValue] = React.useState("");

  const { salesQuotations, debouncedRefetch, loading, totalCount } =
    useSalesQuotations(salesQuotationId, inputValue, setInputValue);

  return (
    <Stack direction="row" spacing={1}>
      <Autocomplete
        placeholder={t("Type to search", { ns: "Global" })}
        options={salesQuotations}
        getOptionLabel={option =>
          `${option.salesQuotationId}${option.createdByUser ? ` (created by ${option.createdByUser})` : ""}`
        }
        inputLabel={inputLabel}
        inputValue={inputValue}
        onInputChange={(_, value, reason) => {
          if (reason === "input") {
            setInputValue(value);
            debouncedRefetch(value);
            onChange(null);
          }
        }}
        required={required}
        value={
          salesQuotations.find(c => c.salesQuotationId === salesQuotationId) ??
          null
        }
        onChange={value => {
          if (value) {
            setInputValue(value.salesQuotationId ?? "");
            onChange(value);
          } else {
            setInputValue("");
            onChange(null);
          }
        }}
        loading={loading}
        error={error}
        disabled={disabled}
      />
    </Stack>
  );
};

const LIMIT = 100;

function useSalesQuotations(
  salesQuotationId: string | undefined | null,
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();
  const [fetchQuery, query] =
    useS4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationsLazyQuery({
      client,
      fetchPolicy: "network-only",
    });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          filter: { salesQuotationId: value || undefined },
          limit: LIMIT,
        },
      });
    },
    [fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (salesQuotationId) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            filter: { salesQuotationId: salesQuotationId },
          },
        });
        const option = getDataOrNull(result.data?.sapS4HanaSalesQuotations)
          ?.edges[0];
        if (option) {
          setInputValue(
            `${option.salesQuotationId}${option.createdByUser ? ` (created by ${option.createdByUser})` : ""}`
          );
        }
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            filter: { salesQuotationId: inputValueRef.current || undefined },
          },
        });
      }
    })();
  }, [salesQuotationId, fetchQuery, setInputValue]);

  const salesQuotations =
    getDataOrNull((query.data ?? query.previousData)?.sapS4HanaSalesQuotations)
      ?.edges ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.sapS4HanaSalesQuotations)
      ?.totalCount ?? 0;

  return {
    salesQuotations,
    refetch,
    debouncedRefetch,
    loading: query.loading,
    totalCount,
  };
}
