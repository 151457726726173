/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type RequirementListItemFragment = { __typename: 'RequirementNode', id: string, title: string, createdAt: any, isPublished: boolean };

export const RequirementListItemFragmentDoc = gql`
    fragment RequirementListItem on RequirementNode {
  id
  title
  createdAt
  isPublished
}
    `;