import React from "react";
import { TruncatedTextValue } from "../text/TruncatedTextValue";
import { LabeledValue } from "./LabeledValue";

interface Props {
  label: string;
  text?: string;
  lines?: number;
  showMoreLabel: string;
  showLessLabel: string;
  notSetLabel: string;
}

export const LabeledTextValue = ({
  lines = 3,
  label,
  text,
  showMoreLabel,
  showLessLabel,
  notSetLabel,
}: Props) => {
  return (
    <LabeledValue label={label}>
      <TruncatedTextValue
        text={text?.trim()}
        lines={lines}
        showMoreLabel={showMoreLabel}
        showLessLabel={showLessLabel}
        notSetLabel={notSetLabel}
      />
    </LabeledValue>
  );
};
