import { useApolloClient } from "@apollo/client";
import {
  CollapseSection,
  getFormattedPrice,
  ModalOpenButton,
  useScreenWidth,
} from "@msys/ui";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useTolgee } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";
import { Button, ButtonLink } from "../../../commons/button/Button";
import { Stack } from "../../../commons/layout/Stack";
import {
  ShopCartFragment,
  useShopAddToCartMutation,
  useShopRemoveFromCartMutation,
} from "../Shop.generated";
import { useTranslate } from "@tolgee/react";
import { QuoteStatusBadge } from "../../quotes/QuoteStatusBadge";
import { ShopListPickerModal } from "../modals/ShopListPickerModal";

interface Props {
  title: string;
  price: number;
  docId: string;
  setCartId: (newCartId: string) => Promise<ShopCartFragment | null>;
  selectedCart: ShopCartFragment | null | undefined;
  refetchCart: () => Promise<ShopCartFragment | null>;
}

export const ShopCartBox = ({
  title,
  price,
  docId,
  setCartId,
  selectedCart,
  refetchCart,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate("Shop");
  const language = useTolgee(["language"]).getLanguage()!;

  const topLevelItems: ShopCartFragment["items"] =
    // @ts-ignore https://github.com/microsoft/TypeScript/issues/44373
    selectedCart?.items?.filter(
      (item: ShopCartFragment["items"][0]) =>
        item.parentId === selectedCart?.rootItem.id
    ) ?? [];

  // @ts-ignore https://github.com/microsoft/TypeScript/issues/44373
  const existingCartItem = topLevelItems.find(
    (item: ShopCartFragment["items"][0]) => item.templateOriginQuoteId === docId
  );

  const [addToCart, { loading: addLoading }] = useShopAddToCartMutation({
    client,
  });

  const [removeFromCart, { loading: removeLoading }] =
    useShopRemoveFromCartMutation({ client });

  return (
    <Paper>
      <Stack width="100%" flexDirection="column" p={isMinTablet ? 2 : 1}>
        <Typography variant="h2">{title}</Typography>
        <Box>
          <Typography variant="h3" component="div">
            {t("Price")}: {getFormattedPrice(price, language)}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            sx={theme => ({ color: theme.palette.grey[500] })}
          >
            {t(
              "Includes VAT. Final price in the quote may be different depending on selected options."
            )}
          </Typography>
        </Box>
      </Stack>

      {selectedCart?.projectId && <Divider />}

      {selectedCart?.projectId && (
        <Box py={0} px={isMinTablet ? 2 : 1}>
          <CollapseSection
            title={t("Shopping list")}
            titleVariant="h4"
            Icon={<AssignmentIcon />}
            isInitiallyExpanded={true}
          >
            <List disablePadding sx={{ mx: isMinTablet ? -2 : -1 }}>
              <ListItem
                divider={false}
                button
                component={Link}
                to={`/projects/${selectedCart.projectId}/${
                  selectedCart.__typename === "Requirement"
                    ? "requirements"
                    : selectedCart.__typename === "Quote"
                      ? "quotes"
                      : "undefined"
                }/${selectedCart.id}/edit`}
              >
                <ListItemText
                  sx={{ mr: 1 }}
                  primary={selectedCart.title ?? ""}
                />
                <Stack spacing={1} alignItems="center">
                  {selectedCart.__typename === "Quote" && (
                    <QuoteStatusBadge quote={selectedCart} align="right" />
                  )}
                  <ModalOpenButton
                    Modal={ShopListPickerModal}
                    modalProps={{
                      selectedCart,
                      handleComplete: async cartId => {
                        await setCartId(cartId);
                      },
                    }}
                  >
                    <Button
                      size="extra-small"
                      color="secondary"
                      variant="outlined"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {t("Change")}
                    </Button>
                  </ModalOpenButton>
                </Stack>
              </ListItem>
            </List>
          </CollapseSection>
        </Box>
      )}

      <Divider />

      <Stack width="100%" flexDirection="column" p={isMinTablet ? 2 : 1}>
        {existingCartItem ? (
          <Button
            variant="contained"
            disabled={!selectedCart}
            isLoading={removeLoading}
            startIcon={<AssignmentIcon />}
            color="warning"
            onClick={async () => {
              if (!selectedCart) throw new Error(`no doc`);
              if (!selectedCart.projectId) throw new Error(`no project`);
              await removeFromCart({
                variables: {
                  input: {
                    docId: selectedCart.id,
                    projectId: selectedCart.projectId,
                    itemId: existingCartItem.id,
                  },
                },
              });
              await refetchCart();
              enqueueSnackbar(t("Item removed from list"));
            }}
          >
            {t("Remove from list")}
          </Button>
        ) : selectedCart ? (
          <Button
            color="primary"
            variant="contained"
            disabled={!selectedCart}
            isLoading={addLoading}
            startIcon={<AssignmentIcon />}
            onClick={async () => {
              if (!selectedCart) throw new Error(`no doc`);
              if (!selectedCart.projectId) throw new Error(`no project`);
              await addToCart({
                variables: {
                  input: {
                    parentDocId: selectedCart.id,
                    parentItemId: selectedCart.rootItem.id,
                    parentProjectId: selectedCart.projectId,
                    templates: [
                      {
                        templateQuoteId: docId,
                      },
                    ],
                  },
                },
              });
              await refetchCart();
              enqueueSnackbar(t("Item added to list"));
            }}
          >
            {t("Add to list")}
          </Button>
        ) : (
          <ModalOpenButton
            Modal={ShopListPickerModal}
            modalProps={{
              selectedCart,
              handleComplete: async cartId => {
                const selectedCart = await setCartId(cartId);
                if (!selectedCart) throw new Error(`no doc`);
                if (!selectedCart.projectId) throw new Error(`no project`);
                try {
                  await addToCart({
                    variables: {
                      input: {
                        parentDocId: selectedCart.id,
                        parentItemId: selectedCart.rootItem.id,
                        parentProjectId: selectedCart.projectId,
                        templates: [
                          {
                            templateQuoteId: docId,
                          },
                        ],
                      },
                    },
                  });
                  await refetchCart();
                  enqueueSnackbar(t("Item added to list"));
                } catch (e) {
                  if (e instanceof Error)
                    enqueueSnackbar(e.message, { variant: "error" });
                }
              },
            }}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<AssignmentIcon />}
            >
              {t("Add to list")}
            </Button>
          </ModalOpenButton>
        )}

        {selectedCart?.projectId ? (
          <ButtonLink
            variant="outlined"
            color="primary"
            to={`/projects/${selectedCart?.projectId}/${
              selectedCart.__typename === "Requirement"
                ? "requirements"
                : selectedCart.__typename === "Quote"
                  ? "quotes"
                  : "undefined"
            }/${selectedCart.id}/preview`}
          >
            {t("Open full list preview")}
          </ButtonLink>
        ) : null}
      </Stack>
    </Paper>
  );
};
