import { Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { TextField } from "../../../commons/form-fields/TextField";

interface FormValues {
  phaseName: string;
}

export function CreateNewPhaseModal({
  handleClose,
  handleComplete,
  isNameAvailable,
}: {
  handleClose: () => void;
  handleComplete: (
    phaseName: string,
    handleClose: () => void
  ) => void | Promise<void>;
  isNameAvailable: (phaseName: string) => boolean;
}) {
  const { t } = useTranslate(["Projects", "Global"]);

  const initialValues: FormValues = {
    phaseName: "",
  };
  const validationSchema = Yup.object().shape({
    phaseName: Yup.string()
      .label(t("Phase name", { ns: "Projects" }))
      .trim()
      .required(t("Phase name can't be empty", { ns: "Projects" }))
      .test(
        "alreadyUsed",
        t("This name is already used", { ns: "Projects" }),
        (name: string | undefined | null) => {
          return Boolean(name && isNameAvailable(name));
        }
      ),
  });
  const handleSubmit = async (values: FormValues) => {
    await handleComplete(values.phaseName, handleClose);
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Modal
          title={t("Create new phase", { ns: "Projects" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", { ns: "Global" }),
              handleClick: handleClose,
              buttonProps: {
                variant: "text",
                disabled: formikProps.isSubmitting,
              },
            },
            {
              label: t("Confirm", { ns: "Global" }),
              buttonProps: {
                loading: formikProps.isSubmitting,
                form: formId,
                type: "submit",
                disabled: !formikProps.dirty || !formikProps.isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <TextField
              name={"phaseName"}
              label={t("Phase name", { ns: "Projects" })}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
