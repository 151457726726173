/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type TemplateTypeOverviewHeaderBox_TemplateTypeFragment = { __typename: 'TemplateType', title: string, description: string };

export const TemplateTypeOverviewHeaderBox_TemplateTypeFragmentDoc = gql`
    fragment TemplateTypeOverviewHeaderBox_TemplateType on TemplateType {
  title
  description
}
    `;