import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import { CollapseSection } from "@msys/ui";
import { CheckboxField } from "../../commons/form-fields/CheckboxField";
import { DateTimePickerField } from "../../commons/form-fields/DateTimePickerField";
import { SwitchField } from "../../commons/form-fields/SwitchField";
import { SearchInput } from "@msys/ui";
import { Stack } from "../../commons/layout/Stack";
import { Modal } from "@msys/ui";
import { OrganisationRole as GQLOrganisationRole } from "../../../clients/graphqlTypes";
import { AddressDetails__AddressFragment } from "../addresses/Addresses.generated";
import { PlanningResourcesFragment } from "./PlanningSchedule.generated";
import { useTranslate } from "@tolgee/react";
import { getAddressLabel } from "../addresses/helpers";
import { useOrganisationRoles } from "../users/useRoles";

type FormValues = {
  onTop: boolean;
  maxDistance: number;
  list: { [id: string]: boolean };
  organisationRoleIds: { [id: string]: boolean };
  availability: {
    from: moment.Moment | null;
    till: moment.Moment | null;
  };
};

export const ResourceFilterModal: React.FC<{
  title?: string;
  usePinnedResources?: boolean;
  useAvailability?: boolean;
  useMaxDistance?: boolean;
  projectAddress?: AddressDetails__AddressFragment;
  resources: PlanningResourcesFragment[];
  roles: GQLOrganisationRole[];
  resourceList: string[] | null | undefined;
  resourceMaxDistance: number | undefined;
  resourceOnTop: boolean | undefined;
  resourceOrganisationRoleIds: string[] | null | undefined;
  resourceAvailability:
    | [moment.Moment | null, moment.Moment | null]
    | null
    | undefined;
  handleClose: () => void;
  handleComplete: (values: {
    resourceList: string[] | null;
    resourceMaxDistance: number;
    resourceOrganisationRoleIds: string[] | null;
    resourceAvailability: [moment.Moment | null, moment.Moment | null] | null;
    resourceOnTop: boolean;
  }) => void;
}> = ({
  title,
  usePinnedResources = true,
  useAvailability = true,
  useMaxDistance = false,
  projectAddress,
  resources,
  roles,
  resourceList,
  resourceMaxDistance,
  resourceOnTop,
  resourceOrganisationRoleIds,
  resourceAvailability,
  handleClose,
  handleComplete,
}) => {
  const { t } = useTranslate(["PlanningModal", "Global"]);

  const formId = React.useMemo(() => uniqueId(), []);

  const { getOrganisationRoleOptions } = useOrganisationRoles();

  const roleOptions = getOrganisationRoleOptions(roles);

  const handleSubmit = async (values: FormValues) => {
    const selectedResources = Object.keys(values.list).filter(
      resourceId =>
        values.list?.[resourceId] && resources.find(r => r.id === resourceId)
    );
    const selectedOrganisationRoleIds = Object.keys(
      values.organisationRoleIds
    ).filter(roleId => values.organisationRoleIds?.[roleId]) as string[];

    handleComplete({
      resourceMaxDistance: values.maxDistance,
      resourceAvailability:
        values.availability.from || values.availability.till
          ? [values.availability.from ?? null, values.availability.till ?? null]
          : null,
      resourceOrganisationRoleIds:
        selectedOrganisationRoleIds.length === roleOptions.length
          ? null
          : selectedOrganisationRoleIds,
      resourceList:
        selectedResources.length === resources.length
          ? null
          : selectedResources,
      resourceOnTop: values.onTop,
    });
    handleClose();
  };

  const initialValues: FormValues = {
    onTop: resourceOnTop ?? true,
    maxDistance: resourceMaxDistance ?? 0,
    list: !resourceList
      ? resources.reduce(
          (acc, resource) => ({
            ...acc,
            [resource.id]: true,
          }),
          {}
        )
      : resourceList.reduce(
          (acc, resourceId) => ({
            ...acc,
            [resourceId]: true,
          }),
          {}
        ),
    organisationRoleIds: !resourceOrganisationRoleIds
      ? roleOptions.reduce(
          (acc, role) => ({
            ...acc,
            [role.value]: true,
          }),
          {}
        )
      : resourceOrganisationRoleIds.reduce(
          (acc, roleId) => ({
            ...acc,
            [roleId]: true,
          }),
          {}
        ),
    availability: resourceAvailability
      ? {
          from: resourceAvailability[0] ?? null,
          till: resourceAvailability[1] ?? null,
        }
      : { from: null, till: null },
  };

  const validationSchema = Yup.object().shape({
    onTop: Yup.boolean(),
    maxDistance: Yup.number()
      .label(
        t("Members within radius", {
          ns: "PlanningModal",
        })
      )
      .min(0),
    availability: Yup.object().shape({
      from: Yup.date()
        .nullable()
        .label(
          t("From", {
            ns: "PlanningModal",
          })
        ),
      till: Yup.date()
        .nullable()
        .label(
          t("Until", {
            ns: "PlanningModal",
          })
        )
        .test(
          "laterThan",
          t("Until must be later than from", {
            ns: "PlanningModal",
          }),
          function (till: Date | null | undefined) {
            const from: Date | null | undefined = this.resolve(Yup.ref("from"));
            return !till || !from || from < till;
          }
        ),
    }),
  });

  const [resourceSearch, setResourceSearch] = useState("");

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isValid }) => {
        const selectedResources = Object.keys(values.list).filter(
          resourceId => values.list?.[resourceId]
        );
        const selectedRoleIds = Object.keys(values.organisationRoleIds).filter(
          roleId => values.organisationRoleIds?.[roleId]
        );
        return (
          <Modal
            title={
              title ??
              t("Filter resources", {
                ns: "PlanningModal",
              })
            }
            actionButtons={[
              {
                label: t("Cancel", {
                  ns: "Global",
                }),
                handleClick: handleClose,
                buttonProps: { variant: "text" },
              },
              {
                label: t("Confirm", {
                  ns: "Global",
                }),
                buttonProps: {
                  type: "submit",
                  form: formId,
                  disabled: !isValid,
                },
              },
            ]}
            handleClose={handleClose}
          >
            <Form id={formId}>
              <Stack flexDirection="column" spacing={1}>
                {usePinnedResources && (
                  <SwitchField
                    name="onTop"
                    label={t("Planned team members at the top", {
                      ns: "PlanningModal",
                    })}
                  />
                )}

                {/* Availability */}
                {useAvailability && (
                  <CollapseSection
                    title={t("Available full-time", {
                      ns: "PlanningModal",
                    })}
                    isInitiallyExpanded={Boolean(
                      values.availability.from || values.availability.till
                    )}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <DateTimePickerField
                          label={t("From", {
                            ns: "PlanningModal",
                          })}
                          name={`availability.from`}
                          disabled={false}
                          isClearable={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DateTimePickerField
                          label={t("Until", {
                            ns: "PlanningModal",
                          })}
                          name={`availability.till`}
                          disabled={false}
                          isClearable={true}
                        />
                      </Grid>
                    </Grid>
                  </CollapseSection>
                )}

                {/* Max distance */}
                {useMaxDistance && Boolean(projectAddress) && (
                  <CollapseSection
                    title={t("Available within distance", {
                      ns: "PlanningModal",
                    })}
                    isInitiallyExpanded={values.maxDistance > 0}
                  >
                    <Field
                      component={TextField}
                      label={t("Members within radius", {
                        ns: "PlanningModal",
                      })}
                      name="maxDistance"
                      type="number"
                      min={1}
                      step={0.25}
                      helperText={t("in km; 0 = any; from {address}", {
                        ns: "PlanningModal",
                        address: getAddressLabel(projectAddress) ?? "",
                      })}
                    />
                  </CollapseSection>
                )}

                {/* Organisation roles */}
                <CollapseSection
                  title={
                    t("Organisation roles", {
                      ns: "PlanningModal",
                    }) +
                    ": " +
                    t("{selectedCount}/{totalCount} selected", {
                      ns: "Global",
                      selectedCount: selectedRoleIds.length,
                      totalCount: roleOptions.length,
                    })
                  }
                >
                  {roleOptions.map(role => (
                    <CheckboxField
                      key={role.value}
                      name={`organisationRoleIds[${role.value}]`}
                      label={role.label}
                    />
                  ))}
                </CollapseSection>

                {/* Team members */}
                <CollapseSection
                  title={
                    t("Team members", {
                      ns: "PlanningModal",
                    }) +
                    ": " +
                    t("{selectedCount}/{totalCount} selected", {
                      ns: "Global",
                      selectedCount: selectedResources.length,
                      totalCount: resources.length,
                    })
                  }
                >
                  <Stack flexDirection="column" spacing={0}>
                    <Stack spacing={3} alignItems="center">
                      <SearchInput
                        placeholder={t("Search", {
                          ns: "Global",
                        })}
                        searchTerm={resourceSearch}
                        onChangeSearchTerm={setResourceSearch}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={false}
                            indeterminate={
                              selectedResources.length > 0 &&
                              selectedResources.length < resources.length
                            }
                            checked={
                              selectedResources.length === resources.length
                            }
                            onChange={(e, value) => {
                              if (selectedResources.length < resources.length) {
                                setFieldValue(
                                  "list",
                                  resources.reduce(
                                    (acc, resource) => ({
                                      ...acc,
                                      [resource.id]: true,
                                    }),
                                    {}
                                  )
                                );
                              } else {
                                setFieldValue("list", {});
                              }
                            }}
                          />
                        }
                        label={t("All", {
                          ns: "Global",
                        })}
                      />
                    </Stack>
                    {resources
                      .filter(
                        resource =>
                          !resourceSearch ||
                          resource.fullname
                            .toLowerCase()
                            .includes(resourceSearch.toLowerCase())
                      )
                      .map(resource => (
                        <CheckboxField
                          key={resource.id}
                          name={`list[${resource.id}]`}
                          label={resource.fullname}
                        />
                      ))}
                  </Stack>
                </CollapseSection>
              </Stack>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
