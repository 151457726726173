/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectAssignBuildingModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type ProjectAssignBuildingModalQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, owningSystemOrganisationId: string, buildingItem?: { __typename: 'Item', id: string, originVersionNumber?: number | null, title: string, docId: string } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string } | null } | null } };

export type ProjectAssignBuildingMutationVariables = Types.Exact<{
  input: Types.AssignBuildingInput;
}>;


export type ProjectAssignBuildingMutation = { assignBuilding: { __typename: 'ProjectAssignBuildingResult', project: { __typename: 'Project', id: string, building?: { __typename: 'Building', id: string, title: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null }, contracteeCrm?: { __typename: 'CrmCompany', id: string, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null } | null } };


export const ProjectAssignBuildingModalDocument = gql`
    query ProjectAssignBuildingModal($projectId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        viewerPermissions
        buildingItem {
          id
          originVersionNumber
          title
          docId
        }
        crmOrganisation {
          id
        }
        owningSystemOrganisationId
      }
    }
  }
}
    `;

/**
 * __useProjectAssignBuildingModalQuery__
 *
 * To run a query within a React component, call `useProjectAssignBuildingModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAssignBuildingModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAssignBuildingModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectAssignBuildingModalQuery(baseOptions: Apollo.QueryHookOptions<ProjectAssignBuildingModalQuery, ProjectAssignBuildingModalQueryVariables> & ({ variables: ProjectAssignBuildingModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectAssignBuildingModalQuery, ProjectAssignBuildingModalQueryVariables>(ProjectAssignBuildingModalDocument, options);
      }
export function useProjectAssignBuildingModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectAssignBuildingModalQuery, ProjectAssignBuildingModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectAssignBuildingModalQuery, ProjectAssignBuildingModalQueryVariables>(ProjectAssignBuildingModalDocument, options);
        }
export function useProjectAssignBuildingModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectAssignBuildingModalQuery, ProjectAssignBuildingModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectAssignBuildingModalQuery, ProjectAssignBuildingModalQueryVariables>(ProjectAssignBuildingModalDocument, options);
        }
export type ProjectAssignBuildingModalQueryHookResult = ReturnType<typeof useProjectAssignBuildingModalQuery>;
export type ProjectAssignBuildingModalLazyQueryHookResult = ReturnType<typeof useProjectAssignBuildingModalLazyQuery>;
export type ProjectAssignBuildingModalSuspenseQueryHookResult = ReturnType<typeof useProjectAssignBuildingModalSuspenseQuery>;
export type ProjectAssignBuildingModalQueryResult = Apollo.QueryResult<ProjectAssignBuildingModalQuery, ProjectAssignBuildingModalQueryVariables>;
export const ProjectAssignBuildingDocument = gql`
    mutation ProjectAssignBuilding($input: AssignBuildingInput!) {
  assignBuilding(input: $input) {
    project {
      id
      building {
        id
        title
        buildingAddress {
          ...AddressDetails__Address
        }
      }
    }
    contracteeCrm {
      id
      billingAddress {
        id
        ...AddressDetails__Address
      }
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export type ProjectAssignBuildingMutationFn = Apollo.MutationFunction<ProjectAssignBuildingMutation, ProjectAssignBuildingMutationVariables>;

/**
 * __useProjectAssignBuildingMutation__
 *
 * To run a mutation, you first call `useProjectAssignBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAssignBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAssignBuildingMutation, { data, loading, error }] = useProjectAssignBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectAssignBuildingMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAssignBuildingMutation, ProjectAssignBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAssignBuildingMutation, ProjectAssignBuildingMutationVariables>(ProjectAssignBuildingDocument, options);
      }
export type ProjectAssignBuildingMutationHookResult = ReturnType<typeof useProjectAssignBuildingMutation>;
export type ProjectAssignBuildingMutationResult = Apollo.MutationResult<ProjectAssignBuildingMutation>;
export type ProjectAssignBuildingMutationOptions = Apollo.BaseMutationOptions<ProjectAssignBuildingMutation, ProjectAssignBuildingMutationVariables>;