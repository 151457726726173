/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { InvoiceSelectAgreedOrProposedList_ItemFragmentDoc } from './buttons/InvoiceSelectButton.generated';
export type InvoiceItemCalculationBox_ItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, needsAgreement: boolean, invoiceAgreedOrProposed: Types.AgreedOrProposedCalculation, quantityUnit: Types.QuantityUnit, invoiceOverriddenActualQuantity?: number | null, invoiceOverriddenActualTime?: number | null, invoiceOverriddenActualMaterialBuyingPrice?: number | null, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, agreedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null };

export type InvoiceItemCalculationBox_QuoteFragment = { __typename: 'Quote', id: string, contractType: Types.ContractType };

export const InvoiceItemCalculationBox_ItemFragmentDoc = gql`
    fragment InvoiceItemCalculationBox_Item on Item {
  id
  type
  needsAgreement
  invoiceAgreedOrProposed
  quantityUnit
  invoiceOverriddenActualQuantity
  invoiceOverriddenActualTime
  invoiceOverriddenActualMaterialBuyingPrice
  ...InvoiceSelectAgreedOrProposedList_Item
}
    ${InvoiceSelectAgreedOrProposedList_ItemFragmentDoc}`;
export const InvoiceItemCalculationBox_QuoteFragmentDoc = gql`
    fragment InvoiceItemCalculationBox_Quote on Quote {
  id
  contractType
}
    `;