import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { ButtonCreate } from "../../commons/button/Button";
import {
  namedOperations,
  ProjectSourcePageName,
} from "../../../clients/graphqlTypes";
import { CreateProjectOrTicketModal } from "../projects/modals/CreateProjectOrTicketModal";
import { OpportunityCreateModal } from "./modals/OpportunityCreateModal";

export const CreateOpportunityButton = ({
  organisationId,
  buildingItemId,
  pageName,
  refetchQueries,
  autoSubmit,
  type,
  size = "small",
}: {
  organisationId?: string;
  buildingItemId?: string;
  pageName?: ProjectSourcePageName;
  refetchQueries?: string[];
  autoSubmit?: boolean;
  type: "icon" | "button";
  size?: "small" | "large";
}) => {
  const { t } = useTranslate("Opportunities");
  const navigate = useNavigate();

  const title = t("Create opportunity");

  return (
    <ModalOpenButton
      Modal={OpportunityCreateModal}
      modalProps={{
        // title,
        // allowedCreateTypes: ["project"],
        autoSubmit,
        refetchQueries: [
          namedOperations.Query.OpportunitiesList,
          ...(refetchQueries ?? []),
        ],
        crmOrganisationId: organisationId,
        buildingItemId,
        pageName,
        handleComplete: async projectId => {
          navigate({
            pathname: `/projects/${projectId}/overview`,
            search: new URLSearchParams({ create: "true" }).toString(),
          });
        },
        desiredProjectInitialStatus: "opportunity",
      }}
    >
      {type === "button" ? (
        <ButtonCreate title={title} sx={{ flexShrink: 0, flexGrow: 0 }} />
      ) : type === "icon" ? (
        <IconButton
          color="primary"
          aria-label={title}
          size={size}
          sx={{ flexShrink: 0, flexGrow: 0 }}
        >
          <Tooltip title={title}>
            <AddIcon />
          </Tooltip>
        </IconButton>
      ) : (
        <div />
      )}
    </ModalOpenButton>
  );
};
