import { CardContainer, ListItem, Tabs } from "@msys/ui";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam";
import { getUrlSearchParamsByParamsName } from "../../../commons/hooks/useStateWithUrlParams";
import { ProjectIcon } from "../../projects/ProjectIcon";
import { CreateProjectOrTicketButton } from "../../projects/buttons/CreateProjectOrTicketButton";
import { ProjectListItem } from "../../projects/components/ProjectListItem";
import { ProjectListItemFragment } from "../../projects/components/ProjectListItem.generated";

interface Props {
  crmCompanyId: string;
  projectsCurrent: ProjectListItemFragment[];
  projectsCurrentTotalCount: number;
  projectsPast: ProjectListItemFragment[];
  projectsPastTotalCount: number;
  projectsCurrentStates: ProjectStateMachineStatus[];
  projectsPastStates: ProjectStateMachineStatus[];
  isLoading: boolean;
}

type QuickFilterValue = "CURRENT" | "PAST";

export const CrmCompanyProjectsBox = ({
  crmCompanyId,
  projectsCurrent,
  projectsCurrentTotalCount,
  projectsPast,
  projectsPastTotalCount,
  projectsCurrentStates,
  projectsPastStates,
  isLoading,
}: Props) => {
  const { t } = useTranslate(["Projects", "Global"]);
  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "projectsBoxQuickFilter",
    "CURRENT"
  );
  const projects =
    quickFilter === "CURRENT"
      ? projectsCurrent
      : quickFilter === "PAST"
        ? projectsPast
        : [];

  const projectsPath = `/projects${
    quickFilter === "CURRENT"
      ? "/current"
      : quickFilter === "PAST"
        ? "/past"
        : ""
  }`;

  return (
    <CardContainer
      Icon={<ProjectIcon />}
      title={t("Projects", { ns: "Projects" })}
      ActionButton={
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <CreateProjectOrTicketButton
            type="icon"
            size="small"
            organisationId={crmCompanyId}
            pageName="CrmCompany"
          />
        </RestrictedByOrganisationPermissionWithDebug>
      }
      isLoading={isLoading}
      isExpandable
    >
      <Box p={1}>
        <Tabs
          condensed
          useSelectOnMobile
          options={[
            {
              value: "CURRENT",
              label: t("Current", { ns: "Projects" }),
              count: projectsCurrentTotalCount,
            },
            {
              value: "PAST",
              label: t("Past", { ns: "Projects" }),
              count: projectsPastTotalCount,
            },
          ]}
          value={quickFilter}
          onChange={(newQuickFilter: QuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Box>
      {projects.length === 0 && (
        <Box p={1} py={3}>
          <Typography align="center" sx={{ color: "grey.500" }} variant="body2">
            {t("There are no items to display", { ns: "Global" })}
          </Typography>
        </Box>
      )}
      {projects.length > 0 && (
        <Stack width="100%" direction="column" spacing={0}>
          {projects.map(project => (
            <ListItem
              key={project.id}
              component={Link}
              //@ts-ignore
              to={`/projects/${project.id}`}
            >
              <ProjectListItem
                project={project}
                showAssignee
                showStatus={
                  quickFilter === "CURRENT"
                    ? projectsCurrentStates.length > 1
                    : quickFilter === "PAST"
                      ? projectsPastStates.length > 1
                      : false
                }
                showContractee={false}
              />
            </ListItem>
          ))}
        </Stack>
      )}
      {projects.length > 0 && (
        <Box>
          <Button
            component={Link}
            to={{
              pathname: projectsPath,
              search: getUrlSearchParamsByParamsName(
                { filterByCrmOrganisationId: crmCompanyId },
                "filters"
              ).toString(),
            }}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See all", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </CardContainer>
  );
};
