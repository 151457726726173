import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, ListItem, Tabs } from "@msys/ui";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { ProjectStateMachineStatus } from "../../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../../auth/useUserData";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam";
import { ProjectIcon } from "../../../features/projects/ProjectIcon";
import { CreateProjectOrTicketButton } from "../../../features/projects/buttons/CreateProjectOrTicketButton";
import { ProjectListItem } from "../../../features/projects/components/ProjectListItem";
import { useDashboardProjectsQuery } from "./DashboardProjectsBox.generated";

type QuickFilterValue = "ALL" | "PROJECTS" | "TICKETS";

export function DashboardProjectsBox() {
  const { t } = useTranslate(["Projects", "Global"]);
  const viewer = useUserData().currentUser!;

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "quickfilterProjectsBox",
    "ALL"
  );

  const allowedStates: ProjectStateMachineStatus[] = viewer.organisation
    .isClientOrganisation
    ? ["contracted", "opportunity"]
    : ["contracted"];

  const client = useApolloClient();
  const query = useDashboardProjectsQuery({
    client,
    fetchPolicy: "cache-and-network",
    variables: {
      filterIncludeState: allowedStates,
    },
  });

  const projectsAll =
    getDataOrNull(query.data?.projectsAll)?.edges.map(e => e.node) ?? [];
  const projectsAllTotalCount =
    getDataOrNull(query.data?.projectsAll)?.totalCount ?? 0;

  const projects =
    getDataOrNull(query.data?.projects)?.edges.map(e => e.node) ?? [];
  const projectsTotalCount =
    getDataOrNull(query.data?.projects)?.totalCount ?? 0;

  const tickets =
    getDataOrNull(query.data?.tickets)?.edges.map(e => e.node) ?? [];
  const ticketsTotalCount = getDataOrNull(query.data?.tickets)?.totalCount ?? 0;

  const quickFilterOptions: {
    label: string;
    value: QuickFilterValue;
    count: number;
  }[] = [
    {
      value: "ALL",
      label: t("All", { ns: "Global" }),
      count: projectsAllTotalCount,
    },
    {
      value: "PROJECTS",
      label: t("Projects", { ns: "Projects" }),
      count: projectsTotalCount,
    },
    {
      value: "TICKETS",
      label: t("Tickets", { ns: "Tickets" }),
      count: ticketsTotalCount,
    },
  ];

  const projectsToShow = {
    ALL: projectsAll,
    PROJECTS: projects,
    TICKETS: tickets,
  }[quickFilter];

  return (
    <CardContainer
      isExpandable
      Icon={<ProjectIcon />}
      title={t("Current projects", { ns: "Projects" })}
      ActionButton={
        <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
          <CreateProjectOrTicketButton
            size="small"
            type="icon"
            pageName="Dashboard"
          />
        </RestrictedByOrganisationPermissionWithDebug>
      }
      isLoading={query.loading}
    >
      {viewer.organisation.isCraftsmanOrganisation && (
        <Box p={1}>
          <Tabs
            condensed
            useSelectOnMobile
            options={quickFilterOptions}
            value={quickFilter}
            onChange={(newQuickFilter: QuickFilterValue) => {
              setQuickFilter(newQuickFilter);
            }}
          />
        </Box>
      )}
      {projectsToShow.length === 0 && (
        <Box p={1} py={3}>
          <Typography align="center" sx={{ color: "grey.500" }} variant="body2">
            {t("There are no items to display", { ns: "Global" })}
          </Typography>
        </Box>
      )}
      {projectsToShow.length > 0 && (
        <Stack width="100%" direction="column" spacing={0}>
          {projectsToShow.map(project => (
            <ListItem
              key={project.id}
              component={Link}
              //@ts-ignore
              to={`/projects/${project.id}`}
            >
              <ProjectListItem
                project={project}
                showAssignee
                showStatus={allowedStates.length > 1}
              />
            </ListItem>
          ))}
        </Stack>
      )}
      {projectsToShow.length > 0 && (
        <Box>
          <Button
            component={Link}
            to={{
              pathname: `/projects/current`,
              search: new URLSearchParams({
                quickfilter: quickFilter,
              }).toString(),
            }}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See all", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </CardContainer>
  );
}
