import { useLocalStorageAsState } from "@msys/ui";

export enum CalendarViewType {
  FullScreen = "full-screen",
  HalfScreen = "half-screen",
  Hidden = "hidden",
}

export const useCalendarViewType = (name: string) => {
  const [calendarView, setCalendarView] =
    useLocalStorageAsState<CalendarViewType>(
      `msys-calendar-view-type-${name}`,
      CalendarViewType.HalfScreen
    );
  return { calendarView: calendarView!, setCalendarView };
};
