import { gql, useApolloClient } from "@apollo/client";
import { MenuItemWithIcon, ModalOpenButton } from "@msys/ui";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { ConfirmModal } from "../../commons/modals/ConfirmModal";
import { useDeleteDraftInvoiceMutation } from "./InvoiceDraftDeleteMenuItem.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  projectId: string;
  docId: string;
  invoiceId: string;
  handleComplete?: () => void;
}

export const InvoiceDraftDeleteMenuItem = ({
  invoiceId,
  projectId,
  docId,
  handleComplete,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate(["InvoiceDetailsForm"]);

  const client = useApolloClient();
  const [deleteInvoice, { loading }] = useDeleteDraftInvoiceMutation({
    client,
  });

  const handleSubmit = async () => {
    try {
      await deleteInvoice({
        variables: {
          input: {
            projectId,
            docId,
            invoiceId,
          },
        },
      });
      enqueueSnackbar(
        t("Invoice deleted", {
          ns: "InvoiceDetailsForm",
        })
      );
      if (handleComplete) {
        handleComplete();
      }
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <ModalOpenButton
      Modal={ConfirmModal}
      modalProps={{
        handleConfirm: handleSubmit,
      }}
      disabled={loading}
    >
      <MenuItemWithIcon icon={<DeleteIcon />}>
        {t("Delete Invoice", {
          ns: "InvoiceDetailsForm",
        })}
      </MenuItemWithIcon>
    </ModalOpenButton>
  );
};
