import { LabeledValueHorizontal, useScreenWidth } from "@msys/ui";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import moment from "moment";
import { px } from "../../../../common/MuiThemeProvider";
import { EntityNumber } from "../../../commons/EntityNumber";
import { getAddressLabel } from "../../addresses/helpers";
import { RequirementHeader__RequirementFragment } from "./RequirementPreviewHeader.generated";

interface Props {
  requirement: RequirementHeader__RequirementFragment;
}

export const RequirementPreviewHeader = ({ requirement }: Props) => {
  const { t } = useTranslate("Quote");

  const contactPersonPhone = requirement.contractee.phone;

  const organisationAddress = getAddressLabel(requirement.contractee.address);

  const { isMaxPhone } = useScreenWidth();

  return (
    <Paper>
      <Stack padding={2} spacing={2} direction={isMaxPhone ? "column" : "row"}>
        <Stack
          flex={3}
          direction="column"
          justifyContent="center"
          spacing={0}
          order={isMaxPhone ? 2 : 1}
        >
          {isMaxPhone && (
            <div>
              <Divider style={{ marginBottom: px.m }} />
            </div>
          )}
          <Typography variant="h3">
            {t("Requirements Document,")}
            <br />
            {t("Bill of Quantities")}
          </Typography>
        </Stack>
        <Stack
          flex={2}
          direction="column"
          spacing={0}
          order={isMaxPhone ? 1 : 2}
        >
          <Typography variant="h3" style={{ marginBottom: px.xs }}>
            {requirement.contractee.companyOrIndividual === "COMPANY"
              ? requirement.contractee.companyName
              : `${requirement.contractee.firstname} ${requirement.contractee.familyname}`}
          </Typography>
          {organisationAddress ? (
            <Typography variant="caption" style={{ marginBottom: px.xs }}>
              {organisationAddress}
            </Typography>
          ) : null}
          <LabeledValueHorizontal label={t("Contact person")} labelWidth={100}>
            {requirement.contractee.contact?.fullname}
          </LabeledValueHorizontal>
          {contactPersonPhone ? (
            <LabeledValueHorizontal label={t("Phone")} labelWidth={100}>
              {contactPersonPhone}
            </LabeledValueHorizontal>
          ) : null}
          {requirement.originalProjectNumber ? (
            <LabeledValueHorizontal label={t("Project No")} labelWidth={100}>
              <EntityNumber
                noLabel
                number={requirement.originalProjectNumber}
              />
            </LabeledValueHorizontal>
          ) : null}
          <LabeledValueHorizontal label={t("Requirement No")} labelWidth={100}>
            <EntityNumber noLabel number={requirement.number} />
          </LabeledValueHorizontal>
          <LabeledValueHorizontal label={t("Issued on")} labelWidth={100}>
            {requirement.publishedAt
              ? moment(requirement.publishedAt).format("L")
              : "-"}
          </LabeledValueHorizontal>
        </Stack>
      </Stack>
    </Paper>
  );
};
