import { gql } from "@apollo/client";
import { CardContainer, CollapseSection, useFormatting } from "@msys/ui";
import { Typography } from "@mui/material";
import { isNil } from "lodash";
import { Stack } from "../../../commons/layout/Stack";
import { OrderDetailsFragment } from "./OrderDetailsBox.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  billOfMaterialsItem: OrderDetailsFragment;
}

export const OrderDetailsBox = ({ billOfMaterialsItem }: Props) => {
  const { t } = useTranslate("Materials");
  const { getFormattedPrice } = useFormatting();

  return (
    <CardContainer title={t("Order details")} isExpandable>
      <Stack flexDirection="column" margin={1} spacing={0}>
        <CollapseSection
          title={t("Material quantity")}
          titleVariant="h4"
          isInitiallyExpanded
        >
          <Stack flexDirection={"column"} padding={1}>
            <Stack justifyContent={"space-between"}>
              <Typography>{"Total required"}</Typography>
              <Typography>{`${billOfMaterialsItem.quantityRequiredTotal} ${billOfMaterialsItem.quantityUnit}`}</Typography>
            </Stack>
            <Stack flexDirection="column" paddingLeft={1}>
              {billOfMaterialsItem.quantityRequiredAccepted !== 0 && (
                <Stack justifyContent={"space-between"}>
                  <Typography variant="body2">
                    {t("Accepted quote items")}
                  </Typography>
                  <Typography variant="body2">
                    {`${billOfMaterialsItem.quantityRequiredAccepted} ${billOfMaterialsItem.quantityUnit}`}
                  </Typography>
                </Stack>
              )}
              {billOfMaterialsItem.quantityRequiredDraft !== 0 && (
                <Stack justifyContent={"space-between"}>
                  <Typography variant="body2">
                    {t("Draft quote items")}
                  </Typography>
                  <Typography variant="body2">
                    {`${billOfMaterialsItem.quantityRequiredDraft} ${billOfMaterialsItem.quantityUnit}`}
                  </Typography>
                </Stack>
              )}
              {billOfMaterialsItem.quantityRequiredPending !== 0 && (
                <Stack justifyContent={"space-between"}>
                  <Typography variant="body2">
                    {t("Pending quote items")}
                  </Typography>
                  <Typography variant="body2">
                    {`${billOfMaterialsItem.quantityRequiredPending} ${billOfMaterialsItem.quantityUnit}`}
                  </Typography>
                </Stack>
              )}
              {billOfMaterialsItem.quantityRequiredChange !== 0 && (
                <Stack justifyContent={"space-between"}>
                  <Typography variant="body2">
                    {t("Items with pending change order")}
                  </Typography>
                  <Typography variant="body2">
                    {`${billOfMaterialsItem.quantityRequiredChange} ${billOfMaterialsItem.quantityUnit}`}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{t("Ordered")}</Typography>
              <Typography>{`${billOfMaterialsItem.quantityOrdered} ${billOfMaterialsItem.quantityUnit}`}</Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{t("To be ordered")}</Typography>
              <Typography>{`${Math.max(
                billOfMaterialsItem.quantityRequiredTotal -
                  billOfMaterialsItem.quantityOrdered,
                0
              )} ${billOfMaterialsItem.quantityUnit}`}</Typography>
            </Stack>
          </Stack>
        </CollapseSection>
        <CollapseSection
          title={t("Material pricing")}
          titleVariant="h4"
          isInitiallyExpanded
        >
          <Stack flexDirection={"column"} padding={1}>
            <Stack justifyContent={"space-between"}>
              <Typography>{t("List price")}</Typography>
              <Typography>
                {!isNil(billOfMaterialsItem.listPrice)
                  ? getFormattedPrice(billOfMaterialsItem.listPrice)
                  : "-"}
              </Typography>
            </Stack>
            <Stack justifyContent={"space-between"}>
              <Typography>{t("Net price")}</Typography>
              <Typography>
                {!isNil(billOfMaterialsItem.netPrice)
                  ? getFormattedPrice(billOfMaterialsItem.netPrice)
                  : "-"}
              </Typography>
            </Stack>
          </Stack>
        </CollapseSection>
      </Stack>
    </CardContainer>
  );
};
