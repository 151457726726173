import { useApolloClient } from "@apollo/client";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { CircularProgress, Icon } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { FC, useRef, useState } from "react";
import {
  useCreateProjectFromXiopdImportMutation,
  useCreateQuoteFromXiopdImportMutation,
} from "./XiopdImport.generated";
import { useTranslate } from "@tolgee/react";
import { XiopdFileUploader } from "./XiopdFileUploader";

type Props = {
  projectId?: string | null;
  refetchQueries?: string[];
  projectParams?: { isTentative?: boolean };
};

export const ImportXiopdButton = ({
  projectId,
  refetchQueries,
  projectParams,
}: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate("Projects");
  const [createProjectFromXiopdImportMutation] =
    useCreateProjectFromXiopdImportMutation({
      client,
    });
  const [createQuoteFromXiopdImportMutation] =
    useCreateQuoteFromXiopdImportMutation({
      client,
    });
  const { enqueueSnackbar } = useSnackbar();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

  async function importXiopd(filename: string) {
    if (!projectId) {
      const createProjectFromXiopdImportResult =
        await createProjectFromXiopdImportMutation({
          variables: {
            input: {
              filename,
              projectParams,
            },
          },
          refetchQueries,
        });

      if (!createProjectFromXiopdImportResult.data) {
        throw new Error("Import not successfull");
      }

      await createQuoteFromXiopdImportMutation({
        variables: {
          input: {
            filename,
            projectId:
              createProjectFromXiopdImportResult.data
                .createProjectFromXiopdImport.project.id,
          },
        },
        refetchQueries,
      });
    } else {
      await createQuoteFromXiopdImportMutation({
        variables: {
          input: {
            filename,
            projectId,
          },
        },
        refetchQueries,
      });
    }

    return undefined;
  }

  return (
    <MenuItemWithIcon
      icon={
        status !== "loading" ? (
          <Icon>
            <FontAwesomeIcon icon={faFileImport} />
          </Icon>
        ) : (
          <CircularProgress size={"1rem"} />
        )
      }
      onClick={async () => {
        fileInputRef.current!.click();
      }}
      disabled={status === "loading"}
    >
      <XiopdFileUploader
        innerRef={fileInputRef}
        onStart={() => setStatus("loading")}
        onCancel={() => {
          setStatus("idle");
        }}
        onError={e => {
          if (e instanceof Error)
            enqueueSnackbar(e.message, { variant: "error" });
          Sentry.captureException(e);
          setStatus("error");
        }}
        onUploadFile={async file => {
          setStatus("loading");
          try {
            await importXiopd(file.name);
            setStatus("idle");
          } catch (e) {
            if (e instanceof Error)
              enqueueSnackbar(e.message, { variant: "error" });
            Sentry.captureException(e);
            setStatus("error");
          }
        }}
      />
      {`${t("Import")} xi:opd`}
    </MenuItemWithIcon>
  );
};
