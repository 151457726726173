import { useApolloClient } from "@apollo/client";
import { MenuButton, ModalOpenButton } from "@msys/ui";
import BugReportIcon from "@mui/icons-material/BugReport";
import { IconButton, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useFeature } from "../../../../common/FeatureFlags";
import { THREED_PLANNER_SHOW } from "../../../../environment";
import { useUserData } from "../../../auth/useUserData";
import {
  LayoutView,
  ToggleLayoutViewButton,
  ToggleLayoutViewButtonGroup,
} from "../../../commons/button/ToggleLayoutViewButtonGroup";
import {
  PageSubHeader,
  PageSubHeaderInjectedProps,
} from "../../../commons/layout/PageSubHeader";
import { useHeaderBox_ModifyItemInfoMutation } from "../../doc-items/boxes/HeaderBox.generated";
import { rootItemHasItemTableConstraints } from "../../doc-items/constraints";
import { TemplateQuoteStatusChip } from "./TemplateQuoteStatus";
import { useTemplateQuoteStatuses } from "./TemplateQuoteStatuses";
import { useTemplateQuoteWarnings } from "./TemplateQuoteWarnings";
import { TemplatesQuoteTemplateMenu } from "./TemplatesQuoteTemplateMenu";
import {
  TemplatesQuoteTemplateSubHeader_QuoteTemplateFragment,
  TemplatesQuoteTemplateSubHeader_QuoteTemplateItemFragment,
} from "./TemplatesQuoteTemplateSubHeader.generated";
import { TemplateDebugModal } from "./debug/TemplateDebugModal";
import { ExportTemplatesMenuItem } from "../buttons/ExportTemplatesMenuItem";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";

interface Props {
  activeView: LayoutView | null;
  docId: string;
  doc: TemplatesQuoteTemplateSubHeader_QuoteTemplateFragment;
  rootItem: TemplatesQuoteTemplateSubHeader_QuoteTemplateItemFragment;
  rootItemId: string;
  pathToDocList: string;
  pathToDoc: string;
  pathToDocVersion: string;
  onDuplicateRefetchQueries?: string[];
  onUpdateDataRefetchQueries?: string[];
  canEdit?: boolean;
  SettingsModalButton: React.ReactElement;
}

export const TemplatesQuoteTemplateSubHeader = ({
  activeView,
  docId,
  doc,
  rootItemId,
  rootItem,
  pathToDocList,
  pathToDoc,
  pathToDocVersion,
  onDuplicateRefetchQueries,
  onUpdateDataRefetchQueries,
  isHeaderVisible,
  setHeaderVisible,
  canEdit = false,
  SettingsModalButton,
}: Props & PageSubHeaderInjectedProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Global", "Templates"]);
  const isTemplateDebugEnabled = useFeature("TemplateDebug");
  const viewer = useUserData().currentUser!;

  const isOwnTemplate =
    doc.owningSystemOrganisationId === viewer.organisation.id;

  const { getTemplateQuoteWarningIcon } = useTemplateQuoteWarnings();
  const { getTemplateQuoteStatusIcons } = useTemplateQuoteStatuses();

  const client = useApolloClient();
  const [modifyItemInfo] = useHeaderBox_ModifyItemInfoMutation({
    client,
    refetchQueries: onUpdateDataRefetchQueries,
    awaitRefetchQueries: true,
  });
  const handleTitleChange = async (title: string) => {
    await modifyItemInfo({
      variables: {
        input: {
          itemId: rootItemId,
          docId,
          projectId: null,
          values: {
            title,
          },
        },
      },
    });
  };

  const statusIcons: React.ReactNode[] = [
    ...getTemplateQuoteStatusIcons(doc),
    getTemplateQuoteWarningIcon(pathToDoc, doc),
  ].filter(Boolean);

  return (
    <PageSubHeader
      title={doc.title}
      status={<TemplateQuoteStatusChip doc={doc} />}
      handleTitleChange={
        canEdit
          ? async newValue => {
              await handleTitleChange(newValue);
              enqueueSnackbar(
                t("Title updated", {
                  ns: "Global",
                })
              );
            }
          : undefined
      }
      titleEditTooltip={
        canEdit
          ? t("Rename", {
              ns: "Global",
            })
          : undefined
      }
      actionButtons={
        canEdit ? (
          <TemplatesQuoteTemplateMenu
            doc={doc}
            pathToDocList={pathToDocList}
            pathToDocVersion={pathToDocVersion}
            onDuplicateRefetchQueries={onDuplicateRefetchQueries}
            onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
          />
        ) : isOwnTemplate ? (
          <MenuButton>
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
              <ExportTemplatesMenuItem
                docIds={[
                  {
                    docId: doc.id,
                    versionNumber: doc.resolvedAsReadModelVersionNumber ?? null,
                  },
                ]}
                title={t("Export template", {
                  ns: "Quote",
                })}
              />
            </RestrictedByOrganisationPermissionWithDebug>
          </MenuButton>
        ) : undefined
      }
      leftButtons={
        <Stack direction={"row"} alignItems="center" spacing={1}>
          {statusIcons}
          {isTemplateDebugEnabled && isOwnTemplate && (
            <ModalOpenButton
              key={"debug-template"}
              Modal={TemplateDebugModal}
              modalProps={{ docId: doc.id, pathToDocList }}
            >
              <IconButton color="primary">
                <BugReportIcon />
              </IconButton>
            </ModalOpenButton>
          )}
        </Stack>
      }
      rightButtons={
        <Stack direction={"row"} alignItems="center" spacing={1}>
          <ToggleLayoutViewButtonGroup
            activeView={activeView}
            setActiveView={view =>
              navigate(
                view === "tree"
                  ? pathToDocVersion
                  : `${pathToDocVersion}/${view}`
              )
            }
          >
            <ToggleLayoutViewButton viewType="tree" />
            {THREED_PLANNER_SHOW === "true" &&
              rootItemHasItemTableConstraints.includes(rootItem.type) &&
              (canEdit || doc.has3dRoomSnapshot) && (
                <ToggleLayoutViewButton viewType="3d" />
              )}
          </ToggleLayoutViewButtonGroup>
          {SettingsModalButton}
        </Stack>
      }
      isHeaderVisible={isHeaderVisible}
      setHeaderVisible={setHeaderVisible}
    />
  );
};
