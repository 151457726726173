/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhaseFragment = { __typename: 'OrganisationProjectPhase', id: string, name: string };

export const ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhaseFragmentDoc = gql`
    fragment ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhase on OrganisationProjectPhase {
  id
  name
}
    `;