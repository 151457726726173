import { Capabilities } from "@msys/common";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { PermissionName } from "../../../clients/graphqlTypes";
import { RestrictedByCapabilityWithDebug } from "../../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { SidebarGroup, SidebarItem } from "../../global/SidebarProvider";

export interface PageLeftSidebarGroup<T extends SidebarItem = SidebarItem> {
  name: string;
  items: T[];
  capability?: Capabilities;
  permission?: PermissionName;
}

export const PageLeftSidebar = ({
  groups,
  activeItem,
  onItemClick,
}: {
  groups: SidebarGroup[];
  activeItem: SidebarItem | undefined;
  onItemClick?: () => void;
}) => {
  if (groups.length === 0) return null;
  return (
    <>
      {groups.map((group, groupIndex) => {
        if (group.items.length === 0) return null;
        let listGroup = (
          <List
            key={`${group.name}-${groupIndex}`}
            component="nav"
            subheader={
              <ListSubheader component="div">{group.name}</ListSubheader>
            }
          >
            {group.items.map((item, itemIndex) => {
              let listItem = (
                <ListItemButton
                  key={`${item.name}-${itemIndex}`}
                  // @ts-ignore
                  component={Link}
                  to={item.path}
                  selected={activeItem === item}
                  onClick={onItemClick}
                >
                  <ListItemIcon
                    sx={theme => ({
                      minWidth: theme.layout.listItemMinWidth.sm,
                    })}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography noWrap>{item.label}</Typography>
                  </ListItemText>
                </ListItemButton>
              );

              if (item.permission) {
                listItem = (
                  <RestrictedByOrganisationPermissionWithDebug
                    permission={item.permission}
                    key={`${item.name}-${itemIndex}-permission`}
                  >
                    {listItem}
                  </RestrictedByOrganisationPermissionWithDebug>
                );
              }
              if (item.capability) {
                listItem = (
                  <RestrictedByCapabilityWithDebug
                    capability={item.capability}
                    key={`${item.name}-${itemIndex}-capability`}
                  >
                    {listItem}
                  </RestrictedByCapabilityWithDebug>
                );
              }

              return listItem;
            })}
          </List>
        );

        if (group.permission) {
          listGroup = (
            <RestrictedByOrganisationPermissionWithDebug
              permission={group.permission}
              key={`${group.name}-${groupIndex}-permission`}
            >
              {listGroup}
            </RestrictedByOrganisationPermissionWithDebug>
          );
        }
        if (group.capability) {
          listGroup = (
            <RestrictedByCapabilityWithDebug
              capability={group.capability}
              key={`${group.name}-${groupIndex}-capability`}
            >
              {listGroup}
            </RestrictedByCapabilityWithDebug>
          );
        }

        return listGroup;
      })}
    </>
  );
};
