/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type ProjectPhaseChip_ProjectFragment = { __typename: 'Project', id: string, phase: { __typename: 'ProjectPhase', id: string, name: string } };

export type ProjectPhaseChip_OrganisationSettingsFragment = { __typename: 'OrganisationSetting', id: string, projectPhases: Array<{ __typename: 'OrganisationProjectPhase', id: string, name: string, applicableForState: Types.ProjectStateMachineStatus }> };

export const ProjectPhaseChip_ProjectFragmentDoc = gql`
    fragment ProjectPhaseChip_Project on Project {
  id
  phase {
    id
    name
  }
}
    `;
export const ProjectPhaseChip_OrganisationSettingsFragmentDoc = gql`
    fragment ProjectPhaseChip_OrganisationSettings on OrganisationSetting {
  id
  projectPhases(filterIncludeState: [opportunity, contracted]) {
    id
    name
    applicableForState
  }
}
    `;