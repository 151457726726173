import { ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import React from "react";
import { CreateTodoItemModal } from "./CreateTodoItemModal";

interface Props
  extends Omit<
    React.ComponentProps<typeof CreateTodoItemModal>,
    "handleClose"
  > {
  Button?: React.ReactElement;
}

export const CreateTodoItemButton = ({ Button, ...modalProps }: Props) => {
  const button = Button ?? (
    <IconButton color="primary" size="small">
      <AddIcon />
    </IconButton>
  );

  return (
    <ModalOpenButton Modal={CreateTodoItemModal} modalProps={modalProps}>
      {button}
    </ModalOpenButton>
  );
};
