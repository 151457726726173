import { CollapseSection, MenuButton, ModalOpenButton } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { Grid, IconButton } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { Stack } from "../../commons/layout/Stack";
import { ViewMode, ViewModeMenuItem } from "../../commons/ViewModeMenuItem";
import { DocType, Props2NonComputed } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import { PropertyAddModal } from "../doc-items/modals/PropertyAddModal";
import { PropertyField } from "../doc-items/PropertyField";

interface Props {
  docType: DocType;
  properties: Props2NonComputed[];
  fieldName: string;
  title?: string;
  disabled?: boolean;
  autoSubmit?: boolean;
  gridColumnSpacing?: number;
}

export const ItemProductPropertiesSection = ({
  docType,
  properties,
  fieldName,
  title,
  disabled,
  gridColumnSpacing = 1,
}: Props) => {
  const { t } = useTranslate("QuoteItem");

  const [viewMode, setViewMode] = React.useState<ViewMode>("visibility");

  const { setFieldValue } = useFormikContext();

  return (
    <CollapseSection
      title={title ?? t("Item Properties")}
      titleVariant="h4"
      isInitiallyExpanded={properties.length > 0}
      itemCount={properties.length}
      hideItemCountOnExpand={true}
      ActionButtons={
        <Stack alignItems="center">
          <MenuButton>
            <ViewModeMenuItem
              viewMode={viewMode}
              onViewModeChange={setViewMode}
              allowedModes={["visibility", "delete"]}
            />
          </MenuButton>
          <ModalOpenButton
            Modal={PropertyAddModal}
            modalProps={{
              docType,
              showQuestionControl: false,
              handleComplete: async property => {
                setFieldValue(
                  fieldName,
                  properties.concat(
                    property.bool
                      ? { ...property.bool, __typename: "Props2Bool" }
                      : property.text
                      ? {
                          ...property.text,
                          __typename: "Props2Text",
                          allowedValuesText:
                            property.text.allowedValuesText.map(v => ({
                              ...v,
                              media: null,
                              __typename: "Props2AllowedValuesText" as const,
                            })),
                        }
                      : property.number
                      ? {
                          ...property.number,
                          __typename: "Props2Number",
                          allowedValuesNumber:
                            property.number.allowedValuesNumber.map(v => ({
                              ...v,
                              media: null,
                              __typename: "Props2AllowedValuesNumber" as const,
                            })),
                          range: {
                            ...property.number.range,
                            __typename: "Props2NumberRange" as const,
                          },
                        }
                      : property.textArray
                      ? {
                          ...property.textArray,
                          __typename: "Props2TextArray",
                          allowedValuesText:
                            property.textArray.allowedValuesText.map(v => ({
                              ...v,
                              media: null,
                              __typename: "Props2AllowedValuesText" as const,
                            })),
                        }
                      : property.numberArray
                      ? {
                          ...property.numberArray,
                          __typename: "Props2NumberArray",
                          allowedValuesNumber:
                            property.numberArray.allowedValuesNumber.map(v => ({
                              ...v,
                              media: null,
                              __typename: "Props2AllowedValuesNumber" as const,
                            })),
                          range: {
                            ...property.numberArray.range,
                            __typename: "Props2NumberRange" as const,
                          },
                        }
                      : []
                  )
                );
              },
            }}
          >
            <IconButton size="small" color="primary">
              <AddIcon />
            </IconButton>
          </ModalOpenButton>
        </Stack>
      }
    >
      <FieldArray
        name={fieldName}
        render={arrayHelpers => (
          <Grid
            container
            columns={2}
            rowSpacing={1}
            columnSpacing={gridColumnSpacing}
          >
            {properties.map((property, i) => (
              <Grid key={property.key} item xs={1}>
                <PropertyField
                  disableExpressions
                  docId=""
                  itemId=""
                  projectId=""
                  property={property}
                  spacing={0.5}
                  setPropertyValue={property => {
                    if (property.bool) {
                      setFieldValue(
                        `${fieldName}.[${i}].valueBool`,
                        property.bool.valueBool
                      );
                    } else if (property.number) {
                      setFieldValue(
                        `${fieldName}.[${i}].valueNumber`,
                        property.number.valueNumber
                      );
                    } else if (property.text) {
                      setFieldValue(
                        `${fieldName}.[${i}].valueText`,
                        property.text.valueText
                      );
                    } else if (property.textArray) {
                      setFieldValue(
                        `${fieldName}.[${i}].valueTextArray`,
                        property.textArray.valueTextArray
                      );
                    } else if (property.numberArray) {
                      setFieldValue(
                        `${fieldName}.[${i}].valueNumberArray`,
                        property.numberArray.valueNumberArray
                      );
                    } else {
                      throw new Error("Property kind not supported");
                    }
                  }}
                  setVisibility={
                    viewMode === "visibility"
                      ? (key, isVisible) => {
                          setFieldValue(
                            `${fieldName}.[${i}].clientVisibility`,
                            isVisible
                          );
                        }
                      : undefined
                  }
                  handleDelete={
                    viewMode === "delete"
                      ? () => {
                          arrayHelpers.remove(i);
                        }
                      : undefined
                  }
                  readOnly={disabled}
                />
              </Grid>
            ))}
          </Grid>
        )}
      />
    </CollapseSection>
  );
};
