/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PimImportationsQueryVariables = Types.Exact<{
  supplierId: Types.Scalars['ID']['input'];
}>;


export type PimImportationsQuery = { pimImportations: { __typename: 'MissingCapabilities' } | { __typename: 'PimImportationsResult', importations: Array<{ __typename: 'PimImportation', id: string, url: string, status: Types.PimImportationStatus, createdAt: any, filename: string, searchIndexCompletionPercentage: number, importCompletionPercentage: number, completionPercentage: number, totalProcessedProducts?: number | null, deletedAt?: any | null, isProcessing: boolean }> } };

export type PimImportationRowFragment = { __typename: 'PimImportation', id: string, url: string, status: Types.PimImportationStatus, createdAt: any, filename: string, searchIndexCompletionPercentage: number, importCompletionPercentage: number, completionPercentage: number, totalProcessedProducts?: number | null, deletedAt?: any | null, isProcessing: boolean };

export type AddPimImportMutationVariables = Types.Exact<{
  input: Types.AddPimImportInput;
}>;


export type AddPimImportMutation = { addPimImport: { __typename: 'AddPimImportationResult', importation: { __typename: 'PimImportation', id: string, url: string, status: Types.PimImportationStatus, filename: string } } };

export type PimRequestImportationUploadUrlMutationVariables = Types.Exact<{
  input: Types.PimRequestImportationUploadUrlInput;
}>;


export type PimRequestImportationUploadUrlMutation = { pimRequestImportationUploadUrl: { __typename: 'RequestUploadUrlResult', uploadUrl: string } };

export type DeletePimImportMutationVariables = Types.Exact<{
  input: Types.DeletePimImportInput;
}>;


export type DeletePimImportMutation = { deletePimImport: { __typename: 'DeletePimImportationResult', importation: { __typename: 'PimImportation', id: string, url: string, status: Types.PimImportationStatus, filename: string } } };

export type DeletePimSupplierImportationsMutationVariables = Types.Exact<{
  input: Types.DeletePimSupplierImportationsInput;
}>;


export type DeletePimSupplierImportationsMutation = { deletePimSupplierImportations: { __typename: 'DeletePimSupplierImportationsResult', importations: Array<{ __typename: 'PimImportation', id: string, url: string, status: Types.PimImportationStatus, filename: string }> } };

export type CancelPimImportMutationVariables = Types.Exact<{
  input: Types.DeletePimImportInput;
}>;


export type CancelPimImportMutation = { deletePimImport: { __typename: 'DeletePimImportationResult', importation: { __typename: 'PimImportation', id: string, url: string, status: Types.PimImportationStatus, filename: string } } };

export const PimImportationRowFragmentDoc = gql`
    fragment PimImportationRow on PimImportation {
  id
  url
  status
  createdAt
  filename
  searchIndexCompletionPercentage
  importCompletionPercentage
  completionPercentage
  totalProcessedProducts
  deletedAt
  isProcessing
}
    `;
export const PimImportationsDocument = gql`
    query PimImportations($supplierId: ID!) {
  pimImportations(filters: {supplierId: $supplierId}) {
    ... on PimImportationsResult {
      importations {
        id
        ...PimImportationRow
      }
    }
  }
}
    ${PimImportationRowFragmentDoc}`;

/**
 * __usePimImportationsQuery__
 *
 * To run a query within a React component, call `usePimImportationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePimImportationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePimImportationsQuery({
 *   variables: {
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function usePimImportationsQuery(baseOptions: Apollo.QueryHookOptions<PimImportationsQuery, PimImportationsQueryVariables> & ({ variables: PimImportationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PimImportationsQuery, PimImportationsQueryVariables>(PimImportationsDocument, options);
      }
export function usePimImportationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PimImportationsQuery, PimImportationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PimImportationsQuery, PimImportationsQueryVariables>(PimImportationsDocument, options);
        }
export function usePimImportationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PimImportationsQuery, PimImportationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PimImportationsQuery, PimImportationsQueryVariables>(PimImportationsDocument, options);
        }
export type PimImportationsQueryHookResult = ReturnType<typeof usePimImportationsQuery>;
export type PimImportationsLazyQueryHookResult = ReturnType<typeof usePimImportationsLazyQuery>;
export type PimImportationsSuspenseQueryHookResult = ReturnType<typeof usePimImportationsSuspenseQuery>;
export type PimImportationsQueryResult = Apollo.QueryResult<PimImportationsQuery, PimImportationsQueryVariables>;
export const AddPimImportDocument = gql`
    mutation AddPimImport($input: AddPimImportInput!) {
  addPimImport(input: $input) {
    importation {
      id
      url
      status
      filename
    }
  }
}
    `;
export type AddPimImportMutationFn = Apollo.MutationFunction<AddPimImportMutation, AddPimImportMutationVariables>;

/**
 * __useAddPimImportMutation__
 *
 * To run a mutation, you first call `useAddPimImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPimImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPimImportMutation, { data, loading, error }] = useAddPimImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPimImportMutation(baseOptions?: Apollo.MutationHookOptions<AddPimImportMutation, AddPimImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPimImportMutation, AddPimImportMutationVariables>(AddPimImportDocument, options);
      }
export type AddPimImportMutationHookResult = ReturnType<typeof useAddPimImportMutation>;
export type AddPimImportMutationResult = Apollo.MutationResult<AddPimImportMutation>;
export type AddPimImportMutationOptions = Apollo.BaseMutationOptions<AddPimImportMutation, AddPimImportMutationVariables>;
export const PimRequestImportationUploadUrlDocument = gql`
    mutation PimRequestImportationUploadUrl($input: PimRequestImportationUploadUrlInput!) {
  pimRequestImportationUploadUrl(input: $input) {
    uploadUrl
  }
}
    `;
export type PimRequestImportationUploadUrlMutationFn = Apollo.MutationFunction<PimRequestImportationUploadUrlMutation, PimRequestImportationUploadUrlMutationVariables>;

/**
 * __usePimRequestImportationUploadUrlMutation__
 *
 * To run a mutation, you first call `usePimRequestImportationUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePimRequestImportationUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pimRequestImportationUploadUrlMutation, { data, loading, error }] = usePimRequestImportationUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePimRequestImportationUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<PimRequestImportationUploadUrlMutation, PimRequestImportationUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PimRequestImportationUploadUrlMutation, PimRequestImportationUploadUrlMutationVariables>(PimRequestImportationUploadUrlDocument, options);
      }
export type PimRequestImportationUploadUrlMutationHookResult = ReturnType<typeof usePimRequestImportationUploadUrlMutation>;
export type PimRequestImportationUploadUrlMutationResult = Apollo.MutationResult<PimRequestImportationUploadUrlMutation>;
export type PimRequestImportationUploadUrlMutationOptions = Apollo.BaseMutationOptions<PimRequestImportationUploadUrlMutation, PimRequestImportationUploadUrlMutationVariables>;
export const DeletePimImportDocument = gql`
    mutation DeletePimImport($input: DeletePimImportInput!) {
  deletePimImport(input: $input) {
    importation {
      id
      url
      status
      filename
    }
  }
}
    `;
export type DeletePimImportMutationFn = Apollo.MutationFunction<DeletePimImportMutation, DeletePimImportMutationVariables>;

/**
 * __useDeletePimImportMutation__
 *
 * To run a mutation, you first call `useDeletePimImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePimImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePimImportMutation, { data, loading, error }] = useDeletePimImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePimImportMutation(baseOptions?: Apollo.MutationHookOptions<DeletePimImportMutation, DeletePimImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePimImportMutation, DeletePimImportMutationVariables>(DeletePimImportDocument, options);
      }
export type DeletePimImportMutationHookResult = ReturnType<typeof useDeletePimImportMutation>;
export type DeletePimImportMutationResult = Apollo.MutationResult<DeletePimImportMutation>;
export type DeletePimImportMutationOptions = Apollo.BaseMutationOptions<DeletePimImportMutation, DeletePimImportMutationVariables>;
export const DeletePimSupplierImportationsDocument = gql`
    mutation DeletePimSupplierImportations($input: DeletePimSupplierImportationsInput!) {
  deletePimSupplierImportations(input: $input) {
    importations {
      id
      url
      status
      filename
    }
  }
}
    `;
export type DeletePimSupplierImportationsMutationFn = Apollo.MutationFunction<DeletePimSupplierImportationsMutation, DeletePimSupplierImportationsMutationVariables>;

/**
 * __useDeletePimSupplierImportationsMutation__
 *
 * To run a mutation, you first call `useDeletePimSupplierImportationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePimSupplierImportationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePimSupplierImportationsMutation, { data, loading, error }] = useDeletePimSupplierImportationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePimSupplierImportationsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePimSupplierImportationsMutation, DeletePimSupplierImportationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePimSupplierImportationsMutation, DeletePimSupplierImportationsMutationVariables>(DeletePimSupplierImportationsDocument, options);
      }
export type DeletePimSupplierImportationsMutationHookResult = ReturnType<typeof useDeletePimSupplierImportationsMutation>;
export type DeletePimSupplierImportationsMutationResult = Apollo.MutationResult<DeletePimSupplierImportationsMutation>;
export type DeletePimSupplierImportationsMutationOptions = Apollo.BaseMutationOptions<DeletePimSupplierImportationsMutation, DeletePimSupplierImportationsMutationVariables>;
export const CancelPimImportDocument = gql`
    mutation CancelPimImport($input: DeletePimImportInput!) {
  deletePimImport(input: $input) {
    importation {
      id
      url
      status
      filename
    }
  }
}
    `;
export type CancelPimImportMutationFn = Apollo.MutationFunction<CancelPimImportMutation, CancelPimImportMutationVariables>;

/**
 * __useCancelPimImportMutation__
 *
 * To run a mutation, you first call `useCancelPimImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPimImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPimImportMutation, { data, loading, error }] = useCancelPimImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPimImportMutation(baseOptions?: Apollo.MutationHookOptions<CancelPimImportMutation, CancelPimImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPimImportMutation, CancelPimImportMutationVariables>(CancelPimImportDocument, options);
      }
export type CancelPimImportMutationHookResult = ReturnType<typeof useCancelPimImportMutation>;
export type CancelPimImportMutationResult = Apollo.MutationResult<CancelPimImportMutation>;
export type CancelPimImportMutationOptions = Apollo.BaseMutationOptions<CancelPimImportMutation, CancelPimImportMutationVariables>;