import { ModalOpenButton, useFormatting } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { EntityNumber } from "../../../commons/EntityNumber";
import { PurchaseOrder__ContractorFragment } from "../../../main-routes/projects/ProjectPurchaseOrderPreview.generated";
import { CrmCompanyEditBranchAddressModal } from "../../crm-companies/modals/CrmCompanyEditBranchAddressModal";
import { DocumentPreviewHeader } from "../../documents/DocumentPreviewHeader";
import {
  PurchaseOrderHeader__OrderFragment,
  PurchaseOrderHeader__ProjectFragment,
  PurchaseOrderHeader__SupplierFragment,
} from "./PurchaseOrderHeader.generated";

interface Props {
  project: PurchaseOrderHeader__ProjectFragment;
  crmSupplier: PurchaseOrderHeader__SupplierFragment | undefined | null;
  author: PurchaseOrderHeader__OrderFragment["author"];
  orderNumber: string;
  orderedDate?: any;
  ownClientId: string | undefined | null;
  contractor: PurchaseOrder__ContractorFragment;
  canEdit: boolean;
  recipientActionButtons?: React.ReactNode;
}

export const PurchaseOrderHeader = ({
  project: { number: projectNumber },
  crmSupplier,
  author,
  orderNumber,
  orderedDate,
  ownClientId,
  contractor,
  canEdit,
  recipientActionButtons,
}: Props) => {
  const { t } = useTranslate("PurchaseOrders");
  const { getFormattedDate } = useFormatting();

  if (!crmSupplier) throw new Error("Supplier is missing");

  const crmSupplierAddress = crmSupplier.branchAddress;
  const contractorAddress =
    contractor?.branchAddress || contractor?.billingAddress;

  const documentData = React.useMemo(
    () => [
      {
        label: t("Project No"),
        value: <EntityNumber noLabel number={projectNumber} />,
      },
      {
        label: t("Client No"),
        value: ownClientId ? (
          <EntityNumber noLabel number={ownClientId} />
        ) : (
          "–"
        ),
      },
      {
        label: t("Order No"),
        value: <EntityNumber noLabel number={orderNumber} />,
      },
      {
        label: t("Issued on"),
        value: orderedDate ? getFormattedDate(orderedDate) : "-",
      },
    ],
    [getFormattedDate, orderNumber, orderedDate, ownClientId, projectNumber, t]
  );

  return (
    <DocumentPreviewHeader
      recipient={{
        contactName: crmSupplier.contactPerson?.fullname ?? null,
        organisationName: crmSupplier.title ?? null,
        address: crmSupplierAddress ?? null,
      }}
      recipientActionButtons={recipientActionButtons}
      recipientAddressActionButtons={
        canEdit ? (
          <ModalOpenButton
            Modal={CrmCompanyEditBranchAddressModal}
            modalProps={{ crmOrganisationId: crmSupplier.id }}
          >
            <IconButton color="secondary" size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </ModalOpenButton>
        ) : undefined
      }
      sender={{
        organisationName: contractor.title,
        contactName: author.fullname,
        contactPhones: author.phones,
        contactEmail: author.email,
        address: contractorAddress ?? null,
      }}
      documentData={documentData}
    />
  );
};
