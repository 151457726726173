import { gql, useApolloClient } from "@apollo/client";
import { Autocomplete } from "@msys/ui";
import { Grid, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useModifyItemVerticalMutation } from "./ItemVerticalMenuItem.generated";
import { useTranslate } from "@tolgee/react";
import { useVerticals } from "../../verticals/hooks/useVerticals";
import { Vertical } from "../../verticals/types";

export const ItemVerticalMenuItem = ({
  projectId,
  docId,
  itemId,
  vertical,
  onChangeVerticalRefetchQueries,
}: {
  projectId: string | null;
  docId: string;
  itemId: string;
  vertical: Vertical | null;
  onChangeVerticalRefetchQueries?: string[];
}) => {
  const { t } = useTranslate(["QuoteItem", "Global"]);
  const { verticalLabels, verticalOptions } = useVerticals();

  const verticalValues = React.useMemo(
    () => verticalOptions.map(o => o.value),
    [verticalOptions]
  );

  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [modifyItemVertical, { loading: modifyItemVerticalLoading }] =
    useModifyItemVerticalMutation({
      client,
    });

  const modifyItemType = React.useCallback(
    async (values: { vertical: Vertical | null }) => {
      try {
        await modifyItemVertical({
          variables: {
            input: {
              projectId,
              docId,
              itemId,
              vertical: values.vertical,
            },
          },
          refetchQueries: onChangeVerticalRefetchQueries,
          awaitRefetchQueries: true,
        });
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [
      projectId,
      docId,
      itemId,
      modifyItemVertical,
      enqueueSnackbar,
      onChangeVerticalRefetchQueries,
    ]
  );

  return (
    <MenuItem
      disableRipple
      onKeyDown={e => e.stopPropagation()}
      onClick={(event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
      }}
    >
      <Grid container alignItems="center" minWidth={"310px"} maxWidth={"380px"}>
        <Grid item xs={5}>
          {t("Vertical", {
            ns: "QuoteItem",
          })}
        </Grid>
        <Grid item xs={7} minWidth={"180px"}>
          <Autocomplete<Vertical, false>
            fullWidth
            disabled={modifyItemVerticalLoading}
            value={vertical}
            placeholder={t("Not set", { ns: "Global" })}
            inputLabel={""}
            size="extra-small"
            onChange={async (value: Vertical | null) => {
              await modifyItemType({
                vertical: value,
              });
            }}
            getOptionLabel={value => verticalLabels[value]}
            options={verticalValues}
            filterOptions={(options, state) =>
              options.filter(option =>
                verticalLabels[option]
                  .toLocaleLowerCase()
                  .trim()
                  .includes(state.inputValue.toLocaleLowerCase().trim())
              )
            }
          />
        </Grid>
      </Grid>
    </MenuItem>
  );
};
