import { getIsDisabled, ModalOpenButton, SplitButton } from "@msys/ui";
import { Button, Stack, StackProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { FileInput } from "../../commons/inputs/FileInput";
import { ProductData } from "./calculateProfitability";
import { VanMarckeProfitabilityModal } from "./VanMarckeProfitabilityModal";

const storeName = "vmpd"; // Van Marcke product data

interface Props {
  projectId: string;
  docId: string;
  stackProps?: StackProps;
}

export const VanMarckeProfitabilityButton = ({
  docId,
  projectId,
  stackProps,
}: Props) => {
  const { t } = useTranslate(["VanMarcke"]);

  const { hasData, uploadData, getItemData } = useIndexedDB();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const buttons = React.useMemo(
    () => [
      <ModalOpenButton
        Modal={VanMarckeProfitabilityModal}
        modalProps={{ projectId, docId, getItemData }}
      >
        <Button disabled={!hasData}>
          {t("Calculate profitability", {
            ns: "VanMarcke",
          })}
        </Button>
      </ModalOpenButton>,
      <Button onClick={() => fileInputRef.current!.click()}>
        {t("Upload data", {
          ns: "VanMarcke",
        })}
      </Button>,
    ],
    [docId, getItemData, hasData, projectId, t]
  );

  const [selectedIndex, setSelectedIndex] = React.useState(
    buttons.findIndex(button => !getIsDisabled(button))
  );

  React.useEffect(() => {
    setSelectedIndex(buttons.findIndex(button => !getIsDisabled(button)));
  }, [buttons, hasData]);

  return (
    <Stack direction={"row"} spacing={1} {...stackProps}>
      <SplitButton
        buttons={buttons}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      <FileInput
        innerRef={fileInputRef}
        accept={".csv"}
        multiple={false}
        onComplete={uploadData}
      />
    </Stack>
  );
};

function useIndexedDB() {
  const [db, setDb] = React.useState<IDBDatabase | null>(null);
  const [hasData, setHasData] = React.useState<boolean>(false);

  React.useEffect(() => {
    const DBOpenRequest = window.indexedDB.open(storeName);
    DBOpenRequest.onerror = event => {
      console.error("Error loading database!", event);
    };
    DBOpenRequest.onsuccess = event => {
      console.info("Database initialized!");
      setDb(DBOpenRequest.result);
    };
    DBOpenRequest.onupgradeneeded = event => {
      console.info("Database upgrade needed!", event);
      // @ts-ignore
      const db = event.target.result;
      if (!db) throw new Error("No database connection!");

      db.createObjectStore(storeName, {
        keyPath: "an",
      });
    };
  }, []);

  const getHasData = React.useCallback(() => {
    return new Promise<boolean>((resolve, reject) => {
      if (!db) {
        resolve(false);
      } else {
        const objectStore = db
          .transaction(storeName, "readonly")
          .objectStore(storeName);
        const request = objectStore.count();
        request.onerror = event => {
          console.error("Request error", event);
          reject(event);
        };
        request.onsuccess = event => {
          // @ts-ignore
          console.info(`Count retrieved: ${event.target?.result}`);
          // @ts-ignore
          resolve(event.target?.result > 0);
        };
      }
    });
  }, [db]);

  const uploadData = React.useCallback(
    async (file: File) => {
      const parsedData: ProductData[] = await parseTsv(file);

      return new Promise<void>((resolve, reject) => {
        if (!db) throw new Error("No database connection!");

        const transaction = db.transaction(storeName, "readwrite");
        transaction.onerror = event => {
          console.error("Transaction error", event);
          reject(event);
        };
        transaction.oncomplete = event => {
          console.info("All done!");
          resolve();
          getHasData().then(setHasData);
        };

        const objectStore = transaction.objectStore(storeName);
        parsedData.forEach(item => {
          const request = objectStore.put(item);
          request.onsuccess = event => {
            // console.info("Object added", item);
          };
        });
      });
    },
    [db, getHasData]
  );

  const getItemData = React.useCallback(
    (articleNumber: string) => {
      return new Promise<ProductData>((resolve, reject) => {
        if (!db) throw new Error("No database connection!");

        const objectStore = db
          .transaction(storeName, "readonly")
          .objectStore(storeName);
        const request = objectStore.get(articleNumber);
        request.onerror = event => {
          console.error("Request error", event);
          reject(event);
        };
        request.onsuccess = event => {
          console.info(
            `Item retrieved for article ${articleNumber}`,
            // @ts-ignore
            event.target?.result
          );
          // @ts-ignore
          resolve(event.target?.result);
        };
      });
    },
    [db]
  );

  React.useEffect(() => {
    getHasData().then(setHasData);
  }, [db, getHasData]);

  return { uploadData, getItemData, hasData };
}

const FIELD_MAP = {
  an: "Articlenumber",
  pp: "Purchase price",
};

async function parseTsv(file: File): Promise<ProductData[]> {
  const data: ProductData[] = [];

  const tsv = await file.text();

  const [head, ...tail] = tsv.split(/\r?\n/);
  const headers = head.split(";");

  const indices = {
    an: headers.indexOf(FIELD_MAP.an),
    pp: headers.indexOf(FIELD_MAP.pp),
  };
  if (Object.values(indices).some(value => value < 0))
    throw new Error("One of required columns missing");

  tail.forEach(item => {
    const values = item.split(";");

    data.push({
      an: values[indices.an],
      pp: parseFloat(values[indices.pp].replace(",", ".")),
    });
  });

  return data;
}
