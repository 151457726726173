import { notNull } from "@msys/common";
import {
  DebouncedSearchInput,
  ListHeader,
  MenuButton,
  ModalOpenButton,
  Tabs,
  useFormatting,
} from "@msys/ui";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import {
  OrganisationProjectsSorting,
  ProjectStateMachineStatus,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { RestrictedByOrganisationPermissionWithDebug } from "../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../auth/useUserData";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { useStateWithUrlParam } from "../../commons/hooks/useStateWithUrlParam";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { useCustomFieldConfig } from "../../features/custom-fields/useCustomFieldConfig";
import { ImportCustomGaebP84Button } from "../../features/importExport/gaebImport/ImportCustomGaebP84Button";
import { ImportXiopdButton } from "../../features/importExport/xiopdImport/ImportXiopdButton";
import { ProjectsFilterModal } from "../../features/projects/ProjectsFilterModal";
import { ProjectsKanban } from "../../features/projects/ProjectsKanban";
import {
  DEFAULT_SORTING,
  ProjectsList,
  type Filters,
  type QuickFilterValue,
} from "../../features/projects/ProjectsList";
import { CreateProjectOrTicketButton } from "../../features/projects/buttons/CreateProjectOrTicketButton";
import { ProjectListItemDisplayConfig } from "../../features/projects/components/ProjectListItem";
import {
  ProjectListItemVisibilitySettingsModal,
  defaultListItemDisplayConfig,
} from "../../features/projects/components/ProjectListItemVisibilitySettingsModal";
import { renderProjectsFilterChips } from "../../features/projects/renderProjectsFilterChips";
import { ImportS4HanaSalesQuotationButton } from "../../features/sap-s4-hana/ImportS4HanaSalesQuotationButton";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { useUserPreference } from "../../features/users/useUserPreference";
import { useVisibilityStore } from "../../features/users/useVisibilityStore";

type ImportVisibilityKeys = "gaeb" | "xiopd";

const DEFAULT_ITEMS_PER_PAGE = 25;
const ALLOWED_VIEWS: CollectionView[] = ["table", "list", "kanban"];

interface Props {
  submenuItems: PageTopbarItem[];
}

export const ProjectsCurrent = ({ submenuItems }: Props) => {
  const { t } = useTranslate(["Projects", "Tickets", "Global"]);
  const viewer = useUserData().currentUser!;
  const { getFormattedPrice } = useFormatting();

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "projects-current",
    "table",
    "list"
  );

  const quickFilterOptions = React.useMemo(
    (): { label: string; value: QuickFilterValue }[] => [
      { value: "ALL", label: t("All", { ns: "Global" }) },
      { value: "PROJECTS", label: t("Projects", { ns: "Projects" }) },
      { value: "TICKETS", label: t("Tickets", { ns: "Tickets" }) },
    ],
    [t]
  );

  const quickFilterSettings: Record<QuickFilterValue, Filters> = React.useMemo(
    () => ({
      ALL: {},
      PROJECTS: {
        filterKind: "DEFAULT",
      },
      TICKETS: {
        filterKind: "TICKET",
      },
    }),
    []
  );

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    {},
    DEFAULT_SORTING,
    DEFAULT_ITEMS_PER_PAGE
  );

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "quickfilter",
    "ALL",
    toRemoveParams
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const allowedStates: ProjectStateMachineStatus[] = viewer.organisation
    .isClientOrganisation
    ? ["contracted", "opportunity"]
    : ["contracted"];

  const variables = {
    ...QUERY_BASE_VARIABLES,
    ...quickFilterSettings[quickFilter],
    ...filters,
    filterIncludeState: filters.filterIncludeState?.length
      ? filters.filterIncludeState
      : allowedStates,
    sorting,
  };

  const { customFieldConfig: customFieldConfigs } =
    useCustomFieldConfig("Project");

  const stateStore = useDataGridStateStore("ProjectsCurrent");

  const importVisibilityStore = useVisibilityStore<ImportVisibilityKeys>(
    "ProjectsCurrent-Import"
  );
  const listItemDisplayConfigStore =
    useUserPreference<ProjectListItemDisplayConfig>(
      `DisplayConfig-ProjectsCurrent`,
      defaultListItemDisplayConfig
    );

  const menuItems = !importVisibilityStore.loading
    ? [
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-gaeb"
        >
          {importVisibilityStore.value.gaeb !== false ? (
            <ImportCustomGaebP84Button
              projectParams={{ isTentative: false }}
              refetchQueries={[namedOperations.Query.ProjectsList]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        <RestrictedByOrganisationPermissionWithDebug
          permission="CREATE_PROJECT"
          key="import-xiopd"
        >
          {importVisibilityStore.value.xiopd !== false ? (
            <ImportXiopdButton
              projectParams={{ isTentative: false }}
              refetchQueries={[namedOperations.Query.ProjectsList]}
            />
          ) : null}
        </RestrictedByOrganisationPermissionWithDebug>,
        viewer.organisation.enabledIntegrationIds.includes("sap_s4hana") ? (
          <RestrictedByOrganisationPermissionWithDebug
            permission="CREATE_PROJECT"
            key="import-s4-hana-salesquoation"
          >
            <ImportS4HanaSalesQuotationButton
              projectId={null}
              refetchQueries={[namedOperations.Query.ProjectsList]}
            />
          </RestrictedByOrganisationPermissionWithDebug>
        ) : null,
      ].filter(notNull)
    : [];

  return (
    <Page
      title={t("Current", { ns: "Projects" })}
      subtitle={t("Projects", { ns: "Projects" })}
      submenuItems={submenuItems}
    >
      <PageContainer $fullHeight>
        <ListHeader
          // hideTitleOnMobile
          // title={t("My Projects", { ns: "Projects" })}
          QuickFilter={
            viewer.organisation.isCraftsmanOrganisation ? (
              <Tabs
                useSelectOnMobile
                options={quickFilterOptions}
                value={quickFilter}
                onChange={(newQuickFilter: QuickFilterValue) => {
                  setQuickFilter(newQuickFilter);
                }}
              />
            ) : undefined
          }
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS.filter(view => {
                if (
                  viewer.organisation.isClientOrganisation &&
                  view === "kanban"
                )
                  return false;
                return true;
              })}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="CREATE_PROJECT">
              <CreateProjectOrTicketButton pageName="Projects" type="button" />
            </RestrictedByOrganisationPermissionWithDebug>
          }
          FilterButton={
            <ModalOpenButton
              Modal={ProjectsFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                customFieldConfigs,
                allowedStates,
                showPhasesFilter: activeView !== "kanban",
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={(newValue: string) => {
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }));
              }}
            />
          }
          FilterChips={renderProjectsFilterChips({
            t,
            filters,
            setFilters,
            getFormattedPrice,
            allowedStates,
          })}
          ActionButtons={
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_ORG">
              <ModalOpenButton
                Modal={ProjectListItemVisibilitySettingsModal}
                modalProps={{
                  importVisibilityStore,
                  listItemDisplayConfigStore,
                  labels: {
                    xiopd: t("{fileFormat} files", {
                      ns: "Global",
                      fileFormat: "xi:opd",
                    }),
                    gaeb: t("{fileFormat} files", {
                      ns: "Global",
                      fileFormat: "GAEB",
                    }),
                  },
                }}
              >
                <IconButton size="small" color="primary">
                  <SettingsIcon />
                </IconButton>
              </ModalOpenButton>
            </RestrictedByOrganisationPermissionWithDebug>
          }
          MenuButton={
            menuItems.length > 0 ? (
              <MenuButton>{menuItems}</MenuButton>
            ) : undefined
          }
          mb={2}
        />
        {activeView === "kanban" ? (
          <ProjectsKanban
            variables={variables}
            displayConfig={listItemDisplayConfigStore.value}
          />
        ) : (
          <ProjectsList
            sorting={sorting}
            setSorting={setSorting}
            activeView={activeView}
            pathToPage={"/projects/current"}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            quickFilter={quickFilter}
            showStatus={allowedStates.length > 1}
            showPhase={true}
            stateStore={stateStore}
            variables={variables}
            displayConfig={listItemDisplayConfigStore.value}
          />
        )}
      </PageContainer>
    </Page>
  );
};
