import { ModalOpenButton } from "@msys/ui";
import { AddContactRelationshipModal } from "../../contact-links/AddContactRelationshipModal";
import React from "react";
import { useApolloClient } from "@apollo/client";
import {
  ProjectContactLinksFragment,
  useProjectAddCrmOrganisationLinkMutation,
  useProjectAddCrmUserLinkMutation,
} from "../boxes/ProjectOverviewContactsBox.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  project: { id: string; crmLinks: Array<ProjectContactLinksFragment> };
  Button: React.ReactElement;
  refetchQueries?: string[];
  handleComplete?: () => void;
}

export const AddProjectContactRelationshipButton = ({
  project,
  Button,
  refetchQueries,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["CrmUsers"]);

  const client = useApolloClient();
  const [addUserLink, { loading: addUserLinkLoading }] =
    useProjectAddCrmUserLinkMutation({
      client,
      refetchQueries,
      awaitRefetchQueries: true,
    });
  const [addOrganisationLink, { loading: addOrganisationLinkLoading }] =
    useProjectAddCrmOrganisationLinkMutation({
      client,
      refetchQueries,
      awaitRefetchQueries: true,
    });

  return (
    <ModalOpenButton
      Modal={AddContactRelationshipModal}
      modalProps={{
        title: t("Add contact to project", {
          ns: "CrmUsers",
        }),
        existingContactLinks: project.crmLinks,
        handleComplete: async (contactType, contactId, role) => {
          if (contactType === "user") {
            await addUserLink({
              variables: {
                input: {
                  projectId: project.id,
                  crmUserId: contactId,
                  linkAs: role,
                  data: {},
                },
              },
            });
          } else if (contactType === "organisation") {
            await addOrganisationLink({
              variables: {
                input: {
                  projectId: project.id,
                  crmOrganisationId: contactId,
                  linkAs: role,
                  data: {},
                },
              },
            });
          } else {
            throw new Error("Invalid contact id");
          }
          handleComplete?.();
        },
      }}
      disabled={addUserLinkLoading || addOrganisationLinkLoading}
    >
      {Button}
    </ModalOpenButton>
  );
};
