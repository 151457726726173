import type { ActionEventArgs } from "@syncfusion/ej2-schedule";
import { useCallback } from "react";
import { useLatest } from "react-use";

type ActionCallback = (data: any) => Promise<void>;

export const actionBegin =
  (onChangeCb: ActionCallback, onRemoveCb?: ActionCallback) =>
  async (args?: ActionEventArgs) => {
    if (!args) return;

    if (args.requestType === "eventCreate") {
      args.cancel = true;
    } else if (args.requestType === "eventChange") {
      await onChangeCb(args.data as any);
    } else if (args.requestType === "eventRemove") {
      await onRemoveCb?.(args.data as any);
    }
  };

export const useActionBegin = (
  onChangeCb: ActionCallback,
  onRemoveCb?: ActionCallback
) => {
  const onChangeCbLatest = useLatest(onChangeCb);
  const onRemoveCbLatest = useLatest(onRemoveCb);

  return useCallback(async (args?: ActionEventArgs) => {
    if (!args) return;
    if (args.requestType === "eventCreate") {
      args.cancel = true;
    } else if (args.requestType === "eventChange") {
      await onChangeCbLatest.current(args.data as any);
    } else if (args.requestType === "eventRemove") {
      await onRemoveCbLatest.current?.(args.data as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
