import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { CardContainer, ErrorMessage, Modal, Select } from "@msys/ui";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikHelpers } from "formik";
import { difference, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import CheckboxTreeField from "../../commons/form-fields/CheckboxTreeField";
import {
  useImportRequirementMutation,
  useQuoteImportRequirementModalQuery,
  useQuoteImportRequirementsModalQuery,
} from "./QuoteImportRequirementsModal.generated";
import { useModifyQuoteMutation } from "./Quotes.generated";

interface Props {
  projectId: string;
  quoteId: string;
  handleClose: () => void;
  handleComplete: () => Promise<void> | void;
}

interface FormValues {
  quoteItemIds: string[];
}

export const QuoteImportRequirementsModal = ({
  projectId,
  quoteId,
  handleClose,
  handleComplete,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate([
    "QuoteEditImportRequirements",
    "QuoteEdit",
    "Global",
  ]);

  const client = useApolloClient();
  const [modifyQuote] = useModifyQuoteMutation({ client });
  const [importRequirement] = useImportRequirementMutation({
    client,
  });
  const query = useQuoteImportRequirementsModalQuery({
    client,
    variables: {
      projectId,
      quoteId,
    },
    fetchPolicy: "network-only",
  });
  const quote = getDataOrNull(query.data?.quote)?.quote;
  const requirements = React.useMemo(
    () =>
      getDataOrNull(query.data?.projectRequirements)?.edges?.map(e => e.node) ??
      [],
    [getDataOrNull(query.data?.projectRequirements)?.edges]
  );

  const [requirementId, setRequirementId] = React.useState<string | null>(null);

  const requirementQuery = useQuoteImportRequirementModalQuery({
    client,
    variables: {
      projectId,
      requirementId: requirementId!,
    },
    skip: !requirementId,
    fetchPolicy: "network-only",
  });
  const requirementDoc = getDataOrNull(
    requirementQuery?.data?.requirement
  )?.requirement;

  const formId = React.useMemo(() => uniqueId(), []);

  React.useEffect(() => {
    if (!query?.loading && !query?.error && requirements.length === 1) {
      setRequirementId(requirements[0].id);
    }
  }, [query?.loading, query?.error, requirements]);

  if (!quote) return null;

  const initialValues: FormValues = {
    quoteItemIds: [],
  };

  const handleSubmit = async (
    values: FormValues,
    formikActions: FormikHelpers<FormValues>
  ) => {
    if (values.quoteItemIds.length === 0) return;
    if (!requirementDoc) return;

    const requirementAllItems = requirementDoc.items;

    try {
      // await createQuoteItemAndChildren(
      //   requirementDocRootItem,
      //   rootItem.id,
      //   requirementAllItems,
      //   values.quoteItemIds
      // );

      // if (!quote.quoteForRequirement) {
      //   await modifyQuote({
      //     variables: {
      //       input: {
      //         projectId,
      //         docId: quoteId,
      //         values: {
      //           quoteForRequirementId: values.requirementId,
      //         },
      //       },
      //     },
      //   });
      // }

      await importRequirement({
        variables: {
          input: {
            projectId,
            sourceRequirementId: requirementDoc.id,
            destQuoteId: quoteId,
            omitSourceItemIds: difference(
              requirementAllItems.map(i => i.id),
              values.quoteItemIds
            ),
          },
        },
      });

      await modifyQuote({
        variables: {
          input: {
            projectId,
            docId: quoteId,
            values: {
              quoteForRequirementId: requirementId,
            },
          },
        },
      });

      enqueueSnackbar(
        t("All items have been successfully imported", {
          ns: "QuoteEditImportRequirements",
        })
      );

      await handleComplete();
      handleClose();
    } catch (error) {
      formikActions.setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => {
        const requirementDocAllItems = requirementDoc?.items;
        return (
          <Modal
            title={t("Import requirements", {
              ns: "QuoteEdit",
            })}
            dialogProps={{ maxWidth: "sm" }}
            actionButtons={
              requirements.length === 0
                ? []
                : [
                    {
                      label: t("Cancel", {
                        ns: "Global",
                      }),
                      handleClick: handleClose,
                      buttonProps: { variant: "text" },
                    },
                    {
                      label: t("Import", {
                        ns: "QuoteEditImportRequirements",
                      }),
                      buttonProps: {
                        loading: formikProps.isSubmitting,
                        form: formId,
                        type: "submit",
                        disabled: !formikProps.values.quoteItemIds.length,
                      },
                    },
                  ]
            }
            handleClose={handleClose}
            isLoading={query.loading}
          >
            {requirements.length === 0 ? (
              <ErrorMessage
                message={t("There are no requirements", {
                  ns: "QuoteEditImportRequirements",
                })}
              />
            ) : (
              <Form id={formId}>
                <Stack direction="column" spacing={1}>
                  <Select
                    value={requirementId}
                    label={t("Requirements", {
                      ns: "QuoteEditImportRequirements",
                    })}
                    options={requirements.map(r => ({
                      label: r.title,
                      value: r.id,
                    }))}
                    onChange={newRequirementId => {
                      formikProps.setFieldValue("quoteItemIds", []);
                      setRequirementId(newRequirementId);
                    }}
                    fullWidth
                  />
                  {requirementDoc && requirementDocAllItems && (
                    <CardContainer>
                      <Box px={2} py={1}>
                        <CheckboxTreeField
                          name="quoteItemIds"
                          allDocItems={requirementDocAllItems}
                          values={formikProps.values.quoteItemIds}
                          setValues={quoteItemIds =>
                            formikProps.setFieldValue(
                              "quoteItemIds",
                              quoteItemIds
                            )
                          }
                        />
                      </Box>
                    </CardContainer>
                  )}
                </Stack>
              </Form>
            )}
          </Modal>
        );
      }}
    </Formik>
  );
};
