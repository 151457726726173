import { CollapseSection } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { MaterialMarginRangesField } from "./MaterialMarginRangesField";

interface Props {
  value: { priceFrom: number; priceTo: number; materialMargin: number }[];
  name: string;
  title: string;
  disabled?: boolean;
  autoSubmit?: boolean;
  defaultMaterialMargin?: number;
}

export const MaterialMarginRangesSection = ({
  value,
  name,
  title,
  disabled,
  autoSubmit = true,
  defaultMaterialMargin,
}: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const addButtonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <CollapseSection
      title={title}
      titleVariant="h4"
      isInitiallyExpanded={value.length > 0}
      itemCount={value.length}
      hideItemCountOnExpand={true}
      ActionButtons={
        !disabled ? (
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              addButtonRef.current?.click();
            }}
          >
            <AddIcon />
          </IconButton>
        ) : undefined
      }
    >
      <MaterialMarginRangesField
        name={name}
        value={value}
        addButtonRef={addButtonRef}
        addButtonLabel={t("Add new range", {
          ns: "OrganisationSettings",
        })}
        disabled={disabled}
        autoSubmit={autoSubmit}
        defaultMaterialMargin={defaultMaterialMargin}
      />
    </CollapseSection>
  );
};
