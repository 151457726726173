import { gql } from "@apollo/client";
import { ModalOpenButton } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import React, { CSSProperties, FC } from "react";
import { CorrectWorkSessionButton_AllDocItemFragment } from "./CorrectWorkSessionButton.generated";
import { useTranslate } from "@tolgee/react";
import { CorrectTaskWorkSessionModal } from "../modals/CorrectTaskWorkSessionModal";

export const CorrectWorkSessionButton = ({
  itemId,
  docId,
  projectId,
  correctionDate,
  timeTrackingRequired,
  style,
}: {
  projectId: string;
  itemId: string;
  docId: string;
  correctionDate?: any;
  timeTrackingRequired: boolean;
  style?: CSSProperties;
}) => {
  const { t } = useTranslate("Task");
  return (
    <ModalOpenButton
      Modal={CorrectTaskWorkSessionModal}
      modalProps={{
        itemId,
        docId,
        projectId,
        correctionDate,
        timeTrackingRequired,
      }}
    >
      <Tooltip title={t("Change total time")}>
        <IconButton
          style={{ width: 30, height: 30, flexShrink: 0, ...style }}
          color="secondary"
          size="small"
        >
          <EditIcon style={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
    </ModalOpenButton>
  );
};
