import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import React from "react";
import { useProjectIsOpportunityLazyQuery } from "./useProjectIsOpportunity.generated";

const isProjectIdDefined = (projectId: string) =>
  projectId !== "current" && projectId !== "past";

export const useProjectIsOpportunity = (
  projectId: string | undefined
): { projectLoading: boolean; projectIsOpportunity: boolean } => {
  const client = useApolloClient();

  const [fetchProject, projectQuery] = useProjectIsOpportunityLazyQuery({
    client,
  });

  React.useEffect(() => {
    if (projectId && isProjectIdDefined(projectId)) {
      fetchProject({ variables: { projectId } });
    }
  }, [projectId, fetchProject]);

  const project = getDataOrNull(projectQuery.data?.project)?.project;

  return !projectId || !isProjectIdDefined(projectId)
    ? { projectLoading: false, projectIsOpportunity: false }
    : {
        projectLoading: projectQuery.loading || !projectQuery.data,
        projectIsOpportunity:
          project?.state === "opportunity" ||
          project?.state === "contractee_declined" ||
          project?.state === "contractor_declined",
      };
};
