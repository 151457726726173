import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useUserData } from "../../auth/useUserData";
import { PageNotFound } from "../../commons/PageNotFound";
import { PageTopbarItem } from "../../commons/layout/Page";
import { InvoiceIcon } from "../../features/invoices/InvoiceIcon";
import { PurchaseOrderIcon } from "../../features/purchase-orders/PurchaseOrderIcon";
import { QuoteIcon } from "../../features/quotes/QuoteIcon";
import { Invoices } from "./Invoices";
import { PurchaseOrders } from "./PurchaseOrders";
import { Quotes } from "./Quotes";
import { RestrictedByCapability } from "../../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermission } from "../../auth/RestrictedByOrganisationPermission";

type PageSubmenuItem = PageTopbarItem;

export const DocumentsRoutes = () => {
  const { t } = useTranslate("ProjectPageTopbar");
  const viewer = useUserData().currentUser!;

  const submenuItems = React.useMemo(() => {
    const items: PageSubmenuItem[] = [
      {
        name: "documents-quotes",
        label: t("Quotes"),
        icon: <QuoteIcon />,
        path: "/documents/quotes",
        permission: "READ_QUOTES",
      },
      {
        name: "documents-purchase-orders",
        label: t("Purchase Orders"),
        icon: <PurchaseOrderIcon />,
        path: "/documents/purchase-orders",
        capability: "ORDERING",
        permission: "MANAGE_QUOTES",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      {
        name: "documents-invoices",
        label: t("Invoices"),
        icon: <InvoiceIcon />,
        path: "/documents/invoices",
        permission: "READ_INVOICES",
      },
    ];

    return items.filter(item => !item.isHidden);
  }, [t, viewer]);

  return (
    <Routes>
      <Route index element={<Navigate to="quotes" replace />} />
      <Route
        path={`quotes`}
        element={
          <RestrictedByOrganisationPermission permission="READ_QUOTES">
            <Quotes submenuItems={submenuItems} />
          </RestrictedByOrganisationPermission>
        }
      />
      <Route
        path={`invoices`}
        element={
          <RestrictedByOrganisationPermission permission="READ_INVOICES">
            <Invoices submenuItems={submenuItems} />
          </RestrictedByOrganisationPermission>
        }
      />
      <Route
        path={`purchase-orders`}
        element={
          <RestrictedByCapability capability="ORDERING">
            <RestrictedByOrganisationPermission permission="MANAGE_QUOTES">
              <PurchaseOrders submenuItems={submenuItems} />
            </RestrictedByOrganisationPermission>
          </RestrictedByCapability>
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
