import { gql, useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Divider, IconButton } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { omit, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { CheckboxField } from "../../commons/form-fields/CheckboxField";
import { SelectField } from "../../commons/form-fields/SelectField";
import { Stack } from "../../commons/layout/Stack";
import {
  CustomFieldDataType,
  CustomFieldObjectType,
} from "../../../clients/graphqlTypes";
import { useSetCustomFieldConfigMutation } from "./EditCustomFieldConfigModal.generated";
import { useCustomFieldConfigQuery } from "./customFieldConfigs.generated";
import { useTranslate } from "@tolgee/react";
import { useCustomFieldDataTypes } from "./useCustomFieldDataTypes";
import { useCustomFieldObjectTypes } from "./useCustomFieldObjectTypes";

interface FormValues {
  customFieldConfigs: {
    objectType: CustomFieldObjectType;
    key: string;
    dataType: CustomFieldDataType;
    group: string;
    allowedValues: string;
    showAsColumnInList: boolean;
    showInCreateForm: boolean;
    isUnique: boolean;
    mandatory: boolean;
  }[];
}

export const EditCustomFieldConfigModal = ({
  handleClose,
  refetchQueries,
}: {
  handleClose: () => void;
  refetchQueries: string[];
}) => {
  const { t } = useTranslate(["CustomFields", "Global"]);
  const { customFieldObjectTypes, customFieldObjectTypeLabels } =
    useCustomFieldObjectTypes();
  const { customFieldDataTypes, customFieldDataTypeLabels } =
    useCustomFieldDataTypes();
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();
  const customFieldConfigQuery = useCustomFieldConfigQuery({ client });
  const [setCustomFieldConfig] = useSetCustomFieldConfigMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries,
  });

  const formId = React.useMemo(() => uniqueId(), []);

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        customFieldConfigs: Yup.array().of(
          Yup.object().shape({
            objectType: Yup.string()
              .label(
                t("Page", {
                  ns: "CustomFields",
                })
              )
              .required(),
            key: Yup.string()
              .label(
                t("Field label", {
                  ns: "CustomFields",
                })
              )
              .required(),
            dataType: Yup.string()
              .label(
                t("Field type", {
                  ns: "CustomFields",
                })
              )
              .required(),
            group: Yup.string().label(
              t("Category", {
                ns: "CustomFields",
              })
            ),
          })
        ),
      }),
    [t]
  );

  if (!customFieldConfigQuery.data) return null;
  const customFieldConfig = customFieldConfigQuery.data.customFieldConfig;

  return (
    <Modal
      title={t("Custom fields configurator", {
        ns: "CustomFields",
      })}
      handleClose={handleClose}
      maxWidth="xl"
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: { variant: "text" },
        },
        {
          label: t("Save", {
            ns: "Global",
          }),
          buttonProps: {
            form: formId,
            type: "submit",
          },
        },
      ]}
    >
      <Formik<FormValues>
        initialValues={{
          customFieldConfigs: customFieldConfig.map(field => {
            return { ...omit(field, ["id", "__typename"]) };
          }),
        }}
        validationSchema={validationSchema}
        onSubmit={async values => {
          try {
            await setCustomFieldConfig({
              variables: {
                input: values.customFieldConfigs.map(e => ({
                  ...e,
                  allowedValues:
                    e.dataType !== "t_text"
                      ? ""
                      : e.allowedValues
                          .split("\n")
                          .filter(Boolean)
                          .join("\n")
                          .toLocaleLowerCase(),
                })),
              },
            });
            enqueueSnackbar(
              t("Custom field config set", {
                ns: "CustomFields",
              })
            );
            handleClose();
          } catch (error) {
            console.error(error);
            enqueueSnackbar(
              t("Failed to set custom field config", {
                ns: "CustomFields",
              }),
              {
                variant: "error",
              }
            );
          }
        }}
      >
        {({ values }) => (
          <Form id={formId}>
            <FieldArray
              name="customFieldConfigs"
              render={arrayHelpers => (
                <Stack flexDirection="column">
                  {values.customFieldConfigs.map((field, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <Divider />}
                      <Stack alignItems="center">
                        <Stack flexDirection="column" flex="1">
                          <Stack>
                            <SelectField
                              name={`customFieldConfigs.${index}.objectType`}
                              label={t("Component", {
                                ns: "CustomFields",
                              })}
                              options={customFieldObjectTypes.map(value => ({
                                label: customFieldObjectTypeLabels[value],
                                value,
                              }))}
                              required
                            />
                            <Field
                              name={`customFieldConfigs.${index}.key`}
                              label={t("Field label", {
                                ns: "CustomFields",
                              })}
                              component={TextField}
                              required
                            />
                            <SelectField
                              name={`customFieldConfigs.${index}.dataType`}
                              label={t("Field type", {
                                ns: "CustomFields",
                              })}
                              options={customFieldDataTypes.map(value => ({
                                label: customFieldDataTypeLabels[value],
                                value,
                              }))}
                              required
                            />
                            {field.dataType === "t_text" && (
                              <Field
                                component={TextField}
                                name={`customFieldConfigs.${index}.allowedValues`}
                                label={t("Picklist options", {
                                  ns: "CustomFields",
                                })}
                                multiline
                                rows={3}
                                helperText={t("Press Enter to separate", {
                                  ns: "CustomFields",
                                })}
                              />
                            )}
                            <Field
                              name={`customFieldConfigs.${index}.group`}
                              label={t("Category", {
                                ns: "CustomFields",
                              })}
                              component={TextField}
                            />
                            <CheckboxField
                              name={`customFieldConfigs.${index}.showAsColumnInList`}
                              label={"show as column"}
                            />
                            <CheckboxField
                              name={`customFieldConfigs.${index}.showInCreateForm`}
                              label={"in create form"}
                            />
                            {/* <CheckboxField
                              name={`customFieldConfigs.${index}.isUnique`}
                              label={"is unique"}
                            /> */}
                            {/* <CheckboxField
                              name={`customFieldConfigs.${index}.mandatory`}
                              label={"mandatory"}
                            /> */}
                          </Stack>
                        </Stack>
                        <IconButton
                          size="small"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </React.Fragment>
                  ))}
                  <Stack>
                    <Button
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={() =>
                        arrayHelpers.push({
                          objectType: "Project",
                          key: "",
                          dataType: "t_text",
                          group: "",
                          allowedValues: "",
                          showAsColumnInList: false,
                          showInCreateForm: false,
                          isUnique: false,
                          mandatory: false,
                        })
                      }
                    >
                      {t("Add new field", {
                        ns: "CustomFields",
                      })}
                    </Button>
                  </Stack>
                </Stack>
              )}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
