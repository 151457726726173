/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PurchaseOrderEditQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  orderId: Types.Scalars['ID']['input'];
}>;


export type PurchaseOrderEditQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, order?: { __typename: 'Order', id: string, number: string, orderedDate?: any | null, supplier?: { __typename: 'Company', id: string } | null, items: Array<{ __typename: 'OrderItem', id: string, originProductSupplierId?: string | null, originProductArticleNumber?: string | null, originQuoteItemId?: string | null, quantityOrdered: number, unitPrice: number }> } | null } | null } };

export type PurchaseOrderEdit_OrderItemFragment = { __typename: 'OrderItem', id: string, originProductSupplierId?: string | null, originProductArticleNumber?: string | null, originQuoteItemId?: string | null, quantityOrdered: number, unitPrice: number };

export type AddPurchaseOrderItemsMutationVariables = Types.Exact<{
  input: Types.AddOrderItemsInput;
}>;


export type AddPurchaseOrderItemsMutation = { addOrderItems: { __typename: 'AddOrderItemsResult', order: { __typename: 'Order', id: string } } };

export type ModifyPurchaseOrderItemMutationVariables = Types.Exact<{
  input: Types.ModifyOrderItemInput;
}>;


export type ModifyPurchaseOrderItemMutation = { modifyOrderItem: { __typename: 'ModifyOrderItemResult', order: { __typename: 'Order', id: string } } };

export type RemovePurchaseOrderItemsMutationVariables = Types.Exact<{
  input: Types.RemoveOrderItemsInput;
}>;


export type RemovePurchaseOrderItemsMutation = { removeOrderItems: { __typename: 'RemoveOrderItemsResult', order: { __typename: 'Order', id: string } } };

export type ModifyPurchaseOrderMutationVariables = Types.Exact<{
  input: Types.ModifyOrderInput;
}>;


export type ModifyPurchaseOrderMutation = { modifyOrder: { __typename: 'ModifyOrderResult', order: { __typename: 'Order', id: string, deliveryAddressType: Types.DeliveryAddressType, supplier?: { __typename: 'Company', id: string } | null, deliveryAddress?: { __typename: 'Address' } | null } } };

export type DeletePurchaseOrderMutationVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type DeletePurchaseOrderMutation = { deleteOrder: { __typename: 'DeleteOrderResult', ok: boolean } };

export type PurchaseOrderEditItemQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  orderId: Types.Scalars['ID']['input'];
}>;


export type PurchaseOrderEditItemQuery = { project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, title: string, viewerPermissions: Array<Types.PermissionName>, order?: { __typename: 'Order', id: string, number: string, orderedDate?: any | null, supplier?: { __typename: 'Company', id: string } | null, items: Array<{ __typename: 'OrderItem', id: string, originProductSupplierId?: string | null, originProductArticleNumber?: string | null, originQuoteItemId?: string | null, quantityOrdered: number, unitPrice: number }> } | null } | null } };

export const PurchaseOrderEdit_OrderItemFragmentDoc = gql`
    fragment PurchaseOrderEdit_OrderItem on OrderItem {
  id
  originProductSupplierId
  originProductArticleNumber
  originQuoteItemId
  quantityOrdered
  unitPrice
}
    `;
export const PurchaseOrderEditDocument = gql`
    query PurchaseOrderEdit($projectId: ID!, $orderId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        order(id: $orderId) {
          id
          number
          orderedDate
          supplier {
            id
          }
          items {
            id
            ...PurchaseOrderEdit_OrderItem
          }
        }
      }
    }
  }
}
    ${PurchaseOrderEdit_OrderItemFragmentDoc}`;

/**
 * __usePurchaseOrderEditQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderEditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderEditQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function usePurchaseOrderEditQuery(baseOptions: Apollo.QueryHookOptions<PurchaseOrderEditQuery, PurchaseOrderEditQueryVariables> & ({ variables: PurchaseOrderEditQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrderEditQuery, PurchaseOrderEditQueryVariables>(PurchaseOrderEditDocument, options);
      }
export function usePurchaseOrderEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrderEditQuery, PurchaseOrderEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrderEditQuery, PurchaseOrderEditQueryVariables>(PurchaseOrderEditDocument, options);
        }
export function usePurchaseOrderEditSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PurchaseOrderEditQuery, PurchaseOrderEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PurchaseOrderEditQuery, PurchaseOrderEditQueryVariables>(PurchaseOrderEditDocument, options);
        }
export type PurchaseOrderEditQueryHookResult = ReturnType<typeof usePurchaseOrderEditQuery>;
export type PurchaseOrderEditLazyQueryHookResult = ReturnType<typeof usePurchaseOrderEditLazyQuery>;
export type PurchaseOrderEditSuspenseQueryHookResult = ReturnType<typeof usePurchaseOrderEditSuspenseQuery>;
export type PurchaseOrderEditQueryResult = Apollo.QueryResult<PurchaseOrderEditQuery, PurchaseOrderEditQueryVariables>;
export const AddPurchaseOrderItemsDocument = gql`
    mutation AddPurchaseOrderItems($input: AddOrderItemsInput!) {
  addOrderItems(input: $input) {
    order {
      id
    }
  }
}
    `;
export type AddPurchaseOrderItemsMutationFn = Apollo.MutationFunction<AddPurchaseOrderItemsMutation, AddPurchaseOrderItemsMutationVariables>;

/**
 * __useAddPurchaseOrderItemsMutation__
 *
 * To run a mutation, you first call `useAddPurchaseOrderItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPurchaseOrderItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPurchaseOrderItemsMutation, { data, loading, error }] = useAddPurchaseOrderItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPurchaseOrderItemsMutation(baseOptions?: Apollo.MutationHookOptions<AddPurchaseOrderItemsMutation, AddPurchaseOrderItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPurchaseOrderItemsMutation, AddPurchaseOrderItemsMutationVariables>(AddPurchaseOrderItemsDocument, options);
      }
export type AddPurchaseOrderItemsMutationHookResult = ReturnType<typeof useAddPurchaseOrderItemsMutation>;
export type AddPurchaseOrderItemsMutationResult = Apollo.MutationResult<AddPurchaseOrderItemsMutation>;
export type AddPurchaseOrderItemsMutationOptions = Apollo.BaseMutationOptions<AddPurchaseOrderItemsMutation, AddPurchaseOrderItemsMutationVariables>;
export const ModifyPurchaseOrderItemDocument = gql`
    mutation ModifyPurchaseOrderItem($input: ModifyOrderItemInput!) {
  modifyOrderItem(input: $input) {
    order {
      id
    }
  }
}
    `;
export type ModifyPurchaseOrderItemMutationFn = Apollo.MutationFunction<ModifyPurchaseOrderItemMutation, ModifyPurchaseOrderItemMutationVariables>;

/**
 * __useModifyPurchaseOrderItemMutation__
 *
 * To run a mutation, you first call `useModifyPurchaseOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPurchaseOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPurchaseOrderItemMutation, { data, loading, error }] = useModifyPurchaseOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyPurchaseOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<ModifyPurchaseOrderItemMutation, ModifyPurchaseOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyPurchaseOrderItemMutation, ModifyPurchaseOrderItemMutationVariables>(ModifyPurchaseOrderItemDocument, options);
      }
export type ModifyPurchaseOrderItemMutationHookResult = ReturnType<typeof useModifyPurchaseOrderItemMutation>;
export type ModifyPurchaseOrderItemMutationResult = Apollo.MutationResult<ModifyPurchaseOrderItemMutation>;
export type ModifyPurchaseOrderItemMutationOptions = Apollo.BaseMutationOptions<ModifyPurchaseOrderItemMutation, ModifyPurchaseOrderItemMutationVariables>;
export const RemovePurchaseOrderItemsDocument = gql`
    mutation RemovePurchaseOrderItems($input: RemoveOrderItemsInput!) {
  removeOrderItems(input: $input) {
    order {
      id
    }
  }
}
    `;
export type RemovePurchaseOrderItemsMutationFn = Apollo.MutationFunction<RemovePurchaseOrderItemsMutation, RemovePurchaseOrderItemsMutationVariables>;

/**
 * __useRemovePurchaseOrderItemsMutation__
 *
 * To run a mutation, you first call `useRemovePurchaseOrderItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePurchaseOrderItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePurchaseOrderItemsMutation, { data, loading, error }] = useRemovePurchaseOrderItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePurchaseOrderItemsMutation(baseOptions?: Apollo.MutationHookOptions<RemovePurchaseOrderItemsMutation, RemovePurchaseOrderItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePurchaseOrderItemsMutation, RemovePurchaseOrderItemsMutationVariables>(RemovePurchaseOrderItemsDocument, options);
      }
export type RemovePurchaseOrderItemsMutationHookResult = ReturnType<typeof useRemovePurchaseOrderItemsMutation>;
export type RemovePurchaseOrderItemsMutationResult = Apollo.MutationResult<RemovePurchaseOrderItemsMutation>;
export type RemovePurchaseOrderItemsMutationOptions = Apollo.BaseMutationOptions<RemovePurchaseOrderItemsMutation, RemovePurchaseOrderItemsMutationVariables>;
export const ModifyPurchaseOrderDocument = gql`
    mutation ModifyPurchaseOrder($input: ModifyOrderInput!) {
  modifyOrder(input: $input) {
    order {
      id
      supplier {
        id
      }
      deliveryAddress {
        __typename
      }
      deliveryAddressType
    }
  }
}
    `;
export type ModifyPurchaseOrderMutationFn = Apollo.MutationFunction<ModifyPurchaseOrderMutation, ModifyPurchaseOrderMutationVariables>;

/**
 * __useModifyPurchaseOrderMutation__
 *
 * To run a mutation, you first call `useModifyPurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPurchaseOrderMutation, { data, loading, error }] = useModifyPurchaseOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyPurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<ModifyPurchaseOrderMutation, ModifyPurchaseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyPurchaseOrderMutation, ModifyPurchaseOrderMutationVariables>(ModifyPurchaseOrderDocument, options);
      }
export type ModifyPurchaseOrderMutationHookResult = ReturnType<typeof useModifyPurchaseOrderMutation>;
export type ModifyPurchaseOrderMutationResult = Apollo.MutationResult<ModifyPurchaseOrderMutation>;
export type ModifyPurchaseOrderMutationOptions = Apollo.BaseMutationOptions<ModifyPurchaseOrderMutation, ModifyPurchaseOrderMutationVariables>;
export const DeletePurchaseOrderDocument = gql`
    mutation DeletePurchaseOrder($orderId: ID!) {
  deleteOrder(orderId: $orderId) {
    ok
  }
}
    `;
export type DeletePurchaseOrderMutationFn = Apollo.MutationFunction<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables>;

/**
 * __useDeletePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchaseOrderMutation, { data, loading, error }] = useDeletePurchaseOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useDeletePurchaseOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables>(DeletePurchaseOrderDocument, options);
      }
export type DeletePurchaseOrderMutationHookResult = ReturnType<typeof useDeletePurchaseOrderMutation>;
export type DeletePurchaseOrderMutationResult = Apollo.MutationResult<DeletePurchaseOrderMutation>;
export type DeletePurchaseOrderMutationOptions = Apollo.BaseMutationOptions<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables>;
export const PurchaseOrderEditItemDocument = gql`
    query PurchaseOrderEditItem($projectId: ID!, $orderId: ID!) {
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        title
        viewerPermissions
        order(id: $orderId) {
          id
          number
          orderedDate
          supplier {
            id
          }
          items {
            id
            ...PurchaseOrderEdit_OrderItem
          }
        }
      }
    }
  }
}
    ${PurchaseOrderEdit_OrderItemFragmentDoc}`;

/**
 * __usePurchaseOrderEditItemQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderEditItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderEditItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderEditItemQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function usePurchaseOrderEditItemQuery(baseOptions: Apollo.QueryHookOptions<PurchaseOrderEditItemQuery, PurchaseOrderEditItemQueryVariables> & ({ variables: PurchaseOrderEditItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseOrderEditItemQuery, PurchaseOrderEditItemQueryVariables>(PurchaseOrderEditItemDocument, options);
      }
export function usePurchaseOrderEditItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseOrderEditItemQuery, PurchaseOrderEditItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseOrderEditItemQuery, PurchaseOrderEditItemQueryVariables>(PurchaseOrderEditItemDocument, options);
        }
export function usePurchaseOrderEditItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PurchaseOrderEditItemQuery, PurchaseOrderEditItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PurchaseOrderEditItemQuery, PurchaseOrderEditItemQueryVariables>(PurchaseOrderEditItemDocument, options);
        }
export type PurchaseOrderEditItemQueryHookResult = ReturnType<typeof usePurchaseOrderEditItemQuery>;
export type PurchaseOrderEditItemLazyQueryHookResult = ReturnType<typeof usePurchaseOrderEditItemLazyQuery>;
export type PurchaseOrderEditItemSuspenseQueryHookResult = ReturnType<typeof usePurchaseOrderEditItemSuspenseQuery>;
export type PurchaseOrderEditItemQueryResult = Apollo.QueryResult<PurchaseOrderEditItemQuery, PurchaseOrderEditItemQueryVariables>;