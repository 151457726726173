/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ChildVisibilityIconButton_ItemFragmentDoc } from '../buttons/ChildVisibilityIconButton.generated';
import { VirtualItem__DocumentItemFragmentDoc } from '../../../trees-virtual/types.generated';
export type SubelementsBox_ItemFragment = { __typename: 'Item', id: string, type: Types.ItemType, isRootItem: boolean, pathForPdf: string, agreement: Types.Agreement, originVersionNumber?: number | null, childrenVisibility: Types.ItemChildrenVisibility, title: string, rank: number, level: number, parentId?: string | null, pathSortable: string, isHidden: boolean, isPriceHidden: boolean, isParentDecisionPreselected: boolean, isParentDecisionNotPreselected: boolean, isParentContingencyNotPreselected: boolean, deletedAt?: any | null, binding: Types.ItemBinding, decisionIsContingentItem: boolean, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, isItemEliminated: boolean, hasChildren: boolean, children: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, pathForPdf: string, isHidden: boolean, isPriceHidden: boolean, isParentContingencyNotPreselected: boolean, isParentDecisionNotPreselected: boolean, title: string, rank: number, level: number, parentId?: string | null, isRootItem: boolean, pathSortable: string, isParentDecisionPreselected: boolean, deletedAt?: any | null, type: Types.ItemType, binding: Types.ItemBinding, decisionIsContingentItem: boolean, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, childrenVisibility: Types.ItemChildrenVisibility, isItemEliminated: boolean, hasChildren: boolean, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null }>, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export type SubelementsBox_ChildItemFragment = { __typename: 'Item', id: string, pathForPdf: string, isHidden: boolean, isPriceHidden: boolean, isParentContingencyNotPreselected: boolean, isParentDecisionNotPreselected: boolean, title: string, rank: number, level: number, parentId?: string | null, isRootItem: boolean, pathSortable: string, isParentDecisionPreselected: boolean, deletedAt?: any | null, type: Types.ItemType, binding: Types.ItemBinding, decisionIsContingentItem: boolean, decisionSubitemsPreselection: Array<string>, decisionBehaviorOfSubitems: Types.DecisionBehaviorOfSubitems, decisionContingentItemPreselection?: boolean | null, isExplicitHidden: boolean, childrenVisibility: Types.ItemChildrenVisibility, isItemEliminated: boolean, hasChildren: boolean, decisionOptionElimination?: { __typename: 'DecisionOptionElimination', reason: string } | null };

export const SubelementsBox_ChildItemFragmentDoc = gql`
    fragment SubelementsBox_ChildItem on Item {
  id
  pathForPdf
  isHidden
  isPriceHidden
  isParentContingencyNotPreselected
  isParentDecisionNotPreselected
  ...VirtualItem__DocumentItem
}
    ${VirtualItem__DocumentItemFragmentDoc}`;
export const SubelementsBox_ItemFragmentDoc = gql`
    fragment SubelementsBox_Item on Item {
  id
  type
  isRootItem
  pathForPdf
  agreement
  children {
    id
    originVersionNumber
    ...SubelementsBox_ChildItem
  }
  ...ChildVisibilityIconButton_Item
  ...VirtualItem__DocumentItem
}
    ${SubelementsBox_ChildItemFragmentDoc}
${ChildVisibilityIconButton_ItemFragmentDoc}
${VirtualItem__DocumentItemFragmentDoc}`;