import { Stack } from "@mui/material";
import { TFunction } from "@tolgee/core";
import { isNil } from "lodash";
import React from "react";
import {
  FilterChip,
  FilterChipBoolean,
} from "../../commons/filters/FilterChip";
import { FilterCrmCompaniesOrPersonsChips } from "../crm-companies/CrmCompaniesOrPersonsSelect";
import { FilterCrmCompanyChips } from "../crm-companies/CrmCompaniesSelect";
import { CrmCompanyFilterChip } from "../crm-companies/CrmCompanyFilterChip";
import { FilterCustomFieldsChips } from "../custom-fields/filters";
import { FilterOrganisationUsersChips } from "../organisations/OrganisationUsersSelect";
import {
  FilterProjectCategoryChips,
  FilterProjectStateChips,
  FilterProjectTypeChips,
} from "./filters";
import { FilterProjectKindChip } from "./filters/ProjectKind";
import {
  ProjectFilterKind,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes";
import { FilterProjectPhasesChips } from "./filters/ProjectPhases";
import { Filters } from "./ProjectsList";

export function renderProjectsFilterChips<F extends Filters>({
  t,
  filters,
  setFilters,
  getFormattedPrice,
  allowedStates,
  onKindChange,
  onPlanningChange,
  onStateChange,
}: {
  t: TFunction<("Projects" | "Global" | "Tickets")[]>;
  filters: F;
  setFilters: React.Dispatch<React.SetStateAction<F>>;
  getFormattedPrice: (value: number | undefined) => string | undefined;
  allowedStates: ProjectStateMachineStatus[];
  onKindChange?: (value: ProjectFilterKind | null | undefined) => Partial<F>;
  onPlanningChange?: (value: boolean | null | undefined) => Partial<F>;
  onStateChange?: (
    value: ProjectStateMachineStatus[] | null | undefined
  ) => Partial<F>;
}) {
  return filters?.projectCategories?.length ||
    filters?.projectType?.length ||
    filters?.buildingZip ||
    filters?.minBudget ||
    filters?.withinRadiusKm ||
    filters?.filterKind ||
    filters?.filterByCustomFields ||
    filters?.filterByCrmOrganisationId ||
    filters?.filterPhaseIds?.length ||
    filters?.filterByCrmLinkIds?.length ||
    filters?.filterBySourceUserIds?.length ||
    filters?.filterBySourceCrmOrganisationIds?.length ||
    (filters?.filterIncludeState?.length &&
      filters?.filterIncludeState?.length !== allowedStates.length) ||
    !isNil(filters.filterAssigned) ||
    !isNil(filters.filterUrgent) ||
    !isNil(filters.filterOverdue) ? (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      <FilterProjectCategoryChips
        values={filters?.projectCategories}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            projectCategories: newValue,
          }))
        }
      />
      <FilterProjectTypeChips
        values={filters?.projectType}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            projectType: newValue,
          }))
        }
      />

      <FilterChip
        label={t("Zip Code", {
          ns: "Projects",
        })}
        resetValue={""}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            buildingZip: newValue,
          }))
        }
        value={filters?.buildingZip}
      />

      <FilterChip
        label={t("Min Budget", {
          ns: "Projects",
        })}
        resetValue={0}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            minBudget: newValue,
          }))
        }
        value={filters?.minBudget}
        getValueLabel={value => getFormattedPrice(value) ?? ""}
      />

      <FilterChip
        label={t("Within radius (km)", {
          ns: "Projects",
        })}
        resetValue={0}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            withinRadiusKm: newValue,
          }))
        }
        value={filters?.withinRadiusKm}
      />

      {!isNil(filters.filterKind) && (
        <FilterProjectKindChip
          value={filters.filterKind}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              ...(onKindChange?.(value) ?? { filterKind: value }),
            }))
          }
        />
      )}

      {!isNil(filters.filterAssigned) && (
        <FilterChipBoolean
          label={t("Planned", { ns: "Projects" })}
          value={filters.filterAssigned}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              ...(onPlanningChange?.(value) ?? { filterAssigned: value }),
            }))
          }
        />
      )}

      {!isNil(filters.filterOverdue) && (
        <FilterChipBoolean
          label={t("Overdue", { ns: "Tickets" })}
          value={filters.filterOverdue}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              filterOverdue: value,
            }))
          }
        />
      )}

      {!isNil(filters.filterUrgent) && (
        <FilterChipBoolean
          label={t("Urgent", { ns: "Tickets" })}
          value={filters.filterUrgent}
          setValue={value =>
            setFilters(filters => ({
              ...filters,
              filterUrgent: value,
            }))
          }
        />
      )}

      <FilterProjectStateChips
        values={filters?.filterIncludeState}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            ...(onStateChange?.(newValue) ?? { filterIncludeState: newValue }),
          }))
        }
        allowedStates={allowedStates}
      />
      <FilterProjectPhasesChips
        values={filters?.filterPhaseIds}
        setValues={newValue =>
          setFilters(filters => ({
            ...filters,
            filterPhaseIds: newValue,
          }))
        }
        allowedStates={allowedStates}
      />
      <FilterCustomFieldsChips
        values={filters?.filterByCustomFields ?? []}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCustomFields: newValue,
          }))
        }
      />
      <CrmCompanyFilterChip
        value={filters?.filterByCrmOrganisationId}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCrmOrganisationId: newValue,
          }))
        }
      />
      <FilterCrmCompaniesOrPersonsChips
        value={filters?.filterByCrmLinkIds}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterByCrmLinkIds: newValue,
          }))
        }
        label={t("Contact", { ns: "Projects" })}
      />
      <FilterOrganisationUsersChips
        values={filters?.filterBySourceUserIds}
        setValues={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterBySourceUserIds: newValue,
          }))
        }
        label={t("Created by", { ns: "Projects" })}
      />
      <FilterCrmCompanyChips
        value={filters?.filterBySourceCrmOrganisationIds}
        setValue={newValue =>
          setFilters(filterVariables => ({
            ...filterVariables,
            filterBySourceCrmOrganisationIds: newValue,
          }))
        }
        label={t("Created by", { ns: "Projects" })}
      />
    </Stack>
  ) : undefined;
}
