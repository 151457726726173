import { Image, Modal } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { useSnackbar } from "notistack";
import React from "react";
import { cssValue } from "../../../common/MuiThemeProvider";
import { useGlobalLoadingCounter } from "../../../common/global-loading-state/useGlobalLoadingCounter";
import { AttachmentPhotoDragAndDropField } from "../../features/attachments/AttachmentPhotoDragAndDropField";
import {
  Attachment,
  uploadFileToUploadCare,
} from "../../features/attachments/helpers";

interface Props {
  initialUrl?: string;
  initialTitle?: string;
  title: string;
  loading?: boolean;
  skipEdit?: boolean;
  handleClose: () => void;
  handleComplete: (attachment: Attachment) => Promise<void> | void;
  handleRemove?: () => Promise<void> | void;
}

export function UploadPhotoModal({
  initialUrl,
  initialTitle,
  title,
  handleClose,
  handleComplete,
  handleRemove,
  loading,
  skipEdit,
}: Props) {
  const [file, setFile] = React.useState<File | null>(null);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [removing, setRemoving] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { increment, decrement } = useGlobalLoadingCounter();

  const { t } = useTranslate("Global");

  const onSubmit = async () => {
    if (!file) return;
    try {
      setUploading(true);
      increment();
      const attachment = await uploadFileToUploadCare(file);
      await handleComplete(attachment);
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setUploading(false);
      decrement();
    }
    handleClose();
  };

  const onRemove = async () => {
    try {
      setRemoving(true);
      await handleRemove?.();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    } finally {
      setRemoving(false);
    }
  };

  return (
    <Modal
      title={title}
      dialogProps={{ maxWidth: "sm" }}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel"),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
            disabled: loading || uploading || removing,
          },
        },
        {
          label: t("Submit"),
          handleClick: onSubmit,
          buttonProps: {
            loading: uploading,
            disabled: !file || loading || uploading || removing,
          },
        },
      ]}
    >
      {initialUrl ? (
        <Image
          src={initialUrl}
          title={initialTitle ?? ""}
          loading={removing}
          borderRadius={cssValue.boxBorderRadius}
          handleDelete={handleRemove ? onRemove : undefined}
        />
      ) : (
        <AttachmentPhotoDragAndDropField skipEdit={skipEdit} onFile={setFile} />
      )}
    </Modal>
  );
}
