/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyQuoteSapS4HanaSalesQuoteMutationVariables = Types.Exact<{
  input: Types.CopyQuoteSapS4HanaSalesQuoteInput;
}>;


export type CopyQuoteSapS4HanaSalesQuoteMutation = { copyQuoteSapS4HanaSalesQuote: { __typename: 'CopyQuoteSapS4HanaSalesQuoteResult', failedForCrmCompanies: Array<{ __typename: 'FailedForCrmCompany', errorMessage: string, crmCompany: { __typename: 'CrmCompany', id: string } }> } };

export type S4HanaQuoteCopyModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type S4HanaQuoteCopyModalQuery = { outgoingQuote: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'OutgoingQuoteResult', quote?: { __typename: 'Quote', id: string, items: Array<{ __typename: 'Item', id: string, originVersionNumber?: number | null, pathForPdf: string, title: string }> } | null }, quoteItemSapS4HanaData: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'QuoteItemSapS4HanaDataResult', items: Array<{ __typename: 'ItemSapS4HanaData', id: string, xId?: string | null }> } };


export const CopyQuoteSapS4HanaSalesQuoteDocument = gql`
    mutation CopyQuoteSapS4HanaSalesQuote($input: CopyQuoteSapS4HanaSalesQuoteInput!) {
  copyQuoteSapS4HanaSalesQuote(input: $input) {
    failedForCrmCompanies {
      crmCompany {
        id
      }
      errorMessage
    }
  }
}
    `;
export type CopyQuoteSapS4HanaSalesQuoteMutationFn = Apollo.MutationFunction<CopyQuoteSapS4HanaSalesQuoteMutation, CopyQuoteSapS4HanaSalesQuoteMutationVariables>;

/**
 * __useCopyQuoteSapS4HanaSalesQuoteMutation__
 *
 * To run a mutation, you first call `useCopyQuoteSapS4HanaSalesQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyQuoteSapS4HanaSalesQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyQuoteSapS4HanaSalesQuoteMutation, { data, loading, error }] = useCopyQuoteSapS4HanaSalesQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyQuoteSapS4HanaSalesQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CopyQuoteSapS4HanaSalesQuoteMutation, CopyQuoteSapS4HanaSalesQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyQuoteSapS4HanaSalesQuoteMutation, CopyQuoteSapS4HanaSalesQuoteMutationVariables>(CopyQuoteSapS4HanaSalesQuoteDocument, options);
      }
export type CopyQuoteSapS4HanaSalesQuoteMutationHookResult = ReturnType<typeof useCopyQuoteSapS4HanaSalesQuoteMutation>;
export type CopyQuoteSapS4HanaSalesQuoteMutationResult = Apollo.MutationResult<CopyQuoteSapS4HanaSalesQuoteMutation>;
export type CopyQuoteSapS4HanaSalesQuoteMutationOptions = Apollo.BaseMutationOptions<CopyQuoteSapS4HanaSalesQuoteMutation, CopyQuoteSapS4HanaSalesQuoteMutationVariables>;
export const S4HanaQuoteCopyModalDocument = gql`
    query S4HanaQuoteCopyModal($projectId: ID!, $quoteId: ID!) {
  outgoingQuote(projectId: $projectId, docId: $quoteId) {
    ... on OutgoingQuoteResult {
      quote {
        id
        items {
          id
          originVersionNumber
          pathForPdf
          title
        }
      }
    }
  }
  quoteItemSapS4HanaData(projectId: $projectId, docId: $quoteId) {
    ... on QuoteItemSapS4HanaDataResult {
      items {
        id
        xId
      }
    }
  }
}
    `;

/**
 * __useS4HanaQuoteCopyModalQuery__
 *
 * To run a query within a React component, call `useS4HanaQuoteCopyModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4HanaQuoteCopyModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4HanaQuoteCopyModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useS4HanaQuoteCopyModalQuery(baseOptions: Apollo.QueryHookOptions<S4HanaQuoteCopyModalQuery, S4HanaQuoteCopyModalQueryVariables> & ({ variables: S4HanaQuoteCopyModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S4HanaQuoteCopyModalQuery, S4HanaQuoteCopyModalQueryVariables>(S4HanaQuoteCopyModalDocument, options);
      }
export function useS4HanaQuoteCopyModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S4HanaQuoteCopyModalQuery, S4HanaQuoteCopyModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S4HanaQuoteCopyModalQuery, S4HanaQuoteCopyModalQueryVariables>(S4HanaQuoteCopyModalDocument, options);
        }
export function useS4HanaQuoteCopyModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<S4HanaQuoteCopyModalQuery, S4HanaQuoteCopyModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S4HanaQuoteCopyModalQuery, S4HanaQuoteCopyModalQueryVariables>(S4HanaQuoteCopyModalDocument, options);
        }
export type S4HanaQuoteCopyModalQueryHookResult = ReturnType<typeof useS4HanaQuoteCopyModalQuery>;
export type S4HanaQuoteCopyModalLazyQueryHookResult = ReturnType<typeof useS4HanaQuoteCopyModalLazyQuery>;
export type S4HanaQuoteCopyModalSuspenseQueryHookResult = ReturnType<typeof useS4HanaQuoteCopyModalSuspenseQuery>;
export type S4HanaQuoteCopyModalQueryResult = Apollo.QueryResult<S4HanaQuoteCopyModalQuery, S4HanaQuoteCopyModalQueryVariables>;