import { gql, useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { isNumber, omit, sortBy } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { FormattedPercentageField } from "../../../commons/form-fields/FormattedPercentageField";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";
import {
  OrganisationDefaultMaterialMarginRangeInput,
  QuoteMaterialPriceHandling,
} from "../../../../clients/graphqlTypes";
import {
  VerticalFormValues,
  VerticalsFormField,
  getVerticalFormValues,
  getVerticalInputValues,
  useVerticalsFieldValidationSchema,
} from "../../verticals/form-fields/VerticalsFormField";
import { MaterialMarginRangesSection } from "../form-fields/MaterialMarginRangesSection";
import { useQuoteMaterialPriceHandlingOptions } from "../useQuoteMaterialPriceHandlingOptions";
import {
  OrganisationPriceAndCalculationSettingsForm_DefaultsFragment,
  useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation,
} from "./OrganisationPriceAndCalculationSettingsBox.generated";

interface Props {
  organisationId: string;
  organisationDefaults: OrganisationPriceAndCalculationSettingsForm_DefaultsFragment;
}

export function OrganisationPriceAndCalculationSettingsBox(props: Props) {
  const { t } = useTranslate(["OrganisationSettings"]);
  return (
    <CardContainer
      isExpandable
      title={t("Price and Calculation settings", {
        ns: "OrganisationSettings",
      })}
      ActionButton={
        <Tooltip
          title={t(
            "Defaults can be adjusted individually on each created document",
            {
              ns: "OrganisationSettings",
            }
          )}
        >
          <IconButton color="secondary" aria-label="Show helper" size="small">
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Box p={1}>
        <OrganisationPriceAndCalculationSettingsForm {...props} />
      </Box>
    </CardContainer>
  );
}

interface FormValues {
  defaultVerticalSettings: VerticalFormValues[];
  defaultMaterialMargin: number;
  defaultMaterialMarginRanges: OrganisationDefaultMaterialMarginRangeInput[];
  defaultQuoteMaterialPriceHandling: QuoteMaterialPriceHandling;
}

function OrganisationPriceAndCalculationSettingsForm({
  organisationId,
  organisationDefaults,
}: {
  organisationId: string;
  organisationDefaults: OrganisationPriceAndCalculationSettingsForm_DefaultsFragment;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["OrganisationSettings", "QuoteEditFooter"]);

  const quoteMaterialPriceHandlingOptions =
    useQuoteMaterialPriceHandlingOptions();

  const client = useApolloClient();
  const [modifyOrganisationDefaults] =
    useOrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaultsMutation(
      {
        client,
      }
    );

  const initialValues = React.useMemo((): FormValues | null => {
    if (!organisationDefaults) return null;
    return {
      defaultVerticalSettings: getVerticalFormValues(
        organisationDefaults.defaultVerticalSettings
      ),
      defaultMaterialMargin: organisationDefaults.defaultMaterialMargin,
      defaultMaterialMarginRanges:
        organisationDefaults.defaultMaterialMarginRanges.map(r =>
          omit(r, "id", "__typename")
        ),
      defaultQuoteMaterialPriceHandling:
        organisationDefaults.defaultQuoteMaterialPriceHandling,
    };
  }, [organisationDefaults]);

  const validationSchema = Yup.object().shape({
    defaultVerticalSettings: useVerticalsFieldValidationSchema(
      "defaultVerticalSettings"
    ),
    defaultMaterialMargin: Yup.number()
      .min(0)
      .label(
        t("Default material margin", {
          ns: "OrganisationSettings",
        })
      )
      .required(),
    defaultMaterialMarginRanges: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          priceFrom: Yup.number()
            .min(0)
            .required()
            .test(
              "priceFromOverlapsWithPrevious",
              t("Should not overlap", {
                ns: "OrganisationSettings",
              }),
              function (priceFrom) {
                // @ts-ignore
                const ranges = (this.from as any)[1].value
                  .defaultMaterialMarginRanges as OrganisationDefaultMaterialMarginRangeInput[];
                const index = (this.options as any).index as number;

                for (let i = 0; i < index; i++) {
                  const range = ranges[i];
                  if (
                    isNumber(priceFrom) &&
                    isNumber(range.priceFrom) &&
                    isNumber(range.priceTo) &&
                    (priceFrom as number) >= range.priceFrom &&
                    (priceFrom as number) < range.priceTo
                  ) {
                    return false;
                  }
                }
                return true;
              }
            )
            .label(
              t("Price from", {
                ns: "OrganisationSettings",
              })
            ),
          priceTo: Yup.number()
            .min(0)
            .required()
            .test(
              "priceToOverlapsWithPrevious",
              t("Should not overlap", {
                ns: "OrganisationSettings",
              }),
              function (priceTo) {
                // @ts-ignore
                const ranges = (this.from as any)[1].value
                  .defaultMaterialMarginRanges as OrganisationDefaultMaterialMarginRangeInput[];
                const index = (this.options as any).index as number;
                for (let i = 0; i < index; i++) {
                  const range = ranges[i];
                  if (
                    isNumber(priceTo) &&
                    isNumber(range.priceFrom) &&
                    isNumber(range.priceTo) &&
                    (priceTo as number) > range.priceFrom &&
                    (priceTo as number) <= range.priceTo
                  ) {
                    return false;
                  }
                }
                return true;
              }
            )
            .test(
              "smallerThanPriceFrom",
              t("Should be bigger than price from", {
                ns: "OrganisationSettings",
              }),
              function (priceTo) {
                // @ts-ignore
                const ranges = (this.from as any)[1].value
                  .defaultMaterialMarginRanges as OrganisationDefaultMaterialMarginRangeInput[];
                const index = (this.options as any).index as number;
                const priceFrom = ranges[index].priceFrom;
                if (
                  isNumber(priceTo) &&
                  isNumber(priceFrom) &&
                  (priceTo as number) <= priceFrom
                ) {
                  return false;
                }
                return true;
              }
            )
            .label(
              t("Price up to", {
                ns: "OrganisationSettings",
              })
            ),
          materialMargin: Yup.number()
            .min(0)
            .required()
            .label(
              t("Material margin", {
                ns: "OrganisationSettings",
              })
            ),
        })
      )
      .required(),
    defaultQuoteMaterialPriceHandling: Yup.string()
      .label("OrganisationSettings::Default material price handling")
      .required(),
  });

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const handleSubmit = async (values: FormValues) => {
    try {
      const input = {
        defaultVerticalSettings: getVerticalInputValues(
          values.defaultVerticalSettings
        ),
        defaultMaterialMargin: values.defaultMaterialMargin,
        defaultMaterialMarginRanges: sortBy(
          values.defaultMaterialMarginRanges,
          r => r.priceFrom
        ),
        defaultQuoteMaterialPriceHandling:
          values.defaultQuoteMaterialPriceHandling,
      };
      await modifyOrganisationDefaults({ variables: { input } });
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  return (
    <Formik<FormValues>
      initialValues={initialValues!}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1}>
            <Typography>
              {t("Work price settings", {
                ns: "OrganisationSettings",
              })}
            </Typography>
            <VerticalsFormField
              name="defaultVerticalSettings"
              disabled={false}
            />
            <Divider />
            <Typography>
              {t("Material price settings", {
                ns: "OrganisationSettings",
              })}
            </Typography>
            <Stack direction="row" spacing={1}>
              <FormattedPercentageField
                name="defaultMaterialMargin"
                label={t("Default material margin", {
                  ns: "OrganisationSettings",
                })}
                disabled={false}
              />
            </Stack>
            <MaterialMarginRangesSection
              title={t("Custom material margin ranges", {
                ns: "OrganisationSettings",
              })}
              value={formikProps.values.defaultMaterialMarginRanges}
              defaultMaterialMargin={formikProps.values.defaultMaterialMargin}
              name="defaultMaterialMarginRanges"
              disabled={false}
            />
            <Stack direction={"column"} spacing={1}>
              <Typography>
                {t("Select material price handling option", {
                  ns: "OrganisationSettings",
                })}
              </Typography>
              <RadioGroupField
                name={"defaultQuoteMaterialPriceHandling"}
                options={quoteMaterialPriceHandlingOptions}
                condensed
              />
            </Stack>
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
