import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { omit } from "lodash";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { AddSignatureModal } from "../../../commons/modals/AddSignatureModal";
import { GetAddressModal } from "../../addresses/GetAddressModal";
import {
  useAcceptQuoteModalQuery,
  useAcceptQuoteModal_SetQuoteContracteeAddressMutation,
} from "./AcceptQuoteModal.generated";

interface Props {
  projectId: string;
  docId: string;
  title?: string;
  handleClose: () => void;
  handleComplete: (values: {
    signature: string;
    place: string;
    date: moment.Moment;
  }) => Promise<void>;
}

export const AcceptQuoteModal = ({
  projectId,
  docId,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate([
    "OrganisationProfile",
    "QuoteAccept",
    "SignatureModal",
  ]);

  const client = useApolloClient();
  const query = useAcceptQuoteModalQuery({
    client,
    variables: { projectId, docId },
    fetchPolicy: "no-cache",
  });
  const [setAddress] = useAcceptQuoteModal_SetQuoteContracteeAddressMutation({
    client,
  });

  const contractee = getDataOrNull(query.data?.quote)?.quote?.contractee;
  const projectBuilding = getDataOrNull(query.data?.project)?.project?.building;
  const projectBuildingAddress = projectBuilding?.buildingAddress;

  if (!contractee) return null;

  return !contractee.address ? (
    <GetAddressModal
      id="system-organisation-billing-address-modal"
      title={t("Please provide your branch address", {
        ns: "QuoteAccept",
      })}
      inputLabel={t("Billing address", {
        ns: "OrganisationProfile",
      })}
      value={projectBuildingAddress}
      handleComplete={async address => {
        try {
          const sanitizedAddress = {
            ...address,
            location: omit(address.location, "__typename"),
          };
          await setAddress({
            variables: {
              input: {
                projectId,
                quoteId: docId,
                docActorId: contractee.id,
                address: omit(sanitizedAddress, "__typename", "id"),
              },
            },
            refetchQueries: [namedOperations.Query.AcceptQuoteModal],
          });
        } catch (error) {
          enqueueSnackbar("Failed to set billing address", {
            variant: "error",
          });
        }
      }}
      handleClose={handleClose}
    />
  ) : (
    <AddSignatureModal
      title={title}
      handleClose={handleClose}
      handleComplete={handleComplete}
      description={t("Please sign here to accept the quote.", {
        ns: "SignatureModal",
      })}
      defaultPlace={
        projectBuildingAddress?.city ?? contractee?.address?.city ?? ""
      }
    />
  );
};
