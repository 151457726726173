import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { getFormattedDateTime } from "@msys/formatting";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Link as MuiLink } from "@mui/material";
import {
  Day,
  Inject,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import {
  EventRenderedArgs,
  NavigatingEventArgs,
} from "@syncfusion/ej2-schedule/src/schedule/base/interface";
import { TFunction } from "@tolgee/core";
import { useTolgee, useTranslate } from "@tolgee/react";
import { isUndefined } from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useUserData } from "../../../auth/useUserData";
import {
  getAddressLabel,
  getAddressSearchUrl,
} from "../../../features/addresses/helpers";
import { color } from "../../../../common/MuiThemeProvider";
import { useDashboardPersonalCalendarQuery } from "./DashboardPersonalCalendarBox.generated";

export const DashboardPersonalCalendarBox = () => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["PlanningModal", "Global"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const [currentDate, setCurrentDate] = React.useState(moment(Date.now()));
  const [rangeStart, setRangeStart] = React.useState<
    moment.Moment | undefined
  >();
  const [rangeEnd, setRangeEnd] = React.useState<moment.Moment | undefined>();

  const client = useApolloClient();
  const query = useDashboardPersonalCalendarQuery({
    client,
    fetchPolicy: "network-only",
    skip: !(rangeStart && rangeEnd),
    variables: {
      rangeStart: rangeStart?.toISOString(true),
      rangeEnd: rangeEnd?.toISOString(true),
      userId: viewer.id,
    },
  });

  const QuickInfoTemplate = React.useMemo(
    () => getQuickInfoTemplate(t, language),
    [t, language]
  );

  const eventRendered = React.useCallback((args: EventRenderedArgs) => {
    if (!args) return;
    const data = args.data as Exclude<typeof scheduleData, undefined>[0];
    if (data.backgroundColor) {
      args.element.style.backgroundColor = data.backgroundColor;
    }
    if (!isUndefined(data.isTentative)) {
      args.element.style.opacity = data.isTentative ? "0.3" : "";
    }
  }, []);

  const scheduleData = React.useMemo(
    () =>
      getDataOrNull(query.data?.planSessions)
        ?.edges.map(e => e.node)
        .map(ps => ({
          planSession: ps,
          id: ps.id,
          title: ps.project.title,
          isAllDay: false,
          ProjectId: ps.project.id,
          WhoId: ps.who.id,
          project: ps.project,
          location: ps.project.buildingInfo?.buildingAddress
            ? getAddressLabel(ps.project.buildingInfo?.buildingAddress)
            : undefined,
          backgroundColor: ps.project.ticket ? color.secondary : color.primary,
          startTime: moment(ps.from).local().toDate(),
          endTime: moment(ps.till).local().toDate(),
          isTentative: ps.isTentative,
        })),
    [query.data?.planSessions]
  );

  React.useEffect(() => {
    setRangeStart(moment(currentDate).startOf("day"));
    setRangeEnd(moment(currentDate).endOf("day"));
  }, [currentDate]);

  const eventSettings = React.useMemo(
    () => ({
      allowDeleting: false,
      allowAdding: false,
      allowEditing: false,
      enableTooltip: true,
      dataSource: scheduleData,
      fields: {
        id: "id",
        subject: { name: "title" },
        isAllDay: { name: "isAllDay" },
        isReadonly: "isReadonly",
        startTime: { name: "startTime" },
        location: { name: "location" },
        endTime: { name: "endTime" },
      },
      template: (props: any) => {
        return (
          <div className="e-inner-wrap">
            <div className="e-subject">{props.title}</div>
            {props.location && (
              <div className="e-location">
                <div className="e-location-icon e-icons"></div>
                <div className="e-location-details e-text-ellipsis">
                  <MuiLink
                    target="_blank"
                    rel="noreferrer nofollow"
                    href={getAddressSearchUrl(props.location)}
                  >
                    {props.location}
                  </MuiLink>
                </div>
              </div>
            )}
            <div className="e-description">
              <Button
                component={Link}
                size="small"
                color="primary"
                rel="noopener noreferrer"
                target="_blank"
                to={`/projects/${props.ProjectId}`}
                style={{ textTransform: "none" }}
                startIcon={<OpenInNewIcon fontSize="small" />}
              >
                {t("Details", { ns: "PlanningModal" })}
              </Button>
            </div>
          </div>
        );
      },
    }),
    [scheduleData, t]
  );

  const selectedDate = React.useMemo(() => currentDate.toDate(), [currentDate]);

  const navigating = React.useCallback((args: NavigatingEventArgs) => {
    if (!args) return;
    if (args.action === "date" && args.currentDate) {
      // setTimeout: avoid scheduler being rendered twice on picking date in date picker
      setTimeout(() => setCurrentDate(moment(args.currentDate)), 0);
    }
  }, []);

  return (
    <Box
      sx={{
        height: {
          xs: "80vh",
          tablet: "100%",
        },
      }}
    >
      <ScheduleComponent
        height="100%"
        selectedDate={selectedDate}
        currentView="Day"
        //@ts-ignore Library mistake in typing
        quickInfoTemplates={QuickInfoTemplate}
        //@ts-ignore Library mistake in typing
        eventSettings={eventSettings}
        navigating={navigating}
        eventRendered={eventRendered}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
        </ViewsDirective>
        <Inject services={[Day]} />
      </ScheduleComponent>
    </Box>
  );
};

const getQuickInfoTemplate = (
  t: TFunction<("PlanningModal" | "Global")[]>,
  language: string
) => ({
  content(props: any) {
    return props.planSession ? (
      <div className="e-event-content e-template">
        <div className="e-subject-wrap">
          <div className="e-date-time">
            <div className="e-date-time-icon e-icons"></div>
            <div className="e-date-time-wrapper e-text-ellipsis">
              <div className="e-date-time-details e-text-ellipsis">
                {getFormattedDateTime(props.startTime, language)} –{" "}
                {getFormattedDateTime(props.endTime, language)}
              </div>
            </div>
          </div>
          {props.location ? (
            <div className="e-location">
              <div className="e-location-icon e-icons"></div>
              <div className="e-location-details e-text-ellipsis">
                <MuiLink
                  target="_blank"
                  rel="noreferrer nofollow"
                  href={getAddressSearchUrl(props.location)}
                >
                  {props.location}
                </MuiLink>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="e-description">
            <Button
              component={Link}
              size="small"
              color="primary"
              rel="noopener noreferrer"
              target="_blank"
              to={`/projects/${props.ProjectId}`}
              style={{ textTransform: "none" }}
              startIcon={<OpenInNewIcon fontSize="small" />}
            >
              {t("Details", {
                ns: "PlanningModal",
              })}
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  },
});
