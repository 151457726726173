/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddProjectMemberModal_ProjectFragment = { __typename: 'Project', id: string, roles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }>, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } }> };

export type AddProjectMemberModalQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type AddProjectMemberModalQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, organisationId: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, defaultProjectRoles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }> }>, project: { __typename: 'MissingPermissions' } | { __typename: 'ProjectResult', project?: { __typename: 'Project', id: string, roles: Array<{ __typename: 'ProjectRole', id: string, label: string, internalName: string }>, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } }> } | null } };

export type AddProjectMemberFromOrganisationMembersMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  userId: Types.Scalars['ID']['input'];
  roleIds: Array<Types.Scalars['ID']['input']>;
}>;


export type AddProjectMemberFromOrganisationMembersMutation = { addProjectMemberFromOrganisationMembers: { __typename: 'AddProjectMemberFromOrganisationMembersResult', project: { __typename: 'Project', id: string, internalStakeholders: Array<{ __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string } }> } } };

export const AddProjectMemberModal_ProjectFragmentDoc = gql`
    fragment AddProjectMemberModal_Project on Project {
  id
  roles {
    id
    label
    internalName
  }
  internalStakeholders {
    id
    user {
      id
      title
      description
      website
      isMeister
      familyname
      firstname
      fullname
      locale
      skillset
    }
  }
}
    `;
export const AddProjectMemberModalDocument = gql`
    query AddProjectMemberModal($projectId: ID!) {
  organisationMemberships(filters: {active: true}) {
    id
    organisationId
    title
    description
    website
    isMeister
    familyname
    firstname
    fullname
    locale
    skillset
    defaultProjectRoles {
      id
      label
      internalName
    }
  }
  project(id: $projectId) {
    ... on ProjectResult {
      project {
        id
        ...AddProjectMemberModal_Project
      }
    }
  }
}
    ${AddProjectMemberModal_ProjectFragmentDoc}`;

/**
 * __useAddProjectMemberModalQuery__
 *
 * To run a query within a React component, call `useAddProjectMemberModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMemberModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddProjectMemberModalQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAddProjectMemberModalQuery(baseOptions: Apollo.QueryHookOptions<AddProjectMemberModalQuery, AddProjectMemberModalQueryVariables> & ({ variables: AddProjectMemberModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddProjectMemberModalQuery, AddProjectMemberModalQueryVariables>(AddProjectMemberModalDocument, options);
      }
export function useAddProjectMemberModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddProjectMemberModalQuery, AddProjectMemberModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddProjectMemberModalQuery, AddProjectMemberModalQueryVariables>(AddProjectMemberModalDocument, options);
        }
export function useAddProjectMemberModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddProjectMemberModalQuery, AddProjectMemberModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddProjectMemberModalQuery, AddProjectMemberModalQueryVariables>(AddProjectMemberModalDocument, options);
        }
export type AddProjectMemberModalQueryHookResult = ReturnType<typeof useAddProjectMemberModalQuery>;
export type AddProjectMemberModalLazyQueryHookResult = ReturnType<typeof useAddProjectMemberModalLazyQuery>;
export type AddProjectMemberModalSuspenseQueryHookResult = ReturnType<typeof useAddProjectMemberModalSuspenseQuery>;
export type AddProjectMemberModalQueryResult = Apollo.QueryResult<AddProjectMemberModalQuery, AddProjectMemberModalQueryVariables>;
export const AddProjectMemberFromOrganisationMembersDocument = gql`
    mutation addProjectMemberFromOrganisationMembers($projectId: ID!, $userId: ID!, $roleIds: [ID!]!) {
  addProjectMemberFromOrganisationMembers(
    projectId: $projectId
    userId: $userId
    roleIds: $roleIds
  ) {
    project {
      id
      internalStakeholders {
        id
        user {
          id
        }
      }
    }
  }
}
    `;
export type AddProjectMemberFromOrganisationMembersMutationFn = Apollo.MutationFunction<AddProjectMemberFromOrganisationMembersMutation, AddProjectMemberFromOrganisationMembersMutationVariables>;

/**
 * __useAddProjectMemberFromOrganisationMembersMutation__
 *
 * To run a mutation, you first call `useAddProjectMemberFromOrganisationMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMemberFromOrganisationMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectMemberFromOrganisationMembersMutation, { data, loading, error }] = useAddProjectMemberFromOrganisationMembersMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useAddProjectMemberFromOrganisationMembersMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectMemberFromOrganisationMembersMutation, AddProjectMemberFromOrganisationMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectMemberFromOrganisationMembersMutation, AddProjectMemberFromOrganisationMembersMutationVariables>(AddProjectMemberFromOrganisationMembersDocument, options);
      }
export type AddProjectMemberFromOrganisationMembersMutationHookResult = ReturnType<typeof useAddProjectMemberFromOrganisationMembersMutation>;
export type AddProjectMemberFromOrganisationMembersMutationResult = Apollo.MutationResult<AddProjectMemberFromOrganisationMembersMutation>;
export type AddProjectMemberFromOrganisationMembersMutationOptions = Apollo.BaseMutationOptions<AddProjectMemberFromOrganisationMembersMutation, AddProjectMemberFromOrganisationMembersMutationVariables>;