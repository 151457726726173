import { LabeledValueHorizontal, useScreenWidth } from "@msys/ui";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { identity } from "lodash";
import React from "react";
import { Phone } from "../../../clients/graphqlTypes";
import { AddressWithBreaks } from "../addresses/AddressWithBreaks";
import { getAddressLabel } from "../addresses/helpers";

export type DocumentDataItem = { label: string; value: React.ReactNode };
type Address = React.ComponentProps<typeof AddressWithBreaks>["address"] &
  Parameters<typeof getAddressLabel>[0];

interface Props {
  recipient: {
    contactName: string | null;
    organisationName: string;
    address: Address | null;
  };
  recipientActionButtons?: React.ReactNode;
  recipientAddressActionButtons?: React.ReactNode;
  sender: {
    organisationName: string;
    contactName: string | null;
    contactPhones: Pick<Phone, "number">[] | null;
    contactEmail: string | null;
    address: Address | null;
  };
  visibility?: {
    isContractorNameVisible: boolean;
    isContractorAddressVisible: boolean;
    isContractorPhoneVisible: boolean;
    isContractorEmailVisible: boolean;
    isContractorContactPersonVisible: boolean;
    isEndClientVisible: boolean;
  };
  documentData: DocumentDataItem[];
}

export const DocumentPreviewHeader = ({
  recipient,
  recipientActionButtons,
  recipientAddressActionButtons,
  sender,
  visibility,
  documentData,
}: Props) => {
  const { t } = useTranslate(["Quote", "Global"]);
  const { isMaxPhone } = useScreenWidth();

  const senderOrganisationName = sender.organisationName;
  const senderOrganisationAddress =
    sender.address && getAddressLabel(sender.address);
  const senderPhone = sender.contactPhones
    ?.map(phone => phone.number)
    .filter(Boolean)
    .map((phone, index) => <div key={index}>{phone}</div>);

  return (
    <Paper>
      <Stack
        padding={2}
        spacing={2}
        direction={isMaxPhone ? "column-reverse" : "row"}
      >
        <Stack flex={3} direction="column" justifyContent="center" spacing={0}>
          <Typography variant="caption" whiteSpace={"pre"}>
            {visibility?.isContractorAddressVisible !== false
              ? [senderOrganisationName, senderOrganisationAddress]
                  .filter(identity)
                  .join(" • ")
              : ""}
          </Typography>

          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <div>
              <Typography>{recipient.organisationName}</Typography>
              {recipient.contactName && (
                <Typography>
                  {recipient.organisationName
                    ? `${t("c/o", { ns: "Global" })} `
                    : ""}
                  {recipient.contactName}
                </Typography>
              )}
            </div>
            {recipientActionButtons}
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {recipient.address && (
              <Typography>
                <AddressWithBreaks
                  address={recipient.address}
                  useCountry={false}
                />
              </Typography>
            )}
            {recipientAddressActionButtons}
          </Stack>
        </Stack>
        {isMaxPhone && <Divider />}
        <Stack flex={2} direction="column" spacing={0}>
          <Box marginBottom={1}>
            {visibility?.isContractorNameVisible !== false && (
              <Typography variant="h4">{sender.organisationName}</Typography>
            )}
            {visibility?.isContractorAddressVisible !== false &&
            sender.address ? (
              <Typography variant={"body2"}>
                {getAddressLabel(sender.address)}
              </Typography>
            ) : null}
          </Box>
          {visibility?.isContractorContactPersonVisible !== false &&
            sender.contactName && (
              <LabeledValueHorizontal
                label={t("Contact person", {
                  ns: "Quote",
                })}
                labelWidth={110}
              >
                {sender.contactName}
              </LabeledValueHorizontal>
            )}
          {visibility?.isContractorPhoneVisible !== false &&
          senderPhone &&
          senderPhone.length > 0 ? (
            <LabeledValueHorizontal
              label={t("Phone", {
                ns: "Quote",
              })}
              labelWidth={110}
            >
              {senderPhone}
            </LabeledValueHorizontal>
          ) : null}
          {visibility?.isContractorEmailVisible !== false &&
          sender.contactEmail ? (
            <LabeledValueHorizontal
              label={t("Email", {
                ns: "Global",
              })}
              labelWidth={110}
            >
              {sender.contactEmail}
            </LabeledValueHorizontal>
          ) : null}
          {documentData.map(field => (
            <LabeledValueHorizontal
              key={field.label}
              label={field.label}
              labelWidth={110}
            >
              {field.value}
            </LabeledValueHorizontal>
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};
