/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type OrganisationEmailTemplateModal_OrganisationEmailTemplateFragment = { __typename: 'OrganisationEmailTemplate', id: string, context: Types.EmailTemplateContext, description: string, subject: string, content: string, attachments: Array<{ __typename: 'Attachment', id: string, title: string, url: string, mimeType: string }> };

export const OrganisationEmailTemplateModal_OrganisationEmailTemplateFragmentDoc = gql`
    fragment OrganisationEmailTemplateModal_OrganisationEmailTemplate on OrganisationEmailTemplate {
  id
  context
  description
  subject
  content
  attachments {
    id
    title
    url
    mimeType
  }
}
    `;