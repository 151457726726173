import { ModalDialog, ModalTitle, RadioGroup } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { intersection } from "lodash";
import React from "react";
import { useUserData } from "../../../auth/useUserData";
import { ProjectSourcePageName } from "../../../../clients/graphqlTypes";
import { CreateProjectModalContent } from "./CreateProjectModalContent";
import { CreateTicketModalContent } from "./CreateTicketModalContent";

export type CreateProjectType = "project" | "ticket";
const defaultCreateTypes: CreateProjectType[] = ["project", "ticket"];

interface Props {
  crmOrganisationId?: string;
  refetchQueries?: string[];
  autoSubmit?: boolean;
  buildingItemId?: string;
  title?: string;
  pageName?: ProjectSourcePageName;
  desiredProjectInitialStatus: "opportunity" | "contracted";
  handleClose: () => void;
  handleComplete?: (projectId: string) => Promise<void> | void;
  initialCreateType?: CreateProjectType;
  allowedCreateTypes?: CreateProjectType[];
}

export const CreateProjectOrTicketModal = ({
  crmOrganisationId,
  refetchQueries,
  autoSubmit = true,
  buildingItemId,
  title,
  handleClose,
  handleComplete,
  pageName,
  desiredProjectInitialStatus,
  initialCreateType = "project",
  allowedCreateTypes = defaultCreateTypes,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate([
    "ProjectCreate",
    "TicketCreate",
    "Projects",
    "Tickets",
    "Global",
  ]);

  const createTypes: CreateProjectType[] = intersection(
    allowedCreateTypes,
    viewer.organisation.isCraftsmanOrganisation
      ? ["project", "ticket"]
      : ["project"]
  );

  const [createType, setCreateType] = React.useState<CreateProjectType>(
    initialCreateType && createTypes.includes(initialCreateType)
      ? initialCreateType
      : createTypes[0]
  );

  const createTypeLabels: Record<CreateProjectType, string> = {
    project: t("Project", { ns: "Projects" }),
    ticket: t("Ticket", { ns: "Tickets" }),
  };

  const RadioGroupForType =
    createTypes.length > 1 ? (
      <RadioGroup
        options={createTypes.map(createType => ({
          value: createType,
          label: createTypeLabels[createType],
        }))}
        value={createType}
        onChange={setCreateType}
        inline
        sx={{ mb: 2 }}
      />
    ) : undefined;

  const modalTitle =
    createType === "project"
      ? t("New project", { ns: "ProjectCreate" })
      : createType === "ticket"
      ? t("New ticket", { ns: "TicketCreate" })
      : "";

  const modalId =
    createType === "project"
      ? "create-project-modal"
      : createType === "ticket"
      ? "create-ticket-modal"
      : "";

  return (
    <ModalDialog id={modalId} handleClose={handleClose}>
      <ModalTitle title={title ?? modalTitle} handleClose={handleClose} />
      {createType === "project" ? (
        <CreateProjectModalContent
          topInfo={RadioGroupForType}
          text={t(
            "Create a project (e.g. Kitchen renovation) to manage between one client and one craftsman or craftsman organization.",
            {
              ns: "ProjectCreate",
            }
          )}
          submitButtonLabel={t("Create project", {
            ns: "ProjectCreate",
          })}
          crmOrganisationId={crmOrganisationId}
          refetchQueries={refetchQueries}
          autoSubmit={autoSubmit && createTypes.length <= 1}
          buildingItemId={buildingItemId}
          handleClose={handleClose}
          handleComplete={handleComplete}
          pageName={pageName}
          desiredProjectInitialStatus={desiredProjectInitialStatus}
        />
      ) : createType === "ticket" ? (
        <CreateTicketModalContent
          topInfo={RadioGroupForType}
          crmOrganisationId={crmOrganisationId}
          refetchQueries={refetchQueries}
          handleClose={handleClose}
          handleComplete={handleComplete}
          pageName={pageName}
          desiredProjectInitialStatus={desiredProjectInitialStatus}
        />
      ) : null}
    </ModalDialog>
  );
};
