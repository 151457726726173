import { StyledComponent } from "@emotion/styled";
import { Box, BoxProps, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import { size } from "../../../common/MuiThemeProvider";
import { transientOptions } from "../../styles";
import { LINE_COLOR } from "../../trees-virtual/helpers";

export const TreeConnector: StyledComponent<
  BoxProps & {
    $isAtTheTopOfTree?: boolean;
    $isAtTheBottomOfTree?: boolean;
  } & MUIStyledCommonProps<Theme>
> = styled(
  Box,
  transientOptions
)<{
  $isAtTheTopOfTree?: boolean;
  $isAtTheBottomOfTree?: boolean;
}>(({ $isAtTheTopOfTree, $isAtTheBottomOfTree }) => ({
  position: "relative",
  width: "100%",
  height: 0,
  ...($isAtTheTopOfTree
    ? {
        "::after": {
          position: "absolute",
          content: '""',
          backgroundColor: LINE_COLOR,
          width: 1,
          left: 10,
          top: `-${size.treeSpacing / 2}px`,
          height: `${size.treeItemFullHeight / 2 + size.treeSpacing}px`,
        },
      }
    : undefined),
  ...($isAtTheBottomOfTree
    ? {
        "::before": {
          position: "absolute",
          content: '""',
          backgroundColor: LINE_COLOR,
          width: 1,
          left: 10,
          bottom: `-${size.treeSpacing / 2}px`,
          height: `${size.treeItemFullHeight / 2 + size.treeSpacing}px`,
        },
      }
    : undefined),
}));
