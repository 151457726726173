import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { RequestViewButtonFragment } from "./RequestViewButton.generated";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Stack } from "../../../commons/layout/Stack";
import { RequestStatusBadge } from "../RequestStatusBadge";
import { useNavigate } from "react-router-dom";

interface Props {
  projectId: string;
  requirement: RequestViewButtonFragment;
}

export function RequestViewButton({ projectId, requirement }: Props) {
  const { t } = useTranslate("Requests");
  const requests = requirement.outgoingRequests;

  const navigate = useNavigate();

  const requestWon = requirement.requirementRequestWonId
    ? requests.find(r => r.id === requirement.requirementRequestWonId) ?? null
    : null;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  return requests.length === 0 ? null : requests.length === 1 ? (
    <Button
      component={Link}
      to={`/projects/${projectId}/requests/${requests[0].id}`}
      color="primary"
      variant="outlined"
    >
      {t("View request")}
    </Button>
  ) : (
    <>
      <ButtonGroup variant="outlined" color="primary">
        <Button
          // TODO: ButtonGroup is not working well with links
          // component={Link}
          // to={`/projects/${projectId}/requests/${
          //   (requestWon ?? requests[0]).id
          // }`}
          onClick={() => {
            navigate(
              `/projects/${projectId}/requests/${
                (requestWon ?? requests[0]).id
              }`
            );
          }}
          color="primary"
        >
          {requestWon ? t("View completed request") : t("View latest request")}
        </Button>
        <Button size="small" onClick={handleOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={e => {
          e.stopPropagation();
          if (anchorEl) handleClose(e);
        }}
        MenuListProps={{
          style: { width: 300 },
        }}
      >
        {requests.map((request, index, array) => (
          <MenuItem
            key={request.id}
            divider={index < array.length - 1}
            component={Link}
            to={`/projects/${projectId}/requests/${request.id}`}
          >
            <Stack
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <span>{request.title}</span>
              <RequestStatusBadge request={request} align="right" />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
