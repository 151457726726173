import { padStart } from "lodash";

interface Props {
  valueMs: number;
  isSimplified?: boolean;
  noNegative?: boolean;
}

export const DurationValue = ({
  valueMs,
  isSimplified = false,
  noNegative = true,
}: Props) => {
  return isSimplified ? (
    <>{formatSimplifiedTime(noNegative ? Math.max(valueMs, 0) : valueMs)}</>
  ) : (
    <>{formatTime(noNegative ? Math.max(valueMs, 0) : valueMs)}</>
  );
};

const formatTime = (ms: number) => {
  const seconds = ms / 1000;
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.round(seconds % 60);
  return [
    padStart(h.toString(), 2, "0"),
    padStart(m.toString(), 2, "0"),
    padStart(s.toString(), 2, "0"),
  ].join(":");
};

const formatSimplifiedTime = (ms: number) => {
  const durationInMinutes = ms / (60 * 1000);
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.floor(durationInMinutes % 60);
  return `${hours}:${padStart(minutes.toString(), 2, "0")} h`;
};
