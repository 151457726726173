/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestAdditionalInfoModalFragment = { __typename: 'Request', id: string, instructions: string };

export type ModifyRequestAdditionalInfoMutationVariables = Types.Exact<{
  input: Types.ModifyRequestInput;
}>;


export type ModifyRequestAdditionalInfoMutation = { modifyRequest: { __typename: 'ModifyRequestResult', request: { __typename: 'Request', id: string, instructions: string } } };

export const RequestAdditionalInfoModalFragmentDoc = gql`
    fragment RequestAdditionalInfoModal on Request {
  id
  instructions
}
    `;
export const ModifyRequestAdditionalInfoDocument = gql`
    mutation ModifyRequestAdditionalInfo($input: ModifyRequestInput!) {
  modifyRequest(input: $input) {
    request {
      id
      instructions
    }
  }
}
    `;
export type ModifyRequestAdditionalInfoMutationFn = Apollo.MutationFunction<ModifyRequestAdditionalInfoMutation, ModifyRequestAdditionalInfoMutationVariables>;

/**
 * __useModifyRequestAdditionalInfoMutation__
 *
 * To run a mutation, you first call `useModifyRequestAdditionalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyRequestAdditionalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyRequestAdditionalInfoMutation, { data, loading, error }] = useModifyRequestAdditionalInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyRequestAdditionalInfoMutation(baseOptions?: Apollo.MutationHookOptions<ModifyRequestAdditionalInfoMutation, ModifyRequestAdditionalInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyRequestAdditionalInfoMutation, ModifyRequestAdditionalInfoMutationVariables>(ModifyRequestAdditionalInfoDocument, options);
      }
export type ModifyRequestAdditionalInfoMutationHookResult = ReturnType<typeof useModifyRequestAdditionalInfoMutation>;
export type ModifyRequestAdditionalInfoMutationResult = Apollo.MutationResult<ModifyRequestAdditionalInfoMutation>;
export type ModifyRequestAdditionalInfoMutationOptions = Apollo.BaseMutationOptions<ModifyRequestAdditionalInfoMutation, ModifyRequestAdditionalInfoMutationVariables>;