import { useApolloClient } from "@apollo/client";
import { Attachment } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { omit } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import {
  AttachmentSnapshotFragment,
  useAddItemAttachmentsMutation,
  useAddProductAttachmentsMutation,
  useAddProjectAttachmentsMutation,
  useAddRequestAttachmentsMutation,
  useModifyItemAttachmentMutation,
  useModifyProductAttachmentMutation,
  useModifyProjectAttachmentMutation,
  useModifyRequestAttachmentMutation,
  useRemoveItemAttachmentMutation,
  useRemoveProductAttachmentMutation,
  useRemoveProjectAttachmentMutation,
  useRemoveRequestAttachmentMutation,
} from "./Attachments.generated";

export type AddAttachmentsFn = (
  attachments: Attachment[]
) => Promise<AttachmentSnapshotFragment[] | undefined>;

export type ModifyAttachmentFn = (
  attachmentId: string,
  url: string
) => Promise<AttachmentSnapshotFragment | undefined>;

export type RemoveAttachmentFn = (
  attachmentId: string
) => Promise<boolean | undefined>;

export const useItemAttachments = (
  itemId: string,
  refetchQueries?: string[]
) => {
  const { t } = useTranslate("FilesBoxTable");

  const client = useApolloClient();

  const [add, { loading: loadingAdd }] = useAddItemAttachmentsMutation({
    client,
  });
  const [modify, { loading: loadingModify }] = useModifyItemAttachmentMutation({
    client,
  });
  const [remove, { loading: loadingRemove }] = useRemoveItemAttachmentMutation({
    client,
  });

  const { enqueueSnackbar } = useSnackbar();

  const addAttachments: AddAttachmentsFn = React.useCallback(
    async (attachments: Attachment[]) => {
      try {
        const r = await add({
          variables: {
            attachments: attachments.map(a => omit(a, "id", "__type")),
            itemId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(
          attachments.length > 1 ? t("Files uploaded") : t("File uploaded")
        );

        return r.data?.addItemAttachments.attachments;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [add, itemId, refetchQueries, client, enqueueSnackbar, t]
  );

  const modifyAttachment: ModifyAttachmentFn = React.useCallback(
    async (attachmentId: string, url: string) => {
      try {
        const r = await modify({
          variables: {
            attachmentId,
            url,
            itemId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        // enqueueSnackbar(t("File uploaded"));

        return r.data?.modifyItemAttachment.attachment;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [modify, itemId, refetchQueries, client, enqueueSnackbar]
  );

  const removeAttachment: RemoveAttachmentFn = React.useCallback(
    async (attachmentId: string) => {
      try {
        const r = await remove({
          variables: {
            attachmentId,
            itemId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(t("File deleted"));

        return r.data?.removeItemAttachments.ok;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [remove, itemId, refetchQueries, client, enqueueSnackbar, t]
  );

  return {
    addAttachments,
    modifyAttachment,
    removeAttachment,
    loading: loadingAdd || loadingModify || loadingRemove,
  };
};

export const useProductAttachments = (
  productId: string,
  refetchQueries?: string[]
) => {
  const { t } = useTranslate("FilesBoxTable");

  const client = useApolloClient();

  const [add, { loading: loadingAdd }] = useAddProductAttachmentsMutation({
    client,
  });
  const [modify, { loading: loadingModify }] =
    useModifyProductAttachmentMutation({
      client,
    });
  const [remove, { loading: loadingRemove }] =
    useRemoveProductAttachmentMutation({
      client,
    });

  const { enqueueSnackbar } = useSnackbar();

  const addAttachments: AddAttachmentsFn = React.useCallback(
    async (attachments: Attachment[]) => {
      try {
        const r = await add({
          variables: {
            attachments: attachments.map(a => omit(a, "id", "__type")),
            productId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(
          attachments.length > 1 ? t("Files uploaded") : t("File uploaded")
        );

        return r.data?.addProductAttachments.attachments;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [add, productId, refetchQueries, client, enqueueSnackbar, t]
  );

  const modifyAttachment: ModifyAttachmentFn = React.useCallback(
    async (attachmentId: string, url: string) => {
      try {
        const r = await modify({
          variables: {
            attachmentId,
            url,
            productId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        // enqueueSnackbar(t("File uploaded"));

        return r.data?.modifyProductAttachment.attachment;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [modify, productId, refetchQueries, client, enqueueSnackbar]
  );

  const removeAttachment: RemoveAttachmentFn = React.useCallback(
    async (attachmentId: string) => {
      try {
        const r = await remove({
          variables: {
            attachmentId,
            productId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(t("File deleted"));

        return r.data?.removeProductAttachments.ok;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [remove, productId, refetchQueries, client, enqueueSnackbar, t]
  );

  return {
    addAttachments,
    modifyAttachment,
    removeAttachment,
    loading: loadingAdd || loadingModify || loadingRemove,
  };
};

export const useProjectAttachments = (
  projectId: string,
  refetchQueries?: string[]
) => {
  const { t } = useTranslate("FilesBoxTable");

  const client = useApolloClient();

  const [add, { loading: loadingAdd }] = useAddProjectAttachmentsMutation({
    client,
  });
  const [modify, { loading: loadingModify }] =
    useModifyProjectAttachmentMutation({
      client,
    });
  const [remove, { loading: loadingRemove }] =
    useRemoveProjectAttachmentMutation({
      client,
    });

  const { enqueueSnackbar } = useSnackbar();

  const addAttachments: AddAttachmentsFn = React.useCallback(
    async (attachments: Attachment[]) => {
      try {
        const r = await add({
          variables: {
            attachments: attachments.map(a => omit(a, "id", "__type")),
            projectId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(
          attachments.length > 1 ? t("Files uploaded") : t("File uploaded")
        );

        return r.data?.addProjectAttachments.attachments;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [add, projectId, refetchQueries, client, enqueueSnackbar, t]
  );

  const modifyAttachment: ModifyAttachmentFn = React.useCallback(
    async (attachmentId: string, url: string) => {
      try {
        const r = await modify({
          variables: {
            attachmentId,
            url,
            projectId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        // enqueueSnackbar(t("File uploaded"));

        return r.data?.modifyProjectAttachment.attachment;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [modify, projectId, refetchQueries, client, enqueueSnackbar]
  );

  const removeAttachment: RemoveAttachmentFn = React.useCallback(
    async (attachmentId: string) => {
      try {
        const r = await remove({
          variables: {
            attachmentId,
            projectId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(t("File deleted"));

        return r.data?.removeProjectAttachments.ok;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [remove, projectId, refetchQueries, client, enqueueSnackbar, t]
  );

  return {
    addAttachments,
    modifyAttachment,
    removeAttachment,
    loading: loadingAdd || loadingModify || loadingRemove,
  };
};

export const useRequestAttachments = (
  requestId: string,
  refetchQueries?: string[]
) => {
  const { t } = useTranslate("FilesBoxTable");

  const client = useApolloClient();

  const [add, { loading: loadingAdd }] = useAddRequestAttachmentsMutation({
    client,
  });
  const [modify, { loading: loadingModify }] =
    useModifyRequestAttachmentMutation({
      client,
    });
  const [remove, { loading: loadingRemove }] =
    useRemoveRequestAttachmentMutation({
      client,
    });

  const { enqueueSnackbar } = useSnackbar();

  const addAttachments: AddAttachmentsFn = React.useCallback(
    async (attachments: Attachment[]) => {
      try {
        const r = await add({
          variables: {
            attachments: attachments.map(a => omit(a, "id", "__type")),
            requestId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(
          attachments.length > 1 ? t("Files uploaded") : t("File uploaded")
        );

        return r.data?.addRequestAttachments.attachments;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [add, requestId, refetchQueries, client, enqueueSnackbar, t]
  );

  const modifyAttachment: ModifyAttachmentFn = React.useCallback(
    async (attachmentId: string, url: string) => {
      try {
        const r = await modify({
          variables: {
            attachmentId,
            url,
            requestId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        // enqueueSnackbar(t("File uploaded"));

        return r.data?.modifyRequestAttachment.attachment;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [modify, requestId, refetchQueries, client, enqueueSnackbar]
  );

  const removeAttachment: RemoveAttachmentFn = React.useCallback(
    async (attachmentId: string) => {
      try {
        const r = await remove({
          variables: {
            attachmentId,
            requestId,
          },
          ...(refetchQueries
            ? {
                refetchQueries: refetchQueries,
                awaitRefetchQueries: true,
              }
            : undefined),
        });

        if (!refetchQueries) await client.reFetchObservableQueries();

        enqueueSnackbar(t("File deleted"));

        return r.data?.removeRequestAttachments.ok;
      } catch (e) {
        if (e instanceof Error)
          enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    [remove, requestId, refetchQueries, client, enqueueSnackbar, t]
  );

  return {
    addAttachments,
    modifyAttachment,
    removeAttachment,
    loading: loadingAdd || loadingModify || loadingRemove,
  };
};
