import { CardContainer, ListItem } from "@msys/ui";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { useRestrictionFilter } from "../../../auth/useRestrictionFilter";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam";
import { getUrlSearchParamsByParamsName } from "../../../commons/hooks/useStateWithUrlParams";
import { TabsWithRestriction } from "../../../commons/TabsWithRestriction";
import { InvoiceIcon } from "../../invoices/InvoiceIcon";
import { InvoiceListItem } from "../../invoices/InvoiceListItem";
import {
  InvoiceListItem_IncomingInvoiceFragment,
  InvoiceListItem_OutgoingInvoiceFragment,
} from "../../invoices/InvoiceListItem.generated";

interface Props {
  crmCompanyId: string;
  invoicesOutgoing: (InvoiceListItem_OutgoingInvoiceFragment & {
    projectId: string;
  })[];
  invoicesOutgoingTotalCount: number;
  invoicesIncoming: (InvoiceListItem_IncomingInvoiceFragment & {
    projectId: string;
  })[];
  invoicesIncomingTotalCount: number;
  isLoading: boolean;
}

type QuickFilterValue = "OUTGOING" | "INCOMING";

export const CrmCompanyInvoicesBox = ({
  crmCompanyId,
  invoicesOutgoing,
  invoicesOutgoingTotalCount,
  invoicesIncoming,
  invoicesIncomingTotalCount,
  isLoading,
}: Props) => {
  const { t } = useTranslate(["Invoices", "Global"]);

  const initialOptions = [
    {
      value: "OUTGOING" as const,
      label: t("Outgoing", { ns: "Invoices" }),
      count: invoicesOutgoingTotalCount,
      capability: "INVOICING" as const,
    },
    {
      value: "INCOMING" as const,
      label: t("Incoming", { ns: "Invoices" }),
      count: invoicesIncomingTotalCount,
    },
  ];
  const restrictionFilter = useRestrictionFilter();
  const options = initialOptions.filter(restrictionFilter);

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "invoicesBoxQuickFilter",
    options[0].value
  );
  const invoices =
    quickFilter === "OUTGOING" && options.some(o => o.value === "OUTGOING")
      ? invoicesOutgoing
      : quickFilter === "INCOMING"
        ? invoicesIncoming
        : [];

  const invoicesPath = `/documents/invoices`;

  return (
    <CardContainer
      Icon={<InvoiceIcon />}
      title={t("Invoices", { ns: "Invoices" })}
      isLoading={isLoading}
      isExpandable
    >
      <Box p={1}>
        <TabsWithRestriction
          condensed
          useSelectOnMobile
          options={initialOptions}
          value={quickFilter}
          onChange={(newQuickFilter: QuickFilterValue) => {
            setQuickFilter(newQuickFilter);
          }}
        />
      </Box>
      {invoices.length === 0 && (
        <Box p={1} py={3}>
          <Typography align="center" sx={{ color: "grey.500" }} variant="body2">
            {t("There are no items to display", { ns: "Global" })}
          </Typography>
        </Box>
      )}
      <Stack width="100%" direction="column" spacing={0}>
        {invoices.length > 0 &&
          invoices.map(invoice => (
            <ListItem
              key={invoice.id}
              component={Link}
              //@ts-ignore
              to={`/projects/${invoice.projectId}/invoices${
                invoice.__typename === "OutgoingInvoice"
                  ? "/outgoing"
                  : invoice.__typename === "IncomingInvoice"
                    ? "/incoming"
                    : ""
              }/${invoice.id}`}
            >
              <InvoiceListItem invoice={invoice} />
            </ListItem>
          ))}
      </Stack>
      {invoices.length > 0 && (
        <Box>
          <Button
            component={Link}
            to={{
              pathname: invoicesPath,
              search: getUrlSearchParamsByParamsName(
                { filterByCrmOrganisationId: crmCompanyId },
                "filters",
                { tab: quickFilter, quickfilter: "ALL" }
              ).toString(),
            }}
            color="secondary"
            variant="text"
            fullWidth
          >
            {t("See more", { ns: "Global" })}
          </Button>
        </Box>
      )}
    </CardContainer>
  );
};
