import AddIcon from "@mui/icons-material/Add";
import { Button, ButtonProps, IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { ButtonCreate } from "../../../commons/button/Button";

export function RequestCreateButton({
  type,
  Icon = <AddIcon />,
  label,
  disabled,
  onClick,
  color = "primary",
  variant = "contained",
}: {
  type: "icon" | "button" | "button-create";
  Icon?: React.ReactElement | null;
  label?: string;
  disabled?: boolean;
  onClick?: ButtonProps["onClick"];
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
}) {
  const { t } = useTranslate("Requests");
  const buttonText = label ?? t("Create request");
  return type === "icon" ? (
    <IconButton
      color={color}
      title={buttonText}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon}
    </IconButton>
  ) : type === "button" ? (
    <Button
      color={color}
      variant={variant}
      startIcon={Icon}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  ) : type === "button-create" ? (
    <ButtonCreate
      variant={variant}
      color={color}
      title={buttonText}
      disabled={disabled}
      onClick={onClick}
      Icon={Icon ?? undefined}
    />
  ) : null;
}
