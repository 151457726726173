import React from "react";
import {
  Counters,
  GlobalLoadingStateContext,
} from "./GlobalLoadingStateContext";

export const GlobalLoadingStateProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [counters, setCounters] = React.useState<Counters>({});

  return (
    <GlobalLoadingStateContext.Provider value={{ counters, setCounters }}>
      {children}
    </GlobalLoadingStateContext.Provider>
  );
};
