/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../addresses/Addresses.generated';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../attachments/Attachments.generated';
import { CustomFieldsFragmentDoc } from '../custom-fields/CustomFieldsBox.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCrmUserMutationVariables = Types.Exact<{
  input: Array<Types.CrmUserCreateInput>;
}>;


export type CreateCrmUserMutation = { createCrmUser: { __typename: 'CrmUserCreateResult', users: Array<{ __typename: 'CrmPerson', id: string }> } };

export type ModifyCrmUserMutationVariables = Types.Exact<{
  users: Array<Types.CrmUserModifyInput>;
}>;


export type ModifyCrmUserMutation = { modifyCrmUser: { __typename: 'CrmUserModifyResult', users: Array<{ __typename: 'CrmPerson', id: string, notices: string, customTags: Array<string>, jobTitle: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> }> } };

export type CrmPersonDetailsFragment = { __typename: 'CrmPerson', id: string, notices: string, customTags: Array<string>, jobTitle: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill>, email: any, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, phones: Array<{ __typename: 'Phone', id: string, main: boolean, type: Types.PhoneType, number: string }>, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }, customFields: Array<{ __typename: 'CustomFieldField', key: string, value: any, dataType: Types.CustomFieldDataType, group: string }> };

export const CrmPersonDetailsFragmentDoc = gql`
    fragment CrmPersonDetails on CrmPerson {
  id
  notices
  customTags
  jobTitle
  homeAddress {
    ...AddressDetails__Address
  }
  title
  description
  website
  isMeister
  familyname
  firstname
  fullname
  locale
  skillset
  email
  phones {
    id
    main
    type
    number
  }
  avatar {
    ...AttachmentSnapshot
  }
  ...CustomFields
}
    ${AddressDetails__AddressFragmentDoc}
${AttachmentSnapshotFragmentDoc}
${CustomFieldsFragmentDoc}`;
export const CreateCrmUserDocument = gql`
    mutation CreateCrmUser($input: [CrmUserCreateInput!]!) {
  createCrmUser(input: $input) {
    users {
      id
    }
  }
}
    `;
export type CreateCrmUserMutationFn = Apollo.MutationFunction<CreateCrmUserMutation, CreateCrmUserMutationVariables>;

/**
 * __useCreateCrmUserMutation__
 *
 * To run a mutation, you first call `useCreateCrmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCrmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCrmUserMutation, { data, loading, error }] = useCreateCrmUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCrmUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateCrmUserMutation, CreateCrmUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCrmUserMutation, CreateCrmUserMutationVariables>(CreateCrmUserDocument, options);
      }
export type CreateCrmUserMutationHookResult = ReturnType<typeof useCreateCrmUserMutation>;
export type CreateCrmUserMutationResult = Apollo.MutationResult<CreateCrmUserMutation>;
export type CreateCrmUserMutationOptions = Apollo.BaseMutationOptions<CreateCrmUserMutation, CreateCrmUserMutationVariables>;
export const ModifyCrmUserDocument = gql`
    mutation ModifyCrmUser($users: [CrmUserModifyInput!]!) {
  modifyCrmUser(input: $users) {
    users {
      id
      ...CrmPersonDetails
    }
  }
}
    ${CrmPersonDetailsFragmentDoc}`;
export type ModifyCrmUserMutationFn = Apollo.MutationFunction<ModifyCrmUserMutation, ModifyCrmUserMutationVariables>;

/**
 * __useModifyCrmUserMutation__
 *
 * To run a mutation, you first call `useModifyCrmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCrmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCrmUserMutation, { data, loading, error }] = useModifyCrmUserMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useModifyCrmUserMutation(baseOptions?: Apollo.MutationHookOptions<ModifyCrmUserMutation, ModifyCrmUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyCrmUserMutation, ModifyCrmUserMutationVariables>(ModifyCrmUserDocument, options);
      }
export type ModifyCrmUserMutationHookResult = ReturnType<typeof useModifyCrmUserMutation>;
export type ModifyCrmUserMutationResult = Apollo.MutationResult<ModifyCrmUserMutation>;
export type ModifyCrmUserMutationOptions = Apollo.BaseMutationOptions<ModifyCrmUserMutation, ModifyCrmUserMutationVariables>;