import { Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { AutoSave } from "../../commons/form-fields/AutoSave";
import { CheckboxGroupField } from "../../commons/form-fields/CheckboxField";
import { RadioGroupField } from "../../commons/form-fields/RadioGroupField";
import { BillOfMaterialsSection } from "../../../clients/graphqlTypes";
import { ColumnExtended } from "./types";
import { useColumnLabel } from "./useColumnLabel";

export interface FormValues {
  dataFormat: "pdf" | "csv" | "xlsx";
  sections: BillOfMaterialsSection[];
  csvFields: ColumnExtended[];
  pdfFields: ColumnExtended[];
}

interface Props {
  autoSave: boolean;
  formId: string;
  initialValues: FormValues;
  onSubmit(values: FormValues): Promise<void>;
}

export const BillOfMaterialsExportForm = ({
  autoSave,
  formId,
  initialValues,
  onSubmit,
}: Props) => {
  const { t } = useTranslate(["PurchaseOrders", "Global"]);

  const validationSchema = Yup.object().shape({
    dataFormat: Yup.string()
      .oneOf(["pdf", "csv", "xlsx"])
      .label(t("Data format", { ns: "PurchaseOrders" }))
      .required(),
    sections: Yup.array()
      .of(Yup.string())
      .label(t("Additional information", { ns: "PurchaseOrders" })),
    csvFields: Yup.array()
      .of(Yup.string())
      .label(t("Fields to export", { ns: "PurchaseOrders" }))
      .min(1)
      .required(),
    pdfFields: Yup.array()
      .of(Yup.string())
      .label(t("Fields to export", { ns: "PurchaseOrders" }))
      .min(1)
      .required(),
  });

  const { columnOptions } = useColumnLabel();

  const sectionOptions = React.useMemo(
    () =>
      [
        {
          value: "projectTitleAndNumber" as const,
          label: t("Project title and number", { ns: "PurchaseOrders" }),
        },
        {
          value: "listOfQuotes" as const,
          label: t("List of quotes", { ns: "PurchaseOrders" }),
        },
        {
          value: "contactInformation" as const,
          label: t("Contact information", { ns: "PurchaseOrders" }),
        },
        {
          value: "projectAddress" as const,
          label: t("Project address", { ns: "PurchaseOrders" }),
        },
      ] as {
        value: BillOfMaterialsSection;
        label: string;
      }[],
    [t]
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {formikProps => (
        <Form id={formId}>
          <Stack direction="column" spacing={1}>
            <RadioGroupField
              label={t("Data format", { ns: "PurchaseOrders" })}
              name="dataFormat"
              options={[
                { value: "pdf", label: "PDF" },
                { value: "csv", label: "CSV" },
                { value: "xlsx", label: "XLSX" },
              ]}
            />

            {(formikProps.values.dataFormat === "csv" ||
              formikProps.values.dataFormat === "xlsx") && (
              <>
                <CheckboxGroupField
                  label={t("Fields to export", { ns: "PurchaseOrders" })}
                  size="small"
                  name="csvFields"
                  options={columnOptions}
                />
              </>
            )}

            {formikProps.values.dataFormat === "pdf" && (
              <>
                <CheckboxGroupField
                  label={t("Fields to export", { ns: "PurchaseOrders" })}
                  size="small"
                  name="pdfFields"
                  options={columnOptions}
                />
                <CheckboxGroupField
                  label={t("Additional information", {
                    ns: "PurchaseOrders",
                  })}
                  size="small"
                  name="sections"
                  options={sectionOptions}
                />
              </>
            )}

            {autoSave && (
              <AutoSave enableReinitialize initialValues={initialValues} />
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
