import GroupIcon from "@mui/icons-material/Group";
import { Badge, Typography } from "@mui/material";
import { last } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "../../commons/Avatar";
import { useBubbleContext } from "../../commons/hooks/useBubbleContext";
import { ProfileListItem } from "../../commons/ProfileListItem";
import { ChannelListItemFragment } from "./Channels.generated";
import { useTranslate } from "@tolgee/react";

interface Props {
  channelId?: string;
  channel: ChannelListItemFragment;
  divider?: boolean;
}

export const ProjectChannelListItem = ({
  channelId,
  channel,
  divider = true,
}: Props) => {
  const { bubble } = useBubbleContext();
  const bubbleAmount = bubble.perChannel[channel.id] || 0;

  const { t } = useTranslate("Channel");

  // const otherParticipants = channel.participants.edges
  //   .map(e => e.node)
  //   .filter(u => u.id !== currentUser.id);

  const lastMessage = useMemo(() => {
    const message = last(channel.messages.edges);
    if (!message) return undefined;
    return message.node.text;
  }, [channel.messages.edges]);

  const lastDate = useMemo(() => {
    const message = last(channel.messages.edges);
    if (!message) return undefined;
    return moment(message.node.createdAt).fromNow();
  }, [channel.messages.edges]);

  return (
    <ProfileListItem
      component={Link}
      to={channel.type !== "USER2USER" ? `${channel.id}` : `u2u/${channel.id}`}
      selected={channel.id === channelId}
      button
      divider={divider}
      avatar={
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          color="error"
          badgeContent={bubbleAmount}
        >
          {/* {channel.type === "USER2USER" ? null : ( */}
          {/* <UserAvatar userAvatar={otherParticipants[0]} /> */}
          <Avatar
            type="circle"
            size="m"
            icon={<GroupIcon />}
            url={channel.logo.url}
          />
          {/* )} */}
        </Badge>
      }
      badge={
        lastDate ? (
          <Typography
            variant="caption"
            color="secondary"
            noWrap
            component="div"
          >
            {lastDate}
          </Typography>
        ) : undefined
      }
      primary={
        channel.type === "PRJ_COMMENTS" ? t("Project comments") : channel.label
      }
      secondary={lastMessage}
    />
  );
};
