/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AttachmentSnapshotFragmentDoc, AttachmentsFragmentDoc, WithItemAttachmentsFragmentDoc } from '../../../attachments/Attachments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuoteTemplateShareButton_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> };

export type ShareQuoteTemplateWithOrganisationsMutationVariables = Types.Exact<{
  input: Types.ShareQuoteTemplateWithOrganisationsInput;
}>;


export type ShareQuoteTemplateWithOrganisationsMutation = { shareQuoteTemplateWithOrganisations: { __typename: 'ShareQuoteTemplateWithOrganisationsResult', quoteTemplate: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> } } };

export type ShareQuoteTemplatePubliclyMutationVariables = Types.Exact<{
  input: Types.ShareQuoteTemplatePubliclyInput;
}>;


export type ShareQuoteTemplatePubliclyMutation = { shareQuoteTemplatePublicly: { __typename: 'ShareQuoteTemplatePubliclyResult', quoteTemplate: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, hasAnyPublishedVersion: boolean, sharedPublicly: boolean, sharedWithOrganisations: Array<{ __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }> } } };

export const QuoteTemplateShareButton_QuoteTemplateFragmentDoc = gql`
    fragment QuoteTemplateShareButton_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
  hasAnyPublishedVersion
  sharedPublicly
  sharedWithOrganisations {
    id
    title
    logo {
      ...AttachmentSnapshot
    }
  }
}
    ${AttachmentSnapshotFragmentDoc}`;
export const ShareQuoteTemplateWithOrganisationsDocument = gql`
    mutation ShareQuoteTemplateWithOrganisations($input: ShareQuoteTemplateWithOrganisationsInput!) {
  shareQuoteTemplateWithOrganisations(input: $input) {
    quoteTemplate {
      id
      resolvedAsReadModelVersionNumber
      ...QuoteTemplateShareButton_QuoteTemplate
    }
  }
}
    ${QuoteTemplateShareButton_QuoteTemplateFragmentDoc}`;
export type ShareQuoteTemplateWithOrganisationsMutationFn = Apollo.MutationFunction<ShareQuoteTemplateWithOrganisationsMutation, ShareQuoteTemplateWithOrganisationsMutationVariables>;

/**
 * __useShareQuoteTemplateWithOrganisationsMutation__
 *
 * To run a mutation, you first call `useShareQuoteTemplateWithOrganisationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareQuoteTemplateWithOrganisationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareQuoteTemplateWithOrganisationsMutation, { data, loading, error }] = useShareQuoteTemplateWithOrganisationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareQuoteTemplateWithOrganisationsMutation(baseOptions?: Apollo.MutationHookOptions<ShareQuoteTemplateWithOrganisationsMutation, ShareQuoteTemplateWithOrganisationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareQuoteTemplateWithOrganisationsMutation, ShareQuoteTemplateWithOrganisationsMutationVariables>(ShareQuoteTemplateWithOrganisationsDocument, options);
      }
export type ShareQuoteTemplateWithOrganisationsMutationHookResult = ReturnType<typeof useShareQuoteTemplateWithOrganisationsMutation>;
export type ShareQuoteTemplateWithOrganisationsMutationResult = Apollo.MutationResult<ShareQuoteTemplateWithOrganisationsMutation>;
export type ShareQuoteTemplateWithOrganisationsMutationOptions = Apollo.BaseMutationOptions<ShareQuoteTemplateWithOrganisationsMutation, ShareQuoteTemplateWithOrganisationsMutationVariables>;
export const ShareQuoteTemplatePubliclyDocument = gql`
    mutation ShareQuoteTemplatePublicly($input: ShareQuoteTemplatePubliclyInput!) {
  shareQuoteTemplatePublicly(input: $input) {
    quoteTemplate {
      id
      resolvedAsReadModelVersionNumber
      ...QuoteTemplateShareButton_QuoteTemplate
    }
  }
}
    ${QuoteTemplateShareButton_QuoteTemplateFragmentDoc}`;
export type ShareQuoteTemplatePubliclyMutationFn = Apollo.MutationFunction<ShareQuoteTemplatePubliclyMutation, ShareQuoteTemplatePubliclyMutationVariables>;

/**
 * __useShareQuoteTemplatePubliclyMutation__
 *
 * To run a mutation, you first call `useShareQuoteTemplatePubliclyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareQuoteTemplatePubliclyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareQuoteTemplatePubliclyMutation, { data, loading, error }] = useShareQuoteTemplatePubliclyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareQuoteTemplatePubliclyMutation(baseOptions?: Apollo.MutationHookOptions<ShareQuoteTemplatePubliclyMutation, ShareQuoteTemplatePubliclyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareQuoteTemplatePubliclyMutation, ShareQuoteTemplatePubliclyMutationVariables>(ShareQuoteTemplatePubliclyDocument, options);
      }
export type ShareQuoteTemplatePubliclyMutationHookResult = ReturnType<typeof useShareQuoteTemplatePubliclyMutation>;
export type ShareQuoteTemplatePubliclyMutationResult = Apollo.MutationResult<ShareQuoteTemplatePubliclyMutation>;
export type ShareQuoteTemplatePubliclyMutationOptions = Apollo.BaseMutationOptions<ShareQuoteTemplatePubliclyMutation, ShareQuoteTemplatePubliclyMutationVariables>;