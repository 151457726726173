import { useApolloClient } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Form, Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useCopyToClipboard, useLocation } from "react-use";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { SwitchField } from "../../../commons/form-fields/SwitchField";
import { copyTextToClipboard } from "../../addresses/helpers";
import { TemplateQuoteSelectField } from "../../templates/quote/TemplateQuoteSelectField";
import {
  OrganisationEmbeddableWizardSnippetBox_OrganisationFragment,
  OrganisationEmbeddableWizardSnippetBox_SettingsFragment,
  useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation,
} from "./OrganisationEmbeddableWizardSnippetBox.generated";

interface Props {
  organisationSettings: OrganisationEmbeddableWizardSnippetBox_SettingsFragment;
  organisation: OrganisationEmbeddableWizardSnippetBox_OrganisationFragment;
}

export const OrganisationEmbeddableWizardSnippetBox = ({
  organisationSettings,
  organisation,
}: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTemplate, setSelectedTemplate] =
    React.useState<
      React.ComponentProps<typeof TemplateQuoteSelectField>["value"]
    >(null);

  const [{ value }, copy] = useCopyToClipboard();

  const snippet = React.useMemo(
    () =>
      selectedTemplate
        ? `<iframe src="https://${location.hostname}/wizard.html?contractorId=${organisation.id}&templateId=${selectedTemplate.id}" ></iframe>`
        : null,
    [location.hostname, organisation.id, selectedTemplate]
  );

  return (
    <CardContainer
      title={t("Embeddable wizard", {
        ns: "OrganisationSettings",
      })}
      isExpandable
    >
      <Stack direction={"column"} spacing={1} padding={1}>
        <TemplateQuoteSelectField
          filterDocumentType="QUOTE"
          inputLabel={t("Select a template", { ns: "OrganisationSettings" })}
          value={selectedTemplate}
          onChange={setSelectedTemplate}
        />
        {snippet && (
          <FormControl variant="filled">
            <InputLabel>
              {t("Snippet code", {
                ns: "OrganisationSettings",
              })}
            </InputLabel>
            <FilledInput
              value={snippet}
              readOnly
              multiline
              fullWidth
              endAdornment={
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={async () => {
                    if (snippet) await copyTextToClipboard(snippet);
                    enqueueSnackbar(
                      t("Copied to clipboard", {
                        ns: "Global",
                      })
                    );
                  }}
                >
                  <Tooltip
                    title={t("Copy snippet", {
                      ns: "OrganisationSettings",
                    })}
                  >
                    <ContentCopyIcon />
                  </Tooltip>
                </IconButton>
              }
            />
          </FormControl>
        )}
        <OrganisationEmbeddableWizardSettingsForm
          organisationId={organisation.id}
          organisationSettings={organisationSettings}
        />
      </Stack>
    </CardContainer>
  );
};

interface FormValues {
  embeddableWizardIsLeadAddressRequired: boolean;
}

function OrganisationEmbeddableWizardSettingsForm({
  organisationId,
  organisationSettings,
}: {
  organisationSettings: OrganisationEmbeddableWizardSnippetBox_SettingsFragment;
  organisationId: string;
}) {
  const viewer = useUserData().currentUser!;
  const client = useApolloClient();
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const [modifyOrganisationSettings] =
    useOrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettingsMutation(
      {
        client,
      }
    );

  if (viewer.organisation.id !== organisationId)
    return <div>not own organisation</div>;

  const initialValues = {
    embeddableWizardIsLeadAddressRequired:
      organisationSettings.embeddableWizardIsLeadAddressRequired,
  };

  const validationSchema = Yup.object().shape({
    embeddableWizardIsLeadAddressRequired: Yup.bool().label(
      t("Lead address is required", { ns: "OrganisationSettings" })
    ),
  });

  const handleSubmit = async (values: FormValues) => {
    await modifyOrganisationSettings({
      variables: {
        input: {
          embeddableWizardIsLeadAddressRequired:
            values.embeddableWizardIsLeadAddressRequired,
        },
      },
    });
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <Stack direction="column" spacing={1}>
            <div>
              <Typography>{t("Settings", { ns: "Global" })}</Typography>
              <SwitchField
                name="embeddableWizardIsLeadAddressRequired"
                label={t("Lead address is required", {
                  ns: "OrganisationSettings",
                })}
                disabled={false}
              />
            </div>
            <AutoSave />
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
