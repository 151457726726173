/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecommendationsBox_ItemFragment = { __typename: 'Item', id: string, docId: string, type: Types.ItemType, recommendations: Array<{ __typename: 'ItemRecommendation', id: string, teaserTitle: string, rank: number, recommendedEntity: { __typename: 'RecommendedTemplate', templateId: string, templateVersionNumber: number } | { __typename: 'RecommendedTemplateType', templateTypeId: string } }> };

export type CreateItemFromItemRecommendationMutationVariables = Types.Exact<{
  input: Types.CreateItemFromItemRecommendationInput;
}>;


export type CreateItemFromItemRecommendationMutation = { createItemFromItemRecommendation: { __typename: 'CreateItemFromItemRecommendationResult' } };

export const RecommendationsBox_ItemFragmentDoc = gql`
    fragment RecommendationsBox_Item on Item {
  id
  docId
  type
  recommendations {
    id
    teaserTitle
    rank
    recommendedEntity {
      ... on RecommendedTemplate {
        templateId
        templateVersionNumber
      }
      ... on RecommendedTemplateType {
        templateTypeId
      }
    }
  }
}
    `;
export const CreateItemFromItemRecommendationDocument = gql`
    mutation CreateItemFromItemRecommendation($input: CreateItemFromItemRecommendationInput!) {
  createItemFromItemRecommendation(input: $input) {
    __typename
  }
}
    `;
export type CreateItemFromItemRecommendationMutationFn = Apollo.MutationFunction<CreateItemFromItemRecommendationMutation, CreateItemFromItemRecommendationMutationVariables>;

/**
 * __useCreateItemFromItemRecommendationMutation__
 *
 * To run a mutation, you first call `useCreateItemFromItemRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemFromItemRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemFromItemRecommendationMutation, { data, loading, error }] = useCreateItemFromItemRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemFromItemRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemFromItemRecommendationMutation, CreateItemFromItemRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemFromItemRecommendationMutation, CreateItemFromItemRecommendationMutationVariables>(CreateItemFromItemRecommendationDocument, options);
      }
export type CreateItemFromItemRecommendationMutationHookResult = ReturnType<typeof useCreateItemFromItemRecommendationMutation>;
export type CreateItemFromItemRecommendationMutationResult = Apollo.MutationResult<CreateItemFromItemRecommendationMutation>;
export type CreateItemFromItemRecommendationMutationOptions = Apollo.BaseMutationOptions<CreateItemFromItemRecommendationMutation, CreateItemFromItemRecommendationMutationVariables>;