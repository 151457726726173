import moment from "moment";
import React from "react";
import { useTranslate } from "@tolgee/react";

interface Props {
  report: {
    fromDate: any;
    tillDate: any;
    number: string;
  };
}

export const TimeReportTitle = ({ report }: Props) => {
  const { t } = useTranslate("TimeReports");
  return (
    /* prettier-ignore */
    <>{`${t("TimeReport")}_${report.number}_${moment(report.fromDate).format("YYYY-MM-DD")}_${moment(report.tillDate).format("YYYY-MM-DD")}`}</>
  );
};
