import { gql } from "@apollo/client";
import { StatusChip, StatusLabel } from "@msys/ui";
import { Tooltip, useTheme } from "@mui/material";
import React, { FC } from "react";
import { Agreement } from "../../../clients/graphqlTypes";
import { QuoteStatusChip_QuoteFragment } from "./QuoteStatusBadge.generated";
import { useTranslate } from "@tolgee/react";

export const ProjectOverviewQuoteDraftBadge: FC<{
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}> = props => {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  return (
    <StatusLabel color={theme.palette.grey[600]} {...props}>
      {t("Draft")}
    </StatusLabel>
  );
};

export const ProjectOverviewQuoteNonBindingBadge: FC<{
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}> = props => {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  return (
    <StatusLabel color={theme.palette.text.secondary} {...props}>
      {t("Non-Binding Quote")}
    </StatusLabel>
  );
};

export const ProjectOverviewQuoteBindingBadge: FC<{
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}> = props => {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  return (
    <StatusLabel color={theme.palette.text.primary} {...props}>
      {t("Binding Quote")}
    </StatusLabel>
  );
};

export const ProjectOverviewQuoteDeclinedBadge: FC<{
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}> = props => {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  return (
    <StatusLabel color={theme.palette.error.main} {...props}>
      {t("Declined")}
    </StatusLabel>
  );
};

export const ProjectOverviewQuoteChangePendingBadge: FC<{
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}> = props => {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  return (
    <StatusLabel color={theme.palette.warning.main} {...props}>
      {t("Change Pending")}
    </StatusLabel>
  );
};

export const ProjectOverviewQuoteAcceptedBadge: FC<{
  align?: React.ComponentProps<typeof StatusLabel>["align"];
}> = props => {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  return (
    <StatusLabel color={theme.palette.success.main} {...props}>
      {t("Accepted")}
    </StatusLabel>
  );
};

export const QuoteStatusBadge = ({
  small,
  align,
  tooltip,
  quote,
}: {
  small?: boolean;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  tooltip?: string;
  quote: {
    isPublished: boolean;
    isBinding: boolean;
    needsAgreement: boolean;
    agreement: Agreement;
  };
}) => {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  if (!quote.isPublished) {
    return (
      <StatusLabel
        data-test-id="quote-status-label"
        color={theme.palette.grey[600]}
        align={align}
        small={small}
      >
        {t("Draft")}
      </StatusLabel>
    );
  }

  if (quote.isBinding === false) {
    const label = (
      <StatusLabel
        data-test-id="quote-status-label"
        color={theme.palette.text.secondary}
        align={align}
        small={small}
      >
        {t("Non-Binding Quote")}
      </StatusLabel>
    );

    if (tooltip) {
      return <Tooltip title={tooltip}>{label}</Tooltip>;
    }

    return label;
  }

  if (quote.agreement === "NONE") {
    return (
      <StatusLabel
        data-test-id="quote-status-label"
        color={theme.palette.text.primary}
        align={align}
        small={small}
      >
        {t("Binding Quote")}
      </StatusLabel>
    );
  }

  if (quote.agreement === "NO") {
    return (
      <StatusLabel
        data-test-id="quote-status-label"
        color={theme.palette.error.main}
        align={align}
        small={small}
      >
        {t("Declined")}
      </StatusLabel>
    );
  }

  if (quote.needsAgreement) {
    return (
      <StatusLabel
        data-test-id="quote-status-label"
        color={theme.palette.warning.main}
        align={align}
        small={small}
      >
        {t("Change Pending")}
      </StatusLabel>
    );
  }

  return (
    <StatusLabel
      data-test-id="quote-status-label"
      color={theme.palette.success.main}
      align={align}
      small={small}
    >
      {t("Accepted")}
    </StatusLabel>
  );
};

export function IncomingQuoteStatusBadge({
  small,
  align,
  tooltip,
  quote,
}: {
  small?: boolean;
  align?: React.ComponentProps<typeof StatusLabel>["align"];
  tooltip?: string;
  quote: {
    agreement: Agreement;
    needsAgreement: boolean;
  };
}) {
  const { t } = useTranslate("QuoteStateBadge");
  const theme = useTheme();

  const [text, color] =
    quote.agreement === "NONE"
      ? [t("Received"), theme.palette.secondary.main]
      : quote.agreement === "NO"
      ? [t("Declined"), theme.palette.error.main]
      : quote.needsAgreement
      ? [t("Change Pending"), theme.palette.warning.main]
      : [t("Accepted"), theme.palette.success.main];

  return (
    <StatusLabel color={color} align={align} small={small}>
      {text}
    </StatusLabel>
  );
}

export const QuoteStatusChip = ({
  quote,
  size,
}: {
  quote: QuoteStatusChip_QuoteFragment;
  size?: React.ComponentProps<typeof StatusChip>["size"];
}) => {
  const { t } = useTranslate("QuoteStateBadge");

  if (!quote.isPublished) {
    return (
      <StatusChip
        data-test-id="quote-status-chip"
        label={t("Draft")}
        color={"default"}
        size={size}
      />
    );
  }

  if (quote.isBinding === false) {
    return (
      <StatusChip
        data-test-id="quote-status-chip"
        label={t("Non-Binding Quote")}
        color={"secondary"}
        size={size}
      />
    );
  }

  if (quote.agreement === "NONE") {
    return (
      <StatusChip
        data-test-id="quote-status-chip"
        label={t("Binding Quote")}
        color={"primary"}
        size={size}
      />
    );
  }

  if (quote.agreement === "NO") {
    return (
      <StatusChip
        data-test-id="quote-status-chip"
        label={t("Declined")}
        color={"error"}
        size={size}
      />
    );
  }

  if (quote.needsAgreement) {
    return (
      <StatusChip
        data-test-id="quote-status-chip"
        label={t("Change Pending")}
        color={"warning"}
        size={size}
      />
    );
  }

  return (
    <StatusChip
      data-test-id="quote-status-chip"
      label={t("Accepted")}
      color={"success"}
      size={size}
    />
  );
};
