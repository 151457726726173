/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type GetItemPriceData_InvoiceItemFragment = { __typename: 'InvoiceItem', id: string, item: { __typename: 'Item', id: string, type: Types.ItemType, isRootItem: boolean, level: number, childrenVisibility: Types.ItemChildrenVisibility, isExplicitHidden: boolean }, calculation: { __typename: 'ItemCalculation', quantity: number, quantityUnit: Types.QuantityUnit, materialPricePerUnit: number, materialPriceSubTotal: number, workSellingRate: number, timeTotal: number, pricePerUnit: number, priceSubTotal: number, priceNetTotal: number } };

export type GetItemPriceData_InvoiceExtraItemFragment = { __typename: 'InvoiceExtraItem', id: string, quantity: number, quantityUnit: Types.QuantityUnit, pricePerUnit: number };

export type GetItemPriceData_InvoicePaymentItemFragment = { __typename: 'InvoicePaymentItem', id: string, price: number };

export type GetItemPriceData_QuoteItemFragment = { __typename: 'Item', id: string, childrenVisibility: Types.ItemChildrenVisibility, isExplicitHidden: boolean };

export type ShouldHidePrice_InvoiceItemFragment = { __typename: 'InvoiceItem', id: string, item: { __typename: 'Item', id: string, childrenVisibility: Types.ItemChildrenVisibility, isExplicitHidden: boolean } };

export type ShouldHidePrice_QuoteItemFragment = { __typename: 'Item', id: string, childrenVisibility: Types.ItemChildrenVisibility, isExplicitHidden: boolean };

export const ShouldHidePrice_QuoteItemFragmentDoc = gql`
    fragment ShouldHidePrice_QuoteItem on Item {
  id
  childrenVisibility
  isExplicitHidden
}
    `;
export const ShouldHidePrice_InvoiceItemFragmentDoc = gql`
    fragment ShouldHidePrice_InvoiceItem on InvoiceItem {
  __typename
  id
  item {
    id
    ...ShouldHidePrice_QuoteItem
  }
}
    ${ShouldHidePrice_QuoteItemFragmentDoc}`;
export const GetItemPriceData_InvoiceItemFragmentDoc = gql`
    fragment GetItemPriceData_InvoiceItem on InvoiceItem {
  __typename
  id
  item {
    id
    type
    isRootItem
    level
  }
  calculation {
    quantity
    quantityUnit
    materialPricePerUnit
    materialPriceSubTotal
    workSellingRate
    timeTotal
    pricePerUnit
    priceSubTotal
    priceNetTotal
  }
  ...ShouldHidePrice_InvoiceItem
}
    ${ShouldHidePrice_InvoiceItemFragmentDoc}`;
export const GetItemPriceData_InvoiceExtraItemFragmentDoc = gql`
    fragment GetItemPriceData_InvoiceExtraItem on InvoiceExtraItem {
  __typename
  id
  quantity
  quantityUnit
  pricePerUnit
}
    `;
export const GetItemPriceData_InvoicePaymentItemFragmentDoc = gql`
    fragment GetItemPriceData_InvoicePaymentItem on InvoicePaymentItem {
  __typename
  id
  price
}
    `;
export const GetItemPriceData_QuoteItemFragmentDoc = gql`
    fragment GetItemPriceData_QuoteItem on Item {
  id
  ...ShouldHidePrice_QuoteItem
}
    ${ShouldHidePrice_QuoteItemFragmentDoc}`;