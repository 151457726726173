import { useTranslate } from "@tolgee/react";
import { once } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { S4HanaCancelReasonSelectModal } from "./S4HanaCancelReasonSelectModal";

export interface S4HanaCancelReasonSelectProcessRef {
  startProcess: (
    props: S4HanaCancelReasonSelectModalProps
  ) => Promise<string | null | undefined>;
}

export interface S4HanaCancelReasonSelectModalProps
  extends Omit<
    React.ComponentProps<typeof S4HanaCancelReasonSelectModal>,
    "handleClose" | "handleComplete"
  > {}

const _S4HanaCancelReasonSelectProcess = (
  _: {},
  forwardedRef: React.Ref<S4HanaCancelReasonSelectProcessRef>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["SapS4Hana", "Global"]);

  const [props, setProps] =
    React.useState<S4HanaCancelReasonSelectModalProps | null>(null);
  const resolveRef = React.useRef<
    ((reasonCode: string | null | undefined) => void) | null
  >(null);

  React.useImperativeHandle(forwardedRef, () => ({
    startProcess: (props: S4HanaCancelReasonSelectModalProps) =>
      new Promise<string | null | undefined>(resolve => {
        /*if (!props.defaultReason) {
          enqueueSnackbar(
            t("No default cancel reason available", { ns: "SapS4Hana" }),
            { variant: "error" }
          );
          resolve(undefined);
          return;
        }*/
        if (props.isReasonRequired && !props.availableReasons.length) {
          enqueueSnackbar(
            t("No available cancel reasons available", { ns: "SapS4Hana" }),
            { variant: "error" }
          );
          resolve(undefined);
          return;
        }
        resolveRef.current = once(resolve);
        setProps(props);
      }),
  }));

  return props ? (
    <S4HanaCancelReasonSelectModal
      {...props}
      handleClose={() => {
        setProps(null);
        resolveRef.current?.(undefined);
      }}
      handleComplete={reasonCode => {
        resolveRef.current?.(reasonCode);
      }}
    />
  ) : null;
};

export const S4HanaCancelReasonSelectProcess = React.memo(
  React.forwardRef(_S4HanaCancelReasonSelectProcess)
);
