import { Chip, TextField, createFilterOptions } from "@mui/material";
import { Field, useField } from "formik";
import { Autocomplete, AutocompleteRenderInputParams } from "formik-mui";
import { useTranslate } from "@tolgee/react";

const filter = createFilterOptions();

export type TagType = "CUSTOM" | "SYSTEM";
export type TagOption = { type: TagType; value: string; inputValue?: string };

export interface TagsFieldProps {
  name: string;
  options: string[];
  // reservedOptions: string[];
  inputLabel: string;
  inputPlaceholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: string;
  noOptionsText?: string;
}

export const TagsField = ({
  name,
  disabled,
  required,
  inputLabel,
  inputPlaceholder,
  options,
  // reservedOptions,
  autoFocus,
  noOptionsText,
}: TagsFieldProps) => {
  const { t } = useTranslate("Global");
  const [, { value, touched, error }, { setValue, setTouched }] =
    useField<TagOption[]>(name);

  // const { reservedTagLabels } = useReservedTagLabels();

  // const autocompleteOptions = options.map(o => ({
  //   value: o,
  //   label: isTagReserved(o) ? reservedTagLabels[o] : o,
  // }));

  return (
    <Field
      component={Autocomplete}
      freeSolo
      multiple
      fullWidth
      openOnFocus
      clearOnBlur
      autoHighlight
      handleHomeEndKeys
      selectOnFocus={true}
      autoFocus={autoFocus}
      name={name}
      noOptionsText={noOptionsText}
      options={options.map(option => ({
        type: "CUSTOM" as const,
        value: option,
      }))}
      getOptionLabel={(o: TagOption | string) =>
        typeof o === "string" ? o : o.value
      }
      isOptionEqualToValue={(option: TagOption, value: TagOption) => {
        return option.value === value.value;
      }}
      disabled={disabled}
      required={required}
      error={touched && !!error}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          label={inputLabel}
          placeholder={inputPlaceholder}
          disabled={disabled}
          error={touched && !!error}
          helperText={touched ? error : undefined}
        />
      )}
      // @ts-ignore
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            value: t("Add “{name}”", {
              name: params.inputValue.trim().toLocaleUpperCase(),
            }),
            type: "CUSTOM",
          });
        }
        return filtered;
      }}
      renderTags={(value: (TagOption | string)[], getTagProps: any) =>
        value.map((value, index) => {
          const { onDelete, ...tagProps } = getTagProps({ index });

          return typeof value === "string" ? (
            <Chip
              key={value}
              variant="outlined"
              size="small"
              label={value}
              onDelete={onDelete}
              {...tagProps}
            />
          ) : (
            <Chip
              key={value.value}
              variant="outlined"
              size="small"
              color={value.type === "SYSTEM" ? "primary" : undefined}
              label={value.value}
              onDelete={value.type === "SYSTEM" ? undefined : onDelete} // we don't allow to remove system tags
              {...tagProps}
            />
          );
        })
      }
      value={value}
      onChange={(e: any, newValue: (TagOption | string)[]) => {
        const newTags = newValue
          .map(v =>
            typeof v === "string"
              ? { type: "CUSTOM" as const, value: v }
              : "inputValue" in v && v.inputValue
              ? { type: "CUSTOM" as const, value: v.inputValue }
              : v
          )
          .map(t => ({ ...t, value: t.value.toLocaleUpperCase().trim() }))
          .filter(t => t.value !== "");

        setValue(newTags);
        requestAnimationFrame(() => {
          setTouched(true);
        });
      }}
      disableCloseOnSelect
      disableClearable
    />
  );
};
