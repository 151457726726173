import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { LoadingSpinner as LoadingIndicator, Modal } from "@msys/ui";
import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../auth/useUserData";
import { ContractType } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";
import {
  getVatValues,
  useVatFieldValidationSchema,
} from "../../vat/form-fields/VatField";
import { TemplateQuoteSelectField } from "../templates/quote/TemplateQuoteSelectField";
import {
  useCreateRequirementModalQuery,
  useCreateRequirementMutation,
} from "./CreateRequirementModal.generated";

enum CreateQuoteSource {
  Empty = "empty",
  Template = "template",
  M1 = "m1",
}

interface FormValues {
  title: string;
  template: {
    id: string;
    title: string;
    owningSystemOrganisationId: string;
  } | null;
  contractType: ContractType;
}

interface Props {
  projectId: string;
  title?: string;
  handleClose: () => void;
  handleComplete?: (requirementId?: string) => Promise<void> | void;
  refetchQueries?: string[];
}

export const RequirementCreateModal = ({
  projectId,
  title,
  handleClose,
  handleComplete,
  refetchQueries,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["RequirementCreate", "Global", "Templates"]);

  const client = useApolloClient();
  const query = useCreateRequirementModalQuery({
    client,
    variables: {
      projectId: projectId,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);

  const templateCount =
    getDataOrNull(query.data?.quoteTemplates)?.totalCount ?? 0;
  const hasTemplateOptions = templateCount > 0;
  const initialTemplate =
    templateCount === 1
      ? getDataOrNull(query.data?.quoteTemplates)?.edges[0].node ?? null
      : null;

  // const [meister1ImportMultiBrandLead, { loading: m1Loading }] =
  //   useMeister1ImportMultiBrandLeadMutation({ client, refetchQueries });

  // TODO: use dedicated mutation for creating quote for opportunity?
  const [createRequirement] = useCreateRequirementMutation({
    client,
    refetchQueries,
  });

  const integrationOptions = React.useMemo(() => [], []);

  const isOnlyEmptyOptionAvailable = !hasTemplateOptions;

  const [source, setSource] = React.useState<CreateQuoteSource>(
    CreateQuoteSource.Empty
  );
  React.useEffect(() => {
    setSource(
      hasTemplateOptions ? CreateQuoteSource.Template : CreateQuoteSource.Empty
    );
  }, [hasTemplateOptions]);

  const handleSubmit = async (values: FormValues) => {
    try {
      let title = values.title;

      if (source === CreateQuoteSource.Template) {
        if (values.template?.title)
          title = `${values.template.title} - ${t("Requirement", {
            ns: "RequirementCreate",
          })}`;
      }

      const newQuote = await createRequirement({
        variables: {
          input: {
            projectId: projectId,
            title,
            templateQuoteId:
              source === CreateQuoteSource.Template
                ? values.template?.id ?? undefined
                : undefined,
          },
        },
      });

      if (!newQuote) {
        enqueueSnackbar(
          t("Error while creating requirement", {
            ns: "RequirementCreate",
          })
        );
        return;
      }

      const docId = newQuote.data?.createRequirement.requirement.id;

      if (!docId) {
        enqueueSnackbar(
          t("Error while creating requirement", {
            ns: "RequirementCreate",
          })
        );
        return;
      }

      enqueueSnackbar(
        t("Requirement created", {
          ns: "RequirementCreate",
        })
      );

      await handleComplete?.(docId);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Title", {
          ns: "Global",
        })
      )
      .required(),
    importRequirement: Yup.boolean(),
    discountPercentage: Yup.number()
      .label(
        t("Discount", {
          ns: "RequirementCreate",
        })
      )
      .min(0)
      .max(1),
    template: Yup.object()
      .shape({
        id: Yup.string().required(),
        title: Yup.string().required(),
      })
      .test(
        "templateRequired",
        t("Template must be selected", {
          ns: "RequirementCreate",
        }),
        function (template) {
          return !(source === CreateQuoteSource.Template && !template);
        }
      )
      .nullable(),
    integration: Yup.object()
      .test(
        "integrationRequired",
        t("Calculator must be selected", {
          ns: "RequirementCreate",
        }),
        function (integration) {
          return !(source === CreateQuoteSource.M1 && !integration);
        }
      )
      .nullable(),

    ...useVatFieldValidationSchema(),
  });

  const initialValues: FormValues = React.useMemo(
    () => ({
      title: `${project?.title ?? ""} - ${t("Requirement", {
        ns: "RequirementCreate",
      })}`,
      discountPercentage: 0,
      contractType: organisationDefaults?.defaultContractType ?? "fup",
      template: initialTemplate,
      integration:
        integrationOptions.length === 1 ? integrationOptions[0] : null,
      request: null,
      ...getVatValues(viewer.organisation.billingAddress?.countryCode),
    }),
    [
      t,
      project?.title,
      viewer.organisation.billingAddress?.countryCode,
      organisationDefaults?.defaultContractType,
      initialTemplate,
      integrationOptions,
    ]
  );

  const formId = React.useMemo(() => uniqueId(), []);

  const isAnyLoading = query.loading; // || m1Loading;

  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  React.useEffect(() => {
    formikRef.current?.validateForm();
  }, [source]);

  return (
    <Formik<FormValues>
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
    >
      {formikProps => (
        <Modal
          id={"requirement-create-modal"}
          title={t("Create new requirement", { ns: "RequirementCreate" })}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Create requirement", { ns: "RequirementCreate" }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid || isAnyLoading,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
          isLoading={isAnyLoading}
        >
          {isAnyLoading ? (
            <Stack direction="column" alignItems="center" spacing={1}>
              <LoadingIndicator />
              <Typography
                variant="body1"
                color="primary"
                align="center"
                sx={{ pb: 6 }}
              >
                {t("Requirements are being created, please wait...", {
                  ns: "RequirementCreate",
                })}
              </Typography>
            </Stack>
          ) : (
            <Form id={formId}>
              <Stack direction="column" spacing={1}>
                <Stack direction="column" spacing={1}>
                  {hasTemplateOptions && (
                    <>
                      <FormControlLabel
                        style={{ marginLeft: 0, marginRight: 0 }}
                        name="source"
                        control={
                          <Radio
                            name="source"
                            value={CreateQuoteSource.Template}
                            checked={source === CreateQuoteSource.Template}
                            onChange={() =>
                              setSource(CreateQuoteSource.Template)
                            }
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {t("Use a requirement template", {
                              ns: "RequirementCreate",
                            })}
                          </Typography>
                        }
                      />

                      {source === CreateQuoteSource.Template && (
                        <Box paddingLeft="42px">
                          <TemplateQuoteSelectField
                            inputLabel={t("Template", { ns: "Templates" })}
                            filterDocumentType="REQUIREMENT"
                            value={formikProps.values.template}
                            onChange={value => {
                              formikProps.setFieldValue("template", value);
                            }}
                          />
                        </Box>
                      )}
                    </>
                  )}

                  {!isOnlyEmptyOptionAvailable && (
                    <FormControlLabel
                      style={{ marginLeft: 0, marginRight: 0 }}
                      name="source"
                      control={
                        <Radio
                          name="source"
                          value={CreateQuoteSource.Empty}
                          checked={source === CreateQuoteSource.Empty}
                          onChange={() => setSource(CreateQuoteSource.Empty)}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {t("Start with an empty requirement", {
                            ns: "RequirementCreate",
                          })}
                        </Typography>
                      }
                    />
                  )}
                  {source === CreateQuoteSource.Empty && (
                    <Stack
                      direction="column"
                      spacing={1}
                      paddingLeft={!isOnlyEmptyOptionAvailable ? "42px" : 0}
                    >
                      <Field
                        autoFocus
                        label={t("Title", {
                          ns: "Global",
                        })}
                        name="title"
                        component={TextField}
                        required
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Form>
          )}
        </Modal>
      )}
    </Formik>
  );
};
