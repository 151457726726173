import { CardContainer, useFormatting } from "@msys/ui";
import { Box, ListItem, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { PermissionName } from "../../../../clients/graphqlTypes";
import { RestrictedByDocumentPermissionWithDebug } from "../../../auth/RestrictedByDocumentPermission";
import { Stack } from "../../../commons/layout/Stack";
import { PurchaseOrderStatusBadge } from "../PurchaseOrderStatusBadge";
import { PurchaseOrderTitle } from "../PurchaseOrderTitle";
import { RelatedOrderItemFragment } from "./RelatedOrderItemsBox.generated";

interface Props {
  orderItems: RelatedOrderItemFragment[];
  project: { id: string };
  document: { viewerPermissions: PermissionName[] };
  isInitiallyClosed?: boolean;
}

export const RelatedOrderItemsBox = ({
  orderItems,
  project,
  document,
  isInitiallyClosed,
}: Props) => {
  const { t } = useTranslate("Materials");
  const { getFormattedPrice } = useFormatting();

  return orderItems.length > 0 ? (
    <CardContainer
      title={t("Included in purchase orders")}
      isExpandable
      itemCount={orderItems.length}
      isInitiallyClosed={isInitiallyClosed}
    >
      {orderItems.map((item, index) => {
        return (
          <ListItem
            key={item.id}
            divider={index < orderItems.length - 1}
            button
            component={Link}
            to={`/projects/${project.id}/materials/purchase-orders/${item.order.id}`}
          >
            <Stack flexDirection="column" flex={1}>
              <Stack
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography>
                  <PurchaseOrderTitle order={item.order} />
                </Typography>
                <PurchaseOrderStatusBadge order={item.order} />
              </Stack>
              <Stack justifyContent="space-between" alignItems="center">
                <Typography variant="caption">
                  <Stack>
                    <Box>{item.order?.supplier?.title ?? "-"}</Box>
                    <RestrictedByDocumentPermissionWithDebug
                      permission="MANAGE_QUOTES"
                      document={document}
                    >
                      <Box>{getFormattedPrice(item.unitPrice)}</Box>
                    </RestrictedByDocumentPermissionWithDebug>
                  </Stack>
                </Typography>
                <Typography variant="caption">
                  {`${item.quantityOrdered} ${item.quantityUnit}`}
                </Typography>
              </Stack>
            </Stack>
          </ListItem>
        );
      })}
    </CardContainer>
  ) : null;
};
