import { useApolloClient } from "@apollo/client";
import { Modal } from "@msys/ui";
import { Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import * as Yup from "yup";
import { useUserData } from "../../../auth/useUserData";
import { TextField } from "../../../commons/form-fields/TextField";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { useAddOrganisationContractingPdfsMutation } from "../sections/OrganisationContractingPdfsSection.generated";
import { useTranslate } from "@tolgee/react";

interface FormValues {
  title: string;
}

interface Props {
  organisationId: string;
  title: string;
  handleClose: () => void;
  handleComplete?: (organisationId: string) => Promise<void>;
}

export const AddOrganisationContractingPdfModal = ({
  organisationId,
  title,
  handleClose,
  handleComplete,
}: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const { enqueueSnackbar } = useSnackbar();

  const viewer = useUserData().currentUser!;

  const client = useApolloClient();

  const [addContractingPdf] = useAddOrganisationContractingPdfsMutation({
    client,
    refetchQueries: [namedOperations.Query.OrganisationSettingsDocuments],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      await addContractingPdf({
        variables: {
          input: {
            title: values.title,
          },
        },
      });
      enqueueSnackbar(
        t("New contracting PDF template added", {
          ns: "OrganisationSettings",
        })
      );
      handleComplete?.(organisationId);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const formId = React.useMemo(() => uniqueId(), []);

  const initialValues = React.useMemo(
    (): FormValues => ({
      title: "",
    }),
    []
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .required()
          .label(
            t("Title", {
              ns: "Global",
            })
          ),
      }),
    [t]
  );

  if (viewer.organisation.id !== organisationId)
    return <div>Not own organisation!</div>;

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => (
        <Modal
          title={title}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Create", {
                ns: "Global",
              }),
              buttonProps: {
                form: formId,
                type: "submit",
                disabled: !formikProps.isValid || !formikProps.dirty,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1} pt={1}>
              <TextField
                label={t("Title", {
                  ns: "Global",
                })}
                name="title"
              />
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
