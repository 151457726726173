/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillOfMaterialsQuotesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
}>;


export type BillOfMaterialsQuotesQuery = { projectOutgoingQuotes: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'ProjectOutgoingQuoteConnection', totalCount: number, edges: Array<{ __typename: 'OutgoingQuoteEdge', node: { __typename: 'OutgoingQuote', id: string, number: string, title: string } }> } };


export const BillOfMaterialsQuotesDocument = gql`
    query BillOfMaterialsQuotes($projectId: ID!) {
  projectOutgoingQuotes(limit: 0, projectId: $projectId) {
    ... on ProjectOutgoingQuoteConnection {
      edges {
        node {
          id
          number
          title
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useBillOfMaterialsQuotesQuery__
 *
 * To run a query within a React component, call `useBillOfMaterialsQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillOfMaterialsQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillOfMaterialsQuotesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useBillOfMaterialsQuotesQuery(baseOptions: Apollo.QueryHookOptions<BillOfMaterialsQuotesQuery, BillOfMaterialsQuotesQueryVariables> & ({ variables: BillOfMaterialsQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillOfMaterialsQuotesQuery, BillOfMaterialsQuotesQueryVariables>(BillOfMaterialsQuotesDocument, options);
      }
export function useBillOfMaterialsQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillOfMaterialsQuotesQuery, BillOfMaterialsQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillOfMaterialsQuotesQuery, BillOfMaterialsQuotesQueryVariables>(BillOfMaterialsQuotesDocument, options);
        }
export function useBillOfMaterialsQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BillOfMaterialsQuotesQuery, BillOfMaterialsQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BillOfMaterialsQuotesQuery, BillOfMaterialsQuotesQueryVariables>(BillOfMaterialsQuotesDocument, options);
        }
export type BillOfMaterialsQuotesQueryHookResult = ReturnType<typeof useBillOfMaterialsQuotesQuery>;
export type BillOfMaterialsQuotesLazyQueryHookResult = ReturnType<typeof useBillOfMaterialsQuotesLazyQuery>;
export type BillOfMaterialsQuotesSuspenseQueryHookResult = ReturnType<typeof useBillOfMaterialsQuotesSuspenseQuery>;
export type BillOfMaterialsQuotesQueryResult = Apollo.QueryResult<BillOfMaterialsQuotesQuery, BillOfMaterialsQuotesQueryVariables>;