import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { Autocomplete } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import { debounce } from "lodash";
import React from "react";
import { highlightWithBold } from "../../utils";
import { useCrmPersonSelectLazyQuery } from "./CrmPersonSelect.generated";

interface Props {
  crmCompanyId?: string | undefined | null;
  crmPersonId?: string | undefined | null;
  onChange: (crmPersonId: string) => void;
  inputLabel: string;
  isRequired?: boolean;
}

export const CrmPersonSelect = ({
  crmCompanyId,
  crmPersonId,
  onChange,
  inputLabel,
  isRequired = false,
}: Props) => {
  const { t } = useTranslate(["Global"]);

  const [inputValue, setInputValue] = React.useState("");

  const { highlightText, crmPersons, debouncedRefetch, loading, totalCount } =
    useCrmPersons(crmCompanyId, crmPersonId, inputValue, setInputValue);

  return (
    <Autocomplete
      placeholder={t("Type to search", { ns: "Global" })}
      options={[
        ...crmPersons,
        ...(totalCount > crmPersons.length
          ? [
              {
                title: t("Type to see more...", { ns: "Global" }),
                id: "__type_to_see_more__" as const,
              },
            ]
          : []),
      ]}
      getOptionDisabled={option => option.id === "__type_to_see_more__"}
      getOptionLabel={option =>
        "__typename" in option ? option.fullname : option.title
      }
      renderOption={(props, option) =>
        "__typename" in option ? (
          <li {...props} key={option.id}>
            {highlightText(option.fullname)}
          </li>
        ) : (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )
      }
      inputLabel={inputLabel}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setInputValue(value);
          debouncedRefetch(value);
          if (crmPersonId) onChange("");
        }
      }}
      required={isRequired}
      value={crmPersons.find(b => b.id === crmPersonId) ?? null}
      onChange={value => {
        if (value && "__typename" in value) {
          setInputValue(value?.fullname ?? "");
          onChange(value?.id ?? "");
        } else if (!value) {
          setInputValue("");
          onChange("");
        }
      }}
      loading={loading}
    />
  );
};

const LIMIT = 10;

function useCrmPersons(
  crmCompanyId: string | undefined | null,
  crmPersonId: string | undefined | null,
  inputValue: string,
  setInputValue: (value: string) => void
) {
  const client = useApolloClient();
  const [fetchQuery, query] = useCrmPersonSelectLazyQuery({
    client,
    variables: { limit: LIMIT },
  });

  const inputValueRef = React.useRef(inputValue);
  inputValueRef.current = inputValue;

  const refetch = React.useCallback(
    async (value: string) => {
      await fetchQuery({
        variables: {
          limit: LIMIT,
          searchTerm: value,
          filterCrmCompanyId: crmCompanyId,
        },
      });
    },
    [crmCompanyId, fetchQuery]
  );

  const debouncedRefetch = React.useMemo(
    () => debounce(refetch, 500),
    [refetch]
  );

  React.useEffect(() => {
    (async () => {
      if (crmPersonId) {
        const result = await fetchQuery({
          variables: {
            limit: 1,
            filterIds: [crmPersonId],
          },
        });
        setInputValue(
          getDataOrNull(result.data?.crmPersons)?.edges[0].node.fullname ?? ""
        );
      } else {
        await fetchQuery({
          variables: {
            limit: LIMIT,
            searchTerm: inputValueRef.current,
            filterCrmCompanyId: crmCompanyId,
          },
        });
      }
    })();
  }, [crmPersonId, setInputValue, fetchQuery, crmCompanyId]);

  const crmPersons =
    getDataOrNull((query.data ?? query.previousData)?.crmPersons)?.edges.map(
      edge => edge.node
    ) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.crmPersons)?.totalCount ??
    0;

  const highlightText = React.useCallback(
    (text: string | null) =>
      text && query?.variables?.searchTerm
        ? highlightWithBold(text, query.variables.searchTerm)
        : text,
    [query?.variables?.searchTerm]
  );

  return {
    highlightText,
    crmPersons,
    refetch,
    debouncedRefetch,
    loading:
      query.loading ||
      Boolean(
        inputValue &&
          query?.variables?.searchTerm &&
          query?.variables?.searchTerm !== inputValue
      ),
    totalCount,
  };
}
