import { gql } from "@apollo/client";
import { CardContainer } from "@msys/ui";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Box } from "@mui/material";
import { CrmCompanyCommentsBox_ChannelFragment } from "./CrmCompanyCommentsBox.generated";
import { useTranslate } from "@tolgee/react";
import { Channel } from "../../channels/Channel";

interface Props {
  crmCompanyId: string;
  channels: CrmCompanyCommentsBox_ChannelFragment[];
  isLoading: boolean;
}

export const CrmCompanyCommentsBox = ({
  crmCompanyId,
  channels,
  isLoading,
}: Props) => {
  const { t } = useTranslate("Channel");

  const channelId = channels[0]?.id;

  return (
    <CardContainer
      Icon={<RateReviewIcon />}
      title={t("Comments")}
      isExpandable
      isInitiallyClosed={false}
      isLoading={isLoading}
    >
      {channelId && (
        <Box
          sx={{
            height: {
              xs: "360px",
              md: "400px",
            },
          }}
          display="flex"
          flexDirection="column"
        >
          <Channel
            channelId={channelId}
            inputPlaceholder={t("Add comment...")}
            loadPreviousMessagesLabel={t("Load previous comments")}
            noMessagesTitle={t("There are no comments yet")}
            layout="COMMENTS"
          />
        </Box>
      )}
    </CardContainer>
  );
};
