import { CardContainer, ListItem } from "@msys/ui";
import { Icon, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Link } from "react-router-dom";
import { ReactComponent as SubcontractingIcon } from "../../../assets/icons/icon-file-move.svg";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import { RequestCreateButton } from "../../requests/buttons/RequestCreateButton";
import { RequestQuoteForOutsourcingButton } from "../../requests/buttons/RequestQuoteForOutsourcingButton";
import { RequestQuoteForRequirementButton } from "../../requests/buttons/RequestQuoteForRequirementButton";
import { OutgoingRequestListItem } from "../../requests/RequestListItem";
import { ProjectOverviewOutgoingRequestsBox_ProjectFragment } from "./ProjectOverviewOutgoingRequestsBox.generated";

interface Props {
  project: ProjectOverviewOutgoingRequestsBox_ProjectFragment;
}

interface Props {}

export const ProjectOverviewOutgoingRequestsBox = ({ project }: Props) => {
  const { t } = useTranslate("ProjectOverview");

  const requests = project?.outgoingQuoteRequests ?? [];

  const visibleRequests = requests.filter(
    request =>
      request.status !== "WITHDRAWN" &&
      (request.status !== "CLOSED" ||
        request.quotes.find(quote => quote.agreement === "NONE"))
  );

  if (visibleRequests.length === 0) return null;

  return (
    <CardContainer
      Icon={
        <Icon>
          <SubcontractingIcon />
        </Icon>
      }
      title={t("Open quote requests")}
      isExpandable
      isInitiallyClosed={false}
      ActionButton={
        project ? (
          <Stack direction={"row"} spacing={0.5}>
            <RestrictedByCapabilityWithDebug
              capability="QUOTING"
              otherwise={
                <RestrictedByProjectPermissionWithDebug
                  permission="SHARE_REQUIREMENTS"
                  project={project}
                >
                  <RequestQuoteForRequirementButton
                    projectId={project.id}
                    Button={<RequestCreateButton type="icon" />}
                  />
                </RestrictedByProjectPermissionWithDebug>
              }
            >
              <RestrictedByProjectPermissionWithDebug
                permission="DECIDE_QUOTES"
                project={project}
              >
                <RequestQuoteForOutsourcingButton
                  projectId={project.id}
                  Button={<RequestCreateButton type="icon" />}
                />
              </RestrictedByProjectPermissionWithDebug>
            </RestrictedByCapabilityWithDebug>
          </Stack>
        ) : undefined
      }
      itemCount={visibleRequests.length}
    >
      {visibleRequests.map(request => (
        <ListItem
          key={request.id}
          component={Link}
          // @ts-ignore
          to={`/projects/${project.id}/requests/${request.id}`}
        >
          <OutgoingRequestListItem request={request} />
        </ListItem>
      ))}
    </CardContainer>
  );
};
