/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpenMasterdataAvailabilityQueryVariables = Types.Exact<{
  msOrganisationId: Types.Scalars['ID']['input'];
}>;


export type OpenMasterdataAvailabilityQuery = { openMasterdataAvailability?: { __typename: 'OpenMasterdataAvailability', itekCompanyId: string, usernameRequired: boolean, clientIdRequired: boolean, passwordRequired: boolean, isAuthenticated: boolean } | null };

export type OpenMasterdataUpdateSupplierProductMutationVariables = Types.Exact<{
  input: Types.OpenMasterdataUpdateSupplierProductInput;
}>;


export type OpenMasterdataUpdateSupplierProductMutation = { openMasterdataUpdateSupplierProduct: { __typename: 'OpenMasterdataUpdateSupplierProductResult', product: any } };


export const OpenMasterdataAvailabilityDocument = gql`
    query OpenMasterdataAvailability($msOrganisationId: ID!) {
  openMasterdataAvailability(msOrganisationId: $msOrganisationId) {
    itekCompanyId
    usernameRequired
    clientIdRequired
    passwordRequired
    isAuthenticated
  }
}
    `;

/**
 * __useOpenMasterdataAvailabilityQuery__
 *
 * To run a query within a React component, call `useOpenMasterdataAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenMasterdataAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenMasterdataAvailabilityQuery({
 *   variables: {
 *      msOrganisationId: // value for 'msOrganisationId'
 *   },
 * });
 */
export function useOpenMasterdataAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<OpenMasterdataAvailabilityQuery, OpenMasterdataAvailabilityQueryVariables> & ({ variables: OpenMasterdataAvailabilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenMasterdataAvailabilityQuery, OpenMasterdataAvailabilityQueryVariables>(OpenMasterdataAvailabilityDocument, options);
      }
export function useOpenMasterdataAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenMasterdataAvailabilityQuery, OpenMasterdataAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenMasterdataAvailabilityQuery, OpenMasterdataAvailabilityQueryVariables>(OpenMasterdataAvailabilityDocument, options);
        }
export function useOpenMasterdataAvailabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OpenMasterdataAvailabilityQuery, OpenMasterdataAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OpenMasterdataAvailabilityQuery, OpenMasterdataAvailabilityQueryVariables>(OpenMasterdataAvailabilityDocument, options);
        }
export type OpenMasterdataAvailabilityQueryHookResult = ReturnType<typeof useOpenMasterdataAvailabilityQuery>;
export type OpenMasterdataAvailabilityLazyQueryHookResult = ReturnType<typeof useOpenMasterdataAvailabilityLazyQuery>;
export type OpenMasterdataAvailabilitySuspenseQueryHookResult = ReturnType<typeof useOpenMasterdataAvailabilitySuspenseQuery>;
export type OpenMasterdataAvailabilityQueryResult = Apollo.QueryResult<OpenMasterdataAvailabilityQuery, OpenMasterdataAvailabilityQueryVariables>;
export const OpenMasterdataUpdateSupplierProductDocument = gql`
    mutation OpenMasterdataUpdateSupplierProduct($input: OpenMasterdataUpdateSupplierProductInput!) {
  openMasterdataUpdateSupplierProduct(input: $input) {
    product
  }
}
    `;
export type OpenMasterdataUpdateSupplierProductMutationFn = Apollo.MutationFunction<OpenMasterdataUpdateSupplierProductMutation, OpenMasterdataUpdateSupplierProductMutationVariables>;

/**
 * __useOpenMasterdataUpdateSupplierProductMutation__
 *
 * To run a mutation, you first call `useOpenMasterdataUpdateSupplierProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenMasterdataUpdateSupplierProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openMasterdataUpdateSupplierProductMutation, { data, loading, error }] = useOpenMasterdataUpdateSupplierProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenMasterdataUpdateSupplierProductMutation(baseOptions?: Apollo.MutationHookOptions<OpenMasterdataUpdateSupplierProductMutation, OpenMasterdataUpdateSupplierProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenMasterdataUpdateSupplierProductMutation, OpenMasterdataUpdateSupplierProductMutationVariables>(OpenMasterdataUpdateSupplierProductDocument, options);
      }
export type OpenMasterdataUpdateSupplierProductMutationHookResult = ReturnType<typeof useOpenMasterdataUpdateSupplierProductMutation>;
export type OpenMasterdataUpdateSupplierProductMutationResult = Apollo.MutationResult<OpenMasterdataUpdateSupplierProductMutation>;
export type OpenMasterdataUpdateSupplierProductMutationOptions = Apollo.BaseMutationOptions<OpenMasterdataUpdateSupplierProductMutation, OpenMasterdataUpdateSupplierProductMutationVariables>;