import { gql, useApolloClient } from "@apollo/client";
import { getPictures } from "@msys/common";
import {
  CardContainer,
  getFormattedDateTime,
  isImageOr3dModel,
  LabeledHtmlValue,
  LabeledTextValue,
  processAttachment,
} from "@msys/ui";
import { Divider, Typography } from "@mui/material";
import { useTolgee, useTranslate } from "@tolgee/react";
import { Field, Form, Formik } from "formik";
import { TextField } from "../../../commons/form-fields/TextField";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { AutoSave } from "../../../commons/form-fields/AutoSave";
import { RichTextField } from "../../../commons/form-fields/RichTextField";
import { PictureGallery } from "../../../commons/images/PictureGallery";
import { Stack } from "../../../commons/layout/Stack";
import { PickBrandButton } from "../buttons/PickBrandButton";
import { PickBrandLineButton } from "../buttons/PickBrandLineButton";
import { PickProductTypeButton } from "../buttons/PickProductTypeButton";
import {
  ProductOverview__ProductFragment,
  usePimSetManufacturerProductProductTypeMutation,
  usePimSetSupplierProductProductTypeMutation,
  usePimUpdateProductMutation,
} from "../Product.generated";
import {
  ProductOverviewHeaderBox__PimProductMetaInfoFragment,
  usePimSetSupplierProductBrandingMutation,
} from "./ProductOverviewHeaderBox.generated";

interface FormValues {
  title: string;
  description: string;
  extendedDescription: string;
  dimensionText: string;
  marketingText: string;
  gtin: string;
  manufacturerArticleNumber: string;
}

interface Props {
  product: ProductOverview__ProductFragment;
  productMetaInfo: ProductOverviewHeaderBox__PimProductMetaInfoFragment | null;
  isEditable?: boolean;
  refetchQueries?: string[];
}

export const ProductOverviewHeaderBox = ({
  product,
  productMetaInfo,
  isEditable,
  refetchQueries,
}: Props) => {
  const client = useApolloClient();
  const { t } = useTranslate(["ProductOverview", "Global"]);

  const [modifyProduct] = usePimUpdateProductMutation({ client });

  const [setSupplierProductProductType] =
    usePimSetSupplierProductProductTypeMutation({ client, refetchQueries });

  const [setSupplierProductBranding] = usePimSetSupplierProductBrandingMutation(
    {
      client,
      refetchQueries,
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const attachments = product.attachments.filter(attachment => {
    if (attachment.group === "3dItemDefinition") {
      return (
        attachment.title === "thumbnail.jpg" ||
        attachment.title === "thumbnail.png"
      );
    }
    return true;
  });
  const pictures = attachments.map(processAttachment).filter(isImageOr3dModel);

  async function onProductTypeChange(productTypeId: string | null) {
    await setSupplierProductProductType({
      variables: {
        input: {
          articleNumber: product.articleNumber,
          supplierId: product.supplierId,
          productTypeId: productTypeId,
        },
      },
    });
  }

  async function onBrandChange(brandId: string | null) {
    await setSupplierProductBranding({
      variables: {
        input: {
          articleNumber: product.articleNumber,
          supplierId: product.supplierId,
          branding: brandId
            ? {
                brandId: brandId,
              }
            : null,
        },
      },
    });
  }

  async function onBrandLineChange(
    brandId: string | null,
    brandLineId: string | null
  ) {
    await setSupplierProductBranding({
      variables: {
        input: {
          articleNumber: product.articleNumber,
          supplierId: product.supplierId,
          branding: brandId
            ? {
                brandId: brandId,
                brandLineId: brandLineId,
              }
            : null,
        },
      },
    });
  }

  const initialValues: FormValues = {
    title: product.texts?.title ?? "",
    description: product.texts?.description ?? "",
    extendedDescription: product.texts?.extendedDescription ?? "",
    dimensionText: product.texts?.dimensionText ?? "",
    marketingText: product.texts?.marketingText ?? "",
    gtin: product.gtin ?? "",
    manufacturerArticleNumber: product.manufacturerArticleNumber ?? "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label(
        t("Title", {
          ns: "ProductOverview",
        })
      )
      .required(),
    description: Yup.string().label(
      t("Description", {
        ns: "ProductOverview",
      })
    ),
    extendedDescription: Yup.string().label(
      t("Extended Description", {
        ns: "ProductOverview",
      })
    ),
    dimensionText: Yup.string().label(
      t("Dimension Text", {
        ns: "ProductOverview",
      })
    ),
    marketingText: Yup.string().label(
      t("Marketing Text", {
        ns: "ProductOverview",
      })
    ),
    gtin: Yup.string().label(
      t("Gtin", {
        ns: "ProductOverview",
      })
    ),
    manufacturerArticleNumber: Yup.string().label(
      t("Manufacturer article number", {
        ns: "ProductOverview",
      })
    ),
  });

  return (
    // <ShowBox
    //   box={{
    //     name: "project-overview-header",
    //     project,
    //   }}
    // >
    // </ShowBox>
    <CardContainer>
      <Stack p={1} flexDirection="column">
        <PictureGallery
          pictures={pictures}
          showAdd={false}
          showDelete={false}
          showRotate={false}
          showUpload={false}
        />

        {isEditable ? (
          <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async values => {
              try {
                await modifyProduct({
                  variables: {
                    input: {
                      productArticleNumber: product.articleNumber,
                      productSupplierId: product.supplierId,
                      values: {
                        texts: {
                          title: values.title,
                          description: values.description,
                          extendedDescription: values.extendedDescription,
                          dimensionText: values.dimensionText,
                          marketingText: values.marketingText,
                        },
                        gtin: values.gtin,
                        manufacturerArticleNumber:
                          values.manufacturerArticleNumber,
                      },
                    },
                  },
                  refetchQueries,
                });
              } catch (error) {
                if (error instanceof Error)
                  enqueueSnackbar(error.message, { variant: "error" });
              }
            }}
          >
            {() => (
              <Form>
                <Stack flexDirection="column">
                  <TextField
                    name="title"
                    label={t("Title", {
                      ns: "ProductOverview",
                    })}
                    disabled={!isEditable}
                    required
                  />
                  <RichTextField
                    disabled={!isEditable}
                    name="description"
                    label={t("Description", {
                      ns: "ProductOverview",
                    })}
                  />
                  <RichTextField
                    disabled={!isEditable}
                    name="extendedDescription"
                    label={t("Extended Description", {
                      ns: "ProductOverview",
                    })}
                  />
                  <RichTextField
                    disabled={!isEditable}
                    name="dimensionText"
                    label={t("Dimension Text", {
                      ns: "ProductOverview",
                    })}
                  />
                  <RichTextField
                    disabled={!isEditable}
                    name="marketingText"
                    label={t("Marketing Text", {
                      ns: "ProductOverview",
                    })}
                  />
                  <Divider />

                  <TextField
                    disabled={!isEditable}
                    name="gtin"
                    label={t("Gtin", {
                      ns: "ProductOverview",
                    })}
                  />
                  <TextField
                    disabled={!isEditable}
                    name="manufacturerArticleNumber"
                    label={t("Manufacturer article number", {
                      ns: "ProductOverview",
                    })}
                  />

                  <PickProductTypeButton
                    label={t("Pick product type", {
                      ns: "ProductOverview",
                    })}
                    valueLabel={t("Product type", {
                      ns: "ProductOverview",
                    })}
                    productType={product.productType}
                    handleChange={(productType: { id: string } | null) =>
                      onProductTypeChange(productType ? productType.id : null)
                    }
                    canEdit={isEditable}
                  />

                  <PickBrandButton
                    label={t("Pick brand", {
                      ns: "ProductOverview",
                    })}
                    valueLabel={t("Brand", {
                      ns: "ProductOverview",
                    })}
                    brand={product.branding?.brand}
                    handleChange={(brand: { id: string } | null) =>
                      onBrandChange(brand ? brand.id : null)
                    }
                    canEdit={isEditable}
                  />

                  <PickBrandLineButton
                    label={t("Pick brandline", {
                      ns: "ProductOverview",
                    })}
                    valueLabel={t("Brandline", {
                      ns: "ProductOverview",
                    })}
                    brandLine={product.branding?.brandLine}
                    brandId={product.branding?.brand?.id}
                    handleChange={(
                      brandLine: { id: string; brandId: string } | null
                    ) =>
                      onBrandLineChange(
                        brandLine
                          ? brandLine.brandId
                          : product.branding?.brand?.id ?? null,
                        brandLine ? brandLine.id : null
                      )
                    }
                    canEdit={isEditable}
                  />

                  {productMetaInfo && (
                    <ProductMetaInfo productMetaInfo={productMetaInfo} />
                  )}

                  <AutoSave />
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <ProductOverviewData
            product={product}
            productMetaInfo={productMetaInfo}
          />
        )}
      </Stack>
    </CardContainer>
  );
};

const ProductOverviewData = ({
  product,
  productMetaInfo,
}: {
  product: ProductOverview__ProductFragment;
  productMetaInfo: ProductOverviewHeaderBox__PimProductMetaInfoFragment | null;
}) => {
  const { t } = useTranslate(["ProductOverview", "Global"]); // TODO

  return (
    <>
      {product.texts?.title && (
        <LabeledTextValue
          label={t("Title", { ns: "ProductOverview" })}
          text={product.texts.title}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}
      {(product.texts?.description || product.texts?.extendedDescription) && (
        <LabeledHtmlValue
          label={t("Description", { ns: "ProductOverview" })}
          html={
            product.texts?.extendedDescription?.startsWith(
              product.texts?.description ?? ""
            )
              ? product.texts.extendedDescription
              : [product.texts.description, product.texts.extendedDescription]
                  .filter(e => e)
                  .join(" ")
          }
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}
      {product.texts?.dimensionText && (
        <LabeledHtmlValue
          label={t("Dimension Text", { ns: "ProductOverview" })}
          html={product.texts.dimensionText}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}
      {product.texts?.marketingText && (
        <LabeledHtmlValue
          label={t("Marketing Text", { ns: "ProductOverview" })}
          html={product.texts.marketingText}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}

      {product.gtin && (
        <LabeledTextValue
          label={t("Gtin", { ns: "ProductOverview" })}
          text={product.gtin}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}

      {product.manufacturerArticleNumber && (
        <LabeledTextValue
          label={t("Manufacturer article number", { ns: "ProductOverview" })}
          text={product.manufacturerArticleNumber}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}

      {product.productType && (
        <LabeledTextValue
          label={t("Product type", { ns: "ProductOverview" })}
          text={product.productType.label}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}
      {product.branding?.brand && (
        <LabeledTextValue
          label={t("Brand", { ns: "ProductOverview" })}
          text={product.branding.brand.title}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}
      {product.branding?.brandLine && (
        <LabeledTextValue
          label={t("Brandline", { ns: "ProductOverview" })}
          text={product.branding.brandLine.title}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}

      {productMetaInfo && <ProductMetaInfo productMetaInfo={productMetaInfo} />}
    </>
  );
};

function ProductMetaInfo({
  productMetaInfo,
}: {
  productMetaInfo: ProductOverviewHeaderBox__PimProductMetaInfoFragment;
}) {
  const { t } = useTranslate(["ProductOverview", "Global"]);

  const language = useTolgee(["language"]).getLanguage()!;

  return (
    <Stack>
      {productMetaInfo?.deletedAt && (
        <LabeledTextValue
          label={t("Deleted at", { ns: "ProductOverview" })}
          text={getFormattedDateTime(productMetaInfo.deletedAt, language)}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}
      {!productMetaInfo?.deletedAt && productMetaInfo?.createdAt && (
        <LabeledTextValue
          label={t("Created at", { ns: "ProductOverview" })}
          text={getFormattedDateTime(productMetaInfo.createdAt, language)}
          notSetLabel={t("Not set", { ns: "Global" })}
          showMoreLabel={t("Show more", { ns: "Global" })}
          showLessLabel={t("Show less", { ns: "Global" })}
        />
      )}
      {!productMetaInfo?.deletedAt &&
        (productMetaInfo.importation ? (
          <LabeledTextValue
            label={t("Importation", { ns: "ProductOverview" })}
            text={
              productMetaInfo.importation.__typename === "PimImportation"
                ? productMetaInfo.importation.filename
                : productMetaInfo.importation.name
            }
            notSetLabel={t("Not set", { ns: "Global" })}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
          />
        ) : productMetaInfo.createdByUser ? (
          <LabeledTextValue
            label={t("Author", { ns: "ProductOverview" })}
            text={productMetaInfo.createdByUser.fullname}
            notSetLabel={t("Not set", { ns: "Global" })}
            showMoreLabel={t("Show more", { ns: "Global" })}
            showLessLabel={t("Show less", { ns: "Global" })}
          />
        ) : null)}
    </Stack>
  );
}
