/* eslint-disable */
import * as Types from '../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddPushNotificationTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type AddPushNotificationTokenMutation = { addPushNotificationToken: boolean };


export const AddPushNotificationTokenDocument = gql`
    mutation AddPushNotificationToken($token: String!) {
  addPushNotificationToken(token: $token)
}
    `;
export type AddPushNotificationTokenMutationFn = Apollo.MutationFunction<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>;

/**
 * __useAddPushNotificationTokenMutation__
 *
 * To run a mutation, you first call `useAddPushNotificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPushNotificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPushNotificationTokenMutation, { data, loading, error }] = useAddPushNotificationTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddPushNotificationTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>(AddPushNotificationTokenDocument, options);
      }
export type AddPushNotificationTokenMutationHookResult = ReturnType<typeof useAddPushNotificationTokenMutation>;
export type AddPushNotificationTokenMutationResult = Apollo.MutationResult<AddPushNotificationTokenMutation>;
export type AddPushNotificationTokenMutationOptions = Apollo.BaseMutationOptions<AddPushNotificationTokenMutation, AddPushNotificationTokenMutationVariables>;