import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItemWithIcon } from "@msys/ui";
import { Icon } from "@mui/material";
import { useTranslate } from "@tolgee/react";

interface Props {
  docId: string;
  itemId: string;
  disabled?: boolean;
  pasteItem: ((docId: string, parentItemId: string) => Promise<void>) | null;
}

export const PasteItemFromClipboardMenuItem = ({
  docId,
  itemId,
  disabled,
  pasteItem,
}: Props) => {
  const { t } = useTranslate("QuoteItem");

  return (
    <MenuItemWithIcon
      icon={
        <Icon>
          <FontAwesomeIcon icon={faClipboard} />
        </Icon>
      }
      disabled={disabled || !pasteItem}
      onClick={async () => {
        if (!pasteItem) throw new Error("Missing pasteItem function");
        await pasteItem(docId, itemId);
      }}
    >
      {t("Paste as subitem")}
    </MenuItemWithIcon>
  );
};
