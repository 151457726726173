/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationIntegrationsBox_IntegrationFragment = { __typename: 'Integration', id: string, integrationId: Types.IntegrationId, name: string, isForeignAccountIdRequired: boolean };

export type OrganisationIntegrationsBox_OrganisationIntegrationFragment = { __typename: 'OrganisationIntegration', id: string, foreignAccountId?: string | null, settings: any, integration: { __typename: 'Integration', id: string, integrationId: Types.IntegrationId, name: string, isForeignAccountIdRequired: boolean } };

export type OrganisationIntegrationsBoxAddMutationVariables = Types.Exact<{
  input: Types.AddOrganisationIntegrationInput;
}>;


export type OrganisationIntegrationsBoxAddMutation = { addOrganisationIntegration: { __typename: 'AddOrganisationIntegrationResult', organisationIntegration: { __typename: 'OrganisationIntegration', id: string } } };

export type OrganisationIntegrationsBoxRemoveMutationVariables = Types.Exact<{
  input: Types.RemoveOrganisationIntegrationInput;
}>;


export type OrganisationIntegrationsBoxRemoveMutation = { removeOrganisationIntegration: { __typename: 'RemoveOrganisationIntegrationResult', ok?: boolean | null } };

export type OrganisationIntegrationsBoxUpdateMutationVariables = Types.Exact<{
  input: Types.UpdateOrganisationIntegrationInput;
}>;


export type OrganisationIntegrationsBoxUpdateMutation = { updateOrganisationIntegration: { __typename: 'UpdateOrganisationIntegrationResult', organisationIntegration: { __typename: 'OrganisationIntegration', id: string } } };

export type M1ApiLeadResponseQueryVariables = Types.Exact<{
  leadId: Types.Scalars['Int']['input'];
}>;


export type M1ApiLeadResponseQuery = { m1ApiLeadResponse?: any | null };

export type OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganisationIntegrationsBox_AvailableIntegrationsQuery = { integrations: { __typename: 'IntegrationConnection', edges: Array<{ __typename: 'IntegrationEdge', node: { __typename: 'Integration', id: string, integrationId: Types.IntegrationId, name: string, isForeignAccountIdRequired: boolean } }> } | { __typename: 'MissingCapabilities' } };

export const OrganisationIntegrationsBox_IntegrationFragmentDoc = gql`
    fragment OrganisationIntegrationsBox_Integration on Integration {
  id
  integrationId
  name
  isForeignAccountIdRequired
}
    `;
export const OrganisationIntegrationsBox_OrganisationIntegrationFragmentDoc = gql`
    fragment OrganisationIntegrationsBox_OrganisationIntegration on OrganisationIntegration {
  id
  integration {
    id
    ...OrganisationIntegrationsBox_Integration
  }
  foreignAccountId
  settings
}
    ${OrganisationIntegrationsBox_IntegrationFragmentDoc}`;
export const OrganisationIntegrationsBoxAddDocument = gql`
    mutation OrganisationIntegrationsBoxAdd($input: AddOrganisationIntegrationInput!) {
  addOrganisationIntegration(input: $input) {
    organisationIntegration {
      id
    }
  }
}
    `;
export type OrganisationIntegrationsBoxAddMutationFn = Apollo.MutationFunction<OrganisationIntegrationsBoxAddMutation, OrganisationIntegrationsBoxAddMutationVariables>;

/**
 * __useOrganisationIntegrationsBoxAddMutation__
 *
 * To run a mutation, you first call `useOrganisationIntegrationsBoxAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationIntegrationsBoxAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationIntegrationsBoxAddMutation, { data, loading, error }] = useOrganisationIntegrationsBoxAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationIntegrationsBoxAddMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationIntegrationsBoxAddMutation, OrganisationIntegrationsBoxAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationIntegrationsBoxAddMutation, OrganisationIntegrationsBoxAddMutationVariables>(OrganisationIntegrationsBoxAddDocument, options);
      }
export type OrganisationIntegrationsBoxAddMutationHookResult = ReturnType<typeof useOrganisationIntegrationsBoxAddMutation>;
export type OrganisationIntegrationsBoxAddMutationResult = Apollo.MutationResult<OrganisationIntegrationsBoxAddMutation>;
export type OrganisationIntegrationsBoxAddMutationOptions = Apollo.BaseMutationOptions<OrganisationIntegrationsBoxAddMutation, OrganisationIntegrationsBoxAddMutationVariables>;
export const OrganisationIntegrationsBoxRemoveDocument = gql`
    mutation OrganisationIntegrationsBoxRemove($input: RemoveOrganisationIntegrationInput!) {
  removeOrganisationIntegration(input: $input) {
    ok
  }
}
    `;
export type OrganisationIntegrationsBoxRemoveMutationFn = Apollo.MutationFunction<OrganisationIntegrationsBoxRemoveMutation, OrganisationIntegrationsBoxRemoveMutationVariables>;

/**
 * __useOrganisationIntegrationsBoxRemoveMutation__
 *
 * To run a mutation, you first call `useOrganisationIntegrationsBoxRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationIntegrationsBoxRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationIntegrationsBoxRemoveMutation, { data, loading, error }] = useOrganisationIntegrationsBoxRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationIntegrationsBoxRemoveMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationIntegrationsBoxRemoveMutation, OrganisationIntegrationsBoxRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationIntegrationsBoxRemoveMutation, OrganisationIntegrationsBoxRemoveMutationVariables>(OrganisationIntegrationsBoxRemoveDocument, options);
      }
export type OrganisationIntegrationsBoxRemoveMutationHookResult = ReturnType<typeof useOrganisationIntegrationsBoxRemoveMutation>;
export type OrganisationIntegrationsBoxRemoveMutationResult = Apollo.MutationResult<OrganisationIntegrationsBoxRemoveMutation>;
export type OrganisationIntegrationsBoxRemoveMutationOptions = Apollo.BaseMutationOptions<OrganisationIntegrationsBoxRemoveMutation, OrganisationIntegrationsBoxRemoveMutationVariables>;
export const OrganisationIntegrationsBoxUpdateDocument = gql`
    mutation OrganisationIntegrationsBoxUpdate($input: UpdateOrganisationIntegrationInput!) {
  updateOrganisationIntegration(input: $input) {
    organisationIntegration {
      id
    }
  }
}
    `;
export type OrganisationIntegrationsBoxUpdateMutationFn = Apollo.MutationFunction<OrganisationIntegrationsBoxUpdateMutation, OrganisationIntegrationsBoxUpdateMutationVariables>;

/**
 * __useOrganisationIntegrationsBoxUpdateMutation__
 *
 * To run a mutation, you first call `useOrganisationIntegrationsBoxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationIntegrationsBoxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationIntegrationsBoxUpdateMutation, { data, loading, error }] = useOrganisationIntegrationsBoxUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganisationIntegrationsBoxUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationIntegrationsBoxUpdateMutation, OrganisationIntegrationsBoxUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationIntegrationsBoxUpdateMutation, OrganisationIntegrationsBoxUpdateMutationVariables>(OrganisationIntegrationsBoxUpdateDocument, options);
      }
export type OrganisationIntegrationsBoxUpdateMutationHookResult = ReturnType<typeof useOrganisationIntegrationsBoxUpdateMutation>;
export type OrganisationIntegrationsBoxUpdateMutationResult = Apollo.MutationResult<OrganisationIntegrationsBoxUpdateMutation>;
export type OrganisationIntegrationsBoxUpdateMutationOptions = Apollo.BaseMutationOptions<OrganisationIntegrationsBoxUpdateMutation, OrganisationIntegrationsBoxUpdateMutationVariables>;
export const M1ApiLeadResponseDocument = gql`
    query M1ApiLeadResponse($leadId: Int!) {
  m1ApiLeadResponse(leadId: $leadId)
}
    `;

/**
 * __useM1ApiLeadResponseQuery__
 *
 * To run a query within a React component, call `useM1ApiLeadResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useM1ApiLeadResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useM1ApiLeadResponseQuery({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useM1ApiLeadResponseQuery(baseOptions: Apollo.QueryHookOptions<M1ApiLeadResponseQuery, M1ApiLeadResponseQueryVariables> & ({ variables: M1ApiLeadResponseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<M1ApiLeadResponseQuery, M1ApiLeadResponseQueryVariables>(M1ApiLeadResponseDocument, options);
      }
export function useM1ApiLeadResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<M1ApiLeadResponseQuery, M1ApiLeadResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<M1ApiLeadResponseQuery, M1ApiLeadResponseQueryVariables>(M1ApiLeadResponseDocument, options);
        }
export function useM1ApiLeadResponseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<M1ApiLeadResponseQuery, M1ApiLeadResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<M1ApiLeadResponseQuery, M1ApiLeadResponseQueryVariables>(M1ApiLeadResponseDocument, options);
        }
export type M1ApiLeadResponseQueryHookResult = ReturnType<typeof useM1ApiLeadResponseQuery>;
export type M1ApiLeadResponseLazyQueryHookResult = ReturnType<typeof useM1ApiLeadResponseLazyQuery>;
export type M1ApiLeadResponseSuspenseQueryHookResult = ReturnType<typeof useM1ApiLeadResponseSuspenseQuery>;
export type M1ApiLeadResponseQueryResult = Apollo.QueryResult<M1ApiLeadResponseQuery, M1ApiLeadResponseQueryVariables>;
export const OrganisationIntegrationsBox_AvailableIntegrationsDocument = gql`
    query OrganisationIntegrationsBox_AvailableIntegrations {
  integrations(limit: 1000) {
    ... on IntegrationConnection {
      edges {
        node {
          ...OrganisationIntegrationsBox_Integration
        }
      }
    }
  }
}
    ${OrganisationIntegrationsBox_IntegrationFragmentDoc}`;

/**
 * __useOrganisationIntegrationsBox_AvailableIntegrationsQuery__
 *
 * To run a query within a React component, call `useOrganisationIntegrationsBox_AvailableIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationIntegrationsBox_AvailableIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationIntegrationsBox_AvailableIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationIntegrationsBox_AvailableIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationIntegrationsBox_AvailableIntegrationsQuery, OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationIntegrationsBox_AvailableIntegrationsQuery, OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables>(OrganisationIntegrationsBox_AvailableIntegrationsDocument, options);
      }
export function useOrganisationIntegrationsBox_AvailableIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationIntegrationsBox_AvailableIntegrationsQuery, OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationIntegrationsBox_AvailableIntegrationsQuery, OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables>(OrganisationIntegrationsBox_AvailableIntegrationsDocument, options);
        }
export function useOrganisationIntegrationsBox_AvailableIntegrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationIntegrationsBox_AvailableIntegrationsQuery, OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationIntegrationsBox_AvailableIntegrationsQuery, OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables>(OrganisationIntegrationsBox_AvailableIntegrationsDocument, options);
        }
export type OrganisationIntegrationsBox_AvailableIntegrationsQueryHookResult = ReturnType<typeof useOrganisationIntegrationsBox_AvailableIntegrationsQuery>;
export type OrganisationIntegrationsBox_AvailableIntegrationsLazyQueryHookResult = ReturnType<typeof useOrganisationIntegrationsBox_AvailableIntegrationsLazyQuery>;
export type OrganisationIntegrationsBox_AvailableIntegrationsSuspenseQueryHookResult = ReturnType<typeof useOrganisationIntegrationsBox_AvailableIntegrationsSuspenseQuery>;
export type OrganisationIntegrationsBox_AvailableIntegrationsQueryResult = Apollo.QueryResult<OrganisationIntegrationsBox_AvailableIntegrationsQuery, OrganisationIntegrationsBox_AvailableIntegrationsQueryVariables>;