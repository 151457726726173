/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type S4HanaQuoteDataHooksQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ID']['input'];
  quoteId: Types.Scalars['ID']['input'];
}>;


export type S4HanaQuoteDataHooksQuery = { quoteSapS4HanaData?: { __typename: 'QuoteSapS4HanaData', id: string, configurableProductSupplierCatalogueIds: Array<string> } | null };


export const S4HanaQuoteDataHooksDocument = gql`
    query S4HanaQuoteDataHooks($projectId: ID!, $quoteId: ID!) {
  quoteSapS4HanaData(projectId: $projectId, docId: $quoteId) {
    id
    configurableProductSupplierCatalogueIds
  }
}
    `;

/**
 * __useS4HanaQuoteDataHooksQuery__
 *
 * To run a query within a React component, call `useS4HanaQuoteDataHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4HanaQuoteDataHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4HanaQuoteDataHooksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useS4HanaQuoteDataHooksQuery(baseOptions: Apollo.QueryHookOptions<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables> & ({ variables: S4HanaQuoteDataHooksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>(S4HanaQuoteDataHooksDocument, options);
      }
export function useS4HanaQuoteDataHooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>(S4HanaQuoteDataHooksDocument, options);
        }
export function useS4HanaQuoteDataHooksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>(S4HanaQuoteDataHooksDocument, options);
        }
export type S4HanaQuoteDataHooksQueryHookResult = ReturnType<typeof useS4HanaQuoteDataHooksQuery>;
export type S4HanaQuoteDataHooksLazyQueryHookResult = ReturnType<typeof useS4HanaQuoteDataHooksLazyQuery>;
export type S4HanaQuoteDataHooksSuspenseQueryHookResult = ReturnType<typeof useS4HanaQuoteDataHooksSuspenseQuery>;
export type S4HanaQuoteDataHooksQueryResult = Apollo.QueryResult<S4HanaQuoteDataHooksQuery, S4HanaQuoteDataHooksQueryVariables>;