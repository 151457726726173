import {
  DebouncedSearchInput,
  ListHeader,
  ModalOpenButton,
  useScreenWidth,
} from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import {
  OrganisationProjectsSorting,
  ProjectStateMachineStatus,
} from "../../../clients/graphqlTypes";
import { useUserData } from "../../auth/useUserData";
import { SwitchCollectionViewButton } from "../../commons/button/SwitchCollectionViewButton";
import { FilterButton } from "../../commons/filters/FilterButton";
import { useFiltersAndPagination } from "../../commons/filters/useFiltersAndPagination";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../commons/hooks/useCollectionView";
import { Page, PageTopbarItem } from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PAGE_LIST_RESULTS_PER_PAGE } from "../../constants";
import {
  FilterRelevantToMe,
  OpportunitiesFilterModal,
} from "../../features/opportunities/OpportunitiesFilterModal";
import {
  Filters,
  OpportunitiesList,
} from "../../features/opportunities/OpportunitiesList";
import { defaultListItemDisplayConfig } from "../../features/opportunities/OpportunitiesSettingsModal";
import { OpportunityListItemDisplayConfig } from "../../features/opportunities/components/OpportunityListItem";
import { renderOpportunitiesFilterChips } from "../../features/opportunities/renderOpportunitiesFilterChips";
import { PROJECT_FILTER_MAP } from "../../features/projects/useProjectSources";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import { useUserPreference } from "../../features/users/useUserPreference";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list"];
const DEFAULT_SORTING: OrganisationProjectsSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

interface Props {
  submenuItems: PageTopbarItem[];
}

export function OpportunitiesPast({ submenuItems }: Props) {
  const { t } = useTranslate([
    "Opportunities",
    "Categories",
    "Global",
    "ProjectMembers",
  ]);

  const viewer = useUserData().currentUser!;

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "opportunities-past",
    "table",
    "list"
  );
  const { isMinTablet } = useScreenWidth();

  const {
    offset,
    limit,
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    toRemoveParams,
  } = useFiltersAndPagination<OrganisationProjectsSorting, Filters>(
    {},
    DEFAULT_SORTING,
    PAGE_LIST_RESULTS_PER_PAGE
  );

  const QUERY_BASE_VARIABLES = {
    offset,
    limit,
    sorting: DEFAULT_SORTING,
  };

  const allowedStates: ProjectStateMachineStatus[] = [
    "contractor_declined",
    "contractee_declined",
  ];

  const variables = {
    ...QUERY_BASE_VARIABLES,
    ...filters,
    filterBySources: filters.sources
      ? filters.sources.map(s => PROJECT_FILTER_MAP[s]).flat(1)
      : undefined,
    filterIncludeState: filters.filterIncludeState?.length
      ? filters.filterIncludeState
      : allowedStates,
    sorting,
  };

  const stateStore = useDataGridStateStore("OpportunitiesPast");
  const listItemDisplayConfigStore =
    useUserPreference<OpportunityListItemDisplayConfig>(
      `DisplayConfig-OpportunitiesCurrent`,
      defaultListItemDisplayConfig
    );

  return (
    <Page
      subtitle={t("Opportunities", { ns: "Opportunities" })}
      title={t("Past", { ns: "Opportunities" })}
      submenuItems={submenuItems}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          // title={title}
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterButton={
            <ModalOpenButton
              Modal={OpportunitiesFilterModal}
              modalProps={{
                sorting,
                setSorting,
                filters,
                setFilters,
                resetFilters,
                allowedStates,
                showRelevantToMe: !isMinTablet,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          FilterFields={
            isMinTablet ? (
              <FilterRelevantToMe filters={filters} setFilters={setFilters} />
            ) : undefined
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", {
                ns: "Global",
              })}
              defaultValue={filters.filterSearchTerm}
              onChangeSearchTerm={newValue =>
                setFilters(filters => ({
                  ...filters,
                  filterSearchTerm: newValue,
                }))
              }
            />
          }
          FilterChips={renderOpportunitiesFilterChips({
            viewer,
            t,
            filters,
            setFilters,
            allowedStates,
            showRelevantToMe: !isMinTablet,
          })}
          mb={2}
        />
        {!listItemDisplayConfigStore.loading && (
          <OpportunitiesList
            sorting={sorting}
            setSorting={setSorting}
            activeView={activeView}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            stateStore={stateStore}
            displayConfig={listItemDisplayConfigStore.value}
            variables={variables}
          />
        )}
      </PageContainer>
    </Page>
  );
}
