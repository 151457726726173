import { noop } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Agreement, PermissionName } from "../../../clients/graphqlTypes";
import { Stack } from "../../commons/layout/Stack";
import { REQUIREMENT_ITEM_TYPES } from "../../constants";
import { BareTreeItem } from "../../trees/BareTreeItem";
import { TreeRightIcons } from "../../trees/components/TreeRightIcons";
import { BuildingPlacementBox } from "../doc-items/boxes/BuildingPlacementBox";
import { DecisionManagementBox } from "../doc-items/boxes/DecisionManagementBox";
import { HeaderBox } from "../doc-items/boxes/HeaderBox";
import { ItemFilesBox } from "../doc-items/boxes/ItemFilesBox";
import { QuantityBox } from "../doc-items/boxes/QuantityBox";
import { RequiredOnCompletionBox } from "../doc-items/boxes/RequiredOnCompletionBox";
import {
  SubelementsBox,
  SubitemComponentProps,
} from "../doc-items/boxes/SubelementsBox";
import { getData123 } from "../projects/requirement-trees";
import {
  RequirementItem_ChildItemComponentFragment,
  RequirementItem_ChildItemFragment,
  RequirementItem_ItemFragment,
  RequirementItem_ProjectFragment,
  RequirementItem_RequirementFragment,
} from "./RequirementItem.generated";
import { shouldRenderRequirementCreateInput } from "./helpers";

interface Props {
  project: RequirementItem_ProjectFragment;
  requirement: RequirementItem_RequirementFragment;
  item: RequirementItem_ItemFragment;
  onUpdateDataRefetchQueries?: string[];
  pathToDocPage: string;
}

export const RequirementItem = ({
  project,
  requirement,
  item,
  onUpdateDataRefetchQueries,
  pathToDocPage,
}: Props) => {
  const navigate = useNavigate();
  const agreement: Agreement = "NONE";

  const ChildItemComponent = React.useMemo(
    () =>
      agreement !== undefined
        ? createChildItemComponent(
            pathToDocPage,
            { viewerPermissions: project.viewerPermissions },
            agreement
          )
        : () => null,
    [pathToDocPage, project.viewerPermissions]
  );

  return (
    <Stack flexDirection="column">
      <HeaderBox
        projectId={project.id}
        docId={requirement.id}
        docType="REQUIREMENT"
        item={item}
        viewMode={"edit"}
        setViewMode={noop}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />

      <QuantityBox item={item} docId={requirement.id} projectId={project.id} />

      {item.type === "decision" && (
        <DecisionManagementBox<RequirementItem_ChildItemFragment>
          item={item}
          doc={{
            id: requirement.id,
            docType: "REQUIREMENT",
            templateIsDraft: false,
            organisationId: requirement.owningSystemOrganisationId,
          }}
          projectId={project.id}
          onItemDeleted={() => {
            navigate(pathToDocPage, { replace: true });
          }}
        />
      )}

      <RequiredOnCompletionBox
        project={project}
        docId={requirement.id}
        document={requirement}
        item={item}
        canEdit={true}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />

      <ItemFilesBox
        projectId={project.id}
        docId={requirement.id}
        docType="REQUIREMENT"
        viewerPermissions={requirement.viewerPermissions}
        item={item}
        onUpdateDataRefetchQueries={onUpdateDataRefetchQueries}
      />

      <SubelementsBox<RequirementItem_ChildItemFragment>
        projectId={project.id}
        doc={{
          id: requirement.id,
          docType: "REQUIREMENT",
          templateIsDraft: false,
        }}
        item={item}
        subitemComponent={ChildItemComponent}
        pathToDocPage={pathToDocPage}
        documentItemTypes={REQUIREMENT_ITEM_TYPES}
        canChangeVisibility={false}
        shouldRenderCreateInput={shouldRenderRequirementCreateInput}
      />

      {item.placement && (
        <BuildingPlacementBox
          project={project}
          docId={requirement.id}
          item={item}
        />
      )}
    </Stack>
  );
};

const createChildItemComponent =
  <T extends RequirementItem_ChildItemComponentFragment>(
    pathToDocPage: string,
    document: {
      viewerPermissions: PermissionName[];
    },
    docAgreement: Agreement
  ) =>
  (itemProps: SubitemComponentProps<T>) => {
    const [Data123Left, Data123Right] = React.useMemo(
      () => getData123(itemProps.item, itemProps.isPriceHidden),
      [itemProps.item, itemProps.isPriceHidden]
    );

    const RightIcons = React.useMemo(
      () => (
        <TreeRightIcons
          document={document}
          item={itemProps.item}
          isVisibleToOtherSide={itemProps.item.isVisibleToOtherSide}
        />
      ),
      [itemProps.item]
    );

    return (
      <BareTreeItem
        docAgreement={docAgreement}
        Data123Left={Data123Left}
        Data123Right={Data123Right}
        RightIcons={RightIcons}
        showPath={!itemProps.item.isDecisionOption}
        item={itemProps.item}
        isRootItem={itemProps.isRootItem}
        depth={itemProps.depth}
        isGreyedOut={itemProps.isGreyedOut}
        isHidden={itemProps.isHidden}
        isPriceHidden={itemProps.isPriceHidden}
        onClick={itemProps.onClick}
        subcontractPath={null}
        to={
          itemProps.to !== undefined
            ? itemProps.to
            : `${pathToDocPage}/items/${itemProps.item.id}`
        }
      />
    );
  };
