import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import {
  ErrorMessage,
  Modal,
  ModalActionButtonProps,
  ModalOpenProcess,
  ModalOpenProcessRef,
  Select,
  useScreenWidth,
} from "@msys/ui";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { isEqual } from "lodash";
import React from "react";
import { useEffectOnce, useLockBodyScroll, useUpdateEffect } from "react-use";
import { ButtonCreate } from "../../../commons/button/Button";
import {
  CollectionView,
  useCollectionViewWithMobile,
} from "../../../commons/hooks/useCollectionView";
import { LayoutWithLeftColumn } from "../../../commons/layout/LayoutWithLeftColumn";
import { track } from "../../../track";
import { SectionTab } from "../../doc-items/SearchProductsAndTemplates";
import { SupplierCatalogueValue } from "../../suppliers/SupplierCatalogueSelect";
import { useDataGridStateStore } from "../../users/useDataGridStateStore";
import { OrganisationSupplierProductFavouriteButton } from "../buttons/OrganisationSupplierProductFavouriteButton";
import { ProductsListView } from "../components/ProductsListView";
import {
  isAnyFilterSet,
  ProductFiltersFormValues,
} from "../filters/ProductFilterFields";
import { productFiltersDefaultValue } from "../filters/ProductFilters";
import { useProductSearchType } from "../filters/useProductSearchTypes";
import { useCanCreateSupplierCatalogueProduct } from "../helper";
import { ProductSearchItem__ProductSearchResultFragment } from "../Product.generated";
import { ProductCategoryTreeChip } from "../ProductCategoryTreeChip";
import {
  ProductCategoryTreeSelect,
  useProductCategoryTree,
} from "../ProductCategoryTreeSelect";
import { ProductSearchLandingPage } from "../ProductSearchLandingPage";
import {
  ChildrenFn,
  ProductSearchListHeader,
} from "../ProductSearchListHeader";
import {
  ProductAskTypeProcess,
  ProductAskTypeProcessRef,
} from "./ProductAskTypeModal";
import {
  ProductCreateModal,
  ProductCreateModalProps,
} from "./ProductCreateModal";
import { ProductOverviewModal } from "./ProductOverviewModal";
import { useProductSearchModalQuery } from "./ProductSearchModal.generated";
import {
  SupplierCatalogueProductCreateModal,
  SupplierCatalogueProductCreateModalProps,
} from "./SupplierCatalogueProductCreateModal";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  initialSearchValue?: string;
  initialFilters?: Partial<ProductFiltersFormValues>;
  handleProductChoice: (
    product: Pick<
      ProductSearchItem__ProductSearchResultFragment,
      "articleNumber" | "supplierId"
    >,
    filters: ProductFiltersFormValues
  ) => Promise<void> | void;
  handleProductCreate?: ProductCreateModalProps["handleSubmit"];
  handleFiltersSave?: (values: ProductFiltersFormValues) => Promise<unknown>;
  handleClose: () => void;
  handleCancel?: () => void;
  cancelTitle?: string;
  canChangeProductType?: boolean;
}

export const ProductSearchModal = ({
  initialSearchValue = "",
  initialFilters,
  handleProductChoice,
  handleProductCreate,
  handleFiltersSave,
  handleClose,
  handleCancel,
  cancelTitle,
  canChangeProductType = true,
}: Props) => {
  const { t } = useTranslate(["Product", "Global", "ProductSearch"]);
  const { isMinTablet, isMinLargeDesktop } = useScreenWidth();

  const [productOverviewModalProduct, setProductOverviewModalProduct] =
    React.useState<{ articleNumber: string; supplierId: string } | null>(null);

  const { productSearchTypeOptions, productSearchType, setProductSearchType } =
    useProductSearchType();

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 25 });

  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  const initialFilterState = React.useMemo(
    () => ({
      ...productFiltersDefaultValue,
      ...initialFilters,
    }),
    [initialFilters]
  );
  const [filters, setFilters] =
    React.useState<ProductFiltersFormValues>(initialFilterState);

  const productSearchVariables = {
    searchTerm: productSearchType === "fulltextSearch" ? searchTerm : undefined,
    specificFieldQueryFields:
      productSearchType !== "fulltextSearch"
        ? [{ field: productSearchType, value: searchTerm }]
        : undefined,
    filters,
  };

  const [section, setSection] = React.useState<SectionTab>(
    Boolean(searchTerm) || isAnyFilterSet(filters) ? "results" : "landing"
  );

  // automatically switching to results when needed
  useEffectOnce(() => {
    if (Boolean(searchTerm) || isAnyFilterSet(filters)) {
      setSection("results");
    }
  });
  useUpdateEffect(() => {
    if (
      (Boolean(searchTerm) || isAnyFilterSet(filters)) &&
      section === "landing"
    )
      setSection("results");
  }, [searchTerm, filters]);

  const client = useApolloClient();
  const query = useProductSearchModalQuery({
    client,
    variables: {
      ...productSearchVariables,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
    },
    skip: section !== "results",
  });

  const resetFilters = () => {
    setFilters(initialFilterState);
  };

  const [activeView, setActiveView] = useCollectionViewWithMobile(
    "products-list-modal",
    "list",
    "list"
  );

  const products =
    getDataOrNull(
      (query.data ?? query.previousData)?.pimSearchProducts
    )?.edges.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.pimSearchProducts)
      ?.totalCount ?? 0;

  const onProductSelect = React.useCallback(
    (product: ProductSearchItem__ProductSearchResultFragment) => {
      track({
        eventName: "AddProduct",
        data: { product, variables: productSearchVariables },
      });
      handleProductChoice(product, filters);
      handleClose();
    },
    [handleProductChoice, handleClose, productSearchVariables, filters]
  );

  React.useEffect(() => {
    const totalCount = getDataOrNull(query.data?.pimSearchProducts)?.totalCount;
    if (
      totalCount &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const actionButtons: ModalActionButtonProps[] = [
    {
      label:
        cancelTitle ??
        t("Cancel", {
          ns: "Global",
        }),
      handleClick: () => {
        handleCancel?.();
        handleClose();
      },
      buttonProps: { variant: "text" },
    },
  ];
  if (handleFiltersSave) {
    actionButtons.push({
      label: t("Save filters", { ns: "ProductSearch" }),
      handleClick: () => {
        handleFiltersSave(filters);
        handleClose();
      },
      buttonProps: {
        variant: "contained",
        disabled:
          !filters.productTypeIds ||
          filters.productTypeIds.length === 0 ||
          isEqual(filters, initialFilters),
      },
    });
  }

  const [searchEnabled, setSearchEnabled] = React.useState<boolean>(false);
  useLockBodyScroll(searchEnabled);

  const theme = useTheme();

  const handleBackClick =
    section === "results"
      ? () => {
          resetFilters();
          setSection("landing");
        }
      : undefined;

  const handleSearchSubmit = () => {
    if (section === "landing") setSection("results");
  };

  const productCategoryProps = useProductCategoryTree(
    filters.supplierIds?.[0] ?? null,
    filters.supplierProductCategoryKey ?? null
  );

  const showCategoriesSelect = Boolean(
    productCategoryProps.supplier &&
      (productCategoryProps.childCategories.length ||
        productCategoryProps.productCategory)
  );

  const handleCategoryTreeSelect = ({
    supplierId,
    supplierProductCategoryKey,
  }: {
    supplierProductCategoryKey?: string | null;
    supplierId?: string | null;
  }) => {
    const newFilters = { ...filters };
    if (supplierId === null) {
      newFilters.supplierIds = null;
    }
    if (supplierProductCategoryKey !== undefined) {
      newFilters.supplierProductCategoryKey = supplierProductCategoryKey;
    }
    setFilters(newFilters);
  };

  return (
    <Modal
      maxWidth="xl"
      dialogProps={{
        PaperProps: { style: { height: "100%" } },
      }}
      handleClose={handleClose}
      title={t("Search Products", {
        ns: "ProductSearch",
      })}
      actionButtons={actionButtons}
      alwaysVisible
      titleProps={{
        sx: {
          position: "relative",
          zIndex: 2,
          backgroundColor: theme.palette.common.white,
        },
      }}
    >
      <ProductSearchModalListHeader
        initialFilterState={initialFilterState}
        filters={filters}
        setFilters={setFilters}
        resetFilters={resetFilters}
        hideFilters={section !== "results"}
        productSearchTypeOptions={productSearchTypeOptions}
        productSearchType={productSearchType}
        setProductSearchType={setProductSearchType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        activeView={activeView}
        setActiveView={setActiveView}
        productSearchVariables={productSearchVariables}
        handleClose={handleClose}
        handleProductCreate={handleProductCreate}
        handleSupplierCatalogueProductCreate={async (product, handleClose) => {
          await handleProductChoice(product, filters);
          handleClose();
        }}
        canChangeProductType={canChangeProductType}
        searchEnabled={searchEnabled}
        setSearchEnabled={setSearchEnabled}
        handleBackClick={handleBackClick}
        handleSearchSubmit={handleSearchSubmit}
        AdditionalChips={
          !isMinTablet &&
          showCategoriesSelect &&
          productCategoryProps.supplier ? (
            <ProductCategoryTreeChip
              supplier={productCategoryProps.supplier}
              productCategory={productCategoryProps.productCategory}
              handleSelect={handleCategoryTreeSelect}
            />
          ) : undefined
        }
      >
        {({ SearchLine, FiltersLine, ChipsLine, SearchBackdrop }) => (
          <Stack direction={"column"} height="100%" spacing={1}>
            <Box>
              <Box
                overflow="hidden"
                px={{ xs: 1, md: 1.5 }}
                mx={{ xs: -1, md: -1.5 }}
                pb={1}
                mb={-1}
                width="100%"
                boxSizing="content-box"
              >
                {SearchLine}
              </Box>
            </Box>
            {SearchBackdrop}
            {query.error && <ErrorMessage message={query.error.message} />}
            {section === "results" ? (
              <LayoutWithLeftColumn
                name="ProductSearchModal"
                leftColumn={
                  showCategoriesSelect ? (
                    <ProductCategoryTreeSelect
                      {...productCategoryProps}
                      categoryKey={filters.supplierProductCategoryKey ?? null}
                      onSelect={handleCategoryTreeSelect}
                    />
                  ) : undefined
                }
                spacing={0}
                leftColumnWidth={isMinLargeDesktop ? "xs" : "xxs"}
              >
                <Stack
                  direction={"column"}
                  height="100%"
                  width="100%"
                  spacing={1}
                  minHeight={0}
                  minWidth={0}
                >
                  {FiltersLine}
                  {ChipsLine}
                  <ProductsList
                    items={products}
                    totalCount={totalCount}
                    activeView={activeView}
                    loading={query.loading}
                    onSelectItem={setProductOverviewModalProduct}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    onProductSelect={onProductSelect}
                  />
                </Stack>
              </LayoutWithLeftColumn>
            ) : section === "landing" ? (
              <Box flex={1} overflow="auto">
                <ProductSearchLandingPage
                  onProductClick={product =>
                    setProductOverviewModalProduct(product)
                  }
                  renderProductSelectButton={product => (
                    <ButtonCircleWrapper>
                      <ProductSelectButton
                        product={product}
                        onProductSelect={onProductSelect}
                      />
                    </ButtonCircleWrapper>
                  )}
                  setProductSearchFilters={setFilters}
                />
              </Box>
            ) : null}
            {productOverviewModalProduct && (
              <ProductOverviewModal
                productArticleNumber={productOverviewModalProduct.articleNumber}
                productSupplierId={productOverviewModalProduct.supplierId}
                handleSubmit={async product => {
                  track({
                    eventName: "AddProduct",
                    data: { product, variables: productSearchVariables },
                  });
                  await handleProductChoice(product, filters);
                  handleClose();
                }}
                handleClose={() => setProductOverviewModalProduct(null)}
              />
            )}
          </Stack>
        )}
      </ProductSearchModalListHeader>
    </Modal>
  );
};

function ProductSearchModalListHeader({
  handleProductCreate,
  handleSupplierCatalogueProductCreate,
  handleClose,
  children,
  ...props
}: Omit<
  React.ComponentProps<typeof ProductSearchListHeader>,
  "ActionButtons" | "allowedViews" | "children"
> & {
  handleProductCreate?: ProductCreateModalProps["handleSubmit"];
  handleSupplierCatalogueProductCreate?: SupplierCatalogueProductCreateModalProps["handleComplete"];
  handleClose: () => void;
  children: ChildrenFn;
}) {
  const { t } = useTranslate(["Global", "ProductSearch"]);

  const [initialSupplierCatalogue, setInitialSupplierCatalogue] =
    React.useState<SupplierCatalogueValue | null>(null);

  const { canCreateSupplierCatalogueProduct } =
    useCanCreateSupplierCatalogueProduct();

  const productCreateRef = React.useRef<ModalOpenProcessRef>();
  const supplierCatalogueProductCreateRef = React.useRef<ModalOpenProcessRef>();
  const productAskTypeProcessRef =
    React.useRef<ProductAskTypeProcessRef | null>(null);

  const handleCreateNewProduct = async () => {
    if (
      handleProductCreate &&
      handleSupplierCatalogueProductCreate &&
      canCreateSupplierCatalogueProduct
    ) {
      // both possible - need to ask at first which product to create
      const values = await productAskTypeProcessRef.current?.open();
      if (values) {
        if (values.productType === "one-time") {
          productCreateRef.current?.open();
        } else if (values.productType === "catalogue") {
          setInitialSupplierCatalogue(values.supplierCatalogue || null);
          setTimeout(() => {
            supplierCatalogueProductCreateRef.current?.open();
          });
        }
      }
    } else if (handleProductCreate) {
      // one-time used product
      productCreateRef.current?.open();
    } else if (
      handleSupplierCatalogueProductCreate &&
      canCreateSupplierCatalogueProduct
    ) {
      // supplier catalogue product
      setInitialSupplierCatalogue(null);
      setTimeout(() => {
        supplierCatalogueProductCreateRef.current?.open();
      });
    }
  };

  const ActionButtons =
    handleProductCreate ||
    (handleSupplierCatalogueProductCreate &&
      canCreateSupplierCatalogueProduct) ? (
      <ButtonCreate
        title={t("New product", { ns: "ProductSearch" })}
        onClick={handleCreateNewProduct}
      />
    ) : undefined;

  return (
    <>
      <ProductSearchListHeader
        {...props}
        children={children}
        allowedViews={ALLOWED_VIEWS}
        ActionButtons={ActionButtons}
        px={{ xs: 1, md: 1.5 }}
        mx={{ xs: -1, md: -1.5 }}
        pt={1}
        mt={-1}
      />
      {handleProductCreate && (
        <ModalOpenProcess
          ref={productCreateRef}
          Modal={ProductCreateModal}
          modalProps={{
            handleSubmit: async values => {
              await handleProductCreate(values);
              handleClose();
            },
          }}
        />
      )}
      {handleSupplierCatalogueProductCreate &&
        canCreateSupplierCatalogueProduct && (
          <ModalOpenProcess
            ref={supplierCatalogueProductCreateRef}
            Modal={SupplierCatalogueProductCreateModal}
            modalProps={{
              initialSupplierCatalogue,
              handleComplete: handleSupplierCatalogueProductCreate,
            }}
          />
        )}
      <ProductAskTypeProcess ref={productAskTypeProcessRef} />
    </>
  );
}

function ProductSelectButton({
  product,
  onProductSelect,
}: {
  product: ProductSearchItem__ProductSearchResultFragment;
  onProductSelect(
    product: ProductSearchItem__ProductSearchResultFragment
  ): void;
}) {
  const { t } = useTranslate(["Global", "ProductSearch"]);
  return (
    <IconButton
      color={"primary"}
      size={"small"}
      onClick={() => {
        onProductSelect(product);
      }}
      title={t("Select", { ns: "ProductSearch" })}
      aria-label={t("Select", { ns: "ProductSearch" })}
    >
      <TouchAppIcon fontSize="small" />
    </IconButton>
  );
}

function ProductsList({
  items,
  totalCount,
  activeView,
  loading,
  onSelectItem,
  paginationModel,
  setPaginationModel,
  onProductSelect,
}: {
  items: ProductSearchItem__ProductSearchResultFragment[];
  totalCount: number;
  activeView: CollectionView;
  loading: boolean;
  onSelectItem(product: ProductSearchItem__ProductSearchResultFragment): void;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: (newPaginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  onProductSelect(
    product: ProductSearchItem__ProductSearchResultFragment
  ): void;
}) {
  const stateStore = useDataGridStateStore("ProductSearchModal");
  const ActionButton = React.useCallback(
    ({
      product,
    }: {
      product: ProductSearchItem__ProductSearchResultFragment;
    }) => (
      <>
        <ButtonCircleWrapper>
          <OrganisationSupplierProductFavouriteButton
            product={product}
            canEdit={true}
          />
        </ButtonCircleWrapper>
        <ButtonCircleWrapper>
          <ProductSelectButton
            product={product}
            onProductSelect={onProductSelect}
          />
        </ButtonCircleWrapper>
      </>
    ),
    [onProductSelect]
  );
  return (
    <ProductsListView
      items={items}
      totalCount={totalCount}
      activeView={activeView}
      loading={loading}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      CardActionButton={ActionButton}
      TableActions={ActionButton}
      stateStore={stateStore}
      tableActionsWidth={110}
      onClick={onSelectItem}
    />
  );
}
