import { LoadingSpinner } from "@msys/ui";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { UserAvatar } from "../features/users/UserAvatar";

export interface AvailableUser {
  id: string;
  active: boolean;
  firstname: string;
  familyname: string;
  avatar: { id: string; url: string };
  organisationTitle: string;
}

interface Props {
  isLoading: boolean;
  availableUsers: AvailableUser[];
  selectedUser: AvailableUser | null;
  handleSelect: (user: AvailableUser) => void;
}

export const SelectUserList = ({
  isLoading,
  availableUsers,
  selectedUser,
  handleSelect,
}: Props) => {
  const { t } = useTranslate(["Global"]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <List>
      {availableUsers.length > 0 ? (
        availableUsers.map(user => (
          <ListItemButton
            key={user.id}
            disabled={!user.active}
            onClick={() => {
              handleSelect(user);
            }}
            selected={user === selectedUser}
          >
            <ListItemAvatar
              sx={theme => ({
                minWidth: theme.layout.listItemMinWidth.lg,
              })}
            >
              <UserAvatar
                userAvatar={{
                  __typename: "AvailableUser",
                  ...user,
                  avatar: { __typename: "AttachmentSnapshot", ...user.avatar },
                }}
                size="m"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h3">{user.organisationTitle}</Typography>
              }
            />
          </ListItemButton>
        ))
      ) : (
        <ListItem sx={{ color: theme => theme.palette.secondary.main }}>
          <ListItemText
            primary={t("No organisations available", { ns: "Global" })}
            color="inherit"
          />
        </ListItem>
      )}
    </List>
  );
};
