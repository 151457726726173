/* eslint-disable jsx-a11y/anchor-has-content */
import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { DataGrid, ListHeader, useFormatting } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RestrictedByProjectPermissionWithDebug } from "../../auth/RestrictedByProjectPermission";
import { EntityNumber } from "../../commons/EntityNumber";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { AddTimeReportButton } from "../../features/time-reports/buttons/AddTimeReportButton";
import { TimeReportStatusBadge } from "../../features/time-reports/components/TimeReportStatusBadge";
import { TimeReportTitle } from "../../features/time-reports/components/TimeReportTitle";
import { useDataGridStateStore } from "../../features/users/useDataGridStateStore";
import {
  TimeReports__ReportFragment,
  useTimeReportsQuery,
} from "./ProjectTimeReports.generated";

interface Props {
  projectId: string;
  prefixBreadcrumbs: BreadcrumbItem[];
  submenuItems: PageTopbarItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}

export const ProjectTimeReports = ({
  projectId,
  submenuItems,
  activeSubmenuItem,
  prefixBreadcrumbs,
}: Props) => {
  const navigate = useNavigate();

  const { t } = useTranslate(["TimeReports", "Global"]);
  const { getFormattedDate } = useFormatting();

  const breadcrumbs = React.useMemo(
    () => [
      ...prefixBreadcrumbs,
      {
        title: t("Tasks", {
          ns: "TimeReports",
        }),
        to: `/projects/${projectId}/tasks`,
      },
      {
        title: t("Time Reports", {
          ns: "TimeReports",
        }),
        to: `/projects/${projectId}/tasks/reports`,
      },
    ],
    [prefixBreadcrumbs, t, projectId]
  );

  const client = useApolloClient();
  const query = useTimeReportsQuery({
    client,
    variables: {
      projectId,
    },
  });

  const project = getDataOrNull(query.data?.project)?.project;
  const timeReports = project?.timeReports ?? [];

  const stateStore = useDataGridStateStore("ProjectTimeReports");

  return (
    <Page
      title={project?.title}
      submenuItems={submenuItems}
      breadcrumbs={breadcrumbs}
      header={
        <PageHeader
          breadcrumbs={breadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
    >
      {project && (
        <PageContainer>
          <ListHeader
            title={t("Time Reports", {
              ns: "TimeReports",
            })}
            CreateButton={
              <RestrictedByProjectPermissionWithDebug
                permission="MANAGE_QUOTES"
                project={project}
              >
                <AddTimeReportButton projectId={projectId} type="button" />
              </RestrictedByProjectPermissionWithDebug>
            }
            mb={2}
          />

          <RestrictedByProjectPermissionWithDebug
            permission="READ_QUOTES"
            project={project}
          >
            <DataGrid
              stateStore={stateStore}
              loading={query.loading}
              hideFooter={true}
              columns={[
                {
                  field: "number",
                  headerName: t("No.", {
                    ns: "Global",
                  }),
                  flex: 1,
                  minWidth: 100,
                  sortable: false,
                  renderCell: ({
                    row: report,
                  }: {
                    row: TimeReports__ReportFragment;
                  }) => <EntityNumber noLabel number={report.number} />,
                },
                {
                  field: "status",
                  headerName: t("Status", {
                    ns: "TimeReports",
                  }),
                  flex: 1,
                  minWidth: 100,
                  sortable: false,
                  renderCell: ({
                    row: report,
                  }: {
                    row: TimeReports__ReportFragment;
                  }) => <TimeReportStatusBadge report={report} />,
                },
                {
                  field: "title",
                  headerName: t("Time report title", {
                    ns: "TimeReports",
                  }),
                  flex: 3,
                  minWidth: 100,
                  sortable: false,
                  renderCell: ({
                    row: report,
                  }: {
                    row: TimeReports__ReportFragment;
                  }) => <TimeReportTitle report={report} />,
                },
                {
                  field: "fromDate",
                  headerName: t("Time period", {
                    ns: "TimeReports",
                  }),
                  flex: 1,
                  minWidth: 100,
                  sortable: false,
                  renderCell: ({
                    row: report,
                  }: {
                    row: TimeReports__ReportFragment;
                  }) =>
                    `${getFormattedDate(report.fromDate)} – ${getFormattedDate(
                      report.tillDate
                    )}`,
                },
              ]}
              rows={timeReports}
              onRowClick={report =>
                navigate(`/projects/${projectId}/tasks/reports/${report.id}`)
              }
            />
          </RestrictedByProjectPermissionWithDebug>
        </PageContainer>
      )}
    </Page>
  );
};
