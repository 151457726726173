import { Capabilities } from "@msys/common";
import React from "react";
import { PermissionName } from "../../clients/graphqlTypes";
import { Project } from "./RestrictedByProjectPermission";
import { useUserData } from "./useUserData";

export const useRestrictionFilter = <
  I extends {
    capability?: Capabilities;
    permission?: PermissionName;
    projectPermission?: { name: PermissionName; project: Project };
  },
>() => {
  const { hasOrganisationPermission, hasCapability } = useUserData();

  return React.useCallback(
    (item: I): boolean => {
      if (item.capability) {
        if (!hasCapability(item.capability)) return false;
      }
      if (item.permission) {
        if (!hasOrganisationPermission(item.permission)) return false;
      }
      if (item.projectPermission) {
        return item.projectPermission.project.viewerPermissions.includes(
          item.projectPermission.name
        );
      }

      return true;
    },
    [hasOrganisationPermission, hasCapability]
  );
};
