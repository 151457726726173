/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportAsJsonMutationVariables = Types.Exact<{
  input: Types.ExportAsJsonInput;
}>;


export type ExportAsJsonMutation = { exportAsJson: { __typename: 'ExportAsJsonResult', downloadUrl: string, filename: string } };


export const ExportAsJsonDocument = gql`
    mutation ExportAsJson($input: ExportAsJsonInput!) {
  exportAsJson(input: $input) {
    downloadUrl
    filename
  }
}
    `;
export type ExportAsJsonMutationFn = Apollo.MutationFunction<ExportAsJsonMutation, ExportAsJsonMutationVariables>;

/**
 * __useExportAsJsonMutation__
 *
 * To run a mutation, you first call `useExportAsJsonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAsJsonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAsJsonMutation, { data, loading, error }] = useExportAsJsonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportAsJsonMutation(baseOptions?: Apollo.MutationHookOptions<ExportAsJsonMutation, ExportAsJsonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportAsJsonMutation, ExportAsJsonMutationVariables>(ExportAsJsonDocument, options);
      }
export type ExportAsJsonMutationHookResult = ReturnType<typeof useExportAsJsonMutation>;
export type ExportAsJsonMutationResult = Apollo.MutationResult<ExportAsJsonMutation>;
export type ExportAsJsonMutationOptions = Apollo.BaseMutationOptions<ExportAsJsonMutation, ExportAsJsonMutationVariables>;