import { StyledComponent } from "@emotion/styled";
import { styled } from "@mui/material/styles";
import { MUIStyledCommonProps } from "@mui/system";
import { Theme } from "@mui/material";
import React from "react";
import { transientOptions } from "../../styles";

interface PageContainerProps {
  $noPadding?: boolean;
  $fullHeight?: boolean;
  $display?: React.CSSProperties["display"];
}

export const PageContainer: StyledComponent<
  MUIStyledCommonProps<Theme> & PageContainerProps,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  {}
> = styled(
  "div",
  transientOptions
)<PageContainerProps>(
  ({ theme, $noPadding, $fullHeight, $display = "flex" }) => ({
    boxSizing: "border-box",
    display: $display,
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    position: "relative",
    height: $fullHeight ? "100%" : "max-content",
    justifyContent: $fullHeight ? "stretch" : undefined,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      maxHeight: "100%",
      overflow: "auto",
      padding: theme.spacing(2),
      ...($noPadding ? { padding: 0 } : {}),
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: theme.spacing(2),
      ...($noPadding ? { padding: 0 } : {}),
    },
  })
);

PageContainer.defaultProps = {
  className: "msys-container",
};
