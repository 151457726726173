/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ShopItemsList_ShoppableTemplateFragmentDoc } from '../../features/shop/components/ShopItemsList.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  sorting?: Types.InputMaybe<Array<Types.OrganisationQuoteTemplatesSorting>>;
  likedByMe?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  createdAfter?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  createdBefore?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  minPrice?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  maxPrice?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ShopQuery = { viewer?: { __typename: 'Viewer', id: string, organisation: { __typename: 'ViewerOrganisation', id: string, shoppableTemplates: { __typename: 'ShoppableTemplateConnection', totalCount: number, edges: Array<{ __typename: 'ShoppableTemplateEdge', node: { __typename: 'ShoppableTemplate', id: string, title: string, description: string, isLikedByMe: boolean, customTags: Array<string>, proposedCalculation?: { __typename: 'ItemCalculation', materialBuyingPricePerUnit: number, discountRate: number, materialMargin: number, materialPriceDiscountedSubTotal: number, materialPricePerUnit: number, materialPriceSubTotal: number, materialFactor: number, priceNetTotal: number, pricePerUnit: number, priceSubTotal: number, priceTotal: number, priceVatTotal: number, quantity: number, quantityUnit: Types.QuantityUnit, timePerUnit: number, timeTotal: number, workSellingRate: number, workBuyingRate: number, workRate: number, workBuyingPricePerUnit: number, workPriceDiscountedSubTotal: number, workPricePerUnit: number, workPriceSubTotal: number, workFactor: number, vatRate: number } | null, shopCatalogProductImage?: { __typename: 'Attachment', id: string, url: string } | null } }> } } } | null };


export const ShopDocument = gql`
    query Shop($offset: Int, $limit: Int!, $sorting: [OrganisationQuoteTemplatesSorting!], $likedByMe: Boolean, $createdAfter: Date, $createdBefore: Date, $minPrice: Float, $maxPrice: Float, $searchTerm: String) {
  viewer {
    id
    organisation {
      id
      shoppableTemplates(
        offset: $offset
        limit: $limit
        sorting: $sorting
        filters: {likedByMe: $likedByMe, createdAfter: $createdAfter, createdBefore: $createdBefore, priceMin: $minPrice, priceMax: $maxPrice}
        search: $searchTerm
      ) {
        totalCount
        edges {
          node {
            id
            ...ShopItemsList_ShoppableTemplate
          }
        }
      }
    }
  }
}
    ${ShopItemsList_ShoppableTemplateFragmentDoc}`;

/**
 * __useShopQuery__
 *
 * To run a query within a React component, call `useShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sorting: // value for 'sorting'
 *      likedByMe: // value for 'likedByMe'
 *      createdAfter: // value for 'createdAfter'
 *      createdBefore: // value for 'createdBefore'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useShopQuery(baseOptions: Apollo.QueryHookOptions<ShopQuery, ShopQueryVariables> & ({ variables: ShopQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
      }
export function useShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
        }
export function useShopSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopQuery, ShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
        }
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopSuspenseQueryHookResult = ReturnType<typeof useShopSuspenseQuery>;
export type ShopQueryResult = Apollo.QueryResult<ShopQuery, ShopQueryVariables>;