import React from "react";
import { PermissionName } from "../../clients/graphqlTypes";
import { Restricted, RestrictedWithDebug } from "./Restricted";

export interface Project {
  viewerPermissions: PermissionName[];
}

interface Props
  extends Omit<React.ComponentProps<typeof Restricted>, "isRestricted"> {
  permission: PermissionName;
  project: Project;
}

export const RestrictedByProjectPermission = ({
  permission,
  project,
  ...props
}: Props) => {
  const isPermitted = project.viewerPermissions.includes(permission);

  return <Restricted isRestricted={!isPermitted} {...props} />;
};

export const RestrictedByProjectPermissionWithDebug = ({
  project,
  permission,
  children,
  ...props
}: Props) => {
  const isPermitted = project.viewerPermissions.includes(permission);

  return (
    <RestrictedWithDebug
      {...props}
      isRestricted={!isPermitted}
      debugLabel={`Project permission: ${permission}`}
      debugColor={"#DA70D6"}
    >
      {children}
    </RestrictedWithDebug>
  );
};
