/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { AddressDetails__AddressFragmentDoc } from '../../addresses/Addresses.generated';
export type InvoiceHeader__ProjectFragment = { __typename: 'Project', id: string, number: string, viewerPermissions: Array<Types.PermissionName>, state: Types.ProjectStateMachineStatus, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, number: string, contactType: Types.CrmOrganisationContactType, billingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, location?: { __typename: 'Location', lat: number, lng: number } | null } | null, contactPerson?: { __typename: 'CrmPerson', id: string, fullname: string } | null } | null };

export type InvoiceHeader__PayeeFragment = { __typename: 'DocActor', id: string, companyName: string, phone: string, email: string, address?: { __typename: 'AddressSnapshot', countryCode: string, postalCode: string, city: string, streetLines1: string } | null, contact?: { __typename: 'DocActorContact', fullname: string } | null };

export type InvoiceHeader__PayerFragment = { __typename: 'DocActor', id: string, companyName: string, companyOrIndividual: Types.DocActorCompanyOrIndividual, firstname: string, familyname: string, phone: string, email: string, address?: { __typename: 'AddressSnapshot', countryCode: string, postalCode: string, city: string, streetLines1: string } | null, contact?: { __typename: 'DocActorContact', fullname: string } | null };

export type InvoiceHeader__InvoiceFragment = { __typename: 'Invoice', id: string, number?: string | null, openedAt?: any | null, deliveryOfServiceStart?: any | null, deliveryOfServiceEnd?: any | null, dueDate?: any | null, contactPerson: { __typename: 'Person', id: string, fullname: string, email: any, phones: Array<{ __typename: 'Phone', id: string, number: string }> } };

export const InvoiceHeader__ProjectFragmentDoc = gql`
    fragment InvoiceHeader__Project on Project {
  id
  number
  viewerPermissions
  state
  crmOrganisation {
    id
    title
    number
    billingAddress {
      ...AddressDetails__Address
    }
    contactType
    contactPerson {
      id
      fullname
    }
  }
}
    ${AddressDetails__AddressFragmentDoc}`;
export const InvoiceHeader__PayeeFragmentDoc = gql`
    fragment InvoiceHeader__Payee on DocActor {
  id
  companyName
  address {
    countryCode
    postalCode
    city
    streetLines1
  }
  phone
  email
  contact {
    fullname
  }
}
    `;
export const InvoiceHeader__PayerFragmentDoc = gql`
    fragment InvoiceHeader__Payer on DocActor {
  id
  companyName
  companyOrIndividual
  address {
    countryCode
    postalCode
    city
    streetLines1
  }
  firstname
  familyname
  phone
  email
  contact {
    fullname
  }
}
    `;
export const InvoiceHeader__InvoiceFragmentDoc = gql`
    fragment InvoiceHeader__Invoice on Invoice {
  id
  number
  openedAt
  deliveryOfServiceStart
  deliveryOfServiceEnd
  dueDate
  contactPerson {
    id
    fullname
    phones {
      id
      number
    }
    email
  }
}
    `;