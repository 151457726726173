import { assertNever } from "@msys/common";
import { Grid } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { UserTitleField } from "../../commons/form-fields/UserTitleField";

type IsRequiredOption =
  | "FIRSTNAME_ONLY"
  | "FAMILYNAME_ONLY"
  | "BOTH"
  | "BOTH_OR_NONE";

interface Props {
  disabled?: boolean;
  titleField?: string;
  familynameField?: string;
  firstnameField?: string;
  isRequiredOption?: IsRequiredOption;
}

export const UserTitleWithNameField = ({
  titleField = "title",
  familynameField = "familyName",
  firstnameField = "firstName",
  isRequiredOption = "FAMILYNAME_ONLY",
  disabled,
}: Props) => {
  const { t } = useTranslate("UserDetails");
  const { values } = useFormikContext<{ [key: string]: string }>();

  const [isFirstnameRequired, isFamilynameRequired] = getIsRequired(
    isRequiredOption,
    values[firstnameField],
    values[familynameField]
  );

  return (
    <Grid container columns={13} columnSpacing={1}>
      <Grid item xs={3}>
        <UserTitleField name={titleField} required disabled={disabled} />
      </Grid>
      <Grid item xs={5}>
        <Field
          component={TextField}
          label={t("First name")}
          name={firstnameField}
          required={isFirstnameRequired}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={5}>
        <Field
          component={TextField}
          label={t("Last name")}
          name={familynameField}
          required={isFamilynameRequired}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

function getIsRequired(
  isRequredOption: IsRequiredOption,
  firstname: string,
  lastname: string
): [firstnameIsRequired: boolean, familynameIsRequired: boolean] {
  switch (isRequredOption) {
    case "FIRSTNAME_ONLY":
      return [true, false];
    case "FAMILYNAME_ONLY":
      return [false, true];
    case "BOTH":
      return [true, true];
    case "BOTH_OR_NONE":
      if (firstname || lastname) {
        return [true, true];
      } else {
        return [false, false];
      }
    default:
      assertNever(isRequredOption);
  }
}
