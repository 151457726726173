import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import React from "react";

export const PasswordField: React.FC<{
  id?: string;
  name: string;
  label: string;
  autoComplete?: string;
}> = ({ name, label, id, autoComplete }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Field
      id={id}
      name={name}
      type={showPassword ? "text" : "password"}
      label={label}
      component={TextField}
      autoComplete={autoComplete}
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(state => !state)}
              onMouseDown={e => e.preventDefault()}
              edge="end"
              tabIndex={-1}
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
