import { useApolloClient } from "@apollo/client";
import { CardContainer, LabeledValue, ModalOpenButton } from "@msys/ui";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider, IconButton } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Stack } from "../../../commons/layout/Stack";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { useCustomFieldConfigQuery } from "../../custom-fields/customFieldConfigs.generated";
import { EditCustomFieldConfigModal } from "../../custom-fields/EditCustomFieldConfigModal";
import { useCustomFieldDataTypes } from "../../custom-fields/useCustomFieldDataTypes";

export const OrganisationCustomFieldConfigBox: React.FC = () => {
  const { t } = useTranslate(["OrganisationSettings", "CustomFields"]);
  const { customFieldDataTypeLabels } = useCustomFieldDataTypes();

  const client = useApolloClient();
  const customFieldConfigQuery = useCustomFieldConfigQuery({ client });

  const customFieldConfig = customFieldConfigQuery.data?.customFieldConfig;
  if (!customFieldConfig) return null;

  return (
    <CardContainer
      title={t("Custom fields configurator", {
        ns: "CustomFields",
      })}
      isInitiallyClosed={false}
      itemCount={customFieldConfig.length}
      ActionButton={
        <ModalOpenButton
          Modal={EditCustomFieldConfigModal}
          modalProps={{
            refetchQueries: [namedOperations.Query.CustomFieldConfig],
          }}
        >
          <IconButton color="secondary" size="small">
            <EditIcon />
          </IconButton>
        </ModalOpenButton>
      }
    >
      {customFieldConfig.map((field, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider />}
          <Stack flexDirection="column" paddingX={2} paddingY={1}>
            <Stack>
              <Box flex="1">
                <LabeledValue
                  label={t("Page", {
                    ns: "CustomFields",
                  })}
                >
                  {field.objectType}
                </LabeledValue>
              </Box>
              <Box flex="1">
                <LabeledValue
                  label={t("Category", {
                    ns: "CustomFields",
                  })}
                >
                  {field.group}
                </LabeledValue>
              </Box>
            </Stack>
            <Stack>
              <Box flex="1">
                <LabeledValue
                  label={t("Field type", {
                    ns: "CustomFields",
                  })}
                >
                  {customFieldDataTypeLabels[field.dataType]}
                </LabeledValue>
              </Box>
              <Box flex="1">
                <LabeledValue
                  label={t("Field label", {
                    ns: "CustomFields",
                  })}
                >
                  {field.key}
                </LabeledValue>
              </Box>
            </Stack>
          </Stack>
        </React.Fragment>
      ))}
    </CardContainer>
  );
};
