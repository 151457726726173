/* eslint-disable */
import * as Types from '../../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
export type QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplateFragment = { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null };

export const QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplateFragmentDoc = gql`
    fragment QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplate on QuoteTemplate {
  id
  resolvedAsReadModelVersionNumber
}
    `;