import {
  DividerWithToggle,
  useLocalStorageAsState,
  useScreenWidth,
} from "@msys/ui";
import { Stack, Theme } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import React from "react";

const DIVIDER_WIDTH = 48;

interface Props {
  // used for saving open state in local storage
  name: string;
  // horizontal spacing
  spacing?: ResponsiveStyleValue<string | number> | undefined;
  leftColumn?: React.ReactNode;
  leftColumnWidth?: keyof Theme["layout"]["columnWidth"];
}

export const LayoutWithLeftColumn = ({
  name,
  children,
  spacing = 2,
  leftColumnWidth = "xxs",
  leftColumn,
}: React.PropsWithChildren<Props>) => {
  const [open, setOpen] = useLocalStorageAsState<boolean>(
    `msys-layoutLeftColumnOpen-${name}`,
    true,
    true
  );
  const { isMinTablet } = useScreenWidth();

  if (!isMinTablet) {
    // mobile
    return <>{children}</>;
  }

  return (
    <Stack
      alignItems={"stretch"}
      height="100%"
      spacing={spacing}
      direction="row"
      overflow="hidden"
      flex={1}
    >
      {leftColumn && (
        <>
          <Stack
            alignItems={"stretch"}
            spacing={0}
            direction="row"
            overflow="hidden"
            flexGrow={0}
            flexShrink={0}
            justifyContent="flex-end"
            sx={theme => ({
              width: open
                ? theme.layout.columnWidth[leftColumnWidth]
                : DIVIDER_WIDTH,
              transition: "width ease-out 0.2s",
            })}
          >
            <Stack
              direction={"column"}
              height={"100%"}
              minWidth={0}
              overflow="auto"
              spacing={1}
              flexGrow={0}
              flexShrink={0}
              sx={theme => ({
                width: theme =>
                  `calc(${theme.layout.columnWidth[leftColumnWidth]}px - ${DIVIDER_WIDTH}px)`,
              })}
            >
              {leftColumn}
            </Stack>
            <DividerWithToggle open={open} onToggle={setOpen} />
          </Stack>
        </>
      )}
      <Stack
        direction={"column"}
        spacing={1}
        flex={1}
        minWidth={0}
        overflow="auto"
      >
        {children}
      </Stack>
    </Stack>
  );
};
