import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useScreenWidth } from "@msys/ui";
import { Box } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { pick } from "lodash";
import React from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Agreement,
  ItemType,
  ModifyItemProductValuesInput,
  PermissionName,
  namedOperations,
} from "../../../clients/graphqlTypes";
import { useFeature } from "../../../common/FeatureFlags";
import { useUserData } from "../../auth/useUserData";
import {
  BreadcrumbItem,
  Page,
  PageTopbarItem,
} from "../../commons/layout/Page";
import { PageColumn } from "../../commons/layout/PageColumn";
import { PageContainer } from "../../commons/layout/PageContainer";
import { PageHeader } from "../../commons/layout/PageHeader";
import { Stack } from "../../commons/layout/Stack";
import {
  ConfirmModalProps,
  ConfirmProcess,
  ConfirmProcessRef,
} from "../../commons/modals/ConfirmProcess";
import { QUOTE_ITEM_TYPES } from "../../constants";
import { isAllowedToPaste } from "../../features/doc-items/constraints";
import {
  getViewerDecisionRole,
  isTreePreviewItemVisible,
} from "../../features/doc-items/helpers";
import { useCreateItem } from "../../features/doc-items/hooks/useCreateItem";
import { useDocItemsClipboard } from "../../features/doc-items/hooks/useDocItemsClipboard";
import { ProductSearchItem__ProductSearchResultFragment } from "../../features/products/Product.generated";
import {
  createTreeClientItem,
  createTreeItem,
  createTreeItemInput,
} from "../../features/projects/quote-trees";
import { QuoteAction } from "../../features/quotes/QuoteAction";
import { useQuoteQuickFilters } from "../../features/quotes/QuoteQuickFilters";
import { QuoteSubHeader } from "../../features/quotes/QuoteSubHeader";
import {
  allowHaveChildren,
  shouldRenderCreateInput,
} from "../../features/quotes/helpers";
import { TemplatesQuoteSelectMultipleComponent_TemplateFragment } from "../../features/templates/quote/TemplatesQuoteSelectMultipleModal.generated";
import { ThreeD } from "../../features/threeD/ThreeD";
import { ThreeDEditorRef } from "../../features/threeD/ThreeDEditor";
import { VirtualItemTree } from "../../trees-virtual/VirtualItemTree";
import { VirtualBareTreeStandaloneItem } from "../../trees-virtual/components/VirtualBareTreeStandaloneItem";
import {
  useEnrichExpandedStoreWithParentPathIds,
  useExpandedStoreWithLocalStorage,
} from "../../trees-virtual/hooks/useExpandedStore";
import { TreeViewMode } from "../../trees-virtual/types";
import { TreeToggleAllExpandedButton } from "../../trees/components/TreeToggleButton";
import {
  ProjectQuoteThreeD_ItemFragment,
  useProjectQuoteThreeDQuery,
  useProjectQuoteThreeD_CreateItemsFromTemplatesMutation,
  useProjectQuoteThreeD_CreateItemsMutation,
  useProjectQuoteThreeD_PasteItemFromClipboardMutation,
} from "./ProjectQuoteThreeD.generated";
import { useHasSapS4HanaIntegration } from "../../features/sap-s4-hana/hooks";

const REFETCH_QUERIES = [namedOperations.Query.ProjectQuoteEdit];

export function ProjectQuoteThreeD({
  projectId,
  quoteId,
  submenuItems,
  prefixBreadcrumbs,
  activeSubmenuItem,
}: {
  projectId: string;
  quoteId: string;
  submenuItems: PageTopbarItem[];
  prefixBreadcrumbs: BreadcrumbItem[];
  activeSubmenuItem: PageTopbarItem | undefined;
}) {
  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get("r");
  const { itemId: selectedItemId = null } = useParams<{
    projectId: string;
    quoteId: string;
    itemId?: string;
  }>();
  const navigate = useNavigate();
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Global", "Quote"]);

  const threeDEditorRef = React.useRef<ThreeDEditorRef>(null);

  const confirmProcessRef = React.useRef<ConfirmProcessRef>(null);
  const startConfirmProcess = React.useCallback((props: ConfirmModalProps) => {
    return confirmProcessRef.current!.startConfirmProcess(props);
  }, []);

  const isClientVisibilityEnabled = useFeature("ClientVisibility");

  const { isMinDesktop, isMinTablet } = useScreenWidth();

  const [treeContainer, setTreeContainer] =
    React.useState<HTMLDivElement | null>(null);
  // const [treeItemsCount, setTreeItemsCount] = useState<number>(0);

  const expandedStore = useExpandedStoreWithLocalStorage(
    "quote",
    quoteId,
    selectedItemId
  );

  const client = useApolloClient();
  const query = useProjectQuoteThreeDQuery({
    client,
    variables: {
      projectId,
      quoteId,
      expandedItemIds: expandedStore.expandedItemIds,
    },
  });
  const project = getDataOrNull(query.data?.project)?.project;
  const doc = getDataOrNull(query.data?.quote)?.quote;
  const docTitle = doc?.title ?? "";

  const pathToDocList = `/projects/${projectId}/quotes`;
  const pathToDoc = `/projects/${projectId}/quotes/${quoteId}`;
  const pathToDocPage = `${pathToDoc}/3d`;

  const isAgreed =
    (doc?.isPublished && doc?.isBinding && doc?.agreement === "YES") ?? false;
  const isViewersQuote =
    doc?.owningSystemOrganisationId === viewer.organisation.id;

  const enableCreating = isViewersQuote ? true : false;
  const enableDragging = isViewersQuote ? !isAgreed : false;

  const [QuickFiltersTabs, quickFilter] =
    useQuoteQuickFilters<ProjectQuoteThreeD_ItemFragment>(
      doc?.agreement === "YES"
    );

  const [filteredTreeItems, rootItem, allDocItems] = React.useMemo(() => {
    if (!doc) return [[], undefined, []];
    const allDocItems = doc.items;
    const rootItem = allDocItems?.find(item => item.isRootItem);
    const treeItems = allDocItems?.filter(
      item => !item.isAnyParentItemEliminated
    );
    const filteredItems = quickFilter(treeItems);
    return [filteredItems, rootItem, allDocItems];
  }, [doc, quickFilter]);

  useEnrichExpandedStoreWithParentPathIds(
    expandedStore,
    allDocItems,
    selectedItemId,
    !query.loading && !query.error
  );

  const isRootSelected = selectedItemId === rootItem?.id;

  const navigateToItem = React.useCallback(
    (id: string) => navigate(`${pathToDocPage}/items/${id}`, { replace: true }),
    [pathToDocPage, navigate]
  );

  const quote = getDataOrNull(query.data?.quote)?.quote;
  const viewerPermissions: PermissionName[] = React.useMemo(
    () => quote?.viewerPermissions ?? [],
    [quote?.viewerPermissions]
  );
  const hasSapS4HanaIntegration = useHasSapS4HanaIntegration();

  const agreement: Agreement | undefined = doc?.agreement;

  const viewerDecisionRole = getViewerDecisionRole(doc?.actors ?? []);

  const {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  } = useCreateQuoteItems({
    projectId,
    quoteId,
    expandedItemIds: expandedStore.expandedItemIds,
  });

  const TreeItem = React.useMemo(() => {
    if (agreement === undefined) return nullFn;
    return isViewersQuote
      ? createTreeItem({
          pathToDocPage,
          projectId,
          doc: { viewerPermissions },
          quoteId,
          docAgreement: agreement,
          startConfirmProcess,
          navigateToItem,
          expandedItemIds: expandedStore.expandedItemIds,
          setItemExpanded: expandedStore.setItemExpanded,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
        })
      : createTreeClientItem({
          pathToDocPage,
          projectId,
          quoteId,
          doc: { viewerPermissions },
          docAgreement: agreement,
          expandedItemIds: expandedStore.expandedItemIds,
          viewerDecisionRole,
        });
  }, [
    agreement,
    isViewersQuote,
    pathToDocPage,
    projectId,
    viewerPermissions,
    quoteId,
    startConfirmProcess,
    navigateToItem,
    expandedStore.expandedItemIds,
    expandedStore.setItemExpanded,
    pasteItem,
    isAllowedToPasteItem,
    viewerDecisionRole,
  ]);

  const TreeItemInput = React.useMemo(
    () =>
      createTreeItemInput({
        viewMode: TreeViewMode.Item,
        quoteId,
        createItem,
        createItemByType,
        createItemFromTemplate,
        createItemsFromTemplates,
        createItemsWithProducts,
        pasteItem,
        isAllowedToPasteItem,
      }),
    [
      createItem,
      createItemByType,
      createItemFromTemplate,
      createItemsFromTemplates,
      createItemsWithProducts,
      isAllowedToPasteItem,
      pasteItem,
      quoteId,
    ]
  );

  // redirect to edit route when selected item is filtered out
  React.useEffect(() => {
    if (
      !query.loading &&
      selectedItemId &&
      !filteredTreeItems.some(item => item.id === selectedItemId) &&
      selectedItemId !== rootItem?.id
    ) {
      navigate(pathToDocPage, { replace: true });
    }
  }, [
    selectedItemId,
    filteredTreeItems,
    pathToDocPage,
    rootItem,
    navigate,
    query.loading,
  ]);

  const filterItem = React.useCallback(
    (i: ProjectQuoteThreeD_ItemFragment) =>
      isTreePreviewItemVisible(true, i, allDocItems, doc?.isBinding ?? true),
    [allDocItems, doc?.isBinding]
  );

  return (
    <Page
      subtitle={project?.title}
      title={docTitle}
      submenuItems={submenuItems}
      breadcrumbs={prefixBreadcrumbs}
      header={
        <PageHeader
          breadcrumbs={prefixBreadcrumbs}
          submenuItems={submenuItems}
          activeSubmenuItem={activeSubmenuItem}
        />
      }
      subHeader={
        project && doc ? (
          <QuoteSubHeader
            project={project}
            quote={doc}
            activeView="3d"
            canEdit={isViewersQuote}
            isReadOnly={isAgreed}
            pathToDocList={pathToDocList}
            pathToDoc={pathToDoc}
            hasSapS4HanaIntegration={hasSapS4HanaIntegration}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
            expandedItemIds={expandedStore.expandedItemIds}
          />
        ) : undefined
      }
      action={
        project &&
        doc && (
          <QuoteAction
            doc={doc}
            project={project}
            onDiscardPendingChangesRefetchQueries={REFETCH_QUERIES}
            onDeclineChangesRefetchQueries={REFETCH_QUERIES}
          />
        )
      }
    >
      {isMinDesktop && (
        <PageColumn
          $hasRightBorder
          $hasBackground
          $display="block"
          ref={isMinTablet ? setTreeContainer : undefined}
        >
          {doc && project && rootItem && (
            <Stack flexDirection={"column"} spacing={2}>
              <Stack width="100%" alignItems="center" spacing={1}>
                <TreeToggleAllExpandedButton
                  areAllItemsExpanded={expandedStore.areAllItemsExpanded}
                  setAllItemsExpanded={expandedStore.setAllItemsExpanded}
                />
                <QuickFiltersTabs />
              </Stack>

              <Box>
                <VirtualBareTreeStandaloneItem
                  item={rootItem}
                  items={filteredTreeItems}
                  allItems={allDocItems}
                  depth={0}
                  itemComponent={TreeItem}
                  selected={isRootSelected}
                  sticky={isRootSelected}
                  top={0}
                  clickable
                  onClick={() => navigateToItem(rootItem.id)}
                  to={`${pathToDocPage}/items/${rootItem.id}`}
                />

                {/* <TreeConnector $isAtTheTopOfTree={treeItemsCount > 0} /> */}
                <VirtualItemTree<
                  ProjectQuoteThreeD_ItemFragment,
                  typeof enableDragging
                >
                  docId={enableDragging ? doc.id : null}
                  projectId={enableDragging ? projectId : null}
                  items={filteredTreeItems}
                  allItems={allDocItems}
                  filterItem={
                    isClientVisibilityEnabled || !isViewersQuote
                      ? filterItem
                      : undefined
                  }
                  selectedItemId={selectedItemId}
                  enableCreating={enableCreating}
                  enableDragging={enableDragging}
                  documentItemTypes={QUOTE_ITEM_TYPES}
                  container={treeContainer}
                  itemComponent={TreeItem}
                  inputComponent={isViewersQuote ? TreeItemInput : nullFn}
                  allowHaveChildren={
                    isViewersQuote ? allowHaveChildren : falseFn
                  }
                  shouldRenderCreateInput={
                    isViewersQuote ? shouldRenderCreateInput : falseFn
                  }
                  expandedStore={expandedStore}
                  // onItemsCountChange={setTreeItemsCount}
                />
              </Box>
            </Stack>
          )}
        </PageColumn>
      )}
      <ConfirmProcess ref={confirmProcessRef} />

      <Outlet
        context={{
          expandedItemIds: expandedStore.expandedItemIds,
          CreateChildComponent: TreeItemInput,
          pasteItem,
          isAllowedToPasteItem,
          viewerDecisionRole,
        }}
      />

      <PageContainer $fullHeight $noPadding>
        <ThreeD
          threeDEditorRef={threeDEditorRef}
          projectId={projectId}
          docId={quoteId}
          returnPath={returnPath ?? pathToDoc}
          allDocItems={allDocItems}
          isEditable={isMinDesktop}
        />
      </PageContainer>
    </Page>
  );
}

function useCreateQuoteItems({
  projectId,
  quoteId,
  expandedItemIds,
}: {
  projectId: string;
  quoteId: string;
  expandedItemIds: string[] | undefined;
}) {
  const client = useApolloClient();
  const [createItems] = useProjectQuoteThreeD_CreateItemsMutation({
    client,
  });

  const { createItem, createItemByType } = useCreateItem({
    projectId,
    createDocumentItems: createItems,
    expandedItemIds,
  });

  const [createItemsFromTemplatesMutation] =
    useProjectQuoteThreeD_CreateItemsFromTemplatesMutation({
      client,
    });

  const createItemFromTemplate = React.useCallback(
    async (title: string, parentItemId: string, templateQuoteId: string) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: quoteId,
            parentItemId,
            templates: [
              {
                templateQuoteId,
              },
            ],
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items[0];
    },
    [createItemsFromTemplatesMutation, expandedItemIds, projectId, quoteId]
  );

  const createItemsFromTemplates = React.useCallback(
    async (
      parentItemId: string,
      templates: {
        quantity: number;
        template: TemplatesQuoteSelectMultipleComponent_TemplateFragment;
      }[]
    ) => {
      const result = await createItemsFromTemplatesMutation({
        variables: {
          input: {
            parentProjectId: projectId,
            parentDocId: quoteId,
            parentItemId,
            templates: templates.map(tpl => ({
              templateQuoteId: tpl.template.id,
              templateEstimatedQuantity: tpl.quantity,
            })),
            expandedItemIds,
          },
        },
      });

      return result.data?.createItemsFromTemplates.items;
    },
    [createItemsFromTemplatesMutation, expandedItemIds, projectId, quoteId]
  );

  const createItemsWithProducts = React.useCallback(
    async (
      parentItemId: string,
      products: {
        quantity: number;
        product:
          | ProductSearchItem__ProductSearchResultFragment
          | ModifyItemProductValuesInput;
      }[]
    ) => {
      const result = await createItems({
        variables: {
          input: {
            projectId,
            docId: quoteId,
            parentItemId,
            items: products.map(opts =>
              "__typename" in opts.product
                ? {
                    title: opts.product.texts?.title ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    product: pick(opts.product, "supplierId", "articleNumber"),
                  }
                : {
                    title: opts.product.productTitle ?? "",
                    type: "paid",
                    decisionBehaviorOfSubitems: "NONE",
                    estimatedQuantity: opts.quantity,
                    productValues: opts.product,
                  }
            ),
            expandedItemIds,
          },
        },
      });

      return result.data?.createItems.items;
    },
    [createItems, expandedItemIds, projectId, quoteId]
  );

  const [copiedItem] = useDocItemsClipboard();
  const [pasteItemFromClipboardMutation] =
    useProjectQuoteThreeD_PasteItemFromClipboardMutation({ client });
  const pasteItem = React.useCallback(
    async (docId: string, parentItemId: string) => {
      if (!copiedItem) throw new Error("No item copied");

      const result = await pasteItemFromClipboardMutation({
        variables: {
          input: {
            sourceProjectId: copiedItem.projectId,
            sourceDocId: copiedItem.docId,
            sourceItemId: copiedItem.itemId,
            destProjectId: projectId,
            destDocId: docId,
            destItemId: parentItemId,
            expandedItemIds,
          },
        },
      });
    },
    [copiedItem, pasteItemFromClipboardMutation, projectId, expandedItemIds]
  );
  const isAllowedToPasteItem = React.useCallback(
    (parentItem: { type: ItemType }) => {
      if (!copiedItem?.itemType || !parentItem.type) return false;

      return isAllowedToPaste({
        documentItemTypes: QUOTE_ITEM_TYPES,
        copiedItemType: copiedItem.itemType,
        parentItemType: parentItem.type,
      });
    },
    [copiedItem?.itemType]
  );

  return {
    createItem,
    createItemByType,
    createItemFromTemplate,
    createItemsFromTemplates,
    createItemsWithProducts,
    pasteItem,
    isAllowedToPasteItem,
  };
}

const nullFn = () => null;
const falseFn = () => false;
