import { useScreenWidth } from "@msys/ui";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useLanguages } from "../../common/translations/useLanguages";
import { LanguageSelector } from "../commons/LanguageSelector";
import { Page } from "../commons/layout/Page";
import { PageContainer } from "../commons/layout/PageContainer";
import { LogoutButton } from "../auth/LogoutButton";

interface Props {
  isAuthenticated: boolean;
}

export const InvitationPage = ({
  isAuthenticated,
  children,
}: React.PropsWithChildren<Props>) => {
  const { languages, selectLanguage, currentLanguage } = useLanguages();
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["PageSignup"]);

  return (
    <Page
      hideNavigation
      isTopBarVisible
      topbarItems={
        <>
          {isAuthenticated && <LogoutButton color={"inherit"} />}
          <LanguageSelector
            languages={languages}
            selectLanguage={selectLanguage}
            currentLanguage={currentLanguage}
          />
        </>
      }
    >
      <PageContainer $fullHeight $noPadding>
        <Stack
          direction={isMinTablet ? "row" : "column"}
          spacing={2}
          height={"100%"}
        >
          <Stack
            flexGrow={2}
            flexBasis={0}
            spacing={1}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
            paddingY={6}
            paddingX={2}
          >
            <Stack
              flexDirection="column"
              alignItems="center"
              width="100%"
              justifyContent="center"
              pb={4}
            >
              {children}
            </Stack>
          </Stack>
          <Stack
            flex={1}
            flexBasis={0}
            direction="column"
            justifyContent="center"
            paddingY={3}
            paddingLeft={2}
            sx={{ backgroundColor: "#033099" }}
          >
            <Box pb={3} px={2} alignSelf="center">
              <Typography
                variant="h2"
                align="center"
                sx={theme => ({
                  color: theme.palette.common.white,
                  fontSize: "1.375rem",
                  lineHeight: 1.2,
                  [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
                    fontSize: "1.5rem",
                    lineHeight: 1.2,
                  },
                  [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
                    fontSize: "1.75rem",
                    lineHeight: 1.2,
                  },
                })}
              >
                {t(
                  "Strap on your tool belts, welcome to the future of construction",
                  { ns: "PageSignup" }
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                backgroundImage: "url(/auth/auth-client.svg)",
                backgroundSize: "contain",
                paddingTop: "96.15384615%",
              }}
            ></Box>
          </Stack>
        </Stack>
      </PageContainer>
    </Page>
  );
};
