import { CustomFieldDataType } from "../../../clients/graphqlTypes";
import { useTranslate } from "@tolgee/react";

export function useCustomFieldDataTypes() {
  const { t } = useTranslate("CustomFields");

  const customFieldDataTypes: CustomFieldDataType[] = [
    "t_text",
    "t_number",
    "t_date",
    "t_boolean",
    "t_external_url",
    "t_youtube_video_id",
    "t_m",
    "t_cm",
    "t_mm",
  ];

  const customFieldDataTypeLabels: Record<CustomFieldDataType, string> = {
    t_text: t("Text"),
    t_boolean: t("Boolean"),
    t_number: t("Number"),
    t_m: t("Size in m"),
    t_cm: t("Size in cm"),
    t_mm: t("Size in mm"),
    t_date: t("Date"),
    t_external_url: t("URL link"),
    t_youtube_video_id: t("Youtube video ID"),
  };

  return { customFieldDataTypes, customFieldDataTypeLabels };
}
