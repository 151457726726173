import { Modal, RadioGroup } from "@msys/ui";
import { Stack, TextField } from "@mui/material";
import React from "react";
import { useTranslate } from "@tolgee/react";

type PasteAs = "INDIVIDUAL" | "GROUP";

interface Props {
  group: string;
  handleClose: () => void;
  handleComplete: (
    newGroup: string | null,
    handleClose: () => void
  ) => Promise<void>;
}

export function PropertiesPasteModal({
  group,
  handleClose,
  handleComplete,
}: Props) {
  const { t } = useTranslate(["Global", "QuoteItem"]);

  const [pasteAs, setPasteAs] = React.useState<PasteAs>("INDIVIDUAL");
  const [newGroup, setNewGroup] = React.useState<string>(group);

  return (
    <Modal
      title={t("Paste individually or as a group?", {
        ns: "QuoteItem",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Cancel", {
            ns: "Global",
          }),
          handleClick: handleClose,
          buttonProps: {
            variant: "text",
          },
        },
        {
          label: t("Confirm", {
            ns: "Global",
          }),
          handleClick: () => {
            handleComplete(pasteAs === "GROUP" ? newGroup : null, handleClose);
          },
          buttonProps: {
            disabled: pasteAs === "GROUP" && newGroup.length < 1,
          },
        },
      ]}
    >
      <Stack alignItems="flex-start" spacing={1}>
        <RadioGroup<PasteAs>
          options={[
            {
              label: t("Paste individually", {
                ns: "QuoteItem",
              }),
              value: "INDIVIDUAL",
            },
            {
              label: t("Paste in a group", {
                ns: "QuoteItem",
              }),
              value: "GROUP",
            },
          ]}
          value={pasteAs}
          onChange={value => {
            setPasteAs(value);
          }}
        />
        {pasteAs === "GROUP" && (
          <TextField
            label={t("Group name", {
              ns: "QuoteItem",
            })}
            value={newGroup}
            onChange={event => {
              setNewGroup(event.target.value);
            }}
            required
          />
        )}
      </Stack>
    </Modal>
  );
}
