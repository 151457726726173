/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProductSearchItem__ProductSearchResultFragmentDoc } from '../../features/products/Product.generated';
import { GetProductFullDescription_ProductSearchResultFragmentDoc } from '../../features/products/helper.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationProductsTableQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  specificFieldQueryFields?: Types.InputMaybe<Array<Types.PimSpecificFieldQueryField>>;
  filters?: Types.InputMaybe<Types.PimSearchProductsFilters>;
}>;


export type OrganisationProductsTableQuery = { pimSearchGroupedProducts: { __typename: 'MissingCapabilities' } | { __typename: 'PimSearchGroupedProductsConnection', totalCount: number, edges: Array<{ __typename: 'PimSearchGroupedProductsEdge', node: { __typename: 'PimSearchGroupedProductsNode', id: string, product: { __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null, title?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null, title?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } } }> } };

export type PimSearchGroupedProductsNodeFragment = { __typename: 'PimSearchGroupedProductsNode', id: string, product: { __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null, title?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', description?: string | null, extendedDescription?: string | null, title?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } };

export type OrganisationProductsRecentViewsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
}>;


export type OrganisationProductsRecentViewsQuery = { pimSupplierProductRecentViews: { __typename: 'MissingCapabilities' } | { __typename: 'PimSupplierProductRecentViewsResult', products: Array<{ __typename: 'PimCustomSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> } | { __typename: 'PimSupplierProductSearchResult', id: string, supplierId: string, gtin?: string | null, articleNumber: string, organisationFavourite: boolean, supplierFavourite: boolean, brand?: string | null, texts?: { __typename: 'PimProductTexts', title?: string | null, description?: string | null, extendedDescription?: string | null } | null, supplier: { __typename: 'Company', id: string, title: string, logo: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }, pricing?: { __typename: 'PimPricing', listPrice?: number | null, purchasePrice?: number | null, netPrice?: number | null } | null, attachments: Array<{ __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string }> }> } };

export const PimSearchGroupedProductsNodeFragmentDoc = gql`
    fragment PimSearchGroupedProductsNode on PimSearchGroupedProductsNode {
  id
  product {
    ...ProductSearchItem__ProductSearchResult
    ...GetProductFullDescription_ProductSearchResult
  }
}
    ${ProductSearchItem__ProductSearchResultFragmentDoc}
${GetProductFullDescription_ProductSearchResultFragmentDoc}`;
export const OrganisationProductsTableDocument = gql`
    query OrganisationProductsTable($offset: Int, $limit: Int!, $searchTerm: String, $specificFieldQueryFields: [PimSpecificFieldQueryField!], $filters: PimSearchProductsFilters) {
  pimSearchGroupedProducts(
    offset: $offset
    limit: $limit
    filters: $filters
    specificFieldQueryFields: $specificFieldQueryFields
    search: $searchTerm
  ) {
    ... on PimSearchGroupedProductsConnection {
      totalCount
      edges {
        node {
          id
          ...PimSearchGroupedProductsNode
        }
      }
    }
  }
}
    ${PimSearchGroupedProductsNodeFragmentDoc}`;

/**
 * __useOrganisationProductsTableQuery__
 *
 * To run a query within a React component, call `useOrganisationProductsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationProductsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationProductsTableQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      specificFieldQueryFields: // value for 'specificFieldQueryFields'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrganisationProductsTableQuery(baseOptions: Apollo.QueryHookOptions<OrganisationProductsTableQuery, OrganisationProductsTableQueryVariables> & ({ variables: OrganisationProductsTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationProductsTableQuery, OrganisationProductsTableQueryVariables>(OrganisationProductsTableDocument, options);
      }
export function useOrganisationProductsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationProductsTableQuery, OrganisationProductsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationProductsTableQuery, OrganisationProductsTableQueryVariables>(OrganisationProductsTableDocument, options);
        }
export function useOrganisationProductsTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationProductsTableQuery, OrganisationProductsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationProductsTableQuery, OrganisationProductsTableQueryVariables>(OrganisationProductsTableDocument, options);
        }
export type OrganisationProductsTableQueryHookResult = ReturnType<typeof useOrganisationProductsTableQuery>;
export type OrganisationProductsTableLazyQueryHookResult = ReturnType<typeof useOrganisationProductsTableLazyQuery>;
export type OrganisationProductsTableSuspenseQueryHookResult = ReturnType<typeof useOrganisationProductsTableSuspenseQuery>;
export type OrganisationProductsTableQueryResult = Apollo.QueryResult<OrganisationProductsTableQuery, OrganisationProductsTableQueryVariables>;
export const OrganisationProductsRecentViewsDocument = gql`
    query OrganisationProductsRecentViews($limit: Int!) {
  pimSupplierProductRecentViews(limit: $limit) {
    ... on PimSupplierProductRecentViewsResult {
      products {
        id
        ...ProductSearchItem__ProductSearchResult
      }
    }
  }
}
    ${ProductSearchItem__ProductSearchResultFragmentDoc}`;

/**
 * __useOrganisationProductsRecentViewsQuery__
 *
 * To run a query within a React component, call `useOrganisationProductsRecentViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationProductsRecentViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationProductsRecentViewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOrganisationProductsRecentViewsQuery(baseOptions: Apollo.QueryHookOptions<OrganisationProductsRecentViewsQuery, OrganisationProductsRecentViewsQueryVariables> & ({ variables: OrganisationProductsRecentViewsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationProductsRecentViewsQuery, OrganisationProductsRecentViewsQueryVariables>(OrganisationProductsRecentViewsDocument, options);
      }
export function useOrganisationProductsRecentViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationProductsRecentViewsQuery, OrganisationProductsRecentViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationProductsRecentViewsQuery, OrganisationProductsRecentViewsQueryVariables>(OrganisationProductsRecentViewsDocument, options);
        }
export function useOrganisationProductsRecentViewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationProductsRecentViewsQuery, OrganisationProductsRecentViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationProductsRecentViewsQuery, OrganisationProductsRecentViewsQueryVariables>(OrganisationProductsRecentViewsDocument, options);
        }
export type OrganisationProductsRecentViewsQueryHookResult = ReturnType<typeof useOrganisationProductsRecentViewsQuery>;
export type OrganisationProductsRecentViewsLazyQueryHookResult = ReturnType<typeof useOrganisationProductsRecentViewsLazyQuery>;
export type OrganisationProductsRecentViewsSuspenseQueryHookResult = ReturnType<typeof useOrganisationProductsRecentViewsSuspenseQuery>;
export type OrganisationProductsRecentViewsQueryResult = Apollo.QueryResult<OrganisationProductsRecentViewsQuery, OrganisationProductsRecentViewsQueryVariables>;