import { ContractingPdfReplacementName } from "@msys/textutils";
import { Modal } from "@msys/ui";
import {
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";

export function AvailableContractingPdfReplacementsModal({
  handleClose,
}: {
  handleClose(): void;
}) {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  const replacements: Record<ContractingPdfReplacementName, string> = {
    "{{contractee name}}": t(
      `Full name of the contractee, e.g. "Company ABC" or "Mr John Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee salutation}}": t(
      'Salutation of the contractee, e.g. "Mr" or "Mrs"',
      { ns: "OrganisationSettings" }
    ),
    "{{contractee firstname}}": t('First name of the contractee, e.g. "John"', {
      ns: "OrganisationSettings",
    }),
    "{{contractee lastname}}": t('Last name of the contractee, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "{{contractee number}}": t("CRM number of the contractee", {
      ns: "OrganisationSettings",
    }),
    "{{contractee address}}": t(`Address of the contractee`, {
      ns: "OrganisationSettings",
    }),
    "{{contractee email}}": t(`Email address of the contractee`, {
      ns: "OrganisationSettings",
    }),
    "{{contractee phone}}": t(`Phone number of the contractee`, {
      ns: "OrganisationSettings",
    }),
    "{{contractee contact person name}}": t(
      `First and last name of the contractee's contact person, e.g. "John Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person salutation}}": t(
      `Salutation of the contractee's contact person, e.g. "Mr"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person firstname}}": t(
      `First name of the contractee's contact person, e.g. "John"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contractee contact person lastname}}": t(
      `Last name of the contractee's contact person, e.g. "Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),

    "{{end client name}}": t(
      `Full name of the end client, e.g. "Agency X" or "Mrs Jane Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client salutation}}": t(
      'Salutation of the individual end client, e.g. "Mr" or "Mrs"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client firstname}}": t('First name of the end client, e.g. "Jane"', {
      ns: "OrganisationSettings",
    }),
    "{{end client lastname}}": t('Last name of the end client, e.g. "Smith"', {
      ns: "OrganisationSettings",
    }),
    "{{end client address}}": t(`Address of the end client`, {
      ns: "OrganisationSettings",
    }),
    "{{end client email}}": t(`Email address of the end client`, {
      ns: "OrganisationSettings",
    }),
    "{{end client phone}}": t(`Phone number of the end client`, {
      ns: "OrganisationSettings",
    }),
    "{{end client contact person name}}": t(
      `First and last name of the end client contact person, e.g. "John Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person salutation}}": t(
      `Salutation of the end client contact person, e.g. "Mr"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person firstname}}": t(
      `First name of the end client contact person, e.g. "John"`,
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{end client contact person lastname}}": t(
      `Last name of the end client contact person, e.g. "Smith"`,
      {
        ns: "OrganisationSettings",
      }
    ),

    "{{contact person name}}": t(
      'The first and last name of the contact person, e.g. "John Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contact person firstname}}": t(
      'The first and last name of the contact person, e.g. "John Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contact person lastname}}": t(
      'The last name of the contact person, e.g. "Smith"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{contact person salutation}}": t('Salutation, e.g. "Mr"', {
      ns: "OrganisationSettings",
    }),
    "{{contact person email}}": t("Email address of the contact person", {
      ns: "OrganisationSettings",
    }),
    "{{contact person phone}}": t("Phone number of the contact person", {
      ns: "OrganisationSettings",
    }),
    "{{organisation name}}": t("The name of your organisation", {
      ns: "OrganisationSettings",
    }),
    "{{organisation billing address}}": t(
      "The billing address of your organisation",
      {
        ns: "OrganisationSettings",
      }
    ),

    "{{project name}}": t("The name of the project", {
      ns: "OrganisationSettings",
    }),
    "{{project number}}": t("The number of the project", {
      ns: "OrganisationSettings",
    }),
    "{{project address}}": t(
      'Full address of the project, e.g. "Hackescher Markt 4, 10178 Berlin"',
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{quote name}}": t("The name of the quote", {
      ns: "OrganisationSettings",
    }),
    "{{quote number}}": t("The number of the quote", {
      ns: "OrganisationSettings",
    }),
    "{{quote earliest work start}}": t(
      "The earliest possible start of the work for the quote",
      {
        ns: "OrganisationSettings",
      }
    ),
    "{{quote work duration}}": t("The work duration of the quote", {
      ns: "OrganisationSettings",
    }),
    "{{quote valid until}}": t("The date the quote is valid until", {
      ns: "OrganisationSettings",
    }),
    "{{quote publish date}}": t("The publish date of quote", {
      ns: "OrganisationSettings",
    }),
  };

  return (
    <Modal
      title={t("Using placeholders in texts", {
        ns: "OrganisationSettings",
      })}
      handleClose={handleClose}
      actionButtons={[
        {
          label: t("Close", {
            ns: "Global",
          }),
          handleClick: handleClose,
        },
      ]}
    >
      <DialogContentText>
        {t(
          "The following placeholders are available for use in every text replacements are listed below:",
          {
            ns: "OrganisationSettings",
          }
        )}
      </DialogContentText>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {t("Placeholder", {
                ns: "OrganisationSettings",
              })}
            </TableCell>
            <TableCell>
              {t("Description", {
                ns: "OrganisationSettings",
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(replacements)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([name, description], index) => (
              <TableRow key={index}>
                <TableCell>{name}</TableCell>
                <TableCell>{description}</TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell>{'<prop("@root", "Key")>'}</TableCell>
            <TableCell>
              {t("The value of property from the quote root item", {
                ns: "OrganisationSettings",
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Modal>
  );
}
