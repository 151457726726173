import { getFiles } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { namedOperations } from "../../../../clients/graphqlTypes";
import { RestrictedByProjectPermissionWithDebug } from "../../../auth/RestrictedByProjectPermission";
import { FilesBoxTable } from "../../attachments/FilesBoxTable";
import { useProjectAttachments } from "../../attachments/useAttachments";
import { ProjectOverviewFilesBox_ProjectFragment } from "./ProjectOverviewFilesBox.generated";

interface Props {
  project: ProjectOverviewFilesBox_ProjectFragment;
}

export const ProjectOverviewFilesBox = ({ project }: Props) => {
  const { t } = useTranslate("Global");

  const { addAttachments, modifyAttachment, removeAttachment, loading } =
    useProjectAttachments(project.id, [namedOperations.Query.ProjectOverview]);

  return (
    <FilesBoxTable
      title={t("Attachments")}
      attachments={getFiles(project.attachments)}
      canEdit={true}
      canSign={true}
      defaultPlace={project.building?.buildingAddress?.city}
      addAttachments={addAttachments}
      modifyAttachment={modifyAttachment}
      removeAttachment={removeAttachment}
      loading={loading}
      renderAddIconButton={button => (
        <RestrictedByProjectPermissionWithDebug
          permission="READ_PROJECT"
          project={project}
        >
          {button}
        </RestrictedByProjectPermissionWithDebug>
      )}
      renderEditIconButton={button => (
        <RestrictedByProjectPermissionWithDebug
          permission="MANAGE_PROJECT"
          project={project}
        >
          {button}
        </RestrictedByProjectPermissionWithDebug>
      )}
    />
  );
};
