import { LoadingSpinner } from "@msys/ui";
import { Box } from "@mui/material";
import React from "react";
import { useIntersection, useLatest } from "react-use";

interface Props {
  fetchMore: () => Promise<void> | void;
  enabled: boolean;
}

export const FetchMoreListener = ({ fetchMore, enabled }: Props) => {
  const [isFetchingMore, setIsFetchingMore] = React.useState<{
    immediate: boolean;
    debounced: boolean;
  }>({ immediate: false, debounced: false });

  // clean timeout on unmount
  const timeoutId = React.useRef<ReturnType<typeof setTimeout>>();
  React.useEffect(() => {
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, []);

  const fetchMoreLatest = useLatest(async () => {
    if (isFetchingMore.debounced) return;
    setIsFetchingMore({ immediate: true, debounced: true });
    try {
      await fetchMore();
    } finally {
      // small timeout to not trigger another reload immediately
      setIsFetchingMore(state => ({ ...state, immediate: false }));
      timeoutId.current = setTimeout(() => {
        setIsFetchingMore(state => ({ ...state, debounced: false }));
      }, 500);
    }
  });

  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const inView = intersection && intersection.intersectionRatio;

  React.useEffect(() => {
    if (enabled && inView && !isFetchingMore.debounced) {
      fetchMoreLatest.current();
    }
  }, [inView, enabled, isFetchingMore.debounced, fetchMoreLatest]);

  return enabled ? (
    <Box display="flex" position="relative" style={{ margin: 0, marginTop: 0 }}>
      <div
        ref={intersectionRef}
        style={{
          position: "absolute",
          width: "100%",
          top: "-250px", // small margin from where we start the trigger
          bottom: "0",
          pointerEvents: "none",
        }}
      />
      {isFetchingMore.debounced && <LoadingSpinner />}
    </Box>
  ) : null;
};
