import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import { ModalOpenButton } from "@msys/ui";
import DecisionIcon from "@mui/icons-material/HelpOutline";
import { Button, ButtonProps } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { ComponentProps } from "react";
import { QuoteTreeItem_TreeItemFragment } from "../../projects/quote-trees.generated";
import { TemplatesQuoteSetsSearchModal } from "../../templates/quote/TemplatesQuoteSetsSearchModal";
import { useDefaultDecisionActions } from "../hooks/useDefaultDecisionActions";
import { useSetItemTemplatePlaceholderTemplateMutation } from "./TemplateSetDecisionModalButton.generated";
import { templateSearch } from "@msys/document-core";

interface Props {
  projectId: string | null;
  templateSearchFilterDefaults: QuoteTreeItem_TreeItemFragment["templateSearchFilterDefaults"];
  templateSearchSortingDefaults: QuoteTreeItem_TreeItemFragment["templateSearchSortingDefaults"];
  docId: string;
  itemId: string;
  canFinalize: boolean;
  setItemExpanded: (id: string, expanded: boolean) => void;
  expandedItemIds: string[] | undefined;
  refetch?: () => Promise<unknown>;
  type?: "button" | "icon";
  color?: ButtonProps["color"];
  label?: string;
  disabled?: boolean;
}

export const TemplateSetDecisionModalButton = ({
  projectId,
  templateSearchFilterDefaults,
  templateSearchSortingDefaults,
  docId,
  itemId,
  canFinalize,
  setItemExpanded,
  expandedItemIds,
  refetch,
  type = "button",
  color = "secondary",
  label,
  disabled,
}: Props) => {
  const { t } = useTranslate(["Decisions"]);

  const client = useApolloClient();

  const decisionActions = useDefaultDecisionActions({
    projectId,
    docId,
    canFinalize,
    expandedItemIds,
    refetch,
  });

  const [setItemTemplatePlaceholderTemplateMutation] =
    useSetItemTemplatePlaceholderTemplateMutation({
      client,
      onCompleted: ({
        setItemTemplatePlaceholderTemplate: {
          item: { id },
        },
      }) => setItemExpanded(id, true),
    });

  const button =
    type === "button" ? (
      <Button
        disabled={disabled}
        color={color}
        variant="contained"
        size="small"
        startIcon={<DecisionIcon />}
        title={label ?? t("Question", { ns: "Decisions" })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {label ?? t("Question", { ns: "Decisions" })}
      </Button>
    ) : type === "icon" ? (
      <Button
        disabled={disabled}
        color={color}
        variant="contained"
        size="small"
        title={t("Question", { ns: "Decisions" })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ minWidth: 32, width: 32 }}
      >
        <DecisionIcon fontSize="small" style={{ fontSize: "1.15rem" }} />
      </Button>
    ) : (
      assertNever(type)
    );

  return (
    <ModalOpenButton
      Modal={TemplatesQuoteSetsSearchModal}
      modalProps={{
        initialFilters: filterDefinitionsToFormValues(
          templateSearchFilterDefaults
        ),
        initialSorting: sortingDefinitionsToFormValues(
          templateSearchSortingDefaults
        ),
        handleTemplateChoice: async template => {
          if (template.resolvedAsReadModelVersionNumber) {
            await setItemTemplatePlaceholderTemplateMutation({
              variables: {
                input: {
                  docId,
                  itemId,
                  projectId,
                  templateQuoteId: template.id,
                  templateQuoteIdAtVersionNumber:
                    template.resolvedAsReadModelVersionNumber,
                },
              },
            });
            await decisionActions.refetch();
          }
        },
      }}
    >
      {button}
    </ModalOpenButton>
  );
};

function filterDefinitionsToFormValues(
  definitions: QuoteTreeItem_TreeItemFragment["templateSearchFilterDefaults"]
): ComponentProps<typeof TemplatesQuoteSetsSearchModal>["initialFilters"] {
  return {
    templateTypeIds: definitions.templateTypeIdFilter
      ? [definitions.templateTypeIdFilter.valueId]
      : null,
    properties: definitions.propertyFilters.map(propertyFilter => {
      switch (propertyFilter.__typename) {
        case "EntitySearchPropertyFilterBoolFilter": {
          return {
            key: propertyFilter.key,
            boolFilter: {
              operatorBool: propertyFilter.operatorBool,
              valueBool: propertyFilter.valueBool,
            },
          };
        }
        case "EntitySearchPropertyFilterBoolInFilter": {
          return {
            key: propertyFilter.key,
            boolInFilter: {
              operatorBoolIn: propertyFilter.operatorBoolIn,
              valueBoolIn: propertyFilter.valueBoolIn,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberBetweenFilter": {
          return {
            key: propertyFilter.key,
            numberBetweenFilter: {
              operatorNumberBetween: propertyFilter.operatorNumberBetween,
              valueNumberBetween: propertyFilter.valueNumberBetween,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberFilter": {
          return {
            key: propertyFilter.key,
            numberFilter: {
              operatorNumber: propertyFilter.operatorNumber,
              valueNumber: propertyFilter.valueNumber,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberInFilter": {
          return {
            key: propertyFilter.key,
            numberInFilter: {
              operatorNumberIn: propertyFilter.operatorNumberIn,
              valueNumberIn: propertyFilter.valueNumberIn,
            },
          };
        }
        case "EntitySearchPropertyFilterTextFilter": {
          return {
            key: propertyFilter.key,
            textFilter: {
              operatorText: propertyFilter.operatorText,
              valueText: propertyFilter.valueText,
            },
          };
        }
        case "EntitySearchPropertyFilterTextInFilter": {
          return {
            key: propertyFilter.key,
            textInFilter: {
              operatorTextIn: propertyFilter.operatorTextIn,
              valueTextIn: propertyFilter.valueTextIn,
            },
          };
        }
        case "EntitySearchPropertyFilterTextArrayOfFilter": {
          return {
            key: propertyFilter.key,
            textArrayOfFilter: {
              operatorTextArrayOf: propertyFilter.operatorTextArrayOf,
              valueTextArrayOf: propertyFilter.valueTextArrayOf,
            },
          };
        }
        case "EntitySearchPropertyFilterNumberArrayOfFilter": {
          return {
            key: propertyFilter.key,
            numberArrayOfFilter: {
              operatorNumberArrayOf: propertyFilter.operatorNumberArrayOf,
              valueNumberArrayOf: propertyFilter.valueNumberArrayOf,
            },
          };
        }
        default:
          assertNever(propertyFilter);
      }
    }),
  };
}

function sortingDefinitionsToFormValues(
  definitions: QuoteTreeItem_TreeItemFragment["templateSearchSortingDefaults"]
): ComponentProps<typeof TemplatesQuoteSetsSearchModal>["initialSorting"] {
  return definitions.map(definition => ({
    propertySorting:
      definition.__typename === "EntitySearchSortingPropertyValueSorting"
        ? {
            kind: definition.kind,
            key: definition.key,
            direction: definition.direction,
          }
        : undefined,
    fieldSorting: undefined,
  }));
}
