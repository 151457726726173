/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateEmailAddressQueryVariables = Types.Exact<{
  emailAddress: Types.Scalars['EmailAddress']['input'];
}>;


export type ValidateEmailAddressQuery = { validateEmailAddress?: boolean | null };


export const ValidateEmailAddressDocument = gql`
    query ValidateEmailAddress($emailAddress: EmailAddress!) {
  validateEmailAddress(emailAddress: $emailAddress)
}
    `;

/**
 * __useValidateEmailAddressQuery__
 *
 * To run a query within a React component, call `useValidateEmailAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateEmailAddressQuery({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useValidateEmailAddressQuery(baseOptions: Apollo.QueryHookOptions<ValidateEmailAddressQuery, ValidateEmailAddressQueryVariables> & ({ variables: ValidateEmailAddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateEmailAddressQuery, ValidateEmailAddressQueryVariables>(ValidateEmailAddressDocument, options);
      }
export function useValidateEmailAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateEmailAddressQuery, ValidateEmailAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateEmailAddressQuery, ValidateEmailAddressQueryVariables>(ValidateEmailAddressDocument, options);
        }
export function useValidateEmailAddressSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateEmailAddressQuery, ValidateEmailAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateEmailAddressQuery, ValidateEmailAddressQueryVariables>(ValidateEmailAddressDocument, options);
        }
export type ValidateEmailAddressQueryHookResult = ReturnType<typeof useValidateEmailAddressQuery>;
export type ValidateEmailAddressLazyQueryHookResult = ReturnType<typeof useValidateEmailAddressLazyQuery>;
export type ValidateEmailAddressSuspenseQueryHookResult = ReturnType<typeof useValidateEmailAddressSuspenseQuery>;
export type ValidateEmailAddressQueryResult = Apollo.QueryResult<ValidateEmailAddressQuery, ValidateEmailAddressQueryVariables>;