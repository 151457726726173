/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { OrganisationQuoteSettingsForm_DefaultsFragmentDoc } from '../../features/organisations/boxes/OrganisationQuoteSettingsBox.generated';
import { OrganisationInvoiceSettingsForm_DefaultsFragmentDoc } from '../../features/organisations/boxes/OrganisationInvoiceSettingsBox.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationSettingsPricesAndCalculationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganisationSettingsPricesAndCalculationsQuery = { organisationDefaults: { __typename: 'OrganisationDefaults', id: string, defaultMaterialMargin: number, defaultContractType: Types.ContractType, defaultQuoteIsBinding: boolean, defaultQuoteValidityDuration: number, defaultQuoteShowOfferConditions: boolean, defaultQuoteMaterialPriceHandling: Types.QuoteMaterialPriceHandling, defaultInvoiceShowLaborCostSeparately: boolean, defaultInvoicePaymentTermDuration: number, defaultMaterialMarginRanges: Array<{ __typename: 'QuoteMaterialMarginRange', priceFrom: number, priceTo: number, materialMargin: number }>, defaultVerticalSettings: Array<{ __typename: 'OrganisationVerticalSetting', id: string, vertical: Types.Vertical, averageWorkBuyingPrice: number, averageWorkSellingPrice: number }> } };


export const OrganisationSettingsPricesAndCalculationsDocument = gql`
    query OrganisationSettingsPricesAndCalculations {
  organisationDefaults {
    ... on OrganisationDefaults {
      id
      ...OrganisationQuoteSettingsForm_Defaults
      ...OrganisationInvoiceSettingsForm_Defaults
    }
  }
}
    ${OrganisationQuoteSettingsForm_DefaultsFragmentDoc}
${OrganisationInvoiceSettingsForm_DefaultsFragmentDoc}`;

/**
 * __useOrganisationSettingsPricesAndCalculationsQuery__
 *
 * To run a query within a React component, call `useOrganisationSettingsPricesAndCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationSettingsPricesAndCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationSettingsPricesAndCalculationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationSettingsPricesAndCalculationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationSettingsPricesAndCalculationsQuery, OrganisationSettingsPricesAndCalculationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationSettingsPricesAndCalculationsQuery, OrganisationSettingsPricesAndCalculationsQueryVariables>(OrganisationSettingsPricesAndCalculationsDocument, options);
      }
export function useOrganisationSettingsPricesAndCalculationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationSettingsPricesAndCalculationsQuery, OrganisationSettingsPricesAndCalculationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationSettingsPricesAndCalculationsQuery, OrganisationSettingsPricesAndCalculationsQueryVariables>(OrganisationSettingsPricesAndCalculationsDocument, options);
        }
export function useOrganisationSettingsPricesAndCalculationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganisationSettingsPricesAndCalculationsQuery, OrganisationSettingsPricesAndCalculationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganisationSettingsPricesAndCalculationsQuery, OrganisationSettingsPricesAndCalculationsQueryVariables>(OrganisationSettingsPricesAndCalculationsDocument, options);
        }
export type OrganisationSettingsPricesAndCalculationsQueryHookResult = ReturnType<typeof useOrganisationSettingsPricesAndCalculationsQuery>;
export type OrganisationSettingsPricesAndCalculationsLazyQueryHookResult = ReturnType<typeof useOrganisationSettingsPricesAndCalculationsLazyQuery>;
export type OrganisationSettingsPricesAndCalculationsSuspenseQueryHookResult = ReturnType<typeof useOrganisationSettingsPricesAndCalculationsSuspenseQuery>;
export type OrganisationSettingsPricesAndCalculationsQueryResult = Apollo.QueryResult<OrganisationSettingsPricesAndCalculationsQuery, OrganisationSettingsPricesAndCalculationsQueryVariables>;