import { SelectMultiple } from "@msys/ui";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { FilterModal } from "../../commons/filters/FilterModal";
import {
  BillOfMaterialsItemStatus,
  BillOfMaterialsItemType,
} from "../../../clients/graphqlTypes";
import { ProjectBillOfMaterialsQueryVariables } from "../../main-routes/projects/ProjectBillOfMaterials.generated";
import { useStatusLabels, useTypeLabels } from "./BillOfMaterialsItemStatus";

export const getQuoteLabel = (quote: { title: string; number: string }) =>
  `${quote.title} (${quote.number})`;

type BillOfMaterialsFilters = Omit<
  ProjectBillOfMaterialsQueryVariables,
  "projectId"
>;

export function BillOfMaterialsFiltersModal({
  filters,
  setFilters,
  resetFilters,
  filterOptions,
  handleClose,
}: {
  filters: BillOfMaterialsFilters;
  setFilters: React.Dispatch<React.SetStateAction<BillOfMaterialsFilters>>;
  resetFilters: () => void;
  handleClose: () => void;
  filterOptions: {
    statuses?: BillOfMaterialsItemStatus[];
    quotes?: { id: string; number: string; title: string }[];
    types?: BillOfMaterialsItemType[];
  };
}) {
  const { t } = useTranslate(["Global", "Materials"]);

  const [filtersState, setFiltersState] = React.useState(filters);

  const { statusLabels } = useStatusLabels();

  const { typeLabels } = useTypeLabels();

  return (
    <FilterModal
      title={t("Material filters", {
        ns: "Materials",
      })}
      handleApply={() => {
        setFilters(filtersState);
      }}
      handleReset={resetFilters}
      handleClose={handleClose}
    >
      <SelectMultiple
        label={t("Status", {
          ns: "Materials",
        })}
        options={
          filterOptions.statuses?.map(status => ({
            value: status,
            label: statusLabels[status],
          })) ?? []
        }
        value={filtersState.filterStatus ?? []}
        onChange={value => {
          setFiltersState(state => ({
            ...state,
            filterStatus: value,
            aggregateProducts: true,
          }));
        }}
      />
      <SelectMultiple
        label={t("Type", {
          ns: "Materials",
        })}
        options={
          filterOptions.types?.map(type => ({
            value: type,
            label: typeLabels[type],
          })) ?? []
        }
        value={filtersState.filterType ?? []}
        onChange={value => {
          setFiltersState(state => ({
            ...state,
            filterType: value,
          }));
        }}
      />
      <SelectMultiple
        label={t("Quote", {
          ns: "Materials",
        })}
        options={
          filterOptions.quotes?.map(quotes => ({
            value: quotes.id,
            label: getQuoteLabel(quotes),
          })) ?? []
        }
        value={filtersState.filterQuoteId ?? []}
        onChange={value => {
          setFiltersState(state => ({
            ...state,
            filterQuoteId: value,
          }));
        }}
      />
    </FilterModal>
  );
}
