import { useApolloClient } from "@apollo/client";
import { draftStateToHtml } from "@msys/textutils";
import { CardContainer, FormattedPrice, Modal, RichTextEditor } from "@msys/ui";
import { Box, Paper } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { RawDraftContentState, convertFromRaw } from "draft-js";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { uniqueId } from "lodash";
import React from "react";
import * as Yup from "yup";
import { QuantityUnit } from "../../../clients/graphqlTypes";
import { FormattedFloatField } from "../../commons/form-fields/FormattedFloatField";
import { FormattedPriceField } from "../../commons/form-fields/FormattedPriceField";
import { Stack } from "../../commons/layout/Stack";
import { cleanHTML } from "../../utils";
import { QuantityUnitField } from "../doc-items/QuantityUnitField";
import {
  InvoiceExtraItemDetailsModal_InvoiceExtraItemFragment,
  InvoiceExtraItemDetailsModal_InvoiceFragment,
} from "./InvoiceExtraItemDetailsModal.generated";
import {
  useAddInvoiceExtraItemMutation,
  useModifyInvoiceExtraItemMutation,
} from "./Invoices.generated";

interface Props {
  invoice: InvoiceExtraItemDetailsModal_InvoiceFragment;
  extraItem?: InvoiceExtraItemDetailsModal_InvoiceExtraItemFragment;
  projectId: string;
  docId: string;
  handleComplete?: () => void | Promise<void>;
  handleClose: () => void;
  refetchQueries: string[];
}

interface FormValues {
  title: string;
  description: string;
  quantity: number;
  quantityUnit: QuantityUnit;
  pricePerUnit: number;
}

export const InvoiceExtraItemDetailsModal = ({
  projectId,
  docId,
  invoice,
  extraItem,
  handleComplete,
  handleClose,
  refetchQueries,
}: Props) => {
  const { t } = useTranslate([
    "InvoiceDetailsForm",
    "Global",
    "ItemPropertyField",
  ]);

  const client = useApolloClient();
  const [addInvoiceExtraItem, { loading: addInvoiceExtraItemLoading }] =
    useAddInvoiceExtraItemMutation({ client });
  const [modifyExtraItem, { loading: modifyExtraItemLoading }] =
    useModifyInvoiceExtraItemMutation({ client });

  const onSubmit = async (values: FormValues) => {
    if (extraItem) {
      await modifyExtraItem({
        variables: {
          input: {
            docId,
            projectId,
            invoiceId: invoice.id,
            invoiceExtraItemId: extraItem.id,
            values: {
              title: values.title,
              description: values.description,
              quantity: values.quantity,
              quantityUnit: values.quantityUnit,
              pricePerUnit: values.pricePerUnit,
            },
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
    } else {
      await addInvoiceExtraItem({
        variables: {
          input: {
            docId,
            projectId,
            invoiceId: invoice.id,
            values: {
              title: values.title,
              description: values.description,
              quantity: values.quantity,
              quantityUnit: values.quantityUnit,
              pricePerUnit: values.pricePerUnit,
            },
          },
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
    }

    await handleComplete?.();
    handleClose();
  };

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      onSubmit={onSubmit}
      initialValues={{
        title: extraItem?.title ?? "",
        description: extraItem?.description ?? "",
        quantity: extraItem?.quantity ?? 1,
        quantityUnit: extraItem?.quantityUnit ?? "piece",
        pricePerUnit: extraItem?.pricePerUnit ?? 0,
      }}
      validationSchema={Yup.object().shape({
        properties: Yup.mixed(),
      })}
    >
      {formikProps => (
        <Modal
          dialogProps={{ maxWidth: "sm" }}
          title={
            extraItem
              ? t("Modify extra item", {
                  ns: "InvoiceDetailsForm",
                })
              : t("Add extra Item", {
                  ns: "InvoiceDetailsForm",
                })
          }
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Cancel", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                type: "submit",
                form: formId,
                disabled: formikProps.isSubmitting || !formikProps.isValid,
                loading: formikProps.isSubmitting,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack flexDirection="column">
              <Paper>
                <Stack p={1} flexDirection="column">
                  <Field
                    component={TextField}
                    name="title"
                    label={t("Title", {
                      ns: "InvoiceDetailsForm",
                    })}
                  />
                  <RichTextEditor
                    label={t("Description", {
                      ns: "InvoiceDetailsForm",
                    })}
                    htmlContent={formikProps.values.description}
                    onChange={async (content: RawDraftContentState) => {
                      formikProps.setFieldValue(
                        "description",
                        cleanHTML(draftStateToHtml(convertFromRaw(content)))
                      );
                    }}
                    saveButtonLabel={t("Save", {
                      ns: "Global",
                    })}
                    cancelButtonLabel={t("Cancel", {
                      ns: "Global",
                    })}
                  />
                </Stack>
              </Paper>
              <CardContainer
                title={t("Invoice Calculation", {
                  ns: "InvoiceDetailsForm",
                })}
              >
                <Stack m={1} alignItems="center">
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexShrink: 1,
                      alignItems: "start",
                    }}
                  >
                    <FormattedFloatField
                      label={t("Quantity", {
                        ns: "InvoiceDetailsForm",
                      })}
                      name="quantity"
                      style={{
                        minWidth: "68px",
                        marginRight: "2px",
                        flexBasis: "48%",
                      }}
                    />

                    <QuantityUnitField name={"quantityUnit"} />
                  </div>
                  x
                  <FormattedPriceField
                    name="pricePerUnit"
                    label={t("Price/Unit", {
                      ns: "InvoiceDetailsForm",
                    })}
                    type="price"
                  />
                  =
                  <Box flex="2">
                    <FormattedPrice
                      value={
                        formikProps.values.pricePerUnit *
                        formikProps.values.quantity
                      }
                    />
                  </Box>
                </Stack>
              </CardContainer>
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
