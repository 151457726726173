import { createFilterOptions } from "@mui/material/useAutocomplete";
import { Autocomplete, AutocompleteProps } from "./Autocomplete";

interface Props<Option = { inputValue?: string | undefined }>
  extends Omit<
    AutocompleteProps<Option, true>,
    | "freeSolo"
    | "clearOnBlur"
    | "selectOnFocus"
    | "handleHomeEndKeys"
    | "getOptionLabel"
  > {
  getOptionLabel: Exclude<
    AutocompleteProps<Option, true>["getOptionLabel"],
    undefined
  >;
  getNewValueOption: (inputValue: string) => Option;
}

export const AutocompleteFreeSolo = <Option,>({
  getOptionLabel,
  getNewValueOption,
  ...props
}: Props<Option>) => {
  const filter = createFilterOptions<Option>();

  return (
    <Autocomplete<Option, true>
      {...props}
      freeSolo
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      getOptionLabel={getOptionLabel}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const isExisting = options.some(
          role => getOptionLabel(role) === params.inputValue
        );
        // Suggest the creation of a new value
        if (params.inputValue !== "" && !isExisting) {
          filtered.push(getNewValueOption(params.inputValue));
        }

        return filtered;
      }}
    />
  );
};
