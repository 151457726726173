/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductSearchModal_ProductTypesQueryVariables = Types.Exact<{
  productTypeIds: Array<Types.Scalars['ID']['input']>;
}>;


export type ProductSearchModal_ProductTypesQuery = { pimProductTypes: { __typename: 'MissingCapabilities' } | { __typename: 'PimProductTypesResult', productTypes: Array<{ __typename: 'PimProductType', id: string, label: string }> } };

export type ProductSearchModal_SuppliersQueryVariables = Types.Exact<{
  organisationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type ProductSearchModal_SuppliersQuery = { searchSuppliers: { __typename: 'SupplierSearchResultConnection', edges: Array<{ __typename: 'SupplierSearchResultEdge', node: { __typename: 'SupplierSearchResult', organisation: { __typename: 'Company', id: string, title: string }, crmCompany?: { __typename: 'CrmCompany', id: string, title: string, linkedSystemOrganisationId?: string | null } | null } }> } };

export type ProductSearchModal_ManufacturersQueryVariables = Types.Exact<{
  organisationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type ProductSearchModal_ManufacturersQuery = { searchManufacturers: { __typename: 'ManufacturerSearchResultConnection', edges: Array<{ __typename: 'ManufacturerSearchResultEdge', node: { __typename: 'ManufacturerSearchResult', organisation: { __typename: 'Company', id: string, title: string } } }> } };


export const ProductSearchModal_ProductTypesDocument = gql`
    query ProductSearchModal_ProductTypes($productTypeIds: [ID!]!) {
  pimProductTypes(ids: $productTypeIds) {
    ... on PimProductTypesResult {
      productTypes {
        id
        label
      }
    }
  }
}
    `;

/**
 * __useProductSearchModal_ProductTypesQuery__
 *
 * To run a query within a React component, call `useProductSearchModal_ProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchModal_ProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchModal_ProductTypesQuery({
 *   variables: {
 *      productTypeIds: // value for 'productTypeIds'
 *   },
 * });
 */
export function useProductSearchModal_ProductTypesQuery(baseOptions: Apollo.QueryHookOptions<ProductSearchModal_ProductTypesQuery, ProductSearchModal_ProductTypesQueryVariables> & ({ variables: ProductSearchModal_ProductTypesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchModal_ProductTypesQuery, ProductSearchModal_ProductTypesQueryVariables>(ProductSearchModal_ProductTypesDocument, options);
      }
export function useProductSearchModal_ProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchModal_ProductTypesQuery, ProductSearchModal_ProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchModal_ProductTypesQuery, ProductSearchModal_ProductTypesQueryVariables>(ProductSearchModal_ProductTypesDocument, options);
        }
export function useProductSearchModal_ProductTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductSearchModal_ProductTypesQuery, ProductSearchModal_ProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchModal_ProductTypesQuery, ProductSearchModal_ProductTypesQueryVariables>(ProductSearchModal_ProductTypesDocument, options);
        }
export type ProductSearchModal_ProductTypesQueryHookResult = ReturnType<typeof useProductSearchModal_ProductTypesQuery>;
export type ProductSearchModal_ProductTypesLazyQueryHookResult = ReturnType<typeof useProductSearchModal_ProductTypesLazyQuery>;
export type ProductSearchModal_ProductTypesSuspenseQueryHookResult = ReturnType<typeof useProductSearchModal_ProductTypesSuspenseQuery>;
export type ProductSearchModal_ProductTypesQueryResult = Apollo.QueryResult<ProductSearchModal_ProductTypesQuery, ProductSearchModal_ProductTypesQueryVariables>;
export const ProductSearchModal_SuppliersDocument = gql`
    query ProductSearchModal_Suppliers($organisationIds: [ID!]) {
  searchSuppliers(
    limit: 0
    filters: {organisationIds: $organisationIds, excludeIfAlreadyCrm: false}
  ) {
    edges {
      node {
        organisation {
          id
          title
        }
        crmCompany {
          id
          title
          linkedSystemOrganisationId
        }
      }
    }
  }
}
    `;

/**
 * __useProductSearchModal_SuppliersQuery__
 *
 * To run a query within a React component, call `useProductSearchModal_SuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchModal_SuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchModal_SuppliersQuery({
 *   variables: {
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useProductSearchModal_SuppliersQuery(baseOptions?: Apollo.QueryHookOptions<ProductSearchModal_SuppliersQuery, ProductSearchModal_SuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchModal_SuppliersQuery, ProductSearchModal_SuppliersQueryVariables>(ProductSearchModal_SuppliersDocument, options);
      }
export function useProductSearchModal_SuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchModal_SuppliersQuery, ProductSearchModal_SuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchModal_SuppliersQuery, ProductSearchModal_SuppliersQueryVariables>(ProductSearchModal_SuppliersDocument, options);
        }
export function useProductSearchModal_SuppliersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductSearchModal_SuppliersQuery, ProductSearchModal_SuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchModal_SuppliersQuery, ProductSearchModal_SuppliersQueryVariables>(ProductSearchModal_SuppliersDocument, options);
        }
export type ProductSearchModal_SuppliersQueryHookResult = ReturnType<typeof useProductSearchModal_SuppliersQuery>;
export type ProductSearchModal_SuppliersLazyQueryHookResult = ReturnType<typeof useProductSearchModal_SuppliersLazyQuery>;
export type ProductSearchModal_SuppliersSuspenseQueryHookResult = ReturnType<typeof useProductSearchModal_SuppliersSuspenseQuery>;
export type ProductSearchModal_SuppliersQueryResult = Apollo.QueryResult<ProductSearchModal_SuppliersQuery, ProductSearchModal_SuppliersQueryVariables>;
export const ProductSearchModal_ManufacturersDocument = gql`
    query ProductSearchModal_Manufacturers($organisationIds: [ID!]) {
  searchManufacturers(
    limit: 0
    filters: {organisationIds: $organisationIds, excludeIfAlreadyCrm: false}
  ) {
    edges {
      node {
        organisation {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useProductSearchModal_ManufacturersQuery__
 *
 * To run a query within a React component, call `useProductSearchModal_ManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSearchModal_ManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSearchModal_ManufacturersQuery({
 *   variables: {
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useProductSearchModal_ManufacturersQuery(baseOptions?: Apollo.QueryHookOptions<ProductSearchModal_ManufacturersQuery, ProductSearchModal_ManufacturersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSearchModal_ManufacturersQuery, ProductSearchModal_ManufacturersQueryVariables>(ProductSearchModal_ManufacturersDocument, options);
      }
export function useProductSearchModal_ManufacturersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSearchModal_ManufacturersQuery, ProductSearchModal_ManufacturersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSearchModal_ManufacturersQuery, ProductSearchModal_ManufacturersQueryVariables>(ProductSearchModal_ManufacturersDocument, options);
        }
export function useProductSearchModal_ManufacturersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductSearchModal_ManufacturersQuery, ProductSearchModal_ManufacturersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSearchModal_ManufacturersQuery, ProductSearchModal_ManufacturersQueryVariables>(ProductSearchModal_ManufacturersDocument, options);
        }
export type ProductSearchModal_ManufacturersQueryHookResult = ReturnType<typeof useProductSearchModal_ManufacturersQuery>;
export type ProductSearchModal_ManufacturersLazyQueryHookResult = ReturnType<typeof useProductSearchModal_ManufacturersLazyQuery>;
export type ProductSearchModal_ManufacturersSuspenseQueryHookResult = ReturnType<typeof useProductSearchModal_ManufacturersSuspenseQuery>;
export type ProductSearchModal_ManufacturersQueryResult = Apollo.QueryResult<ProductSearchModal_ManufacturersQuery, ProductSearchModal_ManufacturersQueryVariables>;